import React from 'react';
import { Container, ColumnWrapperEmptyStart, ColumnWrapperEmptyEnd } from './style';
import { ColumnHeader } from './ColumnHeader';
import { CalendarColumnHeader } from './ColumnHeader/styles';

export const ColumnsHeader: React.FC = () => (
    <Container>
        <ColumnWrapperEmptyStart />
        <ColumnHeader name="carOwner" useRemainingSpace={true} />
        <ColumnHeader name="referenceNumber" />
        <ColumnHeader name="lossNumber" />
        <ColumnHeader name="String_insurance_name" />
        <CalendarColumnHeader name="opened" hiddingPoint={1485} />
        <CalendarColumnHeader name="Date_expiryDate" />
        <CalendarColumnHeader name="lastEntry" hiddingPoint={1325} />
        <ColumnWrapperEmptyEnd />
    </Container>
);
