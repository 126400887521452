import { createEvent, createStore, restore } from 'effector';

import { INITIAL_FORM_VALUES } from './constants';

import { SelectOption } from '@wedat/ui-kit/components/Select';
import { FormValues } from '../../types';
import { Result } from './utils/findSelectedStatus';

const selectOptions = createStore<SelectOption[]>([]);
const initialFormValues = createStore<FormValues>(INITIAL_FORM_VALUES);
const existingStatusSubmitted = createStore<Result | null>(null);

const formSubmitted = createEvent<FormValues>();
const submittedFormValues = restore(formSubmitted, INITIAL_FORM_VALUES);

export const formEvents = {
    formSubmitted
};
export const formStores = {
    selectOptions,
    initialFormValues,
    submittedFormValues,
    existingStatusSubmitted
};
