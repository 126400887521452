import { RefObject, useEffect } from 'react';
import { FixedSizeList } from 'react-window';

import { useInstance } from '../../../hooks/useInstance';

export const useScrollToAddedSubject = (listRef: RefObject<FixedSizeList>) => {
    const { currentSubjects, newSubjectAdded } = useInstance('currentSubjects');
    const lastIndex = currentSubjects.length;

    useEffect(
        () =>
            newSubjectAdded.watch(() => {
                // using `queueMicrotask` to scroll AFTER element was added to list
                queueMicrotask(() => listRef.current?.scrollToItem(lastIndex));
            }),
        [lastIndex, listRef, newSubjectAdded]
    );
};
