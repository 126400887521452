import styled from 'styled-components';
import { TextProps } from './index';

export const Container = styled.span<TextProps>`
    ${({ font, theme }) => (font ? theme.typography[font] : theme.typography.defaultText)}
    ${({ color, theme }) => `color: ${color ?? theme.colors.dustBlue['900']};`}
    ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
    ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
    ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
    ${({ textTransform }) => (textTransform ? `text-transform: ${textTransform};` : 'text-transform: unset;')}
    ${({ textOverflow }) =>
        textOverflow === 'ellipsis' && `text-overflow: ${textOverflow}; overflow: hidden; white-space: nowrap;`}
`;
