import React, { FC, useEffect, useRef, useState } from 'react';
import { useStore } from 'effector-react';

import { Button } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, FullscreenOutlined } from '@ant-design/icons';

import './svg.css';
import { maxDelayDoubleClickMs, maxTouchClickMs, scaleFactor, zoomOnDoubleClick } from '../../initData/eventConfig';
import { useTranslation } from 'react-i18next';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { SvgViewerEvents } from '../GroupGraphicSVG3/GroupGraphicSVG3Types';
import { GraphicTooltip, GraphicTooltipType } from '../GraphicDamageSelection/GraphicTooltip';
import { equipmentModel } from '../../stores/equipmentModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { getTooltipPosition } from '../../utils/getTooltipPosition';
import { getLanguage } from '../../utils/datLocale';
// import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';
// import { ReactSVGPanZoom, TOOL_AUTO } from 'react-svg-pan-zoom';
var { ReactSVGPanZoom, TOOL_AUTO } = require('react-svg-pan-zoom');
const { SvgLoader, SvgProxy } = require('@wedat/react-svgmt');
// import { SvgLoader, SvgProxy } from '@wedat/react-svgmt';

export interface ZoneGraphicParamsType {
    repairPositions: RepairPosition[];
    currentZoneDATID: string | null;
    width?: number;
    height?: number;
    graphicSVG?: string;
    onElementClick: (id: string) => void;
    showFullscreen: boolean | undefined;
    setShowFullScreen: (v: boolean) => void;
    showZoomButton?: boolean;

    setEvents?: (events: SvgViewerEvents) => void;
}

let timer: NodeJS.Timeout;
let timerSvg: NodeJS.Timeout;
let clicks = 0;
let clicksSvg = 0;
let onTouchStartTime = 0;

// const test2342 = `<svg height="100" width="100">
// <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
// Sorry, your browser does not support inline SVG.
// </svg> `;

function onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

function pad(num: number, size: number) {
    var s = '000000000' + num;
    return s.substr(s.length - size);
}

export const ZoneGraphic: FC<ZoneGraphicParamsType> = ({
    repairPositions,
    currentZoneDATID,
    width,
    height,
    graphicSVG,
    onElementClick,
    showFullscreen,
    // setShowFullScreen,
    showZoomButton = false,
    setEvents
}) => {
    // debugger;
    // return (
    //     <div>
    //         {graphicSVG?.length}
    //         <svg height={height} width={width}>
    //             <SvgLoader
    //                 // className="genericGraphic"
    //                 // height={height}
    //                 // width={width}
    //                 // height={2500}
    //                 // width={2500}
    //                 svgXML={graphicSVG || test2342}
    //                 // svgXML={test2342}
    //                 viewBox={'0 0 2500 2500'}
    //                 height={height}
    //                 width={width}
    //             />
    //         </svg>
    //     </div>
    // );

    const { t } = useTranslation();

    const zusatzData = useStore(zoneGraphicsModel.stores.zusatzData);
    const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);
    const dataEquipment = useStore(equipmentModel.stores.dataEquipment);
    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);
    const localesParams = useStore(pluginOptionsModel.stores.localesParams);

    let listPresentZone: string[] | undefined = [];
    if (repairPositions?.length) {
        const repairPositionDVNList = repairPositions.map(rp => rp.DATProcessId).filter(onlyUnique);

        const listPresentGroupId = availableAssemblyGroupsStore.availableAssemblyGroups
            .filter(gr =>
                gr.objectsInfo?.find(
                    oi =>
                        (oi.dvnLeft?.dvn && repairPositionDVNList.includes(oi.dvnLeft?.dvn)) ||
                        (oi.dvnRight?.dvn && repairPositionDVNList.includes(oi.dvnRight?.dvn))
                )
            )
            .map(gr => gr.assemblyGroup.assemblyGroupId);

        const listPresentConstructiongroup = zusatzData?.constructiongroup.filter(gr =>
            listPresentGroupId?.includes(gr.constructiongroupid)
        );
        listPresentZone = listPresentConstructiongroup
            ?.reduce(
                (lastVal: number[], curVal) => lastVal.concat(curVal.zones?.zone?.map(z => z.id) || []),
                [] as number[]
            )
            .map(id => pad(id, 3));
    }

    const Viewer = useRef(null);
    const [value, setValue] = useState<Partial<any>>({});
    const [tool, setTool] = useState<any>(TOOL_AUTO);
    const [tooltipOptions, setTooltipOptions] = useState<GraphicTooltipType | null>(null);

    useEffect(() => {
        if (Viewer.current) {
            (Viewer.current as any).fitToViewer();
            setEvents?.({
                zoomIn: () => (Viewer.current as any).zoomOnViewerCenter(scaleFactor),
                zoomOut: () => (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor),
                fitToViewer: () => (Viewer.current as any).fitToViewer()
            });
        }
    }, [Viewer, showFullscreen, setEvents]);

    // useEffect(() => {
    //     const selectedElements = document.getElementsByClassName('selectedElements');
    //     Array.from(selectedElements).forEach(element => element.classList.remove('selectedElements'));
    //     // todo mast be test and rewriter
    //     selectedElementsId.forEach(selectElementId =>
    //         document
    //             .querySelectorAll('[id^=' + selectElementId + ']>path')
    //             .forEach(element => element.classList.add('selectedElements'))
    //     );
    // }, [selectedElementsId]);

    if (!graphicSVG || !width || !height)
        return (
            <div
                style={{
                    width: '100%',
                    margin: '10em 0em 0em 0em',
                    display: 'inline-block'
                }}
            >
                {/* <Spin spinning size="large">
                </Spin> */}
                {t('zoneGraphics')}
            </div>
        );

    const onSetValue = (inValue: any) => {
        const value = {
            ...inValue
            // SVGWidth: 2500,
            // SVGHeight: 2500
        };

        let deltaX = value.SVGWidth * 0.0;
        let deltaY = value.SVGHeight * 0.0;

        let maxX;
        let minX;
        if (value.a > 1) {
            maxX = deltaX;
            minX = -deltaX + value.SVGWidth - value.SVGWidth * value.a;
        } else {
            maxX = deltaX + value.SVGWidth - value.SVGWidth * value.a;
            minX = -deltaX;
        }

        let maxY;
        let minY;
        if (value.d > 1) {
            maxY = deltaY;
            minY = -deltaY + value.SVGHeight - value.SVGHeight * value.d;
        } else {
            maxY = deltaY + value.SVGHeight - value.SVGHeight * value.d;
            minY = -deltaY;
        }

        let e = value.e > maxX ? maxX : value.e < minX ? minX : value.e;
        let f = value.f > maxY ? maxY : value.f < minY ? minY : value.f;

        setValue({ ...value, e, f });
    };

    const onSvgClick = (e: any, touch = false) => {
        clicksSvg++; //count clicks
        if (clicksSvg === 1) {
            timerSvg = setTimeout(function () {
                clicksSvg = 0;
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clearTimeout(timerSvg); //prevent single-click action
            clicks = 0; //after action performed, reset counter
            clicksSvg = 0;
            if (e.scaleFactor !== 1) (Viewer.current as any).reset();
            else {
                // if (!showFullscreen) setShowFullScreen(true);
                // else
                touch
                    ? (Viewer.current as any).zoom(e.value.endX, e.value.endY, zoomOnDoubleClick)
                    : (Viewer.current as any).zoom(e.point.x, e.point.y, zoomOnDoubleClick);
            }
        }
    };

    const onElementClickId = (e: any) => {
        // console.log('onClick element');
        clicks++; //count clicks
        if (clicks === 1) {
            timer = setTimeout(function () {
                clicks = 0;
                onElementClick(e.target.parentElement.parentElement.id);
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clicks = 0; //after action performed, reset counter
        }
    };

    const onElementMouseEnter = (e: any) => {
        const languageCode = getLanguage(localesParams?.language).languageCode || 'ENG';

        const subModelId = datECode.slice(8, 11);
        const datid = e.target.parentElement.parentElement.id;
        const groupZoneId = parseInt(datid.slice(-2));

        // available construction groups on hovered SVG click
        const constructionGroups = zusatzData?.constructiongroup.filter(
            constGr =>
                (!groupZoneId ||
                    (Array.isArray(constGr.zones?.zone)
                        ? constGr.zones?.zone.find(zone => zone.id === (groupZoneId || -1))
                        : !!groupZoneId)) &&
                ((!constGr.options?.option && !constGr.subModels?.subModel) ||
                    constGr.options?.option?.find(itemOption =>
                        dataEquipment.find(equipItem => equipItem.selected && equipItem.DatEquipmentId === itemOption)
                    ) ||
                    constGr.subModels?.subModel?.find(
                        itemSubModel => parseInt(subModelId) === parseInt(itemSubModel.toString())
                    ))
        );

        const tooltipText =
            constructionGroups
                ?.map(
                    group =>
                        group.constructionGroupNames.lang.find(lng => lng.attr.language === languageCode)?.['#text']
                )
                .join('\n') || '';

        setTooltipOptions({
            text: tooltipText,
            ...getTooltipPosition(e)
        });
    };

    const onElementMouseLeave = (_e: any) => {
        setTooltipOptions(null);
    };

    const currentZoom = value.a;

    return (
        <div>
            {showZoomButton && (
                <div
                    style={{
                        // textAlign: 'left',
                        position: 'absolute',
                        // left: '0',
                        top: '0%',
                        right: '-48px',
                        zIndex: showFullscreen ? 1011 : 11,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        padding: '8px'
                    }}
                >
                    <Button
                        style={{ marginBottom: '6px' }}
                        icon={<ZoomInOutlined />}
                        onClick={_e => {
                            (Viewer.current as any).zoomOnViewerCenter(scaleFactor);
                        }}
                        onTouchEnd={_e => console.log('onTouchEnd ZoomInOutlined')}
                    />
                    <br />
                    <Button
                        style={{ marginBottom: '6px' }}
                        icon={<ZoomOutOutlined />}
                        onClick={() => {
                            (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor);
                        }}
                    />
                    <br />
                    <Button
                        disabled={currentZoom === 1}
                        style={{ marginBottom: '6px' }}
                        icon={<FullscreenOutlined />}
                        onClick={() => {
                            (Viewer.current as any).fitToViewer();
                        }}
                    />
                </div>
            )}
            {/* <div
                     // style={{ width, height }}
                     // onTouchStart={(_e: any) => {
                     //     onTouchStartTime = new Date().getTime();
                     // }}
                     // onTouchEnd={(e: any) => {
                     //     if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                     //     onSvgClick(e, true);
                     // }}
                     // onClick={onSvgClick}
                 > */}
            {tooltipOptions && <GraphicTooltip {...tooltipOptions} />}
            <ReactSVGPanZoom
                // SVGStyle={{ width, height }}
                value={value as any}
                onChangeValue={onSetValue}
                ref={Viewer}
                // SVGBackground="#f0f2f5"
                // background="#f0f2f5"
                // background="#ffffff"
                SVGBackground="var(--weDat-grapa-background)"
                background="var(--weDat-grapa-background)"
                customMiniature={() => null}
                customToolbar={() => null}
                width={width}
                height={height}
                scaleFactorMax={10}
                scaleFactorMin={0.6} // 0.6
                scaleFactor={scaleFactor}
                scaleFactorOnWheel={scaleFactor}
                detectWheel={showFullscreen}
                // detectAutoPan={showFullscreen}
                detectAutoPan={true}
                detectPinchGesture={showFullscreen}
                toolbarProps={{ SVGAlignX: 'right', SVGAlignY: 'center', position: 'right' }}
                // tool="auto"
                // tool="pan"
                // tool={(showFullscreen ? tool : 'none') as any}
                // tool={(tool) as any}
                tool={(currentZoom !== 1 ? tool : 'none') as any}
                onChangeTool={setTool}
                preventPanOutside={false}
                disableDoubleClickZoomWithToolAuto={true}
                onTouchStart={(_e: any) => {
                    onTouchStartTime = new Date().getTime();
                }}
                onTouchEnd={(e: any) => {
                    if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                    if (currentZoom !== 1) onSvgClick(e, true);
                }}
                onClick={onSvgClick}
            >
                <svg height={height} width={width}>
                    <SvgLoader
                        // className="genericGraphic"
                        // height={height}
                        // width={width}
                        // height={2500}
                        // width={2500}
                        svgXML={graphicSVG}
                        viewBox={'0 0 2500 2500'}
                        className={'zoneGraphics1047'}
                    >
                        <SvgProxy
                            selector='[id^="DATID_"] path'
                            // selector=':not([id^="DATID_"])'

                            onTouchStart={(_e: any) => {
                                onTouchStartTime = new Date().getTime();
                            }}
                            ontouchend={(e: any) => {
                                if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                                if (currentZoom !== 1) onElementClickId(e);
                            }}
                            onClick={onElementClickId}
                            onMouseEnter={onElementMouseEnter}
                            onMouseLeave={onElementMouseLeave}
                        />

                        <SvgProxy
                            // key={selectElementId}
                            class={''}
                            // selector={`[id^="DATID"] [id$="${currentZoneDATID?.slice(-3)}"] path`}
                            selector={`[id^="DATID_"] > g > path`}
                            // selector=':not([id^="DATID_"])'
                            // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                        />

                        <SvgProxy
                            // key={selectElementId}
                            class={'selectedGroupZone1077'}
                            // selector={`[id^="DATID"] [id$="${currentZoneDATID?.slice(-3)}"] path`}
                            selector={`[id$="${currentZoneDATID?.slice(-3)}"] > g > path`}
                            // selector=':not([id^="DATID_"])'
                            // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                        />

                        {listPresentZone?.map(zoneId => (
                            <SvgProxy
                                key={zoneId}
                                class={
                                    zoneId === currentZoneDATID?.slice(-3)
                                        ? 'selectedGroupZone1077withPosition'
                                        : 'withPositionsZone'
                                }
                                selector={`[id$="${zoneId}"] > g > path`}
                            />
                        ))}

                        {/* {selectedElementsId.map(selectElementId => (
                            <SvgProxy
                                key={selectElementId}
                                class={'selectedElements'}
                                selector={'[id^=' + selectElementId + ']>path'}
                                // selector=':not([id^="DATID_"])'
                                // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                            />
                        ))} */}
                    </SvgLoader>
                </svg>
            </ReactSVGPanZoom>
            {/* </div> */}
        </div>
    );
};
