import React from 'react';
import {
    BackButton,
    BackButtonContainer,
    ButtonGoToClaim,
    Buttons,
    ButtonWrapper,
    CarOwner,
    Container,
    IconWrapper,
    InfoContainer,
    PhotosContainer,
    PreloaderPreview,
    Price,
    StatusWrapper,
    TopContainer,
    TopContainerInfo,
    TopContainerMainInfo,
    VehicleImage
} from './styles';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { useStore } from 'effector-react';
import { previewEffects, previewEvents, previewStores } from '../../../stores/preview';
import { Arrow } from '@wedat/ui-kit/assets/Icon';
import { ExpiryDateFormat } from '../ExpiryDateFormat';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { currencyValues } from '@dat/core/constants/currency';
import { Tabs } from '@wedat/ui-kit';
import { useTranslation } from 'react-i18next';
import { tabs } from './constants';
import { General } from './General';
import { History } from './History';
import { Comment } from './Comment';
import { Users } from './Users';
import { Printout } from '@dat/printout';
import { imagesStores } from '../../../stores/images';
import { Photos } from './Photos';
import { statusesEvents } from '../../../stores/statuses';
import { pluginEffects, pluginStores } from '../../../stores/plugin';
import { GalleryIcon } from '../../../assets/icons';
import { sharedGalleryEvents } from '@dat/shared-models/gallery';
import { PreviewTabs } from '../../../types/plugin';

export interface PreviewProps {
    close: () => void;
}

export const Preview: React.FC<PreviewProps> = ({ close }) => {
    const { t } = useTranslation();
    const isMobile = useMedia(sizes.phone);
    const claimWrapper = useStore(previewStores.claimWrapper);
    const statusName = useStore(previewStores.status);
    const vehicleImage = useStore(previewStores.vehicleImage);
    const assignedUsers = useStore(previewStores.users);
    const attachments = useStore(imagesStores.attachments);
    const templateId = useStore(sharedTemplateStores.templateId);
    const templateData = templateId ? claimWrapper?.fieldSets?.[templateId]?.data : undefined;

    const expiryDate = templateData?.expiryDate ? new Date(Number(templateData.expiryDate)) : undefined;
    const expiryStatus = Number(templateData?.expiryStatus);
    const currency = currencyValues[claimWrapper?.claim?.activeCalculation?.calculationSettings?.currency || 'EUR'];

    const hideTabs = useStore(pluginStores.pluginOptions)?.settings?.preview?.hideTabs || [];
    const tabsValues = tabs({ t, usersCount: assignedUsers.length, hideTabs });
    const activeTab = useStore(previewStores.activeTab) || tabsValues?.[0]?.id;
    const tabsHandler = (id: React.SetStateAction<string>) => {
        if (id === PreviewTabs.History && claimWrapper?.claim?.id) {
            previewEffects.loadClaimHistoryFx({ contractId: claimWrapper?.claim?.id });
        }
        previewEvents.setActiveTab(id as PreviewTabs);
    };
    const openContract = () => pluginEffects.callOnContractClickFx({ contractId: claimWrapper?.claim?.id });

    const pendingLoadPreview = useStore(previewEffects.loadClaimFx.pending);

    if (pendingLoadPreview) {
        return (
            <Container onClick={event => event.stopPropagation()}>
                <TopContainer>
                    <BackButtonContainer>
                        <BackButton onClick={() => close()}>
                            <Arrow />
                        </BackButton>
                    </BackButtonContainer>
                </TopContainer>
                <PreloaderPreview />
            </Container>
        );
    }

    const renderButtons = () => (
        <Buttons>
            <Price>
                {!!claimWrapper && (claimWrapper?.claim?.activeCalculation?.totalPrice || '0,00')}
                {` ${currency}`}
            </Price>
            <ButtonGoToClaim onClick={openContract}>{t('preview.buttons.goToClaim')}</ButtonGoToClaim>
            {!!claimWrapper && (
                <>
                    <ButtonWrapper>
                        <IconWrapper>
                            <GalleryIcon
                                onClick={() => {
                                    sharedGalleryEvents.setSelectedContractId(claimWrapper.claim.id);
                                }}
                            />
                        </IconWrapper>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <IconWrapper>
                            <Printout
                                options={{
                                    contractId: claimWrapper.claim.id,
                                    registrationNumber: claimWrapper.claim?.vehicle?.registrationNumber,
                                    vin: claimWrapper.claim?.vehicle?.vin,
                                    displayTitle: false,
                                    menuSide: 'left'
                                }}
                            />
                        </IconWrapper>
                    </ButtonWrapper>
                </>
            )}
        </Buttons>
    );

    return (
        <Container onClick={event => event.stopPropagation()}>
            <TopContainer>
                <BackButtonContainer>
                    <BackButton onClick={() => close()}>
                        <Arrow />
                    </BackButton>
                </BackButtonContainer>
                <TopContainerInfo>
                    <TopContainerMainInfo>
                        <StatusWrapper
                            onClick={() =>
                                claimWrapper?.claim.id &&
                                statusesEvents.openStatusModalByContractId(claimWrapper.claim.id)
                            }
                        >
                            {!!claimWrapper && (t(`claim-management:status.${statusName}`) || '---')}
                        </StatusWrapper>
                        <div>
                            <ExpiryDateFormat date={expiryDate} efficiencyStatus={expiryStatus} />
                        </div>
                    </TopContainerMainInfo>
                    <CarOwner>{`${templateData?.owner_name || ''} ${templateData?.owner_surname || ''}`}</CarOwner>
                    {!isMobile && renderButtons()}
                </TopContainerInfo>
                <VehicleImage>
                    <img src={vehicleImage} alt="" />
                </VehicleImage>
            </TopContainer>
            {isMobile && renderButtons()}
            <PhotosContainer>
                {!!claimWrapper && (
                    <Photos
                        attachments={attachments.filter(({ binaryData }) => binaryData?.mimeType?.startsWith('image'))}
                        claimId={claimWrapper.claim.id}
                    />
                )}
            </PhotosContainer>
            {!!claimWrapper && (
                <InfoContainer>
                    <Tabs values={tabsValues} onChange={tabsHandler} active={activeTab} typeStyle="primary" />
                    {activeTab === PreviewTabs.General && <General templateData={templateData || {}} />}
                    {activeTab === PreviewTabs.History && <History />}
                    {activeTab === PreviewTabs.Comment && <Comment />}
                    {activeTab === PreviewTabs.Users && <Users />}
                </InfoContainer>
            )}
        </Container>
    );
};
