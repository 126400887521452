import { combine, createEvent, createStore, restore, sample } from 'effector';

import { API } from '@dat/api';
import { domain } from './plugin';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { parseVXSResponse, splitDatECode, filterArrayOfObjectsByExistingKeys, createDatECode } from '@dat/core/utils';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { vehicleSelectionEffects } from './vehicleSelection';
import { vehicleImagesEffects } from './vehicleImages';
import { vehicleRepairEffects } from './vehicleRepair';
import { multipleVariantSelectionEffects } from './multipleVariantSelection';

import {
    IdentifiedVehicleBookInfo,
    ParsedVXSVehicleObject,
    VehicleInfoObject,
    VinRequestResultEquipmentObject,
    VinResult
} from '@dat/core/types';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { getErrorInformation } from '@dat/core/utils/parseErrorMessage';
import { parseXML } from '@dat/api/utils';
import { modalsEvents } from './modals';
import { i18n } from '@dat/shared-models/i18n';

const setAdditionalInfoSubModels = createEvent<AdditionalInfoProp>();

const additionalInfoSubModels = createStore<AdditionalInfoProp>({}).on(setAdditionalInfoSubModels, (_, data) => data);

// Util from two effects (getVehicleByLicencePlateFx, getVehicleIdentificationByVinFx) for watch.fail
const checkErrorForOpenSubModelModal = async (error: Error) => {
    const errorResponse = getErrorInformation(error.message);

    if (errorResponse && errorResponse.errorBlock) {
        const xmlBlock = parseXML<DAT.VXS>(errorResponse.errorBlock);
        const vehicleField = xmlBlock.getSingleField('Vehicle');

        const infoFieldsObject = vehicleField.getSingleFieldsInnerTextObject(
            'DatECode',
            'VehicleIdentNumber',
            'ConstructionTime',
            'InitialRegistration',
            'Container',
            'VehicleTypeName',
            'ManufacturerName',
            'BaseModelName',
            'SubModelName',
            'VehicleType',
            'Manufacturer',
            'BaseModel',
            'SubModel'
        );

        const reponseSubModels = await vehicleSelectionEffects.getSubModelsFx({
            vehicleType: infoFieldsObject.VehicleType,
            manufacturer: infoFieldsObject.Manufacturer,
            baseModel: infoFieldsObject.BaseModel
        });

        setAdditionalInfoSubModels({
            vehicleType: infoFieldsObject.VehicleType,
            manufacturerName: infoFieldsObject.ManufacturerName,
            baseModelName: infoFieldsObject.BaseModelName,
            manufacturer: infoFieldsObject.Manufacturer,
            baseModel: infoFieldsObject.BaseModel,
            subModel: infoFieldsObject.SubModel
        });

        const subModels = reponseSubModels.map(item => item.key);

        if (subModels.includes(infoFieldsObject.SubModel)) {
            setSubModelEqual(true);
        } else {
            modalsEvents.toggleSubModelsModalOpen(true);
        }
    }
};

const setSubModelEqual = createEvent<Boolean>();
const subModelsEqual = createStore<Boolean>(false).on(setSubModelEqual, (_, data) => data);

//
/*** Identify vehicle by full DAT code ***/
//
const getVehicleIdentificationFx = domain.createEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentification(data);

        return parseVXSResponse(response)[0];
    })
});

//
/*** Identify vehicle by uncompleted DAT code ***/
//
export interface GetPartialVehicleIdentificationParam {
    datECode: string;
    alternativeModel?: boolean;
}

// TODO: construction times
const getPartialVehicleIdentificationFx = createNotifyingEffect({
    handler: async ({ datECode, alternativeModel }: GetPartialVehicleIdentificationParam) => {
        // Options IDs
        const {
            vehicleTypeId: vehicleType,
            manufacturerId: manufacturer,
            baseModelId: baseModel,
            subModelId: subModel
        } = splitDatECode(datECode);
        // Result data
        const validOptions = {
            vehicleType: '',
            manufacturer: '',
            baseModel: '',
            subModel: ''
        };
        let validDatECode = '';
        let isContinuing = true;
        // Requests data
        let manufacturers: DAT.KeyValueField[] = [];
        let baseModels: DAT.KeyValueField[] = [];
        let subModels: DAT.KeyValueField[] = [];

        let { getManufacturersFx, getBaseModelsFx, getSubModelsFx } = vehicleSelectionEffects;

        if (alternativeModel) {
            getManufacturersFx = vehicleSelectionEffects.getAlternativeManufacturersFx;
            getBaseModelsFx = vehicleSelectionEffects.getAlternativeBaseModelsFx;
            getSubModelsFx = vehicleSelectionEffects.getAlternativeSubModelsFx;
        }

        // Slice equipment code
        datECode = datECode.slice(0, 11);

        if (vehicleType) {
            manufacturers = await getManufacturersFx({ vehicleType });

            if (!manufacturers.length) {
                isContinuing = false;
            } else {
                validOptions.vehicleType = vehicleType;
                validDatECode = datECode.slice(0, 2);
            }
        }
        if (isContinuing && manufacturer) {
            baseModels = await getBaseModelsFx({ vehicleType, manufacturer });

            if (baseModels.length) {
                validOptions.manufacturer = manufacturer;
                validDatECode = datECode.slice(0, 5);
            } else {
                isContinuing = false;
            }
        }
        if (isContinuing && baseModel) {
            [subModels] = await Promise.all([
                getSubModelsFx({ vehicleType, manufacturer, baseModel }),
                vehicleRepairEffects.getPaintTypesFx({
                    vehicleType,
                    manufacturer,
                    mainType: baseModel
                })
            ]);

            if (subModels.length) {
                validOptions.baseModel = baseModel;
                validDatECode = datECode.slice(0, 8);
            } else {
                isContinuing = false;
            }
        }
        if (isContinuing && subModel) {
            try {
                // Check if sub model key is in options list
                const isSubModelValid = subModels.some(({ key }) => key === subModel);

                if (!isSubModelValid) {
                    return { validOptions, validDatECode };
                }

                await Promise.all([
                    vehicleSelectionEffects.getAllEquipmentObjectFx({
                        vehicleType,
                        manufacturer,
                        baseModel,
                        subModel
                    }),
                    vehicleImagesEffects.getVehicleImagesFx({ datECode })
                ]);

                validOptions.subModel = subModel;
                validDatECode = datECode.slice(0, 11);
            } catch {}
        }

        return { validOptions, validDatECode };
    }
});

interface vehicleOptionsProps {
    vehicleType: string;
    manufacturer: string;
    baseModel: string;
    subModel: string;
}

const getPartialValuesVehicleIdentificationFx = createNotifyingEffect({
    handler: async ({ vehicleType, manufacturer, baseModel, subModel }: vehicleOptionsProps) => {
        // Result data
        const validOptions = {
            vehicleType: '',
            manufacturer: '',
            baseModel: '',
            subModel: ''
        };
        let isContinuing = true;
        // Requests data
        let manufacturers: DAT.KeyValueField[] = [];
        let baseModels: DAT.KeyValueField[] = [];
        let subModels: DAT.KeyValueField[] = [];

        let { getManufacturersFx, getBaseModelsFx, getSubModelsFx } = vehicleSelectionEffects;

        if (vehicleType) {
            manufacturers = await getManufacturersFx({ vehicleType });

            if (!manufacturers.length) {
                isContinuing = false;
            } else {
                validOptions.vehicleType = vehicleType;
            }
        }
        if (isContinuing && manufacturer) {
            baseModels = await getBaseModelsFx({ vehicleType, manufacturer });

            if (baseModels.length) {
                validOptions.manufacturer = manufacturer;
            } else {
                isContinuing = false;
            }
        }
        if (isContinuing && baseModel) {
            [subModels] = await Promise.all([
                getSubModelsFx({ vehicleType, manufacturer, baseModel }),
                vehicleRepairEffects.getPaintTypesFx({
                    vehicleType,
                    manufacturer,
                    mainType: baseModel
                })
            ]);

            if (subModels.length) {
                validOptions.baseModel = baseModel;
            } else {
                isContinuing = false;
            }
        }
        if (isContinuing && subModel) {
            try {
                // Check if sub model key is in options list
                const isSubModelValid = subModels.some(({ key }) => key === subModel);

                if (!isSubModelValid) {
                    return { validOptions };
                }

                const datECode = createDatECode({ vehicleType, manufacturer, baseModel, subModel });

                await Promise.all([
                    vehicleSelectionEffects.getAllEquipmentObjectFx({
                        vehicleType,
                        manufacturer,
                        baseModel,
                        subModel
                    }),
                    vehicleImagesEffects.getVehicleImagesFx({ datECode })
                ]);

                validOptions.subModel = subModel;
            } catch {}
        }

        return { validOptions };
    }
});

//
/*** Identify vehicle by VIN ***/
//
const getVehicleIdentificationByVinFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationByVinRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByVin(data);
        const vehicleObjects = parseVXSResponse(response);

        if (vehicleObjects.length === 1) {
            return vehicleObjects[0];
        } else {
            // TODO: get rid of modifying pending state
            // Manually disable pending to remove preloader
            const disablePending = domain.createEvent();

            getVehicleIdentificationByVinFx.pending.reset(disablePending);
            getVehicleByLicencePlateFx.pending.reset(disablePending);
            getVehicleByLicencePlateItalyFx.pending.reset(disablePending);

            disablePending();

            // Returning promise that is resolved, when vehicle is selected,
            // or rejected when MultipleVariantSelectionModal is just closed
            return await multipleVariantSelectionEffects.getVehicleFromMultipleSelectionFx(vehicleObjects);
        }
    })
});

getVehicleIdentificationByVinFx.fail.watch(({ error }) => {
    checkErrorForOpenSubModelModal(error);
});

sample({
    clock: getVehicleIdentificationByVinFx.doneData,
    target: sharedVehicleStores.vinResult
});

const setLastSuccessfullyRequestedVin = domain.createEvent<string>();
const resetLastSuccessfullyRequestedVin = domain.createEvent();
const lastSuccessfullyRequestedVin = restore(setLastSuccessfullyRequestedVin, '').reset(
    resetLastSuccessfullyRequestedVin
);

//
/*** Equipment & VINEquipment fields of VIN-request result ***/
//
const setVinRequestResultEquipment = domain.createEvent<VinRequestResultEquipmentObject>();
const resetVinRequestResultEquipment = domain.createEvent();
const vinRequestResultEquipment = restore(setVinRequestResultEquipment, {
    equipment: [],
    vinResultEquipment: []
}).reset(resetVinRequestResultEquipment);
const vinEquipmentIds = vinRequestResultEquipment.map(({ vinResultEquipment }) =>
    filterArrayOfObjectsByExistingKeys(vinResultEquipment, ['datNumber']).map(({ datNumber }) => +datNumber)
);

//
/*** Vehicle colors. Received when vehicle is identified by VIN ***/
//
const setVinColors = domain.createEvent<DAT2.VINColor[]>();
const resetVinColors = domain.createEvent();
const vinColors = restore(setVinColors, []).reset(resetVinColors);

//
/*** Vehicle info. Received when vehicle is identified by VIN ***/
//
const setVehicleInfo = domain.createEvent<VehicleInfoObject>();
const resetVehicleInfo = domain.createEvent();
const vehicleInfo = restore(setVehicleInfo, {} as VehicleInfoObject).reset(resetVehicleInfo);

// Set vehicleInfo
sample({
    source: sharedVehicleStores.vinResult,
    filter: (vinResult): vinResult is ParsedVXSVehicleObject => !!vinResult?.vehicleInfo,
    fn: (vinResult: ParsedVXSVehicleObject) => vinResult.vehicleInfo as VehicleInfoObject,
    target: setVehicleInfo
});

//
/*** VINResult ***/
//

const vinResult = combine<VinResult>({
    vin: lastSuccessfullyRequestedVin,
    vinRequestResultEquipment,
    vinColors,
    vehicleInfo
});

//
/*** Identify vehicle by KBA (FMA) ***/
//
const getVehicleIdentificationByKbaFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationByKbaRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByKba(data);

        return parseVXSResponse(response)[0];
    })
});

//
/*** Identify vehicle by Austrian national code ***/
//
const getVehicleIdentificationByNationalCodeAustriaFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationByNationalCodeAustriaRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByNationalCodeAustria(data);

        return parseVXSResponse(response)[0];
    })
});

//
/*** Identify vehicle by Switzerland codes ***/
//
const getVehicleIdentificationByCodeSwitzerlandFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationByCodeSwitzerlandRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByCodeSwitzerland(data);

        return parseVXSResponse(response)[0];
    })
});

//
/*** Identify vehicle by number plate Italy ***/
//
const getVehicleByLicencePlateItalyFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVinByLicencePlateItalyRequest) => {
        const response = await API.vehicleIdentification.getVinByLicencePlateItaly(data);
        const { vin, licencePlate, initialRegistration, modelPermissionNumber, engineCode, lastRevisionDate } =
            response.getSingleFieldsInnerTextObject(
                'vin',
                'licencePlate',
                'initialRegistration',
                'modelPermissionNumber',
                'engineCode',
                'lastRevisionDate'
            );

        const parsedVXSVehicleObject = await getVehicleIdentificationByVinFx({ vin });

        return {
            parsedVXSVehicleObject,
            vin,
            initialRegistration,
            licencePlate,
            modelPermissionNumber,
            engineCode,
            lastRevisionDate
        };
    })
});

//
/*** Result of registration number request (Italy) ***/
//

const resetRegistrationNumberRequestResult = domain.createEvent();
const registrationNumberRequestResult = domain
    .createStore<IdentifiedVehicleBookInfo | null>(null)
    .reset(resetRegistrationNumberRequestResult);

// Setting result of registration number request
sample({
    source: getVehicleByLicencePlateItalyFx.doneData,
    fn: ({ parsedVXSVehicleObject: _, ...result }) => result,
    target: registrationNumberRequestResult
});

//
/*** Identify vehicle by number plate Germany ***/
//
const getVehicleByLicencePlateGermanyFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVinByLicencePlateGermanyRequest) => {
        const response = await API.vehicleIdentification.getVinByLicencePlateGermany(data);
        const vin = response.getSingleField('vin')._innerText;
        const parsedVXSVehicleObject = await getVehicleIdentificationByVinFx({ vin });

        return { parsedVXSVehicleObject, vin };
    })
});

//
/*** Identify vehicle by number plate Netherlands ***/
//
const getVehicleByLicencePlateNetherlandsFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVinByLicencePlateNetherlandsRequest) => {
        const response = await API.vehicleIdentification.getVinByLicencePlateNetherlands(data);
        const vin = response.getSingleField('vin')._innerText;
        const parsedVXSVehicleObject = await getVehicleIdentificationByVinFx({ vin });

        return { parsedVXSVehicleObject, vin };
    })
});

//
/*** Identify vehicle by number plate France ***/
//
const getVehicleByLicencePlateFranceFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVinByLicencePlateFranceRequest) => {
        const response = await API.vehicleIdentification.getVinByLicencePlateFrance(data);
        const vin = response.getSingleField('vin')._innerText;
        const initialRegistration = response.getSingleField('initialRegistration')._innerText;
        const parsedVXSVehicleObject = await getVehicleIdentificationByVinFx({ vin });

        return { parsedVXSVehicleObject, vin, initialRegistration };
    })
});

//
/*** Identify vehicle by number plate Spain ***/
//
const getVehicleByLicencePlateSpainFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVinByLicencePlateSpainRequest) => {
        const response = await API.vehicleIdentification.getVinByLicencePlateSpain(data);
        const vin = response.getSingleField('vin')._innerText;
        const initialRegistration = response.getSingleField('initialRegistration')._innerText;
        const parsedVXSVehicleObject = await getVehicleIdentificationByVinFx({ vin });

        return { parsedVXSVehicleObject, vin, initialRegistration };
    })
});

//
/*** Identify vehicle by number plate every country ***/
//
export interface GetVehicleByLicencePlateParam {
    licencePlate: string;
    country: string;
}

const getVehicleByLicencePlateFx = createNotifyingEffect({
    handler: cDRH(async ({ licencePlate, country }: GetVehicleByLicencePlateParam) => {
        let parsedVXSVehicleObject: ParsedVXSVehicleObject | undefined;
        let vin = '';
        let initialRegistration = '';

        switch (country) {
            case 'IT': {
                ({ parsedVXSVehicleObject, vin, initialRegistration } = await getVehicleByLicencePlateItalyFx({
                    licencePlate
                }));
                break;
            }
            case 'DE': {
                ({ parsedVXSVehicleObject, vin } = await getVehicleByLicencePlateGermanyFx({
                    licencePlate
                }));
                break;
            }
            case 'NL': {
                ({ parsedVXSVehicleObject, vin } = await getVehicleByLicencePlateNetherlandsFx({
                    licencePlate
                }));
                break;
            }
            case 'FR': {
                ({ parsedVXSVehicleObject, vin, initialRegistration } = await getVehicleByLicencePlateFranceFx({
                    licencePlate
                }));
                break;
            }
            case 'CH': {
                parsedVXSVehicleObject = await getVehicleIdentificationByCodeSwitzerlandFx({
                    licencePlate
                });
                break;
            }
            case 'ES': {
                ({ parsedVXSVehicleObject, vin, initialRegistration } = await getVehicleByLicencePlateSpainFx({
                    licencePlate
                }));
                break;
            }
        }

        if (parsedVXSVehicleObject) {
            parsedVXSVehicleObject.vehicleInfo.vin = vin;
            parsedVXSVehicleObject.vehicleInfo.initialRegistration = initialRegistration;
        }

        return parsedVXSVehicleObject;
    })
});

interface AdditionalInfoProp {
    vehicleType?: string;
    manufacturerName?: string;
    baseModelName?: string;
    manufacturer?: string;
    baseModel?: string;
    subModel?: string;
}

getVehicleByLicencePlateFx.fail.watch(({ error }) => {
    checkErrorForOpenSubModelModal(error);
});

const setLastSuccessfullyRequestedLicencePlate = domain.createEvent<string>();
const resetLastSuccessfullyRequestedLicencePlate = domain.createEvent();
const lastSuccessfullyRequestedLicencePlate = restore(setLastSuccessfullyRequestedLicencePlate, '').reset(
    resetLastSuccessfullyRequestedLicencePlate
);

//
/*** Search vehicle by text ***/
//
const searchVehicleByTextFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetSubModelsByTextSearchRequest) => {
        const response = await API.vehicleSelection.getSubModelsByTextSearch(data);
        const countOfSubModelsFound = +response.getSingleField('countOfSubModelsFound')._innerText;

        if (countOfSubModelsFound === 0) {
            throw new Error(i18n.t('vehicle-selection:noEntryFoundInTextSearch'));
        }

        const vehicleObjects = parseVXSResponse(response);

        if (countOfSubModelsFound === 1) {
            return vehicleObjects[0];
        } else {
            // Manually disable pending to remove preloader
            const disablePending = domain.createEvent();

            searchVehicleByTextFx.pending.reset(disablePending);

            disablePending();

            // Returning promise that is resolved, when vehicle is selected,
            // or rejected when MultipleVariantSelectionModal is just closed
            return await multipleVariantSelectionEffects.getVehicleFromMultipleSelectionFx(vehicleObjects);
        }
    })
});

//
/*** Export ***/
//
export const vehicleIdentificationEvents = {
    setLastSuccessfullyRequestedVin,
    resetLastSuccessfullyRequestedVin,
    setVinRequestResultEquipment,
    resetVinRequestResultEquipment,
    setVinColors,
    resetVinColors,
    setVehicleInfo,
    resetVehicleInfo,
    resetRegistrationNumberRequestResult,
    setLastSuccessfullyRequestedLicencePlate,
    resetLastSuccessfullyRequestedLicencePlate,
    setAdditionalInfoSubModels,
    setSubModelEqual
};
export const vehicleIdentificationEffects = {
    getVehicleIdentificationFx,
    getPartialVehicleIdentificationFx,
    getVehicleIdentificationByVinFx,
    getVehicleIdentificationByKbaFx,
    getVehicleIdentificationByNationalCodeAustriaFx,
    getVehicleIdentificationByCodeSwitzerlandFx,
    getVehicleByLicencePlateFx,
    getVehicleByLicencePlateItalyFx,
    searchVehicleByTextFx,
    getPartialValuesVehicleIdentificationFx
};
export const vehicleIdentificationStores = {
    lastSuccessfullyRequestedVin,
    vinRequestResultEquipment,
    vinEquipmentIds,
    vinColors,
    vinResult,
    vehicleInfo,
    registrationNumberRequestResult,
    lastSuccessfullyRequestedLicencePlate,
    additionalInfoSubModels,
    subModelsEqual
};
export const combinedVehicleIdentificationStores = combine(vehicleIdentificationStores);
