import { StepperConfig } from '../types/config';

const CUSTOMER_INFO_OPTIONS = {
    FormBuilder: {
        data: [
            {
                formName: 'defaultForm'
            },
            {
                groups: [
                    {
                        groupName: 'Selection Client',
                        content: {
                            rows: [
                                {
                                    name: 'firstRow',
                                    fields: [
                                        {
                                            Type: 'string',
                                            id: 'firstName',
                                            label: 'First name',
                                            visible: true,
                                            required: false,
                                            initValue: ''
                                        },
                                        {
                                            Type: 'string',
                                            id: 'lastName',
                                            label: 'Last name ',
                                            visible: true,
                                            required: true,
                                            initValue: ''
                                        },
                                        {
                                            Type: 'subject',
                                            id: 'repairer_id',
                                            label: '"Repairer"',
                                            showList: true,
                                            visible: true,
                                            required: false
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
            {
                conditions: []
            }
        ]
    }
};

const INSURANCE_INFO_OPTIONS = {
    FormBuilder: {
        data: [
            {
                formName: 'defaultForm'
            },
            {
                groups: [
                    {
                        groupName: 'Selection assurance',
                        content: {
                            rows: [
                                {
                                    name: 'firstRow',
                                    fields: [
                                        {
                                            Type: 'string',
                                            id: 'insurance',
                                            label: 'insurance',
                                            visible: true,
                                            required: true,
                                            initValue: ''
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
            {
                conditions: []
            }
        ]
    }
};

const DAMAGE_INFO_OPTIONS = {
    FormBuilder: {
        data: [
            {
                formName: 'defaultForm'
            },
            {
                groups: [
                    {
                        groupName: 'Damage information',
                        content: {
                            rows: [
                                {
                                    name: 'firstRow',
                                    fields: [
                                        {
                                            Type: 'string',
                                            id: 'accidentDescription',
                                            label: 'Accident Description',
                                            visible: true,
                                            required: false,
                                            initValue: ''
                                        },
                                        {
                                            Type: 'string',
                                            id: 'damageDescription',
                                            label: 'damage description',
                                            visible: true,
                                            required: false,
                                            initValue: ''
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            },
            {
                conditions: []
            }
        ]
    }
};

export const CONFIG: StepperConfig[] = [
    {
        title: 'ClientSelection',
        plugin: ['FormBuilder'],
        options: CUSTOMER_INFO_OPTIONS
    },
    {
        title: 'VehicleSelection ',
        plugin: ['VehicleSelectionModal'],
        options: {}
    },
    {
        title: 'FastTrack',
        plugin: ['FastTrack'],
        options: {}
    },
    {
        title: 'DamageInformation',
        plugin: ['FormBuilder', 'DamageSelector'],
        options: DAMAGE_INFO_OPTIONS
    },
    {
        title: 'Grapa',
        plugin: ['Grapa'],
        options: {}
    },
    {
        title: 'InsuranceSelection',
        plugin: ['FormBuilder'],
        options: INSURANCE_INFO_OPTIONS
    }
];
