import styled from 'styled-components/macro';

import { OrientationIcon } from '@wedat/ui-kit/components/Icons';

interface LayoutProps {
    isLandscape: boolean;
}

export const LayoutStyled = styled.div<LayoutProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    width: 100%;

    overflow: auto;

    ${({ isLandscape }) => isLandscape && 'flex-direction: row;'}
`;

export const Orientation = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.white};

    text-align: center;

    z-index: 100;
`;

export const Title = styled.h3`
    margin: 0;
    padding: 0;
    max-width: 600px;
    font-size: 1.5em;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

interface IconProps {
    portrait?: boolean;
}

export const OrientationIconStyled = styled(OrientationIcon)<IconProps>`
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.blue['400']};

    ${({ portrait }) => portrait && 'transform: rotate(90deg);'}
`;
