import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';
import { convertValueToType } from './utils';
import { Label, Input, RadioIcon, Content } from './styles';
import { RadioValue } from './types';

export interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
    value?: RadioValue;
    onChange?: (value: RadioValue) => void;

    /* Value of radio is always converted to string, but sometimes you need to convert it back to appropriate type.
     * If `valueType` is provided then value is converted to this type before change.
     *
     * `from-xml` is special type that allows value to be parsed as if it were parsed via XML-parser:
     * string-numbers are converted to numbers, string-booleans are converted to booleans.
     * It's useful when value comes from XML-response and it's type is unpredictable.
     */
    valueType?: 'number' | 'boolean' | 'from-xml';
}

export const Radio: React.FC<RadioProps> = ({ children, onChange, value, valueType, ...rest }) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
        onChange?.(convertValueToType(e.target.value, valueType));
    };

    return (
        <Label disabled={rest.disabled} checked={rest.checked}>
            <Input {...rest} value={String(value)} type="radio" onChange={handleChange} />
            <RadioIcon />
            <Content>{children}</Content>
        </Label>
    );
};

export * from './types';
