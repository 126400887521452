import { merge, createEvent, createStore } from 'effector';
import { datAuthTokenName, DAT_REFRESH_TOKEN_NAME } from '@dat/core/constants';

const setToken = createEvent<DAT2.Token>();
const setRefreshToken = createEvent<DAT2.Token>();

const token = createStore<DAT2.Token>('').on(setToken, (_, token) => {
    localStorage.setItem(datAuthTokenName, token);
    return token;
});
const refreshToken = createStore<DAT2.Token>('').on(setRefreshToken, (_, token) => {
    localStorage.setItem(DAT_REFRESH_TOKEN_NAME, token);

    return token;
});

const tokenExpired = createEvent();
const logoutManually = createEvent(); // when user presses "Logout" button

const loggedOut = merge([tokenExpired, logoutManually]);

const isAuthorized = token.map(Boolean);

export const sharedLoginEvents = {
    loggedOut,
    tokenExpired,
    logoutManually,
    setToken,
    setRefreshToken
};

export const sharedLoginStores = {
    token,
    isAuthorized,
    refreshToken
};
