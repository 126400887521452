import React, { useCallback } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Text } from '@wedat/ui-kit/components/Text';
import { PlusCircleIcon } from '@wedat/ui-kit/components/Icons';
import { Button } from '@wedat/ui-kit/components/Button';
import { printReportsEvents, printReportsStore } from '../../../stores/printReports';
import { modalsEvents } from '../../../stores/modals';
import { Footer } from '../../Main/styles';
import { Container, ReportItem, RemoveReportItemWrapper, Content } from './styles';

const { addItemToReports, removeItemFromReports, resetReports } = printReportsEvents;
const { toggleIsAttachments } = modalsEvents;

interface Props {
    isTablet: boolean;
}

export const Printout: React.FC<Props> = ({ isTablet }) => {
    const { t } = useTranslation();

    const printReports = useStore(printReportsStore.printReports);
    const selectedReports = useStore(printReportsStore.selectedReports);

    const addItem = (data: DAT2.Internal.PrintReport) => () => addItemToReports(data);

    const removeItem = (data: DAT2.Internal.PrintReport) => () => removeItemFromReports(data);

    const isExist = useCallback(
        (item: DAT2.Internal.PrintReport) => !!selectedReports.find(i => i.id === item.id),
        [selectedReports]
    );

    return (
        <Container>
            <Content>
                {printReports?.map(report => {
                    const selected = isExist(report);

                    return (
                        <ReportItem
                            onClick={selected ? removeItem(report) : addItem(report)}
                            selected={selected}
                            key={report.id}
                        >
                            <Text font="footnote">{report.reportName}</Text>

                            {selected && (
                                <RemoveReportItemWrapper>
                                    <PlusCircleIcon />
                                </RemoveReportItemWrapper>
                            )}
                        </ReportItem>
                    );
                })}
            </Content>

            {isTablet && (
                <Footer>
                    <Button typeStyle={{ type: 'transparent' }} onClick={() => resetReports()}>
                        {t('inputs.cancel')}
                    </Button>
                    <Button onClick={toggleIsAttachments}>{t('inputs.add')}</Button>
                </Footer>
            )}
        </Container>
    );
};
