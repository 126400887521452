import styled, { css } from 'styled-components';

interface Toggle {
    disabled?: boolean;
    checked?: boolean;
}

export const ToggleContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const HiddenToggle = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const StyledToggle = styled.div<Toggle>`
    position: relative;
    width: 56px;
    height: 28px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray_10};
    border-radius: 123px;
    transition: background-color 0.15s ease, border-color 0.15s ease;

    ${HiddenToggle} + &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        content: '';
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.gray_40};
        transition: transform 0.15s ease;
        transform: translate(4px, -50%);
    }

    ${HiddenToggle}:checked + & {
        background-color: ${({ theme }) => theme.colors.blueDark};
    }
    ${HiddenToggle}:checked + &::before {
        background-color: ${({ theme }) => theme.colors.primary};
        transform: translate(32px, -50%);
    }

    ${HiddenToggle}:disabled + & {
        cursor: default;
        opacity: 0.5;
    }
`;

interface TextProps {
    checked?: boolean;
}

export const Text = styled.span<TextProps>`
    margin-left: 10px;
    line-height: 18px;
    transition: color 0.15s ease;

    ${({ checked, theme }) =>
        checked &&
        css`
            color: ${theme.colors.primary};
        `};
`;
