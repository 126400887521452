import { CZLacquerParameters } from '../../types/factors';

export const CZ_LACQUER_INITIAL_PARAMETERS: CZLacquerParameters = {
    isCalculationWithoutConstant: false,
    wage: '',
    type: '',
    isMultiColoured: false,
    colourCount: '',
    isCertifiedMaterials: false,
    isAntiScratchingCoating: false,
    isDiscountCombined: false,
    isDiscountSparePart: false
};
