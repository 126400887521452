import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { sharedTemplateEvents } from '@dat/shared-models/template';
import { sample } from 'effector';
import { pluginEffects } from '../plugin';
import { modalsEvents } from '../ui/modals';

import { orderEvents } from './index';

const { createOrderWithType, openModalOrCreateOrderWithType } = orderEvents;

sample({
    clock: createOrderWithType,
    fn: ({ templateId, layoutType }) => {
        sharedTemplateEvents.setTemplate(templateId);

        if (layoutType === 'AI') {
            modalsEvents.toggleIsAiClaimModalOpen();
        } else {
            pluginEffects.callOnCreateContractClickFx();
        }
    }
});

sample({
    clock: openModalOrCreateOrderWithType,
    source: sharedConfigurationStores.claimBuilders,
    fn: claimBuilders => {
        if (claimBuilders.length === 1) {
            const [{ templateId, layoutType, name }] = claimBuilders;
            createOrderWithType({
                name,
                templateId,
                layoutType: layoutType || 'standard'
            });
        } else {
            modalsEvents.toggleIsNewOrderModalOpen();
        }
    }
});
