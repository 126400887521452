import { domain } from '../plugin';
import { effectorLogger } from '@dat/core/utils';

import { sendPlateReader } from '@dat/api2/services/plate-recognizer';
import { PlateReaderResult } from '../../types/ocr';
import { formatPlateReaderResult } from '../../utils/formatPlateReaderResult';

const sendPlateReaderFx = domain.createEffect(
    async (params: PR.Request.SendPlateReader): Promise<PlateReaderResult> => {
        const response = await sendPlateReader(params);
        return formatPlateReaderResult(response);
    }
);

const plateReaderResult = domain
    .createStore<PlateReaderResult['OCR']>({})
    .on(sendPlateReaderFx.doneData, (_, newState) => newState.OCR);

//
/*** Export ***/
//
export const vehicleOCRStores = {
    plateReaderResult
};

export const vehicleOCREffects = {
    sendPlateReaderFx
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(vehicleOCREffects);
}
