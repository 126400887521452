import { createGlobalStyle, css } from 'styled-components';

import '../../core/styles/fonts.css';

window.addEventListener('resize', () => {
    const datVH = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--dat-vh', `${datVH}px`);
});
window.dispatchEvent(new Event('resize'));

export const GlobalStyle = createGlobalStyle`
    :root {
        --dat-vh: 1vh; // updated dynamically

        --purple: #8760d4;
        --purple-hover: #6f42c1;
        --gray: #868e96;
        --secondary: #adb5bd;
        --success: #02B875;
        --danger: #d9534f;
        --light: #f8f9fa;
        --dark: #343a40;
        --breakpoint-sm: 540px;
    }

    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Noto Sans TC', sans-serif;
        color: var(--dark);
        background:#f8f8f8;
    }

    button, input, select {
        font-family: 'Noto Sans TC', sans-serif;
        outline: none;
        border: none;
        background: none;

        &::placeholder {
            color: var(--gray);
        }
        &:-ms-input-placeholder {
            color: var(--gray);
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Noto Sans TC', sans-serif;
    }
`;

export const GlobalStyleNew = createGlobalStyle`
    ${({
        theme: {
            colors: { gray_40, gray_300 }
        }
    }) => css`
        * {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
        }

        body {
            max-width: 100vw;
            font-family: 'Noto Sans TC', sans-serif;
            color: ${gray_40};
            font-size: 18px;
            font-weight: 500;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Noto Sans TC', sans-serif;
            font-weight: 600;
            color: ${gray_300};
        }
    `}

`;
