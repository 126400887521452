import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

interface Props {
    isIOS: boolean;
}

export const FastTrackStyled = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: block;

    ${media.tablet`
      padding-bottom: 56px;

      ${({ isIOS }) =>
          isIOS &&
          css`
              padding-bottom: 76px;
          `}
    `}
`;
