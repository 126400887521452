import { sample } from 'effector';

import { contractStores } from '../index';
import { sharedConfigurationStores } from '../../configuration';
import { sharedPartnersStores } from './stores';
import { extractAllContractPartners } from './utils/extractAllContractPartners';

const { customerPartners, contractPartners } = sharedPartnersStores;
const { contract } = contractStores;
const { customerSettings } = sharedConfigurationStores;

sample({
    source: { currentCustomerRole: customerSettings.role, contract, customerPartners },
    fn: ({ currentCustomerRole, ...rest }) => {
        const allContractPartners = extractAllContractPartners(rest);
        const contractPartnersWithoutCurrentCustomer = allContractPartners.filter(
            ({ role }) => role !== currentCustomerRole
        );

        return contractPartnersWithoutCurrentCustomer.length
            ? contractPartnersWithoutCurrentCustomer
            : contractPartners.defaultState;
    },
    target: contractPartners
});
