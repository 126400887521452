import React from 'react';
import { useFormikContext } from 'formik';

import { CheckboxField, CheckboxFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationCheckboxField: React.FC<CheckboxFieldProps> = props => {
    const { submitForm } = useFormikContext();

    return <CheckboxField {...props} changeCallback={submitForm} />;
};
