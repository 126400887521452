import { AllVehiclesImages } from '../types';

type Result = AllVehiclesImages[number];

export const spreadAllImagesToAspects = (images: DAT2.VehicleImage[]): Result => {
    const result: Result = { ALL: images };

    images.forEach(image => {
        result[image.aspect] = result[image.aspect] || [];
        result[image.aspect]!.push(image);
    });

    return result;
};
