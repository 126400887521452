import React, { FC } from 'react';
import { useStore } from 'effector-react';

import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { LabourRatesPage } from './pages/LabourRatesPage';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
    closeLabourRatesModal?: () => void;
}

//TODO: remove stars from translations
const App: FC<Props> = ({ options, closeLabourRatesModal }) => {
    const isLoading = useStore(commonStores.isLoading);

    return (
        <PluginProvider name="labour-rates" options={options} onInit={pluginEvents.initPlugin} isLoading={isLoading}>
            <LabourRatesPage closeLabourRatesModal={closeLabourRatesModal} />
        </PluginProvider>
    );
};

export default App;
