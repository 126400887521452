import { createEvent, createStore } from 'effector';
import { DataCondition } from '../types/dataScheme';

type ConditionRules = Record<string, Array<DataCondition>>;

/** condition rules **/

const addConditionRules = createEvent<{ conditions: DataCondition; formName: string }>();
const addNewFormConditionRules = createEvent<ConditionRules>();
const conditionRulesStore = createStore<ConditionRules | Record<string, any>>({})
    .on(addNewFormConditionRules, (state, formName) => ({
        ...state,
        [String(formName)]: {}
    }))
    .on(addConditionRules, (state, { conditions, formName }) => {
        Object.keys(state).forEach((key: string) => {
            if (key === formName) {
                state[key] = conditions;
            }
        });
        return { ...state };
    });

/** options **/

const addOptions = createEvent<Record<any, any> | null>();
const optionsStore = createStore<Record<any, any> | null>(null).on(addOptions, (_state, options) => options);

export { conditionRulesStore, addNewFormConditionRules, addConditionRules, optionsStore, addOptions };
