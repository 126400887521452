import { createEffect, createEvent, createStore, restore } from 'effector';
import i18next from 'i18next';

import { I18nNamespace } from '@dat/core/types/i18n';
import { PayloadForAddTranslationsToI18Next } from './types';
import { INITIAL_LOCALE } from './constants';
import { initReactI18next } from 'react-i18next';

export const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
    lng: INITIAL_LOCALE,
    fallbackLng: INITIAL_LOCALE,
    react: { useSuspense: false }
});

const getTranslationsFx = createEffect<void, BFF.Translations.Response.GetTranslations>();

const triggerRerender = () => {
    // trigger re-render for i18next to show new translations
    // without setTimeout i18next doesn't re-render components for some reason
    setTimeout(() => {
        i18n.emit('languageChanged');
    }, 0);
};

const addTranslationsToI18NextFx = createEffect(
    ({
        namespace,
        translations,
        isTriggerRerender = true
    }: PayloadForAddTranslationsToI18Next & { isTriggerRerender?: boolean }) => {
        Object.entries(translations).forEach(([lng, lngTranslations]) => {
            i18n.addResourceBundle(lng, namespace, lngTranslations, true, true);
        });

        if (isTriggerRerender) {
            triggerRerender();
        }
    }
);

const addBackendTranslationsToI18NextFx = createEffect(
    (backendTranslations: BFF.Translations.Response.GetTranslations) => {
        Object.entries(backendTranslations).map(([namespace, translations]) =>
            addTranslationsToI18NextFx({
                namespace: namespace as I18nNamespace,
                translations,
                isTriggerRerender: false
            })
        );

        triggerRerender();
    }
);

const localeChangedByUser = createEvent<DAT2.Locale>();
const setLocale = createEvent<DAT2.Locale>();
const locale = restore(setLocale, INITIAL_LOCALE).on(localeChangedByUser, (_, newLocale) => newLocale);

// пока что добавил все плагины, потом буду подвязывать к PluginProvider
const namespacesToTranslate = createStore<I18nNamespace[]>([
    'address-book',
    'ai-claim',
    'ai-gallery',
    'all-components',
    'assign-partner',
    'auth',
    'calculation',
    'claim-management',
    'chat',
    'equipment',
    'fast-track',
    'form-builder',
    'gallery',
    'grapa',
    'inbox',
    'labour-rates',
    'labour-rates-generic',
    'mail',
    'part-selection',
    'claim-stepper',
    'plugin-cars',
    'printout',
    'qr-code-plugin',
    'residual-prediction',
    'valuate-finance',
    'vehicle-selection',
    'scheduler',
    'damage-selector',
    'italian-calculation',
    'help-file'
]);

export const sharedI18NEffects = {
    getTranslationsFx,
    addTranslationsToI18NextFx,
    addBackendTranslationsToI18NextFx
};

export const i18nStores = {
    locale,
    namespacesToTranslate
};

export const i18nEvents = {
    setLocale,
    localeChangedByUser
};
