import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';

export const ButtonStyled = styled(Button)`
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
`;

export const Wrapper = styled.div`
    padding-bottom: 40px;
`;
