import React from 'react';
import { MainFiltersContainer } from './styles';
import { SearchInput } from '../SearchInput';
import { StatusFilter } from '../StatusFilter';

export const MainFilters: React.FC = () => (
    <MainFiltersContainer>
        <SearchInput />
        <StatusFilter />
    </MainFiltersContainer>
);
