import styled, { css } from 'styled-components';
import { Input } from '../Input';
import { media } from '../../mediaQueries';
import { getInputSize } from '../../utils/getInputSize';

interface InputStyledProps {
    paddingLeft?: number;
}

interface AbsolutePrefixProps {
    disabled?: boolean;
}

interface ContainerProps {
    inputSize?: 'small' | 'medium' | 'default';
    withPrefix?: boolean;
}

export const AbsolutePrefix = styled.div<AbsolutePrefixProps>`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 2px 8px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    z-index: 10;
    border-radius: 4px 0 0 4px;

    ${media.phoneBig`
        justify-content: center;
        width: 26px;
        height: calc(100% - 2px);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    `}

    ${({ disabled, theme }) => disabled && `color: ${theme.colors.gray_60}`}
`;

export const InputStyled = styled(Input)<InputStyledProps>`
    padding-left: ${({ paddingLeft }) => paddingLeft || '15px'};

    ${media.phoneBig`
        padding-left: 12px;
    `}

    &:disabled {
        color: ${({ theme }) => theme.colors.gray_60};
    }
`;

export const Container = styled.div<ContainerProps>`
    position: relative;
    height: ${({ inputSize }) => getInputSize(inputSize)};
    width: 100%;

    ${({ withPrefix }) =>
        withPrefix &&
        css`
            ${media.phoneBig`
                ${InputStyled} {
                    padding-left: 30px;
                }
            `}
        `}
`;
