export const PAGE_LIMIT = 10;
export const DEFAULT_COLUMNS = [
    'eCode',
    'currency',
    'registrationNumber',
    'vehicle',
    'lossNumber',
    'referenceNumber',
    'carOwner',
    'opened',
    'lastEntry',
    'totalPrice',
    'Date_expiryDate',
    'Integer_expiryStatus',
    'String_insurance_name'
];
export const CLAIMS_LISTER_NAME = 'claims';
