/*
 * All connections between `shared-models` and API are done here
 * to avoid dependency of API-module on `shared-models`
 */
import { forward } from 'effector';

import { apiStores } from '@dat/api2/stores';
import { i18nStores } from '../i18n';
import { sharedConfigurationStores } from '../configuration';
import { sharedTemplateStores } from '../template';

forward({ from: sharedConfigurationStores.customerSettings.apiUrl, to: apiStores.apiUrl });
forward({ from: sharedConfigurationStores.customerSettings.bffUrl, to: apiStores.bffUrl });
forward({ from: sharedTemplateStores.templateSettings.country, to: apiStores.country });
forward({ from: i18nStores.locale, to: apiStores.locale });
