import { ParsedVXSVehicleObject } from '@dat/core/types';

export const fullDatECodeLength = 15;
export const disableBodyScrollOptions = {
    reserveScrollBarGap: true,
    allowTouchMove: () => true
};
export const AVAILABLE_COUNTRIES = ['IT', 'DE', 'NL', 'FR', 'CH', 'ES'];
export const DEFAULT_VEHICLE_DATA: ParsedVXSVehicleObject = {
    datECodeEquipment: [],
    equipment: [],
    paintType: undefined,
    vehicleInfo: {
        baseModel: '',
        baseModelName: '',
        constructionTime: '',
        container: '',
        datECode: '',
        initialRegistration: '',
        kba: '',
        kilometers: '',
        manufacturer: '',
        manufacturerName: '',
        nationalCode: '',
        numberPlate: '',
        subModel: '',
        subModelName: '',
        typeNoteNumber: '',
        vehicleCountry: 'IT',
        vehicleType: '',
        vehicleTypeName: '',
        vin: '',
        tiresPercent: ''
    },
    vinColors: [],
    vinResultEquipment: []
};
