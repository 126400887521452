import { CSSProperties, FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { Container } from './styles';

export interface TextProps
    extends Pick<
        CSSProperties,
        'fontSize' | 'fontWeight' | 'lineHeight' | 'textOverflow' | 'textTransform' | 'userSelect' | 'color'
    > {
    asTag?: 'span' | 'div' | 'p' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    font?: keyof DefaultTheme['typography'];
}

export const _Text: FC<TextProps> = ({ children, asTag = 'span', ...rest }) => (
    <Container as={asTag} {...rest}>
        {children}
    </Container>
);

export const Text = styled(_Text)``;
