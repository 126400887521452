import React from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { Text } from '@wedat/ui-kit/components/Text';
import { DownloadIcon, EyeIcon } from '@wedat/ui-kit/components/Icons';
import { Container, TextWrapper, IconWrapper, IconContainer } from './styles';
import { useToggle } from '@dat/core/hooks/useToggle';

interface Props {
    description?: string;
    getDocument?: () => void;
    openDocument?: () => void;
}

export const ListItem: React.FC<Props> = ({ description, getDocument, openDocument }) => {
    const [mouseDown, toggleMoseDown] = useToggle(false);

    return (
        <Container disableHover={mouseDown}>
            <TextWrapper onClick={getDocument}>
                <Text font="note">{description}</Text>
            </TextWrapper>

            {!isIOS && !isAndroid && (
                <IconContainer onMouseDown={toggleMoseDown} onMouseUp={toggleMoseDown}>
                    <IconWrapper onClick={openDocument}>
                        <EyeIcon />
                    </IconWrapper>
                    <IconWrapper onClick={getDocument}>
                        <DownloadIcon />
                    </IconWrapper>
                </IconContainer>
            )}
        </Container>
    );
};
