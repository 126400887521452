import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { useHistory } from 'react-router';
import { contractStores, contractEffects } from '@dat/shared-models/contract';
import { Preloader } from '@wedat/ui-kit/components/Preloader';
import { Container, Span } from './styles';
import { useTranslation } from 'react-i18next';
interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProcessedModal: FC<Props> = ({ isOpen, setIsOpen }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const contractId = useStore(contractStores.contractId);
    const contract = useStore(contractStores.contract);
    const statusName = String(contract?.statusName);

    useEffect(() => {
        if (isOpen) {
            if (String(contract?.statusType) !== 'uploaded') {
                const interval = setInterval(() => {
                    !!contractId && contractEffects.getContractFx(contractId);
                }, 4000);
                return () => clearInterval(interval);
            } else {
                setIsOpen(false);
                contractEffects.getContractAfterCalculateFx(contractId);
                history.push(`/claims/${contractId}/opening`);
            }
        }
    }, [contract, contractId, history, isOpen, setIsOpen, statusName]);

    return (
        <ModalPrimary zIndex={10002} onDismiss={() => setIsOpen(false)} isOpen={isOpen} hideHeader fullWidth>
            <Container>
                <Span>{statusName !== 'undefined' ? t(`status.${statusName}`) : ''}</Span>
            </Container>
            <Preloader isLoading />
        </ModalPrimary>
    );
};
