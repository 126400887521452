import styled from 'styled-components/macro';

export const ManageUsersContainer = styled.fieldset`
    min-height: 0;
    display: flex;
    flex-direction: column;

    legend {
        display: flex;
    }
`;
