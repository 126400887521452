import { TFunction } from 'react-i18next';
import { MaterialMode, PercentOrAbsoluteMode, PerTimeOrAbsoluteMode } from '../types/factors';
import { RadioGroupItem } from '@wedat/ui-kit/components/RadioGroup';

const percentOrAbsolute = (t: TFunction): RadioGroupItem<PercentOrAbsoluteMode>[] => [
    { value: 'PERCENT', label: t('common.percentRate') },
    { value: 'ABSOLUTE', label: t('common.absolute') }
];
const perTimeOrAbsolute = (t: TFunction): RadioGroupItem<PerTimeOrAbsoluteMode>[] => [
    { value: 'PER_TIME', label: t('paint.wage') },
    { value: 'ABSOLUTE', label: t('paint.wageFlatRate') }
];
const materialMode = (t: TFunction): RadioGroupItem<MaterialMode>[] => [
    {
        value: 'LACQUER_METHOD',
        label: t('paint.euroLacquer.repairWageLacquerMaterialPerPoint')
    },
    {
        value: 'PERCENT',
        label: t('paint.euroLacquer.materialFlatRatePercent')
    },
    {
        value: 'ABSOLUTE',
        label: t('paint.euroLacquer.materialFlatRateAbsolute')
    }
];
const selectedLacquerMethod = (t: TFunction): RadioGroupItem<DAT2.LacquerMethod>[] => [
    { value: 'EURO_LACQUER', label: t('paint.euroLacquer.title') },
    { value: 'MANUFACTURER_SPECIFIC', label: t('paint.manufacturerLacquer.title') },
    { value: 'AZT', label: t('paint.AZTLacquer.title') },
    { value: 'CZ', label: t('paint.CZLacquer.title') }
];

const selectedPaintWork = (t: TFunction): RadioGroupItem<DAT2.PaintWorkType>[] => [
    { value: 'WAGE_MATERIAL_SEPARATELY', label: t('paint.materialSeparately.title') },
    { value: 'WAGE_INCLUSIVE_MATERIAL', label: t('paint.materialInclusive.title') }
];

export const radioItems = {
    percentOrAbsolute,
    perTimeOrAbsolute,
    materialMode,
    selectedLacquerMethod,
    selectedPaintWork
};
