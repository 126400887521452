import { getTokenWithCache } from '@dat/api2dat/services/tokenService';
import axios, { AxiosResponse } from 'axios';
import { pluginOptionsModel } from '../stores/pluginOptionsModel';

import {
    DeletePartType,
    EditPartType,
    GetPartsType,
    GetPartType,
    ProxyRequest,
    ManualPosition
} from '../types/ManualPositionsServiceTypes';

const bffUrl = 'https://wedat-api-dev.wedat.eu/';

export const bffAxiosInstance = axios.create({
    baseURL: bffUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const myClaimProxy = async <P, D, R>(name: string, req: ProxyRequest<P, D>): Promise<R> => {
    const pluginOptions = pluginOptionsModel.stores.pluginOptions.getState();
    const token =
        pluginOptions?.token ||
        (pluginOptions?.credentials && (await getTokenWithCache(pluginOptions?.credentials)))?.token;

    return (
        await bffAxiosInstance.request<R, AxiosResponse<R>>({
            method: 'POST',
            url: `myClaim/proxy/${name}`,
            headers: {
                'DAT-AuthorizationToken': token
            },
            data: req
        })
    ).data;
};

//=====================================  manualPositionsService  =====================================

export const getPart: GetPartType = (id: number) =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/GetManualPosition?manualPosition=${id}`,
        method: 'GET'
    });

export const getParts: GetPartsType = () =>
    myClaimProxy('json', {
        api: 'json',
        bodyType: 'json',
        url: '/partselectionsettings/ListManualPositions?count=50&offset=0&sort-repairCode',
        method: 'GET'
    });

export const editPart: EditPartType = ({ payload, id }: { id?: number; payload: ManualPosition }) =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/SaveManualPosition`,
        method: 'POST',
        data: { ...payload, id }
    });

export const deletePart: DeletePartType = (id: number) =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/RemoveManualPosition?manualPosition=${id}`,
        method: 'POST'
    });
