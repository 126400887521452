import { useEffect, useState, useCallback, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { pointsAsideEvents } from '../../../stores/pointAside';
import { contractsListEffects, contractsListStores } from '../../../stores/contractsList';
import { schedulerStores } from '@dat/shared-models/scheduler';

import { Text } from '@wedat/ui-kit/components/Text';
import { ArrowLong } from '@wedat/ui-kit/components/Icons';

import { AppointmentItem } from '../../AppointmentItem';
import { ClaimItem } from '../../ClaimItem';

import { Container } from '../Preview/styles';
import {
    AppointmentsContent,
    ArrowWrapper,
    ButtonStyled,
    Claims,
    ClaimsContent,
    Content,
    StyledPreloaderCircle,
    TextWrapper,
    Title,
    TopContainer
} from './style';

export const PointsAside: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const todayListOfClaims = useStore(contractsListStores.todayListOfClaims);
    const isLoadingContractRequest = useStore(contractsListEffects.loadMoreContractsFx.pending);
    const todayAppointments = useStore(schedulerStores.todayAppointments);
    const [height, setHeight] = useState<number>();

    const [isLoading, setIsLoading] = useState(true);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        contractsListEffects.loadMoreContractsFx();
    }, []);

    const handleLoadMore = useCallback(() => {
        setIsLoading(false);
        contractsListEffects.loadMoreContractsFx();
    }, []);

    useEffect(() => {
        if (ref?.current !== null) {
            setHeight(ref.current.offsetHeight);
        }
    }, [ref]);

    return (
        <Container>
            <TopContainer>
                <ArrowWrapper onClick={() => pointsAsideEvents.closePointAside()}>
                    <ArrowLong />
                </ArrowWrapper>
                <Title>{t('pointsForToday')}</Title>
            </TopContainer>
            <Content>
                <AppointmentsContent ref={ref}>
                    {todayAppointments.length > 0 ? (
                        todayAppointments.map(point => (
                            <AppointmentItem
                                registrationNumber={point.registrationNumber}
                                id={point.id}
                                address={point.address}
                                userInfo={point.customerInfo}
                                contractId={point.contractId}
                                isInAside={true}
                                key={point.id}
                            />
                        ))
                    ) : (
                        <AppointmentItem isInAside />
                    )}
                </AppointmentsContent>
                <Claims height={height}>
                    <Text fontSize="20px" lineHeight="28px" fontWeight={700} color={theme.colors.dustBlue[900]}>
                        {t('yourExpiryClaimsToday')}
                    </Text>
                    <ClaimsContent>
                        {isLoadingContractRequest && isLoading ? (
                            <StyledPreloaderCircle isLoading={isLoadingContractRequest} width={40} />
                        ) : todayListOfClaims?.length === 0 ? (
                            <TextWrapper>
                                <Text>{t('noExpiryClaimForToday')}</Text>
                            </TextWrapper>
                        ) : (
                            <>
                                {todayListOfClaims?.map(claim => (
                                    <ClaimItem
                                        key={claim?.id}
                                        eCode={claim?.eCode}
                                        id={claim?.id}
                                        refNumber={claim?.referenceNumber}
                                        regNumber={claim?.registrationNumber}
                                        carOwner={claim?.carOwner}
                                    />
                                ))}
                                <StyledPreloaderCircle isLoading={isLoadingContractRequest} width={40} />
                                {todayListOfClaims?.length >= 10 && (
                                    <ButtonStyled onClick={handleLoadMore}>{t('loadMore')}</ButtonStyled>
                                )}
                            </>
                        )}
                    </ClaimsContent>
                </Claims>
            </Content>
        </Container>
    );
};
