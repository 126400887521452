import { guard, sample } from 'effector';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import { factorsEffects, factorsEvents, factorsStores } from './index';
import { initialFactorsParameters } from '../../constants/initialFactorsParameters';
import { invoiceRatesEvents, invoiceRatesStores } from '../selects/invoiceRates';
import { contractEffects, contractEvents } from '@dat/shared-models/contract';
import { extractFactorsParametersFromContract } from '../../utils/extractFactorsParametersFromContract';
import { extractFactorsParametersFromContractMemoField } from '../../utils/extractFactorsParametersFromContractMemoField';
import { extractFactorsParametersFromInvoiceRate } from '../../utils/extractFactorsParametersFromInvoiceRate';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';

import { FactorsParametersObject } from '../../types/factors';

const { predefinedFactorsParametersUpdated } = factorsEvents;
const { updateContractFactorsFx } = factorsEffects;
const { contractFactorsParameters } = factorsStores;
const { repairPositions } = sharedRepairCalculationStores;

contractFactorsParameters.on(contractEvents.contractReceived, (_state, contract) =>
    deepmerge.all<FactorsParametersObject>([
        initialFactorsParameters,
        extractFactorsParametersFromContractMemoField(contract),
        extractFactorsParametersFromContract(contract)
    ])
);

sample({
    source: invoiceRatesEvents.invoiceRateSelected,
    fn: extractFactorsParametersFromInvoiceRate,
    target: predefinedFactorsParametersUpdated
});

const predefinedFactorsParametersAreNotSetInContract = invoiceRatesStores.isInvoiceRateNameNotDefinedInContract;

guard({
    source: predefinedFactorsParametersUpdated,
    filter: predefinedFactorsParametersAreNotSetInContract,
    target: updateContractFactorsFx
});

sample({
    clock: updateContractFactorsFx.doneData,
    source: repairPositions,
    filter: repairPositions => !!repairPositions.length,
    fn: _.noop,
    target: contractEffects.calculateCurrentContractFx
});
