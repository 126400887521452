import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';

import { useVehicleImage } from '../List/ListItem/common/Image/hooks/useVehicleImage';

import { ArrowThin } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';

import { AppointmentId, FlexibleContent, RegistrationNumber } from '../AppointmentItem/style';
import { ClaimImg, ClaimInfo, ClaimItemWrapper, Image } from './style';
import vehiclePlaceholderImage from '../../assets/images/vehicle-placeholder.png';

type Props = {
    eCode?: string | undefined;
    id?: number;
    refNumber?: string;
    regNumber?: string;
    carOwner?: string;
};

export const ClaimItem: React.FC<Props> = ({ eCode, id, refNumber, regNumber, carOwner }) => {
    const vehicleImage = useVehicleImage(eCode);
    const history = useHistory();
    const theme = useTheme();

    const handleOpenClaim = useCallback(() => {
        history.push(`/claims/${id}/opening`);
    }, [id, history]);

    return (
        <ClaimItemWrapper>
            <FlexibleContent>
                <ClaimImg>
                    <Image src={vehicleImage || vehiclePlaceholderImage} alt="vehicle photo" />
                </ClaimImg>

                <ClaimInfo>
                    <Text fontSize="14px" fontWeight={400} lineHeight="20px" color={theme.colors.dustBlue[900]}>
                        {carOwner}
                    </Text>
                    <FlexibleContent>
                        {regNumber && <RegistrationNumber>{regNumber}</RegistrationNumber>}
                        <AppointmentId>{refNumber}</AppointmentId>
                    </FlexibleContent>
                </ClaimInfo>
            </FlexibleContent>
            <ArrowThin onClick={handleOpenClaim} />
        </ClaimItemWrapper>
    );
};
