import styled from 'styled-components';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';
import { SuccessIcon } from '@wedat/ui-kit/assets/Icon';

export const Root = styled.div`
    padding-bottom: 20px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    position: absolute;
    top: 5px;
    left: 20px;
    width: auto;
    padding-right: 10px;
    padding-left: 10px;

    ${media.phone`
        position: static;
        top: auto;
        left: auto;
        width: 100%;
    `}
`;

export const ButtonSubmit = styled(Button)`
    padding-left: 36px;
    padding-right: 36px;
    align-self: flex-end;
    width: auto;

    ${media.phone`
        width: 100%;
    `}
`;

export const Wrapper = styled.div`
    margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const SuccessModalContent = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
    margin-bottom: 24px;
    width: 64px;
    height: 64px;
    color: #27d863;
`;

export const SucceccModalTitle = styled.h2`
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray_300};
`;

export const SucceccModalDescription = styled.p`
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray_40};
`;

export const SuccessButton = styled(Button)`
    width: auto;
    padding-right: 10px;
    padding-left: 10px;

    ${media.phone`
        width: 100%;
    `}
`;
