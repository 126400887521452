import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxContainer } from '@wedat/ui-kit/components/Checkbox/styles';
import {
    SelectField,
    RadioGroupField,
    CheckboxField,
    TabsField,
    NumberInputField,
    InputField
} from '@wedat/ui-kit/Formik';

// components
import { FieldContainer, TabsWrapper } from './style';
import { InputWithSelect } from '../InputWithSelect';
import { GenericField, LabourRateField } from '@dat/shared-models/labour-rates-generic/types';
import { percentOrAbsolute } from '@dat/shared-models/labour-rates-generic/constants/radioItems';

type FieldProps = {
    field: LabourRateField;
    key: string;
    initialValue: GenericField;
    onChange?: (value: any) => void;
};

export const LabourField: FC<FieldProps> = ({ field }) => {
    const { t } = useTranslation();
    const { name, placeholder = '', float, value, type, options, hidden, related = '', required } = field;

    if (hidden) {
        return null;
    }

    switch (type) {
        case 'text':
        case 'number':
            return (
                <FieldContainer isRequired={required}>
                    <InputField
                        label={t(placeholder) || t(name)}
                        name={name}
                        type={type}
                        step={float ? '0.01' : 'any'}
                        required={required}
                    />
                </FieldContainer>
            );
        case 'numberFloat':
            return (
                <FieldContainer isRequired={required}>
                    <NumberInputField
                        hideZeroValue={name.includes('DomusCalculationFactor')}
                        label={t(placeholder) || t(name)}
                        name={name}
                        step={float ? '0.01' : 'any'}
                        required={required}
                    />
                </FieldContainer>
            );

        case 'select':
            return (
                <FieldContainer isRequired={required}>
                    <SelectField name={name} label={t(placeholder) || t(name)} options={options || []} />
                </FieldContainer>
            );

        case 'checkbox':
            return (
                <CheckboxContainer>
                    <CheckboxField
                        required={required}
                        name={name}
                        label={t(placeholder) || t(name)}
                        checked={value as boolean}
                    />
                </CheckboxContainer>
            );

        case 'radioButtonGroup':
            return (
                <FieldContainer>
                    <RadioGroupField items={options || []} name={name} />
                </FieldContainer>
            );

        case 'tabs':
            return (
                (options && (
                    <TabsWrapper>
                        <TabsField name={name} values={options} />
                    </TabsWrapper>
                )) ||
                null
            );

        case 'mode':
            return (
                <FieldContainer isRequired={required}>
                    <InputWithSelect
                        inputProps={{
                            name: related,
                            type: 'number',
                            label: t(name),
                            required: required
                        }}
                        selectProps={{
                            name,
                            options: percentOrAbsolute(t)
                        }}
                    />
                </FieldContainer>
            );

        default:
            return null;
    }
};
