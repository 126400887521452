import { FC, SyntheticEvent } from 'react';
import { Partner, PartnerName, PartnerRole } from './styles';
import { partnersStores } from '../../stores/partners';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { sharedPartnersStores } from '@dat/shared-models/contract/Partners';
import { Dropdown } from '@wedat/ui-kit/components/Dropdown';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
interface Props {
    handleToggleModal: (e: SyntheticEvent, role: DAT2.CustomerRole, id?: number) => void;
    isOpen?: boolean;
    closeSlideUpMenu: () => void;
}

export const RolesPopup: FC<Props> = ({ handleToggleModal, isOpen, closeSlideUpMenu }) => {
    const { t } = useTranslation();
    const contractPartners = useStore(sharedPartnersStores.contractPartners);
    const notAssignedRoles = useStore(partnersStores.unAssignedRoles);
    const isTablet = useMedia(sizes.tablet);

    const getItems = () => (
        <>
            {contractPartners.map(partner => (
                <Partner key={partner.role} onClick={e => handleToggleModal(e, partner.role, partner.partnerId)}>
                    <PartnerName>{partner.name}</PartnerName>
                    <PartnerRole>{t(`roles.${partner.role}`)}</PartnerRole>
                </Partner>
            ))}
            {notAssignedRoles.map(role => (
                <Partner key={role} onClick={e => handleToggleModal(e, role)}>
                    <PartnerName />
                    <PartnerRole>{t(`roles.${role}`)}</PartnerRole>
                </Partner>
            ))}
        </>
    );

    return isTablet ? (
        <SlideUpMenu isOpen={!!isOpen} closeSlideUpMenu={closeSlideUpMenu}>
            {getItems()}
        </SlideUpMenu>
    ) : (
        <Dropdown side="left" isOpen={isOpen}>
            {getItems()}
        </Dropdown>
    );
};
