import { useStore } from 'effector-react';
import { conditionRulesStore } from '../stores/conditions';
import { useCallback } from 'react';

export const useGetConditionRules = () => {
    const conditionRules = useStore(conditionRulesStore);

    return useCallback(
        (formName: string, currentConditionName: string) => {
            let condition = null;
            Object.keys(conditionRules).forEach(key => {
                if (key === formName) {
                    conditionRules[formName].filter((el: any) => {
                        if (el.namedCondition && el.namedCondition === currentConditionName) {
                            condition = el;
                        }
                        return null;
                    });
                }
            });
            return condition;
        },
        [conditionRules]
    );
};
