import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadButtonContainer, UploadIcon, UploadIconContainer, BottomText } from './styles';
import { FileInput } from '../../FileInput';

interface Props {
    groupId: number;
    accept?: string;
    uploadsRemaining: number;
}

export const UploadButton: React.FC<Props> = ({ groupId, uploadsRemaining, accept }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const shouldShowUploadsRemaining = uploadsRemaining && uploadsRemaining !== Infinity;
    const handleUploadClick = () => fileInputRef.current?.click();

    return (
        <UploadButtonContainer>
            <UploadIconContainer onClick={handleUploadClick}>
                <UploadIcon width="28" height="30" />
            </UploadIconContainer>
            <FileInput ref={fileInputRef} accept={accept} name={String(groupId)} multiple />
            {shouldShowUploadsRemaining && (
                <BottomText>
                    {t('max')} {uploadsRemaining}
                </BottomText>
            )}
        </UploadButtonContainer>
    );
};
