import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { isIOS } from 'react-device-detect';

export const Container = styled.div`
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    ${media.tablet`
        padding-bottom: 76px;

        ${
            isIOS &&
            css`
                padding-bottom: 96px;
            `
        }
    `}

    ${media.laptopSmall`
        flex-direction: column;
    `}
`;

export const Header = styled.header`
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
`;

export const FormWrapper = styled.div`
    width: 100%;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;

    ${media.phoneBig`
        margin-top: 10px;
    `}
`;

export const ClaimLeftPart = styled.div`
    width: 100%;
    max-width: 352px;
    flex-shrink: 0;

    &:empty {
        display: none;
    }

    ${media.laptopSmall`
        max-width: 100%;
    `}

    ${media.phoneBig`
        margin-bottom: 10px;
        margin-right: 0;
    `}
`;

export const ClaimRightPart = styled.div`
    width: 100%;
`;
