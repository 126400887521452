import styled, { css } from 'styled-components/macro';
import { Wrapper } from '@wedat/ui-kit/components/Input/styles';
import { media } from '@wedat/ui-kit/mediaQueries';
import {
    InputContainer,
    OCRIconWrapper
} from '@dat/vehicle-selection-modal/src/components/InputFieldWithButtons/styles';

export const Container = styled.div`
    margin-bottom: 20px;
`;

export const InputWrapper = styled.div<{ short?: boolean; isNumberPlate?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${InputContainer} {
        ${media.phoneMedium`
            width: 80%;
        `}
    }

    ${Wrapper} {
        width: 100%;
        max-width: 224px;

        ${media.laptopSmall`
            max-width: unset;
        `}
    }

    ${OCRIconWrapper} {
        right: ${({ isNumberPlate }) => (isNumberPlate ? '60px' : '12px')};

        ${media.laptopSmall`
            right: 12px;
        `}
    }

    ${({ short }) =>
        short &&
        css`
            flex: 1;
            width: 0; /* instead of overflow:hidden, because overflow:hidden hides the label  */

            &:first-of-type {
                margin-right: 12px;
            }
        `}
`;

export const InputFieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export const Divider = styled.div`
    height: 1px;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.colors.dustBlue['100']};
`;
