import axios from '../datAxios';
import { DatProductsPaths, ValuateFinanceServices } from '../constants';

/*
 * Evaluation
 * https://www.datgroup.com/FinanceLine/soap/Evaluation?wsdl
 */
export const getVehicleEvaluation = (data: DAT2.Request.GetVehicleEvaluation) =>
    axios<DAT2.Response.GetVehicleEvaluation>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.Evaluation,
        method: 'getVehicleEvaluation',
        data
    });

export const getVehicleTargetDateEvaluationHistory = (data: DAT2.Request.GetVehicleTargetDateEvaluationHistory) =>
    axios<DAT2.Response.GetVehicleEvaluation>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.Evaluation,
        method: 'getVehicleTargetDateEvaluationHistory',
        data
    });

export const getUsedVehicleForecast = (data: DAT2.Request.GetUsedVehicleForecast) =>
    axios<DAT2.Response.GetUsedVehicleForecast>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.Evaluation,
        method: 'getUsedVehicleForecast',
        data
    });

/*
 *,
 * DossierN
 * https://www.datgroup.com/FinanceLine/soap/DossierN?wsdl
 */
export const createDossierN = (data: DAT2.Request.CreateDossierN) =>
    axios<DAT2.Response.CreateDossierN>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.DossierN,
        method: 'createDossierN',
        data,
        withRequestTag: false
    });

export const changeDossierN = (data: DAT2.Request.ChangeDossierN) =>
    axios<DAT2.Response.ChangeDossierN>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.DossierN,
        method: 'changeDossierN',
        data,
        withRequestTag: false
    });

export const getDossierN = (data: DAT2.Request.GetDossierN) =>
    axios<DAT2.Response.GetDossierN>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.DossierN,
        method: 'getDossierN',
        data,
        withRequestTag: false
    });

export const resetDossier2DefaultN = (data: DAT2.Request.ResetDossier2DefaultN) =>
    axios<DAT2.Response.ResetDossier2DefaultN>({
        product: DatProductsPaths.ValuateFinance,
        service: ValuateFinanceServices.DossierN,
        method: 'resetDossier2DefaultN',
        data,
        withRequestTag: false
    });
