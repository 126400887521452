import React, { FC } from 'react';
import { ContainerStyled, Title } from './styles';

interface Props {
    title?: string;
}

export const Container: FC<Props> = ({ children, title, ...rest }) => (
    <ContainerStyled {...rest}>
        {title && <Title>{title}</Title>}
        {children}
    </ContainerStyled>
);
