import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from 'effector';
import { useStore } from 'effector-react';
import { sharedSubjectsDataStores } from '@dat/shared-models/addressBook';
import { useToggle } from '@dat/core/hooks/useToggle';
import { Select, SingleSelectProps, ValueLabelOption } from '@wedat/ui-kit/components/Select';
import { CloseIcon, SearchIcon } from '@wedat/ui-kit/components/Icons';
import { Button } from '@wedat/ui-kit/components/Button';
import { Input } from '@wedat/ui-kit/components/Input';
import { createSubjectsDataWithRoles } from '../../utils/createSubjectsDataWithRoles';
import { Footer, IconWrapper } from '../Main/styles';
import { NotFound } from '../notFound';
import { ListItem } from './ListItem';
import { Container, SelectWrapper, List, Header, InputWrapper, InputClose, Content } from './styles';

interface Props {
    selectedDestination: BFF.AddressBook.Subject[];
    addItemToDestination: Event<BFF.AddressBook.Subject>;
    removeItemFromDestination: Event<BFF.AddressBook.Subject>;
    reset: Event<void>;
    close: () => void;
    isTablet: boolean;
    isOpen: boolean;
}

export const ContractList: React.FC<Props> = React.memo(
    ({ selectedDestination, addItemToDestination, removeItemFromDestination, reset, close, isTablet, isOpen }) => {
        const { t } = useTranslation();
        const customersSubjectsData = useStore(sharedSubjectsDataStores.customersSubjectsData);

        const DEFAULT_VALUE = useMemo(() => ({ value: 'All', label: t('contactList.all') }), [t]);

        const customersSubjectsDataWithRoles: BFF.AddressBook.SubjectsData = useMemo(() => {
            const current = createSubjectsDataWithRoles(customersSubjectsData);

            return current;
        }, [customersSubjectsData]);

        const allCustomersSubjectsData = useMemo(
            () =>
                customersSubjectsDataWithRoles
                    ? Object.values(customersSubjectsDataWithRoles)
                          .map(item => item?.subjects)
                          .flat()
                    : [],
            [customersSubjectsDataWithRoles]
        );

        const options = useMemo(() => {
            const keys = customersSubjectsDataWithRoles ? Object.keys(customersSubjectsDataWithRoles) : [];
            return [DEFAULT_VALUE, ...keys.map(item => ({ value: item, label: item }))];
        }, [customersSubjectsDataWithRoles, DEFAULT_VALUE]);

        const formattedCustomersSubjectsData: BFF.AddressBook.SubjectsData = useMemo(
            () => ({ All: { subjects: allCustomersSubjectsData }, ...customersSubjectsDataWithRoles }),
            [allCustomersSubjectsData, customersSubjectsDataWithRoles]
        );

        const [isSearch, toggleSearch, setIsSearch] = useToggle();
        const [selectedValue, setSelectedValue] = useState<ValueLabelOption>(DEFAULT_VALUE as ValueLabelOption);
        const [searchValue, setSearchValue] = useState<string>('');
        const [currentSubjectsData, setCurrentSubjectsData] = useState<BFF.AddressBook.SubjectTypeData>();

        useEffect(() => {
            setSearchValue('');
            setIsSearch(false);
        }, [isOpen, setIsSearch]);

        useEffect(() => {
            setCurrentSubjectsData(formattedCustomersSubjectsData?.[selectedValue?.value]);
        }, [formattedCustomersSubjectsData, selectedValue]);

        useEffect(() => {
            if (isSearch) {
                setCurrentSubjectsData(formattedCustomersSubjectsData?.[DEFAULT_VALUE?.value]);
            } else {
                setCurrentSubjectsData(formattedCustomersSubjectsData?.[selectedValue?.value]);
                setSearchValue('');
            }
        }, [formattedCustomersSubjectsData, isSearch, selectedValue?.value, DEFAULT_VALUE]);

        const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value) {
                setCurrentSubjectsData({
                    subjects: allCustomersSubjectsData.filter(
                        // Property 'toLowerCase' does not exist on type 'DAT2.Field.Primitive'
                        sbj => String(sbj.name)?.toLowerCase().indexOf(e.target.value?.toLowerCase()) > -1
                    )
                });
            } else {
                setCurrentSubjectsData(formattedCustomersSubjectsData?.[DEFAULT_VALUE.value]);
            }

            setSearchValue(e.target.value);
        };

        const handleSelectChange = (option: ValueLabelOption) => {
            setSelectedValue(option);
        };

        const handleReset = () => {
            reset();
            setSearchValue('');
        };

        const addItem = (data: BFF.AddressBook.Subject) => () => addItemToDestination(data);

        const removeItem = (data: BFF.AddressBook.Subject) => () => removeItemFromDestination(data);

        const isExist = (item: BFF.AddressBook.Subject) => !!selectedDestination.find(i => i._id === item._id);

        //TODO: make mail field required in address book
        const currentSubjectsDataWithMail = useMemo(
            () => currentSubjectsData?.subjects.filter(item => !!item.mail),
            [currentSubjectsData?.subjects]
        );

        return (
            <Container>
                <Content>
                    <Header>
                        {isSearch ? (
                            <InputWrapper>
                                <Input
                                    value={searchValue}
                                    onChange={handleSearch}
                                    placeholder={t('contactList.search')}
                                />
                                <InputClose onClick={toggleSearch}>
                                    <CloseIcon />
                                </InputClose>
                            </InputWrapper>
                        ) : (
                            <>
                                <SelectWrapper>
                                    <Select
                                        name="subjectsList"
                                        options={options}
                                        value={selectedValue.value}
                                        onChange={handleSelectChange as SingleSelectProps['onChange']}
                                    />
                                </SelectWrapper>
                                <IconWrapper onClick={toggleSearch}>
                                    <SearchIcon />
                                </IconWrapper>
                            </>
                        )}
                    </Header>

                    <List>
                        {currentSubjectsDataWithMail?.map(item => (
                            <ListItem
                                key={item._id}
                                data={item}
                                addItem={addItem(item)}
                                removeItem={removeItem(item)}
                                isExist={isExist(item)}
                            />
                        ))}
                    </List>

                    {!currentSubjectsDataWithMail?.length && <NotFound />}
                </Content>

                {isTablet && (
                    <Footer>
                        <Button typeStyle={{ type: 'transparent' }} onClick={handleReset}>
                            {t('inputs.cancel')}
                        </Button>
                        <Button onClick={close}>{t('inputs.confirm')}</Button>
                    </Footer>
                )}
            </Container>
        );
    }
);
