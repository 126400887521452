import React from 'react';
import { useStore } from 'effector-react';

import { containedPluginsStores } from '../../../../stores/containedPlugins';
import { appointmentEvents, appointmentStores } from '../../../../stores/appointment';
import { useToggle } from '@dat/core/hooks/useToggle';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { DamageSelector } from '@dat/damage-selector';
import { LabourRates } from '@dat/labour-rates';
import { useTranslation } from 'react-i18next';

import {
    PluginLink,
    PluginsLinksContainer,
    VehicleSelectionContainer,
    StyledTooltip,
    HideBlockContainer
} from './styles';

import { EquipmentIcon, LabourRatesIcon, TechnicalDataIcon } from '../../../../assets/icons';
import { VehicleInputs } from '../VehicleInputs';
import { modalsEvents } from '@dat/vehicle-selection-modal/src/stores/modals';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { HideBlock } from '../../../../components/HideBlock';
import { CreateAppointment } from '../../../../components/SchedulerModals/CreateAppointment';
import { CreateExpiryClaim } from '../../../../components/SchedulerModals/CreateExpiryClaim';
import { Slider } from '../Slider';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { vehicleImagesStores } from '../../../../stores/images';

export const Modals: React.FC = () => {
    const { t } = useTranslation();

    const vehicleSelectionPluginOptions = useStore(containedPluginsStores.vehicleSelectionPluginOptions);
    const damageSelectorPluginOptions = useStore(containedPluginsStores.damageSelectorPluginOptions);
    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const labourRatesOptions = useStore(containedPluginsStores.labourRatesPluginOptions);
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);

    const isAppointmentPopupOpen = useStore(appointmentStores.isAppointmentPopupOpen);
    const isExpiryPopupOpen = useStore(appointmentStores.isExpiryPopupOpen);

    const [isLabourRatesOpen, toggleIsLabourRatesOpen] = useToggle(false);

    const createProtectedModalToggler = (originalToggler: Function) => () => {
        if (isVehicleIdentified) {
            originalToggler();
        }
    };

    const closeAppointmentPopup = () => appointmentEvents.toggleIsAppointmentPopupOpen();
    const closeExpiryClaimPopup = () => appointmentEvents.toggleIsExpiryPopupOpen();

    const isMobile = useMedia(sizes.laptop);

    return (
        <>
            <HideBlock sidebarBlock="vehicle-selection">
                <HideBlockContainer>
                    <Slider />
                    <VehicleSelectionContainer
                        noImage={!vehicleImages.length}
                        isVehicleIdentified={isVehicleIdentified}
                    >
                        <VehicleInputs options={vehicleSelectionPluginOptions} />

                        <PluginsLinksContainer>
                            <StyledTooltip text={t('modals.equipment')} width="auto" placement="top-start">
                                <PluginLink
                                    onClick={createProtectedModalToggler(modalsEvents.openEquipmentModal)}
                                    disabled={!isVehicleIdentified}
                                >
                                    <EquipmentIcon />
                                </PluginLink>
                            </StyledTooltip>
                            <StyledTooltip text={t('modals.technicalData')} width="auto" placement="top">
                                <PluginLink
                                    onClick={createProtectedModalToggler(modalsEvents.openTechnicalDataModal)}
                                    disabled={!isVehicleIdentified}
                                >
                                    <TechnicalDataIcon />
                                </PluginLink>
                            </StyledTooltip>
                            <StyledTooltip text={t('modals.labourRates')} width="auto" placement="top-end">
                                <PluginLink
                                    onClick={createProtectedModalToggler(toggleIsLabourRatesOpen)}
                                    disabled={!isVehicleIdentified}
                                >
                                    <LabourRatesIcon />
                                </PluginLink>
                            </StyledTooltip>
                        </PluginsLinksContainer>
                    </VehicleSelectionContainer>
                </HideBlockContainer>
            </HideBlock>

            <HideBlock sidebarBlock="damage-selector">
                <HideBlockContainer>
                    <DamageSelector options={damageSelectorPluginOptions} />
                </HideBlockContainer>
            </HideBlock>

            <HideBlock sidebarBlock="labour-rates">
                <ModalPrimary
                    isOpen={isVehicleIdentified && isLabourRatesOpen}
                    onDismiss={toggleIsLabourRatesOpen}
                    bodyNoPadding
                    hideHeader
                    bodyHeight="90vh"
                    bodyWidth={isMobile ? '100%' : '90vw'}
                    fullFill={isMobile}
                >
                    <LabourRates options={labourRatesOptions} closeLabourRatesModal={toggleIsLabourRatesOpen} />
                </ModalPrimary>
            </HideBlock>

            <HideBlock sidebarBlock="create-appointment">
                <ModalPrimary
                    title={t('create.appointment')}
                    isOpen={isAppointmentPopupOpen}
                    onDismiss={closeAppointmentPopup}
                    bodyNoPadding
                    zIndex={1001}
                >
                    <CreateAppointment onClose={closeAppointmentPopup} />
                </ModalPrimary>
            </HideBlock>

            <HideBlock sidebarBlock="create-expiry-claim">
                <ModalPrimary
                    title={t('create.expiryClaim')}
                    isOpen={isExpiryPopupOpen}
                    onDismiss={closeExpiryClaimPopup}
                    bodyNoPadding
                    zIndex={1001}
                >
                    <CreateExpiryClaim onClose={closeExpiryClaimPopup} />
                </ModalPrimary>
            </HideBlock>
        </>
    );
};
