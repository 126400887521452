import { FC, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { components } from 'react-select';

import { Time, Marker } from '@wedat/ui-kit/assets/Icon';
import { Text } from '@wedat/ui-kit/components/Text';
import { Select } from '@wedat/ui-kit';
import { ArrowThin } from '@wedat/ui-kit/components/Icons';

import SchedulerImage from '@wedat/ui-kit/assets/images/scheduler.png';

import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { pointsAsideEvents, pointsAsideStores } from '../../stores/pointAside';

import {
    Address,
    AppointmentContent,
    AppointmentId,
    AppointmentWrapper,
    ArrowWrapper,
    ComponentWrapper,
    Divider,
    DividerHorizontal,
    FlexibleContent,
    Header,
    Info,
    Location,
    MediaContent,
    IconWrapper,
    PositionWrapper,
    RegistrationNumber,
    SelectButton,
    SelectButtonWrapper,
    SelectWrapper,
    UpperPart,
    UserImg,
    UserInfo,
    UserInfoWrapper,
    ViewAllContainer,
    NoAppointmentWrapper,
    TextWrapper,
    UserNoAvatar
} from './style';

import { APPOINTMENT_ITEM_ADDRESS_MAX_LENGTH } from '../../constants/constants';

type Props = {
    userInfo?: DAT2.UserProfile;
    id?: string;
    image?: string;
    registrationNumber?: string;
    toDate?: string | Date;
    isInAside?: boolean;
    address?: string;
    contractId?: string;
};

type SelectedOption = {
    label: string;
    value: string;
};

const SelectMenuButton = (menuListProps: any): JSX.Element => {
    const { t } = useTranslation();
    const time = useMemo(() => format(new Date(), 'hh:mm'), []);

    return (
        <ComponentWrapper>
            <components.MenuList {...menuListProps}>
                {menuListProps.children}
                <SelectButtonWrapper>
                    <SelectButton
                        onClick={() => {
                            pointsAsideEvents.addTimeInWorkingHours([time]);
                            pointsAsideEvents.setSelectedOption({
                                label: time,
                                value: time
                            });
                        }}
                    >
                        {t('now')}
                    </SelectButton>
                </SelectButtonWrapper>
            </components.MenuList>
        </ComponentWrapper>
    );
};

const DropdownIndicator = (): JSX.Element => (
    <IconWrapper>
        <Time />
    </IconWrapper>
);

export const AppointmentItem: FC<Props> = ({
    userInfo,
    image,
    isInAside,
    address = '',
    registrationNumber,
    id,
    contractId
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const isDesktopSmall = useMedia(sizes.desktopSmall);

    const selected = useStore(pointsAsideStores.selectedOption);
    const workingHours = useStore(pointsAsideStores.workingHours);
    const ref = useRef<HTMLDivElement>(null);

    const handleOpenClaim = useCallback(() => {
        history.push(`/claims/${contractId}/opening`);
    }, [contractId, history]);

    return (
        <AppointmentWrapper isInAside={isInAside}>
            <UpperPart isInAside={isInAside}>
                <Header>{t('myNextPoint')}</Header>
                <ViewAllContainer
                    onClick={() => {
                        pointsAsideEvents.openPointAside();
                    }}
                >
                    {t('viewAll')}
                </ViewAllContainer>
            </UpperPart>
            {id ? (
                <>
                    <AppointmentContent isInAside={isInAside}>
                        <UserInfoWrapper isInAside={isInAside}>
                            <Info isInAside={isInAside}>
                                <UserImg>
                                    {image ? (
                                        <img src={image} alt={userInfo?.name} />
                                    ) : (
                                        <UserNoAvatar>{`${userInfo?.name.charAt(0)} ${userInfo?.surname.charAt(
                                            0
                                        )}`}</UserNoAvatar>
                                    )}
                                </UserImg>
                                <UserInfo>
                                    <Text
                                        fontSize="14px"
                                        lineHeight="20px"
                                        fontWeight={700}
                                    >{`${userInfo?.name} ${userInfo?.surname}`}</Text>
                                    <FlexibleContent>
                                        {registrationNumber && (
                                            <RegistrationNumber>{registrationNumber}</RegistrationNumber>
                                        )}
                                        <AppointmentId>{id}</AppointmentId>
                                    </FlexibleContent>
                                </UserInfo>
                            </Info>
                            <ArrowWrapper isInAside={isInAside}>
                                <ArrowThin onClick={handleOpenClaim} />
                            </ArrowWrapper>
                        </UserInfoWrapper>
                        <DividerHorizontal isInAside={isInAside} />
                        <MediaContent isInAside={isInAside}>
                            <Divider isInAside={isInAside} />
                            <SelectWrapper ref={ref}>
                                <Select
                                    options={workingHours.map(hour => ({
                                        value: hour,
                                        label: hour
                                    }))}
                                    value={(selected as SelectedOption)?.value}
                                    onChange={option => {
                                        option && pointsAsideEvents.setSelectedOption(option as SelectedOption);
                                    }}
                                    name="selectMenuItem"
                                    valueKey="value"
                                    label={t('time')}
                                    components={{ DropdownIndicator, MenuList: SelectMenuButton }}
                                />
                            </SelectWrapper>
                            <Divider isInAside={isInAside} />
                            {address.length > 0 && (
                                <Location isInAside={isInAside}>
                                    <Address>
                                        {address.length > APPOINTMENT_ITEM_ADDRESS_MAX_LENGTH &&
                                        !isInAside &&
                                        !isDesktopSmall
                                            ? address.slice(0, APPOINTMENT_ITEM_ADDRESS_MAX_LENGTH) + '...'
                                            : address}
                                    </Address>
                                    <Marker />
                                </Location>
                            )}
                        </MediaContent>
                        <PositionWrapper isInAside={isInAside}>
                            <ArrowThin onClick={handleOpenClaim} />
                        </PositionWrapper>
                    </AppointmentContent>
                </>
            ) : (
                <NoAppointmentWrapper isInAside={isInAside}>
                    <img src={SchedulerImage} alt="scheduler" />
                    <TextWrapper isInAside={isInAside}>
                        <Text fontSize="12px" lineHeight="18px" fontWeight={400} color={theme.colors.dustBlue[600]}>
                            {t('allAppointmentsHaveBeenCompletedToDate')}
                        </Text>
                    </TextWrapper>
                </NoAppointmentWrapper>
            )}
        </AppointmentWrapper>
    );
};
