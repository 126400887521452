import styled from 'styled-components/macro';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    width: 100%;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    overflow-x: auto;

    ${makeCustomScrollBar()}
`;

export const HeadersContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PositionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
`;

export const FieldWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    flex-shrink: 0;
    margin-right: 16px;
`;
