import React from 'react';
import { ArrowUpIcon } from '../../Icons';
import { DrawerItemProps } from '../types';
import { CloseButton, Container, Content, Header, Title } from './styles';

export const DrawerItem: React.FC<DrawerItemProps> = ({
    width = 500,
    onClose,
    title,
    position = 'right',
    component,
    ...rest
}) => (
    <Container position={position} width={width} {...rest}>
        {(onClose || title) && (
            <Header position={position}>
                {onClose && (
                    <CloseButton onClick={onClose} position={position}>
                        <ArrowUpIcon />
                    </CloseButton>
                )}
                {title && (
                    <Title font="mobileHeader" position={position}>
                        {title}
                    </Title>
                )}
            </Header>
        )}
        <Content>{component}</Content>
    </Container>
);
