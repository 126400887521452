import styled from 'styled-components/macro';

interface Props {
    isActive?: boolean;
    isLaptop?: boolean;
}

export const SideBar = styled.div`
    width: 250px;
    min-height: 100vh;

    background: ${({ theme }) => theme.colors.white};
    border-radius: 15px;

    div:nth-child(1) {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
`;

export const SideBarItem = styled.div<Props>`
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background-color: ${props => props.isActive && props.theme.colors.dustBlue['50']};
    padding-left: 15px;

    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['100']};
    font-size: 14px;
    cursor: pointer;

    @media (max-width: 850px) {
        font-size: 12px;
    }
`;

export const SelectWrapper = styled.div`
    margin: 10px;
`;
