import React, { useEffect, useRef } from 'react';
import { useStore } from 'effector-react';
import { format } from 'date-fns';

import { dialogStores } from '../../stores/dialogs';
import { sharedUserStores } from '@dat/shared-models/user';

import { Header } from './Header';
import { MessageInput } from './MessageInput';
import { Message } from '../MessagingArea/Message';
import { DateMessageDivider } from './DateMessageDivider';
import { Container, MessagesWrapper } from './styles';
import { isSenderCurrentUser } from '../../utils/isSenderCurrentUser';

export const MessagingArea = () => {
    const currentDialog = useStore(dialogStores.currentDialog);
    const username = useStore(sharedUserStores.username);
    const customerNumber = useStore(sharedUserStores.customerNumber);

    let lastMessagesGroupDate: string = '';

    const messagesWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        messagesWrapperRef.current?.scrollTo(0, messagesWrapperRef.current?.scrollHeight);
    }, [currentDialog]);

    return (
        <Container>
            <Header />
            <MessagesWrapper ref={messagesWrapperRef}>
                {currentDialog?.messages.map(message => {
                    const createdDate = new Date(message.created).toLocaleDateString();

                    return (
                        <div key={message.id}>
                            {createdDate !== lastMessagesGroupDate && (
                                <DateMessageDivider date={(lastMessagesGroupDate = createdDate)} />
                            )}
                            <Message
                                message={message.text}
                                time={format(message.created, 'HH:mm')}
                                own={isSenderCurrentUser({ sender: message.sender, username, customerNumber })}
                            />
                        </div>
                    );
                })}
            </MessagesWrapper>
            <MessageInput />
        </Container>
    );
};
