import { createEvent, createStore } from 'effector';
import { FastTrackElementsMenuGroup } from '../types/plugin';

const $fastTrackElementsMenu = createStore<FastTrackElementsMenuGroup | null>(null);
const setFastTrackElementsMenu = createEvent<FastTrackElementsMenuGroup | null>();
$fastTrackElementsMenu.on(setFastTrackElementsMenu, (_, val) => val);

export const fastTrackElementsMenuModel = {
    stores: {
        $fastTrackElementsMenu
    },
    events: {
        setFastTrackElementsMenu
    }
};
