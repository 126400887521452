import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { useEffect, useMemo, useState } from 'react';
import { DataGroup } from '../../types/dataScheme';
import { GroupContent } from '../GroupContent';
import _ from 'lodash';

/**
 * Hook to get Tabs component with FormGroups
 */
export const useTabGroups = (formName: string, formGroups: DataGroup[], horizontalFields?: boolean) => {
    const { tabValues, formGroupsByTabName } = useMemo(() => {
        const formGroupInTabs = formGroups.filter(({ tabName }) => tabName);
        const formGroupsByTabName = _.groupBy(formGroupInTabs, 'tabName');
        const tabValues = Object.keys(formGroupsByTabName).map(tabName => ({
            id: tabName,
            label: tabName
        }));

        return {
            formGroupsByTabName,
            tabValues
        };
    }, [formGroups]);
    const [firstTabValue] = tabValues;

    const [activeTab, setActiveTab] = useState<string>(firstTabValue?.id);

    useEffect(() => {
        if (firstTabValue?.id) {
            setActiveTab(firstTabValue.id);
        }
    }, [firstTabValue?.id]);

    const TabsContent = (
        <>
            <Tabs values={tabValues} onChange={setActiveTab} active={activeTab} fillWidth />

            {tabValues.map(
                ({ id: tabName }) =>
                    activeTab === tabName &&
                    formGroupsByTabName[tabName].map((group, idx) => (
                        <GroupContent
                            key={`${group.groupName}_${idx}`}
                            formName={formName}
                            group={group}
                            horizontalFields={horizontalFields}
                        />
                    ))
            )}
        </>
    );

    return {
        TabsContent
    };
};
