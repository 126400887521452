import { FC } from 'react';
import { useField, useFormikContext, FieldInputProps } from 'formik';

import { FieldEventCallback } from '../types';
import { BoxSwitcher } from '../../components/BoxSwitcher';
import { BoxSwitcherProps } from '../../components/BoxSwitcher/types';

export interface BoxSwitcherFieldProps extends BoxSwitcherProps {
    name: string;
    changeCallback?: FieldEventCallback<any, void, boolean>;
    onUserChange?: (field: FieldInputProps<boolean>) => void;
}

export const BoxSwitcherField: FC<BoxSwitcherFieldProps> = ({ name, changeCallback, onUserChange, ...rest }) => {
    const formikContext = useFormikContext();
    const [{ value, ...field }, , helpers] = useField<boolean>(name);

    const handleChange = (checked: boolean) => {
        helpers.setValue(checked);
        changeCallback?.(checked, formikContext);
        onUserChange?.({
            ...field,
            value: checked
        });
    };

    return <BoxSwitcher {...field} {...rest} checked={!!value} onChange={handleChange} />;
};
