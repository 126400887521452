import { Operand } from './Operand';
import {
    isAnd,
    isEqual,
    isGreater,
    isLess,
    isNonEqual,
    isNotNull,
    isOr,
    filter,
    plus,
    minus,
    multiply,
    divide
} from './index';
import { IGetters, Operator } from './types';

class Condition {
    name: string;
    operator: Operator;
    operands: Operand[];

    constructor({ namedCondition, condition }: any, getters: IGetters) {
        this.name = namedCondition;
        this.operator = condition.operator;
        this.operands = (condition.operands || []).map((operand: any) => new Operand(operand, getters));
    }

    compare = () => {
        const operandsCompare = this.operands.map(operand => Boolean(operand.use()));
        switch (this.operator) {
            case Operator.EQUAL:
                return isEqual<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.IS_NOT_NULL:
                return isNotNull<boolean>(operandsCompare[0]);
            case Operator.OR:
                return isOr<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.AND:
                return isAnd<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.NON_EQUAL:
                return isNonEqual<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.GREATER:
                return isGreater<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.LESS:
                return isLess<boolean>(operandsCompare[0], operandsCompare[1]);
            case Operator.FILTER:
                return filter<boolean>(operandsCompare);
        }
    };

    calculate = () => {
        const operandsCalculate = this.operands.map(operand => Number(operand.use()));

        switch (this.operator) {
            case Operator.PLUS:
                return plus<number>(operandsCalculate[0] || 0, operandsCalculate[1] || 0);
            case Operator.MINUS:
                return minus<number>(operandsCalculate[0] || 0, operandsCalculate[1] || 0);
            case Operator.MULTIPLY:
                return multiply<number>(operandsCalculate[0] || 1, operandsCalculate[1] || 1);
            case Operator.DIVIDE:
                return divide<number>(operandsCalculate[0], operandsCalculate[1] || 1);
            default:
                return 0;
        }
    };
}

export { Condition };
