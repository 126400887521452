import { convertConstructionPeriodToDate } from '@dat/core/utils';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { partsInfoModel } from '../../stores/partsInfoModel';

export const EquipmentDeps: FC<{ partNumber: string }> = ({ partNumber }) => {
    const { t } = useTranslation();

    const currentSparePartVariants = useStore(partsInfoModel.stores.currentSparePartVariants);
    const sparePartVariants = currentSparePartVariants?.sparePartVariant;
    const equipments = currentSparePartVariants?.equipment;

    return (
        <div>
            <h2>{partNumber}</h2>
            {sparePartVariants
                ?.filter(variant => variant.partNumber === partNumber)
                .map((variant, index) => {
                    const constructionTimeFrom = variant.constructionTimeFrom;
                    const constructionPeriod =
                        constructionTimeFrom && constructionTimeFrom > 1
                            ? convertConstructionPeriodToDate(constructionTimeFrom.toString())
                            : '';
                    const constructionConditions = variant.constructionCondition?.toString().split('&') || [];
                    const excludingConditions = variant.excludingCondition?.toString().split('&') || [];

                    return (
                        <div key={index} style={{ borderBottom: '1px #ccc solid', marginBottom: '10px' }}>
                            {constructionPeriod && (
                                <Divider orientation="left" style={{ margin: 0 }}>
                                    {constructionPeriod}
                                </Divider>
                            )}
                            {constructionConditions.length
                                ? constructionConditions?.map(eqNum => (
                                      <div key={eqNum}>
                                          <b>{eqNum}</b>
                                          {' - ' + equipments?.find(eqmnt => eqmnt.number?.toString() === eqNum)?.name}
                                      </div>
                                  ))
                                : t('PartsInfo.NoRestrictions')}
                            {excludingConditions.length ? (
                                <>
                                    <Divider orientation="left" style={{ margin: 0, fontSize: '14px' }}>
                                        {t('PartsInfo.ExcludingConditions')}
                                    </Divider>{' '}
                                    {excludingConditions?.map(eqNum => (
                                        <div key={eqNum}>
                                            <b>{eqNum}</b>
                                            {' - ' +
                                                equipments?.find(eqmnt => eqmnt.number?.toString() === eqNum)?.name}
                                        </div>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    );
                })}
        </div>
    );
};
