import styled from 'styled-components';
import { AccordionItem } from 'react-accessible-accordion';
import { ArrowExpand } from '../Icons';

export const ArrowIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 24px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
`;

export const ArrowExpandStyled = styled(ArrowExpand)`
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.dustBlue['300']};
    transform: rotate(180deg);
    transition: transform 0.3s;
`;

export const StyledAccordionItem = styled(AccordionItem)`
    .accordion__button[aria-expanded='true'] {
        ${ArrowExpandStyled} {
            transform: rotate(0deg);
        }
    }

    .accordion__button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
        background-color: transparent;
        color: ${({ theme }) => theme.colors.blue['300']};
        cursor: pointer;
        /* padding: 11px; */
        font-weight: 500;
        font-size: 18px;
        max-width: 100%;
        text-align: left;
        border: none;
    }
    .accordion__panel {
        color: ${({ theme }) => theme.colors.textSecondary};
        font-weight: 500;
        font-size: 18px;
        animation: fadein 0.4s linear;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
