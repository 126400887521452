import React from 'react';
import { Formik } from 'formik';
import { useStore } from 'effector-react';

import { sharedDossierStores } from '@dat/shared-models/contract/Dossier';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { getParsedArraySafe } from '@dat/api2/utils';
import { getWorkLogicValues } from '../../utils/getWorkLogicValues';

import { Content } from './Content';
import { Container } from './styles';

export const WorkLogic = () => {
    const dossier = useStore(sharedDossierStores.dossier);
    const repairPositions = useStore(sharedRepairCalculationStores.repairPositions);

    const workLogicRepairPosition = repairPositions.find(pos => pos.SparePartNumber === 99991);

    const valuesFromDossier = getWorkLogicValues({
        parameters: getParsedArraySafe(
            dossier?.RepairCalculation?.ProcedureRelatedParameters?.ProcedureRelatedParameter
        ),
        workLogicRepairPosition
    });

    return (
        <Container>
            <Formik initialValues={valuesFromDossier} onSubmit={() => {}}>
                <Content />
            </Formik>
        </Container>
    );
};
