import { XMLObjectInstance } from '@dat/api/utils';

interface EquipmentContainerField {
    EquipmentPosition: DAT.EquipmentPosition;
}

export const parseEquipmentByVin = (response: XMLObjectInstance<DAT.GetVehicleIdentificationByVinResponse>) => {
    const dossiers = response.getFieldArray('Dossier');

    const parseEquipmentPosition = (equipmentContainerField: XMLObjectInstance<EquipmentContainerField> | undefined) =>
        equipmentContainerField?.getFieldArray('EquipmentPosition').map(pos => ({
            DatEquipmentId: +pos.getSingleField('DatEquipmentId')._innerText,
            Description: pos.getSingleField('Description')?._innerText,
            EquipmentClass: pos.getSingleField('EquipmentClass')?._innerText,
            EquipmentGroup: pos.getSingleField('EquipmentGroup')?._innerText,
            Category: pos.getSingleField('Category')?._innerText
        })) || [];

    const result = dossiers.map(dossier => {
        const vehicleField = dossier.getSingleField('Vehicle');

        const equipment = vehicleField.getSingleField('Equipment');

        return {
            equipment: {
                SeriesEquipment: {
                    EquipmentPosition: parseEquipmentPosition(equipment.getSingleField('SeriesEquipment'))
                },
                SpecialEquipment: {
                    EquipmentPosition: parseEquipmentPosition(equipment.getSingleField('SpecialEquipment'))
                }
            }
        };
    });

    return result[0].equipment;
};
