import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { radioItems } from '../../constants/radioItems';

import { AccordionItemContent, CheckboxFieldContainer, Field, FieldsContainer } from '../Layout/styles';
import { CheckboxField, InputField } from '@wedat/ui-kit/Formik';

import { FactorsParametersObject } from '../../types/factors';
import { InputWithSelect } from '../InputWithSelect';

export const LabourCostFactors: React.FC = () => {
    const { t } = useTranslation();
    const { values } = useFormikContext<FactorsParametersObject>();
    const { mechanicWage2, bodyWage2, electricWage2, mechanicWage3, bodyWage3, electricWage3 } =
        values.LabourCostFactor;

    const withLevel2Wages = mechanicWage2 || bodyWage2 || electricWage2;
    const withLevel3Wages = mechanicWage3 || bodyWage3 || electricWage3;

    const percentOrAbsolute = radioItems.percentOrAbsolute(t);

    return (
        <AccordionItemContent>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="LabourCostFactor.dentsCountInProtocol"
                        label={t('labourCost.dentsCountInProtocol')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="LabourCostFactor.isSuppressCavityProtection"
                        label={t('labourCost.isSuppressCavityProtection')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>

            <FieldsContainer>
                <Field>
                    <InputField name="LabourCostFactor.mechanicWage1" label={t('labourCost.mechanicWage', { n: 1 })} />
                </Field>
                {withLevel2Wages && (
                    <Field>
                        <InputField
                            name="LabourCostFactor.mechanicWage2"
                            label={t('labourCost.mechanicWage', { n: 2 })}
                        />
                    </Field>
                )}
                {withLevel3Wages && (
                    <Field>
                        <InputField
                            name="LabourCostFactor.mechanicWage3"
                            label={t('labourCost.mechanicWage', { n: 3 })}
                        />
                    </Field>
                )}

                <Field>
                    <InputField name="LabourCostFactor.bodyWage1" label={t('labourCost.bodyWage', { n: 1 })} />
                </Field>
                {withLevel2Wages && (
                    <Field>
                        <InputField name="LabourCostFactor.bodyWage2" label={t('labourCost.bodyWage', { n: 2 })} />
                    </Field>
                )}
                {withLevel3Wages && (
                    <Field>
                        <InputField name="LabourCostFactor.bodyWage3" label={t('labourCost.bodyWage', { n: 3 })} />
                    </Field>
                )}

                <Field>
                    <InputField name="LabourCostFactor.electricWage1" label={t('labourCost.electricWage', { n: 1 })} />
                </Field>
                {withLevel2Wages && (
                    <Field>
                        <InputField
                            name="LabourCostFactor.electricWage2"
                            label={t('labourCost.electricWage', { n: 2 })}
                        />
                    </Field>
                )}
                {withLevel3Wages && (
                    <Field>
                        <InputField
                            name="LabourCostFactor.electricWage3"
                            label={t('labourCost.electricWage', { n: 3 })}
                        />
                    </Field>
                )}

                <Field>
                    <InputField name="LabourCostFactor.dentWage" label={t('abourCost.dentWage')} />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'LabourCostFactor.discount',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'LabourCostFactor._attributes.mode.discount',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'LabourCostFactor.discountBiw',
                            label: t('common.discountBiw')
                        }}
                        selectProps={{
                            name: 'LabourCostFactor._attributes.mode.discountBiw',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputField name="LabourCostFactor.labourLumpSum" label={t('abourCost.labourLumpSum')} />
                </Field>
            </FieldsContainer>
        </AccordionItemContent>
    );
};
