import styled from 'styled-components/macro';

interface Props {
    isVisible?: boolean;
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
        cursor: pointer;
    }
`;

export const Item = styled.div<Props>`
    min-height: 44px;

    display: ${props => (props.isVisible ? 'none' : 'flex')};
    justify-content: space-between;
    align-items: center;

    padding: 12px 16px;

    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['100']};
    }
`;
