import React, { FC } from 'react';
import { Accordion, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { UUID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import { ArrowExpandStyled, ArrowIcon, StyledAccordionItem } from './styles';

export interface AccordionProps {
    header: React.ReactNode | string;
    uuid?: UUID;
    activeClassName?: string;
    dangerouslySetExpanded?: boolean;
    withoutArrowIcon?: boolean;
}

export { Accordion };

export const AccordionItem: FC<AccordionProps> = ({ header, children, withoutArrowIcon, ...rest }) => (
    <StyledAccordionItem {...rest}>
        <AccordionItemHeading>
            <AccordionItemButton>
                {header}
                {!withoutArrowIcon && (
                    <ArrowIcon>
                        <ArrowExpandStyled />
                    </ArrowIcon>
                )}
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
    </StyledAccordionItem>
);
