import { createEffect, createEvent, createStore } from 'effector';
import { API2 } from '@dat/api2';
import { PayloadForDeleteAccesoryParts, PayloadForSavePositions, PayloadForWorkBench } from './types';
import { WorkLogicFormValues } from '../../types/worklogicForm';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';

const positionAdded = createEvent();
const addInitialPosition = createEvent<DAT2.PositionItaly>();

const initialFormPositions = createStore<DAT2.PositionItaly[]>([]).on(addInitialPosition, (state, payload) => [
    ...state,
    payload
]);
const deletedPositions = createStore<DAT2.PositionItaly[]>([]);
const deletedPositionsIndexes = createStore<number[]>([]);
const isCalculationEmpty = sharedRepairCalculationStores.repairPositions.map(
    repairPositions => repairPositions.length === 0
);

const deleteAccesoryPartsFx = createEffect(({ contractId, ...rest }: PayloadForDeleteAccesoryParts) =>
    API2.myClaim.deleteAccesoryParts({ contractID: contractId, ...rest })
);
const addWorkBenchFx = createEffect(({ contractId, positionsItaly }: PayloadForWorkBench) =>
    API2.myClaim.addWorkBench({
        contractID: contractId,
        positionsItaly: {
            PositionItaly: positionsItaly
        }
    })
);
const saveWorkBenchFx = createEffect<
    { workBench: PayloadForWorkBench; repairPositions: DAT2.RepairPosition[] },
    void
>();
const saveWorkLogicFx = createEffect<{ workLogicFormValues: WorkLogicFormValues }, void>();
const savePositionsFx = createEffect<PayloadForSavePositions, void>();

const collectDeletedPositions = createEvent<DAT2.PositionItaly[]>();
const collectDeletedPositionsIndexes = createEvent<number[]>();

export const positionsEvents = {
    positionAdded,
    collectDeletedPositions,
    collectDeletedPositionsIndexes,
    addInitialPosition
};
export const positionsStores = {
    initialFormPositions,
    deletedPositions,
    deletedPositionsIndexes,
    isCalculationEmpty
};
export const positionsEffects = {
    deleteAccesoryPartsFx,
    savePositionsFx,
    saveWorkBenchFx,
    addWorkBenchFx,
    saveWorkLogicFx
};
