import { TOTAL_PRICE_RELATED_POSITIONS } from '../constants';

export const provideTotalPriceOptions = (options: DAT2.RepairCalculation['CalculationSummary']) => {
    const sortedPrices =
        typeof options === 'object'
            ? Object.entries(options).map(item => {
                  if (typeof item[1] === 'object') {
                      return {
                          key: item[0],
                          value: item[1].TotalSum
                      };
                  }

                  return {
                      key: item[0],
                      value: item[1]
                  };
              })
            : undefined;

    const result = sortedPrices?.filter(item => TOTAL_PRICE_RELATED_POSITIONS.includes(item.key));

    return result;
};
