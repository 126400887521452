import { useStoreMap } from 'effector-react';
import { sharedVehiclesImagesStores } from '@dat/shared-models/contract/Dossier/Vehicle/Images';
import { IMAGE_ASPECT } from '../../../../../../stores/images/constants';
import { createDataURL } from '@dat/core/utils/data/createDataURL';

export const useVehicleImage = (datECode: string | number | undefined) =>
    useStoreMap(sharedVehiclesImagesStores.allVehiclesImages, allVehiclesImages => {
        if (!datECode) return null;

        const image = allVehiclesImages[datECode]?.[IMAGE_ASPECT]?.[0];

        if (!image) return null;

        return createDataURL({
            type: 'image',
            subType: image.imageFormat,
            data: image.imageBase64,
            base64: true
        });
    });
