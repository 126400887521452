import styled, { css } from 'styled-components/macro';
import { ButtonIcon } from '@wedat/ui-kit/components/ButtonIcon';
import { media } from '@wedat/ui-kit/mediaQueries';
import { isIOS } from 'react-device-detect';
import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { Tooltip } from '@wedat/ui-kit/components/Tooltip';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;

    ${media.phoneBig`
        padding-left: 24px;
        padding-right: 24px;
    `}
`;

export const LeftButtons = styled.div`
    display: flex;
    margin-left: 24px;

    ${media.phoneBig`
        position: fixed;
        width: 100%;
        margin-left: 0;
        /* 48px indent common padding */
        width: calc(100% - 48px);
        justify-content: space-between;
        align-items: center;
        /* Indent for Footer */
        bottom: 56px;
        left: 50%;
        height: 56px;
        z-index: 11;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 12px;
        overflow: hidden;
        transform: translateX(-50%);
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));

        ${
            isIOS &&
            css`
                bottom: 76px;
            `
        }
    `}
`;

export const ButtonIconStyled = styled(ButtonIcon)`
    width: 48px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.dustBlue['300']};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;

    ${media.phoneBig`
        border-radius: 0;
        border: none;
    `}
`;

export const ButtonIconStyledWithMargin = styled(ButtonIcon)`
    margin-left: 24px;
    width: 48px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.dustBlue['300']};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    flex-shrink: 0;

    ${media.phoneBig`
        margin-left: 20px;
    `}
`;

export const IconWrapper = styled.span`
    height: 18px;
    width: 18px;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    svg {
        width: 100%;
        height: 100%;
    }

    ${media.phoneBig`
    color: ${({ theme }) => theme.colors.gray_300};
    `}
`;

export const TextStyled = styled(Text)`
    margin-left: 12px;
`;

export const ButtonStyledDelete = styled(Button)`
    min-width: 48px;
    width: fit-content;
    margin-left: 24px;
    padding-left: 40px;
    padding-right: 40px;
    border-color: ${({ theme }) => theme.colors.red['400']};
    color: ${({ theme }) => theme.colors.red['400']};
    background-color: ${({ theme }) => theme.colors.white};
    flex-shrink: 0;
    width: fit-content;

    ${TextStyled} {
        color: ${({ theme }) => theme.colors.red['400']};
    }

    ${IconWrapper} {
        & svg > path {
            stroke: ${({ theme }) => theme.colors.red['400']};
        }
    }

    ${media.phoneBig`
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
    `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.red['400']};
        border-color: ${({ theme }) => theme.colors.red['400']};

        ${TextStyled} {
            color: ${({ theme }) => theme.colors.white};
        }

        ${IconWrapper} {
            & svg > path {
                stroke: ${({ theme }) => theme.colors.white};
            }
        }
    }
`;

export const LeftSideWrapper = styled.div`
    display: flex;
    align-items: center;

    ${media.phoneBig`
        width: 100%;
        justify-content: space-between;
    `}
`;

export const StyledTooltip = styled(Tooltip)`
    &:first-child {
        ${ButtonIconStyled} {
            border-radius: 8px 0px 0px 8px;
        }
    }

    &:not(:first-child) {
        ${ButtonIconStyled} {
            border-left-width: 0;
        }
    }

    &:last-child {
        ${ButtonIconStyled} {
            border-radius: 0px 8px 8px 0px;
        }
    }
`;
