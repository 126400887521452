const ADDRESS_KEY = 'owner_address';

export const retrieveAddress = ({ customTemplateData }: DAT2.ContractFromGetContract): string => {
    if (customTemplateData?.entry && Array.isArray(customTemplateData?.entry)) {
        const foundAddress = customTemplateData?.entry.find(({ key }) => key === ADDRESS_KEY);

        if (foundAddress) {
            return String(foundAddress.value._text);
        }

        return '';
    }
    return '';
};
