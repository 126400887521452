import { MimeMainType, MimeSubTypes } from '../../types/data';

/*
 * MIME-type = type/subType
 * Example:
 * if: type = 'image' & subType = 'jpg'
 * then: MIME-type = `image/jpg'
 */
type Param<M extends MimeMainType> = {
    type: M;
    subType: MimeSubTypes[M] | Uppercase<MimeSubTypes[M]>;
    data: string;
    base64?: boolean;
};
type Result = string;

export const createDataURL = <M extends MimeMainType>({ type, subType, data, base64 }: Param<M>): Result => {
    const mimeType = subType ? `${type}/${subType.toLowerCase()}` : type;
    const base64String = base64 ? ';base64' : '';

    return `data:${mimeType}${base64String},${data}`;
};
