import { guard } from 'effector';
import { contractStores } from '@dat/shared-models/contract';
import { priceDatesEffects } from './index';
import { sharedVehicleEvents, sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

const { getPriceDatesFx } = priceDatesEffects;
const { contractId } = contractStores;
const { newVehicleReceived } = sharedVehicleEvents;
const { isVehicleIdentified } = sharedVehicleStores;

guard({
    clock: [contractId.updates, newVehicleReceived],
    source: contractId.map(contractID => ({ contractID })),
    filter: isVehicleIdentified,
    target: getPriceDatesFx
});
