import cn from './cn/translation.json';
import cz from './cz/translation.json';
import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import gr from './gr/translation.json';
import it from './it/translation.json';
import kr from './kr/translation.json';
import nl from './nl/translation.json';
import pl from './pl/translation.json';
import ru from './ru/translation.json';

const translations = {
    'cs-CZ': cz,
    'de-DE': de,
    'el-GR': gr,
    'en-US': en,
    'es-ES': es,
    'fr-FR': fr,
    'it-IT': it,
    'ko-KR': kr,
    'nl-NL': nl,
    'pl-PL': pl,
    'ru-RU': ru,
    'zh-CN': cn
};

export default translations;
