import styled from 'styled-components/macro';

export const FlexWrapper = styled.div`
    display: flex;
    margin-bottom: 14px;
    align-items: center;
`;

export const SizedBox = styled.div`
    width: 100px;
`;

export const Info = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray_10};
    }
`;

export const SumInfo = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 2px solid ${({ theme }) => theme.colors.gray_10};
    }
`;
