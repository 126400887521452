import { getParsedArraySafe } from '@dat/api2/utils';
import { TableFooterTypes } from '../types/tableFooter';
import { MobileTableHeading } from '../components/Layout/styles';
import { TFunction } from 'react-i18next';
import { NumberFormatWrapper } from '../components/NumberFormatWrapper';

export const createDataForPaintwork = (t: TFunction, dossier: DAT2.Dossier | null, isMobile: boolean) => {
    const PaintworkCalculationResults = dossier?.RepairCalculation?.CalcResultCommon?.LacquerPositions?.LacquerPosition;

    return getParsedArraySafe(PaintworkCalculationResults).map(
        ({ DATProcessId, Description, Duration, Level, Material, MaterialPoints, ValueTotalCorrected, WageLevel1 }) =>
            isMobile
                ? {
                      col1: DATProcessId,
                      col2: '-',
                      col3: Description,
                      children: [
                          {
                              col4: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.level')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{Level}</MobileTableHeading>
                                  </>
                              ),
                              col5: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.materialPoints')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{MaterialPoints}</MobileTableHeading>
                                  </>
                              ),
                              col6: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.material')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={Material} />
                                      </MobileTableHeading>
                                  </>
                              ),
                              col7: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.time')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={Duration} />
                                      </MobileTableHeading>
                                  </>
                              ),
                              col8: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.labourCosts')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={WageLevel1} />
                                      </MobileTableHeading>
                                  </>
                              ),
                              col9: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('paintworkTable.headers.price')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={ValueTotalCorrected} />
                                      </MobileTableHeading>
                                  </>
                              )
                          }
                      ]
                  }
                : {
                      col1: DATProcessId,
                      col2: '-',
                      col3: Description,
                      col4: Level,
                      col5: MaterialPoints,
                      col6: <NumberFormatWrapper value={Material} />,
                      col7: <NumberFormatWrapper value={Duration} />,
                      col8: <NumberFormatWrapper value={WageLevel1} />,
                      col9: <NumberFormatWrapper value={ValueTotalCorrected} />
                  }
    );
};

export const createDataForPaintworkTableFooter = (t: TFunction, dossier: DAT2.Dossier | null): TableFooterTypes => {
    const currency = dossier?.Currency;

    const totalPaintWorkCosts = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.TotalSum;

    const paintMaterialPrice = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Material?.TotalSum;

    const timeSpent = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Wage?.Units;

    const totalLabourCosts = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Wage?.Price;

    const paintWorkConstants =
        dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Material?.LacquerConstants?.LacquerConstant;

    const constantsPrice = getParsedArraySafe(paintWorkConstants).map(({ Price, Description }) => ({
        price: <NumberFormatWrapper value={Price} />,
        label: Description
    }));

    const currencyValue = currency ?? '';

    return {
        lacquerConstants: [...constantsPrice],
        items: [
            {
                price: <NumberFormatWrapper value={timeSpent}>{t('dataForCards.hour')}</NumberFormatWrapper>,
                label: t('dataForCards.timeSpent')
            },
            {
                price: <NumberFormatWrapper value={totalLabourCosts}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.labourCostsForPaintwork')
            },
            {
                price: <NumberFormatWrapper value={paintMaterialPrice}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.paintMaterial')
            },
            {
                price: <NumberFormatWrapper value={totalPaintWorkCosts}>{currencyValue}</NumberFormatWrapper>,
                label: t('totalPaintworkCosts'),
                styles: {
                    fontWeight: 500,
                    fontSize: '17px'
                }
            }
        ]
    };
};
