import { guard } from 'effector';

import { pluginEvents } from './index';
import { contractEffects } from '@dat/shared-models/contract';

const { initPlugin } = pluginEvents;
const { getContractFx } = contractEffects;

guard({
    source: initPlugin.map(options => options.contractId),
    filter: (contractId): contractId is DAT2.ContractId => !isNaN(Number(contractId)),
    target: getContractFx
});
