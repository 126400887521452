import { useCallback, useState } from 'react';
import * as ort from 'onnxruntime-web';

import { CarType, Sides } from '../types';
import { inferenceCarChecker } from '../utils/predictCarChecker';
import { stack } from '../utils/stack';

const VALID_RESULT = 3;

const sessionDetection = ort.InferenceSession.create('/model/detection_model_224_224.ort', {
    executionProviders: ['wasm'],
    graphOptimizationLevel: 'all'
});
// session for checker model
const sessionChecker = ort.InferenceSession.create('/model/checker_model.ort', {
    executionProviders: ['wasm'],
    graphOptimizationLevel: 'all'
});

export const useVideoAiValidation = () => {
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<unknown>();
    const [valid, setIsValid] = useState<boolean>(false);
    let currStack = stack(VALID_RESULT);

    const clearValidationState = () => {
        currStack.flush();
        setIsValid(false);
        currStack.currStack = [];
    };

    const validate = useCallback(
        async (imgs: string[], acceptableCriteria: Sides, carType: CarType) => {
            setLoading(true);
            console.log('IS VALID', valid, acceptableCriteria, carType);
            try {
                const checker = await sessionChecker;
                const detection = await sessionDetection;

                const { output } = await inferenceCarChecker(imgs[0], carType, acceptableCriteria, {
                    checker,
                    detection
                });
                const result = Number(output.data.join(',')) === 1;
                console.log('IMAGE STATUS:', result);

                currStack.push(result);

                // checking if in stack more positive results than negative
                const isPositive = currStack.currStack.filter(i => !!i).length >= currStack.currStack.length / 2;

                console.log('SIZE CONFIRM', currStack.currStack.length >= VALID_RESULT);
                console.log('STACK', currStack.currStack);
                console.log('IS POSITIVE', currStack.currStack);
                console.log('SIZE', currStack.currStack.length);

                if (currStack.currStack.length >= VALID_RESULT && isPositive) {
                    setIsValid(true);
                }
            } catch (error) {
                setLoading(false);
                setError(error);
                setIsValid(false);
                return false;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [valid]
    );

    return {
        validate,
        setIsValid,
        clearValidationState,
        valid,
        loading,
        error
    };
};
