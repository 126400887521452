import { classificationGroupsNames } from '@dat/core/constants';

import { EquipmentSelects } from '../../types/formik';

const equipmentSelects: EquipmentSelects = {} as EquipmentSelects;

Object.values(classificationGroupsNames).forEach(groupName => (equipmentSelects[groupName] = ''));

export const formikInitialValues = {
    country: 'IT', // (test feature)
    repairType: 'REPAIR', // (test feature)
    numberPlate: '',
    vin: '',
    freeTextSearch: '',
    datECode: '',
    alternativeModelDatECode: '',
    nationalCode: '',
    kba: '',
    baseNumber: '',
    typeNoteNumber: '',
    kilometers: '',
    tiresPercent: '',
    cnit: '',
    firstRegistration: undefined as Date | undefined,
    firstRegistrationFilter: false,
    constructionPeriod: '',
    vehicleCountry: '', // used in VinRequestResultModal
    vehicleSelects: {
        vehicleType: '',
        manufacturer: '',
        baseModel: '',
        subModel: ''
    },
    equipmentSelects,
    container: '',
    paintType: '',
    alternativeModelSelects: {
        vehicleType: '',
        manufacturer: '',
        baseModel: '',
        subModel: ''
    },
    multipleVariantSelects: {
        vehicleType: '',
        manufacturer: '',
        baseModel: '',
        subModel: '',
        equipmentSelects
    },
    ManufacturerName: '',
    BaseModelName: '',
    SubModelName: ''
};
