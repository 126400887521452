import React, { useEffect, useMemo, useState } from 'react';
import { useStore, useStoreMap } from 'effector-react';
import { Formik } from 'formik';

import { positionsEvents, positionsStores } from '../../stores/positions';
import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';
import { getParsedArraySafe } from '@dat/api2/utils';
import { addPercentValueForLabourItems } from '../../stores/positions/utils';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { INITIAL_POSITION, USER_DEFINED_POSITIONS } from '../../constants/positions';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { Container, ScrollButton } from './styles';
import { ModalInfoTable } from '../ModalInfoTable';
import { ModalWorkLogic } from '../ModalWorkLogic';
import { ModalWorkBench } from '../ModalWorkBench';
import { ModalInfo } from '../ModalInfo';
import { ModalUserDefinedPositions } from '../ModalUserDefinedPositions';
import { PositionsFormValues } from '../../types/positions';
import { ArrowUpIcon } from '@wedat/ui-kit/components/Icons';
import { Desktop } from '../Desktop';
import { Mobile } from '../Mobile';
import { contractStores } from '@dat/shared-models/contract';

export const Main = () => {
    const positions = useStore(positionsStores.initialFormPositions);
    const procedureRelatedParameters = useStore(sharedRepairCalculationStores.procedureRelatedParameters);
    const isCalculationEmpty = useStore(positionsStores.isCalculationEmpty);
    const contractId = useStore(contractStores.contractId);
    const [isScrolledDown, setIsScrolledDown] = useState(false);
    const isMobile = useMedia(sizes.phoneBig);

    const labourItemsInitial = useStoreMap(sharedCalcResultItalyStores.summariesItaly, summaries =>
        getParsedArraySafe(summaries?.WorkSummaryItaly?.WorkSummaryItemItaly)
    );

    const labourItems = addPercentValueForLabourItems(labourItemsInitial, procedureRelatedParameters);

    const totalSumsItems = useStoreMap(sharedCalcResultItalyStores.summariesItaly, summaries =>
        getParsedArraySafe(summaries?.TotalSummaryItaly?.TotalSummaryItemItaly)
    ).map(item => {
        const newItem = { ...item };
        newItem.DiscountDisplayType = 'percent';

        return newItem;
    });

    const paintworkItems = useStoreMap(sharedCalcResultItalyStores.summariesItaly, summaries =>
        getParsedArraySafe(summaries?.LacquerSummaryItaly?.LacquerSummaryItemItaly)
    );

    const userDefinedPositions = useMemo(() => USER_DEFINED_POSITIONS, []);

    const initialValues = useMemo(
        () => ({ positions, labourItems, totalSumsItems, paintworkItems, userDefinedPositions }),
        [labourItems, paintworkItems, positions, totalSumsItems, userDefinedPositions]
    );

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSetIsScrolledDown = () => {
        setIsScrolledDown(window.scrollY > 100);
    };

    useEffect(() => {
        document.addEventListener('scroll', handleSetIsScrolledDown);

        return () => {
            document.removeEventListener('scroll', handleSetIsScrolledDown);
        };
    }, []);

    useEffect(() => {
        if (isCalculationEmpty && initialValues.positions.length === 0) {
            positionsEvents.addInitialPosition(INITIAL_POSITION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractId]);

    return (
        <Container>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={(_: PositionsFormValues) => {}}>
                <>
                    {isMobile ? <Mobile /> : <Desktop />}
                    <ModalUserDefinedPositions />
                </>
            </Formik>

            <ModalInfo />
            <ModalWorkBench />
            <ModalInfoTable />
            <ModalWorkLogic />
            {isScrolledDown && (
                <ScrollButton onClick={handleScrollTop}>
                    <ArrowUpIcon />
                </ScrollButton>
            )}
        </Container>
    );
};
