import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { Button, Drawer } from 'antd';
import { commonModel } from '../../stores/commonModel';
import { SummaryPanel } from './SummaryPanel';
import { SummaryPanelFooter } from './SummaryPanelFooter';
import { SummaryPanelMobile } from './SummaryPanelMobile';
import { ReactComponent as DeleteIcon } from '../CommonComponents/Icons/DeleteIcon.svg';
import { ArrowRightOutlined } from '@ant-design/icons';
import { fastTrackInterfaceModel } from '../../stores/subscribes';
import { Dossier } from '@dat/api2dat/types/contractTypes';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';

interface SummaryPanelDrawerParams {
    isMobile?: boolean;
    dossier?: Dossier;
}

export const SummaryPanelDrawer: FC<SummaryPanelDrawerParams> = ({ isMobile, dossier }) => {
    const { t } = useTranslation();

    const summaryPanelOpened = useStore(commonModel.stores.summaryPanelOpened);
    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);

    const widthFtSummaryPanel = 'var(--widthFtSummaryPanel)';

    return isMobile ? (
        // ======== MOBILE version
        <Drawer
            maskClosable
            closable={false}
            getContainer={document.getElementById('weDat-ft-main') || false}
            mask={false}
            placement={'bottom'}
            style={{ position: 'absolute' }}
            contentWrapperStyle={{ overflowY: 'hidden' }}
            title={
                <>
                    <span style={{ fontSize: '20px' }}>
                        {t('fastTrack.summaryPanelHeader')}{' '}
                        <span style={{ color: '#687792' }}>{`(${selectedElements.length})`}</span>
                    </span>
                    <span
                        onClick={() => {
                            fastTrackInterfaceModel.events.clear();
                        }}
                    >
                        <Button type="link" icon={<DeleteIcon />} style={{ color: '#687792' }} />
                        <span style={{ color: '#687792', fontWeight: 400 }}>{t('fastTrack.DeleteAll')}</span>
                    </span>
                </>
            }
            footer={<SummaryPanelFooter dossier={dossier} isMobile={true} content={{ calcElements: true }} />}
            visible={summaryPanelOpened}
            width="100%"
            height="100%"
            zIndex={1000}
        >
            <SummaryPanelMobile />
        </Drawer>
    ) : (
        // ======== PC version
        <Drawer
            maskClosable
            closable={false}
            getContainer={false}
            mask={false}
            placement={'right'}
            style={{ position: 'absolute' }}
            contentWrapperStyle={{ overflowY: 'hidden' }}
            title={
                <>
                    <Button
                        size="large"
                        icon={<ArrowRightOutlined />}
                        onClick={() => {
                            commonModel.events.setSummaryPanelOpened(false);
                        }}
                    />
                    <span style={{ marginLeft: '16px', fontSize: '20px' }}>{t('fastTrack.summaryPanelHeader')}</span>
                </>
            }
            footer={<SummaryPanelFooter dossier={dossier} />}
            visible={summaryPanelOpened}
            width={widthFtSummaryPanel}
            height="100%"
            zIndex={1000}
        >
            <SummaryPanel />
        </Drawer>
    );
};
