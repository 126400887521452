// FastMovingBar
import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Button, Space, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';
import { fastMovingBarModel } from '../../stores/fastMovingBarModel';

import { useTranslation } from 'react-i18next';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { commonModel } from '../../stores/commonModel';
import { RepairPositionsList } from '../RepairPositionsList/RepairPositionsList';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { findDvnWithDescriptionAndGroup } from '../../utils/ftRepairPositionConverter';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms/GraphicSetDamageForms';
import { italyAniaModel2 } from '../../stores/italyAniaModel2';
import { SelectedElement } from '../CommonComponents/SelectedElement';
import { ButtonWithMark } from '../CommonComponents/ButtonWithMark/ButtonWithMark';

export const FastMovingBarView: FC<{}> = () => {
    const fastMovingBarMenuSelect = useStore(fastMovingBarModel.stores.fastMovingBarMenuSelect);
    // const repairPositionByDatId = useStore(genericSVGGraphicModel.stores.repairPositionByDatId);
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const isModified = useStore(RepairPositionsModel.store.isModified);
    const ftDatIdToDvnMap = useStore(genericSVGGraphicModel.stores.ftDatIdToDvnMap);
    const { availableAssemblyGroups } = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);

    const { t } = useTranslation();

    const submenuShowTitle = false;

    return (
        <div>
            <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                <div style={{ float: 'left' }}>
                    <Space>
                        <Button
                            size="large"
                            icon={<ArrowRightOutlined />}
                            onClick={() => {
                                rightSideDrawerModel.events.clearHistoryAndClose();
                            }}
                        />
                        <h2 style={{ marginLeft: '8px', marginBottom: '0px' }}>
                            {!submenuShowTitle && fastMovingBarMenuSelect?.svgIcon && (
                                <img width="32px" height="32px" src={fastMovingBarMenuSelect.svgIcon} alt="" />
                            )}
                            &nbsp;&nbsp;&nbsp;
                            {`${fastMovingBarMenuSelect?.menuGroupLabel}`}
                        </h2>
                    </Space>
                </div>
            </div>

            <div
                style={{
                    paddingRight: '4px',
                    position: 'absolute',
                    top: '54px',
                    left: '0px',
                    right: '0px',
                    bottom: '54px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row gutter={[8, 8]}>
                    {fastMovingBarMenuSelect?.elementDatIDs.map(elementDATID => {
                        const isReplace = !!repairPositions.find(
                            repairPosition =>
                                repairPosition.DATProcessId === ftDatIdToDvnMap[elementDATID] &&
                                repairPosition.RepairType === 'replace'
                        );
                        return (
                            <Col span={24}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <SelectedElement
                                        style={{ flexGrow: 0 }}
                                        checked={isReplace}
                                        onChange={() => {
                                            const dvn = ftDatIdToDvnMap[elementDATID];
                                            const dvnInfo = findDvnWithDescriptionAndGroup(
                                                [dvn],
                                                availableAssemblyGroups
                                            );
                                            if (isReplace) {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: ftDatIdToDvnMap[elementDATID],
                                                    repairType: 'replace'
                                                });
                                            } else {
                                                if (dvnInfo && dvnInfo.DATProcessId)
                                                    italyAniaModel2.events.addReplace({
                                                        DATProcessId: dvnInfo.DATProcessId,
                                                        Description: dvnInfo.Description,
                                                        assemblyGroup: dvnInfo.assemblyGroup
                                                    });
                                            }
                                        }}
                                    />

                                    <ButtonWithMark
                                        leftMark={
                                            !!repairPositions.find(
                                                repairPosition =>
                                                    repairPosition.DATProcessId === ftDatIdToDvnMap[elementDATID]
                                            )
                                        }
                                        style={{ textAlign: 'left', flexGrow: 1 }}
                                        size="large"
                                        onClick={() => {
                                            graphicDamageSelectionModel.events.updateStore({
                                                typeOfGraphic: 'FullGraphic'
                                            });
                                            genericSVGGraphicModel.events.setCurrentGrapaObjectsByDatId(elementDATID);
                                            rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
                                        }}
                                    >
                                        {t(`fastLocalNameDATID.${elementDATID}`) || elementDATID}
                                    </ButtonWithMark>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>

            <div
                style={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row gutter={[8, 16]}>
                    <Col span={12}>
                        <Button
                            disabled={!isModified}
                            size="large"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.cancel();
                            }}
                        >
                            {t('ManualRepairPositionForm.Cancel')}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            disabled={!isModified}
                            size="large"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.confirmWithEquipmentCheck();
                                rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                            }}
                        >
                            {t('ManualRepairPositionForm.Confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
