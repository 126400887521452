export const config = {
    platform: 'DAT',
    tools: ['adjust', 'rotate', 'crop', 'resize', 'watermark', 'shapes', 'image', 'text'],
    translations: {
        en: {
            'toolbar.download': 'Upload',
            'toolbar.image_editor_title': 'Image Editor'
        }
    }
};

export type EditorConfig = typeof config;
