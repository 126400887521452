import React, { FC, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { PositionsFormValues } from '../../types/positions';

import { Table } from '@wedat/ui-kit/components/Table';
import { NumberInputField } from '@wedat/ui-kit/Formik';
import { Container, ButtonsWrapper, ButtonStyled, CancelButton, AmountWrapper } from './styles';

interface Props {
    toggleIsOpen: () => void;
}

export const UserDefinedPositions: FC<Props> = ({ toggleIsOpen }) => {
    const {
        values: { userDefinedPositions, positions },
        setFieldValue
    } = useFormikContext<PositionsFormValues>();
    const [, , positionsHelpers] = useField<DAT2.PositionItaly[]>('positions');
    const { t } = useTranslation();

    const columns = [
        {
            Header: t('common.description'),
            accessor: 'col1'
        },
        {
            Header: t('userDefinedPositions.quantity'),
            accessor: 'col2'
        },
        {
            Header: t('userDefinedPositions.labour'),
            accessor: 'col3'
        }
    ];

    const data = useMemo(
        () =>
            userDefinedPositions.map((item, idx) => ({
                col1: item.Description,
                col2: <NumberInputField width={100} name={`userDefinedPositions[${idx}].Amount`} />,
                col3: <AmountWrapper>{item.WorkTimeReplace}</AmountWrapper>
            })),
        [userDefinedPositions]
    );

    return (
        <Container>
            <Table columns={columns} data={data} />
            <ButtonsWrapper>
                <ButtonStyled
                    onClick={() => {
                        toggleIsOpen();
                        userDefinedPositions.forEach(pos => {
                            if (pos.Amount) {
                                const workTimeReplace = pos.WorkTimeReplace * pos.Amount;
                                positionsHelpers.setValue([
                                    ...positions,
                                    { ...pos, WorkTimeReplace: workTimeReplace } as DAT2.PositionItaly
                                ]);
                            }
                        });
                    }}
                >
                    {t('common.ok')}
                </ButtonStyled>
                <CancelButton
                    onClick={() => {
                        toggleIsOpen();
                        userDefinedPositions.forEach((pos, idx) => {
                            if (pos.Amount) {
                                setFieldValue(`userDefinedPositions[${idx}].Amount`, null);
                            }
                        });
                    }}
                >
                    {t('common.cancel')}
                </CancelButton>
            </ButtonsWrapper>
        </Container>
    );
};
