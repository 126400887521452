import { sample } from 'effector';
import { sharedMailStores } from '@dat/shared-models/mail';
import { sendMailEvents } from '../sendMail';
import { modalsEvents } from './index';

const { successfullySent } = sendMailEvents;

const { isMailOpen } = sharedMailStores;
const { toggleIsContractListOpen, toggleIsContractListCCOpen, toggleIsContractListCCNOpen, toggleIsAttachments } =
    modalsEvents;

sample({
    clock: isMailOpen,
    filter: state => !state,
    target: [
        toggleIsContractListOpen,
        toggleIsContractListCCOpen,
        toggleIsContractListCCNOpen,
        toggleIsAttachments,
        successfullySent
    ]
});
