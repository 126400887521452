// import { parse } from 'fast-xml-parser';
import { convertToArrayIfNot } from '@dat/api2dat/utils/arrayConvert';
import { xmlToObjWithArrayMode } from '@dat/api2dat/utils/xmlConverters';
import { firstLetterToUpperCase } from './firstLetterToUpperCase';
import { DatidInfo, ObjectInfo, ObjectInfoSVG, Title } from '../types/svgGrapaTypes';

// const optionsParse = {
//     attributeNamePrefix: '', // '@_'
//     attrNodeName: 'attr', // default is 'false'
//     textNodeName: '#text',
//     ignoreAttributes: false,
//     ignoreNameSpace: true,
//     allowBooleanAttributes: false,
//     parseNodeValue: true,
//     parseAttributeValue: false,
//     trimValues: true,
//     cdataTagName: '__cdata', // default is 'false'
//     cdataPositionChar: '\\c',
//     localeRange: '', // To support non english character in tag/attribute values.
//     parseTrueNumberOnly: true
//     // attrValueProcessor: a => he.decode(a, {isAttributeValue: true}),//default is a=>a
//     // tagValueProcessor : a => he.decode(a) //default is a=>a
// };

export function parseGroupGraphicFromSVG(assemblyGroupGraphicSVG: string, languageCode = 'ENG') {
    // const assemblyGroupGraphic = parse(assemblyGroupGraphicSVG, optionsParse);

    const assemblyGroupGraphic = xmlToObjWithArrayMode(assemblyGroupGraphicSVG, [
        'svg.g',
        'findedGStandardebene.g',
        'metadata.objectInfo',
        'rcs.rc',
        'langs.lang'
    ]);

    const datidInfo = getDatidInfoFromObj(assemblyGroupGraphic);
    const objectsInfo = getObjectsInfoFromObj(datidInfo, languageCode);

    return { objectsInfo, datidInfo };
}

export function getDatidInfoFromObj(assemblyGroupGraphic: any): DatidInfo[] {
    const datidInfo: DatidInfo[] = [];
    if (assemblyGroupGraphic && assemblyGroupGraphic.svg && assemblyGroupGraphic.svg.g) {
        const g = convertToArrayIfNot(assemblyGroupGraphic.svg.g);
        const findedGStandardebene = g.find(el => el.attr && el.attr.id === 'Standardebene');
        if (findedGStandardebene) {
            convertToArrayIfNot(findedGStandardebene.g).forEach(el => {
                if (el?.metadata && el?.metadata.objectInfo) {
                    const arrayObjectInfo: ObjectInfoSVG[] = convertToArrayIfNot(el.metadata.objectInfo);

                    arrayObjectInfo.forEach(objectInfo => {
                        if (objectInfo.dvnLeft && objectInfo.dvnLeft.rcs.rc)
                            // eslint-disable-next-line no-param-reassign
                            objectInfo.dvnLeft.rcs.rc = convertToArrayIfNot(objectInfo.dvnLeft.rcs.rc);

                        if (objectInfo.dvnRight && objectInfo.dvnRight.rcs.rc)
                            // eslint-disable-next-line no-param-reassign
                            objectInfo.dvnRight.rcs.rc = convertToArrayIfNot(objectInfo.dvnRight.rcs.rc);
                    });

                    datidInfo.push({
                        datid: el.attr.id,
                        class: el.attr.class,
                        objectInfoSVG: arrayObjectInfo
                    });
                }
            });
        }
    }
    return datidInfo;
}

export function getObjectsInfoFromObj(datidInfo: DatidInfo[], languageCode: string): ObjectInfo[] {
    const objectsInfo: ObjectInfo[] = [];

    datidInfo.forEach(el => {
        el.objectInfoSVG.forEach(objectInfo => {
            const findedObjectInfoIndex = objectsInfo.findIndex(
                elObjectInfo =>
                    elObjectInfo.attr && objectInfo.attr && elObjectInfo.attr.position === objectInfo.attr.position
            );

            if (!(findedObjectInfoIndex >= 0) && (!el.class || el.class.slice(0, 5) === 'DATID')) {
                const titleMenu = getTitleLangs(objectInfo.title, languageCode);
                const tooltipLang = objectInfo.tooltip ? getTitleLangs(objectInfo.tooltip, languageCode) : '';
                objectsInfo.push({
                    datid: el.datid,
                    class: el.class,
                    titleMenu,
                    tooltipLang,
                    ...objectInfo
                });
            }

            if (
                findedObjectInfoIndex >= 0 &&
                objectsInfo[findedObjectInfoIndex].datid.slice(0, 9) !==
                    convertPositionToDATID(objectInfo.attr.position) &&
                (!el.class || el.class.slice(0, 5) === 'DATID')
            ) {
                const titleMenu = getTitleLangs(objectInfo.title, languageCode);
                const tooltipLang = objectInfo.tooltip ? getTitleLangs(objectInfo.tooltip, languageCode) : '';
                objectsInfo[findedObjectInfoIndex] = {
                    datid: el.datid,
                    class: el.class,
                    titleMenu,
                    tooltipLang,
                    ...objectInfo
                };
            }
        });
    });
    return objectsInfo;
}

function getTitleLangs(title: Title | undefined, languageCode: string): string {
    if (!title?.langs.lang) return '';
    const findedLang = convertToArrayIfNot(title?.langs.lang).find(el => el.attr.languageCode === languageCode);
    if (findedLang) {
        return firstLetterToUpperCase(findedLang['#text']);
    }
    return '';
}

function convertPositionToDATID(position: any): string {
    const s = `000000000${position}`;
    return `DATID_${s.substr(s.length - 3)}`;
}
