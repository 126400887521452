import { createEffect, createStore } from 'effector';
import _ from 'lodash';

import { API2 } from '@dat/api2';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

const getExistingEquipmentFx = createEffect({
    handler: async (requestData: DAT2.Request.GetExistingEquipmentN) => {
        const response = await cDRH(API2.equipment.getExistingEquipmentN)(requestData);

        return response.VXS.SpecialEquipment;
    }
});

const getVehicleTranslationFx = createEffect({
    handler: async (data: DAT2.Request.GetVehicleTranslation) => {
        const response = await cDRH(API2.equipment.getVehicleTranslation)(data);

        return response.VXS.Dossier.Vehicle.Equipment.SpecialEquipment;
    }
});

// TODO: any
const existingEquipment = createStore<any>([]).on(getExistingEquipmentFx.doneData, (_, data) => {
    // TODO: any
    const positions = data?.EquipmentPosition as any;

    return positions.map((item: any) => item.DatEquipmentId);
});

const vinEquipmentResult = sharedVehicleStores.vinResult.map(vinResult =>
    vinResult
        ? (_.uniq(
              vinResult.vinResultEquipment.filter(item => !!item.datNumber).map(item => item.datNumber)
          ) as string[])
        : []
);

// TODO: any
const vehicleTranslationEquipment = createStore<any>({}).on(getVehicleTranslationFx.doneData, (_, data) => data);

export const sharedEquipmentEffects = {
    getVehicleTranslationFx,
    getExistingEquipmentFx
};

export const sharedEquipmentStores = {
    vehicleTranslationEquipment,
    vinEquipmentResult,
    existingEquipment
};
