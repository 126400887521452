import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstance } from '../../hooks/useInstance';

import { ButtonStyled, Container, SelectButton, MobileIconWrapper, TextStyled } from './styles';
import { Save, Delete } from '@wedat/ui-kit/assets/Icon';

export const Buttons: FC = () => {
    const { t } = useTranslation();
    const {
        readOnly,
        showList,
        networkOwnerId,
        displayedId,
        deleteButtonClicked,
        selectButtonClicked,
        saveButtonClicked,
        isInbox
    } = useInstance('readOnly', 'showList', 'networkOwnerId', 'displayedId', 'isInbox');
    const hasDisplayedSubject = !!displayedId;

    return (
        <Container isInbox={isInbox}>
            {hasDisplayedSubject && showList && !readOnly && !networkOwnerId && (
                <ButtonStyled onClick={deleteButtonClicked}>
                    <MobileIconWrapper>
                        <Delete />
                    </MobileIconWrapper>
                    <TextStyled>{t('buttons.delete')}</TextStyled>
                </ButtonStyled>
            )}
            {showList && !readOnly && !networkOwnerId && (
                <ButtonStyled onClick={saveButtonClicked}>
                    <MobileIconWrapper>
                        <Save />
                    </MobileIconWrapper>
                    <TextStyled>{t('buttons.save')}</TextStyled>
                </ButtonStyled>
            )}
            {!isInbox && <SelectButton onClick={selectButtonClicked}>{t('buttons.select')}</SelectButton>}
        </Container>
    );
};
