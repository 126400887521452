import { sample } from 'effector';
import { vehicleVinOCREvents } from '.';
import { vehicleSelectionModalEffects } from '../../components/VehicleSelectionModal/store';

const { vinOcrScan } = vehicleVinOCREvents;
const { getVehicleByVinFx } = vehicleSelectionModalEffects;

sample({
    clock: vinOcrScan,
    fn: clockData => ({ vin: clockData }),
    target: getVehicleByVinFx
});
