import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import _ from 'lodash';
import { sharedTemplateStores } from '@dat/shared-models/template';

import {
    BaseInfoWrapper,
    CardCarStyled,
    CarOwner,
    IconsContainer,
    IconWrapper,
    IconThreeDotsWrapper,
    ImageWrapper,
    InfoPairHalfed,
    InfoPairRight,
    Information,
    MobileImage,
    NumberPlate,
    ReferenceNumber,
    SlideUpMenuContainer,
    SlideUpMenuItem,
    SlideUpMenuItemTitle,
    SwipeContent,
    SwipePrintout,
    SwipeAttachment
} from './styles';

import { ListItemInternalProps } from '../types';
import { Printout } from '@dat/printout';
import { sharedGalleryEvents } from '@dat/shared-models/gallery';
import { ThreeDots, PrintoutIcon, GalleryIcon } from '@wedat/ui-kit/components/Icons';
import { useToggle } from '@dat/core/hooks/useToggle';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { useTranslation } from 'react-i18next';
import { useSwipe } from '../hooks/useSwipe';
import { EfficiencyWrapper } from '../Desktop/styles';
import { getEfficiencyColor } from '../../../../utils/getEfficiencyColor';
import { pluginStores } from '../../../../stores/plugin';
import { ExpiryDateFormat } from '../../ExpiryDateFormat';
import { EXPIRE_YELLOW_FLAG_DEFAULT } from '../../ExpiryDateFormat/constants';

export const Mobile: React.FC<ListItemInternalProps> = ({ contract, onContractClick, onContractDbClick }) => {
    const {
        id: contractId,
        registrationNumber: numberPlate,
        referenceNumber,
        statusName,
        carOwner,
        templateFieldAndValue
    } = contract;

    const { t } = useTranslation();
    const templates = useStore(sharedTemplateStores.availableTemplates);
    const disabledStatuses = templates.default.settings.disabledStatuses;
    const isDisabled = Boolean(statusName && disabledStatuses?.includes(statusName));
    const [isOpenMenu, toggleOpenMenu] = useToggle(false);
    const [isOpenPrintout, toggleOpenPrintout] = useToggle(false);
    const expireYellowFlag =
        useStore(pluginStores.pluginOptions)?.settings?.efficiency?.expireYellowFlag || EXPIRE_YELLOW_FLAG_DEFAULT;
    const expiryDate = templateFieldAndValue?.Date_expiryDate
        ? new Date(Number(templateFieldAndValue?.Date_expiryDate))
        : undefined;
    const expiryStatus = Number(templateFieldAndValue?.Integer_expiryStatus);

    const cardRef = useRef<HTMLDivElement | null>(null);
    const swipeMainRef = useRef<HTMLDivElement | null>(null);
    const swipeContentRef = useRef<HTMLDivElement | null>(null);
    useSwipe({ containerRef: cardRef, swipeMainRef, swipeContentRef });

    return (
        <>
            <CardCarStyled disabled={isDisabled} ref={cardRef}>
                <BaseInfoWrapper ref={swipeMainRef}>
                    <EfficiencyWrapper
                        color={getEfficiencyColor({
                            date: expiryDate,
                            efficiencyStatus: expiryStatus,
                            expireYellowFlag
                        })}
                    />
                    <ImageWrapper
                        onClick={isDisabled ? _.noop : onContractClick}
                        onDoubleClick={isDisabled ? _.noop : onContractDbClick}
                    >
                        <MobileImage />
                        <NumberPlate font="font12" textTransform="uppercase">
                            {numberPlate || ''}
                        </NumberPlate>
                    </ImageWrapper>
                    <Information>
                        <InfoPairHalfed
                            onClick={isDisabled ? _.noop : onContractClick}
                            onDoubleClick={isDisabled ? _.noop : onContractDbClick}
                        >
                            <ExpiryDateFormat date={expiryDate} efficiencyStatus={expiryStatus} />

                            <CarOwner font="footnote" fontWeight={700} textOverflow="ellipsis">
                                {carOwner && carOwner.trim() ? carOwner : '---'}
                            </CarOwner>

                            <ReferenceNumber font="font12" textOverflow="ellipsis">
                                {referenceNumber || '---'}
                            </ReferenceNumber>
                        </InfoPairHalfed>
                        <InfoPairRight>
                            <IconsContainer>
                                <IconThreeDotsWrapper onClick={toggleOpenMenu} opened={isOpenMenu}>
                                    <ThreeDots />
                                </IconThreeDotsWrapper>
                            </IconsContainer>
                        </InfoPairRight>
                    </Information>
                </BaseInfoWrapper>

                <SwipeContent ref={swipeContentRef}>
                    <SwipePrintout onClick={toggleOpenPrintout}>
                        <IconWrapper>
                            <PrintoutIcon />
                        </IconWrapper>
                    </SwipePrintout>
                    <SwipeAttachment
                        onClick={() => contractId && sharedGalleryEvents.setSelectedContractId(contractId)}
                    >
                        <IconWrapper>
                            <GalleryIcon />
                        </IconWrapper>
                    </SwipeAttachment>
                </SwipeContent>
            </CardCarStyled>

            <SlideUpMenu isOpen={isOpenMenu} closeSlideUpMenu={toggleOpenMenu}>
                <SlideUpMenuContainer>
                    <SlideUpMenuItem onClick={toggleOpenPrintout}>
                        <IconWrapper>
                            <PrintoutIcon />
                        </IconWrapper>
                        <SlideUpMenuItemTitle font="note">{t('slideUp.printout')}</SlideUpMenuItemTitle>
                    </SlideUpMenuItem>
                    <SlideUpMenuItem
                        onClick={() => contractId && sharedGalleryEvents.setSelectedContractId(contractId)}
                    >
                        <IconWrapper>
                            <GalleryIcon />
                        </IconWrapper>
                        <SlideUpMenuItemTitle font="note">{t('slideUp.attachment')}</SlideUpMenuItemTitle>
                    </SlideUpMenuItem>
                </SlideUpMenuContainer>
            </SlideUpMenu>

            <Printout
                options={{
                    contractId,
                    displayTitle: false,
                    isOpen: isOpenPrintout,
                    callbackClose: toggleOpenPrintout,
                    isComponent: true
                }}
            />
        </>
    );
};
