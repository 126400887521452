import React from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveConflicts, ConflictsAction, ConflictsActionItem, ConflictsContainer, StyledText } from './styles';

export interface FooterConflictsProps {
    toggleConflicted: () => void;
    removeSelected: () => void;
    confirmSelected: () => void;
    onlyConflicted: boolean;
    conflictedRowsLength: number;
}

export const FooterConflicts: React.FC<FooterConflictsProps> = ({
    toggleConflicted,
    removeSelected,
    confirmSelected,
    onlyConflicted,
    conflictedRowsLength
}) => {
    const { t } = useTranslation();

    return (
        <ConflictsContainer>
            <ConflictsActionItem onClick={toggleConflicted} conflicted={!onlyConflicted}>
                <StyledText font="note">
                    {t(onlyConflicted ? 'showAll' : 'viewConflictingEquipment', {
                        count: conflictedRowsLength
                    })}
                </StyledText>
            </ConflictsActionItem>

            <ConflictsAction>
                <RemoveConflicts onClick={removeSelected} disabled={!conflictedRowsLength}>
                    <StyledText font="note">{t('removeConflicts')}</StyledText>
                </RemoveConflicts>
                <ConflictsActionItem onClick={confirmSelected} disabled={!conflictedRowsLength}>
                    <StyledText font="note">{t('confirm')}</StyledText>
                </ConflictsActionItem>
            </ConflictsAction>
        </ConflictsContainer>
    );
};
