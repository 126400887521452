import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { getSubjectFromContract } from '../utils/getSubjectFromContract';
import { useInstance } from '../hooks/useInstance';

import { contractStores } from '@dat/shared-models/contract';

export const useSubjectFromContract = () => {
    const contract = useStore(contractStores.contract);

    const { subjectType } = useInstance('subjectType');

    const subjectFromContract = useMemo(() => getSubjectFromContract(contract, subjectType), [contract, subjectType]);

    return subjectFromContract;
};
