import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
    width: 98px;
    margin-left: auto;
`;

export const InfoTableWrapper = styled.div`
    ${media.phoneBig`
        width: 500px;
    `}

    ${media.phoneMedium`
        width: 100%;
    `}
`;
