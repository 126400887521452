import styled from 'styled-components';
import { Button } from '@wedat/ui-kit/components/Button';

export const Message = styled.span`
    line-height: 1.5;
`;

export const PromptButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

const PromptButton = styled(Button)`
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.15s ease;
    color: ${({ theme }) => theme.colors.white};
    margin: 3px;

    &:hover {
        opacity: 0.8;
    }
`;

export const AcceptButton = styled(PromptButton)`
    background: var(--success);

    &:hover,
    &:focus {
        opacity: 0.8;
        background: var(--success);
    }
`;

export const DeclineButton = styled(PromptButton)`
    background: var(--danger);

    &:hover,
    &:focus {
        background: var(--danger);
    }
`;
