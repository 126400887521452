import { createDomain, createEvent, createStore, restore, sample, Store } from 'effector';

import { formikInitialValues } from '../components/Main/formikInitialValues';

import { PluginOptions } from '../types/plugin';
import { apiStores } from '@dat/api2/stores';

const { country } = apiStores;

//
/*** Utils ***/
//
// Function that updates formikInitialValues
const updateFormikInitialValues = (pluginOptions: PluginOptions) => {
    const { inputFields } = pluginOptions;

    if (inputFields) {
        Object.entries(inputFields).forEach(entry => {
            const [name, value] = entry as [string, any];

            if (value === undefined) {
                return;
            }

            switch (name) {
                case 'firstRegistration':
                    formikInitialValues.firstRegistration = new Date(value);
                    break;
                case 'DATCode':
                    formikInitialValues.datECode = value;
                    break;
                case 'VIN':
                    formikInitialValues.vin = value;
                    break;
                case 'NumberPlate':
                    formikInitialValues.numberPlate = value;
                    break;
                case 'MileageOdometer':
                    formikInitialValues.kilometers = value;
                    break;
                case 'freeTextSearch':
                    formikInitialValues.freeTextSearch = value;
                    break;
                case 'paintType':
                    formikInitialValues.paintType = value;
                    break;
                case 'AlternativeModelECode':
                    formikInitialValues.alternativeModelDatECode = value;
                    break;
            }
        });
    }
};

export const domain = createDomain();

//
/*** Init plugin ***/
//
const setStatic = createEvent<boolean>();
const isStatic = createStore<boolean>(false).on(setStatic, (_state, payload) => payload);
const initPlugin = domain.createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

// Init plugin settings and formik values
initPlugin.watch(updateFormikInitialValues);

//
/*** Settings ***/
//
const setCountry = domain.createEvent<DAT2.CountryCode>(); // (test feature)
const settings = domain
    .createStore<Partial<Required<PluginOptions>['settings']>>({
        paintMethod: 'MANUFACTURER_SPECIFIC',
        repairIncomplete: true,
        vehicleImages: true,
        firstRegistrationFilter: true,
        VINQueryAutomatic: false,
        NumberPlateSearchAutomatic: false,
        freeTextSearchAutomatic: false,
        DATECodeDisplayedOnModel: true,
        AZMainModel: false,
        initialEditMode: false
    })
    .on(initPlugin, (prevSettings, { settings }) => ({ ...prevSettings, ...settings }));

// TODO: refactor stores structure
sample({
    clock: initPlugin,
    source: country,
    fn: (country, { settings }): DAT2.CountryCode => settings?.country || country,
    target: country
});

const resetPlugin = domain.createEvent();
const storesIgnoringReset: Store<any>[] = [pluginOptions, settings];

domain.onCreateStore(store => {
    if (!storesIgnoringReset.includes(store)) {
        store.reset(resetPlugin);
    }
});

//
/*** Export ***/
//
export const pluginEvents = {
    initPlugin,
    setCountry,
    resetPlugin,
    setStatic
};
export const pluginStores = {
    pluginOptions,
    settings,
    isStatic
};
