import { useState } from 'react';

let originalLogs = console.log;

export const useDebug = () => {
    const [log, setLog] = useState<string[]>([]);

    console.log = (message, value: any) => {
        setLog(prev => [...prev, `${message}  ${JSON.stringify(value)}`]);
    };

    const flushLogs = () => {
        console.log = originalLogs;
    };

    return {
        flushLogs,
        log
    };
};
