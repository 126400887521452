import { objToXml, xmlToObjWithArrayMode } from './xmlConverters';

//
// for test jest run
//
// @ts-ignore
import fetch from 'node-fetch';
import { Credentials } from '../types/api2datTypes';
import { getTokenWithCache } from '../services/tokenService';
import {
    datGoldServicesUrls,
    datProdServicesUrls,
    defaultGoldPartnerSignature,
    defaultProdPartnerSignature
} from '../baseParameters';

function soapEnvelope(body: string) {
    return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"><soapenv:Header/><soapenv:Body>${body}</soapenv:Body></soapenv:Envelope>`;
}

function getSoapMyClaimTokenService(url?: string) {
    return url?.includes('gold')
        ? datGoldServicesUrls.soapMyClaimTokenService
        : datProdServicesUrls.soapMyClaimTokenService;
}
function getDefaultSignature(url?: string) {
    return url?.includes('gold') ? defaultGoldPartnerSignature : defaultProdPartnerSignature;
}

export async function sendCommonSoapRequest(
    serviceUrl: string,
    methodName: string,
    credentials: Credentials | undefined,
    requestData: object,
    tagNamesWithParent?: string[]
) {
    let usedCredentials = { ...credentials };

    if (!!credentials && 'customerPassword' in credentials && credentials.customerPassword) {
        const resToken = await getTokenWithCache(
            { ...getDefaultSignature(serviceUrl), ...credentials },
            getSoapMyClaimTokenService(serviceUrl)
        );
        if (!resToken) throw new Error(`Тo token received`);
        usedCredentials = { 'dat-authorizationtoken': resToken.token };
    }

    const body = { [methodName]: { ...requestData } };
    const textRequest = soapEnvelope(objToXml(body));

    const options = {
        method: 'POST',
        body: textRequest,
        credentials: 'omit',
        headers: {
            ...usedCredentials,
            language: '' // todo remove when possible, require for calculateContractN
        }
    };

    const result = await fetch(serviceUrl, options);

    const resText = await result.text();

    const resObj = xmlToObjWithArrayMode(resText, tagNamesWithParent);

    if (result.status === 500)
        throw new Error(
            `Response status: ${result.status} faultcode: ${resObj?.Envelope?.Body?.Fault?.faultcode} text: ${resText}`
        );

    if (result.status !== 200) throw new Error(`Response status: ${result.status} text: ${resText}`);

    return resObj;
}
