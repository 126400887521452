import React, { FC, InputHTMLAttributes } from 'react';
import { ToggleContainer, HiddenToggle, StyledToggle, Text } from './styles';

export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    onChangeToggle: (value: boolean) => void;
}

export const Toggle: FC<ToggleProps> = ({ className, checked, label, onChangeToggle, ...rest }) => (
    <ToggleContainer className={className}>
        <HiddenToggle checked={checked} onChange={e => onChangeToggle(e.target.checked)} {...rest} type="checkbox" />
        <StyledToggle checked={checked} />
        {label && <Text checked={checked}>{label}</Text>}
    </ToggleContainer>
);
