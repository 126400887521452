import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: center;

    .react-datepicker__custom-header-years {
        flex: 2;
        margin-right: 10px;
    }

    .react-datepicker__custom-header-months {
        flex: 3;
    }
`;
