import React from 'react';
import {
    GeneralContainer,
    GeneralFieldset,
    GeneralLegend,
    GeneralRow,
    GeneralRowLabel,
    GeneralRowValue
} from './styles';
import { useStore } from 'effector-react/effector-react.cjs';
import { useTranslation } from 'react-i18next';
import { previewStores } from '../../../../stores/preview';
import { EditableValue } from './EditableValue';
import { DateFormat } from '../../DateFormat';
import { pluginStores } from '../../../../stores/plugin';

interface GeneralProps {
    templateData: { [fieldName: string]: string };
}

export const General: React.FC<GeneralProps> = ({ templateData }) => {
    const { t } = useTranslation();
    const groupsConfig = useStore(pluginStores.pluginOptions)?.settings?.preview?.generalSection || [];
    const updatedData = useStore(previewStores.templateData);

    return (
        <GeneralContainer>
            {groupsConfig?.map(({ label, fields }) => (
                <GeneralFieldset key={label}>
                    <GeneralLegend>{t(label)}</GeneralLegend>
                    {fields.map(field => {
                        const { fieldName, editable, label: fieldLabel, type } = field;
                        const value = updatedData[fieldName] || templateData[fieldName] || '';
                        return (
                            <GeneralRow key={fieldName}>
                                <GeneralRowLabel>{t(fieldLabel)}</GeneralRowLabel>
                                <GeneralRowValue>
                                    {editable ? (
                                        <EditableValue value={value} field={field} />
                                    ) : value && type === 'date' ? (
                                        <DateFormat>{new Date(value).toISOString()}</DateFormat>
                                    ) : (
                                        value
                                    )}
                                </GeneralRowValue>
                            </GeneralRow>
                        );
                    })}
                </GeneralFieldset>
            ))}
        </GeneralContainer>
    );
};
