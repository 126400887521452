import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';
import { subscribeEffectsToToast } from '@dat/smart-components/Toast/store';
import { contractEffects } from '@dat/shared-models/contract';

const isLoading = createIsLoadingStore(Object.values(contractEffects));

subscribeEffectsToToast([
    contractEffects.getContractFx,
    contractEffects.updateContractFx,
    contractEffects.calculateContractFx
]);

export const commonStores = {
    isLoading
};
