import { i18n } from 'i18next';
import { getTranslations } from './translationsService';

export async function updateI18n(i18next: i18n, language: string, namespaces: string[]) {
    const backendTranslations = await getTranslations({ language, namespaces });
    Object.entries(backendTranslations.data).map(([namespace, translations]) =>
        Object.entries(translations).forEach(([lng, lngTranslations]) => {
            i18next.addResourceBundle(lng, namespace, lngTranslations, true, true);
        })
    );
}
