import styled from 'styled-components/macro';

export const Container = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10001;
    -webkit-transform: translate3d(0, 0, 0);
`;
