import axios from '../axios';
import { DatProductsUrls, VehicleRepairServices } from '@dat/core/constants/enums';

export const getVehicleImagesN = (values: DAT.GetVehicleImagesNRequest) =>
    axios<DAT.GetVehicleImagesNResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleImagery,
        method: 'getVehicleImagesN',
        values
    });
