import { FC, useRef, useEffect, useState } from 'react';

import { Container, SlideUpMenuStyled, Stub } from './styles';

interface Props {
    isOpen: boolean;
    closeSlideUpMenu: () => void;
    isBurger?: boolean;
    className?: string; // for use in styled(SlideUpMenu)
}

export const SlideUpMenu: FC<Props> = ({ children, isOpen, closeSlideUpMenu, isBurger, className }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [heightContainer, setHeightContainer] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setHeightContainer(containerRef.current.getBoundingClientRect().height);
        }
    }, []);

    return (
        <>
            <Stub onClick={closeSlideUpMenu} isOpen={isOpen} />
            <SlideUpMenuStyled
                className={className}
                isOpen={isOpen}
                heightContainer={heightContainer}
                isBurger={isBurger}
            >
                <Container ref={containerRef}>{children}</Container>
            </SlideUpMenuStyled>
        </>
    );
};
