import { createStore } from 'effector';

import { API2 } from '@dat/api2';
import { createEffectDossier } from '../../utils/createEffectDossier';
import { extractTextValuesFromDossier } from '../../utils/extractTextValuesFromDossier';

const getDossierFx = createEffectDossier(API2.valuateFinance.getDossierN); // for testing
const createDossierFx = createEffectDossier(API2.valuateFinance.createDossierN);

const dossier = createStore<DAT2.Dossier>({});
const dossierWithExtractedTextValues = dossier.map(extractTextValuesFromDossier);

const datECodeEquipment = createStore<DAT2.EquipmentPosition[]>([]);

const specialEquipmentPositions = createStore<DAT2.EquipmentPosition[]>([]);

export const dossierEffects = {
    getDossierFx,
    createDossierFx
};
export const dossierStores = {
    dossier,
    dossierWithExtractedTextValues,
    datECodeEquipment,
    specialEquipmentPositions
};
