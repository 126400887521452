import { createEvent, createStore } from 'effector';

// * events
const setCurrentStepData = createEvent<Record<string, string>>();

// * stores
const currentStepData = createStore<Record<string, string>>({}).on(setCurrentStepData, (state, payload) => ({
    ...state,
    ...payload
}));

export const stepperStores = {
    currentStepData
};
export const stepperEvents = {
    setCurrentStepData
};
