import { DvnMapItem } from '../types/ft/dvnMapType';
import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { findDvnsByDatId } from './findDvnByDatId';
import { findGroupByDvns } from './findGroupByDvns';
import { findDvnWithDescriptionAndGroup } from './ftRepairPositionConverter';

export function getFtDatIdToDvnMap({
    availableAssemblyGroups,
    dvnMap,
    datECode
}: {
    availableAssemblyGroups: AvailableAssemblyGroup[];
    dvnMap: DvnMapItem[];
    datECode: string;
}): { [key: string]: number } {
    const setFtDatId = Array.from(new Set(dvnMap.map(item => item.datId)));

    const result: { [key: string]: number } = {};

    if (!availableAssemblyGroups?.length) return result;

    setFtDatId.forEach(ftDatId => {
        const dvns = findDvnsByDatId(ftDatId, datECode, dvnMap);

        // todo need to be test:
        // const dvn = findDvnWithDescriptionAndGroup(dvns, availableAssemblyGroups);
        // if (!!dvn?.DATProcessId) result[ftDatId] = dvn.DATProcessId;

        const findRes = findGroupByDvns(dvns, availableAssemblyGroups);
        if (findRes) {
            const dvn = findDvnWithDescriptionAndGroup(findRes.currentDVNs, availableAssemblyGroups);
            if (!!dvn?.DATProcessId) result[ftDatId] = dvn.DATProcessId;
        }
    });

    return result;
}

export function getFtDatIdByDvnMap(dvn: number, ftDatIdToDvnMap: { [key: string]: number }) {
    return Object.keys(ftDatIdToDvnMap).find(key => ftDatIdToDvnMap[key] === dvn);
}
