// rimsListModel

import { xmlToObjWithArrayMode } from '@dat/api2dat/utils/xmlConverters';
import { createEvent, restore, sample } from 'effector';
import { RimGrInfo, RimsItem, RimsSVG, SVGwithCoordinates } from '../types/rimsType';
import { splitSVGonSVGParts } from '../utils/splitSVGonSVGParts';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';

const setRimsList = createEvent<RimsItem[] | null>();
const rimsList = restore(setRimsList, []);

sample({
    source: availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore,
    fn: availableAssemblyGroupsStore => {
        const rimGroups = availableAssemblyGroupsStore.availableAssemblyGroups.filter(gr => !gr.datidInfo?.length);

        let result: RimsItem[] = [];

        rimGroups.forEach(rimGroup => {
            const svgLIst = splitSVGonSVGParts(rimGroup?.svg);

            if (!!svgLIst) {
                const { rimGraphicList, rimInfoList } = splitOnGraphicAndInfo(svgLIst);
                result = [...result, ...complianceGraphicAndInfo({ rimGraphicList, rimInfoList })];
            }
        });

        return result;
    },
    target: setRimsList
});

export function splitOnGraphicAndInfo(svgList: SVGwithCoordinates[]) {
    // parse to JSON
    const svgListWithParseInfo = svgList.map(svg => ({
        svg,
        parseInfo: xmlToObjWithArrayMode(svg.svgStr, ['svg.g', 'g.g', 'g.text']) as RimsSVG
    }));

    const svgWithInfo: RimGrInfo[] = svgListWithParseInfo.map(s => ({
        svgWithCoordinates: s.svg,
        rimsInfo: {
            constructionGroup: s.parseInfo.svg.constructionGroup,
            rimInfoG: s.parseInfo.svg.g.find(g => g.attr.id === 'Standardebene')?.g[0]
        }
    }));

    // filter rimGraphicList
    const rimGraphicList = svgWithInfo
        .filter(item => !item.rimsInfo?.rimInfoG?.metadata)
        .map(item => item.svgWithCoordinates);

    // filter rimInfoList
    const rimInfoList = svgWithInfo.filter(item => !!item.rimsInfo?.rimInfoG?.metadata);

    return { rimGraphicList, rimInfoList };
}

export function complianceGraphicAndInfo({
    rimGraphicList,
    rimInfoList
}: {
    rimGraphicList: SVGwithCoordinates[];
    rimInfoList: RimGrInfo[];
}): RimsItem[] {
    const result: RimsItem[] = rimGraphicList.map(rimGr => ({
        svgWithCoordinates: rimGr,
        info: [],
        equipmentIds: []
    }));

    rimInfoList.forEach(rimInfo => {
        // find all rim at the top of rim info
        const topRimGr = rimGraphicList.filter(rimGr => rimInfo.svgWithCoordinates.top > rimGr.bottom);
        // add distance info
        const infoWithDistance2 = topRimGr.map(rimGr => {
            const distance2 =
                Math.pow(rimInfo.svgWithCoordinates.left - rimGr.left, 2) +
                Math.pow(rimInfo.svgWithCoordinates.top - rimGr.top, 2);
            return {
                ...rimGr,
                distance2
            };
        });
        // sort by distance
        const sortedRimGr = infoWithDistance2.sort((a, b) => a.distance2 - b.distance2);

        if (sortedRimGr.length) {
            const foundResItem = result.find(rimItem => rimItem.svgWithCoordinates.index === sortedRimGr[0].index);

            if (foundResItem) {
                foundResItem.info?.push(rimInfo.rimsInfo);

                let equipmentIds: string[] = [];

                let fullStr = ' ';
                rimInfo.rimsInfo.rimInfoG?.text?.forEach(it => (fullStr = fullStr + ' ' + it['#text']));
                equipmentIds = fullStr
                    .replace(/[^\d]/g, ' ')
                    .replace(/ +(?= )/g, '')
                    .trim()
                    .split(' ');

                foundResItem.equipmentIds = [...foundResItem.equipmentIds, ...equipmentIds];
            }
        }
    });

    return result;
}

const eqIdRim = rimsList.map(rimL => {
    const eqIdRim: { id: string; rim: RimsItem }[] = [];

    rimL?.forEach(rim => {
        rim.equipmentIds?.forEach(id => {
            eqIdRim.push({
                id,
                rim
            });
        });
    });

    return eqIdRim;
});

export const rimsListModel = {
    stores: {
        rimsList,
        eqIdRim
    },
    events: {},
    effects: {}
};
