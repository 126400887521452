import { LabourCostParameters } from '../../types/factors';

export const labourCostInitialParameters: LabourCostParameters = {
    mechanicWage1: '',
    mechanicWage2: '',
    mechanicWage3: '',
    bodyWage1: '',
    bodyWage2: '',
    bodyWage3: '',
    electricWage1: '',
    electricWage2: '',
    electricWage3: '',
    dentWage: '',
    dentsCountInProtocol: false,
    isSuppressCavityProtection: false,
    discount: '',
    discountBiw: '',
    labourLumpSum: '',

    _attributes: {
        mode: {
            discount: 'PERCENT',
            discountBiw: 'PERCENT'
        }
    }
};
