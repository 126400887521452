import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { PositionsFormValues } from '../../types/positions';
import { useMedia } from '@dat/core/hooks/useMedia';
import { getTotalItem } from '../../utils/getTotalItem';

import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Table } from '@wedat/ui-kit/components/Table';
import { Card } from '../core/styles';
import { Container } from '../Container';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import { NumberInputField } from '@wedat/ui-kit/Formik';
import { PaintWorkItemsWithDiscount } from '../../constants/positions';
import { Text } from '@wedat/ui-kit/components/Text';
import { FlexWrapper, SizedBox, Info, SumInfo } from './styles';

export const Paintwork: React.FC = props => {
    const {
        values: { paintworkItems }
    } = useFormikContext<PositionsFormValues>();
    const { t } = useTranslation();
    const {
        colors: { dustBlue }
    } = useTheme();
    const isMobile = useMedia(sizes.phoneBig);

    const total = getTotalItem(paintworkItems);

    const headers = useMemo(() => [t('common.designation'), t('paintwork.percentage'), t('common.hours')], [t]);

    const columns = useMemo(
        () =>
            headers.map((item, idx) => ({
                Header: (
                    <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                        {item}
                    </Text>
                ),
                accessor: `col${idx + 1}`
            })),
        [dustBlue, headers]
    );

    const data = useMemo(
        () =>
            paintworkItems.map((item, idx) => {
                const percentageInputCondition =
                    item.Identifier === PaintWorkItemsWithDiscount.allLayers ||
                    item.Identifier === PaintWorkItemsWithDiscount.multiLayer;

                const conditionalFontWeight = paintworkItems.length - 1 === idx ? 700 : 400;

                return {
                    col1: (
                        <Text font="note" fontWeight={conditionalFontWeight} color={dustBlue['900']}>
                            {item.Description}
                        </Text>
                    ),
                    col2: percentageInputCondition ? (
                        <SizedBox>
                            <NumberInputField
                                name={`paintworkItems[${idx}].Percentage`}
                                inputSize="small"
                                borderRadius={4}
                            />
                        </SizedBox>
                    ) : null,
                    col3: (
                        <Text font="note" color={dustBlue['900']} fontWeight={conditionalFontWeight}>
                            <NumberFormat value={item.Hours} />
                        </Text>
                    )
                };
            }),
        [dustBlue, paintworkItems]
    );

    return (
        <Container title={t('paintwork.title')} {...props}>
            {isMobile ? (
                <Card>
                    {total && (
                        <SumInfo key={total.Identifier}>
                            <FlexWrapper>
                                <Text font="note" color={dustBlue['900']} fontWeight={700}>
                                    {total.Description}
                                </Text>
                            </FlexWrapper>
                            <SizedBox>
                                <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                    {t('common.hours')}
                                </Text>
                            </SizedBox>
                            <FlexWrapper>
                                <SizedBox>
                                    <Text font="note" color={dustBlue['900']} fontWeight={700}>
                                        {total.Hours}
                                    </Text>
                                </SizedBox>
                            </FlexWrapper>
                        </SumInfo>
                    )}
                    <FlexWrapper>
                        <SizedBox>
                            <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                {t('common.hours')}
                            </Text>
                        </SizedBox>
                        <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                            {t('paintwork.percentage')}
                        </Text>
                    </FlexWrapper>
                    {paintworkItems.map((item, idx) => {
                        const percentageInputCondition =
                            item.Identifier === PaintWorkItemsWithDiscount.allLayers ||
                            item.Identifier === PaintWorkItemsWithDiscount.multiLayer;

                        return (
                            <Info key={item.Identifier}>
                                <FlexWrapper>
                                    <Text font="note" color={dustBlue['900']}>
                                        {item.Description}
                                    </Text>
                                </FlexWrapper>
                                <FlexWrapper>
                                    <SizedBox>
                                        <Text font="note" color={dustBlue['900']}>
                                            {item.Hours}
                                        </Text>
                                    </SizedBox>
                                    {percentageInputCondition ? (
                                        <NumberInputField
                                            borderRadius={4}
                                            width={100}
                                            name={`paintworkItems[${idx}].Percentage`}
                                        />
                                    ) : null}
                                </FlexWrapper>
                            </Info>
                        );
                    })}
                </Card>
            ) : (
                <Table outlineLast columns={columns} data={data} mobileHeaders />
            )}
        </Container>
    );
};
