import React, { FC, memo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { RepairPosition } from '@dat/api2dat/types/contractTypes';

import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { Dvn, ObjectInfo, RC } from '../../../types/svgGrapaTypes';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { commonModel } from '../../../stores/commonModel';
import { ResultGetDomusTimes } from '../../../services/ItalyAniaTypes2';
import { italyAniaModel2 } from '../../../stores/italyAniaModel2';
import { rightSideDrawerModel } from '../../../stores/rightSideDrawerModel';
import { RepairPositionsList } from '../../RepairPositionsList/RepairPositionsList';
import { AvailableAssemblyGroup } from '../../../types/graphicTypes';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms';
import './preselectPartDamage.css';
import { splitOnTwoParts } from '../../../utils/splitOnTwoParts';
import { ButtonWithMark } from '../../CommonComponents/ButtonWithMark/ButtonWithMark';
import { SelectedElement } from '../../CommonComponents/SelectedElement';

function onClose() {
    graphicDamageSelectionModel.events.updateStore({
        currentAssemblyGroupObject: undefined,
        currentAssemblyGroupObjects: undefined,
        currentDVNs: undefined
    });
    // manualPositionModel2.events.closeManualRepairPositionForm();
}

function setCurrentDVN(dvn?: number, currentAssemblyGroupObject?: ObjectInfo) {
    if (!!dvn && !!currentAssemblyGroupObject) {
        graphicDamageSelectionModel.events.updateStore({ currentDVNs: [dvn], currentAssemblyGroupObject });
        rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
    }
}

// const addReplaceEDamage = RepairPositionsModel.event.addReplaceEDamage;
const addReplaceEDamage = italyAniaModel2.events.addReplace;

export const PreselectPartDamage: FC<any> = memo(
    () => {
        const { currentAssemblyGroup, currentAssemblyGroupObjects } = useStore(
            graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
        );
        const { t } = useTranslation();
        const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
        const isModified = useStore(RepairPositionsModel.store.isModified);
        const isMobile = useStore(commonModel.stores.isMobile);

        if (!currentAssemblyGroupObjects || !currentAssemblyGroup) return null;

        const isRightPresent = !!currentAssemblyGroupObjects.find(
            currentObj =>
                currentObj.dvnRight?.dvn &&
                currentObj.dvnLeft?.dvn &&
                !splitOnTwoParts(currentObj.titleMenu).isSplitalble
        );

        const currentAssemblyGroupObjectsOrdered = [...currentAssemblyGroupObjects];

        currentAssemblyGroupObjectsOrdered.sort(
            (a, b) => (a.dvnLeft?.dvn || a.dvnRight?.dvn || 0) - (b.dvnLeft?.dvn || b.dvnRight?.dvn || 0)
        );

        return (
            <div style={{ margin: '8px 8px 8px 8px' }}>
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Space>
                        <Button
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                // commonModel.events.cancel();
                                rightSideDrawerModel.events.backView();
                            }}
                        />
                    </Space>
                </div>
                <div
                    style={{
                        paddingRight: '4px',
                        position: 'absolute',
                        top: '64px',
                        left: '0px',
                        right: '0px',
                        bottom: '54px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 8]}>
                        {isRightPresent && (
                            <>
                                <Col key="LeftReplace" span={12} style={{ textAlign: 'left' }}>
                                    {t('PartSelectionDialog.LeftReplace')}
                                </Col>
                                <Col key="RightReplace" span={12} style={{ textAlign: 'right' }}>
                                    {t('PartSelectionDialog.RightReplace')}
                                </Col>
                            </>
                        )}

                        {currentAssemblyGroupObjectsOrdered?.map((currentObj, index) => (
                            <Col
                                span={24}
                                key={currentObj.datid + currentObj.dvnLeft?.dvn + currentObj.dvnRight?.dvn + index}
                            >
                                <Row gutter={[8, 16]}>
                                    <SelectPartsButtons
                                        currentObj={currentObj}
                                        repairPositions={repairPositions}
                                        currentAssemblyGroup={currentAssemblyGroup}
                                        isRightPresent={isRightPresent}
                                    />
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        // top: '170px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    commonModel.events.cancel();
                                    rightSideDrawerModel.events.backView();
                                    onClose?.();
                                }}
                            >
                                {t('ManualRepairPositionForm.Cancel')}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (isMobile) rightSideDrawerModel.events.clearHistoryAndClose();
                                    else rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                                    commonModel.events.confirmWithEquipmentCheck();
                                    onClose?.();
                                }}
                            >
                                {t('ManualRepairPositionForm.Confirm')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },

    () => true
);

function isANIADamage(dvn: Dvn | undefined, domusTimes?: ResultGetDomusTimes | null) {
    if (!dvn?.rcs.rc.includes(RC.E)) return false;
    return !!domusTimes?.dvn.find(item => item.dvn === dvn.dvn);
}

function SelectPartsButtons({
    currentObj,
    repairPositions,
    currentAssemblyGroup,
    isRightPresent
}: {
    currentObj: ObjectInfo;
    repairPositions: RepairPosition[];
    currentAssemblyGroup: AvailableAssemblyGroup;
    isRightPresent: boolean;
}) {
    const listDVNsRepPos = repairPositions.map(repPos => repPos.DATProcessId);

    const domusTimes = useStore(italyAniaModel2.stores.resultGetDomusTimes);

    // const { t } = useTranslation();

    const isLeftReplace = !!repairPositions.find(
        repPos => repPos.DATProcessId === currentObj.dvnLeft?.dvn && repPos.RepairType === 'replace'
    );

    const isRightReplace = !!repairPositions.find(
        repPos => repPos.DATProcessId === currentObj.dvnRight?.dvn && repPos.RepairType === 'replace'
    );

    if (!!currentObj.dvnLeft && !!currentObj.dvnRight) {
        const { isSplitalble, leftPart, rightPart } = splitOnTwoParts(currentObj.titleMenu);

        if (isSplitalble) {
            // split parts
            return (
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {currentObj.dvnLeft?.rcs.rc.find(item => item === 'E') ? (
                                    <SelectedElement
                                        style={{ flexGrow: 0 }}
                                        checked={isLeftReplace}
                                        onChange={() => {
                                            if (currentAssemblyGroup) {
                                                if (!isLeftReplace) {
                                                    addReplaceEDamage({
                                                        DATProcessId: currentObj.dvnLeft?.dvn || 0,
                                                        Description: leftPart || '',
                                                        assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                    });
                                                } else {
                                                    RepairPositionsModel.event.delRepairTypeDamage({
                                                        dvn: currentObj.dvnLeft?.dvn || 0,
                                                        repairType: 'replace'
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                ) : (
                                    <div style={{ width: '44px', flexGrow: 0 }} />
                                )}
                                <ButtonWithMark
                                    size="large"
                                    style={{ textAlign: 'left', flexGrow: 1 }}
                                    leftMark={listDVNsRepPos.includes(currentObj.dvnLeft?.dvn)}
                                    onClick={() => setCurrentDVN(currentObj.dvnLeft?.dvn, currentObj)}
                                >
                                    {(isANIADamage(currentObj.dvnLeft, domusTimes) ? '* ' : '') + leftPart}
                                </ButtonWithMark>
                                {isRightPresent && <div style={{ width: '44px', flexGrow: 0 }} />}
                            </div>
                        </Col>

                        <Col span={24}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {currentObj.dvnRight?.rcs.rc.find(item => item === 'E') ? (
                                    <SelectedElement
                                        style={{ flexGrow: 0 }}
                                        checked={isRightReplace}
                                        onChange={() => {
                                            if (currentAssemblyGroup) {
                                                if (!isRightReplace) {
                                                    addReplaceEDamage({
                                                        DATProcessId: currentObj.dvnRight?.dvn || 0,
                                                        Description: rightPart || '',
                                                        assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                    });
                                                } else {
                                                    RepairPositionsModel.event.delRepairTypeDamage({
                                                        dvn: currentObj.dvnRight?.dvn || 0,
                                                        repairType: 'replace'
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                ) : (
                                    <div style={{ width: '44px', flexGrow: 0 }} />
                                )}

                                <ButtonWithMark
                                    size="large"
                                    style={{ textAlign: 'left', flexGrow: 1 }}
                                    leftMark={listDVNsRepPos.includes(currentObj.dvnRight?.dvn)}
                                    onClick={() => setCurrentDVN(currentObj.dvnRight?.dvn, currentObj)}
                                >
                                    {(isANIADamage(currentObj.dvnRight, domusTimes) ? '* ' : '') + rightPart}
                                </ButtonWithMark>
                                {isRightPresent && <div style={{ width: '44px', flexGrow: 0 }} />}
                            </div>
                        </Col>
                    </Row>
                </Col>
            );
        }

        // left and right parts
        return (
            <Col span={24}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            {currentObj.dvnLeft?.rcs.rc.find(item => item === 'E') ? (
                                <SelectedElement
                                    style={{ flexGrow: 0 }}
                                    checked={isLeftReplace}
                                    onChange={() => {
                                        if (currentAssemblyGroup) {
                                            if (!isLeftReplace) {
                                                addReplaceEDamage({
                                                    DATProcessId: currentObj.dvnLeft?.dvn || 0,
                                                    Description: currentObj.titleMenu || '',
                                                    assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                });
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: currentObj.dvnLeft?.dvn || 0,
                                                    repairType: 'replace'
                                                });
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <div style={{ width: '44px', flexGrow: 0 }} />
                            )}

                            <ButtonWithMark
                                size="large"
                                style={{ textAlign: 'left', flexGrow: 1 }}
                                leftMark={listDVNsRepPos.includes(currentObj.dvnLeft?.dvn)}
                                rightMark={listDVNsRepPos.includes(currentObj.dvnRight?.dvn)}
                                onClick={() => setCurrentDVN(currentObj.dvnLeft?.dvn, currentObj)}
                            >
                                {(isANIADamage(currentObj.dvnLeft, domusTimes) ||
                                isANIADamage(currentObj.dvnRight, domusTimes)
                                    ? '* '
                                    : '') + currentObj.titleMenu}
                            </ButtonWithMark>

                            {currentObj.dvnRight?.rcs.rc.find(item => item === 'E') ? (
                                <SelectedElement
                                    style={{ flexGrow: 0 }}
                                    checked={isRightReplace}
                                    onChange={() => {
                                        if (currentAssemblyGroup) {
                                            if (!isRightReplace) {
                                                addReplaceEDamage({
                                                    DATProcessId: currentObj.dvnRight?.dvn || 0,
                                                    Description: currentObj.titleMenu || '',
                                                    assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                });
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: currentObj.dvnRight?.dvn || 0,
                                                    repairType: 'replace'
                                                });
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                <div style={{ width: '44px', flexGrow: 0 }} />
                            )}
                        </div>
                    </Col>
                </Row>
            </Col>
        );
    }

    // single parts
    return (
        <Col span={24}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {(currentObj.dvnLeft || currentObj.dvnRight)?.rcs.rc.find(item => item === 'E') ? (
                    <SelectedElement
                        style={{ flexGrow: 0 }}
                        checked={isLeftReplace || isRightReplace}
                        onChange={() => {
                            if (currentAssemblyGroup) {
                                if (!(isLeftReplace || isRightReplace)) {
                                    addReplaceEDamage({
                                        DATProcessId: currentObj.dvnLeft?.dvn || currentObj.dvnRight?.dvn || 0,
                                        Description: currentObj.titleMenu || '',
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup
                                    });
                                } else {
                                    RepairPositionsModel.event.delRepairTypeDamage({
                                        dvn: currentObj.dvnLeft?.dvn || currentObj.dvnRight?.dvn || 0,
                                        repairType: 'replace'
                                    });
                                }
                            }
                        }}
                    />
                ) : (
                    <div style={{ width: '44px', flexGrow: 0 }} />
                )}
                <ButtonWithMark
                    size="large"
                    style={{ textAlign: 'left', flexGrow: 1 }}
                    leftMark={listDVNsRepPos.includes(currentObj.dvnLeft?.dvn || currentObj.dvnRight?.dvn || -1)}
                    onClick={() => setCurrentDVN(currentObj.dvnLeft?.dvn || currentObj.dvnRight?.dvn, currentObj)}
                >
                    {(isANIADamage(currentObj.dvnLeft || currentObj.dvnRight, domusTimes) ? '* ' : '') +
                        currentObj.titleMenu}
                </ButtonWithMark>
                {isRightPresent && <div style={{ width: '44px', flexGrow: 0 }} />}
            </div>
        </Col>
    );
}
