import * as R from 'ramda';
import {
    AllInstances,
    PayloadForAddInstance,
    PayloadForAddSubjectToInstance,
    PayloadForDeleteSubjectFromInstance,
    PayloadForUpdateInstance,
    PayloadForUpdateSubjectOfInstance
} from '../types';

export const addInstance = (instances: AllInstances, instance: PayloadForAddInstance) => ({
    ...instances,
    [instance.instanceId]: instance
});

export const updateInstance = (instances: AllInstances, { instanceId, properties }: PayloadForUpdateInstance) => {
    if (instanceId in instances) {
        return { ...instances, [instanceId]: { ...instances[instanceId], ...properties } };
    }
};

export const destroyInstance = (instances: AllInstances, instanceId: string) => {
    if (instanceId in instances) {
        return R.omit([instanceId], instances);
    }
};

export const addSubjectToInstance = (
    instances: AllInstances,
    { instanceId, subject }: PayloadForAddSubjectToInstance
) => {
    if (instanceId in instances) {
        const currentInstance = instances[instanceId];
        let { currentSubjects } = currentInstance;

        currentSubjects = [...currentSubjects, subject];

        return { ...instances, [instanceId]: { ...currentInstance, currentSubjects } };
    }
};

export const updateSubjectOfInstance = (
    instances: AllInstances,
    { instanceId, subject }: PayloadForUpdateSubjectOfInstance
) => {
    if (instanceId in instances) {
        const currentInstance = instances[instanceId];
        let { currentSubjects } = currentInstance;
        const subjectIndex = currentSubjects.findIndex(({ _id }) => _id === subject._id);

        // TODO: investigate why is there a possibility of subjectIndex being equal to -1
        if (subjectIndex === -1) {
            return undefined;
        }

        currentSubjects = [...currentSubjects];
        currentSubjects[subjectIndex] = subject;

        return { ...instances, [instanceId]: { ...currentInstance, currentSubjects } };
    }
};

export const deleteSubjectFromInstance = (
    instances: AllInstances,
    { instanceId, index }: PayloadForDeleteSubjectFromInstance
) => {
    if (instanceId in instances) {
        const currentInstance = instances[instanceId];
        let { currentSubjects } = currentInstance;

        currentSubjects = [...currentSubjects];
        currentSubjects.splice(index, 1);

        return { ...instances, [instanceId]: { ...currentInstance, currentSubjects } };
    }
};
