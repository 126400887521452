import { createEvent, createStore, createEffect, guard, sample, restore } from 'effector';
// import { createEvent, createStore, createEffect, guard } from 'effector-logger/macro';

import * as comlink from 'comlink';

import {
    AvailableAssemblyGroup,
    AvailableAssemblyGroupsStore,
    DatECodeMaterialInfo,
    DownloadingState
} from '../types/graphicTypes';
import grapaVehicleRepairService, { GrapaVehicleRepairService } from '../services/grapaVehicleRepairService.worker';
import { Credentials, Locale } from '@dat/api2dat/types/api2datTypes';

import { pluginOptionsModel } from './pluginOptionsModel';

// Instantiate worker
const grapaVehicleRepairServiceWorkerInstance: Worker = new grapaVehicleRepairService();
const grapaVehicleRepairServiceApi = comlink.wrap<GrapaVehicleRepairService>(grapaVehicleRepairServiceWorkerInstance);

const updateStore = createEvent<Partial<AvailableAssemblyGroupsStore>>();
const updateStoreGroupData = createEvent<{ groupData: AvailableAssemblyGroup }>();

grapaVehicleRepairServiceApi.initCallbackUpdateStore(comlink.proxy(updateStore));
grapaVehicleRepairServiceApi.initCallbackUpdateStoreGroupData(comlink.proxy(updateStoreGroupData));

const datECode = pluginOptionsModel.stores.pluginOptions.map(
    options => options?.settings?.contract?.Dossier?.Vehicle?.DatECode?.replace(/[^0-9]/g, '') || ''
);

const initStateAvailableAssemblyGroupsStore: AvailableAssemblyGroupsStore = {
    vehicleType: 0,
    manufacturer: 0,
    mainType: 0,
    locale: {},
    startDownloading: undefined,
    availableDate: undefined,
    availableAssemblyGroups: []
};

const _availableAssemblyGroupsStore = createStore<AvailableAssemblyGroupsStore>(initStateAvailableAssemblyGroupsStore);

interface InitList {
    vehicleType: number;
    manufacturer: number;
    mainType: number;
    credentials: Credentials;
    locale: Locale;
    languageCode: string;
    url?: string;
}

// const initList = createEffect({
//     async handler({ vehicleType, manufacturer, mainType, credentials, locale, languageCode, url }: InitList) {
//         await grapaVehicleRepairServiceApi.getAssemblyGroupsWithGrphic({
//             vehicleType,
//             manufacturer,
//             mainType,
//             credentials,
//             locale,
//             languageCode,
//             url
//         });
//     }
// });

const initList = createEffect(grapaVehicleRepairServiceApi.getAssemblyGroupsWithGrphic);

const initListStartFirst = createEvent<InitList>();
guard({
    source: initListStartFirst,
    filter: initList.pending.map(pending => !pending),
    target: initList
});

_availableAssemblyGroupsStore
    .on(updateStore, (oldState: AvailableAssemblyGroupsStore, params: any) => ({ ...oldState, ...params }))
    .on(updateStoreGroupData, (oldState: AvailableAssemblyGroupsStore, { groupData }: any) => {
        if (!oldState.availableAssemblyGroups) return;
        const newAvailableAssemblyGroups = [...oldState.availableAssemblyGroups];

        const foundIndex = newAvailableAssemblyGroups.findIndex(
            el => el.assemblyGroup.assemblyGroupId === groupData.assemblyGroup.assemblyGroupId
        );

        if (foundIndex >= 0) {
            newAvailableAssemblyGroups[foundIndex] = { ...newAvailableAssemblyGroups[foundIndex], ...groupData };
        } else if (groupData.assemblyGroupId) {
            newAvailableAssemblyGroups.push({ ...groupData });
        }

        return { ...oldState, availableAssemblyGroups: newAvailableAssemblyGroups };
    });

// ========================= throttle for _availableAssemblyGroupsStore ===========================
const setAvailableAssemblyGroupsStore = createEvent<AvailableAssemblyGroupsStore>();
const availableAssemblyGroupsStore = restore<AvailableAssemblyGroupsStore>(
    setAvailableAssemblyGroupsStore,
    initStateAvailableAssemblyGroupsStore
);
const timeout = 200;
const timer = createEffect(
    async () =>
        new Promise(resolve => {
            setTimeout(resolve, timeout);
        })
);
guard({
    source: _availableAssemblyGroupsStore,
    filter: timer.pending.map(is => !is),
    target: timer
});
sample({
    source: _availableAssemblyGroupsStore,
    clock: timer.done,
    target: setAvailableAssemblyGroupsStore
});
// ================================================================================================

const downloadingState = availableAssemblyGroupsStore.map<DownloadingState>(availableAssemblyGroupsStore =>
    availableAssemblyGroupsStore?.availableAssemblyGroups
        ? availableAssemblyGroupsStore.availableAssemblyGroups.reduce(
              (accumulator, currentValue) => ({
                  availableNumber: accumulator.availableNumber + (currentValue.availableDate ? 1 : 0),
                  downloadedSize: accumulator.downloadedSize + currentValue.svg.length
              }),
              { availableNumber: 0, downloadedSize: 0 }
          )
        : { availableNumber: 0, downloadedSize: 0 }
);

const setMaterialList = createEvent<DatECodeMaterialInfo>();
const materialList = restore<DatECodeMaterialInfo>(setMaterialList, {
    fza: 0,
    hst: 0,
    ht: 0,
    dvnMaterialList: {}
});

export const availableAssemblyGroupsModel = {
    stores: {
        datECode,
        availableAssemblyGroupsStore,
        downloadingState,
        materialList
    },
    events: {
        setAvailableAssemblyGroupsStore,
        initList,
        initListStartFirst,
        setMaterialList
    }
};
