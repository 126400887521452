import { InputProps } from './types';
import * as Icon from '../../assets/Icon';
import { useMemo, useState, useCallback } from 'react';
import { ClickableIcon } from './styles';

export const useIconComponent = (status: InputProps['status'], inputType?: string) => {
    const [passwordInputType, setPasswordInputType] = useState<string>('password');
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const eyeViewHandler = useCallback(() => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text');
            setIsPasswordShown(true);
        } else {
            setPasswordInputType('password');
            setIsPasswordShown(false);
        }
    }, [passwordInputType, setIsPasswordShown]);

    const IconComponent = useMemo(() => {
        if (status === 'error') {
            return <Icon.ErrorMessage />;
        }

        if (status === 'success') {
            return <Icon.SuccessMessage />;
        }

        if (inputType === 'password') {
            return (
                <ClickableIcon onClick={eyeViewHandler}>
                    {isPasswordShown ? <Icon.OpendEye /> : <Icon.ClosedEye />}
                </ClickableIcon>
            );
        }

        return null;
    }, [status, inputType, eyeViewHandler, isPasswordShown]);

    return { IconComponent, passwordInputType };
};
