import { FC, useCallback, useContext, useState } from 'react';
import { CommentPhoto } from '../PhotoStepsPage/CommentPhoto';
import { Photo } from '../PhotoStepsPage/Photo';
import { PluginStep } from '../../types';
import { FormContext, actions } from '../../reducers/form';
import { Layout } from '../Layout';

const { setRetakePhoto } = actions;

interface Props {
    steps: PluginStep[];
}

enum LocalSteps {
    MakePhoto,
    CommentPhoto
}

export const RetakePhotoStep: FC<Props> = ({ steps }) => {
    const {
        state: { resultForm, retakeIndex },
        dispatch
    } = useContext(FormContext);
    const step = steps[retakeIndex];
    const formStep = resultForm.steps[retakeIndex];
    const [photo, setPhoto] = useState(formStep.photo);
    const [photoState, setPhotoState] = useState<LocalSteps>(LocalSteps.CommentPhoto);

    const handlePhoto = useCallback(
        (newPhoto: string) => {
            setPhotoState(LocalSteps.CommentPhoto);

            setPhoto(newPhoto);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [photo]
    );

    const handleRetake = useCallback(() => {
        setPhotoState(LocalSteps.MakePhoto);
    }, []);

    const handleSave = useCallback(
        (comment: string) => {
            dispatch(setRetakePhoto(photo, comment));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [photo]
    );

    return (
        <Layout forceLandscape={step.forceLandscape}>
            {photoState === LocalSteps.MakePhoto ? (
                <Photo embedGeo={step.embedGeo} maskImg={step.mask?.img} onPhoto={handlePhoto} />
            ) : (
                <CommentPhoto
                    comment={formStep.comment}
                    img={photo}
                    // showComment={step.isShowComment}
                    onRetake={handleRetake}
                    onSave={handleSave}
                />
            )}
        </Layout>
    );
};
