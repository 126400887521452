/*
 * Receives "value" of type DAT.SingleOrArray
 * and returns array whether value is single or array.
 * If value is nullable, an empty array is returned.
 */
export const getParsedArraySafe = <T extends any>(value: DAT2.SingleOrArray<T>): Array<NonNullable<T>> => {
    if (value === undefined || value === null || value === '') {
        return [];
    } else if (Array.isArray(value)) {
        return [...value] as Array<NonNullable<T>>;
    } else {
        return [value] as Array<NonNullable<T>>;
    }
};
