import { attach, sample } from 'effector';
import _ from 'lodash';

import { toastEffects } from '@dat/smart-components/Toast/store';
import { positionsEffects, positionsStores, positionsEvents } from './index';
import { contractEffects, contractEvents, contractStores } from '@dat/shared-models/contract';
import { createRepairPositionsUpdate } from '../../utils/createRepairPositionsUpdate';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { parsePositions } from '../../utils/parsePositions';
import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';
import { PayloadForSavePositions, PayloadForWorkBench } from './types';
import { INITIAL_POSITION } from '../../constants/positions';
import {
    AGGREGATE_PART_CODE_ITALY,
    AGGREGATE_POSITION_ENTRY_TYPE,
    PositionsTypesValues
} from '../../constants/positionFieldsValues';
import {
    getTotalSumsItemsForUpdate,
    getLabourItemsForUpdate,
    getPaintWorkItemsForUpdate,
    findRepairPositions,
    getWorkLogicForUpdate
} from './utils';
import { commonEvents } from '../common';

const { initialFormPositions, deletedPositions, deletedPositionsIndexes } = positionsStores;
const { collectDeletedPositions, collectDeletedPositionsIndexes } = positionsEvents;
const { savePositionsFx, addWorkBenchFx, saveWorkBenchFx, saveWorkLogicFx } = positionsEffects;
const { updateCurrentContractFx, calculateCurrentContractFx, createOrUpdateContractFx } = contractEffects;
const { setIsGettingContractAfterUpdateEnabled } = contractEvents;
const { contractId } = contractStores;
const { positionsItaly } = sharedCalcResultItalyStores;
const { repairPositions, materialPositions } = sharedRepairCalculationStores;

sample({
    clock: positionsItaly,
    source: { positionsItaly, repairPositions },
    fn: ({ positionsItaly, repairPositions }): DAT2.PositionItaly[] => {
        const arr = [...positionsItaly];

        const updated: DAT2.PositionItaly[] = arr
            .map(position => {
                const repPositions = findRepairPositions(position, repairPositions);
                if (!position.WorkTimeReplace) {
                    position.WorkTimeReplace = repPositions.find(
                        repPos => repPos.RepairType === 'dis- and mounting'
                    )?.WorkTime;
                }
                if (!position.WorkTimeLacquer) {
                    position.WorkTimeLacquer = repPositions.find(repPos => repPos.RepairType === 'lacquer')?.WorkTime;
                }
                return { ...INITIAL_POSITION, ...position };
            })
            .sort(({ Position: a = 0 }, { Position: b = 0 }) => a - b);

        const positionsWithHiddenOnes: DAT2.PositionItaly[] = updated.map(pos => {
            let newPosition: DAT2.PositionItaly = { ...pos };
            const arr = ['WorkTimeLacquer', 'WorkTimeReplace', 'WorkTimeOverhaul', 'WorkTimeMechanic'];

            for (let item in newPosition) {
                const typedItem = item as keyof DAT2.PositionItaly;
                const isFieldEmpty = newPosition[typedItem] === 0 || newPosition[typedItem] === undefined;
                if (arr.includes(typedItem) && isFieldEmpty) {
                    newPosition = {
                        ...newPosition,
                        [typedItem]: ''
                    };
                }
            }

            return newPosition;
        });

        const positionsWithAppliedDiscount: DAT2.PositionItaly[] = positionsWithHiddenOnes.map(pos => {
            if (pos.PartDiscountPerc && (pos.PartDiscountType === '' || pos.PartDiscountType === undefined)) {
                return { ...pos, PartDiscountType: 'S' };
            }

            return pos;
        });

        // TODO: DATG-7057614
        // DAT might fix it. The issue is that when we save manual position with description
        // but without price after calculation it isn't in PositionsItaly array, so we just take it from RepairPositions
        const positionsWithoutPrice = repairPositions.filter(
            pos => pos.DATProcessId === 0 && pos.SparePartPrice === 0 && pos.RepairType === 'comment'
        );
        const convertedPositionsWithoutPrice: DAT2.PositionItaly[] = positionsWithoutPrice.map(item => ({
            ...INITIAL_POSITION,
            DATProcessId: item.DATProcessId,
            Description: item.Description
        }));

        return [...positionsWithAppliedDiscount, ...convertedPositionsWithoutPrice];
    },
    target: initialFormPositions
});

deletedPositions
    .on(collectDeletedPositions, (state, payload) => [...state, ...payload])
    .on(savePositionsFx.done, (_state, _payload) => []);

deletedPositionsIndexes
    .on(collectDeletedPositionsIndexes, (_state, payload) => payload)
    .on(savePositionsFx.done, (_state, _payload) => []);

savePositionsFx.use(
    attach({
        source: [repairPositions, deletedPositions, deletedPositionsIndexes, materialPositions, contractId],
        effect: async (
            [initialRepairPositions, deletedPositions, deletedPositionsIndexes, materialPositions, contractId],
            {
                aggregateComponents,
                positions,
                labourItems,
                totalSumsItems,
                paintworkItems,
                initialFormPositions
            }: PayloadForSavePositions
        ) => {
            try {
                commonEvents.setIsLoading(true);
                const graphicalPositions: DAT2.PositionItaly[] = [];
                const manualPositions: DAT2.PositionItaly[] = [];
                const aggregatePositions: DAT2.PositionItaly[] = [];

                const updatedInitialFormPositions = initialFormPositions.filter(
                    (_pos, idx) => !deletedPositionsIndexes.includes(idx)
                );

                const parsedPositions = parsePositions(positions);

                parsedPositions.forEach((position, idx) => {
                    const {
                        DATProcessId,
                        WorkTimeLacquer,
                        WorkTimeMechanic,
                        WorkTimeOverhaul,
                        WorkTimeReplace,
                        PartCodeItaly
                    } = position;
                    const isAggregate = position.Type === PositionsTypesValues.aggregate;
                    const isManual = !DATProcessId && PartCodeItaly !== AGGREGATE_PART_CODE_ITALY && !isAggregate;

                    const conditionalDescription =
                        updatedInitialFormPositions[idx]?.Description === position.Description ||
                        position.Description?.startsWith('#')
                            ? position.Description
                            : `# ${position.Description}`;

                    if (isManual) {
                        const isSingle = WorkTimeLacquer || WorkTimeMechanic || WorkTimeOverhaul || WorkTimeReplace;

                        const isSingleCondition = isSingle ? PositionsTypesValues.single : PositionsTypesValues.comment;

                        manualPositions.push({
                            ...position,
                            Type: position.Type ?? isSingleCondition,
                            Amount: position.Amount || 1,
                            Description: position.Description
                        });
                    } else if (isAggregate) {
                        aggregatePositions.push({
                            ...position,
                            PositionEntryType: AGGREGATE_POSITION_ENTRY_TYPE,
                            Description: conditionalDescription
                        });
                    } else {
                        graphicalPositions.push({
                            ...position,
                            Description: conditionalDescription,
                            PartDiscountType: position.PartDiscountType,
                            PartDiscountAsterisk: position.PartDiscountAsterisk ? '*' : 0,
                            PartNumber: position.PartNumber,
                            PartDiscountPerc: position.PartDiscountPerc
                        });
                    }
                });

                let hasDuplicateNames = false;

                manualPositions.forEach((item, itemIdx) =>
                    manualPositions.forEach((elem, elemIdx) => {
                        if (elem.Description === item.Description && itemIdx !== elemIdx) {
                            hasDuplicateNames = true;
                        }
                    })
                );

                const manualPositionWithoutDescription = manualPositions.some(item => !item.Description);
                if (hasDuplicateNames) {
                    throw Error('alert.errorSameName');
                } else if (manualPositionWithoutDescription) {
                    throw Error('alert.errorNoName');
                } else {
                    const updatedGraphicalPositions: DAT2.SingleOrArray<DAT2.RepairPosition> | undefined =
                        createRepairPositionsUpdate({
                            positionsItaly: graphicalPositions,
                            initialRepairPositions,
                            updatedInitialFormPositions
                        });

                    const updatedAggregatePositions: DAT2.SingleOrArray<DAT2.RepairPosition> | undefined =
                        createRepairPositionsUpdate({
                            positionsItaly: aggregatePositions,
                            initialRepairPositions,
                            fromAggregate: true
                        });

                    const updatedManualPositions: DAT2.SingleOrArray<DAT2.RepairPosition> | undefined =
                        createRepairPositionsUpdate({
                            positionsItaly: manualPositions,
                            initialRepairPositions,
                            fromManual: true
                        });

                    // shouldn't get contract after this update because contract is fetched after `calculateCurrentContractFx`
                    setIsGettingContractAfterUpdateEnabled(false);

                    const paintWorkItemsForUpdate: DAT2.ProcedureRelatedParameter[] =
                        getPaintWorkItemsForUpdate(paintworkItems);

                    const labourItemsForUpdate: DAT2.ProcedureRelatedParameter[] = getLabourItemsForUpdate(labourItems);

                    const totalSumsItemsForUpdate: DAT2.ProcedureRelatedParameter[] =
                        getTotalSumsItemsForUpdate(totalSumsItems);

                    const updatedRepairPositions = [
                        ...updatedGraphicalPositions,
                        ...updatedAggregatePositions,
                        ...updatedManualPositions
                    ];
                    const italianIncludedMaterialPositions: DAT2.MaterialPosition[] = [];
                    materialPositions.forEach(mpos => {
                        positions.forEach(pos => {
                            if (pos.DATProcessId === mpos.DATProcessId) {
                                italianIncludedMaterialPositions.push(mpos);
                            }
                        });
                    });
                    const filteredItalianIncludedMaterialPositions = italianIncludedMaterialPositions.filter(mpos => {
                        const foundPosition = deletedPositions.find(
                            dpos =>
                                mpos.RequiredByProcessId === dpos.DATProcessId &&
                                dpos.Type !== PositionsTypesValues.aggregateComponent
                        );

                        return typeof foundPosition === 'object';
                    });
                    const shouldBeDeletedMaterialsIds = filteredItalianIncludedMaterialPositions.map(
                        pos => pos.DATProcessId
                    );

                    // deleting aggregates
                    const deletedAggregateComponents = deletedPositions.filter(
                        position => position.Type === PositionsTypesValues.aggregateComponent
                    );
                    const deletedAggregateComponentsIds = deletedAggregateComponents.map(
                        position => position.DATProcessId
                    );
                    const arr: DAT2.PositionItaly[] = [];
                    if (aggregateComponents?.length) {
                        aggregateComponents.forEach(position =>
                            deletedAggregateComponentsIds.forEach(id => {
                                if (position.DATProcessId === id) {
                                    arr.push(position);
                                }
                            })
                        );
                    }
                    const aggregateComponentsForDeleteCall = _.uniqWith(arr, _.isEqual);

                    const deletedPositionsForUpdate = createRepairPositionsUpdate({
                        positionsItaly: aggregateComponentsForDeleteCall,
                        initialRepairPositions
                    })
                        .filter(pos => !!pos.Description)
                        .map(pos => ({ ...pos, RepairType: 'replace' }));

                    const removedRepairPositions = initialRepairPositions.filter(
                        pos => pos.PositionEntryType === 'removed'
                    );

                    const repairPositionsForRemoval: DAT2.RepairPosition[] = [
                        ...deletedPositionsForUpdate,
                        ...removedRepairPositions
                    ].map(position => ({
                        ...position,
                        PositionEntryType: 'removed',
                        DATProcessId: 0,
                        WorkTime: 0
                    }));

                    const repairPositionsResult = updatedRepairPositions
                        .filter(
                            pos =>
                                !shouldBeDeletedMaterialsIds.includes(pos.DATProcessId) &&
                                !repairPositionsForRemoval.find(p => p.Description === pos.Description)
                        )
                        .map(pos => ({ ...pos, IsManualDescription: true }));

                    await createOrUpdateContractFx({
                        contractId,
                        Dossier: {
                            RepairCalculation: {
                                RepairPositions: {
                                    RepairPosition: [...repairPositionsResult, ...repairPositionsForRemoval]
                                },
                                ProcedureRelatedParameters: {
                                    ProcedureRelatedParameter: [
                                        ...labourItemsForUpdate,
                                        ...totalSumsItemsForUpdate,
                                        ...paintWorkItemsForUpdate
                                    ]
                                }
                            }
                        }
                    });
                    await calculateCurrentContractFx();
                    setIsGettingContractAfterUpdateEnabled(true);
                    commonEvents.setIsLoading(false);
                }
            } catch (e: any) {
                toastEffects.showErrorToastFx({
                    message: {
                        namespace: 'italian-calculation',
                        key: e.message
                    },
                    toastId: 'savePositionsFx-error'
                });
                commonEvents.setIsLoading(false);
            }
        }
    })
);

interface SaveWorkBenchParams {
    workBench: PayloadForWorkBench;
    repairPositions: DAT2.RepairPosition[];
}

saveWorkBenchFx.use(
    attach({
        source: [repairPositions],
        effect: async (
            [initialRepairPositions],
            { workBench: { contractId, positionsItaly }, repairPositions }: SaveWorkBenchParams
        ) => {
            await addWorkBenchFx({ contractId, positionsItaly });
            if (repairPositions.length) {
                await updateCurrentContractFx({
                    Dossier: {
                        RepairCalculation: {
                            RepairPositions: {
                                RepairPosition: [...initialRepairPositions, ...repairPositions]
                            }
                        }
                    }
                });
            }
        }
    })
);

saveWorkLogicFx.use(
    attach({
        source: [repairPositions],
        effect: async ([repairPositions], { workLogicFormValues }) => {
            const { parameters, repairPosition } = getWorkLogicForUpdate(workLogicFormValues);

            await updateCurrentContractFx({
                Dossier: {
                    RepairCalculation: {
                        RepairPositions: {
                            RepairPosition: [...repairPositions, repairPosition]
                        },
                        ProcedureRelatedParameters: {
                            ProcedureRelatedParameter: [...parameters]
                        }
                    }
                }
            });
            await calculateCurrentContractFx();
        }
    })
);

// TODO: Contract should be calculated every time RepairPositions are changed. Maybe should call calculateCurrentContractFx when repairPositions store in shared-models is updated, instead of this sample. Also there is similar sample in claim-management/stores/contract/init
sample({
    clock: [saveWorkBenchFx.doneData],
    fn: _.noop,
    target: calculateCurrentContractFx
});
