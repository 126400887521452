import { sendCommonSoapRequest } from '../utils/soapRequest';
import { convertToArrayIfNot } from '../utils/arrayConvert';
// import { decodeXml } from '../utils/xmlSpecialCharacters';

import { Credentials, GetEquipmentNParams, PossibleEquipment } from '../types/api2datTypes';
import { datProdServicesUrls } from '../baseParameters';

// ConversionFunctionsService
// https://www.dat.de/myClaim/soap/v2/VehicleRepairService?wsdl

const urlConversionFunctionsService =
    datProdServicesUrls.urlConversionFunctionsService ||
    'https://www.dat.de/myClaim/soap/v2/ConversionFunctionsService';

export async function getPossibleEquipmentN({
    params,
    credentials,
    url
}: {
    params: GetEquipmentNParams;
    credentials: Credentials;
    url?: string;
}): Promise<PossibleEquipment[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlConversionFunctionsService,
        'getPossibleEquipmentN',
        credentials,
        { request: params }
    );

    const result: PossibleEquipment[] = convertToArrayIfNot(
        SoapResult.Envelope.Body.getPossibleEquipmentNResponse.VXS.SpecialEquipment.EquipmentPosition
    );

    result.forEach(item => {
        if (item.ContainedEquipmentPositions)
            item.ContainedEquipmentPositions.EquipmentPosition = convertToArrayIfNot(
                item.ContainedEquipmentPositions?.EquipmentPosition
            );
    });

    return result;
}

export async function getExistingEquipmentN({
    credentials,
    params,
    url
}: {
    params: GetEquipmentNParams;
    credentials: Credentials;
    url?: string;
}): Promise<PossibleEquipment[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlConversionFunctionsService,
        'getExistingEquipmentN',
        credentials,
        { request: params }
    );

    const result: PossibleEquipment[] = convertToArrayIfNot(
        SoapResult.Envelope.Body.getExistingEquipmentNResponse.VXS.SeriesEquipment.EquipmentPosition
    );

    result.forEach(item => {
        if (item.ContainedEquipmentPositions?.EquipmentPosition)
            item.ContainedEquipmentPositions.EquipmentPosition = convertToArrayIfNot(
                item.ContainedEquipmentPositions.EquipmentPosition
            );
    });

    return result;
}
