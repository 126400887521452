import deepmerge from 'deepmerge';

import { ManualFields, ManualFieldsNS } from '../types/manualFields';

const price: ManualFieldsNS.Price = {
    Valuation: {
        DisplayGross: false
    }
};

const basicData: ManualFieldsNS.BasicData = {
    Valuation: {
        DeterminatedDate: new Date()
    },
    Vehicle: {
        InitialRegistration: undefined,
        MileageEstimated: undefined
    },
    VAT: {
        VatType: 'regular'
    }
};

const basicValuation: ManualFieldsNS.BasicValuation = {
    Valuation: {
        InitialRegistrationCorr: undefined,
        MileageCorr: undefined
    }
};

const equipmentValuation: ManualFieldsNS.EquipmentValuation = {
    Vehicle: {
        Equipment: {
            SpecialEquipment: {
                EquipmentPosition: []
            }
        }
    },
    Valuation: {
        EquipmentSign: 'calculated value',

        // Lump-sum valuation:
        ManualEquipmentOriginalPrice: 0,
        EquipmentPercentage: 100,
        EquipmentDecreaseType: 'table2',
        EquipmentDecreaseTypeRemaining: 'table2'
    }
};

const equipmentValue: ManualFieldsNS.EquipmentValue = {
    Valuation: {
        EquipmentPrice: undefined
    }
};

const vehicleCondition: ManualFieldsNS.VehicleCondition = {
    Valuation: {
        Condition: {
            AccidentDamage: 'unknown',
            IncreaseInValue: undefined,
            DecreaseInValue: undefined,
            TiresMountedValue: undefined,
            TiresUnmountedValue: undefined,
            NumberOfOwnersN: 'unknown',
            OwnerCorrectionPerc: undefined,
            ConditionCorrectionFactorPerc: undefined,
            RepairCostsInTradeMargin: false,
            RepairCosts: undefined
        }
    }
};

export const initialManualFields = deepmerge.all([
    price,
    basicData,
    basicValuation,
    equipmentValuation,
    equipmentValue,
    vehicleCondition
]) as ManualFields;
