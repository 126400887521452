import styled, { css } from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { InputFieldStyled, NumberFormatStyled } from '../../core/styles';

export const InfoButton = styled(Button)`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.gray_40};
    cursor: pointer;
    border: none;
    background-color: unset;

    &:hover {
        border: none;
        color: ${({ theme }) => theme.colors.gray_40};
        background-color: unset;
    }
`;

interface Props {
    isActiveCard: boolean;
}

export const Card = styled.div<Props>`
    padding: 12px 24px;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: background-color 0.3s;

    ${({ isActiveCard, theme }) =>
        isActiveCard &&
        css`
            background-color: ${theme.colors.gray_300};

            ${NumberFormatStyled} {
                color: ${theme.colors.gray_40};
            }

            ${InputFieldStyled} {
                background-color: ${theme.colors.white};
            }
        `}

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;
