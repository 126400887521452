import { sample } from 'effector';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { mailConfigurationStores } from './index';

const { productsConfiguration } = sharedTemplateStores;
const { mailConfig } = mailConfigurationStores;

sample({
    source: productsConfiguration,
    fn: productsConfiguration => productsConfiguration?.mail,
    target: mailConfig
});
