import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sharedMailEvents } from '@dat/shared-models/mail';
import { Text } from '@wedat/ui-kit/components/Text';
import { Button } from '@wedat/ui-kit/components/Button';
import successfullySentGif from '../../assets/images/successfullySent.gif';
import { Container, Content, SuccessImage, Info, ButtonWrapper } from './styles';

const { toggleIsMailOpen } = sharedMailEvents;

export const SuccessfullySentView: React.FC = () => {
    const { t } = useTranslation();
    const [seconds, setSeconds] = useState(3);

    useEffect(() => {
        const id = setInterval(() => {
            setSeconds(second => {
                if (second === 0) {
                    clearInterval(id);
                    return second;
                } else {
                    return second - 1;
                }
            });
        }, 1000);
        return () => clearInterval(id);
    }, []);

    return (
        <Container>
            <Content>
                <SuccessImage src={successfullySentGif} alt={t('send.successfullySent')} />
                <Info>
                    <Text font="semiHeading">{t('send.successfullySent')}</Text>
                    <Text font="note">{t('send.successfullySentClose3sec', { seconds })}</Text>
                    <ButtonWrapper>
                        <Button onClick={() => toggleIsMailOpen(false)}>{t('inputs.close')}</Button>
                    </ButtonWrapper>
                </Info>
            </Content>
        </Container>
    );
};
