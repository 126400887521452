import React, { FC, memo } from 'react';

import { List, ConfigProvider, Button, Space, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { RepairPositionElement2 } from '../RepairPositionElement/RepairPositionElement2';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { DamageElementGroup } from '../../types/graphicTypes';
import { onMouseOverModel } from '../../stores/onMouseOverModel';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { convertRepairPositionsToDamageList } from '../../utils/convertRepairPositionsToDamageList';
import { commonModel } from '../../stores/commonModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms/GraphicSetDamageForms';
import { equipmentModel } from '../../stores/equipmentModel';
import { DATProcessIdCommentListModel } from '../../stores/DATProcessIdCommentListModel';
import { ManualRepairPositionFormExtended } from '../GraphicSetDamageForms/ManualRepairPositionForm/ManualRepairPositionFormExtended';
import { manualPositionFormExtendedModel } from '../../stores/manualPositionFormExtendedModel';

import { ReactComponent as EmptyCart } from './EmptyCart.svg';
import { InterimCalculation02 } from '../InterimCalculation/InterimCalculation02';
import { RepairPositionsCalculationSummary } from '../RepairPositionsCalculationSummary/RepairPositionsCalculationSummary';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { PreparedManualPositionsView } from '../PreparedManualPositions/PreparedManualPositionsView';
import { ReactComponent as EquipmentIcon } from '../CommonComponents/Icons/EquipmentIcon.svg';

import { ReactComponent as DeleteIcon } from '../CommonComponents/Icons/DeleteIcon.svg';
import { InlineIcon } from '../CommonComponents/InlineIcon';

export const RepairPositionsList: FC<{}> = memo(
    () => {
        const repairPositions = useStore(RepairPositionsModel.store.repairPositions);

        const isRepPosModified = useStore(RepairPositionsModel.store.isModified);
        const isCommentModified = useStore(DATProcessIdCommentListModel.store.isModified);
        const isEquipmentModified = useStore(equipmentModel.stores.isModified);
        const isModified = isRepPosModified || isCommentModified || isEquipmentModified;

        const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);
        const { availableAssemblyGroups } = availableAssemblyGroupsStore;

        const { t } = useTranslation();

        const isMobile = useStore(commonModel.stores.isMobile);

        const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
        const useInterimCalculation = pluginOptions?.settings?.useInterimCalculation;
        const showLastCalculationResult = pluginOptions?.settings?.showLastCalculationResult;

        const damageList = convertRepairPositionsToDamageList(repairPositions, availableAssemblyGroups);

        return (
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px'
                }}
                // onMouseLeave={() => {
                //     onMouseOverModel.events.clearOnMouseOver();
                // }}
            >
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Space
                    // align="start"
                    // style={{ float: 'left' }}
                    >
                        <Button
                            size="large"
                            icon={<ArrowRightOutlined />}
                            onClick={() => {
                                rightSideDrawerModel.events.clearHistoryAndClose();
                            }}
                        />
                        <h2 style={{ height: '30px', marginLeft: '8px', marginBottom: '0px' }}>
                            {t('RepairPositions.title')} ({damageList.length})
                        </h2>
                    </Space>
                </div>
                <div style={{ margin: '8px', position: 'absolute', top: '0px', right: '0px' }}>
                    <Space style={{ float: 'right' }}>
                        <InlineIcon
                            onClick={() => {
                                RepairPositionsModel.event.setRepairPositions([]);
                            }}
                            icon={<DeleteIcon />}
                        >
                            {t('RepairPositions.deleteAll')}
                        </InlineIcon>

                        {/* <Button
                            size="large"
                            type="link"
                            onClick={() => {
                                RepairPositionsModel.event.setRepairPositions([]);
                            }}
                            // icon={<InlineIcon icon={<DeleteIcon />} />}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}
                        >
                          
                            <InlineIcon
                                onClick={() => {
                                    RepairPositionsModel.event.setRepairPositions([]);
                                }}
                                icon={<DeleteIcon />}
                            >
                                {t('RepairPositions.deleteAll')}
                            </InlineIcon>
                         
                        </Button> */}
                    </Space>
                </div>

                <ConfigProvider renderEmpty={() => <div />}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '50px',
                            left: '0px',
                            right: '0px',
                            bottom: '54px',
                            overflowY: 'auto'
                        }}
                    >
                        {useInterimCalculation && <InterimCalculation02 isMobile={isMobile} />}
                        {showLastCalculationResult && <RepairPositionsCalculationSummary isMobile={isMobile} />}

                        <div style={{ margin: '4px 4px 4px 0px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Button
                                // type="primary"
                                size="large"
                                style={{ flexGrow: 1 }}
                                onClick={() => {
                                    manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                    rightSideDrawerModel.events.setHistoryView(ManualRepairPositionFormExtended);
                                }}
                            >
                                {t('RepairPositions.AddManualPosition')}
                            </Button>

                            <Button
                                // type="primary"
                                style={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}
                                size="large"
                                onClick={() => rightSideDrawerModel.events.setHistoryView(PreparedManualPositionsView)}
                            >
                                {/* {t('PreparedEditingManualRepairPositionList.OpenList')} */}
                                <EquipmentIcon />
                            </Button>
                        </div>

                        <List
                            style={{
                                marginRight: '4px'
                            }}
                            dataSource={damageList}
                            itemLayout="horizontal"
                            renderItem={damageElement =>
                                isShowDamageElement(damageElement) && (
                                    <List.Item
                                        style={{ borderBottom: 0, padding: '4px 0' }}
                                        onDoubleClick={() => {
                                            if (damageElement.repairPositions[0].PositionEntryType === 'manual') {
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm(
                                                    damageElement.repairPositions[0]
                                                );
                                                rightSideDrawerModel.events.setHistoryView(
                                                    ManualRepairPositionFormExtended
                                                );
                                            } else {
                                                graphicDamageSelectionModel.events.setCurrentObjectsByDVN([
                                                    damageElement.repairPositions[0].DATProcessId
                                                ]);
                                                graphicDamageSelectionModel.events.updateStore({
                                                    typeOfGraphic: 'FullGraphic'
                                                });
                                                rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
                                            }
                                        }}
                                        // onMouseOver={() => {
                                        //     if (damageElement.repairPosition.PositionEntryType === 'manual')
                                        //         onMouseOverModel.events.clearOnMouseOver();
                                        //     else
                                        //         onMouseOverModel.events.setHighlightObjectByDVN([
                                        //             damageElement.repairPosition.DATProcessId
                                        //         ]);
                                        // }}
                                        onClick={() => {
                                            if (damageElement.repairPositions[0].PositionEntryType === 'manual') {
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm(
                                                    damageElement.repairPositions[0]
                                                );
                                                rightSideDrawerModel.events.setHistoryView(
                                                    ManualRepairPositionFormExtended
                                                );
                                            } else {
                                                graphicDamageSelectionModel.events.setCurrentAssemblyGroup([
                                                    damageElement.repairPositions[0].DATProcessId
                                                ]);
                                                graphicDamageSelectionModel.events.updateStore({
                                                    typeOfGraphic: 'FullGraphic'
                                                });
                                                onMouseOverModel.events.setHighlightObjectByDVN([
                                                    damageElement.repairPositions[0].DATProcessId
                                                ]);
                                                if (isMobile) rightSideDrawerModel.events.clearHistoryAndClose();
                                            }
                                        }}
                                    >
                                        <RepairPositionElement2
                                            key={damageElement.repairPositions[0].DATProcessId}
                                            damageElement={damageElement}
                                        />
                                    </List.Item>
                                )
                            }
                        />

                        {damageList.length === 0 && (
                            <div style={{ width: '100%', textAlign: 'center', margin: '64px 0px' }}>
                                <EmptyCart />
                                <h2>{t('RepairPositions.emptyCart')}</h2>
                            </div>
                        )}
                    </div>
                </ConfigProvider>

                <div
                    style={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    commonModel.events.cancel();
                                }}
                            >
                                {t('ManualRepairPositionForm.Cancel')}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    commonModel.events.confirmWithEquipmentCheck();
                                }}
                            >
                                {t('ManualRepairPositionForm.Confirm')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);

function isShowDamageElement(damageElement: DamageElementGroup) {
    return !!damageElement.repairPositions.find(
        repPosItem =>
            !!repPosItem.RepairType &&
            [
                'dis- and mounting',
                'overhaul',
                'replace',
                'lacquer',
                'lacquer demounted',
                'fixing',
                'visual inspection',
                'dis- and reassemble',
                'adjust',
                'technical inspection',
                'incidental costs'
            ].includes(repPosItem.RepairType)
    );
}
