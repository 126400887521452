import React, { memo } from 'react';
import { Alert, Col, Row, Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { PreselectPartDamage } from './PreselectPartDamage/PreselectPartDamage';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { FtDamageWrapper } from './FtDamageForm/FtDamageWrapper';
import { SetDamageDVNPartView } from './PreselectPartDamage/SetDamageDVNPartVeiw';

export const GraphicSetDamageForms = memo(
    () => {
        const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
        const { currentAssemblyGroupObjects, currentDVNs, typeOfGraphic } = useStore(
            graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
        );

        if (!currentAssemblyGroupObjects?.length) {
            return <DvnNotFound />;
        }

        const useFastTrackDamagesOnGenericGraphic = pluginOptions?.settings?.useFastTrackDamagesOnGenericGraphic;
        if (useFastTrackDamagesOnGenericGraphic && typeOfGraphic === 'GenericGraphic') {
            return <FtDamageWrapper />;
        }

        if (currentDVNs?.length === 1) {
            return <SetDamageDVNPartView />;
        }

        return <PreselectPartDamage />;
    },
    () => true
);

export const DvnNotFound = memo(
    () => {
        const { t } = useTranslation();
        return (
            <div style={{ margin: '8px 8px 8px 8px' }}>
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Space>
                        <Button
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                rightSideDrawerModel.events.backView();
                            }}
                        />
                    </Space>
                </div>
                <div
                    style={{
                        paddingRight: '4px',
                        position: 'absolute',
                        top: '64px',
                        left: '0px',
                        right: '0px',
                        bottom: '54px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Alert message={t('ManualRepairPositionForm.DvnNotFound')} type="warning" />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);
