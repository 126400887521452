export const parseErrorResponse = (XML: string) => {
    const parser = new DOMParser();
    const XMLDocument = parser.parseFromString(XML, 'text/xml');
    const parseError = XMLDocument.getElementsByTagNameNS('*', 'parsererror')[0];

    if (parseError) {
        throw new Error();
    }

    const errorCodeField = XMLDocument.getElementsByTagNameNS('*', 'faultcode')[0];
    let errorMessageField = XMLDocument.getElementsByTagNameNS('*', 'faultString')[0];

    // Case can be different
    if (!errorMessageField) {
        errorMessageField = XMLDocument.getElementsByTagNameNS('*', 'faultstring')[0];
    }
    if (!errorMessageField) {
        throw new Error();
    }

    let { innerHTML: errorMessage } = errorMessageField;

    return { errorCode: errorCodeField.innerHTML, errorMessage };
};
