import styled from 'styled-components';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

interface Props {
    item?: boolean;
}
export const Container = styled.div`
    flex: 1;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const ItemContent = styled.div<Props>`
    margin-top: 12px;
    display: grid;
    background-color: ${({ theme }) => theme.colors.white};
    grid-template-columns: ${props => (props.item ? `repeat(2, 1fr)` : `repeat(3, 1fr)`)};
    grid-column-gap: 12px;
    grid-row-gap: 16px;
    grid-column: 1 / span 2;
    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0px;
    `}

    ${media.laptop`
        grid-template-columns: 1fr;
    `}
    ${media.phoneMostSmall`
    width:80%;
    `}
`;

export const ItemWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    margin: 5px 20px;
    padding: 16px 28px 16px 28px;
    border-radius: 5px;
    ${media.laptop`
        padding: 12px
    `}
`;
