import styled, { css } from 'styled-components/macro';
import { WrapperStyled } from '@wedat/ui-kit/components/Tabs/styles';
import { hideScrollBar, media } from '@wedat/ui-kit/mediaQueries';

export const StyledWrapper = styled.div`
    .react-select__control {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
`;

export const SearchInputContainer = styled.div`
    position: relative;
    flex: auto;
    margin: 0 20px 10px 0;
    max-width: 100%;
`;

export const LeftIconInput = styled.div<{ image: string }>`
    ${({ image }) =>
        css`
            width: 18px;
            height: 18px;
            position: absolute;
            z-index: 1;
            left: 22px;
            top: 7px;
            opacity: 0.3;
            background: url(${image}) no-repeat center;
        `}
`;

export const RightSelectInput = styled.div`
    position: absolute;
    width: 200px;
    height: 32px;
    right: 0;
    z-index: 1;
    span {
        text-transform: none;
    }
`;

export const FilterFolderContainer = styled.div`
    overflow: auto;
    overflow-y: hidden;

    ${media.phoneBig`
        ${WrapperStyled} {
            ${hideScrollBar()}
        }
    `}
`;

export const FilterWrapper = styled.div`
    min-width: 200px;
    display: flex;
`;

export const FolderFiltersWrapper = styled.div`
    margin: 0;
    white-space: nowrap;
    min-height: 57px;
    
    ${WrapperStyled} {
        ${media.phoneBig`
            width: 100%;
        `}
`;

export const FiltersContainer = styled.div`
    padding: 12px 24px 0;
`;
