import styled from 'styled-components/macro';

export const Container = styled.div`
    width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ImageStyled = styled.img`
    height: 208px;
    width: 208px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 33px auto;
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto 20px auto;
    color: ${({ theme }) => theme.colors.gray_300};
    font-size: 18px;
    line-height: 27px;
    font-family: 'Noto Sans TC', sans-serif;
`;

export const StaticText = styled.span`
    font-weight: 600;
`;

export const UserName = styled.span`
    font-weight: 400;
    text-transform: capitalize;
`;

export const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
