import styled, { css } from 'styled-components';
import { TypeStyle, Color } from './index';

interface Props {
    typeStyle: TypeStyle;
    uppercase?: boolean;
    disabled?: boolean;
    submit?: boolean;
    fillHeight?: boolean;
}

export const yellowStyle = css`
    border: 2px solid;

    &:focus-visible,
    &:focus,
    &:hover {
        outline: none;
        border: 2px solid ${({ theme }) => theme.colors.blue['500']};
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.secondary};
        cursor: pointer;
    }
    &:active {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const blueStyle = css`
    border: 1px solid;

    &:focus-visible,
    &:focus {
        border-color: ${({ theme }) => theme.colors.blue['700']};
        background-color: ${({ theme }) => theme.colors.blue['700']};
        color: ${({ theme }) => theme.colors.white};
    }

    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.blue['500']};
        background-color: ${({ theme }) => theme.colors.blue['500']};
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const transparentColorStyle = css`
    border: 1px solid;

    &:focus-visible,
    &:focus {
        border-color: ${({ theme }) => theme.colors.dustBlue['400']};
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }

    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.dustBlue['400']};
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }
`;

export const redStyle = css`
    border: 2px solid;

    &:focus-visible,
    &:focus,
    &:hover {
        outline: none;
        border: 1px solid ${({ theme }) => theme.colors.mark};
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.mark};
        cursor: pointer;
    }
    &:active {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.mark};
    }
`;

export const disabledStyle = css`
    color: ${({ theme }) => theme.colors.dustBlue['200']};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    background-color: ${({ theme }) => theme.colors.deepBlue['50']};
    pointer-events: none;
`;

const getColorStyle = (typeStyle: Color) => {
    switch (typeStyle) {
        case 'yellow':
            return yellowStyle;
        case 'blue':
            return blueStyle;
        case 'red':
            return redStyle;
        default:
            return blueStyle;
    }
};

const getTypeStyle = (typeStyle: Props['typeStyle']['type']) => {
    switch (typeStyle) {
        case 'primary':
            return primaryStyle;
        case 'secondary':
            return secondaryStyle;
        case 'square':
            return squareStyle;
        case 'transparent':
            return transparentStyle;
        default:
            return primaryStyle;
    }
};

export const squareStyle = css<Props>`
    width: ${({ typeStyle }) => typeStyle.type === 'square' && typeStyle.size}px;
    height: ${({ typeStyle }) => typeStyle.type === 'square' && typeStyle.size}px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['400']};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    ${({ disabled, typeStyle }) =>
        disabled ? disabledStyle : typeStyle.type === 'square' && getColorStyle(typeStyle.color)};
`;

export const transparentStyle = css<Props>`
    height: ${({ fillHeight }) => (fillHeight ? '100%' : '48px')};
    width: 100%;
    padding: 0 40px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['400']};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    ${({ theme }) => theme.typography.defaultMedium};
    ${({ disabled }) => (disabled ? disabledStyle : transparentColorStyle)};
`;

export const primaryStyle = css<Props>`
    height: ${({ fillHeight }) => (fillHeight ? '100%' : '48px')};
    width: 100%;
    padding: 0 40px;
    transition: background-color 0.15s ease-in-out, border 0.15s ease-in-out, color 0.15s ease-in-out;
    ${({ theme }) => theme.typography.defaultMedium};
    background-color: ${({ theme }) => theme.colors.deepBlue['800']};
    border: 1px solid ${({ theme }) => theme.colors.deepBlue['800']};
    color: ${({ theme }) => theme.colors.white};
    ${({ disabled }) => (disabled ? disabledStyle : blueStyle)};
`;

export const secondaryStyle = css<Props>`
    height: 48px;
    width: 130px;
    ${({ theme }) => theme.typography.defaultMedium};
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    ${({ disabled }) => (disabled ? disabledStyle : yellowStyle)};
`;

export const ButtonStyled = styled.button<Props>`
    position: relative;
    border: 0;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : null)}
    ${({ typeStyle }) => getTypeStyle(typeStyle.type)};
    padding: 0;
    transition: color 150ms, background-color 150ms, border 150ms;
`;
