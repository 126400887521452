import React, { FC, CSSProperties, ReactNode } from 'react';

import './icon.css';

export interface IconType {
    style?: CSSProperties;
    className?: string;
    icon?: ReactNode;
    active?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

export const Icon123: FC<IconType> = ({ style, className, icon, active = false, onClick, children }) => (
    <div
        className={
            (active ? 'weDat-grapa-toolbar-icon weDat-grapa-toolbar-icon-active ' : 'weDat-grapa-toolbar-icon ') +
            className
        }
        style={{
            ...style
        }}
        onClick={onClick}
    >
        {icon}
        {children}
    </div>
);
