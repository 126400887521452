import { Button } from '@wedat/ui-kit';
import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
    display: flex;

    ${media.phoneBig`
        margin-right: 0;
    `}
`;

export const ButtonStyled = styled(Button)`
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.colors.blue['50']};
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        border: 1px solid ${({ theme }) => theme.colors.blue['50']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        border: 1px solid ${({ theme }) => theme.colors.blue['50']};
    }

    &:focus {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        border: 1px solid ${({ theme }) => theme.colors.blue['50']};
    }

    ${media.laptop`
        border: none;
        background-color: transparent;
        color: ${({ theme }) => theme.colors.black};
        width: auto;
        max-width: 120px;
        padding-left: 0;
        padding-right: 0;

    &:hover {
        background-color: transparent;
        border: none;
    }

    &:active {
        background-color: transparent;
        border: none;
    }

    &:focus {
        background-color: transparent;
        border: none;
    }
    `}
`;

export const Item = styled.div`
    min-height: 44px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const IconWrapper = styled.div`
    align-items: center;
    margin-right: 8px;
    height: 20px;
    width: 22px;

    svg {
        display: block !important;
        width: 22px;
        height: 20px;
        color: ${({ theme }) => theme.colors.blue['700']};

        ${media.laptop`
            color: ${({ theme }) => theme.colors.black};
        `}
    }
`;
