import FilerobotImageEditor from 'filerobot-image-editor';
import { useStore } from 'effector-react';
import { v4 as uuidv4 } from 'uuid';

import { editorStores, editorEvents } from '../../stores/imageEditor';
import { attachmentsEffects } from '../../stores/attachments';
import { extractDataFromBase64 } from '@dat/core/utils/attachments/extractDataFromBase64';

import { config } from './contants';

type ProccessorProps = {
    canvas: HTMLCanvasElement;
    imageMime: string;
    imageName: string;
};

export const EditDialog = () => {
    const state = useStore(editorStores.editorState);

    const onBeforeComplete = ({ canvas, imageMime }: ProccessorProps) => {
        attachmentsEffects.uploadAttachmentsToGroupFx({
            groupId: state.groupId,
            attachments: [
                {
                    fileName: uuidv4(),
                    binaryData: extractDataFromBase64(canvas.toDataURL()),
                    documentID: state.groupId,
                    mimeType: imageMime
                }
            ]
        });
        return false;
    };

    return (
        <FilerobotImageEditor
            config={config}
            show={state.isOpen}
            src={state.image}
            onBeforeComplete={onBeforeComplete}
            onError={(err: any) => console.error(err)}
            onClose={editorEvents.hideEditor}
        />
    );
};
