import axios from 'axios';
import { refreshTokenInterceptors } from './utils/refreshTokenInterceptors';

export const prAxiosInstance = axios.create({
    headers: {
        // TODO: token
        Authorization: 'Token 411c5e24b761ed6c897f8fbba6cbd60b4db2d4cc',
        Accept: '*/*'
    }
});

refreshTokenInterceptors(prAxiosInstance);
