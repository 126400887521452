import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button } from '@wedat/ui-kit/components/Button';
import { ButtonWrapper, Container } from './styles';

interface Props {
    isValid?: boolean;
    dirty?: boolean;
}
export const Footer: React.FC<Props> = ({ isValid, dirty }) => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormikContext();

    return (
        <Container>
            <ButtonWrapper>
                <Button disabled={!(isValid && dirty)} onClick={() => handleSubmit()}>
                    {t('common.save')}
                </Button>
            </ButtonWrapper>
        </Container>
    );
};
