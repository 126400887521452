import React, { useRef, useState } from 'react';
import { AddressBookIcon } from '@wedat/ui-kit/components/Icons';
import { Button, ButtonAddressBook, Container, DropdownContainer, Title } from './styles';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { useStore } from 'effector-react';
import { configurationStores } from '../../stores/configuration';
import { Dropdown } from '@wedat/ui-kit/components/Dropdown';
import { AddressBookField } from '../AddressBookField';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { useTranslation } from 'react-i18next';

export const ProfileAddressBook = () => {
    const { t } = useTranslation();
    const [isOpenDropDown, setOpenDropdown] = useState(false);
    const [isOpenAddressBookModal, setOpenAddressBookModal] = useState(false);
    const configuration = useStore(configurationStores.configuration);
    const containerRef = useRef(null);
    const isMobile = useMedia(sizes.phoneBig);

    const handleClick = () => {
        setOpenDropdown(true);
    };

    const handleOpenAddressBookModal = () => {
        setOpenAddressBookModal(true);
    };

    const handleCloseAddressBookModal = () => {
        setOpenAddressBookModal(false);
    };

    const getItems = () =>
        configuration?.subjects?.map(subject => (
            <ButtonAddressBook key={subject.id}>
                <AddressBookField
                    field={subject}
                    onOpenModal={handleOpenAddressBookModal}
                    onCloseModal={handleCloseAddressBookModal}
                />
            </ButtonAddressBook>
        ));

    const closeSlideUpMenu = () => {
        setOpenDropdown(false);
    };

    useClickOutside(containerRef, () => {
        if (!isOpenAddressBookModal) {
            setOpenDropdown(false);
        }
    });

    if (!configuration?.subjects) {
        return null;
    }

    return (
        <Container ref={containerRef}>
            <Button onClick={handleClick} active={isOpenDropDown}>
                <AddressBookIcon />
            </Button>
            {isMobile ? (
                <SlideUpMenu isOpen={isOpenDropDown} closeSlideUpMenu={closeSlideUpMenu}>
                    <Title as="h4" font="note" fontWeight={700}>
                        {t('slideUpMenu.addressbook')}
                    </Title>
                    {getItems()}
                </SlideUpMenu>
            ) : (
                <Dropdown side="right" isOpen={isOpenDropDown} width="270px">
                    <DropdownContainer>{getItems()}</DropdownContainer>
                </Dropdown>
            )}
        </Container>
    );
};
