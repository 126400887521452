/**
 * Request to get geolocation
 */
export const getGeolocation = () =>
    new Promise<GeolocationPosition>((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));

export const GEOLOCATION_KEY = 'plugin-cars_geolocation_enable';
export const GEOLOCATION_ENABLE = '1';

/**
 * Check geolocation accepted
 */
export const isGeolocationAccepted = () =>
    new Promise<undefined>(async (res, rej) => {
        let result = { state: 'denied' };

        try {
            // doesnt support in safari
            result = await navigator.permissions.query({ name: 'geolocation' });
        } catch {
            // special key for safari
            const geo = localStorage.getItem(GEOLOCATION_KEY);

            if (geo === GEOLOCATION_ENABLE) {
                return res(undefined);
            }

            return rej();
        }

        result.state === 'granted' ? res(undefined) : rej();
    });

export interface GeolocationInfo {
    date: string;
    time: string;
    latitude: string;
    longitude: string;
}

/**
 * Get geolocation positions with date
 */
export const getGeolocationWithDate = async (): Promise<GeolocationInfo> => {
    const position = await getGeolocation();
    const date = new Date();
    const decimalCount = 7;

    return {
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
        latitude: position.coords.latitude.toFixed(decimalCount),
        longitude: position.coords.longitude.toFixed(decimalCount)
    };
};
