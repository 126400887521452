import { AttachmentGroup, ParsedAttachmentItem, GroupedAttachments } from '../../types/attachments';

interface Param {
    attachments: ParsedAttachmentItem[];
    groups: AttachmentGroup[];
}

export const groupAttachments = ({ attachments, groups }: Param): GroupedAttachments => {
    const result: GroupedAttachments = {};

    groups.forEach(({ id }) => {
        const groupAttachments = attachments.filter(({ documentID }) => documentID === id);

        result[id] = groupAttachments;
    });

    return result;
};
