import React from 'react';
import { useStoreMap } from 'effector-react';

import { useItemContract } from '../../hooks/useItemContract';
import { useVehicleImage } from './hooks/useVehicleImage';
import { sharedVehiclesImagesStores } from '@dat/shared-models/contract/Dossier/Vehicle/Images';

import { ImageWrapper, StyledImage } from './styles';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';

import vehiclePlaceholderImage from '../../../../../assets/images/vehicle-placeholder.png';

export const Image: React.FC = props => {
    const { eCode } = useItemContract();
    const vehicleImage = useVehicleImage(eCode);
    const isLoading = useStoreMap(sharedVehiclesImagesStores.datECodesFetching, datECodesFetching =>
        datECodesFetching.includes(String(eCode))
    );

    return (
        <ImageWrapper {...props}>
            {isLoading ? (
                <PreloaderCircle isLoading={true} />
            ) : (
                <StyledImage src={vehicleImage || vehiclePlaceholderImage} />
            )}
        </ImageWrapper>
    );
};
