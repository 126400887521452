import { createEvent, createStore, sample } from 'effector';
import { AvailableAssemblyGroup, AvailableDefaultDamage } from '../types/graphicTypes';
import { ObjectInfo, RC } from '../types/svgGrapaTypes';
import { findGroupByDvns } from '../utils/findGroupByDvns';
import { splitOnTwoParts } from '../utils/splitOnTwoParts';

import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { commonModel } from './commonModel';

export interface GraphicDamageSelectionModelStore {
    showGraphicGroupsMenu?: boolean;
    showRightSideDrawer?: boolean;

    showFullscreen?: boolean;
    typeOfGraphic?: 'ZoneGraphic' | 'GenericGraphic' | 'FullGraphic' | 'RimsGraphic' | string;

    // isMultiSelect?: boolean; // режим множетственного выбора
    isShowFilterMenu?: boolean; //
    isSelectingLeft?: boolean; //
    isSelectingRight?: boolean; //
    isSelectingSingle?: boolean; //

    isShowMaterialLegends?: boolean;

    isShowMultiSelectMenu?: boolean; //
    defaultDamages?: AvailableDefaultDamage[];
    availableDefaultDamages?: AvailableDefaultDamage[];

    // isShowSelectionElementFromGroup?: boolean; //

    currentAssemblyGroup?: AvailableAssemblyGroup;

    currentAssemblyGroupObject?: ObjectInfo;
    currentAssemblyGroupObjects?: ObjectInfo[];
    currentDVNs?: number[];
}

const defaultValueGraphicDamageSelection = {
    typeOfGraphic: 'GenericGraphic', // 'ZoneGraphic' | 'GenericGraphic' | 'FullGraphic' | 'RimsGraphic'

    isShowFilterMenu: false,
    isSelectingLeft: false,
    isSelectingRight: false,
    isSelectingSingle: false,

    isShowMaterialLegends: false,

    isShowMultiSelectMenu: false,
    // isMultiSelect: false,

    defaultDamages: [],

    availableDefaultDamages: [
        {
            defaultDamageId: 'EReplace',
            damageName: 'Replace',
            codeRC: RC.E,
            RepairType: 'replace',

            DATProcessId: 0,
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false,
            WearGroup: 6,
            WearAdditional: 0
        },
        {
            defaultDamageId: 'ADisAndMounting',
            damageName: 'Dis- and mounting',
            codeRC: RC.A,
            RepairType: 'dis- and mounting',

            DATProcessId: 0,
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false,
            WearGroup: 6,
            WearAdditional: 0
        },
        {
            defaultDamageId: 'lacquerNew',
            damageName: 'Lacquer New',
            codeRC: RC.L,
            RepairType: 'lacquer',

            DATProcessId: 0,
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            LacquerLevel: 'new',
            LacquerLevelId: 0,
            LacquerLevelBaseId: 2,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false,
            WearGroup: 6,
            WearAdditional: 0
        },
        {
            defaultDamageId: 'lacquerSurface',
            damageName: 'Lacquer surface',
            codeRC: RC.L,
            RepairType: 'lacquer',

            DATProcessId: 0,
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            LacquerLevel: 'surface',
            LacquerLevelId: 1,
            LacquerLevelBaseId: 2,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false,
            WearGroup: 6,
            WearAdditional: 0
        }
    ],

    currentAssemblyGroup: undefined,
    currentAssemblyGroupObject: undefined,
    currentAssemblyGroupObjects: undefined,
    currentDVNs: undefined
};

const graphicDamageSelectionModelStore = createStore<GraphicDamageSelectionModelStore>({
    showGraphicGroupsMenu: false,
    showRightSideDrawer: false,
    showFullscreen: false,

    ...defaultValueGraphicDamageSelection
});

const resetGraphicDamageSelection = createEvent();

graphicDamageSelectionModelStore.on(resetGraphicDamageSelection, (oldState: GraphicDamageSelectionModelStore) => ({
    ...oldState,
    ...defaultValueGraphicDamageSelection
}));

const updateStore = createEvent<GraphicDamageSelectionModelStore>();
const updateStoreDirectly = createEvent<GraphicDamageSelectionModelStore>();
const setCurrentObjectsByDVN = createEvent<number[]>();

const togleDefaultDamage = createEvent<string>();

interface ParamsDATIDandGroup {
    datid: string;
    asemblyGroupId?: number;
}

const setCurrentObjectByDATIDandGroup = createEvent<ParamsDATIDandGroup>();

sample({
    source: commonModel.stores.isTablet,
    clock: updateStore,
    fn: (isTablet, updateVal) => {
        // if (
        //     typeof updateVal.showGraphicGroupsMenu === 'undefined' ||
        //     typeof updateVal.showRightSideDrawer === 'undefined'
        // )
        if (isTablet) {
            if (updateVal.showGraphicGroupsMenu) {
                updateVal.showRightSideDrawer = false;
            } else if (updateVal.showRightSideDrawer) {
                updateVal.showGraphicGroupsMenu = false;
            }
        }
        return updateVal;
    },
    target: updateStoreDirectly
});

graphicDamageSelectionModelStore
    .on(
        updateStoreDirectly,
        (oldState: GraphicDamageSelectionModelStore, params: GraphicDamageSelectionModelStore) => ({
            ...oldState,
            ...params
        })
    )
    .on(togleDefaultDamage, (oldState: GraphicDamageSelectionModelStore, defaultDamageId) => {
        let newDefaultDamage: AvailableDefaultDamage[] = [];
        const { defaultDamages, availableDefaultDamages } = oldState;
        if (defaultDamages) newDefaultDamage = [...defaultDamages];

        const finedDefDamage = newDefaultDamage.find(
            elemDefDamage => elemDefDamage.defaultDamageId === defaultDamageId
        );

        if (finedDefDamage) {
            // remove from newDefaultDamage
            newDefaultDamage = newDefaultDamage.filter(
                elemDefDamage => elemDefDamage.defaultDamageId !== defaultDamageId
            );
        } else {
            // add to newDefaultDamage
            if (availableDefaultDamages) {
                const findedAvailDamage = availableDefaultDamages.find(
                    elemDefDamage => elemDefDamage.defaultDamageId === defaultDamageId
                );
                if (findedAvailDamage) newDefaultDamage.push(findedAvailDamage);
            }
        }

        return {
            ...oldState,
            defaultDamages: newDefaultDamage
        };
    });

graphicDamageSelectionModelStore.on(
    setCurrentObjectsByDVN,
    (oldState: GraphicDamageSelectionModelStore, dvns: number[]) => {
        const { availableAssemblyGroups: availableAssemblyGroupsList } =
            availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore.getState();

        const foundGroup = findGroupByDvns(dvns, availableAssemblyGroupsList);

        if (!availableAssemblyGroupsList) return oldState;
        return {
            ...oldState,
            ...foundGroup
        };
    }
);

sample({
    source: [graphicDamageSelectionModelStore, availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore],
    clock: setCurrentObjectByDATIDandGroup,
    fn: ([oldState, { availableAssemblyGroups: availableAssemblyGroupsList }], { datid, asemblyGroupId }) => {
        const currentAssemblyGroupObjects = [];
        let newCurrentAssemblyGroupObject;

        const { currentAssemblyGroup } = oldState;

        const findedAssemblyGroup =
            typeof asemblyGroupId === 'undefined'
                ? currentAssemblyGroup
                : availableAssemblyGroupsList.find(item => item.assemblyGroup.assemblyGroupId === asemblyGroupId);

        if (!findedAssemblyGroup) return oldState;

        const { objectsInfo } = findedAssemblyGroup;
        if (!objectsInfo) return oldState;

        let findedDvnGroup;
        for (let k = 0; k < objectsInfo.length; k += 1) {
            const obj = objectsInfo[k];

            if (obj.datid === datid) {
                newCurrentAssemblyGroupObject = obj;
                findedDvnGroup = obj.dvnGroup;

                if (obj.dvnGroup === 0) {
                    currentAssemblyGroupObjects.push(obj);
                    break;
                }

                break;
            }
        }

        if (findedDvnGroup !== 0) {
            for (let k = 0; k < objectsInfo.length; k += 1) {
                const obj = objectsInfo[k];
                if (obj.dvnGroup === findedDvnGroup) {
                    currentAssemblyGroupObjects.push(obj);
                }
            }
        }

        const currentDVNs: number[] = [];

        // if only one dvn available or (left right) set active window without preselect
        if (currentAssemblyGroupObjects.length === 1) {
            const objInfo = currentAssemblyGroupObjects[0];
            const { isSplitalble } = splitOnTwoParts(objInfo.titleMenu);
            if (!isSplitalble) {
                if (!!objInfo.dvnLeft?.dvn) currentDVNs.push(objInfo.dvnLeft.dvn);
                else if (!!objInfo.dvnRight?.dvn) currentDVNs.push(objInfo.dvnRight.dvn);
            }
        }

        if (currentDVNs.length === 0)
            currentAssemblyGroupObjects.forEach(objInfo => {
                if (!!objInfo.dvnLeft?.dvn) currentDVNs.push(objInfo.dvnLeft.dvn);
                if (!!objInfo.dvnRight?.dvn) currentDVNs.push(objInfo.dvnRight.dvn);
            });

        return {
            ...oldState,
            currentAssemblyGroup: findedAssemblyGroup,
            currentAssemblyGroupObject: newCurrentAssemblyGroupObject,
            currentAssemblyGroupObjects,
            currentDVNs
        };
    },
    target: updateStore
});

const setCurrentAssemblyGroup = createEvent<number[]>();
graphicDamageSelectionModelStore.on(
    setCurrentAssemblyGroup,
    (oldState: GraphicDamageSelectionModelStore, dvns: number[]) => {
        const { availableAssemblyGroups: availableAssemblyGroupsList } =
            availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore.getState();
        if (!availableAssemblyGroupsList) return oldState;
        const foundGroup = findGroupByDvns(dvns, availableAssemblyGroupsList);
        if (!foundGroup) return oldState;
        return {
            ...oldState,
            showGenericGraphic: false,
            currentAssemblyGroup: foundGroup.currentAssemblyGroup
        };
    }
);

const setNextAssemblyGroup = createEvent<AvailableAssemblyGroup[]>();
graphicDamageSelectionModelStore.on(
    setNextAssemblyGroup,
    (oldState: GraphicDamageSelectionModelStore, assemblyGroupsList: AvailableAssemblyGroup[]) => {
        if (!assemblyGroupsList) return oldState;
        const currentAssemblyGroup = oldState.currentAssemblyGroup;
        const currentAssemblyGroupListIndex = assemblyGroupsList.findIndex(
            assemblyGroup =>
                assemblyGroup.assemblyGroup.assemblyGroupId === currentAssemblyGroup?.assemblyGroup.assemblyGroupId
        );
        const nextAssemblyGroupListIndex =
            currentAssemblyGroupListIndex < assemblyGroupsList.length - 1 ? currentAssemblyGroupListIndex + 1 : 0;
        return {
            ...oldState,
            currentAssemblyGroup: assemblyGroupsList[nextAssemblyGroupListIndex]
        };
    }
);

const setPreviousAssemblyGroup = createEvent<AvailableAssemblyGroup[]>();
graphicDamageSelectionModelStore.on(
    setPreviousAssemblyGroup,
    (oldState: GraphicDamageSelectionModelStore, assemblyGroupsList: AvailableAssemblyGroup[]) => {
        if (!assemblyGroupsList) return oldState;
        const currentAssemblyGroup = oldState.currentAssemblyGroup;
        const currentAssemblyGroupListIndex = assemblyGroupsList.findIndex(
            assemblyGroup =>
                assemblyGroup.assemblyGroup.assemblyGroupId === currentAssemblyGroup?.assemblyGroup.assemblyGroupId
        );
        const previousAssemblyGroupListIndex =
            currentAssemblyGroupListIndex > 0 ? currentAssemblyGroupListIndex - 1 : assemblyGroupsList.length - 1;
        return {
            ...oldState,
            currentAssemblyGroup: assemblyGroupsList[previousAssemblyGroupListIndex]
        };
    }
);

// ================================================================================================

const currentDVNs = graphicDamageSelectionModelStore.map(val => val.currentDVNs);

export const graphicDamageSelectionModel = {
    stores: {
        graphicDamageSelectionModelStore,
        currentDVNs
    },
    events: {
        resetGraphicDamageSelection,

        updateStore,
        togleDefaultDamage,
        setCurrentObjectsByDVN,
        setCurrentObjectByDATIDandGroup,
        setCurrentAssemblyGroup,
        setNextAssemblyGroup,
        setPreviousAssemblyGroup
    }
};
