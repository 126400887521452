import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { useOrientation } from '../../hooks/orientation';
import { getTranslates } from '../../utils/plugin';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { useToggle } from '@dat/core/hooks/useToggle';
import { CommentIcon } from '@wedat/ui-kit/components/Icons';

import { CommentWrapper, Content, Textarea } from './styles';

interface Props {
    onChange: (value: string) => void;
    value: string;
    showComment: boolean;
    className?: string;
}

const MAX_COMMENT_SYMBOL = 250;

/**
 * Component with comment btn and Modal with field
 */
export const CommentBtn: FC<Props> = ({
    children,
    onChange,
    className,
    value: valueProps = '',
    showComment = true
}) => {
    const { isLandscape } = useOrientation();
    const [comment, setComment] = useState({ value: valueProps });
    const translates = getTranslates();
    const [visible, toggle] = useToggle(false);

    const handleComment = useCallback(
        ({ currentTarget: { value } }: SyntheticEvent<HTMLTextAreaElement>) => {
            if (comment.value.length < MAX_COMMENT_SYMBOL) {
                setComment({ value });

                onChange(value);
            } else {
                // special behavior for react
                setComment({ value: value.substring(0, value.length - 1) });
            }
        },
        [onChange, comment]
    );

    return (
        <Content className={className}>
            {children}
            {showComment && <CommentIcon onClick={toggle} />}

            <ModalPrimary onDismiss={toggle} isOpen={visible}>
                <CommentWrapper>
                    <Textarea
                        placeholder={translates.commentFieldPlaceholder}
                        isLandscape={isLandscape}
                        value={comment.value}
                        onChange={handleComment}
                    />
                </CommentWrapper>
            </ModalPrimary>
        </Content>
    );
};
