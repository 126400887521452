import { createEffect, createStore } from 'effector';
import { sharedVehiclesImagesEffects } from '@dat/shared-models/contract/Dossier/Vehicle/Images';
import { IMAGE_ASPECT } from './constants';
import { API2 } from '@dat/api2';
import { triggerFileDownload } from '@dat/core/utils/attachments/triggerFileDownload';

const getImagesOfContractsFx = createEffect((contracts: DAT2.ContractFromListContracts[]) => {
    const datECodes = contracts.map(({ eCode }) => eCode).filter((eCode): eCode is string | number => !!eCode);

    return Promise.allSettled(
        datECodes.map(datECode => sharedVehiclesImagesEffects.getVehicleImagesFx({ datECode, aspect: IMAGE_ASPECT }))
    );
});

const getAttachmentFoldersOfClaimFx = createEffect(async (req: DAT2.Internal.Request.GetFoldersOfClaim) => {
    const folders: DAT2.Internal.FolderOfClaim[] | DAT2.Internal.Response.ProxyResponseErrors =
        await API2.myClaimInternal.getFoldersOfClaim(req);
    return Array.isArray(folders) ? folders.flatMap(folder => folder?.attachments) : [];
});
const attachments = createStore<DAT2.Internal.FolderOfClaimAttachment[]>([]);

/*
 * Proxy to trigger direct download of image file.
 * Cannot be done via link element because of cross domain.
 */
const downloadImageOfClaim = createEffect(
    async ({
        filename,
        claimId,
        photoId,
        folderId,
        token
    }: {
        filename: string;
        claimId: number;
        photoId: number;
        folderId: number;
        token: string;
    }) => {
        const response = await fetch(API2.myClaimInternal.getPhotoUrl({ claimId, photoId, folderId, token }));
        const buffer = await (await response.blob()).arrayBuffer();
        const base64 = Buffer.from(buffer).toString('base64');
        triggerFileDownload({ filename, data: `data:image/jpg;base64,${base64}` });
    }
);

attachments.on(getAttachmentFoldersOfClaimFx.doneData, (_, state) => state);

export const imagesStores = {
    attachments
};

export const imagesEffects = {
    getImagesOfContractsFx,
    getAttachmentFoldersOfClaimFx,
    downloadImageOfClaim
};
