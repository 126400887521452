import React from 'react';
import ReactNumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { useStore } from 'effector-react';

import { evaluationEffects } from '../../stores/evaluation';
import { usePriceDependentField } from '../../hooks/usePriceDependentField';
import { addDatPrefixToFieldName } from '../../utils/addDatPrefixToFieldName';

import { StyledNumberText, StyledPreloaderCircle } from './styles';

export interface DatNumberFieldProps extends NumberFormatPropsBase {
    propertyPath?: string; // path to property in Dossier. Similar to Formik's name (e.g. "Vehicle.InitialRegistration")
    onlyWithDatPrefix?: boolean; // if true, shows property only with "Dat" prefix
    withoutIsActive?: boolean;
}

//TODO: describe work logic
export const DatNumberDisplayField: React.FC<DatNumberFieldProps> = ({
    propertyPath = '.',
    onlyWithDatPrefix,
    defaultValue = 0,
    renderText,
    withoutIsActive,
    ...rest
}) => {
    const { activeValue: manualValue } = usePriceDependentField({ baseName: propertyPath, fieldType: 'input' });
    const { activeValue: dossierBaseValue } = usePriceDependentField({ baseName: propertyPath, fieldType: 'display' });
    const { activeValue: dossierDatValue } = usePriceDependentField({
        baseName: addDatPrefixToFieldName(propertyPath),
        fieldType: 'display'
    });

    let value = dossierDatValue;

    if (!onlyWithDatPrefix && dossierDatValue === undefined) {
        value = dossierBaseValue;
    }

    const isActive = withoutIsActive ? true : manualValue === undefined;
    const isLoading = useStore(evaluationEffects.getVehicleEvaluationWithManualFieldsFx.pending);

    return (
        <ReactNumberFormat
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator="."
            decimalSeparator=","
            value={value}
            defaultValue={defaultValue}
            renderText={formattedValue => (
                <StyledNumberText isActive={isActive} isLoading={isLoading}>
                    {renderText ? renderText(formattedValue) : formattedValue}
                    <StyledPreloaderCircle isLoading={isLoading} width={22} />
                </StyledNumberText>
            )}
            {...rest}
        />
    );
};
