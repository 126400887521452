import { format } from 'date-fns';
import { isAdmin } from '../constants';

export const getFormattedTimeISOString = (date: Date) =>
    new Date(format(date, 'MM-dd-yyyy') + ' ' + format(date, 'HH:mm')).toISOString();

export const getFormattedExpiryClaimISOString = (date: Date) =>
    new Date(`${format(date, 'MM-dd-yyyy')} 00:00`).toISOString();

const getFieldByKey = (
    templateDataEntry: DAT2.SingleOrArray<DAT2.Field.Entry<string, DAT2.Field.Primitive>> | null,
    key: string
) =>
    templateDataEntry && Array.isArray(templateDataEntry)
        ? templateDataEntry.find(entry => entry.key === key)?.value?._text?.toString() || ''
        : '';

export const getDescriptionFields = (contract: DAT2.ContractFromGetContract | null) => {
    const templateDataEntry = contract?.customTemplateData?.entry || null;

    const ownerName = getFieldByKey(templateDataEntry, 'owner_name');
    const ownerSurname = getFieldByKey(templateDataEntry, 'owner_surname');
    const ownerPhone = getFieldByKey(templateDataEntry, 'owner_phone');
    const ownerEmail = getFieldByKey(templateDataEntry, 'owner_mail');

    return { ownerName, ownerSurname, ownerPhone, ownerEmail };
};

export const getSelectOptions = (partners: DAT2.Internal.InternalPartner[]) =>
    partners
        .filter(partner => partner.active)
        .map(item => ({ label: item.name, value: Number(item.customerNumber), key: Number(item.customerNumber) }));

export const getPartnersByRoles = (partners: DAT2.Internal.InternalPartner[]) => {
    if (!partners?.length) return { adminPartners: [], userPartners: [] };

    const selectOptions = getSelectOptions(partners);

    const adminPartners = selectOptions.filter(partner => isAdmin(partner.value));
    const userPartners = selectOptions.filter(partner => !adminPartners.includes(partner));

    return { adminPartners, userPartners };
};
