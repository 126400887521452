import styled, { css } from 'styled-components/macro';
import { Partner } from '../RolesPopup/styles';

export const MainContainer = styled.div`
    position: relative;
`;

export const Text = styled.div`
    display: inline-block;
    user-select: none;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
`;

interface ButtonProp {
    isHeader?: boolean;
}

export const AssignPartnerButton = styled.button<ButtonProp>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover ${Text} {
        opacity: 0.5;
    }

    &:hover ${Partner} {
        color: #343a40;
    }

    ${({ isHeader }) =>
        isHeader &&
        css`
            padding: 0;
            border-radius: 0;
        `}
`;
