import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@wedat/ui-kit/components/Button';
import { attachmentsEvents, attachmentsStores } from '../../../stores/attachments';
import { Group } from './Group';
import { Footer } from '../../Main/styles';
import { modalsEvents } from '../../../stores/modals';
import { NotFound } from '../../notFound';
import { Container, Content } from './styles';

interface Props {
    isTablet: boolean;
}

const { toggleIsAttachments } = modalsEvents;
const { resetAttachments } = attachmentsEvents;

export const Gallery: React.FC<Props> = ({ isTablet }) => {
    const { t } = useTranslation();
    const folders = useStore(attachmentsStores.folders);
    const isEmpty = folders.map(item => !!item.attachments.length).some(Boolean);

    return (
        <Container>
            <Content>
                {isEmpty ? (
                    folders?.map(
                        group =>
                            group.attachments.length > 0 && (
                                <Group attachments={group.attachments} key={group.id} group={group} />
                            )
                    )
                ) : (
                    <NotFound />
                )}
            </Content>

            {isTablet && (
                <Footer>
                    <Button typeStyle={{ type: 'transparent' }} onClick={() => resetAttachments()}>
                        {t('inputs.cancel')}
                    </Button>
                    <Button onClick={toggleIsAttachments}>{t('inputs.add')}</Button>
                </Footer>
            )}
        </Container>
    );
};
