import styled, { css } from 'styled-components/macro';

import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { Search } from '@wedat/ui-kit/assets/Icon';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';

interface PartnerCardProps {
    isAssigned?: boolean;
    isSelected?: boolean;
    withNoMargin?: boolean;
    isSingle?: boolean;
}

type DrawerProps = {
    isEmpty: boolean;
};

export const WrapperContent = styled.div`
    display: flex;
    justify-content: space-between;

    // Modal has 90vh height from that subtract footer and header heights
    // This is needed to let modal content has scroll if needed

    height: calc(90vh - 173px);

    ${media.tablet`
        height: auto;
        flex-direction: column-reverse;
        justify-content: flex-end;
    `}
`;

export const PartnersContent = styled.div`
    width: 25%;
    height: 100%;

    ${media.tablet`
        width: 100%;
        height: auto;
    `}
`;

export const MapContent = styled.div`
    width: 75%;
    height: 100%;

    margin-left: 24px;

    ${media.tablet`
        width: 100%;
        height: auto;
        margin-left: 0;
    `}
`;

export const Drawer = styled.div<DrawerProps>`
    width: 100%;
    height: calc(90vh - 173px);

    background: ${({ theme }) => theme.colors.white};
    padding: 16px;

    overflow-y: auto;
    overflow-x: hidden;

    ${makeCustomScrollBar()}

    ${media.tablet`
        display: flex;
        height: 220px;
        overflow-x: auto;
    `}

    ${media.laptop`
        height: 170px;
    `}
`;

export const PartnerCard = styled.div<PartnerCardProps>`
    width: 98%;
    max-width: 350px;

    background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.dustBlue[50] : 'unset')};

    margin-bottom: 20px;
    margin-right: 15px;
    padding: 13px;
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 2px solid ${({ theme }) => theme.colors.gray_10};
    border-radius: 8px;
    border-color: ${({ isAssigned, theme }) => (isAssigned ? theme.colors.blueDark : '#f1f2f4')};

    pointer-events: ${({ isAssigned }) => (isAssigned ? 'none' : 'default')};
    cursor: pointer;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    :last-child {
        margin-right: ${({ withNoMargin }) => (withNoMargin ? '0' : '15px')};

        ${media.phone`
            margin-right: 0;
        `}
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
    }

    ${media.tablet`
        min-width: 228px;
        width: 228px;
        min-height: 120px;
    `}

    ${media.laptopSmall`
        height: 120px;
        margin-bottom: 0px;
    `}

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            ${Text} {
                color: ${theme.colors.white};
            }
        `}
`;

export const PartnerName = styled.span<PartnerCardProps>`
    color: ${({ theme }) => theme.colors.dustBlue[900]};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    ${media.laptopSmall`
        font-size: 14px;
        line-height: 20px;
    `}
`;

export const PartnerInfo = styled.span<PartnerCardProps>`
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.dustBlue[600]};

    margin-bottom: 15px;
    margin-right: 10px;

    ${media.laptopSmall`
        margin-bottom: 10px;
    `}
`;

export const PartnerInfoContainer = styled.div`
    display: flex;
    align-items: baseline;

    font-size: 14px;
    line-height: 20px;
`;

export const Title = styled(Text)`
    margin-bottom: 0;
`;

export const NoPartnersWrapper = styled.div`
    width: 100%;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;

    background: ${({ theme }) => theme.colors.white};

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 15px;

    border-top: 1px solid ${({ theme }) => theme.colors.dustBlue[100]};

    ${media.tablet`
        margin-top: 13px;
    `}

    ${media.phone`
        height: 88px;
        justify-content: space-evenly;
    `}
`;

export const StyledResetButton = styled(Button)`
    width: fit-content;

    background: ${({ theme }) => theme.colors.gray[50]};

    padding: 12px 40px;
    margin-right: 10px;

    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue[900]};

    ${media.phone`
        width: 40%;
        border: 1px solid ${({ theme }) => theme.colors.dustBlue[400]};
    `}
`;

export const ConfirmButtonsContainer = styled.div`
    display: flex;
    align-items: center;

    ${media.phone`
        height: 48px;
        width: 40%;
    `}
`;

export const StyledButton = styled(Button)`
    width: 100px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${media.phone`
        width: 100%;
        height: 48px;
    `}
`;

export const UpperPart = styled.div`
    width: 100%;
    height: 84px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 24px;
    padding-right: 24px;

    ${media.phone`
        justify-content: flex-start;
    `}
`;

export const PartnerRoleWrapper = styled.div`
    width: 90%;
    height: 90vh;

    background: ${({ theme }) => theme.colors.white};

    box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.2);
    border-radius: 8px;

    position: fixed;
    z-index: 10005;
    top: 5vh;
    left: 5%;

    ${media.tablet`
        width: 100%;
        height: 100%;
        max-height: 100vh;
        top: 0;
        left: 0;
        overflow-y: auto;
        border-radius: 0;
    `}
`;

export const BackgroundWrapper = styled.div`
    position: fixed;
    inset: 0;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(70, 80, 100, 0.5);
`;

export const InputWrapper = styled.div`
    width: 260px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 15px;

    & > div > * :first-child {
        padding-left: 25px;
    }

    ${media.phone`
        width: 100%;
        margin: 12px 0;
        & :first-child {
            width: 98%;
            margin-left: 9px;
            margin-right: 16px;
        }
    `}
`;

export const SearchStyled = styled(Search)`
    position: absolute;
    color: ${({ theme }) => theme.colors.dustBlue[800]};
    left: 15px;
    top: 15px;

    ${media.phone`
        left: 25px;
    `}
`;

export const IconWrapper = styled.div`
    height: 48px;
    width: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 16px;

    color: ${({ theme }) => theme.colors.dustBlue['900']};
    cursor: pointer;

    ${media.laptop`
       width: 38px;
       height: 38px;

       top: 10px;
    `}
`;

export const RightPart = styled.div`
    display: flex;
    align-items: center;
`;
