import styled, { css } from 'styled-components/macro';
import { Props } from '.';

export const Label = styled.label`
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    min-height: 19px;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray_40};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray_300};
`;

interface FieldProps {
    size?: Props['size'];
    width?: Props['width'];
    height?: Props['height'];
    isActive: Props['isActive'];
}

const INDENT_WIDTH = 6;

export const FieldWrap = styled.div<FieldProps>`
    margin-left: 6px;
    width: ${({ width }) => width || '100%'};

    ${({ size }) => {
        switch (size) {
            case 'col-1':
                return css`
                    width: calc(11% - ${INDENT_WIDTH}px);
                `;
            case 'col-2':
                return css`
                    width: calc(20% - ${INDENT_WIDTH}px);
                `;
            case 'col-3':
                return css`
                    width: calc(25% - ${INDENT_WIDTH}px);
                `;
            case 'col-4':
                return css`
                    width: calc(30% - ${INDENT_WIDTH}px);
                `;
            case 'col-6':
                return css`
                    width: calc(50% - ${INDENT_WIDTH}px);
                `;
            case 'col-8':
                return css`
                    width: calc(70% - ${INDENT_WIDTH}px);
                `;
            default:
                return css``;
        }
    }}

    ${({ isActive, theme }) =>
        isActive &&
        css`
            ${Label},
            ${Content} {
                color: ${theme.colors.gray_40};
            }
        `}

    ${({ height }) =>
        height &&
        css`
            ${Content} {
                height: auto;
            }
        `}
`;
