import React, { FC } from 'react';
import { useField } from 'formik';
import {
    DatepickerField,
    FormButton,
    InputField,
    RadioGroupField,
    SelectField,
    NumberInputField,
    BoxSwitcherField
} from '@wedat/ui-kit/Formik';

import { DateFieldWrapper, StyledTextareaField, TextStyled } from './styles';
import { DataField } from '../../types/dataScheme';
import { getFieldProps } from './getFieldProps';
import { AddressBookField } from './AddressBookField';
import { useTranslation } from 'react-i18next';
import { useOptions } from '../../hooks/useOptions';
import { GooglePlaces } from '@dat/smart-components/GooglePlaces';

interface Props {
    field: DataField;
    onBlurHandler: any;
}

//TODO: add styles
export const FieldItem: FC<Props> = ({ field, onBlurHandler }) => {
    const pluginOptions = useOptions();
    const { t } = useTranslation();
    const globalHandlers = pluginOptions?.globalHandlers?.[field.Type];

    const [{ value, name }] = useField(field.id);

    if (!field.id) return null;

    const props = {
        ...getFieldProps(field),
        ...globalHandlers,
        onBlur: () => {
            const eventSubstance = { target: { value, name } };
            onBlurHandler(eventSubstance, field);
            globalHandlers?.onBlur?.(eventSubstance);
        }
    };
    const label = t(`labels.${field.id}`, field.label);

    switch (field.Type) {
        case 'string':
        case 'integer':
            return <InputField {...props} label={label} />;
        case 'float':
            return <NumberInputField {...props} thousandSeparator="." label={label} />;
        case 'memo':
            return <StyledTextareaField {...props} label={label} />;
        case 'select':
            return <SelectField {...props} label={label} />;
        case 'boolean':
            return <BoxSwitcherField {...props} label={label} />;
        case 'radio':
            return (
                <>
                    <TextStyled fontSize="18px" lineHeight="27px" color="gray_300" textOverflow="ellipsis">
                        {label}
                    </TextStyled>
                    <RadioGroupField {...props} valueType="from-xml" />
                </>
            );
        case 'date':
            return (
                <DateFieldWrapper>
                    <DatepickerField {...props} configField={field} />
                </DateFieldWrapper>
            );
        case 'subject':
            return <AddressBookField field={field} />;
        case 'button':
            return <FormButton key={field.id}>{field.text}</FormButton>;
        case 'calculatedValue':
            return (
                <>
                    <TextStyled fontSize="18px" lineHeight="27px" color="gray_300" textOverflow="ellipsis">
                        {label}
                    </TextStyled>
                    <div key={field.id}>{field.text}</div>
                </>
            );
        case 'googlePlaces':
            return <GooglePlaces {...props} field={field} label={label} isCity />;

        case undefined:
            return <div key={field.id}>{label}</div>;
        default:
            return null;
    }
};
