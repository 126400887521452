export const getInitialValues = (formFields: any, formName: string) => {
    const currentFields = formFields[formName] || {};

    return Object.entries(currentFields).reduce(
        (acc, [name, object]: [string, any]) => ({
            ...acc,
            [name]: object?.initValue || ''
        }),
        {}
    );
};
