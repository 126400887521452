export const assign = Object.assign;
export const own = ({ graphite: owner }, links) => {
    for (const { graphite } of links) {
        graphite.family.type = 'crosslink';
        graphite.family.owners.push(owner);
        owner.family.links.push(graphite);
    }
};
export const setMeta = (unit, field, value) => {
    unit.graphite.meta[field] = value;
};
export const getForkPage = (val) => val.scope;
export const read = (scope) => ($store) => scope ? scope.getState($store) : $store.getState();
