import { combine, createEffect, createEvent, createStore, guard, sample } from 'effector';
import { FastTrackElementsStore } from '../types/fastTrackElements';
import { effectorLogger } from '@dat/core/utils';
import API2DAT from '@dat/api2dat/';
import { requestCredentialsModel } from './requestCredentialsModel';
import { Contract } from '@dat/api2dat/types/contractTypes';
import { FastTrackConfiguration } from '@dat/api2dat/types/fastTrackInterfaceTypes';
import { GenericSVGGraphic, genericSVGGraphicModel } from './genericSVGGraphicModel';
import { pluginOptionsModel } from './pluginOptionsModel';
import { i18n } from '../i18n';

// fastTrackElements

const $contract = createStore<Contract | null>(null);

const $datECode = pluginOptionsModel.stores.pluginOptions.map(pl => pl?.settings?.datECode);

const getContractFx = createEffect({
    async handler() {
        const contractId = $contractId.getState();
        const credentials = requestCredentialsModel.stores.requestCredentials.getState();
        if (!credentials) return null;
        return API2DAT.myClaimExternalService.getContract(credentials, { contractId });
    }
});
$contract.on(getContractFx.doneData, (_, contract) => contract);
const clearContract = createEvent<undefined>();
$contract.on(clearContract, _ => null);

const $fastTrackConfiguration = createStore<FastTrackConfiguration | null>(null);
const getFastTrackConfiguration = createEffect({
    async handler() {
        const contractId = $contractId.getState() || $contract.getState()?.Dossier.DossierId;
        if (!contractId) return;
        const credentials = requestCredentialsModel.stores.requestCredentials.getState();
        if (!credentials) return null;
        return API2DAT.fastTrackInterface.getFastTrackConfiguration(credentials, { contractId });
    }
});
$fastTrackConfiguration.on(getFastTrackConfiguration.doneData, (_, fastTrackConfiguration) => fastTrackConfiguration);
const setFastTrackConfiguration = createEvent<FastTrackConfiguration>();
$fastTrackConfiguration.on(setFastTrackConfiguration, (_, fastTrackConfiguration) => fastTrackConfiguration);

const $contractId = createStore<number>(0);
const setContractId = createEvent<number>();
$contractId.on(setContractId, (_, contractId) => contractId);

const getDamageFromClaim = createEffect({
    async handler() {
        const contractId = $contractId.getState() || $contract.getState()?.Dossier.DossierId;
        if (!contractId) return;
        const credentials = requestCredentialsModel.stores.requestCredentials.getState();
        if (!credentials) return null;
        return API2DAT.fastTrackInterface.loadFTClaim(credentials, { contractId });
    }
});
const saveFTClaim = createEffect({
    async handler() {
        const contractId = $contractId.getState() || $contract.getState()?.Dossier.DossierId;
        if (!contractId) return;
        const fastTrackElements = $fastTrackElements.getState();
        const credentials = requestCredentialsModel.stores.requestCredentials.getState();
        if (!credentials) return null;
        return API2DAT.fastTrackInterface.saveFTClaim(credentials, {
            contractId,
            fastTrack: { elements: fastTrackElements.selectedElements }
        });
    }
});

const calculateFastTrack = createEffect({
    async handler() {
        const contractId = $contractId.getState() || $contract.getState()?.Dossier.DossierId;
        if (!contractId) return;
        const credentials = requestCredentialsModel.stores.requestCredentials.getState();
        if (!credentials) return null;
        return API2DAT.fastTrackInterface.calculateFastTrack(credentials, {
            contractId
        });
    }
});

const $fastTrackElements = createStore<FastTrackElementsStore>({
    selectedElements: [],
    selectedElementsModified: false,
    // markElementId: 'DATID_0003'
    markElementId: ''
});

const setMarkElementId = createEvent<string>();
const setDamageIfOneElseMarkElementId = createEvent<string>();
const setFastTrackDamage = createEvent<{ damageType: string; elementId: string }>();
const delFastTrackDamage = createEvent<string>();
const setSelectedElementsModified = createEvent<boolean>();
const clearFastTrackDamage = createEvent<undefined>();

interface AvailableDamages {
    datId: string;
    groupName: string;
    damageid: string;
    damageLabel: string;
    damageTranslation: string;
}
export function getAvailableDamagesByDATID(
    datId: string,
    fastTrackConfiguration: FastTrackConfiguration
): AvailableDamages[] {
    let groupName = fastTrackConfiguration.fastTrackGroups[datId];
    let damageGroupList = fastTrackConfiguration.fastTrackDamages[datId];
    if (!damageGroupList && !!groupName) {
        damageGroupList = fastTrackConfiguration.fastTrackDamages[groupName];
    }
    if (!damageGroupList) {
        damageGroupList = fastTrackConfiguration.fastTrackDamages['default'];
    }

    const { language } = i18n;

    return damageGroupList.map(damage => {
        let damageLabel = '';
        let damageTranslation = '';
        let damageid = '';

        if (typeof damage === 'string') {
            damageid = damage;
            damageLabel = damage;
            damageTranslation = i18n.t('fastLocalNameDamages.' + damage, damage);
        }

        if (typeof damage === 'object') {
            damageid = damage.id;
            if (typeof damage.label !== 'string') {
                damageLabel = damage.label[language] || damage.label['en'];
            } else {
                damageLabel = damage.label;
            }

            damageTranslation = i18n.t('fastLocalNameDamages.' + damageid, damageLabel || damageid);
        }

        return {
            datId,
            groupName,
            damageid,
            damageLabel,
            damageTranslation
        };
    });
}

setDamageIfOneElseMarkElementId.watch(datid => {
    const fastTrackConfiguration = $fastTrackConfiguration.getState();
    if (!fastTrackConfiguration) return [];
    const availableDamages = getAvailableDamagesByDATID(datid, fastTrackConfiguration);

    if (availableDamages.length === 1) {
        setFastTrackDamage({ elementId: datid, damageType: availableDamages[0].damageid });
        return;
    }
    setMarkElementId(datid);
});

$fastTrackElements.on(getDamageFromClaim.doneData, (oldState, LoadFTClaimResult) => ({
    ...oldState,
    selectedElements: LoadFTClaimResult?.elements ? LoadFTClaimResult?.elements : []
}));

$fastTrackElements
    .on(setSelectedElementsModified, (oldState, selectedElementsModified) => ({
        ...oldState,
        selectedElementsModified
    }))
    .on(clearContract, oldState => ({
        ...oldState,
        selectedElements: [],
        selectedElementsModified: false
    }))
    .on(setMarkElementId, (oldState, markElementId) => ({
        ...oldState,
        markElementId
    }))
    .on(setFastTrackDamage, (oldState, { damageType, elementId }) => {
        let selectedElements = [...oldState.selectedElements];
        const findElement = selectedElements.find(el => el.elementId === elementId);
        if (findElement) {
            // if (findElement.damageType === damageType) {
            //     // delete from list
            //     selectedElements = oldState.selectedElements.filter(el => el.elementId !== elementId);
            // } else {
            findElement.damageType = damageType;
            // }
        } else selectedElements.push({ damageType, elementId, userComment: '' });
        return {
            ...oldState,
            selectedElements,
            selectedElementsModified: true
        };
    })
    .on(delFastTrackDamage, (oldState, elementId) => {
        const selectedElements = oldState.selectedElements.filter(el => el.elementId !== elementId);
        return {
            ...oldState,
            selectedElements,
            selectedElementsModified: true
        };
    })
    .on(clearFastTrackDamage, oldState => ({
        ...oldState,
        selectedElements: [],
        selectedElementsModified: true
    }));

const $fastTrackSVG = createStore<GenericSVGGraphic | null>(null);
const setFastTrackSVG = createEvent<GenericSVGGraphic | null | undefined>();
$fastTrackSVG.on(setFastTrackSVG, (_, newVal) => newVal);

const nextFastTrackSVG = createEvent<undefined>();
sample({
    source: combine(genericSVGGraphicModel.stores.genericSVGGraphics, $fastTrackSVG),
    clock: nextFastTrackSVG,
    fn: ([genericSVGGraphics, fastTrackSVG]) => {
        if (genericSVGGraphics && genericSVGGraphics.length > 0) {
            const findedIndex = genericSVGGraphics.findIndex(el => el.graphicId === fastTrackSVG?.graphicId);
            if (findedIndex >= 0)
                return genericSVGGraphics[findedIndex + 1 === genericSVGGraphics.length ? 0 : findedIndex + 1];
            return genericSVGGraphics[0];
        }
    },
    target: setFastTrackSVG
});

const prevFastTrackSVG = createEvent<undefined>();
sample({
    source: combine(genericSVGGraphicModel.stores.genericSVGGraphics, $fastTrackSVG),
    clock: prevFastTrackSVG,
    fn: ([genericSVGGraphics, fastTrackSVG]) => {
        if (genericSVGGraphics && genericSVGGraphics.length > 0) {
            const findedIndex = genericSVGGraphics.findIndex(el => el.graphicId === fastTrackSVG?.graphicId);
            if (findedIndex >= 0)
                return genericSVGGraphics[findedIndex === 0 ? genericSVGGraphics.length - 1 : findedIndex - 1];
            return genericSVGGraphics[0];
        }
    },
    target: setFastTrackSVG
});

const setFirstFastTrackSVG = createEvent<undefined>();
sample({
    source: genericSVGGraphicModel.stores.genericSVGGraphics,
    clock: setFirstFastTrackSVG,
    fn: genericSVGGraphics => {
        if (genericSVGGraphics && genericSVGGraphics.length > 0) {
            return genericSVGGraphics[0];
        }
    },
    target: setFastTrackSVG
});

const setFastTrackSVGById = createEvent<string>();
sample({
    source: genericSVGGraphicModel.stores.genericSVGGraphics,
    clock: setFastTrackSVGById,
    fn: (genericSVGGraphics, graphicId) => {
        if (genericSVGGraphics && genericSVGGraphics.length > 0) {
            return genericSVGGraphics.find(grap => grap.graphicId === graphicId) || null;
        }
    },
    target: setFastTrackSVG
});

const setAiMode = createEvent<boolean>();
const $aiMode = createStore(false).on(setAiMode, (_, newVal) => newVal);

const saveFTClaimLastWithWaiting = createEvent();

const saveFTClaimQueueIsPresent = createStore(false);
const setSaveFTClaimQueueIsPresent = createEvent();
const clearSaveFTClaimQueueIsPresent = createEvent();
saveFTClaimQueueIsPresent.on(setSaveFTClaimQueueIsPresent, _ => true);
saveFTClaimQueueIsPresent.on(clearSaveFTClaimQueueIsPresent, _ => false);

guard({
    source: saveFTClaimLastWithWaiting,
    filter: saveFTClaim.pending.map(val => !val),
    target: saveFTClaim
});
guard({
    source: saveFTClaimLastWithWaiting,
    filter: saveFTClaim.pending,
    target: setSaveFTClaimQueueIsPresent
});
guard({
    source: saveFTClaim.finally,
    filter: saveFTClaimQueueIsPresent,
    target: [saveFTClaim, clearSaveFTClaimQueueIsPresent]
});

export const fastTrackElementModel = {
    stores: {
        $contractId,
        $contract,
        $fastTrackElements,
        $fastTrackConfiguration,
        $fastTrackSVG,
        $datECode,
        $aiMode
    },
    events: {
        setMarkElementId,
        setFastTrackDamage,
        setDamageIfOneElseMarkElementId,
        delFastTrackDamage,
        setSelectedElementsModified,
        clearFastTrackDamage,
        setContractId,
        clearContract,
        setFastTrackConfiguration,
        nextFastTrackSVG,
        prevFastTrackSVG,
        setFirstFastTrackSVG,
        setFastTrackSVGById,
        setFastTrackSVG,
        saveFTClaimLastWithWaiting,
        setAiMode
    },

    effects: {
        getDamageFromClaim,
        saveFTClaim,
        calculateFastTrack,
        getContractFx,
        getFastTrackConfiguration
    }
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(fastTrackElementModel.stores);
    effectorLogger(fastTrackElementModel.events);
    effectorLogger(fastTrackElementModel.effects);
}
