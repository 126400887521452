import { FC, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { VehicleSelectionModal, VehicleSelectionModalPluginOptions } from '@dat/vehicle-selection-modal';
import { useTranslation } from 'react-i18next';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { configurationStores } from '../../stores/configuration/';
import { containedPluginsEvents } from '../../stores/containedPlugins';
import { modalsEvents, modalsStores } from '../../stores/ui/modals';
import { lightboxStores } from '../../stores/lightbox';
import { PluginCars } from '../../components/PluginCars';
import { pluginCarsEvents } from '../../stores/pluginCars';
import { Attachments } from '../../components/Attachments';
import { Lightbox } from '@wedat/ui-kit/components/Lightbox';
import { pluginEvents as vehicleSelectionPluginEvents } from '@dat/vehicle-selection-modal/src/stores/plugin';

import {
    ButtonSubmit,
    ButtonWrapper,
    Content,
    Root,
    StyledButton,
    StyledSuccessIcon,
    SucceccModalDescription,
    SucceccModalTitle,
    SuccessButton,
    SuccessModalContent,
    Wrapper
} from './styles';
import { Upload } from '../../components/Upload';
import { attachmentsStores } from '../../stores/attachments';
import { contractEvents } from '../../stores/contract';
import { pluginStores } from '../../stores/plugin';

import { ProcessedModal } from '../../components/ProcessedModal';

const { configuration } = configurationStores;

export const AiClaim: FC = () => {
    const [isOpenProccessModal, setIsOpenProccessModal] = useState(false);
    const { t } = useTranslation();
    const config = useStore(configuration);
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const isOpenAIClaimModal = useStore(modalsStores.isOpenAIClaimModal);
    const attachments = useStore(attachmentsStores.attachments);
    const lightboxState = useStore(lightboxStores.lightboxState);
    const isOpenSuccessModal = useStore(modalsStores.isOpenSuccessModal);

    const handleDismiss = () => {
        modalsEvents.toggleIsOpenAIClaimModal(false);
    };

    const handleOpenPluginCars = () => {
        pluginCarsEvents.openPluginCars();
        modalsEvents.toggleIsOpenAIClaimModal(false);
    };

    const handleCloseSuccessModal = () => {
        modalsEvents.toggleSuccessModal(false);
    };

    const handleSubmitClaim = () => {
        setIsOpenProccessModal(true);
        contractEvents.submitAiClaim();
        modalsEvents.toggleIsOpenAIClaimModal(false);
    };

    const options = useMemo<VehicleSelectionModalPluginOptions>(
        () => ({
            onComplete: args => {
                containedPluginsEvents.vehicleSelectionCompleted(args);
                pluginOptions?.onDismiss?.();
            },
            onDismiss: () => {
                pluginOptions?.onDismiss?.();
                vehicleSelectionPluginEvents.resetPlugin();
            },
            isOpen: pluginOptions?.isOpen,
            isModal: true,
            buttonText: {
                finishButton: t('next')
            }
        }),
        [pluginOptions, t]
    );

    return (
        <Root>
            <VehicleSelectionModal options={options} />
            <ModalPrimary
                maxWidth="832px"
                // TODO: delete zIndex
                zIndex={10001}
                fullWidth
                isOpen={isOpenAIClaimModal}
                onDismiss={handleDismiss}
                footerChildren={
                    <ButtonWrapper>
                        <ButtonSubmit disabled={attachments.length === 0} onClick={handleSubmitClaim}>
                            {t('send')}
                        </ButtonSubmit>
                    </ButtonWrapper>
                }
            >
                <StyledButton onClick={handleOpenPluginCars}>{t('guidedPhotoCapture')}</StyledButton>
                <Content>
                    <Wrapper>
                        <Upload />
                    </Wrapper>
                    <Attachments />
                </Content>
            </ModalPrimary>

            {config.sync && <ProcessedModal isOpen={isOpenProccessModal} setIsOpen={setIsOpenProccessModal} />}

            <ModalPrimary
                // TODO: delete zIndex
                zIndex={10001}
                bodyHeight="480px"
                maxWidth="536px"
                fullWidth
                isOpen={isOpenSuccessModal}
                onDismiss={handleCloseSuccessModal}
            >
                <SuccessModalContent>
                    <StyledSuccessIcon />
                    <SucceccModalTitle>{t('successTitle')}</SucceccModalTitle>
                    <SucceccModalDescription>{t('successDescription')}</SucceccModalDescription>
                    <SuccessButton onClick={handleCloseSuccessModal}>{t('close')}</SuccessButton>
                </SuccessModalContent>
            </ModalPrimary>

            <Lightbox {...lightboxState} />
            <PluginCars />
        </Root>
    );
};
