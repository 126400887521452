import React, { FC } from 'react';

import { Text } from '@wedat/ui-kit/components/Text';
import { Container, TextWrapper } from './styles';

interface Props {
    date: string;
}

export const DateMessageDivider: FC<Props> = ({ date }) => (
    <Container>
        <TextWrapper>
            <Text color="gray_40">{date}</Text>
        </TextWrapper>
    </Container>
);
