import { FC, ReactEventHandler } from 'react';
import { ButtonStyled } from './styles';

interface Props {
    onClick?: ReactEventHandler<HTMLButtonElement>;
    type?: 'primary' | 'secondary';
}

export const Button: FC<Props> = ({ children, onClick, type = 'primary' }) => (
    <ButtonStyled buttonType={type} onClick={onClick}>
        {children}
    </ButtonStyled>
);
