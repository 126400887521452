import { attach, createApi, createEffect, createStore } from 'effector';

import * as reducers from './reducers';
import { API2 } from '@dat/api2';
import { getParsedArraySafe } from '@dat/api2/utils';
import { pendingParams } from '@dat/core/utils/effector/pendingParams';

import { AllVehiclesImages, PayloadForGetVehicleImages } from './types';

const allVehiclesImages = createStore<AllVehiclesImages>({});
const allVehiclesImagesApi = createApi(allVehiclesImages, reducers.allVehiclesImages);

const _getVehicleImagesFx = createEffect(async ({ imageType = 'PICTURE', ...rest }: PayloadForGetVehicleImages) => {
    const response = await API2.vehicleSelection.getVehicleImagesN({ imageType, ...rest });

    return getParsedArraySafe(response.vehicleImagesN?.images);
});
const getVehicleImagesFx = attach({
    source: allVehiclesImages,
    effect: (allVehiclesImages, payload: PayloadForGetVehicleImages) =>
        allVehiclesImages[payload.datECode]?.[payload.aspect] || _getVehicleImagesFx(payload)
});

const datECodesFetching = pendingParams({ effect: getVehicleImagesFx, mapParams: params => String(params.datECode) });

export const sharedVehiclesImagesEvents = {
    ...allVehiclesImagesApi
};
export const sharedVehiclesImagesEffects = {
    getVehicleImagesFx
};
export const sharedVehiclesImagesStores = {
    allVehiclesImages,
    datECodesFetching
};
