import { combine } from 'effector';

import { PluginCarsOptions } from '@dat/plugin-cars';
import { PluginCarsGroupStep, PluginCarsResult } from '../../types/pluginCars';
import { modalsEvents as localModalsEvents } from '../../stores/ui/modals';
import { domain } from '../plugin';

const pluginCarsCompleted = domain.createEvent<PluginCarsResult>();
const photosReceived = domain.createEvent<any>();
const steps = domain.createStore<PluginCarsGroupStep[]>([]);

const pluginCarsOptions = combine(
    steps,
    (steps): PluginCarsOptions => ({
        // selector is used because plugin-cars takes size of container by it's selector
        selector: '#plugin-cars-ai-claim-container',
        isComponent: true,
        steps,
        completeFormCallback: pluginCarsCompleted,
        withSummary: false // disable additional steps
    })
);

const isPluginCarsAvailable = steps.map(steps => !!steps.length);
const openPluginCars = domain.createEvent();
const closePluginCars = domain.createEvent();
const isPluginCarsOpen = domain
    .createStore(false)
    .on(openPluginCars, () => true)
    .on(closePluginCars, () => {
        localModalsEvents.toggleIsOpenAIClaimModal(true);
        return false;
    })
    .reset(pluginCarsCompleted);

export const pluginCarsEvents = {
    pluginCarsCompleted,
    photosReceived,
    openPluginCars,
    closePluginCars
};
export const pluginCarsStores = {
    steps,
    pluginCarsOptions,
    isPluginCarsAvailable,
    isPluginCarsOpen
};
