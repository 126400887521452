import { Scheduler } from '@dat/scheduler';
import { Header } from '@dat/smart-components/Header';
import { ArrowBack } from '@wedat/ui-kit/components/Icons';

import { useHistory } from 'react-router';
import { useStore } from 'effector-react';

import { IconWrapper } from '../../Administration/style';
import { contractStores } from '@dat/shared-models/contract';

export const SchedulerPage = () => {
    const options: DAT2.Plugins.PluginBaseOptions = {
        isComponent: true
    };
    const history = useHistory();
    const contractId = useStore(contractStores.contractId);

    const handleGoBack = (): void => {
        history.push('/');
    };
    return (
        <>
            {contractId === 0 && (
                <>
                    <Header />
                    <IconWrapper onClick={handleGoBack}>
                        <ArrowBack />
                    </IconWrapper>
                </>
            )}
            <Scheduler options={options} />
        </>
    );
};
