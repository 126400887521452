import styled, { css } from 'styled-components/macro';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { isIOS } from 'react-device-detect';
import { media } from '@wedat/ui-kit/mediaQueries';

interface FixedFooterProps {
    high?: boolean;
    disableBurgerMenu?: boolean;
    scroll?: boolean;
}
interface ItemProps {
    disabled?: boolean;
    menuFillWidth?: boolean;
}

export const FooterMobileStyled = styled.div<FixedFooterProps>`
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${({ high }) => (high ? '76px' : '56px')};

    ${({ high }) =>
        isIOS &&
        css`
            height: ${high ? '96px' : '76px'};
        `}
`;

export const Container = styled.div`
    position: absolute;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 0px 16px rgba(0, 0, 0, 0.08);
`;

export const ScrollableMenu = styled.div<FixedFooterProps>`
    display: flex;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.gray['50']};
    height: 100%;
    width: 100%;
    align-items: center;
    box-shadow: 1px 0px ${({ theme }) => theme.colors.gray['300']};
    min-width: 80vw;

    ${media.laptopSmall`
        justify-content: space-between;
    `}

    ${({ scroll }) =>
        scroll &&
        css`
            justify-content: space-between;
            overflow-x: scroll;
        `}

    &::-webkit-scrollbar {
        display: none;
    }

    ${({ disableBurgerMenu }) =>
        disableBurgerMenu &&
        css`
            justify-content: space-around;

            ${media.laptopSmall`
                justify-content: space-around;
            `}
        `}
`;

export const FooterMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled.div`
    width: 28px;
    height: 28px;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: currentColor;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const MenuIcon = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: currentColor;
    margin-right: 12px;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const Item = styled.div<ItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 48px;
    min-height: 40px;
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        color: ${({ theme }) => theme.colors.blue['700']};
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.colors.dustBlue['200']};
            pointer-events: none;
        `}

    ${({ menuFillWidth }) =>
        menuFillWidth &&
        css`
            width: 100%;
            &:hover {
                background-color: unset;
                color: unset;
            }
        `}

    ${media.laptopSmall`
        margin-left: 6px;
        margin-right: 6px;
    `}
`;

export const ItemLink = styled(NavLink).attrs(
    ({ theme }): Partial<NavLinkProps> => ({
        activeStyle: {
            backgroundColor: `${theme.colors.blue['50']}`,
            color: `${theme.colors.blue['700']}`,
            borderBottom: 'none'
        }
    })
)<{ disabled?: boolean }>`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 48px;
        height: 40px;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 4px;
        color: ${disabled ? theme.colors.dustBlue['200'] : theme.colors.dustBlue['900']};

        &:first-child {
            margin-left: 14px;
        }

        ${disabled &&
        css`
            pointer-events: none;
        `}

        ${media.tabletSmall`
            margin-left: 6px;
            margin-right: 6px;

            &:first-child {
                margin-left: 12px;
            }
        `}
    `}
`;

export const MenuItem = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 44px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        color: ${({ theme }) => theme.colors.blue['700']};
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.colors.dustBlue['200']};
            pointer-events: none;
        `}
`;

export const MenuItemLink = styled(NavLink).attrs(
    ({ theme }): Partial<NavLinkProps> => ({
        activeStyle: {
            backgroundColor: `${theme.colors.blue['50']}`,
            color: `${theme.colors.blue['700']}`,
            borderBottom: 'none'
        }
    })
)<{ disabled?: boolean }>`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        height: 44px;
        color: ${disabled ? theme.colors.dustBlue['200'] : theme.colors.dustBlue['900']};

        ${disabled &&
        css`
            pointer-events: none;
        `}
    `}
`;

export const MenuContainer = styled.div`
    padding: 16px;
`;
