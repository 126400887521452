import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { vehicleIdentificationStores } from '../../stores/vehicleIdentification';

import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import { ModalTextFieldContainer, ModalTextLabel, ModalTextValue } from './styles';
import { contractStores } from '../../stores/contract';
import { vehicleOCRStores } from '../../stores/ocr';

export const RegistrationNumberRequestResultModal: FC<ModalProps> = ({ isOpen, onDismiss, ...rest }) => {
    const { t } = useTranslation();
    const identifiedVehicleBookInfo = useStore(vehicleIdentificationStores.registrationNumberRequestResult);
    const vehicleFromContract = useStore(contractStores.vehicleFromContract);
    const plateReaderResult = useStore(vehicleOCRStores.plateReaderResult);

    const OCRfromPlateReaderResult =
        plateReaderResult && Object.entries(plateReaderResult).map(([key, value]) => ({ key, value }));
    const OCRResult = OCRfromPlateReaderResult?.length ? OCRfromPlateReaderResult : vehicleFromContract?.OCRResult;

    let vin, initialRegistration, engineCode, lastRevisionDate, licencePlate, modelPermissionNumber;

    if (vehicleFromContract.vinResult?.registrationNumberRequestResult) {
        ({ vin, initialRegistration, engineCode, lastRevisionDate, licencePlate, modelPermissionNumber } =
            vehicleFromContract.vinResult.registrationNumberRequestResult);
    }

    if (identifiedVehicleBookInfo) {
        ({ vin, initialRegistration, engineCode, lastRevisionDate, licencePlate, modelPermissionNumber } =
            identifiedVehicleBookInfo);
    }

    return (
        <ModalPrimary
            {...rest}
            isOpen={isOpen}
            title={t('registrationNumberRequestResultModalTitle')}
            onDismiss={onDismiss}
        >
            {licencePlate && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('registrationNumberRequestResultModal.registrationNumberLabel')}</ModalTextLabel>
                    <ModalTextValue>{licencePlate}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {vin && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('registrationNumberRequestResultModal.vinLabel')}</ModalTextLabel>
                    <ModalTextValue>{vin}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {modelPermissionNumber && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('registrationNumberRequestResultModal.approvalNumberLabel')} </ModalTextLabel>
                    <ModalTextValue>{modelPermissionNumber}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {engineCode && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('registrationNumberRequestResultModal.engineCodeLabel')}</ModalTextLabel>
                    <ModalTextValue>{engineCode}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {initialRegistration && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('registrationNumberRequestResultModal.firstRegistrationLabel')}</ModalTextLabel>
                    <ModalTextValue>{new Date(initialRegistration).toLocaleDateString('fr')}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {lastRevisionDate && (
                <ModalTextFieldContainer>
                    <ModalTextLabel>
                        {t('registrationNumberRequestResultModal.lastTechnicalInspectionLabel')}
                    </ModalTextLabel>
                    <ModalTextValue>{new Date(lastRevisionDate).toLocaleDateString('fr')}</ModalTextValue>
                </ModalTextFieldContainer>
            )}
            {OCRResult?.map(
                item =>
                    item?.value && (
                        <ModalTextFieldContainer>
                            <ModalTextLabel>{t(`registrationNumberRequestResultModal.${item?.key}`)}</ModalTextLabel>
                            <ModalTextValue>{item?.value}</ModalTextValue>
                        </ModalTextFieldContainer>
                    )
            )}
        </ModalPrimary>
    );
};
