import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, ArrowExpand } from '@wedat/ui-kit/components/Icons';
import { StyledHeader, TitleContainer, IconWrapper } from './styles';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    handleClick: () => void;
    isClicked: boolean;
}

export const Header: React.FC<Props> = ({ handleClick, isClicked }) => {
    const { t } = useTranslation();

    return (
        <StyledHeader>
            <TitleContainer>
                <Text font="mobileHeader">{t('page.header.title')}</Text>
                <IconWrapper onClick={handleClick}>{isClicked ? <ArrowExpand /> : <ArrowDownIcon />}</IconWrapper>
            </TitleContainer>
        </StyledHeader>
    );
};
