import React from 'react';

const Calendar = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.5 14H16.5C16.1022 14 15.7206 14.158 15.4393 14.4393C15.158 14.7206 15 15.1022 15 15.5V16.5C15 16.8978 15.158 17.2794 15.4393 17.5607C15.7206 17.842 16.1022 18 16.5 18H17.5C17.8978 18 18.2794 17.842 18.5607 17.5607C18.842 17.2794 19 16.8978 19 16.5V15.5C19 15.1022 18.842 14.7206 18.5607 14.4393C18.2794 14.158 17.8978 14 17.5 14ZM18 16.5C18 16.6326 17.9473 16.7598 17.8536 16.8536C17.7598 16.9473 17.6326 17 17.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V15.5C16 15.3674 16.0527 15.2402 16.1464 15.1464C16.2402 15.0527 16.3674 15 16.5 15H17.5C17.6326 15 17.7598 15.0527 17.8536 15.1464C17.9473 15.2402 18 15.3674 18 15.5V16.5ZM19.5 4H18V2.5C18 2.36739 17.9473 2.24021 17.8536 2.14645C17.7598 2.05268 17.6326 2 17.5 2C17.3674 2 17.2402 2.05268 17.1464 2.14645C17.0527 2.24021 17 2.36739 17 2.5V4H7V2.5C7 2.36739 6.94732 2.24021 6.85355 2.14645C6.75979 2.05268 6.63261 2 6.5 2C6.36739 2 6.24021 2.05268 6.14645 2.14645C6.05268 2.24021 6 2.36739 6 2.5V4H4.5C3.83696 4 3.20107 4.26339 2.73223 4.73223C2.26339 5.20107 2 5.83696 2 6.5V18.5C2 19.163 2.26339 19.7989 2.73223 20.2678C3.20107 20.7366 3.83696 21 4.5 21H19.5C20.163 21 20.7989 20.7366 21.2678 20.2678C21.7366 19.7989 22 19.163 22 18.5V6.5C22 5.83696 21.7366 5.20107 21.2678 4.73223C20.7989 4.26339 20.163 4 19.5 4ZM21 18.5C21 18.8978 20.842 19.2794 20.5607 19.5607C20.2794 19.842 19.8978 20 19.5 20H4.5C4.10218 20 3.72064 19.842 3.43934 19.5607C3.15804 19.2794 3 18.8978 3 18.5V9H19.5C19.6326 9 19.7598 8.94732 19.8536 8.85355C19.9473 8.75979 20 8.63261 20 8.5C20 8.36739 19.9473 8.24021 19.8536 8.14645C19.7598 8.05268 19.6326 8 19.5 8H3V6.5C3 6.10218 3.15804 5.72064 3.43934 5.43934C3.72064 5.15804 4.10218 5 4.5 5H19.5C19.8978 5 20.2794 5.15804 20.5607 5.43934C20.842 5.72064 21 6.10218 21 6.5V18.5Z"
            fill="currentColor"
        />
    </svg>
);

export default Calendar;
