import { FC, useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

// Components
import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import { ButtonStyled, Footer, InputWrapper, TextareaStyled, Container } from './styles';

// Stores
import { contractEffects, contractStores } from '@dat/shared-models/contract';

// Assets
import { FormikValues } from '../../types/plugin';
import { retrieveField } from '../../utils/retrieveFields';

interface Props extends ModalProps {
    position: string[];
    useWithoutApi: boolean;
    onDescriptionSubmit: (values: FormikValues) => void;
}

export const DamageModal: FC<Props> = ({ isOpen, onDismiss, useWithoutApi, onDescriptionSubmit }) => {
    const { t } = useTranslation();
    const [damageDescription, setDamageDescription] = useState<string>('');
    const [accidentDescription, setAccidentDescription] = useState<string>('');
    const contract = useStore(contractStores.contract);

    const handleSubmit = useCallback(
        async ({ accidentDescription, damageDescription }: FormikValues) => {
            if (!useWithoutApi) {
                await contractEffects.updateCurrentContractFx({
                    templateData: {
                        entry: [
                            {
                                key: 'accidentDescription',
                                value: {
                                    _text: accidentDescription
                                }
                            },
                            {
                                key: 'damageDescription',
                                value: {
                                    _text: damageDescription
                                }
                            }
                        ]
                    }
                });
            }

            setDamageDescription(accidentDescription);
            setAccidentDescription(accidentDescription);
            onDescriptionSubmit({ accidentDescription, damageDescription });
            onDismiss();
        },
        [onDismiss, useWithoutApi, onDescriptionSubmit]
    );

    useEffect(() => {
        if (contract) {
            const currDamageDescription = retrieveField(contract, 'damageDescription');
            const currAccidentDescription = retrieveField(contract, 'accidentDescription');
            setDamageDescription(currDamageDescription);
            setAccidentDescription(currAccidentDescription);
        }
    }, [contract]);

    return (
        <ModalPrimary
            bodyHeight="650px"
            bodyWidth="500px"
            isOpen={isOpen}
            bodyNoPadding={true}
            onDismiss={onDismiss}
            title={t('damageSelector.damageModalTitle')}
        >
            <Container>
                <Formik
                    initialValues={{
                        damageDescription,
                        accidentDescription
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ errors }) => (
                        <Form>
                            <InputWrapper>
                                <TextareaStyled
                                    label={t('damageSelector.accidentDescription')}
                                    helperText={errors.accidentDescription}
                                    name="accidentDescription"
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <TextareaStyled
                                    helperText={errors.damageDescription}
                                    name="damageDescription"
                                    label={t('damageSelector.damageDescription')}
                                />
                            </InputWrapper>
                            <Footer>
                                <ButtonStyled type="submit">{t('damageSelector.send')}</ButtonStyled>
                            </Footer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </ModalPrimary>
    );
};
