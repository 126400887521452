import { sendCommonSoapRequest } from '../utils/soapRequest';
// import { convertToArrayIfNot } from '../utils/arrayConvert';
import { decodeXml } from '../utils/xmlSpecialCharacters';

import {
    GetAssemblyGroupsParams,
    GetAssemblyGroupGraphicParams,
    AssemblyGroup,
    ContractIDParams,
    Credentials,
    GetDVNEquipmentsParams,
    ResultGetDVNEquipments,
    CalculateParams
} from '../types/api2datTypes';
import { datProdServicesUrls } from '../baseParameters';

// VehicleRepairService
// https://www.dat.de/myClaim/soap/v2/VehicleRepairService?wsdl

const urlVehicleRepairService =
    datProdServicesUrls.soapVehicleRepairServiceUrl || 'https://www.dat.de/myClaim/soap/v2/VehicleRepairService';

export async function getAssemblyGroups(
    credentials: Credentials,
    params: GetAssemblyGroupsParams,
    url?: string
): Promise<AssemblyGroup[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlVehicleRepairService,
        'getAssemblyGroups',
        credentials,
        params,
        ['getAssemblyGroupsResponse.AssemblyGroup']
    );
    return SoapResult.Envelope.Body.getAssemblyGroupsResponse.AssemblyGroup;
}

export async function getAssemblyGroupGraphic(
    credentials: Credentials,
    params: GetAssemblyGroupGraphicParams,
    url?: string
): Promise<string> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlVehicleRepairService,
        'getAssemblyGroupGraphic',
        credentials,
        params
    );
    return decodeXml(SoapResult.Envelope.Body.getAssemblyGroupGraphicResponse.SVG);
}

// todo require to define return type in ts
export async function calculate({
    credentials,
    params,
    url
}: {
    credentials: Credentials;
    params: CalculateParams;
    url?: string;
}) {
    const SoapResult = await sendCommonSoapRequest(url || urlVehicleRepairService, 'calculate', credentials, {
        request: params
    });
    return SoapResult.Envelope.Body.calculateResponse.calculationResult;
}

export async function calculateContract(credentials: Credentials, params: ContractIDParams, url?: string) {
    const SoapResult = await sendCommonSoapRequest(url || urlVehicleRepairService, 'calculateContractN', credentials, {
        request: params
    });
    return SoapResult.Envelope.Body.calculateContractNResponse.calculationResult;
}

export async function getDVNEquipments({
    credentials,
    params,
    url
}: {
    credentials: Credentials;
    params: GetDVNEquipmentsParams;
    url?: string;
}): Promise<ResultGetDVNEquipments[]> {
    if (!params.datProcessIds.length) return [];

    const paramsRequest = { request: params };
    const SoapResult = await sendCommonSoapRequest(
        url || urlVehicleRepairService,
        'getDVNEquipments',
        credentials,
        paramsRequest,
        ['getDVNEquipmentsResponse.dvnEquipmentsResult', 'dvnEquipmentsResult.equipments']
    );

    return SoapResult.Envelope.Body.getDVNEquipmentsResponse.dvnEquipmentsResult;

    // const result: ResultGetDVNEquipments[] = convertToArrayIfNot(
    //     SoapResult.Envelope.Body.getDVNEquipmentsResponse.dvnEquipmentsResult
    // );

    // return result.map(item => ({ ...item, equipments: !item.equipments ? [] : convertToArrayIfNot(item.equipments) }));
}
