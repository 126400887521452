/*
 * Contract (claim) - is the main entity of all data in DAT.
 *
 * Nested folder-structure in this directory mimics the structure of a contract itself,
 * so if you want to get e.g. `contract.Dossier.Vehicle` you should go to "contract/Dossier/Vehicle/" folder.
 * Most complete spec for contract's structure can be found in SoapUI project (e.g. createOfUpdateContract method)
 *
 * All nested stores containing data from contract should be mapped from main `contract` store.
 * We are using this approach because it allows to update only one `contract` store (usually via `getContract`)
 * without worrying about nested stores updates - they're updated automatically.
 * (!) Mapped stores can't be updated manually (effector will warn you in console if you do so).
 * If you need to update such store, you should update it's parent.
 *
 * (!) Be careful with stores that don't depend on `contract`, because they keep their state
 * throughout the whole lifecycle of the app, unless you manually clear them.
 * Uncleared state can cause significant impact on memory. Example: `allVehiclesImages`.
 */
import { attach, createEffect, createEvent, createStore, guard, restore } from 'effector';
import { createReEffect } from 'effector-reeffect';

import { API2 } from '@dat/api2';
import { getParsedArraySafe } from '@dat/api2/utils';

import {
    PartialPayloadForCreateOrUpdateContract,
    PayloadForCreateContract,
    PayloadForUpdateContract,
    PayloadForUpdateCurrentContract,
    PayloadForCalculateContract,
    PayloadForCalculateCurrentContract
} from './types';

const createOrUpdateContractFx = createEffect<PartialPayloadForCreateOrUpdateContract, DAT2.ContractId>();
const createContractFx = createEffect<PayloadForCreateContract, DAT2.ContractId>();
const updateContractFx = attach({
    effect: createOrUpdateContractFx,
    mapParams: (payload: PayloadForUpdateContract) => payload
});
const getContractFx = createEffect(async (contractId: number | string) => {
    contractId = +contractId;
    if (isNaN(contractId)) throw new Error(`${getContractFx.name}: contractId must be number`);

    const response = await API2.myClaim.getContract({ contractId });
    const contract = response.return;

    if (!contract) throw new Error(`${getContractFx.name}: bad response`);

    return contract;
});
const getContractAfterCalculateFx = createEffect(getContractFx);

const contractReceived = getContractFx.doneData;
const contract = restore(contractReceived, null);
const resetContract = createEvent();

contract.reset(resetContract);

const isContractReceived = contract.map(Boolean);
const contractId = contract.map(contract => contract?.Dossier?.DossierId || 0);

const updateInitialContract = createEvent();

const newContractReceived = guard({
    clock: [contractId.updates, updateInitialContract],
    source: contract,
    filter: Boolean
});
const updateCurrentContractFx = createReEffect({
    strategy: 'TAKE_LAST',
    handler: attach({
        source: contractId,
        effect: (contractId, payload: PayloadForUpdateCurrentContract) => {
            // Condition for contractId === 0, we don't need updateContractFx, when contractId === 0
            if (contractId) {
                return updateContractFx({ contractId, ...payload });
            }
            throw new Error(`contractId wrong`);
        }
    })
});

const initialContract = restore(newContractReceived, null);

const templateId = contract.map(contract => contract?.complexTemplateData?.templateId); // in most cases you should use `templateId` from `template` stores
const complexTemplateData = contract.map(contract => getParsedArraySafe(contract?.complexTemplateData?.field));
const customTemplateData = contract.map(contract => getParsedArraySafe(contract?.customTemplateData?.entry));
const networkType = contract.map(contract => contract?.networkType);

const isContractDisabled = createStore(false);

const setIsGettingContractAfterUpdateEnabled = createEvent<boolean>();
const isGettingContractAfterUpdateEnabled = restore(setIsGettingContractAfterUpdateEnabled, true);

const calculateContractFx = createEffect(({ contractId, ...rest }: PayloadForCalculateContract) =>
    API2.calculatePro.calculateContractN({ contractID: contractId, ...rest })
);
const calculateCurrentContractFx = attach({
    source: contractId,
    effect: (contractId, payload: PayloadForCalculateCurrentContract) => calculateContractFx({ contractId, ...payload })
});

//
/*** Export ***/
//
export const contractEvents = {
    contractReceived,
    newContractReceived,
    setIsGettingContractAfterUpdateEnabled,
    updateInitialContract,
    resetContract
};
export const contractEffects = {
    createOrUpdateContractFx,
    createContractFx,
    updateContractFx,
    getContractFx,
    getContractAfterCalculateFx,
    updateCurrentContractFx,
    calculateContractFx,
    calculateCurrentContractFx
};
export const contractStores = {
    contract,
    contractId,
    isContractReceived,
    initialContract,
    templateId,
    complexTemplateData,
    customTemplateData,
    networkType,
    isContractDisabled,
    isGettingContractAfterUpdateEnabled
};
