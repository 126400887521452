import { FieldsetStyled } from '@wedat/ui-kit/components/Fieldset';
import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    position: relative;
    flex: auto;
    max-width: 100%;
    display: flex;
`;

export const LeftInputWrapper = styled.div`
    flex: 3;
    min-width: 156px;

    ${FieldsetStyled} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    ${media.phoneSmall`
    min-width: 100px;
    `}
`;

export const RightSelectInput = styled.div`
    flex: 2;
    min-width: 156px;

    ${FieldsetStyled} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }
    ${media.phoneMostSmall`
    min-width: 130px;
    `}
`;
