import { useStore } from 'effector-react';
import { FC, useCallback, useMemo } from 'react';
import { contractEffects } from '@dat/shared-models/contract';
import { stepperContractStores } from '../../stores/contract';
import { stepperStores } from '../../stores/stepper';
import { StepAction, StepButton, StepButtonBack, Title, TitleBack } from './styles';
import { ArrowBack } from '@wedat/ui-kit/components/Icons';
import { ArrowForward } from '@wedat/ui-kit/components/Icons/index';
import { StepperActionsProps } from '../../types/config';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const StepperActions: FC<StepperActionsProps> = ({ isFirst, goPreviousStep, isFilled, goNextStep, isLast }) => {
    const { t } = useTranslation();
    const currentStepData = useStore(stepperStores.currentStepData);
    const contractId = useStore(stepperContractStores.contractId);
    const entries = Object.entries(currentStepData);
    const history = useHistory();
    const templateData = useMemo<DAT2.Field.EntriesContainerForRequest<DAT2.Field.Primitive, DAT2.Field.Primitive>>(
        () => ({
            entry: entries.map(item => ({
                key: item[0],
                value: {
                    _text: JSON.stringify(item[1]).slice(1, -1)
                }
            }))
        }),
        [entries]
    );
    const handleSubmit = useCallback(() => {
        if (contractId > 0) {
            try {
                contractEffects.createOrUpdateContractFx({
                    contractId: Number(contractId),
                    templateData
                });
                history.push(`claims/${contractId}/opening`);
            } catch (error) {
                console.log(error);
            }
        }
    }, [contractId, history, templateData]);

    return (
        <StepAction>
            {!isFirst && (
                <StepButtonBack
                    typeStyle={{ type: 'transparent' }}
                    onClick={() => {
                        goPreviousStep();
                    }}
                >
                    <>
                        <ArrowBack /> <TitleBack>{t('stepperActions.back')}</TitleBack>
                    </>
                </StepButtonBack>
            )}
            <StepButton
                disabled={isFilled}
                typeStyle={{ type: 'primary' }}
                onClick={() => {
                    isLast ? handleSubmit() : goNextStep();
                }}
            >
                {isLast ? (
                    t('stepperActions.save')
                ) : (
                    <>
                        <Title>{t('stepperActions.continue')}</Title> <ArrowForward />
                    </>
                )}
            </StepButton>
        </StepAction>
    );
};
