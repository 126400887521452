import { ObjectWithNameAndChildrenProperties } from '../types/multipleVariantSelection';

type PropObject = Record<string, ObjectWithNameAndChildrenProperties<any, any>>;

export const extractOptionsFromMultipleVariantSelectionProp = (propObject?: PropObject) =>
    propObject
        ? Object.entries(propObject).map(([key, { name }]) => ({
              key,
              value: name,
              label: name
          }))
        : [];
