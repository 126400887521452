import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isMailModalOpen, toggleIsMailModalOpen] = createToggle(false);
const [isValuationModalOpen, toggleIsValuationModalOpen] = createToggle(false);

export const modalsEvents = {
    toggleIsMailModalOpen,
    toggleIsValuationModalOpen
};
export const modalsStores = {
    isMailModalOpen,
    isValuationModalOpen
};
