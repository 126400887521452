import React from 'react';
import { useStore } from 'effector-react';

import { lightboxStores } from '../../stores/lightbox';

import { Lightbox as LightboxComponent } from '@wedat/ui-kit/components/Lightbox';

export const Lightbox: React.FC = () => {
    const lightboxState = useStore(lightboxStores.lightboxState);

    return lightboxState.isOpen ? <LightboxComponent {...lightboxState} /> : null;
};
