import _ from 'lodash';

import { getBaseTokens } from '@wedat/ui-kit/theme/getBaseTokens';
import { LOCALE_STORAGE_KEY } from '@dat/shared-models/i18n/utils';

const { colors } = getBaseTokens(localStorage.getItem(LOCALE_STORAGE_KEY) || 'en-US');

export const STEPS_OPTIONS = [
    { values: _.range(91, 101), backgroundColor: colors.green['600'] },
    { values: _.range(81, 91), backgroundColor: colors.green['600'] },
    { values: _.range(71, 81), backgroundColor: colors.green['300'] },
    { values: _.range(61, 71), backgroundColor: colors.green['300'] },
    { values: _.range(51, 61), backgroundColor: colors.orange['400'] },
    { values: _.range(41, 51), backgroundColor: colors.orange['400'] },
    { values: _.range(31, 41), backgroundColor: colors.orange['400'] },
    { values: _.range(21, 31), backgroundColor: colors.orange['400'] },
    { values: _.range(11, 21), backgroundColor: colors.red['600'] },
    { values: _.range(0, 11), backgroundColor: colors.red['600'] }
];
