import React from 'react';
import { ColumnWrapper, SortUpIcon, SortDownIcon } from '../style';
import { useTranslation } from 'react-i18next';
import { contractsListEvents, contractsListStores } from '../../../../stores/contractsList';
import { useStore } from 'effector-react';

export interface Props {
    name: string;
    sort?: boolean;
    useRemainingSpace?: boolean;
    center?: boolean;
}

export const ColumnHeader: React.FC<Props> = ({ name, sort = true, useRemainingSpace, ...rest }) => {
    const { t } = useTranslation();
    const currentSort = useStore(contractsListStores.params.sort);
    return (
        <ColumnWrapper
            useRemainingSpace={useRemainingSpace}
            sort={sort}
            onClick={
                sort
                    ? () => {
                          contractsListEvents.setSort({
                              name,
                              direction: currentSort.name === name ? !currentSort.direction : false
                          });
                      }
                    : undefined
            }
            {...rest}
        >
            {t(`column.${name}`)}
            {currentSort.name === name && (currentSort.direction ? <SortUpIcon /> : <SortDownIcon />)}
        </ColumnWrapper>
    );
};
