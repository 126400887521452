import { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputField } from '@wedat/ui-kit/Formik';
import { ADDRESS_FIELDS, BANK_FIELDS, GENERAL_FIELDS } from '../../constants';
import { Container, FieldsSubtitle, FormStyled, SaveButton } from './styles';
import { FormUserProfile } from '../../types';

export const Form: FC = () => {
    const { t } = useTranslation();
    const { submitForm, dirty, errors } = useFormikContext<FormUserProfile>();

    return (
        <Container>
            <FormStyled>
                {GENERAL_FIELDS.map(item => (
                    <InputField key={item} name={item} label={t(`auth:${item}`)} helperText={errors[item]} />
                ))}
            </FormStyled>
            <FieldsSubtitle>{t('address')}</FieldsSubtitle>
            <FormStyled>
                {ADDRESS_FIELDS.map(item => (
                    <InputField key={item} name={item} label={t(`auth:${item}`)} helperText={errors[item]} />
                ))}
            </FormStyled>
            <FieldsSubtitle>{t('bank')}</FieldsSubtitle>
            <FormStyled>
                {BANK_FIELDS.map(item => (
                    <InputField key={item} name={item} label={t(`auth:${item}`)} helperText={errors[item]} />
                ))}
            </FormStyled>
            <SaveButton disabled={!dirty} onClick={submitForm}>
                {t('auth:profile.form.saveButton')}
            </SaveButton>
        </Container>
    );
};
