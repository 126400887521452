export enum DatProductsPaths {
    VehicleRepair = 'myClaim/soap/v2',
    MyClaim = 'myClaim/soap/v2',
    ValuateFinance = 'FinanceLine/soap'
}

export enum VehicleRepairServices {
    VehicleSelectionService = 'VehicleSelectionService',
    VehicleIdentificationService = 'VehicleIdentificationService',
    VehicleImagery = 'VehicleImagery',
    ConversionFunctionsService = 'ConversionFunctionsService',
    VehicleRepairService = 'VehicleRepairService',
    VehicleRepairItalyService = 'VehicleRepairItalyService'
}
export enum MyClaimServices {
    MyClaimExternalService = 'MyClaimExternalService',
    VehicleRepairService = 'VehicleRepairService'
}
export enum ValuateFinanceServices {
    Evaluation = 'Evaluation',
    DossierN = 'DossierN'
}

export enum ErrorCodes {
    AuthorizationFailed = 'dat:Server.authorizationFailed'
}
