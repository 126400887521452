import { createEvent, createStore, restore } from 'effector';

const setFirstFilterValue = createEvent<string>();
const firstFilterValue = restore(setFirstFilterValue, '');

const setSecondFilterValue = createEvent<string>();
const secondFilterValue = restore(setSecondFilterValue, '');

const setMobileFilterValue = createEvent<string>();
const mobileFilterValue = restore(setMobileFilterValue, '');

const toggleMobileFilterVisible = createEvent<void>();
const mobileFilterVisible = createStore<boolean>(false).on(toggleMobileFilterVisible, prev => !prev);

export const tableFilterEvents = {
    setFirstFilterValue,
    setSecondFilterValue,
    setMobileFilterValue,
    toggleMobileFilterVisible
};

export const tableFilterStores = {
    firstFilterValue,
    secondFilterValue,
    mobileFilterValue,
    mobileFilterVisible
};
