import { guard } from 'effector';

import { sharedContractStatusEvents, sharedContractStatusEffects } from './stores';

import { sharedTemplateStores } from '../../template';

const { disabledStatusSet } = sharedContractStatusEvents;
const { changeContractStatusFx } = sharedContractStatusEffects;

const { templateSettings } = sharedTemplateStores;

guard({
    clock: changeContractStatusFx.done,
    source: templateSettings.disabledStatuses,
    filter: (disabledStatuses, { params: { statusName } }) => !!disabledStatuses?.includes(statusName),
    target: disabledStatusSet
});
