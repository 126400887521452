// ButtonWithMark

import React from 'react';
import { Button, ButtonProps } from 'antd';
// import { CheckOutlined } from '@ant-design/icons';
import { ReactComponent as CheckIcon } from '../Icons/CheckIcon.svg';
import { ReactComponent as CheckIconInverse } from '../Icons/CheckIconInverse.svg';

export interface TypeButtonWithMark extends ButtonProps {
    leftMark?: boolean;
    rightMark?: boolean;
}

export function ButtonWithMark(props: TypeButtonWithMark) {
    return (
        <Button {...props}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {props.leftMark && (
                    <div style={{ textAlign: 'center', width: '20px', height: '21px', flexGrow: 0 }}>
                        {/* <CheckOutlined />   */}
                        {props.type === 'primary' ? <CheckIconInverse /> : <CheckIcon />}
                    </div>
                )}
                <div style={{ flexGrow: 1 }}>{props.children}</div>
                {props.rightMark && (
                    <div style={{ textAlign: 'center', width: '20px', height: '21px', flexGrow: 0 }}>
                        {/* <CheckOutlined /> */}
                        {props.type === 'primary' ? <CheckIconInverse /> : <CheckIcon />}
                    </div>
                )}
            </div>
        </Button>
    );
}
