/**
 * Dynamic mask img names for the corresponding mask img URLs.
 * For easier plugin configuration
 */
export const MASK_IMG_URL = 'https://datgroupsolutions.eu/masks';

// Vehicle type part to be replaced in URL
export const MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART = '$VEHICLE_TYPE$';

export const STEPS_MASK_IMG_DYNAMIC_NAMES_R = {
    dynamicFrontView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_R.svg`,
    dynamicDriverFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_driver_R.svg`,
    dynamicDriverRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_driver_R.svg`,
    dynamicRearView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_R.svg`,
    dynamicPassengerRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_passenger_R.svg`,
    dynamicPassengerFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_passenger_R.svg`
};

export const STEPS_MASK_IMG_DYNAMIC_NAMES_G = {
    dynamicFrontView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_G.svg`,
    dynamicDriverFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_driver_G.svg`,
    dynamicDriverRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_driver_G.svg`,
    dynamicRearView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_G.svg`,
    dynamicPassengerRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_passenger_G.svg`,
    dynamicPassengerFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_passenger_G.svg`
};

export const STEPS_MASK_IMG_DYNAMIC_NAMES = {
    dynamicFrontView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front.svg`,
    dynamicDriverFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_driver.svg`,
    dynamicDriverRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_driver.svg`,
    dynamicRearView: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear.svg`,
    dynamicPassengerRear: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_rear_passenger.svg`,
    dynamicPassengerFront: `${MASK_IMG_URL}/Mask_${MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART}_Front_passenger.svg`
};
