import styled from 'styled-components/macro';
import { ButtonIcon } from '@wedat/ui-kit/components/ButtonIcon';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

export const IconWrapper = styled.span`
    height: 18px;
    width: 18px;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.white};

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const TextStyled = styled(Text)`
    margin-left: 11px;
    color: ${({ theme }) => theme.colors.white};
`;

export const ButtonIconStyled = styled(ButtonIcon)`
    min-width: 48px;
    width: fit-content;
    padding-left: 40px;
    padding-right: 40px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.gray_40};
    background-color: ${({ theme }) => theme.colors.deepBlue['800']};
    color: ${({ theme }) => theme.colors.white};

    &:disabled {
        background-color: ${({ theme }) => theme.colors.dustBlue['100']};
    }

    &:hover {
        border-width: 1px;
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.deepBlue['800']};

        ${TextStyled} {
            color: ${({ theme }) => theme.colors.deepBlue['800']};
        }

        ${IconWrapper} {
            svg {
                color: ${({ theme }) => theme.colors.deepBlue['800']};
            }
        }
    }

    ${media.phoneBig`
        padding-left: 16px;
        padding-right: 16px;
        height: 100%;
        border: none;
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.dustBlue['900']};
    `}
`;

export const Container = styled.div`
    display: flex;

    ${media.phoneBig`
        width: fit-content;
        height: 100%;
    `}
`;
