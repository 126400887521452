import { FastTrackConfiguration } from '@dat/api2dat/types/fastTrackInterfaceTypes';
import { AvalableDamages } from '../components/GraphicSetDamageForms/FtDamageForm/FtDamageForm';
import { FtDvnMapType } from '../types/ft/dvnMapType';
import { ftDamagesType } from '../types/ft/ftDamagesType';
import { ftGroupsType } from '../types/ft/ftGroupsType';
import { ftRepairType } from '../types/ft/ftRepairType';
import { i18n } from '../i18n';

export function getAvalableDamagesByDATIDftSettings(
    datId: string | null,
    ftSettings?: {
        ftDamages?: ftDamagesType;
        ftRepairs?: ftRepairType[];
        ftGroups?: ftGroupsType;
        ftDvnMap?: FtDvnMapType;
    }
): AvalableDamages[] {
    if (!datId || !ftSettings?.ftDamages) return [];

    const fastTrackGroups = convertFtGroupFromFtSettings(ftSettings.ftGroups);

    return getAvalableDamagesByDATID(datId, { fastTrackDamages: ftSettings.ftDamages, fastTrackGroups });
}

export function convertFtGroupFromFtSettings(ftGroups?: ftGroupsType): {
    [key: string]: string;
} {
    const result: { [key: string]: string } = {};
    if (!ftGroups) return result;
    Object.entries(ftGroups).forEach(([key, val]) =>
        val.forEach(datId => {
            result[datId] = key;
        })
    );
    return result;
}

export function getAvalableDamagesByDATID(
    datId: string | null,
    fastTrackConfiguration: FastTrackConfiguration
): AvalableDamages[] {
    if (!datId) return [];

    let groupName = fastTrackConfiguration.fastTrackGroups[datId];
    let damageGroupList = fastTrackConfiguration.fastTrackDamages[datId];
    if (!damageGroupList && !!groupName) {
        damageGroupList = fastTrackConfiguration.fastTrackDamages[groupName];
    }
    if (!damageGroupList) {
        damageGroupList = fastTrackConfiguration.fastTrackDamages['default'];
    }

    const { language } = i18n;

    return damageGroupList.map(damage => {
        let damageLable = '';
        let damageTranslation = '';
        let damageid = '';

        if (typeof damage === 'string') {
            damageid = damage;
            damageLable = damage;
            damageTranslation = i18n.exists('fastLocalNameDamages:' + damage)
                ? i18n.t('fastLocalNameDamages:' + damage)
                : damage;
        }

        if (typeof damage === 'object') {
            damageid = damage.id;
            if (typeof damage.label !== 'string') {
                damageLable = damage.label[language];
                damageTranslation = damage.label[language] || damage.label['en'] || damage.id;
            } else {
                damageLable = damage.label;
                damageTranslation = i18n.exists('fastLocalNameDamages:' + damage.id)
                    ? i18n.t('fastLocalNameDamages:' + damage.id)
                    : damage.label || damage.id;
            }
        }

        return {
            datId,
            groupName,
            damageid,
            damageLable,
            damageTranslation
        };
    });
}
