import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { Price } from '../Price';
import { SpareParts } from '../SpareParts';
import { Labour } from '../Labour';
import { Paintwork } from '../Paintwork';
import { CalculateResultsTabs, tabs } from './constants';
import { PostEditing } from '../PostEditing';

import { TabsContainer } from './styles';
import { sharedTemplateStores } from '@dat/shared-models/template';

export const CalculateContractTabs: FC = () => {
    const { t } = useTranslation();
    const productsConfiguration = useStore(sharedTemplateStores.productsConfiguration);
    const showPostEditingCalculation = productsConfiguration?.calculation?.showPostEditingCalculation;

    const [activeTab, setActiveTab] = useState(CalculateResultsTabs.Price);
    const isMobile = useMedia(sizes.phone);

    const tabsHandler = (id: React.SetStateAction<string>) => {
        setActiveTab(id as CalculateResultsTabs);
    };

    const tabsValues = tabs(t);

    return (
        <TabsContainer>
            {!showPostEditingCalculation && (
                <Tabs
                    values={tabsValues}
                    active={activeTab}
                    onChange={tabsHandler}
                    typeStyle={isMobile ? 'secondary' : 'primary'}
                />
            )}
            {showPostEditingCalculation ? (
                <>
                    <PostEditing />
                    <Price />
                </>
            ) : (
                <>
                    {activeTab === CalculateResultsTabs.Price && <Price />}
                    {activeTab === CalculateResultsTabs.SpareParts && <SpareParts />}
                    {activeTab === CalculateResultsTabs.Labour && <Labour />}
                    {activeTab === CalculateResultsTabs.Paintwork && <Paintwork />}
                </>
            )}
        </TabsContainer>
    );
};
