import React from 'react';
import { Formik, Form } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { evaluationEvents, evaluationStores } from '../stores/evaluation';

import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Container, Content, AccordionContainer, AccrodionItemWrapper } from './styles';
import { BasicData } from '../components/sections/BasicData';
import { BasicValuation } from '../components/sections/BasicValuation';
import { EquipmentValuation } from '../components/sections/EquipmentValuation';
import { EquipmentValue } from '../components/sections/EquipmentValue';
import { VehicleCondition } from '../components/sections/VehicleCondition';
import { SalesProcurement } from '../components/sections/SalesProcurement';
import { TopSection } from '../components/sections/TopSection';
import { Toast } from '@dat/smart-components/Toast';

import { Accordion, AccordionItem } from '@wedat/ui-kit/components/Accordion';
import { Text } from '@wedat/ui-kit/components/Text';

export const ValuateFinancePage: React.FC = () => {
    const { t } = useTranslation();
    const manualFieldsOfInitialEvaluation = useStore(evaluationStores.manualFieldsOfInitialEvaluation);
    const isMobile = useMedia(sizes.laptop);

    return (
        <Container>
            <Formik
                enableReinitialize
                initialValues={manualFieldsOfInitialEvaluation}
                onSubmit={evaluationEvents.handleSubmitPartialManualFields}
            >
                <>
                    <Form>
                        <TopSection isMobile={isMobile} />

                        <Content>
                            <AccordionContainer>
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'BasicData'}
                                            header={<Text font="defaultBold">{t('basicData.Output of result')}</Text>}
                                        >
                                            <BasicData />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>

                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'BasicValuation'}
                                            header={<Text font="defaultBold">{t('basicValuation.title')}</Text>}
                                        >
                                            <BasicValuation isMobile={isMobile} />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>

                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'EquipmentValuation'}
                                            header={<Text font="defaultBold">{t('equipmentValuation.title')}</Text>}
                                        >
                                            <EquipmentValuation isMobile={isMobile} />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>

                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'EquipmentValue'}
                                            header={<Text font="defaultBold">{t('equipmentValue.title')}</Text>}
                                        >
                                            <EquipmentValue />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>

                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'VehicleCondition'}
                                            header={<Text font="defaultBold">{t('vehicleCondition.title')}</Text>}
                                        >
                                            <VehicleCondition />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>

                                    <AccrodionItemWrapper>
                                        <AccordionItem
                                            uuid={'SalesProcurement'}
                                            header={<Text font="defaultBold">{t('salesProcurement.title')}</Text>}
                                        >
                                            <SalesProcurement isMobile={isMobile} />
                                        </AccordionItem>
                                    </AccrodionItemWrapper>
                                </Accordion>
                            </AccordionContainer>
                        </Content>
                    </Form>

                    <Toast />
                </>
            </Formik>
        </Container>
    );
};
