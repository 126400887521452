import { createEvent, createNode, launch, step, } from 'effector';
import { CancelledError, LimitExceededError } from './error';
import { QUEUE, RACE, TAKE_EVERY, TAKE_FIRST, TAKE_LAST, } from './strategy';
import { cancellable, createRunning, } from './promise';
import { assign, getForkPage, read, setMeta } from './tools';
export const patchRunner = (runner, runnerScope) => {
    const runs = createRunning();
    assign(runner.scope, runnerScope);
    runner.seq = seq(runnerScope.anyway, runs);
    runnerScope.cancel.watch(() => runs.cancelAllEv());
};
const seq = (anyway, runs) => [
    step.compute({
        safe: true,
        filter: false,
        priority: 'effect',
        fn(upd, scopeArg, stack) {
            const scope = scopeArg;
            let handler = scope.handler;
            if (getForkPage(stack)) {
                const handlerArg = getForkPage(stack).handlers[scope.handlerId];
                if (handlerArg)
                    handler = handlerArg;
            }
            upd.handler = handler;
            return upd;
        },
    }),
    step.run({
        fn({ params, args, req, handler }, runScope, { scope }) {
            const { feedback, limit, cancelled, inFlight } = runScope;
            let { strategy, timeout } = runScope;
            strategy = (args && args.strategy) || strategy;
            timeout = (args && args.timeout) || timeout;
            const runnerScope = {
                params,
                strategy,
                timeout,
                feedback,
                push: runs.push,
                unpush: runs.unpush,
                cancelAll: runs.cancelAll,
                inFlight,
                scope,
                $running: runs.$running,
            };
            const go = run(runnerScope, handler, fin(runnerScope, anyway, 0, req.rs), fin(runnerScope, anyway, 1, req.rj), fin(runnerScope, cancelled, 2, req.rj));
            const running = read(scope)(runs.$running);
            if (running.length >= limit) {
                return go(new LimitExceededError(limit, running.length));
            }
            if (strategy === TAKE_FIRST && running.length > 0) {
                return go(new CancelledError(strategy));
            }
            if (strategy === TAKE_LAST && running.length > 0) {
                runs.cancelAll(strategy, scope);
            }
            if (strategy === QUEUE && running.length > 0) {
                const promise = cancellable(Promise.all(running.map(p => p.catch(() => { }))));
                runs.push(promise, scope);
                return promise.then(() => (runs.unpush(promise, scope), go()), error => (runs.unpush(promise, scope), go(error)));
            }
            go();
        },
    }),
];
const run = ({ params, push, timeout, scope }, handler, onResolve, onReject, onCancel) => (immediatelyCancelError) => {
    if (immediatelyCancelError) {
        return onCancel()(immediatelyCancelError);
    }
    let cancel;
    let result;
    try {
        result = handler(params, abort => (cancel = abort));
    }
    catch (error) {
        return onReject()(error);
    }
    if (Object(result) === result && typeof result.then === 'function') {
        const promise = cancellable(result, cancel, timeout);
        push(promise, scope);
        return promise.then(onResolve(promise), error => error instanceof CancelledError
            ? onCancel(promise)(error)
            : onReject(promise)(error));
    }
    return onResolve()(result);
};
const internalFinally = createEvent({ named: 'internalFinally' });
setMeta(internalFinally, 'needFxCounter', 'dec');
const fin = ({ params, unpush, strategy, feedback, cancelAll, inFlight, scope, $running, }, target, type, fn) => (promise) => (data) => {
    unpush(promise, scope);
    const runningCount = read(scope)($running).length;
    const targets = [sidechain];
    const payloads = [[fn, data]];
    if (type === 2 || !runningCount || strategy === RACE) {
        const body = type === 0
            ? { params, result: data, status: 'done' }
            : type === 1
                ? { params, error: data, status: 'fail' }
                : { params, error: data };
        if (feedback)
            body.strategy = strategy;
        targets.unshift(target);
        payloads.unshift(body);
        if (strategy === RACE && type !== 2) {
            cancelAll(strategy, scope);
        }
    }
    else if (runningCount && (strategy === TAKE_EVERY || strategy === QUEUE)) {
        targets.push(internalFinally);
    }
    if (runningCount && (type !== 2 || strategy !== RACE)) {
        launch({
            scope: scope,
            target: [inFlight],
            params: [runningCount],
        });
    }
    else {
        targets.unshift(inFlight);
        payloads.unshift(runningCount);
    }
    launch({
        target: targets,
        params: payloads,
        defer: true,
        scope,
    });
};
const sidechain = createNode({
    node: [
        step.run({
            fn([fn, value]) {
                fn(value);
            },
        }),
    ],
    meta: { op: 'fx', fx: 'sidechain' },
});
