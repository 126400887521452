import { createEvent, createStore } from 'effector';
import _ from 'lodash';
import { WORKING_HOURS } from '../../constants/constants';

type SelectedOption = { value: string; label: string };

const pointAsideOpened = createStore<boolean>(false);
const openPointAside = createEvent();
const closePointAside = createEvent<void>();
pointAsideOpened.on(openPointAside, () => true).on(closePointAside, () => false);

const timeNow = createStore<string>('');
const setTimeNow = createEvent<string>();
timeNow.on(setTimeNow, (_, payload) => payload);

const selectedOption = createStore<SelectedOption>({ value: '', label: '' });
const setSelectedOption = createEvent<SelectedOption>();
selectedOption.on(setSelectedOption, (_, payload) => payload);

const workingHours = createStore<string[]>(WORKING_HOURS);
const addTimeInWorkingHours = createEvent<string[]>();
workingHours.on(addTimeInWorkingHours, (state, payload) => _.uniq([...state, ...payload]));

export const pointsAsideEvents = {
    openPointAside,
    closePointAside,
    setTimeNow,
    setSelectedOption,
    addTimeInWorkingHours
};

export const pointsAsideStores = {
    pointAsideOpened,
    timeNow,
    selectedOption,
    workingHours
};
