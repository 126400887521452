import { TFunction } from 'react-i18next';

import { KeyValueOption } from '@wedat/ui-kit/components/Select';

export const equipmentSignRadioItems = (t: TFunction) => [
    { id: 'calculated value', value: 'calculated value', label: t('equipmentValuation.Single-value valuation') },
    { id: 'flat-rate value', value: 'flat-rate value', label: t('equipmentValuation.Lump-sum valuation') }
];

export const decreaseTypeSelectOptions: KeyValueOption<DAT2.DecreaseType>[] = [
    {
        key: 'table1',
        label: 'T1',
        value: 'T1'
    },
    {
        key: 'table2',
        label: 'T2',
        value: 'T2'
    },
    {
        key: 'table3',
        label: 'T3',
        value: 'T3'
    },
    {
        key: 'table4',
        label: 'T4',
        value: 'T4'
    },
    {
        key: 'residual value',
        label: 'RVal',
        value: 'RVal'
    }
];
