export const isUpperCase = (value?: string) => {
    if (!!value) {
        if (value.startsWith('#')) {
            return value.slice(2) === value.slice(2).toUpperCase();
        }
        return value === value.toUpperCase();
    }

    return false;
};
