import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { SUB_ROUTES_PATH_NAMES } from '../../constants/router';
import { ContractStatusChangeModal } from '@dat/smart-components/ContractStatusChangeModal';
import { getFieldText } from '@dat/api2/utils';

import { Chat } from '@dat/chat';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { ChatButton } from '@dat/smart-components/ChatButton';
import { Header } from '@dat/smart-components/Header';
import { StyledNavLink } from '../StyledNavLink';
import { StyledLink } from '../StyledLink';
import { containedPluginsStores } from '../../stores/containedPlugins';
import { HideBlock } from '../HideBlock';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { AssignPartner } from '@dat/assign-partner';
import { Printout } from '@dat/printout';
import { contractStores } from '@dat/shared-models/contract';
import { GalleryIcon, MailIcon } from '@wedat/ui-kit/components/Icons';
import { modalsEvents, modalsStores } from '../../stores/modals';
import { SchedulerMenu } from '../SchedulerMenu';
import { parsePluginPathName } from '../../utils/parsePluginPathName';

import {
    AdditionalMobileBar,
    AdditionalBarSection,
    ButtonPlugin,
    MainContainer,
    PluginLink,
    SlideWrap,
    StatusName,
    StyledTooltip,
    Wrapper,
    Image
} from './styles';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { useToggle } from '@dat/core/hooks/useToggle';
import { sharedGalleryEvents } from '@dat/shared-models/gallery';
import { ModalValuation } from '../ModalValuation';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { valuateEffects } from '../../stores/valuate';
import { sharedMailEvents } from '@dat/shared-models/mail';
import { TotalPrice } from '../TotalPrice';
import { Mail } from '@dat/mail';
import { vehicleImagesStores } from '../../stores/images';
import { PluginDropdown } from '../PluginDropdown';

const { processingValuateDataFx } = valuateEffects;

export const ClaimHeader = () => {
    const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);

    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const isValuationModalOpen = useStore(modalsStores.isValuationModalOpen);
    const isCalculationAvailable = useStore(containedPluginsStores.isCalculationAvailable);
    const isInvoiceAvailiable = useStore(containedPluginsStores.invoiceOptions);
    const contractType = useStore(sharedTemplateStores.templateSettings.contractType);
    const isTablet = useMedia(sizes.tablet);
    const isDesktopSmall = useMedia(sizes.desktopSmall);
    const [isChatOpen, setIsChatOpen] = useState(false);

    const isItalian = contractType === 'vro_domus_calculation';

    const { t } = useTranslation();

    const printoutPluginOptions = useStore(containedPluginsStores.printoutPluginOptions);
    const assignPartnerOptions = useStore(containedPluginsStores.assignPartnerPluginoptions);
    const mailOptions = useStore(containedPluginsStores.mailPluginOptions);
    const isContractReceived = useStore(contractStores.isContractReceived);
    const contractId = useStore(contractStores.contractId);
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);

    const contract = useStore(contractStores.contract);

    const history = useHistory();
    const params = useParams<{ contractId: string }>();
    const pluginPath = history.location.pathname;
    const isCalculationPath = parsePluginPathName(pluginPath) === 'calculation';
    const isValuationPath = parsePluginPathName(pluginPath) === 'valuation';

    // Url to internal dat-coworker applicaiton which availiable only for their coworkers
    // It runs locally and is not available for public that's why we need to use localhost
    const CREATE_INVOICE_URL = `http://localhost:8140/create/${params.contractId}`;
    const openStatus = () => setIsStatusPopupOpen(true);
    const closeStatus = () => setIsStatusPopupOpen(false);

    const [isOpenSlideUpStatus, openSlideUpStatus] = useToggle(false);

    useEffect(() => {
        if (!isCalculationAvailable && isCalculationPath && !isItalian) {
            history.push(`./${SUB_ROUTES_PATH_NAMES.claim.grapa}`);
        }
    }, [history, isCalculationAvailable, isCalculationPath, isItalian]);

    const rightContent = [
        <HideBlock sidebarBlock="total-price">
            <TotalPrice />
        </HideBlock>,
        contract?.statusName ? (
            <HideBlock sidebarBlock="status">
                <StatusName onClick={openStatus}>{t(`status.${String(contract.statusName)}`)}</StatusName>
            </HideBlock>
        ) : null,
        !isDesktopSmall ? (
            <HideBlock sidebarBlock="assign-partner">
                <StyledTooltip text={t('header.assignPartner')} width="auto" placement="top-start">
                    <PluginLink>
                        <AssignPartner options={{ ...assignPartnerOptions, isHeader: true }} />
                    </PluginLink>
                </StyledTooltip>
            </HideBlock>
        ) : null,
        !isDesktopSmall ? (
            <HideBlock sidebarBlock="scheduler">
                <StyledTooltip text={t('header.scheduler')} width="auto" placement="top-start">
                    <PluginLink>
                        <SchedulerMenu />
                    </PluginLink>
                </StyledTooltip>
            </HideBlock>
        ) : null,
        !isDesktopSmall ? (
            <HideBlock sidebarBlock="printout">
                <StyledTooltip text={t('header.printout')} width="auto" placement="top-start">
                    <PluginLink>
                        <Printout options={{ ...printoutPluginOptions, displayTitle: false }} />
                    </PluginLink>
                </StyledTooltip>
            </HideBlock>
        ) : null,
        !isDesktopSmall ? (
            <HideBlock sidebarBlock="gallery">
                <StyledTooltip text={t('header.attachment')} width="auto" placement="top-start">
                    <PluginLink
                        disabled={!isContractReceived}
                        onClick={() => {
                            sharedGalleryEvents.setSelectedContractId(contractId);
                        }}
                    >
                        <GalleryIcon />
                    </PluginLink>
                </StyledTooltip>
            </HideBlock>
        ) : null,
        <HideBlock sidebarBlock="mail">
            {isDesktopSmall ? (
                <Mail options={mailOptions} />
            ) : (
                <StyledTooltip text={t('header.mail')} width="auto" placement="top-start">
                    <PluginLink onClick={() => sharedMailEvents.toggleIsMailOpen()} disabled={!isContractReceived}>
                        <MailIcon />
                    </PluginLink>

                    <Mail options={mailOptions} />
                </StyledTooltip>
            )}
        </HideBlock>,
        isDesktopSmall ? <PluginDropdown /> : null
    ];

    return (
        <MainContainer>
            <Header
                additionalButtonsChildren={
                    <>
                        <Wrapper mobile>
                            <PluginLink>
                                {contract?.statusName && !isTablet && (
                                    <HideBlock sidebarBlock="status">
                                        <StatusName onClick={openSlideUpStatus}>
                                            {t(`status.${String(contract.statusName)}`)}
                                        </StatusName>
                                    </HideBlock>
                                )}
                            </PluginLink>
                            <HideBlock sidebarBlock="chat">
                                <StyledTooltip text={t('header.chat')} width="auto" placement="top-start">
                                    <ChatButton
                                        onClick={() => {
                                            setIsChatOpen(true);
                                        }}
                                    />
                                </StyledTooltip>
                                <ModalPrimary
                                    fullWidth
                                    bodyNoPadding
                                    isOpen={isChatOpen}
                                    onDismiss={() => setIsChatOpen(false)}
                                    hideHeader
                                >
                                    <Chat options={{ isComponent: true, closeChat: () => setIsChatOpen(false) }} />
                                </ModalPrimary>
                            </HideBlock>
                        </Wrapper>
                    </>
                }
                leftContent={[
                    <StyledNavLink to={`./${SUB_ROUTES_PATH_NAMES.claim.opening}`}>{t('header.claim')}</StyledNavLink>,
                    <HideBlock headerBlock="fast-track">
                        <StyledNavLink
                            to={`./${SUB_ROUTES_PATH_NAMES.claim.fastTrack}`}
                            disabled={!isVehicleIdentified}
                        >
                            {t('header.fastTrack')}
                        </StyledNavLink>
                    </HideBlock>,
                    <HideBlock headerBlock="grapa">
                        <StyledNavLink to={`./${SUB_ROUTES_PATH_NAMES.claim.grapa}`} disabled={!isVehicleIdentified}>
                            {t('header.grapa')}
                        </StyledNavLink>
                    </HideBlock>,
                    <HideBlock headerBlock="calculation">
                        <StyledNavLink
                            to={`./${SUB_ROUTES_PATH_NAMES.claim.calculation}`}
                            disabled={(!isCalculationAvailable && !isItalian) || (isItalian && !isVehicleIdentified)}
                        >
                            {t('header.calculation')}
                        </StyledNavLink>
                    </HideBlock>,
                    <HideBlock headerBlock="valuation">
                        <ButtonPlugin
                            as="div"
                            onClick={() => processingValuateDataFx()}
                            disabled={!isVehicleIdentified}
                            active={isValuationPath}
                        >
                            {t('header.valuation')}
                        </ButtonPlugin>
                    </HideBlock>,
                    <HideBlock headerBlock="invoice">
                        <StyledLink href={CREATE_INVOICE_URL} disabled={!isInvoiceAvailiable}>
                            {t('header.invoice')}
                        </StyledLink>
                    </HideBlock>
                ]}
                rightContent={isTablet ? undefined : rightContent}
                homeIcon
            />

            <AdditionalMobileBar>
                <AdditionalBarSection>
                    {vehicleImages && vehicleImages[0] && (
                        <Image src={`data:image/png;base64,${vehicleImages[0].imageBase64}`} alt="vehicle photo" />
                    )}
                    <PluginLink>
                        {contract?.statusName && isTablet && (
                            <HideBlock sidebarBlock="status">
                                <StatusName onClick={openSlideUpStatus}>
                                    {t(`status.${String(contract.statusName)}`)}
                                </StatusName>
                            </HideBlock>
                        )}
                    </PluginLink>
                </AdditionalBarSection>
                <PluginLink>
                    <TotalPrice />
                </PluginLink>
            </AdditionalMobileBar>

            <HideBlock sidebarBlock="status">
                {isStatusPopupOpen && (
                    <ContractStatusChangeModal
                        contractId={contractId}
                        initialStatusId={getFieldText(contract?.status_id)}
                        isOpen={isStatusPopupOpen}
                        onDismiss={closeStatus}
                        onStatusChange={closeStatus}
                        onStatusChangeFailed={closeStatus}
                    />
                )}
            </HideBlock>
            {isTablet && (
                <SlideUpMenu isOpen={isOpenSlideUpStatus} closeSlideUpMenu={openSlideUpStatus}>
                    <SlideWrap>
                        <ContractStatusChangeModal
                            contractId={contractId}
                            initialStatusId={getFieldText(contract?.status_id)}
                            isOpen
                            onDismiss={openSlideUpStatus}
                            onStatusChange={openSlideUpStatus}
                            onStatusChangeFailed={openSlideUpStatus}
                        />
                    </SlideWrap>
                </SlideUpMenu>
            )}
            <ModalPrimary
                maxWidth="700px"
                bodyHeight="650px"
                title={t('modals.valuation.title')}
                bodyNoPadding
                isOpen={isValuationModalOpen}
                onDismiss={modalsEvents.toggleIsValuationModalOpen}
            >
                <ModalValuation />
            </ModalPrimary>
        </MainContainer>
    );
};
