import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { sharedUserStores, sharedUserEffects } from '@dat/shared-models/user';
import { convertFileToBase64 } from '@dat/core/utils/data/convertFileToBase64';
import { CameraIcon, profileIcon } from '@wedat/ui-kit/components/Icons';

import { Container, Text, ImageStyled, ImageWrap, Stub, ButtonEdit, CameraIconStyled } from './styles';

export const PictureRow = () => {
    const { t } = useTranslation();

    const userProfile = useStore(sharedUserStores.userProfile);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageUpload = () => {
        fileInputRef.current?.click();
    };

    return (
        <Container>
            <ImageWrap onClick={handleImageUpload}>
                <ImageStyled src={userProfile?.image || profileIcon} alt="user avatar" />
                <Stub>
                    <CameraIconStyled />
                </Stub>
            </ImageWrap>
            <ButtonEdit type="button" onClick={handleImageUpload}>
                <CameraIcon />
                <Text>{t('auth:profile.form.edit')}</Text>
            </ButtonEdit>
            <input
                ref={fileInputRef}
                hidden
                type="file"
                onChange={async e => {
                    const fileZero = e.target.files?.[0];
                    if (fileZero) {
                        const convertedFile = await convertFileToBase64(fileZero);
                        sharedUserEffects.updateUserProfileFx({ image: convertedFile });
                    }
                }}
            />
        </Container>
    );
};
