import { ReactElement, useContext } from 'react';
import { Steps } from '../../types';
import { FormContext } from '../../reducers/form';

interface Props {
    step: Steps;
    children: ReactElement;
}

/**
 * Component for render children if step the same
 */
export const StepRoute = ({ step, children }: Props) => {
    const {
        state: { step: currentStep }
    } = useContext(FormContext);

    if (currentStep !== step) return null;

    return children;
};
