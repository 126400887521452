import React, { FC } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useInstance } from '../../hooks/useInstance';
import { initialFormValues } from './constants';

import { Cross } from '@wedat/ui-kit/assets/Icon';
import { List } from './List';
import { ButtonStyled, Container, StyledInputField, FormStyled } from './styles';
import { Formik } from 'formik';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

export const SubjectsList: FC = () => {
    const { t } = useTranslation();
    const { readOnly, showList, networkOwnerId, addButtonClicked, isFormVisibleOnMobile } = useInstance(
        'readOnly',
        'showList',
        'networkOwnerId',
        'isFormVisibleOnMobile'
    );

    const isMobile = useMedia(sizes.tablet);

    if (!showList) return null;

    return (
        <Formik initialValues={initialFormValues} onSubmit={_.noop}>
            <FormStyled>
                <Container isFormVisibleOnMobile={isFormVisibleOnMobile && isMobile}>
                    {!readOnly && !networkOwnerId && (
                        <ButtonStyled onClick={addButtonClicked}>
                            <Cross />
                            {t('add')}
                        </ButtonStyled>
                    )}
                    <StyledInputField name="search" placeholder={t('placeholders.search')} />
                    <List />
                </Container>
            </FormStyled>
        </Formik>
    );
};
