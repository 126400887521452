import styled from 'styled-components/macro';

export const PluginStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    height: 100%;
    width: 100%;

    padding: 0;
    margin: 0;

    ${({ theme }) => theme.typography.note};
    background-color: ${({ theme }) => theme.colors.white};

    color: ${({ theme }) => theme.colors.gray['800']};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow: auto;

    * {
        box-sizing: border-box;
    }
`;
