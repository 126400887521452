import { INITIAL_WORK_LOGIC_VALUES } from '../components/WorkLogic/constants';
import { WorkLogicFormValues } from '../types/worklogicForm';

export const getWorkLogicValues = ({
    parameters,
    workLogicRepairPosition
}: {
    parameters: DAT2.ProcedureRelatedParameter[];
    workLogicRepairPosition?: DAT2.RepairPosition;
}) => {
    const workLogicValues: WorkLogicFormValues = { ...INITIAL_WORK_LOGIC_VALUES };

    parameters?.forEach(({ attribute, _text }) => {
        if (attribute === 'isDeductionCommonWork' && typeof _text === 'boolean') {
            workLogicValues.isDeductionCommonWork = _text;
        }
        if (attribute === 'isDeductionIdenticalParts' && typeof _text === 'boolean') {
            workLogicValues.isDeductionIdenticalParts = _text;
        }
        if (attribute === 'isDeductionNeighbouringMetalParts' && typeof _text === 'boolean') {
            workLogicValues.isDeductionNeighbouringMetalParts = _text;
        }
        if (attribute === 'isSurchargeDifferingColourShades' && typeof _text === 'boolean') {
            workLogicValues.isSurchargeDifferingColourShades = _text;
        }
        if (attribute === 'isSurchargePaintingNeighbouringParts' && typeof _text === 'boolean') {
            workLogicValues.isSurchargePaintingNeighbouringParts = _text;
        }
        if (attribute === 'isSurchargeWasteDisposalCosts' && typeof _text === 'boolean') {
            workLogicValues.isSurchargeWasteDisposalCosts = _text;
        }
        if (workLogicRepairPosition && workLogicRepairPosition.SparePartAmount === 1) {
            workLogicValues.surcharge = 'complete';
        }
        if (workLogicRepairPosition && workLogicRepairPosition.SparePartAmount === 2) {
            workLogicValues.surcharge = 'partial';
        }
    });

    return workLogicValues;
};
