import { createI18nInstance } from '../utils/locale/createI18nInstance';

// import cn from './cn/translation.json';
// import cz from './cz/translation.json';
// import de from './de/translation.json';
import en from './en/translation.json';
// import es from './es/translation.json';
// import fr from './fr/translation.json';
// import gr from './gr/translation.json';
// import it from './it/translation.json';
// import kr from './kr/translation.json';
// import nl from './nl/translation.json';
// import pl from './pl/translation.json';
import ru from './ru/translation.json';

export const translations = { 'en-US': en, 'ru-RU': ru };

// export const { i18n, t } = createI18nInstance({ zh: cn, cs: cz, de, en, es, fr, el: gr, it, ko: kr, nl, pl, ru });
export const { i18n } = createI18nInstance(translations);
