import styled from 'styled-components';

export const LogsBlock = styled.div`
    background: ${({ theme: { colors } }) => colors.black};
    opacity: 0.5;
    width: 200px;
    position: absolute;
    bottom: 0;
    top: 0;
    overflow: scroll;
`;

export const LogText = styled.span`
    color: ${({ theme: { colors } }) => colors.white};
    font-size: small;
    margin-bottom: 5px;
`;
