import styled from 'styled-components/macro';

import { Text } from '@wedat/ui-kit';
import { media } from '@wedat/ui-kit/mediaQueries';
import { LabelStyled } from '@wedat/ui-kit/components/Input/styles';

interface FuelLevelWrapperProps {
    isVehicleIdentified: boolean;
}

interface InputWrapperProps {
    isFuelInputVisible: boolean;
}

interface PercentageStyledProps {
    onClick: () => void;
}

export const FuelLevelWrapper = styled.div<FuelLevelWrapperProps>`
    display: ${({ isVehicleIdentified }) => (isVehicleIdentified ? 'flex' : 'none')};
    align-items: center;
    width: 84px;
    height: 80px;
    border-radius: 8px;
    margin-top: 16px;
    padding-left: 8px;
    padding-right: 8px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray['100']};
    }

    ${media.laptopSmall`
        margin-top: 50px;
    `}

    ${media.phone`
        margin-top: 30px;
    `}
`;

export const GasInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3px;
`;

export const PercentageStyled = styled(Text)<PercentageStyledProps>`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    min-width: 30px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const InputWrapper = styled.div<InputWrapperProps>`
    display: ${({ isFuelInputVisible }) => (isFuelInputVisible ? 'flex' : 'none')};
    position: absolute;
    right: 50px;
    top: 25px;
    width: 80px;
    height: fit-content;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 12px;
    box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.2);
    z-index: 1;

    ${LabelStyled} {
        max-width: 54px;
        width: 54px;

        ${Text} {
            font-size: 14px;
        }
    }

    ${media.laptopSmall`
        right: 110px;
        top: 35px;
    `}

    ${media.phoneMedium`
        right: 55px;
        top: 35px;
    `}
`;
