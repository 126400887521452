import React, { FC, useRef, useCallback, useState, FocusEvent } from 'react';
import { useTheme } from 'styled-components';

import { Label } from '../Label';
import { FieldsetStyled } from '../Fieldset';
import { Legend, LegendText } from '../Legend';

import { ErrorStyled, StyledTextArea, TextAreaWrapperStyled, TextStyled, Wrapper } from './styles';
export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    maxRows?: number;
    expandable?: boolean;
    defaultHeight?: string;
    label?: string;
    status?: 'error' | 'success' | 'default';
    helperText?: string;
    borderRadius?: number;
}

export const TextArea: FC<TextareaProps> = ({
    label,
    helperText,
    status = 'default',
    maxRows = Infinity,
    onBlur,
    expandable,
    defaultHeight = '72px',
    placeholder,
    maxLength,
    disabled,
    ...rest
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const textarea = textareaRef.current;

    const theme = useTheme();

    const [focused, setFocused] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
        setFocused(true);
        rest.onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
        onBlur?.(e);
        setFocused(false);
    };

    const autoResize = useCallback(
        event => {
            if (textarea) {
                textarea.style.height = defaultHeight;
                const fontSize = Number(window.getComputedStyle(textarea).fontSize.replace('px', ''));
                const height = !!rest.value ? Math.min(fontSize * maxRows, textarea.scrollHeight) : defaultHeight;
                textarea.style.height = `${height}px`;
            }

            rest.onInput?.(event);
        },
        [defaultHeight, maxRows, rest, textarea]
    );

    const hasLabel = label !== '' && label !== undefined;
    const filled = rest?.value !== undefined && rest.value !== '';
    const showPlaceholder = focused || !hasLabel;

    return (
        <Wrapper {...{ status }}>
            <Label disabled={disabled} status={status} filled={filled} focused={focused}>
                {label}
            </Label>

            <TextAreaWrapperStyled>
                <StyledTextArea
                    onInput={expandable ? autoResize : rest.onInput}
                    height={defaultHeight}
                    ref={textareaRef}
                    status={status}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={showPlaceholder ? placeholder : ''}
                    maxLength={maxLength}
                    disabled={disabled}
                    {...rest}
                />

                <FieldsetStyled borderRadius={rest.borderRadius} status={status}>
                    <Legend focused={focused} filled={filled} disabled={disabled}>
                        <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                            {label}
                        </LegendText>
                    </Legend>
                </FieldsetStyled>
            </TextAreaWrapperStyled>
            {maxLength && maxLength > 0 ? (
                <TextStyled color={theme.colors.dustBlue[400]} font="footnote">{`${
                    String(rest?.value)?.length || 0
                } / ${maxLength}`}</TextStyled>
            ) : null}

            {status === 'error' && helperText && <ErrorStyled>{helperText}</ErrorStyled>}
        </Wrapper>
    );
};
