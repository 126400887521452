// ResultSparePartSearch

import { FC } from 'react';
import { Input, Modal, Form } from 'antd';
import { useStore } from 'effector-react';
import { sparePartSearchModel } from '../../stores/sparePartSearchModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { useTranslation } from 'react-i18next';

export const ResultSparePartSearch: FC<{}> = () => {
    const { t } = useTranslation();

    const resultGetExtPartNoInfoByModelAndExtPartNo = useStore(
        sparePartSearchModel.stores.resultGetExtPartNoInfoByModelAndExtPartNo
    );
    const datECode = useStore(availableAssemblyGroupsModel.stores.datECode);

    let sparePartPosition = resultGetExtPartNoInfoByModelAndExtPartNo?.Dossiers.Dossier?.find(
        dossier => dossier.Vehicle.DatECode === datECode
    )?.SparePartPositions.SparePartPosition[0];

    if (!sparePartPosition)
        sparePartPosition =
            resultGetExtPartNoInfoByModelAndExtPartNo?.Dossiers.Dossier?.[0].SparePartPositions.SparePartPosition[0];

    if (!sparePartPosition) return null;

    const {
        DATProcessId,
        // DATProcessIdName,
        PartNumber,
        Description,
        LastUPE,
        LastUPEDate,
        // SecondtoLastUPE,
        // SecondtoLastUPEDate,
        // ThirdtoLastUPE,
        // ThirdtoLastUPEDate,
        // FourthtoLastUPE,
        // FourthtoLastUPEDate,
        // Orderable,
        WorkTimeMin,
        WorkTimeMax
    } = sparePartPosition;

    return (
        <Modal
            title={`${t('resultSparePartSearch.title')}: ${PartNumber}`}
            visible={!!resultGetExtPartNoInfoByModelAndExtPartNo}
            okText={t('resultSparePartSearch.OpenInGraphic')}
            cancelText={t('resultSparePartSearch.cancel')}
            onOk={() => {
                sparePartSearchModel.events.setResultGetExtPartNoInfoByModelAndExtPartNo(null);
                graphicDamageSelectionModel.events.setCurrentObjectsByDVN([DATProcessId]);
            }}
            onCancel={() => sparePartSearchModel.events.setResultGetExtPartNoInfoByModelAndExtPartNo(null)}
        >
            {/* <AntAutoForm1 value={resultGetExtPartNoInfoByModelAndExtPartNo} /> */}
            {/* <AntAutoForm1 value={sparePartPosition} /> */}
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                // initialValues={{ remember: true }}
                autoComplete="off"
            >
                {/* <Form.Item label="PartNumber">
                    <Input value={PartNumber} />
                </Form.Item> */}
                {/* <Form.Item label="DATProcessId">
                    <Input value={DATProcessId} />
                </Form.Item> */}
                {/* <Form.Item label="DATProcessIdName">
                    <Input value={DATProcessIdName} />
                </Form.Item> */}
                <Form.Item label={t('resultSparePartSearch.Description')}>
                    <Input value={Description} />
                </Form.Item>
                <Form.Item label={t('resultSparePartSearch.LastUPE')}>
                    <Input value={LastUPE} />
                </Form.Item>
                <Form.Item label={t('resultSparePartSearch.LastUPEDate')}>
                    <Input value={LastUPEDate} />
                </Form.Item>
                <Form.Item label={t('resultSparePartSearch.WorkTimeMin')}>
                    <Input value={WorkTimeMin} />
                </Form.Item>
                <Form.Item label={t('resultSparePartSearch.WorkTimeMax')}>
                    <Input value={WorkTimeMax} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
