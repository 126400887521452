import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { InputField } from '@wedat/ui-kit/Formik';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import { PositionNumberInputField } from '../PositionNumberInputField';

interface InputFieldStyledProps {
    aggregate?: boolean;
    aggregateComponent?: boolean;
    isGray?: boolean;
}

export const Card = styled.div`
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;

    ${media.phoneBig`
        padding: 12px 24px;
    `}
`;

export const Wrapper = styled.div`
    margin-left: -6px;
    display: flex;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 10px;

        ${media.phoneBig`
            padding-bottom: 10px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
        `}
    }
`;
interface WrapperProp {
    tabs?: boolean;
}

export const WrapperContent = styled.div<WrapperProp>`
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    ${({ tabs }) =>
        tabs &&
        css`
            ${media.phoneBig`
            margin-bottom: 0;
        `}
        `}
`;

export const InputFieldStyled = styled(InputField)<InputFieldStyledProps>`
    min-width: unset;

    ${({ aggregate, theme }) => aggregate && `color: ${theme.colors.blue['300']};`}
    ${({ aggregateComponent, theme }) =>
        aggregateComponent &&
        css`
            background-color: ${theme.colors.blue['50']};
            color: ${theme.colors.dustBlue['900']};
        `}
    ${({ isGray, theme }) => isGray && `background-color: ${theme.colors.deepBlue['50']};`}
`;

export const PositionNumberInputFieldStyled = styled(PositionNumberInputField)`
    /* padding-left: 5px; */
    letter-spacing: -0.5px;
`;
export const NumberFormatStyled = styled(NumberFormat)`
    padding-left: 5px;
    display: flex;
    align-items: center;
    height: 48px;
`;

export const Info = styled.div`
    &:not(:last-child) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray_10};
    }
`;

export const DiscountDisplaySwap = styled.div`
    display: flex;
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue['400']};
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    left: 91px;
    top: 3px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['300']};
    }
`;
