import { AttachmentGroup, ParsedAttachmentItem } from '../../types/attachments';

interface Param {
    attachments: ParsedAttachmentItem[];
    groups: AttachmentGroup[];
}

export const getAttachmentsWithoutGroup = ({ attachments, groups }: Param): ParsedAttachmentItem[] => {
    const groupsIds = groups.map(({ id }) => id);

    return attachments.filter(({ documentID }) => !groupsIds.includes(documentID));
};
