import { createEvent } from 'effector';
import { FormikValuationValues } from '../../components/ModalValuation/constants';

const mailFormSubmitted = createEvent<DAT2.Internal.Request.LandingMail>();
const valuationFormSubmitted = createEvent<FormikValuationValues>();

export const formEvents = {
    mailFormSubmitted,
    valuationFormSubmitted
};
