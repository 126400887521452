import React from 'react';
import { Container, NotFoundImg } from './styles';
import noData from '../assets/illustration.png';
import { Text } from '@wedat/ui-kit/components/Text';
import { useTranslation } from 'react-i18next';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

interface NotFoundProps {
    className?: string;
}

export const NotFound: React.FC<NotFoundProps> = props => {
    const isMobile = useMedia(sizes.phoneBig);
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <NotFoundImg src={noData} alt={t('inbox:NothingFound')} />
            <Text font={isMobile ? 'note' : 'defaultText'}>{t('inbox:NothingFound')}</Text>
        </Container>
    );
};
