import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { CheckboxContainer } from '@wedat/ui-kit/components/Checkbox/styles';
import { RadioGroupStyled } from '@wedat/ui-kit/components/RadioGroup/styles';

interface FieldsContainerProps {
    marginTop?: boolean;
    marginBottom?: boolean;
}

export const SelectFieldContainer = styled.div`
    width: 245px;

    ${media.phoneMedium`
        width: 100%;
    `}
`;

export const CheckboxFieldContainer = styled.div`
    ${CheckboxContainer} {
        height: 100%;
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }
`;

export const Field = styled.div`
    min-width: 300px;
    width: 100%;
`;

export const FieldsContainer = styled.div<FieldsContainerProps>`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 16px;

    ${({ marginTop }) => marginTop && `margin-top: 32px;`}
    ${({ marginBottom }) => marginBottom && `margin-bottom: 32px;`}

    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
    `}

    ${media.laptop`
        grid-template-columns: 1fr;
    `}
`;

export const TitleWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 24px;
`;

export const AccordionItemContent = styled.div`
    margin-top: 32px;
`;

export const TabsWrapper = styled.div`
    max-width: 800px;

    ${media.laptop`
        max-width: 100%;
    `}
`;

export const RadioGroupContainer = styled.div`
    ${RadioGroupStyled} {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 16px;

        ${media.tablet`
            grid-template-columns: repeat(2, 1fr);
        `}

        ${media.laptop`
            grid-template-columns: 1fr;
        `}
    }
`;
