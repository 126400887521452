import React, { LegacyRef, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import Calendar from '@toast-ui/react-calendar';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { schedulerEffects, schedulerEvents, schedulerStores } from '@dat/shared-models/scheduler';

import { IEventScheduleObject, IEventObject, TEventBeforeCreateSchedule } from 'tui-calendar';
import { SchedulerEvent } from '../../types';
import { CALENDAR_HEIGHT, GOOGLE_MAPS_LINK, monthFields, SELECT_FIELDS, weekFields } from '../../constants/constants';
import {
    appointmentTypeFormatter,
    eventToExpiryClaimTypeFormatter,
    eventTypeArrFormatter,
    expiryClaimToEventTypeFormatter,
    updatedEventListFormatter
} from '../../utils/formatters';

import { CustomEditor } from '../CustomEditor';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';

import { SchedulerWrapper } from '../../styles';
import 'tui-calendar/dist/tui-calendar.css';
import { sharedUserStores } from '@dat/shared-models/user';
import { sharedPartnersEffects, sharedPartnersStores } from '@dat/shared-models/contract/Partners';
import { sharedProfilesEffects, sharedProfilesStores } from '@dat/shared-models/profiles';
import { pluginStores } from '../../stores/plugin';
import _ from 'lodash';
import { Select, SelectOption } from '@wedat/ui-kit/components/Select';
import { SelectStyled, SelectWrapper } from './style';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { contractStores } from '@dat/shared-models/contract';
import { getCurrentEvent } from '../../utils';

interface Props {
    selectedView: string;
    calendarRef?: LegacyRef<Calendar>;
    withoutApi?: boolean;
}

type SelectedOptionGroup = {
    id: number;
    value: string;
    label: string;
};
/**
 * Check the Toast UI Calendar documentation - https://github.com/nhn/toast-ui.react-calendar
 * @param options
 * */
export const Scheduler: React.FC<Props> = ({ selectedView, calendarRef, withoutApi = false }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    // const isLaptop = useMedia(sizes.laptop);

    const selectedTab = useStore(schedulerStores.selectedTab);
    const appointments = useStore(schedulerStores.appointments);
    const selectedUserID = useStore(schedulerStores.selectedUser);

    const customerNumber = useStore(sharedUserStores.customerNumber);
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const contractIdFromStore = useStore(contractStores.contractId);

    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isEditingView, setIsEditingView] = useState(false);

    const [appointmentEvents, setAppointmentEvents] = useState<SchedulerEvent[]>([]);
    const [expiryClaimEvents, setExpiryClaimEvents] = useState<SchedulerEvent[]>([]);
    const [schedules, setSchedules] = useState<SchedulerEvent[]>([]);
    const [partnerSelectValue, setPartnerSelectValue] = useState<string>('');
    const [customerProfile, setCustomerProfile] = useState<string>('');
    const [selectedPartnerId, setSelectedPartnerId] = useState<number>();

    const listOfPartners = useStore(sharedPartnersStores.listOfPartners);
    const listOfProfiles = useStore(sharedProfilesStores.sameCustomerProfilesByCustomerNumber);

    const isAppointmentView = selectedTab === SELECT_FIELDS.appointments;
    const isAdminRole = useStore(sharedConfigurationStores.customerConfiguration).settings?.isAdmin;

    const contractId = useMemo(
        () => (withoutApi ? pluginOptions?.contractId : contractIdFromStore),
        [contractIdFromStore, pluginOptions?.contractId, withoutApi]
    );

    const profileSelectOptions: SelectOption<string | number>[] = useMemo(
        () =>
            Object.values(listOfProfiles).map(profile => ({
                value: profile.name,
                label: `${profile.name} ${profile.surname}`
            })),
        [listOfProfiles]
    );

    const partnersSelectOption = useMemo(
        () =>
            isAdminRole
                ? listOfPartners.filter(item => item.label === 'EXPERT' || item.label === 'REPAIRER')
                : listOfPartners,
        [isAdminRole, listOfPartners]
    );

    useEffect(() => {
        if (isAdminRole) {
            sharedPartnersEffects.getCustomerPartnersFx({ contractId: Number(contractId) });
            sharedProfilesEffects.getProfilesFx();
        }
    }, [contractId, isAdminRole]);

    useEffect(() => {
        const events = isAppointmentView ? appointmentEvents : expiryClaimEvents;

        if (isAdminRole) {
            selectedPartnerId
                ? setSchedules(
                      events.filter(
                          event => event.assignee === selectedPartnerId || event.createdBy === selectedPartnerId
                      )
                  )
                : setSchedules(events);
            return;
        }

        setSchedules([]);
        events.forEach(item => {
            item.assignee === 0
                ? item.createdBy === customerNumber &&
                  setSchedules(prev => {
                      prev = prev.filter(({ id }) => id !== item.id);
                      return [...prev, item];
                  })
                : item.assignee === customerNumber &&
                  setSchedules(prev => {
                      prev = prev.filter(({ id }) => id !== item.id);
                      return [...prev, item];
                  });
        });
    }, [
        appointmentEvents,
        customerNumber,
        expiryClaimEvents,
        isAdminRole,
        isAppointmentView,
        pluginOptions?.credentials?.customerNumber,
        selectedPartnerId,
        selectedUserID,
        withoutApi
    ]);

    const createOrUpdateEventList = async (
        eventList: SchedulerEvent[],
        updatedEvent: IEventObject,
        isAppointment: boolean
    ) => {
        const updatedEventFromList = eventList.find(event => event.id === updatedEvent.schedule.id);

        if (updatedEventFromList) {
            const updatedEvents = updatedEventListFormatter(eventList, updatedEventFromList, updatedEvent);

            if (isAppointment) {
                const updatedEventsToSend = appointmentTypeFormatter(updatedEvents);
                await schedulerEffects.createOrUpdateAppointments({ value: updatedEventsToSend });
            } else {
                const updatedEventsToSend = eventToExpiryClaimTypeFormatter(updatedEvents);
                await schedulerEffects.createExpirationDate({ value: updatedEventsToSend });
            }
        }
    };

    const handleCreate = async (event: TEventBeforeCreateSchedule) => {
        await schedulerEvents.updateSelectedEvent(event);

        setIsEditingView(false);
        pluginOptions?.contractId !== 0 && setIsEditorOpen(true);
    };

    const handleEventDrop = async (updatedEvent: IEventObject) => {
        await createOrUpdateEventList(appointmentEvents, updatedEvent, true);

        await schedulerEffects.getAppointmentsList();
    };

    const handleUpdate = async (event: IEventObject) => {
        const currentEvent: DAT2.Appointment | DAT2.ExpiryClaim | undefined = getCurrentEvent(
            appointments,
            event,
            isAppointmentView
        );
        if (!isAdminRole && currentEvent?.createdBy !== customerNumber) return;

        const isDragUpdate = event.triggerEventName !== 'click';

        const eventsToShow = isAppointmentView
            ? eventTypeArrFormatter(appointments.appointmentDates, theme)
            : expiryClaimToEventTypeFormatter(appointments.expiryClaimsDate, theme);

        const eventToEdit = eventsToShow.find(schedule => schedule.id === event.schedule.id);

        const droppableEvent = {
            ...event,
            schedule: eventToEdit as SchedulerEvent
        };

        if (!isDragUpdate) {
            await schedulerEvents.updateSelectedEvent(eventToEdit);

            setIsEditingView(true);
            pluginOptions?.contractId !== 0 && setIsEditorOpen(true);
        } else {
            await handleEventDrop(droppableEvent);
        }
    };

    const handleScheduleClick = (event: IEventScheduleObject) => {
        // access to Edit and Delete buttons and change innerHTML so we can translate the texts
        const tuiPopupDeleteButton = document.querySelector(
            '.tui-full-calendar-popup-delete > span.tui-full-calendar-content'
        );
        const tuiPopupEditButton = document.querySelector(
            '.tui-full-calendar-popup-edit > span.tui-full-calendar-content'
        );

        if (tuiPopupEditButton) {
            tuiPopupEditButton.innerHTML = t('info.edit');
        }

        if (tuiPopupDeleteButton) {
            tuiPopupDeleteButton.innerHTML = t('info.delete');
        }

        // access to scheduler state property, you will see claim ID  in there, need to redirect to "/claimID/opening" page on click
        const tuiPopupElement = document.querySelector(
            '.tui-full-calendar-section-detail .tui-full-calendar-popup-detail-item .tui-full-calendar-content'
        ) as HTMLElement;

        tuiPopupElement.style.cursor = 'pointer';

        tuiPopupElement?.addEventListener('click', () => {
            history.push(`/${event.schedule.state}/opening`);
        });

        // access to address+city+zip, open Google Maps with the link
        const addressElemParent = document.querySelector(
            '.tui-full-calendar-popup-detail-item.tui-full-calendar-popup-detail-item-separate'
        );

        const addressElem = addressElemParent?.firstElementChild as HTMLElement;

        addressElem.style.cursor = 'pointer';
        addressElem.style.textDecoration = 'underline';

        const locationAddress: string[] = addressElem?.innerHTML.split(' ');

        let queryMap = GOOGLE_MAPS_LINK;

        if (locationAddress?.length) {
            for (let i = 0; i < locationAddress?.length; i++) {
                queryMap += '+' + locationAddress[i];
            }
        }

        addressElem?.addEventListener('click', () => {
            window.open(queryMap, '_blank');
        });
    };

    const handleDelete = async (event: IEventScheduleObject) => {
        const currentEvent: DAT2.Appointment | DAT2.ExpiryClaim | undefined = getCurrentEvent(
            appointments,
            event,
            isAppointmentView
        );
        if (!isAdminRole && currentEvent?.createdBy !== customerNumber) return;

        const updatedEvents = appointments.appointmentDates.filter(i => i.id !== event.schedule.id);
        await schedulerEffects.createOrUpdateAppointments({ value: updatedEvents });

        const { appointmentDates, expiryClaimsDate } = await schedulerEffects.getAppointmentsList();

        setAppointmentEvents(eventTypeArrFormatter(appointmentDates, theme));
        setExpiryClaimEvents(expiryClaimToEventTypeFormatter(expiryClaimsDate, theme));
    };

    useEffect(() => {
        if (appointments.appointmentDates?.length) {
            const appointmentsCalendarView = eventTypeArrFormatter(appointments.appointmentDates, theme);
            setAppointmentEvents(appointmentsCalendarView);
        }

        if (appointments.expiryClaimsDate?.length) {
            const expiryClaimsCalendarView = expiryClaimToEventTypeFormatter(appointments.expiryClaimsDate, theme);

            setExpiryClaimEvents(expiryClaimsCalendarView);
        }
    }, [appointments.appointmentDates, appointments.expiryClaimsDate, t, theme]);

    const handleChangePartners = (value: SelectedOptionGroup) => {
        setPartnerSelectValue(value.label);
        setSelectedPartnerId(value.id);
        sharedProfilesEffects.getProfilesByCustomerNumberFx(value.id);
    };

    const handleChangeProfiles = (value: any) => {
        setCustomerProfile(value.label);
    };

    const customStyles = {
        groupHeading: () => ({
            background: theme.colors.white,
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '5px',
            color: theme.colors.gray['500'],
            fontSize: '16px'
        }),
        group: () => ({ background: theme.colors.white })
    };

    return (
        <>
            {isAdminRole && (
                <SelectWrapper>
                    <SelectStyled>
                        <Select
                            styles={customStyles}
                            name="selectMenuItem"
                            valueKey="value"
                            label={partnerSelectValue ? partnerSelectValue : t('scheduler.partners')}
                            value={partnerSelectValue}
                            onChange={value => {
                                handleChangePartners(value as SelectedOptionGroup);
                            }}
                            options={partnersSelectOption as unknown as SelectOption<string | number>[]}
                            menuShouldBlockScroll={true}
                        />
                    </SelectStyled>
                    {profileSelectOptions.length > 0 && (
                        <SelectStyled>
                            <Select
                                styles={customStyles}
                                name="selectMenuItem"
                                valueKey="value"
                                label={customerProfile ? customerProfile : t('scheduler.profiles')}
                                value={customerProfile}
                                options={profileSelectOptions}
                                menuShouldBlockScroll={true}
                                onChange={value => {
                                    handleChangeProfiles(value as SelectOption);
                                }}
                            />
                        </SelectStyled>
                    )}
                </SelectWrapper>
            )}

            <SchedulerWrapper>
                <Calendar
                    ref={calendarRef}
                    height={CALENDAR_HEIGHT}
                    view={selectedView}
                    schedules={schedules}
                    useDetailPopup
                    disableDblClick
                    disableClick={false}
                    isReadOnly={false}
                    taskView={false}
                    scheduleView
                    month={monthFields(t)}
                    week={weekFields(t)}
                    onBeforeCreateSchedule={handleCreate}
                    onBeforeUpdateSchedule={handleUpdate}
                    onClickSchedule={handleScheduleClick}
                    onBeforeDeleteSchedule={handleDelete}
                />
            </SchedulerWrapper>

            <ModalPrimary
                hideHeader
                isOpen={isEditorOpen}
                onDismiss={() => setIsEditorOpen(false)}
                bodyNoPadding
                zIndex={1001}
            >
                <CustomEditor isEditingView={isEditingView} onClose={() => setIsEditorOpen(false)} />
            </ModalPrimary>
        </>
    );
};
