import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
`;

export const Content = styled.div`
    overflow-y: auto;
    ${makeCustomScrollBar()}
`;
