import { PartialDeep } from 'type-fest';

export const extractFromCustomerConfiguration = {
    customerSettings: (customerConfiguration: PartialDeep<DAT2.CustomerConfiguration>) =>
        customerConfiguration.settings || {},

    userSettings: (
        customerConfiguration: PartialDeep<DAT2.CustomerConfiguration>,
        username: keyof DAT2.CustomerConfiguration['users'] | undefined
    ): PartialDeep<DAT2.UserSettings> => customerConfiguration.users?.[username || '']?.settings || {},

    templateSettings: (
        customerConfiguration: PartialDeep<DAT2.CustomerConfiguration>,
        templateId: keyof DAT2.CustomerConfiguration['templates'] | undefined
    ): PartialDeep<DAT2.TemplateSettings> => customerConfiguration.templates?.[templateId || NaN]?.settings || {},

    productsConfiguration: (
        customerConfiguration: PartialDeep<DAT2.CustomerConfiguration>,
        templateId: keyof DAT2.CustomerConfiguration['templates'] | undefined
    ): DAT2.ProductsConfiguration => customerConfiguration.templates?.[templateId || NaN]?.products || {}
};
