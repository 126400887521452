import React from 'react';
import { useStore } from 'effector-react';

import { attachmentsEvents, attachmentsStores } from '../../stores/attachments';

import { Wrapper, StyledAttachment, AttachmentsContainer } from './styles';
import { lightboxEvents } from '../../stores/lightbox';

export const Attachments: React.FC = () => {
    const attachments = useStore(attachmentsStores.attachments);

    if (attachments.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            <AttachmentsContainer>
                {attachments.map((attachment, index) => (
                    <StyledAttachment
                        onClickExpand={() =>
                            lightboxEvents.openGroupInLightbox({
                                groupId: attachment.documentID,
                                initialIndex: index
                            })
                        }
                        onClickDelete={() => attachmentsEvents.deleteAttachment(index)}
                        attachment={attachment}
                        index={index}
                        key={attachment.uploaded + attachment.fileName}
                    />
                ))}
            </AttachmentsContainer>
        </Wrapper>
    );
};
