import React, { useMemo } from 'react';

import { SalesProcurementStyled } from './styles';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { dossierStores } from '../../../stores/dossier';
import { addDecimalZerosToFormattedValue } from './utils';

import { DatNumberDisplayField } from '../../DatNumberDisplayField';
import { Table } from '@wedat/ui-kit/components/Table';

interface Props {
    isMobile: boolean;
    withoutHeaders?: boolean;
}

export const SalesProcurement: React.FC<Props> = ({ isMobile, withoutHeaders }) => {
    const { t } = useTranslation();
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);

    const columns = useMemo(
        () => [
            {
                Header: t('Description'),
                accessor: 'col1'
            },
            {
                Header: t('salesProcurement.Rounded (for printing)'),
                accessor: 'col2'
            },
            {
                Header: t('salesProcurement.Calculated'),
                accessor: 'col3'
            }
        ],
        [t]
    );

    const data = useMemo(
        () => [
            {
                col1: t('salesProcurement.PurchaseValue'),
                col2: '',
                col3: <DatNumberDisplayField propertyPath="Valuation.Condition.ConditionSubTotal2" />
            },
            {
                col1: t('salesProcurement.repair'),
                col2: '',
                col3: <DatNumberDisplayField propertyPath="Valuation.Condition.RepairCosts" withoutIsActive />
            },
            {
                col1: t('salesProcurement.Dealership sales value according to DAT'),
                col2: !isMobile && (
                    <DatNumberDisplayField
                        propertyPath="Valuation.SalesPrice"
                        decimalScale={0}
                        renderText={addDecimalZerosToFormattedValue}
                    />
                ),
                col3: <DatNumberDisplayField propertyPath="Valuation.SalesPrice" />
            },
            {
                col1: (
                    <>
                        {t('salesProcurement.Trade margin')}{' '}
                        {dossierWithExtractedTextValues.Valuation?.Condition?.RepairCostsInTradeMargin &&
                            t('salesProcurement.+ repair costs')}
                    </>
                ),
                col2: !isMobile && (
                    <DatNumberDisplayField
                        propertyPath="Valuation.Margin"
                        decimalScale={0}
                        renderText={addDecimalZerosToFormattedValue}
                    />
                ),
                col3: <DatNumberDisplayField propertyPath="Valuation.Margin" />
            },
            {
                col1: t('salesProcurement.Dealership purchasing value'),
                col2: !isMobile && (
                    <DatNumberDisplayField
                        propertyPath="Valuation.PurchasePrice"
                        decimalScale={0}
                        renderText={addDecimalZerosToFormattedValue}
                    />
                ),
                col3: <DatNumberDisplayField propertyPath="Valuation.PurchasePrice" />
            }
        ],
        [isMobile, dossierWithExtractedTextValues.Valuation?.Condition?.RepairCostsInTradeMargin, t]
    );

    return (
        <SalesProcurementStyled>
            <Table columns={columns} data={data} breakpoint="zero" withoutHeaders={withoutHeaders} />
        </SalesProcurementStyled>
    );
};
