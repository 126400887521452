import React from 'react';

const ErrorMessage = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21C6.25329 21 2 16.7467 2 11.5C2 6.25329 6.25329 2 11.5 2ZM11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20C16.1944 20 20 16.1944 20 11.5C20 6.80558 16.1944 3 11.5 3ZM11.5 15.9C11.8314 15.9 12.1 16.1686 12.1 16.5C12.1 16.8314 11.8314 17.1 11.5 17.1C11.1686 17.1 10.9 16.8314 10.9 16.5C10.9 16.1686 11.1686 15.9 11.5 15.9ZM11.5 7C11.7761 7 12 7.22386 12 7.5V13.5C12 13.7761 11.7761 14 11.5 14C11.2239 14 11 13.7761 11 13.5V7.5C11 7.22386 11.2239 7 11.5 7Z"
            fill="#BF330A"
        />
    </svg>
);

export default ErrorMessage;
