// PreparedManualPositions

import React, { FC, memo } from 'react';

import { ConfigProvider, Button, Space, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import { useStore } from 'effector-react';

import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';

import { PreparedManualPositionsList } from './PreparedManualPositionsList';
import { preparedManualPositionsModel } from '../../stores/preparedManualPositionsModel';
import { EditPreparedMPositionsView } from './EditPreparedMPositionsView';

export const PreparedManualPositionsView: FC<{}> = memo(
    () => {
        const { t } = useTranslation();

        // const isMobile = useStore(commonModel.stores.isMobile);

        return (
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px'
                }}
            >
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Space>
                        <Button
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                // rightSideDrawerModel.events.clearHistoryAndClose();
                                rightSideDrawerModel.events.backView();
                            }}
                        />
                        <h2 style={{ height: '30px', marginLeft: '8px', marginBottom: '0px' }}>
                            {t('PreparedEditingManualRepairPositionList.title')}
                        </h2>
                    </Space>
                </div>

                <ConfigProvider renderEmpty={() => <div />}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '50px',
                            left: '0px',
                            right: '0px',
                            bottom: '54px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        <PreparedManualPositionsList />
                    </div>
                </ConfigProvider>

                <div
                    style={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={24}>
                            <Button
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    preparedManualPositionsModel.event.newEditedManualItem();
                                    rightSideDrawerModel.events.setHistoryView(EditPreparedMPositionsView);
                                }}
                            >
                                {t('PreparedEditingManualRepairPositionList.AddNew')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);
