import { getParsedArraySafe } from '@dat/api2/utils';
import { PartialFactorsParametersObject } from '../types/factors';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';

export const extractFactorsParametersFromContractMemoField = (
    contract: DAT2.ContractFromGetContract
): PartialFactorsParametersObject => {
    const entries = getParsedArraySafe(contract?.customTemplateData?.entry);
    const parametersEntry = entries.find(entry => entry.key === CONTRACT_ENTRIES_KEYS.MEMO.labourRates);
    const parametersJSON = parametersEntry?.value._text;

    if (parametersJSON) {
        try {
            return JSON.parse(String(parametersJSON));
        } catch {}
    }

    return {};
};
