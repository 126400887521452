import { sendCommonSoapRequest } from '../utils/soapRequest';
import { decodeXml } from '../utils/xmlSpecialCharacters';
import { Credentials, GetSparePartVariantsParams, ResultGetSparePartVariants } from '../types/api2datTypes';

// vehicleRepairOnlineService
// https://www.dat.de/VehicleRepairOnline/services/VehicleRepairService?wsdl

const urlVehicleRepairOnlineService = 'https://www.dat.de/VehicleRepairOnline/services/VehicleRepairService';

const credentialsForVehicleRepairOnlineService: Credentials = {
    interfacePartnerNumber: '3131411',
    interfacePartnerSignature: 'jA0EAwMCrjjoCCEuBBBgySvQtBjnp+NKnkteqoMkkzMRZlXddAQsnEG1vdDRAyRfRq+a41mrUtxJrmOg',
    customerNumber: '3131411',
    customerLogin: 'ferrsimo',
    customerPassword: 'ferrsimo01'
};

export async function getSparePartVariants({
    // credentials,
    params,
    url
}: {
    credentials: Credentials;
    params: GetSparePartVariantsParams;
    url?: string;
}): Promise<ResultGetSparePartVariants | undefined> {
    if (!params.datProcessId) return undefined;

    const paramsRequest = { request: params };
    const SoapResult = await sendCommonSoapRequest(
        url || urlVehicleRepairOnlineService,
        'getSparePartVariants',
        // credentials,
        credentialsForVehicleRepairOnlineService,
        paramsRequest,
        ['SparePartVariantsResponse.sparePartVariant', 'SparePartVariantsResponse.equipment']
    );

    const result: ResultGetSparePartVariants =
        SoapResult.Envelope.Body.getSparePartVariantsResponse.SparePartVariantsResponse;

    result.equipment?.forEach(val => (val.name = decodeXml(val.name)));

    result.sparePartVariant?.forEach(val => {
        if (val?.constructionCondition) val.constructionCondition = decodeXml(val.constructionCondition.toString());
        if (val?.description) val.description = decodeXml(val.description.toString());
        if (val?.partNumber) val.partNumber = decodeXml(val.partNumber.toString());
    });

    return result;
}
