import React, { FC, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { useToggle } from '@dat/core/hooks/useToggle';

import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { Logo } from '@wedat/ui-kit/components/Logo';
import { ProfileForm } from '../ProfileForm';
import { Profile } from '../Profile';
import { LanguageModal } from '../LanguageModal';
import { LanguageSlideUp } from '../LanguageSlideUp';
import { ReloadIcon, searchIcon, HomeIcon } from '@wedat/ui-kit/components/Icons';

import {
    StyledHeader,
    Container,
    LogoWrapper,
    RightPartWrapper,
    ListStyled,
    ChildItem,
    ReloadButton,
    LeftPartWrapper,
    SearchButton,
    SearchButtonWrapper,
    ListLeft,
    AdditionalButtonsContainer,
    HomeLink,
    StyledTooltip
} from './styles';
import { useStore } from 'effector-react';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { useTranslation } from 'react-i18next';

type ContentProps = React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;

interface Props {
    additionalButtonsChildren?: React.ReactNode;
    withReloader?: boolean;
    leftContent?: ContentProps[];
    rightContent?: ContentProps[];
    onReload?: () => void;
    onSearch?: () => void;
    noHideContent?: boolean;
    fixed?: boolean;
    homeIcon?: boolean;
}

export const Header: FC<Props> = ({
    leftContent,
    rightContent,
    additionalButtonsChildren,
    withReloader,
    onReload,
    onSearch,
    noHideContent,
    fixed,
    homeIcon
}) => {
    const isTablet = useMedia(sizes.tablet);
    const [isOpenMenu, toggleIsOpenMenu, setIsMenuOpen] = useToggle(false);
    const [isOpenSelectModal, toggleSelectOpenModal] = useToggle(false);
    const [isProfileOpen, toggleIsProfileOpen] = useToggle(false);

    const menuRef = useRef(null);
    useClickOutside(menuRef, () => setIsMenuOpen(false));

    const userTheme = useStore(sharedConfigurationStores.userSettings.theme);
    const silverLogo = userTheme?.silverLogo;

    const { t } = useTranslation();

    return (
        <StyledHeader fixed={fixed}>
            <Container>
                <LeftPartWrapper>
                    <Link to="/">
                        <LogoWrapper>
                            <Logo silverLogo={silverLogo} />
                        </LogoWrapper>
                    </Link>
                    {homeIcon && (
                        <HomeLink to="/">
                            <HomeIcon />
                        </HomeLink>
                    )}

                    {onSearch && (
                        <SearchButtonWrapper
                            onClick={() => {
                                onSearch();
                            }}
                        >
                            <SearchButton image={searchIcon} />
                        </SearchButtonWrapper>
                    )}
                    <ListLeft isOpenMenu={false} homeIcon={homeIcon}>
                        {leftContent?.map((ch, idx) => (
                            <ChildItem key={`listLeft${idx}`} onClick={toggleIsOpenMenu}>
                                {ch}
                            </ChildItem>
                        ))}
                    </ListLeft>
                </LeftPartWrapper>
                <RightPartWrapper>
                    {rightContent && (
                        <ListStyled isOpenMenu={isOpenMenu} noHideContent={noHideContent}>
                            {rightContent?.map((ch, idx) => (
                                <ChildItem key={`listLeft${idx}`} onClick={toggleIsOpenMenu}>
                                    {ch}
                                </ChildItem>
                            ))}
                        </ListStyled>
                    )}
                    {withReloader && (
                        <StyledTooltip text={t('inbox:header.reload')} width="auto" placement="top-start">
                            <ReloadButton
                                onClick={() => {
                                    onReload?.();
                                }}
                            >
                                <ReloadIcon />
                            </ReloadButton>
                        </StyledTooltip>
                    )}
                    <AdditionalButtonsContainer>{additionalButtonsChildren}</AdditionalButtonsContainer>
                    <Profile changeLanguage={toggleSelectOpenModal} openProfile={toggleIsProfileOpen} />
                    <ProfileForm isOpen={isProfileOpen} onDismiss={toggleIsProfileOpen} />
                    {isTablet ? (
                        <LanguageSlideUp isOpen={isOpenSelectModal} closeSlideUpMenu={toggleSelectOpenModal} />
                    ) : (
                        <LanguageModal closeSelectModal={toggleSelectOpenModal} isOpen={isOpenSelectModal} />
                    )}
                </RightPartWrapper>
            </Container>
        </StyledHeader>
    );
};
