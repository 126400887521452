import styled from 'styled-components/macro';
import { CloseIcon, ProfileIcon } from '@wedat/ui-kit/components/Icons';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    height: 88px;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    padding-left: 20px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ProfileIconStyled = styled(ProfileIcon)`
    height: 48px;
    width: 48px;
    margin-right: 10px;
    flex-shrink: 0;

    ${media.tablet`
        display: none;
    `}
`;

export const UserAvatar = styled.img`
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 10px;

    ${media.tablet`
        display: none;
    `}
`;

export const CloseIconStyled = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray_40};
`;

export const BackButton = styled.button`
    padding: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid ${({ theme }) => theme.colors.gray_10};
    cursor: pointer;
    border-radius: 3px;
    user-select: none;

    ${media.tablet`
        display: flex;
    `}

    svg {
        width: 12px;
        height: 18px;
    }
`;

export const GroupAndRole = styled.div`
    display: flex;
    align-items: center;
`;

export const MemberDataWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
