import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Layout = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 700px;
    max-width: 1000px;
    flex-direction: column;

    @media (min-width: 1300px) {
        width: 100%;
    }

    @media (max-width: 1100px) {
        padding: 0;
    }

    ${media.laptop`
        min-width: unset;
        padding: 0;
    `}
`;

export const MainContent = styled.div`
    display: flex;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    height: 100%;

    ${media.tablet`
        display: none;
    `}
`;

export const Col = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
`;

export const HeaderChildrenWrapper = styled.div`
    display: none;

    ${media.tablet`
        display: flex;
    `}
`;

export const MobileContentWrapper = styled.div`
    display: none;

    ${media.tablet`
        display: flex;
        position: relative;
        background-color: ${({ theme }) => theme.colors.white};
        height: 100%;
        width: 100%;
    `}
`;
