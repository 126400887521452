import styled, { css } from 'styled-components/macro';
import { isIOS } from 'react-device-detect';

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 40px;
    border-radius: 4px;
    transition: background-color 150ms;
    cursor: pointer;

    svg {
        width: 28px;
        height: 28px;
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }
`;

export const NewOrderWrapper = styled.div`
    position: fixed;
    right: 16px;
    bottom: 72px;
    width: 48px;
    height: 48px;
    border-radius: 12px;

    ${isIOS &&
    css`
        bottom: 96px;
    `}
`;
