import { format } from 'date-fns';

export const createEntriesArrayFromSubject = (formValues: BFF.AddressBook.Subject, subjectType: string) =>
    Object.keys(formValues).map(key => {
        const value = formValues[key] as DAT2.Field.Primitive | Date;
        const isDate = value instanceof Date;

        return {
            key: key.endsWith('_id') ? `${subjectType}${key}` : `${subjectType}_${key}`,
            value: {
                _text: (isDate ? format(value, 'dd.MM.yy') : formValues[key]) || '',
                type: 'xs:string'
            }
        };
    });
