import styled from 'styled-components/macro';
import { Form } from 'formik';
import { Logo } from '@wedat/ui-kit/components/Logo';
import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
`;

export const LogoStyled = styled(Logo)`
    margin-right: auto;
    margin-bottom: 40px;
`;

export const FormStyled = styled(Form)`
    width: 375px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;

    ${media.phone`
        max-width: 330px;
    `}
`;

export const Field = styled.div`
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 26px;
    }
`;

export const Footer = styled.div`
    display: flex;
    margin-top: 1rem;
    width: 100%;
`;

export const SelectLanguage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    height: 48px;
    width: 56px;
    margin-right: 10px;
    border: ${({ theme }) => `1px solid  ${theme.colors.dustBlue['100']}`};
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        border-color: ${({ theme }) => theme.colors.dustBlue['300']};
    }
`;

export const ErrorText = styled(Text)`
    margin-right: auto;
`;

export const StyledText = styled(Text)`
    margin-bottom: 30px;
    margin-right: auto;
`;

export const CarMobileImg = styled.img`
    position: absolute;
    top: 34px;
    right: 0;
`;

export const Flag = styled.img`
    width: 24px;
    height: 16px;
`;
