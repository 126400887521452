import React, { FC } from 'react';
import './stores/init';
import { useTranslation } from 'react-i18next';

import { WhiteContainer, TitleWrapper } from './styles';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { PictureAndForm } from './components/PictureAndForm';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    inAuth?: boolean;
    isOpen?: boolean;
    onDismiss?: () => void;
}

export const ProfileForm: FC<Props> = ({ inAuth, isOpen, onDismiss }) => {
    const { t } = useTranslation();

    return (
        <>
            {inAuth ? (
                <WhiteContainer>
                    <TitleWrapper>
                        <Text font="semiHeading" textOverflow="ellipsis">
                            {t('auth:profile.title')}
                        </Text>
                    </TitleWrapper>

                    <PictureAndForm />
                </WhiteContainer>
            ) : (
                <ModalPrimary
                    isOpen={isOpen || false}
                    onDismiss={() => {
                        onDismiss?.();
                    }}
                    maxWidth="590px"
                    title={t('auth:profile.modalTitle')}
                    bodyHeight={'fit-content'}
                >
                    <PictureAndForm />
                </ModalPrimary>
            )}
        </>
    );
};
