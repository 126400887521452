import React, { FC, useEffect, useState } from 'react';
import { Input, Button, InputNumber, Space, Row, Col, Collapse, Select, Checkbox } from 'antd';
import { DeleteOutlined, ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { manualPositionFormExtendedModel } from '../../../stores/manualPositionFormExtendedModel';
import { commonModel } from '../../../stores/commonModel';
import { rightSideDrawerModel } from '../../../stores/rightSideDrawerModel';
import { RepairPositionsList } from '../../RepairPositionsList/RepairPositionsList';
import { workTimeUnits } from '../../../initData/workTimeUnits';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { getManufacturerByDatECode } from '@dat/api2dat/services/vehicleSelectionPredefined';
import { SelectedElement } from '../../CommonComponents/SelectedElement';
import { checkManualRepairPositionForm } from '../../../utils/checkManualRepairPositionForm';
import { convertRepairTypeToFullRepairType } from '../../../utils/ftRepairToRepairPosition';
// import { PreparedManualPositionsList } from '../../PreparedManualPositions/PreparedManualPositionsList';

const { Panel } = Collapse;
const { Option } = Select;

export const ManualRepairPositionFormExtended: FC<{}> = () => {
    const { t } = useTranslation();

    const manualRepairPositionForm = useStore(manualPositionFormExtendedModel.stores.manualRepairPositionForm);
    const isModified = useStore(RepairPositionsModel.store.isModified);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);

    const [activeKey, setActiveKey] = useState<string>('');

    const manufacturer = datECode ? getManufacturerByDatECode(datECode) : '';

    const additionalRC = pluginOptions?.settings?.additionalRC || [];
    const repairCodesPZST = additionalRC.filter(rc => rc === 'P' || rc === 'Z' || rc === 'S' || rc === 'T');

    const hst = datECode.slice(2, 5);
    const timeUnitsPerHour = workTimeUnits.find(unit => unit.hst === hst)?.timeUnitsPerHour;

    const {
        key,
        DATProcessId,
        Description,
        RepairType,
        WorkTime,
        WorkPrice,
        SparePartNumber,
        SparePartPrice,
        SparePartDiscount,
        PreDamage,
        PredamagePercentage
    } = manualRepairPositionForm;

    const workTimeInTimeUnits = Math.round((WorkTime || 0) * (timeUnitsPerHour || 1));

    const [showPreDamagePrc, setShowPreDamagePrc] = useState(!!PredamagePercentage);
    const [checkedPreDamageRepairType, setCheckedPreDamageRepairType] = useState(RepairType);

    useEffect(() => {
        setActiveKey(RepairType || '');
    }, [RepairType]);

    function unfoldKey(key: string) {
        if (activeKey !== key) {
            setActiveKey(key);
        }
    }

    return (
        <div
            style={{
                // margin: '8px',
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px'
            }}
        >
            <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                <Space>
                    <Button
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                            rightSideDrawerModel.events.backView();
                        }}
                    />
                    <h2 style={{ marginLeft: '8px', marginBottom: '0px' }}>{t('ManualRepairPositionForm.Title')}</h2>
                </Space>

                <Space style={{ float: 'right' }}>
                    {!key ? null : (
                        <>
                            <Button
                                size="large"
                                type="link"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    RepairPositionsModel.event.delRepairPositionByKey([key]);
                                    manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                }}
                            >
                                {t('ManualRepairPositionForm.Delete')}
                            </Button>
                        </>
                    )}
                </Space>
            </div>

            <div
                style={{
                    position: 'absolute',
                    top: '64px',
                    left: '0px',
                    right: '0px',
                    bottom: '54px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row align="middle" gutter={[8, 8]} style={{ marginBottom: '8px' }}>
                    {/* <Col span={24}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            size="large"
                            onClick={() => rightSideDrawerModel.events.setHistoryView(PreparedManualPositionsView)}
                        >
                            {t('PreparedEditingManualRepairPositionList.OpenList')}
                        </Button>
                    </Col> */}

                    <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                        {t('ManualRepairPositionForm.Description')}
                    </Col>
                    <Col span={12}>
                        <Input
                            size="large"
                            value={Description}
                            onChange={e => {
                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                    ...manualRepairPositionForm,
                                    key,
                                    DATProcessId,
                                    Description: e.target.value
                                });
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    expandIconPosition="right"
                    activeKey={activeKey}
                    onChange={val => {
                        setActiveKey(typeof val === 'string' ? val : val[val.length - 1]);
                    }}
                >
                    <Panel
                        header={
                            <Space>
                                <SelectedElement
                                    checked={
                                        RepairType === 'replace' &&
                                        checkManualRepairPositionForm(manualRepairPositionForm)
                                    }
                                    onChange={_e => {
                                        if (RepairType === 'replace') {
                                            RepairPositionsModel.event.delRepairPositionByKey([key]);
                                            manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                        } else {
                                            unfoldKey('replace');
                                        }
                                    }}
                                />
                                {t('RepairType.label.E')}
                            </Space>
                        }
                        key="replace"
                    >
                        <Row align="middle" gutter={[8, 8]}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.PreDamage')}
                            </Col>
                            <Col span={12}>
                                <Select
                                    size="large"
                                    defaultValue="none"
                                    value={
                                        RepairType === 'replace'
                                            ? showPreDamagePrc || typeof PredamagePercentage === 'number'
                                                ? 'preDamagePrc'
                                                : PreDamage
                                                ? 'preDamage'
                                                : 'none'
                                            : 'none'
                                    }
                                    style={{
                                        width: '100%'
                                    }}
                                    onChange={value => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            PreDamage: value === 'preDamage',
                                            ...(value !== 'preDamagePrc' && { PredamagePercentage: null }),
                                            RepairType: 'replace'
                                        });
                                        setShowPreDamagePrc(value === 'preDamagePrc');
                                    }}
                                >
                                    <Option value="none">{t('ManualRepairPositionForm.PreDamageOptions.none')}</Option>
                                    <Option value="preDamage">
                                        {t('ManualRepairPositionForm.PreDamageOptions.preDamage')}
                                    </Option>
                                    <Option value="preDamagePrc">
                                        {t('ManualRepairPositionForm.PreDamageOptions.preDamagePrc')}
                                    </Option>
                                </Select>
                            </Col>
                            {showPreDamagePrc && RepairType === 'replace' && (
                                <>
                                    <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                        {t('ManualRepairPositionForm.PreDamage')} (%)
                                    </Col>
                                    <Col span={12}>
                                        <InputNumber
                                            size="large"
                                            type="number"
                                            min={0}
                                            max={100}
                                            value={
                                                RepairType === 'replace' && PredamagePercentage !== null
                                                    ? PredamagePercentage
                                                    : undefined
                                            }
                                            onChange={value => {
                                                const currentViewValues =
                                                    RepairType === 'replace' ? manualRepairPositionForm : {};
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                    ...currentViewValues,
                                                    Description,
                                                    key,
                                                    DATProcessId,
                                                    PredamagePercentage: value,
                                                    RepairType: 'replace'
                                                });
                                            }}
                                        />
                                    </Col>
                                </>
                            )}
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.SparePartNumber')}
                            </Col>
                            <Col span={12}>
                                <Input
                                    size="large"
                                    addonAfter={
                                        <a
                                            href={
                                                'https://www.google.com/search?tbm=isch&q=' +
                                                (SparePartNumber || '') +
                                                ' ' +
                                                manufacturer
                                            }
                                            target={'_blank'}
                                            rel="noreferrer"
                                        >
                                            <SearchOutlined />
                                        </a>
                                    }
                                    value={RepairType === 'replace' ? SparePartNumber : undefined}
                                    onChange={e => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};

                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            SparePartNumber: e.target.value,
                                            RepairType: 'replace'
                                        });
                                    }}
                                />
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.SparePartPrice')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'replace' ? SparePartPrice : undefined}
                                    onChange={value => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};

                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            SparePartPrice: value,
                                            RepairType: 'replace'
                                        });
                                    }}
                                />
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.SparePartDiscount')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'replace' ? SparePartDiscount : undefined}
                                    onChange={value => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};

                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            SparePartDiscount: value,
                                            RepairType: 'replace'
                                        });
                                    }}
                                />
                            </Col>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkTime')}
                            </Col>
                            {timeUnitsPerHour && (
                                <>
                                    <Col span={12}>
                                        <InputNumber
                                            size="large"
                                            type="number"
                                            value={RepairType === 'replace' ? workTimeInTimeUnits : undefined}
                                            onChange={value => {
                                                const currentViewValues =
                                                    RepairType === 'replace' ? manualRepairPositionForm : {};

                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                    ...currentViewValues,
                                                    Description,
                                                    key,
                                                    DATProcessId,
                                                    WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                    WorkPrice: undefined,
                                                    RepairType: 'replace'
                                                });
                                            }}
                                        />
                                        <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                </>
                            )}
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'replace' ? WorkTime : undefined}
                                    onChange={value => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};

                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkTime: Math.round(value * 100) / 100,
                                            WorkPrice: undefined,
                                            RepairType: 'replace'
                                        });
                                    }}
                                />
                                <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkPrice')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'replace' ? WorkPrice : undefined}
                                    onChange={value => {
                                        const currentViewValues =
                                            RepairType === 'replace' ? manualRepairPositionForm : {};

                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            ...currentViewValues,
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkPrice: value,
                                            WorkTime: undefined,
                                            RepairType: 'replace'
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Panel>
                    <Panel
                        header={
                            <Space>
                                <SelectedElement
                                    checked={
                                        RepairType === 'overhaul' &&
                                        checkManualRepairPositionForm(manualRepairPositionForm)
                                    }
                                    onChange={_e => {
                                        if (RepairType === 'overhaul') {
                                            RepairPositionsModel.event.delRepairPositionByKey([key]);
                                            manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                        } else {
                                            unfoldKey('overhaul');
                                        }
                                    }}
                                />
                                {t('RepairType.label.I')}
                            </Space>
                        }
                        key="overhaul"
                    >
                        <Row align="middle" gutter={[8, 8]}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.PreDamage')}
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    defaultChecked={PreDamage && RepairType === 'overhaul'}
                                    onChange={e => {
                                        if (RepairType === 'overhaul' && (WorkTime || WorkPrice))
                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                Description,
                                                key,
                                                DATProcessId,
                                                WorkTime: WorkTime,
                                                WorkPrice: WorkPrice,
                                                PreDamage: e.target.checked,
                                                RepairType: 'overhaul'
                                            });
                                        setCheckedPreDamageRepairType(e.target.checked ? 'overhaul' : '');
                                    }}
                                >
                                    {t('ManualRepairPositionForm.PreDamageOptions.preDamage')}
                                </Checkbox>
                            </Col>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkTime')}
                            </Col>
                            {timeUnitsPerHour && (
                                <>
                                    <Col span={12}>
                                        <InputNumber
                                            size="large"
                                            type="number"
                                            value={RepairType === 'overhaul' ? workTimeInTimeUnits : undefined}
                                            onChange={value => {
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                    Description,
                                                    key,
                                                    DATProcessId,
                                                    WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                    WorkPrice: undefined,
                                                    PreDamage: checkedPreDamageRepairType === 'overhaul',
                                                    RepairType: 'overhaul'
                                                });
                                            }}
                                        />
                                        <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                </>
                            )}
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'overhaul' ? WorkTime : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkTime: Math.round(value * 100) / 100,
                                            WorkPrice: undefined,
                                            PreDamage: checkedPreDamageRepairType === 'overhaul',
                                            RepairType: 'overhaul'
                                        });
                                    }}
                                />
                                <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkPrice')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'overhaul' ? WorkPrice : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkPrice: value,
                                            WorkTime: undefined,
                                            PreDamage: checkedPreDamageRepairType === 'overhaul',
                                            RepairType: 'overhaul'
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Panel>
                    <Panel
                        header={
                            <Space>
                                <SelectedElement
                                    checked={
                                        RepairType === 'fixing' &&
                                        checkManualRepairPositionForm(manualRepairPositionForm)
                                    }
                                    onChange={_e => {
                                        if (RepairType === 'fixing') {
                                            RepairPositionsModel.event.delRepairPositionByKey([key]);
                                            manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                        } else {
                                            unfoldKey('fixing');
                                        }
                                    }}
                                />
                                {t('RepairType.label./')}
                            </Space>
                        }
                        key="fixing"
                    >
                        <Row align="middle" gutter={[8, 8]}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.PreDamage')}
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    defaultChecked={PreDamage && RepairType === 'fixing'}
                                    onChange={e => {
                                        if (RepairType === 'fixing' && (WorkTime || WorkPrice))
                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                Description,
                                                key,
                                                DATProcessId,
                                                WorkTime: WorkTime,
                                                WorkPrice: WorkPrice,
                                                PreDamage: e.target.checked,
                                                RepairType: 'fixing'
                                            });
                                        setCheckedPreDamageRepairType(e.target.checked ? 'fixing' : '');
                                    }}
                                >
                                    {t('ManualRepairPositionForm.PreDamageOptions.preDamage')}
                                </Checkbox>
                            </Col>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkTime')}
                            </Col>
                            {timeUnitsPerHour && (
                                <>
                                    <Col span={12}>
                                        <InputNumber
                                            size="large"
                                            type="number"
                                            value={RepairType === 'fixing' ? workTimeInTimeUnits : undefined}
                                            onChange={value => {
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                    Description,
                                                    key,
                                                    DATProcessId,
                                                    WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                    WorkPrice: undefined,
                                                    PreDamage: checkedPreDamageRepairType === 'fixing',
                                                    RepairType: 'fixing'
                                                });
                                            }}
                                        />
                                        <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                </>
                            )}
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'fixing' ? WorkTime : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkTime: Math.round(value * 100) / 100,
                                            WorkPrice: undefined,
                                            PreDamage: checkedPreDamageRepairType === 'fixing',
                                            RepairType: 'fixing'
                                        });
                                    }}
                                />
                                <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkPrice')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'fixing' ? WorkPrice : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkPrice: value,
                                            WorkTime: undefined,
                                            PreDamage: checkedPreDamageRepairType === 'fixing',
                                            RepairType: 'fixing'
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Panel>
                    <Panel
                        header={
                            <Space>
                                <SelectedElement
                                    checked={
                                        RepairType === 'dis- and mounting' &&
                                        checkManualRepairPositionForm(manualRepairPositionForm)
                                    }
                                    onChange={_e => {
                                        if (RepairType === 'dis- and mounting') {
                                            RepairPositionsModel.event.delRepairPositionByKey([key]);
                                            manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                        } else {
                                            unfoldKey('dis- and mounting');
                                        }
                                    }}
                                />
                                {t('RepairType.label.A')}
                            </Space>
                        }
                        key="dis- and mounting"
                    >
                        <Row align="middle" gutter={[8, 8]}>
                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkTime')}
                            </Col>
                            {timeUnitsPerHour && (
                                <>
                                    <Col span={12}>
                                        <InputNumber
                                            size="large"
                                            type="number"
                                            value={RepairType === 'dis- and mounting' ? workTimeInTimeUnits : undefined}
                                            onChange={value => {
                                                manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                    Description,
                                                    key,
                                                    DATProcessId,
                                                    WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                    WorkPrice: undefined,
                                                    RepairType: 'dis- and mounting'
                                                });
                                            }}
                                        />
                                        <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                    </Col>
                                    <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                </>
                            )}
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'dis- and mounting' ? WorkTime : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkTime: Math.round(value * 100) / 100,
                                            WorkPrice: undefined,
                                            RepairType: 'dis- and mounting'
                                        });
                                    }}
                                />
                                <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                            </Col>

                            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                {t('ManualRepairPositionForm.WorkPrice')}
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    size="large"
                                    type="number"
                                    value={RepairType === 'dis- and mounting' ? WorkPrice : undefined}
                                    onChange={value => {
                                        manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                            Description,
                                            key,
                                            DATProcessId,
                                            WorkPrice: value,
                                            WorkTime: undefined,
                                            RepairType: 'dis- and mounting'
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Panel>
                    {additionalRC.includes('N') && (
                        <Panel
                            header={
                                <Space>
                                    <SelectedElement
                                        checked={
                                            RepairType === 'incidental costs' &&
                                            checkManualRepairPositionForm(manualRepairPositionForm)
                                        }
                                        onChange={_e => {
                                            if (RepairType === 'incidental costs') {
                                                RepairPositionsModel.event.delRepairPositionByKey([key]);
                                                manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                            } else {
                                                unfoldKey('incidental costs');
                                            }
                                        }}
                                    />
                                    {t('RepairType.label.N')}
                                </Space>
                            }
                            key={'incidental costs'}
                        >
                            <Row align="middle" gutter={[8, 8]}>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ManualRepairPositionForm.SparePartPrice')}
                                </Col>
                                <Col span={12}>
                                    <InputNumber
                                        size="large"
                                        type="number"
                                        value={RepairType === 'incidental costs' ? SparePartPrice : undefined}
                                        onChange={value => {
                                            const currentViewValues =
                                                RepairType === 'incidental costs' ? manualRepairPositionForm : {};

                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                ...currentViewValues,
                                                Description,
                                                key,
                                                DATProcessId,
                                                SparePartPrice: value,
                                                RepairType: 'incidental costs'
                                            });
                                        }}
                                    />
                                </Col>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ManualRepairPositionForm.WorkTime')}
                                </Col>
                                {timeUnitsPerHour && (
                                    <>
                                        <Col span={12}>
                                            <InputNumber
                                                size="large"
                                                type="number"
                                                value={
                                                    RepairType === 'incidental costs' ? workTimeInTimeUnits : undefined
                                                }
                                                onChange={value => {
                                                    const currentViewValues =
                                                        RepairType === 'incidental costs'
                                                            ? manualRepairPositionForm
                                                            : {};

                                                    manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                        ...currentViewValues,
                                                        Description,
                                                        key,
                                                        DATProcessId,
                                                        WorkTime:
                                                            Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                        WorkPrice: undefined,
                                                        RepairType: 'incidental costs'
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                        </Col>
                                        <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                    </>
                                )}
                                <Col span={12}>
                                    <InputNumber
                                        size="large"
                                        type="number"
                                        value={RepairType === 'incidental costs' ? WorkTime : undefined}
                                        onChange={value => {
                                            const currentViewValues =
                                                RepairType === 'incidental costs' ? manualRepairPositionForm : {};

                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                ...currentViewValues,
                                                Description,
                                                key,
                                                DATProcessId,
                                                WorkTime: Math.round(value * 100) / 100,
                                                WorkPrice: undefined,
                                                RepairType: 'incidental costs'
                                            });
                                        }}
                                    />
                                    <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                                </Col>

                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ManualRepairPositionForm.WorkPrice')}
                                </Col>
                                <Col span={12}>
                                    <InputNumber
                                        size="large"
                                        type="number"
                                        value={RepairType === 'incidental costs' ? WorkPrice : undefined}
                                        onChange={value => {
                                            const currentViewValues =
                                                RepairType === 'incidental costs' ? manualRepairPositionForm : {};

                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                ...currentViewValues,
                                                Description,
                                                key,
                                                DATProcessId,
                                                WorkPrice: value,
                                                WorkTime: undefined,
                                                RepairType: 'incidental costs'
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    )}
                    {repairCodesPZST.length &&
                        repairCodesPZST.map(rc => {
                            const repairType = convertRepairTypeToFullRepairType(rc) || '';

                            return (
                                <Panel
                                    header={
                                        <Space>
                                            <SelectedElement
                                                checked={
                                                    RepairType === repairType &&
                                                    checkManualRepairPositionForm(manualRepairPositionForm)
                                                }
                                                onChange={_e => {
                                                    if (RepairType === repairType) {
                                                        RepairPositionsModel.event.delRepairPositionByKey([key]);
                                                        manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                                    } else {
                                                        unfoldKey(repairType);
                                                    }
                                                }}
                                            />
                                            {t('RepairType.label.' + rc)}
                                        </Space>
                                    }
                                    key={repairType}
                                >
                                    <Row align="middle" gutter={[8, 8]}>
                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                            {t('ManualRepairPositionForm.WorkTime')}
                                        </Col>
                                        {timeUnitsPerHour && (
                                            <>
                                                <Col span={12}>
                                                    <InputNumber
                                                        size="large"
                                                        type="number"
                                                        value={
                                                            RepairType === repairType ? workTimeInTimeUnits : undefined
                                                        }
                                                        onChange={value => {
                                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm(
                                                                {
                                                                    Description,
                                                                    key,
                                                                    DATProcessId,
                                                                    WorkTime:
                                                                        Math.round(
                                                                            (value / (timeUnitsPerHour || 1)) * 100
                                                                        ) / 100,
                                                                    WorkPrice: undefined,
                                                                    RepairType: repairType
                                                                }
                                                            );
                                                        }}
                                                    />
                                                    <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                                </Col>
                                                <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                                            </>
                                        )}
                                        <Col span={12}>
                                            <InputNumber
                                                size="large"
                                                type="number"
                                                value={RepairType === repairType ? WorkTime : undefined}
                                                onChange={value => {
                                                    manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                        Description,
                                                        key,
                                                        DATProcessId,
                                                        WorkTime: Math.round(value * 100) / 100,
                                                        WorkPrice: undefined,
                                                        RepairType: repairType
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                                        </Col>

                                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                            {t('ManualRepairPositionForm.WorkPrice')}
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber
                                                size="large"
                                                type="number"
                                                value={RepairType === repairType ? WorkPrice : undefined}
                                                onChange={value => {
                                                    manualPositionFormExtendedModel.events.setManualRepairPositionForm({
                                                        Description,
                                                        key,
                                                        DATProcessId,
                                                        WorkPrice: value,
                                                        WorkTime: undefined,
                                                        RepairType: repairType
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Panel>
                            );
                        })}
                </Collapse>
            </div>
            <div
                style={{
                    position: 'absolute',
                    // top: '170px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row gutter={[8, 16]}>
                    <Col span={12}>
                        <Button
                            disabled={!isModified}
                            size="large"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.cancel();
                                manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                            }}
                        >
                            {t('ManualRepairPositionForm.Cancel')}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            disabled={!isModified || !checkManualRepairPositionForm(manualRepairPositionForm)}
                            size="large"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.confirm();
                                manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                                rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                            }}
                        >
                            {t('ManualRepairPositionForm.Confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
