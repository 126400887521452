import { FC } from 'react';
import { SwitcherCircle, SwitcherRoot, SwitcherTrack, SwitcherStyledProps, SwitcherInput } from './styles';

export interface SwitcherProps extends SwitcherStyledProps {
    onChange?: (checked: boolean) => void;
}

export const Switcher: FC<SwitcherProps> = ({ checked, disabled, onChange }) => (
    <SwitcherRoot disabled={disabled} onClick={() => onChange?.(!checked)}>
        <SwitcherInput type="checkbox" checked={checked} disabled={disabled} />
        <SwitcherCircle />
        <SwitcherTrack />
    </SwitcherRoot>
);
