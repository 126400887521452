import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { Gate } from './stores/component';
import { formEvents, formStores } from './stores/form';
import './stores/init';

import {
    ButtonsContainer,
    Container,
    StyledButton,
    StyledFormButton,
    TextareaWrap,
    TextareaFieldStyled
} from './styles';
import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { SelectField } from '@wedat/ui-kit/Formik/SelectField';

export interface ContractStatusChangeModalProps extends ModalProps {
    contractId: number;
    initialStatusId?: DAT2.ContractStatusTransition['statusId'];
    onStatusChange?: (status: DAT2.ContractStatusTransition) => void;
    onStatusChangeFailed?: () => void;
}

//TODO: fix css, add translations dynamically
export const ContractStatusChangeModal: React.FC<ContractStatusChangeModalProps> = (
    props: ContractStatusChangeModalProps
) => {
    const { t } = useTranslation();
    const isTablet = useMedia(sizes.tablet);

    const { isOpen, onDismiss } = props;
    const initialFormValues = useStore(formStores.initialFormValues);
    const selectOptions = useStore(formStores.selectOptions);

    const getForm = () => (
        <>
            <Gate {...props} />
            <Formik initialValues={initialFormValues} onSubmit={formEvents.formSubmitted} enableReinitialize>
                <Form>
                    <Container>
                        <SelectField
                            label={t('inbox:statusPopup')}
                            name="statusId"
                            options={selectOptions}
                            isSearchable={!isTablet} // this for disable virtual keyboard in mobile
                        />
                        <TextareaWrap>
                            <TextareaFieldStyled
                                name="comment"
                                label={t('inbox:statusPopupComponent.comment')}
                                maxLength={500}
                            />
                        </TextareaWrap>
                        <ButtonsContainer>
                            <StyledButton onClick={onDismiss} typeStyle={{ type: 'square', color: 'blue' }}>
                                {t('inbox:cancel')}
                            </StyledButton>
                            <StyledFormButton type="submit">{t('inbox:statusPopupComponent.ok')}</StyledFormButton>
                        </ButtonsContainer>
                    </Container>
                </Form>
            </Formik>
        </>
    );

    return isTablet && isOpen ? (
        getForm()
    ) : (
        <ModalPrimary bodyHeight="auto" isOpen={isOpen} title={t('inbox:statusPopup')} onDismiss={onDismiss}>
            {getForm()}
        </ModalPrimary>
    );
};
