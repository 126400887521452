export const SELECT_FIELDS = {
    appointments: 'appointments',
    expiryClaims: 'expiryClaims'
};

export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23];
export const disabledTime = () => DISABLED_HOURS;

// Will be changed in stage 2
const ADMIN_ID = 3134154;

export const isAdmin = (id: number) => id === ADMIN_ID;
