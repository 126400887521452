/*
 * Template - the object with settings and products-configuration
 * used to configure the contract and products (plugins).
 *
 * Contracts are created via claim-builders, and every claim-builder has `templateId`
 * property that determines which template should be used during creation.
 * Every contract is built on base of some template and has the same `templateId` property
 * as the claim-builder with which the contract was created.
 * When some contract or claim-builder is opened, `templateId` store is updated
 * which leads to update of `templateSettings` and `productsConfiguration` stores.
 *
 * Template affects:
 * - contract's data (country, restriction, etc.) and behaviour (disabledStatuses, initialStatus)
 * - products' behaviour and (not so often) data
 */
import { combine, createEvent, createStore, restore } from 'effector';
import { makeRestoreable } from '@dat/core/utils/effector/makeRestoreable';
import { INITIAL_TEMPLATE_CONFIGURATION, INITIAL_TEMPLATE_SETTINGS } from '../configuration/constants';
import { MergedTemplates } from './types';

// Available templates are templates from customer-configuration filtered by `availableTemplateIds` from user-settings
const availableTemplates = createStore<MergedTemplates>({ default: INITIAL_TEMPLATE_CONFIGURATION });
const setTemplate = createEvent<DAT2.TemplateId | null>();
const setDefaultTemplate = createEvent();
// `templateId` can be `null` only in case of claim creation when user's `defaultTemplateId` is not specified
const templateId = restore(setTemplate, null).reset(setDefaultTemplate);
// `_templateKey` is internal store that is used to access default template if `templateId` is not specified
const _templateKey = templateId.map<keyof DAT2.CustomerConfiguration['templates']>(id => id || 'default');

const templateSettings = restore(makeRestoreable(INITIAL_TEMPLATE_SETTINGS));

/*
 * Products-configuration is taken from customer's configuration without preprocessing and can be invalid.
 * Each product (plugin or app) should have it's own initial configuration inside it,
 * so this store doesn't need initial value, it can be `undefined`.
 * Preprocessing of individual product-configuration should be done also inside product
 */
const productsConfiguration = combine(
    [availableTemplates, _templateKey],
    ([availableTemplates, _templateKey]) => availableTemplates[_templateKey]?.products
);

export const sharedTemplateEvents = {
    setTemplate,
    setDefaultTemplate
};
export const sharedTemplateStores = {
    availableTemplates,
    templateId,
    templateSettings, // object
    productsConfiguration,

    _templateKey
};
