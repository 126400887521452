import * as R from 'ramda';

import { getParsedArraySafe } from '@dat/api2/utils';

import { FormBuilderPluginOptions } from '@dat/form-builder';
import { DataField } from '@dat/form-builder/src/types/dataScheme';

interface Param {
    initialData: FormBuilderPluginOptions['data'];
    contract: DAT2.ContractFromGetContract;
}

export const extractFormBuilderDataFromContract = ({
    initialData,
    contract
}: Param): FormBuilderPluginOptions['data'] => {
    const formBuilderData: FormBuilderPluginOptions['data'] = R.clone(initialData);
    const formBuilderFieldsNames = getFormBuilderFieldsNames(formBuilderData);
    const contractEntries = getParsedArraySafe(contract.customTemplateData?.entry);
    const formEntries = contractEntries.filter(({ key }) => formBuilderFieldsNames.includes(key));

    formEntries.forEach(({ key, value }) => {
        setFormBuilderFieldValue({
            data: formBuilderData,
            name: key,
            value: value._text
        });
    });

    return formBuilderData;
};

interface SetFormBuilderFieldValueParam {
    data: FormBuilderPluginOptions['data'];
    name: string;
    value: DAT2.Field.Primitive;
}

const setFormBuilderFieldValue = ({ data, name, value }: SetFormBuilderFieldValueParam) => {
    const fields = getFormBuilderFields(data);
    const fieldToChange = fields.find(({ id }) => id === name);

    if (fieldToChange) {
        fieldToChange.initValue = value;
    }
};

const getFormBuilderFields = (data: FormBuilderPluginOptions['data']): DataField[] => {
    const rows = data[1].groups.flatMap(group => group.content.rows);
    const fields = rows.flatMap(row => row.fields);

    return fields;
};

const getFormBuilderFieldsNames = (data: FormBuilderPluginOptions['data']): string[] =>
    getFormBuilderFields(data).map(({ id }) => id);
