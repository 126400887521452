import React, { FC } from 'react';

import { pluginEvents } from './stores/plugin';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => (
    <PluginProvider name="assign-partner" options={options} onInit={pluginEvents.initPlugin}>
        <Main />
    </PluginProvider>
);

export default App;
