import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useStore } from 'effector-react';
import { pathOr } from 'ramda';
import { ObjectSchema } from 'yup';

import { formFieldsStore } from '../../stores/fieldsValues';
import { addOptions, conditionRulesStore } from '../../stores/conditions';
import { useTabGroups } from './hooks';
import { useGetConditionRules, useGetConditionValues } from '../../hooks/';
import { DataCondition, DataField, DataGroup } from '../../types/dataScheme';
import { getInitialValues } from '../../utils/getInitialValues';
import { Condition } from '../../utils/conditions/Condition';
import { getForm, getFromCurrentForm } from '../../utils/';

import { FormButton } from '@wedat/ui-kit/Formik';
import { GroupContent } from '../GroupContent';
import { PluginOptions } from '../../types/plugin';
import { FormikController } from '../FormikController';
import { FormWrapper } from './styles';

export const Main: FC<PluginOptions> = ({
    data,
    options,
    onComplete,
    onChangeForm,
    horizontalFields,
    horizontalFieldsFullWidth,
    globalInitialValues
}) => {
    const [formGroups, setFormGroups] = useState<DataGroup[]>([]);
    const [schema, setSchema] = useState<ObjectSchema | null>(null);
    const [formName, setFormName] = useState<string>('');
    const [currentCondition, setCondition] = useState<DataCondition | null>();
    const { TabsContent } = useTabGroups(formName, formGroups, horizontalFields);

    const conditionRules = useStore(conditionRulesStore);
    const formFields = useStore(formFieldsStore);
    const initialValues = useMemo(() => getInitialValues(formFields, formName), [formFields, formName]);
    const getRules = useGetConditionRules();

    // calculate condition
    const { getFieldKey, getFieldValue, getOption } = useGetConditionValues(formName);

    const applyCalculateCondition = useCallback(() => {
        const rules = pathOr([], ['condition', 0])(currentCondition);
        const condition = new Condition(
            { namedCondition: 'CALCULATE', condition: rules },
            { key: getFieldKey, value: getFieldValue, option: getOption }
        );
        return condition.calculate();
    }, [currentCondition, getFieldKey, getFieldValue, getOption]);

    // get condition rules for calculation
    useEffect(() => {
        if (conditionRules) {
            const currentConditionRules = getRules(formName, 'CALCULATE');
            setCondition(currentConditionRules);
        }
    }, [formName, conditionRules, getRules]);

    useEffect(() => {
        const { groups, schema, formName } = getForm(data || []);
        setFormGroups(groups);
        setSchema(schema);
        setFormName(formName);
        options && addOptions(options);
    }, [options, data]);

    //  get submit button
    const currentFormFields = getFromCurrentForm(formFields, formName);

    const submitBtn: DataField | undefined = useMemo(() => {
        const submitField: DataField = Object.values(currentFormFields).find(
            (field: DataField) => field.Type === 'submit'
        );
        return submitField;
    }, [currentFormFields]);

    const handleFormikSubmit = () => {
        const result = applyCalculateCondition();
        onComplete(result);
    };

    return (
        <FormWrapper>
            <Formik
                enableReinitialize
                initialValues={globalInitialValues ? globalInitialValues : initialValues}
                validationSchema={schema}
                onSubmit={handleFormikSubmit}
            >
                <>
                    <FormikController onChange={onChangeForm} />

                    {!!formGroups &&
                        formGroups
                            .filter(({ tabName }) => !tabName)
                            .map((group, idx) => (
                                <GroupContent
                                    key={`${group.groupName}_${idx}`}
                                    formName={formName}
                                    group={group}
                                    horizontalFields={horizontalFields}
                                    horizontalFieldsFullWidth={horizontalFieldsFullWidth}
                                />
                            ))}

                    {TabsContent}

                    {!!submitBtn && (
                        <FormButton submit uppercase onClick={handleFormikSubmit}>
                            {submitBtn?.text || 'submit'}
                        </FormButton>
                    )}
                </>
            </Formik>
        </FormWrapper>
    );
};
