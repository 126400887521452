import { attach, forward } from 'effector';

import { statusesEffects, statusesStores } from './index';
import { componentStores } from '../component';
import { sharedContractStatusEffects } from '@dat/shared-models/contract/Status';
import { formStores } from '../form';

const { changeStatusFx } = statusesEffects;
const { possibleStatuses } = statusesStores;
const { contractId } = componentStores;
const { submittedFormValues } = formStores;
const { getPossibleContractStatusTransitionsFx } = sharedContractStatusEffects;

forward({
    from: contractId,
    to: getPossibleContractStatusTransitionsFx
});

forward({
    from: getPossibleContractStatusTransitionsFx.doneData,
    to: possibleStatuses
});

changeStatusFx.use(
    attach({
        effect: sharedContractStatusEffects.changeContractStatusFx,
        source: { contractId, submittedFormValues },
        mapParams: (
            { name, statusType, statusId }: DAT2.ContractStatusTransition,
            { contractId, submittedFormValues }
        ) => ({
            ...submittedFormValues,
            statusName: name,
            statusType,
            contractId,
            statusId
        })
    })
);
