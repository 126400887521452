import { getParsedArraySafe } from '@dat/api2/utils';
import { createNewSubject } from './createNewSubject';

import { SubjectType } from '../types/subjects';

export const getSubjectFromContract = (
    contract: DAT2.ContractFromGetContract | null,
    subjectType: SubjectType
): BFF.AddressBook.Subject => {
    const subjectEntries = getParsedArraySafe(contract?.customTemplateData?.entry).filter(item =>
        item.key.startsWith(`${subjectType}_`)
    );

    const subjectFromContract = subjectEntries.reduce(
        (obj, item) =>
            Object.assign(obj, {
                [item.key === `${subjectType}_id` ? '_id' : item.key.replace(`${subjectType}_`, '')]: item.value._text
            }),
        {} as BFF.AddressBook.Subject
    );

    return { ...createNewSubject(), ...subjectFromContract };
};
