import { createEffect, createEvent, createStore } from 'effector';
import { CardValues, FieldValues } from '../../types/calculation';

const calculateAndGetContractFx = createEffect<DAT2.ContractId, void>();

const setFieldValues = createEvent<FieldValues[]>();
const setCardValues = createEvent<CardValues[]>();

const fieldValues = createStore<FieldValues[]>([]).on(setFieldValues, (_state, payload) => payload);
const cardValues = createStore<CardValues[]>([]).on(setCardValues, (_state, payload) => payload);

export const calculationEffects = {
    calculateAndGetContractFx
};

export const calculationEvents = {
    setFieldValues,
    setCardValues
};

export const calculationStores = {
    fieldValues,
    cardValues
};
