import React from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonProps } from '../../components/Button';
export interface IFormButtonProps extends ButtonProps {
    isIgnoreValidation?: boolean;
}

export const FormButton: React.FC<IFormButtonProps> = props => {
    const { isValid } = useFormikContext();
    const { disabled = false, isIgnoreValidation = false, ...rest } = props;

    const disable = disabled || (isIgnoreValidation ? false : !isValid);

    return <Button {...rest} disabled={disable} />;
};
