import React, { FC } from 'react';
import { useStore } from 'effector-react';
import * as R from 'ramda';

import { i18nEvents, i18nStores } from '@dat/shared-models/i18n';
import { LOCALES_LANGUAGE_NAMES } from '@dat/core/constants/i18n';

import { Content, Item, Flag } from './styles';
import { FLAGS_IMAGES } from '@dat/core/constants/flags';

interface Props {
    close: () => void;
}

export const Languages: FC<Props> = ({ close }) => {
    const currentLocale = useStore(i18nStores.locale);

    const createChangeSelectHandler = (locale: DAT2.Locale) => () => {
        i18nEvents.localeChangedByUser(locale);
        close();
    };

    return (
        <Content>
            {R.toPairs(LOCALES_LANGUAGE_NAMES).map(([locale, name]) => (
                <Item
                    key={locale}
                    isActive={locale === currentLocale}
                    type="button"
                    onClick={createChangeSelectHandler(locale)}
                >
                    <Flag src={FLAGS_IMAGES[locale]} />
                    {name}
                </Item>
            ))}
        </Content>
    );
};
