import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { hideBlocksStores } from '../stores/hideBlocks';
import { HeaderBlocks, SideBarBlocks } from '../types/hideBlocks';

interface Params {
    headerBlock?: HeaderBlocks;
    sidebarBlock?: SideBarBlocks;
}

export const useHiddenBlock = ({ headerBlock, sidebarBlock }: Params) => {
    const hideBlocks = useStore(hideBlocksStores.hideBlocks);

    const hiddenHeaderBlock = useMemo(
        () => Boolean(headerBlock && hideBlocks?.header?.includes(headerBlock)),
        [hideBlocks, headerBlock]
    );

    const hiddenSidebarBlock = useMemo(
        () => Boolean(sidebarBlock && hideBlocks?.sidebar?.includes(sidebarBlock)),
        [hideBlocks, sidebarBlock]
    );

    return hiddenHeaderBlock || hiddenSidebarBlock;
};
