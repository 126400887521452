import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

interface CreateAppointmentPopupStyledProps {
    isDescriptionProvided: boolean;
}

export const CreateAppointmentPopupStyled = styled.div<CreateAppointmentPopupStyledProps>`
    width: 600px;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    ${media.phoneBig`
            width: 100%;
        `}

    .ant-picker {
        width: 100%;
        height: 48px;
        border-radius: 8px;
    }

    .ant-input-group-addon {
        height: 48px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }

    .ant-input {
        height: 48px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: ${({ isDescriptionProvided }) => (!isDescriptionProvided ? '8px' : 0)};
        border-top-left-radius: ${({ isDescriptionProvided }) => (!isDescriptionProvided ? '8px' : 0)};
    }
`;

export const CreateAppointmentPopupContentStyled = styled.div`
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px); // 48px button's height
`;

export const UpperSectionStyled = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const InputSectionStyled = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const DatePickerWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const DatePickerSectionStyled = styled.div`
    display: block;
    width: 100%;

    &:first-child {
        margin-right: 12px;
    }
`;

export const DatepickerStyled = styled.div`
    width: 100%;
`;

export const ExpirationPickerSectionStyled = styled.div`
    display: block;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const ExpirationPickersStyled = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonStyledWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;

    .ant-picker {
        &:first-child {
            margin-right: 0;
        }
    }
`;

export const ButtonSectionStyled = styled.div`
    width: 130px;
`;

export const ErrorContainer = styled.div`
    span {
        color: ${({ theme }) => theme.colors.red['900']};
    }
`;
