import { useMemo } from 'react';
import { useField } from 'formik';
import * as R from 'ramda';

import { useInstance } from '../../../hooks/useInstance';

import { Subject } from '../../../types/subjects';

export const useFoundSubjects = () => {
    const { currentSubjects } = useInstance('currentSubjects');
    const [{ value: search }] = useField('search');

    return useMemo(
        () =>
            currentSubjects.filter((subject: Subject) =>
                Object.values(R.omit(['_id'], subject)).some(value =>
                    String(value).toUpperCase().includes(search.toUpperCase())
                )
            ),
        [currentSubjects, search]
    );
};
