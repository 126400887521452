import { createStore, createEvent, createEffect, sample, attach } from 'effector';
import { calculate } from '@dat/api2dat/services/vehicleRepairService';
import { PluginOptions } from '../types/plugin';
import { getInterimCalcParams } from '../utils/getInterimCalcParams';
import { requestCredentialsModel } from './requestCredentialsModel';
import { RepairPositionsModel } from './repairPositionsModel';
import { equipmentModel } from './equipmentModel';

export interface InterimCalculationType {
    sparePartsCosts?: number;
    labourCosts?: number;
    lacquerCosts?: number;
    totalCosts: number;
    totalCostsVAT: number;
    currency: string;
}

const setInterimCalculation = createEvent<InterimCalculationType | null>();
const interimCalculation = createStore<InterimCalculationType | undefined | null>(null).on(
    setInterimCalculation,
    (_, newInterimCalculation) => newInterimCalculation
);

// const setIsLoading = createEvent<boolean>();
// const isLoading = createStore<boolean>(false).on(setIsLoading, (_, newIsLoading) => newIsLoading);
const setIsExpanded = createEvent<boolean>();
const isExpanded = createStore<boolean>(false).on(setIsExpanded, (_, newIsExpanded) => newIsExpanded);

const calculateFX = createEffect(calculate);

// INIT PLUGIN
const initInterimCalculationFx = attach({
    source: requestCredentialsModel.stores.requestCredentials,
    mapParams: (pluginOptions: PluginOptions | null, credentials) => {
        const dossier = pluginOptions?.settings?.contract?.Dossier;

        const repairPositions = dossier?.RepairCalculation?.RepairPositions?.RepairPosition;
        const specialEquipmentIds =
            dossier?.Vehicle?.Equipment?.SpecialEquipment?.EquipmentPosition.map(eq => eq.DatEquipmentId) || [];
        const seriesEquipmentIds =
            dossier?.Vehicle?.Equipment?.SeriesEquipment?.EquipmentPosition.map(eq => eq.DatEquipmentId) || [];
        const equipment = [...specialEquipmentIds, ...seriesEquipmentIds];

        const params = getInterimCalcParams(pluginOptions, repairPositions, equipment);

        // if (credentials && params) interimCalculationModel.events.setIsLoading(true);

        return { credentials, params };
    },
    effect: calculateFX
});

// CONFIRM
const getInterimCalculationFx = attach({
    source: {
        credentials: requestCredentialsModel.stores.requestCredentials,
        repairPositions: RepairPositionsModel.store.confirmedRepairPositions,
        specialEquipment: equipmentModel.stores.specialEquipment,
        existingEquipment: equipmentModel.stores.existingEquipment
    },
    mapParams: (
        pluginOptions: PluginOptions | null,
        { credentials, repairPositions, specialEquipment, existingEquipment }
    ) => {
        const specialEquipmentIds = specialEquipment?.EquipmentPosition.map(eq => eq.DatEquipmentId) || [];
        const existingEquipmentIds = existingEquipment.map(eq => eq.DatEquipmentId);
        const equipment = [...specialEquipmentIds, ...existingEquipmentIds];

        const params = getInterimCalcParams(pluginOptions, repairPositions, equipment);

        // if (credentials && params) interimCalculationModel.events.setIsLoading(true);

        return { credentials, params };
    },
    effect: calculateFX
});

sample({
    clock: calculateFX.doneData,
    fn: calculationResult => {
        // setIsLoading(false);
        const calcSummary = calculationResult.Dossier?.RepairCalculation?.CalculationSummary;
        const currency = calculationResult.Dossier?.Currency;

        return calcSummary
            ? {
                  sparePartsCosts: calcSummary.SparePartsCosts.TotalSum,
                  labourCosts: calcSummary.LabourCosts.TotalSum,
                  lacquerCosts: calcSummary.LacquerCosts.TotalSum,
                  totalCosts: calcSummary.TotalNetCosts,
                  totalCostsVAT: calcSummary.TotalGrossCosts,
                  currency: currency
              }
            : null;
    },
    target: setInterimCalculation
});

export const interimCalculationModel = {
    stores: {
        interimCalculation,
        // isLoading,
        isExpanded
    },
    events: {
        setInterimCalculation,
        // setIsLoading,
        setIsExpanded
    },
    effects: {
        initInterimCalculationFx,
        getInterimCalculationFx,
        calculateFX
    }
};
