import { getParsedArraySafe } from '@dat/api2/utils';
import { TableFooterTypes } from '../types/tableFooter';
import { MobileTableHeading } from '../components/Layout/styles';
import { TFunction } from 'react-i18next';
import { NumberFormatWrapper } from '../components/NumberFormatWrapper';

export const createDataForLabour = (t: TFunction, dossier: DAT2.Dossier | null, isMobile: boolean) => {
    const LabourCalculationResults = dossier?.RepairCalculation?.CalcResultCommon?.LabourPositions?.LabourPosition;

    return getParsedArraySafe(LabourCalculationResults).map(
        ({ DATProcessId, WageLevel, Description, Duration, RepairType, ValueTotalCorrected, WageType, WorkNumber }) =>
            isMobile
                ? {
                      col1: DATProcessId,
                      col2: WorkNumber,
                      col3: Description,
                      children: [
                          {
                              col4: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.level')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{WageLevel}</MobileTableHeading>
                                  </>
                              ),
                              col5: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.repairType')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{WageType}</MobileTableHeading>
                                  </>
                              ),
                              col6: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.labourType')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{RepairType}</MobileTableHeading>
                                  </>
                              ),
                              col7: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.type')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{t('default')}</MobileTableHeading>
                                  </>
                              ),
                              col8: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.time')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={Duration} />
                                      </MobileTableHeading>
                                  </>
                              ),
                              col9: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('labourTable.headers.price')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={ValueTotalCorrected} />
                                      </MobileTableHeading>
                                  </>
                              )
                          }
                      ]
                  }
                : {
                      col1: DATProcessId,
                      col2: WorkNumber,
                      col3: Description,
                      col4: WageLevel,
                      col5: WageType,
                      col6: RepairType,
                      col7: t('default'),
                      col8: <NumberFormatWrapper value={Duration} />,
                      col9: <NumberFormatWrapper value={ValueTotalCorrected} />
                  }
    );
};

export const createDataForLabourTableFooter = (t: TFunction, dossier: DAT2.Dossier | null): TableFooterTypes => {
    const currency = dossier?.Currency;

    const totalBodyWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Bodywork?.Price;

    const totalElectricsWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Electric?.Price;

    const totalMechanicsWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Mechanic?.Price;

    const totalWorkCosts = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.TotalSum;

    const bodyWorkTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Bodywork?.Units;

    const electricTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Electric?.Units;

    const mechanicTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Mechanic?.Units;

    const totalTimeSpent = (bodyWorkTime || 0) + (electricTime || 0) + (mechanicTime || 0);

    const currencyValue = currency ?? '';

    return {
        items: [
            {
                price: <NumberFormatWrapper value={totalTimeSpent}>{t('dataForCards.hour')}</NumberFormatWrapper>,
                label: t('dataForCards.timeSpent')
            },
            {
                price: <NumberFormatWrapper value={totalBodyWorksPrice}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.body')
            },
            {
                price: <NumberFormatWrapper value={totalElectricsWorksPrice}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.electrics')
            },
            {
                price: <NumberFormatWrapper value={totalMechanicsWorksPrice}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.mechanics')
            },
            {
                price: <NumberFormatWrapper value={totalWorkCosts}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.totalWorkCosts'),
                styles: {
                    fontWeight: 500,
                    fontSize: '17px'
                }
            }
        ]
    };
};
