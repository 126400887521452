/*
 * Extracts property "_text" of field if field is object.
 * Returns back field's value if field is primitive.
 */
//TODO: refactor type
export const getFieldText = <T extends DAT2.Field.Primitive>(
    field: T | DAT2.Field.AttrText<T> | undefined
): T | undefined => {
    if (typeof field === 'object') {
        return field._text;
    }

    return field;
};
