import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 536px;

    ${media.phone`
        max-width: 100%;
    `}
`;

export const ButtonRecover = styled(Button)`
    width: 308px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    margin-bottom: 16px;
    background-color: ${({ theme }) => theme.colors.deepBlue['800']};
    color: ${({ theme }) => theme.colors.white};

    ${media.phone`
        width: 100%;
    `}
`;

export const ButtonNew = styled(Button)`
    width: 308px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    margin-bottom: 16px;
    border: ${({ theme }) => theme.colors.gray['100']} 2px solid;

    ${media.phone`
        width: 100%;
    `}
`;

export const TextStyled = styled(Text)`
    width: 308px;
    margin-top: 36px;
    margin-bottom: 32px;

    ${media.phone`
        width: 100%;
    `}
`;
