import React, { useState, FC } from 'react';

import { FieldsetStyled } from '../Fieldset';
import { Legend, LegendText } from '../Legend';

import { InputProps } from './types';

import { IconStyled, InputStyled, Wrapper, LabelStyled, ButtonReset } from './styles';
import { SearchIcon, CloseIcon } from '@wedat/ui-kit/components/Icons';

export const SearchInput: FC<InputProps> = ({
    label,
    name,
    placeholder = '',
    onChange,
    onFocus,
    onBlur,
    onResetInput,
    disabled = false,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setInputValue(value);

        onChange?.(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);

        onFocus?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);

        onBlur?.(e);
    };

    const handleResetInput = () => {
        setInputValue('');

        onResetInput?.();
    };

    const hasLabel = !!label;
    const filled = !!String(inputValue);
    const showPlaceholder = focused || !hasLabel;

    return (
        <Wrapper width={rest.width} {...rest}>
            <InputStyled
                type="text"
                value={inputValue}
                name={name}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                placeholder={showPlaceholder ? placeholder : ''}
            />
            <LabelStyled filled={filled} focused={focused} disabled={disabled}>
                {label}
            </LabelStyled>
            <IconStyled>
                <SearchIcon />
            </IconStyled>
            <FieldsetStyled width={rest.width} disabled={disabled}>
                <Legend disabled={disabled} focused={focused} filled={filled}>
                    <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                        {label}
                    </LegendText>
                </Legend>
            </FieldsetStyled>
            <ButtonReset onClick={handleResetInput}>
                <CloseIcon />
            </ButtonReset>
        </Wrapper>
    );
};
