import { getKindOfSVG } from '../../utils/getKindOfSVG';
import { sample } from 'effector';
import { containedPluginsStores } from '../containedPlugins';
import { pluginCarsStores } from '../pluginCars';
import { vehicleSVGEffects, vehicleSVGEvents, vehicleSVGStores } from './index';
import { setStepsImgDynamicSVG } from '../../utils/setStepsImgDynamicSVG';
import { ClientConfiguration } from '../../types/plugin';
import { sharedTemplateStores } from '@dat/shared-models/template';

const { productsConfiguration } = sharedTemplateStores;

const sharedConfiguration = productsConfiguration.map<ClientConfiguration | undefined>(
    productsConfiguration => productsConfiguration?.['ai-claim']
);

sample({
    source: containedPluginsStores.vehicleSelectionPluginResult,
    fn: vehicleSelectionPluginResult => {
        const datECode = vehicleSelectionPluginResult?.vehicle.datECode;
        // Parameters from DAT Europa Code
        const FZA = datECode?.substring(0, 2) || '';
        const HST = datECode?.substring(2, 5) || '';
        const HT = datECode?.substring(5, 8) || '';
        return {
            FZA,
            HST,
            HT
        };
    },
    target: vehicleSVGEffects.getVehicleTypeFX
});

sample({
    clock: vehicleSVGEffects.getVehicleTypeFX.doneData,
    fn: getVehicleTypeResponse => {
        if (!getVehicleTypeResponse.length) return '4_doors'; // default SVG mask is for 4-doors vehicle

        const [vehicleType, doors] = getVehicleTypeResponse;
        const datECode = containedPluginsStores.vehicleSelectionPluginResult.getState()?.vehicle.datECode || '';

        return getKindOfSVG(datECode, vehicleType, doors);
    },
    target: vehicleSVGEvents.setKindOfSVG
});

sample({
    source: { sharedConfiguration: sharedConfiguration, kindOfSVG: vehicleSVGStores.kindOfSVG },
    clock: vehicleSVGStores.kindOfSVG,
    fn: ({ sharedConfiguration, kindOfSVG }) =>
        setStepsImgDynamicSVG(sharedConfiguration?.photoCapture?.steps || [], kindOfSVG),
    target: pluginCarsStores.steps
});
