import { useStore } from 'effector-react';
import { Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { formatBytesWithoutSpace } from '../../utils/formatBytes';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';

import { FC, memo, useEffect, useState } from 'react';

const timeOutAfterDefault = 3000;

const DownloadingProgress: FC<{ timeOutAfter?: number; style?: React.CSSProperties }> = ({ timeOutAfter, style }) => {
    const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);
    const initListPending = useStore(availableAssemblyGroupsModel.events.initList.pending);
    const downloadingState = useStore(availableAssemblyGroupsModel.stores.downloadingState);

    const [delay, setDelay] = useState(false);
    const [previousPending, setPreviousPending] = useState(initListPending);

    useEffect(() => {
        if (previousPending && !initListPending && !delay) {
            setPreviousPending(initListPending);
            setDelay(true);
            setTimeout(() => {
                setDelay(false);
            }, timeOutAfter || timeOutAfterDefault);
        }
    }, [initListPending, previousPending, timeOutAfter, delay]);

    if (!initListPending && !delay) return null;

    return (
        <div
            style={{
                padding: '8px 16px 8px 16px',
                width: '100%',
                textAlign: 'center',
                ...style
            }}
            // onClick={() =>
            //     graphicDamageSelectionModel.events.updateStore({
            //         showGroupsList: true
            //     })
            // }
        >
            {!availableAssemblyGroupsStore.startDownloading
                ? ' '
                : DateTime.fromJSDate(availableAssemblyGroupsStore.availableDate || new Date())
                      .diff(DateTime.fromJSDate(availableAssemblyGroupsStore.startDownloading))
                      .toFormat('s.SSS')
                      .slice(0, -2) + 's'}
            &ensp;
            {formatBytesWithoutSpace(downloadingState.downloadedSize)}
            &ensp;
            {downloadingState.availableNumber}/{availableAssemblyGroupsStore.availableAssemblyGroups.length}
            &emsp;
            {initListPending ? <LoadingOutlined /> : ''}
            <br />
            <Progress
                percent={
                    (downloadingState.availableNumber / availableAssemblyGroupsStore.availableAssemblyGroups.length) *
                    100
                }
                showInfo={false}
            />
        </div>
    );
};

export default memo(DownloadingProgress, () => true);
