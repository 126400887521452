import React from 'react';

import { useStore } from 'effector-react';
import { ClaimStepper } from '@dat/claim-stepper';
import { containedPluginsStores } from '../../stores/containedPlugins';

export const StepperPage: React.FC = () => {
    const options = useStore(containedPluginsStores.stepperPluginOptions);

    return <ClaimStepper options={options} />;
};
