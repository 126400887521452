import { factorsToSearch } from '../constants/factors';
import { getParsedArraySafe } from '@dat/api2/utils';
import { filterArrayOfObjectsByExistingKeys } from '@dat/core/utils';
import { parseProcedureRelatedParametersObject } from './parseProcedureRelatedParametersObject';

import { PartialFactorsParametersObject } from '../types/factors';
import { PlainObject } from '@dat/core/types/common';

export const extractFactorsParametersFromContract = (
    contract: DAT2.ContractFromGetContract
): PartialFactorsParametersObject => {
    const result: PlainObject = {};
    const parameters = getParsedArraySafe(
        contract?.Dossier?.RepairCalculation?.ProcedureRelatedParameters?.ProcedureRelatedParameter
    );

    factorsToSearch.forEach(factor => {
        const factorParameters = parameters.filter(parameter => parameter.factor === factor);
        const factorProcedureRelatedParametersObject = createProcedureRelatedParametersObject(factorParameters);

        result[factor] = parseProcedureRelatedParametersObject(factorProcedureRelatedParametersObject);
    });

    return result;
};

const createProcedureRelatedParametersObject = (
    procedureRelatedParameters: DAT2.ProcedureRelatedParameter[]
): DAT2.ProcedureRelatedParametersObject => {
    const result: DAT2.ProcedureRelatedParametersObject = {};
    const validParameters = filterArrayOfObjectsByExistingKeys(procedureRelatedParameters, ['attribute']);

    validParameters.forEach(parameter => {
        const { attribute: parameterKey, ...parameterProperties } = parameter;

        result[parameterKey] = parameterProperties;
    });

    return result;
};
