import { createEffect, createEvent, createStore, forward, guard, merge, restore, sample, split } from 'effector';
import _ from 'lodash';

import { contractEffects, contractStores } from '@dat/shared-models/contract';
import { containedPluginsEvents, containedPluginsStores } from '../containedPlugins';
import { pluginStores as equipmentPluginStores } from '@dat/equipment/src/stores/plugin';
import { equipmentEvents } from '@dat/equipment/src/stores/equipment';
import { factorsStores } from '@dat/labour-rates/src/stores/factors';
import { fastTrackElementModel } from '@dat/fast-track/src/stores/fastTrackElementModel';
import { createPayloadForCreateOrUpdateContract } from '../../utils/createPayloadForCreateOrUpdateContract';
import { sharedContractStatusEffects } from '@dat/shared-models/contract/Status/index';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { guardPath } from '@dat/core/utils/effector/guardPath';
import { ROUTES } from '../../constants/router';
import { pluginStores } from '../plugin';

// import { GrapaPluginResult } from '@dat/grapa';
import { formEvents } from '../form';
import { ResultGrapaDossier } from '@dat/grapa/src/types/plugin';
import { prepareEquipmentForContract } from '@dat/core/utils/prepareEquipmentForContract';
import { evaluationEffects } from '@dat/valuate-finance/src/stores/evaluation';

const { changeContractStatusFx } = sharedContractStatusEffects;
const { contractId } = contractStores;
const { urlPath } = pluginStores;
const { repairPositions } = sharedRepairCalculationStores;
const { createContractFx, updateCurrentContractFx, getContractFx, calculateCurrentContractFx } = contractEffects;
const { vehicleSelectionCompleted, formBuilderFieldBlurred, formBuilderFieldChanged, grapaRepairPositionsChanged } =
    containedPluginsEvents;
const { isCalculationAvailable } = containedPluginsStores;
const { contractFactorsParameters: labourRatesFactorsParameters } = factorsStores;
const { pluginResult: equipmentPluginResult } = equipmentPluginStores;
const { equipmentMoved } = equipmentEvents;
const { mailFormSubmitted, valuationFormSubmitted } = formEvents;
const {
    effects: { saveFTClaim }
} = fastTrackElementModel;

const isContractCreated = contractId.map(id => !!id);

/* Plugins updates affecting the contract */
const vehicleSelectionUpdated = sample({
    clock: vehicleSelectionCompleted,
    fn: vehicleSelectionPluginResult => {
        const vsmResult = { ...vehicleSelectionPluginResult };
        vsmResult.existingEquipment = vehicleSelectionPluginResult.existingEquipment || {
            SeriesEquipment: [],
            SpecialEquipment: []
        };
        const SeriesEquipment = vehicleSelectionPluginResult.vinResult?.vinRequestResultEquipment?.equipment.filter(
            item => item.standard
        );
        const SpecialEquipment = vehicleSelectionPluginResult.vinResult?.vinRequestResultEquipment?.equipment.filter(
            item => !item.standard
        );
        vsmResult.existingEquipment.SeriesEquipment = SeriesEquipment
            ? prepareEquipmentForContract(SeriesEquipment)
            : vsmResult.existingEquipment.SeriesEquipment;
        vsmResult.existingEquipment.SpecialEquipment = SpecialEquipment
            ? prepareEquipmentForContract(SpecialEquipment)
            : vsmResult.existingEquipment.SpecialEquipment;

        return {
            vehicleSelectionPluginResult: vsmResult,
            equipmentPluginResult: vehicleSelectionPluginResult.existingEquipment
        };
    }
});

const formValuesValuationUpdated = sample({
    clock: evaluationEffects.getVehicleEvaluationFx.doneData,
    source: valuationFormSubmitted,
    filter: (sourceData, clockData) => sourceData && !!clockData?.Valuation,
    fn: sourceData => ({ formikValuationValues: sourceData })
});

const formValuesMailUpdated = sample({
    clock: mailFormSubmitted,
    fn: values => ({ formikMailValues: values })
});

const equipmentUpdated = sample({
    clock: equipmentMoved,
    source: { equipmentPluginResult }
});

const formBuilderFieldUpdated = merge([
    formBuilderFieldBlurred.map(e => ({ formBuilderField: e.target })),
    formBuilderFieldChanged.map(formBuilderField => ({ formBuilderField }))
]);

/* Create contract */
const formBuilderFieldUpdatedWithValue = guard({
    source: formBuilderFieldUpdated,
    filter: ({ formBuilderField }) => !!formBuilderField.value
});

// !!!should pass some arg mb that will stand for was it used from inside address-book or not
const contractShouldBeCreated = guard({
    clock: [vehicleSelectionUpdated, formBuilderFieldUpdatedWithValue],
    filter: isContractCreated.map(is => !is)
});

sample({
    source: contractShouldBeCreated,
    fn: createPayloadForCreateOrUpdateContract,
    target: createContractFx
});

/* Get contract when it's created or updated not via `updateCurrentContract` */
guardPath({
    path: ROUTES.claim.root,
    basename: urlPath,
    source: createContractFx.doneData,
    target: getContractFx
});

guardPath({
    path: ROUTES.claim.root,
    basename: urlPath,
    clock: [saveFTClaim.done, changeContractStatusFx.done],
    source: contractId,
    target: getContractFx
});

// Reload contract on status change in order to reload the form and re-evaluate form conditions (options changed)
forward({
    from: changeContractStatusFx.done.map(({ params: { contractId } }) => contractId),
    to: getContractFx
});

/* Update contract */
const contractShouldBeUpdated = guard({
    clock: [
        vehicleSelectionUpdated,
        equipmentUpdated,
        formBuilderFieldUpdated,
        formValuesMailUpdated,
        formValuesValuationUpdated
    ],
    filter: isContractCreated
});

const formBuilderFieldUpdatedData = restore(contractShouldBeUpdated, null).map(data =>
    data !== null && 'formBuilderField' in data ? data.formBuilderField : null
);

sample({
    clock: contractShouldBeUpdated,
    source: labourRatesFactorsParameters,
    fn: (labourRatesFactorsParameters, clockData) =>
        createPayloadForCreateOrUpdateContract({ labourRatesFactorsParameters, ...clockData }),
    target: updateCurrentContractFx
});

// Calculate contract after equipmentUpdated
sample({
    clock: updateCurrentContractFx.doneData,
    source: [repairPositions, formBuilderFieldUpdatedData],
    filter: ([repairPositions, formBuilderFieldUpdatedData]) =>
        Array.isArray(repairPositions) &&
        !!repairPositions.length &&
        formBuilderFieldUpdatedData?.formName === 'calculationForm',
    fn: _.noop,
    target: contractEffects.calculateCurrentContractFx
});

/* Save grapa result */
//TODO: make `createSingleQueueEffect` function
const saveGrapaResultFx = createEffect((grapaPluginResult: ResultGrapaDossier) =>
    updateCurrentContractFx(createPayloadForCreateOrUpdateContract({ grapaPluginResult }))
);
const resetLastQueuedGrapaResult = createEvent();
const lastQueuedGrapaResult = createStore<ResultGrapaDossier | null>(null).reset(resetLastQueuedGrapaResult);

split({
    source: grapaRepairPositionsChanged,
    match: {
        save: saveGrapaResultFx.pending.map(pending => !pending),
        queue: saveGrapaResultFx.pending
    },
    cases: {
        save: saveGrapaResultFx,
        queue: lastQueuedGrapaResult
    }
});

guard({
    clock: saveGrapaResultFx.finally,
    source: lastQueuedGrapaResult,
    filter: (lastQueuedGrapaResult): lastQueuedGrapaResult is ResultGrapaDossier => !!lastQueuedGrapaResult,
    target: [saveGrapaResultFx, resetLastQueuedGrapaResult]
});

/* Calculate contract */
const isRepairPositionsChangedByUser = createStore(false).on(
    merge([saveFTClaim.done, saveGrapaResultFx.done]),
    () => true
);

const shouldCalculateContract = guard({
    clock: sharedRepairCalculationStores.repairPositions.updates,
    source: { isCalculationAvailable, isRepairPositionsChangedByUser },
    filter: ({ isCalculationAvailable, isRepairPositionsChangedByUser }) =>
        isCalculationAvailable && isRepairPositionsChangedByUser
});

sample({
    clock: shouldCalculateContract,
    fn: _.noop,
    target: calculateCurrentContractFx
});

isRepairPositionsChangedByUser.reset(calculateCurrentContractFx.done);
