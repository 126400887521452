import { getBaseTokens } from '../getBaseTokens';
import { DefaultTheme } from 'styled-components';

const getTokens = (locale: string): DefaultTheme => ({
    name: 'dark',
    colors: {
        primary: getBaseTokens(locale).colors.blue['400'],
        secondary: getBaseTokens(locale).colors.yellow[10],
        mark: getBaseTokens(locale).colors.red[10],
        bgMain: getBaseTokens(locale).colors.black,
        bgColor: getBaseTokens(locale).colors.gray_10,
        textSecondary: getBaseTokens(locale).colors.gray_100,

        bgHeader: getBaseTokens(locale).colors.deepBlue['800'],
        textPrimary: getBaseTokens(locale).colors.white,
        textDisabled: getBaseTokens(locale).colors.dustBlue['800'],
        textActive: getBaseTokens(locale).colors.yellow['400'],
        textHeader: getBaseTokens(locale).colors.gray['500'],

        ...getBaseTokens(locale).colors
    },
    typography: getBaseTokens(locale).typography
});

export { getTokens };
