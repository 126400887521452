import React from 'react';
import { NumberInputField, NumberInputFieldProps } from '@wedat/ui-kit/Formik';

interface Props extends NumberInputFieldProps {
    index: number;
    workLevelSymbol?: string;
    stopPropagation?: boolean;
    disableOnChange?: boolean;
    hideValue?: boolean;
}

export const PositionNumberInputField: React.FC<Props> = ({ workLevelSymbol, name, index, ...rest }) => {
    const prefix = workLevelSymbol && `${workLevelSymbol}`;

    return <NumberInputField {...rest} prefix={prefix} name={`positions.${index}.${name}`} />;
};
