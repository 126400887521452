import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const MainContainer = styled.div`
    position: relative;
`;

export const Text = styled.div`
    display: inline-block;
    user-select: none;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
`;

export const ReportDamageButton = styled(Button)`
    width: 100%;

    margin-top: 15px;

    ${media.phoneSmall`
        margin-top: 25px;
    `}
`;

export const RotateComponent = styled.div`
    transform: rotate(90deg);
`;
