import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { radioItems } from '../../constants/radioItems';
import { selectsOptions } from '../../constants/selectsOptions';
import { paintTypesStores } from '../../stores/selects/paintTypes';

import { CheckboxFieldContainer, Field, FieldsContainer, RadioGroupContainer, TitleWrapper } from '../Layout/styles';
import { SelectField, RadioGroupField, InputField, CheckboxField } from '@wedat/ui-kit/Formik';

import { FactorsParametersObject } from '../../types/factors';
import { InputWithSelect } from '../InputWithSelect';
import { Text } from '@wedat/ui-kit/components/Text';

export const EuroLacquerFactors: React.FC = () => {
    const { t } = useTranslation();
    const {
        values: {
            EuroLacquerFactor: {
                addition,
                wageMode,
                materialMode,
                wageInclMaterialMode,
                isApplyUndercoatLacquerWhenRemoved,
                isLacquerAssemblyWhenRemoved
            }
        },
        setFieldValue,
        initialValues
    } = useFormikContext<FactorsParametersObject>();
    const paintTypesSelectOptions = useStore(paintTypesStores.selectOptionsEuro);

    /* "addition" affects "exemplarySheets"  */
    // TODO: после открытия labour rates, компонент реднерится несколько раз и в addition появляется boolean значение
    const isExemplarySheetsDisabled = !(addition || []).includes('B');

    const onUserChangeAddition = (selectedValues: string[]) => {
        const shouldSetInitialExemplarySheets = selectedValues.includes('B');
        const shouldClearExemplarySheets = !selectedValues.includes('B');

        if (shouldSetInitialExemplarySheets) {
            setFieldValue('EuroLacquerFactor.exemplarySheets', initialValues.EuroLacquerFactor.exemplarySheets);
        }
        if (shouldClearExemplarySheets) {
            setFieldValue('EuroLacquerFactor.exemplarySheets', '');
        }
    };

    const percentOrAbsolute = radioItems.percentOrAbsolute(t);
    const perTimeOrAbsolute = radioItems.perTimeOrAbsolute(t);
    const materialModeValues = radioItems.materialMode(t);

    return (
        <>
            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="EuroLacquerFactor.type"
                        options={paintTypesSelectOptions}
                        label={t('paint.type')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
                <Field>
                    <SelectField
                        changeCallbackMulti={onUserChangeAddition}
                        name="EuroLacquerFactor.addition"
                        options={selectsOptions.addition}
                        isMulti={true}
                        label={t('paint.addition')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
                <Field>
                    <InputField name="EuroLacquerFactor.colorName" label={t('paint.colorName')} />
                </Field>
                <Field>
                    <InputField name="EuroLacquerFactor.colorCode" label={t('paint.colorCode')} />
                </Field>
                <Field>
                    <InputField name="EuroLacquerFactor.color2ndName" label={`${t('paint.color2ndName')} [2]`} />
                </Field>
                <Field>
                    <InputField name="EuroLacquerFactor.color2ndCode" label={`${t('paint.color2ndCode')} [2]`} />
                </Field>
                <Field>
                    <InputField
                        disabled={isExemplarySheetsDisabled}
                        name="EuroLacquerFactor.exemplarySheets"
                        label={t('paint.exemplarySheets')}
                    />
                </Field>
            </FieldsContainer>

            <RadioGroupContainer>
                <RadioGroupField items={perTimeOrAbsolute} name="EuroLacquerFactor.wageMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.wage"
                        disabled={wageMode !== 'PER_TIME'}
                        label={t('paint.wage')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.wageFlatRate"
                        disabled={wageMode !== 'ABSOLUTE'}
                        label={t('paint.wageFlatRate')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'EuroLacquerFactor.discountWage',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'EuroLacquerFactor._attributes.mode.discountWage',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>

            <TitleWrapper>
                <Text font="defaultBold">{t('paint.paintMaterialSubtitle')}</Text>
            </TitleWrapper>

            <RadioGroupContainer>
                <RadioGroupField items={materialModeValues} name="EuroLacquerFactor.materialMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.materialPerPointCost"
                        disabled={materialMode !== 'LACQUER_METHOD'}
                        label={t('paint.euroLacquer.repairWageLacquerMaterialPerPoint')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.materialIndex"
                        disabled={materialMode !== 'LACQUER_METHOD'}
                        label={`${t('paint.euroLacquer.materialIndex')}*`}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.materialFlatRatePercent"
                        disabled={materialMode !== 'PERCENT'}
                        label={t('paint.euroLacquer.materialFlatRatePercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.materialFlatRateAbsolute"
                        disabled={materialMode !== 'ABSOLUTE'}
                        label={`${t('paint.euroLacquer.materialFlatRateAbsolute')}*`}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.materialSpecialLacquer"
                        label={t('paint.euroLacquer.materialSpecialLacquer')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'EuroLacquerFactor.discountMaterial',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'EuroLacquerFactor._attributes.mode.discountMaterial',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>

            <TitleWrapper>
                <Text font="defaultBold">{t('paint.labourCostsForPaintworkSubtitle')}</Text>
            </TitleWrapper>

            <RadioGroupContainer>
                <RadioGroupField items={perTimeOrAbsolute} name="EuroLacquerFactor.wageInclMaterialMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="EuroLacquerFactor.isReducedPreparationTime"
                        label={t('paint.euroLacquer.isReducedPreparationTime')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="EuroLacquerFactor.isFrameworkUse"
                        label={t('paint.euroLacquer.isFrameworkUse')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="EuroLacquerFactor.isSurchargeForSecondColor"
                        label={t('paint.euroLacquer.isSurchargeForSecondColor')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="EuroLacquerFactor.isLacquerPlasticWhenFitted"
                        label={t('paint.euroLacquer.isLacquerPlasticWhenFitted')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={isLacquerAssemblyWhenRemoved}
                        name="EuroLacquerFactor.isApplyUndercoatLacquerWhenRemoved"
                        label={t('paint.euroLacquer.isApplyUndercoatLacquerWhenRemoved')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={isApplyUndercoatLacquerWhenRemoved}
                        name="EuroLacquerFactor.isLacquerAssemblyWhenRemoved"
                        label={t('paint.euroLacquer.isLacquerAssemblyWhenRemoved')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>

            <FieldsContainer marginTop>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.wageInclMaterial"
                        disabled={wageInclMaterialMode !== 'PER_TIME'}
                        label={t('paint.wageInclMaterial')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.wageInclMaterialFlatRate"
                        disabled={wageInclMaterialMode !== 'ABSOLUTE'}
                        label={t('paint.wageInclMaterialFlatRate')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'EuroLacquerFactor.discountWageInclMaterial',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'EuroLacquerFactor._attributes.mode.discountWageInclMaterial',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>

                <Field>
                    <InputField
                        name="EuroLacquerFactor.preparationTimePercent"
                        label={t('paint.euroLacquer.preparationTimePercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.preparationTimePlasticPercent"
                        label={t('paint.euroLacquer.preparationTimePlasticPercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="EuroLacquerFactor.disposalCostPercent"
                        label={t('paint.euroLacquer.disposalCostPercent')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.maskingWorkGlassCount}
                        name="EuroLacquerFactor.maskingWorkGlassCount"
                        label={t('paint.euroLacquer.maskingWorkGlassCount')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.maskingWorkPlasticCount}
                        name="EuroLacquerFactor.maskingWorkPlasticCount"
                        label={t('paint.euroLacquer.maskingWorkPlasticCount')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.matBlackWindowFrameCount}
                        name="EuroLacquerFactor.matBlackWindowFrameCount"
                        label={t('paint.euroLacquer.matBlackWindowFrameCount')}
                    />
                </Field>
            </FieldsContainer>
        </>
    );
};
