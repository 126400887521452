import { getParsedArraySafe } from '@dat/api2/utils';
import { excludeEquipmentWithoutIds } from './excludeEquipmentWithoutIds';
import { ParsedEquipment, TransferEquipment } from '../types/equipment';

interface AdditionalParams {
    isChildren?: boolean;
}

export const createTransferEquipment = (
    equipment: ParsedEquipment,
    additionalParams?: AdditionalParams
): TransferEquipment =>
    equipment.map(({ ContainedEquipmentPositions, ...obj }) => {
        const key = obj.DatEquipmentId + (additionalParams?.isChildren ? '-child' : '');
        const description = obj.Description || '';
        const isChild = additionalParams?.isChildren;
        let children: TransferEquipment | undefined;

        if (ContainedEquipmentPositions?.EquipmentPosition) {
            const containedEquipment = getParsedArraySafe(ContainedEquipmentPositions?.EquipmentPosition);
            const validContainedEquipment = excludeEquipmentWithoutIds(containedEquipment);

            children = createTransferEquipment(validContainedEquipment, { isChildren: true });
        }

        return {
            ...obj,
            key,
            description,
            children,
            isChild
        };
    });
