import { FC, useRef, useState, useEffect } from 'react';
import { ImageResult } from '../../../types/ai-gallery';
import { ParsedAttachmentItem } from '../../../types/attachments';
import { Layer } from '../Layer';
import { SlideItemStyled, Image } from './styles';
interface Props {
    attachment: ParsedAttachmentItem;
    list: ImageResult[];
}

export const SlideItem: FC<Props> = ({ attachment, list }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const [scaleLayer, setScaleLayer] = useState(1);

    useEffect(() => {
        fitStageIntoParentContainer();
    }, [containerRef.current?.offsetWidth]);

    const fitStageIntoParentContainer = () => {
        if (containerRef.current && imageRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            const imageWidth = imageRef.current.naturalWidth;
            const scale = containerWidth / imageWidth;

            setScaleLayer(scale);
        }
    };

    return (
        <SlideItemStyled ref={containerRef}>
            <Image
                src={attachment.base64}
                alt={attachment.fileName}
                ref={imageRef}
                onLoad={fitStageIntoParentContainer}
            />
            <Layer scale={scaleLayer} list={list} currentFileName={attachment.fileName} />
        </SlideItemStyled>
    );
};
