import React from 'react';
import { useFormikContext } from 'formik';

import { RadioGroupField, RadioGroupFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationRadioGroupField: React.FC<RadioGroupFieldProps> = props => {
    const { submitForm } = useFormikContext();

    return <RadioGroupField {...props} changeCallback={submitForm} />;
};
