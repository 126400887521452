export const PART_DISCOUNT_TYPE_OPTIONS = [
    {
        label: 'S',
        value: 'S',
        key: 'S'
    },
    {
        label: 'D',
        value: 'D',
        key: 'D'
    },
    {
        label: 'M',
        value: 'M',
        key: 'M'
    },
    {
        label: 'C',
        value: 'C',
        key: 'C'
    }
];
