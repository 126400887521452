import styled from 'styled-components/macro';

export const Layout = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px;
`;

export const Container = styled.div`
    width: fit-content;
    padding-bottom: 100px;
`;
