import styled from 'styled-components';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import { getTablePadding } from './utils';
import { MobileTableHeading } from '../Layout/styles';

interface Props {
    isSelected?: boolean;
    isLabour?: boolean;
    isPaintWork?: boolean;
    isExpanded?: boolean;
}

interface ArrowIconProps {
    isRowExpanded: boolean;
}

export const TableContainer = styled.div<Props>`
    overflow-x: auto;
    ${makeCustomScrollBar()};

    table {
        width: 100%;
        border: 1px solid #f1f2f4;
        font-family: 'Noto Sans TC', sans-serif;
        color: #465064;
        border-collapse: collapse;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }

            ${media.phone`
                margin-bottom: 20px;
                border-radius: 2px;
            `}
        }

        th {
            color: #c9cfdc;
            font-weight: 500;
            font-size: 15px;
            white-space: nowrap;
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #f1f2f4;
            border-right: 1px solid #f1f2f4;

            :last-child {
                border-right: 0;
            }
        }

        td:nth-child(3) {
            white-space: nowrap;

            ${media.phone`
                white-space: unset;
            `}
        }

        ${media.phone`
            border: none;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: ${({ isLabour, isPaintWork }) => `${getTablePadding(isLabour, isPaintWork)}px`};

            table, th, tr, td {
                display: block;
                border: none;
            }
        `}
    }
`;

// TODO: [color]
export const TableRow = styled.tr<Props>`
    background-color: ${({ isSelected }) => (isSelected ? '#e3f1ff' : '#fff')};
    color: ${({ isSelected, theme }) => (isSelected ? theme.colors.blueDark : '#465064')};

    ${media.phone`
        background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.blueDark : '#fff')};
        color: ${({ isSelected }) => (isSelected ? '#fff' : '#465064')};
        position: relative;
        padding: 15px;
        padding-bottom: ${({ isExpanded }) => (isExpanded ? '50px' : '25px')};

        // TODO: [color]
        ${MobileTableHeading}:nth-child(odd) {
            color: ${({ isSelected }) => (isSelected ? 'rgba(255, 255, 255, 0.5);' : '#C9CFDC')}
        }
    `}
`;

export const TableHead = styled.thead`
    ${media.phone`
        display: none;
    `}
`;

export const ArrowIconContainer = styled.td<ArrowIconProps>`
    display: inline-block;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 16px);

    &&& {
        padding: 0;
    }

    & svg {
        width: 16px;
        height: 16px;
        transform: ${({ isRowExpanded }) => (isRowExpanded ? 'rotate(90deg)' : 'rotate(-90deg)')};
    }
`;

export const MobileTd = styled.td`
    &&& {
        padding: 0;
        &.second {
            position: absolute;
            right: 15px;
            top: 15px;
        }
        &.third {
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }
`;
