import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-hjson';
// import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-textmate';

import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';

export const PropsEditor2: FC = () => {
    const { pluginOptionsHJSONText, isModified } = useStore(pluginOptionsModel.stores.pluginOptionsEditor);

    return (
        <div>
            <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                <Space style={{ float: 'left' }}>
                    <Button
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                            // rightSideDrawerModel.events.backView();
                            rightSideDrawerModel.events.clearHistoryAndClose();
                        }}
                    />
                </Space>
                <Space style={{ float: 'right' }}>
                    <Button
                        size="large"
                        disabled={!isModified}
                        onClick={() => {
                            pluginOptionsModel.events.applyTextOptions();
                        }}
                    >
                        Apply
                    </Button>
                </Space>
            </div>

            <AceEditor
                highlightActiveLine
                showGutter
                fontSize={14}
                height="calc(100%-50px)"
                mode="hjson"
                name="blah2"
                placeholder=""
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2
                }}
                showPrintMargin={false}
                style={{
                    position: 'absolute',
                    top: '50px',
                    right: 0,
                    bottom: 0,
                    left: 0
                }}
                theme="github"
                value={pluginOptionsHJSONText}
                width="100%"
                onChange={pluginOptionsModel.events.updateOptionText}
            />
        </div>
    );
};
