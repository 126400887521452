import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';

import { ValuateFinance } from '@dat/valuate-finance';

import { Container } from './styles';
import { getTextValuesFromParsedObject } from '@dat/api2/utils';
import { contractEffects } from '../../../stores/contract';
import { valuateStores } from '../../../stores/valuate';
import { vehicleImagesStores } from '../../../stores/images';

export const ValuationPage = () => {
    const requestDataValuate = useStore(valuateStores.requestDataValuate);
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);

    const [image, setImage] = useState<string | undefined>();

    // vehicleImages is updated from getContact because of this, an infinite loop occurs in Valuate
    useEffect(() => {
        if (!image) {
            setImage(vehicleImages?.[0]?.imageBase64);
        }
    }, [image, vehicleImages]);

    const handleComplete = useCallback((data: DAT2.VXS) => {
        // condition for typescript SingleOrArray<Dossier>
        if (!Array.isArray(data?.Dossier)) {
            const { PurchasePrice, SalesPrice, ...rest } = getTextValuesFromParsedObject(data.Dossier?.Valuation || {});

            const result = {
                valuationResult: rest,
                totalSalesPrice: SalesPrice,
                totalPurchasePrice: PurchasePrice,
                appraisalEquipments: data?.Dossier?.Vehicle?.Equipment
            };

            contractEffects.saveMemoFieldsValuateFx(result);
        }
    }, []);

    const options = useMemo(
        () => ({
            onComplete: handleComplete,
            isComponent: true,
            requestData: {
                ...requestDataValuate
            },
            image
        }),
        [handleComplete, requestDataValuate, image]
    );

    return (
        <Container>
            <ValuateFinance options={options} />
        </Container>
    );
};
