import { getMemberUsername } from './getMemberUsername';
interface Param {
    dialog: DAT2.Plugins.Chat.Dialog;
    username: string;
    sameCustomerProfiles: BFF.Customer.Response.GetProfiles;
}

export const getCompanionUserAvatar = ({ dialog, username, sameCustomerProfiles }: Param) => {
    const companionUsername =
        getMemberUsername(dialog.members.find(member => getMemberUsername(member) !== username)) || '';

    const companionUserAvatar =
        companionUsername && sameCustomerProfiles[companionUsername] && sameCustomerProfiles[companionUsername].image
            ? sameCustomerProfiles[companionUsername].image
            : '';

    return companionUserAvatar;
};
