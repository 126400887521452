import { I18nNamespace } from '../types/i18n';

// NOTE: the list of locales is used in config-overrides-common.js (in case of extended , this should be done in two places)
export const LOCALES_LANGUAGE_NAMES: Record<DAT2.Locale, string> = {
    'en-US': 'English',
    'el-GR': 'Ελληνικά',
    'fr-FR': 'Français',
    'it-IT': 'Italiano',
    'ru-RU': 'Русский',
    'pl-PL': 'Polski',
    'zh-CN': 'Chinese',
    'cs-CZ': 'Czech',
    'de-DE': 'Deutsch',
    'es-ES': 'Spanish',
    'ko-KR': 'Korean',
    'nl-NL': 'Nederlandse',
    'de-AT': 'Österreichisch',
    'de-CH': 'Schweizerisch'
};

export const LOCALES = Object.keys(LOCALES_LANGUAGE_NAMES) as DAT2.Locale[];

export const I18N_NAMESPACES: Record<I18nNamespace, I18nNamespace> = {
    'ui-kit': 'ui-kit',
    'address-book': 'address-book',
    'damage-selector': 'damage-selector',
    'ai-claim': 'ai-claim',
    'ai-gallery': 'ai-gallery',
    'assign-partner': 'assign-partner',
    auth: 'auth',
    calculation: 'calculation',
    'claim-management': 'claim-management',
    chat: 'chat',
    equipment: 'equipment',
    'fast-track': 'fast-track',
    'form-builder': 'form-builder',
    gallery: 'gallery',
    grapa: 'grapa',
    inbox: 'inbox',
    'italian-calculation': 'italian-calculation',
    'labour-rates': 'labour-rates',
    'labour-rates-generic': 'labour-rates-generic',
    'plugin-cars': 'plugin-cars',
    printout: 'printout',
    'qr-code-plugin': 'qr-code-plugin',
    'residual-prediction': 'residual-prediction',
    'valuate-finance': 'valuate-finance',
    'vehicle-selection': 'vehicle-selection',
    scheduler: 'scheduler',
    'part-selection': 'part-selection',
    'claim-stepper': 'claim-stepper',
    mail: 'mail',
    'help-file': 'help-file',
    'all-components': 'all-components'
};
