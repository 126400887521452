import Jimp from 'jimp';
import { Tensor } from 'onnxruntime-web';

export async function getImageTensorFromPath(path: string) {
    // 1. load the image
    const image = await Jimp.read(path);
    const height = image.getHeight();
    const width = image.getWidth();
    const dims = [3, height, width];
    // 2. convert to tensor
    const imageTensor = imageDataToTensor(image, dims);

    // 3. return the tensor
    return [imageTensor, height, width];
}

function imageDataToTensor(image: Jimp, dims: number[]) {
    // 1. Get buffer data from image and create R, G, and B arrays.
    const imageBufferData = image.bitmap.data;
    const [redArray, greenArray, blueArray]: number[][] = [[], [], []];
    // 2. Loop through the image buffer and extract the R, G, and B channels
    for (let i = 0; i < imageBufferData.length; i += 4) {
        redArray.push(imageBufferData[i]);
        greenArray.push(imageBufferData[i + 1]);
        blueArray.push(imageBufferData[i + 2]);
        // skip data[i + 3] to filter out the alpha channel
    }

    // 3. Concatenate RGB to transpose [H, W, 3] -> [3, H, W] to a number array
    const transposedData = redArray.concat(greenArray).concat(blueArray);

    // 4. convert to float32
    let i,
        l = transposedData.length; // length, we need this for the loop
    // create the Float32Array size 3 * H * W for these dimensions output
    const uint8Data = new Uint8Array(dims[0] * dims[1] * dims[2]);
    for (i = 0; i < l; i++) {
        uint8Data[i] = transposedData[i]; // / 255.0; // convert to float
    }
    // 5. create the tensor object from onnxruntime-web.
    return new Tensor('uint8', uint8Data, dims);
}
