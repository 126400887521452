import { AttachmentGroup, GroupedAttachments, RemainingUploadsOfGroups } from '../../types/attachments';

interface Param {
    groupedAttachments: GroupedAttachments;
    groups: AttachmentGroup[];
}

export const getRemainingUploadsOfGroups = ({ groupedAttachments, groups }: Param): RemainingUploadsOfGroups => {
    const result: RemainingUploadsOfGroups = {};

    groups.forEach(({ id, max }) => {
        if (max) {
            const groupAttachments = groupedAttachments[id];

            result[id] = max - groupAttachments.length;
        } else {
            result[id] = Infinity;
        }
    });

    return result;
};
