import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { importIniEffects } from '../../stores/importIni';
import { pluginStores } from '../../stores/plugin';
import { ImportIniContainer, ImportIniText } from './styles';

export const ImportIni: React.FC = () => {
    const { t } = useTranslation();
    const templateField = useStore(pluginStores.pluginOptions)?.settings?.importIni;

    return (
        <ImportIniContainer>
            <label>
                <ImportIniText>{t('importIni.title')}</ImportIniText>
                <input
                    hidden
                    type="file"
                    accept=".txt,.ini"
                    onChange={event => importIniEffects.handleFileInputChangeFx({ event, templateField })}
                />
            </label>
        </ImportIniContainer>
    );
};
