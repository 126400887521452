import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { InputProps } from './types';
import { Label } from '../Label';
import { getInputSize } from '../../utils/getInputSize';

export const Wrapper = styled.div<Pick<InputProps, 'inputSize'> & { width: string | number | undefined }>`
    display: flex;
    ${({ width }) => width && `width: ${width}px;`}
    flex-direction: column;
    position: relative;
    min-width: 0;
    height: ${({ inputSize }) => getInputSize(inputSize)};
`;

export const ErrorStyled = styled.div`
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.red[900]};
    margin-left: 12px;
`;

export const LabelStyled = styled(Label)``;

export const InputStyled = styled(InputMask)<
    Pick<Required<InputProps>, 'status' | 'withIcon'> &
        Pick<InputProps, 'inputSize' | 'borderRadius' | 'uppercase' | 'disabled'>
>`
    width: 100%;
    height: ${({ inputSize }) => getInputSize(inputSize)};
    padding-left: 12px;
    padding-right: ${({ withIcon }) => (withIcon ? '40px' : '12px')};
    ${({ theme }) => theme.typography.note};
    color: ${({ theme, disabled }) => (disabled ? 'inherit' : theme.colors.dustBlue['900'])};
    box-sizing: border-box;
    background: none;
    margin: none;
    border: none;
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')};
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
    outline: none;
    z-index: ${({ disabled }) => (disabled ? 1 : 0)};

    &::placeholder {
        color: ${({ theme }) => theme.colors.textSecondary};
    }

    &:-webkit-autofill + ${LabelStyled} {
        transform: translate(7px, -9px) scale(0.75);
    }
`;

export const IconStyled = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
`;

export const ClickableIcon = styled.div`
    cursor: pointer;
`;

export const InputWrapperStyled = styled.div<Pick<InputProps, 'inputSize' | 'borderRadius'>>`
    display: inline-flex;
    position: relative;
    user-select: none;
    height: ${({ inputSize }) => getInputSize(inputSize)};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')};
`;

export const InputPrefix = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
`;
