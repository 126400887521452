import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div<{ selected: boolean }>`
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    background-color: ${({ theme, selected }) => (selected ? theme.colors.gray['50'] : theme.colors.white)};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 24px;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
`;

export const TitlesContainer = styled(Text)`
    display: flex;
    flex-direction: column;
    margin-right: 12px;
`;

export const Title = styled(Text)``;

export const Role = styled(Text)`
    color: ${({ theme }) => theme.colors.blue['200']};
`;
