import { getParsedArraySafe } from '@dat/api2/utils';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';

export const extractAIResultFromContract = (contract: DAT2.ContractFromGetContract) => {
    const fields = getParsedArraySafe(contract?.complexTemplateData?.field);
    const AIResultField = fields.find(field => field.name === CONTRACT_ENTRIES_KEYS.MEMO.AIResult);
    const aiResultJSON = AIResultField?.stringValue;

    if (aiResultJSON) {
        try {
            return JSON.parse(String(aiResultJSON));
        } catch {
            return undefined;
        }
    }
};
