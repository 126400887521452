import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Link } from 'react-router-dom';
import { Tooltip } from '@wedat/ui-kit';

interface HeaderProps {
    fixed?: boolean;
}

interface ListProps {
    isOpenMenu: boolean;
    noHideContent?: boolean;
}

export const StyledHeader = styled.header<HeaderProps>`
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    z-index: 1000; // full screen of Grappa and modal windows must overlap header (grapa's max z-index: 1020)
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;
    transition: box-shadow 300ms ease-in-out 0s;

    background-color: ${({ theme }) => theme.colors.bgHeader};
    color: ${({ theme }) => theme.colors.textPrimary};

    ${media.laptop`
       height: 58px;
    `}

    ${media.laptop`
       box-shadow: none;
    `}

    ${({ fixed }) =>
        fixed &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        `}
`;

export const LogoWrapper = styled.div`
    display: flex;
    cursor: pointer;
`;

export const Container = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    ${media.phoneBig`
        padding-left: 12px;
        padding-right: 12px;
    `}
`;

export const Text = styled.div`
    margin-left: 15px;
    font-weight: 500;
    font-size: 18px;
`;

export const TextBold = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

export const RightPartWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonToggle = styled.button`
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.gray_300};
    display: none;
    cursor: pointer;

    ${media.tabletSmall`
        display: block;
    `}
`;

export const ButtonToggleStyled = styled(ButtonToggle)`
    margin-left: 15px;

    ${media.tablet`
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;

export const List = styled.ul<ListProps>`
    display: flex;
    align-items: baseline;
    list-style: none;
    margin-bottom: 0;
    z-index: 10;

    ${({ noHideContent }) =>
        !noHideContent &&
        media.tabletSmall`
        position: fixed;
        top: 90px;
        left: 2%;
        width: 96%;
        flex-direction: column;
        align-items: flex-start;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 5px;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s;

        ${p =>
            p.isOpenMenu &&
            css`
                transform: scaleY(1);
            `}
    `}
`;

export const ListStyled = styled(List)`
    z-index: 1;
    width: auto;
    align-items: center;

    ${media.tabletSmall`
        padding: 0;
    `}

    ${({ isOpenMenu, noHideContent }) =>
        !noHideContent &&
        media.tablet`
                position: fixed;
                top: 80px;
                left: 0;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                background-color: ${({ theme }) => theme.colors.white};
                transform: scaleY(0);
                transform-origin: top;
                transition: transform 0.3s;

                ${
                    isOpenMenu &&
                    css`
                        transform: scaleY(1);
                    `
                }
        `}
`;

export const ListLeft = styled(ListStyled)<{ homeIcon?: boolean }>`
    margin-left: ${({ homeIcon }) => (homeIcon ? '32px' : '44px')};
    padding-left: 0;
    height: 100%;
`;

export const ChildItem = styled.li`
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;

    ${media.tablet`
        width: 100%;
    `}

    &:empty {
        display: none;
    }
`;

export const ReloadButton = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 3px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textPrimary};
    transition: color 150ms;

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const LeftPartWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const HomeLink = styled(Link)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: ${theme.colors.textPrimary};
        border-radius: 8px;
        transition: color 150ms;
        margin-left: 46px;

        &:hover {
            color: ${theme.colors.blue['500']};
        }

        ${media.laptop`
            margin-left: 20px;
        `}
    `}
`;

export const SearchButtonWrapper = styled.div`
    width: 48px;
    height: 48px;
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.gray_10};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;

export const SearchButton = styled.div<{ image: string }>`
    ${({ image }) =>
        css`
            width: 18px;
            height: 18px;
            background: url(${image}) no-repeat center;
            opacity: 0.8;
        `}
`;

export const TextWrapper = styled.div`
    display: flex;

    ${media.phoneMedium`
        display: none;
    `}
`;

export const AdditionalButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 26px;
`;

export const StyledTooltip = styled(Tooltip)`
    height: 48px;
`;
