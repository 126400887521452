import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';
import { Card, DiscountDisplaySwap, NumberFormatStyled } from '../core/styles';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

interface AnimatedTableWrapperProps {
    show?: boolean;
}

interface IconWrapperProps {
    show?: boolean;
}

interface AnimatedIconProps {
    show: boolean;
}

interface AnimatedCardProps extends AnimatedIconProps {}

interface FlexWrapperProps {
    isLast?: boolean;
}

interface TotalHeaderRowProps {
    scrollLimit?: boolean;
}

export const TotalHeaderRow = styled.div<TotalHeaderRowProps>`
    display: grid;
    width: 100%;
    padding: 8px 16px;
    position: fixed;
    top: ${({ scrollLimit }) => (scrollLimit ? '0' : '99px')};
    left: 0;
    grid-template-columns: repeat(5, 20%);
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;
    z-index: 1;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // 56 - total row height
    height: 56px;
    background-color: ${({ theme }) => theme.colors.white};
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 8px 56px rgba(174, 174, 192, 0.6), 0px -8px 56px ${({ theme }) => theme.colors.white};
    z-index: 11;
`;

export const AnimatedTableWrapper = styled.div<AnimatedTableWrapperProps>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    bottom: 0;
    transition: transform 1s;
    transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100%)')};
    z-index: 13;
    ${({ show, theme }) =>
        show && `box-shadow: 0px 8px 56px rgba(174, 174, 192, 0.6), 0px -8px 56px ${theme.colors.white};`}
`;

export const DataWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 24px 12px 36px;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 12;
`;

export const DataWrapperStyled = styled(DataWrapper)`
    padding-right: 24px;
`;

export const DataRightPart = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 20%);
    width: 80%;
    justify-content: space-between;
    padding-right: 10px;
`;

export const FlexWrapper = styled.div<FlexWrapperProps>`
    display: flex;
    ${({ isLast }) => isLast && 'justify-self: end;'}
`;

export const TextStyled = styled(Text)`
    margin-right: 8px;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
    width: 24px;
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 20px;
    cursor: pointer;
    ${({ show }) => (show ? 'opacity: 1;' : 'opacity: 0; pointer-events: none; cursor: unset;')}
`;

export const IconWrapperFlipped = styled(IconWrapper)`
    transform: rotate(180deg);
`;

export const DumbComponent = styled.div`
    width: 24px;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 20px;
`;

export const Column = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const TextWidthStyled = styled(Text)`
    width: 220px;
`;

export const FlexWrapperMobile = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const IconWrapperMobile = styled.div<AnimatedIconProps>`
    transition: transform 1s;
    transform: ${({ show }) => (show ? 'rotate(0)' : 'rotate(180deg)')};
    cursor: pointer;
`;

export const InputWrapperMobile = styled.div`
    position: relative;
`;

export const DiscountDisplaySwapMobile = styled(DiscountDisplaySwap)`
    left: 86px;
`;

export const FlexDescriptionWrapperMobile = styled(FlexWrapperMobile)`
    margin-bottom: 22px;
`;

export const ColumnMobile = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);
`;

export const FlexWrapperTitleMobile = styled(FlexWrapperMobile)`
    padding-top: 12px;
    padding-bottom: 12px;
`;

export const AnimatedCard = styled(Card)<AnimatedCardProps>`
    max-height: ${({ show }) => (show ? '1000px' : '0')};
    padding-top: 0;
    ${({ show }) => !show && 'padding-bottom: 0;'}
    transition: max-height 1s;
    overflow: hidden;
`;

export const ContainerMobile = styled.div`
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    border-radius: 16px;

    ${makeCustomScrollBar()}
`;

export const NumberFormatSmall = styled(NumberFormatStyled)`
    height: 20px;
    padding-left: 0;
`;
