// DATProcessIdCommentView

import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input } from 'antd';
import { DATProcessIdCommentListModel } from '../../../stores/DATProcessIdCommentListModel';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';

export function DATProcessIdCommentView() {
    const { t } = useTranslation();

    const DATProcessIdCommentList = useStore(DATProcessIdCommentListModel.store.DATProcessIdCommentList);
    const { currentDVNs } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const dvn = currentDVNs?.length === 1 ? currentDVNs[0] : NaN;
    const comment = DATProcessIdCommentList.find(item => item.DATProcessId === dvn);

    // const [showComment, setShowComment] = useState<boolean>(!!comment);

    if (!dvn) return null;

    return (
        <div style={{ margin: '8px 0px 0px 0px' }}>
            <Row gutter={[8, 0]}>
                {/* <Col span={4} style={{ textAlign: 'right', maxWidth: '100%' }}>
                    <Tooltip title={t('ManualRepairPositionForm.Comment')}>
                        <Button
                            size={'large'}
                            onClick={() => {
                                setShowComment(!showComment);
                            }}
                        >
                            <IconPartComment color={'#000000D9'} />
                        </Button>
                    </Tooltip>
                </Col> */}
                {/* {(true || showComment) && ( */}
                <Col span={24}>
                    <Input.TextArea
                        size="large"
                        placeholder={t('ManualRepairPositionForm.Comment')}
                        onChange={e => {
                            const value = e.target.value;
                            value
                                ? DATProcessIdCommentListModel.event.addOrUpdateDATProcessIdComment({
                                      DATProcessId: dvn,
                                      Comment: value
                                  })
                                : DATProcessIdCommentListModel.event.delDATProcessIdComment({
                                      DATProcessId: dvn
                                  });
                        }}
                        allowClear={true}
                        value={comment?.Comment || ''}
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                </Col>
                {/* )} */}
            </Row>
        </div>
    );
}
