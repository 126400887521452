import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { radioItems } from '../../constants/radioItems';
import { paintTypesStores } from '../../stores/selects/paintTypes';

import { SelectField, RadioGroupField, InputField, CheckboxField } from '@wedat/ui-kit/Formik';

import { Field, FieldsContainer, RadioGroupContainer } from '../Layout/styles';

import { FactorsParametersObject } from '../../types/factors';
import { InputWithSelect } from '../InputWithSelect';

export const ManufacturerLacquerFactors: React.FC = () => {
    const { t } = useTranslation();
    const { values } = useFormikContext<FactorsParametersObject>();
    const paintTypesSelectOptions = useStore(paintTypesStores.selectOptionsManufacturer);

    const percentOrAbsolute = radioItems.percentOrAbsolute(t);
    const perTimeOrAbsolute = radioItems.perTimeOrAbsolute(t);
    const materialModeValues = radioItems.materialMode(t);

    return (
        <>
            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="ManufacturerLacquerFactor.type"
                        options={paintTypesSelectOptions}
                        label={t('paint.type')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
                <Field>
                    <InputField name="ManufacturerLacquerFactor.colorName" label={t('paint.colorName')} />
                </Field>
                <Field>
                    <InputField name="ManufacturerLacquerFactor.colorCode" label={t('paint.colorCode')} />
                </Field>
                <Field>
                    <InputField name="ManufacturerLacquerFactor.color2ndName" label={`${t('paint.colorName')} [2]`} />
                </Field>
                <Field>
                    <InputField name="ManufacturerLacquerFactor.color2ndCode" label={`${t('paint.colorCode')} [2]`} />
                </Field>
            </FieldsContainer>

            <RadioGroupContainer>
                <RadioGroupField items={perTimeOrAbsolute} name="ManufacturerLacquerFactor.wageMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop marginBottom>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.wage"
                        disabled={values.ManufacturerLacquerFactor.wageMode !== 'PER_TIME'}
                        label={t('paint.wage')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.wageFlatRate"
                        disabled={values.ManufacturerLacquerFactor.wageMode !== 'ABSOLUTE'}
                        label={t('paint.wageFlatRate')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountWage',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountWage',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountWageBiw',
                            label: t('common.discountBiw')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountWageBiw',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>

            <RadioGroupContainer>
                <RadioGroupField items={materialModeValues} name="ManufacturerLacquerFactor.materialMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop marginBottom>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.materialFlatRatePercent"
                        disabled={values.ManufacturerLacquerFactor.materialMode !== 'PERCENT'}
                        label={t('paint.manufacturerLacquer.materialFlatRatePercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.materialFlatRateAbsolute"
                        disabled={values.ManufacturerLacquerFactor.materialMode !== 'ABSOLUTE'}
                        label={t('paint.manufacturerLacquer.materialFlatRateAbsolute')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountMaterial',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountMaterial',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountMaterialBiw',
                            label: t('common.discountBiw')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountMaterialBiw',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>

            <RadioGroupContainer>
                <RadioGroupField items={perTimeOrAbsolute} name="ManufacturerLacquerFactor.wageInclMaterialMode" />
            </RadioGroupContainer>

            <FieldsContainer marginTop marginBottom>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.wageInclMaterial"
                        disabled={values.ManufacturerLacquerFactor.wageInclMaterialMode !== 'PER_TIME'}
                        label={t('paint.wageInclMaterial')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.wageInclMaterialFlatRate"
                        disabled={values.ManufacturerLacquerFactor.wageInclMaterialMode !== 'ABSOLUTE'}
                        label={t('paint.wageInclMaterialFlatRate')}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountWageInclMaterial',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountWageInclMaterial',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'ManufacturerLacquerFactor.discountWageInclMaterialBiw',
                            label: t('common.discountBiw')
                        }}
                        selectProps={{
                            name: 'ManufacturerLacquerFactor._attributes.mode.discountWageInclMaterialBiw',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputField
                        name="ManufacturerLacquerFactor.preparationTimePercent"
                        label={t('paint.manufacturerLacquer.preparationTimePercent')}
                    />
                </Field>
            </FieldsContainer>

            <CheckboxField
                name="ManufacturerLacquerFactor.isLacquerAssemblyWhenRemoved"
                label={t('paint.manufacturerLacquer.isLacquerAssemblyWhenRemoved')}
            />
        </>
    );
};
