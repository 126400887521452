import React, { FC } from 'react';
import { useStore } from 'effector-react';

import './svg.css';

import { useTranslation } from 'react-i18next';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';

const { SvgLoader, SvgProxy } = require('@wedat/react-svgmt');
// import { SvgLoader, SvgProxy } from '@wedat/react-svgmt';

export interface ZoneGraphicPreviewParamsType {
    repairPositions: RepairPosition[];
    currentZoneDATID: string | null;
    width?: number;
    height?: number;
    graphicSVG?: string;
    onElementClick: (id: string) => void;
}

function onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
}

function pad(num: number, size: number) {
    var s = '000000000' + num;
    return s.substr(s.length - size);
}

export const ZoneGraphicPreview: FC<ZoneGraphicPreviewParamsType> = ({
    repairPositions,
    currentZoneDATID,
    width,
    height,
    graphicSVG,
    onElementClick
}) => {
    const { t } = useTranslation();

    const zusatzData = useStore(zoneGraphicsModel.stores.zusatzData);
    const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);

    let listPresentZone: string[] | undefined = [];
    if (repairPositions?.length) {
        const repairPositionDVNList = repairPositions.map(rp => rp.DATProcessId).filter(onlyUnique);

        const listPresentGroupId = availableAssemblyGroupsStore.availableAssemblyGroups
            .filter(gr =>
                gr.objectsInfo?.find(
                    oi =>
                        (oi.dvnLeft?.dvn && repairPositionDVNList.includes(oi.dvnLeft?.dvn)) ||
                        (oi.dvnRight?.dvn && repairPositionDVNList.includes(oi.dvnRight?.dvn))
                )
            )
            .map(gr => gr.assemblyGroup.assemblyGroupId);

        const listPresentConstructiongroup = zusatzData?.constructiongroup.filter(gr =>
            listPresentGroupId?.includes(gr.constructiongroupid)
        );
        listPresentZone = listPresentConstructiongroup
            ?.reduce(
                (lastVal: number[], curVal) => lastVal.concat(curVal.zones?.zone?.map(z => z.id) || []),
                [] as number[]
            )
            .map(id => pad(id, 3));
    }

    if (!graphicSVG || !width || !height)
        return (
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                    margin: '10em 0em 0em 0em',
                    display: 'inline-block'
                }}
            >
                {/* <Spin spinning size="large">
                </Spin> */}
                {t('zoneGraphics')}
            </div>
        );

    const onElementClickId = (e: any) => {
        onElementClick(e.target.parentElement.parentElement.id);
    };

    return (
        // <div style={{ width: '100%', textAlign: 'center', margin: '-16px 0px 0px 0px' }}>
        <svg height={height} width={width}>
            <SvgLoader
                // className="genericGraphic"
                // height={height}
                // width={width}
                // height={2500}
                // width={2500}
                svgXML={graphicSVG}
                viewBox={'0 0 2500 2500'}
                className={'zoneGraphics1047'}
            >
                <SvgProxy
                    selector='[id^="DATID_"] path'
                    // selector=':not([id^="DATID_"])'

                    onClick={onElementClickId}
                />

                <SvgProxy
                    // key={selectElementId}
                    class={''}
                    // selector={`[id^="DATID"] [id$="${currentZoneDATID?.slice(-3)}"] path`}
                    selector={`[id^="DATID_"] > g > path`}
                    // selector=':not([id^="DATID_"])'
                    // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                />

                <SvgProxy
                    // key={selectElementId}
                    class={'selectedGroupZone1077'}
                    // selector={`[id^="DATID"] [id$="${currentZoneDATID?.slice(-3)}"] path`}
                    selector={`[id$="${currentZoneDATID?.slice(-3)}"] > g > path`}
                    // selector=':not([id^="DATID_"])'
                    // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                />

                {listPresentZone?.map(zoneId => (
                    <SvgProxy
                        key={zoneId}
                        class={
                            zoneId === currentZoneDATID?.slice(-3)
                                ? 'selectedGroupZone1077withPosition'
                                : 'withPositionsZone'
                        }
                        selector={`[id$="${zoneId}"] > g > path`}
                    />
                ))}
            </SvgLoader>
        </svg>
        // </div>
    );
};
