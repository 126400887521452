import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from '../Languages';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';

import { Title } from './styles';

interface Props {
    isOpen: boolean;
    closeSlideUpMenu: () => void;
}

export const LanguageSlideUp: FC<Props> = ({ isOpen, closeSlideUpMenu }) => {
    const { t } = useTranslation();

    return (
        <SlideUpMenu isOpen={isOpen} closeSlideUpMenu={closeSlideUpMenu}>
            <Title>{t('auth:profile.selectLanguage')}</Title>
            <Languages close={closeSlideUpMenu} />
        </SlideUpMenu>
    );
};
