import { useStore } from 'effector-react';

import { pluginStores } from '../../../stores/plugin';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OptionText, WrenchStyled } from '../styles';
import { useTranslation } from 'react-i18next';

import { CustomSelectProps } from '../index';
import { CustomSelectOption } from '../types';

export const useOptions = (props: CustomSelectProps) => {
    let { options } = props;

    options = useOptionsWithIds({ ...props, options });
    options = useRepairIncompleteOptions({ ...props, options }); // ! converts option's label into ReactNode !

    return options;
};

const useOptionsWithIds = (props: CustomSelectProps): CustomSelectOption[] => {
    const { DATECodeDisplayedOnModel } = useStore(pluginStores.settings);
    const { options, idLength } = props;

    if (DATECodeDisplayedOnModel && idLength) {
        return options.map(({ key, value, ...rest }) => {
            const currValue = `${String(key).padStart(idLength, '0')} ${value}`;
            return {
                ...rest,
                value: currValue,
                key,
                label: currValue
            };
        });
    }

    return options;
};
const useRepairIncompleteOptions = ({ options }: CustomSelectProps): CustomSelectOption[] => {
    const { t } = useTranslation();
    return options.map(({ key, value, repairIncomplete, ...rest }) => {
        const label = (
            <OptionText title={repairIncomplete && t('alternativeModelInfoText')}>
                {repairIncomplete === 'true' && <WrenchStyled />}
                {value}
            </OptionText>
        );

        return {
            ...rest,
            value,
            key,
            label
        };
    });
};
