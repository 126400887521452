import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isCardParametersModalOpen, toggleIsCardParametersModalOpen] = createToggle(false);
const [isFiltersModalOpen, toggleIsFiltersModalOpen] = createToggle(false);
const [isAddFilterModalOpen, toggleIsAddFilterModalOpen] = createToggle(false);
const [isNewOrderModalOpen, toggleIsNewOrderModalOpen] = createToggle(false);
const [isSearchModalOpen, toggleIsSearchModalOpen] = createToggle(false);
const [isStatusModalOpen, toggleIsStatusModalOpen] = createToggle(false);
const [isAiClaimModalOpen, toggleIsAiClaimModalOpen] = createToggle(false);

export const modalsEvents = {
    toggleIsCardParametersModalOpen,
    toggleIsFiltersModalOpen,
    toggleIsAddFilterModalOpen,
    toggleIsNewOrderModalOpen,
    toggleIsSearchModalOpen,
    toggleIsStatusModalOpen,
    toggleIsAiClaimModalOpen
};
export const modalsStores = {
    isCardParametersModalOpen,
    isFiltersModalOpen,
    isAddFilterModalOpen,
    isNewOrderModalOpen,
    isSearchModalOpen,
    isStatusModalOpen,
    isAiClaimModalOpen
};
