import { forward } from 'effector';

import { pluginEvents } from './index';
import { contractsListEffects } from '../contractsList';
import { modalsEvents } from '../ui/modals';

const { initPlugin, pluginUnmounted } = pluginEvents;
const { loadUserSettingsFx } = contractsListEffects;
const { toggleIsNewOrderModalOpen } = modalsEvents;

forward({
    from: initPlugin,
    to: [loadUserSettingsFx]
});

pluginUnmounted.watch(() => {
    toggleIsNewOrderModalOpen(false);
});
