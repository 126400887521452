// faultString can contain "additionalInformation" element
// <additionalInformation>...</additionalInformation>
// Finding first XML bracket to exclude all text after it
export const matchedBracket = (message: string) => message.match(/<|&lt;/);

export const getErrorInformation = (message: string) => {
    const matchBracket = matchedBracket(message);

    return {
        message: matchBracket ? message.slice(0, matchBracket.index).trim() : message,
        errorBlock: matchBracket && message.slice(matchBracket?.index).replace(/&lt;/gi, '<').replace(/&gt;/gi, '>')
    };
};
