import * as R from 'ramda';

import { attachmentsStores } from '../attachments';
import { pluginCarsStores } from '../pluginCars';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { ClientConfiguration } from '../../types/plugin';

const configuration = sharedTemplateStores.productsConfiguration.map<ClientConfiguration | undefined>(
    productsConfiguration => productsConfiguration?.['gallery']
);

attachmentsStores.groups.on(
    configuration,
    (_, configuration) => configuration?.groups && R.uniqBy(({ id }) => id, configuration.groups)
);

pluginCarsStores.steps.on(configuration, (_, configuration) => configuration?.photoCapture?.steps);

pluginCarsStores.configForPluginCars.on(configuration, (_, configuration) => ({
    additionalPhotoForceLandscape: configuration?.additionalPhotoForceLandscape,
    withSummary: configuration?.photoCapture?.withSummary
}));
