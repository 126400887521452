import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Button } from '@wedat/ui-kit/components/Button';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px 44px 44px;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
`;

export const VehicleNameWrapper = styled.div`
    margin-top: 12px;
`;

export const Right = styled.div`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 20px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    max-height: 220px;
    display: flex;
    justify-content: center;

    ${media.laptop`
        max-height: 160px;
    `}
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

export const PriceContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: fit-content;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 8px;
`;

export const Label = styled.label`
    height: 17px;
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
`;

export const VatRate = styled.div`
    margin-left: 20px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    font-weight: 400;
    font-size: 18px;

    ${media.laptop`
        margin-left: 0;
        margin-top: 12px;
    `}
`;

export const Field = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
`;

export const LeftInfo = styled.div`
    display: flex;

    ${media.laptop`
        flex-direction: column;
    `}
`;

export const ButtonWrapper = styled.div`
    ${media.phone`
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px;
        background-color: ${({ theme }) => theme.colors.white};
        border-top: 2px solid ${({ theme }) => theme.colors.gray_40};
        z-index: 1000;    
    `}
`;

export const StyledButton = styled(Button)`
    padding: 0 20px;
`;
