import { getParsedArraySafe } from '@dat/api2/utils';
import { PaintWorkItemsWithDiscount } from '../../constants/positions';
import { WorkLogicFormValues } from '../../types/worklogicForm';

export const getTotalSumsItemsForUpdate = (
    totalSumsItems: DAT2.TotalSummaryItemItaly[]
): DAT2.ProcedureRelatedParameter[] => {
    const totalSumsUpdated: DAT2.ProcedureRelatedParameter[] = getParsedArraySafe(
        totalSumsItems.map(item => {
            if (item.Identifier === 'work' && item.DiscountDisplayType === 'percent') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discount',
                    factor: 'LabourCostFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'work') {
                return {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discount',
                    factor: 'LabourCostFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'total' && item.DiscountDisplayType === 'percent') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discount',
                    factor: 'CalculationFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'total') {
                return {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discount',
                    factor: 'CalculationFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'spareParts' && item.DiscountDisplayType === 'percent') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discount',
                    factor: 'SparePartFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'spareParts') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discount',
                    factor: 'SparePartFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                };
            }

            if (item.Identifier === 'repairBench' && item.DiscountDisplayType === 'percent') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discountTotalAmountWrokbench',
                    factor: 'DomusCalculationFactor',
                    mode: 'PER_HOUR',
                    type: 'Price'
                };
            }

            if (item.Identifier === 'repairBench') {
                return {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discountTotalAmountWrokbench',
                    factor: 'DomusCalculationFactor',
                    mode: 'PER_HOUR',
                    type: 'Price'
                };
            }

            if (item.Identifier === 'wasteDisposal' && item.DiscountDisplayType === 'percent') {
                return {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discountTotalAmountDisposal',
                    factor: 'DomusCalculationFactor',
                    mode: 'PER_HOUR',
                    type: 'Price'
                };
            }

            if (item.Identifier === 'wasteDisposal') {
                return {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discountTotalAmountDisposal',
                    factor: 'DomusCalculationFactor',
                    mode: 'PER_HOUR',
                    type: 'Price'
                };
            }

            return null;
        })
    );

    totalSumsItems.forEach(item => {
        if (item.Identifier === 'lacquerMaterial' && item.DiscountDisplayType === 'percent') {
            const arr: DAT2.ProcedureRelatedParameter[] = [
                {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discountMaterial',
                    factor: 'EuroLacquerFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                },
                {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discountMaterial',
                    factor: 'ManufacturerLacquerFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                },
                {
                    _text: item.ValueNetDiscountPerc || '',
                    attribute: 'discountMaterial',
                    factor: 'AztLacquerFactor',
                    mode: 'PERCENT',
                    type: 'Discount'
                }
            ];

            totalSumsUpdated.push(...arr);
            return;
        }

        if (item.Identifier === 'lacquerMaterial') {
            const arr: DAT2.ProcedureRelatedParameter[] = [
                {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discountMaterial',
                    factor: 'EuroLacquerFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                },
                {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discountMaterial',
                    factor: 'ManufacturerLacquerFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                },
                {
                    _text: item.ValueNetDiscountValue || '',
                    attribute: 'discountMaterial',
                    factor: 'AztLacquerFactor',
                    mode: 'ABSOLUTE',
                    type: 'Discount'
                }
            ];

            totalSumsUpdated.push(...arr);
            return;
        }
    });

    return totalSumsUpdated;
};

export const addPercentValueForLabourItems = (
    labourItems: DAT2.WorkSummaryItemItaly[],
    procedureRelatedParameter: DAT2.ProcedureRelatedParameter[]
) =>
    labourItems.map(item => {
        if (item.Identifier === 'body') {
            const newItem = { ...item };
            const discountPerc = procedureRelatedParameter?.find(item => {
                const condition =
                    item.attribute === 'discountTotalAmountBody' &&
                    item.factor === 'DomusCalculationFactor' &&
                    item.mode === 'PER_HOUR' &&
                    item.type === 'Price';

                if (condition) {
                    return item;
                }

                return null;
            })?._text;

            newItem.DiscountPerc = Number(discountPerc) || 0;

            return newItem;
        }

        if (item.Identifier === 'lacquer') {
            const newItem = { ...item };
            const discountPerc = procedureRelatedParameter?.find(item => {
                const condition =
                    item.attribute === 'discountWage' &&
                    item.factor === 'EuroLacquerFactor' &&
                    item.mode === 'PERCENT' &&
                    item.type === 'Discount';

                if (condition) {
                    return item;
                }

                return null;
            })?._text;

            newItem.DiscountPerc = Number(discountPerc) || 0;

            return newItem;
        }

        if (item.Identifier === 'mechanics') {
            const newItem = { ...item };
            const discountPerc = procedureRelatedParameter?.find(item => {
                const condition =
                    item.attribute === 'discountTotalAmountMechanic' &&
                    item.factor === 'DomusCalculationFactor' &&
                    item.mode === 'PER_HOUR' &&
                    item.type === 'Price';

                if (condition) {
                    return item;
                }

                return null;
            })?._text;

            newItem.DiscountPerc = Number(discountPerc) || 0;

            return newItem;
        }

        return item;
    });

export const getLabourItemsForUpdate = (labourItems: DAT2.WorkSummaryItemItaly[]): DAT2.ProcedureRelatedParameter[] => {
    // TODO: any
    const arr: any = [];

    labourItems.forEach(item => {
        if (item.Identifier === 'body') {
            arr.push(
                ...[
                    // value
                    {
                        _text: item.WagePerHour || 0,
                        attribute: 'bodyWage1',
                        factor: 'LabourCostFactor',
                        mode: 'PER_HOUR',
                        type: 'Price'
                    },
                    // discount
                    {
                        _text: item.DiscountPerc || 0,
                        attribute: 'discountTotalAmountBody',
                        factor: 'DomusCalculationFactor',
                        mode: 'PER_HOUR',
                        type: 'Price'
                    }
                ]
            );
        }

        if (item.Identifier === 'lacquer') {
            const items: DAT2.ProcedureRelatedParameter[] = [
                // values
                {
                    _text: item.WagePerHour || 0,
                    attribute: 'wage',
                    factor: 'EuroLacquerFactor',
                    mode: 'PER_HOUR',
                    type: 'Price'
                },
                {
                    _text: item.WagePerHour || 0,
                    attribute: 'wage',
                    mode: 'PER_HOUR',
                    type: 'Price',
                    factor: 'ManufacturerLacquerFactor'
                },
                {
                    _text: item.WagePerHour || 0,
                    attribute: 'wage',
                    mode: 'PER_HOUR',
                    type: 'Price',
                    factor: 'AztLacquerFactor'
                },
                // discounts
                {
                    _text: item.DiscountPerc || 0,
                    type: 'Discount',
                    mode: 'PERCENT',
                    attribute: 'discountWage',
                    factor: 'EuroLacquerFactor'
                },
                {
                    _text: item.DiscountPerc || 0,
                    type: 'Discount',
                    mode: 'PERCENT',
                    attribute: 'discountWage',
                    factor: 'ManufacturerLacquerFactor'
                },
                {
                    _text: item.DiscountPerc || 0,
                    type: 'Discount',
                    mode: 'PERCENT',
                    attribute: 'discountWage',
                    factor: 'AztLacquerFactor'
                }
            ];
            arr.push(...items);
        }

        if (item.Identifier === 'mechanics') {
            const items = [
                // values
                {
                    attribute: 'mechanicWage1',
                    factor: 'LabourCostFactor',
                    type: 'Price',
                    mode: 'PER_HOUR',
                    _text: item.WagePerHour
                },
                {
                    attribute: 'mechanicWage2',
                    factor: 'LabourCostFactor',
                    type: 'Price',
                    mode: 'PER_HOUR',
                    _text: item.WagePerHour
                },
                {
                    attribute: 'mechanicWage3',
                    factor: 'LabourCostFactor',
                    type: 'Price',
                    mode: 'PER_HOUR',
                    _text: item.WagePerHour
                },
                // discount
                {
                    attribute: 'discountTotalAmountMechanic',
                    factor: 'DomusCalculationFactor',
                    mode: 'PER_HOUR',
                    type: 'Price',
                    _text: item.DiscountPerc
                }
            ];
            arr.push(...items);
        }
    });

    return getParsedArraySafe(arr);
};

export const getPaintWorkItemsForUpdate = (
    paintworkItems: DAT2.LacquerSummaryItemItaly[]
): DAT2.ProcedureRelatedParameter[] => {
    const arr: DAT2.ProcedureRelatedParameter[] = [];

    paintworkItems.forEach(item => {
        if (item.Identifier === PaintWorkItemsWithDiscount.allLayers) {
            arr.push({
                _text: item.Percentage || '',
                attribute: 'extraChargeAllLayersPercent',
                factor: 'DomusCalculationFactor',
                type: 'Double'
            });
        }

        if (item.Identifier === PaintWorkItemsWithDiscount.multiLayer) {
            arr.push({
                _text: item.Percentage || '',
                attribute: 'extraChargeOnlyMultiLayerPercent',
                factor: 'DomusCalculationFactor',
                type: 'Double'
            });
        }
    });

    return arr;
};

export const findRepairPositions = (position: DAT2.PositionItaly, repairPositions: DAT2.RepairPosition[]) =>
    repairPositions.filter(repairPos => repairPos.DATProcessId === position.DATProcessId);

export const getWorkLogicForUpdate = (
    workLogicFormValues: WorkLogicFormValues
): { parameters: DAT2.ProcedureRelatedParameter[]; repairPosition: DAT2.RepairPosition } => {
    const arr: DAT2.ProcedureRelatedParameter[] = [];
    const surchargeRepairPosition: DAT2.RepairPosition = {
        DATProcessId: 0,
        RepairType: 'incidental costs',
        Description: 'calculateItalia.lbl.adjacentPaint.surcharge',
        PositionEntryType: 'italiaPostCalc',
        IsRepairExtension: false,
        SparePartNumber: '99991',
        SparePartAmount: 1.0,
        SparePartNumberOrigin: 'MANUAL',
        WorkType: 'other',
        DentsWithFinishing: false,
        DentsWithSetupTime: false,
        DentsWithAddLightMetals: false,
        DentsOutOfReach: false,
        IsAdditionalLM: false,
        PreDamage: false,
        AlloyLM: false,
        LargeScale: false,
        AdhesiveTechnologyScale: false,
        AdditionLM: false,
        WorkCompleted: false,
        ContainMicroDents: false,
        AdhesiveMethod: false,
        WorkIndication: 0
    };

    Object.keys(workLogicFormValues).forEach(item => {
        if (item === 'surcharge') {
            if (workLogicFormValues.surcharge === 'complete') {
                surchargeRepairPosition.SparePartAmount = 1.0;
            }
            if (workLogicFormValues.surcharge === 'partial') {
                surchargeRepairPosition.SparePartAmount = 2.0;
            }
        } else {
            arr.push({
                factor: 'DomusCalculationFactor',
                type: 'Boolean',
                attribute: item,
                _text: workLogicFormValues[item as keyof WorkLogicFormValues]
            });
        }
    });

    return { parameters: getParsedArraySafe(arr), repairPosition: surchargeRepairPosition };
};
