import styled from 'styled-components';

export interface RadioGroupStylesProps {
    column?: boolean;
}

export const RadioGroupStyled = styled.div<RadioGroupStylesProps>`
    display: flex;
    flex-wrap: wrap;
    ${({ column }) => column && 'flex-direction: column;'}
`;

export const RadioStyled = styled.div<RadioGroupStylesProps>`
    &:not(:last-child) {
        margin-right: ${({ column }) => (column ? 0 : '10px')};
        margin-bottom: ${({ column }) => (column ? '10px' : 0)};
    }
`;
