import { getParsedArraySafe } from '@dat/api2/utils';
import { TableFooterTypes } from '../types/tableFooter';
import { MobileTableHeading } from '../components/Layout/styles';
import { TFunction } from 'react-i18next';
import { NumberFormatWrapper } from '../components/NumberFormatWrapper';

export const createDataForSpareParts = (t: TFunction, dossier: DAT2.Dossier | null, isMobile: boolean) => {
    const materialCalculationResults =
        dossier?.RepairCalculation?.CalcResultCommon?.MaterialPositions?.MaterialPosition;

    return getParsedArraySafe(materialCalculationResults).map(
        ({ Amount, DATPartNumber, DATProcessId, Description, ValuePerUnit, ValueTotal }) =>
            isMobile
                ? {
                      col1: DATProcessId,
                      col2: DATPartNumber,
                      col3: Description,
                      children: [
                          {
                              col4: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('sparePartsTable.headers.type')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{t('default')}</MobileTableHeading>
                                  </>
                              ),
                              col5: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('sparePartsTable.headers.discount')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>-</MobileTableHeading>
                                  </>
                              ),
                              col6: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('sparePartsTable.headers.quantity')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>{Amount}</MobileTableHeading>
                                  </>
                              ),
                              col7: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('sparePartsTable.headers.unitPrice')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={ValuePerUnit} />
                                      </MobileTableHeading>
                                  </>
                              ),
                              col8: (
                                  <>
                                      <MobileTableHeading isTitle={true}>
                                          {t('sparePartsTable.headers.price')}:
                                      </MobileTableHeading>
                                      <MobileTableHeading>
                                          <NumberFormatWrapper value={ValueTotal} />
                                      </MobileTableHeading>
                                  </>
                              )
                          }
                      ]
                  }
                : {
                      col1: DATProcessId,
                      col2: DATPartNumber,
                      col3: Description,
                      col4: null,
                      col5: '-',
                      col6: Amount,
                      col7: <NumberFormatWrapper value={ValuePerUnit} />,
                      col8: <NumberFormatWrapper value={ValueTotal} />
                  }
    );
};

export const createDataForSparePartsTableFooter = (t: TFunction, dossier: DAT2.Dossier | null): TableFooterTypes => {
    const currency = dossier?.Currency;

    const totalSparePartsCosts = dossier?.RepairCalculation?.CalculationSummary?.SparePartsCosts?.TotalSum;

    const currencyValue = currency ?? '';

    return {
        items: [
            {
                price: <NumberFormatWrapper value={totalSparePartsCosts}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.sparePartsTotal'),
                styles: {
                    fontWeight: 500,
                    fontSize: '17px'
                }
            },
            {
                price: <NumberFormatWrapper value={totalSparePartsCosts}>{currencyValue}</NumberFormatWrapper>,
                label: t('dataForCards.sparePartsSubtotal')
            },
            {
                price: '-',
                label: t('dataForCards.smallParts[PERCENT_OF_PARTS]')
            }
        ]
    };
};
