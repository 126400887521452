import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Button } from '@wedat/ui-kit/components/Button';

type Props = {
    isInAside?: boolean;
};

export const AppointmentWrapper = styled.div<Props>`
    width: 100%;
    height: ${({ isInAside }) => (isInAside ? '131px' : '100%')};

    background: ${({ theme }) => theme.colors.white};
    position: relative;

    padding-top: ${({ isInAside }) => (isInAside ? '12px' : '16px')};
    padding-button: ${({ isInAside }) => (isInAside ? '12px' : '16px')};
    margin-bottom: ${({ isInAside }) => isInAside && '10px'};

    border: ${({ isInAside, theme }) => (isInAside ? `1px solid ${theme.colors.dustBlue[100]}` : 'none')};
    border-radius: 16px;

    font-size: calc(100% - 2px); // subtract 2px from default(14px)
    line-height: 18px;

    ${media.desktopSmall`
        width: 100%;
        height: ${({ isInAside }) => !isInAside && '120px'};
        margin-left: ${({ isInAside }) => !isInAside && '0px'};
    `}
    ${media.phoneBig`
        height: ${({ isInAside }) => !isInAside && '175px'};
    `};
`;

export const AppointmentContent = styled.div<Props>`
    width: 100%;
    height: 120px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${media.desktopSmall`
        height: ${({ isInAside }) => !isInAside && '50px'};
        flex-direction: ${({ isInAside }) => !isInAside && 'row'};
        align-items: ${({ isInAside }) => !isInAside && 'center'};
        justify-content: ${({ isInAside }) => !isInAside && 'space-between'}
    `}

    ${media.phoneBig`
        height: 125px;
        flex-direction: column;
        justify-content: flex-start;
    `}
`;

export const Header = styled.h4<Props>`
    margin: 0;

    ${({ theme }) => theme.typography.mobileHeader};
    color: ${({ theme }) => theme.colors.dustBlue[900]};

    ${media.laptopSmall`
        display: ${({ isInAside }) => isInAside && 'none'};
    `}
`;

export const ViewAllContainer = styled.div`
    cursor: pointer;

    color: ${({ theme }) => theme.colors.blue[700]};
`;

export const Divider = styled.div<Props>`
    width: 1px;
    height: 46px;

    background: ${({ theme }) => theme.colors.dustBlue[100]};
    display: none;

    ${media.desktopSmall`
        display: ${({ isInAside }) => !isInAside && 'block'};
    `}
    ${media.phoneBig`
        display: none;
    `}
`;

export const UpperPart = styled.div<Props>`
    display: ${({ isInAside }) => (isInAside ? 'none' : 'flex')};
    align-items: center;
    justify-content: space-between;

    margin-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
`;

export const UserInfoWrapper = styled.div<Props>`
    width: 100%;
    height: 42px;

    display: flex;
    align-item: center;
    justify-content: space-between;

    padding-left: 16px;
    padding-right: 16px;

    ${media.desktopSmall`
        width: ${({ isInAside }) => !isInAside && 'auto'};
    `}

    ${media.phoneBig`
        width: 100%
    `}
`;

export const Info = styled.div<Props>`
    display: flex;
    align-items: flex-start;
`;

export const UserInfo = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 9px;
    margin-top: -5px;
`;

export const UserImg = styled.div`
    width: 28px;
    height: 28px;
    min-width: 28px;

    border-radius: 50%;

    & img:first-child {
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-radius: 50%;
    }
`;

export const RegistrationNumber = styled.div`
    background: ${({ theme }) => theme.colors.gray[100]};

    padding: 2px 4px;
    border-radius: 4px;
`;

export const AppointmentId = styled.div`
    margin-left: 5px;

    color: ${({ theme }) => theme.colors.dustBlue[600]};
`;

export const FlexibleContent = styled.div`
    display: flex;
    align-items: center;
    font-size: calc(100% - 2px);
`;

export const Location = styled.div<Props>`
    width: ${({ isInAside }) => (isInAside ? '35%' : '50%')};

    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${media.desktopSmall`
        flex-direction: ${({ isInAside }) => !isInAside && 'row-reverse'};
    `}
    ${media.phoneBig`
        width: 50%;
        flex-direction: ${({ isInAside }) => !isInAside && 'row'};
        justify-content: flex-end;
    `}
`;

export const Address = styled.div`
    color: ${({ theme }) => theme.colors.dustBlue[600]};
    margin-left: 13px;
    margin-right: 13px;
`;

export const MediaContent = styled.div<Props>`
    width: 100%;
    height: ${({ isInAside }) => (isInAside ? '37x' : '40px')};

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 16px;
    padding-right: 16px;

    ${media.desktopSmall`
        width: ${({ isInAside }) => !isInAside && '63%'};
    `}

    ${media.phoneBig`
        width: 100%;
    `}
`;

export const DividerHorizontal = styled.div<Props>`
    width: 100%;
    height: 1px;

    background: ${({ theme }) => theme.colors.dustBlue['100']};

    margin-top: 12px;
    margin-bottom: 12px;

    ${media.desktopSmall`
        display: ${({ isInAside }) => !isInAside && 'none'};
    `}

    ${media.phoneBig`
        display: block;
    `}
`;

export const SelectWrapper = styled.div`
    width: 112px;

    & div {
        border: none;
    }

    & label {
        background: ${({ theme }) => theme.colors.white};
        margin-top: -3px;
    }
`;

export const SelectButtonWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 5px;
`;

export const SelectButton = styled(Button)`
    width: 80%;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue[400]};
    ${({ theme }) => theme.typography.note}
    color: ${({ theme }) => theme.colors.dustBlue[900]};
`;

export const ComponentWrapper = styled.div`
    width: 160px;
    background: ${({ theme }) => theme.colors.white};
`;

export const ArrowWrapper = styled.div<Props>`
    margin-right: 2px;
    cursor: pointer;

    ${media.desktopSmall`
        display: ${({ isInAside }) => !isInAside && 'none'};
    `}
    ${media.phoneBig`
        display: ${({ isInAside }) => !isInAside && 'block'};
    `}
`;

export const PositionWrapper = styled.div<Props>`
    display: none;
    position: absolute;
    right: 25px;
    bottom: 31px;
    cursor: pointer;

    ${media.desktopSmall`
        display: ${({ isInAside }) => !isInAside && 'block'};
    `}

    ${media.phoneBig`
        display: ${({ isInAside }) => !isInAside && 'none'};
    `}
`;

export const IconWrapper = styled.div`
    margin-top: 5px;
    margin-right: 10px;
`;

export const NoAppointmentWrapper = styled.div<Props>`
    width: 100%;
    height: ${({ isInAside }) => (isInAside ? '100%' : '60%')};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;

    ${media.desktopSmall`
        height: ${({ isInAside }) => (isInAside ? '100%' : '60%')};
        justify-content: flex-start;
    `};
`;

export const TextWrapper = styled.div<Props>`
    width: 60%;
    margin-left: 24px;

    ${media.desktopSmall`
        margin-left: 24px
    `}
`;

export const UserNoAvatar = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.dustBlue[900]};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;
