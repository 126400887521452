import * as R from 'ramda';

import { sharedDossierStores } from '../index';
import { filterArrayOfObjectsByExistingKeys } from '@dat/core/utils';
import { getParsedArraySafe } from '@dat/api2/utils';

const repairCalculation = sharedDossierStores.dossier.map(dossier => dossier?.RepairCalculation);

const repairPositions = repairCalculation.map<DAT2.RepairPosition[]>((repairCalculation, prevPositions) => {
    const positions = getParsedArraySafe(repairCalculation?.RepairPositions?.RepairPosition);
    const validPositions = filterArrayOfObjectsByExistingKeys(positions, ['DATProcessId']);

    //TODO: create util that checks deep equality between new and old results, and if equal, returns old one, so the store isn't updated (keepIfEquals). const repairPositions = repairCalculation.map(keepIfEquals<Type>(reducer))
    if (prevPositions && R.equals(prevPositions, validPositions)) return prevPositions;

    return validPositions;
});

const procedureRelatedParameters = repairCalculation.map(repairCalculation =>
    getParsedArraySafe(repairCalculation?.ProcedureRelatedParameters?.ProcedureRelatedParameter)
);

const materialPositions = repairCalculation.map(repairCalculation =>
    getParsedArraySafe(repairCalculation?.CalcResultCustomerRanking?.MaterialPositions?.MaterialPosition)
);

const calculationSummary = repairCalculation.map(repairCalculation => repairCalculation?.CalculationSummary);

export const sharedRepairCalculationStores = {
    repairCalculation,
    repairPositions,
    procedureRelatedParameters,
    materialPositions,
    calculationSummary
};
