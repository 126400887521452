import { attach, createEffect, createEvent, createStore } from 'effector';

const createInitialHtmlSnapshot = createEvent();
const initialHtmlSnapshot = createStore('').on(createInitialHtmlSnapshot, () => document.documentElement.innerHTML);
const resetHtmlToInitialFx = attach({
    source: initialHtmlSnapshot,
    effect: createEffect((initialHtmlSnapshot: string) => {
        document.documentElement.innerHTML = initialHtmlSnapshot;
    })
});

export const htmlEvents = {
    createInitialHtmlSnapshot
};
export const htmlEffects = {
    resetHtmlToInitialFx
};
export const htmlStores = {
    initialHtmlSnapshot
};
