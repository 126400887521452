import styled, { css } from 'styled-components';

interface Checkbox {
    disabled?: boolean;
    checked?: boolean;
}

export const CheckboxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 18px;
    cursor: pointer;
`;

export const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    top: 0;
    left: 0;
`;

export const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
`;

export const StyledCheckbox = styled.div<Checkbox>`
    display: inline-flex;
    width: 18px;
    height: 18px;
    border: 2px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 2px;
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.bgColor};
    cursor: pointer;
    transition: background-color 0.15s ease, border-color 0.15s ease;

    ${HiddenCheckbox}:checked + & {
        box-shadow: none;
        background-color: ${({ theme }) => theme.colors.blue['400']};
        border-color: ${({ theme }) => theme.colors.blue['400']};
    }
    ${HiddenCheckbox}:focus-visible + & {
        box-shadow: none;
        outline: ${({ theme }) => `${theme.colors.gray_300} solid 2px`};
    }
    ${HiddenCheckbox}:disabled + & {
        cursor: default;
        background-color: transparent;
        border: 2px solid ${({ theme }) => theme.colors.dustBlue['100']};
    }
    ${HiddenCheckbox}:disabled:checked + & {
        cursor: default;
        background-color: ${({ theme }) => theme.colors.dustBlue['100']};
        border: 2px solid ${({ theme }) => theme.colors.dustBlue['100']};

        polyline {
            stroke: ${({ theme }) => theme.colors.dustBlue['400']};
        }
    }
`;

interface TextProps {
    disabled?: boolean;
}

export const Text = styled.span<TextProps>`
    margin-left: 10px;
    line-height: 18px;
    transition: color 0.15s ease;
    ${({ theme }) => theme.typography.note};

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.colors.dustBlue['200']};
        `};
`;
