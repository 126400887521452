import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const CategoriesWrapper = styled.section`
    width: 300px;
    display: flex;

    ${media.laptop`
        width: 100%;
    `}
`;
