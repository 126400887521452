import styled from 'styled-components/macro';
import { ButtonIcon } from '@wedat/ui-kit/components/ButtonIcon';
import { media } from '@wedat/ui-kit/mediaQueries';
import { InputStyled } from '@wedat/ui-kit/components/Input/styles';

interface InputContainerProps {
    fullWidth?: boolean;
}

interface InputFieldWithButtonContainerProps {
    withNumberPlate?: boolean;
}

export const StyledButtonIcon = styled(ButtonIcon)`
    min-width: 48px;
    width: 48px;
    flex: none;

    ${media.phoneMedium`
        width: 35px;
    `}
`;

export const InputContainer = styled.div<InputContainerProps>`
    position: relative;
    width: 100%;

    ${InputStyled} {
        padding-right: 40px;
    }

    ${media.phone`
        width: ${({ fullWidth = true }) => (fullWidth ? '100%' : '60%')};
        flex: 1;
        /* Add this for IOS fix */
        max-width: 80%;
    `}
`;

export const OCRIconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const InputFieldWithButtonContainer = styled.div<InputFieldWithButtonContainerProps>`
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    & button:not(:last-child) {
        margin-right: 10px;
    }

    ${media.phone`
        justify-content: space-between;
    `}

    ${OCRIconWrapper} {
        right: ${({ withNumberPlate }) => withNumberPlate && '12px !important'};
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    margin-left: 10px;
`;

export const Label = styled.label`
    display: flex;
    cursor: pointer;
`;
