import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Card } from '../core/styles';
import { Table } from '@wedat/ui-kit/components/Table';

export const CardStyled = styled(Card)`
    display: none;

    ${media.phoneBig`
        display: block;
    `}
`;

export const TableStyled = styled(Table)`
    ${media.phoneBig`
        display: none;
    `}
`;

export const SizedBox = styled.div`
    width: 100px;
`;

export const FlexWrapper = styled.div`
    display: flex;
    margin-bottom: 14px;
    align-items: center;
`;

export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);
    gap: 16px;

    ${media.phoneBig`
        display: flex;

        /* Need for style all child element inside GridWrapper */
        > div,
        > span {
            width: 25%;
        }
    `}
`;

export const Info = styled.div`
    &:not(:last-child) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray_10};
    }
`;

export const SumInfo = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
        border-bottom: 2px solid ${({ theme }) => theme.colors.gray_10};
    }
`;
