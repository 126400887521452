import { TFunction } from 'react-i18next';

export enum CalculateResultsTabs {
    Price = '1',
    SpareParts = '2',
    Labour = '3',
    Paintwork = '4',
    AuxiliaryCosts = '5',
    Discounts = '6',
    RepairCostExpantion = '7'
}

export const tabs = (t: TFunction) => [
    { id: CalculateResultsTabs.Price, label: t('calculateContractTabs.price') },
    { id: CalculateResultsTabs.SpareParts, label: t('calculateContractTabs.spareParts') },
    { id: CalculateResultsTabs.Labour, label: t('calculateContractTabs.labour') },
    { id: CalculateResultsTabs.Paintwork, label: t('calculateContractTabs.paintwork') },
    { id: CalculateResultsTabs.AuxiliaryCosts, label: t('calculateContractTabs.auxiliaryCosts'), disabled: true },
    { id: CalculateResultsTabs.Discounts, label: t('calculateContractTabs.discounts'), disabled: true },
    {
        id: CalculateResultsTabs.RepairCostExpantion,
        label: t('calculateContractTabs.repairCostExpantion'),
        disabled: true
    }
];
