import React from 'react';

import { useStore } from 'effector-react';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { contractsListEffects, contractsListStores } from '../../stores/contractsList';
import { previewEvents, previewStores } from '../../stores/preview';
import { pointsAsideStores } from '../../stores/pointAside';

import { Virtuoso } from '@wedat/ui-kit/components/Virtuoso';
import { NotFound } from '@dat/smart-components/NotFound';

import { ItemsPreloader } from './ItemsPreloader';
import { ListItem } from './ListItem';
import { ColumnsHeader } from './ColumnsHeader';
import { Filters } from './Filters';
import { Preview } from './Preview';
import { PointsAside } from './PointsAside';

import { Container } from './styles';

export const List: React.FC = () => {
    const isTablet = useMedia(sizes.tablet);
    const contracts = useStore(contractsListStores.contracts);
    const isLoading = useStore(contractsListEffects.loadMoreContractsFx.pending);

    const previewOpened = useStore(previewStores.previewOpened);
    const pointAsideOpened = useStore(pointsAsideStores.pointAsideOpened);

    return (
        <Container onClick={() => previewEvents.closePreview()}>
            <Filters />
            {!isTablet && <ColumnsHeader />}

            {!contracts.length && !isLoading ? (
                <NotFound />
            ) : (
                <Virtuoso
                    data={contracts}
                    itemContent={(id, contract) => (
                        <ListItem key={id} contract={contract} openPreview={previewEvents.openPreview} />
                    )}
                    endReached={() => contractsListEffects.loadMoreContractsFx()}
                    increaseViewportBy={500}
                    components={{ Footer: ItemsPreloader }}
                />
            )}
            {previewOpened && <Preview close={previewEvents.closePreview} />}
            {pointAsideOpened && <PointsAside />}
        </Container>
    );
};
