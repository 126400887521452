import { FC, useCallback } from 'react';
import { useStore } from 'effector-react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import { InputField, SelectField, NumberInputField } from '@wedat/ui-kit/Formik';
import { ModalContainer, Label, StyledModalButton, Field, ModalButtonsContainer } from './styles';
import { selectsOptions } from '../../constants/selectsOptions';
import { PART_SELECTION_VALIDATION } from './constants';
import { partSelectionEffects, partSelectionStores } from '@dat/shared-models/partSelection';
import { PartSelectionFormState } from '@dat/shared-models/partSelection/types';

interface Props extends ModalProps {}

export const PartSelectionModal: FC<Props> = ({ onDismiss, isOpen }) => {
    const { t } = useTranslation();
    const initialFormState = useStore(partSelectionStores.initialFormState);

    const handleSubmit = useCallback(
        (payload: PartSelectionFormState, { resetForm }) => {
            if (initialFormState.id) {
                partSelectionEffects.editPartEffect({ id: initialFormState.id, payload });
                return;
            }

            partSelectionEffects.createPartEffect(payload);
            resetForm();
        },
        [initialFormState.id]
    );

    return (
        <Formik
            validationSchema={PART_SELECTION_VALIDATION}
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialFormState}
        >
            {({ handleSubmit, values }) => (
                <Form
                    onSubmit={async () => {
                        handleSubmit();
                    }}
                >
                    <ModalPrimary
                        bodyWidth="auto"
                        bodyHeight="auto"
                        title={t('modal.title')}
                        onDismiss={onDismiss}
                        isOpen={isOpen}
                    >
                        <ModalContainer>
                            <Field>
                                <Label>{t('modal.label.country')}</Label>
                                <SelectField
                                    name="country"
                                    options={selectsOptions.selectCountries(t)}
                                    placeholder={t('modal.placeholder.pleaseSelect')}
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="repairType">{t('modal.label.repairType')}</Label>
                                <SelectField
                                    id="repairType"
                                    options={selectsOptions.selectRC(t)}
                                    name="repairType"
                                    placeholder={t('modal.placeholder.select')}
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="description">{t('modal.label.description')}</Label>
                                <InputField id="description" label={t('modal.label.description')} name="description" />
                            </Field>
                            {!(
                                values.repairType === 'REPARATION_AND_PAINTING' ||
                                values.repairType === 'DISMOUNTING_MOUNTING'
                            ) && (
                                <Field>
                                    <Label htmlFor="price">{t('modal.label.price')}</Label>
                                    <NumberInputField
                                        hideZeroValue
                                        id="price"
                                        name="price"
                                        label={t('modal.label.price')}
                                    />
                                </Field>
                            )}
                            <Field>
                                <Label htmlFor="workingTime">{t('modal.label.workingTime')}</Label>
                                <NumberInputField
                                    hideZeroValue
                                    id="workingTime"
                                    label={t('modal.label.workingTime')}
                                    name="workingTime"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="quantity">{t('modal.label.quantity')}</Label>
                                <NumberInputField
                                    hideZeroValue={true}
                                    id="quantity"
                                    label={t('modal.label.quantity')}
                                    name="quantity"
                                />
                            </Field>

                            <ModalButtonsContainer>
                                <StyledModalButton
                                    onClick={() => {
                                        onDismiss();
                                        handleSubmit();
                                    }}
                                    type="submit"
                                    typeStyle={{ type: 'primary' }}
                                >
                                    {t('modal.label.save')}
                                </StyledModalButton>
                                <StyledModalButton onClick={onDismiss} typeStyle={{ type: 'transparent' }}>
                                    {t('modal.label.cancel')}
                                </StyledModalButton>
                            </ModalButtonsContainer>
                        </ModalContainer>
                    </ModalPrimary>
                </Form>
            )}
        </Formik>
    );
};
