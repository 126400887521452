import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const RowStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    ${media.laptop`
        width: 100%;
        margin-right: 0;
    `}

    &:empty {
        display: none;
    }
`;
