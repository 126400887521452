import { Button } from '@wedat/ui-kit/components/Button';
import styled from 'styled-components';

export const Modal = styled.div`
    display: inline-block;
    background-color: red;
`;

export const Title = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const ConfirmButton = styled(Button)`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 0px 0px;
`;

export const DenyButton = styled(Button)`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 10px;
`;
