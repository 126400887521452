import { createEffect, createEvent, createStore } from 'effector';
import { FormValues } from '../../types/formValues';

const loginWithCredentialsFromFormFx = createEffect<DAT2.Credentials, BFF.User.Response.Login>();

const shouldLoginViaForm = createEvent<any>();
const formSubmitted = createEvent<FormValues>();

const isShowForm = createStore(false);
const isError = createStore(false);
const isSuccess = createStore(false);

export const formEvents = {
    shouldLoginViaForm,
    formSubmitted
};
export const formEffects = {
    loginWithCredentialsFromFormFx
};
export const formStores = {
    isShowForm,
    isError,
    isSuccess
};
