import { createStore, createEvent, createEffect, sample } from 'effector';
import { dynamicGenericSVGGraphics, vehicleTypePart } from '../initData/dynamicGenericSVGGraphics';
import { getVehicleType } from '../services/italyAniaSerivce2';
import { getKindOfSVG } from '../utils/getKindOfSVG';
import { genericSVGGraphicModel } from './genericSVGGraphicModel';

const setVehicleSVG = createEvent<string>();
const vehicleSVG = createStore<string>('').on(setVehicleSVG, (_, newVehicleSVG) => newVehicleSVG);

const getVehicleTypeFX = createEffect(getVehicleType);

sample({
    clock: getVehicleTypeFX.doneData,
    fn: getVehicleTypeResponse => {
        if (!getVehicleTypeResponse?.response.length) return '4_doors';

        const datECode = getVehicleTypeResponse.datecode;

        const [vehicleType, doors] = getVehicleTypeResponse.response;

        return getKindOfSVG(datECode, vehicleType, doors);
    },
    target: setVehicleSVG
});

sample({
    clock: setVehicleSVG,
    fn: vehicleSVG =>
        dynamicGenericSVGGraphics.map(grap => ({
            ...grap,
            sourceUrl: grap.sourceUrl.replaceAll(vehicleTypePart, vehicleSVG)
        })),
    target: genericSVGGraphicModel.effects.setGenericGraphicFx
});

export const vehicleSVGModel = {
    stores: {
        vehicleSVG
    },
    events: {
        setVehicleSVG
    },
    effects: {
        getVehicleTypeFX
    }
};
