export const defaultFields = [
    { name: 'fullAddress', type: 'googlePlaces' },
    { name: 'name' },
    { name: 'fax' },
    { name: 'phone' },
    { name: 'mobilePhone' },
    { name: 'idVatNumber' },
    { name: 'vatNumber' },
    { name: 'mail' },
    { name: 'pec' },
    { name: 'zip' },
    { name: 'memo', type: 'memo' }
];
