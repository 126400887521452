import _ from 'lodash';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useStore } from 'effector-react';

import { postEditingEffects, postEditingStores } from '../../stores/postEditing';

import { Text } from '@wedat/ui-kit/components/Text';
import { BinIcon, PlusIcon } from '@wedat/ui-kit/components/Icons';
import { PositionsTable } from './PositionsTable';

import {
    ButtonStyledDelete,
    Container,
    Header,
    IconWrapper,
    IconWrapperDelete,
    PositionsTableContainer,
    TextStyled
} from './styles';
import { DiscountedPositions } from './DiscountedPositions';
import { getParsedArraySafe } from '@dat/api2/utils';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';

export const PostEditing = () => {
    const initialFormPositions = useStore(postEditingStores.initialFormPositions);
    const selectedPositions = useStore(postEditingStores.selectedPositions);

    const repairCalculation = useStore(sharedRepairCalculationStores.repairCalculation);
    const discountedPositions = getParsedArraySafe(
        repairCalculation?.CalcResultCommon?.DiscountPositions?.DiscountPosition
    );

    const initialValues = useMemo(() => initialFormPositions, [initialFormPositions]);

    const addPosition = () => {};

    const handleDelete = () => {
        postEditingEffects.deletePositions(null);
    };

    return (
        <Container>
            <Formik initialValues={initialValues} onSubmit={_.noop} enableReinitialize>
                <PositionsTableContainer>
                    <Header>
                        <Text font="semiHeading" fontWeight={700}>
                            Positions
                        </Text>
                        <IconWrapper onClick={addPosition}>
                            <PlusIcon />
                        </IconWrapper>
                        {!!selectedPositions.length && (
                            <ButtonStyledDelete onClick={handleDelete}>
                                <IconWrapperDelete>
                                    <BinIcon />
                                </IconWrapperDelete>
                                <TextStyled font="note">Delete {selectedPositions.length} positions</TextStyled>
                            </ButtonStyledDelete>
                        )}
                    </Header>
                    <PositionsTable />
                </PositionsTableContainer>
            </Formik>
            {!!discountedPositions.length && <DiscountedPositions />}
        </Container>
    );
};
