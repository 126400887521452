import React, { FC, CSSProperties, ReactNode } from 'react';

import './InlineIcon.css';

export interface IconType {
    style?: CSSProperties;
    className?: string;
    icon?: ReactNode;
    active?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

export const InlineIcon: FC<IconType> = ({ style, className, icon, active = false, onClick, children }) => (
    <div
        className={
            (active ? 'weDat-grapa-inline-icon weDat-grapa-inline-icon-active ' : 'weDat-grapa-inline-icon ') +
            className
        }
        style={{
            ...style
        }}
        onClick={onClick}
    >
        <div className={'weDat-grapa-inline-icon-box'}>{icon}</div>
        <div>{children}</div>
    </div>
);
