import React, { CSSProperties, FC } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { genericSVGGraphicModel } from '../../../stores/genericSVGGraphicModel';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

export interface FtDamageFormType {
    markElementId?: string;
    avalableDamages?: AvalableDamages[];
    selectedElements?: FastTrackElement[];
}

export interface AvalableDamages {
    datId: string;
    groupName: string;
    damageid: string;
    damageLable: string;
    damageTranslation: string;
}

export interface FastTrackElement {
    elementId: string;
    userComment: string;
    damageType: string;
}

const styleOptionsDamage: CSSProperties = {
    minWidth: '80%',
    marginTop: 12,
    textAlign: 'center'
};

const FtDamageForm: FC<FtDamageFormType> = () => {
    const markElementId = useStore(genericSVGGraphicModel.stores.currentFtDatId);
    const currentAvailableDamage = useStore(genericSVGGraphicModel.stores.currentAvailableDamage);
    const currentFtElementDamage = useStore(genericSVGGraphicModel.stores.currentFtElementDamage);

    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center' }}>
            <h3
                style={{
                    marginTop: 16,
                    textAlign: 'center',
                    display: 'block'
                }}
            >
                {t(`fastLocalNameDATID.${markElementId}`)}
            </h3>

            {currentAvailableDamage?.map(element => (
                <Button
                    key={element.damageid}
                    size="large"
                    style={styleOptionsDamage}
                    type={currentFtElementDamage === element.damageid ? 'primary' : 'default'}
                    onClick={_ => {
                        if (!!markElementId) {
                            genericSVGGraphicModel.events.clearRepairPositionByFtDatId({ ftDatId: markElementId });
                            genericSVGGraphicModel.events.setRepairPositionByFtDatIdAndDamage({
                                ftDatId: markElementId,
                                ftDamage: element.damageid
                            });
                        }
                    }}
                >
                    {element.damageTranslation}
                </Button>
            ))}

            <Button
                type="link"
                style={{ ...styleOptionsDamage, fontWeight: 'bold' }}
                size="large"
                onClick={_ => {
                    if (!!markElementId) {
                        genericSVGGraphicModel.events.clearRepairPositionByFtDatId({ ftDatId: markElementId });
                    }
                }}
                icon={<DeleteOutlined />}
            >
                {t('fastTrack.noDamage')}
            </Button>
        </div>
    );

    // todo button go to full graphic
};

export default FtDamageForm;
