import styled from 'styled-components';

import { Label } from '../Label';

export const Wrapper = styled.div<{ width: string | number | undefined }>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${({ width }) => width && `width: ${width}px;`}
    min-width: 0;
    height: 48px;
`;

export const LabelStyled = styled(Label)`
    transform: ${props =>
        props.filled || props.focused ? 'translate(7px, -9px) scale(0.75)' : 'translate(44px, 11px) scale(1)'};
`;

export const InputStyled = styled.input`
    height: 100%;
    padding-left: 44px;
    padding-right: 44px;
    ${({ theme }) => theme.typography.note};
    color: ${({ theme, disabled }) => (disabled ? 'inherit' : theme.colors.dustBlue['900'])};
    box-sizing: border-box;
    background: none;
    margin: none;
    border: none;
    border-radius: 8px;
    outline: none;
    z-index: ${({ disabled }) => (disabled ? 1 : 0)};

    &::placeholder {
        color: ${({ theme }) => theme.colors.textSecondary};
    }

    &:-webkit-autofill + ${LabelStyled} {
        transform: translate(7px, -9px) scale(0.75);
    }
`;

export const IconStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 12px;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    transform: translateY(-50%);
`;

export const ButtonReset = styled.button`
    border: none;
    position: absolute;
    top: 50%;
    right: 12px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
`;
