import { TabButton } from '@wedat/ui-kit/components/Tabs';
import { TFunction } from 'i18next';

export enum AttachmentsTabs {
    Gallery = '1',
    Printreports = '2'
}

export const attachmentsTabsValues = (t: TFunction): TabButton[] => [
    {
        id: AttachmentsTabs.Gallery,
        label: t('titles.gallery')
    },
    {
        id: AttachmentsTabs.Printreports,
        label: t('titles.printreports')
    }
];
