// ReplaceTypeView
import { useStore } from 'effector-react';
import React from 'react';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
// import { ExtPartNoInfoView } from './ExtPartNoInfoView';
import { ManualSet } from './ManualSetView';

export function ReplaceTypeView() {
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const { currentAssemblyGroupObject: currentObj, currentDVNs } = useStore(
        graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
    );

    // const dvnLR = currentObj?.dvnLeft || currentObj?.dvnRight;

    if (currentDVNs?.length !== 1) return null;
    const dvn = currentDVNs[0];
    const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;

    const repairPositionReplace = repairPositions.find(
        item => item.DATProcessId === dvn && item.RepairType === 'replace'
    );

    // function getRepairPositionByDvn(dvn: number) {
    //     return repairPositions.find(item => item.DATProcessId === dvn);
    // }

    if (!dvnLR || !currentObj) return null;

    return (
        <>
            <ManualSet el={currentObj} dvnLR={dvnLR} repairPosition={repairPositionReplace} />
            {/* <ExtPartNoInfoView /> */}
        </>
    );
}
