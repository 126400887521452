import React from 'react';
import { useStore } from 'effector-react';
import { commonStores } from '../../stores/common';
import { SelectField, SelectFieldProps } from '@wedat/ui-kit/Formik';
import { useOptions } from './hooks/useOptions';
import { CustomSelectOption } from './types';
import { useValueAutoSelection } from '@dat/core/hooks/useValueAutoSelection';

export interface CustomSelectProps extends SelectFieldProps {
    options: CustomSelectOption[];
    idLength?: number;
}

export const CustomSelectField: React.FC<CustomSelectProps> = props => {
    const { name } = props;
    const focusedSelectName = useStore(commonStores.focusedSelectName);
    const isFocused = focusedSelectName === name;
    const options = useOptions(props);

    useValueAutoSelection<CustomSelectOption>(props);

    return <SelectField {...props} menuIsOpen={isFocused} options={options} valueKey="key" />;
};
