import styled from 'styled-components/macro';
import { GearIcon as GearIconBase } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';

export const Title = styled(Text)`
    padding-left: 20px;
`;

export const MenuItem = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${({ active, theme }) => (active ? theme.colors.blue['100'] : 'transparent')};
`;

export const Label = styled(Text)`
    padding-left: 20px;
`;

export const GearIcon = styled(GearIconBase)`
    margin-left: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
`;

export const SlideUp = styled(SlideUpMenu)<{ isLandscape?: boolean }>`
    left: auto;
    right: 0;
    max-width: ${({ isLandscape }) => (isLandscape ? '375px' : 'auto')};
`;
