import { FC } from 'react';
import { useStore } from 'effector-react';

import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';
import './stores/init';

import { AiClaim } from './pages/AiClaim';
import { PluginProvider } from '@dat/smart-components/PluginProvider';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => {
    const isLoading = useStore(commonStores.isLoading);

    if (options.isOpen === undefined) {
        options.isOpen = true;
    }

    return (
        <PluginProvider
            name="ai-claim"
            options={options}
            onInit={pluginEvents.initPlugin}
            isLoading={isLoading}
            triggerRemount={pluginEvents.resetPlugin}
        >
            <AiClaim />
        </PluginProvider>
    );
};

export default App;
