import styled, { DefaultTheme } from 'styled-components';
import { LabelProps } from './types';

const getColor = (theme: DefaultTheme, { status, focused, disabled }: LabelProps) => {
    if (status === 'error') {
        return theme.colors.red[900];
    }

    if (status === 'success') {
        return theme.colors.green[900];
    }

    if (focused) {
        return theme.colors.blue[500];
    }

    if (disabled) {
        return theme.colors.dustBlue[200];
    }

    return theme.colors.dustBlue[400];
};

export const LabelStyled = styled.label<LabelProps>`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    max-width: ${({ filled, focused }) => (filled || focused ? '100%' : 'calc(100% - 10px - 24px)')};
    color: ${({ theme, ...props }) => getColor(theme, props)};
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    transform: ${props =>
        props.filled || props.focused ? 'translate(7px, -9px) scale(0.75)' : 'translate(7px, 11px) scale(1)'};
    transform-origin: top left;
    transition: transform 200ms;
    z-index: 1;
`;
