import { forward } from 'effector';

import { contractsListEffects, contractsListEvents, contractsListStores } from './index';
import { CLAIMS_LISTER_NAME, PAGE_LIMIT } from './constants';
import { FolderType } from './types';
import { sharedLoginEvents } from '@dat/shared-models/auth/login';

const { newContractsLoaded, increasePageOffset, addNewContracts, resetContracts, reloadContracts } =
    contractsListEvents;
const { contracts, columns, params, listUserSettings, filters, foldersCounts } = contractsListStores;
const { loadUserSettingsFx, loadFolderFilterFx, loadFolderCountFx } = contractsListEffects;

listUserSettings.on(loadUserSettingsFx.doneData, (_, state) => state);
columns.on(
    listUserSettings.updates.map(
        ({ listerSettings }) => listerSettings.find(setting => setting.name === CLAIMS_LISTER_NAME)?.visibleColumns
    ),
    (_, state) => state
);

forward({
    from: listUserSettings.updates.map(({ folderFilters }) => ({
        folderType: FolderType.FILTER,
        folderId: folderFilters[0].id,
        storedFilterId: folderFilters[0].storedFilter_id
    })),
    to: loadFolderFilterFx
});

listUserSettings.watch(loadUserSettingsFx.doneData, settings => {
    settings.folderFilters.forEach(({ id }) => {
        loadFolderCountFx({
            folderId: id
        });
    });
});

foldersCounts.on(loadFolderCountFx.done, (state, { params: { folderId }, result }) => ({
    ...state,
    [folderId]: result
}));

forward({
    from: newContractsLoaded,
    to: [addNewContracts, increasePageOffset]
});

params.offset.on(increasePageOffset, (current: number) => current + PAGE_LIMIT);

forward({
    from: sharedLoginEvents.loggedOut,
    to: resetContracts
});

contracts.reset(resetContracts);
params.offset.reset(resetContracts);

forward({
    from: [
        params.sort.updates,
        filters.globalSearch.updates,
        filters.statuses.updates,
        filters.folder.updates,
        filters.efficiency.updates
    ],
    to: reloadContracts
});
