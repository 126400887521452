import { SetRequired } from 'type-fest';

/*
 * Filters array of objects by excluding objects in which
 * properties with keys from second argument `keysToExist`
 * equal to `undefined` or `null`.
 */
export const filterArrayOfObjectsByExistingKeys = <T extends object, K extends Array<keyof T>>(
    arr: T[],
    keysToExist: K
): Array<SetRequired<T, K[number]>> =>
    arr.filter(obj => keysToExist.every(key => obj[key] !== undefined && obj[key] !== null)) as Array<
        SetRequired<T, K[number]>
    >;
