import React from 'react';
import { useStore } from 'effector-react';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';

import { modalsEvents, modalsStores } from '../../stores/ui/modals';
import { WorkBench } from '../WorkBench';
import { useTranslation } from 'react-i18next';

export const ModalWorkBench: React.FC = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isWorkBenchOpen);

    return (
        <ModalPrimary
            bodyWidth="fit-content"
            bodyHeight="fit-content"
            title={t('modalWorkBench.title')}
            isOpen={isOpen}
            onDismiss={modalsEvents.setIsWorkBenchOpen}
        >
            <WorkBench />
        </ModalPrimary>
    );
};
