import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import OneSignal from 'react-onesignal';

import { ROUTES } from './constants/router';
import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { sharedUserStores } from '@dat/shared-models/user';
import { HistoryGateConnector } from './components/HistoryGateConnector';
import { InboxPage } from './pages/Inbox';
import { ClaimPage } from './pages/Claim';
import { StepperPage } from './pages/GuidedStepper';

import './stores/init'; //TODO: move back after patronum fix https://github.com/effector/patronum/pull/186

import { PluginOptions } from './types/plugin';
import { AdministrationPage } from './pages/Administration';
import { SchedulerPage } from './pages/Claim/SchedulerPage';

interface Props {
    options: PluginOptions;
}

OneSignal.init({ appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID ?? '' });

const App: React.FC<Props> = ({ options }) => {
    const { urlPath } = options;
    const isLoading = useStore(commonStores.isLoading);
    const username = useStore(sharedUserStores.username);

    useEffect(() => {
        if (!!username) {
            OneSignal.setExternalUserId(username);
        }
    }, [username]);

    return (
        <PluginProvider
            name="claim-management"
            options={options}
            onInit={pluginEvents.initPlugin}
            isLoading={isLoading}
        >
            <Router basename={urlPath}>
                <HistoryGateConnector />
                <Route exact path={ROUTES.inbox.root} component={InboxPage} />
                <Route exact path={ROUTES.inbox.guided} component={StepperPage} />
                <Route exact path={ROUTES.inbox.scheduler} component={SchedulerPage} />
                <Route path={ROUTES.administration.root} component={AdministrationPage} />
                <Route path={ROUTES.claim.root} component={ClaimPage} />
            </Router>
        </PluginProvider>
    );
};

export default App;
