import React, { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper/core';

import { SlideItem } from './SlideItem';
import { aiGalleryEvents, aiGalleryStores } from '../../stores/gallery';
import { PluginOptions } from '../../types/plugin';

import { SwiperStyled, GalleryStyled, Toggles, ToggleStyled } from './styles';
import { useTranslation } from 'react-i18next';
import { contractEvents } from '@dat/shared-models/contract';

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

interface Props {
    options: PluginOptions;
}

export const Gallery: FC<Props> = React.memo(({ options }) => {
    const { t } = useTranslation();
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
    const aiGallery = useStore(aiGalleryStores.aiGallery);
    const attachments = useStore(aiGalleryStores.attachments);
    const hasPanel = useStore(aiGalleryStores.hasPanel);
    const hasPanelDamage = useStore(aiGalleryStores.hasPanelDamage);
    const filteredAttachments = attachments.filter(item => item.mimeType === 'image/jpeg');

    const handleThumbsSwiper = (e: SwiperClass) => {
        setThumbsSwiper(e);
    };

    useEffect(() => {
        if (!aiGallery) {
            contractEvents.updateInitialContract();
        }
        aiGalleryEvents.getGalleryContract(options.contractId);
    }, [aiGallery, options.contractId]);

    const handleChangePanel = (value: boolean) => {
        aiGalleryEvents.setPanel(value);
    };

    const handleChangeDamagePanel = (value: boolean) => {
        aiGalleryEvents.setPanelDamage(value);
    };

    if (!aiGallery?.aiResults?.aiPipelineImages || attachments.length === 0) {
        return null;
    }

    return (
        <GalleryStyled>
            <Toggles>
                <ToggleStyled checked={hasPanel} onChangeToggle={handleChangePanel} label={t('panels')} />
                <ToggleStyled checked={hasPanelDamage} onChangeToggle={handleChangeDamagePanel} label={t('damages')} />
            </Toggles>
            <SwiperStyled>
                <Swiper spaceBetween={10} cssMode={true} thumbs={{ swiper: thumbsSwiper }} className="swiper-gallery">
                    {filteredAttachments.map(item => (
                        <SwiperSlide key={item.uploaderId}>
                            <SlideItem attachment={item} list={aiGallery.aiResults.aiPipelineImages} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                    onSwiper={handleThumbsSwiper}
                    onInit={handleThumbsSwiper}
                    spaceBetween={10}
                    navigation={true}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesVisibility={true}
                    watchSlidesProgress={true}
                    className="swiper-gallery-2"
                >
                    {filteredAttachments.map(item => (
                        <SwiperSlide key={item.uploaderId}>
                            <img src={item.base64} alt={`alt-${item.fileName}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperStyled>
        </GalleryStyled>
    );
});
