import { EfficiencyStatus } from '../stores/efficiency/types';

export const getEfficiencyColor = ({
    date,
    efficiencyStatus,
    expireYellowFlag
}: {
    date?: Date;
    efficiencyStatus: EfficiencyStatus;
    expireYellowFlag: number;
}) => {
    if (!date) {
        return;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateDay = new Date(date.getTime());
    dateDay.setHours(0, 0, 0, 0);

    if (
        efficiencyStatus === EfficiencyStatus.NOT_COMPLETED ||
        efficiencyStatus === EfficiencyStatus.COMPLETED_NOT_ON_TIME
    ) {
        if (today.getTime() > dateDay.getTime()) {
            return 'red';
        } else if (expireYellowFlag) {
            const expireYellowDate = new Date(dateDay.getTime() - expireYellowFlag * 24 * 60 * 60 * 1000);
            if (today.getTime() > expireYellowDate.getTime()) {
                return 'orange';
            }
        }
    }
};
