import { combine, createEvent, createStore, restore } from 'effector';
import { sharedPartnersEffects } from '@dat/shared-models/contract/Partners';
import { locationStores } from '../location';
import { findNearestLocation } from '../../utils/pathFind';

const partnersWithCoords = restore(sharedPartnersEffects.getCustomerPartnersFx, []);

const partnersWithPosition = combine(
    { listOfPartners: partnersWithCoords, position: locationStores.claimPosition },
    ({ listOfPartners, position }) => findNearestLocation(listOfPartners, position)
);

const selectRole = createEvent<DAT2.CustomerRole>();
const assignPartnerToContract = createEvent<DAT2.Request.AssignPartnerToContract>();
const unAssignPartnerToContract = createEvent<DAT2.Request.UnAssignPartnerFromContract>();

const activeRole = createStore<DAT2.CustomerRole>('EXPERT').on(selectRole, (_, role) => role);
const unAssignedRoles = createStore<DAT2.CustomerRole[]>([]);

export const partnersEvents = {
    assignPartnerToContract,
    unAssignPartnerToContract,
    selectRole
};

export const partnersStores = {
    partnersWithPosition,
    unAssignedRoles,
    activeRole
};
