import { restore, createEvent, createEffect, attach } from 'effector';
import { API2 } from '@dat/api2';
import { sharedLoginStores } from '@dat/shared-models/auth/login';

const getAttachmentsOfFolder = createEffect(API2.myClaimInternal.getAttachmentsOfFolder);

interface GetFoldersOfClaimParams {
    claimId: number;
    token: string;
}

const getFoldersOfClaim = createEffect(async ({ claimId, token }: GetFoldersOfClaimParams) => {
    const response = await API2.myClaimInternal.getFoldersOfClaim({ claimId });

    // because response might be { error: ... }
    const result = Array.isArray(response) ? response : [];

    result.forEach(folder => {
        folder.attachments.forEach(async attachment => {
            if (attachment.photo) {
                attachment.base64 = `${API2.myClaimInternal.getPhotoUrl({
                    photoId: attachment.id,
                    folderId: attachment.document_id ?? 0,
                    claimId,
                    token
                })}`;
            }
        });
    });

    return result;
});

interface GetFoldersOfClaimWithTokenParams {
    claimId: number;
}

const getFoldersOfClaimWithToken = attach({
    source: sharedLoginStores.token,
    mapParams: (params: GetFoldersOfClaimWithTokenParams, token) => ({ ...params, token }),
    effect: getFoldersOfClaim
});

const folders = restore(getFoldersOfClaim, []);

const setAttachments = createEvent<DAT2.Internal.FolderOfClaimAttachment[]>();
const addItemToAttachments = createEvent<DAT2.Internal.FolderOfClaimAttachment>();
const removeItemFromAttachments = createEvent<DAT2.Internal.FolderOfClaimAttachment>();
const resetAttachments = createEvent();

const selectedAttachments = restore<DAT2.Internal.FolderOfClaimAttachment[]>(setAttachments, [])
    .on(addItemToAttachments, (state, payload) => [...state, payload])
    .on(removeItemFromAttachments, (state, payload) => state.filter(item => item.id !== payload.id))
    .reset(resetAttachments);

export const attachmentsEvents = {
    setAttachments,
    addItemToAttachments,
    removeItemFromAttachments,
    resetAttachments
};

export const attachmentsEffects = {
    getAttachmentsOfFolder,
    getFoldersOfClaim,
    getFoldersOfClaimWithToken
};

export const attachmentsStores = {
    selectedAttachments,
    folders
};
