import { createEvent } from 'effector';

import { pluginEvents } from '../index';
import { createInstancePropertySetter } from './createInstancePropertySetter';
import { findInstanceSubjectsData } from './findInstanceSubjectsData';
import { computeInstanceDynamicProperties } from './computeInstanceDynamicProperties';
import { effectorLogger } from '@dat/core/utils';

import { Subject } from '../../../types/subjects';
import { PayloadForInstanceOptionsReceived } from '../types';
import { Instance, InstanceEvents, InstanceUIProperties } from '../../../types/instance';
import { CustomersSubjectsData } from '../../subjectsData/types';

interface PayloadForCreateInstance {
    customersSubjectsData: CustomersSubjectsData;
    initialPayload: PayloadForInstanceOptionsReceived;
}

//TODO: нужно обрабатывать вариант, когда выбранный юзер удаляется.
export const createInstance = ({ customersSubjectsData, initialPayload }: PayloadForCreateInstance): Instance => {
    const { instanceId } = initialPayload;
    const subjectsData = findInstanceSubjectsData({ customersSubjectsData, ...initialPayload });

    const dynamicProperties = computeInstanceDynamicProperties({ subjectsData, initialPayload });
    const uiProperties: InstanceUIProperties = { isModalOpen: false, isFormVisibleOnMobile: false };
    const events = createEvents(instanceId);

    if (process.env.NODE_ENV === 'development') {
        effectorLogger(events);
    }

    return {
        instanceId,
        events,
        ...dynamicProperties,
        ...uiProperties
    };
};

const createEvents = (instanceId: string): InstanceEvents => {
    const setIsModalOpen = createInstancePropertySetter(instanceId, 'isModalOpen');
    const openModal = setIsModalOpen.prepend(() => true);
    const closeModal = setIsModalOpen.prepend(() => false);

    const setIsFormVisibleOnMobile = createInstancePropertySetter(instanceId, 'isFormVisibleOnMobile');
    const showFormOnMobile = setIsFormVisibleOnMobile.prepend(() => true);
    const hideFormOnMobile = setIsFormVisibleOnMobile.prepend(() => false);

    const addButtonClicked = createEvent<any>();
    const deleteButtonClicked = createEvent<any>();
    const selectButtonClicked = createEvent<any>();
    const saveButtonClicked = createEvent<any>();

    const subjectSelectedInList = createEvent<Subject>();
    const subjectUpdated = createEvent<Subject>();
    const newSubjectAdded = pluginEvents.addSubjectToInstance.filterMap(payload =>
        payload.instanceId === instanceId ? payload.subject : undefined
    );
    const subjectDeleted = pluginEvents.deleteSubjectFromInstance.filterMap(payload =>
        payload.instanceId === instanceId ? payload.index : undefined
    );

    const setDisplayedId = createInstancePropertySetter(instanceId, 'displayedId');
    const setAssignedId = createInstancePropertySetter(instanceId, 'assignedId');

    const displaySubject = setDisplayedId.prepend((subject: Subject | undefined) => subject?._id);
    const assignSubject = setAssignedId.prepend((subject: Subject | undefined) => subject?._id);
    const saveSubjects = createEvent<any>();

    const optionsUpdated = pluginEvents.instanceOptionsReceived.filter({
        fn: payload => payload.instanceId === instanceId
    });

    return {
        openModal,
        closeModal,
        showFormOnMobile,
        hideFormOnMobile,
        addButtonClicked,
        deleteButtonClicked,
        selectButtonClicked,
        saveButtonClicked,
        subjectSelectedInList,
        newSubjectAdded,
        subjectUpdated,
        subjectDeleted,
        displaySubject,
        assignSubject,
        saveSubjects,
        optionsUpdated
    };
};
