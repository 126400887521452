import styled from 'styled-components/macro';
import { WrapperStyled } from '@wedat/ui-kit/components/Tabs/styles';
import { media, sizes } from '@wedat/ui-kit/mediaQueries';
import preloaderImage from '@wedat/ui-kit/assets/images/preloader.gif';
import { Button } from '@wedat/ui-kit';

export const PreloaderPreview = styled.div`
    background: url(${preloaderImage}) no-repeat center;
    background-size: contain;
    width: 200px;
    height: 80%;
    align-self: center;
`;

export const Container = styled.div`
    position: fixed;
    z-index: 1001;
    width: 40%;
    height: 100%;
    right: 0;
    top: 0;
    padding: 20px 10px 10px 10px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.gray['300']};
    display: flex;
    flex-direction: column;

    ${media.tablet`
        width: 70%;
    `}

    ${media.phoneBig`
        width: 100%;
    `}
`;

export const TopContainer = styled.div`
    display: flex;
`;

export const TopContainerInfo = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const TopContainerMainInfo = styled.div`
    display: flex;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;

    @media (min-width: ${sizes.phone}px) {
        margin-top: auto;
    }
`;

export const StatusWrapper = styled.div`
    cursor: pointer;
    margin-right: 10px;
`;

export const VehicleImage = styled.div`
    width: 200px;
    img {
        width: 100%;
    }
`;

export const PhotosContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.gray['200']};
    padding: 5px;
    margin-right: -10px;
    margin-left: -10px;
    height: 130px;
`;

export const InfoContainer = styled.div`
    margin-top: 10px;
    min-height: 0;
    display: flex;
    flex-direction: column;
    
    textarea {
        width: 100%;
        height: 200px;
    }
    
    ${WrapperStyled} {
        margin-bottom: 10px;
        flex: 0 0 70px;
        
        ${media.phoneBig`
            width: 100%;
            flex: 0 0 50px;
        `}
`;

export const CarOwner = styled.div`
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: bolder;

    ${media.phone`
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    `}
`;

export const Price = styled.div`
    background-color: ${({ theme }) => theme.colors.gray['200']};
    padding: 5px 10px;
    justify-content: space-evenly;
    border-radius: 8px;
    margin-right: 5px;
    margin-left: 5px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
    border: thin ${({ theme }) => theme.colors.black} solid;
    border-radius: 8px;
    padding: 7px 10px;
    cursor: pointer;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
`;

export const ButtonGoToClaim = styled(Button)`
    flex: 1;
`;

export const PrintOutButton = styled(ButtonWrapper)`
    height: 22px;
`;

export const BackButtonContainer = styled.div``;

export const BackButton = styled(ButtonWrapper)`
    margin: 0 10px 10px 10px;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    svg {
        transform: rotate(180deg);
    }
`;
