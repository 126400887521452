import styled from 'styled-components';

import { Text } from '../Text';

export interface LegendProps {
    focused?: boolean;
    filled?: boolean;
    disabled?: boolean;
}

export const Legend = styled.legend<LegendProps>`
    float: unset;
    overflow: hidden;
    display: block;
    width: auto;
    padding: 0;
    height: 11px;
    visibility: hidden;
    max-width: ${props => (props.focused || props.filled ? '100%' : '0.01px')};
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    white-space: nowrap;
`;

export const LegendText = styled(Text)`
    visibility: visible;
    display: inline-block;
    opacity: 0;
`;
