import { sharedUserStores } from '@dat/shared-models/user';
import { FormUserProfile } from '../../types';
import { FIELDS, DEFAULT_USER_PROFILE } from '../../constants';
import * as R from 'ramda';

const formUserProfile = sharedUserStores.userProfile.map<FormUserProfile>((userProfile, oldValue) => {
    const newValue: FormUserProfile = R.pick(FIELDS, userProfile || DEFAULT_USER_PROFILE);
    const equals = R.equals(newValue, oldValue);

    if (equals && oldValue) {
        return oldValue;
    }

    return newValue;
});

export const dataStores = {
    formUserProfile
};
