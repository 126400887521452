import React from 'react';

import { attachmentsEvents } from '../../stores/attachments';

export const FileInput = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
    (props, ref) => {
        // image/doc/xsl/pdf/txt
        const accept =
            props.accept ||
            '.doc, .docx, .xsl, .ini, .xlsx, .hjson, .pptx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, text/plain, application/pdf, image/*';

        return (
            <input
                {...props}
                ref={ref}
                hidden
                type="file"
                accept={accept}
                onChange={attachmentsEvents.handleFileInputChange}
            />
        );
    }
);
