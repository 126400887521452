import { Constraint } from '../types';

export const modelConstraints: Omit<Constraint, 'generic'> = {
    SW: {
        front: [0.6, 0.92, 0.6, 0.0, 0.0, 0.0],
        rear: [0.6, 0.92, 0.6, 0.45, 0.75, 0.6],
        frontRight: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        frontLeft: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        rearRight: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35],
        rearLeft: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35]
    },
    SUV: {
        front: [0.6, 0.92, 0.6, 0.0, 0.0, 0.0],
        rear: [0.6, 0.92, 0.6, 0.45, 0.75, 0.6],
        frontRight: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        frontLeft: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        rearRight: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35],
        rearLeft: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35]
    },
    '5-doors': {
        front: [0.6, 0.92, 0.6, 0.0, 0.0, 0.0],
        rear: [0.6, 0.92, 0.6, 0.45, 0.75, 0.6],
        frontRight: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        frontLeft: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        rearRight: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35],
        rearLeft: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35]
    },
    '4-doors': {
        front: [0.6, 0.92, 0.6, 0.0, 0.0, 0.0],
        rear: [0.6, 0.92, 0.6, 0.45, 0.75, 0.6],
        frontRight: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        frontLeft: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        rearRight: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35],
        rearLeft: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35]
    },
    '3-doors': {
        front: [0.6, 0.92, 0.6, 0.0, 0.0, 0.0],
        rear: [0.6, 0.92, 0.6, 0.45, 0.75, 0.6],
        frontRight: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        frontLeft: [0.73, 0.85, 0.4, 0.65, 0.2, 0.35],
        rearRight: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35],
        rearLeft: [0.75, 0.85, 0.36, 0.6, 0.2, 0.35]
    }
};

export const modelConstraints224: Constraint = {
    SW: {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    },
    SUV: {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    },
    '3-doors': {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    },
    '4-doors': {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    },
    '5-doors': {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    },
    generic: {
        front: [0.6, 0.9, 0.7, 0.0, 0.0, 0.0, 0.2, 0.35],
        rear: [0.6, 0.95, 0.55, 0.45, 0.75, 0.65, 0.2, 0.35],
        frontRight: [0.75, 0.85, 0.4, 0.6, 0.15, 0.3, 0.0, 5.0],
        frontLeft: [0.7, 0.85, 0.4, 0.65, 0.2, 0.35, 0.0, 5.0],
        rearRight: [0.7, 0.85, 0.4, 0.7, 0.2, 0.3, 0.65, 1.1],
        rearLeft: [0.7, 0.9, 0.35, 0.6, 0.2, 0.35, 0.65, 1.1]
    }
};
