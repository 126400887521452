import { RequestData } from '@dat/valuate-finance/src/types/plugin';
import { createEvent, createStore, attach } from 'effector';

import { modalsEvents } from '../modals';
import { getValuateData } from '../../utils/getValuateData';
import { historyEvents } from '../history';
import { SUB_ROUTES_PATH_NAMES } from '../../constants/router';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { contractStores } from '@dat/shared-models/contract';
import { sharedEquipmentStores } from '@dat/shared-models/equipment';
import { extractVinResultFromContract } from '@dat/core/utils/extractVinResultFromContract';

const { datECode, vehicle } = sharedVehicleStores;
const { vehicleTranslationEquipment } = sharedEquipmentStores;
const { contract } = contractStores;

const setRedirectedValuate = createEvent<boolean>();
const setRequestDataValuate = createEvent<RequestData>();

const requestDataValuate = createStore<RequestData>({} as RequestData).on(
    setRequestDataValuate,
    (_, dataValuate) => dataValuate
);

const redirectedValuate = createStore<boolean>(false).on(setRedirectedValuate, (_, redirected) => redirected);

const processingValuateDataFx = attach({
    source: { datECode, vehicle, vehicleTranslationEquipment, contract },
    effect: ({ datECode, vehicle, vehicleTranslationEquipment, contract }) => {
        const vinResult = contract ? extractVinResultFromContract(contract) : {};

        const container = vinResult?.vehicleInfo?.container || vehicle?.Container;

        if (vehicle?.MileageOdometer && vehicle?.InitialRegistration && container) {
            valuateEvents.setRedirectedValuate(true);
            const valuateData = getValuateData({
                datECode,
                vehicle,
                equipment: vehicleTranslationEquipment,
                contract,
                values: {
                    vehicleContainer: container,
                    kilometers: vehicle.MileageOdometer || null,
                    firstRegistration: new Date(vehicle.InitialRegistration.slice(0, 10))
                }
            });
            valuateEvents.setRequestDataValuate(valuateData);

            historyEvents.push([SUB_ROUTES_PATH_NAMES.claim.valuation]);
        } else {
            modalsEvents.toggleIsValuationModalOpen(true);
        }
    }
});

export const valuateEvents = {
    setRedirectedValuate,
    setRequestDataValuate
};

export const valuateStores = {
    requestDataValuate,
    redirectedValuate
};

export const valuateEffects = {
    processingValuateDataFx
};
