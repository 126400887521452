import styled, { css } from 'styled-components/macro';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { StyledNavLinkProps } from './index';
import { media } from '@wedat/ui-kit/mediaQueries';

export const StyledNavLink = styled(NavLink).attrs(
    ({ theme }): Partial<NavLinkProps> => ({
        activeStyle: {
            borderBottom: `4px solid ${theme.colors.textActive}`,
            color: theme.colors.textActive,
            fontWeight: 700
        }
    })
)<StyledNavLinkProps>`
    ${({ theme, disabled }) => css`
        margin-right: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 100%;
        font: ${theme.typography.note.font};
        border-bottom: 4px solid transparent;
        transition: opacity 150ms;

        &&& {
            color: ${disabled ? theme.colors.textDisabled : theme.colors.textPrimary};
        }

        &:hover {
            opacity: 0.5;
        }

        ${disabled &&
        css`
            pointer-events: none;
        `}
    `}

    ${media.tablet`
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    `}
`;

export const StyledNavLinkIcon = styled(NavLink).attrs(
    ({ theme }): Partial<NavLinkProps> => ({
        activeStyle: { backgroundColor: theme.colors.blue['50'], color: theme.colors.blue['700'] }
    })
)<StyledNavLinkProps>`
    ${({ theme, disabled }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: ${disabled ? theme.colors.dustBlue['50'] : theme.colors.dustBlue['800']};
        border-radius: 8px;
        transition: color 150ms;

        ${disabled &&
        css`
            pointer-events: none;
        `}

        &:hover {
            color: ${theme.colors.blue['500']};
        }
    `}
`;
