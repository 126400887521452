import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/dat-logo.svg';
import { ReactComponent as LogoDark } from '../../assets/images/dat-logo-dark.svg';
import LogoSilver from '../../assets/images/dat-logo-silver.png';
import LogoSilverDark from '../../assets/images/dat-logo-silver-dark.png';

export interface StyledLogoProps {
    size?: 'large' | 'medium';
}

const logoStyles = css<StyledLogoProps>`
    width: auto;
    height: ${({ size = 'medium' }) => (size === 'medium' ? '20px' : '32px')};
`;

export const LogoImg = styled(Logo)`
    ${logoStyles}
`;

export const LogoDarkImg = styled(LogoDark)`
    ${logoStyles}
`;

export const LogoSilverImg = styled.img.attrs(() => ({
    src: LogoSilver
}))`
    ${logoStyles};
`;

export const LogoSilverDarkImg = styled.img.attrs(() => ({
    src: LogoSilverDark
}))`
    ${logoStyles};
`;
