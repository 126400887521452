import React, { Fragment, useEffect, useState } from 'react';
import { Event } from 'effector';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    trigger?: Event<any>;
}

/* This component forces it's children to remount and reset their state when `trigger` event is called */
export const Remount: React.FC<Props> = ({ trigger, children }) => {
    const [key, setKey] = useState(uuidv4);

    useEffect(
        () =>
            trigger?.watch(() => {
                setKey(uuidv4());
            }),
        [trigger]
    );

    return <Fragment key={key}>{children}</Fragment>;
};
