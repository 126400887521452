import { getErrorInformation } from '@dat/core/utils/parseErrorMessage';
import { createEffect, Effect } from 'effector';
import { toastEffects } from './store';

export const createNotifyingEffect = <FN extends Function>(config: {
    name?: string;
    handler: FN;
    sid?: string;
}): FN extends (...args: infer Args) => infer Done
    ? Effect<
          Args['length'] extends 0 ? void : 0 | 1 extends Args['length'] ? Args[0] | void : Args[0],
          Done extends Promise<infer Async> ? Async : Done
      >
    : never => {
    const effect = createEffect(config);

    effect.fail.watch(({ error }) => {
        const errorBlock = error && error.message ? getErrorInformation(error.message).message : '';

        const errorMessage = errorBlock || 'defaultErrorMessage';

        toastEffects.showErrorToastFx({
            message: {
                namespace: 'ui-kit',
                key: errorMessage
            }
        });
    });

    return effect;
};
