import React, { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { summarySVG_css } from './elementSVG';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { useEmbedElementHeightByAspectRatio } from '../../utils/useElementHeight';
import { urlOfRepoForSVG } from '../../initData/dynamicGenericSVGGraphics';
import { vehicleSVGModel } from '../../stores/vehicleSVGModel';
import { getUrlBlobByUrl } from '@dat/grapa/src/utils/getByUrl';

export const SummarySVG: FC = () => {
    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const vehicleSVG = useStore(vehicleSVGModel.stores.vehicleSVG);

    const [summarySVGUrl, setSummarySVGUrl] = useState('');
    const [elementSVG, setElementSVG] = useState<Document | null>(null);

    const embedRef = useEmbedElementHeightByAspectRatio(1920 / 1080);

    function onLoadSVG() {
        if (embedRef.current) {
            const svgDoc = embedRef.current.getSVGDocument();
            if (svgDoc) {
                const styleElement = svgDoc.createElementNS('http://www.w3.org/2000/svg', 'style');
                styleElement.textContent = summarySVG_css;
                svgDoc.children[0].appendChild(styleElement);
            }
            setElementSVG(svgDoc);
        }
    }

    useEffect(() => {
        if (!vehicleSVG) return;

        const getBlob = async () => {
            const newUrl = await getUrlBlobByUrl(`${urlOfRepoForSVG}/TOP_${vehicleSVG}.svg`);
            setSummarySVGUrl(newUrl);
        };
        getBlob().catch(console.error);
    }, [vehicleSVG]);

    useEffect(() => {
        if (elementSVG) {
            const oldSelectedElements = elementSVG.querySelectorAll('.repairLight,.repairMedium,.repairHard');
            oldSelectedElements.forEach(element =>
                element.classList.remove(...['repairLight', 'repairMedium', 'repairHard'])
            );

            selectedElements.forEach(element => {
                const damageType = element.damageType;
                let repairTypeClass = '';

                if (damageType.includes('light') || damageType.includes('paint')) {
                    repairTypeClass = 'repairLight';
                } else if (damageType.includes('medium') || damageType.includes('severe')) {
                    repairTypeClass = 'repairMedium';
                } else {
                    repairTypeClass = 'repairHard';
                }

                elementSVG
                    .querySelectorAll(`[id^=${element.elementId}]>path`)
                    .forEach(elem => elem.classList.add(repairTypeClass));
            });
        }
    }, [selectedElements, elementSVG]);

    return (
        <div>
            <object width="100%">
                <embed
                    key={summarySVGUrl}
                    ref={embedRef}
                    style={{
                        transform: 'rotate(-90deg)',
                        width: '150px',
                        height: 'auto',
                        marginTop: '-90px'
                    }}
                    type="image/svg+xml"
                    src={summarySVGUrl}
                    onLoad={onLoadSVG}
                />
            </object>
        </div>
    );
};
