import React, { FC, CSSProperties } from 'react';
import './toolBar.css';

export interface ToolBarType {
    style?: CSSProperties;
    className?: string;
    isMobile?: boolean;
    flexDirection?: 'row' | 'column';
    children: React.ReactNode;
}

export const ToolBar: FC<ToolBarType> = ({
    style,
    className,
    isMobile = false,
    flexDirection = 'column',
    children
}) => {
    if (!children) return null;
    return (
        <div
            className={
                (isMobile ? 'weDat-grapa-tool-bar-mobile ' : 'weDat-grapa-tool-bar ') +
                (flexDirection === 'row' ? 'weDat-grapa-tool-bar-direction-row ' : '') +
                className
            }
            style={style}
        >
            {children}
        </div>
    );
};
