enum Operator {
    EQUAL = 'equal',
    IS_NOT_NULL = 'isNotNull',
    OR = 'or',
    AND = 'and',
    NON_EQUAL = 'nonEqual',
    GREATER = 'greater',
    LESS = 'less',
    FILTER = 'filter',
    PLUS = 'plus',
    MINUS = 'minus',
    MULTIPLY = 'multiply',
    DIVIDE = 'divide'
}

enum FieldType {
    'field' = 'field',
    'string' = 'string',
    'number' = 'number',
    'fieldKey' = 'fieldKey',
    'option' = 'option'
}

export interface IGetters {
    value: FieldValueGetter;
    key: FieldKeyValueGetter;
    option: OptionValueGetter;
}

export type FieldValueGetter = (id: string) => any;
export type FieldKeyValueGetter = (id: string, key: string) => any;
export type OptionValueGetter = (option: string) => any;

export { Operator, FieldType };
