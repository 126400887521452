import React, { FC } from 'react';
import { useStore } from 'effector-react';

import { events, stores } from './store';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { AcceptButton, DeclineButton, Message, PromptButtonsWrapper } from './styles';
import { CheckIcon, CloseIcon } from '@wedat/ui-kit/components/Icons';

// Main component
export const NotificationModal: FC = () => {
    const notification = useStore(stores.notification);

    const acceptPrompt = () => {
        events.answerToPrompt(true);
        events.resetNotification();
    };
    const declinePrompt = () => {
        events.answerToPrompt(false);
        events.resetNotification();
    };

    return (
        <ModalPrimary isOpen={!!notification} title={notification?.title} onDismiss={declinePrompt}>
            <Message>{notification?.message}</Message>
            {notification?.isPrompt && (
                <PromptButtonsWrapper>
                    <AcceptButton onClick={acceptPrompt}>
                        <CheckIcon fill="#fff" width="21" />
                    </AcceptButton>
                    <DeclineButton onClick={declinePrompt}>
                        <CloseIcon fill="#fff" width="17" />
                    </DeclineButton>
                </PromptButtonsWrapper>
            )}
        </ModalPrimary>
    );
};
