import { createApi, createEvent, createStore } from 'effector';
import * as reducers from './reducers';
import { AllInstances, PayloadForInstanceOptionsReceived } from './types';

const instances = createStore<AllInstances>({});
const instancesApi = createApi(instances, reducers.instances);
const instanceOptionsReceived = createEvent<PayloadForInstanceOptionsReceived>();

export const events = {
    ...instancesApi,
    instanceOptionsReceived
};
export const stores = {
    instances
};
