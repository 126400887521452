import { restore, createEffect } from 'effector';
import { API2 } from '@dat/api2';

// effects
const getNotifications = createEffect(API2.bff.notifications.getNotifications);
const removeNotifications = createEffect(API2.bff.notifications.removeNotifications);

// stores
const notifications = restore<BFF.Notification[]>(getNotifications.doneData, []);

export const notificationsEffects = {
    getNotifications,
    removeNotifications
};

export const notificationsStores = {
    notifications
};
