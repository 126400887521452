import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { ObjectInfo } from '../types/svgGrapaTypes';

export function findGroupByDvns(dvns: number[], availableAssemblyGroupsList: AvailableAssemblyGroup[]) {
    let currentAssemblyGroup: AvailableAssemblyGroup | undefined;
    const currentAssemblyGroupObjects: ObjectInfo[] | undefined = [];
    let currentAssemblyGroupObject: ObjectInfo | undefined;
    let currentDVNs: number[] | undefined = [];

    outer: for (let i = 0; i < availableAssemblyGroupsList.length; i += 1) {
        const { objectsInfo } = availableAssemblyGroupsList[i];

        if (!objectsInfo) return null;

        let foundDvnGroup;
        for (let k = 0; k < objectsInfo.length; k += 1) {
            const obj = objectsInfo[k];

            const foundRightDvn = dvns.find(dvn => dvn === obj.dvnRight?.dvn);
            const foundLeftDvn = dvns.find(dvn => dvn === obj.dvnLeft?.dvn);

            if (foundRightDvn) currentDVNs.push(foundRightDvn);
            if (foundLeftDvn) currentDVNs.push(foundLeftDvn);

            if ((obj.dvnRight && foundRightDvn) || foundLeftDvn) {
                currentAssemblyGroupObject = obj;
                currentAssemblyGroup = availableAssemblyGroupsList[i];
                foundDvnGroup = obj.dvnGroup;

                if (obj.dvnGroup === 0) {
                    currentAssemblyGroup = availableAssemblyGroupsList[i];
                    foundDvnGroup = obj.dvnGroup;
                    currentAssemblyGroupObjects.push(obj);
                    break outer;
                }

                break;
            }
        }

        for (let k = 0; k < objectsInfo.length; k += 1) {
            const obj = objectsInfo[k];
            if (obj.dvnGroup === foundDvnGroup) {
                currentAssemblyGroupObjects.push(obj);
            }
        }

        if (currentAssemblyGroup) break;
    }

    currentDVNs = Array.from(new Set(currentDVNs)); // remove double rom currentDVNs

    return {
        currentAssemblyGroup,
        currentAssemblyGroupObjects,
        currentAssemblyGroupObject,
        currentDVNs
    };
}

export function findObjectInfoByDvn(dvn: number, availableAssemblyGroupsList: AvailableAssemblyGroup[]) {
    let result: ObjectInfo | undefined;

    // eslint-disable-next-line
    availableAssemblyGroupsList.find(groupItem =>
        // eslint-disable-next-line
        groupItem.objectsInfo?.find(objInfoItem => {
            if (objInfoItem.dvnLeft?.dvn === dvn || objInfoItem.dvnRight?.dvn === dvn) {
                result = objInfoItem;
                return true;
            }
        })
    );

    return result;
}
