import React, { useEffect, useRef } from 'react';
import { useStore } from 'effector-react';
import { PluginCars, PluginCarsOptions } from '@dat/plugin-cars';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { Container } from './styles';
import { ResultForm } from '@dat/plugin-cars/src/types';
import mask from '../../assets/img/plugin-cars-mask-vertical.svg';
import { vehicleSelectionModalEffects } from '../VehicleSelectionModal/store';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { disableBodyScrollOptions } from '../../constants';

interface VsmPluginCarsProps {
    isPluginCarsOpen?: boolean;
    pluginCarsToggle?: any;
}

export const VsmPluginCars: React.FC<VsmPluginCarsProps> = ({ isPluginCarsOpen, pluginCarsToggle }) => {
    const targetElement = useRef<HTMLElement>(null);
    const country = useStore(sharedTemplateStores.templateSettings.country);

    useEffect(() => {
        if (targetElement.current && !!isPluginCarsOpen) {
            disableBodyScroll(targetElement.current, disableBodyScrollOptions);
        } else {
            clearAllBodyScrollLocks();
        }
    }, [isPluginCarsOpen, targetElement]);

    const pluginCarsCompleted = (res: ResultForm) => {
        pluginCarsToggle();
        vehicleSelectionModalEffects.getVehicleByOCRFx({
            OCRPayload: { mmc: true, upload: res.steps[0].photo },
            country
        });
    };

    const pluginCarsOptions: PluginCarsOptions = {
        selector: '#plugin-cars-vsm-container',
        isComponent: true,
        completeFormCallback: pluginCarsCompleted,
        withSummary: false,
        steps: [
            {
                mask: {
                    img: mask
                },
                photoType: 'vehicle'
            }
        ]
    };

    return isPluginCarsOpen ? (
        <Container id="plugin-cars-vsm-container" ref={targetElement}>
            <PluginCars {...pluginCarsOptions} />
        </Container>
    ) : null;
};
