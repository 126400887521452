import styled from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { Button } from '@wedat/ui-kit/components/Button';

export const TableWrapper = styled.div`
    padding: 20px;
    padding-bottom: 0;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 7px;
    overflow-x: auto;
    overflow-y: hidden;

    ${makeCustomScrollBar()}

    ${media.phone`
        padding: 0;
        background-color: transparent;
    `};
`;

export const TableHeaderButton = styled(Button)`
    width: 200px;
`;

export const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

export const Flag = styled.img`
    width: 24px;
    height: 16px;

    margin-right: 10px;
`;
