import { useStore } from 'effector-react';

import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';

import { WrapperContent } from '../core/styles';

import { Positions } from '../Positions';
import { SumsOfIndividual } from '../SumsOfIndividual';
import { TotalSums } from '../TotalSums';
import { LabourStyled, PaintAndLabourWrapper, PaintworkStyled } from './styles';

export const Desktop = () => {
    const positionsItalyLength = useStore(sharedCalcResultItalyStores.positionsItaly).length;

    return (
        <>
            <WrapperContent>
                <Positions />
            </WrapperContent>
            <WrapperContent>
                <TotalSums />
            </WrapperContent>
            {!!positionsItalyLength && (
                <WrapperContent>
                    <SumsOfIndividual />
                </WrapperContent>
            )}
            {!!positionsItalyLength && (
                <PaintAndLabourWrapper>
                    <PaintworkStyled />
                    <LabourStyled />
                </PaintAndLabourWrapper>
            )}
        </>
    );
};
