// mappingDVNWorkTypeWorkLevelModel

import { createStore, createEffect } from 'effector';
import {
    getMappingDVNWorkTypeWorkLevel,
    GetMappingDVNWorkTypeWorkLevelType
} from '../services/mappingDVNWorkTypeWorkLevelService';

const mappingDVNWorkTypeWorkLevel = createStore<GetMappingDVNWorkTypeWorkLevelType | null>(null);

const getMappingDVNWorkTypeWorkLevelFX = createEffect(getMappingDVNWorkTypeWorkLevel);

mappingDVNWorkTypeWorkLevel.on(getMappingDVNWorkTypeWorkLevelFX.doneData, (_, newData) => newData);

export const mappingDVNWorkTypeWorkLevelModel = {
    stores: {
        mappingDVNWorkTypeWorkLevel
    },
    events: {},
    effects: {
        getMappingDVNWorkTypeWorkLevelFX
    }
};
