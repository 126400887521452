import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { initialFormValues, validationSchema, FORMAT_CHARS } from './constants';
import { formEvents, formStores } from '../../stores/form';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import carMobileImg from '../../assets/car-mobile.svg';
import { i18nStores } from '@dat/shared-models/i18n';

import {
    CarMobileImg,
    Container,
    ErrorText,
    Field,
    Flag,
    Footer,
    FormStyled,
    LogoStyled,
    SelectLanguage,
    StyledText
} from './styles';
import { FormButton, InputField } from '@wedat/ui-kit/Formik';
import { useToggle } from '@dat/core/hooks/useToggle';
import { LanguageSlideUp } from '@dat/smart-components/LanguageSlideUp';
import { LanguageModal } from '@dat/smart-components/LanguageModal';
import { FLAGS_IMAGES } from '@dat/core/constants/flags';
import { useTheme } from 'styled-components';

export const FormAuth: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useMedia(sizes.phoneBig);
    const isError = useStore(formStores.isError);
    const [isOpenSelectModal, toggleSelectOpenModal] = useToggle(false);
    const currentLocale = useStore(i18nStores.locale);
    const theme = useTheme();

    return (
        <Container>
            <>
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema(t)}
                    onSubmit={formEvents.formSubmitted}
                >
                    <FormStyled>
                        <LogoStyled size={isMobile ? 'medium' : 'large'} />
                        {isMobile && <CarMobileImg src={carMobileImg} />}
                        <StyledText font="font22" fontWeight="bold">
                            {t('logIn')}
                        </StyledText>
                        <Field>
                            <InputField
                                id="customerNumberAndUser"
                                name="customerNumberAndUser"
                                mask="9999999/******************************"
                                formatChars={FORMAT_CHARS}
                                label={`${t('inputsPlaceholders.customerNumber')}/${t('inputsPlaceholders.user')}`}
                            />
                        </Field>
                        <Field>
                            <InputField
                                id="password"
                                name="password"
                                type="password"
                                label={t('inputsPlaceholders.password')}
                            />
                        </Field>
                        {isError && <ErrorText color={theme.colors.red['900']}>{t('authorizationFailed')}</ErrorText>}
                        <Footer>
                            <SelectLanguage onClick={toggleSelectOpenModal}>
                                <Flag src={FLAGS_IMAGES[currentLocale]} />
                            </SelectLanguage>

                            {isMobile ? (
                                <LanguageSlideUp isOpen={isOpenSelectModal} closeSlideUpMenu={toggleSelectOpenModal} />
                            ) : (
                                <LanguageModal closeSelectModal={toggleSelectOpenModal} isOpen={isOpenSelectModal} />
                            )}

                            {/* TODO: fix that the button is clickable for 1 sec when field is empty and just after that gets disabled  */}
                            <FormButton submit>{t('formButtonText')}</FormButton>
                        </Footer>
                    </FormStyled>
                </Formik>
            </>
        </Container>
    );
};
