import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
export function useResizer(): boolean {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    function handleSizeChange(): void {
        return setIsMobile(window.innerWidth < 599);
    }

    useEffect(() => {
        window.addEventListener('resize', handleSizeChange);

        return () => {
            window.removeEventListener('resize', handleSizeChange);
        };
    }, [isMobile]);

    return isMobile;
}

export function useWindowSize() {
    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export function useWindowOrientation() {
    function getOrientation() {
        if (window.orientation) {
            return window.orientation;
        }
        if (window.screen && window.screen.orientation && window.screen.orientation.angle) {
            return window.screen.orientation.angle;
        }
        return 0;
    }

    const [windowOrientation, setWindowOrientation] = useState(getOrientation());

    useEffect(() => {
        function handleOrientationchange() {
            setWindowOrientation(getOrientation());
        }

        window.addEventListener('orientationchange', handleOrientationchange);
        return () => window.removeEventListener('orientationchange', handleOrientationchange);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowOrientation;
}

interface ResUseElementSize {
    width?: number;
    height?: number;
}

export function useElementSize({
    showFullscreen,
    width = 0,
    height = 0
}: {
    showFullscreen: any;
    width: number;
    height: number;
}) {
    const targetRef = useRef<HTMLDivElement>(null);

    function getSize() {
        return {
            width: targetRef?.current?.offsetWidth,
            height: targetRef?.current?.offsetHeight
        };
    }

    const [windowSize, setWindowSize] = useState<ResUseElementSize>({ width, height });

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }); // Empty array ensures that effect is only run on mount and unmount

    useEffect(handleResize, [showFullscreen]);

    return { targetRef, windowSize };
}

export const useSize = (target: React.RefObject<HTMLDivElement>) => {
    const [size, setSize] = useState<DOMRect>();

    useLayoutEffect(() => {
        setSize(target?.current?.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry: any) => setSize(entry.contentRect));
    return size;
};
