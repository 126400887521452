import styled from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import { WrapperStyled } from '@wedat/ui-kit/components/Tabs/styles';

import { ArrowIcon } from '@wedat/ui-kit/components/Accordion/styles';

export const ActiveTabContainer = styled.div`
    margin-top: 20px;
`;

export const TabsContainer = styled.div`
    width: 100%;
    background-color: #fff;
    border-bottom: 2px solid #f1f2f4;
    display: flex;
    justify-content: center;

    & button {
        min-width: 200px;

        ${media.phone`
            padding-right: 20px;
            min-width: max-content;
            text-align: left;
            background-color: transparent;
            border-color: transparent;
        `}
    }

    ${WrapperStyled} {
        overflow-x: auto;
        ${makeCustomScrollBar()}

        ${media.phone`
            padding: 10px 10px 0 10px;
        `}
    }
`;

export const Title = styled.h2`
    margin: 20px 0 15px 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.gray_300};

    ${media.phone`
        margin-left: 5px;
    `}
`;

export const Container = styled.div`
    width: 100%;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
`;

export const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

export const AccordionContainer = styled.div`
    flex: 1;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    overflow: auto;
    ${makeCustomScrollBar()}

    ${media.laptop`
        padding-left: 16px;
        padding-right: 16px;
    `}
`;

export const AccrodionItemWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 32px;
    border-radius: 16px;

    ${ArrowIcon} {
        right: 0;
    }

    ${media.laptop`
        padding: 24px
    `}
`;
