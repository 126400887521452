import { attach, createEffect, createEvent, createStore } from 'effector';
import { API2 } from '@dat/api2';
import { EfficiencyMetrics, EfficiencyStatus, GlobalEfficiencyMetrics } from './types';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { getMonday } from '../../utils/getMonday';
import { addDays, addWeeks, startOfMonth, subDays } from 'date-fns';

const weekStartDate = getMonday(new Date());

const detailsEfficiencyMetrics = createStore<EfficiencyMetrics>({ totalTodo: -1, max: 0, perDays: {} });
const countClaimsOverdue = createStore<number>(-1);
const mainEfficiencyMetrics = createStore<GlobalEfficiencyMetrics>({});
const detailsEfficiencyFilters = createStore<{ from: Date; to: Date }>({
    from: weekStartDate,
    to: addWeeks(weekStartDate, 1)
});
const mainEfficiencyFilters = createStore<{ from: Date }>({
    from: startOfMonth(new Date())
});

const fetchTotalClaims = async ({
    templateId,
    expiryFrom,
    expiryTo,
    efficiencyStatus
}: {
    templateId: string;
    expiryFrom?: Date;
    expiryTo?: Date;
    efficiencyStatus?: EfficiencyStatus;
}) => {
    const filter: DAT2.Internal.ListClaimsFilters = {};

    if (expiryFrom || expiryTo) {
        filter[`${templateId}#expiryDate`] = {
            low: expiryFrom?.getTime(),
            high: expiryTo?.getTime()
        };
    }
    if (efficiencyStatus !== undefined) {
        filter[`${templateId}#expiryStatus`] = { low: efficiencyStatus, high: efficiencyStatus };
    }

    return (
        await API2.myClaimInternal.listClaims({
            params: {
                count: 1,
                offset: 0,
                searchIn: ['id', 'Date_expiryDate', 'Integer_expiryStatus'],
                initialLoad: false
            },
            data: {
                filter
            },
            templateId: templateId
        })
    ).total;
};

const loadTotalClaimsOverdueFx = attach({
    source: sharedTemplateStores.templateId,
    effect: templateId => {
        let overdueDate = new Date();
        overdueDate.setHours(0, 0, 0, 0);
        overdueDate = subDays(overdueDate, 1);

        return fetchTotalClaims({
            templateId: templateId?.toString() || '',
            expiryTo: overdueDate,
            efficiencyStatus: EfficiencyStatus.NOT_COMPLETED
        });
    }
});

const loadDetailsEfficiencyMetricsFx = createEffect(
    async ({
        from,
        to,
        templateId
    }: {
        from: Date;
        to: Date;
        templateId: number | null;
    }): Promise<EfficiencyMetrics> => {
        if (!templateId) throw Error('templateId cannot be undefined');
        let result;
        let offset = 0;
        const count = 100; // max possible count
        const claims: DAT2.Internal.ClaimItem[] = [];

        do {
            result = await API2.myClaimInternal.listClaims({
                params: {
                    count,
                    offset,
                    searchIn: ['id', 'Date_expiryDate', 'Integer_expiryStatus'],
                    initialLoad: false
                },
                data: {
                    filter: {
                        [`${templateId}#expiryDate`]: {
                            low: from.getTime(),
                            high: to.getTime()
                        }
                    }
                },
                templateId: templateId.toString()
            });
            offset += result.list.length;
            result.list.forEach(v => claims.push(v));
        } while (result.total > count && result?.list?.length === count);

        const data: EfficiencyMetrics = {
            totalTodo: 0,
            max: 0,
            perDays: {}
        };

        for (let day = from; day.getTime() < to.getTime(); day = addDays(day, 1)) {
            data.perDays[day.getTime()] = { due: 0, completed: 0 };
        }

        claims.forEach(({ templateFieldAndValue }) => {
            if (!templateFieldAndValue?.Date_expiryDate || templateFieldAndValue?.Integer_expiryStatus === undefined) {
                return;
            }
            const date = new Date(Number(templateFieldAndValue.Date_expiryDate));
            date.setHours(0, 0, 0, 0);
            const expiryDate = date.getTime();

            if (!data.perDays[expiryDate]) {
                return;
            }

            data.perDays[expiryDate].due++;
            if (data.perDays[expiryDate].due > data.max) {
                data.max = data.perDays[expiryDate].due;
            }
            if (data.totalTodo === undefined) {
                data.totalTodo = 0;
            }
            data.totalTodo++;

            if (templateFieldAndValue.Integer_expiryStatus > 0) {
                data.perDays[expiryDate].completed++;
            }
        });

        return data;
    }
);

const detailsEfficiencyFiltersChangeFx = attach({
    source: [detailsEfficiencyFilters, sharedTemplateStores.templateId],
    mapParams: (_params, [{ from, to }, templateId]) => ({
        from,
        to,
        templateId
    }),
    effect: loadDetailsEfficiencyMetricsFx
});

const loadMainEfficiencyMetricsFx = createEffect(
    async ({ from, templateId }: { from: Date; templateId: number | null }): Promise<GlobalEfficiencyMetrics> => {
        if (!templateId) throw Error('templateId cannot be undefined');
        return {
            totalCompletedInTime: await fetchTotalClaims({
                expiryFrom: from,
                templateId: templateId?.toString(),
                efficiencyStatus: EfficiencyStatus.COMPLETED_ON_TIME
            }),
            totalCompletedNotInTime: await fetchTotalClaims({
                expiryFrom: from,
                templateId: templateId?.toString(),
                efficiencyStatus: EfficiencyStatus.COMPLETED_NOT_ON_TIME
            })
        };
    }
);

const mainEfficiencyFiltersChangeFx = attach({
    source: [mainEfficiencyFilters, sharedTemplateStores.templateId],
    mapParams: (_params, [{ from }, templateId]) => ({
        from,
        templateId
    }),
    effect: loadMainEfficiencyMetricsFx
});

const setEfficiencyDateFilter = createEvent<{ from: Date; to: Date }>();

const selected = createStore<{
    date?: number;
    claimsOverdue: boolean;
}>({ claimsOverdue: false });
const setSelectedDate = createEvent<number>();
const resetSelectedDate = createEvent<void>();
const toggleSelectedClaimOverdue = createEvent<void>();

const reloadEfficiencyMetrics = createEvent<void>();

detailsEfficiencyMetrics.on(detailsEfficiencyFiltersChangeFx.doneData, (_, state) => state);
detailsEfficiencyFilters.on(setEfficiencyDateFilter, (_, state) => state);
mainEfficiencyMetrics.on(mainEfficiencyFiltersChangeFx.doneData, (_, state) => state);
countClaimsOverdue.on(loadTotalClaimsOverdueFx.doneData, (_, state) => state);

selected
    .on(setSelectedDate, (_, state) => ({ date: state, claimsOverdue: false }))
    .on(resetSelectedDate, (_, _state) => ({ claimsOverdue: false }))
    .on(toggleSelectedClaimOverdue, current => ({ claimsOverdue: !current.claimsOverdue }));

export const efficiencyEffects = {
    detailsEfficiencyFiltersChangeFx,
    mainEfficiencyFiltersChangeFx,
    loadTotalClaimsOverdueFx
};

export const efficiencyEvents = {
    setEfficiencyDateFilter,
    setSelectedDate,
    resetSelectedDate,
    toggleSelectedClaimOverdue,
    reloadEfficiencyMetrics
};

export const efficiencyStores = {
    countClaimsOverdue,
    detailsEfficiencyMetrics,
    mainEfficiencyMetrics,
    detailsEfficiencyFilters,
    selected
};
