import React from 'react';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { pluginEvents } from './stores/plugin';
import { PluginOptions } from './types/plugin';
import { Main } from './components/Main';

interface Props {
    options: PluginOptions;
}

const App: React.FC<Props> = ({ options }) => (
    <PluginProvider name="scheduler" options={options} onInit={pluginEvents.initPlugin}>
        <Main withoutApi={options?.withoutApi} />
    </PluginProvider>
);

export default App;
