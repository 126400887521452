import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modalsEvents, modalsStores } from '../../stores/modals';
import { vehicleIdentificationStores } from '../../stores/vehicleIdentification';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Equipment, EquipmentPluginOptions } from '@dat/equipment';
import { useMedia } from '@dat/core/hooks/useMedia';

import { CurrentFormikValues } from '../../types/formik';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { equipmentEvents } from '../../stores/equipment';
import { PluginResult } from '@dat/equipment/src/types/plugin';

export const EquipmentModal = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isEquipmentModalOpen);
    const vinEquipmentIds = useStore(vehicleIdentificationStores.vinEquipmentIds);
    const { values, submitForm } = useFormikContext<CurrentFormikValues>();
    const { datECode, constructionPeriod, container } = values;
    const [options, setOptions] = useState<EquipmentPluginOptions | null>(null);
    const isMobile = useMedia(sizes.laptop);

    useEffect(
        () =>
            modalsEvents.openEquipmentModal.watch(() => {
                if (constructionPeriod) {
                    setOptions({
                        onComplete: (result: PluginResult) => {
                            equipmentEvents.setExistingEquipmentStore(result);
                        },
                        requestData: { datECode, constructionTime: +constructionPeriod, container },
                        vinEquipmentIds,
                        isComponent: true,
                        inVSM: true,
                        vin: values.vin
                    });
                } else {
                    setOptions(null);
                }
            }),
        [datECode, constructionPeriod, container, vinEquipmentIds, submitForm, values.vin]
    );

    if (!options) {
        return null;
    }

    return (
        <ModalPrimary
            title={t('equipment')}
            bodyNoPadding
            isOpen={isOpen}
            onDismiss={modalsEvents.closeEquipmentModal}
            bodyHeight="90vh"
            bodyWidth={isMobile ? '100%' : '90vw'}
            hideHeader
            fullFill={isMobile}
        >
            <Equipment options={options} closeEquipmentModal={modalsEvents.closeEquipmentModal} />
        </ModalPrimary>
    );
};
