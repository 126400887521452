import { FC, useEffect, useState, useRef, ReactPortal } from 'react';
import ReactDOM from 'react-dom';

interface PortalProp {
    el?: string;
    id?: string;
    className?: string;
}

export const Portal: FC<PortalProp> = ({ children, className = '', el, id = '' }) => {
    const [container] = useState(() =>
        el
            ? // This will be executed only on the initial render
              // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
              document.createElement(el)
            : undefined
    );

    useEffect(() => {
        if (container && !id) {
            container.classList.add(className);
            document.body.appendChild(container);
            return () => {
                document.body.removeChild(container);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const portalRef = useRef<ReactPortal | null>(null);

    // React.Portal can't unmount, that's why when rerendering children is duplicated.
    // Because should watch amount chldren. If children is already mounting return null.
    useEffect(() => {
        if (id) {
            // Id for query selector
            const portalContentId = `portal-${id}`;
            // Wrapper for correct counting children
            const getChildrenContent = () => <div id={portalContentId}>{children}</div>;

            const domElement = document.querySelector(`#${id}`);
            // All the same children
            const childrenNodeList = domElement?.querySelectorAll(`#${portalContentId}`);

            if (childrenNodeList?.length === 0) {
                // First children mounting
                portalRef.current = domElement && ReactDOM.createPortal(getChildrenContent(), domElement);
            } else {
                // If children is already mounting return null
                portalRef.current = null;
            }
        }
        // don't add children to dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!!id) {
        return portalRef.current;
    }

    return ReactDOM.createPortal(children, container || document.body);
};
