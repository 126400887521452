import styled from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

import { RowStyled } from '@dat/form-builder/src/components/RowContent/styles';
import { TextAreaWrapperStyled } from '@wedat/ui-kit/components/TextArea/styles';
import { Wrapper as GooglePlacesWrapper } from '@dat/smart-components/GooglePlaces/styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
    padding-right: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: auto;
    overflow-x: hidden;

    ${makeCustomScrollBar()}

    @media (max-width: 1100px) {
        padding: 0;
    }

    ${media.tablet`
        width: 100%;
        min-width: 350px;
    `}

    ${media.phone`
        min-width: unset;
        margin-bottom: 0;
    `}
`;

export const BackButton = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray_300};
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    user-select: none;

    svg {
        width: 12px;
        height: 18px;
        margin-right: 5px;
    }
`;

const $indent = 10;

export const FormStyled = styled.div`
    padding: 20px 0;
    width: 100%;
    height: 100%;

    @media (min-width: 1200px) {
        ${RowStyled} {
            width: calc(50% - ${$indent}px);
        }

        ${TextAreaWrapperStyled}, ${GooglePlacesWrapper} {
            width: calc(200% + ${$indent * 2}px);
        }
    }

    ${media.tablet`
        height: auto;
        display: flex;
        justify-content: center;
    `}
`;
