import { usersListEffects, usersListEvents, usersListStores } from './index';
import { guard } from 'effector';

const { fetchAvailableUsers } = usersListEffects;
const { availableUsers } = usersListStores;
const { loadMoreAvailableUsers } = usersListEvents;

guard({
    source: availableUsers,
    clock: loadMoreAvailableUsers,
    filter: availableUsers => !availableUsers.length,
    target: fetchAvailableUsers
});
