import { createEffect, restore } from 'effector';

import { API2 } from '@dat/api2';
import { getParsedArraySafe } from '@dat/api2/utils';
import { createPriceDatesSelectOptions } from '../../../utils/selectsOptions';

const getPriceDatesFx = createEffect(async (payload: DAT2.Request.GetContractPriceGenerations) => {
    const response = await API2.calculatePro.getContractPriceGenerations(payload);

    return getParsedArraySafe(response.priceDates?.priceDate);
});
const priceDates = restore(getPriceDatesFx, []);
const selectOptions = priceDates.map(createPriceDatesSelectOptions);

export const priceDatesEffects = {
    getPriceDatesFx
};
export const priceDatesStores = {
    priceDates,
    selectOptions
};
