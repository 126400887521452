import { useStore } from 'effector-react';
import { containedPluginsStores } from '../../../stores/containedPlugins';
import { Container, FormWrapper, ClaimLeftPart, ClaimRightPart } from './styles';
import { FormBuilder } from '@dat/form-builder';
import { Modals } from './Modals';
import { GalleryModal } from '@dat/smart-components/GalleryModal';
import { useEffect } from 'react';
import { blockingModalEffects, blockingModalCombines } from '@dat/shared-models/blockingModal';
import { sharedUserStores } from '@dat/shared-models/user';
import { contractStores } from '@dat/shared-models/contract';
import { UnavailableClaim } from '../UnavailableClaim';

export const OpeningPage = () => {
    const formBuilderPluginOptions = useStore(containedPluginsStores.formBuilderPluginOptions);
    const vehicleSelectionPluginCarsOpened = useStore(containedPluginsStores.vehicleSelectionPluginCarsOpened);
    const customerNumber = useStore(sharedUserStores.customerNumber);
    const username = useStore(sharedUserStores.username);
    const complexTemplateData = useStore(contractStores.complexTemplateData);
    const { updateBlockingStatusFx, unblockUserFx } = blockingModalEffects;
    const isDisabled = useStore(blockingModalCombines.isDisabled);
    const contractId = useStore(contractStores.contractId);

    useEffect(() => {
        if (!isNaN(Number(contractId)) && Number(contractId) !== 0) {
            updateBlockingStatusFx({ customerNumber, username, complexTemplateData });
        }
        return () => {
            if (!isNaN(Number(contractId)) && Number(contractId) !== 0) {
                unblockUserFx();
            }
        };
        // I added ignore, because with dependencies useEffect doesn't work
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isDisabled ? (
        <UnavailableClaim />
    ) : (
        <Container>
            <ClaimLeftPart>
                <Modals />
            </ClaimLeftPart>
            {!vehicleSelectionPluginCarsOpened && (
                <>
                    <ClaimRightPart>
                        <FormWrapper>
                            <FormBuilder options={formBuilderPluginOptions} />
                        </FormWrapper>
                    </ClaimRightPart>
                    <GalleryModal />
                </>
            )}
        </Container>
    );
};
