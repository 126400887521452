import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';

import { useStore } from 'effector-react';
import { contractStores } from '@dat/shared-models/contract';
import { appointmentEvents } from '../../stores/appointment';

import { ROUTES, SUB_ROUTES_PATH_NAMES } from '../../constants/router';
import { SCHEDULER_MENU_OPTIONS, SCHEDULER_MENU_ROUTES } from '../../constants/scheduler';

import { Text } from '@wedat/ui-kit/components/Text';
import { Dropdown } from '@wedat/ui-kit/components/Dropdown';

import { Container, Item } from './styles';
import { CalendarIcon } from '@wedat/ui-kit/components/Icons';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

interface Props {
    isOpen?: boolean;
    callbackClose?: () => void;
    side?: 'left' | 'right';
}

export const SchedulerMenu: FC<Props> = ({ isOpen, callbackClose, side = 'right' }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const isTablet = useMedia(sizes.tablet);

    const contractId = useStore(contractStores.contractId);
    const [isListVisible, setIsListVisible] = useState(!!isOpen);
    const containerRef = useRef(null);

    const options = [
        SCHEDULER_MENU_OPTIONS.createAppointment,
        SCHEDULER_MENU_OPTIONS.createExpiryClaim,
        SCHEDULER_MENU_OPTIONS.scheduler
    ];

    const handleMenuItemClick = (option: string) => {
        if (option === SCHEDULER_MENU_OPTIONS.scheduler && contractId !== 0) {
            return history.push(`/claims/${contractId}/${SUB_ROUTES_PATH_NAMES.claim.scheduler}`);
        } else if (option === SCHEDULER_MENU_OPTIONS.scheduler && contractId === 0) {
            return history.push(`/${SUB_ROUTES_PATH_NAMES.claim.scheduler}`);
        }

        if (option === SCHEDULER_MENU_OPTIONS.createExpiryClaim) {
            return appointmentEvents.toggleIsExpiryPopupOpen();
        }

        appointmentEvents.toggleIsAppointmentPopupOpen();
    };

    useClickOutside(containerRef, () => {
        if (!isTablet) {
            setIsListVisible(false);
        }
    });

    // TODO: fix this, we need default only props, without effect
    useEffect(() => {
        setIsListVisible(!!isOpen);
    }, [isOpen]);

    const isVisibleItem = useCallback(
        (option: string) => {
            const { createExpiryClaim } = SCHEDULER_MENU_OPTIONS;
            const isInClaimOrScheduler =
                option === createExpiryClaim && SCHEDULER_MENU_ROUTES.every(i => history.location.pathname.includes(i));
            const isFromInbox = contractId === 0;

            if (isInClaimOrScheduler || isFromInbox) {
                return true;
            }
            return false;
        },
        [contractId, history?.location.pathname]
    );

    if (isTablet) {
        return (
            <SlideUpMenu
                isOpen={isListVisible}
                closeSlideUpMenu={() => {
                    setIsListVisible(false);
                    callbackClose?.();
                }}
            >
                {options?.map(option => (
                    <Item key={option} onClick={() => handleMenuItemClick(option)} isVisible={isVisibleItem(option)}>
                        <Text font="note">{t(option)}</Text>
                    </Item>
                ))}
            </SlideUpMenu>
        );
    }
    const handleClickCalendar = () => {
        contractId !== 0 ? setIsListVisible(!isListVisible) : history.push(ROUTES.inbox.scheduler);
    };
    return (
        <Container ref={containerRef} onClick={handleClickCalendar}>
            <CalendarIcon />

            <Dropdown side={side} isOpen={isListVisible}>
                {options?.map(option => (
                    <Item key={option} onClick={() => handleMenuItemClick(option)} isVisible={isVisibleItem(option)}>
                        <Text font="note">{t(option)}</Text>
                    </Item>
                ))}
            </Dropdown>
        </Container>
    );
};
