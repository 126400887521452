import React, { FC, useRef } from 'react';
import { useField } from 'formik';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useInstance } from '../../../hooks/useInstance';
import { useFoundSubjects } from '../hooks/useFoundSubjects';
import { useScrollToAddedSubject } from '../hooks/useScrollToAddedSubject';
import { LIST_ITEM_HEIGHT, LIST_TEXT_HEIGHT } from '../constants';

import { Container, Text } from './styles';
import { ListItem } from '../ListItem';

export const List: FC = () => {
    const { currentSubjects, networkOwnerId, readOnly } = useInstance('currentSubjects', 'networkOwnerId', 'readOnly');
    const [{ value: search }] = useField('search');

    const foundSubjects = useFoundSubjects();
    const noMatchedResults = !!search && !foundSubjects.length;
    const addEnabled = !currentSubjects.length && !readOnly && !networkOwnerId && !search;

    const listRef = useRef<FixedSizeList>(null);
    useScrollToAddedSubject(listRef);

    return (
        <Container>
            {noMatchedResults && <Text>No matched results</Text>}
            {addEnabled && <Text>Add your first contact</Text>}

            <AutoSizer>
                {({ width, height }) => (
                    <FixedSizeList
                        ref={listRef}
                        className="list"
                        itemData={foundSubjects}
                        itemCount={foundSubjects.length}
                        itemSize={LIST_ITEM_HEIGHT}
                        width={width}
                        height={height - LIST_TEXT_HEIGHT}
                    >
                        {ListItem}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Container>
    );
};
