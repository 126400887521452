export const elementsSVG_css = `

g[id^='DATID_'] > path {
    fill: white !important;
    fill-opacity: 0.0 !important;
}

g[id^='DATID_'] > path:hover {
    /* <!--stroke-width: 3 !important;--> */
    /* <!--stroke: blue !important;--> */
    fill: black !important;
    fill-opacity: 0.7 !important;
}

g[id^='DATID_'] > path.selectedElements {
    /* <!--stroke-width: 5 !important;--> */
    /* <!--stroke-opacity: 0.3 !important;--> */
    /* <!--stroke: red !important;--> */
    /* <!-- fill: red !important; --> */
    fill: #1890ff !important;
    fill-opacity: 0.7 !important;
    /* <!-- fill-opacity: 0.3 !important; --> */
}

g[id^='DATID_'] > path.selectedElements:hover {
    /* <!--stroke-width: 5 !important;--> */
    /* <!--stroke-opacity: 0.3 !important;--> */
    /* <!--stroke: red !important;--> */
    /* <!-- fill: red !important; --> */
    fill: black !important;
    fill-opacity: 0.7 !important;
    /* <!-- fill-opacity: 0.3 !important; --> */
}

g[id^='DATID_'] > path.markElement {
    stroke-width: 2.5 !important;
    stroke: black !important;
    fill: black !important;
    fill-opacity: 0.8 !important;
    /* <!-- fill: red !important; */
    /* fill-opacity: 0.7 !important; --> */
}


`;
