export interface ObjectInfo extends ObjectInfoSVG {
    datid: string;
    class: string;
    titleMenu: string;
    tooltipLang: string;
}

export interface DatidInfo {
    datid: string;
    class: string;
    objectInfoSVG: ObjectInfoSVG[];
}

export interface ObjectInfoSVG {
    attr: { position: string };
    title?: Title;
    tooltip?: Tooltip;
    dvnGroup?: number;
    kzSeite?: KzSeite;
    dvnLeft?: Dvn;
    dvnRight?: Dvn;
}

export interface Dvn {
    dvn: number;
    etBauart: number;
    etBauartOptKz: number;
    rcs: RCS;
}

export interface RCS {
    rc: RC[];
}

export enum RC {
    A = 'A',
    E = 'E',
    I = 'I',
    L = 'L',
    M = 'M',
    P = 'P',
    Z = 'Z',
    S = 'S',
    T = 'T',
    U = 'U',
    W = 'W'
}

export enum KzSeite {
    E = 'e',
    EH = 'e,h',
    H = 'h'
}

export interface Title {
    attr: TitleAttr;
    langs: TitleLangs;
}

export interface TitleAttr {
    uebid: string;
    stnr: string;
}

export interface TitleLangs {
    lang: LangClass[];
}

export interface LangClass {
    '#text': string;
    attr: LangAttr;
}

export interface LangAttr {
    languageCode: string;
    countryCode: string;
    stnr: string;
}

export interface Tooltip {
    attr: TitleAttr;
    langs: TooltipLangs;
}

export interface TooltipLangs {
    lang: LangClass[];
}
