import { events, effects } from './store';
import { createNotifyingEffect } from './createNotifyingEffect';
import { subscribeEffectsToNotificationModal } from './subscribeEffectsToNotificationModal';
import { NotificationModal } from './MainComponent';

const { showNotification, showError, showInfoMessage } = events;
const { getPromptAnswer } = effects;

export {
    showNotification,
    showError,
    showInfoMessage,
    getPromptAnswer,
    createNotifyingEffect,
    subscribeEffectsToNotificationModal,
    NotificationModal
};
