import styled from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { CALENDAR_HEIGHT } from './constants/constants';

export const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;

    ${media.laptopSmall`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const TabsWrapper = styled.div`
    button {
        height: 32px;
        font-size: 14px;
    }

    ${media.laptopSmall`
         margin-bottom: 12px
    `}
`;

export const DaySwitchSelection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;

    button {
        border: 1px solid ${({ theme }) => theme.colors.blueDark};
    }

    ${media.laptopSmall`
         justify-content: flex-start;
    `}
`;

export const ArrowWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.blueDark};
    border-radius: 50%;
    cursor: pointer;
    margin-right: 12px;

    svg {
        width: 24px;
    }
`;

export const ArrowWrapperRotated = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.blueDark};
    border-radius: 50%;
    cursor: pointer;
    margin-left: 12px;

    svg {
        width: 24px;
        transform: rotate(180deg);
    }
`;

export const SchedulerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .tui-calendar-react-root {
        width: 100%;
    }
`;

export const UsersListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;

    width: 100%;
    height: ${CALENDAR_HEIGHT};
    max-width: 280px;
    padding-bottom: 44px;

    border-top: 1px solid ${({ theme }) => theme.colors.gray['200']};
    border-right: 1px solid ${({ theme }) => theme.colors.gray['200']};
    background-color: ${({ theme }) => theme.colors.white};

    overflow-y: auto;
    overflow-x: hidden;

    ${makeCustomScrollBar()}
`;

export const SelectedUserStyled = styled.div`
    padding: 16px;
`;

export const SelectedUserMobileStyled = styled.div`
    padding: 24px;
`;

export const DropdownWrapper = styled.div`
    position: absolute;
`;
