import React from 'react';

const ErrorToast = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.9248 0C7.144 0 0 7.1776 0 16C0 24.8224 7.1776 32 16 32C24.8224 32 32 24.8224 32 16C32 7.1776 24.7888 0 15.9248 0ZM16 28.8C8.9424 28.8 3.2 23.0576 3.2 16C3.2 8.9424 8.9072 3.2 15.9248 3.2C23.0256 3.2 28.8 8.9424 28.8 16C28.8 23.0576 23.0576 28.8 16 28.8Z"
            fill="currentColor"
        />
        <path d="M14.3999 8H17.5999V19.2H14.3999V8ZM14.3999 20.8H17.5999V24H14.3999V20.8Z" fill="currentColor" />
    </svg>
);

export default ErrorToast;
