export const retrieveField = ({ customTemplateData }: DAT2.ContractFromGetContract, field: string) => {
    if (customTemplateData?.entry && Array.isArray(customTemplateData?.entry)) {
        const foundField = customTemplateData?.entry.find(({ key }) => key === field);

        if (foundField) {
            return String(foundField.value._text);
        }
    }
    return '';
};
