import React from 'react';
import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginProps } from './types';

const App: React.FC<PluginProps> = options => (
    <PluginProvider name="plugin-cars" options={options} noAuth>
        <Main {...options} />
    </PluginProvider>
);

export default App;
