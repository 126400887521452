import styled from 'styled-components/macro';

interface Props {
    width?: string | number;
    height?: string | number;
    fit?: boolean;
}

export const SeparatorStyled = styled.div<Props>`
    flex: ${({ fit }) => (fit ? '1 1 auto' : 'none')};
    ${({ width }) => `width: ${width}px`};
    ${({ height }) => `height: ${height}px`};
`;
