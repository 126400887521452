import { useStore } from 'effector-react';

import { dossierStores } from '../../../stores/dossier';
import { useShouldShowGrossValues } from '../../../hooks/useShouldShowGrossValues';

export const useDealerShipSalesValue = () => {
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);
    const shouldShowGrossValues = useShouldShowGrossValues();

    let ConditionSubTotal2: number;
    let RepairCosts: number;

    if (shouldShowGrossValues) {
        ConditionSubTotal2 = dossierWithExtractedTextValues.Valuation?.Condition?.ConditionSubTotal2Gross || 0;
        RepairCosts = dossierWithExtractedTextValues.Valuation?.Condition?.RepairCostsGross || 0;
    } else {
        ConditionSubTotal2 = dossierWithExtractedTextValues.Valuation?.Condition?.ConditionSubTotal2 || 0;
        RepairCosts = dossierWithExtractedTextValues.Valuation?.Condition?.RepairCosts || 0;
    }

    return ConditionSubTotal2 - RepairCosts;
};
