import './fonts.css';

const NOTO_SANS_LANGUAGES = ['ru-RU', 'el-GR'];

const getBaseTokens = (locale: string) => {
    const fontFamily = NOTO_SANS_LANGUAGES.includes(locale) ? 'Noto Sans' : 'Noto Sans TC';

    return {
        colors: {
            // colors between comment lines are deprecated
            //---------------------//
            white: '#FFFFFF',
            black: '#0D0D0D',
            blueDark: '#103366',
            blueLight: '#E3F1FF',
            gray_10: '#F1F2F4',
            gray_40: '#C9CFDC',
            gray_60: '#ACB6CB',
            gray_100: '#505050',
            gray_200: '#667185',
            gray_300: '#465064',
            //---------------------//
            dustBlue: {
                50: '#edf0fc',
                100: '#d3dceb',
                200: '#bac4d8',
                300: '#a0acc4',
                400: '#8b98b3',
                500: '#7786a4',
                600: '#687792',
                700: '#56637a',
                800: '#465064',
                900: '#323B4C'
            },
            gray: {
                50: '#fafafa',
                100: '#f6f6f6',
                200: '#f0f0f0',
                300: '#e2e2e2',
                400: '#c0c0c0',
                500: '#a1a1a1',
                600: '#787878',
                700: '#636363',
                800: '#444444 ',
                900: '#232323'
            },
            blue: {
                50: '#e3f2ff',
                100: '#bcdeff',
                200: '#90c9ff',
                300: '#63b4ff',
                400: '#42a4ff',
                500: '#2594fe',
                600: '#2786ef',
                700: '#2574db',
                800: '#2462c9 ',
                900: '#2243a8'
            },
            green: {
                50: '#e8f5ea',
                100: '#c8e7ca',
                200: '#a6d8a9',
                300: '#82ca87',
                400: '#66be6d',
                500: '#4cb253',
                600: '#43a34a',
                700: '#38913f',
                800: '#2e8035',
                900: '#1b6122'
            },
            yellow: {
                50: '#FFF9E0',
                100: '#FFEEB1',
                200: '#FFE47D',
                300: '#FFDB44',
                400: '#FFD100',
                500: '#FFC800',
                600: '#FFB900',
                700: '#FFA500',
                800: '#FF9300',
                900: '#FF7200',
                10: '#FFB444' // TODO: color from old styles, replace to new palette
            },
            red: {
                50: '#fbe9e7',
                100: '#ffcbbc',
                200: '#ffaa90',
                300: '#ff8964',
                400: '#ff6e42',
                500: '#ff5421',
                600: '#f44e1d',
                700: '#e64718',
                800: '#d84014',
                900: '#bf330a',
                10: '#FF6F42' // TODO: color from old styles, replace to new palette
            },
            deepBlue: {
                50: '#e5e9ef',
                100: '#bdc7d9',
                200: '#93a3c0',
                300: '#6a7fa6',
                400: '#4b6595',
                500: '#274c86',
                600: '#21457e',
                700: '#173c72',
                800: '#103366',
                900: '#07224f'
            },
            orange: {
                50: '#fff3df',
                100: '#ffdfaf',
                200: '#ffca7b',
                300: '#ffb444',
                400: '#ffa413',
                500: '#ff9400',
                600: '#fb8800',
                700: '#f57700',
                800: '#ef6700',
                900: '#e74a00'
            }
        },
        typography: {
            heading: {
                font: `600 36px/54px ${fontFamily}`
            },
            semiHeading: {
                font: `500 24px/36px ${fontFamily}`
            },
            mobileHeader: {
                font: `700 20px/28px ${fontFamily}`
            },
            defaultMedium: {
                font: `500 18px/26px ${fontFamily}`
            },
            defaultBold: {
                font: `700 18px/26px ${fontFamily}`
            },
            defaultText: {
                font: `400 18px/26px ${fontFamily}`
            },
            note: {
                font: `400 16px/24px ${fontFamily}`
            },
            noteBold: {
                font: `700 16px/24px ${fontFamily}`
            },
            footnote: {
                font: `400 14px/20px ${fontFamily}`
            },
            footnoteBold: {
                font: `700 14px/20px ${fontFamily}`
            },
            font12: {
                font: `400 12px/18px ${fontFamily}`
            },
            font22: {
                font: `400 22px/32px ${fontFamily}`
            }
        }
    };
};

export { getBaseTokens };
