import { FC, ReactNode, useMemo } from 'react';
import { Table } from '@wedat/ui-kit/components/Table';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@wedat/ui-kit/assets/Icon/Delete';
import EditIcon from '@wedat/ui-kit/assets/Icon/Edit';
import { EditButton, DeleteButton } from './styles';
import { Row } from 'react-table';
import { partSelectionEvents } from '@dat/shared-models/partSelection';

interface TableProps {
    data: DAT2.Internal.PartData | null;
    onEdit: (data: DAT2.Internal.Rate) => void;
    handlePromptDismiss: () => void;
}

interface TableData {
    country?: string;
    repairType?: string;
    price?: number;
    workingTime?: number;
    quantity?: number;
    description?: string;
    id: number;
    edit?: ReactNode;
    delete?: ReactNode;
}

export const PartSelectionTable: FC<TableProps> = ({ data, onEdit, handlePromptDismiss }) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                Header: t('table.columns.headings.country') as string,
                accessor: 'country'
            },
            {
                Header: t('table.columns.headings.repairType') as string,
                accessor: 'repairType',
                Cell: ({
                    row: {
                        original: { repairType = 'notFound' }
                    }
                }) => t(`table.${repairType}`)
            },
            {
                Header: t('table.columns.headings.price') as string,
                accessor: 'price'
            },
            {
                Header: t('table.columns.headings.workingTime') as string,
                accessor: 'workingTime'
            },
            {
                Header: t('table.columns.headings.quantity') as string,
                accessor: 'quantity'
            },
            {
                Header: t('table.columns.headings.description') as string,
                accessor: 'description'
            },
            {
                Header: t('table.columns.headings.edit') as string,
                accessor: 'edit',
                Cell: ({
                    row: {
                        original: { id }
                    }
                }: any) => (
                    <EditButton
                        typeStyle={{ type: 'transparent' }}
                        onClick={() => {
                            onEdit(id);
                            partSelectionEvents.setIdEvent(id);
                        }}
                    >
                        <EditIcon width="18" height="18" />
                    </EditButton>
                )
            },
            {
                Header: t('table.columns.headings.delete') as string,
                accessor: 'delete',
                Cell: ({ row }: { row: Row<TableData> }) => (
                    <DeleteButton
                        typeStyle={{ type: 'transparent' }}
                        onClick={() => {
                            handlePromptDismiss();
                            partSelectionEvents.setIdEvent(row.original.id);
                        }}
                    >
                        <DeleteIcon />
                    </DeleteButton>
                )
            }
        ],
        [t, onEdit, handlePromptDismiss]
    );

    return <Table columns={columns} data={data ? data.list : []} />;
};
