import { FC } from 'react';
import { Step } from '../../components/Step';
import { Stepper } from '../../components/Stepper/stepper';
import { Props } from '../../types/config';

export const StepperPage: FC<Props> = ({ config }) => (
    <Stepper
        steps={config.map(({ title, plugin, options }) => ({
            title,
            options,
            plugin,
            element: stepProps => <Step plugin={plugin} options={options} {...stepProps} />
        }))}
    />
);
