import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';
import { InputField } from '@wedat/ui-kit/Formik';
import { Form } from 'formik';

interface ContainerProps {
    isFormVisibleOnMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 400px;
    height: 100%;
    margin-right: 20px;
    flex-direction: column;
    padding: 0 20px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ isFormVisibleOnMobile }) => (isFormVisibleOnMobile ? 'opacity: 0;' : 'opacity: 1;')}

    ${media.phone`
        min-width: unset;
        width: 100%;
    `}
`;

export const StyledInputField = styled(InputField)`
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const ButtonStyled = styled(Button)`
    min-height: 50px;
    margin-bottom: 30px;

    &:hover,
    &:focus {
        svg {
            path {
                fill: ${({ theme }) => theme.colors.blueDark};
            }
        }
    }
`;

export const FormStyled = styled(Form)`
    height: 100%;
    padding: 20px 0;

    ${media.tablet`
        width: 100%;
    `}
`;
