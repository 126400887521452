import styled, { css } from 'styled-components';

interface TrProps {
    selected?: boolean;
    outlineLast?: boolean;
}

interface ColProps {
    mobileGridColAmount?: number;
}

interface TdProps {
    disabled?: boolean;
    outlineLast?: boolean;
}

interface ThProps {
    verticalAlignTopTh?: boolean;
}

export const TableContainer = styled.table`
    border-spacing: 0;
    width: 100%;
`;

export const THead = styled.thead`
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;
export const TBody = styled.tbody``;

export const Th = styled.th<ThProps>`
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_40};
    text-align: left;
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    ${({ verticalAlignTopTh }) => verticalAlignTopTh && 'vertical-align: top;'}

    &:last-child {
        text-align: end;
    }
`;

export const Td = styled.td<TdProps>`
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_300};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}

    &:last-child {
        text-align: end;
    }
`;

export const Tr = styled.tr<TrProps>`
    background-color: ${({ theme, selected }) => (selected ? theme.colors.gray_40 : theme.colors.white)};

    &:nth-last-child(2) {
        ${({ outlineLast, theme }) => outlineLast && `border-bottom: 2px solid ${theme.colors.dustBlue['50']};`}
    }

    &:last-child {
        ${Td} {
            border-bottom: none;
        }
    }

    ${Td} {
        background-color: ${({ theme, selected }) => (selected ? theme.colors.gray_40 : theme.colors.white)};

        // clarification is in the italian-calculation/src/components/Positions/PositionsTable/index.tsx
        & > button[data-is-in-selected-row='true'] {
            ${({ theme, selected }) => selected && `color: ${theme.colors.white}`}
        }
    }
`;

export const MobileContainer = styled.div`
    width: 100%;
`;

export const Col = styled.div<ColProps>`
    display: grid;
    grid-template-columns: ${({ mobileGridColAmount }) =>
        mobileGridColAmount
            ? `repeat(${mobileGridColAmount}, minmax(80px, 1fr))`
            : 'repeat(auto-fill, minmax(200px, 1fr))'};
    grid-gap: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    }
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;

    &:last-child {
        text-align: end;
    }
`;
