import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';
import { Item, StyledText } from '../Header/styles';

export const ImportIniContainer = styled(Item)`
    ${media.tablet`
        display: none;
    `}
`;
export const ImportIniText = styled(StyledText)`
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.deepBlue['800']};
    }
`;
