import React, { FC, useState, memo } from 'react';
import { useStore, useStoreMap } from 'effector-react';
import { Menu, Input, Button, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';

import { AvailableAssemblyGroup } from '../../types/graphicTypes';

import { ObjectInfo } from '../../types/svgGrapaTypes';

// import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';
import { fastMovingBarMenuItems } from '../fastMovingBarMenuItems/fastMovingBarMenuItems';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { onMouseOverModel } from '../../stores/onMouseOverModel';
import DownloadingProgress from '../DownloadingProgress/DownloadingProgress';
import { sparePartSearchModel } from '../../stores/sparePartSearchModel';
import { ResultSparePartSearch } from '../ResultSparePartSearch/ResultSparePartSearch';
import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';
import { subscribes } from '../../stores/subscribes';
import { updateFromStaticGrapaService } from '../../stores/updateFromStaticGrapaServiceModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { PropsEditor2 } from '../PropsEditor/PropsEditor2';

const minSearchTextLength = 2;

function filterGraphicsList(graphicsList: AvailableAssemblyGroup[], searchTxt: string): AvailableAssemblyGroup[] {
    if (!searchTxt) return graphicsList;

    if (!graphicsList.length) return graphicsList;

    const searchNumber = parseInt(searchTxt);
    const searchTxtLowCase = searchTxt.toLowerCase();

    let filteredGraphicsList: AvailableAssemblyGroup[] = graphicsList.map(el => {
        if (el.objectsInfo) {
            const newObjectsInfo = el.objectsInfo.filter(
                eleObj =>
                    eleObj.titleMenu.toLowerCase().indexOf(searchTxtLowCase) >= 0 ||
                    eleObj.dvnLeft?.dvn === searchNumber ||
                    eleObj.dvnRight?.dvn === searchNumber
            );
            return { ...el, objectsInfo: newObjectsInfo };
        }
        return el;
    });

    filteredGraphicsList = filteredGraphicsList.filter(
        el =>
            (el.objectsInfo && el.objectsInfo.length > 0) ||
            (el.assemblyGroup.name && el.assemblyGroup.name.toLowerCase().indexOf(searchTxt.toLowerCase()) >= 0)
    );

    return filteredGraphicsList;
}

interface Props {
    isMobile: boolean;
}

const GraphicGroupsMenu: FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();
    // const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);
    const filteredByZoneAndOptionsGraphicsList = useStore(subscribes.stores.filteredByZoneAndOptionsGraphicsList);

    const loadingFromStaticGraphicService = useStore(updateFromStaticGrapaService.effects.getFilesByDatECodeFx.pending);

    const genericSVGGraphics = useStore(genericSVGGraphicModel.stores.genericSVGGraphics);

    const currentSvgZoneGraphic = useStore(zoneGraphicsModel.stores.currentSvgZoneGraphic);
    const zoneGraphicSVG = currentSvgZoneGraphic?.value;

    const getExtPartNoInfoByModelAndExtPartNoFxPending = useStore(
        sparePartSearchModel.effects.getExtPartNoInfoByModelAndExtPartNoFx.pending
    );

    const [searchText, setSearchText] = useState('');
    const [openKeys, setOpenKeys] = useState<any>([]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[] | undefined>(['ZoneGraphic']);

    let filteredGraphicsList: AvailableAssemblyGroup[] = filterGraphicsList(
        filteredByZoneAndOptionsGraphicsList,
        searchText
    );

    const showMenuGenericGroups = useStoreMap(
        pluginOptionsModel.stores.pluginOptions,
        pluginOptions => pluginOptions?.settings?.showMenuGenericGroups
    );
    const showMenuGenericGraphics = useStoreMap(
        pluginOptionsModel.stores.pluginOptions,
        pluginOptions => pluginOptions?.settings?.showMenuGenericGraphics
    );
    const fastMovingBar = useStoreMap(
        pluginOptionsModel.stores.pluginOptions,
        pluginOptions => pluginOptions?.settings?.fastMovingBar
    );

    function onChangeSearchText(newSearchText: string) {
        if (newSearchText.length > minSearchTextLength) {
            setOpenKeys(filteredGraphicsList.map(el => `${el.assemblyGroup.assemblyGroupId}`));
        } else {
            setOpenKeys([]);
        }
        setSearchText(newSearchText);
    }

    function onSelectMenu(selectedKeys: React.Key) {
        setSelectedKeys([selectedKeys]);
    }

    const currentZoneDATID = useStore(zoneGraphicsModel.stores.currentZoneDATID);
    const isFiltratedByZoneGroups = !!currentZoneDATID;

    let filtredSelectedKeys = selectedKeys;

    if (searchText.toLowerCase() === 'settings') {
        setSearchText('');
        graphicDamageSelectionModel.events.updateStore({
            showGraphicGroupsMenu: false
        });
        rightSideDrawerModel.events.setCurrentView(PropsEditor2);
    }

    return (
        <div style={{ width: '100%', padding: '72px 0px 0px 0px' }}>
            <div
                style={{
                    width: '100%',
                    padding: '16px 14px 16px 18px',
                    // width: '100%',
                    position: 'absolute',
                    top: '0px',
                    // right: '18px',
                    right: '0px',
                    background: 'white',
                    zIndex: 1
                }}
            >
                <Row>
                    <Input
                        // width="100%"
                        // style={{ width: '100%' }}
                        id="searchInput"
                        allowClear
                        placeholder={t('InputSearchTextPlaceHolder')}
                        size="large"
                        value={searchText}
                        onChange={e => onChangeSearchText(e.target.value)}
                    />
                    {filteredGraphicsList.length === 0 && searchText.length >= 6 && (
                        <Button
                            style={{ width: '100%', margin: '8px 0px 0px 0px' }}
                            icon={
                                !getExtPartNoInfoByModelAndExtPartNoFxPending ? <SearchOutlined /> : <LoadingOutlined />
                            }
                            type="primary"
                            onClick={() =>
                                sparePartSearchModel.events.runGetExtPartNoInfoByModelAndExtPartNo([searchText])
                            }
                        >
                            {t('sparePartSearch')}
                        </Button>
                    )}
                </Row>
            </div>

            <ResultSparePartSearch />

            <DownloadingProgress
            // style={{
            //     color: 'rgba(255, 255, 255, 0.65)'
            // }}
            />

            {!(filteredGraphicsList.length === 0 && searchText.length >= 6) && (
                <div
                    style={{
                        // width: '100%',
                        // padding: '16px 32px 16px 16px',
                        // width: '100%',
                        // position: 'absolute',
                        // top: '64px',
                        // right: 0,
                        // bottom: 0,
                        // left: 0
                        // background: 'white',
                        // zIndex: '1'

                        position: 'absolute',
                        top: '73px',
                        right: '0px',
                        left: '0px',
                        bottom: '0px',
                        overflowY: 'scroll'
                    }}
                >
                    <Menu
                        onMouseLeave={() => {
                            onMouseOverModel.events.clearOnMouseOver();
                        }}
                        mode="inline"
                        openKeys={openKeys}
                        // theme="dark"
                        onOpenChange={setOpenKeys}
                        selectedKeys={filtredSelectedKeys as string[]}
                        onSelect={({ key }) => onSelectMenu(key)}
                    >
                        {/* zone graphics */}
                        {!!zoneGraphicSVG && (
                            <Menu.Item
                                key={'ZoneGraphic'}
                                onClick={() => {
                                    if (isMobile) {
                                        graphicDamageSelectionModel.events.updateStore({
                                            showGraphicGroupsMenu: false
                                        });
                                    }
                                    graphicDamageSelectionModel.events.updateStore({
                                        typeOfGraphic: 'ZoneGraphic'
                                    });
                                }}
                            >
                                {isFiltratedByZoneGroups && (
                                    <>
                                        <Button
                                            icon={<CloseOutlined />}
                                            onClick={e => {
                                                zoneGraphicsModel.events.setCurrentZoneDATID('');
                                                e.stopPropagation();
                                            }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                    </>
                                )}

                                {t(`zoneGraphics`)}
                            </Menu.Item>
                        )}

                        {!isFiltratedByZoneGroups &&
                            showMenuGenericGroups &&
                            fastMovingBar &&
                            fastMovingBar.length > 0 && (
                                <Menu.SubMenu
                                    key="fastMovingBar"
                                    title={t(`fastMovingBar.GenericGroups`) || 'Generic groups'}
                                >
                                    {fastMovingBarMenuItems({
                                        fastMovingBar,
                                        submenuShowTitle: true,
                                        enableOnMouseOver: true,
                                        t
                                    })}
                                </Menu.SubMenu>
                            )}
                        {!isFiltratedByZoneGroups &&
                            showMenuGenericGraphics &&
                            genericSVGGraphics
                                .filter(genericGraphicItem => !!genericGraphicItem.graphicSVG)
                                .map(genericGraphicItem => (
                                    <Menu.Item key={genericGraphicItem.graphicId}>
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                            onClick={_ => {
                                                onMouseOverModel.events.clearOnMouseOver();
                                                setOpenKeys([]);
                                                graphicDamageSelectionModel.events.updateStore({
                                                    currentAssemblyGroup: undefined,
                                                    typeOfGraphic: 'GenericGraphic',
                                                    currentAssemblyGroupObject: undefined,
                                                    currentAssemblyGroupObjects: undefined
                                                });
                                                if (isMobile) {
                                                    graphicDamageSelectionModel.events.updateStore({
                                                        showGraphicGroupsMenu: false
                                                    });
                                                }
                                                genericSVGGraphicModel.events.setCurrentGenericGraphic(
                                                    genericGraphicItem
                                                );
                                            }}
                                            // onMouseOver={() => {
                                            //     onMouseOverModel.events.setHighlightGenericGraphic(genericGraphicItem);
                                            // }}
                                        >
                                            {t(`genericGraphic.${genericGraphicItem.graphicId}`) ||
                                                genericGraphicItem.label}
                                        </div>
                                    </Menu.Item>
                                ))}
                        {searchText.length > minSearchTextLength || true
                            ? filteredGraphicsList.map((element: AvailableAssemblyGroup) =>
                                  !!element.objectsInfo?.length || true ? (
                                      <Menu.SubMenu
                                          className={
                                              !!selectedKeys?.find(
                                                  item => item === element.assemblyGroup.assemblyGroupId
                                              )
                                                  ? 'ant-menu-item-selected'
                                                  : ''
                                          }
                                          key={element.assemblyGroup.assemblyGroupId}
                                          title={
                                              <div
                                                  style={{
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                  }}
                                                  //   onMouseOver={() => {
                                                  //       onMouseOverModel.events.setHighlightByAssemblyGroupAdnDatid({
                                                  //           availableAssemblyGroup: element,
                                                  //           datId: ''
                                                  //       });
                                                  //   }}
                                                  onClick={event => {
                                                      onMouseOverModel.events.clearOnMouseOver();
                                                      onSelectMenu(element.assemblyGroup.assemblyGroupId);
                                                      setOpenKeys([]);
                                                      graphicDamageSelectionModel.events.updateStore({
                                                          typeOfGraphic: 'FullGraphic',
                                                          currentAssemblyGroup: element,
                                                          currentAssemblyGroupObject: undefined,
                                                          currentAssemblyGroupObjects: undefined
                                                      });
                                                      if (isMobile)
                                                          graphicDamageSelectionModel.events.updateStore({
                                                              showGraphicGroupsMenu: false
                                                          });

                                                      event.stopPropagation();
                                                  }}
                                              >
                                                  {!element.availableDate && <LoadingOutlined />}
                                                  {element.assemblyGroup.name}
                                              </div>
                                          }
                                      >
                                          {element.objectsInfo &&
                                              element.objectsInfo.map((eleObj: ObjectInfo) => (
                                                  <Menu.Item
                                                      key={`${element.assemblyGroup.assemblyGroupId}-${eleObj.attr.position}`}
                                                  >
                                                      <div
                                                          style={{
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis'
                                                          }}
                                                          onClick={() => {
                                                              onMouseOverModel.events.clearOnMouseOver();
                                                              graphicDamageSelectionModel.events.setCurrentObjectByDATIDandGroup(
                                                                  {
                                                                      datid: eleObj.datid,
                                                                      asemblyGroupId:
                                                                          element.assemblyGroup.assemblyGroupId
                                                                  }
                                                              );
                                                              graphicDamageSelectionModel.events.updateStore({
                                                                  //   showGenericGraphic: false,
                                                                  typeOfGraphic: 'FullGraphic',
                                                                  currentAssemblyGroup:
                                                                      filteredByZoneAndOptionsGraphicsList.find(
                                                                          it =>
                                                                              it.assemblyGroup.assemblyGroupId ===
                                                                              element.assemblyGroup.assemblyGroupId
                                                                      )
                                                              });
                                                              if (isMobile)
                                                                  graphicDamageSelectionModel.events.updateStore({
                                                                      showGraphicGroupsMenu: false
                                                                  });
                                                          }}
                                                          onMouseOver={() => {
                                                              onMouseOverModel.events.setHighlightByAssemblyGroupAdnDatid(
                                                                  {
                                                                      availableAssemblyGroup: element,
                                                                      datId: eleObj.datid
                                                                  }
                                                              );
                                                          }}
                                                      >
                                                          {eleObj.titleMenu}
                                                      </div>
                                                  </Menu.Item>
                                              ))}
                                      </Menu.SubMenu>
                                  ) : (
                                      <Menu.Item key={element.assemblyGroup.assemblyGroupId}>
                                          <div
                                              style={{
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis'
                                              }}
                                              onClick={() => {
                                                  graphicDamageSelectionModel.events.updateStore({
                                                      currentAssemblyGroup: element,
                                                      currentAssemblyGroupObject: undefined,
                                                      currentAssemblyGroupObjects: undefined
                                                  });

                                                  graphicDamageSelectionModel.events.updateStore({
                                                      typeOfGraphic: 'FullGraphic'
                                                  });
                                                  if (isMobile)
                                                      graphicDamageSelectionModel.events.updateStore({
                                                          showGraphicGroupsMenu: false
                                                      });
                                              }}
                                          >
                                              {!element.availableDate && <LoadingOutlined />}
                                              {element.assemblyGroup.name}
                                          </div>
                                      </Menu.Item>
                                  )
                              )
                            : filteredGraphicsList.map(element => <MenuItemM element={element} />)}

                        {/* rims graphics */}

                        {/* {!!debuggingMode && ( */}
                        {!isFiltratedByZoneGroups && (
                            <Menu.Item
                                key={'RimsGraphic'}
                                onClick={() => {
                                    if (isMobile) {
                                        graphicDamageSelectionModel.events.updateStore({
                                            showGraphicGroupsMenu: false
                                        });
                                    }
                                    graphicDamageSelectionModel.events.updateStore({
                                        typeOfGraphic: 'RimsGraphic'
                                    });
                                }}
                            >
                                {/* <ChromeOutlined />
                                &nbsp; */}
                                {t(`RimsGraphic`)}
                            </Menu.Item>
                        )}
                    </Menu>

                    {loadingFromStaticGraphicService && (
                        <div style={{ padding: '0px 16px 0px 24px' }}>
                            {/* <Spin style={{ width: '100%', padding: '8px 0px 8px 0px' }} /> */}
                            <Skeleton
                                // loading={loadingFromStaticGraphicService}
                                active
                                paragraph={{ rows: 15 }}
                                title={false}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const MenuItem = ({ element, ...props }: { element: AvailableAssemblyGroup }) => (
    <Menu.Item {...props} key={element.assemblyGroup.assemblyGroupId}>
        {/* {element.assemblyGroup.name} */}
        <div
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
            onClick={() => {
                graphicDamageSelectionModel.events.updateStore({
                    currentAssemblyGroup: element,
                    currentAssemblyGroupObject: undefined,
                    currentAssemblyGroupObjects: undefined
                });

                graphicDamageSelectionModel.events.updateStore({
                    typeOfGraphic: 'FullGraphic'
                });
                // if (isMobile)
                //     graphicDamageSelectionModel.events.updateStore({
                //         showGraphicGroupsMenu: false
                //     });
            }}
        >
            {!element.availableDate && <LoadingOutlined />}
            {element.assemblyGroup.name}
        </div>
    </Menu.Item>
);

const MenuItemM = memo(
    MenuItem,
    (prevProps, nextProps) =>
        prevProps.element.assemblyGroup.assemblyGroupId === nextProps.element.assemblyGroup.assemblyGroupId &&
        prevProps.element.assemblyGroup.name === nextProps.element.assemblyGroup.name
);

export default memo(GraphicGroupsMenu, (prevProps, nextProps) => prevProps.isMobile === nextProps.isMobile);
