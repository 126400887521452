import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { PositionsFormValues } from '../../types/positions';
import { useMedia } from '@dat/core/hooks/useMedia';
import { getTotalItem } from '../../utils/getTotalItem';

import { NumberInputField } from '@wedat/ui-kit/Formik';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import { Container } from '../Container';
import { Text } from '@wedat/ui-kit/components/Text';
import { FlexWrapper, GridWrapper, SizedBox, Info, SumInfo, TableStyled, CardStyled } from './styles';
import { NumberFormatStyled } from '../core/styles';

export const Labour: React.FC = props => {
    const {
        values: { labourItems }
    } = useFormikContext<PositionsFormValues>();
    const { t } = useTranslation();
    const {
        colors: { dustBlue }
    } = useTheme();
    const isMobile = useMedia(sizes.phoneBig);

    const total = getTotalItem(labourItems);

    const headers = useMemo(
        () => [
            t('common.designation'),
            t('common.hours'),
            t('labour.priceHour'),
            t('labour.discount'),
            t('labour.total')
        ],
        [t]
    );

    const mobileHeaders = useMemo(
        () => [t('common.hours'), t('labour.priceHour'), t('labour.discount'), t('labour.total')],
        [t]
    );

    const columns = useMemo(
        () =>
            headers.map((item, idx) => ({
                Header: (
                    <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                        {item}
                    </Text>
                ),
                accessor: `col${idx + 1}`
            })),
        [dustBlue, headers]
    );

    const data = useMemo(
        () =>
            labourItems.map((item, idx) => {
                const isLast = idx === labourItems.length - 1;
                const conditionalFontWeight = labourItems.length - 1 === idx ? 700 : 400;

                return {
                    col1: (
                        <Text font="note" color={dustBlue['900']} fontWeight={conditionalFontWeight}>
                            {item.Description}
                        </Text>
                    ),
                    col2: (
                        <Text font="note" color={dustBlue['900']} fontWeight={conditionalFontWeight}>
                            <NumberFormat value={item.Hours} />
                        </Text>
                    ),
                    col3: (
                        <>
                            {isLast ? null : (
                                <SizedBox>
                                    <NumberInputField
                                        inputSize="small"
                                        borderRadius={4}
                                        width={100}
                                        name={`labourItems[${idx}].WagePerHour`}
                                        hideZeroValue
                                    />
                                </SizedBox>
                            )}
                        </>
                    ),
                    col4: (
                        <>
                            {isLast ? null : (
                                <SizedBox>
                                    <NumberInputField
                                        inputSize="small"
                                        borderRadius={4}
                                        width={100}
                                        name={`labourItems[${idx}].DiscountPerc`}
                                        hideZeroValue
                                    />
                                </SizedBox>
                            )}
                        </>
                    ),
                    col5: (
                        <Text font="note" color={dustBlue['900']} fontWeight={conditionalFontWeight}>
                            <NumberFormat value={item.TotalWage} />
                        </Text>
                    )
                };
            }),
        [dustBlue, labourItems]
    );

    return (
        <Container title={t('labour.title')} {...props}>
            {isMobile ? (
                <CardStyled>
                    {total && (
                        <SumInfo key={total.Identifier}>
                            <FlexWrapper>
                                <Text font="note" color={dustBlue['900']} fontWeight={700}>
                                    {total.Description}
                                </Text>
                            </FlexWrapper>
                            <FlexWrapper>
                                <SizedBox>
                                    <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                        {t('common.hours')}
                                    </Text>
                                </SizedBox>
                                <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                    {t('labour.total')}
                                </Text>
                            </FlexWrapper>
                            <FlexWrapper>
                                <SizedBox>
                                    <Text font="note" color={dustBlue['900']} fontWeight={700}>
                                        {total.Hours}
                                    </Text>
                                </SizedBox>
                                <Text font="note" color={dustBlue['900']} fontWeight={700}>
                                    {total.TotalWage}
                                </Text>
                            </FlexWrapper>
                        </SumInfo>
                    )}
                    <GridWrapper>
                        {mobileHeaders.map(item => (
                            <SizedBox>
                                <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                    {item}
                                </Text>
                            </SizedBox>
                        ))}
                    </GridWrapper>
                    {labourItems.map((item, idx) => {
                        const isLast = idx === labourItems.length - 1;

                        return (
                            <Info key={item.Description}>
                                <FlexWrapper>
                                    <Text font="note" color={dustBlue['900']}>
                                        {item.Description}
                                    </Text>
                                </FlexWrapper>
                                <GridWrapper>
                                    <NumberFormatStyled value={item.Hours} />

                                    {isLast ? null : (
                                        <NumberInputField
                                            borderRadius={4}
                                            width={100}
                                            name={`labourItems[${idx}].WagePerHour`}
                                        />
                                    )}

                                    {isLast ? null : (
                                        <NumberInputField
                                            borderRadius={4}
                                            width={100}
                                            name={`labourItems[${idx}].DiscountPerc`}
                                        />
                                    )}

                                    <NumberFormatStyled value={item.TotalWage} />
                                </GridWrapper>
                            </Info>
                        );
                    })}
                </CardStyled>
            ) : (
                <TableStyled outlineLast verticalAlignTopTh columns={columns} data={data} mobileHeaders />
            )}
        </Container>
    );
};
