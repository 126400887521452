import styled, { css } from 'styled-components';

interface LabelProps {
    disabled?: boolean;
    checked?: boolean;
}

export const RadioIcon = styled.div`
    position: relative;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    transition: opacity 150ms;

    &::before,
    &::after {
        position: absolute;
        content: '';
        box-sizing: border-box;
    }

    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.blue['400']};
        transition: border-color 150ms;
    }

    &::after {
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: ${({ theme }) => theme.colors.blue['400']};
        opacity: 0;
        visibility: hidden;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 150ms, visibility 150ms;
        will-change: opacity, visibility;
    }
`;

export const Content = styled.div`
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    transition: color 150ms;
`;

export const Input = styled.input`
    display: none;
    &:checked {
        & + ${RadioIcon}::after {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const Label = styled.label<LabelProps>`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            ${RadioIcon} {
                &::after {
                    background-color: ${({ theme }) => theme.colors.dustBlue['100']};
                }
                &::before {
                    border-color: ${({ theme }) => theme.colors.dustBlue['100']};
                }
            }
            ${Content} {
                color: ${({ theme }) => theme.colors.dustBlue['200']};
            }
        `}
`;
