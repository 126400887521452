import { combine, sample } from 'effector';

import { sharedSubjectsDataStores, sharedSubjectsDataEffects } from '@dat/shared-models/addressBook';

import { events as pluginEvents, stores as pluginStores } from '../../plugin/stores';
import { computeInstanceDynamicProperties } from './computeInstanceDynamicProperties';
import { createNewSubject } from '../../../utils/createNewSubject';
import { findInstanceSubjectsData } from './findInstanceSubjectsData';
import { createPayloadForUpdateSubjects } from '../../subjectsData/utils/createPayloadForUpdateSubjects';

import { Instance } from '../../../types/instance';

/* This function for instance is like init file for stores */
export const connectInstanceUnits = (instance: Instance) => {
    const { instanceId, events } = instance;
    // Besides `instanceId` only events are taken from `instance` argument
    // All other data should be taken from `instanceStore`, because it's dynamic
    const {
        addButtonClicked,
        deleteButtonClicked,
        subjectUpdated,
        displaySubject,
        saveSubjects,
        subjectDeleted,
        hideFormOnMobile,
        optionsUpdated
    } = events;
    const eventsArray = Object.values(events);

    const instanceStore = pluginStores.instances.map(instances => instances[instanceId]);
    const subjectsDataStore = combine(
        [instanceStore, sharedSubjectsDataStores.customersSubjectsData],
        ([instance, customersSubjectsData]) => findInstanceSubjectsData({ customersSubjectsData, ...instance })
    );

    /* Dynamic data updates */
    sample({
        //TODO: a lof of updates
        clock: [subjectsDataStore, ...eventsArray],
        source: { instance: instanceStore, subjectsData: subjectsDataStore },
        fn: ({ instance, subjectsData }) => ({
            instanceId,
            properties: computeInstanceDynamicProperties({
                subjectsData,
                initialPayload: instance,
                currentInstance: instance
            })
        }),
        target: pluginEvents.updateInstance
    });
    sample({
        clock: optionsUpdated,
        source: { instance: instanceStore, subjectsData: subjectsDataStore },
        fn: ({ instance, subjectsData }, initialPayload) => ({
            instanceId,
            properties: computeInstanceDynamicProperties({
                subjectsData,
                initialPayload,
                currentInstance: instance
            })
        }),
        target: pluginEvents.updateInstance
    });

    /* Save subjects of instance */
    const isSavingAllowed = instanceStore.map(instance => !instance.networkOwnerId);
    //TODO: save on close
    sample({
        clock: saveSubjects,
        source: instanceStore,
        filter: isSavingAllowed,
        fn: ({ initialSubjects, currentSubjects, subjectType }) =>
            createPayloadForUpdateSubjects({ initialSubjects, newSubjects: currentSubjects, subjectType }),
        target: sharedSubjectsDataEffects.updateSubjectsFx
    });

    /* Buttons events */
    sample({
        source: addButtonClicked,
        fn: () => ({ instanceId, subject: createNewSubject() }),
        target: pluginEvents.addSubjectToInstance
    });
    sample({
        clock: deleteButtonClicked,
        source: instanceStore,
        fn: ({ displayedId, currentSubjects }) => ({
            instanceId,
            index: currentSubjects.findIndex(({ _id }) => _id === displayedId)
        }),
        target: pluginEvents.deleteSubjectFromInstance
    });

    sample({
        clock: subjectUpdated,
        source: instanceStore,
        fn: ({ displayedSubject }, updatedValues) => ({
            instanceId,
            subject: { ...displayedSubject, ...updatedValues }
        }),
        target: pluginEvents.updateSubjectOfInstance
    });
    sample({
        clock: subjectDeleted,
        source: instanceStore,
        fn: ({ currentSubjects }, deletedIndex) => currentSubjects[deletedIndex - 1] || currentSubjects[deletedIndex],
        target: [displaySubject, hideFormOnMobile]
    });
};
