import { hideScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AttachmentsList = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

    padding-left: 24px;
    padding-right: 24px;

    ${media.phoneBig`
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;

        ${hideScrollBar()}
    `}
`;
