import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { PositionsFormValues } from '../../../types/positions';
import { PositionsCard } from '../PositionsCard';
import { positionsEvents } from '../../../stores/positions';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Accordion, AccordionItem } from '@wedat/ui-kit/components/Accordion';
import { PositionsTypesValues } from '../../../constants/positionFieldsValues';
import { AccordionHeader, List, Text } from './styles';
import { isUpperCase } from '@dat/core/utils/isUpperCase';

interface Props {
    onPositionClick: (index: number) => void;
    selectedPositionIndexes: number[];
}

export const PositionsList: FC<Props> = ({ onPositionClick, selectedPositionIndexes }) => {
    const { values } = useFormikContext<PositionsFormValues>();
    const [indexExpanded, setIndexExpanded] = useState<number | undefined>(undefined);
    const listRef = useRef<HTMLDivElement>(null);
    const isMobile = useMedia(sizes.phoneBig);

    useEffect(
        () =>
            positionsEvents.positionAdded.watch(() => {
                queueMicrotask(() => {
                    if (isMobile && listRef.current) {
                        const scrollHeight = listRef.current.scrollHeight;
                        setIndexExpanded(values.positions.length);
                        window.scrollTo({ top: scrollHeight, behavior: 'smooth' });
                    }
                });
            }),
        [isMobile, listRef, values.positions.length]
    );

    const renderHeaderAccordion = (position: DAT2.PositionItaly) => {
        const isGray = position.Type === PositionsTypesValues.aggregateComponent && isUpperCase(position.Description);
        const isAggregate = position.Type === PositionsTypesValues.aggregate;
        const isAggregateComponent = position.Type === PositionsTypesValues.aggregateComponent && !isGray;

        return (
            <AccordionHeader aggregate={isAggregate} aggregateComponent={isAggregateComponent}>
                <Text>{position.Description}</Text>
            </AccordionHeader>
        );
    };

    return (
        <List ref={listRef}>
            <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                preExpanded={[String(indexExpanded)]}
                // Need for re-render component for expanded accordion, when we click add new position
                key={String(indexExpanded)}
            >
                {values.positions.map((position, index) => (
                    <AccordionItem header={renderHeaderAccordion(position)} key={index} uuid={String(index)}>
                        <PositionsCard
                            position={position}
                            index={index}
                            selectedPositionIndexes={selectedPositionIndexes}
                            onPositionClick={onPositionClick}
                        />
                    </AccordionItem>
                ))}
            </Accordion>
        </List>
    );
};
