import _ from 'lodash';
import { isDate } from 'date-fns';

import { getISODateString } from '@dat/core/utils';

import { ManualFields } from '../types/manualFields';
import { PlainObject } from '@dat/core/types/common';
import { PartialDeep } from 'type-fest';

export const convertManualFieldsToDossier = (manualFields: PartialDeep<ManualFields>): DAT2.Dossier =>
    addUserOriginToAllProperties(manualFields);

const addUserOriginToAllProperties = (object: PlainObject): PlainObject => {
    const result: PlainObject = {};

    Object.entries(object).forEach(([key, value]) => {
        result[key] = parseProperty(value);
    });

    return result;
};

const parseProperty = (property: any): any => {
    if (Array.isArray(property)) {
        return property.map(parseProperty);
    }
    if (_.isPlainObject(property)) {
        return addUserOriginToAllProperties(property);
    }

    return addUserOriginToProperty(property);
};

const addUserOriginToProperty = (value: DAT2.Field.Primitive | Date) => ({
    _text: parseValue(value),
    origin: 'user'
});

const parseValue = (value: DAT2.Field.Primitive | Date): DAT2.Field.Primitive => {
    if (isDate(value)) {
        return getISODateString(value);
    }

    return value;
};
