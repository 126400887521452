// Delay 0.5 sec
export const delay = () => new Promise(res => setTimeout(res, 500));

/**
 * Function to get reducer action
 */
export const getAction = <T extends string, P extends object>(type: T, payload: P) =>
    ({
        type,
        payload
    } as const);
