import styled, { css } from 'styled-components';

import { InputProps } from '../Input/types';

export const ErrorStyled = styled.div`
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.red['900']};
    margin-left: 12px;
`;

export const errorStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.red['900']};
`;

export const successStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.green['900']};
`;

export const defaultStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};

    &:hover {
        border-color: ${({ theme }) => theme.colors.dustBlue['300']};
    }

    &:focus {
        border-color: ${({ theme }) => theme.colors.blue['500']};
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.dustBlue['200']};
        border-color: ${({ theme }) => theme.colors.dustBlue['50']};
        background-color: ${({ theme }) => theme.colors.gray['50']};
    }
`;

const getStatusStyle = (statusStyle: InputProps['status']) => {
    switch (statusStyle) {
        case 'error':
            return errorStyle;
        case 'success':
            return successStyle;
        case 'default':
            return defaultStyle;
        default:
            return defaultStyle;
    }
};

export const FieldsetStyled = styled.fieldset<Pick<InputProps, 'status' | 'borderRadius' | 'width'>>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    min-width: 0%;
    position: absolute;
    text-align: left;
    inset: -5px 0px 0px;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    overflow: hidden;
    ${({ status }) => getStatusStyle(status)};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')};
`;
