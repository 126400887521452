import axios from '../axios';
import { DatProductsUrls, VehicleRepairServices } from '@dat/core/constants/enums';

export const getLacquerTypeKeys = (values: DAT.GetLacquerTypeKeysRequest) =>
    axios<DAT.GetLacquerTypeKeysResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleRepairService,
        method: 'getLacquerTypeKeys',
        values
    });
