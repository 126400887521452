// 1. What is FastTrack interfacing
import fetch from 'node-fetch';
import {
    datGoldServicesUrls,
    datProdServicesUrls,
    defaultGoldPartnerSignature,
    defaultProdPartnerSignature
} from '../baseParameters';
import { Credentials } from '../types/api2datTypes';
import {
    FastTrackAdditionalElements,
    FastTrackConfiguration,
    FastTrackElement
} from '../types/fastTrackInterfaceTypes';
import { getTokenWithCache } from './tokenService';

// fastTrackInterface  REST

// FastTrack interfacing is a set of options that allows to integrate FastTrack solution with 3rd party applications.
// The purpose of this document is to collect and describe business requirements as preparation for development.
//     2. Glossary

//     • FastTrack [FT] – Name of the DAT solution allowing for fast damage capturing and calculation of light damages based on predefined rules. For details please refer for FastTrack documentation
//     • Damage Definition [DD] – Configuration file that allows to define what damage types are available to certain elements or group of elements
//     • Repair Definition [RD] – Configuration file that allows to define what repair operations apply for specific damages with connection to specific elements
//     • FastTrack element – Single vehicle part that damage can be defined for. Elements can be grouped in groups for better damage profiles definition.
//     • FastTrack group – a collection of elements with similar features that allows to define the same damages for then. For example: tires, rims, headlights, bumpers etc.
//     • claimId – equivalent to contractId – Unique identifier to claim in MyClaim system
//     3. Potentials and target group

// Right now there are two ongoing project in DAT Group:
//     • FastTrack Mobile  - Internal mobile application
//     • Apreel mobile app for Claim handling support. Currently used by Warta Insurer in Poland

// ===========================================================================================

// 4.1. UC-1 List of Damage type definitions
// Collects are available damage types for automatic selection of external system or for presenting to the user for manual selection/configuration

// Actors
//     • 3rd party external system
// Basic flow of events
//     • Send request for the list
// Input
//     • None
// Result
//     • List of damage type definitions for current user

// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/listDamages

//     • Example request (empty object – no parameters required)
// {}

//     • Example response
// [
//    "Damage config Insurer 1",
//    "Damage config Insurer 2",
//    "Damage config Test"
// ]

export async function listDamages(credentials: Credentials, url?: string): Promise<Array<string>> {
    return SendComonFastTrackRequest(credentials, { body: '{}' }, 'listDamages', url);
}

// ===========================================================================================

// 4.2. UC-2 List of Repair definitions
// Collects are available repair definitions.

// Actors
//     • 3rd party external system
// Basic flow of events
//     • Send request for the list
// Input
//     • None
// Result
//     • List of repair types type definitions for current user

// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/listRepairs

//     • Example request (empty object – no parameters required)
// {}

//     • Example response
// [
//    "Damage config Insurer 1",
//    "Damage config Insurer 2",
//    "Damage config Test"
// ]

export async function listRepairs(credentials: Credentials, url?: string): Promise<Array<string>> {
    return SendComonFastTrackRequest(credentials, { body: '{}' }, 'listRepairs', url);
}

// ===========================================================================================

// 4.3. UC-3 Download FastTrack configuration for selected claim
// Downloads FastTrack configuration for selected claim.
// If claim exists and claim’s template contains FastTrack data, then its configuration will be returned
// This configuration will be used for displaying  possible damage options for FastTrack elements.
// Actors
//     • 3rd party external system
// Basic flow of events
//     • Send request for the configuration file
// Input
//     • ID of the claim
// Result
//     • FastTrack configuration file enriched with translations.
// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module
//     • Claim must exists

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/getFastTrackConfiguration

//     • Example request (claimId/contractId is required)
// {
// "contractId" : 1114025
// }

//     • Example response (shortened)
// {
//    "fastTrackGroups":    {
//       "DATID_0067": "FTGROUP_RIMS",
//       "DATID_0001": "FTGROUP_BUMPERS",
//       "DATID_0023": "FTGROUP_LIGHTS",
//       "DATID_0036": "FTGROUP_GLASS"
//    },
//    "fastTrackDamages":{   // TODO wrong description
//       "FTGROUP_GLASS":[
//          "scratch",
//          "rupture"
//       ],
//       "DATID_0002":[
//          "scratch",
//          "brokenHook",
//          "rupture"
//       ],
//       "FTGROUP_HANDLES":[
//          "scratch",
//          "brokenHook",
//          "rupture"
//       ]
//    }
// }

export interface GetFastTrackConfigurationParams {
    contractId: number;
}

export async function getFastTrackConfiguration(
    credentials: Credentials,
    params: GetFastTrackConfigurationParams,
    url?: string
): Promise<FastTrackConfiguration> {
    return SendComonFastTrackRequest(credentials, { body: JSON.stringify(params) }, 'getFastTrackConfiguration', url);
}

// ===========================================================================================

// 4.4. UC-4 Calculate FastTrack
// Executes FastTrack calculation with currently saved FastTrack (Previously updated by SaveFTClaim function).
// Actors
// 3rd party external system
// Basic flow of events
//     • Send request for executing calculation
// Input
//     • ID of the claim to be calculated
// Result
//     • None (or message in case of error)
//     • To download calculation result use “getContract” method from MyClaimExternalService
// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module
//     • FastTrack damages must be saved to the claim

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/calculateFastTrack
//     • Example request (claimId is required)
// {
// "contractId" : 1114025
// }

export interface CalculateFastTrackParams {
    contractId: number;
}

export async function calculateFastTrack(
    credentials: Credentials,
    params: CalculateFastTrackParams,
    url?: string
): Promise<{}> {
    return SendComonFastTrackRequest(credentials, { body: JSON.stringify(params) }, 'calculateFastTrack', url);
}

// ===========================================================================================

// 4.5. UC-5 Load FastTrack damages
// Downloads from the server previously defined FastTrack damaged elements along with FastTrack parameters.
// Actors
// 3rd party external system
// Basic flow of events
//     • Send request for data
// Input
//     • ID of the claim to be downloaded FastTrack data for
// Result
//     • FastTrack JSON object – See example below
// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module
//     • FastTrack claim must exist

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/loadFTClaim
//     • Example request (claimId is required)
// {
// "contractId" : 1114025
// }
//     • Example response
// {
// 	"spoProfile": "",
// 	"damageProfile": "FT PL",
// 	"costRate": "",
// 	"repairProfile": "FT PL",
// 	"elements": [
// 		{
// 			"elementId": "DATID_0003",
// 			"userComment": "optional any user comment-X ",
// 			"damageType": "crack"
// 		},
// 		{
// 			"elementId": "DATID_0024",
// 			"userComment": "additional optional user comment  for damaged part",
// 			"damageType": "rupture"
// 		}
// 	],
// 	"additionalElements": [
// 		{
// 			"quantity": 1,
// 			"price": 999,
// 			"description": "additional element 1",
// 			"workingTime": 1,
// 			"repairCode": "I"
// 		},
// 		{
// 			"quantity": 1,
// 			"price": 45,
// 			"description": "additional element 2"
// 		}
// 	]
// }

export interface LoadFTClaimParams {
    contractId: number;
}

export interface LoadFTClaimResult {
    spoProfile: string;
    damageProfile: string;
    costRate: string;
    repairProfile: string;
    elements?: FastTrackElement[];
    additionalElements?: FastTrackAdditionalElements[];
}

export async function loadFTClaim(
    credentials: Credentials,
    params: LoadFTClaimParams,
    url?: string
): Promise<LoadFTClaimResult> {
    return SendComonFastTrackRequest(credentials, { body: JSON.stringify(params) }, 'loadFTClaim', url);
}

// ===========================================================================================

// 4.6. UC-6 Save FastTrack damages
// Creates/Updates defined FastTrack damaged elements along with FastTrack parameters.
// Actors
// 3rd party external system
// Basic flow of events
//     • Send request for data
// Input
//     • JSON object with defined contract ID and FastTrack Object
// Result
//     • none
// Preconditions
//     • 3rd  party system must be authenticated and authorised for usage of FastTrack module
//     • FastTrack claim must exist

// Examples
//     • URL
// http://www.datgroup.com/myClaim/rest/FastTrackService/saveFTClaim
//     • Example request
// {
// 	"contractId": 1114025,
// 	"fastTrack": {
// 		"additionalElements": [
// 		{
// 			"quantity": 1,
// 			"price": 999,
// 			"description": "additional element 1",
// 			"workingTime": 1,
// 			"repairCode": "I"
// 		},
// 		{
// 			"quantity": 1,
// 			"price": 45,
// 			"description": "additional element 2"
// 		}
// 		],
// 		"elements": [
// 		{
// 			"elementId": "DATID_0003",
// 			"userComment": "la la la ",
// 			"damageType": "crack"
// 		},
// 		{
// 			"elementId": "DATID_0024",
// 			"userComment": "very deep scratch",
// 			"damageType": "rupture"
// 		},
// 		{
// 			"elementId": "DATID_0023",
// 			"userComment": "3cm hole diameter",
// 			"damageType": "hole"
// 		}
// 		]
// 	}
// }

export interface SaveFTClaimParams {
    contractId: number;
    fastTrack: {
        elements?: FastTrackElement[];
        additionalElements?: FastTrackAdditionalElements[];
    };
}

export interface SaveFTClaimResult {}

export async function saveFTClaim(
    credentials: Credentials,
    params: SaveFTClaimParams,
    url?: string
): Promise<SaveFTClaimResult> {
    return SendComonFastTrackRequest(credentials, { body: JSON.stringify(params) }, 'saveFTClaim', url);
}

// ===========================================================================================

// 5. Technical Information
// WSDL Address: https://www.datgroup.com/myClaim/soap/FastTrackService?wsdl

const urlFastTrackService =
    datProdServicesUrls.restFastTrackServiceUrl || 'https://www.dat.de/myClaim/rest/FastTrackService';
// const urlFastTrackService = 'https://www.datgroup.com/myClaim/rest/FastTrackService';

function getSoapMyClaimTokenService(url?: string) {
    return url?.includes('gold')
        ? datGoldServicesUrls.soapMyClaimTokenService
        : datProdServicesUrls.soapMyClaimTokenService;
}
function getDefaultSignature(url?: string) {
    return url?.includes('gold') ? defaultGoldPartnerSignature : defaultProdPartnerSignature;
}

async function SendComonFastTrackRequest(
    credentials: Credentials,
    options: any,
    methodName: string,
    url?: string
): Promise<any> {
    if ('customerPassword' in credentials) {
        const resToken = await getTokenWithCache(
            { ...getDefaultSignature(url), ...credentials },
            getSoapMyClaimTokenService(url)
        );
        if (!resToken) throw new Error(`Тo token received`);
        credentials = { 'dat-authorizationtoken': resToken.token };
    }

    const resOptions = {
        method: 'POST',
        headers: {
            ...credentials
        },
        ...options
    };
    const serviceUrl = (url || urlFastTrackService) + '/' + methodName;
    const result = await fetch(serviceUrl, resOptions);
    const resText = await result.text();
    if (result.status !== 200) {
        throw new Error(`Response status: ${result.status} text: ${resText}`);
    }
    const resObj = JSON.parse(resText);
    return resObj;
}
