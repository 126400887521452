import React from 'react';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { useTranslation } from 'react-i18next';
import { Languages } from '../Languages';

interface Props {
    closeSelectModal: () => void;
    isOpen: boolean;
}
export const LanguageModal: React.FC<Props> = ({ closeSelectModal, isOpen }) => {
    const { t } = useTranslation();

    return (
        <ModalPrimary
            bodyHeight="auto"
            isOpen={isOpen}
            title={t('auth:profile.selectLanguage')}
            onDismiss={closeSelectModal}
            bodyWidth="330px"
            bodyNoPadding
        >
            <Languages close={closeSelectModal} />
        </ModalPrimary>
    );
};
