import { attach, combine, createApi, createEffect, createEvent, createStore, forward } from 'effector';

import * as reducers from './reducers';
import { API2 } from '@dat/api2';
import { CLAIMS_LISTER_NAME, DEFAULT_COLUMNS, PAGE_LIMIT } from './constants';
import { FolderType, ListerUserSettings } from './types';
import { convertNullValuesToUndefined } from './utils/convertNullValuesToUndefined';
import _ from 'lodash';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { subDays, isToday } from 'date-fns';
import { debounce } from 'patronum';

const listUserSettings = createStore<ListerUserSettings>({ folderFilters: [], listerSettings: [] });
const loadUserSettingsFx = createEffect(async () => {
    const settings = await API2.myClaimInternal.loadModuleConfig({ moduleId: '/inbox.jsp' });

    let listerSetting = settings.listerSettings.find(lister => lister.name === CLAIMS_LISTER_NAME);
    if (!listerSetting) {
        const defaultColumns = await API2.myClaimInternal.defaultColumns({
            listerType: 'INBOX',
            embedded: 'templateFields'
        });
        listerSetting = { name: CLAIMS_LISTER_NAME, visibleColumns: defaultColumns.visible };
        settings.listerSettings.push(listerSetting);
    }

    // Add mandatory columns to claim lister
    const claimListerColumnsSet = new Set(listerSetting.visibleColumns);
    DEFAULT_COLUMNS.forEach(col => claimListerColumnsSet.add(col));
    listerSetting.visibleColumns = Array.from(claimListerColumnsSet);

    return settings;
});

const listClaimsFx = createEffect(async (payload: DAT2.Internal.Request.ListClaims) => {
    const resp = await API2.myClaimInternal.listClaims(payload);
    const contracts = resp.list;
    return contracts.map(contract => {
        if (contract.eCode) contract.eCode = contract.eCode.replaceAll(' ', '');
        return contract;
    });
});

const todayListOfClaims = createStore<DAT2.Internal.ClaimItem[]>([]);
const contracts = createStore<DAT2.Internal.ClaimItem[]>([]);
const columns = createStore<string[]>([]);
const params = {
    count: createStore<number>(PAGE_LIMIT),
    offset: createStore<number>(0),
    initialLoad: createStore<boolean>(true),
    recount: createStore<boolean>(true),
    sort: createStore<{ direction: boolean; name: string }>({ direction: false, name: 'lastEntry' })
};
const activeFolder = createStore<{ folderType: FolderType; folderId?: number }>({
    folderType: FolderType.ALL_CLAIMS
});
const activeGlobalSearch = createStore<{ columnName: string; value: string }>({ columnName: 'actionName', value: '' });

todayListOfClaims.on(listClaimsFx.doneData, (state, payload) => {
    const filteredArray = payload.filter(item => isToday(item?.templateFieldAndValue?.Date_expiryDate as number));
    return _.uniqBy([...state, ...filteredArray], 'id');
});

const filters = {
    statuses: createStore<string | number | null>(null),
    globalSearch: createStore<{ columnName: string; value: string } | null>(null),
    folder: createStore<DAT2.Internal.ListClaimsFilters>({}),
    efficiency: createStore<{ date?: number; claimsOverdue: boolean } | null>(null)
};
const setFilters = {
    statuses: createEvent<string | number | null>(),
    globalSearch: createEvent<{ columnName: string; value: string }>(),
    efficiency: createEvent<{ date?: number; claimsOverdue: boolean }>()
};
const setSort = createEvent<{ direction: boolean; name: string }>();

const combinedFilters = combine(
    { ...filters, templateId: sharedTemplateStores.templateId },
    ({ statuses, globalSearch, folder, efficiency, templateId }): DAT2.Internal.ListClaimsFilters => {
        let filterObj: DAT2.Internal.ListClaimsFilters = {};
        if (folder) {
            filterObj = { ...folder };
        }

        if (statuses) {
            filterObj.status = [{ label: ['', '', '', ''], value: [statuses] }];
        }

        if (globalSearch) {
            filterObj[globalSearch.columnName] = {
                filterType: 'BasicStringFilterElement',
                label: '',
                value: globalSearch.value
            };
        }

        if (efficiency) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            filterObj[`${templateId}#expiryDate`] = efficiency.date
                ? { low: efficiency.date, high: efficiency.date }
                : { high: subDays(today, 1).getTime() };
            filterObj[`${templateId}#expiryStatus`] = {
                low: 0,
                high: efficiency.claimsOverdue ? 0 : undefined
            };
        }

        return filterObj;
    }
);

const foldersCounts = createStore<{ [folderId: number]: number }>({});
const loadFolderCountFx = createEffect(
    async ({ folderId }: { folderId: number }): Promise<number> =>
        (
            await API2.myClaimInternal.listClaims({
                params: {
                    folderType: FolderType.FILTER,
                    folderId,
                    count: 1,
                    offset: 0,
                    initialLoad: false
                },
                data: {
                    closed: false
                }
            })
        ).total
);

const requestMoreContracts = combine(
    { templateId: sharedTemplateStores.templateId, activeFolder, combinedFilters, columns, ...params },
    ({ templateId, activeFolder, combinedFilters, columns, sort, ...rest }): DAT2.Internal.Request.ListClaims => ({
        params: {
            folderType: activeFolder.folderType,
            folderId: activeFolder.folderId,
            searchIn: columns, // Api will only return data for columns specified with searchIn
            sort: `${sort.direction ? '' : '-'}${sort.name}`,
            ...convertNullValuesToUndefined(rest)
        },
        data: { filter: combinedFilters },
        templateId: templateId?.toString()
    })
);

const loadMoreContractsFx = attach({ source: requestMoreContracts, effect: listClaimsFx });
const newContractsLoaded = loadMoreContractsFx.doneData.filter({ fn: contracts => contracts.length > 0 });
const increasePageOffset = createEvent<any>();
const reloadContracts = createEvent<void>();

const contractsApi = createApi(contracts, reducers.contracts);
const resetContracts = createEvent();
const reloadContractsFx = createEffect(() => {
    resetContracts();
    return loadMoreContractsFx();
});

const loadFolderFilterFx = createEffect(
    async ({
        folderId,
        storedFilterId
    }: {
        folderType: FolderType;
        folderId?: number;
        storedFilterId?: number;
    }): Promise<DAT2.Internal.ListClaimsFilters> => {
        if (folderId && storedFilterId) {
            return await API2.myClaimInternal.getFilter({ storedFilter: storedFilterId });
        }
        return {};
    }
);

forward({
    from: reloadContracts,
    to: reloadContractsFx
});

const isMobileSearchVisible = createStore<boolean>(false);
const toggleMobileSearch = createEvent<void>();
isMobileSearchVisible.on(toggleMobileSearch, _ => !_);

listUserSettings.on(loadUserSettingsFx.doneData, (_, state) => state);
columns.on(
    listUserSettings.updates.map(
        ({ listerSettings }) => listerSettings.find(setting => setting.name === CLAIMS_LISTER_NAME)?.visibleColumns
    ),
    (_, state) => state
);

activeFolder.on(loadFolderFilterFx.done, (_, { params: { folderId, folderType } }) => ({
    folderType,
    folderId
}));

params.sort.on(setSort, (_, state) => state);

const setSearchTarget = debounce({ source: setFilters.globalSearch, timeout: 500 });
filters.globalSearch.on(setSearchTarget, (_, filter) => (filter && filter.value !== '' ? filter : null));
activeGlobalSearch.on(setSearchTarget, (_, state) => state);

filters.statuses.on(setFilters.statuses, (_, filter) => filter);
filters.folder.on(loadFolderFilterFx.doneData, (_, filter) => filter);

filters.efficiency.on(setFilters.efficiency, (_, filter) =>
    !filter || (!filter.date && !filter.claimsOverdue) ? null : filter
);

const refreshTemplateData = createEvent<{ contractId: number; data: { [fieldName: string]: string | number } }>();
contracts.on(refreshTemplateData, (currentContracts, { contractId, data }) => {
    const index = currentContracts.findIndex(contract => contract.id === contractId);
    if (index !== -1) {
        const newContracts = [...currentContracts];
        const updatedContract = {
            ...currentContracts[index],
            templateFieldAndValue: { ...currentContracts[index].templateFieldAndValue, ...data }
        };
        newContracts.splice(index, 1, updatedContract);
        return newContracts;
    }
});

export const contractsListEvents = {
    newContractsLoaded,
    increasePageOffset,
    ...contractsApi,
    resetContracts,
    reloadContracts,
    setFilters,
    setSort,
    toggleMobileSearch,
    refreshTemplateData
};
export const contractsListEffects = {
    loadMoreContractsFx,
    reloadContractsFx,
    loadUserSettingsFx,
    loadFolderFilterFx,
    loadFolderCountFx
};
export const contractsListStores = {
    listUserSettings,
    params,
    contracts,
    columns,
    filters,
    activeFolder,
    combinedFilters,
    isMobileSearchVisible,
    activeGlobalSearch,
    foldersCounts,
    todayListOfClaims
};
