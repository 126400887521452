import styled from 'styled-components/macro';

interface Props {
    own?: boolean;
}

export const Container = styled.div<Props>`
    display: flex;
    width: fit-content;
    max-width: 70%;
    margin-bottom: 48px;
    flex-direction: column;
    ${({ own }) => own && `margin-left: auto;`}
`;

export const MessageWrapper = styled.div<Props>`
    display: flex;
    width: fit-content;
    padding: 16px;
    margin-bottom: 10px;
    ${({ own, theme }) => own && `color: ${theme.colors.white};`}
    background-color: ${({ own, theme }) => (own ? theme.colors.blueDark : theme.colors.white)};
    border-radius: 3px;
`;

export const TimeWrapper = styled.div<Props>`
    width: fit-content;
    ${({ own }) => (own ? 'margin-left: auto;' : 'margin-right: auto;')}
`;
