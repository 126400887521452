import { attach, sample } from 'effector';

import { sharedSubjectsDataEffects } from '@dat/shared-models/addressBook';

import { subjectsDataEffects, subjectsDataEvents } from './index';
import { hasNetworkOwnerCustomerNumber } from './utils/hasNetworkOwnerCustomerNumber';
import { currentUserDataKey } from './constants';
import { contractEffects, contractStores } from '@dat/shared-models/contract';

const { setSubjectsDataByCustomerNumber } = subjectsDataEvents;
const { updateContractSubjectFx } = subjectsDataEffects;
const { getSubjectsFx, updateSubjectsFx } = sharedSubjectsDataEffects;
const { createOrUpdateContractFx, getContractFx } = contractEffects;
const { contractId } = contractStores;

/* customersSubjectsData */
sample({
    clock: [getSubjectsFx.done, updateSubjectsFx.done],
    fn: ({ params, result }) => ({
        customerNumber: hasNetworkOwnerCustomerNumber(params) ? params.networkOwnerCustomerNumber : currentUserDataKey,
        subjectsData: result
    }),
    target: setSubjectsDataByCustomerNumber
});

updateContractSubjectFx.use(
    attach({
        source: contractId,
        effect: async (contractId, templateData) => {
            const responseContractId = await createOrUpdateContractFx({
                contractId,
                templateData: {
                    entry: [...templateData]
                }
            });
            const currentContractId = contractId || responseContractId;
            await getContractFx(currentContractId);
        }
    })
);
