import styled from 'styled-components/macro';
import { CameraIcon } from '@wedat/ui-kit/components/Icons';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.phoneBig`
        margin-bottom: 32px;
    `}
`;

export const CameraIconStyled = styled(CameraIcon)`
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.white};
`;

export const Stub = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(37 148 254 / 40%);
    opacity: 0;
    transition: opacity 150ms;
`;

export const ImageWrap = styled.div`
    position: relative;
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        ${Stub} {
            opacity: 1;
        }
    }
`;

export const ButtonEdit = styled.button`
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    transition: color 150ms;

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const Text = styled.span`
    margin-left: 10px;
    display: block;
    ${({ theme }) => theme.typography.footnote};
    color: currentColor;
`;

export const ImageStyled = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 24px;
    object-fit: cover;
`;
