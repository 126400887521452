import React from 'react';
import { useStore } from 'effector-react';

import { pluginCarsStores } from '../../stores/pluginCars';

import { Container } from './styles';
import { PluginCars as PluginCarsPlugin } from '@dat/plugin-cars';

export const PluginCars = () => {
    const pluginCarsOptions = useStore(pluginCarsStores.pluginCarsOptions);
    const isPluginCarsOpen = useStore(pluginCarsStores.isPluginCarsOpen);

    if (!isPluginCarsOpen) {
        return null;
    }

    return (
        <Container id="plugin-cars-gallery-container">
            <PluginCarsPlugin {...pluginCarsOptions} />
        </Container>
    );
};
