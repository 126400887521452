import styled from 'styled-components/macro';

interface ExpiryDateFormatContainerProps {
    color?: string;
}

export const ExpiryDateFormatContainer = styled.div<ExpiryDateFormatContainerProps>`
    ${(p: ExpiryDateFormatContainerProps) => p?.color && `color: ${p.color};`}
    width: 100px;

    svg {
        vertical-align: sub;
    }
`;

export const NoDateWrapper = styled.div`
    display: inline-block;
    margin-left: 10px;
`;
