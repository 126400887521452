import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit';

export const UsersListContainer = styled.div`
    min-height: 0;
    display: flex;
    flex-direction: column;
`;

export const AddButton = styled(Button)`
    padding: 5px 20px;
    width: fit-content;
`;
