import styled from 'styled-components/macro';
import { SmileUnavailableIcon, HomeIcon } from '../../../assets/icons';
import { media } from '@wedat/ui-kit/mediaQueries';
import { StyledNavLink } from '../../../components/StyledNavLink';

export const Root = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
`;

export const Wrap = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    max-width: 870px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const SmileUnavailableIconStyled = styled(SmileUnavailableIcon)`
    margin-bottom: 38px;
    width: 240px;
    height: 240px;

    ${media.phone`
      width: 200px;
      height: 200px;
    `}
`;

export const Description = styled.p`
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    text-align: center;

    ${media.phone`
      font-size: 20px;
      line-height: 30px;
    `}
`;

export const TextStyled = styled.span`
    font-weight: 500;
    font-size: 18px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    ${media.tabletSmall`
        width: 100%;

        &:hover {
            ${TextStyled} {
                color: ${({ theme }) => theme.colors.blueDark};
            }
        }
    `}
`;

export const HomeIconStyled = styled(HomeIcon)`
    margin-right: 20px;
`;

export const NavLinkStyled = styled(StyledNavLink)`
    transition: color 0.2s;
    border: 2px solid ${({ theme }) => theme.colors.gray_10};
    border-radius: 2px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    & svg {
        transition: fill 0.2s;
    }

    &:hover {
        color: #40a9ff;

        & svg {
            fill: #40a9ff;
        }
    }

    ${media.phone`
        justify-content: center;
        width: 100%;
    `}
`;
