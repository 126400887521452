import React from 'react';
import { CalendarNew } from '@wedat/ui-kit/assets/Icon';
import { useStore } from 'effector-react';
import { pluginStores } from '../../../stores/plugin';
import { EfficiencyStatus } from '../../../stores/efficiency/types';
import { ExpiryDateFormatContainer, NoDateWrapper } from './styles';
import CalendarChecked from '@wedat/ui-kit/assets/Icon/CalendarChecked';
import CalendarCrossed from '@wedat/ui-kit/assets/Icon/CalendarCrossed';
import { getEfficiencyColor } from '../../../utils/getEfficiencyColor';
import { DateFormatWrapper } from '../ListItem/Desktop/styles';
import { EXPIRE_YELLOW_FLAG_DEFAULT } from './constants';
import { DateFormat } from '../DateFormat';

export interface ExpiryDateFormatProps {
    date?: Date;
    efficiencyStatus: EfficiencyStatus;
}

export const ExpiryDateFormat: React.FC<ExpiryDateFormatProps> = ({ date, efficiencyStatus }) => {
    const expireYellowFlag =
        useStore(pluginStores.pluginOptions)?.settings?.efficiency?.expireYellowFlag || EXPIRE_YELLOW_FLAG_DEFAULT;

    const renderDate = () => {
        if (!date) {
            return (
                <>
                    <CalendarNew width={18} height={18} />
                    <NoDateWrapper>--</NoDateWrapper>
                </>
            );
        }
        return (
            <>
                {efficiencyStatus === EfficiencyStatus.COMPLETED_ON_TIME && <CalendarChecked width={16} height={20} />}
                {efficiencyStatus === EfficiencyStatus.COMPLETED_NOT_ON_TIME && (
                    <CalendarCrossed width={16} height={20} />
                )}
                {efficiencyStatus === EfficiencyStatus.NOT_COMPLETED && <CalendarNew width={18} height={18} />}
                <DateFormatWrapper>
                    <DateFormat>{date.toISOString()}</DateFormat>
                </DateFormatWrapper>
            </>
        );
    };

    return (
        <ExpiryDateFormatContainer color={getEfficiencyColor({ date, efficiencyStatus, expireYellowFlag })}>
            {renderDate()}
        </ExpiryDateFormatContainer>
    );
};
