import { parse, X2jOptionsOptional } from 'fast-xml-parser';

const options: X2jOptionsOptional = {
    attributeNamePrefix: '',
    textNodeName: '_text',
    ignoreAttributes: false,
    ignoreNameSpace: true,
    allowBooleanAttributes: false, // empty attrs
    parseNodeValue: true, // parse to number or boolean
    parseAttributeValue: true, // parse to number or boolean
    trimValues: true,
    parseTrueNumberOnly: true,
    arrayMode: false
};

export const parseXML = <T>(XML: string): T => {
    let result = parse(XML, options, true);

    result = result?.Envelope?.Body;

    if (result) {
        const firstKey = Object.keys(result) as unknown as keyof typeof result;

        result = result[firstKey];
    }

    if (!result) {
        throw new Error('Parsing error');
    }

    return result;
};

export const parseValueAsXML = (value: any) => parse(`<value>${value}</value>`, options, true).value;
