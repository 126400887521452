import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { elementsSVG_css } from './elementsSVG';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { useEmbedElementHeightByAspectRatio } from '../../utils/useElementHeight';
// import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';

import './fastTrackSVG.css';

export interface FastTrackSVGProps {
    selectedElementsId: string[];
    markElementId: string | undefined;
    onElementClick: (id: string) => void;
    isMobile: boolean;
}

export const FastTrackSVG: FC<FastTrackSVGProps> = ({
    selectedElementsId,
    markElementId,
    onElementClick,
    isMobile
}) => {
    const [elementSVG, setElementSVG] = useState<Document | null>(null);

    // const [svgHeight, setSVGHeight] = useState<string | undefined>('auto');

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    const fastTrackSVG = useStore(fastTrackElementModel.stores.$fastTrackSVG);

    const maxWidthSVG = pluginOptions?.settings?.maxWidthSVG;

    // const genericSVGGraphics = useStore(genericSVGGraphicModel.stores.genericSVGGraphics);

    const embedRef = useEmbedElementHeightByAspectRatio(1920 / 1080);

    function onLoadSVG() {
        // let fastTrackSVG = document.getElementById('fastTrackSVG') as HTMLObjectElement;
        // let svgContainer = document.getElementById('svgContainer') as HTMLObjectElement;

        if (embedRef.current) {
            let fastTrackSVG = embedRef.current;
            const svgDoc = fastTrackSVG.getSVGDocument();
            if (svgDoc) {
                const styleElement = svgDoc.createElementNS('http://www.w3.org/2000/svg', 'style');
                styleElement.textContent = elementsSVG_css; // add whatever you need here
                svgDoc.children[0].appendChild(styleElement);
                // debugger;
            }

            setElementSVG(svgDoc);
            // setSVGHeight('auto');
        }
    }

    useEffect(() => {
        function onElementClickEvent(event: any) {
            onElementClick(event.target.parentElement.id);
        }
        elementSVG &&
            elementSVG.querySelectorAll('[id^="DATID_"]>path').forEach(element => {
                element.addEventListener('click', onElementClickEvent);
            });
    }, [elementSVG, onElementClick]);

    useEffect(() => {
        if (elementSVG) {
            const markElements = elementSVG.getElementsByClassName('markElement');
            Array.from(markElements).forEach(element => element.classList.remove('markElement'));
            if (markElementId) {
                elementSVG
                    .querySelectorAll('[id^=' + markElementId + ']>path')
                    .forEach(element => element.classList.add('markElement'));
            }
        }
    }, [markElementId, elementSVG]);

    useEffect(() => {
        if (elementSVG && selectedElementsId) {
            const selectedElements = elementSVG.getElementsByClassName('selectedElements');
            Array.from(selectedElements).forEach(element => element.classList.remove('selectedElements'));
            selectedElementsId.forEach(
                selectElementId =>
                    elementSVG &&
                    elementSVG
                        .querySelectorAll('[id^=' + selectElementId + ']>path')
                        .forEach(element => element.classList.add('selectedElements'))
            );
        }
    }, [selectedElementsId, elementSVG]);

    return (
        <div className={isMobile ? 'weDat-ft-main-svg-mobile' : 'weDat-ft-main-svg'}>
            <object width="100%">
                <embed
                    key={fastTrackSVG?.graphicId}
                    ref={embedRef}
                    style={
                        isMobile && fastTrackSVG?.graphicId === 'default'
                            ? {
                                  transform: 'rotate(90deg)',
                                  width: window.screen.height - 420 + 'px',
                                  height: 'auto'
                              }
                            : {
                                  maxWidth: maxWidthSVG || '40em',
                                  width: '100%'
                              }
                    }
                    type="image/svg+xml"
                    src={fastTrackSVG?.graphicSVG}
                    onLoad={onLoadSVG}
                />
            </object>
        </div>
    );
};
