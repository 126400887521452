//
// for test jest run
//
import { datProdServicesUrls } from '../baseParameters';
import { CredentialsWithSignatures, GenerateTokenParams } from '../types/api2datTypes';
import { sendCommonSoapRequest } from '../utils/soapRequest';

const restTokenServiceUrl =
    datProdServicesUrls.restTokenServiceUrl ||
    'https://www.dat.de/AuthorizationManager/service--/endpoint/tokenService';

export async function generateRestToken(params: GenerateTokenParams, url?: string) {
    const options = {
        method: 'POST',
        body: JSON.stringify({ ...params, action: 'generateToken' })
        // headers: {}
    };

    const result = await fetch(url || restTokenServiceUrl, options);
    const resText = await result.text();

    if (result.status !== 200) throw new Error(`Response status: ${result.status} text: ${resText}`);

    return resText.slice(1, -1);
}

const urlTokenService =
    datProdServicesUrls.soapMyClaimTokenService ||
    'https://www.dat.de/myClaim/soap/v2/MyClaimExternalAuthenticationService';

export async function generateSoapToken(inputParams: CredentialsWithSignatures, url?: string) {
    const params = { ...inputParams };

    const requestData = { request: params };

    const SoapResult = await sendCommonSoapRequest(url || urlTokenService, 'generateToken', undefined, requestData);

    return SoapResult.Envelope.Body.generateTokenResponse.token;
}

let tokenCache: { params: CredentialsWithSignatures; token: string; generateTime: number }[] = [];
const tokenCacheTTL: number = 25 * 60 * 1000; // 25min

export async function getTokenWithCache(params: CredentialsWithSignatures, url?: string) {
    const foundValIndex = tokenCache.findIndex(
        cacheVal =>
            cacheVal.params.customerNumber === params.customerNumber &&
            cacheVal.params.customerLogin === params.customerLogin &&
            cacheVal.params.customerPassword === params.customerPassword &&
            cacheVal.params.interfacePartnerNumber === params.interfacePartnerNumber &&
            cacheVal.params.interfacePartnerSignature === params.interfacePartnerSignature
    );

    if (foundValIndex !== -1 && tokenCache[foundValIndex].generateTime + tokenCacheTTL > new Date().getTime()) {
        return { token: tokenCache[foundValIndex].token, cache: true };
    }

    const token = await generateSoapToken(params, url);

    if (token) {
        const newCacheVal = { params, token, generateTime: new Date().getTime() };
        if (foundValIndex !== -1) tokenCache[foundValIndex] = newCacheVal;
        else tokenCache.push(newCacheVal);
        return { token, cache: false };
    }

    return undefined;
}

export async function clearTokenCache(token?: string) {
    if (token) tokenCache = tokenCache.filter(cacheVal => cacheVal.token !== token);
    else tokenCache = [];
}
