import { merge } from 'effector';
import { vehicleSelectionModalEffects } from '../VehicleSelectionModal/store';

const shouldOpenModal = merge([
    vehicleSelectionModalEffects.getVehicleByOCRFx.pending,
    vehicleSelectionModalEffects.getVehicleByNumberPlateFx.pending,
    vehicleSelectionModalEffects.getVehicleByVinFx.done
]);

export const mainEvents = {
    shouldOpenModal
};
