import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { commonModel } from '../../stores/commonModel';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';

import './summaryPanelMin.css';

export const SummaryPanelMin = () => {
    const { t } = useTranslation();

    const contract = useStore(fastTrackElementModel.stores.$contract);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    const resultFormat = pluginOptions?.settings?.resultFormat;

    const formatter = new Intl.NumberFormat(
        resultFormat?.locales || 'ru-RU',
        resultFormat?.numberFormatOptions || {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        }
    );

    const calculationSummary = contract?.Dossier.RepairCalculation?.CalculationSummary;
    const totalGrossCosts = calculationSummary?.TotalGrossCorrected || calculationSummary?.TotalGrossCosts || 0;

    return (
        <div className="weDat-ft-summaryPanelMin">
            <Button
                size="large"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                    commonModel.events.setSummaryPanelOpened(true);
                }}
            />
            <h2 className="rotated">{t('fastTrack.summaryPanelHeader')}</h2>
            <div className="weDat-ft-summaryPanelMin-bottom">
                <div className="weDat-ft-calcResult rotated">
                    <div className="weDat-ft-calcResult-header">{t('fastTrack.CalculationSummary.TotalPriceVAT')}</div>
                    <div className="weDat-ft-calcResult-price">{formatter.format(totalGrossCosts)}</div>
                </div>
            </div>
        </div>
    );
};
