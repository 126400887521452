import styled, { css } from 'styled-components';
import { isIOS } from 'react-device-detect';

interface SlideMenuPropStyled {
    isOpen: boolean;
    heightContainer: number;
    isBurger?: boolean;
}

export const SlideUpMenuStyled = styled.div<SlideMenuPropStyled>`
    position: fixed;
    z-index: 1001;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateY(100%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: transform 150ms;

    ${({ isOpen }) =>
        isOpen &&
        css`
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            transform: translateY(0);
        `}

    ${({ isBurger }) =>
        isBurger &&
        css`
            z-index: 999;
            bottom: 56px;

            ${isIOS &&
            css`
                bottom: 76px;
            `}
        `}
`;

export const Container = styled.div``;

export const Stub = styled.div<{ isOpen: boolean }>`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transform: scale(0);
    transition: opacity 150ms;

    ${({ isOpen }) =>
        isOpen &&
        css`
            opacity: 1;
            transform: scale(1);
        `}
`;
