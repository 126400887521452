import styled, { css } from 'styled-components/macro';

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    outline: 0;
`;

interface ItemProps {
    isActive: boolean;
}

export const Item = styled.button<ItemProps>`
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    height: 50px;
    ${({ theme }) => theme.typography.note};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 150ms;

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    ${({ isActive, theme }) =>
        isActive &&
        css`
            background-color: ${theme.colors.blue['100']};
        `}
`;

export const Flag = styled.img`
    margin-right: 10px;
    width: 24px;
    height: 16px;
`;
