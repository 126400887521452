import {
    DatProductsPaths,
    ErrorCodes,
    MyClaimServices,
    ValuateFinanceServices,
    VehicleRepairServices
} from './constants';
import { parseXML } from './utils/soap/parseXML';
import { apiEvents, apiStores } from './stores';
import { refreshTokenInterceptors } from './utils/refreshTokenInterceptors';
import { datAxiosInstance } from './datAxiosInstance';
import { createSoapRequest, SoapData, SoapRequestPayload } from './utils/soap/createSoapRequest';

// "language" header is a fix for broken DAT-methods (calculateContract, addManualPositions)
refreshTokenInterceptors(datAxiosInstance, { Language: apiStores?.country.getState() });

interface Param<Values extends SoapData> extends SoapRequestPayload<Values> {
    product: DatProductsPaths;
    service?: VehicleRepairServices | MyClaimServices | ValuateFinanceServices;
}

const datAxios = <Response, Values extends SoapData = {}>({
    service,
    product,
    ...soapRequestData
}: Param<Values>): Promise<Response> => {
    const host = apiStores.apiUrl.getState();
    const path = `${product}/${service || ''}`;
    const url = new URL(path, host).href;
    const lang = apiStores?.country.getState();

    return datAxiosInstance({
        url,
        data: createSoapRequest(soapRequestData),
        headers: {
            'accept-language': lang
        }
    })
        .then(XML => parseXML<Response>(String(XML)))
        .catch(ErrorXML => {
            const { faultcode, faultstring } = parseXML<DAT2.ErrorResponse>(String(ErrorXML));
            if (faultcode === ErrorCodes.AuthorizationFailed) {
                apiEvents.tokenExpired();
            }

            // TODO: get rid of error?
            throw new Error(faultstring);
        });
};

export default datAxios;
