/*
 * Normally equipment positions in "getExistingEquipmentN"
 * or "getPossibleEquipmentN" responses should contain "DatEquipmentId" property,
 * but DAT.EquipmentPosition interface has this prop set to optional,
 * so we need to validate equipment manually.
 */
import { filterArrayOfObjectsByExistingKeys } from '@dat/core/utils';

export const excludeEquipmentWithoutIds = (equipment: DAT2.EquipmentPosition[]) =>
    filterArrayOfObjectsByExistingKeys(equipment, ['DatEquipmentId']);
