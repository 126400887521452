import { createEvent } from 'effector';

const openModalOrCreateOrderWithType = createEvent();

const createOrderWithType = createEvent<DAT2.ClaimBuilder>();

export const orderEvents = {
    createOrderWithType,
    openModalOrCreateOrderWithType
};
