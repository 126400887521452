import React, { FC } from 'react';
import { useStore } from 'effector-react';

import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { ValuateFinancePage } from './pages/ValuateFinancePage';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => {
    const isLoading = useStore(commonStores.isLoading);

    return (
        <PluginProvider name="valuate-finance" options={options} onInit={pluginEvents.initPlugin} isLoading={isLoading}>
            <ValuateFinancePage />
        </PluginProvider>
    );
};

export default App;
