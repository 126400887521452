import styled, { css } from 'styled-components/macro';
import { AdditionalIcon } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';

export const TabPhotoPanelStyled = styled.div`
    margin: 5px 10px;
`;

export const Title = styled(Text)`
    margin: 0;
    padding: 5px 10px;

    font-weight: 600;

    color: ${({ theme }) => theme.colors.black};
`;

export const Photos = styled.div`
    display: flex;
    flex-wrap: wrap;

    padding-right: 10px;
`;

export const PhotoStyled = styled.div`
    width: 33.33333%;

    padding: 3px 0 3px 10px;
`;

export const PhotoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
`;

export const PhotoTitle = styled.h3`
    margin: 2px 0 0;

    line-height: 1.2;
    font-size: 10px;
    text-align: center;

    color: ${({ theme }) => theme.colors.black};
`;

const imageStyles = css`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 5px;
`;

export const Image = styled.img`
    ${imageStyles};
`;

export const AdditionalIconStyled = styled(AdditionalIcon)`
    ${imageStyles};
`;
