import { combine, createEvent, createStore } from 'effector';

import { PluginCarsOptions } from '@dat/plugin-cars';
import {
    GroupedPluginCarsPhotos,
    ConfigForPluginCars,
    PluginCarsGroupStep,
    PluginCarsResult
} from '../../types/pluginCars';

const pluginCarsCompleted = createEvent<PluginCarsResult>();
const groupedPhotosReceived = createEvent<GroupedPluginCarsPhotos>();
const steps = createStore<PluginCarsGroupStep[]>([]);
const configForPluginCars = createStore<ConfigForPluginCars>({});
const pluginCarsOptions = combine(
    [steps, configForPluginCars],
    ([steps, configForPluginCars]): PluginCarsOptions => ({
        // selector is used because plugin-cars takes size of container by it's selector
        selector: '#plugin-cars-gallery-container',
        isComponent: true,
        completeFormCallback: pluginCarsCompleted,
        ...configForPluginCars,
        steps
    })
);

const isPluginCarsAvailable = steps.map(steps => !!steps.length);
const openPluginCars = createEvent<unknown>();
const closePluginCars = createEvent<any>();
const isPluginCarsOpen = createStore(false)
    .on(openPluginCars, () => true)
    .on(closePluginCars, () => false)
    .reset(pluginCarsCompleted);

export const pluginCarsEvents = {
    pluginCarsCompleted,
    groupedPhotosReceived,
    openPluginCars,
    closePluginCars
};
export const pluginCarsStores = {
    steps,
    pluginCarsOptions,
    isPluginCarsAvailable,
    isPluginCarsOpen,
    configForPluginCars
};
