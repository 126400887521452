import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { sendMailEffects } from './stores/sendMail';
import { Main } from './components/Main';
import { pluginEvents } from './stores/plugin';
import { PluginOptions } from './types/plugin';
import './stores/init';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = React.memo(({ options }) => {
    const isLoading = useStore(sendMailEffects.sendMailFxWithAttachments.pending);

    return (
        <PluginProvider name="mail" options={options} onInit={pluginEvents.initPlugin} isLoading={isLoading}>
            <Main />
        </PluginProvider>
    );
});

export default App;
