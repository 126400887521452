import React from 'react';
import { combine, createEvent, createStore } from 'effector';
import _ from 'lodash';
import { format } from 'date-fns';
import deepmerge from 'deepmerge';

import { contractStores } from '@dat/shared-models/contract';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { defaultFormBuilderData } from '../../constants/defaultFormBuilderData';
import { i18nStores } from '@dat/shared-models/i18n';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { extractFormBuilderDataFromContract } from '../../utils/extractFormBuilderDataFromContract';
import { splitLocale } from '@dat/core/utils/splitLocale';

import { VehicleSelectionModalPluginOptions, VehicleSelectionModalPluginResult } from '@dat/vehicle-selection-modal';
import { FormBuilderPluginOptions } from '@dat/form-builder';
import { GrapaPluginOptions } from '@dat/grapa';
import { FastTrackPluginOptions } from '@dat/fast-track';
import { LabourRatesPluginOptions } from '@dat/labour-rates';
import { DatepickerFieldProps } from '@wedat/ui-kit/Formik';
import { GalleryPluginOptions } from '@dat/gallery';
import { CalculationPluginOptions } from '@dat/calculation';
import { AssignPartnerPluginOptions } from '@dat/assign-partner';
import { FieldInputProps } from 'formik';
// import { RepairPositionWithRowKey } from '@dat/grapa/src/stores/repairPositionsModel';
// import { EquipmentPosition } from '@dat/api2dat/types/contractTypes';
import { sharedEquipmentStores } from '@dat/shared-models/contract/Dossier/Vehicle/Equipment';
import { VehicleInputsFormValues } from '../../pages/Claim/Opening/VehicleInputs/types';
import { sharedContractStatusStores } from '@dat/shared-models/contract/Status';
import { sharedUserStores } from '@dat/shared-models/user';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { ResultGrapaDossier } from '@dat/grapa/src/types/plugin';
import { DamageSelectorPluginOptions } from '@dat/damage-selector';
import { sharedLoginStores } from '@dat/shared-models/auth/login';
import { createToggle } from '@dat/core/utils/effector/createToggle';
import { DataField } from '@dat/form-builder/src/types/dataScheme';
import { StepperPluginOptions } from '@dat/claim-stepper';
import { ClientConfiguration } from '@dat/ai-claim/src/types/plugin';
import { MailPluginOptions } from '@dat/mail';
import { extractVinResultFromContract } from '@dat/core/utils/extractVinResultFromContract';
import { VINEquipment } from '@dat/core/types';
// import { getParsedArraySafe } from '@dat/api2/utils';

//
/*** Vehicle selection ***/
//

// Vehicle should be fully selected, otherwise "createOrUpdateContract" will work incorrect.
// This comment is just a tip, because VSM has own validation which checks the selection of vehicle.

const vehicleSelectionInputsChanged = createEvent<VehicleInputsFormValues>();

const [vehicleSelectionPluginCarsOpened, vehicleSelectionPluginCarsTrigger] = createToggle(false);
const vehicleSelectionCompleted = createEvent<VehicleSelectionModalPluginResult>();
const vehicleSelectionPluginOptions = combine<VehicleSelectionModalPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId,
    onComplete: (result: VehicleSelectionModalPluginResult) => vehicleSelectionCompleted(result),
    pluginCarsToggleCallback: () => vehicleSelectionPluginCarsTrigger()
});

//
/*** Labour rates ***/
//
const labourRatesPluginOptions = createStore<LabourRatesPluginOptions>({
    isComponent: true
});

type FormBuilderName = 'form' | 'calculationForm'; // calculationForm invokes calculateContractN
type FormBuilderAdditionalData = { formName: FormBuilderName };
type FormBuilderBlurPayload = React.FocusEvent<HTMLInputElement & FormBuilderAdditionalData>;
type FormBuilderChangePayload = FieldInputProps<any> & FormBuilderAdditionalData;

//
/*** Form builder ***/
//
const formBuilderFieldBlurred = createEvent<FormBuilderBlurPayload>();
const formBuilderFieldChanged = createEvent<FormBuilderChangePayload>();

window.addEventListener('formBuilderFieldChanged', ((e: CustomEvent<FormBuilderChangePayload>) => {
    formBuilderFieldChanged(e.detail);
}) as EventListener);

const createFormBuilderPluginOptions = (name: FormBuilderName) =>
    combine(
        [
            sharedTemplateStores.productsConfiguration,
            contractStores.initialContract,
            sharedContractStatusStores.statusId,
            sharedConfigurationStores?.customerSettings?.role,
            sharedUserStores.customerNumber
        ],
        ([productsConfiguration, contract, statusId, customerRole, customerNumber]): FormBuilderPluginOptions => {
            // Delete default state form for calculationForm, because we need hide  this block without data from server
            const dataFormDefault = name === 'calculationForm' ? [] : defaultFormBuilderData;
            const initialData = productsConfiguration?.['claim-management']?.[name] || dataFormDefault;
            const currentData = contract ? extractFormBuilderDataFromContract({ initialData, contract }) : initialData;

            const getFormatField = (format: DataField['format']) => {
                if (format === 'alternative') {
                    return 'yyyy-MM-dd';
                }

                if (format === 'timePicker') {
                    // TODO: need two formats
                    return "yyyy-MM-dd'T'HH:mm:ss";
                }

                return 'dd.MM.yy';
            };

            /* Handlers */
            const handleDatepickerEditEnd: DatepickerFieldProps['onEditEnd'] = (_value, _formikContext, field) => {
                // format can be 'standart' | 'alternative' | 'timePicker'
                const formatField = getFormatField(field.configField?.format);

                formBuilderFieldBlurred({
                    target: {
                        name: field.name,
                        value: field.value && format(field.value, formatField),
                        formName: name
                    }
                } as FormBuilderBlurPayload);
            };

            const objectWithBlurHandler = {
                onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                    formBuilderFieldBlurred({
                        ...event,
                        target: {
                            ...event.target,
                            formName: name
                        }
                    } as FormBuilderBlurPayload);
                }
            };

            const objectWithUserChangeHandler = {
                onUserChange: (data: FieldInputProps<any>) => {
                    formBuilderFieldChanged({
                        ...data,
                        formName: name
                    });
                }
            };

            return {
                isComponent: true,
                horizontalFields: true,
                data: currentData,
                options: {
                    statusId,
                    customerRole,
                    customerNumber
                },
                onComplete: _.noop,
                globalHandlers: {
                    string: objectWithBlurHandler,
                    integer: objectWithBlurHandler,
                    float: objectWithBlurHandler,
                    memo: objectWithBlurHandler,
                    boolean: objectWithUserChangeHandler,
                    radio: objectWithUserChangeHandler,
                    select: objectWithUserChangeHandler,
                    subject: objectWithUserChangeHandler,
                    date: {
                        onEditEnd: handleDatepickerEditEnd
                    }
                }
            };
        }
    );

const formBuilderPluginOptions = createFormBuilderPluginOptions('form');
const formBuilderPluginCalculationOptions = createFormBuilderPluginOptions('calculationForm');

//
/*** Fast-track ***/
//
const fastTrackPluginOptions = combine({
    contractId: contractStores.contractId,
    datECode: sharedVehicleStores.datECode,
    currency: sharedTemplateStores.templateSettings.currency,
    productsConfiguration: sharedTemplateStores.productsConfiguration,
    locale: i18nStores.locale,
    token: sharedLoginStores.token
}).map<FastTrackPluginOptions>(({ contractId, datECode, currency, productsConfiguration, locale, token }) => ({
    isComponent: true,
    credentialsWithPartnerSignature: undefined,
    locale,
    token,
    settings: deepmerge(
        {
            contractId,
            datECode,
            debugingMode: false,
            createNewClaimAvailable: false,
            showContractFields: false,
            showPredefinedVehicleSelection: false,
            fastTrackElementsMenuModal: true,
            saveFTDamageOnChange: true,
            fastTrackElementsMenu: [
                {
                    menuGroupId: 'glasses',
                    menuGroupLabel: 'Glasses',
                    // menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                    elementDatIDs: ['DATID_0043', 'DATID_0044', 'DATID_0047']
                },
                {
                    menuGroupId: 'doors',
                    menuGroupLabel: 'Doors',
                    // menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                    elementDatIDs: ['DATID_0041', 'DATID_0042']
                }
            ],
            mainSVGURLs: [
                `${process.env.PUBLIC_URL}/FastTrackSVG/fastTrack.svg`,
                `${process.env.PUBLIC_URL}/FastTrackSVG/SW/FT1_SW.svg`,
                `${process.env.PUBLIC_URL}/FastTrackSVG/SW/FT2_SW.svg`
            ],
            resultFormat: {
                numberFormatOptions: {
                    currency
                }
            }
        },
        productsConfiguration?.['fast-track']?.settings || {},
        { arrayMerge: (_destinationArray, sourceArray) => sourceArray }
    )
}));

interface VinEquipmentsProp {
    manufacturerCode: string;
    datNumber: string;
    shortName?: string;
}

//
/*** Grapa ***/
//
const grapaRepairPositionsChanged = createEvent<ResultGrapaDossier>();
const grapaPluginOptions = combine(
    [
        sharedRepairCalculationStores.repairPositions,
        sharedRepairCalculationStores.calculationSummary,
        sharedVehicleStores.datECode,
        i18nStores.locale,
        sharedLoginStores.token,
        sharedTemplateStores.templateSettings.country,
        sharedTemplateStores.templateSettings.contractType,
        sharedTemplateStores.productsConfiguration,
        sharedVehicleStores.vehicle,
        sharedEquipmentStores.equipment,
        contractStores.contract
    ],
    ([
        repairPositions,
        calculationSummary,
        datECode,
        locale,
        token,
        datCountryIndicator,
        contractType,
        productsConfiguration,
        vehicle,
        equipment,
        contract
    ]): GrapaPluginOptions => {
        const vinResult = contract ? extractVinResultFromContract(contract) : {};
        const vinEquipmentsFiltered = (vinResult?.vinRequestResultEquipment?.vinResultEquipment.filter(
            item => !!item.datNumber
        ) || []) as VinEquipmentsProp[];

        const vinEquipments: VINEquipment[] = vinEquipmentsFiltered.map(item => ({
            AvNumberDat: +item.datNumber,
            ManufacturerCode: item.manufacturerCode,
            ShortName: item.shortName || ''
        }));

        const VINEquipments = {
            VINEquipment: vinEquipments
        };

        return {
            isComponent: true,
            token,
            // onChangeRepairPositions: (repairPositions: RepairPositionWithRowKey[]) =>
            //     grapaRepairPositionsChanged({ repairPositions }),
            // onChangeDeselectedSeriesEquipment: (deselectedSeriesEquipment: EquipmentPosition[]) => {
            //     grapaRepairPositionsChanged({ deselectedSeriesEquipment });
            // },
            // onChangeSpecialEquipment: (specialEquipment: EquipmentPosition[]) => {
            //     grapaRepairPositionsChanged({ specialEquipment });
            // },
            onConfirmDossier: (dossier: ResultGrapaDossier) => {
                grapaRepairPositionsChanged(dossier);
            },
            locale,
            settings: deepmerge(
                {
                    debuggingMode: false,
                    useWorkTypeLevel: false,
                    contract: {
                        complexTemplateData: {
                            attr: {
                                templateType: contractType
                            }
                        },
                        Dossier: {
                            Country: datCountryIndicator,
                            Vehicle: {
                                DatECode: datECode || undefined,
                                ConstructionTime: vehicle?.ConstructionTime,

                                AlternativeVehicleType: vehicle?.AlternativeVehicleType,
                                AlternativeManufacturer: vehicle?.AlternativeManufacturer,
                                AlternativeBaseModel: vehicle?.AlternativeBaseModel,
                                AlternativeSubModel: vehicle?.AlternativeSubModel,

                                Equipment: {
                                    SeriesEquipment: equipment?.SeriesEquipment,
                                    DeselectedSeriesEquipment: equipment?.DeselectedSeriesEquipment,
                                    SpecialEquipment: equipment?.SpecialEquipment
                                },
                                VINResult: {
                                    VINEquipments: vinEquipments ? VINEquipments : vehicle?.VINResult?.VINEquipments
                                }
                            },
                            RepairCalculation: {
                                RepairPositions: {
                                    RepairPosition: repairPositions
                                },
                                CalculationSummary: calculationSummary
                            }
                        }
                    },
                    locale: {
                        country: splitLocale(locale).countryCode,
                        language: splitLocale(locale).languageCode,
                        datCountryIndicator
                    },
                    maxWidth: '100vw',
                    height: '100%',
                    showFullscreen: false
                },
                productsConfiguration?.['grapa'] || {},
                { arrayMerge: (_destinationArray, sourceArray) => sourceArray }
            )
        };
    }
);

//
/*** Labour Rates Generic ***/
//
const labourRatesGenericOptions = combine([sharedTemplateStores.productsConfiguration], ([productsConfiguration]) => ({
    isComponent: true,
    fieldsDictionary: productsConfiguration?.['labour-rates-generic']?.fieldsDictionary
}));

//
/*** Stepper ***/
//
const { productsConfiguration } = sharedTemplateStores;

const stepperConfiguration = productsConfiguration.map<ClientConfiguration | undefined>(
    productsConfiguration => productsConfiguration?.['claim-stepper']
);
const stepperPluginOptions = combine<StepperPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId,
    config: stepperConfiguration
});

//
/*** Gallery ***/
//
const galleryPluginOptions = combine<GalleryPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId
});

//
/*** Invoice ***/
//
const invoiceOptions = combine(
    [sharedTemplateStores.productsConfiguration],
    ([productsConfiguration]) => !productsConfiguration?.['claim-management'].hideBlocks.header.includes('invoice')
);

//
/*** Calculation ***/
//
const calculationPluginOptions = combine<CalculationPluginOptions>({
    isComponent: true
});
const isCalculationAvailable = sharedRepairCalculationStores.repairPositions.map(
    repairPositions => repairPositions.length > 0
);

//
/*** Printout ***/
//
const printoutPluginOptions = combine({
    isComponent: true,
    contractId: contractStores.contractId,
    vin: contractStores?.contract?.map(contract => contract?.Dossier?.Vehicle?.VehicleIdentNumber),
    registrationNumber: contractStores?.contract?.map(
        contract => contract?.Dossier?.Vehicle?.RegistrationData?.LicenseNumber
    )
});
//
/*** Assign Partner ***/
//
const assignPartnerPluginoptions = combine<AssignPartnerPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId
});

//
/*** Damage Selector ***/
//
const damageSelectorPluginOptions = combine<DamageSelectorPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId
});

//
/*** Mail ***/
//

const mailPluginOptions = combine<MailPluginOptions>({
    isComponent: true,
    contractId: contractStores.contractId
});

//
/*** Export ***/
//
export const containedPluginsEvents = {
    vehicleSelectionCompleted,
    formBuilderFieldBlurred,
    formBuilderFieldChanged,
    grapaRepairPositionsChanged,
    vehicleSelectionInputsChanged
};
export const containedPluginsStores = {
    vehicleSelectionPluginOptions,
    labourRatesGenericOptions,
    labourRatesPluginOptions,
    formBuilderPluginOptions,
    invoiceOptions,
    formBuilderPluginCalculationOptions,
    fastTrackPluginOptions,
    grapaPluginOptions,
    galleryPluginOptions,
    calculationPluginOptions,
    isCalculationAvailable,
    printoutPluginOptions,
    assignPartnerPluginoptions,
    stepperPluginOptions,
    damageSelectorPluginOptions,
    vehicleSelectionPluginCarsOpened,
    mailPluginOptions
};
