import { ColumnHeader } from './index';
import styled from 'styled-components/macro';

export interface CalendarColumnHeaderProps {
    hiddingPoint?: number;
}

export const CalendarColumnHeader = styled(ColumnHeader)<CalendarColumnHeaderProps>`
    flex: 0 0 135px;

    ${(p: CalendarColumnHeaderProps) =>
        p.hiddingPoint &&
        `
        @media screen and (max-width: ${p.hiddingPoint}px) {
            display: none;
        }
    `}
`;
