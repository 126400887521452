import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderEvents } from '../../stores/order';
import { Button } from '@wedat/ui-kit/components/Button';
import { PlusIcon } from '@wedat/ui-kit/components/Icons';
import { ButtonContainer, ButtonTextWrapper } from './styles';

interface NewOrderProps {
    withoutText?: boolean;
    fillHeight?: boolean;
}

export const NewOrder: React.FC<NewOrderProps> = ({ withoutText, fillHeight }) => {
    const { t } = useTranslation();

    const handleClick = () => orderEvents.openModalOrCreateOrderWithType();

    return (
        <Button onClick={handleClick} fillHeight={fillHeight}>
            <ButtonContainer withoutText={withoutText}>
                <PlusIcon /> {!withoutText && <ButtonTextWrapper>{t('newOrder.title')}</ButtonTextWrapper>}
            </ButtonContainer>
        </Button>
    );
};
