import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    width: 100%;
    position: relative;
`;

export const TextWrapper = styled.div`
    width: fit-content;
    margin: 0 auto;

    &::before {
        content: '';
        display: block;
        width: 43%;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.gray_40};
        opacity: 0.25;
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 123px;

        ${media.tablet`
            width: 41%;
        `}

        ${media.tabletSmall`
            width: 37%;
        `}

        ${media.phone`
            width: 35%;
        `}
    }

    &::after {
        content: '';
        display: block;
        width: 43%;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.gray_40};
        opacity: 0.25;
        position: absolute;
        right: 0;
        top: 50%;
        border-radius: 123px;

        ${media.tablet`
            width: 41%;
        `}

        ${media.tabletSmall`
            width: 37%;
        `}

        ${media.phone`
            width: 35%;
        `}
    }
`;
