import { sample } from 'effector';

import { guardPath } from '@dat/core/utils/effector/guardPath';
import { externalFilesEffects } from './index';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { pluginStores } from '../plugin';
import { ROUTES } from '../../constants/router';

const { loadFilesFx } = externalFilesEffects;
const { templateSettings } = sharedTemplateStores;
const { urlPath } = pluginStores;

guardPath({
    //remove sample after patronum fix https://github.com/effector/patronum/pull/186
    source: sample({ source: { js: templateSettings.externalJS, css: templateSettings.externalCSS } }),
    path: ROUTES.claim.root,
    basename: urlPath,
    target: loadFilesFx
});
