import { createEvent, createStore, createEffect, Store, combine } from 'effector';

import { sharedPartnersStores } from '@dat/shared-models/contract/Partners';
import { sharedUserStores } from '@dat/shared-models/user';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { API2 } from '@dat/api2';
import { PartnerMemberData } from '../../types/members';
import { RoleSortedDialogs } from './types';
import { isSenderCurrentUser } from '../../utils/isSenderCurrentUser';
import { getMemberCustomerNumber } from '../../utils/getMemberCustomerNumber';

const { contractPartners } = sharedPartnersStores;
const { username, customerNumber } = sharedUserStores;
const { customerSettings } = sharedConfigurationStores;

const createDialogFx = createEffect(API2.bff.chat.createDialog);
const sendMessageFx = createEffect(API2.bff.chat.sendMessage);
const readMessagesFx = createEffect(API2.bff.chat.readMessages);

const createContractBeforeDialogFx = createEffect<void, DAT2.ContractId>();

const openDialog = createEvent<DAT2.Plugins.Chat.Dialog['id']>();
const createPossibleDialogs = createEvent();
const createDialogEvent = createEvent<{ messageText: string }>();
const sendMessageEvent = createEvent<{ messageText: string }>();
const readMessagesEvent = createEvent();
const sendMessage = createEvent<{ messageText: string }>();

const dialogs = createStore<DAT2.Plugins.Chat.Dialog[]>([]);
const contractDialogs = createStore<DAT2.Plugins.Chat.Dialog[]>([]);
const partners: Store<PartnerMemberData[]> = contractPartners.map(contractPartners =>
    contractPartners.map(partner => ({
        customerNumber: partner.customerNumber,
        name: partner.name || 'SomeName',
        department: '',
        role: partner.role
    }))
);
const currentDialog = createStore<DAT2.Plugins.Chat.Dialog | null>(null);
const isDialogOpen = currentDialog.map(item => (item === null ? false : true));
const unreadMessagesCount = combine(
    [dialogs, customerNumber, username],
    ([dialog, customerNumber, username]) =>
        dialog
            .map(dialog =>
                dialog.messages.filter(
                    message =>
                        !message.read && !isSenderCurrentUser({ sender: message.sender, username, customerNumber })
                )
            )
            .flat(2).length
);
const roleSortedDialogs: Store<RoleSortedDialogs> = combine(
    [partners, dialogs, username, customerSettings.role, customerNumber],
    ([partners, dialogs, username, currentRole, customerNumber]) => {
        const sortedDialogs: RoleSortedDialogs = {};

        dialogs.forEach(dialog => {
            const companion = dialog.members.find(
                item => !isSenderCurrentUser({ sender: item, username, customerNumber })
            );
            let role: DAT2.CustomerRole;

            if (companion?.includes(String(customerNumber))) {
                role = currentRole;
            } else {
                const dialogPartner = partners.find(
                    partner => partner.customerNumber === getMemberCustomerNumber(companion)
                );
                if (!dialogPartner) return;
                role = dialogPartner?.role;
            }

            if (!sortedDialogs[role]) {
                sortedDialogs[role] = [];
            }
            sortedDialogs[role]?.push(dialog);
        });

        return sortedDialogs;
    }
);

export const dialogStores = {
    unreadMessagesCount,
    contractDialogs,
    currentDialog,
    partners,
    isDialogOpen,
    roleSortedDialogs,
    dialogs
};

export const dialogEvents = {
    openDialog,
    createPossibleDialogs,
    createDialogEvent,
    sendMessageEvent,
    readMessagesEvent,
    sendMessage
};

export const dialogEffects = {
    createDialogFx,
    sendMessageFx,
    readMessagesFx,
    createContractBeforeDialogFx
};
