import React from 'react';
import { TextArea } from '@wedat/ui-kit';
import { previewEvents, previewStores } from '../../../../stores/preview';
import { useStore } from 'effector-react';
import { pluginStores } from '../../../../stores/plugin';
import { DEFAULT_COMMENT_FIELD } from '../../../../stores/preview/constants';
import { useTranslation } from 'react-i18next';
import { CommentContainer } from './styles';

export const Comment = () => {
    const { t } = useTranslation();
    const fieldName =
        useStore(pluginStores.pluginOptions)?.settings?.preview?.commentSection?.fieldName || DEFAULT_COMMENT_FIELD;
    const value = useStore(previewStores.comment);

    return (
        <CommentContainer>
            <TextArea
                label={t('previewTabs.comment')}
                id={fieldName}
                name={fieldName}
                value={value}
                onChange={(event: any) => previewEvents.updateComment(event.target.value)}
            />
        </CommentContainer>
    );
};
