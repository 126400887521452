import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';

import { useIconComponent } from './hooks';

import { FieldsetStyled } from '../Fieldset';
import { Legend, LegendText } from '../Legend';

import { InputProps } from './types';

import { ErrorStyled, IconStyled, InputStyled, InputPrefix, InputWrapperStyled, Wrapper, LabelStyled } from './styles';

export const Input = React.forwardRef<ReactInputMask, InputProps>(
    (
        {
            label,
            value,
            name,
            type,
            placeholder = '',
            onChange,
            onFocus,
            onBlur,
            onClick,
            mask,
            unmaskFn,
            disabled = false,
            status = 'default',
            helperText,
            autoFocus = false,
            withIcon: withIconProp = false,
            stopPropagation,
            inputSize = 'default',
            prefix = '',
            borderRadius,
            ...rest
        },
        ref
    ) => {
        const { IconComponent, passwordInputType } = useIconComponent(status, type);
        const [focused, setFocused] = useState(false);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let { value } = e.target;

            if (unmaskFn) {
                value = unmaskFn(value);
            }

            e.target.value = value;

            onChange?.(e);
        };

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(true);

            onFocus?.(e);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);

            let { value } = e.target;

            e.target.value = value;

            onBlur?.(e);
        };

        const hasLabel = !!label;
        const hasPrefix = !!prefix;
        const filled = !!String(value) || hasPrefix;
        const showPlaceholder = focused || !hasLabel;
        const withIcon = withIconProp || Boolean(IconComponent);

        const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onClick?.();
            if (stopPropagation) {
                e.stopPropagation();
            }
        };

        return (
            <Wrapper {...{ status }} inputSize={inputSize} width={rest.width} onClick={handleOnClick}>
                <InputWrapperStyled inputSize={inputSize}>
                    {hasPrefix && <InputPrefix>{prefix}</InputPrefix>}

                    <InputStyled
                        type={type === 'password' ? passwordInputType : type}
                        value={value}
                        name={name}
                        mask={mask || ''}
                        maskPlaceholder=""
                        maskChar=""
                        uppercase={rest.uppercase}
                        status={status}
                        onFocus={handleFocus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                        placeholder={showPlaceholder ? placeholder : ''}
                        autoFocus={autoFocus}
                        withIcon={withIcon}
                        ref={ref}
                        inputSize={inputSize}
                        prefix={prefix}
                        {...rest}
                    />
                    <LabelStyled
                        status={status}
                        filled={filled}
                        focused={focused}
                        withIcon={withIcon}
                        disabled={disabled}
                    >
                        {label}
                    </LabelStyled>
                    {IconComponent && <IconStyled>{IconComponent}</IconStyled>}
                    <FieldsetStyled borderRadius={borderRadius} status={status} width={rest.width} disabled={disabled}>
                        <Legend disabled={disabled} focused={focused} filled={filled}>
                            <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                                {label}
                            </LegendText>
                        </Legend>
                    </FieldsetStyled>
                </InputWrapperStyled>
                {status === 'error' && helperText && <ErrorStyled>{helperText}</ErrorStyled>}
            </Wrapper>
        );
    }
);
