import React from 'react';

const Marker = () => (
    <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 0.499873C5.16716 0.481169 3.40155 1.18935 2.08963 2.4694C0.777718 3.74946 0.0263539 5.49712 0 7.32987C0
        11.2199 2 15.3299 5.9 19.5699C6.04041 19.7213 6.21059 19.8422 6.39988 19.9248C6.58916 20.0074 6.79347 20.0501 7
        20.0501C7.20653 20.0501 7.41084 20.0074 7.60012 19.9248C7.78941 19.8422 7.95959 19.7213 8.1 19.5699C12 15.3399
        14 11.2199 14 7.32987C13.9736 5.49712 13.2223 3.74946 11.9104 2.4694C10.5984 1.18935 8.83284 0.481169 7
        0.499873ZM7.36 18.8899C7.31412 18.9396 7.25845 18.9792 7.19649 19.0063C7.13453 19.0335 7.06763 19.0475 7
        19.0475C6.93237 19.0475 6.86547 19.0335 6.80351 19.0063C6.74155 18.9792 6.68588 18.9396 6.64 18.8899C2.9 14.8499
        1 10.9999 1 7.32987C1.02633 5.76234 1.67232 4.26902 2.7967 3.17649C3.92109 2.08396 5.43236 1.48115 7
        1.49987C8.56764 1.48115 10.0789 2.08396 11.2033 3.17649C12.3277 4.26902 12.9737 5.76234 13 7.32987C13 10.9999
        11.1 14.8499 7.36 18.8899ZM7 4.99987C6.60444 4.99987 6.21776 5.11717 5.88886 5.33693C5.55996 5.5567 5.30362
        5.86905 5.15224 6.23451C5.00087 6.59996 4.96126 7.00209 5.03843 7.39005C5.1156 7.77802 5.30608 8.13438 5.58579
        8.41409C5.86549 8.69379 6.22186 8.88427 6.60982 8.96144C6.99778 9.03861 7.39991 8.99901 7.76537 8.84763C8.13082
        8.69626 8.44318 8.43991 8.66294 8.11101C8.8827 7.78211 9 7.39544 9 6.99987C9 6.46944 8.78929 5.96073 8.41421
        5.58566C8.03914 5.21059 7.53043 4.99987 7 4.99987ZM7 7.99987C6.80222 7.99987 6.60888 7.94122 6.44443
        7.83134C6.27998 7.72146 6.15181 7.56528 6.07612 7.38256C6.00043 7.19983 5.98063 6.99876 6.01921 6.80478C6.0578
        6.6108 6.15304 6.43262 6.29289 6.29277C6.43275 6.15291 6.61093 6.05767 6.80491 6.01909C6.99889 5.9805 7.19996
        6.00031 7.38268 6.07599C7.56541 6.15168 7.72159 6.27985 7.83147 6.4443C7.94135 6.60875 8 6.80209 8 6.99987C8
        7.26509 7.89464 7.51944 7.70711 7.70698C7.51957 7.89452 7.26522 7.99987 7 7.99987Z"
            fill="#2574DB"
        ></path>
    </svg>
);
export default Marker;
