import { useStore } from 'effector-react';
import { Divider, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { ReactComponent as LegendColor } from './MaterialLegendsColor.svg';

import './MaterialLegends.css';

const materialList: string[] = [
    'materialMetal',
    'materialGlass',
    'materialBrakeLight',
    'materialPlastic',
    'materialGlassFibre',
    'materialCarbon',
    'materialHardenedSteel',
    'materialLightMetal',
    'materialAluminium',
    'materialMagnesium',
    'materialTitan',
    'materialInternalCovers',
    'materialHighVoltage'
];

export const MaterialLegends = ({ isMobile }: { isMobile?: boolean }) => {
    const { isShowMaterialLegends } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const { t } = useTranslation();

    function onClose() {
        graphicDamageSelectionModel.events.updateStore({
            isShowMaterialLegends: false
        });
    }

    const buttonRect = document.querySelector('.materialLegendsButton')?.getBoundingClientRect();
    const legendsLeftCoord = buttonRect ? buttonRect.x + buttonRect.width - 310 : 0;
    const legendsBottomCoord = buttonRect ? buttonRect.height + 30 : 0;

    return (
        <Modal
            mask={!!isMobile}
            footer={null}
            title={null}
            closable={!!isMobile}
            zIndex={1015}
            visible={isShowMaterialLegends}
            wrapClassName={'materialLegendsWrapper'}
            className={isMobile ? 'materialLegendsMobile' : 'materialLegends'}
            style={
                isMobile
                    ? undefined
                    : { left: legendsLeftCoord + 'px', bottom: legendsBottomCoord, top: 'auto', right: 'auto' }
            }
            onCancel={onClose}
        >
            <h3>{t('Legend.Legends')}</h3>
            <Divider plain>{t('Legend.Material')}</Divider>
            {materialList.map(mat => (
                <Row key={mat}>
                    <div className="materialLegend">
                        <span className="materialLegendColor">
                            <LegendColor className={mat} />
                        </span>
                        {t('Legend.Materials.' + mat)}
                    </div>
                </Row>
            ))}
            <Divider plain>{t('Legend.SelectedElement')}</Divider>
            <Row>
                <div className="materialLegend">
                    <span className="materialLegendColor">
                        <LegendColor className="leftStSelectedElements" />
                    </span>
                    {t('Legend.LeftSideSelected')}
                </div>
            </Row>
            <Row>
                <div className="materialLegend">
                    <span className="materialLegendColor">
                        <LegendColor className="rightStSelectedElements" />
                    </span>
                    {t('Legend.RightSideSelected')}
                </div>
            </Row>
            <Row>
                <div className="materialLegend">
                    <span className="materialLegendColor">
                        <LegendColor className="leftRighStSelectedElements" />
                    </span>
                    {t('Legend.BothSideSelected')}
                </div>
            </Row>
        </Modal>
    );
};
