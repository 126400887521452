import { createEvent, createStore } from 'effector';
import { getFromCurrentForm } from '../utils/getFromCurrentForm';
import { DataField } from '../types/dataScheme';

type FormFieldsData = Record<string, Record<string, DataField>>;
type FormValuesData = Record<string, Record<string, string>>;

/*** Form fields values ***/

const addFieldValue = createEvent<{ formName: string; newValue: Record<string, string> }>();
const addNewFormValues = createEvent<FormValuesData>();
const fieldsValuesStore = createStore<FormValuesData | Record<string, any>>({})
    .on(addNewFormValues, (state, formName) => ({
        ...state,
        [String(formName)]: {}
    }))
    .on(addFieldValue, (state: FormValuesData, { formName, newValue }) => {
        Object.keys(state).forEach(key => {
            if (key === formName) {
                state[key] = { ...state[key], ...newValue };
            }
        });
        return { ...state };
    });

/*** Form fields ***/

const addField = createEvent<{ field: DataField; formName: string }>();
const addFieldKeyValue = createEvent<{ fieldId: string; key: string; value: any; formName: string }>();
const addNewFormFields = createEvent<FormFieldsData>();
const formFieldsStore = createStore<FormFieldsData | Record<string, any>>({})
    .on(addNewFormFields, (state, formName) => ({
        ...state,
        [String(formName)]: {}
    }))
    .on(addField, (state, { field, formName }) => {
        Object.keys(state).forEach(key => {
            if (key === formName) {
                state[key][field.id] = field;
            }
        });
        return { ...state };
    })
    .on(addFieldKeyValue, (state, { fieldId, key, value, formName }) => {
        const current = getFromCurrentForm(state, formName);
        Object.keys(current).forEach(field => {
            if (field === fieldId) {
                current[fieldId][key] = value;
            }
        });
        return { ...state };
    });

export {
    addNewFormValues,
    addNewFormFields,
    addField,
    addFieldValue,
    formFieldsStore,
    fieldsValuesStore,
    addFieldKeyValue
};
