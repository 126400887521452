import React from 'react';

const CalendarCrossed = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 216.374 216.374" {...props}>
        <path
            d="M207.374 30.135h-25.438V13.432c0-4.971-4.029-9-9-9s-9 4.029-9 9v16.703H52.438V13.432c0-4.971-4.029-9-9-9s-9 4.029-9 9v16.703H9a9 9 0 0 0-9 9v163.808a9 9 0 0 0 9 9h198.374a9 9 0 0 0 9-9V39.135a9 9 0 0 0-9-9zm-9 18v19.997H18V48.135h180.374zM18 193.942V86.132h180.374v107.811H18z"
            fill="currentColor"
        />
        <path
            d="M145.534 109.813c-3.115-3.873-8.781-4.488-12.653-1.373l-24.694 19.86-24.693-19.86a9 9 0 0 0-11.28 14.027l21.613 17.382-21.613 17.382a9 9 0 0 0 11.28 14.027l24.693-19.859 24.693 19.859a8.961 8.961 0 0 0 5.634 1.986 9 9 0 0 0 5.646-16.013l-21.613-17.382 21.613-17.382c3.874-3.114 4.489-8.779 1.374-12.654z"
            fill="currentColor"
        />
    </svg>
);

export default CalendarCrossed;
