import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { HomeIcon } from '../../assets/icons';
import { StyledNavLink } from '../StyledNavLink';
import { Tooltip } from '@wedat/ui-kit/components/Tooltip';

export const MainContainer = styled.div``;

export const ButtonToggle = styled.button`
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.gray_300};
    display: none;
    cursor: pointer;

    ${media.tabletSmall`
        display: block;
    `}
`;

export const TextStyled = styled.span`
    font-weight: 500;
    font-size: 18px;
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const HomeButton = styled.button`
    height: 48px;
    padding: 8px 20px;

    display: flex;
    align-items: center;
    background-color: transparent;

    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: ${({ theme }) => theme.colors.blueDark};
    }

    ${media.tablet`
        width: 100%;
        height: auto;
        padding: 0;
        border: none;
    `}
`;

export const HomeIconStyled = styled(HomeIcon)``;

export const Text = styled.div`
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;

    :hover {
        color: ${({ theme }) => theme.colors.blueDark};
    }
`;

interface PluginLinkProps {
    disabled?: boolean;
}

export const PluginLink = styled.div<PluginLinkProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
    transition: color 150ms;

    color: ${({ disabled, theme }) => (disabled ? theme.colors.textDisabled : theme.colors.textPrimary)};

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }

    & svg {
        width: 24px;
        height: 24px;
        color: currentColor;
    }

    ${media.tablet`
        padding-top: 10px;
        padding-bottom: 10px;
        width: auto;
        height: auto;

         svg {
             display: none;
         }

         ${Text} {
             margin-left: 0;
         }
    `}
`;

export const ButtonPlugin = styled(StyledNavLink)<{ active?: boolean }>`
    cursor: pointer;

    ${({ active, theme }) =>
        active &&
        css`
            color: ${theme.colors.blue['700']};
            border-bottom: 4px solid ${theme.colors.blue['700']};
        `}

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const Wrapper = styled.div<{ mobile?: boolean }>`
    display: flex;
    align-items: center;

    ${PluginLink} {
        display: none;
    }

    ${media.tablet`
        ${PluginLink} {
            padding: 0;
            margin-right: 20px;
            display: block;
        }

        svg {
            display: block;
        }
    `}

    ${({ mobile }) =>
        mobile &&
        css`
            ${media.phone`
                ${PluginLink} {
                    margin-right: 8px;
                    max-width: 103px;
                }

                ${StatusName} {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            `}
        `}
`;

export const AdditionalMobileBar = styled.div`
    display: none;

    ${media.laptop`
        display: flex;
        width: 100%;
        height: 40px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;
        justify-content: space-between;
        overflow: hidden;
        padding-left: 12px;
        padding-right: 12px;
    `}
`;

export const AdditionalBarSection = styled.div`
    display: flex;
`;

export const StatusName = styled.div`
    padding: 8px 16px;
    margin-right: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    ${({ theme }) => theme.typography.note};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 8px;
    cursor: pointer;
    transition: color 150ms, background-color 150ms;
    margin-left: 10px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray['200']};
        color: ${({ theme }) => theme.colors.blue['500']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        color: ${({ theme }) => theme.colors.blue['700']};
    }

    ${media.tablet`
        ${({ theme }) => theme.typography.footnote};
    `}
`;

export const Image = styled.img`
    max-height: 40px;
    margin-right: 10px;
`;

export const SlideWrap = styled.div`
    padding: 16px 24px;
`;

export const StyledTooltip = styled(Tooltip)`
    height: 48px;
`;
