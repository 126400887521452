import { merge } from 'effector';

import { domain, pluginEvents, pluginStores } from './plugin';
import { effectorLogger } from '@dat/core/utils';

import { EditableSelectsNames, EditableSelectsObject } from '../types/selectsEdit';

//
/*** Edit status ***/
//
const toggleEditMode = domain.createEvent();
const isEditModeOn = domain
    .createStore(false)
    .on(toggleEditMode, prevStatus => !prevStatus)
    .on(pluginStores.settings, (_, { initialEditMode }) => initialEditMode);

//
/*** Labels current values ***/
//
interface SetValuePayload {
    name: EditableSelectsNames;
    value: string;
}

const setLabelValue = domain.createEvent<SetValuePayload>();
const labelsValues = domain
    .createStore<EditableSelectsObject>({
        'vehicleSelects.vehicleType': 'VEHICLE TYPE',
        'vehicleSelects.manufacturer': 'BRAND',
        'vehicleSelects.baseModel': 'MAIN MODEL',
        'vehicleSelects.subModel': 'SUBTYPE'
    })
    .on(setLabelValue, (valuesObject, { name, value }) => ({
        ...valuesObject,
        [name]: value
    }));

//
/*** Labels additional values ***/
//
const setAdditionalLabelValue = domain.createEvent<SetValuePayload>();
const additionalLabelsValues = domain
    .createStore<EditableSelectsObject>({
        'vehicleSelects.vehicleType': '',
        'vehicleSelects.manufacturer': '',
        'vehicleSelects.baseModel': '',
        'vehicleSelects.subModel': ''
    })
    .on(setAdditionalLabelValue, (valuesObject, { name, value }) => ({
        ...valuesObject,
        [name]: value
    }))
    .on(pluginEvents.initPlugin, (values, { inputFields }) => {
        if (inputFields) {
            const { BrandAdditionalLabel, ModelAdditionalLabel, SubModelAdditionalLabel } = inputFields;

            values = { ...values };

            BrandAdditionalLabel && (values['vehicleSelects.manufacturer'] = BrandAdditionalLabel);
            ModelAdditionalLabel && (values['vehicleSelects.baseModel'] = ModelAdditionalLabel);
            SubModelAdditionalLabel && (values['vehicleSelects.subModel'] = SubModelAdditionalLabel);

            return values;
        }
    });

//
/*** Edit-inputs values ***/
//
const setEditInputValue = domain.createEvent<SetValuePayload>();
const updateEditInputsValues = domain.createEvent();
const editInputsValues = domain
    .createStore<EditableSelectsObject>(labelsValues.getState())
    .on(setEditInputValue, (valuesObject, { name, value }) => ({
        ...valuesObject,
        [name]: value
    }))
    .on(merge([toggleEditMode, updateEditInputsValues]), () => labelsValues.getState());

//
/*** Export ***/
//
export const selectsEditEvents = {
    toggleEditMode,
    setLabelValue,
    setAdditionalLabelValue,
    setEditInputValue,
    updateEditInputsValues
};
export const selectsEditStores = {
    isEditModeOn,
    labelsValues,
    additionalLabelsValues,
    editInputsValues
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(selectsEditEvents);
    effectorLogger(selectsEditStores);
}
