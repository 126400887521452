import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstance } from '../../hooks/useInstance';
import { useSubjectFromContract } from '../../hooks/useSubjectFromContract';

import { PlusCircleIcon, EditIcon } from '@wedat/ui-kit/components/Icons';
import { Label } from '@wedat/ui-kit/components/Label';
import { FieldsetStyled } from '@wedat/ui-kit/components/Fieldset';
import { Legend, LegendText } from '@wedat/ui-kit/components/Legend';

import {
    ButtonAddressBook,
    ButtonFilledStyled,
    ButtonStyled,
    Container,
    Icon,
    IconImage,
    Item,
    LabelStyled,
    List,
    SubjectName,
    TextStyled
} from './styles';

interface ListItem {
    text: string;
}
interface Props {
    list?: ListItem[];
    formValues: BFF.AddressBook.Subject;
}

export const Card: FC<Props> = ({ list, formValues }) => {
    const { t } = useTranslation();
    const { subjectType, assignedSubject, label, openModal, showList, isInbox, icon, onOpenModal } = useInstance(
        'subjectType',
        'assignedSubject',
        'label',
        'showList',
        'isInbox',
        'icon',
        'onOpenModal'
    );
    const translatedLabel = t(`subjectsTypes.${subjectType}`, label);

    const subjectFromContract = useSubjectFromContract();

    const subjectToShow = showList ? assignedSubject : subjectFromContract;

    const getBlockWithSubjectName = (subjectToShow: BFF.AddressBook.Subject) => (
        <ButtonFilledStyled>
            <LabelStyled filled={true} withIcon>
                {translatedLabel}
            </LabelStyled>
            <SubjectName>
                {subjectToShow.name || formValues.name || ''} {subjectToShow.surname || formValues.surname || ''}
            </SubjectName>
            <Icon>
                <EditIcon />
            </Icon>

            {list && list.length > 0 && (
                <List>
                    {list.map((item, key) => (
                        <Item key={key}>{item.text}</Item>
                    ))}
                </List>
            )}
            <FieldsetStyled>
                <Legend filled={true}>
                    <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                        {translatedLabel}
                    </LegendText>
                </Legend>
            </FieldsetStyled>
        </ButtonFilledStyled>
    );

    const getButtonAddressBook = () => (
        <ButtonStyled>
            <Label filled={false} withIcon>
                {translatedLabel}
            </Label>
            <Icon>
                <PlusCircleIcon />
            </Icon>
            <FieldsetStyled>
                <Legend filled={false}>
                    <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                        {translatedLabel}
                    </LegendText>
                </Legend>
            </FieldsetStyled>
        </ButtonStyled>
    );

    const handleOpenModal = () => {
        openModal();
        onOpenModal?.();
    };

    const templateDefault = !!subjectToShow?.name ? getBlockWithSubjectName(subjectToShow) : getButtonAddressBook();

    return (
        <Container onClick={handleOpenModal}>
            {isInbox ? (
                <ButtonAddressBook>
                    <IconImage src={icon} />
                    <TextStyled font="footnote">{translatedLabel}</TextStyled>
                </ButtonAddressBook>
            ) : (
                templateDefault
            )}
        </Container>
    );
};
