import { addField, addFieldValue, addNewFormFields, addNewFormValues } from '../stores/fieldsValues';
import { addNewFormConditionRules, addConditionRules } from '../stores/conditions';
import { getValidationSchema } from './validationSchema';
import * as Yup from 'yup';

// add conditions to store
const conditionsProcessing = (conditionObj: Record<string, any>, formName: string) => {
    const conditions = conditionObj.conditions;
    addConditionRules({ conditions, formName });
};

const fieldsProcessing = (obj: any, formName: string) => {
    obj.fields.forEach((field: any) => {
        addField({ field, formName });
        addFieldValue({ formName, newValue: { [field.id]: field.initValue } });
    });
};

const processing = (
    obj: Record<string, any>,
    formName: string,
    appendGroup: (group: any) => void,
    appendSchema: (schema: any) => void,
    appendFormName: (formName: string) => string
) => {
    if (obj.hasOwnProperty('formName')) {
        addNewFormValues(obj.formName);
        appendFormName(obj.formName);
        addNewFormFields(obj.formName);
        addNewFormConditionRules(obj.formName);
    }
    if (obj.hasOwnProperty('groups')) {
        appendGroup(obj.groups);
    }
    if (obj.hasOwnProperty('conditions')) {
        conditionsProcessing(obj, formName);
    }
    if (obj.hasOwnProperty('fields')) {
        appendSchema(getValidationSchema(obj));
        fieldsProcessing(obj, formName);
    }
    Object.values(obj).forEach(value => {
        if (value instanceof Object) {
            processing(value, formName, appendGroup, appendSchema, appendFormName);
        }
        if (value instanceof Array) {
            value.forEach(form => processing(form, formName, appendGroup, appendSchema, appendFormName));
        }
    });
};

export const getForm = (arr: any[]) => {
    let formGroups: any[] = [];
    let schema: any = null;
    let formName: string = '';

    const appendGroup = (groups: any) => {
        formGroups = [...formGroups, ...groups];
    };
    const appendSchema = (_schema: any) => {
        schema = { ...schema, ..._schema };
    };
    const appendFormName = (_formName: string) => (formName = _formName);
    arr.forEach(form => processing(form, formName, appendGroup, appendSchema, appendFormName));

    return {
        groups: formGroups,
        formName: formName,
        schema: Yup.object().shape(schema)
    };
};
