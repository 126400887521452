import React from 'react';

const OpendEye = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.6704 11.25C20.6004 9.37 17.5104 5 12.0004 5C6.49036 5 3.40036 9.37 2.33036 11.25C2.11384 11.631 2 12.0618 2 12.5C2 12.9382 2.11384 13.369 2.33036 13.75C3.40036 15.63 6.49036 20 12.0004 20C17.5104 20 20.6004 15.63 21.6704 13.75C21.8869 13.369 22.0007 12.9382 22.0007 12.5C22.0007 12.0618 21.8869 11.631 21.6704 11.25ZM20.8004 13.25C19.8204 15 17.0004 19 12.0004 19C7.00036 19 4.18036 15 3.20036 13.25C3.06772 13.0223 2.99784 12.7635 2.99784 12.5C2.99784 12.2365 3.06772 11.9777 3.20036 11.75C4.18036 10 7.00036 6 12.0004 6C17.0004 6 19.8204 10 20.8004 11.75C20.933 11.9777 21.0029 12.2365 21.0029 12.5C21.0029 12.7635 20.933 13.0223 20.8004 13.25ZM12.0004 8.5C11.2092 8.5 10.4359 8.7346 9.77808 9.17412C9.12029 9.61365 8.6076 10.2384 8.30485 10.9693C8.00209 11.7002 7.92288 12.5044 8.07722 13.2804C8.23156 14.0563 8.61253 14.769 9.17194 15.3284C9.73135 15.8878 10.4441 16.2688 11.22 16.4231C11.9959 16.5775 12.8002 16.4983 13.5311 16.1955C14.262 15.8928 14.8867 15.3801 15.3262 14.7223C15.7658 14.0645 16.0004 13.2911 16.0004 12.5C16.0004 11.4391 15.5789 10.4217 14.8288 9.67157C14.0786 8.92143 13.0612 8.5 12.0004 8.5ZM12.0004 15.5C11.407 15.5 10.827 15.3241 10.3337 14.9944C9.8403 14.6648 9.45579 14.1962 9.22872 13.6481C9.00166 13.0999 8.94225 12.4967 9.05801 11.9147C9.17376 11.3328 9.45949 10.7982 9.87904 10.3787C10.2986 9.95912 10.8331 9.6734 11.4151 9.55764C11.997 9.44189 12.6002 9.5013 13.1484 9.72836C13.6966 9.95542 14.1651 10.3399 14.4948 10.8333C14.8244 11.3266 15.0004 11.9067 15.0004 12.5C15.0004 13.2956 14.6843 14.0587 14.1217 14.6213C13.5591 15.1839 12.796 15.5 12.0004 15.5Z"
            fill="#323B4C"
        />
    </svg>
);

export default OpendEye;
