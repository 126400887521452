import styled, { css } from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';
import { FieldValuesStyle } from '../../types/calculation';

interface Props {
    styles?: FieldValuesStyle;
    content?: string;
}

export const PriceContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    padding: 20px 10px 20px 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;

    ${media.phone`
        padding: 0;
        background-color: transparent;
    `}
`;

export const Field = styled.div`
    width: 23%;
    min-width: 195px;
    padding: 15px 15px;
    margin-bottom: 15px;
    margin-right: 10px;
    border: 2px solid #f1f2f4;
    color: #465064;
    font-size: 13px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.tabletSmall`
        width: 30%;
    `}

    ${media.laptop`
        width: 45%;
    `}

    ${media.phone`
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        border: none;
        border-radius: 2px;
        font-weight: 500;
        font-size: 16px;
    `}
`;

export const Title = styled.h2`
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #465064;

    ${media.phone`
        display: none;
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const TextField = styled.span<Props>`
    text-align: ${props => (props.content === 'value' ? 'right' : 'left')};

    ${({ styles }) => {
        if (styles) {
            const { fontSize, fontWeight } = styles;

            return css`
                font-weight: ${fontWeight};
                color: ${({ theme }) => theme.colors.blueDark};
                font-size: ${fontSize};
            `;
        }
    }}
`;
