import styled, { css } from 'styled-components/macro';

interface Props {
    buttonType: 'primary' | 'secondary';
}

const TYPE_STYLES = {
    primary: css`
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.deepBlue['800']};
        border: none;
    `,
    secondary: css`
        background-color: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.deepBlue['800']};
        color: ${({ theme }) => theme.colors.deepBlue['800']};
    `
};

export const ButtonStyled = styled.button<Props>`
    height: 48px;
    width: 100%;

    font-size: 16px;
    font-weight: 500;

    outline: none;

    border-radius: 8px;

    cursor: pointer;

    opacity: 1;

    transition: opacity 0.2s;

    ${({ buttonType }) => TYPE_STYLES[buttonType]}

    &:hover {
        opacity: 0.85;
    }
`;
