import styled from 'styled-components/macro';

import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { TextareaField } from '@wedat/ui-kit/Formik';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';

export const StyledButton = styled(Button)`
    width: 100px;
`;

export const Container = styled.div`
    padding: 20px;
`;

export const StyledResetButton = styled(Button)`
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
`;

export const CancelButton = styled.div`
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
`;

export const ConfirmButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background: ${({ theme }) => theme.colors.white};
    position: fixed;
    bottom: 0;
    left: 0;

    ${media.phone`
        border-top: 2px solid ${({ theme }) => theme.colors.gray_100};
    `}
`;

export const ModalContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 750px;
    max-height: 390px;
    margin-bottom: 65px;
    overflow-y: auto;
    ${makeCustomScrollBar()}

    ${media.phone`
        max-height: unset;
        margin-bottom: 90px;
    `}
`;

export const Title = styled(Text)`
    margin-bottom: 10px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const TextareaStyled = styled(TextareaField)`
    width: 100%;
    height: 140px;
    border-radius: 4px;
`;

export const ButtonStyled = styled(Button)`
    margin-left: 20px;
    width: 124px;
`;

export const InputWrapper = styled.div`
    margin-bottom: 20px;
`;
