import React from 'react';
import styled, { css } from 'styled-components';

export interface StyledProps {
    side?: 'left' | 'right';
    children?: React.ReactNode;
    isOpen?: boolean;
    width?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const Container = styled.div<StyledProps>`
    position: absolute;
    top: calc(100% + 4px);
    display: flex;
    flex-direction: column;
    min-width: 260px;
    ${({ width }) => width && `width: ${width};`};
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.21);
    border-radius: 12px;

    &:before {
        content: '';
        height: 4px;
        width: 100%;
        background-color: transparent;
        margin-top: -4px;
    }

    ${({ side = 'right' }) => {
        const cssPropSide = side === 'right' ? 'left' : 'right';
        return css`
            ${cssPropSide}: 0;
        `;
    }}
`;
