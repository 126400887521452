/*
 * Receives parsed object and creates new object from it by these rules:
 *
 * - picks top-level properties
 * - if property has value of primitive type, it's not changed
 * - if property is an object with "_text", value of this property becomes "_text"'s value
 * - all other properties are deleted
 *
 */
import { getFieldText } from './getFieldText';
import { PlainObject } from '@dat/core/types/common';
import { ExtractTextFromFieldWithPartialAttributes } from '../types';

export type GetTextValuesFromParsedObject<ParsedObject extends PlainObject> = {
    [K in keyof ParsedObject]: ParsedObject[K] extends DAT2.Field.AttrText<DAT2.Field.Primitive> | undefined
        ? ParsedObject[K]['_text']
        : ParsedObject[K] extends DAT2.Field.TextWithPartialAttributes<DAT2.Field.Primitive, object> | undefined
        ? ExtractTextFromFieldWithPartialAttributes<ParsedObject[K]>
        : ParsedObject[K] extends DAT2.Field.Primitive | undefined
        ? ParsedObject[K]
        : never;
};

export const getTextValuesFromParsedObject = <Obj extends PlainObject>(obj: Obj): GetTextValuesFromParsedObject<Obj> =>
    Object.entries(obj).reduce((acc, [key, field]) => {
        const text = getFieldText(field);

        if (text !== undefined) {
            acc[key as keyof GetTextValuesFromParsedObject<Obj>] = text;
        }

        return acc;
    }, {} as GetTextValuesFromParsedObject<Obj>);
