import { createEffect, createEvent, createStore } from 'effector';
import { API2 } from '@dat/api2';

const availableUsers = createStore<DAT2.Internal.User[]>([]);
const loadMoreAvailableUsers = createEvent<void>();

const fetchAvailableUsers = createEffect(async () => (await API2.myClaimInternal.listUsers()).list);

availableUsers.on(fetchAvailableUsers.doneData, (_, users) =>
    users.sort((a, b) => `${a.firstName} ${a.surname}`.localeCompare(`${b.firstName} ${b.surname}`))
);

export const usersListStores = {
    availableUsers
};

export const usersListEvents = {
    loadMoreAvailableUsers
};

export const usersListEffects = {
    fetchAvailableUsers
};
