import React, { FC } from 'react';

import { Container } from './styles';
import { SizeCounter } from './types';

export interface CounterProps {
    count: number;
    size?: SizeCounter;
}

export const Counter: FC<CounterProps> = ({ count, size = 'small' }) => (
    <Container size={size}>{count > 99 ? '+99' : `+${count}`}</Container>
);
