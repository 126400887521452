import { createStore, createEffect, guard, createEvent, forward, combine, sample } from 'effector';
// import { createStore, createEffect, guard, createEvent, forward } from 'effector-logger/macro';

import { DvnMapItem, FtDvnMapType } from '../types/ft/dvnMapType';
import { FtVxsRepairsType, ftRepairType } from '../types/ft/ftRepairType';
import { ftDamagesType } from '../types/ft/ftDamagesType';
import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { getUrlBlobByUrl } from '../utils/getByUrl';
import { parseFTDvnMap } from '../utils/parseFTDvnMap';
import { findDvnsByDatId } from '../utils/findDvnByDatId';
import { convertRepairPositionToDatId } from '../utils/convertRepairPositionToDatId';
import { convertFtGroupFromFtSettings, getAvalableDamagesByDATID } from '../utils/getAvalableDamagesByDATIDftSettings';
import {
    findDvnWithDescriptionAndGroupByFtDatId,
    getFtDamageFromRepairPositions,
    getRepairPositionsFromFtDamageWithDVN
} from '../utils/ftRepairPositionConverter';
import { getFtDatIdToDvnMap } from '../utils/getFtDatIdToDvnMap';
import { ftRepairToRepairPosition } from '../utils/ftRepairToRepairPosition';

import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { pluginOptionsModel } from './pluginOptionsModel';
import { graphicDamageSelectionModel } from './graphicDamageSelectionModel';
import { RepairPositionsModel } from './repairPositionsModel';

const dvnMapStore = createStore<DvnMapItem[]>([]);
const setDvnMapByFtDvnMap = createEvent<FtDvnMapType | undefined>();
dvnMapStore.on(setDvnMapByFtDvnMap, (_, val) => val && parseFTDvnMap(val));

const ftDatIdToDvnMap = createStore<{ [key: string]: number }>({});
const setFtDatIdToDvnMap = createEvent<{
    availableAssemblyGroups: AvailableAssemblyGroup[];
    dvnMap: DvnMapItem[];
    datECode: string;
}>();
ftDatIdToDvnMap.on(setFtDatIdToDvnMap, (_, val) => val && getFtDatIdToDvnMap(val));

const vxsRepairs = createStore<FtVxsRepairsType[]>([]);
const setVxsRepairsByFtRepairs = createEvent<ftRepairType[] | undefined>();
vxsRepairs.on(setVxsRepairsByFtRepairs, (_, val) => val && ftRepairToRepairPosition(val));

const ftDamages = createStore<ftDamagesType | null>(null);
const setFtDamages = createEvent<ftDamagesType | undefined>();
ftDamages.on(setFtDamages, (_, val) => val);

const fastTrackGroupsStore = pluginOptionsModel.stores.pluginOptions.map(pluginOptions =>
    convertFtGroupFromFtSettings(pluginOptions?.settings?.ftSettings?.ftGroups)
);

export interface GenericSVGGraphic {
    graphicId: string;
    label?: string;
    sourceUrl: string;
    graphicSVG?: string;
}

const genericSVGGraphics = createStore<GenericSVGGraphic[]>([]);

const currentFtDatId = createStore<string | null>(null);
const setCurrentFtDatId = createEvent<string>();
currentFtDatId.on(setCurrentFtDatId, (_, val) => val);

const currentGenericGraphic = createStore<GenericSVGGraphic | undefined | null>(null);
const setCurrentGenericGraphic = createEvent<GenericSVGGraphic | undefined>();
currentGenericGraphic.on(setCurrentGenericGraphic, (_, val) => val);

const updateGenericSVGGraphics = createEvent<GenericSVGGraphic[]>();
genericSVGGraphics.on(updateGenericSVGGraphics, (_state, graphics) => {
    const newState = graphics;
    // const newState = [...state];
    // graphics.forEach(graphic => {
    //     const foundIndexValue = newState.findIndex(val => val.graphicId === graphic.graphicId);
    //     if (foundIndexValue === -1) {
    //         newState.push(graphic);
    //     } else {
    //         newState[foundIndexValue] = graphic;
    //     }
    // });
    return newState;
});

sample({
    source: currentGenericGraphic,
    clock: genericSVGGraphics,
    filter: currentGenericGraphic => !currentGenericGraphic,
    fn: (_, genericSVGGraphics) => genericSVGGraphics[0],
    target: setCurrentGenericGraphic
});

const initGenericGraphicFirst = createEvent<GenericSVGGraphic[] | undefined>();
const initGenericGraphicFx = createEffect({
    async handler(genericSVGGraphics: GenericSVGGraphic[] | undefined) {
        if (!genericSVGGraphics) return;
        const groupPromises = genericSVGGraphics.map(async genericSVGGraphic => {
            const newVal = { ...genericSVGGraphic };
            newVal.graphicSVG = await getUrlBlobByUrl(genericSVGGraphic.sourceUrl);
            return newVal;
        });
        const result = await Promise.all(groupPromises);
        return result;
    }
});

sample({
    clock: initGenericGraphicFx.doneData,
    filter: data => !!data,
    target: updateGenericSVGGraphics as any
});

const setGenericGraphicFx = createEffect({
    async handler(genericSVGGraphics: GenericSVGGraphic[] | null) {
        if (!genericSVGGraphics) return;
        const groupPromises = genericSVGGraphics.map(async genericSVGGraphic => {
            const newVal = { ...genericSVGGraphic };
            newVal.graphicSVG = await getUrlBlobByUrl(genericSVGGraphic.sourceUrl);
            return newVal;
        });
        const result = await Promise.all(groupPromises);
        return result;
    }
});

sample({
    clock: setGenericGraphicFx.doneData,
    filter: data => !!data,
    target: updateGenericSVGGraphics as any
});

guard({
    source: initGenericGraphicFirst,
    filter: initGenericGraphicFx.pending.map(pending => !pending),
    target: initGenericGraphicFx
});

const setCurrentGrapaObjectsByDatId = createEvent<string>();
sample({
    source: combine([dvnMapStore, availableAssemblyGroupsModel.stores.datECode]),
    clock: setCurrentGrapaObjectsByDatId,
    fn: ([dvnMap, datECode], dvnId) => findDvnsByDatId(dvnId, datECode, dvnMap),
    target: graphicDamageSelectionModel.events.setCurrentObjectsByDVN
});

forward({
    from: setCurrentGrapaObjectsByDatId,
    to: setCurrentFtDatId
});

const repairPositionByDatId = combine([ftDatIdToDvnMap, RepairPositionsModel.store.repairPositions]).map(
    ([ftDatIdToDvnMap, repairPositions]) => convertRepairPositionToDatId(repairPositions, ftDatIdToDvnMap)
);

const currentAvailableDamage = combine([
    currentFtDatId,
    fastTrackGroupsStore,
    pluginOptionsModel.stores.pluginOptions
]).map(
    ([currentFtDatId, fastTrackGroups, pluginOptions]) =>
        pluginOptions?.settings?.ftSettings?.ftDamages &&
        getAvalableDamagesByDATID(currentFtDatId, {
            fastTrackDamages: pluginOptions?.settings?.ftSettings?.ftDamages,
            fastTrackGroups
        })
);

const setRepairPositionByFtDatIdAndDamage = createEvent<{ ftDatId: string; ftDamage: string }>();

sample({
    source: combine([
        vxsRepairs,
        fastTrackGroupsStore,
        dvnMapStore,
        availableAssemblyGroupsModel.stores.datECode,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ]),
    clock: setRepairPositionByFtDatIdAndDamage,
    fn: ([ftVxsRepairs, ftGroups, dvnMap, datECode, availableAssemblyGroupsList], { ftDatId, ftDamage }) =>
        getRepairPositionsFromFtDamageWithDVN(
            ftDatId,
            ftDamage,
            ftVxsRepairs,
            ftGroups,
            dvnMap,
            datECode,
            availableAssemblyGroupsList.availableAssemblyGroups
        ),
    target: RepairPositionsModel.event.addRepairPositionsWithExistenceCheck
});

const clearRepairPositionByFtDatId = createEvent<{ ftDatId: string }>();
sample({
    source: combine([
        dvnMapStore,
        availableAssemblyGroupsModel.stores.datECode,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ]),
    clock: clearRepairPositionByFtDatId,
    fn: ([dvnMap, datECode, availableAssemblyGroupsList], { ftDatId }) =>
        findDvnWithDescriptionAndGroupByFtDatId(
            ftDatId,
            datECode,
            dvnMap,
            availableAssemblyGroupsList.availableAssemblyGroups
        )?.DATProcessId,
    target: RepairPositionsModel.event.delDamage
});

const currentFtElementDamage = combine({
    ftDatId: currentFtDatId,
    ftDamages: ftDamages,
    ftGroups: fastTrackGroupsStore,
    ftVxsRepairs: vxsRepairs,
    dvnMap: dvnMapStore,
    datECode: availableAssemblyGroupsModel.stores.datECode,
    availableAssemblyGroupsStore: availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore,
    repairPositions: RepairPositionsModel.store.repairPositions
}).map(
    ({ ftDatId, ftDamages, ftGroups, ftVxsRepairs, dvnMap, datECode, availableAssemblyGroupsStore, repairPositions }) =>
        getFtDamageFromRepairPositions({
            ftDatId,
            ftDamages,
            ftGroups,
            ftVxsRepairs,
            dvnMap,
            datECode,
            availableAssemblyGroupsList: availableAssemblyGroupsStore.availableAssemblyGroups,
            repairPositions: repairPositions
        })
);

export const genericSVGGraphicModel = {
    stores: {
        dvnMapStore,
        ftDatIdToDvnMap,
        vxsRepairs,
        ftDamages,
        fastTrackGroupsStore,

        currentGenericGraphic,
        currentFtDatId,
        currentAvailableDamage,
        currentFtElementDamage,
        genericSVGGraphics,
        repairPositionByDatId
    },
    events: {
        setDvnMapByFtDvnMap,
        setFtDatIdToDvnMap,
        setVxsRepairsByFtRepairs,
        setFtDamages,

        updateGenericSVGGraphics,
        initGenericGraphicFirst,
        setCurrentGenericGraphic,
        setCurrentGrapaObjectsByDatId,
        setCurrentFtDatId,
        setRepairPositionByFtDatIdAndDamage,
        clearRepairPositionByFtDatId
    },
    effects: {
        initGenericGraphicFx,
        setGenericGraphicFx
    }
};
