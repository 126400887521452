import { attach, guard, sample } from 'effector';
import deepmerge from 'deepmerge';

import { API2 } from '@dat/api2';
import { contractEffects, contractStores, contractEvents } from './stores';
import { sharedTemplateStores } from '../template';
import { sharedContractStatusStores } from './Status';
import { createInitialPayloadForCreateContract } from './utils/createInitialPayloadForCreateContract';

import { PartialPayloadForCreateOrUpdateContract, PayloadForCreateContract } from './types';

const {
    createOrUpdateContractFx,
    createContractFx,
    updateCurrentContractFx,
    getContractFx,
    getContractAfterCalculateFx,
    calculateCurrentContractFx
} = contractEffects;
const { updateInitialContract } = contractEvents;
const { contract, contractId, isContractDisabled, isGettingContractAfterUpdateEnabled } = contractStores;
const { statusName } = sharedContractStatusStores;
const { templateSettings } = sharedTemplateStores;

//TODO: create separate method for templates
createOrUpdateContractFx.use(
    attach({
        source: { templateId: sharedTemplateStores.templateId, ...templateSettings },
        effect: async (
            { contractType, networkType, templateId, currency, country },
            payload: PartialPayloadForCreateOrUpdateContract
        ) => {
            const response = await API2.myClaim.createOrUpdateContract({
                contractType,
                networkType,
                templateId: templateId || undefined,
                Dossier: {
                    Country: country,
                    Currency: currency,
                    ...payload.Dossier
                },
                ...payload
            });
            const contractId = response.return;

            if (!contractId) throw new Error('createOrUpdateContractFx: bad response');

            return contractId;
        }
    })
);

createContractFx.use(
    attach({
        source: templateSettings,
        mapParams: (payload: PayloadForCreateContract, { country, currency }) =>
            deepmerge(payload || {}, createInitialPayloadForCreateContract({ country, currency })),
        effect: createOrUpdateContractFx
    })
);

sample({
    source: { statusName, disabledStatuses: templateSettings.disabledStatuses },
    fn: ({ statusName, disabledStatuses }) => Boolean(statusName && disabledStatuses?.includes(statusName)),
    target: isContractDisabled
});

guard({
    source: updateCurrentContractFx.doneData,
    filter: isGettingContractAfterUpdateEnabled,
    target: getContractFx
});

sample({
    clock: calculateCurrentContractFx.doneData,
    source: contract,
    fn: (contract, calcResponse): DAT2.ContractFromGetContract => ({
        ...contract,
        Dossier: calcResponse.calculationResult?.Dossier
    }),
    target: contract
});

sample({
    clock: calculateCurrentContractFx.done,
    source: contractId,
    target: getContractAfterCalculateFx
});

sample({
    clock: getContractAfterCalculateFx.doneData,
    target: updateInitialContract
});
