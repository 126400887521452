// import { InterimCalculationProps } from './InterimCalculationTypes';
import React from 'react';
import { Row, Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { interimCalculationModel } from '../../stores/interimCalculationModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import './InterimCalculation02.css';

export interface InterimCalculation02Props {
    isMobile: boolean;
}

export const InterimCalculation02 = ({ isMobile: _ }: InterimCalculation02Props) => {
    const { t } = useTranslation();

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    const interimCalculation = useStore(interimCalculationModel.stores.interimCalculation);
    // const isLoading = useStore(interimCalculationModel.stores.isLoading);
    const isLoading = useStore(interimCalculationModel.effects.calculateFX.pending);
    const isExpanded = useStore(interimCalculationModel.stores.isExpanded);

    // const useInterimCalculation = pluginOptions?.settings?.useInterimCalculation;

    const dossierCurrency = pluginOptions?.settings?.contract?.Dossier?.Currency;
    const locale = pluginOptions?.locale;

    const resultFormat = pluginOptions?.settings?.priceFormat;
    const formatter = new Intl.NumberFormat(
        locale || resultFormat?.locales || 'en-US',
        resultFormat?.numberFormatOptions
            ? {
                  ...resultFormat?.numberFormatOptions,
                  style: 'currency',
                  currency: dossierCurrency || resultFormat?.numberFormatOptions?.currency || 'EUR'
              }
            : {
                  style: 'currency',
                  currency: dossierCurrency || interimCalculation?.currency || 'EUR',
                  minimumFractionDigits: 2
              }
    );

    // const summaryText =
    //     interimCalculation?.totalCostsVAT !== undefined
    //         ? interimCalculation?.totalCostsVAT + ' ' + (interimCalculation?.currency || '')
    //         : 'Missing required parameters';

    const totalCosts = interimCalculation?.totalCosts;
    const labourCosts = interimCalculation?.labourCosts;
    const sparePartsCosts = interimCalculation?.sparePartsCosts;
    const lacquerCosts = interimCalculation?.lacquerCosts;
    const totalCostsWithVat = interimCalculation?.totalCostsVAT;

    return (
        <div style={{ margin: '8px 16px' }}>
            <Spin spinning={isLoading}>
                <Row gutter={[8, 8]} align="middle">
                    <Col span={12} className="wedat-grapa-repair-summaries-header">
                        {t('InterimCalculationSummary.totalCosts')}
                    </Col>
                    <Col
                        onClick={_ => {
                            interimCalculationModel.events.setIsExpanded(!isExpanded);
                        }}
                        span={12}
                        className="wedat-grapa-repair-position-ResultPrice"
                        // style={{ textAlign: 'right', fontSize: 'large', width: '100%', fontWeight: 'bold' }}
                    >
                        {formatter.format(totalCosts || 0)}
                    </Col>

                    {isExpanded && (
                        <>
                            <Col span={12} className="wedat-grapa-repair-summaries-label">
                                {t('InterimCalculationSummary.labourCosts')}
                            </Col>
                            <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                                {formatter.format(labourCosts || 0.0)}
                            </Col>

                            <Col span={12} className="wedat-grapa-repair-summaries-label">
                                {t('InterimCalculationSummary.sparePartsCosts')}
                            </Col>
                            <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                                {formatter.format(sparePartsCosts || 0.0)}
                            </Col>

                            <Col span={12} className="wedat-grapa-repair-summaries-label">
                                {t('InterimCalculationSummary.lacquerCosts')}
                            </Col>
                            <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                                {formatter.format(lacquerCosts || 0.0)}
                            </Col>

                            <Col span={12} className="wedat-grapa-repair-summaries-label">
                                {t('InterimCalculationSummary.totalCostsWithVat')}
                            </Col>
                            <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                                {formatter.format(totalCostsWithVat || 0.0)}
                            </Col>
                        </>
                    )}
                </Row>
            </Spin>
        </div>
    );
};
