import React, { useRef } from 'react';
import ReactNumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { InputProps } from '../Input/types';

import { Container, AbsolutePrefix, InputStyled } from './styles';
//TODO: version of `react-number-format` is fixed in package.json, because update causes errors in types. Unfix version when ready to fix type errors
export interface NumberInputProps
    extends Omit<NumberFormatPropsBase, 'onChange'>,
        Pick<InputProps, 'inputSize' | 'borderRadius' | 'stopPropagation'> {
    onChange?: (value: number | undefined) => void;
    withPrefix?: boolean;
    defaultLeftPadding?: number;
    label?: string;
    wrapperMarginTop?: string;
    noRightBorder?: boolean;
}

const StyledInput = React.forwardRef(({ ...rest }, _ref) => <InputStyled {...rest} />);

export const NumberInput: React.FC<NumberInputProps> = ({
    onChange,
    onValueChange,
    prefix,
    label,
    withPrefix,
    defaultLeftPadding,
    disabled,
    inputSize = 'default',
    onClick,
    stopPropagation,
    ...rest
}) => {
    const handleValueChange: NumberFormatPropsBase['onValueChange'] = values => {
        onChange?.(values.floatValue);
        onValueChange?.(values);
    };

    const prefixRef = useRef<HTMLDivElement>(null);
    const paddingLeft =
        withPrefix && prefixRef?.current?.clientWidth
            ? `${prefixRef?.current?.clientWidth + 7}px` || `${defaultLeftPadding}px`
            : 0;

    const handleOnClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        onClick?.(e);
        if (stopPropagation) {
            e.stopPropagation();
        }
    };

    return (
        <Container inputSize={inputSize} withPrefix={withPrefix} onClick={handleOnClick}>
            {withPrefix && (
                <AbsolutePrefix disabled={disabled} ref={prefixRef}>
                    {withPrefix ? prefix : ''}
                </AbsolutePrefix>
            )}
            <ReactNumberFormat
                decimalScale={2}
                allowLeadingZeros
                fixedDecimalScale={true}
                decimalSeparator=","
                customInput={StyledInput}
                onValueChange={handleValueChange}
                paddingLeft={defaultLeftPadding ? `${defaultLeftPadding + 7}px` : paddingLeft}
                label={label}
                disabled={disabled}
                inputSize={inputSize}
                {...rest}
            />
        </Container>
    );
};
