import { createEvent, createStore } from 'effector';

interface contractFieldsType {
    phone?: string;
    plateNumber?: string;
    modified: boolean;
}

const $contractFields = createStore<contractFieldsType>({ modified: false });

const setContractFields = createEvent<Partial<contractFieldsType>>();
$contractFields.on(setContractFields, (oldStare, val) => ({ ...oldStare, ...val, modified: true }));

const clearContractFields = createEvent<undefined>();
$contractFields.on(clearContractFields, _ => ({ modified: false }));

export const contractFieldsModel = {
    stores: {
        $contractFields
    },
    events: {
        setContractFields,
        clearContractFields
    }
};
