import { DatServicesUrls } from './types/api2datTypes';

export const defaultGoldPartnerSignature = {
    interfacePartnerNumber: '3450002',
    interfacePartnerSignature: 'jA0EAwMCxT8ofFDAzfNgySsKaEfRJdKTGRwtXXwV6Amqt2QPKq7OdLKifupXckIIrpRjyu9ADGT0pSNi'
};

export const datGoldServicesUrls: DatServicesUrls = {
    soapMyClaimTokenService: 'https://gold.dat.de/myClaim/soap/v2/MyClaimExternalAuthenticationService',
    soapMyClaimExternalServiceUrl: 'https://gold.dat.de/myClaim/soap/v2/MyClaimExternalService',
    soapVehicleIdentificationServiceUrl: 'https://gold.dat.de/myClaim/soap/v2/VehicleIdentificationService',
    soapVehicleRepairServiceUrl: 'https://gold.dat.de/myClaim/soap/v2/VehicleRepairService',
    urlConversionFunctionsService: 'https://gold.dat.de/myClaim/soap/v2/ConversionFunctionsService',
    restFastTrackServiceUrl: 'https://gold.dat.de/myClaim/rest/FastTrackService',
    restTokenServiceUrl: 'https://gold.dat.de/AuthorizationManager/service--/endpoint/tokenService',
    sparePartsServiceUrl: 'https://gold.dat.de/PartsInfo/services/SpareParts',
    // staticGrapaServiceUrl: 'https://storage.yandexcloud.net/grapa-data/grapa-service'
    staticGrapaServiceUrl: 'https://storage.yandexcloud.net/grapa-data/grapa-service2'
};

export const defaultProdPartnerSignature = {
    interfacePartnerNumber: '3451429',
    interfacePartnerSignature: '0AEEDC851F10198ADD4E2FB3915EA8881B4AD2FA2DA5E44F989C8ED8B1237F6E'
};

const API_URL = process.env.REACT_APP_API_URL || 'https://www.dat.de/';

export const datProdServicesUrls: DatServicesUrls = {
    soapMyClaimTokenService: `${API_URL}myClaim/soap/v2/MyClaimExternalAuthenticationService`,
    soapMyClaimExternalServiceUrl: `${API_URL}myClaim/soap/v2/MyClaimExternalService`,
    soapVehicleIdentificationServiceUrl: `${API_URL}myClaim/soap/v2/VehicleIdentificationService`,
    soapVehicleRepairServiceUrl: `${API_URL}myClaim/soap/v2/VehicleRepairService`,
    urlConversionFunctionsService: `${API_URL}myClaim/soap/v2/ConversionFunctionsService`,
    restFastTrackServiceUrl: `${API_URL}myClaim/rest/FastTrackService`,
    restTokenServiceUrl: `${API_URL}AuthorizationManager/service--/endpoint/tokenService`,
    sparePartsServiceUrl: `${API_URL}PartsInfo/services/SpareParts`,
    // staticGrapaServiceUrl: 'https://storage.yandexcloud.net/grapa-data/grapa-service'
    staticGrapaServiceUrl: 'https://storage.yandexcloud.net/grapa-data/grapa-service2'
};
