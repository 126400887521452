import { Checkbox } from '../../Checkbox';

import { StyledOption } from './styles';
import { OptionProps } from './types';

export const Option = (props: OptionProps) => {
    const {
        isSelected,
        selectProps: { isMulti }
    } = props;

    return (
        <StyledOption {...props} defaultWhiteSpaceOption={props?.defaultWhiteSpaceOption}>
            {isMulti && <Checkbox checked={isSelected} />}
            {props.children}
        </StyledOption>
    );
};
