import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { DamageSelectedModal } from './DamageSelectedModal';
import { FastTrackSVG } from '../FastTrackSVG/FastTrackSVG';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { aiGalleryEvents } from '@dat/ai-gallery/src/stores/gallery';
import { AiGallery } from '@dat/ai-gallery';
import { FastTrackElementsMenu } from '../FastTrackElementsMenu/FastTrackElementsMenu';
import { FastTrackElementsMenuMobile } from '../FastTrackElementsMenu/FastTrackElementsMenuMobile';
import { FastTrackSVGController } from '../FastTrackSVG/FastTrackSVGController';
import { commonModel } from '../../stores/commonModel';

import './fastTrackCalculation.css';

export const FastTrackCalculation: FC = () => {
    const { selectedElements, markElementId } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    const contract = useStore(fastTrackElementModel.stores.$contract);
    const contractId = useStore(fastTrackElementModel.stores.$contractId);
    const aiMode = useStore(fastTrackElementModel.stores.$aiMode);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const isMobile = useStore(commonModel.stores.isMobile);
    const summaryPanelOpened = useStore(commonModel.stores.summaryPanelOpened);

    const withAiGallery = !!contractId && aiMode;

    useEffect(() => {
        if (pluginOptions?.settings?.contractId && aiMode) {
            aiGalleryEvents.getGalleryContract(pluginOptions?.settings?.contractId);
        }
    }, [pluginOptions?.settings?.contractId, aiMode]);

    if (!fastTrackConfiguration) return null;

    if (!pluginOptions?.settings) return null;
    const { settings } = pluginOptions;
    const { showSVGFastTrack, showFastTrackElementsMenu } = settings;

    const aiEnabled = !!contract?.customTemplateData.entry.find(data => data.key === 'AIJsonResponse');

    const mediaQueryForTabletLandscape =
        '(min-width: ' + ((settings.maxMobileWidth || 862) + 1) + 'px) and (max-width: 1281px)';
    const isTabletPanelOpened = window.matchMedia(mediaQueryForTabletLandscape).matches && summaryPanelOpened;

    return (
        <div style={{ textAlign: 'center' }}>
            {showFastTrackElementsMenu && !withAiGallery ? (
                isMobile || isTabletPanelOpened ? (
                    <FastTrackElementsMenuMobile elementsMenuType="modal" />
                ) : (
                    <FastTrackElementsMenu />
                )
            ) : null}
            {showSVGFastTrack && !aiMode && (
                <FastTrackSVG
                    selectedElementsId={selectedElements.map(elem => elem.elementId)}
                    markElementId={markElementId}
                    onElementClick={fastTrackElementModel.events.setMarkElementId}
                    isMobile={isMobile || isTabletPanelOpened}
                />
            )}
            {withAiGallery && (
                <div
                    className={
                        isMobile || isTabletPanelOpened
                            ? 'weDat-ft-aiGallery-container-mobile'
                            : 'weDat-ft-aiGallery-container'
                    }
                >
                    <AiGallery
                        options={{
                            ...pluginOptions.settings,
                            isComponent: true,
                            contractId
                        }}
                    />
                </div>
            )}
            {showSVGFastTrack && (
                <FastTrackSVGController isMobile={isMobile || isTabletPanelOpened} aiEnabled={aiEnabled} />
            )}
            <DamageSelectedModal isMobile={isMobile} />
        </div>
    );
};
