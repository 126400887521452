import { forward, sample } from 'effector';

import { statusesEvents, statusesStores } from './index';
import { contractsListEvents, contractsListStores } from '../contractsList';
import { modalsEvents } from '../ui/modals';
import { contractStores } from '@dat/shared-models/contract';

const { statusChanged, openStatusModalByContractId } = statusesEvents;
const { contractId, initialStatusId } = statusesStores;
const { setStatusOfContract } = contractsListEvents;
const { contracts } = contractsListStores;
const { toggleIsStatusModalOpen } = modalsEvents;

forward({
    from: openStatusModalByContractId,
    to: toggleIsStatusModalOpen
});
// Need change contractId when we open statusModal in inbox, because we haven't contractId in inbox
forward({
    from: contractId,
    to: contractStores.contractId
});

sample({
    source: { contracts, contractId },
    fn: ({ contracts, contractId }) => {
        const currentContract = contracts.find(contract => contract.id === contractId);

        return currentContract?.status_id || null;
    },
    target: initialStatusId
});

sample({
    clock: statusChanged,
    source: contractId,
    fn: (contractId, status) => ({ contractId, status }),
    target: [setStatusOfContract, toggleIsStatusModalOpen]
});
