import * as Yup from 'yup';
import { FormUserProfile } from '../types';

export const DEFAULT_USER_PROFILE: DAT2.UserProfile = {
    name: '',
    surname: '',
    email: '',
    telephone: '',
    street: '',
    city: '',
    postCode: 0,
    accountNo: 0,
    sortCode: 0,
    bank: '',
    IBAN: '',
    BIC: '',
    Vat: '',
    tax: 0
};

export const FIELDS: Array<keyof DAT2.UserProfile> = [
    'name',
    'surname',
    'email',
    'telephone',
    'street',
    'city',
    'postCode',
    'accountNo',
    'sortCode',
    'bank',
    'IBAN',
    'BIC',
    'Vat',
    'tax'
];

export const GENERAL_FIELDS: Partial<keyof DAT2.UserProfile>[] = ['name', 'surname', 'email', 'telephone'];
export const ADDRESS_FIELDS: Partial<keyof DAT2.UserProfile>[] = ['street', 'city', 'postCode'];
export const BANK_FIELDS: Partial<keyof DAT2.UserProfile>[] = [
    'accountNo',
    'sortCode',
    'bank',
    'IBAN',
    'BIC',
    'Vat',
    'tax'
];

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const validationSchema = ({ name, surname, email, telephone }: FormUserProfile) =>
    Yup.object().shape<DAT2.UserProfile>({
        name: Yup.string().required().label(name),
        surname: Yup.string().required().label(surname),
        email: Yup.string().required().email(email),
        telephone: Yup.string().matches(phoneRegExp, telephone),
        street: Yup.string(),
        accountNo: Yup.number().min(12),
        sortCode: Yup.number().min(6),
        Vat: Yup.string().min(15).max(34),
        tax: Yup.number()
    });
