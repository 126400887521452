// PZSTTypeView
import React from 'react';

import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Col, Row, InputNumber, Select } from 'antd';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { workTimeUnits } from '../../../initData/workTimeUnits';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { convertRepairTypeToFullRepairType } from '../../../utils/ftRepairToRepairPosition';
import { mappingDVNWorkTypeWorkLevelModel } from '../../../stores/mappingDVNWorkTypeWorkLevelModel';

const { Option } = Select;

export function PZSTTypeView() {
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const {
        currentAssemblyGroup,
        currentAssemblyGroupObject: currentObj,
        currentDVNs
    } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);
    const { t } = useTranslation();

    const mappingDVNWorkTypeWorkLevel = useStore(mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel);

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const useWorkTypeLevel = pluginOptions?.settings?.useWorkTypeLevel;

    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);

    if (currentDVNs?.length !== 1) return null;
    const dvn = currentDVNs[0];
    const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;
    if (!dvnLR) return null;

    const hst = datECode.slice(2, 5);

    const repairCode =
        dvnLR.rcs.rc.filter(elem => elem === 'P' || elem === 'Z' || elem === 'S' || elem === 'T')[0] || '';
    const RepairType = convertRepairTypeToFullRepairType(repairCode);
    const timeUnitsPerHour = workTimeUnits.find(unit => unit.hst === hst)?.timeUnitsPerHour;

    const repairPosition = repairPositions.find(item => item.DATProcessId === dvn && item.RepairType === RepairType);

    const WorkTime = repairPosition?.WorkTime || 0;
    const workTimeInTimeUnits = Math.round(WorkTime * (timeUnitsPerHour || 1));
    const WorkPrice = repairPosition?.WorkPrice || 0;

    const WorkType = repairPosition?.WorkType;
    const WorkLevel = repairPosition?.WorkLevel;

    if (!currentAssemblyGroup || !RepairType) return null;

    const defWorkType = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workType;
    const defWorkLevel = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workLevel;

    return (
        <div style={{ margin: '16px 0px 16px 0px' }}>
            <Row align="middle" gutter={[8, 8]}>
                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('ManualRepairPositionForm.WorkTime')}
                </Col>
                {timeUnitsPerHour && (
                    <>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                type="number"
                                value={workTimeInTimeUnits}
                                onChange={value => {
                                    RepairPositionsModel.event.addPZSTDamage({
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        DATProcessId: dvnLR.dvn || 0,
                                        Description: currentObj?.titleMenu || '',
                                        WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                        WorkPrice: 0,
                                        repairType: RepairType
                                    });
                                }}
                            />
                            <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                    </>
                )}
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={WorkTime}
                        onChange={value => {
                            RepairPositionsModel.event.addPZSTDamage({
                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                DATProcessId: dvnLR.dvn || 0,
                                Description: currentObj?.titleMenu || '',
                                WorkTime: Math.round(value * 100) / 100,
                                WorkPrice: 0,
                                repairType: RepairType
                            });
                        }}
                    />
                    <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('ManualRepairPositionForm.WorkPrice')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={WorkPrice}
                        onChange={value => {
                            RepairPositionsModel.event.addPZSTDamage({
                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                DATProcessId: dvnLR.dvn || 0,
                                Description: currentObj?.titleMenu || '',
                                WorkPrice: parseFloat(value.toString()),
                                WorkTime: 0,
                                repairType: RepairType
                            });
                        }}
                    />
                </Col>

                {useWorkTypeLevel && (
                    <>
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ExtPartNoInfoView.WorkType')}
                        </Col>
                        <Col span={12}>
                            <Select
                                size="large"
                                style={{ width: 120 }}
                                defaultValue={defWorkType}
                                value={WorkType}
                                onChange={value => {
                                    RepairPositionsModel.event.addPZSTDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: currentObj?.titleMenu || '',
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        repairType: RepairType,
                                        WorkType: value || null
                                    });
                                }}
                                allowClear
                            >
                                <Option value="other">{t('WorkType.Other')}</Option>
                                <Option value="mechanic">{t('WorkType.Mechanic')}</Option>
                                <Option value="car body">{t('WorkType.CarBody')}</Option>
                                <Option value="electric">{t('WorkType.Electric')}</Option>
                            </Select>
                        </Col>

                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ExtPartNoInfoView.WorkLevel')}
                        </Col>
                        <Col span={12}>
                            <Select
                                size="large"
                                style={{ width: 120 }}
                                defaultValue={defWorkLevel}
                                value={WorkLevel}
                                onChange={value => {
                                    RepairPositionsModel.event.addPZSTDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: currentObj?.titleMenu || '',
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        repairType: RepairType,
                                        WorkLevel: value || null
                                    });
                                }}
                                allowClear
                            >
                                <Option value="0">{t('WorkLevel.0')}</Option>
                                <Option value="1">{t('WorkLevel.1')}</Option>
                                <Option value="2">{t('WorkLevel.2')}</Option>
                                <Option value="3">{t('WorkLevel.3')}</Option>
                            </Select>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
}
