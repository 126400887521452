import _ from 'lodash';
import { Subject, SubjectType } from '../../../types/subjects';

type Param = {
    initialSubjects: Subject[];
    newSubjects: Subject[];
    subjectType: SubjectType;
};

type Result = BFF.AddressBook.Request.UpdateSubjects;

//TODO: very heavy fn blocks thread when 5000 subjects
export const createPayloadForUpdateSubjects = ({ initialSubjects, newSubjects, subjectType }: Param): Result => ({
    subjectType,
    toAdd: getAddedDiff(initialSubjects, newSubjects),
    toUpdate: getUpdatedDiff(initialSubjects, newSubjects),
    toDelete: getDeletedDiff(initialSubjects, newSubjects).map(({ _id }) => _id)
});

const getAddedDiff = (initialSubjects: Subject[], newSubjects: Subject[]) =>
    _.differenceBy(newSubjects, initialSubjects, '_id');

const getUpdatedDiff = (initialSubjects: Subject[], newSubjects: Subject[]) => {
    const intersectionById = _.intersectionBy(newSubjects, initialSubjects, '_id');

    return _.differenceWith(intersectionById, initialSubjects, _.isEqual);
};

const getDeletedDiff = (initialSubjects: Subject[], newSubjects: Subject[]) =>
    _.differenceBy(initialSubjects, newSubjects, '_id');
