import { ExtractActions, FormStep, ResultForm, Steps } from '../types';
import { getAction } from '../utils/common';
import { createContext } from 'react';

interface State {
    resultForm: ResultForm;
    step: Steps;
    /* index of retaken photo on summary page */
    retakeIndex: number;
    videoStream: MediaStream | null;
}

export const initialState: State = {
    resultForm: {
        steps: []
    },
    step: Steps.AwaitMedia,
    retakeIndex: 0,
    videoStream: null
};

export const actions = {
    setResultFormStep: (formStep: FormStep) => getAction('SET_RESULT_FORM_STEP', formStep),
    setAdditionalStep: (additionalPhoto: string) => getAction('SET_ADDITIONAL_STEP', { additionalPhoto }),
    changeStep: (nextStep?: Steps) => getAction('CHANGE_STEP', { nextStep }),
    /* go to retake photo step */
    retakePhoto: (indexPhoto: number) => getAction('RETAKE_PHOTO', { indexPhoto }),
    /* save of retake photo result */
    setRetakePhoto: (photo: string, comment: string) => getAction('SET_RETAKE_PHOTO', { photo, comment }),
    setVideoStream: (stream: State['videoStream']) => getAction('SET_VIDEO_STREAM', { stream })
};

type ActionTypes = ExtractActions<typeof actions>;

type ContextType = {
    state: State;
    dispatch: (action: ActionTypes) => void;
};

export const reducer = (state: State, action: ActionTypes) => {
    switch (action.type) {
        case 'SET_RESULT_FORM_STEP':
            return {
                ...state,
                resultForm: {
                    ...state.resultForm,
                    steps: [...state.resultForm.steps, action.payload]
                }
            };

        case 'SET_VIDEO_STREAM':
            return {
                ...state,
                videoStream: action.payload.stream
            };

        case 'SET_ADDITIONAL_STEP':
            return {
                ...state,
                step: Steps.SendForm,
                resultForm: {
                    ...state.resultForm,
                    additionalSteps: [...(state.resultForm.additionalSteps || []), action.payload.additionalPhoto]
                }
            };

        case 'CHANGE_STEP':
            const { nextStep } = action.payload;
            return {
                ...state,
                step: nextStep ? nextStep : state.step + 1
            };

        case 'RETAKE_PHOTO':
            const { indexPhoto } = action.payload;
            return {
                ...state,
                step: Steps.Retake,
                retakeIndex: indexPhoto
            };

        case 'SET_RETAKE_PHOTO':
            const { retakeIndex } = state;
            const { comment, photo } = action.payload;
            const newResultFormSteps = [...state.resultForm.steps];

            newResultFormSteps[retakeIndex] = {
                ...newResultFormSteps[retakeIndex],
                comment,
                photo
            };

            return {
                ...state,
                resultForm: {
                    ...state.resultForm,
                    steps: newResultFormSteps
                },
                step: Steps.SendForm,
                retakeIndex: 0
            };

        default:
            return state;
    }
};

export const FormContext = createContext({} as ContextType);
