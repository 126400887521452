import { createStore, createEffect, createEvent, forward } from 'effector';
import { getParts, getPart, deletePart, editPart, createPart } from '@dat/api2/services/part-selection';
import { PartSelectionFormState } from './types';

const DEFAULT_FORM_STATE: PartSelectionFormState = {
    country: '',
    repairType: '',
    repairCode: '',
    price: 0,
    workingTime: 0,
    quantity: 0,
    description: '',
    currency: '',
    customerId: 0
};

// events
const setIdEvent = createEvent<number>();
const createNewPart = createEvent();

// * effects
const getPartsEffect = createEffect(getParts);
const getPartEffect = createEffect(getPart);
const deletePartEffect = createEffect(deletePart);
const editPartEffect = createEffect(editPart);
const createPartEffect = createEffect(createPart);

// * stores
const initialFormState = createStore<PartSelectionFormState>(DEFAULT_FORM_STATE);

const parts = createStore<DAT2.Internal.PartData | null>(null);
const part = createStore<DAT2.Internal.Rate | null>(null).on(getPartEffect.doneData, (_, payload) => payload.data);

parts.on(getPartsEffect.doneData, (_, payload) => payload.data);
initialFormState.on(getPartEffect.doneData, (_, payload) => payload.data).on(createNewPart, () => DEFAULT_FORM_STATE);

forward({
    from: setIdEvent,
    to: getPartEffect
});

forward({
    from: [createPartEffect.done, deletePartEffect.done, editPartEffect.done],
    to: getPartsEffect
});

export const partSelectionEffects = {
    getPartsEffect,
    getPartEffect,
    deletePartEffect,
    editPartEffect,
    createPartEffect
};
export const partSelectionEvents = {
    setIdEvent,
    createNewPart
};

export const partSelectionStores = {
    parts,
    part,
    initialFormState
};
