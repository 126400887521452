/**
 * hst === datECode.slice(2,5)
 */
export const workTimeUnits: {
    hst: string;
    manufacturer: string;
    timeUnitsPerHour: number;
}[] = [
    {
        hst: '060',
        manufacturer: 'Audi',
        timeUnitsPerHour: 100
    },
    {
        hst: '130',
        manufacturer: 'BMW',
        timeUnitsPerHour: 12
    },
    {
        hst: '189',
        manufacturer: 'Cupra',
        timeUnitsPerHour: 100
    },
    {
        hst: '580',
        manufacturer: 'MINI',
        timeUnitsPerHour: 12
    },
    {
        hst: '710',
        manufacturer: 'Porsche',
        timeUnitsPerHour: 100
    },
    {
        hst: '790',
        manufacturer: 'Seat',
        timeUnitsPerHour: 100
    },
    {
        hst: '800',
        manufacturer: 'Škoda',
        timeUnitsPerHour: 100
    },
    {
        hst: '905',
        manufacturer: 'VW',
        timeUnitsPerHour: 100
    },
    {
        hst: '910',
        manufacturer: 'Volvo',
        timeUnitsPerHour: 10
    }
];
