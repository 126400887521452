import { forward, sample } from 'effector';

import { formEvents, formStores } from './index';
import { statusesEffects, statusesStores } from '../statuses';
import { componentEffects, componentStores } from '../component';
import { findSelectedStatus, Result } from './utils/findSelectedStatus';

import { FormValues } from '../../types';
import { sharedContractStatusEffects } from '@dat/shared-models/contract/Status';
import { contractStores } from '@dat/shared-models/contract';

const { formSubmitted } = formEvents;
const { selectOptions, initialFormValues, existingStatusSubmitted } = formStores;
const { possibleStatuses } = statusesStores;
const { changeStatusFx } = statusesEffects;
const { callOnStatusChangeFx, callOnStatusChangeFailedFx } = componentEffects;
const { props } = componentStores;
const { getPossibleContractStatusTransitionsFx } = sharedContractStatusEffects;
const { contractId } = contractStores;

sample({
    source: possibleStatuses,
    fn: statuses => statuses.map(({ statusId, name }) => ({ value: statusId, label: name, key: statusId })),
    target: selectOptions
});

sample({
    source: props,
    fn: (props): FormValues => ({ statusId: props.initialStatusId, note: '' }),
    target: initialFormValues
});

sample({
    clock: formSubmitted,
    source: possibleStatuses,
    fn: (possibleStatuses, formValues): Result => findSelectedStatus(possibleStatuses, formValues),
    target: existingStatusSubmitted
});

sample({
    clock: existingStatusSubmitted,
    filter: (existingStatusSubmitted): existingStatusSubmitted is DAT2.ContractStatusTransition =>
        !!existingStatusSubmitted,
    target: changeStatusFx
});

forward({
    from: changeStatusFx.done.map(({ params }) => params),
    to: callOnStatusChangeFx
});

forward({
    from: changeStatusFx.fail,
    to: callOnStatusChangeFailedFx
});

sample({
    clock: callOnStatusChangeFx.doneData,
    source: contractId,
    fn: contractId => contractId,
    target: getPossibleContractStatusTransitionsFx
});
