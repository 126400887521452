import { createEffect } from 'effector';

import { API2 } from '@dat/api2';
import { getParsedArraySafe } from '@dat/api2/utils';
import { parseAttachmentItem } from '@dat/core/utils/attachments/parseAttachmentItem';

import { PayloadForUploadMultipleAttachmentsByFolderId } from './types';
import { getFilenameWithoutExt } from './utils/getFilenameWithoutExt';

const listAttachmentsOfContractFx = createEffect(async (payload: DAT2.Request.ListAttachmentsOfContract) => {
    let response;
    try {
        response = await API2.myClaim.listAttachmentsOfContract(payload);
    } catch (e) {
        return [];
    }

    const attachments = getParsedArraySafe(response.return);
    const attachmentsWithBase64 = attachments.map(parseAttachmentItem);

    return attachmentsWithBase64.reverse();
});

const uploadSingleAttachmentFx = createEffect((payload: DAT2.Request.UploadAttachmentByFolderID) =>
    API2.myClaim.uploadAttachmentByFolderID({
        ...payload,
        attachmentItem: {
            published: new Date().toISOString(),
            uploaded: new Date().toISOString(),
            ...payload.attachmentItem
        }
    })
);

const uploadMultipleAttachmentsFx = createEffect(
    async ({ contractId, attachments }: PayloadForUploadMultipleAttachmentsByFolderId) => {
        const result: DAT2.Response.UploadAttachmentByFolderID[] = [];

        // Works for only one file...
        for (let i = 0; i < attachments.length; i++) {
            const attachment = await uploadSingleAttachmentFx({ contractId, attachmentItem: attachments[i] });

            result.push(attachment);
        }

        return result;
    }
);

const deleteAttachmentFx = createEffect(
    async ({ contractId, folderId, filename }: { contractId: number; folderId: number; filename: string }) => {
        // collect attachments for ids
        const attachments = await API2.myClaimInternal.getAttachmentsOfFolder({
            claimId: contractId,
            folderId
        });

        const filenameToDelete = getFilenameWithoutExt(filename);
        const attachmentToDelete = attachments.find(
            attachment => getFilenameWithoutExt(attachment.fileName) === filenameToDelete
        );

        if (attachmentToDelete) {
            return API2.myClaimInternal.deleteAttachments({
                claimId: contractId,
                folderId,
                attachmentsIds: [attachmentToDelete.id]
            });
        }
    }
);

export const sharedAttachmentsEffects = {
    listAttachmentsOfContractFx,
    uploadSingleAttachmentFx,
    uploadMultipleAttachmentsFx,
    deleteAttachmentFx
};
