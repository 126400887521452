export const summarySVG_css = `

g[id^='DATID_'] > path.repairLight {
    fill: #f9e603 !important;
    fill-opacity: 0.8 !important;
    stroke: #FFFFFF;
    stroke-width: 0.2;
    stroke-miterlimit: 10;
}

g[id^='DATID_'] > path.repairMedium {
    fill: orange !important;
    fill-opacity: 0.8 !important;
    stroke: #FFFFFF;
    stroke-width: 0.2;
    stroke-miterlimit: 10;
}

g[id^='DATID_'] > path.repairHard {
    fill: red !important;
    fill-opacity: 0.8 !important;
    stroke: #FFFFFF;
    stroke-width: 0.2;
    stroke-miterlimit: 10;
}

g[id^='DATID_'] > path {
    fill: white;
    fill-opacity: 0.0;
}

`;
