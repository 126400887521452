import { FC, useEffect, useMemo, useState } from 'react';
import { merge } from 'effector';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { contractStores } from '@dat/shared-models/contract';
import { pluginEvents } from '../../stores/plugin';

import { useInstance } from '../../hooks/useInstance';
import { useSubjectFromContract } from '../../hooks/useSubjectFromContract';
import { parseToFormBuilderData } from '../../stores/plugin/utils/parseToFormBuilderData';

import { createNewSubject } from '../../utils/createNewSubject';
import { getParsedArraySafe } from '@dat/api2/utils';
import { createEntriesArrayFromSubject } from '../../utils/createEntriesArrayFromSubject';

import { defaultFields } from '../../constants/defaultFields';

import { FormBuilder, FormBuilderPluginOptions } from '@dat/form-builder';
import { Container, FormStyled } from './styles';
import { subjectsDataEffects } from '../../stores/subjectsData';

interface Props {
    formValues: BFF.AddressBook.Subject;
    setFormValues: React.Dispatch<React.SetStateAction<BFF.AddressBook.Subject>>;
}

export const SubjectForm: FC<Props> = ({ formValues, setFormValues }) => {
    const { t } = useTranslation();
    const {
        subjectUpdated,
        saveButtonClicked,
        selectButtonClicked,
        assignSubject,
        saveSubjects,
        subjectSelectedInList,
        newSubjectAdded,
        displaySubject,
        showFormOnMobile,
        closeModal,
        instanceId,
        showList,
        assignedSubject,
        onAssignSubject,
        displayedSubject,
        fields,
        subjectType,
        onCloseModal
    } = useInstance(
        'instanceId',
        'showList',
        'onAssignSubject',
        'assignedSubject',
        'displayedSubject',
        'fields',
        'subjectType',
        'onCloseModal'
    );

    const subjectFromContract = useSubjectFromContract();
    const contract = useStore(contractStores.contract);
    const contractId = useStore(contractStores.contractId);
    const customTemplateDataEntry = getParsedArraySafe(contract?.customTemplateData?.entry);
    const [isAddedByAddButton, setIsAddedByAddButton] = useState(false);

    const resultFormBuilderData = useMemo(
        () => parseToFormBuilderData(fields || defaultFields, showList ? displayedSubject : subjectFromContract, t),
        [displayedSubject, fields, showList, subjectFromContract, t]
    );

    const formBuilderOptions = useMemo<FormBuilderPluginOptions>(
        () => ({
            data: resultFormBuilderData,
            options: {},
            onComplete: () => {},
            onChangeForm: values => {
                setFormValues(prevState => ({ ...prevState, ...values }));
            }
        }),
        [resultFormBuilderData, setFormValues]
    );

    useEffect(
        () =>
            merge([subjectSelectedInList, newSubjectAdded]).watch(subject => {
                setIsAddedByAddButton(true);
                setFormValues({ ...createNewSubject(), ...subject });
                subjectUpdated(formValues);
                displaySubject(subject);
                showFormOnMobile();
            }),
        [
            subjectSelectedInList,
            newSubjectAdded,
            subjectUpdated,
            formValues,
            displaySubject,
            showFormOnMobile,
            setFormValues
        ]
    );

    useEffect(
        () =>
            saveButtonClicked.watch(() => {
                if (!isAddedByAddButton && displayedSubject._id !== assignedSubject?._id) {
                    pluginEvents.addSubjectToInstance({ instanceId, subject: { ...displayedSubject, ...formValues } });
                }
                subjectUpdated(formValues);
                if (showList) {
                    saveSubjects();
                }
                setIsAddedByAddButton(false);
            }),
        [
            assignedSubject?._id,
            displayedSubject,
            formValues,
            instanceId,
            isAddedByAddButton,
            saveButtonClicked,
            saveSubjects,
            showList,
            subjectUpdated
        ]
    );

    useEffect(
        () =>
            selectButtonClicked.watch(async () => {
                if (showList) {
                    saveButtonClicked();
                    await subjectsDataEffects.updateContractSubjectFx(
                        createEntriesArrayFromSubject(formValues, subjectType)
                    );
                } else {
                    await subjectsDataEffects.updateContractSubjectFx(
                        createEntriesArrayFromSubject(formValues, subjectType)
                    );
                }
                assignSubject(showList ? displayedSubject : formValues);
                closeModal();
                onCloseModal?.();
                onAssignSubject?.(showList ? displayedSubject : formValues);
            }),
        [
            assignSubject,
            closeModal,
            contract,
            contractId,
            customTemplateDataEntry,
            displayedSubject,
            formValues,
            onAssignSubject,
            onCloseModal,
            saveButtonClicked,
            selectButtonClicked,
            showList,
            subjectType
        ]
    );

    return (
        <FormStyled>
            <Container>
                <FormBuilder options={formBuilderOptions} />
            </Container>
        </FormStyled>
    );
};
