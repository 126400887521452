import { format } from 'date-fns';
import { PlateReaderResult } from '../types/ocr';

export const formatPlateReaderResult = (response: PR.OCRPlateNumber): PlateReaderResult => {
    const probableResult = response.results?.[0];

    return {
        licencePlate: probableResult?.plate?.toUpperCase(),
        country: probableResult?.region?.code.toUpperCase(),
        OCR: {
            vehicleModelOCR: probableResult?.model_make?.[0].model,
            vehicleColorOCR: probableResult?.color?.[0].color,
            vehicleMakerOCR: probableResult?.model_make?.[0].make,
            predictedPlateOCR: probableResult?.candidates
                ?.map(({ score, plate }) => `${plate} (${Math.round(score * 100 * 10) / 10}%)`.toUpperCase())
                ?.join(', '),
            OCRPlateScanDate: format(new Date(), 'dd/MM/yyyy')
        }
    };
};
