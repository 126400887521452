import { GaugeContent, GaugeMask, GaugeNeedle } from './styles';
import React from 'react';

export interface GaugeProps {
    efficiency: number;
}

export const Gauge: React.FC<GaugeProps> = ({ efficiency }) => (
    <>
        <GaugeContent />
        <GaugeMask />
        <GaugeNeedle value={efficiency} />
    </>
);
