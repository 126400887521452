import styled from 'styled-components';
import { Button } from '@wedat/ui-kit/components/Button';

export const EditButton = styled(Button)`
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 425px) {
        justify-content: flex-start;
    }
`;

export const DeleteButton = styled(Button)`
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 425px) {
        justify-content: flex-start;
    }
`;
