import { guard, sample } from 'effector';
import { generatePath } from 'react-router-dom';

import { ClaimPageGate, routerEffects } from './index';
import { historyEvents } from '../history';
import { pluginEffects, pluginStores } from '../plugin';
import { contractEffects, contractEvents, contractStores } from '@dat/shared-models/contract';
import { ROUTES, ROUTES_PARAMS_VALUES } from '../../constants/router';
import { htmlEffects } from '../html';
import { guardPath } from '@dat/core/utils/effector/guardPath';
import { sharedContractStatusEvents } from '@dat/shared-models/contract/Status';

const { pushClaimRouteByIdFx } = routerEffects;
const { getContractFx } = contractEffects;
const { newContractReceived } = contractEvents;
const { contractId } = contractStores;
const { reloadPluginFx } = pluginEffects;
const { urlPath } = pluginStores;
const { resetHtmlToInitialFx } = htmlEffects;
const { disabledStatusSet } = sharedContractStatusEvents;

pushClaimRouteByIdFx.use((contractId: string | number) => {
    historyEvents.push([generatePath(ROUTES.claim.opening, { contractId })]);
});

/* Creating new contract */
guardPath({
    path: `/claims/${ROUTES_PARAMS_VALUES.claim.contractId.new}`,
    basename: urlPath,
    clock: newContractReceived,
    source: contractId,
    target: pushClaimRouteByIdFx
});

/* Getting existing contract */
const existingContractRouteOpened = guard({
    source: ClaimPageGate.open,
    filter: ({ contractId }) => contractId !== ROUTES_PARAMS_VALUES.claim.contractId.new
});

sample({
    source: existingContractRouteOpened,
    fn: ({ contractId }) => contractId,
    target: getContractFx
});

/* Exiting claim page */
getContractFx.fail.watch(() => {
    historyEvents.replace([ROUTES.inbox.root]);
});

disabledStatusSet.watch(() => {
    historyEvents.push([ROUTES.inbox.root]);
});

ClaimPageGate.close.watch(() => {
    resetHtmlToInitialFx();
    reloadPluginFx();
});
