import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react/effector-react.cjs';
import { useTranslation } from 'react-i18next';

import { printoutEffects, printoutStores } from '../../stores/print';

import { PrintoutIcon } from '@wedat/ui-kit/components/Icons';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';

import { Container, Title, Icon, ReportsContainer, GroupName, AccordionHeader } from './styles';
import { ListItem } from '../Listitem';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Accordion, AccordionItem } from '@wedat/ui-kit/components/Accordion';

interface Props {
    contractId: number;
    displayTitle?: boolean;
    menuSide?: 'left' | 'right';
    isComponent?: boolean;
    isOpen?: boolean;
    callbackClose?: () => void;
    registrationNumber?: string;
    vin?: string;
}

export const Main: FC<Props> = ({
    contractId,
    displayTitle = true,
    isComponent,
    isOpen,
    callbackClose,
    registrationNumber,
    vin
}) => {
    const { t } = useTranslation();
    const isTablet = useMedia(sizes.tablet);
    const printoutConfig = useStore(printoutStores.printoutConfig);

    const { printoutReports, isDefaultReportShown } = printoutConfig;

    const [isListVisible, setIsListVisible] = useState(!!isOpen);

    useEffect(() => {
        setIsListVisible(!!isOpen);
    }, [isOpen]);

    const getDocument = async (reportIdentification?: number, description?: string) => {
        const data = { contractId, registrationNumber, vin, reportIdentification, description };
        await printoutEffects.getDocumentWithConfFx(data);
    };

    const openDocument = async (reportIdentification?: number) => {
        const data = { contractId, registrationNumber, vin, reportIdentification };
        await printoutEffects.openDocumentFx(data);
    };

    const getItems = (groupIndex: number) => (
        <>
            {printoutReports?.[groupIndex]?.reports?.map(report => (
                <ListItem
                    description={report.description}
                    getDocument={() => getDocument(report.id, report.description)}
                    openDocument={() => openDocument(report.id)}
                />
            ))}
        </>
    );

    const defaultReport = () => {
        isDefaultReportShown && (
            <ListItem
                description={t('default')}
                getDocument={() => getDocument()}
                openDocument={() => openDocument()}
            />
        );
    };

    const SlideComponent = () => (
        <SlideUpMenu
            isOpen={isListVisible}
            closeSlideUpMenu={() => {
                setIsListVisible(false);
                callbackClose?.();
            }}
        >
            <Accordion allowMultipleExpanded allowZeroExpanded>
                {defaultReport()}
                {printoutReports?.map((item, idx) => (
                    <>
                        <AccordionItem
                            uuid={item.groupName}
                            header={<AccordionHeader font="noteBold">{item.groupName}</AccordionHeader>}
                        >
                            {getItems(idx)}
                        </AccordionItem>
                    </>
                ))}
            </Accordion>
        </SlideUpMenu>
    );

    if (isComponent && isTablet) return <SlideComponent />;

    return (
        <>
            <Container
                onClick={() => {
                    if (!printoutReports?.length) {
                        getDocument();
                    } else {
                        setIsListVisible(true);
                    }
                }}
            >
                <Icon>
                    <PrintoutIcon />
                </Icon>
                {displayTitle && <Title>{t('printout')}</Title>}
                {isTablet && <SlideComponent />}
            </Container>

            {!isTablet && (
                <ModalPrimary title={t('printout')} isOpen={isListVisible} onDismiss={() => setIsListVisible(false)}>
                    {defaultReport()}
                    {printoutReports?.map((item, idx) => (
                        <>
                            <GroupName font="noteBold">{item.groupName}</GroupName>
                            <ReportsContainer>{getItems(idx)}</ReportsContainer>
                        </>
                    ))}
                </ModalPrimary>
            )}
        </>
    );
};
