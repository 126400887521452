import { DiscountTypes } from '../../constants/enums';

export const netCorr = (item: DAT2.TotalSummaryItemItaly) => {
    const isPercent = item.DiscountDisplayType === DiscountTypes.percent;

    if (isPercent && item.ValueNetDiscountPerc) {
        const valDiscPerc = item.ValueNetDiscountPerc;
        const valNet = item.ValueNet || 0;

        return valNet - (valNet * valDiscPerc) / 100;
    } else if (!isPercent && item.ValueNetDiscountValue) {
        const valDiscPerc = item.ValueNetDiscountValue;
        const valNet = item.ValueNet || 0;

        return valNet - valDiscPerc;
    }

    return item.ValueNetCorrected || 0;
};
