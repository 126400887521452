import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { ErrorBoundary } from '@wedat/ui-kit/components/ErrorBoundary';
import { testCredentials3132781 } from '@dat/core/constants/testCredentials';
import { htmlEvents } from './stores/html';

import App from './App';

import { PluginOptions } from './types/plugin';

htmlEvents.createInitialHtmlSnapshot();

if (process.env.NODE_ENV === 'development') {
    render(
        <React.StrictMode>
            <App options={{ credentials: testCredentials3132781 }} />
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    window.CLAIM_MANAGEMENT_API = {
        init: (options: PluginOptions) => {
            const renderElement = document.querySelector(options.selector!);

            if (renderElement) {
                unmountComponentAtNode(renderElement);
                render(
                    <ErrorBoundary>
                        <App options={options} />
                    </ErrorBoundary>,
                    renderElement
                );
            }
        }
    };
}
