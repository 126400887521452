import React from 'react';
import { useStore } from 'effector-react';

import { vehicleImagesStores } from '../../../../stores/images';
import { contractStores } from '@dat/shared-models/contract';
import { commify } from '@wedat/ui-kit/utils/commify';

import { Container, GalleryWrapper, Price, PlateInfoContainer, Image, GalleryContainer } from './styles';
import { currencyValues } from '@dat/core/constants/currency';
import { FuelLevel } from '../../../../components/FuelLevel';

export const Slider: React.FC = () => {
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);
    const contract = useStore(contractStores.contract);

    const currency = contract?.Dossier?.Currency;
    const currentCurrency = currencyValues[currency || 'EUR'];

    const totalDamageCoverage =
        contract?.Dossier?.RepairCalculation?.CalculationSummary?.TotalNetCorrected ??
        contract?.Dossier?.RepairCalculation?.CalculationSummary?.TotalNetCosts;

    const displayedTotalDamage =
        totalDamageCoverage && currentCurrency ? `${commify(totalDamageCoverage.toFixed(2))} ${currentCurrency}` : '';

    return (
        <Container>
            <GalleryWrapper noImage={!vehicleImages.length}>
                <GalleryContainer
                    center={!displayedTotalDamage}
                    bottom={!vehicleImages.length}
                    right={!displayedTotalDamage && !vehicleImages.length}
                >
                    {vehicleImages && vehicleImages[0] && (
                        <Image src={`data:image/png;base64,${vehicleImages[0].imageBase64}`} alt="vehicle photo" />
                    )}

                    {displayedTotalDamage && (
                        <PlateInfoContainer>
                            <Price asTag="span" textOverflow="ellipsis">
                                {displayedTotalDamage}
                            </Price>
                        </PlateInfoContainer>
                    )}
                    <FuelLevel />
                </GalleryContainer>
            </GalleryWrapper>
        </Container>
    );
};
