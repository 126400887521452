import { createEvent, createStore, combine, forward, restore, sample } from 'effector';

import { PluginOptionsEditor } from '../types/pluginOptionsModelTypes';
import { PluginOptions } from '../types/plugin';
import Hjson from 'hjson';
import * as R from 'ramda';
import merge from 'deepmerge';
import { defaultOptions } from '../defaultOptions';

// PluginOptionsModel

const setPluginOptions = createEvent<PluginOptions>();
const pluginOptions = restore(setPluginOptions, null);
const updatePluginOptions = createEvent<PluginOptions>();

const updateTextFromPluginOptions = createEvent<PluginOptions>();

const applyTextOptions = createEvent();

sample({
    source: pluginOptions,
    clock: updatePluginOptions,
    fn: (oldState, newVal) => {
        let newOption = newVal
            ? merge(defaultOptions, newVal, {
                  clone: true,
                  arrayMerge: (_, sourceArray) => sourceArray
              })
            : defaultOptions;

        return !!oldState
            ? merge(oldState, newOption, {
                  clone: true,
                  arrayMerge: (_, sourceArray) => sourceArray
              })
            : newOption;
    },
    target: setPluginOptions
});

forward({
    from: setPluginOptions,
    to: updateTextFromPluginOptions
});

pluginOptions.on(applyTextOptions, _ => {
    const optionsEditor = pluginOptionsEditor.getState();
    const pluginOptions = Hjson.parse(optionsEditor.pluginOptionsHJSONText || '');
    return pluginOptions;
});

const pluginOptionsEditor = createStore<PluginOptionsEditor>({
    // pluginOptions: {},
    pluginOptionsHJSONText: '',
    showPluginOptions: false,
    isModified: false
});

// const updateStore = createEvent<$PluginOptionsEditor>();

const updateOptionText = createEvent<string>();

const toggleShowPluginOptions = createEvent();
const offShowPluginOptions = createEvent();

pluginOptionsEditor
    // .on(updateStore, (oldState, params) => merge(oldState, params))
    .on(updateTextFromPluginOptions, (oldState, pluginOptions) => ({
        ...oldState,
        // pluginOptions,
        pluginOptionsHJSONText: JSON.stringify(pluginOptions, null, 2),
        isModified: false
    }))
    .on(updateOptionText, (oldState, pluginOptionsHJSONText) => ({
        ...oldState,
        pluginOptionsHJSONText,
        isModified: true
    }))
    .on(applyTextOptions, (oldState, _) => ({
        ...oldState,
        // pluginOptions: Hjson.parse(oldState.pluginOptionsHJSONText || ''),
        isModified: false
    }))
    .on(toggleShowPluginOptions, oldState => ({
        ...oldState,
        showPluginOptions: !oldState.showPluginOptions
    }))
    .on(offShowPluginOptions, oldState => ({
        ...oldState,
        showPluginOptions: false
    }));

const localesParams = combine(pluginOptions).map<
    | {
          //   DatECode?: string;
          locale?: {
              country: string; // 'ru'
              datCountryIndicator: string; // 'ru'
              language: string; // 'RU'
          };
          language?: string;
          languageCode?: string; // for graphic request  'RUS'
      }
    | undefined
>(([pluginOptions], oldState) => {
    const locale = pluginOptions?.locale;
    const arrayString = locale?.split('-');

    const newState = {
        locale: {
            // language: getLanguage(language).locale.language || 'en',
            // country: getLanguage(language).locale.country || 'US',

            language: arrayString?.[0] || 'en',
            country: arrayString?.[1] || 'US',

            datCountryIndicator: pluginOptions?.settings?.locale?.attr.datCountryIndicator || 'DE'
        },

        language: arrayString?.[0] || 'en'
    };
    return !R.equals(oldState, newState) ? newState : undefined;
});

export const pluginOptionsModel = {
    stores: {
        pluginOptionsEditor,
        pluginOptions,
        localesParams
    },
    events: {
        // updateStore,
        updatePluginOptions,
        updateTextFromPluginOptions,
        updateOptionText,
        applyTextOptions,
        toggleShowPluginOptions,
        offShowPluginOptions
    }
};
