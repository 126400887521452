import styled from 'styled-components/macro';
import { sizes } from '@wedat/ui-kit/mediaQueries';

interface PanelProps {
    selected: boolean;
}

interface SelectorProps {
    selected: boolean;
}

export const Container = styled.div`
    margin: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Panel = styled.div<PanelProps>`
    @media (max-width: ${sizes.tablet}px) {
        display: ${(p: PanelProps) => (p.selected ? 'block' : 'none')};
    }
`;

export const Selectors = styled.div``;
export const Selector = styled.div<SelectorProps>`
    background-color: ${({ selected, theme }) => (selected ? theme.colors.blue['700'] : theme.colors.deepBlue['50'])};
    border-radius: 8px;
    width: 50px;
    height: 7px;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
`;
