import { createEvent, restore } from 'effector';
import { createGate } from 'effector-react';
import { History } from 'history';
import { HistoryEvents } from './types';

export const HistoryGate = createGate<History>();
const history = restore(HistoryGate.state.updates, null);

export const historyEvents: HistoryEvents = {
    push: createEvent(),
    replace: createEvent(),
    go: createEvent(),
    goBack: createEvent(),
    goForward: createEvent(),
    block: createEvent(),
    listen: createEvent(),
    createHref: createEvent()
};

export const historyStores = {
    history
};
