import { useState } from 'react';
import { useStore } from 'effector-react';
import { Button, Input, Row, Col, Space, Radio } from 'antd';
import { ArrowRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';

import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { DataEquipment, equipmentModel } from '../../stores/equipmentModel';
// import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';

import { useTranslation } from 'react-i18next';
import { EquipmentTable } from './EquipmentTable';
import { commonModel } from '../../stores/commonModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';

export const EquipmentList = () => {
    const [filter, setFilter] = useState<'parts' | 'changed' | 'all'>('parts');
    const [debugging, _setDebugging] = useState<boolean>(false);

    const { currentDVNs } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const dataEquipment = useStore(equipmentModel.stores.dataEquipment);
    const isModified = useStore(equipmentModel.stores.isModified);

    const { t } = useTranslation();

    // const options = useStore(pluginOptionsModel.stores.pluginOptions);
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);

    const [searchText, setSearchText] = useState('');

    let dataSource: DataEquipment[] = dataEquipment;

    if (filter === 'parts') {
        let filterByDvns: number[] = currentDVNs || [];

        filterByDvns = repairPositions.map(repPos => repPos.DATProcessId);

        if (filterByDvns.length)
            dataSource = dataSource.filter(equipment => equipment.dvns?.find(dvn => filterByDvns.includes(dvn)));
        else dataSource = [];
    }

    const trimWords = searchText
        .trim()
        .split(' ')
        .map((word: string) => word.trim());

    if (filter === 'changed')
        dataSource = dataSource.filter(item => (item.series || item.vin || item.existing) !== item.selected);

    const filteredDataSource = dataSource.filter(item => {
        let stringItem = '' + item.DatEquipmentId + ' ' + item.Description?.toLowerCase();
        if (item.ContainedEquipmentPositions)
            item.ContainedEquipmentPositions.EquipmentPosition.forEach(ep => {
                stringItem = stringItem + ' ' + ep.DatEquipmentId + ' ' + ep.Description?.toLowerCase();
            });
        for (const word of trimWords) {
            if (!stringItem.includes(word.toLowerCase())) return false;
        }
        return true;
    });

    return (
        <div
            style={{
                // margin: '8px',
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px'
            }}
        >
            <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        {/* <div style={{ float: 'left' }}> */}
                        <Space>
                            <Button
                                size="large"
                                icon={<ArrowRightOutlined />}
                                onClick={() => {
                                    rightSideDrawerModel.events.clearHistoryAndClose();
                                }}
                            />
                            <h2 style={{ marginLeft: '8px', marginBottom: '0px' }}>{t('EquipmentList.Equipment')}</h2>
                        </Space>
                        {/* </div> */}
                    </Col>

                    <Col span={24}>
                        <Input
                            size="large"
                            prefix={
                                !!searchText ? <CloseOutlined onClick={() => setSearchText('')} /> : <SearchOutlined />
                            }
                            placeholder={t('EquipmentList.SearchPlaceHolder')}
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            style={{ height: '48px', borderRadius: '8px', maxWidth: '100%' }}
                        />
                    </Col>

                    <Col span={24}>
                        <Radio.Group
                            className="weDatRadioButton"
                            // style={{ borderRadius: '8px' }}
                            size="large"
                            onChange={e => setFilter(e.target.value)}
                            value={filter}
                            buttonStyle="solid"
                        >
                            <Radio.Button value="parts">{t('EquipmentList.BySelectedPart')}</Radio.Button>
                            <Radio.Button value="changed">{t('EquipmentList.Changed')}</Radio.Button>
                            <Radio.Button value="all">{t('EquipmentList.All')}</Radio.Button>
                        </Radio.Group>
                    </Col>

                    {/* 
                    {options?.settings?.debuggingMode && (
                        <Col span={24}>
                            <Button
                                type={debugging ? 'primary' : 'text'}
                                onClick={_e => setDebugging(!debugging)}
                                icon={<SettingOutlined />}
                            >
                                
                            </Button>
                        </Col>
                    )} */}
                </Row>
            </div>

            <div
                style={{
                    position: 'absolute',
                    top: '190px',
                    left: '0px',
                    right: '0px',
                    bottom: '58px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Col span={24}>
                    <EquipmentTable debugging={debugging} filteredDataSource={filteredDataSource} />
                </Col>

                <Col span={0}></Col>
            </div>

            <div
                style={{
                    position: 'absolute',
                    // top: '170px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row gutter={[8, 16]}>
                    <Col span={12}>
                        <Button
                            disabled={!isModified}
                            size="large"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.cancel();
                            }}
                        >
                            {t('ManualRepairPositionForm.Cancel')}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            disabled={!isModified}
                            size="large"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => {
                                commonModel.events.confirmWithEquipmentCheck();
                            }}
                        >
                            {t('ManualRepairPositionForm.Confirm')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
