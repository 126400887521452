import { forward, merge, sample } from 'effector';

import { dossierStores, dossierEffects } from './index';
import { evaluationEffects } from '../evaluation';
import { getParsedArraySafe } from '@dat/api2/utils';

const { getDossierFx } = dossierEffects;
const { dossier, datECodeEquipment, specialEquipmentPositions } = dossierStores;
const { getVehicleEvaluationFx, getVehicleEvaluationWithManualFieldsFx } = evaluationEffects;

const dossierReceived = merge([
    getDossierFx.doneData,
    getVehicleEvaluationWithManualFieldsFx.doneData,
    getVehicleEvaluationFx.doneData
]);

forward({
    from: dossierReceived,
    to: dossier
});

// `datECodeEquipment` should be saved once at the beginning
// because in `createDossier` responses it may be empty
sample({
    source: getVehicleEvaluationFx.doneData,
    fn: dossier => getParsedArraySafe(dossier.Vehicle?.DATECodeEquipment?.EquipmentPosition),
    target: datECodeEquipment
});

sample({
    source: getVehicleEvaluationFx.doneData,
    fn: dossier => getParsedArraySafe(dossier.Vehicle?.Equipment?.SpecialEquipment?.EquipmentPosition),
    target: specialEquipmentPositions
});
