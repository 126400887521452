import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { SelectComponentsConfig, StylesConfig, Theme } from 'react-select';
import { useTheme } from 'styled-components';

import { Option } from './Option';
import { ValueContainer } from './ValueContainer';

import { Label } from '../Label';
import { FieldsetStyled } from '../Fieldset';
import { Legend, LegendText } from '../Legend';
import { sizes } from '../../mediaQueries';
import { useMedia } from '../../../core/hooks/useMedia';

import { MultiSelectProps, SelectProps, SingleSelectProps, ValueLabelOption } from './types';

import { getValueForReactSelect } from './utils';

import * as styledComponents from './styles';
import { SelectRoot } from './styles';
import { ExtendedOptionProps } from './Option/types';

const customComponents = ({
    defaultWhiteSpaceOption
}: ExtendedOptionProps): SelectComponentsConfig<ValueLabelOption, boolean> => ({
    ...styledComponents,
    Option: props => <Option {...props} defaultWhiteSpaceOption={defaultWhiteSpaceOption} />,
    ValueContainer
});

type Props = SelectProps & {
    valueKey?: 'value' | 'key';
    // Need for typecast
    valueType?: 'string' | 'number';
    borderRadius?: number;
    defaultWhiteSpaceOption?: boolean;
};

export const Select: React.FC<Props> = ({
    options,
    value,
    onChange,
    label,
    disabled,
    backgroundColor,
    isMulti,
    menuIsOpen,
    formatOptionLabel,
    formatValueLabel,
    onFocus,
    onBlur,
    placeholder = '',
    withPortal,
    valueKey = 'value',
    valueType,
    isSearchable = true,
    borderRadius,
    defaultWhiteSpaceOption,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(!!menuIsOpen);
    const [focused, setFocused] = useState(false);
    const isMobile = useMedia(sizes.laptop);

    const ref = useRef<ReactSelect<ValueLabelOption>>(null);
    const styledComponentsTheme = useTheme();

    const valueLabelOptions = options as ValueLabelOption[];

    const valueForReactSelect = useMemo(
        () => getValueForReactSelect({ value, options: valueLabelOptions, isMulti, valueKey, valueType }),
        [value, valueLabelOptions, isMulti, valueKey, valueType]
    );

    useEffect(() => {
        setIsOpen(!!menuIsOpen);
    }, [menuIsOpen]);

    useEffect(() => {
        if (isOpen) ref.current?.focus();
        else ref.current?.blur();
    }, [isOpen]);

    const handleChangeSingleSelect = (option: ValueLabelOption | null) => {
        onChange = onChange as SingleSelectProps['onChange']; // just for type cast
        onChange(option);
    };

    const handleChangeMultiSelect = (options: ValueLabelOption[]) => {
        onChange = onChange as MultiSelectProps['onChange']; // just for type cast
        onChange(options);
    };

    const handleChange = isMulti ? handleChangeMultiSelect : handleChangeSingleSelect;

    const handleOpenMenu = () => setIsOpen(true);

    const handleCloseMenu = () => setIsOpen(false);

    /* Format option-label / value-label */
    const handleFormatOptionLabel: SelectProps['formatOptionLabel'] = (option, meta) => {
        const isValueLabel = meta.context === 'value';

        option = { ...option };

        if (formatOptionLabel) option.label = formatOptionLabel(option, meta);

        if (isValueLabel && formatValueLabel) option.label = formatValueLabel(option, meta);

        return option.label;
    };

    const handleFocus: SelectProps['onFocus'] = e => {
        setFocused(true);

        onFocus?.(e);
    };

    const handleBlur: SelectProps['onBlur'] = e => {
        setFocused(false);

        onBlur?.(e);
    };

    const customStyles: StylesConfig<ValueLabelOption, boolean> = {
        container: styles => ({
            ...styles,
            minWidth: '60px',
            backgroundColor: backgroundColor || styledComponentsTheme.colors.white,
            borderRadius: '8px'
        }),
        control: styles => {
            if (borderRadius) {
                return { ...styles, borderRadius };
            }
            return { ...styles };
        },
        menuPortal: styles => ({ ...styles, zIndex: 10001 }) // z-index setting more then in modals
    };

    return (
        <SelectRoot>
            <ReactSelect<ValueLabelOption, boolean>
                //@ts-ignore - strange compilation error on `ref`
                ref={ref}
                components={customComponents({
                    defaultWhiteSpaceOption
                })}
                isDisabled={disabled}
                value={valueForReactSelect}
                // using `as any` because @ts-ignore doesn't work here when compiling
                onChange={handleChange as any}
                options={valueLabelOptions}
                isOptionDisabled={option => !!option.disabled}
                isMulti={isMulti}
                menuIsOpen={isOpen}
                onMenuOpen={handleOpenMenu}
                onMenuClose={handleCloseMenu}
                onFocus={handleFocus}
                onBlur={handleBlur}
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={isSearchable}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                menuPlacement="auto"
                menuPosition={isMobile ? 'absolute' : 'fixed'}
                styles={customStyles}
                formatOptionLabel={handleFormatOptionLabel}
                placeholder={placeholder}
                menuPortalTarget={withPortal || !isMobile ? document.body : null}
                theme={reactSelectTheme =>
                    ({
                        ...reactSelectTheme,
                        ...styledComponentsTheme
                    } as unknown as Theme)
                }
                {...rest}
                autoFocus={false}
            />
            <Label focused={focused} filled={!!valueForReactSelect} withIcon>
                {label}
            </Label>

            <FieldsetStyled borderRadius={borderRadius}>
                <Legend focused={focused} filled={!!valueForReactSelect} disabled={disabled}>
                    <LegendText fontSize="12px" textOverflow="ellipsis" color="inherit">
                        {label}
                    </LegendText>
                </Legend>
            </FieldsetStyled>
        </SelectRoot>
    );
};

export * from './types';
