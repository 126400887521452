import { createGate } from 'effector-react';
import { PluginProviderProps } from '../../index';

export const Gate = createGate<PluginProviderProps<any>>();

const pluginInitialized = Gate.open;
const pluginUnmounted = Gate.close;

const props = Gate.state;

export const componentEvents = {
    pluginInitialized,
    pluginUnmounted
};
export const componentStores = {
    props
};
