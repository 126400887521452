import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { positionsEffects } from '../../../stores/positions';
import { modalsEvents } from '../../../stores/ui/modals';
import { WorkbenchFormValues } from '../../../types/workbenchForm';
import { MAP_TIME_VALUES, MAP_PRICE_VALUES, getTimeBlockRadioValues, getTypeBlockRadioValues } from '../constants';
import { contractStores } from '@dat/shared-models/contract';
import { getWorkBenchPayload } from '../../../utils/getWorkBenchPayload';

import { RadioGroupItem } from '@wedat/ui-kit/components/RadioGroup';
import { RadioGroupField } from '@wedat/ui-kit/Formik';
import { Text } from '@wedat/ui-kit/components/Text';
import {
    BlocksWrapper,
    TimeBlock,
    TypeBlock,
    InputFieldWrapper,
    InputFieldStyled,
    SemiHeadingWrapper,
    ButtonsWrapper,
    ButtonStyled,
    CancelButton,
    InputText,
    AdditionalWrapper
} from '../styles';
import { useTheme } from 'styled-components';

export const Content = () => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<WorkbenchFormValues>();
    const contractId = useStore(contractStores.contractId);
    const theme = useTheme();

    useEffect(() => {
        const mappedTime = MAP_TIME_VALUES[values.time];
        setFieldValue(mappedTime.key, mappedTime.value);
    }, [setFieldValue, values.time]);

    useEffect(() => {
        const mappedPrice = MAP_PRICE_VALUES[values.type];
        setFieldValue(mappedPrice.key, mappedPrice.value);
    }, [setFieldValue, values.type]);

    const timeBlockRadioValues: RadioGroupItem<string>[] = getTimeBlockRadioValues(t);

    const typeBlockRadioValues: RadioGroupItem<string>[] = getTypeBlockRadioValues(t);

    const handleAddWorkbench = () => {
        const { positionsItaly, repairPositions } = getWorkBenchPayload(values);
        positionsEffects.saveWorkBenchFx({ workBench: { contractId, positionsItaly }, repairPositions });
    };

    return (
        <>
            <AdditionalWrapper>
                <BlocksWrapper>
                    <TimeBlock>
                        <SemiHeadingWrapper>
                            <Text font="semiHeading" color={theme.colors.gray_300}>
                                {t('workBench.time')}
                            </Text>
                        </SemiHeadingWrapper>
                        <RadioGroupField column name="time" items={timeBlockRadioValues} />
                        <InputFieldWrapper>
                            <InputFieldStyled name="dependentTime" readOnly />
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <InputFieldStyled label={t('workBench.timeForParts')} name="timeForParts" />
                        </InputFieldWrapper>
                        <InputFieldWrapper>
                            <InputFieldStyled label={t('workBench.timeForPreparatory')} name="timeForPreparatory" />
                        </InputFieldWrapper>
                    </TimeBlock>
                    <TypeBlock>
                        <SemiHeadingWrapper>
                            <Text font="semiHeading" color={theme.colors.gray_300}>
                                {t('workBench.type')}
                            </Text>
                        </SemiHeadingWrapper>
                        <RadioGroupField column name="type" items={typeBlockRadioValues} />
                        <InputFieldWrapper>
                            <InputFieldStyled name="price" />
                            <InputText textTransform="capitalize" color={theme.colors.gray_40}>
                                {t('common.eur')}
                            </InputText>
                        </InputFieldWrapper>
                    </TypeBlock>
                </BlocksWrapper>
            </AdditionalWrapper>
            <ButtonsWrapper>
                <ButtonStyled
                    onClick={() => {
                        handleAddWorkbench();
                        modalsEvents.setIsWorkBenchOpen();
                    }}
                >
                    {t('common.ok')}
                </ButtonStyled>
                <CancelButton
                    onClick={() => {
                        modalsEvents.setIsWorkBenchOpen();
                    }}
                >
                    {t('common.cancel')}
                </CancelButton>
            </ButtonsWrapper>
        </>
    );
};
