import { Card, List } from 'antd';
import { useStore } from 'effector-react';
import { FC, useState } from 'react';
import { fastTrackElementsMenuModel } from '../../stores/fastTrackElementsMenuModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { ElementsMenuList } from './ElementsMenuList';
import { ElementsMenuModal } from './ElementsMenuModal';
import { ElementsMenuDrawer } from './ElementsMenuDrawer';
import { ReactComponent as ChevronDownIcon } from '../CommonComponents/Icons/ChevronDownIcon.svg';
import { ReactComponent as ChevronUpIcon } from '../CommonComponents/Icons/ChevronUpIcon.svg';
import { ReactComponent as SelectedElementIcon } from '../CommonComponents/Icons/SelectedElementIcon.svg';
import { scrollElement, checkIfScrollEnd } from '../../utils/scrollElement';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';

import './fastTrackElementsMenu.css';

export const FastTrackElementsMenu: FC<{ elementsMenuType?: 'drawer' | 'modal' }> = ({ elementsMenuType }) => {
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const fastTrackElementsMenuState = useStore(fastTrackElementsMenuModel.stores.$fastTrackElementsMenu);

    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const selectedElementsIds = selectedElements.map(elem => elem.elementId);

    const [showScrollDownBtn, setShowScrollDownBtn] = useState(!checkIfScrollEnd('.weDat-ft-elements-list', 0, 'down'));
    const [showScrollUpBtn, setShowScrollUpBtn] = useState(false);

    const fastTrackElementsMenu = pluginOptions?.settings?.fastTrackElementsMenu;
    const fastTrackElementsMenuModal = pluginOptions?.settings?.fastTrackElementsMenuModal;
    const showSVGFastTrack = pluginOptions?.settings?.showSVGFastTrack;
    if (!fastTrackElementsMenu) return null;

    return (
        <div className={showSVGFastTrack ? 'weDat-ft-elements-menu' : 'weDat-ft-elements-menu-noSVG'}>
            {(!fastTrackElementsMenuState || fastTrackElementsMenuModal) && (
                <>
                    {showScrollUpBtn && showSVGFastTrack ? (
                        <div className="weDat-ft-scroll-up-btn-container">
                            <div
                                className="weDat-ft-scroll-up-btn"
                                onClick={() => {
                                    const newScrollVal = scrollElement('.weDat-ft-elements-list', 300, 'up');
                                    if (newScrollVal === -1000) return;
                                    setShowScrollUpBtn(
                                        !checkIfScrollEnd('.weDat-ft-elements-list', newScrollVal, 'up')
                                    );
                                    setShowScrollDownBtn(true);
                                }}
                            >
                                <ChevronUpIcon />
                            </div>
                        </div>
                    ) : null}

                    <List className="weDat-ft-elements-list" itemLayout="vertical">
                        {fastTrackElementsMenu.map(menuGroup => (
                            <List.Item
                                key={menuGroup.menuGroupId}
                                onClick={() => {
                                    fastTrackElementsMenuModel.events.setFastTrackElementsMenu(menuGroup);
                                }}
                            >
                                <Card hoverable style={{ width: '100%' }}>
                                    <div className="weDat-ft-elements-list-img">
                                        <svg height="32" width="58">
                                            <image
                                                height="32"
                                                viewBox="0 0 32 58"
                                                xlinkHref={menuGroup.menuGroupImgUrl}
                                            />
                                        </svg>
                                        {menuGroup.elementDatIDs?.find(elemId =>
                                            selectedElementsIds.includes(elemId)
                                        ) && <SelectedElementIcon />}
                                    </div>
                                    <Card.Meta title={menuGroup.menuGroupLabel || menuGroup.menuGroupId} />
                                </Card>
                            </List.Item>
                        ))}
                    </List>
                    {showScrollDownBtn &&
                    showSVGFastTrack &&
                    !checkIfScrollEnd('.weDat-ft-elements-list', 0, 'down') ? (
                        <div className="weDat-ft-scroll-down-btn-container">
                            <div
                                className="weDat-ft-scroll-down-btn"
                                onClick={() => {
                                    const newScrollVal = scrollElement('.weDat-ft-elements-list', 300, 'down');
                                    if (newScrollVal === -1000) return;
                                    setShowScrollDownBtn(
                                        !checkIfScrollEnd('.weDat-ft-elements-list', newScrollVal, 'down')
                                    );
                                    setShowScrollUpBtn(true);
                                }}
                            >
                                <ChevronDownIcon />
                            </div>
                        </div>
                    ) : null}
                </>
            )}

            {!fastTrackElementsMenuModal && fastTrackElementsMenuState && <ElementsMenuList />}
            {fastTrackElementsMenuModal &&
                fastTrackElementsMenuState &&
                (elementsMenuType === 'drawer' ? <ElementsMenuDrawer /> : <ElementsMenuModal />)}
        </div>
    );
};
