import { createEvent, createStore } from 'effector';
import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isModalInfoOpen, toggleIsModalInfoOpen] = createToggle(false);
const [isWorkBenchOpen, setIsWorkBenchOpen] = createToggle(false);
const [isWorkLogicOpen, setIsWorkLogicOpen] = createToggle(false);
const [isUserDefinedPositionsOpen, toggleIsUserDefinedPositionsOpen] = createToggle(false);

const setActiveInfoPosition = createEvent<DAT2.PositionItaly | null>();
const activeInfoPosition = createStore<DAT2.PositionItaly | null>(null).on(
    setActiveInfoPosition,
    (_state, payload) => payload
);

export const modalsEvents = {
    toggleIsModalInfoOpen,
    setIsWorkBenchOpen,
    setIsWorkLogicOpen,
    setActiveInfoPosition,
    toggleIsUserDefinedPositionsOpen
};
export const modalsStores = {
    isModalInfoOpen,
    isWorkBenchOpen,
    isWorkLogicOpen,
    activeInfoPosition,
    isUserDefinedPositionsOpen
};
