import React, { FC } from 'react';
import { useStore } from 'effector-react';

import { dialogStores } from '@dat/chat/src/stores/dialogs/index';
import { Counter } from '@wedat/ui-kit/components/Counter';
import { ChatIcon } from '@wedat/ui-kit/components/Icons';

import { WrapIcon, Button } from './styles';
interface Props {
    onClick: () => void;
}

export const ChatButton: FC<Props> = ({ onClick }) => {
    const unreadMessagesCount = useStore(dialogStores.unreadMessagesCount);

    return (
        <Button type="button" onClick={onClick}>
            <WrapIcon>
                <ChatIcon height="100%" width="100%" />
                {unreadMessagesCount !== 0 && <Counter count={unreadMessagesCount} />}
            </WrapIcon>
        </Button>
    );
};
