import { currentUserDataKey } from '../../subjectsData/constants';
import { CustomersSubjectsData } from '../../subjectsData/types';
import { SubjectsData } from '../../../types/subjects';

type Param = {
    customersSubjectsData: CustomersSubjectsData;
    networkOwnerId?: string;
};
type Result = SubjectsData | undefined;

export const findInstanceSubjectsData = ({ customersSubjectsData, networkOwnerId }: Param): Result =>
    customersSubjectsData[networkOwnerId || currentUserDataKey];
