import styled from 'styled-components';
import { Toggle } from '@wedat/ui-kit/components/Toggle';

export const GalleryStyled = styled.div``;

export const Toggles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
`;

export const ToggleStyled = styled(Toggle)`
    &:not(:first-child) {
        margin-left: 40px;
    }
`;

export const SwiperStyled = styled.div`
    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        height: auto;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-gallery {
        height: 80%;
        width: 100%;
    }

    .swiper-gallery-2 {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .swiper-gallery-2 .swiper-slide {
        width: 25%;
        height: auto;
        opacity: 0.4;
    }

    .swiper-gallery-2 .swiper-slide-thumb-active {
        opacity: 1;
    }
`;
