import { createStore, createEffect, createEvent } from 'effector';
import { UpdatePositionsPayload } from './types';

const setSelectedPositionsEvent = createEvent<string>();

const updatePositions = createEffect<UpdatePositionsPayload, void>();
const deletePositions = createEffect<any, void>();

const initialFormPositions = createStore<DAT2.MaterialPosition[]>([]);
const selectedPositions = createStore<string[]>([])
    .on(setSelectedPositionsEvent, (state, payload) => {
        if (state.some(item => item === payload)) {
            return state.filter(item => item !== payload);
        } else {
            return [...state, payload];
        }
    })
    .on(deletePositions.doneData, _ => []);

export const postEditingStores = { initialFormPositions, selectedPositions };

export const postEditingEvents = { setSelectedPositionsEvent };

export const postEditingEffects = { updatePositions, deletePositions };
