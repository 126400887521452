import { forward, sample } from 'effector';

import { formEffects, formEvents, formStores } from './index';
import { sharedAuthEffects, sharedAuthEvents } from '@dat/shared-models/auth';

const { shouldLoginViaForm, formSubmitted } = formEvents;
const { isError, isShowForm, isSuccess } = formStores;
const { loginWithCredentialsFromFormFx } = formEffects;

loginWithCredentialsFromFormFx.use(credentials => sharedAuthEffects.loginFx({ credentials }));

forward({
    from: sharedAuthEffects.loginFx.fail,
    to: shouldLoginViaForm
});

isShowForm.on(shouldLoginViaForm, () => true);

sample({
    source: formSubmitted,
    fn: credentials => {
        const [customerNumber, user] = credentials.customerNumberAndUser.split('/');

        return {
            customerNumber: +customerNumber,
            password: credentials.password,
            user
        };
    },
    target: loginWithCredentialsFromFormFx
});

isError.on(loginWithCredentialsFromFormFx.fail, () => true);
isSuccess.on(loginWithCredentialsFromFormFx.doneData, () => true);

isShowForm.reset(sharedAuthEvents.loggedIn);
isError.reset(sharedAuthEvents.loggedIn);
