import styled, { css } from 'styled-components/macro';
import { Image } from '../common/Image';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';
import { ExpiryDateFormatContainer } from '../../ExpiryDateFormat/styles';

export interface Props {
    borderRadius?: number;
}

interface CardCarProps {
    disabled: boolean;
}

interface EfficiencyWrapperProps {
    color?: string;
}

interface CalendarDateProps {
    hiddingPoint?: number;
}

export const BaseInfoWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const EfficiencyWrapper = styled.div<EfficiencyWrapperProps>`
    ${(p: EfficiencyWrapperProps) => !!p.color && `background-color:${p.color};`}
    flex: 0 0 5px;
`;

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 114px;
    width: 134px;
    flex-shrink: 0;
    flex-direction: column;
    border-radius: 5px 0 0 ${(p: Props) => p.borderRadius}px;
    cursor: pointer;

    ${media.tablet`
        width: 148px;
        height: 142px;
    `}
`;

export const DesktopImage = styled(Image)`
    ${PreloaderCircle} {
        color: #fff;
        width: 65px;
    }
    margin: 0 15px;
    img {
        max-height: 92px;
        max-width: 134px;
    }

    ${media.tablet`
        margin: 0 10px;
        img {
            max-width: 128px;
        }       
    `}
`;

export const Information = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    ${media.tablet`
        height: 142px;
        align-items: baseline;
    `}
`;

export const ColumnWrapper = styled.div`
    flex: 0 0 180px;
    margin: 0 10px;
    word-break: break-all;

    ${media.tablet`
        flex: 1 1 150px;
        margin: 0 10px;
    `}
`;

export const MainInfoColumnWrapper = styled(ColumnWrapper)`
    flex: 1 1 180px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 140px;
    cursor: pointer;
    height: 100%;
`;

export const OrderNumber = styled(ColumnWrapper)`
    flex: 0 0 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const DamageNumber = styled(ColumnWrapper)``;
export const InsuranceName = styled(ColumnWrapper)``;

export const CalendarDate = styled(ColumnWrapper)<CalendarDateProps>`
    display: flex;
    align-items: center;
    flex: 0 0 135px;

    ${(p: CalendarDateProps) =>
        p.hiddingPoint &&
        `
        @media screen and (max-width: ${p.hiddingPoint}px) {
            display: none;
        }
    `}
`;

export const IconColumnWrapper = styled(ColumnWrapper)`
    flex-basis: auto;
    flex-direction: column;
    display: flex;
    align-self: center;
    margin-bottom: 10px;

    svg {
        cursor: pointer;
    }

    ${media.tablet`
        flex: 0 0 auto;
    `}
`;

export const VehicleModel = styled(Text)`
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    margin-top: 4px;
    margin-bottom: 6px;
`;

export const CarOwner = styled(Text)`
    margin-top: auto;
`;

export const NumberPlate = styled(Text)`
    position: relative;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 4px;
    padding: 4px 8px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    width: fit-content;
    top: -8px;
`;

export const IconWrapper = styled.div`
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    & svg {
        display: block;
    }
`;

export const CardCarStyled = styled.div<Props & CardCarProps>`
    position: relative;
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray['100']};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                background-color: rgba(255, 255, 255, 0.4);
            }
            /* opacity: 0.4; */
            ${VehicleModel},
            ${ImageWrapper} {
                cursor: default;
            }
        `};

    ${ExpiryDateFormatContainer} {
        width: auto;
    }
`;

export const DateFormatWrapper = styled.span`
    margin-left: 8px;
`;

export const StatusName = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.blueDark};
    cursor: pointer;
    width: fit-content;
    margin: auto 0 12px 0;

    ${media.tablet`
        margin: auto 0 15px 0;
    `}
`;
