import styled from 'styled-components/macro';
import { Attachment } from '@wedat/ui-kit/components/Attachment';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const AttachmentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledAttachment = styled(Attachment)`
    margin: 10px 10px;
    width: 100px;
    height: 100px;
`;
