import styled from 'styled-components/macro';

export const Container = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 104px;
`;

export const SuccessImage = styled.img`
    width: 100%;
    max-width: 390px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transform: translateY(-42px);
`;

export const ButtonWrapper = styled.div`
    margin-top: 32px;
    min-width: 230px;
`;
