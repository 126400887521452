import styled from 'styled-components/macro';

export const Button = styled.button`
    border: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textPrimary};
    transition: color 150ms;

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const WrapIcon = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
`;
