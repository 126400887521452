import React, { FC } from 'react';

import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => (
    <PluginProvider name="printout" options={options}>
        <Main
            contractId={options.contractId}
            displayTitle={options.displayTitle}
            menuSide={options.menuSide}
            isComponent={options.isComponent}
            isOpen={options.isOpen}
            callbackClose={options.callbackClose}
            registrationNumber={options.registrationNumber}
            vin={options.vin}
        />
    </PluginProvider>
);

export default App;
