import { createEffect } from 'effector';
import { connectInstanceUnits } from './utils/connectInstanceUnits';
import { effectorLogger } from '@dat/core/utils';
//because circular dependencies
import { events, stores } from './stores';

const connectInstanceUnitsFx = createEffect(connectInstanceUnits);

export const pluginEvents = { ...events };
export const pluginEffects = {
    connectInstanceUnitsFx
};
export const pluginStores = {
    ...stores
};

if (process.env.NODE_ENV === 'development') {
    effectorLogger(pluginEvents);
    effectorLogger(pluginEffects);
    effectorLogger(pluginStores);
}
