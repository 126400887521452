import React, { FC, useEffect, useMemo, useRef } from 'react';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { pluginEffects } from '../../stores/plugin';
import { notificationsEffects, notificationsStores } from '../../stores/notifications';
import { useToggle } from '@dat/core/hooks/useToggle';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { sharedProfilesStores } from '@dat/shared-models/profiles';

import {
    Wrapper,
    Title,
    NotificationCard,
    Content,
    CreationDate,
    NotificationMessage,
    StyledProfileIcon,
    CompanionName,
    PartnerName,
    ContractLink,
    ProfileInfoWrapper,
    ProfileWrapper,
    StyledCloseIcon,
    Image,
    NotificationsWrapper
} from './styles';
import { NotificationsButton } from '../NotificationsButton';

interface Props {
    className?: string;
}

export const Notifications: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    const [isOpenNotificationsPopup, toggleIsOpenNotificationsPopup] = useToggle(false);
    const notifications = useStore(notificationsStores.notifications);
    const sameCustomerProfiles = useStore(sharedProfilesStores.sameCustomerProfiles);
    const notificationsPopupRef = useRef<HTMLDivElement>(null);

    const handleDeleteNotifications = (notificationId: string) => {
        notificationsEffects.removeNotifications({ ids: [notificationId] });
    };

    const handleContractClick = (notificationId: string, contractId?: number) => {
        if (contractId) {
            pluginEffects.callOnContractClickFx({ contractId });
            handleDeleteNotifications(notificationId);
        }
    };

    const notificationsCount = useMemo(() => notifications.length, [notifications]);

    useClickOutside(notificationsPopupRef, () => {
        if (isOpenNotificationsPopup) {
            toggleIsOpenNotificationsPopup();
        }
    });

    useEffect(() => {
        notificationsEffects.getNotifications();
    }, []);

    return (
        <NotificationsWrapper ref={notificationsPopupRef} className={className}>
            <NotificationsButton onClick={toggleIsOpenNotificationsPopup} countOfMessages={notificationsCount} />
            {isOpenNotificationsPopup && notificationsCount > 0 && (
                <Wrapper isSingleNotification={notificationsCount === 1}>
                    <Title>{t('notification.title')}</Title>
                    <Content>
                        {notifications.map(({ created, id, data }) => {
                            const userName = data?.sender.split('-').pop() || '';

                            return (
                                <NotificationCard key={id}>
                                    <CreationDate>{format(created, 'dd.MM.yyyy')}</CreationDate>
                                    <ProfileWrapper>
                                        {sameCustomerProfiles[userName].image ? (
                                            <Image src={sameCustomerProfiles[userName].image} alt="avatar" />
                                        ) : (
                                            <StyledProfileIcon />
                                        )}
                                        <ProfileInfoWrapper>
                                            <CompanionName>
                                                {sameCustomerProfiles[userName].name}{' '}
                                                {sameCustomerProfiles[userName].surname}
                                            </CompanionName>
                                            <PartnerName>{t('notification.partnerName')}</PartnerName>
                                        </ProfileInfoWrapper>
                                    </ProfileWrapper>
                                    <NotificationMessage>
                                        {t('notification.privateMessage')}
                                        <ContractLink
                                            role="button"
                                            onClick={() => handleContractClick(id, data?.contractId)}
                                        >
                                            {t('notification.linkName')}
                                        </ContractLink>
                                    </NotificationMessage>
                                    <StyledCloseIcon
                                        onClick={() => {
                                            handleDeleteNotifications(id);
                                        }}
                                    />
                                </NotificationCard>
                            );
                        })}
                    </Content>
                </Wrapper>
            )}
            {isOpenNotificationsPopup && notificationsCount === 0 && (
                <Wrapper isSingleNotification>
                    <Title>{t('notification.title')}</Title>
                    <Content>{t('notification.emptyText')}</Content>
                </Wrapper>
            )}
        </NotificationsWrapper>
    );
};
