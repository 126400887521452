import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

export const TooltipContentItem = styled.div`
    :not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

export const TooltipText = styled(Text)`
    color: ${({ theme }) => theme.colors.white};
    display: block;
`;

export const TooltipContentWrapper = styled.div`
    display: none;
`;

export const TooltipContentContainer = styled.div``;
