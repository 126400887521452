import styled from 'styled-components';

interface HideableProps {
    hidden: boolean;
}

export const Hideable = styled.div<HideableProps>`
    height: 100%;
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;
