import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { usePriceDependentField } from '../../../hooks/usePriceDependentField';
import { evaluationStores } from '../../../stores/evaluation';

import { NumberInputField, NumberInputFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationNumberInputField: React.FC<NumberInputFieldProps> = ({ name, ...rest }) => {
    const { setFieldValue, submitForm } = useFormikContext();
    const { activeName, inactiveName, activeValue, inactiveValue } = usePriceDependentField({
        baseName: name,
        fieldType: 'input'
    });
    const manualFieldsOfLastEvaluation = useStore(evaluationStores.manualFieldsOfLastEvaluation);
    const lastEvaluatedValue = getIn(manualFieldsOfLastEvaluation, activeName);

    useEffect(() => {
        // While active value is entered by user through input-field,
        // inactive value is automatically calculated from active and should be set manually.
        setFieldValue(inactiveName, inactiveValue);
    }, [inactiveValue, inactiveName, setFieldValue]);

    const isDirty = activeValue !== lastEvaluatedValue;

    const handleBlur = () => {
        if (isDirty) {
            submitForm();
        }
    };

    return <NumberInputField {...rest} name={activeName} onBlur={handleBlur} />;
};
