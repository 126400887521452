import { combine, createEvent, createStore, restore, sample } from 'effector';
import {
    Category,
    EquipmentPositionElement,
    PossibleEquipment,
    ResultGetDVNEquipments
} from '../../../api2dat/types/api2datTypes';
import { EquipmentPosition, EquipmentPositions } from '../../../api2dat/types/contractTypes';
import { commonModel } from './commonModel';
import { pluginOptionsModel } from './pluginOptionsModel';

export interface DataEquipment {
    key: string;
    DatEquipmentId?: number;
    selected?: boolean;
    deselected?: boolean;
    possible?: boolean;
    existing?: boolean;
    special?: boolean;
    vin?: boolean;
    series?: boolean;
    // dvn?: boolean;
    dvns?: number[];
    Description: string;

    Category?: Category;
    EquipmentGroup?: string;
    EquipmentClass?: number;
    ContainedEquipmentPositions?: {
        EquipmentPosition: EquipmentPositionElement[];
    };
    AddedByLogikCheck?: boolean;
    ManufacturerCode?: string;
}

const vinEquipments = pluginOptionsModel.stores.pluginOptions.map(
    opt => opt?.settings?.contract?.Dossier?.Vehicle?.VINResult?.VINEquipments
);
const seriesEquipment = pluginOptionsModel.stores.pluginOptions.map(
    opt => opt?.settings?.contract?.Dossier?.Vehicle?.Equipment?.SeriesEquipment
);

const setPossibleEquipment = createEvent<PossibleEquipment[]>();
const possibleEquipment = restore(setPossibleEquipment, []);
const addPossibleEquipment = createEvent<EquipmentPosition>();
possibleEquipment.on(addPossibleEquipment, (oldState, equipment) => {
    if (!equipment.DatEquipmentId) return;
    const foundItem = oldState.find(equip => equip.DatEquipmentId === equipment.DatEquipmentId);
    if (!foundItem) {
        const newPossibleEquipment: PossibleEquipment = {
            DatEquipmentId: equipment.DatEquipmentId,
            Description: equipment.Description
        };
        return [...oldState, newPossibleEquipment];
    }
});

const setExistingEquipment = createEvent<PossibleEquipment[]>();
const existingEquipment = restore(setExistingEquipment, []);

const deselectedSeriesEquipment = createStore<EquipmentPositions | null>(null);
const setDeselectedSeriesEquipment = createEvent<EquipmentPositions | null>();
deselectedSeriesEquipment.on(setDeselectedSeriesEquipment, (_, newVal) => newVal);
const initDeselectedSeriesEquipment = createEvent<EquipmentPositions | null>();
deselectedSeriesEquipment.on(initDeselectedSeriesEquipment, (_, newVal) => newVal);

const confirmedDeselectedSeriesEquipment = createStore<EquipmentPositions | null>(null);
const setConfirmedDeselectedSeriesEquipment = createEvent<EquipmentPositions | null>();
confirmedDeselectedSeriesEquipment.on(setConfirmedDeselectedSeriesEquipment, (_, newVal) => newVal);
confirmedDeselectedSeriesEquipment.on(initDeselectedSeriesEquipment, (_, newVal) => newVal);

sample({
    source: deselectedSeriesEquipment,
    clock: commonModel.events.confirm,
    // fn: repPos => repPos,
    target: setConfirmedDeselectedSeriesEquipment
});

sample({
    source: confirmedDeselectedSeriesEquipment,
    clock: commonModel.events.cancel,
    // fn: repPos => repPos,
    target: setDeselectedSeriesEquipment
});

pluginOptionsModel.stores.pluginOptions
    .map(opt => opt?.settings?.contract?.Dossier?.Vehicle?.Equipment?.DeselectedSeriesEquipment)
    .watch(initDeselectedSeriesEquipment);

const addDeselectedSeriesEquipment = createEvent<EquipmentPosition>();
sample({
    source: deselectedSeriesEquipment,
    clock: addDeselectedSeriesEquipment,
    fn: (oldState, equipment) => {
        const foundItem = oldState?.EquipmentPosition.find(equip => equip.DatEquipmentId === equipment.DatEquipmentId);
        if (!foundItem) {
            const EquipmentPosition = oldState?.EquipmentPosition || [];
            return { EquipmentPosition: [...EquipmentPosition, equipment] };
        }
        return oldState;
    },
    target: setDeselectedSeriesEquipment
});

const delDeselectedSeriesEquipment = createEvent<number>();
sample({
    source: deselectedSeriesEquipment,
    clock: delDeselectedSeriesEquipment,
    fn: (oldState, DatEquipmentId) => ({
        EquipmentPosition: oldState?.EquipmentPosition.filter(itemE => itemE.DatEquipmentId !== DatEquipmentId) || []
    }),
    target: setDeselectedSeriesEquipment
});

const specialEquipment = createStore<EquipmentPositions | null>(null);
const setSpecialEquipment = createEvent<EquipmentPositions | null>();
specialEquipment.on(setSpecialEquipment, (_, newVal) => newVal);
const initSpecialEquipment = createEvent<EquipmentPositions | null>();
specialEquipment.on(initSpecialEquipment, (_, newVal) => newVal);

const confirmedSpecialEquipment = createStore<EquipmentPositions | null>(null);
const setConfirmedSpecialEquipment = createEvent<EquipmentPositions | null>();
confirmedSpecialEquipment.on(setConfirmedSpecialEquipment, (_, newVal) => newVal);
confirmedSpecialEquipment.on(initSpecialEquipment, (_, newVal) => newVal);

sample({
    source: specialEquipment,
    clock: commonModel.events.confirm,
    // fn: repPos => repPos,
    target: setConfirmedSpecialEquipment
});

sample({
    source: confirmedSpecialEquipment,
    clock: commonModel.events.cancel,
    // fn: repPos => repPos,
    target: setSpecialEquipment
});

pluginOptionsModel.stores.pluginOptions
    .map(opt => opt?.settings?.contract?.Dossier?.Vehicle?.Equipment?.SpecialEquipment)
    .watch(initSpecialEquipment);

const addSpecialEquipment = createEvent<EquipmentPosition>();

sample({
    source: specialEquipment,
    clock: addSpecialEquipment,
    fn: (oldState, equipment) => {
        const foundItem = oldState?.EquipmentPosition.find(equip => equip.DatEquipmentId === equipment.DatEquipmentId);
        if (!foundItem) {
            const EquipmentPosition = oldState?.EquipmentPosition || [];
            return { EquipmentPosition: [...EquipmentPosition, equipment] };
        }
        return oldState;
    },
    target: setSpecialEquipment
});

const delSpecialEquipment = createEvent<number>();

sample({
    source: specialEquipment,
    clock: delSpecialEquipment,
    fn: (oldState, DatEquipmentId) => ({
        EquipmentPosition: oldState?.EquipmentPosition.filter(itemE => itemE.DatEquipmentId !== DatEquipmentId) || []
    }),
    target: setSpecialEquipment
});

const setDvnEquipment = createEvent<ResultGetDVNEquipments[]>();
const dvnEquipment = restore(setDvnEquipment, []); // createStore<ResultGetDVNEquipments[]>([]);
// reset when change datECode or ConstructionTime
pluginOptionsModel.stores.pluginOptions
    .map(pluginOptions => pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode)
    .watch(() => setDvnEquipment([]));
pluginOptionsModel.stores.pluginOptions
    .map(pluginOptions => pluginOptions?.settings?.contract?.Dossier?.Vehicle?.ConstructionTime)
    .watch(() => setDvnEquipment([]));

const groupedDvnEquipment = dvnEquipment.map(dvnEq => {
    const result = dvnEq.reduce<
        {
            DatEquipmentId: number; // number
            datProcessIds: number[];
            Description: string; // name
        }[]
    >((accumulator, currentValue) => {
        currentValue.equipments?.forEach(equipment => {
            const foundIndex = accumulator.findIndex(
                accItem =>
                    // accItem.datProcessIds.find(datProcessId => datProcessId === equipment.number)
                    accItem.DatEquipmentId === equipment.number
            );
            if (foundIndex === -1)
                accumulator.push({
                    DatEquipmentId: equipment.number,
                    Description: equipment.name,
                    datProcessIds: [currentValue.datProcessId]
                });
            else {
                const oldVal = accumulator[foundIndex];
                const newVal = {
                    ...oldVal,
                    datProcessIds: [...oldVal.datProcessIds, currentValue.datProcessId]
                };
                accumulator[foundIndex] = newVal;
            }
        });

        return accumulator;
    }, []);

    return result;
});

const dataEquipment = combine({
    vinEquipments,
    seriesEquipment,
    groupedDvnEquipment,
    possibleEquipment,
    existingEquipment,
    deselectedSeriesEquipment,
    specialEquipment
}).map(
    ({
        vinEquipments,
        seriesEquipment,
        groupedDvnEquipment,
        possibleEquipment,
        existingEquipment,
        deselectedSeriesEquipment,
        specialEquipment
    }) => {
        let dataEquipment: DataEquipment[] = [];

        // todo use join like this:
        // function join (indexName, defaults, ...arrays) {
        //     const map = new Map();
        //     arrays.forEach((array) => {
        //         array.forEach((item) => {
        //             map.set(
        //                 item[indexName],
        //                 Object.assign(
        //                     item,
        //                     map.get(item[indexName])
        //                 )
        //             );
        //         })
        //     })
        //     return [...map.values()].map(item => Object.assign({}, defaults, item));
        // }

        possibleEquipment.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) foundVal.possible = true;
            else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: false,
                    vin: false,
                    series: false,
                    dvns: [],
                    possible: true,
                    existing: false
                });
        });

        existingEquipment.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) {
                foundVal.existing = true;
                foundVal.selected = true;
            } else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: true,
                    vin: false,
                    series: false,
                    dvns: [],
                    possible: false,
                    existing: true
                });
        });

        vinEquipments?.VINEquipment?.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.AvNumberDat === item.DatEquipmentId);
            if (foundVal) {
                foundVal.vin = true;
                foundVal.selected = true;
            } else if (!!equipment.AvNumberDat) {
                const newRecord = {
                    ...equipment,
                    key: '',
                    DatEquipmentId: equipment.AvNumberDat,
                    selected: true,
                    vin: true,
                    series: false,
                    dvns: [],
                    possible: false,
                    existing: false,
                    Description: equipment.ShortName,
                    ManufacturerCode: equipment.ManufacturerCode
                };
                newRecord.key = getKeyFromRecord(newRecord);
                dataEquipment.push(newRecord);
            }
        });

        seriesEquipment?.EquipmentPosition.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) {
                foundVal.series = true;
                foundVal.selected = true;
            } else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: true,
                    vin: false,
                    series: true,
                    dvns: [],
                    possible: false,
                    existing: false
                });
        });

        groupedDvnEquipment.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) {
                foundVal.dvns = equipment.datProcessIds;
            } else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: false,
                    vin: false,
                    series: false,
                    possible: false,
                    existing: false,
                    dvns: equipment.datProcessIds
                });
        });

        deselectedSeriesEquipment?.EquipmentPosition?.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) {
                foundVal.selected = false;
                foundVal.deselected = true;
            } else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: false,
                    deselected: true,
                    vin: false,
                    series: false,
                    dvns: [],
                    possible: false,
                    existing: false
                });
        });
        specialEquipment?.EquipmentPosition?.forEach(equipment => {
            let foundVal = dataEquipment.find(item => equipment.DatEquipmentId === item.DatEquipmentId);
            if (foundVal) {
                foundVal.selected = true;
                foundVal.special = true;
            } else
                dataEquipment.push({
                    ...equipment,
                    key: getKeyFromRecord(equipment),
                    selected: true,
                    special: true,
                    vin: false,
                    series: false,
                    dvns: [],
                    possible: false,
                    existing: false
                });
        });

        dataEquipment = dataEquipment.sort((a, b) =>
            a.DatEquipmentId && b.DatEquipmentId && a.DatEquipmentId < b.DatEquipmentId ? 1 : -1
        );

        return dataEquipment;
    }
);

function getKeyFromRecord(record: Partial<DataEquipment>) {
    return '' + record.DatEquipmentId + ' ' + record.Description;
}

const selectEquipment = createEvent<DataEquipment>();
selectEquipment.watch(dataEquipment => {
    if (dataEquipment.vin || dataEquipment.series || dataEquipment.existing) {
        dataEquipment.DatEquipmentId && delDeselectedSeriesEquipment(dataEquipment.DatEquipmentId);
    } else {
        dataEquipment.DatEquipmentId && delDeselectedSeriesEquipment(dataEquipment.DatEquipmentId);
        addSpecialEquipment({
            DatEquipmentId: dataEquipment.DatEquipmentId,
            Description: dataEquipment.Description
        });
    }
});

const deselectEquipment = createEvent<DataEquipment>();
deselectEquipment.watch(dataEquipment => {
    if (dataEquipment.vin || dataEquipment.series || dataEquipment.existing) {
        addDeselectedSeriesEquipment({
            DatEquipmentId: dataEquipment.DatEquipmentId,
            Description: dataEquipment.Description
        });
        dataEquipment.DatEquipmentId && delSpecialEquipment(dataEquipment.DatEquipmentId);
    } else {
        dataEquipment.DatEquipmentId && delSpecialEquipment(dataEquipment.DatEquipmentId);
        addPossibleEquipment({
            DatEquipmentId: dataEquipment.DatEquipmentId,
            Description: dataEquipment.Description
        });
    }
});

const changeEquipment = createEvent<DataEquipment>();

const isModified = combine(
    confirmedSpecialEquipment,
    specialEquipment,
    confirmedDeselectedSeriesEquipment,
    deselectedSeriesEquipment
).map(([cd, d, cs, s]) => cd !== d || cs !== s);

sample({
    source: dataEquipment,
    clock: changeEquipment,
    fn: (dataEquipment, itemEquipment) => {
        if (itemEquipment.selected) {
            deselectEquipment(itemEquipment);
        } else {
            if (itemEquipment.EquipmentGroup) {
                dataEquipment
                    .filter(
                        itEq =>
                            itEq.selected &&
                            !!itEq.EquipmentGroup &&
                            itEq.EquipmentGroup === itemEquipment.EquipmentGroup
                    )
                    .forEach(deselectEquipment);
            }
            selectEquipment(itemEquipment);
        }
    }
});

export const equipmentModel = {
    stores: {
        vinEquipments, // equipment from Vin
        seriesEquipment, // equipment from dossier base on DatECode
        existingEquipment, // equipment from ConversionFunctionsService base on DatECode
        dvnEquipment, // equipment from request getDVNEquipments
        groupedDvnEquipment, // equipment from request getDVNEquipments grouped by equipment
        possibleEquipment, // possible equipment non serial(DatECode) non vin
        deselectedSeriesEquipment, // deselected vin and serial(DatECode)
        specialEquipment, // added non vin and non serial(DatECode)
        dataEquipment, // result table equipment

        confirmedSpecialEquipment,
        confirmedDeselectedSeriesEquipment,
        isModified
    },
    events: {
        changeEquipment,
        setExistingEquipment,
        setPossibleEquipment,
        setDeselectedSeriesEquipment,
        setSpecialEquipment,

        setConfirmedSpecialEquipment,
        setConfirmedDeselectedSeriesEquipment
    }
};
