import { FC, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { calculatePositionCorrPrice } from '../../../utils/calculatePositionCorrPrice';
import { getWorkLevelSymbol } from '../../../utils/getWorkLevelSymbol';
import { PART_DISCOUNT_TYPE_OPTIONS } from '../../../constants/selects';
import { PositionsFormValues } from '../../../types/positions';
import { modalsEvents } from '../../../stores/ui/modals';
import { AGGREGATE_PART_CODE_ITALY, PositionsTypesValues } from '../../../constants/positionFieldsValues';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { checkTimeExistence } from '../../../utils/checkTimeExistence';
import { toastEffects } from '@dat/smart-components/Toast/store';
import { checkManufacturer } from '../../../utils/checkManufacturer';
import { checkWorkBenchOrWorkLogic } from '../../../utils/checkWorkBenchOrWorkLogic';

import { CheckboxField, SelectField } from '@wedat/ui-kit/Formik';
import { Card, InfoButton } from './styles';
import { Field } from '../../Field';
import { InputFieldStyled, NumberFormatStyled, PositionNumberInputFieldStyled, Wrapper } from '../../core/styles';
import { InfoIcon } from '@wedat/ui-kit/components/Icons';
import { isUpperCase } from '@dat/core/utils/isUpperCase';

interface Props {
    position: DAT2.PositionItaly;
    index: number;
    onPositionClick: (index: number) => void;
    selectedPositionIndexes: number[];
}

export const PositionsCard: FC<Props> = ({ position, index, onPositionClick, selectedPositionIndexes }) => {
    const { t } = useTranslation();
    const {
        values: { positions },
        setFieldValue,
        getFieldProps
    } = useFormikContext<PositionsFormValues>();
    const theme = useTheme();

    const materialPositions = useStore(sharedRepairCalculationStores.materialPositions);
    const repairPositions = useStore(sharedRepairCalculationStores.repairPositions);

    const handleClickCard = (idx: number) => () => {
        onPositionClick(idx);
    };

    // TODO: think about delete copypaste
    const handleChangePercent = useCallback(
        (value: number | undefined, index: number) => {
            if (!value) {
                // Need logic for empty PartDiscountType if PartDiscrountPerc is empty
                setFieldValue(`positions.${index}.PartDiscountType`, '');
            }

            if (
                value &&
                (positions[index].PartDiscountType === '' || typeof positions[index].PartDiscountType === 'undefined')
            ) {
                setFieldValue(`positions.${index}.PartDiscountType`, 'S');
            }
        },
        [setFieldValue, positions]
    );

    useEffect(() => {
        positions.forEach((item, idx) => {
            if (item.PartDiscountPerc) {
                setFieldValue(`positions.${idx}.PartDiscountType`, 'S');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positions]);

    const isGray = position.Type === PositionsTypesValues.aggregateComponent && isUpperCase(position.Description);
    const isAggregate = position.Type === PositionsTypesValues.aggregate;
    const isAggregateComponent = position.Type === PositionsTypesValues.aggregateComponent && !isGray;
    // disabled for editing
    const isWorkBenchOrWorkLogic = checkWorkBenchOrWorkLogic(position);

    const isManual = !position.DATProcessId && position.PartCodeItaly !== AGGREGATE_PART_CODE_ITALY && !isAggregate;
    const isManualWithSparePartPrice = !!(isManual && position.ValueParts);

    const doesReplaceExist = checkTimeExistence(position.WorkTimeReplace);
    const doesOverhaulExist = checkTimeExistence(position.WorkTimeOverhaul);
    const doesLacquerExist = checkTimeExistence(position.WorkTimeLacquer);
    const doesMechanicExist = checkTimeExistence(position.WorkTimeMechanic);

    const hasQuantity = position.Amount && position.Amount > 0;
    const quantityFromMaterial = materialPositions.find(mPos => position.PartNumber === mPos.PartNumber)?.Amount;
    const quantityFromMaterialConverted = quantityFromMaterial ? String(quantityFromMaterial).split('.').join(',') : '';
    const amount = hasQuantity ? position.Amount : quantityFromMaterialConverted;

    const isActiveCard = selectedPositionIndexes.includes(index);

    const isManufacturer = checkManufacturer(position, repairPositions) && !isManual;

    const mechanicFieldProps = getFieldProps(`positions.${index}.WorkTimeMechanic`);
    const overhaulFieldProps = getFieldProps(`positions.${index}.WorkTimeOverhaul`);
    const discountPercFieldProps = getFieldProps(`positions.${index}.PartDiscountPerc`);

    return (
        <Card onClick={handleClickCard(index)} isActiveCard={isActiveCard}>
            <Wrapper>
                <Field label={t('common.pn')} size="col-8">
                    <InputFieldStyled
                        name={`positions.${index}.PartNumber`}
                        disabled={isAggregate || isWorkBenchOrWorkLogic}
                        stopPropagation
                    />
                </Field>
                <Field size="col-4" label={t('positionsCard.quantity')}>
                    <NumberFormatStyled value={amount} />
                </Field>
            </Wrapper>
            <Wrapper>
                <Field label={t('common.designation')}>
                    <InputFieldStyled
                        name={`positions.${index}.Description`}
                        aggregate={isAggregate}
                        aggregateComponent={isAggregateComponent}
                        isGray={isGray}
                        width={300}
                        disabled={isWorkBenchOrWorkLogic || isAggregateComponent}
                        stopPropagation
                    />
                </Field>
            </Wrapper>
            <Wrapper>
                <Field size="col-3" label={t('common.sr')}>
                    <PositionNumberInputFieldStyled
                        name="WorkTimeReplace"
                        index={index}
                        withPrefix={doesReplaceExist}
                        workLevelSymbol={getWorkLevelSymbol({ position, key: 'WorkLevelReplace' })}
                        defaultLeftPadding={40}
                        disabled={isAggregateComponent || isWorkBenchOrWorkLogic}
                        stopPropagation
                        hideValue={isAggregateComponent || isGray}
                    />
                </Field>
                <Field size="col-3" label={t('common.la')}>
                    <PositionNumberInputFieldStyled
                        name="WorkTimeOverhaul"
                        index={index}
                        withPrefix={doesOverhaulExist}
                        workLevelSymbol={getWorkLevelSymbol({ position, key: 'WorkLevelOverhaul' })}
                        defaultLeftPadding={40}
                        disabled={isAggregateComponent || isGray || isWorkBenchOrWorkLogic}
                        stopPropagation
                        hideValue={isAggregateComponent || isGray}
                        onChange={() => {
                            if (
                                !!mechanicFieldProps.value &&
                                mechanicFieldProps.value !== '0' &&
                                (isManufacturer || isManual)
                            ) {
                                setFieldValue(`positions.${index}.WorkTimeOverhaul`, '');
                                toastEffects.showInfoToastFx({
                                    message: {
                                        namespace: 'italian-calculation',
                                        key: 'alert.setTime.message'
                                    },
                                    title: {
                                        namespace: 'italian-calculation',
                                        key: 'alert.setTime.title'
                                    },
                                    toastId: 'setTimeAlert'
                                });
                            }
                        }}
                    />
                </Field>
                <Field size="col-3" label={t('positionsCard.ve')}>
                    <PositionNumberInputFieldStyled
                        name="WorkTimeLacquer"
                        index={index}
                        withPrefix={doesLacquerExist}
                        workLevelSymbol={getWorkLevelSymbol({ position, key: 'WorkLevelLacquer' })}
                        defaultLeftPadding={40}
                        disabled={isAggregateComponent || isWorkBenchOrWorkLogic || isGray || isManufacturer}
                        stopPropagation
                        hideValue={isAggregateComponent || isGray || isManufacturer}
                    />
                </Field>
                <Field size="col-3" label={t('common.me')}>
                    <PositionNumberInputFieldStyled
                        name="WorkTimeMechanic"
                        index={index}
                        withPrefix={doesMechanicExist}
                        workLevelSymbol={getWorkLevelSymbol({ position, key: 'WorkLevelMechanic' })}
                        defaultLeftPadding={40}
                        disabled={
                            (isAggregate ||
                                isAggregateComponent ||
                                isGray ||
                                isWorkBenchOrWorkLogic ||
                                !isManufacturer) &&
                            !isManual
                        }
                        stopPropagation
                        hideValue={isAggregateComponent || isGray}
                        onChange={() => {
                            if (
                                !!overhaulFieldProps.value &&
                                overhaulFieldProps.value !== '0' &&
                                (isManufacturer || isManual)
                            ) {
                                setFieldValue(`positions.${index}.WorkTimeMechanic`, '');
                                toastEffects.showInfoToastFx({
                                    message: {
                                        namespace: 'italian-calculation',
                                        key: 'alert.setTime.message'
                                    },
                                    title: {
                                        namespace: 'italian-calculation',
                                        key: 'alert.setTime.title'
                                    },
                                    toastId: 'setTimeAlert'
                                });
                            }
                        }}
                    />
                </Field>
            </Wrapper>
            <Wrapper>
                <Field label={t('positionsTable.partsPrice')}>
                    <PositionNumberInputFieldStyled
                        workLevelSymbol={isManualWithSparePartPrice ? '#' : ''}
                        width={100}
                        withPrefix={isManualWithSparePartPrice}
                        index={index}
                        defaultLeftPadding={isManualWithSparePartPrice ? 40 : 0}
                        name="ValueParts"
                        disabled={isAggregate || isWorkBenchOrWorkLogic}
                        thousandSeparator="."
                        stopPropagation
                    />
                </Field>
            </Wrapper>
            <Wrapper>
                <Field
                    label="*"
                    size="col-1"
                    content={
                        position.ValueParts && (
                            <CheckboxField
                                name={`positions.${index}.PartDiscountAsterisk`}
                                disabled={isAggregate || isWorkBenchOrWorkLogic}
                            />
                        )
                    }
                />
                <Field size="col-3" label={t('positionsCard.discountType')}>
                    {position.ValueParts && (
                        <SelectField
                            name={`positions.${index}.PartDiscountType`}
                            backgroundColor={isActiveCard ? theme.colors.white : theme.colors.gray_10}
                            options={PART_DISCOUNT_TYPE_OPTIONS}
                            disabled={isAggregate || isWorkBenchOrWorkLogic || !discountPercFieldProps.value}
                        />
                    )}
                </Field>
                <Field size="col-3" label={t('positionsCard.discount')}>
                    {position.ValueParts && (
                        <PositionNumberInputFieldStyled
                            name="PartDiscountPerc"
                            index={index}
                            onChange={value => handleChangePercent(value, index)}
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                            stopPropagation
                        />
                    )}
                </Field>
                <Field size="col-3" label={t('positionsTable.corrPrice')}>
                    <NumberFormatStyled
                        value={calculatePositionCorrPrice(position)}
                        disabled={isAggregate || isWorkBenchOrWorkLogic}
                    />
                </Field>
                <Field
                    size="col-1"
                    content={
                        position.WorkLevelReplace === 'manufacturer' ? (
                            <InfoButton onClick={() => modalsEvents.setActiveInfoPosition(position)}>
                                <InfoIcon />
                            </InfoButton>
                        ) : null
                    }
                ></Field>
            </Wrapper>
        </Card>
    );
};
