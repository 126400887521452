export interface EfficiencyMetrics {
    totalTodo?: number;
    max: number;
    perDays: EfficiencyPerDaysMetrics;
}

export interface EfficiencyPerDaysMetrics {
    [prop: number]: EfficiencyPerDayMetrics;
}

export interface EfficiencyPerDayMetrics {
    due: number;
    completed: number;
}

export interface GlobalEfficiencyMetrics {
    totalCompletedInTime?: number;
    totalCompletedNotInTime?: number;
}

export enum EfficiencyStatus {
    NOT_COMPLETED = 0,
    COMPLETED_NOT_ON_TIME = 1,
    COMPLETED_ON_TIME = 2
}
