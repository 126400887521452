import { createEvent, restore } from 'effector';

import { PluginOptions } from '../../types/plugin';

const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);
const contractId = pluginOptions.map(options => options?.contractId);

export const pluginEvents = {
    initPlugin
};

export const pluginStores = {
    pluginOptions,
    contractId
};
