import axios from '../axios';
import { DatProductsUrls, VehicleRepairServices } from '@dat/core/constants/enums';

export const getVehicleTypes = (values: DAT.GetVehicleTypesRequest) =>
    axios<DAT.GetVehicleTypesResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getVehicleTypes',
        values
    });

export const getManufacturers = (values: DAT.GetManufacturersRequest) =>
    axios<DAT.GetManufacturersResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getManufacturers',
        values
    });

export const getBaseModelsN = (values: DAT.GetBaseModelsNRequest) =>
    axios<DAT.GetBaseModelsNResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getBaseModelsN',
        values
    });

export const getSubModels = (values: DAT.GetSubModelsRequest) =>
    axios<DAT.GetSubModelsResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getSubModels',
        values
    });

export const compileDatECode = (values: DAT.CompileDatECodeRequest) =>
    axios<DAT.CompileDatECodeResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'compileDatECode',
        values
    });

export const getClassificationGroups = (values: DAT.GetClassificationGroupsRequest) =>
    axios<DAT.GetClassificationGroupsResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getClassificationGroups',
        values
    });

export const getOptionsbyClassification = (values: DAT.GetOptionsByClassificationRequest) =>
    axios<DAT.GetOptionsByClassificationResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getOptionsbyClassification',
        values
    });

export const getConstructionPeriodsN = (values: DAT.GetConstructionPeriodsNRequest) =>
    axios<DAT.GetConstructionPeriodsNResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getConstructionPeriodsN',
        values
    });

export const getPriceFocusCases = (values: DAT.GetPriceFocusCasesRequest) =>
    axios<DAT.GetPriceFocusCasesResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getPriceFocusCases',
        values
    });

export const getSubModelsByTextSearch = (values: DAT.GetSubModelsByTextSearchRequest) =>
    axios<DAT.GetSubModelsByTextSearchResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getSubModelsByTextSearch',
        values
    });
