import { SuccessIcon } from '@wedat/ui-kit/assets/Icon';
import { CloseIcon } from '@wedat/ui-kit/components/Icons';
import styled from 'styled-components/macro';

export const PhotoSteps = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    overflow: auto;
`;

interface StepsProps {
    blue: boolean;
}

export const StepsStyled = styled.div<StepsProps>`
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.white};

    ${({ blue, theme }) => blue && `color: ${theme.colors.deepBlue['800']};`}
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
    color: green;
`;

export const StyledCloseIcon = styled(CloseIcon)`
    color: red;
`;

export const Strong = styled.strong`
    font-weight: 700;
`;
