import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

interface PoiButtonProps {
    primary?: boolean;
}
interface WrapperProps {
    rotate?: string;
}

export const PoiButton = styled.button<PoiButtonProps>`
    display: inline-block;
    border-radius: 50%;
    padding: 0.5rem 0;
    margin: 0.5rem 1rem;
    width: 20px;
    height: 20px;
    border: 2px solid;
    cursor: pointer;
    background: ${props => (props.primary ? props.theme.colors.red['10'] : props.theme.colors.white)};
`;

export const СarImage = styled.img`
    width: 100%;
`;

export const ButtonBlock = styled.div`
    width: 100%;
    height: 135%;

    position: absolute;
    top: -33px;
    display: grid;

    ${media.phoneSmall`
        top: -7px;
    `}
`;

export const Buttons = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;

    margin-top: 25px;

    ${media.phoneSmall`
        margin-top: 0;
    `}
`;

export const ButtonFrontCapote = styled.div`
    margin-left: -50px;
`;

export const ButtonsFront = styled.div`
    margin-left: -30px;

    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 800px) {
        margin-right: 10px;
        padding-right: 20px;
    }
`;

export const ButtonBack = styled.div`
    margin-right: -32px;
    cursor: pointer;

    button:nth-child(1) {
        margin-right: -5px;
    }
`;

export const Wrapper = styled.div<WrapperProps>`
    width: 100%;

    position: relative;
    margin: auto;

    transform: ${props => props.rotate === 'true' && 'rotate(90deg)'};

    ${media.laptopSmall`
        width: 93%;
    `}

    ${media.phoneSmall`
        width: 83%;
    `}
`;
