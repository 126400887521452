import styled from 'styled-components/macro';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const GeneralContainer = styled.div`
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const GeneralFieldset = styled.fieldset`
    padding: 5px;
`;

export const GeneralLegend = styled.legend`
    font-size: 14px;
    font-weight: bolder;
`;

export const GeneralRow = styled.div`
    border-bottom: thin ${({ theme }) => theme.colors.gray['100']} solid;
    margin-bottom: 5px;
    padding: 5px;
    display: flex;
`;

export const GeneralRowLabel = styled.div`
    margin-bottom: 5px;
    padding-right: 5px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    align-self: center;
`;

export const GeneralRowValue = styled.div`
    margin-left: auto;
    padding-left: 5px;
`;
