import React from 'react';
import { useTheme } from 'styled-components';

import { Text } from '../../Text';

import { StyledBadgeCounter } from './styles';
import { BadgeCounterProps } from './types';

export const BadgeCounter = ({ selectedValues }: BadgeCounterProps) => {
    const theme = useTheme();

    return (
        <StyledBadgeCounter>
            <Text font="footnote" fontWeight="bold" color={theme.colors.white}>
                +{selectedValues}
            </Text>
        </StyledBadgeCounter>
    );
};
