import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { FormValues } from '../../types/formValues';

export const initialFormValues: FormValues = {
    customerNumberAndUser: '',
    password: ''
};

export const validationSchema = (t: TFunction) =>
    Yup.object().shape<FormValues>({
        customerNumberAndUser: Yup.string().required(t('errorInput')),
        password: Yup.string().required(t('errorInput'))
    });

export const FORMAT_CHARS = {
    '9': '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-z0-9-_@.]'
};
