import { createStore, sample } from 'effector';

import { configurationStores } from './index';
import { pluginStores } from '../plugin';
import { defaultConfiguration } from '../../constants/defaultConfiguration';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';

const { configuration } = configurationStores;
const { pluginOptions } = pluginStores;
const { userSettings } = sharedConfigurationStores;

const authUserSettings = createStore({});

// Needed to get around the changed userSettings typing
sample({
    source: { welcomePopup: userSettings.welcomePopup, profileManagement: userSettings.profileManagement },
    fn: ({ welcomePopup, profileManagement }) => ({
        welcomePopup,
        profileManagement
    }),
    target: authUserSettings
});

sample({
    source: {
        pluginOptions,
        authUserSettings
    },
    fn: ({ pluginOptions, authUserSettings }) => ({
        ...defaultConfiguration,
        ...authUserSettings,
        ...pluginOptions
    }),
    target: configuration
});
