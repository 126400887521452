import { InputStyled } from '@wedat/ui-kit/components/Input/styles';
import styled from 'styled-components/macro';

export const Container = styled.div`
    position: relative;
    width: 100%;

    ${InputStyled} {
        padding-left: 44px;
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
    }
`;
