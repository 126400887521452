// ElementsMenuList

import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { fastTrackElementsMenuModel } from '../../stores/fastTrackElementsMenuModel';
import { ElementsMenuTable } from './ElementsMenuTable';

export const ElementsMenuList: FC = () => {
    // const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    const fastTrackElementsMenuState = useStore(fastTrackElementsMenuModel.stores.$fastTrackElementsMenu);

    // if (!fastTrackConfiguration) return null;

    // const translationFromFastTrackConfiguration: { [key: string]: string } = {};
    // Object.entries(fastTrackConfiguration.fastTrackDamages).forEach(([_, value]) =>
    //     value.forEach(elem => (translationFromFastTrackConfiguration[elem.id] = elem.label))
    // );

    // // const currentGroupFTMenu = fastTrackElementsMenu?.find(groupMenu=>);

    // if (!fastTrackElementsMenuState) return null;

    return (
        <div style={{ textAlign: 'left' }}>
            <Button
                size="large"
                type="primary"
                // style={{ width: '100%' }}
                onClick={() => fastTrackElementsMenuModel.events.setFastTrackElementsMenu(null)}
                icon={<ArrowLeftOutlined />}
            >
                {/* {vehicleSelectionManufacturer?.manufacturerLabel ||
                    vehicleSelectionManufacturer?.manufacturerId ||
                    '...'} */}
                Groups
            </Button>
            <h2 style={{ display: 'inline', marginLeft: '8px' }}> {fastTrackElementsMenuState?.menuGroupLabel} </h2>
            <br />
            <br />
            <ElementsMenuTable />
        </div>
    );
};
