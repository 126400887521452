import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, StyledText } from '../Header/styles';
import { InvoiceContainer } from './styles';

export const Invoice: React.FC = () => {
    const { t } = useTranslation();
    // Url to internal dat-coworker applicaiton which availiable only for their coworkers
    // It runs locally and is not available for public that's why we need to use localhost
    const INVOICE_OPEN_URL = 'http://localhost:8140/open';

    return (
        <InvoiceContainer>
            <Button
                type="button"
                onClick={() => {
                    window.open(INVOICE_OPEN_URL);
                }}
            >
                <StyledText fontWeight={500} textTransform="capitalize">
                    {t('header.invoiceOpening')}
                </StyledText>
            </Button>
        </InvoiceContainer>
    );
};
