import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';

export const StyledText = styled(Text)`
    color: inherit;
`;

export const LegendContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;

    margin-right: 64px;
    width: 100%;

    ${media.laptop`
        margin-right: 0;
    `}
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    margin-right: 28px;

    ${media.laptop`
        max-width: 140px;
        width: 100%;
    `}

    &:last-of-type {
        margin-right: 0;
    }
`;

export const IndicatorWrapper = styled.div`
    display: flex;
    margin-right: 12px;
    margin-top: 2px;
`;
