import { createEffect, createEvent, createStore, restore } from 'effector';
import { API2 } from '@dat/api2';

interface GetListPrintReportForClaimParams {
    claimId: number;
}

const getListPrintReportForClaim = createEffect(
    async ({ claimId }: GetListPrintReportForClaimParams) =>
        await API2.myClaimInternal.getListPrintReportForClaim({ claimId })
);

const printReports = createStore<DAT2.Internal.PrintReport[]>([]).on(
    getListPrintReportForClaim.doneData,
    (_, payload) => payload?.data?.printReports
);

const setReports = createEvent<DAT2.Internal.PrintReport[]>();
const addItemToReports = createEvent<DAT2.Internal.PrintReport>();
const removeItemFromReports = createEvent<DAT2.Internal.PrintReport>();
const resetReports = createEvent();

const selectedReports = restore<DAT2.Internal.PrintReport[]>(setReports, [])
    .on(addItemToReports, (state, payload) => [...state, payload])
    .on(removeItemFromReports, (state, payload) => state.filter(item => item.id !== payload.id))
    .reset(resetReports);

export const printReportsEvents = {
    setReports,
    addItemToReports,
    removeItemFromReports,
    resetReports
};

export const printReportsEffects = {
    getListPrintReportForClaim
};

export const printReportsStore = {
    printReports,
    selectedReports
};
