import { sharedTemplateStores } from '@dat/shared-models/template';
import { combine } from 'effector';

const invoiceOptions = combine(
    [sharedTemplateStores.productsConfiguration],
    ([productsConfiguration]) => !productsConfiguration?.['claim-management'].hideBlocks.header.includes('invoice')
);

export const containedPluginsStores = {
    invoiceOptions
};
