import React, { FC, useMemo } from 'react';
import {
    useTable,
    useRowSelect,
    useRowState,
    useGlobalFilter,
    useExpanded,
    useSortBy,
    Column,
    Row,
    useMountedLayoutEffect
} from 'react-table';
import { useStore } from 'effector-react';
import {
    TableContainer,
    TableTitle,
    InputContainer,
    TableWrapper,
    TableHeader,
    TableHeading,
    ItemsCount,
    TableRow,
    TableHeaderContainer,
    SubrowCell,
    Subrow,
    SortIconsContainer,
    StyledTable,
    TableBody,
    TableCell,
    SortDownIcon,
    SortUpIcon,
    TableHeadingContainer,
    TableHeaderInfo,
    MobileFilter,
    SubRowDescriptionCOntainer,
    Knee
} from './styles';

import { SearchInput } from './SearchInput';
import { customGlobalFilter } from './utils';
import { TableData } from '../../types/table';
import { Event } from 'effector';
import { useTranslation } from 'react-i18next';
import { NoRowsData } from '../NoRowsData';
import { SortArrowsIcon } from '@wedat/ui-kit/components/Icons';
import { Categories } from '../Categories';
import { tableFilterStores } from '../../stores/tableFilter';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

export interface SelectedIdsTypes {
    [key: string]: boolean;
}

interface Props {
    columns: Column<TableData>[];
    data: TableData[];
    title: string;
    selectedIds: SelectedIdsTypes;
    onSelectRows: (rowsIds: SelectedIdsTypes) => void;
    isMobile: boolean;
    filterValue?: string;
    setFilterValue?: Event<string>;
}

const renderRowSubComponent = (row: Row<TableData>, isMobile: boolean) => {
    const {
        original: { children }
    } = row;

    const tableCells = isMobile
        ? children?.map((row, index: number) => (
              <Subrow role="row" key={index} isLast={index === children.length - 1}>
                  <SubrowCell role="cell">{row.col4}</SubrowCell>
              </Subrow>
          ))
        : children?.map((row, index: number) => (
              <TableRow role="row" key={index}>
                  <TableCell role="cell">{row.col0}</TableCell>
                  <TableCell role="cell">{row.col1}</TableCell>
                  <TableCell role="cell">{row.col2}</TableCell>
                  <TableCell role="cell">{row.col3}</TableCell>
                  <TableCell role="cell">
                      <SubRowDescriptionCOntainer>
                          <Knee />
                          {row.col4}
                      </SubRowDescriptionCOntainer>
                  </TableCell>
                  <TableCell role="cell">{row.col5}</TableCell>
              </TableRow>
          ));

    return tableCells;
};

export const EquipmentTable: FC<Props> = React.memo(
    ({ data, columns, title, onSelectRows, selectedIds, filterValue, setFilterValue }) => {
        const isPhone = useMedia(sizes.laptop);
        const mobileFilterVisible = useStore(tableFilterStores.mobileFilterVisible);
        const globalFilter = useMemo(() => customGlobalFilter(), []);

        const { t } = useTranslation();
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            selectedFlatRows,
            state: { selectedRowIds }
        } = useTable<TableData>(
            {
                columns,
                data,
                globalFilter,
                initialState: { selectedRowIds: selectedIds, globalFilter: filterValue },
                autoResetSortBy: false,
                autoResetFilters: false
            },
            useGlobalFilter,
            useSortBy,
            useRowState,
            useExpanded,
            useRowSelect
        );

        useMountedLayoutEffect(() => {
            onSelectRows(selectedRowIds);
        }, [selectedRowIds]);

        return (
            <TableContainer>
                <MobileFilter opened={mobileFilterVisible}>
                    <Categories />
                    <InputContainer>
                        <SearchInput setGlobalFilter={setFilterValue} />
                    </InputContainer>
                </MobileFilter>

                <TableHeaderContainer>
                    <TableHeaderInfo>
                        <TableTitle font="defaultBold">
                            {title} ({rows.length})
                        </TableTitle>
                        <ItemsCount font="footnote">
                            {selectedFlatRows.length ? `${selectedFlatRows.length} ${t('selected')}` : ''}
                        </ItemsCount>
                    </TableHeaderInfo>
                    <InputContainer>
                        <SearchInput setGlobalFilter={setFilterValue} />
                    </InputContainer>
                </TableHeaderContainer>

                <TableWrapper>
                    <StyledTable {...getTableProps()}>
                        <TableHeader {...headerGroups[0].getHeaderGroupProps()}>
                            {headerGroups[0].headers.map(column => (
                                <TableHeading {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <TableHeadingContainer>
                                        {column.render('Header')}

                                        {column.canSort &&
                                            (column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <SortIconsContainer>
                                                        <SortUpIcon />
                                                    </SortIconsContainer>
                                                ) : (
                                                    <SortIconsContainer>
                                                        <SortDownIcon />
                                                    </SortIconsContainer>
                                                )
                                            ) : (
                                                <SortIconsContainer>
                                                    <SortArrowsIcon />
                                                </SortIconsContainer>
                                            ))}
                                    </TableHeadingContainer>
                                </TableHeading>
                            ))}
                        </TableHeader>
                        <TableBody {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map(row => {
                                    prepareRow(row);
                                    return (
                                        <React.Fragment key={row.getRowProps().key}>
                                            <TableRow
                                                withSubRow={row.isExpanded}
                                                withExpander={!!row.original.children?.length}
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    row.toggleRowSelected(!row.isSelected);
                                                }}
                                                isSelected={row.getToggleRowSelectedProps().checked}
                                                isConflicted={!!row.original.conflictedItem}
                                                isDisabled={row.original.isDisabled}
                                            >
                                                {row.cells.map(cell => (
                                                    <TableCell {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            {row.isExpanded && renderRowSubComponent(row, isPhone)}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <NoRowsData />
                            )}
                        </TableBody>
                    </StyledTable>
                </TableWrapper>
            </TableContainer>
        );
    }
);
