import * as ort from 'onnxruntime-web';
import { TypedTensor } from 'onnxruntime-web';
import { Sessions } from '../types';

export async function runPhotoCapturingModel(
    preprocessedData: number | TypedTensor<'uint8'>,
    constraints: Float32Array,
    view: Int32Array,
    image_shape: Int32Array,
    { checker, detection }: Sessions
) {
    try {
        const { output, inferenceTime } = await runBothInference(
            detection,
            checker,
            preprocessedData,
            constraints,
            view,
            image_shape
        );
        return { output, inferenceTime };
    } catch (error) {
        console.log('error', error);
        throw error;
    }
}

async function runBothInference(
    session_detection: ort.InferenceSession,
    session_checker: ort.InferenceSession,
    preprocessedData: number | ort.TypedTensor<'uint8'>,
    constraints: Float32Array,
    view: Int32Array,
    image_shape: Int32Array
) {
    // Get start time to calculate inference time.
    const start = new Date();
    // create feeds for detection model
    const feeds_detection: any = {};
    feeds_detection[session_detection.inputNames[0]] = preprocessedData;

    // Run the session detection inference.
    const outputDetection = await session_detection.run(feeds_detection);
    const detectionResult = outputDetection[session_detection.outputNames[0]];

    // create feeds for checker model.
    const feeds_checker: any = {};
    const tensorView = new ort.Tensor('int32', view, []);
    const tensorConstraints = new ort.Tensor('float32', constraints, [8]);
    const tensorShape = new ort.Tensor('int32', image_shape, [2]);
    feeds_checker[session_checker.inputNames[0]] = detectionResult;
    feeds_checker[session_checker.inputNames[1]] = tensorView;
    feeds_checker[session_checker.inputNames[2]] = tensorConstraints;
    feeds_checker[session_checker.inputNames[3]] = tensorShape;

    // Run the session inference.
    const outputData = await session_checker.run(feeds_checker);
    // Get the end time to calculate inference time.
    const end = new Date();
    // Convert to seconds.
    const inferenceTime = (end.getTime() - start.getTime()) / 1000;
    // Get output results with the output name from the model export.
    const output = outputData[session_checker.outputNames[0]];

    return { output, inferenceTime };
}
