import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { schedulerEffects, schedulerEvents, schedulerStores } from '@dat/shared-models/scheduler';

import { TabValue } from '../../types';
import { SELECT_FIELDS, VIEW_OPTIONS } from '../../constants/constants';

import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { Text } from '@wedat/ui-kit/components/Text';
import { Arrow } from '@wedat/ui-kit/assets/Icon';
import { Button } from '@wedat/ui-kit/components/Button';
import { Scheduler } from '../Scheduler';

import {
    ArrowWrapper,
    ArrowWrapperRotated,
    DaySwitchSelection,
    SectionWrapper,
    SelectedUserStyled,
    TabsWrapper
} from '../../styles';
import { sharedProfilesEffects } from '@dat/shared-models/profiles';
import { getInitialLocale } from '@dat/shared-models/i18n/utils';
import { pluginStores } from '../../stores/plugin';
import _ from 'lodash';

const viewOptions = [VIEW_OPTIONS.week, VIEW_OPTIONS.today, VIEW_OPTIONS.month];
const selectionValues = [SELECT_FIELDS.appointments];

type Props = {
    withoutApi?: boolean;
};

export const Main: React.FC<Props> = ({ withoutApi }) => {
    // Type is not exported that's why any
    const calendarRef = useRef<any>(null);
    const { t } = useTranslation();

    const [selectedView, setSelectedView] = useState(viewOptions[0]);
    const [monthName, setMonthName] = useState('');

    const selectedFilter = useStore(schedulerStores.selectedTab);
    const selectedUser = useStore(schedulerStores.selectedUser);
    const pluginOptions = useStore(pluginStores.pluginOptions);

    const [tabValues, setTabValues] = useState<TabValue[]>([]);

    const viewOptionValues: TabValue[] = viewOptions.map(i => ({
        id: i,
        label: t(i)
    }));

    const isMonthView = selectedView === VIEW_OPTIONS.month;

    useEffect(() => {
        pluginOptions?.contractId === 0
            ? setTabValues(prev => {
                  const value = selectionValues.map(i => ({
                      id: i,
                      label: t(i)
                  }));
                  return _.uniqBy([...prev, ...value], 'id');
              })
            : setTabValues(prev => _.uniqBy([...prev, { id: selectionValues[0], label: t(selectionValues[0]) }], 'id'));
    }, [pluginOptions?.contractId, t]);

    const getMonthName = () => {
        if (calendarRef.current) {
            const start = calendarRef.current.calendarInst.getDateRangeStart();

            const locale = getInitialLocale();

            const startMonth = new Date(start._date);

            // When the calendar starts on the day of the previous month, we add 15 days to get the actual month
            const halfMonth = 15;
            const monthStartDay = 1;

            if (startMonth.getDate() !== monthStartDay) {
                startMonth.setDate(startMonth.getDate() + halfMonth);
            }
            return setMonthName(startMonth.toLocaleString(locale, { month: 'short', year: 'numeric' }).toUpperCase());
        }
    };

    const handlePrev = () => {
        if (calendarRef.current) {
            calendarRef.current.calendarInst.prev();
            isMonthView && getMonthName();
        }
    };

    const handleToday = () => {
        if (calendarRef.current) {
            calendarRef.current.calendarInst.today();
            isMonthView && getMonthName();
        }
    };

    const handleNext = () => {
        if (calendarRef.current) {
            calendarRef.current.calendarInst.next();
            isMonthView && getMonthName();
        }
    };

    const handleViewChange = (value: string) => {
        setSelectedView(value);
    };

    const handleFilterChange = async (value: string) => {
        schedulerEvents.changeActiveTab(value);
        localStorage.setItem('isAppointmentView', JSON.stringify(value === SELECT_FIELDS.appointments));
    };

    useEffect(() => {
        schedulerEffects.getAppointmentsList();
        sharedProfilesEffects.getProfilesFx();
    }, []);

    useEffect(() => {
        if (isMonthView) {
            getMonthName();
        } else {
            setMonthName('');
        }
    }, [isMonthView]);

    return (
        <>
            <SectionWrapper>
                <TabsWrapper>
                    <Tabs
                        values={tabValues}
                        active={selectedFilter}
                        onChange={handleFilterChange}
                        typeStyle="primary"
                    />

                    {isMonthView && (
                        <SectionWrapper>
                            <Text fontSize="14px" fontWeight="bold">
                                {monthName}
                            </Text>
                        </SectionWrapper>
                    )}
                </TabsWrapper>

                <TabsWrapper>
                    <Tabs
                        values={viewOptionValues}
                        active={selectedView}
                        onChange={handleViewChange}
                        typeStyle="primary"
                    />

                    <DaySwitchSelection>
                        <ArrowWrapper onClick={handlePrev}>
                            <Arrow />
                        </ArrowWrapper>

                        <Button onClick={handleToday}>{t('today')}</Button>

                        <ArrowWrapperRotated onClick={handleNext}>
                            <Arrow />
                        </ArrowWrapperRotated>
                    </DaySwitchSelection>
                </TabsWrapper>
            </SectionWrapper>

            {!!selectedUser && (
                <SelectedUserStyled>
                    <Text fontSize="20px" fontWeight="bold">
                        {`${t('calendar.of')} ${selectedUser}`}
                    </Text>
                </SelectedUserStyled>
            )}

            <Scheduler selectedView={selectedView} calendarRef={calendarRef} withoutApi={withoutApi} />
        </>
    );
};
