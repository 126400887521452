import csCZ from './cs-CZ/translation.json';
import deDE from './de-DE/translation.json';
import elGR from './el-GR/translation.json';
import enUS from './en-US/translation.json';
import esES from './es-ES/translation.json';
import frFR from './fr-FR/translation.json';
import itIT from './it-IT/translation.json';
import koKR from './ko-KR/translation.json';
import nlNL from './nl-NL/translation.json';
import plPL from './pl-PL/translation.json';
import ruRU from './ru-RU/translation.json';
import zhCN from './zh-CN/translation.json';

export const TRANSLATIONS = {
    'cs-CZ': csCZ,
    'de-DE': deDE,
    'el-GR': elGR,
    'en-US': enUS,
    'es-ES': esES,
    'fr-FR': frFR,
    'it-IT': itIT,
    'ko-KR': koKR,
    'nl-NL': nlNL,
    'pl-PL': plPL,
    'ru-RU': ruRU,
    'zh-CN': zhCN
};
