import React, { FC } from 'react';
import { RowStyled } from './styles';
import { FieldContent } from '../FieldContent';
import { DataRow, DataField } from '../../types/dataScheme';

interface Props {
    row: DataRow;
    formName: string;
    isConditionTrue: boolean | undefined;
    keyCondition: Object | null;
    isMemo?: boolean;
}

export const RowContent: FC<Props> = ({ row, isConditionTrue, keyCondition, formName, isMemo }) => (
    <RowStyled>
        {row.fields.map((field: DataField) => (
            <FieldContent
                isMemo={isMemo}
                key={field.id}
                fieldId={field.id}
                formName={formName}
                isConditionTrue={isConditionTrue}
                keyCondition={keyCondition}
            />
        ))}
    </RowStyled>
);
