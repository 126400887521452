import { FC, useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { pluginEvents } from './stores/plugin/';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';
import { AppContext } from './App.context';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => {
    const instanceId = useMemo(() => uuidv4(), []);
    const handlePluginInit = useCallback(() => {
        pluginEvents.instanceOptionsReceived({ ...options, instanceId });
    }, [options, instanceId]);

    useEffect(
        () => () => {
            pluginEvents.destroyInstance(instanceId);
        },
        [instanceId]
    );

    return (
        <PluginProvider name="address-book" options={options} onInit={handlePluginInit}>
            <AppContext.Provider value={instanceId}>
                <Main />
            </AppContext.Provider>
        </PluginProvider>
    );
};

export default App;
