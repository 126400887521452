import { useOrientation } from './orientation';
import {
    getLandscapeVideoContainerSize,
    getLandscapeVideoContainerSizeForComment,
    getPortraitVideoContainerSize
} from '../utils/mediaDevices';

/**
 * Hook to get container size for video and image
 */
export const useMediaContainerSize = (commentMode = false) => {
    const { isLandscape } = useOrientation();

    return isLandscape
        ? commentMode
            ? getLandscapeVideoContainerSizeForComment()
            : getLandscapeVideoContainerSize()
        : getPortraitVideoContainerSize();
};
