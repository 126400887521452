import React from 'react';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_WITH_TIME } from '@wedat/ui-kit/components/Datepicker/constants';
import { format as fnsFormat, isToday, isYesterday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Text } from './style';

export interface DateFormatProps {
    format?: string;
    children: string | undefined | null;
}

export const DateFormat: React.FC<DateFormatProps> = ({ format = DEFAULT_DATE_FORMAT, children }) => {
    const { t } = useTranslation();
    if (!children) return null;

    const customFormat = (key: string, date: Date) => (
        <>
            {t(key)}
            {format === DEFAULT_DATE_FORMAT_WITH_TIME && <Text>{fnsFormat(date, 'hh:mm aa')}</Text>}
        </>
    );

    const getDateString = () => {
        try {
            const date = new Date(children);
            if (isToday(date)) {
                return customFormat('date.today', date);
            }
            if (isYesterday(date)) {
                return customFormat('date.yesterday', date);
            }
            return fnsFormat(date, format);
        } catch {
            return children;
        }
    };

    return <>{getDateString()}</>;
};
