export interface GetDomusTimesParams {
    datECode?: string;
    doors?: string;
}

export interface ResultGetDomusTimes {
    datecode: string;
    doors: string;
    dvn: DvnTime[];
    noOfDVNs: number;
    aggregates: Aggregate[];
    noOfAggregates: number;
}

export interface Aggregate {
    description: string;
    dvn: AggregateDvn[];
}

export interface AggregateDvn {
    DSPEL: string;
    DVN: number;
    side: Side;
}

export enum Side {
    Left = 'left',
    Neutral = 'neutral',
    Right = 'right'
}

export interface DvnTime {
    dvn: number;
    repairTime: RepairTime;
}

export const availableAniaComplicity = [
    'easy-min',
    'easy-avg',
    'easy-max',
    'medium-min',
    'medium-avg',
    'medium-max',
    'hard-min',
    'hard-avg',
    'hard-max'
    // 'replace'
];

export interface RepairTime {
    overhaul?: { [key: string]: number };
    // {
    //     // replace: number;
    // };

    'dis- and- mounting'?: { [key: string]: number };
    lacquer?: { [key: string]: number };
}

export interface Overhaul {}

export interface ResultGetVehicleType {
    datECode: string;
    response: [string, number];
}
