import React, { ChangeEvent, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { useTheme } from 'styled-components';

import { combinedVehicleIdentificationStores } from '../../../stores/vehicleIdentification';
import { vehicleSelectionModalEffects } from '../store';
import { sharedTemplateStores } from '@dat/shared-models/template';
import {
    contractStores as sharedContractStores,
    contractEffects as sharedContractEffects
} from '@dat/shared-models/contract';
import { contractStores, contractEffects } from '../../../stores/contract';
import { pluginStores } from '../../../stores/plugin';
import { modalsEvents } from '../../../stores/modals';
import { vehicleOCRStores } from '../../../stores/ocr';

import { CurrentFormikValues } from '../../../types/formik';

import { convertFileToBase64 } from '@dat/core/utils/data/convertFileToBase64';
import { getParsedArraySafe } from '@dat/api2/utils';

import { AVAILABLE_COUNTRIES } from '../../../constants';

import { InputFieldWithButtons, InputFieldWithButtonsProps } from '../../InputFieldWithButtons';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Content, ButtonNew, ButtonRecover, TextStyled } from './styles';
import { Text } from '@wedat/ui-kit/components/Text';

export const NumberPlateInputField: React.FC<Partial<InputFieldWithButtonsProps>> = props => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext<CurrentFormikValues>();
    const [{ value }] = useField('numberPlate');

    const {
        colors: { white }
    } = useTheme();

    const country = useStore(sharedTemplateStores.templateSettings.country);
    const { registrationNumberRequestResult, lastSuccessfullyRequestedLicencePlate } = useStore(
        combinedVehicleIdentificationStores
    );
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const existingPlateBehavior = pluginOptions?.settings?.existingPlateBehavior;
    const vehicleFromContract = useStore(contractStores.vehicleFromContract);
    const contractInVSM = useStore(contractStores.contractInVSM);
    const vehicleByOCR = useStore(vehicleOCRStores.plateReaderResult);
    const contractId = useStore(sharedContractStores.contractId);
    const contract = useStore(sharedContractStores.contract);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [licencePlateState, setLicencePlateState] = useState('');

    const withNumberPlate = AVAILABLE_COUNTRIES.includes(country);

    const getVehicleByNumberPlate = async (licencePlate: string, behavior?: string) => {
        if (existingPlateBehavior === 'newVINRequest' || behavior === 'newVINRequest') {
            await vehicleSelectionModalEffects.getVehicleByNumberPlateFx({ licencePlate, country });
        }
        if (existingPlateBehavior === 'recoverData' || behavior === 'recoverData') {
            if (contractInVSM) {
                if (contractInVSM.Dossier?.DossierId) {
                    await contractEffects.getVehicleFromContractFx({ contractId: contractInVSM.Dossier?.DossierId });
                }
                const entry = getParsedArraySafe(contractInVSM.customTemplateData?.entry);
                const entryFiltered = entry.filter(
                    item =>
                        item.key.startsWith('owner') ||
                        item.key.startsWith('insurance') ||
                        item.key.startsWith('insurer') ||
                        item.key.startsWith('VINResult')
                );

                const contractForUpdate = {
                    contractId,
                    Dossier: {
                        Country: country,
                        Currency: contract?.Dossier?.Currency || 'EUR',
                        Vehicle: contractInVSM.Dossier?.Vehicle,
                        RepairCalculation: {}
                    },
                    templateData: {
                        entry: entryFiltered
                    }
                };

                await sharedContractEffects.createOrUpdateContractFx(contractForUpdate);
            }
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('numberPlate', e.target.value.toUpperCase());
    };

    const handleOCRUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const upload = await convertFileToBase64(event.target.files[0]);
            vehicleSelectionModalEffects.getVehicleByOCRFx({ OCRPayload: { mmc: true, upload }, country });
        }
    };

    const handleSearch = async (licencePlate: string) => {
        // TODO: вынести try catch в эффект listContractsFx
        const listFN = async () => {
            try {
                return getParsedArraySafe(
                    await contractEffects.listContractsFx({
                        pageLimit: 100,
                        attributes: { plateNumber: licencePlate }
                    })
                );
            } catch (e) {
                console.log('error', e);
                return [];
            }
        };
        const list = await listFN();
        if (!existingPlateBehavior) {
            if (list.length) {
                setIsModalOpen(true);
                setLicencePlateState(licencePlate);
            } else {
                getVehicleByNumberPlate(licencePlate, 'newVINRequest');
            }
        } else {
            getVehicleByNumberPlate(licencePlate, 'newVINRequest');
        }
    };

    const OCRclick = () => {
        modalsEvents.toggleIsPluginCarsOpen();
        pluginOptions?.pluginCarsToggleCallback?.();
    };

    return (
        <>
            <ModalPrimary
                isOpen={isModalOpen}
                onDismiss={() => setIsModalOpen(false)}
                title={t('plateRequest.title')}
                bodyHeight="fit-content"
                bodyWidth="300"
            >
                <Content>
                    <TextStyled>{t('plateRequest.text')}</TextStyled>
                    <ButtonRecover
                        onClick={() => {
                            getVehicleByNumberPlate(licencePlateState, 'recoverData');
                            setIsModalOpen(false);
                        }}
                    >
                        <Text textOverflow="ellipsis" color={white}>
                            {t('plateRequest.recover')}
                        </Text>
                    </ButtonRecover>
                    <ButtonNew
                        onClick={() => {
                            getVehicleByNumberPlate(licencePlateState, 'newVINRequest');
                            setIsModalOpen(false);
                        }}
                    >
                        <Text textOverflow="ellipsis">{t('plateRequest.new')}</Text>
                    </ButtonNew>
                </Content>
            </ModalPrimary>
            <InputFieldWithButtons
                data-cy="number-plate"
                label={t('inputsPlaceholders.numberPlate')}
                isSearchDisabled={!value}
                isBookDisabled={
                    !vehicleFromContract.vinResult?.registrationNumberRequestResult &&
                    !registrationNumberRequestResult &&
                    !vehicleByOCR?.predictedPlateOCR
                }
                uppercase
                status={
                    !!lastSuccessfullyRequestedLicencePlate && lastSuccessfullyRequestedLicencePlate === value
                        ? 'success'
                        : 'default'
                }
                onSearch={handleSearch}
                onChange={handleOnChange}
                onOCRUpload={handleOCRUpload}
                OCRclick={isMobile ? OCRclick : _.noop}
                isMobile={isMobile}
                withNumberPlate={withNumberPlate}
                withSearchButton={withNumberPlate}
                {...props}
                name="numberPlate"
            />
        </>
    );
};
