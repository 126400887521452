import { combine, createEvent, createStore, guard, sample } from 'effector';
import { uniqBy } from 'ramda';
import { convertConstructionPeriodToDate } from '@dat/core/utils';

import { API } from '@dat/api';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';

//
/*** Get construction periods and time ***/
//
const getConstructionPeriodsFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetConstructionPeriodsNRequest) => {
        const response = await API.vehicleSelection.getConstructionPeriodsN(data);
        const { constructionTimeMin, constructionTimeMax } = response.getSingleField('constructionPeriodN');
        const constructionPeriods = response.getFieldArray('entry').map(({ value }) => ({
            key: value,
            value: convertConstructionPeriodToDate(value),
            label: convertConstructionPeriodToDate(value)
        }));
        const uniqueConstructionPeriods = uniqBy(({ key }) => key, constructionPeriods);

        return {
            constructionTimeMin,
            constructionTimeMax,
            constructionPeriods: uniqueConstructionPeriods
        };
    })
});

/*** Construction periods ***/
const resetConstructionPeriods = createEvent();

/*** Construction time ***/
const constructionTimeFrom = createStore('')
    .on(getConstructionPeriodsFx.doneData, (_, { constructionTimeMin }) => constructionTimeMin)
    .reset(resetConstructionPeriods);
const constructionTimeTo = createStore('')
    .on(getConstructionPeriodsFx.doneData, (_, { constructionTimeMax }) => constructionTimeMax)
    .reset(resetConstructionPeriods);

//
/*** Containers (price focus cases) ***/
//
const getContainersFx = createNotifyingEffect({
    handler: cDRH(API.vehicleSelection.getPriceFocusCases)
});
const containers = createStore<DAT.KeyValueField[]>([])
    .on(getContainersFx.doneData, (_, response) => response.getFieldArray('priceFocusCase'))
    .reset(resetConstructionPeriods);

// Get containers when constructionTimeFrom or constructionTimeTo are updated and not empty
// if restriction equals "ALL" or "APPRAISAL" + get datECode from last getConstructionPeriodsFx call
sample({
    source: getConstructionPeriodsFx,
    clock: guard({
        source: combine({ constructionTimeFrom, constructionTimeTo }),
        filter: ({ constructionTimeFrom, constructionTimeTo }) => !!constructionTimeFrom && !!constructionTimeTo
    }),
    fn: ({ datECode }, { constructionTimeFrom, constructionTimeTo }) => ({
        datECode,
        constructionTimeFrom,
        constructionTimeTo
    }),
    target: getContainersFx
});

export const vehicleStores = {
    containers
};

export const vehicleEffects = {
    getConstructionPeriodsFx
};
