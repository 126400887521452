import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { isSafari } from 'react-device-detect';
import { media } from '../../mediaQueries';

interface Props {
    withGradient?: boolean;
    withDots?: boolean;
    withArrows?: boolean;
    story?: boolean;
}

export const StyledSlider = styled(Slider)<Props>`
    margin: 2rem 0;

    ${media.phone`
        ${isSafari && 'overflow: hidden;'}
    `}

    ${props =>
        props.withGradient &&
        `&::before {
        content: '';
        display: block;
        width: 210px;
        height: 100%;
        background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        left: 30px;
        z-index: 1;
    }`}

    ${props =>
        props.withGradient &&
        `&::after {
        content: '';
        width: 210px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        position: absolute;
        right: 30px;
        z-index: 1;
    }`}

    .slick {
        &-list {
            margin: 0 35px;
        }
        &-track {
            margin: auto;
            ${props => props.story && `display: flex;`}
        }
        &-slide {
            padding: 0 7px;

            // Height fix
            & > div {
                display: flex;
            }
        }
    }

    @media (max-width: 768px) {
        &::before,
        &::after {
            display: none;
        }
    }
`;

interface VehicleImageProps {
    url: string;
}

export const Slide = styled.div<VehicleImageProps>`
    ${({ url }) => css`
        padding-top: 66%;
        background: url(${url}) center;
        background-size: cover;
        border-radius: 8px;
        cursor: pointer;
    `}
`;

const ArrowButton = styled.button`
    width: 36px;
    height: 36px;
    padding: 0;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.gray_10};
    border-radius: 2px;
    transform: rotate(-90deg);
    transition: background 0.15s ease;
    cursor: pointer;

    & > svg {
        width: 12px;
    }

    &.slick-disabled {
        background: ${({ theme }) => theme.colors.gray_10};
        cursor: default;

        &:hover {
            background: ${({ theme }) => theme.colors.gray_10};
            border: none;

            & path {
                fill: #465064;
            }
        }
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.blueDark};

        & path {
            fill: ${({ theme }) => theme.colors.blueDark};
        }
    }
`;

export const PrevArrow = styled(ArrowButton)`
    ${(p: Props) => !p.withArrows && 'display: none !important;'}
    left: 0;
    z-index: 2;
    transform: rotate(0deg);
`;

export const NextArrow = styled(ArrowButton)`
    ${(p: Props) => !p.withArrows && 'display: none !important;'}
    right: 0;
    z-index: 2;
    transform: rotate(180deg);
`;

export const StoryWrapper = styled.div`
    display: flex;
    width: 28%;
    height: 250px;
    padding: 20px;
    border-radius: 5px 0px 0px 5px;
    background-color: ${({ theme }) => theme.colors.white};

    @media (max-width: 900px) {
        display: none;
    }

    & > div {
        width: 100%;
    }
`;
