import { TransferEquipment, EquipmentItemData } from '../types/equipment';

type Params = {
    availableTableSelected: EquipmentItemData[];
    existingEquipment: TransferEquipment;
    conflicted: EquipmentItemData[];
};

type Result = {
    existing: EquipmentItemData[];
    removed: EquipmentItemData[];
    conflicted: EquipmentItemData[];
};

export const transferWithSwap = ({ availableTableSelected, existingEquipment, conflicted }: Params) => {
    const result: Result = {
        existing: availableTableSelected,
        removed: [],
        conflicted: [...conflicted]
    };

    existingEquipment.forEach(item => {
        const indexConflictedItemByClass =
            item.EquipmentClass && result.existing.findIndex(i => i.class === item.EquipmentClass);
        const indexConflictedItemByGroup =
            item.EquipmentGroup && result.existing.findIndex(i => i.group === item.EquipmentGroup);

        if (typeof indexConflictedItemByClass === 'number' && indexConflictedItemByClass >= 0) {
            const conflictedItem = {
                ...result.existing[indexConflictedItemByClass],
                replaced: item
            };

            result.conflicted = [...result.conflicted.filter(i => i.class !== conflictedItem.class), conflictedItem];
            result.removed.push(item);
        } else if (typeof indexConflictedItemByGroup === 'number' && indexConflictedItemByGroup >= 0) {
            const conflictedItem = {
                ...result.existing[indexConflictedItemByGroup],
                replaced: item
            };

            result.conflicted = [...result.conflicted.filter(i => i.group !== conflictedItem.group), conflictedItem];
            result.removed.push(item);
        } else {
            result.existing.push(item);
        }
    });

    return result;
};
