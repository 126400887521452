import styled from 'styled-components/macro';

interface VideoProps {
    width: string;
    height: string;
}

export const VideoStyled = styled.div<VideoProps>`
    position: relative;
    width: 100%;

    flex: none;

    ${({ width }) => `width: ${width}`};
    ${({ height }) => `height: ${height}`};

    overflow: hidden;
`;

interface PlayerProps {
    isLandscape: boolean;
}

export const Player = styled.video<PlayerProps>`
    position: relative;
    width: 100%;
    height: auto;

    &::-webkit-media-controls {
        display: none !important;
    }

    ${({ isLandscape }) => isLandscape && 'height: 100%;'}
`;

interface MaskProps {
    mask: string;
}

export const Mask = styled.div<MaskProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-image: ${({ mask }) => `url(${mask})`};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
`;
