//
/*** Types ***/
//
export type FieldObject<T> = T &
    XMLObjectInstance<T> & {
        _innerText?: string;
    };
export type GetFieldsObjectResult<T> = {
    [K in keyof T]: Array<FieldObject<T[K]>>;
};
export type GetSingleFieldsObjectResult<T> = {
    [K in keyof T]: FieldObject<T[K]>;
};
export type GetSingleFieldsInnerTextObjectResult<T> = {
    [K in keyof T]: string;
};
export interface XMLObjectInstance<T> {
    getFieldArray<K extends keyof T>(field: K): Array<FieldObject<T[K]>>;

    getSingleField<K extends keyof T>(field: K): Array<FieldObject<T[K]>>[0];

    getFieldsObject<A extends Array<keyof T>>(...args: A): { [K in A[number]]: Array<FieldObject<T[K]>> };

    getSingleFieldsObject<A extends Array<keyof T>>(...args: A): { [K in A[number]]: FieldObject<T[K]> };

    getSingleFieldsInnerTextObject<A extends Array<keyof T>>(...args: A): { [K in A[number]]: string };
}

//
/*** Parsing functions ***/
//

// Creates XMLObjectInstance from DOM-node or XML-document
function createXMLObjectInstance<T>(XMLInstance: Element | Document): XMLObjectInstance<T> {
    // Returns array of all field's elements (object instances)
    const getFieldArray = <K extends keyof T>(field: K): Array<FieldObject<T[K]>> =>
        getXMLFieldObjectInstances(field, XMLInstance);

    // Returns first element. Use if field in response is single
    const getSingleField = <K extends keyof T>(field: K): FieldObject<T[K]> => getFieldArray(field)[0];

    // Returns object with arrays of elements of every given field
    const getFieldsObject = (...fields: Array<keyof T>): GetFieldsObjectResult<T> => {
        const result: GetFieldsObjectResult<T> = {} as GetFieldsObjectResult<T>;

        fields.forEach(field => {
            result[field] = getFieldArray(field);
        });

        return result;
    };

    // Returns object with first element of every given field
    const getSingleFieldsObject = (...fields: Array<keyof T>): GetSingleFieldsObjectResult<T> => {
        const result: GetSingleFieldsObjectResult<T> = {} as GetSingleFieldsObjectResult<T>;

        fields.forEach(field => {
            result[field] = getSingleField(field);
        });

        return result;
    };

    // Returns object with _innerText of every field's first element
    const getSingleFieldsInnerTextObject = (...fields: Array<keyof T>): GetSingleFieldsInnerTextObjectResult<T> => {
        const result: GetSingleFieldsInnerTextObjectResult<T> = {} as GetSingleFieldsInnerTextObjectResult<T>;
        const singleFieldsObject = getSingleFieldsObject(...fields);

        fields.forEach(field => {
            result[field] = singleFieldsObject[field]?._innerText || '';
        });

        return result;
    };

    return {
        getFieldArray,
        getSingleField,
        getFieldsObject,
        getSingleFieldsObject,
        getSingleFieldsInnerTextObject
    };
}

// Search elements in "searchNode" by tag-name (field)
// and make an array of object instances of every element
// with it's attributes and innerHTML content
function getXMLFieldObjectInstances<T, K extends keyof T>(
    field: K,
    searchNode: Element | Document
): Array<FieldObject<T[K]>> {
    type CurrentFieldObject = FieldObject<T[K]>;

    const elements = searchNode.getElementsByTagNameNS('*', String(field));

    return Array.from(elements).map(element => {
        const fieldObject = createXMLObjectInstance<T[K]>(element) as CurrentFieldObject;

        if (element.innerHTML) {
            fieldObject._innerText = element.innerHTML;
        }
        if (element.hasAttributes()) {
            const { attributes } = element;

            for (let i = 0; i < attributes.length; i++) {
                const attrName = attributes[i].name as keyof CurrentFieldObject;
                // noinspection UnnecessaryLocalVariableJS - disables warning in WebStorm
                const attrValue = attributes[i].value as CurrentFieldObject[keyof CurrentFieldObject];

                fieldObject[attrName] = attrValue;
            }
        }

        return fieldObject;
    });
}

//
/*** Main function ***/
//
export const parseXML = <T>(XML: string): XMLObjectInstance<T> => {
    const parser = new DOMParser();
    const XMLDocument = parser.parseFromString(XML, 'text/xml');
    const error = XMLDocument.getElementsByTagNameNS('*', 'parsererror')[0];

    if (error) {
        throw new Error(error.innerHTML);
    }

    return createXMLObjectInstance<T>(XMLDocument);
};
