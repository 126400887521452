import styled from 'styled-components/macro';

export interface GaugeNeedleProps {
    value: number;
}

export const GaugeContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.red['500']} 0%,
        ${({ theme }) => theme.colors.red['500']} 12%,
        ${({ theme }) => theme.colors.orange['500']} 13%,
        ${({ theme }) => theme.colors.orange['500']} 49%,
        ${({ theme }) => theme.colors.yellow['500']} 50%,
        ${({ theme }) => theme.colors.yellow['500']} 87%,
        ${({ theme }) => theme.colors.green['500']} 88%,
        ${({ theme }) => theme.colors.green['500']} 100%
    );
    border-top-left-radius: 60% 100%;
    border-top-right-radius: 60% 100%;
`;

export const GaugeMask = styled.div`
    position: absolute;
    bottom: 0;
    right: 5%;
    width: 90%;
    height: 90%;
    background-color: ${({ theme }) => theme.colors.white};
    border-top-left-radius: 60% 100%;
    border-top-right-radius: 60% 100%;
`;

export const GaugeNeedle = styled.div<GaugeNeedleProps>`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray['400']};
    width: 3%;
    height: 50%;
    border-top-right-radius: 50% 100%;
    border-top-left-radius: 50% 100%;
    left: 48%;
    top: 15%;
    transform: rotate(${(p: GaugeNeedleProps) => (p.value * 240) / 100 - 120}deg);
    transform-origin: bottom;
`;
