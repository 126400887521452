import { bffAxiosInstance } from '../bffAxios';

export interface ProxyRequest<P, D> {
    url: string;
    method: 'GET' | 'POST' | 'DELETE' | 'PUT';
    params?: P;
    data?: D;
    dataType?: 'form';
    api: 'json' | 'rest' | 'some';
    bodyType?: 'json' | 'form';
    headers?: { [key: string]: string };
}

export const myClaimProxy = <P, D, R>(name: string, req: ProxyRequest<P, D>): Promise<R> =>
    bffAxiosInstance.request<R, R>({
        method: 'POST',
        url: `myClaim/proxy/${name}`,
        data: req
    });
