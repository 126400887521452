import { classificationGroupsNames } from '@dat/core/constants';
import { TFunction } from 'react-i18next';

export const getTranslationForInputLabel = (text: string, t: TFunction) => {
    switch (text) {
        case classificationGroupsNames[1]:
            return t('selectsLabels.engine');

        case classificationGroupsNames[2]:
            return t('selectsLabels.bodywork');

        case classificationGroupsNames[4]:
            return t('selectsLabels.typeOfDrive');

        case classificationGroupsNames[5]:
            return t('selectsLabels.driversCab');

        case classificationGroupsNames[10]:
            return t('selectsLabels.equipmentLine');

        case classificationGroupsNames[7]:
            return t('selectsLabels.model');

        case classificationGroupsNames[9]:
            return t('selectsLabels.numberOfAxles');

        case classificationGroupsNames[8]:
            return t('selectsLabels.suspensionType');

        case classificationGroupsNames[6]:
            return t('selectsLabels.vehicleWeight');

        case classificationGroupsNames[11]:
            return t('selectsLabels.transmission');

        case classificationGroupsNames[3]:
            return t('selectsLabels.wheelbase');
    }
};
