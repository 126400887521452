import React, { useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Dropdown, GalleryIcon, MailIcon, PluginMenu, Text } from '@wedat/ui-kit';

import { HideBlock } from '../HideBlock';
import { SchedulerMenu } from '../SchedulerMenu';

import { AssignPartner } from '@dat/assign-partner';
import { Printout } from '@dat/printout';

import { sharedGalleryEvents } from '@dat/shared-models/gallery';
import { sharedMailEvents } from '@dat/shared-models/mail';
import { containedPluginsStores } from '../../stores/containedPlugins';
import { contractStores } from '@dat/shared-models/contract';

import { PluginLink } from '../ClaimHeader/styles';

import { PluginDropdownWrapper, DropdownContainer, ButtonStyled, Item, TitleWrapper } from './styles';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';

export const PluginDropdown = () => {
    const [isListVisible, setIsListVisible] = useState(false);

    const clickRef = useRef(null);

    const [isAssignPartnerOpen, setIsAssignPartnerOpen] = useState(false);
    const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
    const [isPrintoutOpen, setIsPrintoutOpen] = useState(false);

    const printoutPluginOptions = useStore(containedPluginsStores.printoutPluginOptions);
    const assignPartnerOptions = useStore(containedPluginsStores.assignPartnerPluginoptions);
    const isContractReceived = useStore(contractStores.isContractReceived);
    const contractId = useStore(contractStores.contractId);

    const { t } = useTranslation();

    useClickOutside(clickRef, () => {
        setIsListVisible(false);
        setIsAssignPartnerOpen(false);
        setIsSchedulerOpen(false);
    });

    return (
        <PluginDropdownWrapper ref={clickRef}>
            <ButtonStyled>
                <PluginMenu ref={clickRef} onClick={() => setIsListVisible(!isListVisible)} />
                <DropdownContainer>
                    <Dropdown isOpen={isListVisible} side="left">
                        <HideBlock sidebarBlock="assign-partner">
                            <Item onClick={() => setIsAssignPartnerOpen(!isAssignPartnerOpen)}>
                                <PluginLink>
                                    <AssignPartner
                                        options={{
                                            ...assignPartnerOptions,
                                            isHeader: true,
                                            isOpen: isAssignPartnerOpen
                                        }}
                                    />
                                </PluginLink>
                                <TitleWrapper>
                                    <Text fontSize="14px">{t('header.assignPartner')}</Text>
                                </TitleWrapper>
                            </Item>
                        </HideBlock>
                        <HideBlock sidebarBlock="scheduler">
                            <Item onClick={() => setIsSchedulerOpen(!isSchedulerOpen)}>
                                <PluginLink>
                                    <SchedulerMenu isOpen={isSchedulerOpen} side="left" />
                                </PluginLink>
                                <TitleWrapper>
                                    <Text fontSize="14px">{t('header.scheduler')}</Text>
                                </TitleWrapper>
                            </Item>
                        </HideBlock>
                        <HideBlock sidebarBlock="printout">
                            <Item onClick={() => setIsPrintoutOpen(!isPrintoutOpen)}>
                                <PluginLink>
                                    <Printout
                                        options={{
                                            ...printoutPluginOptions,
                                            displayTitle: false,
                                            isOpen: isPrintoutOpen
                                        }}
                                    />
                                </PluginLink>
                                <TitleWrapper>
                                    <Text fontSize="14px">{t('header.printout')}</Text>
                                </TitleWrapper>
                            </Item>
                        </HideBlock>
                        <HideBlock sidebarBlock="gallery">
                            <Item
                                onClick={() => {
                                    sharedGalleryEvents.setSelectedContractId(contractId);
                                }}
                            >
                                <PluginLink disabled={!isContractReceived}>
                                    <GalleryIcon />
                                </PluginLink>
                                <TitleWrapper>
                                    <Text fontSize="14px">{t('header.attachment')}</Text>
                                </TitleWrapper>
                            </Item>
                        </HideBlock>
                        <HideBlock sidebarBlock="mail">
                            <Item onClick={() => sharedMailEvents.toggleIsMailOpen()}>
                                <PluginLink>
                                    <MailIcon />
                                </PluginLink>
                                <TitleWrapper>
                                    <Text fontSize="14px">{t('header.mail')}</Text>
                                </TitleWrapper>
                            </Item>
                        </HideBlock>
                    </Dropdown>
                </DropdownContainer>
            </ButtonStyled>
        </PluginDropdownWrapper>
    );
};
