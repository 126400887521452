import * as Yup from 'yup';
import { endOfYesterday } from 'date-fns';
import { DISABLED_HOURS } from '@dat/shared-models/scheduler/constants';
import { SchedulerEvent } from '../types';

interface SelectedDates {
    start: Date;
    end: Date;
}

export const newAppointmentFieldValues = (selectedDates: SelectedDates, selectedEvent: SchedulerEvent | null) => ({
    date: selectedDates.start,
    startTime: selectedDates.start,
    endTime: selectedDates.end,
    description: selectedEvent?.description || '',
    address: selectedEvent?.address || '',
    city: selectedEvent?.city || '',
    zip: selectedEvent?.zip || '',
    assignee: selectedEvent?.assignee || 0,
    createdBy: selectedEvent?.createdBy || 0
});

export const NEW_APPOINTMENT_SCHEMA = Yup.object({
    date: Yup.date().label('date').required('Required'),
    startTime: Yup.date()
        .label('endTime')
        .required('Required')
        .test('start-time', 'Please select working hours', (value): boolean => {
            if (value) {
                return !DISABLED_HOURS.includes(value.getHours());
            }

            return false;
        }),
    endTime: Yup.date()
        .label('endTime')
        .required('Required')
        .min(Yup.ref('startTime'), 'End time cannot be less than start time')
        .test('end-time', 'Please select working hours', (value): boolean => {
            if (value) {
                return !DISABLED_HOURS.includes(value.getHours());
            }

            return false;
        })
        .test('end-time', 'End time should be greater than start time', function (this, value): boolean {
            if (value) {
                const startTime = this.parent.startTime.getTime();
                const endTime = value.getTime();
                return startTime !== endTime;
            }

            return false;
        })
});

/**
 * Expiry Claim form fields and validation schema
 */
export const newExpiryClaimFieldValues = (selectedDates: SelectedDates, selectedEvent: SchedulerEvent | null) => ({
    expiryDate: selectedDates.start,
    description: selectedEvent?.description || '',
    address: selectedEvent?.address || '',
    city: selectedEvent?.city || '',
    zip: selectedEvent?.zip || '',
    assignee: selectedEvent?.assignee || 0,
    createdBy: selectedEvent?.createdBy || 0
});

export const NEW_EXPIRY_CLAIM_SCHEMA = Yup.object({
    expiryDate: Yup.date()
        .label('expiryDate')
        .required('Required')
        .min(endOfYesterday(), 'Expiration Date cannot be in the past')
});
