import React from 'react';
import { Route, RouteProps, useRouteMatch } from 'react-router-dom';

import { Hideable } from '@wedat/ui-kit/components/Hideable';

/* This Route is always mounted, but hidden if path doesn't match the current route */
export const AlwaysAliveRoute: React.FC<RouteProps> = props => {
    const match = useRouteMatch(props);

    return (
        <Hideable hidden={!match}>
            <Route {...props} path="/" />
        </Hideable>
    );
};
