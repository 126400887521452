import { Text } from '@wedat/ui-kit/components/Text';
import styled from 'styled-components/macro';

export const Content = styled.div`
    flex: 1 1 auto;
`;

export const Title = styled(Text)`
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
`;

export const ButtonWrap = styled.div`
    margin: 10px;
`;
