import React, { FC } from 'react';
import { useField } from 'formik';

import { Input } from '../../components/Input';
import { InputProps } from '../../components/Input/types';

export interface InputFieldProps extends InputProps {
    name: string;
}

export const InputField: FC<InputFieldProps> = ({ name, ...rest }) => {
    const [field, meta] = useField(name);
    const joinedProps = { ...rest, ...field };

    const isError = Boolean(meta.error) && meta.touched;

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        field.onBlur(e);
        rest.onBlur?.(e);
    };

    return <Input {...joinedProps} status={isError ? 'error' : 'default'} onBlur={handleBlur} />;
};
