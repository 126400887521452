import { FC, ReactEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoScanIcon } from '@wedat/ui-kit/components/Icons';
import { Button, TextStyled } from './styles';

interface Props {
    onClick: ReactEventHandler<HTMLButtonElement>;
    className?: string;
    disabled?: boolean;
}

export const PhotoButton: FC<Props> = ({ onClick, className, disabled }) => {
    const { t } = useTranslation();

    return (
        <Button className={className} onClick={onClick} disabled={disabled}>
            <PhotoScanIcon />
            <TextStyled font="note" textTransform="uppercase">
                {t('capture')}
            </TextStyled>
        </Button>
    );
};
