import { getSparePartVariants } from '@dat/api2dat/services/vehicleRepairOnlineService';
import { Credentials, ResultGetSparePartVariants } from '@dat/api2dat/types/api2datTypes';
import { attach, combine, createEffect, createEvent, createStore } from 'effector';
import { DatLocalesParams, pluginOptionsModel } from './pluginOptionsModel';
import { requestCredentialsModel } from './requestCredentialsModel';

const sparePartVariants = createStore<ResultGetSparePartVariants[]>([]);
const setSparePartVariants = createEvent();
sparePartVariants.on(setSparePartVariants, (_oldValue, newVal) => newVal);

// if datECode change or Locale change then clear the cache
sparePartVariants.reset(
    combine({
        modelDatECodeWithAlternative: pluginOptionsModel.stores.modelDatECodeWithAlternative,
        localesParams: pluginOptionsModel.stores.localesParams,
        requestCredentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls
    })
);

const getSparePartVariantsFx = createEffect(getSparePartVariants);

sparePartVariants.on(getSparePartVariantsFx.done, (oldValue, { result }) => {
    if (!result) return;

    const newVal = [...oldValue];

    const foundIndex = newVal.findIndex(item => item.datProcessId === result?.datProcessId);

    if (foundIndex !== -1) {
        newVal[foundIndex] = result;
    } else {
        newVal.push(result);
    }
    return newVal;
});

const defaultLocale = {
    country: 'US',
    datCountryIndicator: 'DE',
    language: 'en'
};

const getSparePartVariantsByDvnsFx = attach({
    source: {
        sparePartVariants,
        modelDatECodeWithAlternative: pluginOptionsModel.stores.modelDatECodeWithAlternative,
        localesParams: pluginOptionsModel.stores.localesParams,
        requestCredentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls
    },
    mapParams: (
        dvns: number[],
        { sparePartVariants, modelDatECodeWithAlternative, localesParams, requestCredentials, datServicesUrls }
    ) => ({
        sparePartVariants,
        dvns,
        modelDatECodeWithAlternative,
        localesParams,
        credentials: requestCredentials,
        vehicleRepairOnlineServiceUrl: datServicesUrls.vehicleRepairOnlineServiceUrl
    }),
    effect: createEffect(
        async ({
            sparePartVariants,
            dvns,
            modelDatECodeWithAlternative,
            localesParams,
            credentials,
            vehicleRepairOnlineServiceUrl
        }: {
            sparePartVariants: ResultGetSparePartVariants[];
            dvns: number[];
            modelDatECodeWithAlternative: string | undefined;
            localesParams: DatLocalesParams | undefined;
            credentials: Credentials;
            vehicleRepairOnlineServiceUrl: string | undefined;
        }) => {
            if (!modelDatECodeWithAlternative || !localesParams) return;

            const result = await Promise.all(
                dvns
                    .filter(dvn => !sparePartVariants.find(item => item.datProcessId === dvn))
                    .map(dvn =>
                        getSparePartVariantsFx({
                            params: {
                                locale: { attr: localesParams?.locale || defaultLocale },
                                datECode: modelDatECodeWithAlternative,
                                datProcessId: dvn
                            },
                            credentials,
                            url: vehicleRepairOnlineServiceUrl
                        })
                    )
            );
            return result;
        }
    )
});

export const sparePartVariantsModel = {
    stores: {
        sparePartVariants
    },
    events: {},
    effects: {
        getSparePartVariantsFx,
        getSparePartVariantsByDvnsFx
    }
};
