import { sample } from 'effector';

import { pluginEvents } from '../plugin';
import { attachmentsEffects, attachmentsEvents, attachmentsStores } from './index';
import { parseAttachmentItem } from '@dat/core/utils/attachments/parseAttachmentItem';
import { convertFilesToAttachments } from '@dat/core/utils/attachments/convertFilesToAttachments';

import { ParsedAttachmentItem, PayloadForChangeAttachmentStatus } from '../../types/attachments';
import { sharedAttachmentsEffects } from '@dat/shared-models/contract/Attachments';
import { getFilenameWithoutExt } from '@dat/shared-models/contract/Attachments/utils/getFilenameWithoutExt';

const { addNewAttachment, changeAttachmentStatus, uploadAttachmentByFolderIdToCurrentContract, filesInGroupSelected } =
    attachmentsEvents;
const { listAttachmentsOfContractFx, uploadAttachmentByFolderIdFx, uploadAttachmentsToGroupFx } = attachmentsEffects;

attachmentsStores.contractId.on(attachmentsEvents.setContractId, (_, contractId) => contractId);

listAttachmentsOfContractFx.use(sharedAttachmentsEffects.listAttachmentsOfContractFx);
uploadAttachmentByFolderIdFx.use(sharedAttachmentsEffects.uploadSingleAttachmentFx);

sample({
    clock: uploadAttachmentByFolderIdToCurrentContract,
    source: pluginEvents.initPlugin,
    fn: ({ contractId }, attachmentItem) => ({
        contractId,
        attachmentItem
    }),
    target: uploadAttachmentByFolderIdFx
});

sample({
    source: uploadAttachmentByFolderIdFx,
    fn: ({ attachmentItem }): ParsedAttachmentItem => ({ ...parseAttachmentItem(attachmentItem), status: 'uploading' }),
    target: addNewAttachment
});

sample({
    source: uploadAttachmentByFolderIdFx.done,
    fn: ({ params }): PayloadForChangeAttachmentStatus => ({
        name: params.attachmentItem.fileName,
        status: 'uploaded'
    }),
    target: changeAttachmentStatus
});

sample({
    source: uploadAttachmentByFolderIdFx.fail,
    fn: ({ params }): PayloadForChangeAttachmentStatus => ({
        name: params.attachmentItem.fileName,
        status: 'uploading-failed'
    }),
    target: changeAttachmentStatus
});

filesInGroupSelected.watch(async ({ groupId, files }) => {
    const attachments = await convertFilesToAttachments({ files, groupId });

    uploadAttachmentsToGroupFx({ groupId, attachments });
});

attachmentsStores.attachments.on(
    sharedAttachmentsEffects.deleteAttachmentFx.done.map(({ params }) => params),
    (currentAttachments, { filename: filenameToRemove }) => {
        const filenameToRemoveWithoutExt = getFilenameWithoutExt(filenameToRemove);
        return currentAttachments.filter(
            ({ fileName }) => getFilenameWithoutExt(fileName) !== filenameToRemoveWithoutExt
        );
    }
);
