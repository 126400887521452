import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Table } from '@wedat/ui-kit/components/Table';
import { Text } from '@wedat/ui-kit/components/Text';

import { modalsEvents, modalsStores } from '../../stores/ui/modals';
import { Buttons, ButtonStyled, Description, TableWrapper, Title, UnderlineText } from './styles';

export const ModalInfo: React.FC = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isModalInfoOpen);

    const columns = useMemo(
        () => [
            {
                Header: t('common.rc'),
                accessor: 'col1'
            },
            {
                Header: t('common.dvn'),
                accessor: 'col2'
            },
            {
                Header: t('common.designation'),
                accessor: 'col3'
            },
            {
                Header: t('common.rc'),
                accessor: 'col4'
            },
            {
                Header: t('common.dvn'),
                accessor: 'col5'
            },
            {
                Header: t('common.logic'),
                accessor: 'col6'
            }
        ],
        [t]
    );

    const data = useMemo(
        () => [
            {
                col1: <Text fontWeight={400}>A</Text>,
                col2: <UnderlineText>43711</UnderlineText>,
                col3: <Text fontWeight={400}>FENDER FRT LH</Text>,
                col4: <Text fontWeight={400}>E</Text>,
                col5: <UnderlineText>43711</UnderlineText>,
                col6: <Text fontWeight={400}>Work logic</Text>
            },
            {
                col1: <Text fontWeight={400}>A</Text>,
                col2: <UnderlineText>43711</UnderlineText>,
                col3: <Text fontWeight={400}>FENDER FRT LH</Text>,
                col4: <Text fontWeight={400}>E</Text>,
                col5: <UnderlineText>43711</UnderlineText>,
                col6: <Text fontWeight={400}>Work logic</Text>
            },
            {
                col1: <Text fontWeight={400}>A</Text>,
                col2: <UnderlineText>43711</UnderlineText>,
                col3: <Text fontWeight={400}>FENDER FRT LH</Text>,
                col4: <Text fontWeight={400}>E</Text>,
                col5: <UnderlineText>43711</UnderlineText>,
                col6: <Text fontWeight={400}>Work logic</Text>
            }
        ],
        []
    );

    return (
        <ModalPrimary
            maxWidth="943px"
            bodyHeight="auto"
            fullWidth
            isOpen={isOpen}
            title={t('modalInfo.title')}
            onDismiss={modalsEvents.toggleIsModalInfoOpen}
            footerChildren={
                <Buttons>
                    <ButtonStyled onClick={modalsEvents.toggleIsModalInfoOpen} uppercase>
                        {t('common.ok')}
                    </ButtonStyled>
                </Buttons>
            }
        >
            <Title font="defaultMedium" asTag="h3">
                {t('modalInfo.infoTitle')}
            </Title>
            <TableWrapper>
                <Table columns={columns} data={data} mobileHeaders />
            </TableWrapper>
            <Description font="note">{t('modalInfo.descriptionVin')}</Description>
            <Description font="note">{t('modalInfo.descriptionNumber')}</Description>
        </ModalPrimary>
    );
};
