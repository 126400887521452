import styled, { css } from 'styled-components';
import { Text } from '../../Text';
import { DrawerItemProps } from '../types';

type ContainerProps = Pick<
    DrawerItemProps,
    'position' | 'width' | 'isOpen' | 'offset' | 'zIndex' | 'fullScreen' | 'halfScreen'
>;
type HeaderProps = Pick<DrawerItemProps, 'position'>;

const fullScreenStyle = css<ContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    min-width: 100vw;
    height: calc(var(--dat-vh, 1vh) * 100);

    ${({ isOpen, position }) =>
        !isOpen &&
        css`
            transform: translateX(${position === 'left' ? '-100%' : '100%'});
        `}
`;

const defaultStyle = css<ContainerProps>`
    width: ${({ width }) => `${width}px`};
    min-width: ${({ width }) => `${width}px`};
    transform: ${({ position, offset }) =>
        offset && (position === 'left' ? `translateX(-${offset}px)` : `translateX(${offset}px)`)};
    z-index: ${({ zIndex }) => zIndex};
    box-shadow: 0px 8px 56px rgba(174, 174, 192, 0.6), 0px -8px 56px ${({ theme }) => theme.colors.white};

    ${({ isOpen, position, offset }) =>
        !isOpen &&
        css`
            transform: translateX(${position === 'left' ? `calc(-100% - ${offset}px)` : `calc(100% + ${offset}px)`});
            box-shadow: none;
        `}
`;

const halfScreenStyle = css<ContainerProps>`
    ${defaultStyle};
    width: 50vw;
    min-width: 50vw;
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.white};
    transition: transform 0.3s, box-shadow 0.3s;

    ${({ halfScreen, fullScreen }) => {
        const halfOrDefaultScreenStyle = halfScreen ? halfScreenStyle : defaultStyle;
        return fullScreen ? fullScreenStyle : halfOrDefaultScreenStyle;
    }}
`;

export const Header = styled.div<HeaderProps>`
    display: flex;
    flex-direction: ${({ position }) => (position === 'right' ? 'row' : 'row-reverse')};
    align-items: center;
    padding: 32px 24px;
`;

export const CloseButton = styled.div<HeaderProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 8px;
    margin-right: ${({ position }) => position === 'right' && '20px'};
    cursor: pointer;

    &:focus-visible,
    &:focus,
    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.dustBlue['400']};
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }

    > svg {
        transform: rotate(${({ position }) => (position === 'right' ? '90deg' : '-90deg')});
    }
`;

export const Title = styled(Text)<HeaderProps>`
    margin-right: ${({ position }) => position === 'left' && '20px'};
`;

export const Content = styled.div`
    flex: 1;
    overflow: auto;
    height: 100%;
`;
