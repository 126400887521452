export interface PreviewGroup {
    label: string;
    fields: PreviewField[];
}

export interface PreviewField {
    label: string;
    fieldName: string;
    editable: boolean;
    type?: PreviewFieldType;
    value?: string;
}

export enum PreviewFieldType {
    STRING = 'string',
    DATE = 'date'
}

export enum PreviewTabs {
    General = 'General',
    History = 'History',
    Comment = 'Comment',
    Users = 'Users'
}

export type PluginOptions = DAT2.Plugins.PluginBaseOptions & {
    onContractClick?: (contract: DAT2.ContractFromListContracts) => any; //TODO: [any]
    onCreateContractClick?: () => any;

    settings?: {
        pageLimit?: string;
        importIni?: string;
        efficiency?: {
            enabled: boolean;
            gaugeDisabled?: boolean;
            expireYellowFlag: number;
        };
        preview?: {
            generalSection?: PreviewGroup[];
            commentSection?: {
                fieldName: string;
            };
            hideTabs?: PreviewTabs[];
        };
    };

    styles?: {
        fontSize?: {
            fieldsTitles?: number;
        };
        field?: {
            fontSize?: number;
            color?: string;
            backgroundColor?: string;
        };
        button?: {
            fontSize?: number;
            color?: string;
            backgroundColor?: string;
            backgroundColorHover?: string;
            height?: number;
        };
        form?: {
            borderColor?: string;
            borderWidth?: number;
            borderRadius?: number;
            backgroundColor?: string;
        };
        page?: {
            bgImage?: string;
        };
        icons?: {
            search?: string;
        };
        select?: {
            bgImage?: string;
            colorButton?: string;
        };
        header?: {
            backgroundColor?: string;
        };
    };
};
