import { attach, combine, createEffect, createEvent, createStore, restore, sample, Store } from 'effector';
import { uniq } from 'ramda';
import _ from 'lodash';

import { API2 } from '@dat/api2';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { parseEquipmentResponse } from '../../utils/parseEquipmentResponse';
import { createTransferEquipment } from '../../utils/createTransferEquipment';

import { ParsedEquipment, ParsedEquipmentPosition, PayloadForGetEquipment } from '../../types/equipment';
import { prepareEquipmentForContract } from '@dat/core/utils/prepareEquipmentForContract';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

//
/*** Get and parse equipment ***/
//

const getPossibleEquipmentFx = createEffect({
    handler: async ({ requestData, vinEquipmentIds }: PayloadForGetEquipment) => {
        const response = await cDRH(API2.equipment.getPossibleEquipmentN)(requestData);

        return parseEquipmentResponse(response, vinEquipmentIds);
    }
});

const getExistingEquipmentInVSMFx = createEffect<void, ParsedEquipment>();

getExistingEquipmentInVSMFx.use(
    attach({
        source: sharedVehicleStores.vinResult,
        effect: vinResult => {
            let existingEquipmentForInVSM: ParsedEquipment = [];

            if (vinResult?.equipment) {
                existingEquipmentForInVSM = prepareEquipmentForContract(vinResult.equipment);
            }

            return existingEquipmentForInVSM;
        }
    })
);

const getExistingEquipmentFx = createEffect({
    handler: async ({ requestData, vinEquipmentIds }: PayloadForGetEquipment) => {
        const response = await cDRH(API2.equipment.getExistingEquipmentN)(requestData);

        return parseEquipmentResponse(response, vinEquipmentIds);
    }
});

const existingEquipmentStore = restore(getExistingEquipmentFx.doneData, []);
const existingEquipmentInVSMStore = restore(getExistingEquipmentInVSMFx.doneData, []);
const setIsInVSM = createEvent<boolean>();
const isInVSM = createStore<boolean>(false).on(setIsInVSM, (_state, payload) => payload);

const getAllEquipmentFx = createNotifyingEffect({
    handler: async (data: PayloadForGetEquipment) => {
        getExistingEquipmentInVSMFx();
        setIsInVSM(!!data.inVSM);

        const [possibleEquipment, existingEquipment] = await Promise.all([
            getPossibleEquipmentFx(data),
            getExistingEquipmentFx(data)
        ]);

        return [...possibleEquipment, ...existingEquipment];
    }
});
const allEquipment = restore<ParsedEquipment>(getAllEquipmentFx, []);

//
/*** Transfer data ***/
//
const equipmentMoved = createEvent<string[]>();
const existingTransferEquipmentIds = restore(
    equipmentMoved.map(ids => ids.map(id => +id)),
    []
);
const newExistingTransferEquipmentIdsReceived = sample({
    clock: getAllEquipmentFx.doneData,
    source: { existingEquipmentStore, existingEquipmentInVSMStore, isInVSM },
    fn: ({ existingEquipmentStore, existingEquipmentInVSMStore, isInVSM }, _allEquipment) => {
        const existingEquipmentIds = existingEquipmentStore.map(({ DatEquipmentId }) => DatEquipmentId);
        const existingEquipmentInVSMStoreIds = existingEquipmentInVSMStore.map(({ DatEquipmentId }) => DatEquipmentId);

        if (isInVSM) {
            return existingEquipmentInVSMStoreIds;
        }

        return existingEquipmentIds;
    }
});

const allTransferEquipment = allEquipment.map(equipment => createTransferEquipment(equipment));
const existingTransferEquipment: Store<ParsedEquipmentPosition[]> = sample({
    source: allEquipment,
    clock: existingTransferEquipmentIds,
    fn: (allEquipment, existingEquipmentIds) =>
        allEquipment.filter(({ DatEquipmentId }) => existingEquipmentIds.includes(DatEquipmentId))
});

const categories = allEquipment.map(allEquipment => {
    const allCategories = allEquipment.map(({ Category }) => Category).filter(category => !!category);

    return uniq(allCategories) as string[];
});

//
/*** Export ***/
//
export const equipmentEvents = {
    equipmentMoved,
    newExistingTransferEquipmentIdsReceived
};
export const equipmentEffects = {
    getAllEquipmentFx,
    getExistingEquipmentFx
};
export const equipmentStores = {
    allEquipment,
    allTransferEquipment,
    existingTransferEquipmentIds,
    existingTransferEquipment,
    categories,
    existingEquipmentStore
};
export const combinedEquipmentStores = combine(equipmentStores);
