import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Wrapper, ButtonStyled } from './styles';
import { pluginCarsEvents, pluginCarsStores } from '../../stores/pluginCars';

export const Buttons: React.FC = () => {
    const { t } = useTranslation();

    const isPluginCarsAvailable = useStore(pluginCarsStores.isPluginCarsAvailable);

    return (
        <Wrapper>
            <ButtonStyled disabled={!isPluginCarsAvailable} onClick={pluginCarsEvents.openPluginCars}>
                {t('guidedPhotoCapture')}
            </ButtonStyled>
        </Wrapper>
    );
};
