import React, { useState } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { schedulerEffects, schedulerStores } from '@dat/shared-models/scheduler';
import { sharedUserStores } from '@dat/shared-models/user';
import { contractStores } from '@dat/shared-models/contract';
import {
    getDescriptionFields,
    getFormattedExpiryClaimISOString,
    getFormattedTimeISOString
} from '@dat/shared-models/scheduler/utils';

import { INITIAL_APPOINTMENT_FORM_VALUES, APPOINTMENT_FORM_SCHEMA } from '../../../constants/appointmentConstants';

import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { Input } from '@wedat/ui-kit/components/Input';
import { Datepicker } from '@dat/smart-components/Datepicker';
import { Timepicker } from '@wedat/ui-kit/components/Timepicker';

import {
    CreateAppointmentPopupStyled,
    DatePickerWrapperStyled,
    DatePickerSectionStyled,
    ButtonStyledWrapper,
    CreateAppointmentPopupContentStyled,
    ExpirationPickerSectionStyled,
    ExpirationPickersStyled,
    ErrorContainer,
    UpperSectionStyled,
    InputSectionStyled,
    DatepickerStyled
} from '../styles';
import { SelectAssignee } from '@dat/scheduler/src/components/SelectAssignee';
import { isAdmin } from '@dat/shared-models/scheduler/constants';

interface Props {
    onClose: () => void;
}

export const CreateAppointment: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();

    const appointments = useStore(schedulerStores.appointments);
    const selectedUserID = useStore(schedulerStores.selectedUser);

    const contractId = useStore(contractStores.contractId);
    const contract = useStore(contractStores.contract);
    const customerNumber = useStore(sharedUserStores.customerNumber);

    const [assigneeId, setAssigneeId] = useState<number | null>(null);
    const [assigneeError, setAssigneeError] = useState(false);

    const isAdminRole = isAdmin(customerNumber);

    const licenseNumber = contract?.Dossier?.Vehicle?.RegistrationData?.LicenseNumber || '';

    const { ownerName, ownerSurname, ownerPhone, ownerEmail } = getDescriptionFields(contract);

    const descriptionPrefix = `${licenseNumber} ${ownerName} ${ownerSurname} ${ownerPhone} ${ownerEmail}`;
    const isDescriptionProvided = !!descriptionPrefix.trim().length;

    const getErrors = (errors: FormikErrors<FormikValues>, assigneeError: boolean) => {
        if (assigneeError) {
            return <Text fontSize="12px">{t('select.assignee')}</Text>;
        }

        if (errors) {
            const errorValue = Object.values(errors)?.[0];

            return <Text fontSize="12px">{errorValue}</Text>;
        }

        return null;
    };

    const handleAssigneeChange = (id: number) => {
        setAssigneeId(id);
    };

    const handleSubmit = async (formValues: FormikValues) => {
        if (!assigneeId && isAdminRole) return setAssigneeError(true);

        const startDate = getFormattedTimeISOString(formValues.startTime);
        const endDate = getFormattedTimeISOString(formValues.endTime);
        const expirationDate = getFormattedExpiryClaimISOString(formValues.expiryDate);

        const newAppointment = {
            contractId: contractId.toString(),
            fromDate: startDate,
            toDate: endDate,
            type: 'personal',
            description: formValues.description,
            registrationNumber: licenseNumber,
            customerInfo: {
                name: ownerName,
                surname: ownerSurname,
                telephone: ownerPhone,
                email: ownerEmail
            },
            address: formValues.address,
            city: formValues.city,
            zip: formValues.zip,
            createdBy: customerNumber,
            assignee: assigneeId || selectedUserID
        };

        const newExpiryClaim = {
            contractId: contractId.toString(),
            date: expirationDate,
            type: 'personal',
            description: formValues.description,
            registrationNumber: licenseNumber,
            customerInfo: {
                name: ownerName,
                surname: ownerSurname,
                telephone: ownerPhone,
                email: ownerEmail
            },
            address: formValues.address,
            city: formValues.city,
            zip: formValues.zip,
            createdBy: customerNumber,
            assignee: assigneeId || selectedUserID
        };

        const isExistingAppointment = appointments.appointmentDates.find(
            appointment => appointment.contractId === newAppointment.contractId
        );

        const updatedAppointments = isExistingAppointment
            ? appointments.appointmentDates.map(appointment =>
                  appointment.contractId === newAppointment.contractId ? newAppointment : appointment
              )
            : [...appointments.appointmentDates, newAppointment];

        const isExistingExpiryClaim = appointments.expiryClaimsDate.find(
            i => i.contractId === newExpiryClaim.contractId
        );

        const updatedExpiryDates = isExistingExpiryClaim
            ? appointments.expiryClaimsDate.map(expiryClaim =>
                  expiryClaim.contractId === newExpiryClaim.contractId ? newExpiryClaim : expiryClaim
              )
            : [...appointments.expiryClaimsDate, newExpiryClaim];

        await schedulerEffects.submitForm({
            updatedAppointments,
            updatedExpiryDates
        });

        return onClose();
    };

    return (
        <Formik
            initialValues={INITIAL_APPOINTMENT_FORM_VALUES}
            validationSchema={APPOINTMENT_FORM_SCHEMA}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue, errors, submitForm }) => (
                <CreateAppointmentPopupStyled isDescriptionProvided={isDescriptionProvided}>
                    <CreateAppointmentPopupContentStyled>
                        <UpperSectionStyled>
                            <Input
                                prefix={isDescriptionProvided ? descriptionPrefix : ''}
                                value={values.description}
                                label={t('description')}
                                onChange={e => setFieldValue('description', e.target.value)}
                            />
                        </UpperSectionStyled>

                        {isAdminRole && <SelectAssignee onChange={handleAssigneeChange} />}

                        <Text font="footnote">{t('date')}</Text>

                        <DatepickerStyled>
                            <Datepicker name="date" onChange={val => setFieldValue('date', val)} value={values.date} />
                        </DatepickerStyled>

                        <DatePickerWrapperStyled>
                            <DatePickerSectionStyled>
                                <Text font="footnote">{t('start.time')}</Text>

                                <Timepicker
                                    name="startTime"
                                    onChange={val => setFieldValue('startTime', val)}
                                    value={values.startTime}
                                />
                            </DatePickerSectionStyled>

                            <DatePickerSectionStyled>
                                <Text font="footnote">{t('end.time')}</Text>

                                <Timepicker
                                    name="endTime"
                                    onChange={val => setFieldValue('endTime', val)}
                                    value={values.endTime}
                                />
                            </DatePickerSectionStyled>
                        </DatePickerWrapperStyled>

                        <ExpirationPickerSectionStyled>
                            <Text font="footnote">{t('expiryClaim.date')}</Text>

                            <ExpirationPickersStyled>
                                <DatepickerStyled>
                                    <Datepicker
                                        name="expiryDate"
                                        onChange={val => setFieldValue('expiryDate', val)}
                                        value={values.expiryDate}
                                        popperPlacement="top-start"
                                    />
                                </DatepickerStyled>
                            </ExpirationPickersStyled>
                        </ExpirationPickerSectionStyled>

                        <InputSectionStyled>
                            <Input
                                value={values.address}
                                label={t('address')}
                                onChange={e => setFieldValue('address', e.target.value)}
                            />
                        </InputSectionStyled>
                        <InputSectionStyled>
                            <Input
                                value={values.city}
                                label={t('city')}
                                onChange={e => setFieldValue('city', e.target.value)}
                            />
                        </InputSectionStyled>
                        <InputSectionStyled>
                            <Input
                                value={values.zip}
                                label={t('zip')}
                                onChange={e => setFieldValue('zip', e.target.value)}
                            />
                        </InputSectionStyled>

                        <ErrorContainer>{getErrors(errors, assigneeError)}</ErrorContainer>
                    </CreateAppointmentPopupContentStyled>

                    <ButtonStyledWrapper>
                        <Button disabled={!!Object.keys(errors).length} onClick={submitForm}>
                            {t('create.new')}
                        </Button>
                    </ButtonStyledWrapper>
                </CreateAppointmentPopupStyled>
            )}
        />
    );
};
