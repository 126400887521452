import React from 'react';
import { useStore } from 'effector-react';

import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';

interface Props {
    options?: PluginOptions;
}

const App: React.FC<Props> = ({ options = {} }) => {
    const isLoading = useStore(commonStores.isLoading);

    return (
        <PluginProvider
            name="italian-calculation"
            options={options}
            onInit={pluginEvents.initPlugin}
            isLoading={isLoading}
        >
            <Main />
        </PluginProvider>
    );
};

export default App;
