import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { TFunction } from 'react-i18next';
import { AvailableAssemblyGroup, DamageElementGroup } from '../types/graphicTypes';

import { RepairPositionWithRowKey } from '../stores/repairPositionsModel';

export const getArrayAvailableLacquer = (t: TFunction, lacquerMethod?: string, materialType?: number) => {
    if (lacquerMethod !== 'AZT' && lacquerMethod !== 'EURO_LACQUER')
        return [
            // default
            { key: 1, LacquerLevel: 'none', LacquerLevelId: 0, description: t('avaliableLaquer.none') },
            { key: 2, LacquerLevel: 'new', LacquerLevelId: 4, description: t('avaliableLaquer.new') },
            { key: 3, LacquerLevel: 'surface', LacquerLevelId: 1, description: t('avaliableLaquer.surface') },
            { key: 4, LacquerLevel: 'major', LacquerLevelId: 3, description: t('avaliableLaquer.major') },
            { key: 5, LacquerLevel: 'minor', LacquerLevelId: 2, description: t('avaliableLaquer.minor') }
        ];

    // 0 = plastic part, 4 = plastic part without text
    return materialType === 0 || materialType === 4
        ? [
              // Plastic part
              { key: 1, LacquerLevel: 'none', LacquerLevelId: 0, description: t('avaliableLaquer.none') },
              {
                  key: 2,
                  LacquerLevel: 'surface',
                  LacquerLevelId: 1,
                  description: t(`avaliableLaquer.${lacquerMethod}.plastic.surface`)
              },
              {
                  key: 3,
                  LacquerLevel: 'minor',
                  LacquerLevelId: 2,
                  description: t(`avaliableLaquer.${lacquerMethod}.plastic.repair`)
              },
              {
                  key: 4,
                  LacquerLevel: 'major',
                  LacquerLevelId: 3,
                  description: t(`avaliableLaquer.${lacquerMethod}.plastic.newLevel3`)
              },
              {
                  key: 5,
                  LacquerLevel: 'new',
                  LacquerLevelId: 4,
                  description: t(`avaliableLaquer.${lacquerMethod}.plastic.newLevel4`)
              },
              {
                  key: 6,
                  LacquerLevel: 'plastic',
                  LacquerLevelId: 5,
                  description: t(`avaliableLaquer.${lacquerMethod}.plastic.newLevel5`)
              }
          ]
        : [
              // Metal part
              {
                  key: 1,
                  LacquerLevel: 'none',
                  LacquerLevelId: 0,
                  description: t('avaliableLaquer.none')
              },
              {
                  key: 2,
                  LacquerLevel: 'new',
                  LacquerLevelId: 4,
                  description: t(`avaliableLaquer.${lacquerMethod}.metal.new`)
              },
              {
                  key: 3,
                  LacquerLevel: 'surface',
                  LacquerLevelId: 1,
                  description: t(`avaliableLaquer.${lacquerMethod}.metal.surface`)
              },
              {
                  key: 4,
                  LacquerLevel: 'minor',
                  LacquerLevelId: 2,
                  description: t(`avaliableLaquer.${lacquerMethod}.metal.minor`)
              },
              {
                  key: 5,
                  LacquerLevel: 'major',
                  LacquerLevelId: 3,
                  description: t(`avaliableLaquer.${lacquerMethod}.metal.major`)
              }
          ];
};

export function getLacquerDescription({ LacquerLevel, LacquerLevelId }: RepairPosition, t: TFunction) {
    const arrayAvailableLacquer = getArrayAvailableLacquer(t);
    const foundLaquer = arrayAvailableLacquer.find(item => item.LacquerLevelId === LacquerLevelId);

    if (foundLaquer) return foundLaquer.description;

    return `${LacquerLevel} ${LacquerLevelId}`;
}

export function convertRepairPositionsToDamageList(
    repairPositions: RepairPositionWithRowKey[],
    availableAssemblyGroups: AvailableAssemblyGroup[] | undefined
): DamageElementGroup[] {
    const damageList: DamageElementGroup[] = [];

    repairPositions.forEach(element => {
        const indexFoundRecord = damageList.findIndex(item =>
            !!element.DATProcessId
                ? item.DATProcessId === element.DATProcessId
                : element.Description && item.Description === element.Description
        );

        const foundRecord =
            indexFoundRecord >= 0
                ? damageList[indexFoundRecord]
                : {
                      DATProcessId: element.DATProcessId,
                      Description: element.Description,
                      leftRight: '',
                      repairPositions: []
                  };

        foundRecord.repairPositions.push(element);

        if (indexFoundRecord < 0) {
            damageList.push(foundRecord);
        }

        if (element.RepairType === 'replace') {
            foundRecord.WorkTypeE = element;
        } else if (element.RepairType === 'dis- and mounting') {
            foundRecord.WorkTypeA = element;
        } else if (element.RepairType === 'overhaul' || element.RepairType === 'fixing') {
            foundRecord.WorkTypeI = element;
        } else if (element.RepairType === 'lacquer' || element.RepairType === 'lacquer demounted') {
            foundRecord.WorkTypeL = element;
        }

        if (element.SparePartNumber) {
            foundRecord.SparePartNumber = element.SparePartNumber;
        }
        if (element.SparePartDiscount) {
            foundRecord.SparePartDiscount = element.SparePartDiscount;
        }
        if (element.SparePartPrice) {
            foundRecord.SparePartPrice = element.SparePartPrice;
        }
    });

    if (availableAssemblyGroups) {
        damageList.forEach(damageListItem => {
            damageListItem.leftRight = getLeftOrRightSide(availableAssemblyGroups, damageListItem.DATProcessId);
        });
    }

    return damageList;
}

export function getLeftOrRightSide(availableAssemblyGroups: AvailableAssemblyGroup[], dvn: number) {
    for (let i = 0; i < availableAssemblyGroups.length; i += 1) {
        const { objectsInfo } = availableAssemblyGroups[i];
        if (objectsInfo)
            for (let k = 0; k < objectsInfo.length; k += 1) {
                const obj = objectsInfo[k];
                if (obj.dvnLeft && obj.dvnLeft.dvn === dvn) {
                    if (obj.dvnRight) return 'L';
                }
                if (obj.dvnRight && obj.dvnRight.dvn === dvn) {
                    if (obj.dvnLeft) return 'R';
                }
            }
    }
    return '';
}
