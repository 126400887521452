import { FormBuilderPluginOptions } from '@dat/form-builder';

export const defaultFormBuilderData: FormBuilderPluginOptions['data'] = [
    {
        formName: 'defaultForm'
    },
    {
        groups: [
            {
                groupName: '',
                content: {
                    rows: [
                        {
                            name: 'firstRow',
                            fields: [
                                {
                                    Type: 'string',
                                    id: 'referenceNumber',
                                    label: 'ORDER NR.',
                                    visible: true
                                },
                                {
                                    Type: 'string',
                                    id: 'createOrderDate',
                                    label: 'CREATION DATE',
                                    visible: true,
                                    readOnly: true
                                }
                            ]
                        },
                        {
                            name: 'secondRow',
                            fields: [
                                {
                                    Type: 'string',
                                    id: 'jobNumber',
                                    label: 'DESCRIPTION',
                                    visible: true
                                },
                                {
                                    Type: 'string',
                                    id: 'calculationsettings_country',
                                    label: 'COUNTRY',
                                    visible: true,
                                    readOnly: true
                                }
                            ]
                        },
                        {
                            name: 'thirdRow',
                            fields: [
                                {
                                    Type: 'string',
                                    id: 'cboContactPerson',
                                    label: 'REFERENT',
                                    visible: true
                                },
                                {
                                    Type: 'string',
                                    id: 'repairKind',
                                    label: 'REPAIR KIND',
                                    visible: true
                                }
                            ]
                        },
                        {
                            name: 'fourthRow',
                            fields: [
                                {
                                    Type: 'date',
                                    id: 'enteringDate',
                                    label: 'ACCEPTANCE DATE',
                                    visible: true
                                },
                                {
                                    Type: 'date',
                                    id: 'appointmentDate',
                                    label: 'APPOINTMENT DATE',
                                    visible: true
                                }
                            ]
                        },
                        {
                            name: 'fifthRow',
                            fields: [
                                {
                                    Type: 'date',
                                    id: 'leavingDate',
                                    label: 'DELIVERY DATE',
                                    visible: true
                                },
                                {
                                    Type: 'date',
                                    id: 'invoiceDate',
                                    label: 'INVOICE DATE',
                                    visible: true
                                }
                            ]
                        }
                    ]
                }
            }
        ]
    },
    { conditions: [] }
];
