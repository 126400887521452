import { useState, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { FormBuilder } from '@dat/form-builder';
import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { Calculation } from '@dat/calculation';

import { containedPluginsStores } from '../../../stores/containedPlugins';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { Wrapper, Root, TabsWrapper, FormBuilderWrapper } from './styles';
import { ItalianCalculation } from '@dat/italian-calculation';
import { contractEvents } from '@dat/shared-models/contract';

const { updateInitialContract } = contractEvents;

enum CalculationTabs {
    Calculation = '1',
    FormBuilder = '2'
}

export const CalculationPage: FC = () => {
    const { t } = useTranslation();
    const options = useStore(containedPluginsStores.calculationPluginOptions);
    const isCalculationAvailable = useStore(containedPluginsStores.isCalculationAvailable);
    const formBuilderPluginCalculationOptions = useStore(containedPluginsStores.formBuilderPluginCalculationOptions);

    const [activeTab, setActiveTab] = useState(CalculationTabs.Calculation);
    const isMobile = useMedia(sizes.phone);

    const tabsHandler = (id: SetStateAction<string>) => {
        setActiveTab(id as CalculationTabs);
    };

    const contractType = useStore(sharedTemplateStores.templateSettings.contractType);

    const renderFB = activeTab === CalculationTabs.FormBuilder;

    useEffect(() => {
        if (renderFB) {
            updateInitialContract();
        }
    }, [renderFB]);

    const isItalian = contractType === 'vro_domus_calculation';

    if (!isCalculationAvailable && !isItalian) {
        return null;
    }

    return (
        <Root>
            {formBuilderPluginCalculationOptions.data.length > 0 && (
                <TabsWrapper>
                    <Tabs
                        values={[
                            { id: CalculationTabs.Calculation, label: t('calculateTabs.total') },
                            { id: CalculationTabs.FormBuilder, label: t('calculateTabs.formBuilder') }
                        ]}
                        active={activeTab}
                        onChange={tabsHandler}
                        typeStyle={isMobile ? 'secondary' : 'primary'}
                    />
                </TabsWrapper>
            )}

            {activeTab === CalculationTabs.Calculation && (
                <Wrapper>
                    {isItalian ? <ItalianCalculation options={options} /> : <Calculation options={options} />}
                </Wrapper>
            )}
            {renderFB && (
                <Wrapper>
                    <FormBuilderWrapper>
                        <FormBuilder options={formBuilderPluginCalculationOptions} />
                    </FormBuilderWrapper>
                </Wrapper>
            )}
        </Root>
    );
};
