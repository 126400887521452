import React, { ButtonHTMLAttributes, FC } from 'react';
import { ButtonStyled } from '../Button/styles';
import { TypeStyle } from '../Button';

export interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    /*** To set shape style for button */
    typeStyle?: TypeStyle;

    /*** To set disabled value for button*/
    disabled?: boolean;

    /*** Set this for an button click handler*/
    onClick?: (event?: React.MouseEvent<HTMLButtonElement> | React.PointerEvent<HTMLButtonElement>) => void;
}

//TODO: remove this component and extend main `Button`. no need to split them in two different components
export const ButtonIcon: FC<ButtonIconProps> = ({
    disabled = false,
    typeStyle = { type: 'primary' },
    onClick = () => {},
    children,
    ...rest
}) => (
    <ButtonStyled typeStyle={typeStyle} onClick={onClick} disabled={disabled} {...rest}>
        {children}
    </ButtonStyled>
);
