import styled, { css } from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';
import { SortDown } from '@wedat/ui-kit/assets/Icon';

interface Props {
    isSelected?: boolean;
    withSubRow?: boolean;
    isWithoutContent?: boolean;
    withExpander?: boolean;
    isConflicted?: boolean;
    isDisabled?: boolean;
}

interface StyledButtonIconProps {
    arrowIconDirection: 'left' | 'right';
    disabled: boolean;
}

interface SubRowsProps {
    isWithoutContent?: boolean;
    isLast?: boolean;
}

export const TableContainer = styled.div<Props>`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 600px;
    height: 100%;
    width: 100%;

    @media (max-height: 820px) {
        // ipad air height
        min-height: 500px;
    }

    ${media.laptop`
        background-color: ${({ theme }) => theme.colors.gray['100']};
        padding: 0;
        margin-top: 0;
    `}
`;

export const TableTitle = styled(Text)`
    ${media.laptop`
        display: none;
    `}
`;

export const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;

    ${media.laptop`
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 16px;
    `}
`;

export const TableHeader = styled.thead`
    position: sticky;
    top: 0;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.colors.dustBlue['50']}; // its border for sticky position
    z-index: 10;

    ${media.laptop`
        display: none;
    `}
`;

export const TableHeading = styled.th`
    vertical-align: inherit;
    padding: 12px 0 12px 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    min-width: 60px;
    text-align: start;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    ${({ theme }) => theme.typography.footnote};
`;

export const TableHeadingContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ItemsCount = styled(Text)`
    color: ${({ theme }) => theme.colors.dustBlue['400']};
`;

export const TableWrapper = styled.div`
    position: absolute;
    flex: 1;
    top: 160px;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    ${makeCustomScrollBar()}

    ${media.laptop`
        overflow: visible;
        position: relative;
        top: unset;
        right: unset;
        left: unset;
        bottom: unset;
    `}
`;

export const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;

    ${media.phoneBig`
        table-layout: fixed;
    `}
`;

export const TableBody = styled.tbody`
    ${media.laptop`
        width: 100%;
        display: table-caption;
        background-color: ${({ theme }) => theme.colors.gray['100']};
        padding: 0 14px;
    `}
`;

export const TableCell = styled.td<Props>`
    padding: 8px 10px;
    ${({ theme }) => theme.typography.note}

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}

    &:last-child {
        text-align: end;
    }
`;

export const TableRow = styled.tr<Props>`
    cursor: pointer;
    background-color: ${({ isSelected, isConflicted, theme }) =>
        isSelected ? theme.colors.dustBlue['50'] : isConflicted ? theme.colors.orange['50'] : theme.colors.white};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        `}

    &:hover {
        ${TableCell} {
            color: ${({ theme }) => theme.colors.blueDark};
        }
    }

    ${media.laptop` 
        position: relative;
        display: flex;
        height: 100%;
        min-height: 85px;
        width: 100%;
        margin: 10px 0;
        border-radius: 16px;
        align-items: center;

        & ${TableCell}:nth-child(4) {
            position: absolute;
            right: 0px;
            top: 22px;
        }

        & ${TableCell}:nth-child(5) {
            padding-bottom: 12px;
            padding-right: 54px;
            max-width: 500px;
        }

        & ${TableCell}:last-child {
            position: absolute;
            right: 60px;
        }

       

        ${({ withSubRow }) =>
            withSubRow &&
            css`
                margin-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: none;
            `}
    `}

    ${media.phoneBig`
        position: relative;
        display: block;
        width: 100%;
        margin: 10px 0;
  
        border-radius: 16px;
        border: none;

        ${({ withSubRow }) =>
            withSubRow &&
            css`
                margin-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            `}

        & ${TableCell}:nth-child(1) {
            padding-left: 12px;
            padding-top: 13px;
        }

        & ${TableCell}:nth-child(2) {
            position: absolute;
            left: 104px;
            top: 2px;
        }

        & ${TableCell}:nth-child(3) {
            position: absolute;
            left: 62px;
            top: 9px;
        }

        & ${TableCell}:nth-child(4) {
            position: absolute;
            right: 6px;
            top: 6px;
        }

        & ${TableCell}:nth-child(5) {
            padding-bottom: 12px;
            padding-right: 54px;
        }

        & ${TableCell}:last-child {
            position: absolute;
            bottom: 6px;
            right: 16px;
        }
    `}
`;

export const SubrowCell = styled.td<SubRowsProps>`
    display: ${({ isWithoutContent }) => (isWithoutContent ? 'none' : 'block')};
    padding: 10px 12px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    ${({ theme }) => theme.typography.note}
`;

export const Subrow = styled.tr<SubRowsProps>`
    position: relative;
    display: block;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ isLast }) =>
        isLast &&
        css`
            border-radius: 0 0 16px 16px;
            margin-bottom: 20px;
        `}
`;

export const TransferEquipmentButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledButtonIcon = styled.div<StyledButtonIconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 2px 8px;
    color: ${({ theme, disabled }) => theme.colors.dustBlue[disabled ? '200' : '800']};
    cursor: pointer;

    ${({ arrowIconDirection }) =>
        arrowIconDirection === 'right'
            ? css`
                  svg {
                      transform: rotate(90deg);
                  }
              `
            : css`
                  svg {
                      transform: rotate(-90deg);
                  }
              `}

    ${media.laptop`
        height: 48px;
        width: 93%;
        margin: 10px 0;
    `}
`;

export const TableHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 26px 0;

    ${media.laptop`
        display: none;
    `}
`;

export const TableHeaderInfo = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SortIconsContainer = styled.div`
    display: inline-block;
    margin-left: 10px;
`;

const sortIconStyles = css`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 5px;
`;

export const SortUpIcon = styled(SortDown)`
    transform: rotate(180deg);
    ${sortIconStyles};
`;

export const SortDownIcon = styled(SortDown)`
    ${sortIconStyles}
`;

interface MobileFilterProps {
    opened: boolean;
}

export const MobileFilter = styled.div<MobileFilterProps>`
    display: none;

    ${media.laptop`
        display: flex;
        position: fixed;
        top: 118px; // fixed header height
        right: 0;
        left: 0;
        z-index: 2;
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        flex-direction: column;
        padding: 16px;
        box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;

        ${({ opened }) => !opened && `display: none;`}
    `}
`;

export const SubRowDescriptionCOntainer = styled.div`
    position: relative;
    display: flex;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const Knee = styled.div`
    width: 6px;
    height: 6px;
    border-left: 1px solid ${({ theme }) => theme.colors.dustBlue['600']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['600']};
    margin-top: 10px;
    margin-right: 12px;
`;
