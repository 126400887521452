export const splitDatECode = (datECode: string) => {
    // Unmasked value
    const unmaskedDatECode = datECode.split(' ').join('');

    // Object with masked codes (with zeros)
    const codesObject = {
        vehicleTypeId: unmaskedDatECode.slice(0, 2).padEnd(2, '0'),
        manufacturerId: unmaskedDatECode.slice(2, 5).padEnd(3, '0'),
        baseModelId: unmaskedDatECode.slice(5, 8).padEnd(3, '0'),
        subModelId: unmaskedDatECode.slice(8, 11).padEnd(3, '0'),
        equipmentId: unmaskedDatECode.slice(11, 15)
    };

    const { vehicleTypeId, manufacturerId, baseModelId, subModelId, equipmentId } = codesObject;

    // Masked value (with zeros)
    const maskedDatECode = `${vehicleTypeId} ${manufacturerId} ${baseModelId} ${subModelId} ${equipmentId}`.trim();

    // Unmask codes
    Object.keys(codesObject).forEach(key => {
        type K = keyof typeof codesObject;

        if (key !== 'equipmentId') {
            while (codesObject[key as K][0] === '0') {
                codesObject[key as K] = codesObject[key as K].slice(1);
            }
        }
    });

    return { datECode: unmaskedDatECode, maskedDatECode, ...codesObject };
};
