import { FC } from 'react';
import { LabelProps } from './types';
import { Text } from '../Text';
import { LabelStyled } from './styles';

export const Label: FC<LabelProps> = ({ children, ...props }) => (
    <LabelStyled {...props}>
        <Text font="note" textOverflow="ellipsis" color="inherit">
            {children}
        </Text>
    </LabelStyled>
);
