import { getGeolocationWithDate } from './geolocation';
import { drawGps } from './canvas';
import { isDisableStream } from './plugin';
import { DEVICE_ID } from '../constants/localStorage';

/**
 * Polyfill for mediaDevices.getUserMedia
 */
(function () {
    // @ts-ignore
    if (!navigator.mediaDevices) navigator.mediaDevices = {};
    if (!navigator.mediaDevices.getUserMedia) {
        const getUserMedia =
            // @ts-ignore
            navigator.webkitGetUserMedia ||
            // @ts-ignore
            navigator.mozGetUserMedia ||
            // @ts-ignore
            navigator.msGetUserMedia ||
            // @ts-ignore
            navigator.getUserMedia;

        if (getUserMedia) {
            navigator.mediaDevices.getUserMedia = constraints =>
                new Promise((resolve, reject) => {
                    getUserMedia(
                        constraints,
                        (stream: MediaStream) => {
                            resolve(stream);
                        },
                        (error: any) => {
                            reject(error);
                        }
                    );
                });
        } else {
            navigator.mediaDevices.getUserMedia = () =>
                new Promise((_resolve, reject) => {
                    reject('getUserMedia is not supported in this browser.');
                    alert('WebRTC is not supported in this browser.');
                });
        }
    }
})();

/**
 * Media query constraints for video in portrait mode
 */
const getPortraitConstraints = (deviceId?: MediaTrackConstraintSet['deviceId']): MediaStreamConstraints => ({
    video: {
        facingMode: 'environment',
        width: {
            ideal: 1920
        },
        height: {
            ideal: 1080
        },
        deviceId
    },
    audio: false
});

/**
 * Media query constraints for video in landscape mode
 */
const getLandscapeConstraints = (deviceId?: MediaTrackConstraintSet['deviceId']): MediaStreamConstraints => {
    const sizes = getRootRenderEl()?.getBoundingClientRect();

    return {
        video: {
            facingMode: 'environment',
            width: {
                ideal: 1920
            },
            height: {
                ideal: 1080
            },
            aspectRatio: sizes ? sizes.width / sizes.height : 1920 / 1080,
            deviceId
        },
        audio: false
    };
};

/**
 * Get media support needs HTTPS
 */
export const getUserMedia = (
    isLandscape = false,
    deviceIdParam?: MediaTrackConstraintSet['deviceId']
): Promise<void | MediaStream> => {
    const deviceIdLS: MediaTrackConstraintSet['deviceId'] | null = localStorage.getItem(DEVICE_ID);

    const deviceId = deviceIdParam || deviceIdLS || undefined;

    const constraints = isLandscape ? getLandscapeConstraints(deviceId) : getPortraitConstraints(deviceId);

    if (navigator.mediaDevices) {
        return navigator.mediaDevices.getUserMedia(constraints);
    }

    return Promise.reject();
};

/**
 * Stop media stream
 */
export const turnOffUserMedia = (stream: MediaStream | null) => {
    const isDisable = isDisableStream();

    if (stream && isDisable) {
        stream.getTracks().forEach(track => track.stop());
    }
};

/**
 * Check media device accepted
 */
export const isMediaDeviceAccepted = () =>
    new Promise<undefined>(async (res, rej) => {
        let videoDevice: MediaDeviceInfo | undefined;

        try {
            if (typeof navigator?.mediaDevices?.enumerateDevices !== 'function') return rej();

            const devices = await navigator.mediaDevices.enumerateDevices();

            videoDevice = devices.find(device => device.kind === 'videoinput' && device.deviceId);
        } catch {
            return rej();
        }

        videoDevice ? res(undefined) : rej();
    });

/**
 * Get video stream and apply to video element
 */
export const acceptWebCamToVideo = async (
    isLandscape: boolean,
    video?: HTMLVideoElement,
    deviceId?: MediaTrackConstraintSet['deviceId']
): Promise<MediaStream | null> => {
    try {
        if (!video) return null;

        const stream = await getUserMedia(isLandscape, deviceId);

        if (!stream) return null;
        video.srcObject = stream;

        await video.play();

        // Hacks for Mobile Safari
        video.setAttribute('playsinline', 'true');

        return stream;
    } catch {
        return null;
    }
};

export const getCanvasImage = (canvas: HTMLCanvasElement) => canvas.toDataURL('image/jpeg');

export const captureSample = (video?: HTMLVideoElement | null) => {
    if (!video) return '';
    const canvas = document.createElement('canvas');

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    const ctx = canvas.getContext('2d');

    if (!ctx) return '';

    ctx.drawImage(video, 0, 0, videoWidth, videoHeight, 0, 0, videoWidth, videoHeight);

    return getCanvasImage(canvas);
};

/**
 * Capture screen from video with coords and return base64
 */
export const captureVideo = async (video?: HTMLVideoElement | null, embedGeo = true): Promise<string> => {
    if (!video) return '';

    // check enable geo
    let geo = null;
    try {
        geo = await getGeolocationWithDate();
    } catch {}

    const canvas = document.createElement('canvas');

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    // alert(`videoWidth: ${videoWidth}, videoHeight: ${videoHeight}`);

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    const ctx = canvas.getContext('2d');

    if (!ctx) return '';

    ctx.drawImage(video, 0, 0, videoWidth, videoHeight, 0, 0, videoWidth, videoHeight);

    // write geolocation info to photo
    if (geo && embedGeo) {
        drawGps(canvas, geo);
    }

    return getCanvasImage(canvas);
};

const getRootRenderEl = () => {
    const pluginSelector = window.PLUGIN_API.__options?.selector || '#root';

    return document.querySelector(pluginSelector);
};

/**
 * Get special size for container in portrait mode to correct work on mobile phone
 */
export const getPortraitVideoContainerSize = () => {
    const sizes = getRootRenderEl()?.getBoundingClientRect();

    const width = sizes?.width || window.screen.width;
    const height = sizes?.height || window.screen.height;

    return {
        width: `${width}px`,
        height: `${height}px`
    };
};

/**
 * Get special size for container in landscape mode to correct work on mobile phone
 */
export const getLandscapeVideoContainerSize = () => {
    const sizes = getRootRenderEl()?.getBoundingClientRect();

    const width = sizes?.width || window.innerHeight;
    const height = sizes?.height || window.innerHeight;

    return {
        width: `${width}px`,
        height: `${height}px`
    };
};

/**
 * Get special size for container in landscape mode for comment step
 */
export const getLandscapeVideoContainerSizeForComment = () => {
    const sizes = getRootRenderEl()?.getBoundingClientRect();

    const height = sizes?.height || window.innerHeight;

    return {
        width: `${height / 0.75}px`,
        height: `${height}px`
    };
};
