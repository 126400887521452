import React, { useMemo } from 'react';
import { AddressBook, AddressBookPluginOptions } from '@dat/address-book';
import { DataField } from '@dat/form-builder/src/types/dataScheme';

interface Props {
    field: DataField;
    onOpenModal: () => void;
    onCloseModal: () => void;
}

export const AddressBookField: React.FC<Props> = ({ field, onOpenModal, onCloseModal }) => {
    const options = useMemo<AddressBookPluginOptions>(
        () => ({
            isComponent: true,
            isInbox: true,
            onOpenModal,
            onCloseModal,
            subjectType: field.id.endsWith('_id') ? field.id.slice(0, -3) : field.id,
            icon: field.icon,
            label: field.label,
            assignedId: field.initValue,
            readOnly: field.readOnly,
            showList: field.showList,
            networkOwnerId: field.networkOwnerId
        }),
        [
            field.icon,
            field.id,
            field.initValue,
            field.label,
            field.networkOwnerId,
            field.readOnly,
            field.showList,
            onCloseModal,
            onOpenModal
        ]
    );

    return <AddressBook options={options} />;
};
