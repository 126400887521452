import { myClaimProxy } from './my-claim-rest.ts';

export const getPart = (id: number): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Rate>> =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/GetManualPosition?manualPosition=${id}`,
        method: 'GET'
    });

export const getParts = (): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.PartData>> =>
    myClaimProxy('json', {
        api: 'json',
        bodyType: 'json',
        url: '/partselectionsettings/ListManualPositions?count=50&offset=0&sort-repairCode',
        method: 'GET'
    });

export const editPart = ({
    payload,
    id
}: DAT2.Internal.Request.EditInput): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Rate>> =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/SaveManualPosition`,
        method: 'POST',
        data: { ...payload, id }
    });

export const deletePart = (id: number): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Rate>> =>
    myClaimProxy('json', {
        api: 'json',
        url: `/partselectionsettings/RemoveManualPosition?manualPosition=${id}`,
        method: 'POST'
    });

export const createPart = (
    payload: DAT2.Internal.Rate
): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Rate>> =>
    myClaimProxy('json', {
        api: 'json',
        url: '/partselectionsettings/SaveManualPosition',
        method: 'POST',
        data: { ...payload }
    });
