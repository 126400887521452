import { previewEffects, previewEvents, previewStores } from './index';
import { debounce } from 'patronum';
import { pluginStores } from '../plugin';
import { DEFAULT_COMMENT_FIELD } from './constants';
import { forward, sample } from 'effector';
import { sharedGalleryStores } from '@dat/shared-models/gallery';
import { imagesEffects } from '../images';
import { contractsListEvents } from '../contractsList';
import { capitalize } from 'lodash';

const { templateData, claimWrapper, previewOpened } = previewStores;
const { updateCurrentClaimFx, updateUsersOfClaimFx } = previewEffects;
const { updateComment, changeUser, openPreview } = previewEvents;

debounce({
    source: templateData.updates,
    timeout: 1000,
    target: updateCurrentClaimFx
});

debounce({
    source: updateComment.map(value => ({
        [pluginStores.pluginOptions.getState()?.settings?.preview?.commentSection?.fieldName || DEFAULT_COMMENT_FIELD]:
            value
    })),
    timeout: 1000,
    target: updateCurrentClaimFx
});

forward({
    from: changeUser,
    to: updateUsersOfClaimFx
});

forward({
    from: openPreview,
    to: [previewEffects.loadClaimFx, imagesEffects.getAttachmentFoldersOfClaimFx]
});

sample({
    source: {
        claimWrapper,
        isGalleryModalOpen: sharedGalleryStores.isGalleryModalOpen,
        previewOpened
    },
    clock: sharedGalleryStores.isGalleryModalOpen,
    filter: ({ claimWrapper, isGalleryModalOpen, previewOpened }) =>
        previewOpened && !isGalleryModalOpen && !!claimWrapper,
    fn: ({ claimWrapper }) => ({ claimId: claimWrapper?.claim.id || 0 }),
    target: imagesEffects.getAttachmentFoldersOfClaimFx
});

sample({
    source: {
        claimWrapper,
        templateData,
        pluginOptions: pluginStores.pluginOptions
    },
    fn: ({ claimWrapper, templateData, pluginOptions }) => {
        const data: { [fieldName: string]: string | number } = Object.keys(templateData).reduce((data, key) => {
            const settings = pluginOptions?.settings?.preview?.generalSection?.flatMap(group =>
                group?.fields?.find(field => field.fieldName === key)
            );
            return {
                ...data,
                [`${capitalize(settings?.[0]?.type || 'String')}_${key}`]: templateData[key]
            };
        }, {});
        return {
            contractId: claimWrapper?.claim?.id || 0,
            data
        };
    },
    target: contractsListEvents.refreshTemplateData
});
