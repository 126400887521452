// PreparedManualPositionsList

import React, { FC, useEffect } from 'react';

import { Space, Spin } from 'antd';

import { useStore } from 'effector-react';
// import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { preparedManualPositionsModel } from '../../stores/preparedManualPositionsModel';
import { RepairPositionElementView } from './RepairPositionElementView';
import { convertRepairPositionsToDamageList } from '../../utils/convertRepairPositionsToDamageList';
import { repairTypeByRepairCode } from '../../utils/ftRepairToRepairPosition';

import { ReactComponent as ManualPositionIcon } from '../CommonComponents/Icons/ManualPositionIcon.svg';

export const PreparedManualPositionsList: FC<{}> = () => {
    // const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    const preparedManualPositionsList = useStore(preparedManualPositionsModel.store.preparedManualPositionsList);
    const fetchPreparedManualPositionsListFxPending = useStore(
        preparedManualPositionsModel.effect.fetchPreparedManualPositionsListFx.pending
    );

    useEffect(() => {
        preparedManualPositionsModel.effect.fetchPreparedManualPositionsListFx(undefined);
    }, []);

    return (
        <Spin spinning={fetchPreparedManualPositionsListFxPending}>
            <Space direction="vertical" style={{ marginRight: '4px', width: '100%' }}>
                {preparedManualPositionsList?.map(el => (
                    <RepairPositionElementView
                        key={el.id}
                        manualPositionId={el.id}
                        damageElement={{
                            ...convertRepairPositionsToDamageList(
                                [
                                    {
                                        key: 'prepManPosId_' + el.id,
                                        DATProcessId: 0,
                                        Description: el.description,
                                        RepairType: repairTypeByRepairCode(el.repairCode).repairType,
                                        WorkTime: el.workingTime,
                                        SparePartPrice: el.price,
                                        SparePartAmount: el.quantity
                                    }
                                ],
                                []
                            )?.[0],
                            // icon: <AddIcon />
                            icon: <ManualPositionIcon style={{ width: '100%' }} />
                        }}
                    />
                ))}
            </Space>
        </Spin>
    );
};
