// LacquerTypeView

import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button, Col, InputNumber, Row, Select } from 'antd';
import { getArrayAvailableLacquer } from '../../../utils/convertRepairPositionsToDamageList';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { workTimeUnits } from '../../../initData/workTimeUnits';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { useEffect, useState } from 'react';

const { Option } = Select;

export function LacquerTypeView() {
    const { t } = useTranslation();

    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const additionalRC = pluginOptions?.settings?.additionalRC;
    const hst = datECode.slice(2, 5);
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const {
        currentAssemblyGroup,
        currentAssemblyGroupObject: currentObj,
        currentDVNs
    } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const [showPreDamagePrc, setShowPreDamagePrc] = useState(false);

    useEffect(() => {
        setShowPreDamagePrc(false);
    }, [currentDVNs]);

    // if (currentDVNs?.length !== 1) return null;
    // const dvn = currentDVNs[0];

    // const dvnLR = currentObj?.dvnLeft || currentObj?.dvnRight;
    // if (!dvnLR) return null;
    // const dvn = dvnLR.dvn;

    if (currentDVNs?.length !== 1) return null;
    const dvn = currentDVNs[0];
    const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;

    const lacquer = repairPositions.find(item => item.DATProcessId === dvn && item.RepairType === 'lacquer');
    const timeUnitsPerHour = workTimeUnits.find(unit => unit.hst === hst)?.timeUnitsPerHour;
    const WorkTime = lacquer?.WorkTime || 0;
    const workTimeInTimeUnits = Math.round(WorkTime * (timeUnitsPerHour || 1));
    const LacquerPrice = lacquer?.LacquerPrice || 0;
    const SparePartDiscount = lacquer?.SparePartDiscount || 0;
    const PreDamage = lacquer?.PreDamage;
    const PredamagePercentage = lacquer?.PredamagePercentage;

    const lacquerLDamage = currentAssemblyGroup
        ? {
              assemblyGroup: currentAssemblyGroup.assemblyGroup,
              DATProcessId: dvnLR?.dvn || 0,
              Description: currentObj?.titleMenu || '',
              LacquerLevel: lacquer?.LacquerLevel || '',
              LacquerLevelId: lacquer?.LacquerLevelId || 0
          }
        : null;

    const procedureRelatedParameters =
        pluginOptions?.settings?.contract?.Dossier?.RepairCalculation?.ProcedureRelatedParameters
            ?.ProcedureRelatedParameter;
    const selectedLacquerMethod = procedureRelatedParameters?.find(
        param => param.attr.attribute === 'selectedLacquerMethod'
    )?.['#text'] as string;
    const materialType = currentObj?.dvnLeft?.etBauart || currentObj?.dvnRight?.etBauart;

    const arrayAvailableLacquer = getArrayAvailableLacquer(t, selectedLacquerMethod, materialType);

    return (
        <Row align="middle" gutter={[8, 8]}>
            {dvnLR?.rcs.rc.find(item => item === 'U' && additionalRC?.includes('U')) && (
                <Col span={24}>* {t('ManualRepairPositionForm.AutoLacquerNotAvailable')}</Col>
            )}
            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                {t('ManualRepairPositionForm.PreDamage')}
            </Col>
            <Col span={12}>
                <Select
                    size="large"
                    defaultValue="none"
                    value={
                        showPreDamagePrc || typeof PredamagePercentage === 'number'
                            ? 'preDamagePrc'
                            : PreDamage
                            ? 'preDamage'
                            : 'none'
                    }
                    style={{
                        width: '100%'
                    }}
                    onChange={value => {
                        if (lacquerLDamage)
                            RepairPositionsModel.event.addLacquerLDamage({
                                ...lacquerLDamage,
                                PreDamage: value === 'preDamage',
                                PredamagePercentage: null
                            });
                        setShowPreDamagePrc(value === 'preDamagePrc');
                    }}
                >
                    <Option value="none">{t('ManualRepairPositionForm.PreDamageOptions.none')}</Option>
                    <Option value="preDamage">{t('ManualRepairPositionForm.PreDamageOptions.preDamage')}</Option>
                    <Option value="preDamagePrc">{t('ManualRepairPositionForm.PreDamageOptions.preDamagePrc')}</Option>
                </Select>
            </Col>
            {showPreDamagePrc || typeof PredamagePercentage === 'number' ? (
                <>
                    <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                        {t('ManualRepairPositionForm.PreDamage')} (%)
                    </Col>
                    <Col span={12}>
                        <InputNumber
                            size="large"
                            type="number"
                            min={0}
                            max={100}
                            value={PredamagePercentage !== null ? PredamagePercentage : undefined}
                            onChange={value => {
                                if (lacquerLDamage)
                                    RepairPositionsModel.event.addLacquerLDamage({
                                        ...lacquerLDamage,
                                        PredamagePercentage: value
                                    });
                                if (!showPreDamagePrc) setShowPreDamagePrc(true);
                            }}
                        />
                    </Col>
                </>
            ) : null}
            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                {t('ManualRepairPositionForm.WorkTime')}
            </Col>
            {timeUnitsPerHour && (
                <>
                    <Col span={12}>
                        <InputNumber
                            size="large"
                            type="number"
                            value={workTimeInTimeUnits}
                            onChange={value => {
                                if (lacquerLDamage)
                                    RepairPositionsModel.event.addLacquerLDamage({
                                        ...lacquerLDamage,
                                        WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                        LacquerPrice: 0,
                                        SparePartDiscount: SparePartDiscount
                                    });
                            }}
                        />
                        <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                </>
            )}
            <Col span={12}>
                <InputNumber
                    size="large"
                    type="number"
                    value={WorkTime}
                    onChange={value => {
                        if (lacquerLDamage)
                            RepairPositionsModel.event.addLacquerLDamage({
                                ...lacquerLDamage,
                                WorkTime: Math.round(value * 100) / 100,
                                LacquerPrice: 0,
                                SparePartDiscount: SparePartDiscount
                            });
                    }}
                />
                <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
            </Col>

            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                {t('ManualRepairPositionForm.LacquerPrice')}
            </Col>
            <Col span={12}>
                <InputNumber
                    size="large"
                    type="number"
                    value={LacquerPrice}
                    onChange={value => {
                        if (lacquerLDamage)
                            RepairPositionsModel.event.addLacquerLDamage({
                                ...lacquerLDamage,
                                WorkTime: 0,
                                LacquerPrice: parseFloat(value?.toString()),
                                SparePartDiscount: SparePartDiscount
                            });
                    }}
                />
            </Col>

            <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                {t('ManualRepairPositionForm.SparePartDiscount')}
            </Col>
            <Col span={12}>
                <InputNumber
                    size="large"
                    type="number"
                    value={SparePartDiscount}
                    onChange={value => {
                        if (lacquerLDamage)
                            RepairPositionsModel.event.addLacquerLDamage({
                                ...lacquerLDamage,
                                WorkTime: WorkTime,
                                LacquerPrice: LacquerPrice,
                                SparePartDiscount: parseFloat(value?.toString())
                            });
                    }}
                />
            </Col>
            {arrayAvailableLacquer.map(item => {
                const sameLacquer =
                    !!lacquer &&
                    lacquer.LacquerLevel === item.LacquerLevel &&
                    lacquer.LacquerLevelId === item.LacquerLevelId;

                return (
                    <Col span={24}>
                        <Button
                            key={item.key}
                            // className={'lacquerSelectionButtons'}
                            style={{ width: '100%' }}
                            size="large"
                            type={sameLacquer ? 'primary' : 'default'}
                            onClick={() => {
                                if (currentAssemblyGroup)
                                    if (sameLacquer)
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn: dvnLR?.dvn,
                                            repairType: 'lacquer'
                                        });
                                    else
                                        RepairPositionsModel.event.addLacquerLDamage({
                                            assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                            DATProcessId: dvnLR?.dvn || 0,
                                            Description: currentObj?.titleMenu || '',
                                            LacquerLevel: item.LacquerLevel,
                                            LacquerLevelId: item.LacquerLevelId
                                        });
                            }}
                        >
                            {item.description}
                        </Button>
                    </Col>
                );
            })}
        </Row>
    );
}
