import { domain } from '../plugin';
import { PayloadForHandleCreation } from './types';

const submitAiClaim = domain.createEvent();
const handleCreationFx = domain.createEffect<PayloadForHandleCreation, void>();

export const contractEvents = {
    submitAiClaim
};
export const contractEffects = {
    handleCreationFx
};
