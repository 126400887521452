import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { getTranslationForInputLabel } from './utils';
import { contractEffects, contractStores } from '../../stores/contract';
import {
    combinedVehicleSelectionStores,
    vehicleSelectionEffects,
    vehicleSelectionEvents
} from '../../stores/vehicleSelection';
import {
    combinedVehicleIdentificationStores,
    vehicleIdentificationEffects,
    vehicleIdentificationEvents,
    vehicleIdentificationStores
} from '../../stores/vehicleIdentification';
import { vehicleImagesEffects, vehicleImagesEvents, vehicleImagesStores } from '../../stores/vehicleImages';
import { vehicleRepairEffects, vehicleRepairStores } from '../../stores/vehicleRepair';
import { commonEffects, commonEvents } from '../../stores/common';
import { pluginStores } from '../../stores/plugin';
import { convertConstructionPeriodToDate, createDatECode } from '@dat/core/utils';
import { ClassificationGroupsKeys, classificationGroupsNames, constructionPeriodsArray } from '@dat/core/constants';
import { fullDatECodeLength } from '../../constants';
import { useToggle } from '@dat/core/hooks/useToggle';
import {
    alternativeBaseModelChangeCallback,
    alternativeManufacturerChangeCallback,
    alternativeSubModelChangeCallback,
    alternativeVehicleTypeChangeCallback,
    baseModelChangeCallback,
    constructionPeriodChangeCallback,
    containerChangeCallback,
    firstRegistrationChangeCallback,
    firstRegistrationFilterChangeCallback,
    manufacturerChangeCallback,
    subModelChangeCallback,
    vehicleTypeChangeCallback
} from './changeCallbacks';
import { vehicleSelectionModalEffects } from './store';

import {
    AlternativeModelText,
    CheckboxLabel,
    ColorField,
    ColorText,
    DatepickerContainer,
    EquipmentButton,
    GetStarted,
    InProgress,
    SelectsContainer,
    InputMarginWrapper,
    MainButtonsWrapper,
    PaintTypeCheckIcon,
    SectionTitle,
    SelectsGroupContainer,
    SelectsSeparator,
    SliderContainer,
    SliderWrapper,
    StyledButtonIcon,
    StyledFinishButton,
    StyledModalTextFieldContainer,
    StyledTechnicalDataButton,
    VehicleSelectionWrapper,
    YellowDot
} from './styles';
import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import { InputField } from '@wedat/ui-kit/Formik/InputField';
import { InputFieldWithButtons } from '../InputFieldWithButtons';

import { DatepickerField } from '@wedat/ui-kit/Formik/DatepickerField';
import { CheckboxField } from '@wedat/ui-kit/Formik/CkeckboxField';
import { NumberInputField } from '@wedat/ui-kit/Formik';
import { EquipmentSelect } from '../EquipmentSelect';
import { ImagesSlider } from '@wedat/ui-kit/components/ImagesSlider';
import { RegistrationNumberRequestResultModal } from '../RegistrationNumberRequestResultModal';
import { VinRequestResultModal } from '../VinRequestResultModal';
import { MultipleVariantSelectionModal } from '../MultipleVariantSelectionModal';
import { CustomSelectField } from '../CustomSelect';
import { VinInputField } from './VinInputField';
import { NumberPlateInputField } from './NumberPlateInputField';
import { CurrentFormikValues } from '../../types/formik';
import { ParsedVXSVehicleObject } from '@dat/core/types';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Info, List } from '@wedat/ui-kit/assets/Icon';
import { modalsEvents } from '../../stores/modals';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { parseDateString } from '@dat/core/utils/parseDateString';

export const MainModal: FC<ModalProps> = React.memo(({ isOpen, onDismiss }) => {
    // Formik context
    const formikContext = useFormikContext<CurrentFormikValues>();
    const { setFieldValue, values, initialValues, handleSubmit } = formikContext;
    // Stores
    const { t } = useTranslation();
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const restriction = useStore(sharedTemplateStores.templateSettings.restriction);
    const country = useStore(sharedTemplateStores.templateSettings.country);
    const { VINQueryAutomatic, NumberPlateSearchAutomatic, freeTextSearchAutomatic } = useStore(pluginStores.settings);
    const { lastSuccessfullyRequestedVin, vinColors } = useStore(combinedVehicleIdentificationStores);
    const vinPaintType = vinColors[0]?.PaintType;
    const paintTypes = useStore(vehicleRepairStores.paintTypes);
    const {
        vehicleTypes,
        isOptionWithRepairIncompleteSelected,
        alternativeManufacturers,
        alternativeBaseModels,
        alternativeSubModels,
        manufacturers,
        baseModels,
        subModels,
        allEquipmentObject,
        constructionPeriods,
        containers,
        technicalData,
        isVehicleSelected
    } = useStore(combinedVehicleSelectionStores);
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);
    const vehicleFromContract = useStore(contractStores.vehicleFromContract);
    // for autoSelect in VSM
    const additionalInfoSubModels = useStore(vehicleIdentificationStores.additionalInfoSubModels);
    const subModelEqual = useStore(vehicleIdentificationStores.subModelsEqual);

    // Local state/variables
    const [isModalInitialized, setIsModalInitialized] = useState(false);
    const [isRegistrationNumberRequestResultModalOpen, toggleIsRegistrationNumberRequestResultModalOpen] =
        useToggle(false);
    const [isVinRequestResultModalOpen, toggleIsVinRequestResultModalOpen] = useToggle(false);
    const currentEquipmentGroupsKeys = Object.keys(allEquipmentObject) as ClassificationGroupsKeys[];

    const setVsmSelects = async () => {
        const vehicleType = additionalInfoSubModels.vehicleType || '';
        const manufacturer = additionalInfoSubModels.manufacturer || '';
        const baseModel = additionalInfoSubModels.baseModel || '';
        const subModel = additionalInfoSubModels.subModel || '';

        const { validOptions } = await vehicleIdentificationEffects.getPartialValuesVehicleIdentificationFx({
            vehicleType,
            manufacturer,
            baseModel,
            subModel
        });

        setFieldValue('vehicleSelects', validOptions);

        vehicleIdentificationEvents.setSubModelEqual(false);
    };

    useEffect(() => {
        if (subModelEqual) {
            setVsmSelects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subModelEqual]);

    // Set default vehicle type when vehicleTypes data is received for the first time
    useEffect(() => {
        if (vehicleTypes.length && !isModalInitialized) {
            initialValues.vehicleSelects.vehicleType = '1';
            setIsModalInitialized(true);
        }
    }, [vehicleTypes, initialValues, isModalInitialized]);

    // Set DAT code when it's compiled
    useEffect(() => {
        const unwatchDatECode = vehicleSelectionEffects.compileDatECodeFx.doneData.watch(datECode => {
            setFieldValue('datECode', datECode);
        });

        return () => {
            unwatchDatECode();
        };
    }, [setFieldValue]);

    // Focus "paintType" select when vehicle is selected
    useEffect(() => {
        if (isVehicleSelected && (!values.paintType || !paintTypes.some(({ key }) => key === values.paintType))) {
            commonEvents.focusSelect('paintType');
        }
    }, [isVehicleSelected, setFieldValue, values.paintType, paintTypes]);

    // Set the largest construction period
    // when constructionPeriods are updated
    useEffect(() => {
        if (!constructionPeriods.length || values.constructionPeriod) {
            return;
        }

        const maxValue = Math.max(...constructionPeriods.map(({ key }) => +key));

        setFieldValue('constructionPeriod', String(maxValue));
    }, [constructionPeriods, values.constructionPeriod, setFieldValue]);

    // Get construction periods when datECode is compiled
    useEffect(
        () =>
            vehicleSelectionEffects.compileDatECodeFx.doneData.watch(datECode => {
                vehicleSelectionEffects.getConstructionPeriodsFx({ datECode, container: values.container });
            }),
        [values.container]
    );

    // Disable firstRegistrationFilter when VIN is successfully requested
    useEffect(
        () =>
            vehicleIdentificationEffects.getVehicleIdentificationByVinFx.done.watch(() => {
                setFieldValue('firstRegistrationFilter', false);
            }),

        [setFieldValue]
    );

    // Reset container if restriction is not appropriate
    useEffect(() => {
        if (restriction !== 'ALL' && restriction !== 'APPRAISAL') {
            setFieldValue('container', '');
        }
    }, [restriction, values.container, setFieldValue]);

    // Reset selects focus when opened
    useEffect(() => {
        commonEvents.focusSelect('');
    }, [isOpen]);

    //
    /*** Reset vehicle and equipment selects ***/
    //
    const resetVehicleCompletely = useCallback(() => {
        vehicleSelectionEvents.resetManufacturers();
        vehicleSelectionEvents.resetBaseModels();
        vehicleSelectionEvents.resetSubModels();
        vehicleSelectionEvents.resetAllEquipment();
        commonEvents.resetOptionsDependentOnEquipment();
        vehicleImagesEvents.resetVehicleImages();

        commonEvents.focusSelect('');

        setFieldValue('vehicleSelects', initialValues.vehicleSelects);
        setFieldValue('equipmentSelects', initialValues.equipmentSelects);
    }, [setFieldValue, initialValues]);

    //
    /*** Set values received from parsed VXS response ***/
    //
    const setVehicleDataFromVXSVehicleObject = useCallback(
        async ({
            vehicleInfo,
            datECodeEquipment,
            paintType,
            vinColors,
            equipment,
            vinResultEquipment
        }: ParsedVXSVehicleObject) => {
            let {
                datECode,
                vin,
                numberPlate,
                nationalCode,
                typeNoteNumber,
                kba,
                constructionTime,
                initialRegistration,
                kilometers,
                tiresPercent,
                container,
                vehicleType,
                manufacturer,
                baseModel,
                subModel,
                vehicleCountry
            } = vehicleInfo;
            const restriction = sharedTemplateStores.templateSettings.restriction.getState();
            // In case of text search equipment is not received
            const isEquipmentReceived = !!datECodeEquipment.length;

            if (!datECode) {
                datECode = createDatECode({ vehicleType, manufacturer, baseModel, subModel });
            }
            if (restriction !== 'ALL' && restriction !== 'APPRAISAL') {
                container = '';
            }

            // Reset selects data and current focus to prevent visible re-renders and callbacks
            resetVehicleCompletely();

            // Get all available options for identified vehicle.
            // vehicleTypes -> manufacturers -> baseModels -> subModels should be requested alternately
            // to avoid unnecessary callbacks caused by auto-selection of single options.
            await vehicleSelectionEffects.getVehicleTypesFx({});
            await vehicleSelectionEffects.getManufacturersFx({ vehicleType });
            await vehicleSelectionEffects.getBaseModelsFx({ vehicleType, manufacturer });
            await vehicleSelectionEffects.getSubModelsFx({ vehicleType, manufacturer, baseModel });

            const [getConstructionPeriodsResponse, paintTypes] = await Promise.all([
                Promise.resolve(
                    datECode.length === fullDatECodeLength
                        ? vehicleSelectionEffects.getConstructionPeriodsFx({ datECode, container })
                        : null
                ),
                vehicleRepairEffects.getPaintTypesFx({ vehicleType, manufacturer, mainType: baseModel }),
                Promise.resolve(
                    datECode && constructionTime
                        ? vehicleSelectionEffects.getTechnicalDataFx({
                              datECode: datECode,
                              container,
                              constructionTime: +constructionTime
                          })
                        : null
                ),
                vehicleImagesEffects.getVehicleImagesFx({ datECode }),
                vehicleSelectionEffects
                    .getAllEquipmentObjectFx({
                        vehicleType,
                        manufacturer,
                        baseModel,
                        subModel
                    })
                    .then(() => {
                        // "availableEquipmentObject" is dependent on "allEquipmentObject"
                        // so it should be requested only after "getAllEquipmentObjectFx"
                        if (isEquipmentReceived) {
                            return vehicleSelectionEffects.getAvailableEquipmentObjectFx({
                                vehicleType,
                                manufacturer,
                                baseModel,
                                subModel,
                                availableOptions: datECodeEquipment.map(({ id }) => id)
                            });
                        }
                    })
                    .catch(() => {})
            ]);

            // If received construction periods don't contain
            // vehicle's constructionTime value then add it manually
            const constructionPeriods = getConstructionPeriodsResponse?.constructionPeriods;

            if (
                constructionTime &&
                constructionPeriods &&
                !constructionPeriods.find(({ key }) => key === constructionTime)
            ) {
                vehicleSelectionEvents.setConstructionPeriods([
                    {
                        key: constructionTime,
                        value: convertConstructionPeriodToDate(constructionTime),
                        label: convertConstructionPeriodToDate(constructionTime)
                    },
                    ...constructionPeriods
                ]);
            }
            if (!constructionTime && !constructionPeriods?.length) {
                const largestPossiblePeriodKey = '9999';

                vehicleSelectionEvents.setConstructionPeriods([
                    {
                        key: largestPossiblePeriodKey,
                        value: convertConstructionPeriodToDate(largestPossiblePeriodKey),
                        label: convertConstructionPeriodToDate(largestPossiblePeriodKey)
                    }
                ]);
            }

            // Create first registration date value
            let firstRegistrationDateValue: Date | undefined;

            if (initialRegistration) {
                firstRegistrationDateValue = parseDateString(initialRegistration);
            }

            // Paint type
            paintType = paintType || initialValues.paintType || vehicleFromContract.paintType;
            paintType = paintTypes?.some(({ key }) => key === paintType) ? paintType : '';

            // Finally set received values
            vin && setFieldValue('vin', vin);
            numberPlate && setFieldValue('numberPlate', numberPlate);
            datECode && setFieldValue('datECode', datECode);
            nationalCode && setFieldValue('nationalCode', nationalCode);
            typeNoteNumber && setFieldValue('typeNoteNumber', typeNoteNumber);
            kba && setFieldValue('kba', kba);
            constructionTime && setFieldValue('constructionPeriod', constructionTime);
            firstRegistrationDateValue && setFieldValue('firstRegistration', firstRegistrationDateValue);
            vehicleCountry && setFieldValue('vehicleCountry', vehicleCountry);
            setFieldValue('firstRegistrationFilter', false);
            kilometers && setFieldValue('kilometers', kilometers);
            tiresPercent && setFieldValue('tiresPercent', tiresPercent);
            setFieldValue('vehicleSelects', { vehicleType, manufacturer, baseModel, subModel });
            paintType && setFieldValue('paintType', paintType);
            container && setFieldValue('container', container);

            datECodeEquipment.forEach(({ classificationGroup, id }) => {
                if (classificationGroup) {
                    const name = classificationGroupsNames[classificationGroup];

                    setFieldValue(`equipmentSelects.${name}`, id);
                }
            });

            vinColors && vehicleIdentificationEvents.setVinColors(vinColors);

            vehicleIdentificationEvents.setVinRequestResultEquipment({
                equipment,
                vinResultEquipment
            });

            // Focus first equipment or paint type select if needed
            if (!isEquipmentReceived) {
                commonEvents.focusFirstNotSingleOptionEquipmentSelect();
            } else if (!paintType) {
                commonEvents.focusSelect('paintType');
            }
        },
        [resetVehicleCompletely, initialValues.paintType, vehicleFromContract.paintType, setFieldValue]
    );

    //
    /*** Get vehicle by DAT Code ***/
    //

    /*** Full code ***/
    const getVehicleByFullDatECode = useCallback(
        async (datECode: string) => {
            const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleIdentificationFx({
                datECode
            });

            setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
        },
        [setVehicleDataFromVXSVehicleObject]
    );

    /*** Uncompleted code ***/
    const getVehicleByUncompletedDatECode = useCallback(
        async (datECode: string, alternativeModel?: boolean) => {
            const { validOptions, validDatECode } =
                await vehicleIdentificationEffects.getPartialVehicleIdentificationFx({
                    datECode,
                    alternativeModel
                });

            const selectsToUpdate = alternativeModel ? 'alternativeModelSelects' : 'vehicleSelects';

            setFieldValue(selectsToUpdate, validOptions);

            if (!alternativeModel) {
                setFieldValue('datECode', validDatECode);
            } else {
                // Alternative model is compiled by DAT Code and which means
                // that last modified select was "vehicleSelects.mainModel"
                // therefore focus "vehicleSelects.subModel" after compilation
                commonEvents.focusSelect('vehicleSelects.subModel');

                return;
            }

            // Identify select that should be focused
            const firstEmptyVehicleSelectEntry = Object.entries(validOptions).find(([_, value]) => !value);

            if (firstEmptyVehicleSelectEntry) {
                const firstEmptyVehicleSelectName = firstEmptyVehicleSelectEntry[0];

                commonEvents.focusSelect(`vehicleSelects.${firstEmptyVehicleSelectName}`);
            } else {
                commonEvents.focusFirstNotSingleOptionEquipmentSelect();
            }
        },
        [setFieldValue]
    );

    /*** Main function combining full and uncompleted codes ***/
    const getVehicleByDatECode = useCallback(
        async (datECode: string) => {
            const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

            if (!doChange) {
                return;
            }

            /* Full code */
            if (datECode.length === fullDatECodeLength) {
                try {
                    await getVehicleByFullDatECode(datECode);

                    return;
                } catch {
                    // If full code is not valid then request uncompleted code
                }
            }

            /* Uncompleted code */
            resetVehicleCompletely();

            getVehicleByUncompletedDatECode(datECode);
        },
        [getVehicleByFullDatECode, getVehicleByUncompletedDatECode, resetVehicleCompletely]
    );

    //
    /*** Get vehicle from contract ***/
    //
    const getVehicleFromContract = useCallback(
        async (contractId: number) => {
            let { vinResult, ...vehicle } = await contractEffects.getVehicleFromContractFx({ contractId });

            if (vehicle.vehicleInfo.datECode.length === fullDatECodeLength) {
                const vehicleWithEquipment = await vehicleIdentificationEffects.getVehicleIdentificationFx({
                    datECode: vehicle.vehicleInfo.datECode
                });

                vehicle = {
                    ...vehicle,
                    datECodeEquipment: vehicleWithEquipment.datECodeEquipment
                };

                if (vinResult?.vehicleInfo?.tiresPercent) {
                    vehicle = {
                        ...vehicle,
                        vehicleInfo: { ...vehicle.vehicleInfo, tiresPercent: vinResult?.vehicleInfo?.tiresPercent }
                    };
                }
            }

            await setVehicleDataFromVXSVehicleObject(vehicle);

            vinResult?.vin && vehicleIdentificationEvents.setLastSuccessfullyRequestedVin(vinResult.vin);
            vinResult?.vinRequestResultEquipment &&
                vehicleIdentificationEvents.setVinRequestResultEquipment(vinResult.vinRequestResultEquipment);
        },
        [setVehicleDataFromVXSVehicleObject]
    );

    useEffect(() => {
        if (pluginOptions?.contractId) {
            getVehicleFromContract(pluginOptions?.contractId);
        }
    }, [pluginOptions?.contractId, getVehicleFromContract]);

    //
    /*** Get vehicle by VIN ***/
    //
    useEffect(
        () =>
            vehicleSelectionModalEffects.getVehicleByVinFx.done.watch(
                async ({ params: { vin }, result: parsedVXSVehicleObject }) => {
                    await setVehicleDataFromVXSVehicleObject({
                        ...parsedVXSVehicleObject,
                        vehicleInfo: { ...parsedVXSVehicleObject.vehicleInfo, vin }
                    });
                    vehicleIdentificationEvents.setLastSuccessfullyRequestedVin(vin);
                }
            ),
        [setVehicleDataFromVXSVehicleObject]
    );

    //
    /*** Get vehicle by number plate ***/
    //
    useEffect(
        () =>
            vehicleSelectionModalEffects.getVehicleByNumberPlateFx.done.watch(
                async ({ params: { licencePlate }, result: parsedVXSVehicleObject }) => {
                    if (parsedVXSVehicleObject) {
                        await setVehicleDataFromVXSVehicleObject({
                            ...parsedVXSVehicleObject,
                            vehicleInfo: {
                                ...parsedVXSVehicleObject.vehicleInfo,
                                numberPlate: licencePlate
                            }
                        });
                    }
                    if (parsedVXSVehicleObject?.vehicleInfo.vin) {
                        vehicleIdentificationEvents.setLastSuccessfullyRequestedVin(
                            parsedVXSVehicleObject?.vehicleInfo.vin
                        );
                    }
                    vehicleIdentificationEvents.setLastSuccessfullyRequestedLicencePlate(licencePlate);
                }
            ),
        [setVehicleDataFromVXSVehicleObject]
    );

    //
    /*** Get vehicle by OCR ***/
    //
    useEffect(
        () =>
            vehicleSelectionModalEffects.getVehicleByOCRFx.done.watch(async ({ result: parsedVXSVehicleObject }) => {
                if (parsedVXSVehicleObject?.licencePlate) {
                    await setVehicleDataFromVXSVehicleObject({
                        ...parsedVXSVehicleObject,
                        vehicleInfo: {
                            ...parsedVXSVehicleObject.vehicleInfo,
                            numberPlate: parsedVXSVehicleObject.licencePlate
                        }
                    });
                    vehicleIdentificationEvents.setLastSuccessfullyRequestedLicencePlate(
                        parsedVXSVehicleObject.licencePlate
                    );
                }
                if (parsedVXSVehicleObject?.vehicleInfo.vin) {
                    vehicleIdentificationEvents.setLastSuccessfullyRequestedVin(
                        parsedVXSVehicleObject?.vehicleInfo.vin
                    );
                }
            }),
        [setVehicleDataFromVXSVehicleObject, toggleIsRegistrationNumberRequestResultModalOpen]
    );

    //
    /*** Get vehicle by Austrian national code ***/
    //
    const getVehicleByNationalCode = async (nationalCode: string) => {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            return;
        }

        const parsedVXSVehicleObject =
            await vehicleIdentificationEffects.getVehicleIdentificationByNationalCodeAustriaFx({
                nationalCode
            });

        setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
    };

    //
    /*** Get vehicle by KBA (FMA) Germany ***/
    //
    const getVehicleByKba = async (kba: string) => {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            return;
        }

        const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleIdentificationByKbaFx({ kba });

        setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
    };

    //
    /*** Get vehicle by base number Switzerland ***/
    //
    const getVehicleByBaseNumber = async (baseNumber: string) => {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            return;
        }

        const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleIdentificationByCodeSwitzerlandFx({
            baseNumber
        });

        setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
    };

    //
    /*** Get vehicle by type sheet number Switzerland ***/
    //
    const getVehicleByTypeSheetNumber = async (typeNoteNumber: string) => {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            return;
        }

        const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleIdentificationByCodeSwitzerlandFx({
            typeNoteNumber
        });

        setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
    };

    //
    /*** Search vehicle with text ***/
    //
    const searchVehicleByText = useCallback(
        async (searchText: string) => {
            const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

            if (!doChange) {
                return;
            }

            const parsedVXSVehicleObject = await vehicleIdentificationEffects.searchVehicleByTextFx({ searchText });

            if (parsedVXSVehicleObject) {
                setVehicleDataFromVXSVehicleObject(parsedVXSVehicleObject);
            }
        },
        [setVehicleDataFromVXSVehicleObject]
    );

    //
    /*** Dynamic fields settings ***/
    //

    // Country
    const withNationalCode = country === 'AT';
    const withKba = country === 'DE';
    const withBaseNumber = country === 'CH';
    const withTypeSheetNumber = country === 'CH';
    const withCnit = country === 'FR';

    // Repair type
    const withContainer = restriction === 'ALL' || restriction === 'APPRAISAL';
    const withPaintTypes = restriction === 'ALL' || restriction === 'REPAIR';

    // Alternative model
    const withAlternativeModel = isOptionWithRepairIncompleteSelected;

    //
    /*** Automatic vehicle search when plugin is initialized ***/
    //
    useEffect(() => {
        const { datECode, vin, numberPlate, freeTextSearch } = initialValues;

        if (datECode) {
            getVehicleByDatECode(datECode);
            return;
        }
        if (VINQueryAutomatic && vin) {
            vehicleSelectionModalEffects.getVehicleByVinFx({ vin });
            return;
        }
        if (NumberPlateSearchAutomatic && numberPlate) {
            vehicleSelectionModalEffects.getVehicleByNumberPlateFx({ licencePlate: numberPlate, country });
            return;
        }
        if (freeTextSearchAutomatic && freeTextSearch) {
            searchVehicleByText(freeTextSearch);
        }
    }, [
        VINQueryAutomatic,
        NumberPlateSearchAutomatic,
        freeTextSearchAutomatic,
        getVehicleByDatECode,
        searchVehicleByText,
        initialValues,
        country
    ]);

    //
    /*** Automatic alternative model set up by it's DAT Code ***/
    //
    useEffect(() => {
        const { alternativeModelDatECode } = initialValues;

        if (withAlternativeModel && alternativeModelDatECode) {
            getVehicleByUncompletedDatECode(alternativeModelDatECode, true);
        }
    }, [withAlternativeModel, getVehicleByUncompletedDatECode, initialValues]);

    //
    /*** Unmask functions ***/
    //
    const unmaskDatECode = (value: string) => value.replace(/ /g, '');

    //
    /*** Plugins ***/
    //
    const handleDismiss = () => {
        if (pluginOptions?.isComponent) {
            handleSubmit();
        } else {
            onDismiss();
        }
    };

    // breakpoints for ui
    const isTablet = useMedia(sizes.tablet);
    const isTabletSmall = useMedia(sizes.tabletSmall);
    const isLaptop = useMedia(sizes.laptop);
    const isPhone = useMedia(sizes.phone);

    const getSliderWidth = useMemo(
        () => () => {
            if (isLaptop) {
                return 400;
            }

            if (isTabletSmall) {
                return 384;
            }

            if (isTablet) {
                return 270;
            }

            return 368;
        },
        [isLaptop, isTabletSmall, isTablet]
    );

    const getSlideToShow = useMemo(
        () => () => {
            if (isPhone) {
                return 1;
            }

            if (isLaptop) {
                return 2;
            }

            if (isTabletSmall) {
                return 3;
            }

            if (isTablet) {
                return 2;
            }

            return 3;
        },
        [isPhone, isLaptop, isTabletSmall, isTablet]
    );

    return (
        <ModalPrimary
            footerChildren={
                <MainButtonsWrapper>
                    {/* TODO: should remove this condition to avoid split. check TODO in `ButtonIcon` */}
                    {isLaptop ? (
                        <>
                            <StyledButtonIcon
                                typeStyle={{ type: 'secondary' }}
                                disabled={!technicalData}
                                onClick={modalsEvents.openTechnicalDataModal}
                            >
                                <Info />
                            </StyledButtonIcon>
                            <StyledButtonIcon
                                typeStyle={{ type: 'secondary' }}
                                disabled={!isVehicleSelected}
                                onClick={modalsEvents.openEquipmentModal}
                            >
                                <List />
                            </StyledButtonIcon>
                        </>
                    ) : (
                        <>
                            <EquipmentButton
                                disabled={!isVehicleSelected}
                                typeStyle={{ type: 'secondary' }}
                                onClick={modalsEvents.openEquipmentModal}
                            >
                                {t('equipment')}
                            </EquipmentButton>
                            <StyledTechnicalDataButton
                                disabled={!technicalData}
                                typeStyle={{ type: 'secondary' }}
                                onClick={modalsEvents.openTechnicalDataModal}
                            >
                                {t('technicalData')}
                            </StyledTechnicalDataButton>
                        </>
                    )}
                    <StyledFinishButton
                        disabled={!formikContext.values.paintType && restriction === 'REPAIR'}
                        onClick={() => handleSubmit()}
                    >
                        {pluginOptions?.buttonText?.finishButton || t('finish')}
                    </StyledFinishButton>
                </MainButtonsWrapper>
            }
            title={t('vehicleSelectionModalTitle')}
            isOpen={isOpen}
            onDismiss={handleDismiss}
        >
            <VehicleSelectionWrapper>
                <GetStarted>
                    <NumberPlateInputField
                        withBookButton
                        withOCRButton
                        onBookClick={toggleIsRegistrationNumberRequestResultModalOpen}
                    />

                    <VinInputField withBookButton onBookClick={toggleIsVinRequestResultModalOpen} />
                    <InputFieldWithButtons
                        name="freeTextSearch"
                        label={t('inputsPlaceholders.freeTextSearch')}
                        onSearch={searchVehicleByText}
                    />
                    <InputFieldWithButtons
                        data-cy="dat-code"
                        name="datECode"
                        mask="99 999 999 999 9999"
                        label="DAT"
                        unmaskFn={unmaskDatECode}
                        onSearch={getVehicleByDatECode}
                    />
                    {withNationalCode && (
                        <InputFieldWithButtons
                            mask="999999"
                            name="nationalCode"
                            label={t('inputsPlaceholders.nationalCode')}
                            onSearch={getVehicleByNationalCode}
                        />
                    )}
                    {withKba && (
                        <InputFieldWithButtons label="kba" mask="****/***" name="kba" onSearch={getVehicleByKba} />
                    )}
                    {withBaseNumber && (
                        <InputFieldWithButtons
                            mask="999.999.999"
                            label={t('inputsPlaceholders.baseNumber')}
                            name="baseNumber"
                            onSearch={getVehicleByBaseNumber}
                        />
                    )}
                    {withTypeSheetNumber && (
                        <InputFieldWithButtons
                            mask="9aa999"
                            label={t('typeNoteNumber')}
                            name="typeNoteNumber"
                            onSearch={getVehicleByTypeSheetNumber}
                        />
                    )}
                    {withCnit && (
                        <InputMarginWrapper>
                            <InputField label={t('inputsPlaceholders.cnit')} name="cnit" />
                        </InputMarginWrapper>
                    )}
                    <InputMarginWrapper>
                        <NumberInputField
                            fixedDecimalScale={false}
                            thousandSeparator="."
                            name="kilometers"
                            label="KM"
                        />
                    </InputMarginWrapper>
                    <DatepickerContainer>
                        <DatepickerField
                            maxDate={new Date()}
                            minDate={new Date(`${constructionPeriodsArray[0]}`)}
                            name="firstRegistration"
                            label={`${t('registrationNumberRequestResultModal.firstRegistrationLabel')}`}
                            onEditEnd={firstRegistrationChangeCallback}
                            popperPlacement="bottom"
                        />
                    </DatepickerContainer>
                    {!lastSuccessfullyRequestedVin && (
                        <InputMarginWrapper>
                            <CheckboxField
                                changeCallback={firstRegistrationFilterChangeCallback}
                                name="firstRegistrationFilter"
                            />
                            <CheckboxLabel>
                                {t('fieldsTitles.firstRegistration')} {t('filter')}
                            </CheckboxLabel>
                        </InputMarginWrapper>
                    )}
                </GetStarted>
                <SelectsSeparator />
                <InProgress>
                    <SelectsContainer>
                        {isTabletSmall && (
                            <SliderWrapper>
                                {vehicleImages.length > 0 && (
                                    <SliderContainer width={`${getSliderWidth()}px`}>
                                        <ImagesSlider
                                            slidesToShow={getSlideToShow()}
                                            images={vehicleImages}
                                            headerSlide={!isPhone}
                                        />
                                    </SliderContainer>
                                )}
                            </SliderWrapper>
                        )}

                        <SelectsGroupContainer>
                            <CustomSelectField
                                changeCallback={vehicleTypeChangeCallback}
                                label={t('selectsLabels.vehicleType')}
                                name="vehicleSelects.vehicleType"
                                options={vehicleTypes}
                                idLength={2}
                            />
                            <CustomSelectField
                                data-cy="manufacturer-select"
                                label={t('selectsLabels.manufacturer')}
                                changeCallback={manufacturerChangeCallback}
                                name="vehicleSelects.manufacturer"
                                options={manufacturers}
                                idLength={3}
                            />
                            <CustomSelectField
                                label={t('selectsLabels.baseModel')}
                                data-cy="main-model-select"
                                changeCallback={baseModelChangeCallback}
                                name="vehicleSelects.baseModel"
                                options={baseModels}
                                idLength={3}
                            />
                            <CustomSelectField
                                label={t('selectsLabels.subModel')}
                                data-cy="sub-model-select"
                                changeCallback={subModelChangeCallback}
                                name="vehicleSelects.subModel"
                                options={subModels}
                                idLength={3}
                            />
                        </SelectsGroupContainer>

                        <SelectsGroupContainer>
                            {currentEquipmentGroupsKeys.map(group => (
                                <div key={group}>
                                    <EquipmentSelect
                                        label={getTranslationForInputLabel(classificationGroupsNames[group], t)}
                                        data-cy={`equipment-select-${group}`}
                                        key={group}
                                        classificationGroupKey={group}
                                    />
                                </div>
                            ))}
                            {withContainer && (
                                <CustomSelectField
                                    label={t('selectsLabels.container')}
                                    changeCallback={containerChangeCallback}
                                    disabled={!containers.length}
                                    name="container"
                                    options={containers}
                                />
                            )}
                            {!isLaptop && (
                                <CustomSelectField
                                    label={t('selectsLabels.constructionPeriod')}
                                    changeCallback={constructionPeriodChangeCallback}
                                    disabled={!constructionPeriods.length}
                                    name="constructionPeriod"
                                    options={constructionPeriods}
                                />
                            )}
                            {isLaptop && (
                                <CustomSelectField
                                    label={t('selectsLabels.constructionPeriod')}
                                    changeCallback={constructionPeriodChangeCallback}
                                    disabled={!constructionPeriods.length}
                                    name="constructionPeriod"
                                    options={constructionPeriods}
                                />
                            )}
                            {withPaintTypes && !!paintTypes.length && (
                                <CustomSelectField
                                    label={t('selectsLabels.paintType')}
                                    name="paintType"
                                    options={paintTypes}
                                    formatOptionLabel={({ label, value }) => (
                                        <>
                                            {value === vinPaintType && <PaintTypeCheckIcon />}
                                            {label}
                                        </>
                                    )}
                                />
                            )}
                            {!!vinColors.length
                                ? vinColors.map(({ ColorID, Code, Description }) => {
                                      if (ColorID === 'A1') {
                                          return (
                                              <ColorField>
                                                  <StyledModalTextFieldContainer key={ColorID || Code || Description}>
                                                      <ColorText>
                                                          <YellowDot />
                                                          {Code}
                                                      </ColorText>
                                                      <ColorText>{Description}</ColorText>
                                                  </StyledModalTextFieldContainer>
                                              </ColorField>
                                          );
                                      }

                                      return null;
                                  })
                                : !!vehicleFromContract.vinColors?.length &&
                                  vehicleFromContract.vinColors.map(({ ColorID, Code, Description }) => {
                                      if (ColorID === 'A1') {
                                          return (
                                              <ColorField>
                                                  <StyledModalTextFieldContainer key={ColorID || Code || Description}>
                                                      <ColorText>
                                                          <YellowDot />
                                                          {Code}
                                                      </ColorText>
                                                      <ColorText>{Description}</ColorText>
                                                  </StyledModalTextFieldContainer>
                                              </ColorField>
                                          );
                                      }

                                      return null;
                                  })}
                        </SelectsGroupContainer>

                        {withAlternativeModel && (
                            <>
                                <SectionTitle>{t('alternativeModelTitle')}</SectionTitle>
                                <AlternativeModelText>
                                    {t('alternativeModelInfoText')}
                                    <br />
                                    {t('alternativeModelActionText')}
                                </AlternativeModelText>
                                <SelectsGroupContainer>
                                    <CustomSelectField
                                        label={t('selectsLabels.vehicleType')}
                                        changeCallback={alternativeVehicleTypeChangeCallback}
                                        name="alternativeModelSelects.vehicleType"
                                        options={vehicleTypes}
                                        idLength={2}
                                    />
                                    <CustomSelectField
                                        label={t('manufacturer')}
                                        changeCallback={alternativeManufacturerChangeCallback}
                                        name="alternativeModelSelects.manufacturer"
                                        options={alternativeManufacturers}
                                        idLength={3}
                                    />
                                    <CustomSelectField
                                        label={t('baseModel')}
                                        changeCallback={alternativeBaseModelChangeCallback}
                                        name="alternativeModelSelects.baseModel"
                                        options={alternativeBaseModels}
                                        idLength={3}
                                    />
                                    <CustomSelectField
                                        label={t('subModel')}
                                        changeCallback={alternativeSubModelChangeCallback}
                                        name="alternativeModelSelects.subModel"
                                        options={alternativeSubModels}
                                        idLength={3}
                                    />
                                </SelectsGroupContainer>
                            </>
                        )}
                    </SelectsContainer>

                    {!isTabletSmall && (
                        <SliderWrapper sticky>
                            {vehicleImages.length > 0 && (
                                <SliderContainer width={`${getSliderWidth()}px`}>
                                    <ImagesSlider slidesToShow={getSlideToShow()} images={vehicleImages} headerSlide />
                                </SliderContainer>
                            )}
                        </SliderWrapper>
                    )}
                </InProgress>
            </VehicleSelectionWrapper>
            <RegistrationNumberRequestResultModal
                isOpen={isRegistrationNumberRequestResultModalOpen}
                onDismiss={toggleIsRegistrationNumberRequestResultModalOpen}
            />
            <VinRequestResultModal isOpen={isVinRequestResultModalOpen} onDismiss={toggleIsVinRequestResultModalOpen} />
            <MultipleVariantSelectionModal />
        </ModalPrimary>
    );
});
