import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

// Components
import { DamageSelector } from '../DamageSelector';
import { DamageModal } from '../DamageModal';
import { ReportDamageButton, MainContainer } from './styles';

import { useToggle } from '@dat/core/hooks/useToggle';
import { contractStores, contractEffects } from '@dat/shared-models/contract';
import { retrieveField } from '../../utils/retrieveFields';
import { DEFAULT_IMAGE } from './constants';
import { FormikValues } from '../../types/plugin';
import { pluginStores } from '../../stores/plugin';

type Props = {
    image?: string;
    onPoiUpdate?: (position: string[]) => void;
    onDescriptionSubmit?: (position: FormikValues) => void;
    withoutApi?: boolean;
    manualPositions?: string[];
};

export const Main: FC<Props> = ({
    image = DEFAULT_IMAGE,
    onPoiUpdate = () => {},
    onDescriptionSubmit = () => {},
    manualPositions = [],
    withoutApi = false
}) => {
    const { t } = useTranslation();
    const contract = useStore(contractStores.contract);
    const pluginOptions = useStore(pluginStores.pluginOptions);

    const clickRef = useRef(null);
    const [isDamageModalOpen, setDamageModalOpen] = useState(true);
    const [isOpenPartnerRolesModal, toggleIsOpenDamageModal] = useToggle(false);
    const [activePositon, setActivePosition] = useState<string[]>([]);
    const [isShowButton, setIsShowButton] = useState(true);

    useEffect(() => {
        pluginOptions?.isShowDamageSelectorButton === false ? setIsShowButton(false) : setIsShowButton(true);
    }, [pluginOptions]);

    const updatePositions = useCallback(
        async (currPosition: string[]) => {
            if (withoutApi) {
                onPoiUpdate(currPosition);
                return;
            }
            await contractEffects.updateCurrentContractFx({
                templateData: {
                    entry: {
                        key: 'poi',
                        value: {
                            _text: JSON.stringify(currPosition)
                        }
                    }
                }
            });
        },
        [withoutApi, onPoiUpdate]
    );

    const attachDamagePoints = useCallback(
        async (position: string) => {
            const currPosition = activePositon.includes(position)
                ? activePositon.filter(item => item !== position)
                : [...activePositon, position];
            setActivePosition(currPosition);
            updatePositions(currPosition);
        },
        [activePositon, updatePositions]
    );

    const openDamageModal = useCallback(() => {
        setDamageModalOpen(true);
        toggleIsOpenDamageModal();
    }, [toggleIsOpenDamageModal]);

    useEffect(() => {
        if (withoutApi && !activePositon.length && manualPositions.length > 0) {
            setActivePosition(manualPositions);
            return;
        }

        if (contract) {
            const pois = retrieveField(contract, 'poi');
            if (pois && !activePositon.length) {
                setActivePosition(JSON.parse(pois));
            }
        }
    }, [activePositon.length, activePositon, contract, withoutApi, manualPositions]);
    return (
        <MainContainer ref={clickRef}>
            <DamageSelector
                image={image}
                onAttahDamagePoint={attachDamagePoints}
                positions={activePositon}
                isRotatedComponent={pluginOptions?.isRotatedComponent}
            />
            {isDamageModalOpen && (
                <DamageModal
                    isOpen={isOpenPartnerRolesModal}
                    onDismiss={toggleIsOpenDamageModal}
                    onDescriptionSubmit={onDescriptionSubmit}
                    position={activePositon}
                    useWithoutApi={withoutApi}
                />
            )}
            {isShowButton && (
                <ReportDamageButton onClick={openDamageModal}>{t('damageSelector.describeDamage')}</ReportDamageButton>
            )}
        </MainContainer>
    );
};
