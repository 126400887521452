import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, CheckboxField } from '@wedat/ui-kit/Formik';
import { AccordionItemContent, CheckboxFieldContainer, Field, FieldsContainer } from '../Layout/styles';

export const InsurancePositions: React.FC = () => {
    const { t } = useTranslation();

    return (
        <AccordionItemContent>
            <FieldsContainer marginBottom>
                <Field>
                    <InputField name="DomusCalculationFactor.layerMono" label={t('insurancePositions.layerMono')} />
                </Field>
                <Field>
                    <InputField name="DomusCalculationFactor.layerAdd15Mono" label={t('insurancePositions.addHours')} />
                </Field>
                <Field>
                    <InputField name="DomusCalculationFactor.layerDouble" label={t('insurancePositions.layerDouble')} />
                </Field>
                <Field>
                    <InputField
                        name="DomusCalculationFactor.layerAdd15Double"
                        label={t('insurancePositions.addHours')}
                    />
                </Field>
                <Field>
                    <InputField name="DomusCalculationFactor.layerTriple" label={t('insurancePositions.layerTriple')} />
                </Field>
                <Field>
                    <InputField
                        name="DomusCalculationFactor.layerAdd15Triple"
                        label={t('insurancePositions.addHours')}
                    />
                </Field>
                <Field>
                    <InputField name="DomusCalculationFactor.layerQuad" label={t('insurancePositions.layerQuad')} />
                </Field>
                <Field>
                    <InputField name="DomusCalculationFactor.layerAdd15Quad" label={t('insurancePositions.addHours')} />
                </Field>
                <Field>
                    <InputField
                        name="DomusCalculationFactor.wasteDisposalCostsPercValue"
                        label={t('insurancePositions.wasteDisposalCostsPercValue')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="DomusCalculationFactor.wasteDisposalCostsMaxValue"
                        label={t('insurancePositions.wasteDisposalCostsMaxValue')}
                    />
                </Field>
            </FieldsContainer>

            <FieldsContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isSurchargePaintingNeighbouringParts"
                        label={t('insurancePositions.isSurchargePaintingNeighbouringParts')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isDeductionNeighbouringMetalParts"
                        label={t('insurancePositions.isDeductionNeighbouringMetalParts')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isSurchargeWasteDisposalCosts"
                        label={t('insurancePositions.isSurchargeWasteDisposalCosts')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isDeductionIdenticalParts"
                        label={t('insurancePositions.isDeductionIdenticalParts')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isSurchargeDifferingColourShades"
                        label={t('insurancePositions.isSurchargeDifferingColourShades')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="DomusCalculationFactor.isDeductionCommonWork"
                        label={t('insurancePositions.isDeductionCommonWork')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
        </AccordionItemContent>
    );
};
