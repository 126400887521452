import { useState, useCallback } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Coords } from '../types/plugin';

const DEFAULT_CENTER = {
    lat: 41.902782,
    lng: 12.496366
};

export const useMap = () => {
    const [center, setCenter] = useState<google.maps.LatLng | Coords>(DEFAULT_CENTER);

    const [direction, setDirection] = useState<Coords | null>(null);
    const [directionResult, setDirectionResult] = useState<google.maps.DirectionsResult>();

    const [map, setMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? ''
    });

    const onDirectionCallback = useCallback(
        (response: google.maps.DirectionsResult | null) => {
            if (response !== null) {
                setDirectionResult(response);
            }
        },
        [setDirectionResult]
    );

    const onLoad = useCallback(
        map => {
            setMap(map);
        },
        [setMap]
    );

    const onUnmount = useCallback(() => {
        setMap(null);
    }, [setMap]);

    return {
        onLoad,
        onUnmount,
        map,
        setMap,
        isLoaded,
        direction,
        setDirection,
        onDirectionCallback,
        setDirectionResult,
        directionResult,
        center,
        setCenter
    };
};
