import React from 'react';

import { ROUTES_PARAMS_VALUES } from '../../constants/router';
import { routerEffects } from '../../stores/router';
import { Inbox, InboxPluginOptions } from '@dat/inbox';

export const InboxPage: React.FC = () => {
    const handleContractClick: InboxPluginOptions['onContractClick'] = ({ contractId }) => {
        contractId && routerEffects.pushClaimRouteByIdFx(contractId);
    };
    const handleCreateContractClick: InboxPluginOptions['onCreateContractClick'] = () => {
        routerEffects.pushClaimRouteByIdFx(ROUTES_PARAMS_VALUES.claim.contractId.new);
    };
    return (
        <Inbox
            options={{
                isComponent: true,
                onContractClick: handleContractClick,
                onCreateContractClick: handleCreateContractClick
            }}
        />
    );
};
