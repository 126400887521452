import { DvnMapItem } from '../types/ft/dvnMapType';
import { isDatECodeMatchMask } from './convertRepairPositionToDatId';

export function findDvnsByDatId(datId: string, datECode: string, dvnMap: DvnMapItem[]): number[] {
    let foundDvnMapItem = dvnMap.find(item => item.datId === datId && isDatECodeMatchMask(datECode, item.datECodeMask));
    if (!foundDvnMapItem) {
        foundDvnMapItem = dvnMap.find(item => item.datId === datId);
    }
    return foundDvnMapItem?.dvns || [];
}
