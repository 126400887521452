import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { BaseDamageParams } from '../stores/repairPositionsModel';
import { DvnMapItem } from '../types/ft/dvnMapType';
import { ftDamagesType } from '../types/ft/ftDamagesType';
import { FtVxsRepairsType } from '../types/ft/ftRepairType';
import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { findDvnsByDatId } from './findDvnByDatId';
import { getAvalableDamagesByDATID } from './getAvalableDamagesByDATIDftSettings';

export function getRepairPositionsFromFtDamage(
    ftDatId: string,
    damageId: string,
    // ftDamages: ftDamagesType,
    ftVxsRepairs: FtVxsRepairsType[],
    ftGroups: {
        [key: string]: string;
    }
): Partial<RepairPosition>[] {
    let result: Partial<RepairPosition>[] = [];

    let findedRepair = ftVxsRepairs.find(repair => repair.damageId === damageId && repair.elemOrGroupId === ftDatId);
    if (findedRepair) result = findedRepair.vxsRepairPositions;

    const groupId = ftGroups[ftDatId];
    findedRepair = ftVxsRepairs.find(repair => repair.damageId === damageId && repair.elemOrGroupId === groupId);
    if (findedRepair) result = findedRepair.vxsRepairPositions;

    findedRepair = ftVxsRepairs.find(
        repair => repair.damageId === damageId && (repair.elemOrGroupId === 'default' || !repair.elemOrGroupId)
    );
    if (findedRepair) result = findedRepair.vxsRepairPositions;

    return result;
}

export function getFtDamageFromRepairPositions({
    ftDatId,
    ftDamages,
    ftGroups,
    ftVxsRepairs,
    dvnMap,
    datECode,
    availableAssemblyGroupsList,
    repairPositions
}: {
    ftDatId: string | null;
    ftDamages: ftDamagesType | null;
    ftGroups: {
        [key: string]: string;
    };
    ftVxsRepairs: FtVxsRepairsType[];
    dvnMap: DvnMapItem[];
    datECode: string;
    availableAssemblyGroupsList: AvailableAssemblyGroup[];
    repairPositions: RepairPosition[];
}): String {
    if (!ftDatId || !ftDamages || !datECode) return '';

    const avalableDamages = getAvalableDamagesByDATID(ftDatId, {
        fastTrackGroups: ftGroups,
        fastTrackDamages: ftDamages
    });

    const dvns = findDvnsByDatId(ftDatId, datECode, dvnMap);
    const dvnWithBaseData = findDvnWithDescriptionAndGroup(dvns, availableAssemblyGroupsList);

    const bestMatchResult: {
        ftDamage: string;
        score: number;
    }[] = avalableDamages.map(ftDamage => {
        let ftVxsrepairPositions = getRepairPositionsFromFtDamage(ftDatId, ftDamage.damageid, ftVxsRepairs, ftGroups);
        const ftVxsrepairPositionsWithDvn = ftVxsrepairPositions.map(repairPosition => ({
            ...repairPosition,
            DATProcessId: dvnWithBaseData?.DATProcessId || 0 // repairPosition.DATProcessId
        }));
        return {
            ftDamage: ftDamage.damageid,
            score: getMatchScore(ftVxsrepairPositionsWithDvn, repairPositions)
        };
    });

    const sortedByScocore = bestMatchResult.sort((a, b) => b.score - a.score);

    return sortedByScocore[0].score > 0 ? sortedByScocore[0].ftDamage : '';
}

function getMatchScore(ftVxsrepairPositins: Partial<RepairPosition>[], repairPositions: RepairPosition[]): number {
    const listMatched = ftVxsrepairPositins.filter(
        ftRepaPosit =>
            !!repairPositions.find(repPos =>
                isObject1ContainObject2(
                    repPos as unknown as { [key: string]: string },
                    ftRepaPosit as { [key: string]: string }
                )
            )
    );

    return listMatched.length;
}

function isObject1ContainObject2(object1: { [key: string]: string }, object2: { [key: string]: string }): boolean {
    const arrayKeyVal = Object.entries(object2);
    for (const [key, val] of arrayKeyVal) {
        if (!!val && object1[key] !== val) return false;
    }
    return true;
}

export function getRepairPositionsFromFtDamageWithDVN(
    ftDatId: string,
    damageId: string,
    ftVxsRepairs: FtVxsRepairsType[],
    ftGroups: {
        [key: string]: string;
    },
    dvnMap: DvnMapItem[],
    datECode: string,
    availableAssemblyGroupsList: AvailableAssemblyGroup[]
): RepairPosition[] {
    let resultwithoutDvn = getRepairPositionsFromFtDamage(ftDatId, damageId, ftVxsRepairs, ftGroups);

    const dvns = findDvnsByDatId(ftDatId, datECode, dvnMap);
    const dvnWithBaseData = findDvnWithDescriptionAndGroup(dvns, availableAssemblyGroupsList);

    return resultwithoutDvn.map(repairPosition => ({
        ...repairPosition,
        DATProcessId: dvnWithBaseData?.DATProcessId || 0, // repairPosition.DATProcessId
        Description: dvnWithBaseData?.Description,
        ConstructionGroupId: dvnWithBaseData?.assemblyGroup.assemblyGroupId,
        ConstructionGroup: dvnWithBaseData?.assemblyGroup.name,
        PositionEntryType: 'graphical'
        // ConstructionType: 6
    }));
}

export function findDvnWithDescriptionAndGroupByFtDatId(
    ftDatId: string,
    datECode: string,
    dvnMap: DvnMapItem[],
    availableAssemblyGroupsList: AvailableAssemblyGroup[]
) {
    const dvns = findDvnsByDatId(ftDatId, datECode, dvnMap);
    return findDvnWithDescriptionAndGroup(dvns, availableAssemblyGroupsList);
}

export function findDvnWithDescriptionAndGroup(
    dvns: number[],
    availableAssemblyGroupsList: AvailableAssemblyGroup[]
): BaseDamageParams | undefined {
    let result: BaseDamageParams | undefined = undefined;

    dvns.find(dvn =>
        availableAssemblyGroupsList.find(group =>
            group.objectsInfo?.find(objInfo => {
                if (objInfo.dvnLeft?.dvn === dvn || objInfo.dvnRight?.dvn === dvn) {
                    result = {
                        DATProcessId: dvn,
                        Description: objInfo.titleMenu,
                        assemblyGroup: group.assemblyGroup
                    };
                    return true;
                }
                return false;
            })
        )
    );

    return result;
}
