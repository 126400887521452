import React from 'react';
import { useStore } from 'effector-react';
import { useRouteMatch } from 'react-router-dom';

import { containedPluginsStores } from '../../../stores/containedPlugins';
import { contractStores } from '@dat/shared-models/contract';

import { Gallery } from '@dat/gallery';
import { useBlink } from '../Grapa/useBlink';
import { ROUTES } from '../../../constants/router';

export const GalleryPage: React.FC = () => {
    const options = useStore(containedPluginsStores.galleryPluginOptions);
    const isContractReceived = useStore(contractStores.isContractReceived);
    const match = useRouteMatch(ROUTES.claim.gallery);
    const blink = useBlink([options]);

    const shouldMount = isContractReceived && (blink || match);

    if (shouldMount) {
        return <Gallery options={options} />;
    }

    return null;
};
