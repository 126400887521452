import { attach, createEffect, createStore, restore } from 'effector';

import { API2 } from '@dat/api2';
import { contractStores } from '../index';
import { getParsedArraySafe } from '@dat/api2/utils';
import _ from 'lodash';

import {
    ListPartners,
    PayloadForAssignPartnerToCurrentContract,
    PayloadForUnAssignPartnerFromCurrentContract
} from './types';

const { contractId } = contractStores;

const getCustomerPartnersFx = createEffect(async (payload: DAT2.Request.ListPartners) => {
    const response = await API2.myClaimInternal.getPartnerList({
        claimId: payload.contractId
    });
    return getParsedArraySafe(response.data.list);
});
const customerPartners = restore(getCustomerPartnersFx.doneData, []);

const listOfPartners = createStore<ListPartners[]>([]);

listOfPartners.on(getCustomerPartnersFx.doneData, (_state, payload) => {
    const modifiedArray = payload.map(item => ({
        label: item.role,
        options: payload
            .filter(i => i.role === item.role)
            .map(option => ({
                id: option.customerNumber,
                label: option.name,
                value: option.name
            }))
    }));
    return _.uniqBy(modifiedArray, 'label');
});

const assignPartnerToContractFx = createEffect(async (payload: DAT2.Request.AssignPartnerToContract) => {
    const response = await API2.myClaim.assignPartnerToContract(payload);
    return response.return;
});
const assignPartnerToCurrentContractFx = attach({
    source: contractId,
    mapParams: (payload: PayloadForAssignPartnerToCurrentContract, contractId) => ({ contractId, ...payload }),
    effect: assignPartnerToContractFx
});

const unAssignPartnerFromContractFx = createEffect(async (payload: DAT2.Request.UnAssignPartnerFromContract) => {
    const response = await API2.myClaim.unAssignPartnerFromContract(payload);

    return response.return;
});
const unAssignPartnerFromCurrentContractFx = attach({
    source: contractId,
    mapParams: (payload: PayloadForUnAssignPartnerFromCurrentContract, contractId) => ({ contractId, ...payload }),
    effect: unAssignPartnerFromContractFx
});

const contractPartners = createStore<DAT2.Partner[]>([]); // current customer is excluded

export const sharedPartnersEffects = {
    getCustomerPartnersFx,
    assignPartnerToContractFx,
    assignPartnerToCurrentContractFx,
    unAssignPartnerFromContractFx,
    unAssignPartnerFromCurrentContractFx
};
export const sharedPartnersStores = {
    customerPartners,
    contractPartners,
    listOfPartners
};
