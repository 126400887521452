import React from 'react';
import { useStore } from 'effector-react';
import { previewEffects, previewStores } from '../../../../stores/preview';
import {
    HistoryContainer,
    HistoryDate,
    HistoryHeader,
    HistoryHeaderDate,
    HistoryList,
    HistoryListItem,
    HistoryLoading
} from './styles';
import { useTranslation } from 'react-i18next';
import { Text } from '@wedat/ui-kit';
import { useTheme } from 'styled-components';
import { DateFormat } from '../../DateFormat';
import { DEFAULT_DATE_FORMAT_WITH_TIME } from '@wedat/ui-kit/components/Datepicker/constants';

export const History = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const history = useStore(previewStores.claimHistory);
    const isLoadingHistory = useStore(previewEffects.loadClaimHistoryFx.pending);
    if (isLoadingHistory) {
        return (
            <HistoryContainer>
                <HistoryLoading />
            </HistoryContainer>
        );
    }
    return (
        <HistoryContainer>
            <HistoryHeader>
                <HistoryHeaderDate>{t('history.header.date')}</HistoryHeaderDate>
                <div>{t('history.header.whatChanged')}</div>
            </HistoryHeader>
            <HistoryList>
                {history.map((entry, index) => (
                    <HistoryListItem key={index}>
                        <HistoryDate>
                            <DateFormat format={DEFAULT_DATE_FORMAT_WITH_TIME}>
                                {new Date(entry.entryDate).toISOString()}
                            </DateFormat>
                        </HistoryDate>
                        <div>
                            <Text>
                                {entry?.senderData?.name}
                                <Text color={theme.colors.gray['400']} fontSize="14px">
                                    {!!entry?.senderData?.name && ' - '}
                                    {entry.sender} - {entry?.senderData?.customerNumber}
                                </Text>
                            </Text>
                            <div>{t(`history.type.${entry.type}`)}</div>
                            <Text color={theme.colors.dustBlue['600']} fontSize="14px">
                                {entry.message}
                            </Text>
                        </div>
                    </HistoryListItem>
                ))}
            </HistoryList>
        </HistoryContainer>
    );
};
