import React, { FC, useEffect, useState, useRef } from 'react';
import ReactImageLightbox from 'react-image-lightbox';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { options } from './constants';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export interface LightboxProps {
    images: string[];
    isOpen: boolean;
    onClose: () => void;
    initialImageIndex?: number;
}

export const Lightbox: FC<LightboxProps> = ({ images, isOpen, onClose, initialImageIndex = 0 }) => {
    const [imageIndex, setImageIndex] = useState(initialImageIndex);
    const targetElement = useRef<Element | HTMLElement>(null);

    useEffect(() => setImageIndex(initialImageIndex), [initialImageIndex]);

    if (!isOpen) {
        return null;
    }

    const movePrev = () => setImageIndex(prev => (prev + images.length - 1) % images.length);
    const moveNext = () => setImageIndex(prev => (prev + 1) % images.length);

    const customStyles = {
        overlay: {
            zIndex: 10001
        }
    };

    return (
        <ReactImageLightbox
            onAfterOpen={() => {
                if (targetElement.current) {
                    disableBodyScroll(targetElement.current, options);
                }
            }}
            reactModalStyle={customStyles}
            mainSrc={images[imageIndex]}
            nextSrc={images[(imageIndex + 1) % images.length]}
            prevSrc={images[(imageIndex + images.length - 1) % images.length]}
            onCloseRequest={() => {
                onClose();
                clearAllBodyScrollLocks();
            }}
            onMoveNextRequest={moveNext}
            onMovePrevRequest={movePrev}
            // @ts-ignore
            ref={targetElement}
        />
    );
};
