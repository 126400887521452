import styled, { css } from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    disabled?: boolean;
    conflicted?: boolean;
}

export const ConflictsContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const ConflictsAction = styled.div`
    display: flex;
    align-items: center;
`;
export const ConflictsActionItem = styled.div<Props>`
    ${({ conflicted, theme }) => conflicted && `color: ${theme.colors.orange['800']};`}
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
            cursor: not-allowed;
        `}
`;
export const RemoveConflicts = styled(ConflictsActionItem)`
    margin-right: 10px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;
export const StyledText = styled(Text)`
    color: inherit;
`;
