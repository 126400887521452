import React from 'react';
import ReactNumberFormat, { NumberFormatProps } from 'react-number-format';

export const NumberFormat: React.FC<NumberFormatProps> = props => (
    <ReactNumberFormat
        thousandSeparator="."
        displayType="text"
        decimalScale={2}
        fixedDecimalScale={true}
        decimalSeparator=","
        {...props}
    />
);
