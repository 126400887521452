import styled from 'styled-components';

export const StyledBadgeCounter = styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.blue['400']};
    border-radius: 8px;
    width: 33px;
    height: 28px;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    margin-left: 10px;
`;
