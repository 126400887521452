import { createEvent, restore, attach } from 'effector';

import { PluginOptions } from '../../types/plugin';

const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

const callOnContractClickFx = attach({
    source: pluginOptions,
    effect: (pluginOptions, contract: DAT2.ContractFromListContracts) => pluginOptions?.onContractClick?.(contract)
});
const callOnCreateContractClickFx = attach({
    source: pluginOptions,
    effect: pluginOptions => pluginOptions?.onCreateContractClick?.()
});

const pluginUnmounted = createEvent<PluginOptions>();

export const pluginEffects = {
    callOnContractClickFx,
    callOnCreateContractClickFx
};
export const pluginEvents = {
    initPlugin,
    pluginUnmounted
};
export const pluginStores = {
    pluginOptions
};
