import { getRates, getRate, deleteRate, createOrUpdateRate } from '@dat/api2/services/myClaim-internal';
import { pluginStores } from '@dat/labour-rates-generic/src/stores/plugin';
import { combine, createEffect, createEvent, createStore, forward, restore } from 'effector';
import { fieldsDictionary } from './constants/fieldsDictionary';
import { initialConfig } from './constants/initialConfig';
import { FormState, RateParam } from './types';
import { generateConfigFromResponse, generateFormState } from './utils';

const DEFAULT_RATE_PARAMS = {
    ownerId: 0,
    id: -1,
    country: 'it-IT'
};

/**
 * Effects
 */
const getRatesFx = createEffect(getRates);
const getRateFx = createEffect(getRate);
const deleteRateFx = createEffect(deleteRate);
const createOrUpdateRateFx = createEffect(createOrUpdateRate);

/**
 * Events
 */
const setRateParams = createEvent<RateParam>();
const createNewRate = createEvent();

/**
 * Stores
 */
const rates = createStore<DAT2.Internal.RateItem[]>([]);
const rate = restore(getRateFx.doneData, null);
const initialFormState = createStore<FormState | null>(null);
const ownerId = createStore<number>(0);
const rateParams = restore<RateParam>(setRateParams, DEFAULT_RATE_PARAMS);

const config = combine(pluginStores.pluginOptions, rateParams, (pluginOptions, rateParamsStore) => ({
    config: generateConfigFromResponse(
        initialConfig,
        rateParamsStore.country,
        pluginOptions?.fieldsDictionary ?? fieldsDictionary
    )
}));

forward({
    from: [deleteRateFx.doneData, createOrUpdateRateFx.doneData],
    to: getRatesFx
});

forward({
    from: setRateParams.map(item => item.id),
    to: getRateFx
});

const addZeros = (value: string | number) =>
    value.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });

ownerId.on(getRatesFx.doneData, (_, payload) => +Object.keys(payload.data.owners)[0]);
rates.on(getRatesFx.doneData, (_, payload) =>
    payload.data.items
        .map(item => ({
            ...item,
            wage_body_1: addZeros(item.wage_body_1 ?? 0),
            wage_electric_1: addZeros(item.wage_electric_1 ?? 0),
            wage_mechanic_1: addZeros(item.wage_mechanic_1 ?? 0),
            wage_painting: addZeros(item.wage_painting ?? 0)
        }))
        .filter(item => item.settlementType !== 'SHARED')
);

const configWithRateParams = combine(
    [config, rateParams, pluginStores.pluginOptions],
    ([configStore, rateParamsStore, pluginOptions]) => ({
        config: configStore.config,
        rateParams: rateParamsStore,
        fieldsDictionary: pluginOptions?.fieldsDictionary ?? fieldsDictionary
    })
);

configWithRateParams
    .on(getRateFx.doneData, (state, payload) => ({
        ...state,
        config: generateConfigFromResponse(payload.costRateFactors, state.rateParams.country, state.fieldsDictionary)
    }))
    .on(createNewRate, state => ({
        ...state,
        config: generateConfigFromResponse()
    }));

initialFormState
    .on(setRateParams, () => null)
    .on(getRateFx.doneData, (_, payload) => generateFormState(payload.costRateFactors))
    .on(createNewRate, () => generateFormState());

rateParams.on(createNewRate, () => DEFAULT_RATE_PARAMS);

export const labourRatesGenericStores = {
    rates,
    rate,
    config,
    configWithRateParams,
    rateParams,
    initialFormState,
    ownerId
};
export const labourRatesGenericEvents = {
    setRateParams,
    createNewRate
};
export const labourRatesGenericEffects = {
    getRatesFx,
    getRateFx,
    deleteRateFx,
    createOrUpdateRateFx,
    createNewRate
};
