export const LacquerFactorTypes = [
    {
        value: '',
        id: '',
        label: 'Please select'
    },
    {
        value: '00',
        id: '',
        label: 'Costing excludes paintw. log.'
    },
    {
        value: '05',
        id: '',
        label: '2-K unicolour (1-layer)'
    },
    {
        value: '09',
        id: '',
        label: 'Unicolour (2-layer)'
    },
    {
        value: '12',
        id: '',
        label: 'Metallic (2-layer)'
    },
    {
        value: '15',
        id: '',
        label: 'Mineral effect (2-layer)'
    },
    {
        value: '16',
        id: '',
        label: 'Unicolour (3-layer)'
    },
    {
        value: '17',
        id: '',
        label: 'Metallic (3-layer)'
    },
    {
        value: '19',
        id: '',
        label: '2-tone (met./met. 3-layer)'
    },
    {
        value: '20',
        id: '',
        label: '2-tone (uni./met. 3-layer)'
    },
    {
        value: '26',
        id: '',
        label: '2-tone (uni./uni. 2-layer)'
    },
    {
        value: '27',
        id: '',
        label: '2-tone (uni./met.  2-layer)'
    },
    {
        value: '28',
        id: '',
        label: '2-tone (met./met. 2-layer)'
    },
    {
        value: '30',
        id: '',
        label: 'Pearl effect (2-layer)'
    },
    {
        value: '31',
        id: '',
        label: 'Pearl-coloured effect (2-l.)'
    },
    {
        value: '32',
        id: '',
        label: 'Pearlescent effect (3-layer)'
    },
    {
        value: '40',
        id: '',
        label: 'Effect paint (4-layer)'
    },
    {
        value: '50',
        id: '',
        label: 'Unicol. (scr.-res. clear ct.)'
    },
    {
        value: '51',
        id: '',
        label: 'met (scratch-res. clear-coat)'
    },
    {
        value: '52',
        id: '',
        label: 'Pearl paint scratch-res. 2-l.'
    },
    {
        value: '53',
        id: '',
        label: 'Pearl paint scratch-res. 3-l.'
    },
    {
        value: '55',
        id: '',
        label: 'Unicol. (multi-f. base paint)'
    },
    {
        value: '56',
        id: '',
        label: 'Metallic (Multi-f. base paint)'
    },
    {
        value: '60',
        id: '',
        label: 'Custom paintwork'
    }
];

export const ManufacturerLacquerType = [
    {
        value: '',
        id: '',
        label: 'Please select'
    },
    {
        value: '00',
        id: '',
        label: 'Costing excludes paintw. log.'
    },
    {
        value: '01',
        id: '',
        label: 'Synthetic resin unicoloured'
    },
    {
        value: '05',
        id: '',
        label: '2-K unicolour (1-layer)'
    },
    {
        value: '09',
        id: '',
        label: 'Unicolour (2-layer)'
    },
    {
        value: '10',
        id: '',
        label: 'Metallic (1-layer)'
    },
    {
        value: '12',
        id: '',
        label: 'Metallic (2-layer)'
    },
    {
        value: '15',
        id: '',
        label: 'Mineral effect (2-layer)'
    },
    {
        value: '16',
        id: '',
        label: 'Unicolour (3-layer)'
    },
    {
        value: '17',
        id: '',
        label: 'Metallic (3-layer)'
    },
    {
        value: '19',
        id: '',
        label: '2-tone (met./met. 3-layer)'
    },
    {
        value: '20',
        id: '',
        label: '2-tone (uni./met. 3-layer)'
    },
    {
        value: '22',
        id: '',
        label: '2-tone (uni./uni. 1-layer)'
    },
    {
        value: '24',
        id: '',
        label: '2-tone (mica/mica 2-layer)'
    },
    {
        value: '25',
        id: '',
        label: '2-tone (uni./met.  2-layer)'
    },
    {
        value: '26',
        id: '',
        label: '2-tone (uni./uni. 2-layer)'
    },
    {
        value: '27',
        id: '',
        label: '2-tone (uni./met.  2-layer)'
    },
    {
        value: '28',
        id: '',
        label: '2-tone (met./met. 2-layer)'
    },
    {
        value: '30',
        id: '',
        label: 'Pearl effect (2-layer)'
    },
    {
        value: '31',
        id: '',
        label: 'Pearl-coloured effect (2-l.)'
    },
    {
        value: '32',
        id: '',
        label: 'Pearlescent effect (3-layer)'
    },
    {
        value: '33',
        id: '',
        label: 'High Reflex (3-layer)'
    },
    {
        value: '34',
        id: '',
        label: 'Iridesc. high-gloss (3-layer)'
    },
    {
        value: '35',
        id: '',
        label: 'Mica (2-layer)'
    },
    {
        value: '36',
        id: '',
        label: 'Mica (3-layer)'
    },
    {
        value: '40',
        id: '',
        label: 'Effect paint (4-layer)'
    },
    {
        value: '50',
        id: '',
        label: 'Unicol. (scr.-res. clear ct.)'
    },
    {
        value: '51',
        id: '',
        label: 'met (scratch-res. clear-coat)'
    },
    {
        value: '52',
        id: '',
        label: 'Pearl paint scratch-res. 2-l.'
    },
    {
        value: '53',
        id: '',
        label: 'Pearl paint scratch-res. 3-l.'
    },
    {
        value: '55',
        id: '',
        label: 'Unicol. (multi-f. base paint)'
    },
    {
        value: '56',
        id: '',
        label: 'Metallic (Multi-f. base paint)'
    }
];
