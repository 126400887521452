import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

import { AssignPartnerButton, MainContainer, Text } from './styles';
import { RolesPopup } from '../RolesPopup';
import { PartnersModal } from '../PartnerRolesModal';
import { useToggle } from '@dat/core/hooks/useToggle';
import { useStore } from 'effector-react';
import { partnersEvents, partnersStores } from '../../stores/partners';
import { locationStores } from '../../stores/location';
import { pluginStores } from '../../stores/plugin';
import { AssignPatnerIcon } from '@wedat/ui-kit/components/Icons';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { sharedPartnersStores } from '@dat/shared-models/contract/Partners';

export const Main: FC = () => {
    const { t } = useTranslation();
    const isTablet = useMedia(sizes.tablet);

    const [isRolesOpen, setIsRolesOpen] = useState(false);
    const clickRef = useRef(null);

    const pluginOptions = useStore(pluginStores.pluginOptions);
    const [isOpenPartnerRolesModal, toggleIsOpenPartnerRolesModal] = useToggle(false);
    const [activePartner, setActivePartner] = useState(-1);

    const partnersWithPosition = useStore(sharedPartnersStores.customerPartners);
    const claimPosition = useStore(locationStores.claimPosition);
    const activeRole = useStore(partnersStores.activeRole);

    const handleToggleModal = (e: SyntheticEvent, role: DAT2.CustomerRole, id?: number) => {
        e.stopPropagation();
        partnersEvents.selectRole(role);
        const index = partnersWithPosition.findIndex(partner => partner.id === id);
        setActivePartner(index);

        toggleIsOpenPartnerRolesModal();
    };

    useClickOutside(clickRef, () => {
        if (!isTablet) {
            setIsRolesOpen(false);
        }
    });

    const handleCloseSlideUpMenu = () => {
        setIsRolesOpen(false);
        pluginOptions?.callbackClose?.();
    };

    useEffect(() => {
        setIsRolesOpen(!!pluginOptions?.isOpen);
    }, [pluginOptions?.isOpen]);

    if (pluginOptions?.isComponent && isTablet) {
        return (
            <>
                <RolesPopup
                    isOpen={isRolesOpen}
                    handleToggleModal={handleToggleModal}
                    closeSlideUpMenu={handleCloseSlideUpMenu}
                />
                <PartnersModal
                    claimPosition={claimPosition}
                    partners={partnersWithPosition}
                    activePartnerIndex={activePartner}
                    setActivePartner={setActivePartner}
                    isOpen={isOpenPartnerRolesModal}
                    activeRole={activeRole}
                    onDismiss={toggleIsOpenPartnerRolesModal}
                />
            </>
        );
    }

    return (
        <MainContainer ref={clickRef}>
            <AssignPartnerButton isHeader={pluginOptions?.isHeader} onClick={() => setIsRolesOpen(!isRolesOpen)}>
                <AssignPatnerIcon />
                <RolesPopup
                    isOpen={isRolesOpen}
                    handleToggleModal={handleToggleModal}
                    closeSlideUpMenu={handleCloseSlideUpMenu}
                />
                {pluginOptions?.displayTitle && <Text>{t('title')}</Text>}
            </AssignPartnerButton>

            <PartnersModal
                claimPosition={claimPosition}
                partners={partnersWithPosition}
                activePartnerIndex={activePartner}
                setActivePartner={setActivePartner}
                isOpen={isOpenPartnerRolesModal}
                activeRole={activeRole}
                onDismiss={toggleIsOpenPartnerRolesModal}
            />
        </MainContainer>
    );
};
