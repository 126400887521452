import React, { FC } from 'react';
import {
    FooterContainer,
    FooterItem,
    ConstantsContainer,
    ConstantsItem,
    FooterItemsContainer,
    SparePartsContainer
} from './styles';
import { TextField } from '../Price/styles';
import { TableFooterTypes } from '../../types/tableFooter';

export const TableFooter: FC<TableFooterTypes> = ({ items, lacquerConstants, isMobile, isSpareParts }) => (
    <>
        {isSpareParts && isMobile && (
            <SparePartsContainer>
                {items
                    .filter((_, index) => index !== 0)
                    .map((item, index) => (
                        <FooterItem key={index}>
                            <TextField styles={item?.styles}>{item?.label}</TextField>
                            <TextField styles={item?.styles}>{item?.price}</TextField>
                        </FooterItem>
                    ))}
            </SparePartsContainer>
        )}
        <FooterContainer>
            {!isMobile && (
                <ConstantsContainer isVisible={lacquerConstants?.length}>
                    {lacquerConstants?.map((item, index) => (
                        <ConstantsItem key={index}>
                            <TextField>{item?.label}</TextField>
                            <TextField>{item?.price}</TextField>
                        </ConstantsItem>
                    ))}
                </ConstantsContainer>
            )}
            {isSpareParts && isMobile ? (
                <FooterItemsContainer>
                    <FooterItem>
                        <TextField styles={items?.[0].styles}>{items?.[0].label}</TextField>
                        <TextField styles={items?.[0].styles}>{items?.[0].price}</TextField>
                    </FooterItem>
                </FooterItemsContainer>
            ) : (
                <FooterItemsContainer>
                    {items.map((item, index) => (
                        <FooterItem key={index}>
                            <TextField styles={item?.styles}>{item?.label}</TextField>
                            <TextField styles={item?.styles}>{item?.price}</TextField>
                        </FooterItem>
                    ))}
                </FooterItemsContainer>
            )}
        </FooterContainer>
    </>
);
