import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTheme } from 'styled-components';

import { TablePosition, UpdatePositionsPayload } from '../../../stores/postEditing/types';

import { postEditingEffects, postEditingEvents, postEditingStores } from '../../../stores/postEditing';
import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { LACQUER_MATERIAL_IDX } from '../../../constants/postEditing';

import { Text } from '@wedat/ui-kit/components/Text';
import { InputField } from '@wedat/ui-kit/Formik/InputField';
import { NumberInputField, SelectField } from '@wedat/ui-kit/Formik';

import {
    Container,
    HeadersContainer,
    PositionContainer,
    FieldWrapper,
    AdditionalPositionWrapper,
    AnimatedContainer,
    ArrowDownIconStyled
} from './styles';
import { getLevelOptions } from '../../../utils/getLevelOptions';

const { updatePositions } = postEditingEffects;

export const PositionsTable = () => {
    const {
        colors: { dustBlue }
    } = useTheme();
    const { t } = useTranslation();
    const { values, dirty } = useFormikContext<TablePosition[]>();
    const repairPositions = useStore(sharedRepairCalculationStores.repairPositions);
    const productsConfiguration = useStore(sharedTemplateStores.productsConfiguration);
    const selectedPositions = useStore(postEditingStores.selectedPositions);

    const expandAllLinesOnInitialRender = productsConfiguration?.calculation?.expandAllLinesOnInitialRender;
    const hideLacquerMaterialColumn = productsConfiguration?.calculation?.hideLacquerMaterialColumn;

    const [expendedLines, setExpendedLines] = useState<number[]>(
        expandAllLinesOnInitialRender ? values.map((_item, idx) => idx) : []
    );

    const headers = useMemo(
        () => [
            // width numbers here and in jsx are partially taken from design and partially are chosen on a call with Simone on 14.09.2022
            { label: t('postEditingMainTable.sparePartCode'), width: 146 },
            { label: t('postEditingMainTable.quantity'), width: 32 },
            { label: t('postEditingMainTable.level'), width: 60 },
            { label: t('postEditingMainTable.designation'), width: 234 },
            { label: t('postEditingMainTable.labourTime'), width: 67 },
            { label: t('postEditingMainTable.labourPrice'), width: 67 },
            { label: t('postEditingMainTable.lacquerMaterial'), width: 67 },
            { label: t('postEditingMainTable.lacquerTime'), width: 67 },
            { label: t('postEditingMainTable.lacquerPrice'), width: 88 },
            { label: t('postEditingMainTable.labourAndLacquerTotal'), width: 88 },
            { label: t('postEditingMainTable.partPrice'), width: 88 },
            { label: t('postEditingMainTable.totalPrice'), width: 88 }
        ],
        [t]
    );

    const handlePositionClick = (idx: number, DATProcessId: number) => {
        postEditingEvents.setSelectedPositionsEvent(`${idx}-${DATProcessId}`);
    };

    const handleSubPositionClick = (
        idx: number,
        subIdx: number,
        positionType: 'labour' | 'lacquer',
        DATProcessId: number
    ) => {
        postEditingEvents.setSelectedPositionsEvent(`${positionType}-${idx}-${subIdx}-${DATProcessId}`);
    };

    const toggleSubPositions = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, isExpended: boolean, idx: number) => {
        e.stopPropagation();
        setExpendedLines(prevState => {
            if (isExpended) {
                return prevState.filter(el => el !== idx);
            } else {
                return [...prevState, idx];
            }
        });
    };

    const handleParseSelectedElement = (selectedItem: string) => {
        const [positionType, idx, subIdx] = selectedItem.split('-');

        return `${positionType}-${idx}-${subIdx}`;
    };

    const levelOptions = getLevelOptions();

    const handleUpdatePositions = ({ position, updatingField, subType }: UpdatePositionsPayload) => {
        if (dirty) {
            updatePositions({
                position,
                updatingField,
                ...(subType && { subType })
            });
        }
    };

    return (
        <Container>
            <HeadersContainer>
                {headers.map((item, idx) => {
                    if (hideLacquerMaterialColumn && idx === LACQUER_MATERIAL_IDX) {
                        return null;
                    }
                    return (
                        <FieldWrapper key={idx} width={item.width}>
                            <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                                {item.label}
                            </Text>
                        </FieldWrapper>
                    );
                })}
            </HeadersContainer>
            {values?.map((pos, idx) => {
                const isSelected = selectedPositions.some(item => item.split('-')[0] === String(idx));
                const isExpended = expendedLines.some(item => item === idx);
                const subPosAmount = (pos.subLacquerPositions?.length || 0) + (pos.subLabourPositions?.length || 0);

                return (
                    <PositionContainer key={idx}>
                        <AdditionalPositionWrapper
                            isSelected={isSelected}
                            onClick={() => {
                                handlePositionClick(idx, pos.DATProcessId || 0);
                            }}
                        >
                            <FieldWrapper width={146}>
                                <InputField
                                    stopPropagation
                                    inputSize="medium"
                                    height={40}
                                    name={`${idx}.PartNumber`}
                                    onBlur={() =>
                                        handleUpdatePositions({
                                            position: pos,
                                            updatingField: 'SparePartNumber'
                                        })
                                    }
                                />
                            </FieldWrapper>
                            <FieldWrapper width={32}>{pos.Amount}</FieldWrapper>
                            <FieldWrapper width={60} />
                            <FieldWrapper width={234}>
                                <InputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.Description`}
                                    onBlur={() =>
                                        handleUpdatePositions({
                                            position: pos,
                                            updatingField: 'Description'
                                        })
                                    }
                                />
                                {pos.subLabourPositions?.length || pos.subLacquerPositions?.length ? (
                                    <ArrowDownIconStyled
                                        shown={isExpended}
                                        onClick={e => toggleSubPositions(e, isExpended, idx)}
                                    />
                                ) : null}
                            </FieldWrapper>
                            <FieldWrapper width={67}>
                                <NumberInputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.labourTimeCalculated`}
                                    disabled
                                />
                            </FieldWrapper>
                            <FieldWrapper width={67}>
                                <NumberInputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.labourPriceCalculated`}
                                    disabled
                                />
                            </FieldWrapper>
                            {!hideLacquerMaterialColumn && (
                                <FieldWrapper width={67}>
                                    <NumberInputField
                                        stopPropagation
                                        inputSize="medium"
                                        name={`${idx}.lacquerMaterialCalculated`}
                                        disabled
                                    />
                                </FieldWrapper>
                            )}
                            <FieldWrapper width={67}>
                                <NumberInputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.lacquerTimeCalculated`}
                                    disabled
                                />
                            </FieldWrapper>
                            <FieldWrapper width={88}>
                                <NumberInputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.lacquerPriceCalculated`}
                                    disabled
                                />
                            </FieldWrapper>
                            <FieldWrapper width={88}>
                                <NumberInputField stopPropagation inputSize="medium" name={`${idx}.LLTotal`} disabled />
                            </FieldWrapper>
                            <FieldWrapper width={88}>
                                <NumberInputField
                                    onBlur={() =>
                                        handleUpdatePositions({
                                            position: pos,
                                            updatingField: 'SparePartPrice'
                                        })
                                    }
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.ValueTotalCorrected`}
                                />
                            </FieldWrapper>
                            <FieldWrapper width={88}>
                                <NumberInputField
                                    stopPropagation
                                    inputSize="medium"
                                    name={`${idx}.totalValueCalculated`}
                                    disabled
                                />
                            </FieldWrapper>
                        </AdditionalPositionWrapper>
                        <AnimatedContainer show={isExpended} subPosAmount={subPosAmount}>
                            {pos.subLabourPositions?.map((subPos, subIdx) => {
                                const isSelectedSubPosition = selectedPositions.some(
                                    item => handleParseSelectedElement(item) === `labour-${idx}-${subIdx}`
                                );

                                return (
                                    <AdditionalPositionWrapper
                                        onClick={() => {
                                            handleSubPositionClick(idx, subIdx, 'labour', subPos.DATProcessId || 0);
                                        }}
                                        isSelected={isSelectedSubPosition}
                                        isSubPosition
                                    >
                                        <FieldWrapper width={146}>
                                            <InputField
                                                stopPropagation
                                                inputSize="medium"
                                                height={40}
                                                name={`${idx}.subLabourPositions.${subIdx}.LabourPosId`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={32} />
                                        {/* TODO: add stopPropagation prop for select as it is for Input */}
                                        <FieldWrapper width={60}>
                                            <SelectField
                                                name={`${idx}.subLabourPositions.${subIdx}.WageLevel`}
                                                options={levelOptions}
                                                // onInputChange={() => {
                                                // handleUpdatePositions({
                                                //     position: subPos,
                                                //     updatingField: 'WorkLevel',
                                                //     subType: 'labour'
                                                // });
                                                // }}
                                                inputSize="medium"
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={234}>
                                            <InputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLabourPositions.${subIdx}.Description`}
                                                onBlur={() =>
                                                    handleUpdatePositions({
                                                        position: pos,
                                                        updatingField: 'Description',
                                                        subType: 'labour'
                                                    })
                                                }
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLabourPositions.${subIdx}.Duration`}
                                                onBlur={() =>
                                                    handleUpdatePositions({
                                                        position: subPos,
                                                        updatingField: 'Duration',
                                                        subType: 'labour'
                                                    })
                                                }
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                onBlur={() =>
                                                    handleUpdatePositions({
                                                        position: subPos,
                                                        updatingField: 'ValueTotal',
                                                        subType: 'labour'
                                                    })
                                                }
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLabourPositions.${subIdx}.ValueTotal`}
                                            />
                                        </FieldWrapper>
                                        {!hideLacquerMaterialColumn && (
                                            <FieldWrapper width={67}>
                                                <NumberInputField
                                                    stopPropagation
                                                    inputSize="medium"
                                                    name={`name`}
                                                    disabled
                                                />
                                            </FieldWrapper>
                                        )}
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLabourPositions.${subIdx}.ValueTotalCorrected`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                    </AdditionalPositionWrapper>
                                );
                            })}
                            {pos.subLacquerPositions?.map((subPos: TablePosition, subIdx) => {
                                const isSelectedSubPosition = selectedPositions.some(
                                    item => handleParseSelectedElement(item) === `lacquer-${idx}-${subIdx}`
                                );
                                // this is a temp solution until we know how to update absent repair positions with repair type lacquer
                                const isLaqDisabled = !repairPositions.some(
                                    item => item.DATProcessId === subPos.DATProcessId && item.RepairType === 'lacquer'
                                );

                                return (
                                    <AdditionalPositionWrapper
                                        onClick={() => {
                                            handleSubPositionClick(idx, subIdx, 'lacquer', subPos.DATProcessId || 0);
                                        }}
                                        isSelected={isSelectedSubPosition}
                                        isSubPosition
                                    >
                                        <FieldWrapper width={146}>
                                            <InputField
                                                stopPropagation
                                                inputSize="medium"
                                                height={40}
                                                name={`${idx}.subLaсquerPositions.${subIdx}.LabourPosId`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={32} />
                                        {/* TODO: add stopPropagation prop for select as it is for Input */}
                                        <FieldWrapper width={60}>
                                            <SelectField
                                                name={`${idx}.subLacquerPositions.${subIdx}.Level`}
                                                options={levelOptions}
                                                // onUserChange={() =>
                                                // handleUpdatePositions({
                                                //     position: pos,
                                                //     updatingField: 'WorkLevel',
                                                //     subType: 'lacquer'
                                                // })
                                                // }
                                                inputSize="medium"
                                                // disabled={isLaqDisabled}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={234}>
                                            <InputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLacquerPositions.${subIdx}.Description`}
                                                onBlur={() =>
                                                    handleUpdatePositions({
                                                        position: subPos,
                                                        updatingField: 'Description',
                                                        subType: 'lacquer'
                                                    })
                                                }
                                                disabled={isLaqDisabled}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        {!hideLacquerMaterialColumn && (
                                            <FieldWrapper width={67}>
                                                <NumberInputField
                                                    stopPropagation
                                                    inputSize="medium"
                                                    name={`${idx}.subLacquerPositions.${subIdx}.Material`}
                                                    disabled={isLaqDisabled}
                                                />
                                            </FieldWrapper>
                                        )}
                                        <FieldWrapper width={67}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLacquerPositions.${subIdx}.Duration`}
                                                onBlur={() =>
                                                    handleUpdatePositions({
                                                        position: subPos,
                                                        updatingField: 'Duration',
                                                        subType: 'labour'
                                                    })
                                                }
                                                disabled={isLaqDisabled}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLacquerPositions.${subIdx}.ValueTotal`}
                                                disabled={isLaqDisabled}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`name`}
                                                disabled
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLacquerPositions.${subIdx}.ValueTotalCorrected`}
                                                disabled={isLaqDisabled}
                                            />
                                        </FieldWrapper>
                                        <FieldWrapper width={88}>
                                            <NumberInputField
                                                stopPropagation
                                                inputSize="medium"
                                                name={`${idx}.subLacquerPositions.${subIdx}.ValueTotalCorrected`}
                                                disabled={isLaqDisabled}
                                            />
                                        </FieldWrapper>
                                    </AdditionalPositionWrapper>
                                );
                            })}
                        </AnimatedContainer>
                    </PositionContainer>
                );
            })}
        </Container>
    );
};
