import { media } from '@wedat/ui-kit/mediaQueries';
import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
`;

export const Wrap = styled.div``;

export const ImageStyled = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    object-fit: cover;
`;

export const Arrow = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    transition: color 150ms;
`;

export const Flag = styled.img`
    width: 24px;
    height: 16px;
`;

interface ItemProps {
    head?: boolean;
}

export const Item = styled.div<ItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    min-height: 44px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    }

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        min-height: 48px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['100']};
    }

    ${({ head }) =>
        head &&
        css`
            cursor: default;
            &:hover,
            &:focus {
                background-color: transparent;
            }
        `}
`;

export const ItemTitle = styled.h3`
    margin-bottom: 0;
    display: block;
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const ItemText = styled.p`
    margin-bottom: 0;
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const ButtonProfile = styled.div`
    padding: 4px;
    display: flex;
    align-items: center;
    width: 84px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 8px;
    transition: background-color 150ms;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray['200']};

        ${Arrow} {
            color: ${({ theme }) => theme.colors.blue['500']};
        }
    }
    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};

        ${Arrow} {
            color: ${({ theme }) => theme.colors.blue['500']};
        }
    }

    ${media.laptop`
        padding: 0;
        height: 40px;
    `}
`;

export const ItemContent = styled.div`
    display: flex;
    align-items: center;

    ${ItemTitle} {
        margin-left: 12px;
        color: ${({ theme }) => theme.colors.dustBlue['600']};
    }
`;
export const StyledLink = styled.a``;
