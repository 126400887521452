import axios from '../axios';
import { DatProductsUrls, VehicleRepairServices } from '@dat/core/constants/enums';

export const getVehicleIdentification = (values: DAT.GetVehicleIdentificationRequest) =>
    axios<DAT.GetVehicleIdentificationResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentification',
        values
    });

export const getVehicleIdentificationByVin = (values: DAT.GetVehicleIdentificationByVinRequest) =>
    axios<DAT.GetVehicleIdentificationByVinResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentificationByVin',
        values
    });

export const getVehicleIdentificationByKba = (values: DAT.GetVehicleIdentificationByKbaRequest) =>
    axios<DAT.GetVehicleIdentificationByKbaResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentificationByKba',
        values
    });

export const getVehicleIdentificationByNationalCodeAustria = (
    values: DAT.GetVehicleIdentificationByNationalCodeAustriaRequest
) =>
    axios<DAT.GetVehicleIdentificationByNationalCodeAustriaResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentificationByNationalCodeAustria',
        values
    });

export const getVehicleIdentificationByCodeSwitzerland = (
    values: DAT.GetVehicleIdentificationByCodeSwitzerlandRequest
) =>
    axios<DAT.GetVehicleIdentificationByCodeSwitzerlandResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentificationByCodeSwitzerland',
        values
    });

export const getVinByLicencePlateItaly = (values: DAT.GetVinByLicencePlateItalyRequest) =>
    axios<DAT.GetVinByLicencePlateItalyResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVinByLicencePlateItaly',
        values
    });

export const getVinByLicencePlateGermany = (values: DAT.GetVinByLicencePlateGermanyRequest) =>
    axios<DAT.GetVinByLicencePlateGermanyResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVinByLicencePlateGermany',
        values
    });

export const getVinByLicencePlateNetherlands = (values: DAT.GetVinByLicencePlateNetherlandsRequest) =>
    axios<DAT.GetVinByLicencePlateNetherlandsResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVinByLicencePlateNetherlands',
        values
    });

export const getVinByLicencePlateFrance = (values: DAT.GetVinByLicencePlateFranceRequest) =>
    axios<DAT.GetVinByLicencePlateFranceResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVinByLicencePlateFrance',
        values
    });

export const getVinByLicencePlateSpain = (values: DAT.GetVinByLicencePlateSpainRequest) =>
    axios<DAT.GetVinByLicencePlateSpainResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVinByLicencePlateSpain',
        values
    });
