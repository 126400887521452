import React from 'react';
import { useStore } from 'effector-react';

import { contractsListEffects } from '../../../stores/contractsList';

import { Container } from './styles';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';

export const ItemsPreloader: React.FC = () => {
    const isLoading = useStore(contractsListEffects.loadMoreContractsFx.pending);

    return isLoading ? (
        <Container>
            <PreloaderCircle isLoading={true} />
        </Container>
    ) : null;
};
