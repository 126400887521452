import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';
import { SearchInputContainer } from '../styles';
import { StatusFilterWrapper } from '../StatusFilter/styles';

export const MainFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (max-width: 700px) {
        ${SearchInputContainer}, ${StatusFilterWrapper} {
            flex: auto;
        }
    }

    ${media.phoneBig`
        flex-direction: column;
        margin-bottom: 0;

        ${SearchInputContainer}, ${StatusFilterWrapper} {
            flex: auto;
        }

        ${SearchInputContainer} {
            margin: 0 0 20px;
        }
    `}
`;
