import { createEffect, Effect } from 'effector';
import { getParsedArraySafe } from '@dat/api2/utils';

type DossierGetter = (payload: any) => Promise<DAT2.VXSResponse>;

/**
 * @param VXSGetter - API method which returns DAT2.VXS
 * @return - effect which returns DAT2.Dossier
 */
export const createEffectDossier = <FN extends DossierGetter>(VXSGetter: FN): Effect<Parameters<FN>[0], DAT2.Dossier> =>
    createEffect(async (...params: Parameters<FN>) => {
        const response = await VXSGetter(params[0]);
        const dossier = getParsedArraySafe(response.VXS.Dossier)[0];

        return dossier;
    });
