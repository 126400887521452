import { createStore, createEvent, createEffect, sample } from 'effector';
import { getVehicleType } from '../services/italyAniaSerivce2';
import { genericSVGGraphicModel } from './genericSVGGraphicModel';
import { pluginOptionsModel } from './pluginOptionsModel';

export interface VehicleSVG {
    kindOfSVG: string;
    doorsNumber?: number;
}

const vehicleSVG = createStore<VehicleSVG>({ kindOfSVG: '4_doors', doorsNumber: 4 });
const setVehicleSVG = createEvent<VehicleSVG>();
vehicleSVG.on(setVehicleSVG, (_, newVehicleSVG) => newVehicleSVG);

const getVehicleTypeFX = createEffect(getVehicleType);

sample({
    clock: getVehicleTypeFX.doneData,
    fn: getVehicleTypeResponse => {
        if (!getVehicleTypeResponse?.response.length)
            return {
                kindOfSVG: '4_doors',
                doorsNumber: 4
            };
        const datECode = getVehicleTypeResponse.datECode;
        const TWO_DOORS = ['Cbr2', 'Cpe/Cbr2', 'Cpe2', 'Htp2', 'Road2', 'Rod/Cpe2', 'Spi2', 'Tg2', 'Berl2v3'];
        const resDataVehicleType = getVehicleTypeResponse.response[0];
        const resDataDoorsNumber = getVehicleTypeResponse.response[1];
        const doorsNumber = [3, 4, 5].includes(resDataDoorsNumber) ? resDataDoorsNumber : 5;
        let kindOfSVG = doorsNumber + '_doors';

        if (datECode.indexOf('01730048') !== -1 || datECode.indexOf('01730049') !== -1) {
            kindOfSVG = 'RRover';
        } else if (datECode.indexOf('01570190') !== -1 || datECode.indexOf('01570188') !== -1) {
            kindOfSVG = 'EQA';
        } else if (datECode.indexOf('01905152') !== -1) {
            kindOfSVG = 'ID4';
        } else if (
            resDataVehicleType.toUpperCase().includes('SUV') ||
            resDataVehicleType.toUpperCase().includes('SAV') ||
            resDataVehicleType.toUpperCase().includes('MV')
        ) {
            kindOfSVG = 'SUV';
        } else if (resDataVehicleType.toUpperCase().includes('SW')) {
            kindOfSVG = 'SW';
        } else if (TWO_DOORS.includes(resDataVehicleType)) {
            kindOfSVG = '3_doors';
        } else if (resDataVehicleType === 'Berl2v3' && doorsNumber === 5) {
            kindOfSVG = '5_doors';
        }

        return {
            kindOfSVG: kindOfSVG,
            doorsNumber: doorsNumber
        };
    },
    target: setVehicleSVG
});

sample({
    source: pluginOptionsModel.stores.pluginOptions,
    clock: setVehicleSVG,
    fn: (pluginOptions, vehicleSVG) => {
        const graphic = pluginOptions?.settings?.genericSVGGraphicsByKindOfSVG?.find(
            graphic => graphic.kindOfSVG === vehicleSVG.kindOfSVG
        );
        return graphic ? graphic.genericSVGGraphics : null;
    },
    target: genericSVGGraphicModel.effects.setGenericGraphicFx
});

export const vehicleSVGModel = {
    stores: {
        vehicleSVG
    },
    events: {
        setVehicleSVG
    },
    effects: {
        getVehicleTypeFX
    }
};
