import { createEvent, restore, attach } from 'effector';
import { PluginOptions } from '../../types/plugin';

const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);
const reloadPluginFx = attach({
    source: pluginOptions,
    effect: (pluginOptions: PluginOptions | null) => {
        const script = document.createElement('script');
        const link = document.createElement('link');

        script.src = `${process.env.PUBLIC_URL}/plugin.js`;
        link.href = `${process.env.PUBLIC_URL}/plugin.css`;
        link.rel = 'stylesheet';

        script.addEventListener('load', () => {
            if (pluginOptions && process.env.NODE_ENV === 'production') {
                window.CLAIM_MANAGEMENT_API.init(pluginOptions);
            }
        });

        document.head.append(script, link);
    }
});

const urlPath = pluginOptions.map(pluginOptions => pluginOptions?.urlPath || '');

export const pluginEvents = {
    initPlugin
};
export const pluginEffects = {
    reloadPluginFx
};
export const pluginStores = {
    pluginOptions,
    urlPath
};
