import { DefaultTheme } from 'styled-components';
import { getBaseTokens } from '../getBaseTokens';

const getTokens = (locale: string): DefaultTheme => ({
    name: 'light',
    colors: {
        primary: getBaseTokens(locale).colors.blue['400'],
        secondary: getBaseTokens(locale).colors.yellow[10],
        mark: getBaseTokens(locale).colors.red[10],
        bgMain: getBaseTokens(locale).colors.white,
        bgColor: getBaseTokens(locale).colors.gray_10,
        textSecondary: getBaseTokens(locale).colors.gray_40,

        bgHeader: getBaseTokens(locale).colors.white,
        textPrimary: getBaseTokens(locale).colors.dustBlue['800'],
        textDisabled: getBaseTokens(locale).colors.dustBlue['50'],
        textActive: getBaseTokens(locale).colors.blue['700'],
        textHeader: getBaseTokens(locale).colors.deepBlue['800'],

        ...getBaseTokens(locale).colors
    },
    typography: getBaseTokens(locale).typography
});

export { getTokens };
