import { apiStores } from '../../stores';

// Types
export type SoapValue = string | number | boolean | SoapData | SoapValue[] | undefined | null;

export interface SoapData {
    [name: string]: SoapValue;
}

export interface SoapRequestPayload<V extends SoapData> {
    method: string;
    data?: V;
    withRequestTag?: boolean;
    breakLines?: boolean; // adds new line char (\n) after every opening and before every closing tag
}

export interface GetTemplateParam {
    method: string;
    valuesString: string;
    localeString: string;
    withRequestTag?: boolean;
}

// Parsing functions
function getTemplate({ method, localeString, valuesString, withRequestTag = true }: GetTemplateParam) {
    return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:dat="dat">
            <soapenv:Header/>
            <soapenv:Body>
                <dat:${method}>
                    ${withRequestTag ? '<request>' : ''}
                        ${localeString}
                        ${valuesString}
                    ${withRequestTag ? '</request>' : ''}
                </dat:${method}>
            </soapenv:Body>
        </soapenv:Envelope>`;
}

function getValuesString(values: SoapData = {}, breakLines?: boolean): string {
    return Object.keys(values).reduce<string>((acc, key) => acc + getFieldString(key, values[key], breakLines), '');
}

function getFieldString(key: string, soapValue: SoapValue, breakLines?: boolean): string {
    const tagWrapChar = breakLines ? '\n' : '';

    if (!soapValue && typeof soapValue !== 'boolean' && typeof soapValue !== 'number') {
        return '';
    } else if (Array.isArray(soapValue)) {
        return soapValue.reduce<string>((acc, next) => acc + tagWrapChar + getFieldString(key, next, breakLines), '');
    } else if (typeof soapValue === 'object') {
        const attrEntries = Object.entries(soapValue).filter(([key]) => key.startsWith('@_'));
        let attributesString = attrEntries.reduce((acc, [attrName, attrValue]) => {
            const attrNameWithoutPrefix = attrName.slice(2);

            return acc + ` ${attrNameWithoutPrefix}="${attrValue}"`;
        }, '');
        let content;

        if (soapValue.hasOwnProperty('_text')) {
            // Add every property to "attributesString"
            const attrWithoutPrefixEntries = Object.entries(soapValue).filter(
                ([key]) => !key.startsWith('@_') && key !== '_text'
            );

            attributesString = attrWithoutPrefixEntries.reduce(
                (acc, [attrName, attrValue]) => acc + ` ${attrName}="${attrValue}"`,
                attributesString
            );
            content = soapValue._text ?? '';
        } else {
            const childrenEntries = Object.entries(soapValue).filter(([key]) => !key.startsWith('@_'));
            const childrenObject = Object.fromEntries(childrenEntries);

            content = getValuesString(childrenObject, breakLines);
        }

        return `${tagWrapChar}<${key}${attributesString}>${tagWrapChar}${content}${tagWrapChar}</${key}>`;
    } else {
        return `${tagWrapChar}<${key}>${tagWrapChar}${soapValue}${tagWrapChar}</${key}>`;
    }
}

// Main
export const createSoapRequest = <V extends SoapData>({
    method,
    data,
    withRequestTag,
    breakLines
}: SoapRequestPayload<V>) => {
    const datCountryIndicator = apiStores.country.getState();
    const [languageCode] = apiStores.locale.getState().split('-');

    // Making request body with XML fields
    // <fieldName>value</fieldName>
    const valuesString = getValuesString(data, breakLines);
    const localeString = `<locale country="${datCountryIndicator}" datCountryIndicator="${datCountryIndicator}" language="${languageCode}" />`;

    return getTemplate({
        method,
        valuesString,
        localeString,
        withRequestTag
    });
};
