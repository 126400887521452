import { useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { modalsEvents, modalsStores } from '../../stores/modals';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { useTheme } from 'styled-components';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';
import { CurrentFormikValues } from '../../types/formik';

import { vehicleSelectionStores } from '../../stores/vehicleSelection';
import { vehicleIdentificationEffects, vehicleIdentificationStores } from '../../stores/vehicleIdentification';
import _ from 'lodash';

import {
    HeaderContent,
    Container,
    HeaderTitle,
    Buttons,
    ButtonSubModel,
    Footer,
    ButtonStyled,
    Wrapper,
    HeaderDescription,
    SearchInputStyled,
    NotFoundStyled
} from './styles';

export const SubModelsModal = () => {
    const formikContext = useFormikContext<CurrentFormikValues>();
    const { t } = useTranslation();
    const theme = useTheme();
    const isOpen = useStore(modalsStores.isSubModelsModalOpen);
    const [activeSubModel, setActiveSubModel] = useState('');
    const [inputValue, setInputValue] = useState('');
    const { setFieldValue } = formikContext;

    const additionalInfoSubModels = useStore(vehicleIdentificationStores.additionalInfoSubModels);

    const manufacturerName = additionalInfoSubModels.manufacturerName || '';
    const baseModelName = additionalInfoSubModels.baseModelName || '';
    const vehicleType = additionalInfoSubModels.vehicleType || '';
    const manufacturer = additionalInfoSubModels.manufacturer || '';
    const baseModel = additionalInfoSubModels.baseModel || '';
    const subModel = additionalInfoSubModels.subModel || '';

    const subModels = useStore(vehicleSelectionStores.subModels);

    const isLaptopSmall = useMedia(sizes.laptopSmall);

    const description = `${manufacturerName}&nbsp;${baseModelName.replace(/&amp;gt;/g, '>')}`;

    const handleChoiceSubModel = (id: string) => () => {
        setActiveSubModel(id);
    };

    const handleConfirm = async () => {
        const { validOptions } = await vehicleIdentificationEffects.getPartialValuesVehicleIdentificationFx({
            vehicleType,
            manufacturer,
            baseModel,
            subModel
        });

        setFieldValue('vehicleSelects', validOptions);
        modalsEvents.toggleSubModelsModalOpen(false);
    };

    const handleResetInput = () => {
        setInputValue('');
    };

    const handleSearch = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, 1000);

    const subModelsFiltered = useMemo(
        () =>
            inputValue
                ? subModels.filter(({ value }) => value.toLowerCase().includes(inputValue.toLowerCase()))
                : subModels,
        [inputValue, subModels]
    );

    return (
        <ModalPrimary
            headerChildren={
                <HeaderContent>
                    <HeaderTitle asTag="h3" fontWeight={700} font="semiHeading" color={theme.colors.dustBlue['900']}>
                        {t('modals.subModel.headerTitle')}
                    </HeaderTitle>
                    <HeaderDescription asTag="p" font="note" color={theme.colors.dustBlue['900']}>
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </HeaderDescription>
                </HeaderContent>
            }
            bodyNoPadding
            isOpen={isOpen}
            onDismiss={modalsEvents.toggleSubModelsModalOpen}
            bodyHeight="80vh"
            bodyWidth={isLaptopSmall ? '100%' : '535px'}
            fullFill={isLaptopSmall}
        >
            <Wrapper>
                <Container>
                    <Text asTag="p" font="note" color={theme.colors.dustBlue['900']}>
                        {t('modals.subModel.description')}
                    </Text>
                    {subModels.length > 10 && (
                        <SearchInputStyled onResetInput={handleResetInput} onChange={handleSearch} />
                    )}
                    {subModelsFiltered.length > 0 ? (
                        <Buttons>
                            {subModelsFiltered.map(({ key, value }) => (
                                <ButtonSubModel
                                    key={key}
                                    onClick={handleChoiceSubModel(key)}
                                    active={key === activeSubModel}
                                >
                                    {value}
                                </ButtonSubModel>
                            ))}
                        </Buttons>
                    ) : (
                        <NotFoundStyled />
                    )}
                </Container>
                <Footer>
                    <ButtonStyled
                        type="button"
                        onClick={modalsEvents.toggleSubModelsModalOpen}
                        typeStyle={{ type: 'transparent' }}
                    >
                        {t('modals.subModel.cancel')}
                    </ButtonStyled>
                    <ButtonStyled disabled={!activeSubModel} onClick={handleConfirm}>
                        {t('modals.subModel.confirm')}
                    </ButtonStyled>
                </Footer>
            </Wrapper>
        </ModalPrimary>
    );
};
