import { PlainObject } from '../../types/common';
import { Restoreable } from '../../types/effector';

type Result<TObject> = Restoreable<TObject>;

/*
 * Makes object valid as argument for `restore` - converts `undefined` values to `null`s.
 * Useful because stores don't accept `undefined` as value.
 */
export const makeRestoreable = <TParam extends PlainObject>(object: TParam): Result<TParam> =>
    Object.entries(object).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value === undefined ? null : value
        }),
        {} as Result<TParam>
    );
