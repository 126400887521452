import styled, { css } from 'styled-components/macro';

export const SalesProcurementStyled = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const Table = styled.table`
    border-spacing: 0;
    width: 100%;
`;

export const THead = styled.thead``;
export const TBody = styled.tbody``;

export const Th = styled.th`
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_40};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
`;

export const Td = styled.td<{ disabled?: boolean }>`
    min-width: 130px;
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_300};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;

export const Tr = styled.tr`
    background-color: var(--light);
    &:not(:first-child) {
        border-top: 2px solid ${({ theme }) => theme.colors.gray_10};
    }
`;
