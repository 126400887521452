import styled from 'styled-components/macro';

export const Title = styled.h4`
    padding-left: 20px;
    display: flex;
    align-items: center;
    ${({ theme }) => theme.typography.note};
    font-weight: 700;
    height: 44px;
`;
