import styled from 'styled-components/macro';

export interface BarProps {
    size: number;
    completedVisible?: boolean;
    dueVisible?: boolean;
}

export interface BarContainerProps {
    selected: boolean;
}

export const Separator = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    width: 100%;
`;

export const BarContainer = styled.div<BarContainerProps>`
    max-width: 45px;
    min-width: 30px;
    flex: 1;
    text-align: center;
    padding: 10px 8px;
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
    ${({ selected, theme }) =>
        selected &&
        `
        background-color: ${theme.colors.blue['50']};
        border-radius: 8px;
        ${Separator} {
            background-color: ${theme.colors.blue['50']};
        }
        `}
    :hover {
        cursor: pointer;
    }
`;

export const BarWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 9px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const CompletedBar = styled.div<BarProps>`
    background-color: ${({ theme }) => theme.colors.green['200']};
    height: ${(p: BarProps) => p.size}%;
    border-radius: ${(p: BarProps) => (p.dueVisible ? '0 0' : '8px 8px')} 8px 8px;
`;

export const DueBar = styled.div<BarProps>`
    background-color: ${({ theme }) => theme.colors.blue['400']};
    height: ${(p: BarProps) => p.size}%;
    border-radius: 8px 8px ${(p: BarProps) => (p.completedVisible ? '0 0' : '8px 8px')};
`;
