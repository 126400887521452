import React, { ChangeEvent, FC } from 'react';
import { useField, useFormikContext, FieldInputProps } from 'formik';

import { Checkbox, CheckboxProps } from '../../components/Checkbox';

import { FieldEventCallback } from '../types';

export interface CheckboxFieldProps extends CheckboxProps {
    name: string;
    changeCallback?: FieldEventCallback<any, void, boolean>;
    onUserChange?: (field: FieldInputProps<boolean>) => void;
}

export const CheckboxField: FC<CheckboxFieldProps> = ({ name, changeCallback, onUserChange, ...rest }) => {
    const formikContext = useFormikContext();
    const [{ value, ...field }, , helpers] = useField<boolean>(name);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(e.target.checked);
        changeCallback?.(e.target.checked, formikContext);
        onUserChange?.({
            ...field,
            value: e.target.checked
        });
    };

    return <Checkbox {...field} {...rest} checked={!!value} onChange={handleChange} />;
};
