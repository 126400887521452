import styled from 'styled-components/macro';

import { Button, Text } from '@wedat/ui-kit';
import { PluginLink } from '../ClaimHeader/styles';

export const PluginDropdownWrapper = styled.div``;

export const ButtonStyled = styled(Button)`
    background-color: transparent;
    padding: 14px;
    border: none;
    color: ${({ theme }) => theme.colors.textPrimary};

    &:hover,
    &:focus,
    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        color: ${({ theme }) => theme.colors.blue['700']};
    }
`;

export const Item = styled.div`
    min-height: 44px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;

    &:hover {
        ${PluginLink} {
            color: ${({ theme }) => theme.colors.black};
        }

        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    ${Text} {
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
    }

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
`;

export const TitleWrapper = styled.div``;

export const DropdownContainer = styled.div`
    position: absolute;
    top: 20px;
    bottom: 0px;
    left: 140px;
`;
