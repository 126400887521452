import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { ObjectInfo } from '../types/svgGrapaTypes';

export function findGroupByDvns(dvns: number[], availableAssemblyGroupsList: AvailableAssemblyGroup[]) {
    let currentAssemblyGroup: AvailableAssemblyGroup | undefined;
    const currentAssemblyGroupObjects: ObjectInfo[] | undefined = [];
    let currentAssemblyGroupObject: ObjectInfo | undefined;

    outer: for (let i = 0; i < availableAssemblyGroupsList.length; i += 1) {
        const { objectsInfo } = availableAssemblyGroupsList[i];

        if (!objectsInfo) return null;

        let findedDvnGroup;
        for (let k = 0; k < objectsInfo.length; k += 1) {
            const obj = objectsInfo[k];

            // if ((obj.dvnRight && obj.dvnRight.dvn === dvn) || (obj.dvnLeft && obj.dvnLeft.dvn === dvn)) {
            if (
                (obj.dvnRight && dvns.find(dvn => dvn === obj.dvnRight?.dvn)) ||
                dvns.find(dvn => dvn === obj.dvnLeft?.dvn)
            ) {
                currentAssemblyGroupObject = obj;
                currentAssemblyGroup = availableAssemblyGroupsList[i];
                findedDvnGroup = obj.dvnGroup;

                if (obj.dvnGroup === 0) {
                    currentAssemblyGroup = availableAssemblyGroupsList[i];
                    findedDvnGroup = obj.dvnGroup;
                    currentAssemblyGroupObjects.push(obj);
                    break outer;
                }

                break;
            }
        }

        for (let k = 0; k < objectsInfo.length; k += 1) {
            const obj = objectsInfo[k];
            if (obj.dvnGroup === findedDvnGroup) {
                currentAssemblyGroupObjects.push(obj);
            }
        }

        if (currentAssemblyGroup) break;
    }

    return {
        currentAssemblyGroup,
        currentAssemblyGroupObjects,
        currentAssemblyGroupObject
    };
}
