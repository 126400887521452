// import { InterimCalculationProps } from './InterimCalculationTypes';
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import './RepairPositionsCalculationSummary.css';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';

export interface RepairPositionsCalculationSummaryProps {
    isMobile: boolean;
}

export const RepairPositionsCalculationSummary = ({ isMobile: _ }: RepairPositionsCalculationSummaryProps) => {
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const isInitModified = useStore(RepairPositionsModel.store.isInitModified);

    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const dossierCurrency = pluginOptions?.settings?.contract?.Dossier?.Currency;
    const locale = pluginOptions?.locale;
    const resultFormat = pluginOptions?.settings?.priceFormat;

    const formatter = new Intl.NumberFormat(
        locale || resultFormat?.locales || 'en-US',
        resultFormat?.numberFormatOptions
            ? {
                  ...resultFormat?.numberFormatOptions,
                  style: 'currency',
                  currency: dossierCurrency || resultFormat.numberFormatOptions.currency || 'EUR'
              }
            : {
                  style: 'currency',
                  currency: dossierCurrency || 'EUR',
                  minimumFractionDigits: 2
              }
    );

    const CalculationSummary = pluginOptions?.settings?.contract?.Dossier?.RepairCalculation?.CalculationSummary;

    const totalCosts = CalculationSummary?.TotalNetCosts;
    const labourCosts = CalculationSummary?.LabourCosts?.TotalSum;
    const sparePartsCosts = CalculationSummary?.SparePartsCosts?.TotalSum;
    const lacquerCosts = CalculationSummary?.LacquerCosts?.TotalSum;
    const totalCostsWithVat = CalculationSummary?.TotalGrossCosts;

    return (
        <div style={{ margin: '8px 16px' }}>
            <Row gutter={[8, 8]} align="middle">
                <Col span={12} className="wedat-grapa-repair-summaries-header">
                    {t('CalculationSummary.totalCosts')}
                </Col>
                <Col
                    onClick={_ => {
                        setIsExpanded(!isExpanded);
                    }}
                    span={12}
                    className={
                        isInitModified
                            ? 'wedat-grapa-repair-summaries-ResultPrice-notActual'
                            : 'wedat-grapa-repair-summaries-ResultPrice'
                    }
                >
                    {formatter.format(totalCosts || 0)}
                </Col>

                {isExpanded && (
                    <>
                        <Col span={12} className="wedat-grapa-repair-summaries-label">
                            {t('CalculationSummary.labourCosts')}
                        </Col>
                        <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                            {formatter.format(labourCosts || 0.0)}
                        </Col>

                        <Col span={12} className="wedat-grapa-repair-summaries-label">
                            {t('CalculationSummary.sparePartsCosts')}
                        </Col>
                        <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                            {formatter.format(sparePartsCosts || 0.0)}
                        </Col>

                        <Col span={12} className="wedat-grapa-repair-summaries-label">
                            {t('CalculationSummary.lacquerCosts')}
                        </Col>
                        <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                            {formatter.format(lacquerCosts || 0.0)}
                        </Col>

                        <Col span={12} className="wedat-grapa-repair-summaries-label">
                            {t('CalculationSummary.totalCostsWithVat')}
                        </Col>
                        <Col span={12} className="wedat-grapa-repair-position-SparePartPrice">
                            {formatter.format(totalCostsWithVat || 0.0)}
                        </Col>
                    </>
                )}
            </Row>
        </div>
    );
};
