import { FastTrackConfiguration } from '@dat/api2dat/types/fastTrackInterfaceTypes';

export function getTranslationFromFastTrackConfiguration({
    fastTrackConfiguration,
    language
}: {
    fastTrackConfiguration: FastTrackConfiguration;
    language: string;
}) {
    const translationFromFastTrackConfiguration: { [key: string]: string } = {};

    Object.entries(fastTrackConfiguration.fastTrackDamages).forEach(([_, value]) =>
        value.forEach(elem => {
            if (typeof elem === 'string') {
                translationFromFastTrackConfiguration[elem] = elem;
            }
            if (typeof elem === 'object')
                translationFromFastTrackConfiguration[elem.id] =
                    typeof elem.label === 'string' ? elem.label : elem.label[language] || elem.label['en'];
        })
    );

    return translationFromFastTrackConfiguration;
}
