import axios from '../datAxios';
import { DatProductsPaths, MyClaimServices } from '../constants';

export const calculateContractN = (data: DAT2.Request.CalculateContractN) =>
    axios<DAT2.Response.CalculateContractN>({
        product: DatProductsPaths.VehicleRepair,
        service: MyClaimServices.VehicleRepairService,
        method: 'calculateContractN',
        data
    });

export const getContractPriceGenerations = (data: DAT2.Request.GetContractPriceGenerations) =>
    axios<DAT2.Response.GetContractPriceGenerations>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.VehicleRepairService,
        method: 'getContractPriceGenerations',
        data,
        withRequestTag: false
    });

export const getLacquerTypeKeys = (data: DAT2.Request.GetLacquerTypeKeys) =>
    axios<DAT2.Response.GetLacquerTypeKeys>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.VehicleRepairService,
        method: 'getLacquerTypeKeys',
        data
    });
