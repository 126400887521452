import styled from 'styled-components/macro';
import { ArrowIcon } from '@wedat/ui-kit/components/Accordion/styles';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';

export const Root = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const AccordionContainer = styled.div`
    flex: 1;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    overflow: auto;
    ${makeCustomScrollBar()}

    ${media.laptop`
        padding-left: 16px;
        padding-right: 16px;
    `}
`;

export const AccrodionItemWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 32px;
    border-radius: 16px;

    ${ArrowIcon} {
        right: 0;
    }

    ${media.laptop`
        padding: 24px
    `}
`;
