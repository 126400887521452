import { createStore, createEvent } from 'effector';

const setIsProfileShown = createEvent<boolean>();
const isProfileShown = createStore<boolean>(false).on(setIsProfileShown, (_, state) => state);

export const profileStores = {
    isProfileShown
};

export const profileEvents = {
    setIsProfileShown
};
