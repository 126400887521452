import styled from 'styled-components/macro';
import { FieldsetStyled } from '@wedat/ui-kit/components/Fieldset';

export const FieldWrap = styled.div`
    display: flex;
    align-items: center;
`;

export const LeftInput = styled.div`
    width: 50%;
    ${FieldsetStyled} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
export const RightInput = styled.div`
    width: 50%;
    ${FieldsetStyled} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }
`;
