import { useToggle } from '@dat/core/hooks/useToggle';
import { GearIcon, Label, MenuItem, SlideUp, Title } from './styles';
import { useEffect, useState } from 'react';
import { DEVICE_ID } from '../../../../constants/localStorage';
import { useOrientation } from '../../../../hooks/orientation';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get 2 related components:
 *  - Gear Icon to open slide up for camera selection
 *  - SlideUpMenu to select camera
 */
export const useSelectCameras = (
    videoStream: MediaStream | null,
    onChangeCamera: (device: MediaDeviceInfo) => void
) => {
    const [openSelectCameras, toggleSelectCameras] = useToggle();
    const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
    const { isLandscape } = useOrientation();
    const { t } = useTranslation();

    const currentDeviceId = videoStream?.getVideoTracks()[0]?.getCapabilities()?.deviceId;

    useEffect(() => {
        const getCameras = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameraDevices = devices
                .filter(device => device.kind === 'videoinput' && device.deviceId)
                .filter(
                    ({ label }) =>
                        !label.includes('facing front') && !label.toLowerCase().includes('камера на передней панели')
                ); // filter "facing" camera

            setCameras(cameraDevices);
        };

        getCameras();
    }, [videoStream]);

    const handleChangeCamera = (camera: MediaDeviceInfo) => {
        toggleSelectCameras();

        if (camera.deviceId !== currentDeviceId) {
            onChangeCamera(camera);

            localStorage.setItem(DEVICE_ID, camera.deviceId);
        }
    };

    // show nothing if there aren't additional cameras
    if (!videoStream || cameras.length <= 1) return [null, null];

    return [
        <GearIcon onClick={toggleSelectCameras} />,
        <SlideUp isLandscape={isLandscape} isOpen={openSelectCameras} closeSlideUpMenu={toggleSelectCameras}>
            <Title as="h4" fontWeight={700} font="note">
                {t('selectCameras')}
            </Title>
            {cameras.map((camera, idx) => (
                <MenuItem key={camera.deviceId} active={currentDeviceId === camera.deviceId}>
                    <Label as="p" onClick={() => handleChangeCamera(camera)}>
                        {camera.label} ({idx})
                    </Label>
                </MenuItem>
            ))}
        </SlideUp>
    ];
};
