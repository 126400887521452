import { isSameDay, isValid, format, getYear, isSameHour, isSameMinute } from 'date-fns';
import _ from 'lodash';

import * as locales from 'date-fns/locale';
import { DateFnsLocaleFormat, TodayTranslate, TimeTranslate } from './constants';

import { DateFnsLocaleFormatValues, DatepickerParsedValue, DatepickerUnparsedValue } from './types';

import { getISODateString } from '../../../core/utils';

export const parseValue = (value: DatepickerUnparsedValue): DatepickerParsedValue => {
    if (typeof value === 'string') {
        value = new Date(value);
    }
    if (!value || !isValid(value) || Array.isArray(value)) {
        value = undefined;
    }

    return value;
};

interface GetValidValueParam {
    unvalidatedValue: DatepickerParsedValue;
    minDate?: Date | null;
    maxDate?: Date | null;
}

export const getValidValue = ({ unvalidatedValue, minDate, maxDate }: GetValidValueParam): DatepickerParsedValue => {
    const currentDateValue = unvalidatedValue && omitTime(unvalidatedValue);
    minDate = minDate && omitTime(minDate);
    maxDate = maxDate && omitTime(maxDate);

    if (currentDateValue && minDate && currentDateValue < minDate) {
        return minDate;
    }
    if (currentDateValue && maxDate && currentDateValue > maxDate) {
        return maxDate;
    }

    return unvalidatedValue;
};

export const omitTime = (date: Date) => new Date(getISODateString(date));

/* Check equality by YYYY-MM-DD */
export const isValuesEqualByDate = (
    value1: DatepickerParsedValue,
    value2: DatepickerParsedValue,
    showTimeSelect?: boolean
) => {
    if (value1 && value2) {
        const sameDate = isSameDay(value1, value2) && isSameHour(value1, value2) && isSameMinute(value1, value2);

        return showTimeSelect ? sameDate : isSameDay(value1, value2);
    }
    if (!value1 && !value2) {
        return true;
    }

    return false;
};

export interface UserDateLocale {
    userLocale: Locale | null;
    dateFormat: string;
}

export const getUserDateLocale = (language: DAT2.Locale): UserDateLocale => {
    let userLocale: Locale;
    let dateFormat = '';

    const languageFnsName = getDateFnsLocaleName(language);
    userLocale = locales[languageFnsName];

    if (!userLocale) {
        return {
            userLocale: null,
            dateFormat: `${dateFormat}, hh:mm aa`
        };
    }

    if (userLocale.formatLong) {
        dateFormat = userLocale.formatLong.date({ width: 'short' }).toLowerCase();

        // Exclude all except "y" characters
        const yearString = /[^y]/g[Symbol.split](dateFormat).join('');
        // Make it "yyyy"
        dateFormat = dateFormat.replace(yearString, yearString.padStart(4, 'y'));
        // Replace "m" with "M"
        dateFormat = dateFormat.replace(/m/g, 'M');
    }

    return { userLocale, dateFormat: `${dateFormat}, hh:mm aa` };
};

export const getDateFnsLocaleName = (language: DAT2.Locale) =>
    DateFnsLocaleFormat[language] as DateFnsLocaleFormatValues;
export const getTimeTranslate = (language: DAT2.Locale) => TimeTranslate[language];
export const getTodayTranslate = (language: DAT2.Locale) => TodayTranslate[language];

export const getMonthsOptions = (language: DAT2.Locale) => {
    const languageFnsName = getDateFnsLocaleName(language);
    const locale = locales[languageFnsName];

    const monthIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const months = monthIds.map(id => ({ value: id, label: format(new Date().setMonth(id, 1), 'LLLL', { locale }) }));

    return months;
};

export const getYearsOptions = () => {
    const years = _.range(1900, getYear(new Date()) + 21, 1);
    const yearsOptions = years.map(year => ({ value: year, label: year }));

    return yearsOptions;
};
