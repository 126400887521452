import React from 'react';
import { Field, useField } from 'formik';

import { TextArea, TextareaProps } from '../../components/TextArea';

interface Props extends TextareaProps {
    name: string;
}

export const TextareaField: React.FC<Props> = ({ name, ...rest }) => {
    const [field, meta] = useField(name);
    const joinedProps = { ...rest, ...field };

    const isError = Boolean(meta.error) && meta.touched;

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        field.onBlur(e);
        rest.onBlur?.(e);
    };

    return <Field as={TextArea} {...joinedProps} status={isError ? 'error' : 'default'} onBlur={handleBlur} />;
};
