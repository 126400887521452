import { guard, sample } from 'effector';

import { sharedSubjectsDataStores, sharedSubjectsDataEffects } from '@dat/shared-models/addressBook';
import { pluginEvents, pluginEffects, pluginStores } from './index';
import { subjectsDataStores } from '../subjectsData';
import { currentUserDataKey } from '../subjectsData/constants';
import { createInstance } from './utils/createInstance';

const { instanceOptionsReceived, addInstance } = pluginEvents;
const { connectInstanceUnitsFx } = pluginEffects;
const { instances } = pluginStores;
const { getSubjectsFx } = sharedSubjectsDataEffects;
const { customersNumbersFetching } = subjectsDataStores;
const { customersSubjectsData } = sharedSubjectsDataStores;

const shouldFetchData = guard({
    clock: instanceOptionsReceived,
    source: { customersSubjectsData, customersNumbersFetching },
    filter: ({ customersSubjectsData, customersNumbersFetching }, { networkOwnerId }) => {
        const customerNumber = networkOwnerId || currentUserDataKey;
        const isFetched = !!customersSubjectsData[customerNumber];
        const isFetching = customersNumbersFetching.includes(customerNumber);

        return !isFetched && !isFetching;
    }
});

sample({
    clock: shouldFetchData,
    source: instanceOptionsReceived,
    fn: ({ networkOwnerId }) => ({ networkOwnerCustomerNumber: networkOwnerId }),
    target: getSubjectsFx
});

const shouldCreateNewInstance = guard({
    clock: instanceOptionsReceived,
    source: instances,
    filter: (instances, { instanceId }) => !(instanceId in instances)
});
const newInstanceOptionsReceived = sample({
    clock: shouldCreateNewInstance,
    source: instanceOptionsReceived
});

sample({
    clock: newInstanceOptionsReceived,
    source: customersSubjectsData,
    fn: (customersSubjectsData, initialPayload) => createInstance({ customersSubjectsData, initialPayload }),
    target: addInstance
});

sample({
    source: addInstance,
    target: connectInstanceUnitsFx
});
