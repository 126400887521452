import { attach, forward } from 'effector';
import { API2 } from '@dat/api2';
import { i18n, i18nEvents, i18nStores, sharedI18NEffects } from './index';
import { setUserDateLocale } from '@dat/smart-components/Datepicker/utils';
import { sharedLoginEvents } from '../auth/login';
import { getLocalStorageLocale, removeLocalStorageLocale, setLocalStorageLocale } from './utils';

const { getTranslationsFx, addBackendTranslationsToI18NextFx } = sharedI18NEffects;
const { locale, namespacesToTranslate } = i18nStores;
const { setLocale, localeChangedByUser } = i18nEvents;

getTranslationsFx.use(
    attach({
        source: { locale, namespacesToTranslate },
        effect: ({ locale, namespacesToTranslate }) =>
            API2.bff.translations.getTranslations({ language: locale, namespaces: namespacesToTranslate })
    })
);

locale.watch(locale => i18n.changeLanguage(locale));
locale.watch(() => getTranslationsFx());
locale.watch(locale => setUserDateLocale(locale));
locale.watch(() => {
    // это костыль, нужно будет убрать его, когда сделаем нормальный localStorage
    // сброс происходит для того, чтобы установить локаль из LS, а не из конфигурации
    const localeLS = getLocalStorageLocale();

    if (localeLS) {
        setLocale(localeLS);
    }
});

forward({
    from: getTranslationsFx.doneData,
    to: addBackendTranslationsToI18NextFx
});

forward({
    from: localeChangedByUser,
    to: locale
});

localeChangedByUser.watch(languageCode => setLocalStorageLocale(languageCode));

sharedLoginEvents.loggedOut.watch(() => removeLocalStorageLocale());
