//TODO: make it separate util
// YYYY-MM-DD
export const getISODateString = (dateInstance: Date) => {
    const year = dateInstance.getFullYear();
    const month = String(dateInstance.getMonth() + 1).padStart(2, '0');
    const date = String(dateInstance.getDate()).padStart(2, '0');

    return `${year}-${month}-${date}`;
};

export const convertDateToConstructionPeriod = (date: Date) => {
    const minDate = new Date(`1969-12-04T00:00:00`);
    const maxDate = new Date(`2053-03-28T00:00:00`);

    if (date < minDate) {
        return '1';
    }
    if (date > maxDate) {
        return '9999';
    }

    const isItThirtyFirst = date.getDate() === 31;
    let ct = (date.getFullYear() - 1970) * 120 + 10;
    ct += date.getMonth() * 10;
    ct += isItThirtyFirst ? 9 : Math.floor((date.getDate() - 1) / 3);
    return String(ct);
};
