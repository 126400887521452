// predefinedVehicleSelection

import { createEvent, createStore } from 'effector';
import { PredefinedVehicleSelectionBaseType, PredefinedVehicleSelectionModelType } from '../types/plugin';

const $vehicleSelectionManufacturer = createStore<PredefinedVehicleSelectionBaseType | null>(null);
const setVehicleSelectionManufacturer = createEvent<PredefinedVehicleSelectionBaseType>();
$vehicleSelectionManufacturer.on(setVehicleSelectionManufacturer, (_, val) => val);

const $showManufacturerList = createStore<boolean>(false);
const setShowManufacturerList = createEvent<boolean>();
$showManufacturerList.on(setShowManufacturerList, (_, val) => val);

setVehicleSelectionManufacturer.watch(() => setShowModelList(true));

const $vehicleSelectionModel = createStore<PredefinedVehicleSelectionModelType | null>(null);
const setVehicleSelectionModel = createEvent<PredefinedVehicleSelectionModelType>();
$vehicleSelectionModel.on(setVehicleSelectionModel, (_, val) => val);
$vehicleSelectionModel.on(setVehicleSelectionManufacturer, _ => null);

const $showModelList = createStore<boolean>(false);
const setShowModelList = createEvent<boolean>();
$showModelList.on(setShowModelList, (_, val) => val);

const $vehicleSelectionModified = createStore<boolean>(false);
const setVehicleSelectionModified = createEvent<boolean>();
$vehicleSelectionModified.on(setVehicleSelectionModel, _ => true);
$vehicleSelectionModified.on(setVehicleSelectionManufacturer, _ => true);
$vehicleSelectionModified.on(setVehicleSelectionModified, (_, value) => value);

export const predefinedVehicleSelectionModel = {
    stores: {
        $vehicleSelectionManufacturer,
        $vehicleSelectionModel,
        $showManufacturerList,
        $showModelList,
        $vehicleSelectionModified
    },
    events: {
        setVehicleSelectionManufacturer,
        setVehicleSelectionModel,
        setShowManufacturerList,
        setShowModelList,
        setVehicleSelectionModified
    }
};
