import React from 'react';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { radioItems } from '../../constants/radioItems';
import { priceDatesStores } from '../../stores/selects/priceDates';

import { CheckboxField, InputField, SelectField } from '@wedat/ui-kit/Formik';
import { AccordionItemContent, CheckboxFieldContainer, Field, FieldsContainer } from '../Layout/styles';

import { FactorsParametersObject } from '../../types/factors';
import { InputWithSelect } from '../InputWithSelect';
import { Text } from '@wedat/ui-kit/components/Text';

export const SparePartsFactors: React.FC = () => {
    const { t } = useTranslation();

    const {
        values: {
            SparePartFactor: { surchargeInProtocolOly, surchargeSeparated }
        }
    } = useFormikContext<FactorsParametersObject>();
    const priceDatesSelectOptions = useStore(priceDatesStores.selectOptions);

    const percentOrAbsolute = radioItems.percentOrAbsolute(t);

    return (
        <AccordionItemContent>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={surchargeSeparated}
                        name="SparePartFactor.surchargeInProtocolOly"
                        label={t('spareParts.surchargeInProtocolOly')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={surchargeInProtocolOly}
                        name="SparePartFactor.surchargeSeparated"
                        label={t('spareParts.surchargeSeparated')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>

            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="SparePartFactor.priceDate"
                        options={priceDatesSelectOptions}
                        label={t('spareParts.priceDate')}
                    />
                </Field>
                <Field>
                    <InputField name="SparePartFactor.increaseDecrease" label={t('spareParts.increaseDecrease')} />
                </Field>

                <Field>
                    <InputField
                        name="SparePartFactor.sparePartsDisposalCosts"
                        label={t('spareParts.sparePartsDisposalCosts')}
                    />
                </Field>
                <Field>
                    <InputField name="SparePartFactor.sparePartLumpSum" label={t('spareParts.sparePartLumpSum')} />
                </Field>
                <Field>
                    <InputField name="SparePartFactor.bracketSetRentCost" label={t('spareParts.bracketSetRentCost')} />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'CalculationFactor.discount',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'SparePartFactor._attributes.mode.discount',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'SparePartFactor.discountBiw',
                            label: t('common.discountBiw')
                        }}
                        selectProps={{
                            name: 'SparePartFactor._attributes.mode.discountBiw',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>

            <Text font="defaultBold">{t('spareParts.procurementCostsSubtitle')}</Text>
            <FieldsContainer marginTop marginBottom>
                <Field>
                    <InputField
                        name="SparePartFactor.bracketSetProcurementCost"
                        label={t('spareParts.bracketSetProcurementCost')}
                    />
                </Field>
                <Field>
                    <InputField name="SparePartFactor.procurementCost" label={t('spareParts.procurementCost')} />
                </Field>
                <Field>
                    <InputField
                        name="SparePartFactor.bodyInWhiteProcurementCost"
                        label={t('spareParts.bodyInWhiteProcurementCost')}
                    />
                </Field>
            </FieldsContainer>

            <Text font="defaultBold">{t('spareParts.smallPartsAndConsumablesSubtitle')}</Text>
            <FieldsContainer marginTop>
                <Field>
                    <InputField
                        name="SparePartFactor.smallSparePartFlatRatePrice"
                        label={t('spareParts.smallSparePartFlatRatePrice')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="SparePartFactor.smallSparePartPercentOfPart"
                        label={t('spareParts.smallSparePartPercentOfPart')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="SparePartFactor.smallSparePartPercentOfPartBiw"
                        label={t('spareParts.smallSparePartPercentOfPartBiw')}
                    />
                </Field>
            </FieldsContainer>
        </AccordionItemContent>
    );
};
