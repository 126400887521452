import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
interface Props {
    item?: string;
}

export const StepBody = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;

    ${media.tablet`
    flex-direction: column;
`}
`;

export const FormBuilderWrapper = styled.div<Props>`
    width: ${props => (props.item === 'DamageSelector' ? '320px' : props.item === 'FormBuilder' ? '280px' : '100%')};

    padding: 10px;
`;
