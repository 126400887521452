import { FC, useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import {
    labourRatesGenericEffects,
    labourRatesGenericStores,
    labourRatesGenericEvents
} from '@dat/shared-models/labour-rates-generic';

// Components
import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { Table } from '@wedat/ui-kit/components/Table';
import { EditIcon, TrashIcon } from '@wedat/ui-kit/components/Icons';
import { Icon } from '@dat/smart-components/Toast/styles';
import { TableWrapper, TableHeader, TableHeaderButton, Flag } from './styles';
import { RateModal } from '../RateModal';

// Constants
import { FLAGS_IMAGES } from '@dat/core/constants/flags';

// Hooks
import { useToggle } from '@dat/core/hooks/useToggle';

// Types
import { CellType, TableData } from '../../types';

export const Main: FC = () => {
    const { t } = useTranslation();
    const [isRateModalOpen, toggleIsRateModalOpen] = useToggle(false);
    const rates = useStore(labourRatesGenericStores.rates);

    const getRates = useCallback(async () => {
        await labourRatesGenericEffects.getRatesFx();
    }, []);

    const onRateEdit = useCallback(
        (id: number, ownerId: number, country: string) => {
            labourRatesGenericEvents.setRateParams({
                id,
                ownerId,
                country
            });
            toggleIsRateModalOpen();
        },
        [toggleIsRateModalOpen]
    );

    useEffect(() => {
        getRates();
    }, [getRates]);

    const columns: Column<TableData>[] = useMemo(
        () => [
            {
                Header: String(t('table.headings.name')),
                accessor: 'name'
            },
            {
                Header: String(t('table.headings.country')),
                accessor: 'country',
                Cell: ({
                    row: {
                        original: { country }
                    }
                }) => {
                    const flagName = Object.keys(FLAGS_IMAGES).find(item => item.includes(country)) || 'it-IT';
                    const flagKey = flagName as keyof typeof FLAGS_IMAGES;
                    return <Flag src={FLAGS_IMAGES[flagKey]} />;
                }
            },
            {
                Header: String(t('table.headings.networkType')),
                accessor: 'networkType'
            },
            {
                Header: String(t('table.headings.paintingMethod')),
                accessor: 'paintingMethod',
                Cell: ({
                    row: {
                        original: { paintingMethod = 'notFound' }
                    }
                }) => t(`table.values.${paintingMethod}`)
            },
            {
                Header: String(t('table.headings.wage_body_1')),
                accessor: 'wage_body_1'
            },
            {
                Header: String(t('table.headings.wage_mechanic_1')),
                accessor: 'wage_mechanic_1'
            },
            {
                Header: String(t('table.headings.wage_electric_1')),
                accessor: 'wage_electric_1'
            },
            {
                Header: String(t('table.headings.wage_painting')),
                accessor: 'wage_painting'
            },
            {
                Header: String(t('table.headings.edit')),
                accessor: 'editRate',
                Cell: ({
                    row: {
                        original: { id, owner_id, country }
                    }
                }: CellType) => (
                    <Button
                        typeStyle={{
                            type: 'transparent'
                        }}
                        onClick={() => onRateEdit(id, owner_id, country)}
                    >
                        <Icon>
                            <EditIcon />
                        </Icon>
                    </Button>
                )
            },
            {
                Header: String(t('table.headings.delete')),
                accessor: 'deleteRate',
                Cell: ({ row }: CellType) => (
                    <Button
                        typeStyle={{
                            type: 'transparent'
                        }}
                        onClick={() => labourRatesGenericEffects.deleteRateFx(row.original.id)}
                    >
                        <Icon>
                            <TrashIcon />
                        </Icon>
                    </Button>
                )
            }
        ],
        [t, onRateEdit]
    );

    return (
        <TableWrapper>
            <TableHeader>
                <Text>{t('table.title')}</Text>
                <TableHeaderButton
                    onClick={() => {
                        labourRatesGenericEvents.createNewRate();
                        toggleIsRateModalOpen();
                    }}
                >
                    {t('table.actions.create')}
                </TableHeaderButton>
            </TableHeader>
            {isRateModalOpen && <RateModal isOpen={isRateModalOpen} onDismiss={toggleIsRateModalOpen} />}
            <Table columns={columns as any} data={rates} title={t('table.title')} />
        </TableWrapper>
    );
};
