import React from 'react';

const Add = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 18 18" {...props}>
        <path
            d="M11.125 7.875C10.5727 7.875 10.125 7.42728 10.125 6.875V1C10.125 0.447715 9.67729 0 9.125 0H8.875C8.32271 0 7.875 0.447715 7.875 1V6.875C7.875 7.42728 7.42728 7.875 6.875 7.875H1C0.447715 7.875 0 8.32271 0 8.875V9.125C0 9.67729 0.447715 10.125 1 10.125H6.875C7.42728 10.125 7.875 10.5727 7.875 11.125V17C7.875 17.5523 8.32271 18 8.875 18H9.125C9.67729 18 10.125 17.5523 10.125 17V11.125C10.125 10.5727 10.5727 10.125 11.125 10.125H17C17.5523 10.125 18 9.67729 18 9.125V8.875C18 8.32271 17.5523 7.875 17 7.875H11.125Z"
            fill="currentColor"
        />
    </svg>
);

export default Add;
