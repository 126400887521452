import { createEffect, createStore } from 'effector';

import { login } from '@dat/api2/services/bff/user';
import { DAT_REFRESH_TOKEN_NAME } from '@dat/core/constants';

const loginFx = createEffect(login);
const loggedIn = loginFx.doneData;
const validTokenReceived = loggedIn.map(({ token }) => token);

const validRefreshTokenReceived = loggedIn.map(({ refreshToken }) => {
    // get refresh after login or localStorage
    const token = refreshToken || localStorage.getItem(DAT_REFRESH_TOKEN_NAME) || '';

    return token;
});

const isLoggedOutManually = createStore(false);

export const sharedAuthEvents = {
    loggedIn,
    validTokenReceived,
    validRefreshTokenReceived
};

export const sharedAuthEffects = {
    loginFx
};
export const sharedAuthStores = {
    isLoggedOutManually
};
