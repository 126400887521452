import { WORK_LEVELS_SYMBOLS } from '../constants/positions';
import { WorkLevelBase } from '../types/positions';

interface Param {
    position: DAT2.PositionItaly;
    key: Extract<
        keyof DAT2.PositionItaly,
        'WorkLevelLacquer' | 'WorkLevelMechanic' | 'WorkLevelOverhaul' | 'WorkLevelReplace'
    >;
}
type Result = string;

export const getWorkLevelSymbol = ({ position, key }: Param): Result => {
    let symbol = '';
    const levelValue = position[key];
    const levelValue2 = position[`${key}2`];

    if (levelValue2) {
        const baseLevelValue2 = levelValue2.split('-')[0] as WorkLevelBase;
        symbol = WORK_LEVELS_SYMBOLS[baseLevelValue2];
    } else if (!levelValue) {
        symbol = WORK_LEVELS_SYMBOLS.manual;
    } else {
        const baseLevelValue = levelValue.split('-')[0] as WorkLevelBase;
        symbol = WORK_LEVELS_SYMBOLS[baseLevelValue];
    }

    return symbol;
};
