import { createEffect, createEvent, restore } from 'effector';
import _ from 'lodash';

import { OnComplete, OnUpdate, PluginOptions } from '../../types/plugin';

const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);
const completePlugin = createEvent<unknown>();

const invokeUpdateCallbackFx = createEffect<OnUpdate>(_.noop);
const invokeCompleteCallbackFx = createEffect<OnComplete>(_.noop);

export const pluginEvents = {
    initPlugin,
    completePlugin
};
export const pluginEffects = {
    invokeUpdateCallbackFx,
    invokeCompleteCallbackFx
};
export const pluginStores = {
    pluginOptions
};
