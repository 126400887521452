import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Text} {
        color: ${({ theme }) => theme.colors.dustBlue['600']};
    }
`;

export const NotFoundImg = styled.img`
    width: 280px;
    height: 224px;
    margin-bottom: 12px;

    ${media.phoneBig`
        width: 224px;
        height: 180px;
    `}
`;
