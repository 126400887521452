type Param = {
    customerNumber: number;
    contractId: number | string;
    username: string;
    companionCustomerNumber: number;
    companionUsername: string;
};
type Result = string;

export const generateChatId = ({
    contractId,
    customerNumber,
    username,
    companionCustomerNumber,
    companionUsername
}: Param): Result => {
    let identifiers: (string | number)[] = [
        contractId,
        customerNumber,
        username,
        companionCustomerNumber,
        companionUsername
    ];

    return identifiers.join('-');
};
