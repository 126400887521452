import { bffAxiosInstance } from '../../bffAxios';

export const getProfiles = (): Promise<BFF.Customer.Response.GetProfiles> =>
    bffAxiosInstance.post('customer/getProfiles');

export const getProfilesByCustomerNumber = (customerId: number): Promise<BFF.Customer.Response.GetProfiles> =>
    bffAxiosInstance.post(`customer/getProfiles?customerId=${customerId}`);

export const getAppointments = (): Promise<BFF.Customer.Response.GetAppointments> =>
    bffAxiosInstance.get('customer/appointments');

export const createOrUpdateAppointment = (
    payload: BFF.Customer.Request.CreateOrUpdateAppointment
): Promise<BFF.Customer.Response.CreateOrUpdateAppointment> => bffAxiosInstance.post('customer/appointments', payload);

export const createOrUpdateExpireDate = (
    payload: BFF.Customer.Request.CreateOrUpdateExpiryDate
): Promise<BFF.Customer.Response.CreateOrUpdateExpiryDate> =>
    bffAxiosInstance.post('customer/expiration-date', payload);
