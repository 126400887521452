import { AssemblyGroup } from '@dat/api2dat/types/api2datTypes';
import { createEvent, createStore, sample, combine } from 'effector';
import * as R from 'ramda';
import { RepairPosition, RepairType } from '@dat/api2dat/types/contractTypes';
import { AvailableDefaultDamage, AvailableAssemblyGroupsStore } from '../types/graphicTypes';
import { Dvn } from '../types/svgGrapaTypes';
import { uniqIdString } from '../utils/uniqId';
import { commonModel } from './commonModel';
import { mappingDVNWorkTypeWorkLevelModel } from './mappingDVNWorkTypeWorkLevelModel';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { getLeftOrRightSide } from '../utils/convertRepairPositionsToDamageList';

export interface RepairPositionWithRowKey extends RepairPosition {
    key: string;
}

// export interface RepairPositionsModelStore {
//     repairPositions: RepairPositionWithRowKey[];
// }

export type RepairPositionsModelStore = RepairPositionWithRowKey[];

const repairPositions = createStore<RepairPositionWithRowKey[]>([], {
    updateFilter: (newResult, oldResult) => !R.equals(oldResult, newResult)
});
const setRepairPositions = createEvent<RepairPositionWithRowKey[] | undefined>();
repairPositions.on(setRepairPositions, (_, newRepairPositions) => newRepairPositions);
const initRepairPosition = createEvent<RepairPositionWithRowKey[]>();
repairPositions.on(
    initRepairPosition,
    (_, newRepairPositions) =>
        // newRepairPositions.map(repPos => ({ ...repPos, key: uniqIdString() }))
        newRepairPositions
);

const confirmedRepairPositions = createStore<RepairPositionWithRowKey[]>([]);
const setConfirmedRepairPositions = createEvent<RepairPositionWithRowKey[]>();
confirmedRepairPositions.on(setConfirmedRepairPositions, (_, newRepairPositions) => newRepairPositions);
confirmedRepairPositions.on(initRepairPosition, (_, newRepairPositions) => newRepairPositions);

const isModified = combine(repairPositions, confirmedRepairPositions).map(([rep, conf]) => rep !== conf);

const isInitModified = createStore<boolean>(true);
isInitModified.on(initRepairPosition, _ => false);
isInitModified.on(setRepairPositions, _ => true);

sample({
    source: repairPositions,
    clock: commonModel.events.confirm,
    // fn: repPos => repPos,
    target: setConfirmedRepairPositions
});

sample({
    source: confirmedRepairPositions,
    clock: commonModel.events.cancel,
    // fn: repPos => repPos,
    target: setRepairPositions
});

const defaultMandatoryRepPositionConstructor = {
    SparePartUsed: false,
    DentsWithFinishing: false,
    DentsWithSetupTime: false,
    DentsWithAddLightMetals: false,
    IsAdditionalLM: false,
    PreDamage: false,
    AdditionLM: false,
    ContainMicroDents: false,
    IsManualDescription: true
};

export interface BaseDamageParams {
    DATProcessId: number;
    Description: string;
    assemblyGroup: AssemblyGroup;
    SparePartNumber?: string;
    SparePartPrice?: number;
    SparePartDiscount?: number;
    WorkTime?: number;
    WorkPrice?: number;
    WorkType?: string | null;
    WorkLevel?: string | null;
    WorkManualInput?: '#' | '';
    PreDamage?: boolean;
    PredamagePercentage?: number | null;
}

interface BaseDamageWorkParams {
    DATProcessId: number;
    Description: string;
    assemblyGroup: AssemblyGroup;
    WorkTime?: number;
    WorkPrice?: number;
    WorkType?: string | null;
    WorkLevel?: string | null;
    PreDamage?: boolean;
}

interface BaseDamageLacquerParams {
    DATProcessId: number;
    Description: string;
    assemblyGroup: AssemblyGroup;
    LacquerLevel: string;
    LacquerLevelId: number;
    WorkTime?: number;
    LacquerPrice?: number;
    SparePartDiscount?: number;
    PreDamage?: boolean;
    PredamagePercentage?: number | null;
}

type BaseDamagePZSTParams = BaseDamageWorkParams & {
    repairType?: RepairType;
};

const delDamage = createEvent<number | undefined>();
const addRepairPositionsWithExistenceCheck = createEvent<RepairPosition[]>();
const addReplaceEDamage = createEvent<BaseDamageParams | undefined>();
const addOverhaulIDamage = createEvent<BaseDamageWorkParams>();
const addFixingSlashDamage = createEvent<BaseDamageWorkParams>();
const addDisAndMountingADamage = createEvent<BaseDamageParams>();
const addLacquerLDamage = createEvent<BaseDamageLacquerParams>();
const addLacquerDemountedMDamage = createEvent<BaseDamageLacquerParams>();
const addPZSTDamage = createEvent<BaseDamagePZSTParams>();
const delRepairTypeDamage = createEvent<{ dvn: number | undefined; repairType: RepairType }>();
const toggleDamageByDVNWithDefault = createEvent<{
    dvn: Dvn;
    description: string;
    assemblyGroup: AssemblyGroup;
    defaultDamages?: AvailableDefaultDamage[];
}>();

function clearUndefinedProps(obj: any) {
    const copyObj = { ...obj };
    Object.keys(copyObj).forEach(key => copyObj[key] === undefined && delete copyObj[key]);
    return copyObj;
}

const addRepPositionWithExistCheck = (
    repairPositions: RepairPositionWithRowKey[],
    addRepPositions: RepairPosition[],
    availableAssemblyGroupsStore: AvailableAssemblyGroupsStore
) => {
    if (!addRepPositions.length) return repairPositions;

    const { availableAssemblyGroups } = availableAssemblyGroupsStore;

    const newRepPos = [...repairPositions];
    addRepPositions.forEach(addedRepPos => {
        const newRepairPosition = {
            ...defaultMandatoryRepPositionConstructor,
            ...addedRepPos,
            // Description: addedRepPos.DATProcessId + ' ' + addedRepPos.Description
            Description:
                addedRepPos.Description + ' ' + getLeftOrRightSide(availableAssemblyGroups, addedRepPos.DATProcessId)
        };

        const foundIndex = newRepPos.findIndex(
            exRepPos =>
                exRepPos.DATProcessId === addedRepPos.DATProcessId && exRepPos.RepairType === addedRepPos.RepairType
        );
        if (foundIndex === -1) {
            newRepPos.push({ key: uniqIdString(), ...newRepairPosition });
        } else {
            newRepPos[foundIndex] = {
                ...newRepPos[foundIndex],
                ...clearUndefinedProps(newRepairPosition)
            };
        }
    });

    return newRepPos;
};

sample({
    source: repairPositions,
    clock: delDamage,
    fn: (repairPositions, DATProcessId) => {
        if (!DATProcessId) return repairPositions;
        let newRepairPositions = repairPositions.filter(el => el.DATProcessId !== DATProcessId);
        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: repairPositions,
    clock: delRepairTypeDamage,
    fn: (repairPositions, { dvn, repairType }) => {
        if (!dvn) return repairPositions;
        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === dvn && el.RepairType === repairType)
        );
        return newRepairPositions;
    },
    target: setRepairPositions
});

const newDvnRepairPosition = combine(confirmedRepairPositions, repairPositions).map(
    ([confirmedRepairPositions, repairPositions]) => {
        const newPosition = Array.from(
            new Set(
                repairPositions
                    .map(repPos => repPos.DATProcessId)
                    .filter(repPosDvn => !confirmedRepairPositions.find(confPos => confPos.DATProcessId === repPosDvn))
            )
        );
        return newPosition;
    }
);

sample({
    source: [
        repairPositions,
        mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ],
    clock: addReplaceEDamage,
    fn: ([repairPositions, mappingDVNWorkTypeWorkLevel, availableAssemblyGroupsStore], addedPosition) => {
        if (!addedPosition) return;

        const {
            DATProcessId,
            Description,
            assemblyGroup,
            SparePartNumber,
            SparePartPrice,
            SparePartDiscount,
            WorkTime,
            WorkPrice,
            WorkManualInput,
            WorkType,
            WorkLevel,
            PreDamage,
            PredamagePercentage
        } = addedPosition;

        const RepairType = 'replace';
        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === DATProcessId && (el.RepairType === 'overhaul' || el.RepairType === 'fixing'))
        );

        let WorkIndication = undefined;
        if (WorkTime) WorkIndication = 1;
        if (WorkPrice) WorkIndication = 2;

        let WorkTypeVal = WorkType;
        let WorkLevelVal = WorkLevel;
        const foundRepair = newRepairPositions.find(
            el => el.DATProcessId === DATProcessId && el.RepairType === RepairType
        );
        if (typeof WorkType === 'undefined' && typeof foundRepair?.WorkType === 'undefined') {
            WorkTypeVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workType;
        }
        if (typeof WorkLevel === 'undefined' && typeof foundRepair?.WorkLevel === 'undefined') {
            WorkLevelVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workLevel;
        }

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'replace',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage,
            PredamagePercentage,
            AdditionLM: false,
            ContainMicroDents: false,
            // WearGroup: 6,
            // WearAdditional: 0,
            SparePartNumber,
            SparePartPrice,
            SparePartDiscount,
            WorkTime,
            WorkPrice,
            WorkType: WorkTypeVal,
            WorkLevel: WorkLevelVal,
            WorkIndication,
            WorkManualInput
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [
        repairPositions,
        mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ],
    clock: addOverhaulIDamage,
    fn: (
        [repairPositions, mappingDVNWorkTypeWorkLevel, availableAssemblyGroupsStore],
        { DATProcessId, Description, assemblyGroup, WorkTime, WorkPrice, WorkType, WorkLevel, PreDamage }
    ) => {
        const RepairType = 'overhaul';

        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === DATProcessId && el.RepairType === 'replace')
        );

        let WorkIndication = undefined;
        if (WorkTime) WorkIndication = 1;
        if (WorkPrice) WorkIndication = 2;

        let WorkTypeVal = WorkType;
        let WorkLevelVal = WorkLevel;
        const foundRepair = newRepairPositions.find(
            el => el.DATProcessId === DATProcessId && el.RepairType === RepairType
        );
        if (typeof WorkType === 'undefined' && typeof foundRepair?.WorkType === 'undefined') {
            WorkTypeVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workType;
        }
        if (typeof WorkLevel === 'undefined' && typeof foundRepair?.WorkLevel === 'undefined') {
            WorkLevelVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workLevel;
        }

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'overhaul',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            WorkTime: WorkTime || undefined,
            WorkPrice: WorkPrice || undefined,
            WorkType: WorkTypeVal,
            WorkLevel: WorkLevelVal,
            WorkIndication,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            PreDamage,
            AdditionLM: false,
            ContainMicroDents: false
            // WearGroup: 6,
            // WearAdditional: 0
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );
        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [
        repairPositions,
        mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ],
    clock: addFixingSlashDamage,
    fn: (
        [repairPositions, mappingDVNWorkTypeWorkLevel, availableAssemblyGroupsStore],
        { DATProcessId, Description, assemblyGroup, WorkTime, WorkPrice, WorkType, WorkLevel, PreDamage }
    ) => {
        const RepairType = 'fixing';

        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === DATProcessId && el.RepairType === 'replace')
        );

        let WorkIndication = undefined;
        if (WorkTime) WorkIndication = 1;
        if (WorkPrice) WorkIndication = 2;

        let WorkTypeVal = WorkType;
        let WorkLevelVal = WorkLevel;
        const foundRepair = newRepairPositions.find(
            el => el.DATProcessId === DATProcessId && el.RepairType === RepairType
        );
        if (typeof WorkType === 'undefined' && typeof foundRepair?.WorkType === 'undefined') {
            WorkTypeVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workType;
        }
        if (typeof WorkLevel === 'undefined' && typeof foundRepair?.WorkLevel === 'undefined') {
            WorkLevelVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[RepairType]?.workLevel;
        }

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'fixing',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            WorkTime: WorkTime || undefined,
            WorkPrice: WorkPrice || undefined,
            WorkType: WorkTypeVal,
            WorkLevel: WorkLevelVal,
            WorkIndication,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage,
            AdditionLM: false,
            ContainMicroDents: false
            // WearGroup: 6,
            // WearAdditional: 0
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [repairPositions, availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore],
    clock: addDisAndMountingADamage,
    fn: ([repairPositions, availableAssemblyGroupsStore], { DATProcessId, Description, assemblyGroup }) => {
        const RepairType = 'dis- and mounting';

        let newRepairPositions = repairPositions;

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'dis- and mounting',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false
            // WearGroup: 6,
            // WearAdditional: 0
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [repairPositions, availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore],
    clock: addLacquerLDamage,
    fn: (
        [repairPositions, availableAssemblyGroupsStore],
        {
            DATProcessId,
            Description,
            assemblyGroup,
            LacquerLevel,
            LacquerLevelId,
            WorkTime,
            LacquerPrice,
            SparePartDiscount,
            PreDamage,
            PredamagePercentage
        }
    ) => {
        const RepairType = 'lacquer';

        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === DATProcessId && el.RepairType === 'lacquer demounted')
        );

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'lacquer',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            LacquerLevel,
            LacquerLevelId,
            LacquerLevelBaseId: 2,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage,
            PredamagePercentage,
            AdditionLM: false,
            ContainMicroDents: false,
            // WearGroup: 6,
            // WearAdditional: 0
            WorkTime,
            LacquerPrice,
            SparePartDiscount
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [repairPositions, availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore],
    clock: addLacquerDemountedMDamage,
    fn: (
        [repairPositions, availableAssemblyGroupsStore],
        {
            DATProcessId,
            Description,
            assemblyGroup,
            LacquerLevel,
            LacquerLevelId,
            WorkTime,
            LacquerPrice,
            SparePartDiscount,
            PreDamage,
            PredamagePercentage
        }
    ) => {
        const RepairType = 'lacquer demounted';

        let newRepairPositions = repairPositions.filter(
            el => !(el.DATProcessId === DATProcessId && el.RepairType === 'lacquer')
        );

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType, // : 'lacquer demounted',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            LacquerLevel,
            LacquerLevelId,
            LacquerLevelBaseId: 2,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage,
            PredamagePercentage,
            AdditionLM: false,
            ContainMicroDents: false,
            // WearGroup: 6,
            // WearAdditional: 0
            WorkTime,
            LacquerPrice,
            SparePartDiscount
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: [
        repairPositions,
        mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ],
    clock: addPZSTDamage,
    fn: (
        [repairPositions, mappingDVNWorkTypeWorkLevel, availableAssemblyGroupsStore],
        { DATProcessId, Description, assemblyGroup, WorkTime, WorkPrice, repairType, WorkType, WorkLevel }
    ) => {
        let newRepairPositions = repairPositions;

        let WorkIndication = undefined;
        if (WorkTime) WorkIndication = 1;
        if (WorkPrice) WorkIndication = 2;

        if (!repairType) return repairPositions;

        let WorkTypeVal = WorkType;
        let WorkLevelVal = WorkLevel;
        const foundRepair = newRepairPositions.find(
            el => el.DATProcessId === DATProcessId && el.RepairType === repairType
        );
        if (typeof WorkType === 'undefined' && typeof foundRepair?.WorkType === 'undefined') {
            WorkTypeVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[repairType]?.workType;
        }
        if (typeof WorkLevel === 'undefined' && typeof foundRepair?.WorkLevel === 'undefined') {
            WorkLevelVal = mappingDVNWorkTypeWorkLevel?.[DATProcessId.toString()]?.[repairType]?.workLevel;
        }

        const newRecord: RepairPosition = {
            DATProcessId, // 44210,
            RepairType: repairType, // : 'visual inspection' | 'dis- and reassemble' | 'adjust' | 'technical inspection',
            Description, // 'Капот',
            ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
            ConstructionGroup: assemblyGroup.name, // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            PositionEntryType: 'graphical',
            ConstructionType: 6,
            SparePartUsed: false,
            WorkTime: WorkTime || undefined,
            WorkPrice: WorkPrice || undefined,
            WorkType: WorkTypeVal,
            WorkLevel: WorkLevelVal,
            WorkIndication,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AdditionLM: false,
            ContainMicroDents: false
            // WearGroup: 6,
            // WearAdditional: 0
        };

        newRepairPositions = addRepPositionWithExistCheck(
            newRepairPositions,
            [newRecord],
            availableAssemblyGroupsStore
        );

        return newRepairPositions;
    },
    target: setRepairPositions
});

sample({
    source: repairPositions,
    clock: toggleDamageByDVNWithDefault,
    fn: (repairPositions, { dvn, description, assemblyGroup, defaultDamages }) => {
        // найти список повреждений по dvn
        const filtredDamages = repairPositions.filter(repPos => repPos.DATProcessId === dvn.dvn);

        const absentDamages = defaultDamages?.filter(
            defDamage => !filtredDamages.find(repPos => repPos.RepairType === defDamage.RepairType)
        );

        const possibleAbsentDamage = absentDamages?.filter(damag => dvn.rcs.rc.find(rcCode => rcCode === damag.codeRC));

        let newRepairPositions: RepairPositionWithRowKey[] = [...repairPositions];
        // если повреждения уже стоят, то сбросить их
        if (possibleAbsentDamage?.length === 0) {
            newRepairPositions = repairPositions.filter(repPos => repPos.DATProcessId !== dvn.dvn);
            return newRepairPositions;
        }

        // если хватает не всех повреждеинй установить не достающие
        possibleAbsentDamage?.forEach(absentDamages => {
            newRepairPositions.push({
                key: uniqIdString(),
                ...absentDamages,
                DATProcessId: dvn.dvn,
                Description: description,
                ConstructionGroupId: assemblyGroup.assemblyGroupId, // 1,
                ConstructionGroup: assemblyGroup.name // 'ПЕР. ЧАСТЬ АВТОМОБИЛЯ НАР.',
            });
        });
        return newRepairPositions;
    },
    target: addRepairPositionsWithExistenceCheck
});

sample({
    source: [repairPositions, availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore],
    clock: addRepairPositionsWithExistenceCheck,
    fn: ([repPos, availableAssemblyGroupsStore], addRepPos) =>
        addRepPositionWithExistCheck(repPos, addRepPos, availableAssemblyGroupsStore),
    target: setRepairPositions
});

const createOrUpdateRepairPosition = createEvent<Partial<RepairPositionWithRowKey>>();

sample({
    source: repairPositions,
    clock: createOrUpdateRepairPosition,
    fn: (repairPositions, repPos) => {
        let newRepPos: RepairPositionWithRowKey = { ...repPos, key: repPos.key || uniqIdString(), DATProcessId: 0 };

        let newRepairPositions = [...repairPositions];
        const foundIndexRepPos = newRepairPositions.findIndex(itemRepPos => itemRepPos.key === repPos.key);
        if (foundIndexRepPos !== -1) {
            newRepairPositions[foundIndexRepPos] = newRepPos;
        } else {
            newRepairPositions.push(newRepPos);
        }

        return newRepairPositions;
    },
    target: setRepairPositions
});

const delRepairPositionByKey = createEvent<String[]>();

sample({
    source: repairPositions,
    clock: delRepairPositionByKey,
    fn: (repairPositions, keyForDel) => {
        let newRepairPositions = repairPositions.filter(repPos => !keyForDel.includes(repPos.key));
        return newRepairPositions;
    },
    target: setRepairPositions
});

// =====================================================================================================================

export const RepairPositionsModel = {
    store: {
        repairPositions,
        confirmedRepairPositions,
        isModified,
        isInitModified,
        newDvnRepairPosition
    },

    event: {
        initRepairPosition,
        setRepairPositions,

        setConfirmedRepairPositions,

        addRepairPositionsWithExistenceCheck,
        delRepairPositionByKey,
        createOrUpdateRepairPosition,
        delDamage,

        delRepairTypeDamage,

        addReplaceEDamage,
        addOverhaulIDamage,
        addFixingSlashDamage,
        addDisAndMountingADamage,
        addLacquerLDamage,
        addLacquerDemountedMDamage,
        addPZSTDamage,
        toggleDamageByDVNWithDefault
    }
};
