import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const StepAction = styled.div`
    min-width: 25%;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    box-shadow: 0px 8px 32px ${({ theme }) => theme.colors.gray[50]}, 0px -8px 32px ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10px;
    margin: 20px 0;

    ${media.phone`
    min-width: 90%;
`}
`;
export const StepButton = styled(Button)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 0 5px;
`;
export const StepButtonBack = styled(Button)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 0 5px;

    ${media.phone`
    width: 30%;
`}
`;
export const TitleBack = styled.span`
    margin-right: 5px;

    ${media.phone`
    display: none;
`}
`;
export const Title = styled.span`
    margin-left: 5px;
`;
