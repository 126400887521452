import { getDefaultLocale, registerLocale, setDefaultLocale } from 'react-datepicker';
import { events } from './store';

import { getUserDateLocale } from '@wedat/ui-kit/components/Datepicker/utils';

export const setUserDateLocale = async (language: string) => {
    const currentDateLocale = getDefaultLocale();

    // Define date locale and date format
    if (currentDateLocale !== language) {
        const { userLocale, dateFormat } = getUserDateLocale(language as DAT2.Locale);

        events.setDateFormat(dateFormat);

        if (userLocale) {
            registerLocale(language, userLocale);
            setDefaultLocale(language);
        }
    }
};
