import React, { FC } from 'react';
import { Formik } from 'formik';
import { useStore } from 'effector-react';
import _ from 'lodash';

import { initialFormikValues } from '../../constants/initialFormikValues';
import { commonStores } from '../../stores/common';

import { Container } from './styles';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { EquipmentTransfer } from '../EquipmentTransfer';
import { Preloader } from '@wedat/ui-kit/components/Preloader';
import { useMedia } from '@dat/core/hooks/useMedia';

interface Props {
    closeEquipmentModal: () => void;
}

export const Main: FC<Props> = ({ closeEquipmentModal }) => {
    const isLoading = useStore(commonStores.isLoading);
    const isMobile = useMedia(sizes.laptop);

    return (
        <Formik initialValues={initialFormikValues} validateOnBlur={false} validateOnChange={false} onSubmit={_.noop}>
            <Container>
                <EquipmentTransfer isMobile={isMobile} closeEquipmentModal={closeEquipmentModal} />
                <Preloader isLoading={isLoading} />
            </Container>
        </Formik>
    );
};
