import { format } from 'date-fns';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { getParsedArraySafe } from '@dat/api2/utils';
import { DEFAULT_DATE_FORMAT } from '@dat/core/constants/dateFormats';

export const extractOCRFromContract = (
    contract: DAT2.ContractFromGetContract
): DAT2.Field.EntryForRequest<string, DAT2.Field.Primitive>[] => {
    const entries = getParsedArraySafe(contract?.customTemplateData?.entry);
    const OCRresultEntry = entries
        .filter(entry => {
            const OCR = CONTRACT_ENTRIES_KEYS.MEMO.OCR as { [key: string]: string };
            return OCR[entry.key];
        })
        .map(filtredEntry => {
            const value =
                filtredEntry.key === 'OCRPlateScanDate' || filtredEntry.key === 'OCRVINScanDate'
                    ? format(new Date(filtredEntry.value?._text.toString()), DEFAULT_DATE_FORMAT)
                    : filtredEntry.value?._text;

            return { ...filtredEntry, value };
        });

    return OCRresultEntry;
};
