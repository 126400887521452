import React from 'react';
import { SelectField, SelectFieldProps } from '@wedat/ui-kit/Formik';
import { KeyValueOption } from '@wedat/ui-kit/components/Select';
import { getOptions } from './utils';
import { useValueAutoSelection } from '@dat/core/hooks/useValueAutoSelection';

export interface CustomSelectProps extends SelectFieldProps {
    options: KeyValueOption[];
    idLength?: number;
    defaultWhiteSpaceOption?: boolean;
}

export const CustomSelectField: React.FC<CustomSelectProps> = props => {
    const selectOptions = getOptions(props.options);

    useValueAutoSelection<KeyValueOption>(props);

    return <SelectField {...props} options={selectOptions} valueKey="key" />;
};
