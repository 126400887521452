import { guard } from 'effector';
import { vehicleImagesEffects } from './index';
import { contractEvents } from '@dat/shared-models/contract';

const { getVehicleImagesFx } = vehicleImagesEffects;
const { contractReceived } = contractEvents;

const payloadForImages = contractReceived.map(contract => ({
    datECode: contract.Dossier?.Vehicle?.DatECode,
    aspect: 'ANGULARFRONT'
}));

guard({
    source: payloadForImages,
    // @ts-ignore
    filter: (payload): payload is DAT2.Request.GetVehicleImagesN => !!payload.datECode,
    target: getVehicleImagesFx
});
