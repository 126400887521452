import React from 'react';
import { InputField, SelectField, InputFieldProps, SelectFieldProps } from '@wedat/ui-kit/Formik';
import { Container, RightSelectInput, LeftInputWrapper } from './styles';

interface Props {
    inputProps: InputFieldProps;
    selectProps: SelectFieldProps;
}

export const InputWithSelect: React.FC<Props> = ({ inputProps, selectProps }) => (
    <Container>
        <LeftInputWrapper>
            <InputField {...inputProps} />
        </LeftInputWrapper>

        <RightSelectInput>
            <SelectField {...selectProps} />
        </RightSelectInput>
    </Container>
);
