import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { decreaseTypeSelectOptions } from '../constants';

import { NoWrap, Content } from '../styles';
import { initialManualFields } from '../../../../constants/initialManualFields';
import { ManualFields } from '../../../../types/manualFields';

import { DatNumberDisplayField } from '../../../DatNumberDisplayField';

import { Table } from '@wedat/ui-kit/components/Table';
import { EvaluationNumberInputField } from '../../../evaluationFields/Input';
import { EvaluationSelectField } from '../../../evaluationFields/Select';

interface Props {
    isMobile: boolean;
}

export const LumpSumValuation: React.FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();
    const {
        values: {
            Valuation: {
                ManualEquipmentOriginalPrice = initialManualFields.Valuation.ManualEquipmentOriginalPrice,
                EquipmentPercentage = initialManualFields.Valuation.EquipmentPercentage,
                EquipmentPrice
            }
        }
    } = useFormikContext<ManualFields>();

    const leadingNewPrice = ManualEquipmentOriginalPrice * (EquipmentPercentage / 100);
    const trailingCorrectionPercentageForFlatEquipmentEvaluation = 100 - EquipmentPercentage;
    const trailingNewPrice = ManualEquipmentOriginalPrice - leadingNewPrice;

    //TODO: Simone should explain how these values are calculated
    const leadingResidualValue = NaN;
    const trailingResidualValue = NaN;

    const isFieldsDisabled = EquipmentPrice !== undefined;

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'col1'
            },
            {
                Header: '',
                accessor: 'col2'
            },
            {
                Header: '',
                accessor: 'col3'
            },
            {
                Header: '',
                accessor: 'col4'
            },
            {
                Header: '',
                accessor: 'col5'
            }
        ],
        []
    );

    const data = useMemo(
        () => [
            {
                col1: (
                    <NoWrap>
                        {t('equipmentValuation.Lump sum of total equipment value')}
                        &nbsp;&nbsp;
                        <EvaluationNumberInputField
                            fixedDecimalScale={false}
                            name="Valuation.ManualEquipmentOriginalPrice"
                            disabled={isFieldsDisabled}
                            placeholder={isMobile ? 'Manually' : ''}
                        />
                    </NoWrap>
                ),
                col2: (
                    <NoWrap>
                        <EvaluationNumberInputField
                            min={-999}
                            max={999}
                            fixedDecimalScale={false}
                            name="Valuation.EquipmentPercentage"
                            disabled={isFieldsDisabled}
                            placeholder={isMobile ? 'Manually' : ''}
                            label="%"
                        />
                    </NoWrap>
                ),
                col3: <DatNumberDisplayField value={leadingNewPrice} />,
                col4: (
                    <EvaluationSelectField
                        options={decreaseTypeSelectOptions}
                        name="Valuation.EquipmentDecreaseType"
                        disabled={isFieldsDisabled}
                    />
                ),
                col5: <DatNumberDisplayField value={leadingResidualValue} />
            },
            {
                col1: '',
                col2: (
                    <>
                        <DatNumberDisplayField
                            fixedDecimalScale={false}
                            suffix=" %"
                            value={trailingCorrectionPercentageForFlatEquipmentEvaluation}
                        />
                    </>
                ),
                col3: <DatNumberDisplayField value={trailingNewPrice} />,
                col4: (
                    <EvaluationSelectField
                        options={decreaseTypeSelectOptions}
                        name="Valuation.EquipmentDecreaseTypeRemaining"
                        disabled={isFieldsDisabled}
                    />
                ),
                col5: <DatNumberDisplayField value={trailingResidualValue} />
            }
        ],
        [
            t,
            isFieldsDisabled,
            isMobile,
            leadingNewPrice,
            leadingResidualValue,
            trailingCorrectionPercentageForFlatEquipmentEvaluation,
            trailingNewPrice,
            trailingResidualValue
        ]
    );

    return (
        <Content>
            <Table columns={columns} data={data} breakpoint="laptop" mobileGridColAmount={1} />
        </Content>
    );
};
