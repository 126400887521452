import { useStore } from 'effector-react';
import { getIn, useField } from 'formik';

import { dossierStores } from '../stores/dossier';
import { namesOfPriceDependentFields } from '../constants/namesOfPriceDependentFields';
import { useShouldShowGrossValues } from './useShouldShowGrossValues';

interface Param {
    baseName: string;
    fieldType: 'input' | 'display';
}

interface Result {
    activeName: string; // currently used name based on "Price" radio-group value
    inactiveName: string; // currently inactive name based on "Price" radio-group value
    activeValue: number | undefined; // currently used value based on "Price" radio-group value
    inactiveValue: number | undefined; // currently inactive value based on "Price" radio-group value
}

//TODO: describe work logic
export const usePriceDependentField = ({ baseName, fieldType }: Param): Result => {
    const shouldShowGrossValues = useShouldShowGrossValues();
    const isFieldPriceDependent = namesOfPriceDependentFields.includes(baseName);
    const isGrossFieldActive = isFieldPriceDependent && shouldShowGrossValues;

    const grossName = addGrossSuffix(baseName);
    const activeName = isGrossFieldActive ? grossName : baseName;
    const inactiveName = isGrossFieldActive ? baseName : grossName;

    const valuesObject = {
        input: useCalculateInputValues({ baseName, isGrossFieldActive }),
        display: useCalculateDisplayValues(baseName)
    };

    const { baseValue, grossValue } = valuesObject[fieldType];
    const activeValue = isGrossFieldActive ? grossValue : baseValue;
    const inactiveValue = isGrossFieldActive ? baseValue : grossValue;

    return {
        activeName,
        inactiveName,
        activeValue: Number.isNaN(activeValue) ? undefined : activeValue,
        inactiveValue: Number.isNaN(inactiveValue) ? undefined : inactiveValue
    };
};

interface UseCalculateInputValuesParam {
    baseName: string;
    isGrossFieldActive: boolean;
}

const useCalculateInputValues = ({ baseName, isGrossFieldActive }: UseCalculateInputValuesParam) => {
    const grossToBaseRatio = useGrossToBaseRatio();

    let [{ value: baseValue }] = useField<number | undefined>(baseName);
    let [{ value: grossValue }] = useField<number | undefined>(addGrossSuffix(baseName));

    baseValue = Number(baseValue);
    grossValue = Number(grossValue);

    // When "Net" radio-button is selected, gross-value should be calculated from net-value
    const shouldCalculateGrossFromBase = !isGrossFieldActive;
    // When "Gross" radio-button is selected, net-value should be calculated from gross-value
    const shouldCalculateBaseFromGross = isGrossFieldActive;

    if (shouldCalculateGrossFromBase) {
        grossValue = baseValue * grossToBaseRatio;
    }
    if (shouldCalculateBaseFromGross) {
        baseValue = grossValue / grossToBaseRatio;
    }

    return {
        baseValue,
        grossValue
    };
};

const useCalculateDisplayValues = (baseName: string) => {
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);
    const grossToBaseRatio = useGrossToBaseRatio();

    let baseValue: number | undefined = getIn(dossierWithExtractedTextValues, baseName);
    let grossValue: number | undefined = getIn(dossierWithExtractedTextValues, addGrossSuffix(baseName));

    if (grossValue === undefined) {
        grossValue = Number(baseValue) * grossToBaseRatio;
    }

    return {
        baseValue,
        grossValue
    };
};

/* Result of the division `grossValue / baseValue` */
const useGrossToBaseRatio = () => {
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);
    const vatRate = dossierWithExtractedTextValues.VAT?.VatAtValuationTime || 0;

    return 1 + vatRate / 100;
};

const addGrossSuffix = (baseName: string) => baseName + 'Gross';
