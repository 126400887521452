import styled, { css } from 'styled-components/macro';
import { WrapperStyled } from '@wedat/ui-kit/components/Tabs/styles';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { isIOS } from 'react-device-detect';

export const Root = styled.div``;

export const Wrapper = styled.div`
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;

    ${media.tablet`
        padding-bottom: 76px;

        ${
            isIOS &&
            css`
                padding-bottom: 96px;
            `
        }
    `}

    ${media.phone`
      padding-left: 17px;
      padding-right: 17px;
    `}
`;
export const FormBuilderWrapper = styled.div`
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const TabsWrapper = styled.div`
    margin-top: 20px;
    padding-left: 50px;

    ${media.phone`
      padding-left: 17px;
    `}

    ${WrapperStyled} {
        overflow-x: auto;

        & button {
            min-width: 200px;

            ${media.phone`
                min-width: unset;
                width: fit-content;
                padding-right: 20px;
                min-width: max-content;
                text-align: left;
                background-color: transparent;
                border-color: transparent;
            `}
        }

        ${media.phone`
            ${makeCustomScrollBar()}
        `}
    }
`;
