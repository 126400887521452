import { sample } from 'effector';
import { spread } from 'patronum';

import { lightboxEvents, lightboxStores } from './index';
import { attachmentsStores } from '../attachments';

const { openGroupInLightbox } = lightboxEvents;
const { images, initialImageIndex } = lightboxStores;

const { attachments } = attachmentsStores;

sample({
    clock: openGroupInLightbox,
    source: { attachments },
    fn: ({ attachments }, { initialIndex }) => ({
        images: attachments.map(({ base64 }) => base64),
        initialImageIndex: initialIndex
    }),
    target: spread({
        targets: { images, initialImageIndex }
    })
});
