import styled from 'styled-components';
import { Tooltip } from '@wedat/ui-kit';

export const StyledTooltip = styled(Tooltip)`
    width: 600px;
    height: 100%;
    position: absolute;
    z-index: 2;
`;

export const DisabledItemWrapper = styled.div`
    width: 100%;
    height: 95%;
    cursor: not-allowed;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
`;

export const ComponentWrapper = styled.div`
    position: relative;
`;
