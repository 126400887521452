import { FieldType, IGetters, FieldValueGetter, FieldKeyValueGetter, OptionValueGetter } from './types';

interface IValueBase {
    value: string | number;
    getValue(): string | number;
}

interface IValue {
    constant: string;
    type: FieldType;
}

interface IKeyValue extends IValue {
    key: string;
}

// value from field by field id
class FieldValue implements IValueBase {
    value: string;
    fieldValueGetter: FieldValueGetter;

    constructor(value: string, valueGetter: FieldValueGetter) {
        this.value = value;
        this.fieldValueGetter = valueGetter;
    }

    getValue = () => this.fieldValueGetter(this.value);
}

class StringValue implements IValueBase {
    value: string;

    constructor(value: string) {
        this.value = value;
    }

    getValue = () => this.value;
}

class NumberValue implements IValueBase {
    value: number;

    constructor(value: number) {
        this.value = value;
    }

    getValue = () => this.value;
}

// value from field key
class FieldKeyValue implements IValueBase {
    value: string;
    key: string;
    fieldValueGetter: FieldKeyValueGetter;

    constructor(value: string, key: string, valueGetter: FieldKeyValueGetter) {
        this.value = value;
        this.key = key;
        this.fieldValueGetter = valueGetter;
    }

    getValue = () => this.fieldValueGetter(this.value, this.key);
}

// value from object options
class OptionValue implements IValueBase {
    value: string;
    fieldValueGetter: OptionValueGetter;

    constructor(key: string, valueGetter: OptionValueGetter) {
        this.value = key;
        this.fieldValueGetter = valueGetter;
    }

    getValue = () => this.fieldValueGetter(this.value);
}

class Value {
    data: FieldValue | StringValue | NumberValue | FieldKeyValue | OptionValue;

    constructor({ constant, type, key }: IKeyValue, getters: IGetters) {
        switch (type) {
            case FieldType.field:
                this.data = new FieldValue(constant, getters.value);
                break;
            case FieldType.string:
                this.data = new StringValue(constant);
                break;
            case FieldType.number:
                this.data = new NumberValue(Number(constant));
                break;
            case FieldType.fieldKey:
                this.data = new FieldKeyValue(constant, key, getters.key);
                break;
            case FieldType.option:
                this.data = new OptionValue(constant, getters.option);
                break;
        }
    }

    getValue = () => this.data.getValue();
}

export { Value };
