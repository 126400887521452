export const ROUTES_PARAMS_NAMES = {
    claim: {
        contractId: 'contractId'
    }
} as const;

export const ROUTES_PARAMS_VALUES = {
    claim: {
        contractId: {
            new: 'new'
        }
    }
} as const;

export const SUB_ROUTES_PATH_NAMES = {
    claim: {
        opening: 'opening',
        gallery: 'gallery',
        fastTrack: 'fast-track',
        grapa: 'grapa',
        calculation: 'calculation',
        valuation: 'valuation',
        scheduler: 'scheduler',
        partSelection: 'part-selection',
        labourRatesGeneric: 'labour-rates-generic'
    },
    inbox: {
        guided: 'guided',
        scheduler: 'scheduler'
    },
    administration: {
        partSelection: 'part-selection',
        labourRatesGeneric: 'labour-rates-generic'
    }
} as const;

export const ROUTES = {
    inbox: {
        root: '/',
        guided: `/${SUB_ROUTES_PATH_NAMES.inbox.guided}`,
        scheduler: `/${SUB_ROUTES_PATH_NAMES.inbox.scheduler}`
    },
    claim: {
        root: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}`,
        opening: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.opening}`,
        gallery: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.gallery}`,
        fastTrack: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.fastTrack}`,
        grapa: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.grapa}`,
        calculation: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.calculation}`,
        valuation: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.valuation}`,
        scheduler: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.scheduler}`,
        partSelection: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.partSelection}`,
        labourRatesGeneric: `/claims/:${ROUTES_PARAMS_NAMES.claim.contractId}/${SUB_ROUTES_PATH_NAMES.claim.labourRatesGeneric}`
    },
    administration: {
        root: '/administration',
        partSelection: `/administration/${SUB_ROUTES_PATH_NAMES.administration.partSelection}`,
        labourRatesGeneric: `/administration/${SUB_ROUTES_PATH_NAMES.administration.labourRatesGeneric}`
    }
} as const;

export const ALWAYS_ALIVE_ROUTES = {
    claim: [ROUTES.claim.fastTrack, ROUTES.claim.grapa, ROUTES.claim.gallery]
} as const;
