import React, { ChangeEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { combinedVehicleIdentificationStores } from '../../../stores/vehicleIdentification';
import { vehicleSelectionModalEffects } from '../store';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { InputFieldWithButtons, InputFieldWithButtonsProps } from '../../InputFieldWithButtons';
import { vehicleSelectionStores } from '../../../stores/vehicleSelection';
import { modalsEvents } from '../../../stores/modals';
import { pluginStores } from '../../../stores/plugin';

export const VinInputField: React.FC<Partial<InputFieldWithButtonsProps>> = props => {
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const [{ value }] = useField('vin');
    const { setFieldValue } = useFormikContext();
    const country = useStore(sharedTemplateStores.templateSettings.country);
    const { lastSuccessfullyRequestedVin } = useStore(combinedVehicleIdentificationStores);
    const getVehicleByVin = (vin: string) => vehicleSelectionModalEffects.getVehicleByVinFx({ vin });
    const technicalData = useStore(vehicleSelectionStores.technicalData);

    const vinInputMask = country === 'DE' ? '*****************  |  *' : '*****************';
    const unmaskVin = (value: string) => value.replace(/[ ,|]/g, '');

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('vin', e.target.value.toUpperCase());
    };

    const toggleIsAnylineOpen = () => {
        modalsEvents.toggleIsAnylineOpen();
        pluginOptions?.pluginCarsToggleCallback?.();
    };

    return (
        <InputFieldWithButtons
            data-cy="vin"
            label="VIN"
            uppercase
            isSearchDisabled={value?.length < 17}
            mask={vinInputMask}
            isBookDisabled={!technicalData}
            unmaskFn={unmaskVin}
            status={!!lastSuccessfullyRequestedVin && lastSuccessfullyRequestedVin === value ? 'success' : 'default'}
            onSearch={getVehicleByVin}
            onChange={handleOnChange}
            {...props}
            name="vin"
            OCRclick={toggleIsAnylineOpen}
            withOCRButton
        />
    );
};
