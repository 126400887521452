import styled from 'styled-components';
import { ReactComponent as PreloaderIcon } from '../../assets/images/preloader-circle.svg';

export const StyledPreloaderIcon = styled(PreloaderIcon)`
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    color: ${p => p.style?.color || p.color || p.theme.colors.primary};
    animation: rotate 1s infinite linear;
`;
