import React from 'react';
import { useStore } from 'effector-react';
import { useRouteMatch } from 'react-router-dom';

import { containedPluginsStores } from '../../../stores/containedPlugins';
import { ROUTES } from '../../../constants/router';
import { useBlink } from './useBlink';

import { Grapa } from '@dat/grapa';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { isIOS } from 'react-device-detect';
import { GrapaStyled } from './styles';

export const GrapaPage: React.FC = () => {
    const options = useStore(containedPluginsStores.grapaPluginOptions);
    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const match = useRouteMatch(ROUTES.claim.grapa);
    const blink = useBlink([options]);

    const shouldMount = isVehicleIdentified && (blink || match);

    if (shouldMount) {
        return (
            <GrapaStyled isIOS={isIOS}>
                <Grapa options={options} />
            </GrapaStyled>
        );
    }

    return null;
};
