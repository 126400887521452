import { Main } from './Main';
import { Details } from './Details';
import { Slider } from './Main/Slider';
import { useMedia } from '@dat/core/hooks/useMedia';
import { Appointment, EfficiencyContainer, MainWrapper } from './styles';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { AppointmentItem } from '../AppointmentItem';
import { useStore } from 'effector-react';
import { schedulerStores } from '@dat/shared-models/scheduler';
import { configurationStores } from '../../stores/configuration';
import { pluginStores } from '../../stores/plugin';

export const Efficiency = () => {
    const isTablet = useMedia(sizes.tabletSmall);
    const todayAppointments = useStore(schedulerStores.todayAppointments);
    const config = useStore(configurationStores.configuration);

    const isGaugeEnabled = !useStore(pluginStores.pluginOptions)?.settings?.efficiency?.gaugeDisabled;

    const renderAppointment = () => (
        <Appointment>
            {todayAppointments.length > 0 ? (
                <AppointmentItem
                    registrationNumber={todayAppointments[0].registrationNumber}
                    id={todayAppointments[0].id}
                    address={todayAppointments[0].address}
                    userInfo={todayAppointments[0].customerInfo}
                />
            ) : (
                <AppointmentItem />
            )}
        </Appointment>
    );

    return (
        <>
            {isTablet ? (
                <>
                    <Slider>
                        {config?.appointmentsVisibility && renderAppointment()}
                        <Details />
                        {isGaugeEnabled && <Main />}
                    </Slider>
                </>
            ) : (
                <EfficiencyContainer isVisibleAppointment={config?.appointmentsVisibility}>
                    {config.appointmentsVisibility && renderAppointment()}
                    <MainWrapper isVisibleAppointment={config?.appointmentsVisibility}>
                        <Details />
                        {isGaugeEnabled && <Main />}
                    </MainWrapper>
                </EfficiencyContainer>
            )}
        </>
    );
};
