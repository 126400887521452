import { Col, Row } from 'antd';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { partsInfoModel } from '../../stores/partsInfoModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';

import './partsInfo.css';

export const PriceHistory: FC<{ partNumber: string }> = ({ partNumber }) => {
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);
    const resultGetExtPartNoInfoByModelAndExtPartNo = useStore(
        partsInfoModel.stores.resultGetExtPartNoInfoByModelAndExtPartNo
    );

    const country = pluginOptions?.settings?.locale?.country;

    const dossier = resultGetExtPartNoInfoByModelAndExtPartNo?.Dossiers.Dossier.find(
        dos => dos.Vehicle.DatECode === datECode
    );

    // TEMPORARILY bugfix: removed leading letter "A" in mercedes part numbers, which breaks getExtPartNoInfoByModelAndExtPartNo request
    // TODO: define a transcodification for mercedes part numbers
    const partNumberVerified = datECode.slice(2, 5) === '570' ? partNumber.slice(1) : partNumber;

    const sparePartPositions = dossier?.SparePartPositions.SparePartPosition;
    const sparePartPositionsByPartNumber = sparePartPositions?.find(
        part => part.PartNumber.toString() === partNumberVerified
    );

    return (
        <div>
            <h2>{partNumber}</h2>
            <Row gutter={[8, 8]} className="partsInfoPriceHistory">
                <Col span={12}>{new Date(sparePartPositionsByPartNumber?.LastUPEDate || '').toLocaleDateString()}</Col>
                <Col span={12}>
                    {new Intl.NumberFormat(country, {
                        minimumFractionDigits: 2
                    }).format(sparePartPositionsByPartNumber?.LastUPE || 0)}
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="partsInfoPriceHistory">
                <Col span={12}>
                    {new Date(sparePartPositionsByPartNumber?.SecondtoLastUPEDate || '').toLocaleDateString()}
                </Col>
                <Col span={12}>
                    {new Intl.NumberFormat(country, {
                        minimumFractionDigits: 2
                    }).format(sparePartPositionsByPartNumber?.SecondtoLastUPE || 0)}
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="partsInfoPriceHistory">
                <Col span={12}>
                    {new Date(sparePartPositionsByPartNumber?.ThirdtoLastUPEDate || '').toLocaleDateString()}
                </Col>
                <Col span={12}>
                    {new Intl.NumberFormat(country, {
                        minimumFractionDigits: 2
                    }).format(sparePartPositionsByPartNumber?.ThirdtoLastUPE || 0)}
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="partsInfoPriceHistory">
                <Col span={12}>
                    {new Date(sparePartPositionsByPartNumber?.FourthtoLastUPEDate || '').toLocaleDateString()}
                </Col>
                <Col span={12}>
                    {new Intl.NumberFormat(country, {
                        minimumFractionDigits: 2
                    }).format(sparePartPositionsByPartNumber?.FourthtoLastUPE || 0)}
                </Col>
            </Row>
        </div>
    );
};
