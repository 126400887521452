import React, { FC, SetStateAction, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modalsEvents } from '../../../stores/ui/modals';
import { commonEvents } from '../../../stores/common';

import {
    BinIcon,
    CarIcon,
    CheckSmoothIcon,
    EraserIcon,
    GearIcon,
    InfoIcon,
    PlusIcon
} from '@wedat/ui-kit/components/Icons';
import {
    ButtonIconStyled,
    ButtonIconStyledWithMargin,
    ButtonStyledDelete,
    Container,
    IconWrapper,
    LeftButtons,
    LeftSideWrapper,
    StyledTooltip,
    TextStyled
} from './styles';
import { ActionButtons } from '../../ActionButtons';
import { INITIAL_POSITION } from '../../../constants/positions';
import { positionsEffects, positionsEvents, positionsStores } from '../../../stores/positions';
import { PositionsFormValues } from '../../../types/positions';
import { contractStores } from '@dat/shared-models/contract';
import { PayloadForSavePositions } from '../../../stores/positions/types';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    selectedPositionIndexes: number[];
    setSelectedPositionIndexes: React.Dispatch<SetStateAction<number[]>>;
    isMobile: boolean;
}

export const Header: FC<Props> = ({ selectedPositionIndexes, setSelectedPositionIndexes, isMobile }) => {
    const { t } = useTranslation();
    const [{ value: positions }, , positionsHelpers] = useField<DAT2.PositionItaly[]>('positions');
    const { values, initialValues, resetForm } = useFormikContext<PositionsFormValues>();
    const contractId = useStore(contractStores.contractId);

    const handleSave = useCallback((savePayload: PayloadForSavePositions) => {
        positionsEffects.savePositionsFx(savePayload);
    }, []);

    const handleRemove = () => {
        commonEvents.setIsLoading(true);
        const newPositions = positions.filter((_, index) => !selectedPositionIndexes.includes(index));
        const deletedPositions = positions.filter((_, index) => selectedPositionIndexes.includes(index));

        positionsEvents.collectDeletedPositions(deletedPositions);
        positionsEvents.collectDeletedPositionsIndexes(selectedPositionIndexes);

        positionsHelpers.setValue(newPositions);
        setSelectedPositionIndexes([]);

        const aggregateComponents = values.positions.filter(pos => pos.Type === 'aggregateComponent');

        const savePayload = {
            aggregateComponents,
            positions: newPositions,
            labourItems: values.labourItems,
            paintworkItems: values.paintworkItems,
            totalSumsItems: values.totalSumsItems,
            initialFormPositions: initialValues.positions,
            contractId
        };

        handleSave(savePayload);
    };

    const pushInitialPosition = () => {
        positionsHelpers.setValue([...positions, INITIAL_POSITION]);
    };

    return (
        <Container>
            <LeftSideWrapper>
                <Text font="semiHeading" fontWeight={700}>
                    {t('positions.title')}
                </Text>
                <LeftButtons>
                    {/* TODO: disabled until we have legit info */}
                    <StyledTooltip text={t('header.info')} width="auto" placement="top-start">
                        <ButtonIconStyled disabled onClick={modalsEvents.toggleIsModalInfoOpen}>
                            <IconWrapper>
                                <InfoIcon />
                            </IconWrapper>
                        </ButtonIconStyled>
                    </StyledTooltip>

                    <StyledTooltip text={t('header.addManualPosition')} width="auto" placement="top-start">
                        <ButtonIconStyled onClick={pushInitialPosition}>
                            <IconWrapper>
                                <PlusIcon />
                            </IconWrapper>
                        </ButtonIconStyled>
                    </StyledTooltip>

                    <StyledTooltip text={t('header.userDefinedPosition')} width="auto" placement="top-start">
                        <ButtonIconStyled onClick={modalsEvents.toggleIsUserDefinedPositionsOpen}>
                            <IconWrapper>
                                <CheckSmoothIcon />
                            </IconWrapper>
                        </ButtonIconStyled>
                    </StyledTooltip>

                    <StyledTooltip text={t('header.workbench')} width="auto" placement="top-start">
                        <ButtonIconStyled onClick={modalsEvents.setIsWorkBenchOpen}>
                            <IconWrapper>
                                <CarIcon />
                            </IconWrapper>
                        </ButtonIconStyled>
                    </StyledTooltip>

                    <StyledTooltip text={t('header.workLogic')} width="auto" placement="top-start">
                        <ButtonIconStyled onClick={modalsEvents.setIsWorkLogicOpen}>
                            <IconWrapper>
                                <GearIcon />
                            </IconWrapper>
                        </ButtonIconStyled>
                    </StyledTooltip>
                    {isMobile && <ActionButtons isMobile={isMobile} />}
                </LeftButtons>
                {isMobile ? (
                    <>
                        {!!selectedPositionIndexes.length && (
                            <ButtonStyledDelete onClick={handleRemove}>
                                <IconWrapper>
                                    <BinIcon />
                                </IconWrapper>
                            </ButtonStyledDelete>
                        )}
                        <ButtonIconStyledWithMargin
                            onClick={() => {
                                resetForm();
                                positionsStores.deletedPositions.reset();
                            }}
                        >
                            <IconWrapper>
                                <EraserIcon />
                            </IconWrapper>
                        </ButtonIconStyledWithMargin>
                    </>
                ) : (
                    <ButtonIconStyledWithMargin
                        onClick={() => {
                            resetForm();
                            positionsStores.deletedPositions.reset();
                        }}
                    >
                        <IconWrapper>
                            <EraserIcon />
                        </IconWrapper>
                    </ButtonIconStyledWithMargin>
                )}
                {!!selectedPositionIndexes.length && !isMobile && (
                    <ButtonStyledDelete onClick={handleRemove}>
                        <IconWrapper>
                            <BinIcon />
                        </IconWrapper>
                        <TextStyled font="note">
                            {t('positionsTable.delete')} {selectedPositionIndexes.length} {t('positionsTable.lines')}
                        </TextStyled>
                    </ButtonStyledDelete>
                )}
            </LeftSideWrapper>
            {!isMobile && <ActionButtons />}
        </Container>
    );
};
