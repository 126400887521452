// PreparedManualPositions

import React, { FC, memo } from 'react';

import { Row, Col, InputNumber, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { preparedManualPositionsModel } from '../../stores/preparedManualPositionsModel';
import { repairTypeByRepairCode, RepairTypeEnum } from '../../utils/ftRepairToRepairPosition';

const { Option } = Select;

export const EditPreparedMPositions: FC<{}> = memo(
    () => {
        const { t } = useTranslation();
        const editedManualItem = useStore(preparedManualPositionsModel.store.editedManualItem);

        return (
            <Row align="middle" gutter={[8, 8]}>
                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('PreparedEditingManualRepairPositionForm.repairType')}
                </Col>
                <Col span={12}>
                    <Select
                        size="large"
                        style={{ width: '100%' }}
                        value={editedManualItem?.repairCode}
                        onChange={value => {
                            preparedManualPositionsModel.event.updateEditedManualItem({
                                repairType: repairTypeByRepairCode(value).representation,
                                repairCode: value
                            });
                        }}
                    >
                        {RepairTypeEnum.map(item => (
                            <Option key={item[1]} value={item[1]}>
                                {t('RepairType.label.' + item[1], item[3])}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('PreparedEditingManualRepairPositionForm.description')}
                </Col>
                <Col span={12}>
                    <Input
                        size="large"
                        style={{ width: '100%' }}
                        value={editedManualItem?.description}
                        onChange={e => {
                            preparedManualPositionsModel.event.updateEditedManualItem({
                                description: e.target.value
                            });
                        }}
                    />
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('PreparedEditingManualRepairPositionForm.quantity')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        min={1}
                        size="large"
                        type="number"
                        value={editedManualItem?.quantity}
                        onChange={value => {
                            preparedManualPositionsModel.event.updateEditedManualItem({
                                quantity: value
                            });
                        }}
                    />
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('PreparedEditingManualRepairPositionForm.price')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={editedManualItem?.price}
                        onChange={value => {
                            preparedManualPositionsModel.event.updateEditedManualItem({
                                price: value
                            });
                        }}
                    />
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('PreparedEditingManualRepairPositionForm.workingTime')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={editedManualItem?.workingTime}
                        onChange={value => {
                            preparedManualPositionsModel.event.updateEditedManualItem({
                                workingTime: value
                            });
                        }}
                    />
                </Col>
            </Row>
        );
    },
    () => true
);
