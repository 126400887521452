import { FC, useEffect } from 'react';
import { useTable, TableOptions, useRowSelect, TableInstance } from 'react-table';

import { useMedia } from '../../../core/hooks/useMedia';
import { sizes } from '../../mediaQueries';

import { Text } from '../Text';
import { TableContainer, THead, TBody, Tr, Th, Td, MobileContainer, Col, InnerWrapper } from './styles';
import { useTheme } from 'styled-components';

interface Props extends TableOptions<object> {
    breakpoint?: keyof typeof sizes;
    mobileHeaders?: boolean;
    rowsSelectable?: boolean;
    onTableChange?: (table: TableInstance<object>) => void;
    // if u need a certain amount of columns in mobile grid u go with the prop below
    mobileGridColAmount?: number;
    outlineLast?: boolean;
    verticalAlignTopTh?: boolean;
    withoutHeaders?: boolean;
}

export const Table: FC<Props> = ({
    columns,
    data,
    breakpoint = 'phoneBig',
    mobileHeaders = false,
    rowsSelectable,
    mobileGridColAmount,
    onTableChange,
    outlineLast,
    verticalAlignTopTh,
    withoutHeaders
}) => {
    const table = useTable({ columns, data }, useRowSelect);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds }
    } = table;
    const isMobile = useMedia(sizes[breakpoint]);
    const theme = useTheme();

    useEffect(() => {
        onTableChange?.(table);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowIds, table]);

    return isMobile ? (
        <MobileContainer>
            {rows.map((row, _i) => {
                prepareRow(row);
                return (
                    <Col {...row.getRowProps()} mobileGridColAmount={mobileGridColAmount} key={_i}>
                        {row.cells.map((cell, i) => (
                            <InnerWrapper key={i}>
                                {mobileHeaders && (
                                    <Text font="footnote" color={theme.colors.dustBlue['900']}>
                                        {cell.column.Header}
                                    </Text>
                                )}
                                <Text font="footnote" color={theme.colors.dustBlue['900']} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </Text>
                            </InnerWrapper>
                        ))}
                    </Col>
                );
            })}
        </MobileContainer>
    ) : (
        <TableContainer {...getTableProps()}>
            {!withoutHeaders && (
                <THead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th verticalAlignTopTh={verticalAlignTopTh} {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </THead>
            )}
            <TBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    return (
                        <Tr
                            outlineLast={outlineLast}
                            {...row.getRowProps()}
                            onClick={() => {
                                if (rowsSelectable) {
                                    row.toggleRowSelected();
                                }
                            }}
                            selected={row.isSelected}
                        >
                            {row.cells.map(cell => (
                                <Td outlineLast={outlineLast} {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </Td>
                            ))}
                        </Tr>
                    );
                })}
            </TBody>
        </TableContainer>
    );
};
