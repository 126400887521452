import React from 'react';
import { useStore } from 'effector-react';

import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { dialogStores } from '../../stores/dialogs';
import { pluginStores } from '../../stores/plugin';

import { MessagingArea } from '../MessagingArea';
import { DialogList } from '../DialogList';
import { Container, MobileMessagingAreaWrapper } from './styles';

export const Main = () => {
    const isDialogOpen = useStore(dialogStores.isDialogOpen);
    const pluginOptions = useStore(pluginStores.pluginOptions);

    const isMobile = useMedia(sizes.laptopSmall);
    const isComponent = pluginOptions?.isComponent;

    return (
        <Container isComponent={!!isComponent}>
            {!isMobile ? (
                <>
                    <DialogList />
                    <MessagingArea />
                </>
            ) : (
                <>
                    <DialogList />
                    <MobileMessagingAreaWrapper hidden={!isDialogOpen}>
                        <MessagingArea />
                    </MobileMessagingAreaWrapper>
                </>
            )}
        </Container>
    );
};
