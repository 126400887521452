import React from 'react';
import { FilterFolderContainer, FiltersContainer } from './styles';
import { FoldersList } from './FoldersList';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { MainFilters } from './MainFilters';

export const Filters: React.FC = () => {
    const isTabletSmall = useMedia(sizes.tabletSmall);
    return (
        <FiltersContainer>
            <FilterFolderContainer>
                <FoldersList />
            </FilterFolderContainer>

            {!isTabletSmall && <MainFilters />}
        </FiltersContainer>
    );
};
