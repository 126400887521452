import { sharedRepairCalculationStores } from '../index';
import { getParsedArraySafe } from '@dat/api2/utils';

const calcResultItaly = sharedRepairCalculationStores.repairCalculation.map(
    repairCalculation => repairCalculation?.CalcResultItaly || null
);
const positionsItaly = calcResultItaly.map(calcResult => getParsedArraySafe(calcResult?.PositionsItaly?.PositionItaly));
const summariesItaly = calcResultItaly.map(calcResult => calcResult?.SummariesItaly);

export const sharedCalcResultItalyStores = {
    calcResultItaly,
    positionsItaly,
    summariesItaly
};
