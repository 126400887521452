import styled, { css } from 'styled-components';
import { Virtuoso } from 'react-virtuoso';
import { makeCustomScrollBar } from '../../mediaQueries';
import { isIOS } from 'react-device-detect';

export const StyledVirtuoso: typeof Virtuoso = styled(Virtuoso)`
    flex-grow: 1;

    ${isIOS &&
    css`
        -webkit-overflow-scrolling: auto !important;
    `}

    ${makeCustomScrollBar()}
`;
