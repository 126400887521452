import deepmerge from 'deepmerge';
import { MergedTemplates } from '../types';

type Param = DAT2.CustomerConfiguration['templates'];
type Result = MergedTemplates;

export const mergeAllTemplatesWithDefault = (templates: Param): Result => {
    const mergedEntries = Object.entries(templates).map(([id, templateConfiguration]) => [
        id,
        deepmerge(templates.default, templateConfiguration, {
            arrayMerge: /* overwrite */ (_leftArray, rightArray) => rightArray
        })
    ]);

    return Object.fromEntries(mergedEntries);
};
