import { CONSTRUCTION_TIME_DEFAULT } from '@dat/core/constants/constructionTime';
import { getISODateString } from '@dat/core/utils';
import { RequestData } from '@dat/valuate-finance/src/types/plugin';
import { format } from 'date-fns';
import { FormikValuationValues } from '../components/ModalValuation/constants';

interface ValuateDataProps {
    datECode?: string;
    vehicle: DAT2.Vehicle | null;
    equipment?: DAT2.SingleOrArray<DAT2.EquipmentPosition>;
    contract: DAT2.ContractFromGetContract | null;
    values: FormikValuationValues;
}

export const getValuateData = ({ datECode, vehicle, equipment, contract, values }: ValuateDataProps): RequestData => {
    const { vehicleContainer, kilometers, firstRegistration } = values;
    const constructionTime = vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT;
    const container = vehicleContainer || '';
    const totalNetPrice =
        contract?.Dossier?.RepairCalculation?.CalculationSummary?.TotalNetCorrected ??
        contract?.Dossier?.RepairCalculation?.CalculationSummary?.TotalNetCosts;

    return {
        datECode: datECode || '',
        container,
        totalNetPrice,
        mileage: kilometers ? +kilometers : undefined,
        constructionTime,
        registrationDate: firstRegistration ? format(firstRegistration, 'yyyy-MM-dd') : '',
        restriction: 'APPRAISAL' as DAT2.Restriction,
        equipment: equipment as DAT2.Request.GetVehicleEvaluation['equipment'],
        evaluationDate: getISODateString(new Date())
    };
};
