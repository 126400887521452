import { BarContainer, BarWrapper, CompletedBar, DueBar, Separator } from './styles';
import { efficiencyEvents, efficiencyStores } from '../../../../stores/efficiency';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { isToday } from 'date-fns';
import { Text } from '@wedat/ui-kit';
import { useTheme } from 'styled-components';

interface BarProps {
    due: number;
    completed: number;
    max: number;
    time: Date;
}

export const Bar: React.FC<BarProps> = ({ due, completed, time, max }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const duePercent = ((due - completed) * 100) / (max || 1);
    const completedPercent = !max ? 100 : (completed * 100) / (max || 1);

    const isSelected = useStore(efficiencyStores.selected).date === time.getTime();

    return (
        <BarContainer
            selected={isSelected}
            onClick={() =>
                isSelected ? efficiencyEvents.resetSelectedDate() : efficiencyEvents.setSelectedDate(time.getTime())
            }
        >
            <Text fontWeight="bolder" fontSize="14px">
                {!!completed && (
                    <>
                        <Text fontSize="14px" color={theme.colors.green['300']}>
                            {completed}
                        </Text>
                        /
                    </>
                )}
                {due}
            </Text>
            <BarWrapper>
                <DueBar size={duePercent} completedVisible={!!completed} />
                <Separator />
                <CompletedBar size={completedPercent} dueVisible={!!duePercent} />
            </BarWrapper>
            <Text fontSize="12px" color={theme.colors.gray['500']}>
                {isToday(time) ? t('date.today') : time.getDate()}
            </Text>
        </BarContainer>
    );
};
