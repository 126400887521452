import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';
import { subscribeEffectsToToast } from '@dat/smart-components/Toast/store';
import { partSelectionEffects } from '@dat/shared-models/partSelection';

const isLoading = createIsLoadingStore(
    Object.values({
        ...partSelectionEffects
    })
);

subscribeEffectsToToast([
    ...Object.values({
        ...partSelectionEffects
    })
]);

export const commonStores = {
    isLoading
};
