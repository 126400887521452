import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Table, Checkbox } from 'antd';

import { DataEquipment, equipmentModel } from '../../stores/equipmentModel';
import { CheckOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { rimsListModel } from '../../stores/rimsListModel';
import { sparePartVariantsModel } from '../../stores/sparePartVariantsModel';

const { SvgLoader } = require('@wedat/react-svgmt');

export const EquipmentTable: FC<{ debugging: boolean; filteredDataSource: DataEquipment[] }> = ({
    debugging,
    filteredDataSource
}) => {
    const { t } = useTranslation();
    const getSparePartVariantsFxPending = useStore(sparePartVariantsModel.effects.getSparePartVariantsFx.pending);
    const vinEquipments = useStore(equipmentModel.stores.vinEquipments);
    const eqIdRim = useStore(rimsListModel.stores.eqIdRim);

    const selectedRowKeys: string[] = filteredDataSource
        .filter(item => (item.series || item.vin || item.existing) !== item.selected)
        .map(item => item.key);

    function getRimsIfPresent(eqId: string | undefined) {
        if (!eqId) return;
        const foundRim = eqIdRim.find(id => id.id === eqId?.toString());
        if (!foundRim) return;
        return (
            <div style={{ width: '50px', float: 'right' }}>
                <SvgLoader width={50} height={50} svgXML={foundRim.rim.svgWithCoordinates.svgStr} />
            </div>
        );
    }

    const EquipmentDescription = ({ record }: { record: DataEquipment }) => (
        <>
            {getRimsIfPresent(record.DatEquipmentId?.toString())}
            <div>{'' + record.DatEquipmentId + ' ' + record.Description}</div>

            {record.ContainedEquipmentPositions?.EquipmentPosition.length && (
                <>
                    {record.ContainedEquipmentPositions?.EquipmentPosition.map(ep => (
                        <>
                            {getRimsIfPresent(ep.DatEquipmentId?.toString())}
                            <br />
                            &nbsp; &nbsp; {'- ' + ep.DatEquipmentId + ' ' + ep.Description}
                        </>
                    ))}
                </>
            )}
        </>
    );

    let columns: any = [
        {
            title: t('EquipmentList.ser'),
            dataIndex: 'series',
            render: (_text: any, record: DataEquipment, _index: any) =>
                record.series || !!record.existing ? <CheckOutlined /> : '',
            align: 'center',
            width: '1em'
        },
        {
            title: t('EquipmentList.vin'),
            dataIndex: 'vin',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },

        {
            title: t('EquipmentList.Description'),
            dataIndex: 'Description',
            render: (_text: any, record: DataEquipment, _index: any) => <EquipmentDescription record={record} />
        }
    ];

    let columnsDebug: any = [
        {
            title: t('EquipmentList.possible'),
            dataIndex: 'possible',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        {
            title: t('EquipmentList.existing'),
            dataIndex: 'existing',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        {
            title: t('EquipmentList.ser'),
            dataIndex: 'series',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        {
            title: t('EquipmentList.vin'),
            dataIndex: 'vin',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        // {
        //     title: 'DVN',
        //     dataIndex: 'dvn',
        //     render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
        //     align: 'center',
        //     width: '1em'
        // },
        {
            title: t('EquipmentList.DVN'),
            dataIndex: 'dvn',
            align: 'center',
            width: '1em',
            render: (_text: any, record: DataEquipment, _index: any) => (
                <>
                    {!!record.dvns?.length && (
                        <>
                            {record.dvns.map(dvn => (
                                <>
                                    {dvn} <br />
                                </>
                            ))}
                        </>
                    )}
                </>
            )
        },

        {
            title: t('EquipmentList.deselected'),
            dataIndex: 'deselected',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        {
            title: t('EquipmentList.special'),
            dataIndex: 'special',
            render: (text: any, _record: any, _index: any) => (!!text ? <CheckOutlined /> : ''),
            align: 'center',
            width: '1em'
        },
        // {
        //     title: 'Id',
        //     dataIndex: 'DatEquipmentId',
        //     align: 'center'
        // },
        {
            title: t('EquipmentList.Description'),
            dataIndex: 'Description',
            render: (_text: any, record: DataEquipment, _index: any) => <EquipmentDescription record={record} />
        },

        {
            title: t('EquipmentList.Category'),
            dataIndex: 'Category',
            align: 'center'
        },
        {
            title: t('EquipmentList.EquipmentGroup'),
            dataIndex: 'EquipmentGroup',
            align: 'center'
        },
        {
            title: t('EquipmentList.EquipmentClass'),
            dataIndex: 'EquipmentClass',
            align: 'center'
        },
        {
            title: t('EquipmentList.AddedByLogikCheck'),
            dataIndex: 'AddedByLogikCheck',
            align: 'center'
        },
        {
            title: t('EquipmentList.ManufacturerCode'),
            dataIndex: 'ManufacturerCode',
            align: 'center'
        }
    ];

    if (!vinEquipments?.VINEquipment.length) columns = columns.filter((item: any) => item.dataIndex !== 'vin');

    function onRecordClick(record: DataEquipment) {
        equipmentModel.events.changeEquipment(record);
    }

    return (
        <Table
            style={{ width: '100%' }}
            rowSelection={{
                selectedRowKeys,
                columnWidth: 1,
                renderCell: (_text, record) => <Checkbox checked={record.selected} />,
                columnTitle: <></>
            }}
            onRow={(record, _rowIndex) => ({
                onClick: _event => onRecordClick(record) // click row
            })}
            dataSource={filteredDataSource}
            columns={debugging ? columnsDebug : columns}
            pagination={false}
            size="small"
            loading={getSparePartVariantsFxPending}
        />
    );
};
