import { FormConfig, FormState, GenericField, GenericObject, LabourRateField } from '../types';
import { DEFAULT_FIELD, fieldsDictionary, selectedLacquerMethod } from '../constants/fieldsDictionary';
import { initialConfig } from '../constants/initialConfig';
import { omit, pick } from 'ramda';

export const tabs = Object.keys(initialConfig);

let inputConfig = {
    country: 'IT',
    fieldsDictionary
};
/* 
    WTF is going on:
    - We receive an object from response (you can see example in constants/initialConfig.ts)
    - We need to convert this object to config for form (formik or any another type of form)
    - User will work with this form
    - User will submit this from then we need convert it to shape of response back and submit to API
*/

// Create fields from response object fro config
const generateFields = (fields: GenericObject, parent: string, currName: string | null = null): LabourRateField[] => {
    const fieldsKeys = Object.keys(fields);

    return fieldsKeys
        .flatMap(item => {
            const currentFieldValue = fields[item];

            // We need to create name of the field if currname exist it means it's a parent object then we need to add prefix
            const fieldName = currName ? `${item}-${currName}` : item;
            if (currentFieldValue === null) {
                // TODO: Add config for nullable fields
                // Generate default value for them
                return generateFields(
                    {
                        mode: 1,
                        value: 0
                    },
                    parent,
                    item
                );
            }

            // checking if item which came from labour-rates api is object
            const isObject = typeof currentFieldValue === 'object' && currentFieldValue !== null;

            // if it object then we need to generate a fields of nested values
            if (isObject) {
                // Checking if object is empty convert to keys and see lentght of it
                const fieldNames = Object.keys(currentFieldValue);

                if (fieldNames.length > 1) {
                    // call recursion in case if children has more items
                    return generateFields(currentFieldValue, parent, item);
                }
            }

            // Find this field inside dictionary from config
            const field =
                inputConfig.fieldsDictionary.find(field => field.name === `${parent}-${fieldName}`) || DEFAULT_FIELD;

            return {
                ...field,
                // if value is empty then take default value
                value: currentFieldValue ?? field?.value
            };
        })
        .filter(item => item.name !== 'default');
};

// Lacquer Methods must be in single block so we casting it to PaintMethods because in response it has 2 objects
export const createPaintMethods = (input = initialConfig, initialLacquerType = '0') => {
    const fields = Object.keys(input).flatMap(item => generateFields(input[item as keyof typeof input], item));

    return {
        fields: [selectedLacquerMethod(Number(initialLacquerType)), ...fields],
        type: 'Tab',
        id: 'paint-factors',
        title: 'Paint Factors'
    };
};

// Create config from response
export const generateConfigFromResponse = (
    input = initialConfig,
    country: string = 'IT',
    fieldsDictionaryInput = fieldsDictionary
): FormConfig[] => {
    inputConfig = {
        fieldsDictionary: fieldsDictionaryInput,
        country
    };
    // remove this factors from stream of generating rates (it needs because it's dynamically set in future but it part of config)
    const lacquerFactorkeys = ['ManufacturerLacquerFactor', 'EuroLacquerFactor'];
    const lacquerFactorTypes = pick(lacquerFactorkeys, input);
    const lacquerRates = createPaintMethods(lacquerFactorTypes, String(input.CalculationFactor.selectedLacquerMethod));
    const keysToOmit = country !== 'IT' ? [...lacquerFactorkeys] : lacquerFactorkeys;
    const restOfTheRates = omit(keysToOmit, input);
    const rates = Object.keys(restOfTheRates).map(rateName => {
        // take each object from response and convert it field with fieldsDictionary
        const fieldInput = input[rateName as keyof typeof input];

        const fields = generateFields(fieldInput, rateName).sort((a, b) => a.order - b.order);
        return {
            type: 'Tab',
            title: rateName,
            id: rateName.split(' ').join('-'),
            fields
        };
    });

    return [...rates, lacquerRates];
};

const castCheckBox = (value: GenericField) => {
    const isNumber = typeof value === 'number';

    return isNumber ? !!+value : value;
};

// Generate initial state for form
// Input can be any object which Backend will decide
export const generateFormState = (input = initialConfig) => {
    const generatedConfig = generateConfigFromResponse(input);
    const formState: FormState = {};
    const initialFormState = generatedConfig.reduce((acc, { fields }) => {
        fields.forEach(({ type, name, value }) => {
            const values = {
                checkbox: castCheckBox(value),
                tabs: String(value)
            };

            const typeKey = type as keyof typeof values;

            acc[name] = values[typeKey] ? values[typeKey] : value;
        });
        return acc;
    }, formState);

    return initialFormState;
};
