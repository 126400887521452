import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { Form, useFormikContext } from 'formik';

// Components
import { Text } from '@wedat/ui-kit/components/Text';
import { Container, ItemContent, ItemWrapper } from './styles';
import { LabourField } from '../LabourField';

// Deps
import { labourRatesGenericStores } from '@dat/shared-models/labour-rates-generic';
import { FormState } from '@dat/shared-models/labour-rates-generic/types';
import { conditions } from '../../utils/conditions';
import { apiStores } from '@dat/api2/stores';

export const FormGenerator: FC = () => {
    const { t } = useTranslation();
    const datCountryIndicator = useStore(apiStores.country);
    const formConfig = useStore(labourRatesGenericStores.config).config;

    const { values } = useFormikContext<FormState>();
    const filteredForItaly = useMemo(
        () =>
            datCountryIndicator === 'IT' ? formConfig : formConfig.filter(item => item.id !== 'DomusCalculationFactor'),
        [datCountryIndicator, formConfig]
    );
    const controlledConfig = useMemo(
        () =>
            filteredForItaly
                .map(config => {
                    const fields = config.fields.map(field => {
                        if (field.condition) {
                            const { operator, valueOf, toValue } = field.condition;

                            return !conditions[operator](String(values[valueOf]), String(toValue)) ? null : field;
                        }

                        return field;
                    });
                    return {
                        ...config,
                        fields
                    };
                })
                .filter(item => !item.fields.every(field => field?.hidden))
                .filter(item => !item.fields.includes(null) || item.id === 'paint-factors'),
        [filteredForItaly, values]
    );

    return (
        <Container>
            <Form>
                {controlledConfig.map(({ fields, title, id }, configIndex) => (
                    <ItemWrapper key={`card-${title}-${configIndex}`}>
                        <Text fontSize="18px" font="mobileHeader">
                            {t(`${title}`)}
                        </Text>
                        <ItemContent item={id === 'DomusCalculationFactor'}>
                            {fields.map(
                                (field, index) =>
                                    field && (
                                        <LabourField
                                            field={field}
                                            key={`field-${index}`}
                                            initialValue={values[field.name]}
                                        />
                                    )
                            )}
                        </ItemContent>
                    </ItemWrapper>
                ))}
            </Form>
        </Container>
    );
};
