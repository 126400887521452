import { FC, useEffect } from 'react';
import { StepBody, FormBuilderWrapper } from './styles';
import { DamageSelector } from '@dat/damage-selector';
import { FormBuilder } from '@dat/form-builder';
import { VehicleSelectionModal } from '@dat/vehicle-selection-modal';
import { useStore } from 'effector-react';
import { stepperEvents, stepperStores } from '../../stores/stepper';
import { stepperContractStores } from '../../stores/contract';
import { StepProps } from '../../types/config';
import { contractEffects } from '@dat/shared-models/contract';
import { parseVehicleSelectionPluginResult } from '../../utils/parseVehicleSelectionResult';

const componentsToBeRendered = {
    DamageSelector,
    VehicleSelectionModal,
    FormBuilder
};

export const Step: FC<StepProps> = ({ options, plugin, step, values, setValues, goNextStep }) => {
    const contractId = useStore(stepperContractStores.contractId);
    const currentStepData = useStore(stepperStores.currentStepData);
    useEffect(() => {
        stepperEvents.setCurrentStepData(values);
    }, [values, step]);

    return (
        <StepBody>
            {plugin?.map(item => {
                const Plugin = componentsToBeRendered[item as keyof typeof componentsToBeRendered];
                return (
                    <FormBuilderWrapper item={item} key={`${item}-${step}`}>
                        <Plugin
                            options={{
                                ...options[item],
                                onChangeForm: values => {
                                    setValues(values);
                                },
                                onComplete: result => {
                                    contractEffects.createOrUpdateContractFx({
                                        ...parseVehicleSelectionPluginResult(result),

                                        contractId: Number(contractId)
                                    });
                                    goNextStep();
                                },

                                pluginCarsToggleCallback: () =>
                                    stepperContractStores.vehicleSelectionPluginCarsTrigger(),
                                isComponent: true,
                                contractId,
                                isModal: true,
                                toggleModal: true,
                                isShowDamageSelectorButton: false,
                                globalInitialValues: currentStepData
                            }}
                        />
                    </FormBuilderWrapper>
                );
            })}
        </StepBody>
    );
};
