import fetch from 'node-fetch';
import { GetDomusTimesParams, ResultGetDomusTimes, ResultGetVehicleType } from './ItalyAniaTypes2';

const urlItalyAniaService = 'https://wedat.it/domus/api';

export async function getDomusTimes({
    datECode,
    doors
}: GetDomusTimesParams): Promise<ResultGetDomusTimes | undefined> {
    if (!datECode) return;

    const options = {
        method: 'GET'
    };
    const url = urlItalyAniaService + '/getDomusTimes/' + datECode + '/' + doors;

    const resultFetch = await fetch(url, options);
    const resJson = await resultFetch.json();
    const result: ResultGetDomusTimes = resJson;
    return result;
}

export async function getVehicleType(datECode: string | undefined): Promise<ResultGetVehicleType | undefined> {
    if (!datECode) return;
    const FZA = datECode.substring(0, 2);
    const HST = datECode.substring(2, 5);
    const HT = datECode.substring(5, 8);
    const formData = 'FZA=' + FZA + '&HST=' + HST + '&HT=' + HT;

    const options = {
        method: 'POST',
        body: new URLSearchParams(formData)
    };
    const url = urlItalyAniaService + '/getVehicleType';

    const resultFetch = await fetch(url, options);
    const resJson = await resultFetch.json();
    const resArray: [string, number] = resJson;
    const result: ResultGetVehicleType = {
        datECode: datECode,
        response: resArray
    };
    return result;
}
