import styled, { css } from 'styled-components/macro';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';

interface StyledNumberTextProps {
    isActive: boolean;
    isLoading: boolean;
}

export const StyledNumberText = styled.span<StyledNumberTextProps>`
    position: relative;
    opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};

    ${({ isLoading }) =>
        isLoading &&
        css`
            color: transparent;
        `};
`;

export const StyledPreloaderCircle = styled(PreloaderCircle)`
    width: 22px;

    position: absolute;
    top: calc(50% - 11px);
    left: 0;
`;
