import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

interface CardContainerProps {
    isTotal?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
    width: 23%;
    min-width: 195px;
    padding: 15px;
    margin-bottom: 20px;
    margin-right: 10px;
    background-color: #f1f2f4;
    border: 2px solid #f1f2f4;
    border-radius: 10px;
    height: max-content;

    ${media.tabletSmall`
        width: 45%;
    `}

    ${media.laptop`
        width: 45%;
    `}

    ${media.phone`
        bottom: 0;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        padding-bottom: 15px;
        background-color: #fff;
        border: none;
        border-radius: 2px;
        font-weight: 500;
        font-size: 16px;
    `}
`;

export const CardTitle = styled.h3`
    font-weight: 600;
    font-size: 13px;
    color: #465064;

    &::after {
        content: '';
        display: block;
        margin-top: 15px;
        width: 100%;
        height: 2px;
        background-color: #eaebec;
    }

    ${media.phone`
        font-size: 16px;
    `}
`;

export const CardContent = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: #465064;

    ${media.phone`
        font-size: 16px;
    `}
`;

export const CardValue = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
`;
