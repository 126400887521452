import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isContractListOpen, toggleIsContractListOpen] = createToggle(false);
const [isContractListCCOpen, toggleIsContractListCCOpen] = createToggle(false);
const [isContractListCCNOpen, toggleIsContractListCCNOpen] = createToggle(false);
const [isAttachmentsOpen, toggleIsAttachments] = createToggle(false);

export const modalsEvents = {
    toggleIsContractListOpen,
    toggleIsContractListCCOpen,
    toggleIsContractListCCNOpen,
    toggleIsAttachments
};

export const modalsStores = {
    isContractListOpen,
    isContractListCCOpen,
    isContractListCCNOpen,
    isAttachmentsOpen
};
