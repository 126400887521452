import { format } from 'date-fns';

let last: number = 0;

// the number of milliseconds elapsed since January 1, 1970  +3 randome digits
// exmemple result:          1622614743101517, (where 1622614743101 is 2 June 2021 6:19:03.101 and 517 random number)
// Number.MAX_SAFE_INTEGER = 9007199254740991
export default function uniqId() {
    const time = Date.now();
    last = last || time;
    last = time > last ? time : last + 1;
    const rand = Math.random();
    const res = last * 1e3 + Math.trunc(rand * 999);
    return res;
}
// the number of milliseconds elapsed since January 1, 1970  +3 randome digits
// exmemple result: '1622614743101007', (where 1622614743101 is 2 June 2021 6:19:03.101 and 007 random number)
export function uniqIdString() {
    const time = Date.now();
    last = last || time;
    last = time > last ? time : last + 1;
    const randStr = '000' + Math.trunc(Math.random() * 999);
    const res = last.toString() + randStr.substr(randStr.length - 3);
    return res;
}

export function addKeyToAntDesignTableForReact(table: any) {
    if (table) {
        if (!Array.isArray(table)) {
            table = [table];
        }
        table.forEach((elem: any) => (elem.key = uniqId().toString()));
    }
    return table;
}

// exemple result: 2021-06-02T06:23:55.555Z_386
export function uniqIdDateString() {
    const time = new Date();
    const timems = time.getTime();
    last = last || timems;
    last = timems > last ? timems : last + 1;
    const rand = Math.random();
    const res = `${time.toISOString()}_${Math.trunc(rand * 999)}`;
    return res;
}

// exemple result: 2021-06-02 09:23 5734
export function getUniqDocNumber() {
    const time = new Date();
    const timeStr = format(time, 'yyyy-MM-dd HH:mm');
    const rand = Math.trunc(1000 + Math.random() * 8999);
    return `${timeStr} ${rand}`;
}
