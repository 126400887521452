import axios from 'axios';
import { createEffect, createStore } from 'effector';
import { Coords, GeoResult } from '../../types/plugin';

const claimPosition = createStore<Coords>({
    lat: 0,
    lng: 0
});

const DEFAULT_COORDS = {
    coords: {
        lat: 0,
        lng: 0
    },
    address: ''
};

export const getCoords = async (address: string) => {
    if (!address) {
        return DEFAULT_COORDS;
    }

    const config = {
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
        url: 'https://maps.googleapis.com/maps/api/geocode/json'
    };

    const { data } = await axios({
        method: 'get',
        url: `${config.url}?address=${address}&key=${config.apiKey}`
    });

    if (data.results[0]) {
        const {
            geometry: { location },
            formatted_address
        } = data.results[0];

        return {
            coords: location,
            address: formatted_address
        };
    }
    return DEFAULT_COORDS;
};

export const getClaimPositionFx = createEffect(getCoords);

claimPosition.on(getClaimPositionFx.doneData, (_, data: GeoResult) => data.coords);

export const locationStores = {
    claimPosition
};

export const locationEffects = {
    getClaimPositionFx
};
