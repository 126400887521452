import { positionsEffects } from '../positions';
import { contractEffects } from '@dat/shared-models/contract';
import { createEvent } from 'effector';

const {
    calculateContractFx,
    calculateCurrentContractFx,
    createOrUpdateContractFx,
    updateCurrentContractFx,
    updateContractFx
} = contractEffects;
const { savePositionsFx, saveWorkBenchFx, addWorkBenchFx, deleteAccesoryPartsFx } = positionsEffects;

const setIsLoading = createEvent<boolean>();

const isLoading =
    calculateContractFx.pending ||
    calculateCurrentContractFx.pending ||
    createOrUpdateContractFx.pending ||
    updateCurrentContractFx.pending ||
    updateContractFx.pending ||
    savePositionsFx.pending ||
    saveWorkBenchFx.pending ||
    addWorkBenchFx.pending ||
    deleteAccesoryPartsFx.pending;

isLoading.on(setIsLoading, (_state, payload) => payload);

export const commonStores = {
    isLoading
};

export const commonEvents = {
    setIsLoading
};
