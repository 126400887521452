import { guard } from 'effector';
import { paintTypesEffects, paintTypesStores } from './index';
import { PayloadForGetPaintTypesManufacturer } from '../../../types/paintTypes';

const { getPaintTypesManufacturerFx } = paintTypesEffects;
const { partialPayloadForGetPaintTypesManufacturer } = paintTypesStores;

const isPayloadForGetPaintTypesManufacturerFull = (
    payload: Partial<PayloadForGetPaintTypesManufacturer>
): payload is PayloadForGetPaintTypesManufacturer => Object.values(payload).every(val => val !== undefined); //TODO: make isParsedValueEmpty util

guard({
    source: partialPayloadForGetPaintTypesManufacturer,
    filter: isPayloadForGetPaintTypesManufacturerFull,
    target: getPaintTypesManufacturerFx
});
