import { combine } from 'effector';

import { PayloadForOpenGroupInLightbox } from '../../types/lightbox';
import { LightboxProps } from '@wedat/ui-kit/components/Lightbox';
import { domain } from '../plugin';

const openGroupInLightbox = domain.createEvent<PayloadForOpenGroupInLightbox>();
const closeLightbox = domain.createEvent();

const images = domain.createStore<string[]>([]).reset(closeLightbox);
const initialImageIndex = domain.createStore(0);
const isOpen = images.map(images => !!images.length);

const lightboxState = combine<LightboxProps>({
    images,
    initialImageIndex,
    isOpen,
    onClose: () => closeLightbox()
});

export const lightboxEvents = {
    openGroupInLightbox,
    closeLightbox
};
export const lightboxStores = {
    images,
    initialImageIndex,
    isOpen,
    lightboxState
};
