import { SparePartsParameters } from '../../types/factors';

export const sparePartsInitialParameters: SparePartsParameters = {
    priceDate: '',
    increaseDecrease: '',
    surchargeInProtocolOly: false,
    surchargeSeparated: false,
    sparePartsDisposalCosts: '',
    sparePartLumpSum: '',
    bracketSetRentCost: '',
    discount: '',
    discountBiw: '',

    bracketSetProcurementCost: '',
    procurementCost: '',
    bodyInWhiteProcurementCost: '',

    smallSparePartFlatRatePrice: '',
    smallSparePartPercentOfPart: '',
    smallSparePartPercentOfPartBiw: '',

    _attributes: {
        mode: {
            discount: 'PERCENT',
            discountBiw: 'PERCENT'
        }
    }
};
