import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    margin-bottom: 56px;

    ${media.phoneBig`
        display: flex;
        flex-direction: column;
    `}
`;

export const ScrollButton = styled.button`
    display: none;
    position: fixed;
    right: 24px;
    bottom: 160px;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dustBlue['800']};
    border-radius: 12px;
    cursor: pointer;
    filter: drop-shadow(0px 8px 28px rgba(104, 119, 146, 0.2));

    ${media.phoneBig`
        display: block;
    `}
`;
