import styled from 'styled-components';

export interface SwitcherStyledProps {
    checked?: boolean;
    disabled?: boolean;
}

const WIDTH = 48;

export const SwitcherRoot = styled.div<Pick<SwitcherStyledProps, 'disabled'>>`
    position: relative;
    display: inline-flex;
    flex: none;
    width: ${WIDTH}px;
    height: 24px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const SwitcherInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: inherit;
`;

export const SwitcherCircle = styled.div`
    position: absolute;
    left: 3px;
    top: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.dustBlue[300]};
    transform: translateX(0);
    transition: transform 150ms;

    ${SwitcherInput}:checked + & {
        background-color: ${({ theme }) => theme.colors.blue[500]};
        transform: translateX(${WIDTH - 24}px);
    }

    ${SwitcherInput}:disabled + & {
        background-color: ${({ theme }) => theme.colors.dustBlue[100]};
    }
`;

export const SwitcherTrack = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.dustBlue[50]};
    border-radius: 24px;

    ${SwitcherInput}:checked + & {
        background-color: ${({ theme }) => theme.colors.blue[50]};
    }

    ${SwitcherInput}:disabled + & {
        background-color: ${({ theme }) => theme.colors.dustBlue[50]};
    }
`;
