import { forward } from 'effector';
import { aiClaimEvents } from './index';
import { modalsEvents } from '../ui/modals';
import { contractsListEffects } from '../contractsList';
import { sharedTemplateEvents } from '@dat/shared-models/template';

const { aiClaimCompleted } = aiClaimEvents;
const { reloadContractsFx } = contractsListEffects;
const { toggleIsAiClaimModalOpen } = modalsEvents;
const { setDefaultTemplate } = sharedTemplateEvents;

forward({
    from: aiClaimCompleted,
    to: [toggleIsAiClaimModalOpen, reloadContractsFx, setDefaultTemplate]
});
