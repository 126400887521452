// splitSVGonSVGParts

import { SVGwithCoordinates } from '../types/rimsType';

export function splitSVGonSVGParts(svg: string | undefined): SVGwithCoordinates[] | undefined {
    if (!svg) return undefined;

    var parser = new DOMParser();
    var svgObj = parser.parseFromString(svg, 'image/svg+xml');

    const gStandardebene = svgObj.getElementById('Standardebene');

    if (!gStandardebene) return undefined;

    const listSvgDocuments: SVGwithCoordinates[] = [];

    const ids = [];
    for (let i = 0; i < gStandardebene.children.length; i++) {
        if (gStandardebene.children[i].id !== 'dat-logo') ids.push(i);
    }

    ids.forEach(id => {
        const svgWC = replaceStrdardebene(svg, id);
        if (svgWC) listSvgDocuments.push(svgWC);
    });

    return listSvgDocuments;
}

function replaceStrdardebene(svg: string, index: number): SVGwithCoordinates | undefined {
    var parser = new DOMParser();
    var svgObj = parser.parseFromString(svg, 'image/svg+xml');
    const gStandardebene = svgObj.getElementById('Standardebene');
    if (!gStandardebene) return undefined;

    const svgItem = gStandardebene.children[index];

    gStandardebene.innerHTML = '';
    gStandardebene.appendChild(svgItem);

    const elemDiv = document.createElement('div');
    elemDiv.style.visibility = 'hidden';
    const svgElem = svgObj.children[0];
    elemDiv.appendChild(svgElem);
    document.body.appendChild(elemDiv);

    const divBounding = elemDiv.getBoundingClientRect();

    const bounding = svgItem.getBoundingClientRect();

    const left = bounding.left;
    const right = bounding.right;
    const top = bounding.top;
    const bottom = bounding.bottom;

    const boundingWidth = right - left;
    const boundingHeight = bottom - top;

    const scaleX = svgElem.clientWidth / 83.873;
    const scaleY = svgElem.clientHeight / 137.336;

    const sideMargin = 10;

    const width = (boundingWidth + 2 * sideMargin) / scaleX;
    const height = (boundingHeight + 2 * sideMargin) / scaleY;

    gStandardebene.setAttribute(
        'transform',
        'translate(' +
            (divBounding.left - left + sideMargin) / scaleX +
            ',' +
            (divBounding.top - top + sideMargin) / scaleY +
            ')'
    );

    svgElem.setAttribute(
        'viewBox',
        // '0 0 83.873 137.336'
        '0 0 ' + width + ' ' + height
    );

    var oSerializer = new XMLSerializer();
    var svgStr = oSerializer.serializeToString(svgElem);

    elemDiv.remove();

    return { svgStr, left, top, right, bottom, index };
}
