import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@wedat/ui-kit/components/Portal';

import { NumberPlateInputField } from '../VehicleSelectionModal/NumberPlateInputField';
import { InputField, NumberInputField } from '@wedat/ui-kit/Formik';
import { VinInputField } from '../VehicleSelectionModal/VinInputField';

interface Props {
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const PortalFields: FC<Props> = ({ onBlur, onFocus }) => {
    const { t } = useTranslation();

    return (
        <>
            {/* Create Portal for inputs to claim-management VehicleInputs */}
            <Portal id="number-plate-input">
                <NumberPlateInputField onBlur={onBlur} onFocus={onFocus} withOCRButton />
            </Portal>
            <Portal id="vin-input">
                <VinInputField onBlur={onBlur} onFocus={onFocus} withOCRButton />
            </Portal>
            <Portal id="kilometers-input">
                <NumberInputField
                    fixedDecimalScale={false}
                    thousandSeparator="."
                    name="kilometers"
                    label="KM"
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </Portal>
            <Portal id="tires-input">
                <InputField
                    name="tiresPercent"
                    label={t('inputsPlaceholders.tiresPercent')}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </Portal>
        </>
    );
};
