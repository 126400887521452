import React, { useMemo } from 'react';

import { useMedia } from '@dat/core/hooks/useMedia';
import { pluginEffects } from '../../../stores/plugin';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import { statusesEvents } from '../../../stores/statuses';
import { ItemContractContext } from './context';
import { DisabledItemWrapper, StyledTooltip, ComponentWrapper } from './styles';
import { sharedUserStores } from '@dat/shared-models/user';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

interface Props {
    contract: DAT2.Internal.ClaimItem;
    openPreview: ({ claimId }: { claimId: number }) => void;
}

export const ListItem: React.FC<Props> = ({ contract, openPreview }) => {
    const isMobile = useMedia(sizes.phoneBig);
    const Component = isMobile ? Mobile : Desktop;
    const customerNumber = useStore(sharedUserStores.customerNumber);
    const username = useStore(sharedUserStores.username);
    const openContract = () => pluginEffects.callOnContractClickFx({ contractId: contract.id });
    const handleStatusClick = () => contract.id && statusesEvents.openStatusModalByContractId(contract.id);
    const { t } = useTranslation();

    const blockedUserId = contract.templateFieldAndValue && contract.templateFieldAndValue['String_blockedUserId'];
    const blockedUntilDate =
        contract.templateFieldAndValue && contract.templateFieldAndValue['String_blockedUntilDate'];

    const splitedUserId = blockedUserId?.toString().split('/')[1];
    const isTimePast = useMemo(
        () => blockedUntilDate && new Date(String(blockedUntilDate)).getTime() < new Date().getTime(),
        [blockedUntilDate]
    );

    const checkedForUndefined = useMemo(
        () =>
            blockedUserId && blockedUntilDate
                ? blockedUntilDate !== 'undefined' &&
                  blockedUserId !== 'undefined' &&
                  blockedUserId !== `${customerNumber}/${username}` &&
                  !isTimePast
                : false,
        [blockedUntilDate, blockedUserId, customerNumber, isTimePast, username]
    );

    return (
        <ItemContractContext.Provider value={contract}>
            {checkedForUndefined ? (
                <ComponentWrapper>
                    <Component
                        contract={contract}
                        onContractClick={() => {}}
                        onContractDbClick={() => {}}
                        onStatusClick={() => {}}
                    ></Component>
                    <DisabledItemWrapper></DisabledItemWrapper>
                    <StyledTooltip text={`${t('blockText')} ${splitedUserId}`}></StyledTooltip>
                </ComponentWrapper>
            ) : (
                <Component
                    contract={contract}
                    onContractClick={event => {
                        event.stopPropagation();
                        openPreview({ claimId: contract.id });
                    }}
                    onContractDbClick={openContract}
                    onStatusClick={handleStatusClick}
                />
            )}
        </ItemContractContext.Provider>
    );
};
