import { EquipmentPosition } from '@dat/api2dat/types/contractTypes';

export function getDoorsNumber(seriesEquipment?: EquipmentPosition[]) {
    if (!seriesEquipment) return '3';

    const foundVal = seriesEquipment.find(
        equipment =>
            equipment.DatEquipmentId === 10002 ||
            equipment.DatEquipmentId === 10003 ||
            equipment.DatEquipmentId === 10004 ||
            equipment.DatEquipmentId === 10005
    );

    return foundVal?.DatEquipmentId?.toString().slice(-1) || '3';
}
