import React, { useMemo } from 'react';
import { DataField } from '../../types/dataScheme';
import { useFormikContext } from 'formik';
import { AddressBook, AddressBookPluginOptions } from '@dat/address-book';
import { useOptions } from '../../hooks/useOptions';

interface Props {
    field: DataField;
}

export const AddressBookField: React.FC<Props> = ({ field }) => {
    const pluginOptions = useOptions();
    const { setFieldValue } = useFormikContext();

    const options = useMemo<AddressBookPluginOptions>(
        () => ({
            isComponent: true,
            subjectType: field.id.endsWith('_id') ? field.id.slice(0, -3) : field.id,
            label: field.label,
            assignedId: field.initValue,
            readOnly: field.readOnly,
            showList: field.showList,
            networkOwnerId: field.networkOwnerId,
            onAssignSubject: subject => {
                setFieldValue(field.id, subject?._id);
                pluginOptions?.globalHandlers?.subject?.['onUserChange']?.({
                    name: field.id.endsWith('_id') ? field.id.slice(0, -3) : field.id,
                    value: subject,
                    type: 'subject'
                });
            }
        }),
        [
            field.id,
            field.initValue,
            field.label,
            field.networkOwnerId,
            field.readOnly,
            field.showList,
            pluginOptions?.globalHandlers?.subject,
            setFieldValue
        ]
    );

    return <AddressBook options={options} />;
};
