import { sample } from 'effector';
import { spread } from 'patronum';

import { lightboxEvents, lightboxStores } from './index';
import { attachmentsStores } from '../attachments';

const { openGroupInLightbox } = lightboxEvents;
const { images, initialImageIndex } = lightboxStores;

const { groupedAttachments, attachmentsWithoutGroup } = attachmentsStores;

sample({
    clock: openGroupInLightbox,
    source: { groupedAttachments, attachmentsWithoutGroup },
    fn: ({ groupedAttachments, attachmentsWithoutGroup }, { groupId, initialIndex }) => {
        const attachmentsToShow = groupedAttachments[groupId] || attachmentsWithoutGroup;

        return {
            images: attachmentsToShow.map(({ base64 }) => base64),
            initialImageIndex: initialIndex
        };
    },
    target: spread({
        targets: { images, initialImageIndex }
    })
});
