import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Switcher } from '../Switcher';
import { Text } from '../Text';
import { BoxSwitcherRoot } from './styles';
import { BoxSwitcherProps } from './types';

export const BoxSwitcher: FC<BoxSwitcherProps> = ({
    checked,
    disabled,
    className,
    onChange,
    label = '',
    noBorder,
    noPadding,
    borderRadius,
    noHeight
}) => {
    const theme = useTheme();

    return (
        <BoxSwitcherRoot
            tabIndex={0}
            className={className}
            disabled={disabled}
            onClick={() => onChange?.(!checked)}
            noBorder={noBorder}
            noPadding={noPadding}
            borderRadius={borderRadius}
            noHeight={noHeight}
        >
            <Text
                userSelect="none"
                font="note"
                color={disabled ? theme.colors.dustBlue[200] : theme.colors.deepBlue[900]}
                textOverflow="ellipsis"
            >
                {label}
            </Text>
            <Switcher checked={checked} disabled={disabled} />
        </BoxSwitcherRoot>
    );
};
