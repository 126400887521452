import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../Table';
import { TableFooter } from '../TableFooter';
import { createDataForLabour, createDataForLabourTableFooter } from '../../utils/createDataForLabour';
import { useStore } from 'effector-react';
import { TableWrapper } from '../Layout/styles';
import { Column } from 'react-table';
import { TableData } from '../../types/table';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { sharedDossierStores } from '@dat/shared-models/contract/Dossier';

export const Labour: FC = () => {
    const { t } = useTranslation();

    const dossier = useStore(sharedDossierStores.dossier);
    const isMobile = useMedia(sizes.phone);

    const data: TableData[] = useMemo(() => createDataForLabour(t, dossier, isMobile), [dossier, isMobile, t]);

    const columns: Column<TableData>[] = useMemo(
        () => [
            {
                Header: String(t('labourTable.headers.labourType')),
                accessor: 'col1'
            },
            {
                Header: String(t('labourTable.headers.WPN')),
                accessor: 'col2'
            },
            {
                Header: String(t('labourTable.headers.description')),
                accessor: 'col3'
            },
            {
                Header: String(t('labourTable.headers.level')),
                accessor: 'col4'
            },
            {
                Header: String(t('labourTable.headers.repairType')),
                accessor: 'col5'
            },
            {
                Header: String(t('labourTable.headers.labourType')),
                accessor: 'col6'
            },
            {
                Header: String(t('labourTable.headers.type')),
                accessor: 'col7'
            },
            {
                Header: String(t('labourTable.headers.time')),
                accessor: 'col8'
            },
            {
                Header: String(t('labourTable.headers.price')),
                accessor: 'col9'
            }
        ],
        [t]
    );

    const footerData = useMemo(() => createDataForLabourTableFooter(t, dossier), [dossier, t]);

    return (
        <TableWrapper>
            <Table columns={columns} data={data} title={t('labourTable.title')} isLabour={true} />
            <TableFooter items={footerData.items} />
        </TableWrapper>
    );
};
