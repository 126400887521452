import React from 'react';
import { useTranslation } from 'react-i18next';

import { radioItems } from '../../constants/radioItems';
import { selectsOptions } from '../../constants/selectsOptions';

import { SelectField, InputField, CheckboxField } from '@wedat/ui-kit/Formik';
import { InputWithSelect } from '../InputWithSelect';
import { AccordionItemContent, CheckboxFieldContainer, Field, FieldsContainer } from '../Layout/styles';

export const Calculations: React.FC = () => {
    const { t } = useTranslation();
    const percentOrAbsolute = radioItems.percentOrAbsolute(t);
    const biwOptimizationMode = selectsOptions.biwOptimizationMode(t);

    return (
        <AccordionItemContent>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CalculationFactor.showLongWorkStrings"
                        label={t('calculations.showLongWorkStrings')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CalculationFactor.showAllIncludedWork"
                        label={t('calculations.showAllIncludedWork')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CalculationFactor.showFordFinisNumber"
                        label={t('calculations.showFordFinisNumber')}
                    />
                </CheckboxFieldContainer>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CalculationFactor.calculationWithoutWork"
                        label={t('calculations.calculationWithoutWork')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>

            <FieldsContainer>
                <Field>
                    <SelectField
                        name="CalculationFactor.biwOptimizationMode"
                        options={biwOptimizationMode}
                        label={t('calculations.biwOptimizationMode')}
                    />
                </Field>
                <Field>
                    <InputField name="CalculationFactor.preDamageTotal" label={t('calculations.preDamageTotal')} />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'CalculationFactor.discount',
                            label: t('common.discount')
                        }}
                        selectProps={{
                            name: 'CalculationFactor._attributes.mode.discount',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'CalculationFactor.newForOld',
                            label: t('calculations.newForOld')
                        }}
                        selectProps={{
                            name: 'CalculationFactor._attributes.mode.newForOld',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
                <Field>
                    <InputField
                        name="CalculationFactor.totalValueImprovementAmount"
                        label={t('calculations.totalValueImprovementAmount')}
                    />
                </Field>
                <Field>
                    <InputField name="CalculationFactor.vatRate" label={t('calculations.vatRate')} />
                </Field>
            </FieldsContainer>
        </AccordionItemContent>
    );
};
