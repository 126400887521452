import _ from 'lodash';

import { factorsToSearch, initialFactorsParameters } from '../constants/factors';
import { parseProcedureRelatedParametersObject } from './parseProcedureRelatedParametersObject';

import { PartialFactorsParametersObject, FactorName } from '../types/factors';

export const extractFactorsParametersFromInvoiceRate = (
    invoiceRate: DAT2.RefOrRateSet
): PartialFactorsParametersObject => {
    const factorsEntries = Object.entries(invoiceRate).filter(isFactorEntry);
    const parsedFactorsEntries = factorsEntries.map(([factor, parametersObject]) => {
        const allowedParametersObject = pickAllowedParameters({ parametersObject, factor });
        const parsedParametersObject = parseProcedureRelatedParametersObject(allowedParametersObject);

        return [factor, parsedParametersObject];
    });
    const resultWithoutName = Object.fromEntries(parsedFactorsEntries);

    return addInvoiceRateNameParameter(resultWithoutName, invoiceRate.name);
};

interface PickAllowedParametersParam {
    parametersObject: DAT2.ProcedureRelatedParametersObject; //TODO: DAT2.RefOrRateSet[FactorName]
    factor: FactorName;
}

const pickAllowedParameters = ({ parametersObject, factor }: PickAllowedParametersParam) => {
    let allowedParametersKeys = Object.keys(initialFactorsParameters[factor]);

    if (factor === 'EuroLacquerFactor' || factor === 'ManufacturerLacquerFactor') {
        const parametersDefinedInVehicleSelection = ['type']; // these parameters are always defined in "vehicle-selection" and shouldn't be changed

        allowedParametersKeys = allowedParametersKeys.filter(key => !parametersDefinedInVehicleSelection.includes(key));
    }

    return _.pickBy(parametersObject, (_value, key) => allowedParametersKeys.includes(key));
};

const isFactorEntry = (
    entry: [string, any]
): entry is [FactorName, DAT2.ProcedureRelatedParametersObject /*TODO: DAT2.RefOrRateSet[FactorName]*/] => {
    const [key] = entry;

    return (factorsToSearch as string[]).includes(key);
};

const addInvoiceRateNameParameter = (
    factorsParameters: PartialFactorsParametersObject,
    name?: DAT2.RefOrRateSet['name']
): PartialFactorsParametersObject => ({
    ...factorsParameters,
    CalculationFactor: {
        ...factorsParameters.CalculationFactor,
        referenceSetName: name
    }
});
