import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { dossierStores } from '../../../stores/dossier';
import { vatTypeRadioItems } from './constants';

import { BasicInformationStyled, ColumnsWrapepr, Field, Info, List, ListItem, InfoTitle } from './styles';
import { EvaluationDatepickerField } from '../../evaluationFields/Datepicker';
import { EvaluationNumberInputField } from '../../evaluationFields/Input';
import { EvaluationRadioGroupField } from '../../evaluationFields/RadioGroup';
import { Text } from '@wedat/ui-kit/components/Text';
import { VehicleName } from '../../VehicleName';

export const BasicData: React.FC = () => {
    const { t } = useTranslation();
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);
    const datECodeEquipment = useStore(dossierStores.datECodeEquipment);

    const vatTypeRadioItemsValue = vatTypeRadioItems(t);

    return (
        <BasicInformationStyled>
            <ColumnsWrapepr>
                <Field>
                    <EvaluationDatepickerField
                        label={t('basicData.Reference date')}
                        maxDate={new Date()}
                        name="Valuation.DeterminatedDate"
                    />
                </Field>
                <Field>
                    <EvaluationDatepickerField
                        label={t('basicData.First registration')}
                        maxDate={new Date()}
                        name="Vehicle.InitialRegistration"
                    />
                </Field>
                <Field>
                    <EvaluationNumberInputField
                        fixedDecimalScale={false}
                        thousandSeparator="."
                        label={t('basicData.Current mileage (km)')}
                        name="Vehicle.MileageEstimated"
                    />
                </Field>

                <Info>
                    <InfoTitle>
                        <Text font="note" color="inherit">
                            {t('basicData.Vehicle data')}
                        </Text>
                    </InfoTitle>
                    <VehicleName />
                    <List>
                        {datECodeEquipment.map(item => (
                            <ListItem key={item.DatEquipmentId}>
                                <Text font="note">{item.Description}</Text>
                            </ListItem>
                        ))}
                    </List>
                    <Text font="note">{dossierWithExtractedTextValues.Vehicle?.ContainerName}</Text>
                </Info>

                <Info>
                    <InfoTitle>
                        <Text font="note" color="inherit">
                            DAT €uropa-Code®
                        </Text>
                    </InfoTitle>
                    <Text font="note">
                        {dossierWithExtractedTextValues.Vehicle?.DatECode}&nbsp;
                        {dossierWithExtractedTextValues.Vehicle?.Container}&nbsp;
                        {dossierWithExtractedTextValues.Vehicle?.ConstructionTime}
                    </Text>
                </Info>
                <Field>
                    <InfoTitle>
                        <Text font="note" color="inherit">
                            {t('basicData.Taxation type')}
                        </Text>
                    </InfoTitle>
                    <EvaluationRadioGroupField items={vatTypeRadioItemsValue} name="VAT.VatType" column />
                </Field>
            </ColumnsWrapepr>
        </BasicInformationStyled>
    );
};
