import React from 'react';

const SuccessMessage = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.8521 8.85496C18.0482 8.66048 18.0494 8.3439 17.855 8.14786C17.6605 7.95182 17.3439 7.95056 17.1479 8.14504L9.93986 15.2958L6.852 12.2349C6.65588 12.0405 6.3393 12.0419 6.1449 12.238C5.9505 12.4341 5.95188 12.7507 6.148 12.9451L9.588 16.3551C9.78296 16.5484 10.0973 16.5483 10.2921 16.355L17.8521 8.85496Z"
            fill="#1B6122"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.22209 3.60104C7.78435 2.55717 9.62108 2 11.5 2C14.0187 2.00265 16.4336 3.00439 18.2146 4.78541C19.9956 6.56643 20.9974 8.98126 21 11.5C21 13.3789 20.4428 15.2157 19.399 16.7779C18.3551 18.3402 16.8714 19.5578 15.1355 20.2769C13.3996 20.9959 11.4895 21.184 9.64665 20.8175C7.80383 20.4509 6.11109 19.5461 4.78249 18.2175C3.45389 16.8889 2.5491 15.1962 2.18254 13.3534C1.81598 11.5105 2.00412 9.60041 2.72315 7.86451C3.44218 6.12861 4.65982 4.64491 6.22209 3.60104ZM6.77766 18.5675C8.17548 19.5015 9.81886 20 11.5 20C13.7535 19.9974 15.914 19.101 17.5075 17.5075C19.101 15.914 19.9974 13.7535 20 11.5C20 9.81886 19.5015 8.17547 18.5675 6.77765C17.6335 5.37984 16.306 4.29037 14.7528 3.64702C13.1996 3.00368 11.4906 2.83535 9.84174 3.16333C8.1929 3.4913 6.67834 4.30085 5.4896 5.48959C4.30085 6.67834 3.4913 8.19289 3.16333 9.84173C2.83535 11.4906 3.00368 13.1996 3.64703 14.7528C4.29037 16.306 5.37984 17.6335 6.77766 18.5675Z"
            fill="#1B6122"
        />
    </svg>
);

export default SuccessMessage;
