import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

export const SelectStyled = styled.div`
    width: 250px;
    height: 48px;

    background: ${({ theme }) => theme.colors.white};
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: 15px;
    margin-bottom: 15px;
`;

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    ${media.laptopSmall`
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    `}
`;
