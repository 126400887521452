import styled from 'styled-components';

export const PreloaderContainer = styled.div<{ noOpacity?: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    opacity: ${({ noOpacity }) => (noOpacity ? '1' : '0.8')};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
`;

export const PreloaderImage = styled.img`
    width: 100%;
    max-width: 140px;
`;
