import React from 'react';
import { Menu } from 'antd';
import { FastMovingBarItems } from '../../types/plugin';
import { TFunction } from 'react-i18next';

import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { onMouseOverModel } from '../../stores/onMouseOverModel';

export interface FastMovingBarItemsParams {
    fastMovingBar: FastMovingBarItems[];
    submenuShowTitle?: boolean;
    enableOnMouseOver?: boolean;
    t: TFunction;
}

export const fastMovingBarMenuItems = ({
    fastMovingBar,
    submenuShowTitle,
    enableOnMouseOver,
    t
}: FastMovingBarItemsParams) =>
    fastMovingBar.map(item => (
        <Menu.SubMenu
            key={item.menuGroupId}
            title={submenuShowTitle ? t(`fastMovingBarMenu.${item.menuGroupId}`) || item.menuGroupLabel : ''}
            icon={!submenuShowTitle && item.svgIcon && <img width="16px" height="16px" src={item.svgIcon} alt="" />}
        >
            {item.elementDatIDs.map(elementDATID => (
                <Menu.Item
                    key={elementDATID}
                    onClick={() => {
                        graphicDamageSelectionModel.events.updateStore({
                            // showGenericGraphic: false
                            typeOfGraphic: 'FullGraphic'
                        });
                        genericSVGGraphicModel.events.setCurrentGrapaObjectsByDatId(elementDATID);
                    }}
                    onMouseOver={() => {
                        !!enableOnMouseOver && onMouseOverModel.events.setHighlightObjectByFTDatId(elementDATID);
                    }}
                >
                    {t(`fastLocalNameDATID.${elementDATID}`) || elementDATID}
                </Menu.Item>
            ))}
        </Menu.SubMenu>
    ));
