import styled from 'styled-components/macro';
import { FormButton, SelectField } from '@wedat/ui-kit/Formik';
import { Button } from '@wedat/ui-kit/components/Button';
import { TextareaField } from '@wedat/ui-kit/Formik/TextareaField';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledSelectField = styled(SelectField)`
    width: 100%;
    margin-bottom: 16px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: 20px;

    ${media.tablet`
        margin-bottom: 0;
        width: 100%;
    `}
`;

export const StyledFormButton = styled(FormButton)`
    padding: 13px;
    margin-left: 20px;
    background-color: ${({ theme }) => theme.colors.blueDark};

    ${media.tablet`
        width: 50%;
    `}
`;

export const TextareaFieldStyled = styled(TextareaField)`
    width: 100%;
    height: 120px;
`;

export const TextareaWrap = styled.div`
    margin: 24px 0px;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    padding: 10px 20px;

    ${media.tablet`
        width: 50%;
    `}
`;
