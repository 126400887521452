import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { attachmentsEvents } from '../../stores/attachments';

import { Description, Info, Root, UploadFileIconStyled, UploadIconWrap, TextDesktop, TextMobile } from './styles';
import { toastEffects } from '@dat/smart-components/Toast/store';

export const Upload = () => {
    const { t } = useTranslation();

    const validationFiles = async (acceptedFiles: Blob[]) =>
        await Promise.all(acceptedFiles.map(async (file: Blob) => ((await isGoodImage(file)) ? file : null)));

    const onDrop = async (acceptedFiles: Blob[]) => {
        const files = await validationFiles(acceptedFiles);

        const newFiles = files.filter(file => file !== null) as Blob[];
        attachmentsEvents.filesInSelected(newFiles);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const isGoodImage = (file: Blob) => {
        const result = new Promise(res => {
            const image = new Image();

            image.addEventListener('load', function () {
                if (this.width >= 800 && this.height >= 600) {
                    res(true);
                } else {
                    toastEffects.showErrorToastFx({
                        message: {
                            namespace: 'ai-claim',
                            key: 'error.resolution'
                        }
                    });
                    res(false);
                }
            });

            image.src = URL.createObjectURL(file);
        });

        return result;
    };

    return (
        <Root {...getRootProps()}>
            <input {...getInputProps()} type="file" accept="image/*" />
            {isDragActive ? (
                <Description>{t('uploadDragDescription')}</Description>
            ) : (
                <Info>
                    <UploadIconWrap>
                        <UploadFileIconStyled />
                    </UploadIconWrap>
                    <Description>
                        <TextMobile>{t('upload')}</TextMobile>
                        <TextDesktop>{t('uploadDescription')}</TextDesktop>
                    </Description>
                </Info>
            )}
        </Root>
    );
};
