import React from 'react';

import { NewOrder } from './NewOrder';
import { StatusChange } from './StatusChange';
import { AiClaim } from './AiClaim';
import { GalleryModal } from '@dat/smart-components/GalleryModal';
import { ModalWrapper } from './styles';

export const Modals = () => (
    <ModalWrapper>
        <NewOrder />
        <StatusChange />
        <AiClaim />
        <GalleryModal />
    </ModalWrapper>
);
