import { sharedTemplateStores } from '@dat/shared-models/template';
import { sample } from 'effector';
import { printoutStores } from './index';

const { productsConfiguration } = sharedTemplateStores;
const { printoutConfig } = printoutStores;

sample({
    source: productsConfiguration,
    fn: productsConfiguration => {
        if (typeof productsConfiguration?.['printout'].isDefaultReportShown !== 'undefined') {
            return {
                ...productsConfiguration?.printout
            };
        }

        return {
            ...productsConfiguration?.printout,
            isDefaultReportShown: true
        };
    },
    target: printoutConfig
});
