import styled from 'styled-components/macro';

export const InfoButton = styled.button`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.gray_40};
    cursor: pointer;
    border: none;
    background-color: unset;

    &:hover {
        border: none;
        color: ${({ theme }) => theme.colors.gray_40};
        background-color: unset;
    }
`;
