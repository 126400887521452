import React, { useCallback, useState } from 'react';

import { dialogEvents } from '../../../stores/dialogs';

import { SendIcon } from '@wedat/ui-kit/components/Icons';
import { Container, TextareaStyled, SendButton } from './styles';
import { useTranslation } from 'react-i18next';

export const MessageInput = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');

    const sendMessage = useCallback(() => {
        if (message.trim().length) {
            dialogEvents.sendMessage({ messageText: message });
            setMessage('');
        }
    }, [message]);

    const sendMessageOnEnter = useCallback(
        event => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                sendMessage();
            }
        },
        [sendMessage]
    );

    return (
        <Container>
            <TextareaStyled
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyUp={sendMessageOnEnter}
                onKeyDown={event => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                    }
                }}
                maxRows={5}
                defaultHeight="47px"
                expandable
                placeholder={t('inputsPlaceholders.message')}
            />
            <SendButton type="submit" onClick={sendMessage}>
                <SendIcon />
            </SendButton>
        </Container>
    );
};
