import { sample } from 'effector';

import { configurationStores } from './index';
import { pluginStores } from '../plugin';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { defaultConfiguration } from '../../constants/defaultConfiguration';

const { configuration } = configurationStores;
const { pluginOptions } = pluginStores;
const { productsConfiguration } = sharedTemplateStores;

const sharedConfiguration = productsConfiguration.map(productsConfiguration => productsConfiguration?.['inbox']);

sample({
    source: { pluginOptions, sharedConfiguration },
    fn: ({ pluginOptions, sharedConfiguration }) => ({
        ...sharedConfiguration,
        ...pluginOptions,
        ...defaultConfiguration
    }),
    target: configuration
});
