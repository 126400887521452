import { createEffect } from 'effector';
import { PayloadForLoadFiles } from './types';

const loadFilesFx = createEffect(({ js, css }: PayloadForLoadFiles) => {
    js?.forEach(appendJSFx);
    css?.forEach(appendCSSFx);
});

const appendJSFx = createEffect((src: string) => {
    const script = document.createElement('script');

    script.src = src;
    document.head.append(script);
});

const appendCSSFx = createEffect((href: string) => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = href;
    document.head.append(link);
});

export const externalFilesEffects = {
    loadFilesFx,
    appendJSFx,
    appendCSSFx
};
