import { media } from '@wedat/ui-kit/mediaQueries';
import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
    position: absolute;
`;

export const Footer = styled.div`
    padding: 20px 24px;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;

    ${media.laptop`
        padding: 12px 16px;
        border-top: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
    `}
`;

export const IconWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 8px;
    transition: color 0.3s, border-color 0.3s;
    cursor: pointer;

    &:focus-visible,
    &:focus,
    &:hover,
    &:active {
        border-color: ${({ theme }) => theme.colors.dustBlue['400']};
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }

    ${({ theme, active }) =>
        active &&
        css`
            border-color: ${theme.colors.blue['700']};
            color: ${theme.colors.blue['700']};

            &:hover {
                border-color: ${theme.colors.blue['700']};
                color: ${theme.colors.blue['700']};
            }
        `}
`;

export const AddIconWrapper = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    cursor: pointer;

    &:active {
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }
`;

export const RemoveIconWrapper = styled(AddIconWrapper)`
    transform: rotate(45deg);
    color: ${({ theme }) => theme.colors.red['400']};
`;
