import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.tablet`
        width: 32px;
        height: 32px;
    `}
`;

export const Title = styled.div`
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.gray_300};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    &:hover {
        opacity: 0.5;
    }

    ${media.tablet`
        margin-left: 12px;
        ${({ theme }) => theme.typography.note};
        font-weight: 400;
    `}
`;

export const ReportsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    margin-bottom: 30px;

    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
    `}

    ${media.laptop`
        grid-template-columns: 1fr;
    `}
`;

export const GroupName = styled(Text)`
    margin-left: 16px;
`;

export const AccrodionItemWrapper = styled.div`
    width: 100%;
    min-height: 48px;
`;

export const AccordionHeader = styled(GroupName)`
    min-height: 48px;
    display: flex;
    align-items: center;
`;
