import { createStore, Effect, Store } from 'effector';

type Param<Params, MapFn extends (params: Params) => any> = {
    /* Effect to watch */
    effect: Effect<Params, any>;
    /* Function to transform parameters if needed */
    mapParams?: MapFn;
};
type Result<T> = Store<T[]>;

/*
 * Creates a store with array of params that remain in this array
 * only when effect called with these params is pending
 */
export const pendingParams = <Params, MapFn extends (params: Params) => any>({
    effect,
    mapParams
}: Param<Params, MapFn>): Result<StoreItemValue<Params, MapFn>> => {
    const store = createStore<StoreItemValue<Params, MapFn>[]>([]);
    const getValue: (params: Params) => StoreItemValue<Params, MapFn> = params =>
        mapParams ? mapParams(params) : params;

    store.on(effect, (current, params) => [...current, getValue(params)]);
    store.on(effect.finally, (current, { params }) => {
        const indexToRemove = current.indexOf(getValue(params));

        current = [...current];
        current.splice(indexToRemove, 1);

        return current;
    });

    return store;
};

type StoreItemValue<Params, MapFn extends (params: Params) => any> = MapFn extends (...args: any) => any
    ? ReturnType<MapFn>
    : Params;
