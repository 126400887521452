import styled from 'styled-components/macro';
import { Form } from 'formik';
import { media } from '@wedat/ui-kit/mediaQueries';

export const HeaderStyled = styled.div`
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    padding: 32px;
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;
    z-index: 10;

    ${media.laptop`
        flex-direction: column;
        padding: 24px;
    `}
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.dustBlue['600']};

    ${media.laptop`
        flex-direction: column;
    `}
`;

export const SelectWrap = styled.div`
    width: 294px;

    ${media.laptop`
        width: 100%;
    `}
`;

export const FormStyled = styled(Form)``;
