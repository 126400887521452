import { PlainObject } from '@dat/core/types/common';
import { getTranslations } from '../../api2/services/bff/translations';

const pdfDecoder = (result: PlainObject) => {
    const byteCharacters = atob(result.binaryData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = 'how-to-use-weDat';
    link.href = url;
    link.click();
};

export const handleDownloadClick = async (currentLocale: DAT2.Locale) => {
    const res = await getTranslations({
        language: currentLocale,

        namespaces: ['help-file']
    });
    const result = res['help-file']?.[currentLocale];
    if (result) {
        return pdfDecoder(result);
    }
};
