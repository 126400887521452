/*
 * Users' profiles
 */
import { createEffect, restore } from 'effector';
import { API2 } from '@dat/api2';

const getProfilesFx = createEffect(API2.bff.customer.getProfiles);

const getProfilesByCustomerNumberFx = createEffect(API2.bff.customer.getProfilesByCustomerNumber);

const sameCustomerProfiles = restore(getProfilesFx.doneData, {});

const sameCustomerProfilesByCustomerNumber = restore(getProfilesByCustomerNumberFx.doneData, {});

export const sharedProfilesEffects = {
    getProfilesFx,
    getProfilesByCustomerNumberFx
};

export const sharedProfilesStores = {
    sameCustomerProfiles,
    sameCustomerProfilesByCustomerNumber
};
