import React, { forwardRef } from 'react';
import { TooltipContentItem, TooltipText, TooltipContentWrapper, TooltipContentContainer } from './styles';

interface Props {
    subjects: BFF.AddressBook.Subject[];
}

export const TooltipContent = forwardRef<HTMLDivElement, Props>(({ subjects }, ref) => (
    <TooltipContentWrapper>
        <TooltipContentContainer ref={ref}>
            {subjects.map(item => (
                <TooltipContentItem>
                    <TooltipText font="footnoteBold" textOverflow="ellipsis">
                        {item.name}
                    </TooltipText>
                    <TooltipText font="footnote" textOverflow="ellipsis">
                        {item.mail}
                    </TooltipText>
                </TooltipContentItem>
            ))}
        </TooltipContentContainer>
    </TooltipContentWrapper>
));
