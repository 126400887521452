import { createStore } from 'effector';

interface MailConfig {
    mailboxFolderId?: number;
}

const mailConfig = createStore<MailConfig>({});

export const mailConfigurationStores = {
    mailConfig
};
