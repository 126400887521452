import { EquipmentItemData, TransferEquipmentItem } from '../../types/equipment';

type SelectedTableRows = {
    key: string;
    description?: string;
    class?: string | number;
    group?: string;
};

export const findExpandingRows = (row: TransferEquipmentItem) => {
    if (row.children) {
        return true;
    }

    return false;
};

export const createRowsWithDisabled = (rows: TransferEquipmentItem[], firstTableSelectedRowsIds: SelectedTableRows[]) =>
    rows.map(row => {
        const disabledClassOrGroup = firstTableSelectedRowsIds.find(
            selectedRow =>
                (selectedRow.class && selectedRow.class === row.EquipmentClass) ||
                (selectedRow.group && selectedRow.group === row.EquipmentGroup)
        );

        const isDisabled = disabledClassOrGroup && disabledClassOrGroup.key !== row.key;

        if (findExpandingRows(row)) {
            const children = row.children?.map(a => ({
                col0: '',
                col1: '',
                col2: '',
                col3: '',
                col4: a.description,
                col5: ''
            }));
            return {
                col0: '',
                col1: row.key,
                col2: row.isSpecial,
                col3: row.vin,
                col4: row.description,
                children
            };
        }

        return {
            col0: '',
            col1: row.key,
            col2: row.isSpecial,
            col3: row.vin,
            col4: row.description,
            children: undefined,
            isDisabled
        };
    });

export const createRowsWithConflicted = (
    rows: TransferEquipmentItem[],
    conflicted: EquipmentItemData[],
    onlyConflicted: boolean
) => {
    const formattedRows = rows.map(row => {
        const conflictedItem = conflicted.find(conflictedItem => conflictedItem.key === row.key);

        if (findExpandingRows(row)) {
            const children = row.children?.map(a => ({
                col0: '',
                col1: '',
                col2: '',
                col3: '',
                col4: a.description,
                col5: ''
            }));
            return {
                col0: '',
                col1: row.key,
                col2: row.isSpecial,
                col3: row.vin,
                col4: row.description,
                children,
                conflictedItem
            };
        }

        return {
            col0: '',
            col1: row.key,
            col2: row.isSpecial,
            col3: row.vin,
            col4: row.description,
            children: undefined,
            conflictedItem
        };
    });

    const filteredRows = onlyConflicted ? formattedRows.filter(i => !!i.conflictedItem) : formattedRows;

    return filteredRows;
};

export const getTableSelected = (tableSelectedIds: string[], equipmentStringIds: TransferEquipmentItem[]) => {
    const selectedTableRows: SelectedTableRows[] = [];

    tableSelectedIds.forEach(id => {
        equipmentStringIds.forEach((item, index) => {
            if (id === String(index)) {
                selectedTableRows.push({
                    key: item.key,
                    description: item.description,
                    class: item.EquipmentClass,
                    group: item.EquipmentGroup
                });
            }
        });
    });

    return selectedTableRows;
};
