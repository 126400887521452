import jwt from 'jsonwebtoken';

type Param = string;
type Result = Pick<DAT2.Credentials, 'customerNumber' | 'user'>;

export const decodeDatToken = (token: Param): Result => {
    try {
        const decodeResult = jwt.decode(token);
        const decodeResultObject = typeof decodeResult === 'object' ? decodeResult : JSON.parse(decodeResult);
        const { clnt, user } = decodeResultObject;

        if (!clnt || !user) throw new Error();

        return {
            customerNumber: +clnt,
            user: user
        };
    } catch {
        throw new Error('Invalid token');
    }
};
