import { createEvent } from 'effector';

import { datAuthTokenName } from '@dat/core/constants';

//
/*** Expire token ***/
//
const expireToken = createEvent().prepend<void>(() => {
    localStorage.setItem(datAuthTokenName, '');
});

//
/*** Export ***/
//
export const apiEvents = {
    expireToken
};
