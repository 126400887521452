import axios from '../datAxios';
import { DatProductsPaths, VehicleRepairServices } from '../constants';

export const getExistingEquipmentN = (data: DAT2.Request.GetExistingEquipmentN) =>
    axios<DAT2.Response.GetExistingEquipmentN>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.ConversionFunctionsService,
        method: 'getExistingEquipmentN',
        data
    });

export const getPossibleEquipmentN = (data: DAT2.Request.GetPossibleEquipmentN) =>
    axios<DAT2.Response.GetPossibleEquipmentN>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.ConversionFunctionsService,
        method: 'getPossibleEquipmentN',
        data
    });

export const getVehicleTranslation = (data: DAT2.Request.GetVehicleTranslation) =>
    axios<DAT2.Response.GetVehicleTranslation>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleTranslation',
        data
    });
