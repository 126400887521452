import React, { forwardRef, useRef, useState } from 'react';
import NumbericFormat from 'react-number-format';
import { useStore } from 'effector-react';

import { ProgressBar } from './ProgressBar';

import { fuelLevelEffects, fuelLevelStores } from '../../stores/fuelLevel';

import { contractStores } from '@dat/shared-models/contract';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

import { getFuelLevelValueFromContract } from '../../utils/getFuelLevelValueFromContract';

import { Text } from '@wedat/ui-kit/components/Text';
import { GasIcon, Input, Preloader } from '@wedat/ui-kit';

import { useClickOutside } from '@dat/core/hooks/useClickOutside';

import { FuelLevelWrapper, GasInfo, InputWrapper, PercentageStyled } from './styles';

const { contract } = contractStores;

const { fuelValueProcessingFx } = fuelLevelEffects;

const CustomInput = forwardRef((props, _ref) => <Input autoFocus {...props} />);

export const FuelLevel = React.memo(() => {
    const [fuelInputVisible, setFuelInputVisible] = useState(false);
    const clickRef = useRef(null);

    const currentContract = useStore(contract);
    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const isLoading = useStore(fuelLevelStores.isLoading);

    const fuelLevelValue = currentContract ? getFuelLevelValueFromContract(currentContract) : null;

    const handleFuelInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await fuelValueProcessingFx(event.target.value === '' ? '0' : event.target.value);
    };

    useClickOutside(clickRef, () => {
        setFuelInputVisible(false);
    });

    return (
        <>
            <FuelLevelWrapper ref={clickRef} isVehicleIdentified={isVehicleIdentified}>
                <InputWrapper isFuelInputVisible={fuelInputVisible}>
                    <NumbericFormat
                        value={fuelLevelValue?.toString()}
                        label="Fuel"
                        onBlur={handleFuelInputChange}
                        allowNegative={false}
                        valueIsNumericString={true}
                        customInput={CustomInput}
                        allowLeadingZeros={false}
                        isAllowed={({ value }) => +value >= 0 && Number(value) <= 100}
                    />
                </InputWrapper>
                <PercentageStyled
                    onClick={() => setFuelInputVisible(!fuelInputVisible)}
                    fontSize="12px"
                    fontWeight={400}
                    lineHeight="18px"
                    color="gray"
                >
                    {fuelLevelValue}%
                </PercentageStyled>
                <GasInfo>
                    <Text fontSize="12px" fontWeight={700} lineHeight="18px" color="green">
                        F
                    </Text>
                    <GasIcon />
                    <Text fontSize="12px" fontWeight={700} lineHeight="18px" color="red">
                        E
                    </Text>
                </GasInfo>
                <ProgressBar fuelValue={fuelLevelValue?.toString()} />
            </FuelLevelWrapper>
            <Preloader isLoading={isLoading} />
        </>
    );
});
