import { FtDvnMapType, DvnMapItem } from '../types/ft/dvnMapType';

export function parseFTDvnMap(ftDvnMap: FtDvnMapType): DvnMapItem[] {
    return Object.entries(ftDvnMap).map(([key, val]) => {
        const subst = key.split('.');
        return {
            datId: subst[0],
            datECodeMask: subst[1],
            dvns: val
        };
    });
}
