import React, { Component } from 'react';

import { FallBackUI } from './FallBackUI';

interface State {
    errorInfo: any;
}

export class ErrorBoundary extends Component<{}, State> {
    constructor(props: any) {
        super(props);
        this.state = { errorInfo: null };
    }

    componentDidCatch(_error: any, errorInfo: any) {
        this.setState({
            errorInfo: errorInfo
        });
    }

    render() {
        if (this.state.errorInfo) {
            return <FallBackUI />;
        }

        return this.props.children;
    }
}
