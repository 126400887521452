import React, { memo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'antd';

import { downloadURL } from '../../utils/downloadURL';

import { EquipmentList } from '../EquipmentList/EquipmentList';
import { ButtonGroupBar } from '../CommonComponents/ButtonGroupBar';
import { ToolBar } from '../CommonComponents/ToolBar';
import { Icon123 } from '../CommonComponents/Icon';
import { FastMovingBarIcon } from '../FastMovingBar/FastMovingBarIcon';

import { ReactComponent as CartIcon } from '../CommonComponents/Icons/CartIcon.svg';
import { ReactComponent as EquipmentIcon } from '../CommonComponents/Icons/EquipmentIcon.svg';
import { ReactComponent as SelectionFilterIcon } from '../CommonComponents/Icons/SelectionFilterIcon.svg';
import { ReactComponent as MultiSelectionIcon } from '../CommonComponents/Icons/MultiSelectionIcon.svg';
import { ReactComponent as NextElementIcon } from '../CommonComponents/Icons/NextElementIcon.svg';
import { ReactComponent as PreviousElementIcon } from '../CommonComponents/Icons/PreviousElementIcon.svg';
import { ReactComponent as AddIcon } from '../CommonComponents/Icons/AddIcon.svg';
import { ReactComponent as CarIcon } from '../CommonComponents/Icons/CarIcon.svg';
import { ReactComponent as ZoneCarIcon } from '../CommonComponents/Icons/ZoneCarIcon.svg';
import { ReactComponent as SearchIcon } from '../CommonComponents/Icons/SearchIcon.svg';
import { ReactComponent as InfoIcon } from '../CommonComponents/Icons/InfoIcon.svg';
import { ReactComponent as CrossIcon } from '../CommonComponents/Icons/CrossIcon.svg';

import { ReactComponent as ScaleInIcon } from '../CommonComponents/Icons/ScaleInIcon.svg';
import { ReactComponent as ScaleOutIcon } from '../CommonComponents/Icons/ScaleOutIcon.svg';
import { ReactComponent as ScaleFitIcon } from '../CommonComponents/Icons/ScaleFitIcon.svg';
import { ReactComponent as FullScreenIcon } from '../CommonComponents/Icons/FullScreenIcon.svg';
import { SaveOutlined } from '@ant-design/icons';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';
import { equipmentModel } from '../../stores/equipmentModel';
import { subscribes } from '../../stores/subscribes';
import { SvgViewerEvents } from '../GroupGraphicSVG3/GroupGraphicSVG3Types';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { convertRepairPositionsToDamageList } from '../../utils/convertRepairPositionsToDamageList';
import { ManualRepairPositionFormExtended } from '../GraphicSetDamageForms/ManualRepairPositionForm/ManualRepairPositionFormExtended';
import { manualPositionFormExtendedModel } from '../../stores/manualPositionFormExtendedModel';

import { RepairPositionsList } from '../RepairPositionsList/RepairPositionsList';
import { SplitterButtonGroupBar } from '../CommonComponents/SplitterButtonGroupBar';

export const ToolBarsMobile = memo(({ svgViewerEvents }: { svgViewerEvents?: SvgViewerEvents }) => {
    const { t } = useTranslation();
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const {
        showGraphicGroupsMenu,
        showFullscreen,
        typeOfGraphic,
        currentAssemblyGroup,
        isSelectingSingle,
        isSelectingLeft,
        isSelectingRight,
        defaultDamages,
        isShowFilterMenu,
        isShowMultiSelectMenu,
        isShowMaterialLegends
    } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const deselectedSeriesEquipment = useStore(equipmentModel.stores.deselectedSeriesEquipment);
    const specialEquipment = useStore(equipmentModel.stores.specialEquipment);
    const filteredByZoneAndOptionsGraphicsList = useStore(subscribes.stores.filteredByZoneAndOptionsGraphicsList);
    const currentView = useStore(rightSideDrawerModel.stores.currentView);

    const availableAssemblyGroupsStore = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);
    const { availableAssemblyGroups } = availableAssemblyGroupsStore;

    const currentSvgZoneGraphic = useStore(zoneGraphicsModel.stores.currentSvgZoneGraphic);
    const zoneGraphicSVG = currentSvgZoneGraphic?.value;

    if (!pluginOptions) return null;
    const showFastMovingBar = pluginOptions.settings?.showFastMovingBar;
    const vehicle = pluginOptions.settings?.contract?.Dossier?.Vehicle;

    const damageList = convertRepairPositionsToDamageList(repairPositions, availableAssemblyGroups);

    const isMobile = true;

    return (
        <>
            {/* ======================================= bottom tool bar ===============================================  */}
            <ToolBar
                isMobile={isMobile}
                flexDirection="row"
                style={{
                    position: 'absolute',
                    left: '0px',
                    bottom: '0px',
                    zIndex: showFullscreen ? 1010 : 10
                }}
            >
                <ButtonGroupBar isMobile={isMobile} flexDirection="row">
                    <Icon123
                        icon={<CarIcon />}
                        active={showGraphicGroupsMenu}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                showGraphicGroupsMenu: !showGraphicGroupsMenu,
                                currentAssemblyGroupObject: undefined,
                                currentAssemblyGroupObjects: undefined
                            });
                        }}
                    />

                    {!!zoneGraphicSVG && (
                        <Icon123
                            icon={<ZoneCarIcon />}
                            active={typeOfGraphic === 'ZoneGraphic'}
                            onClick={() => {
                                graphicDamageSelectionModel.events.updateStore({
                                    typeOfGraphic: 'ZoneGraphic'
                                });
                            }}
                        />
                    )}

                    <Icon123
                        icon={<SearchIcon />}
                        // active={typeOfGraphic !== 'ZoneGraphic'}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                showGraphicGroupsMenu: !showGraphicGroupsMenu,
                                currentAssemblyGroupObject: undefined,
                                currentAssemblyGroupObjects: undefined
                            });

                            window.setTimeout(() => document.getElementById('searchInput')?.focus(), 500);
                            // document.getElementById('searchInput')?.focus();
                        }}
                    />

                    {pluginOptions.onReturn && pluginOptions.onReturn instanceof Function && (
                        <Icon123
                            icon={<PreviousElementIcon />}
                            onClick={() => {
                                if (pluginOptions.onReturn && pluginOptions.onReturn instanceof Function)
                                    pluginOptions.onReturn({
                                        repairPositions,
                                        deselectedSeriesEquipment: deselectedSeriesEquipment?.EquipmentPosition,
                                        specialEquipment: specialEquipment?.EquipmentPosition
                                    });
                            }}
                        />
                    )}

                    {pluginOptions.settings?.debuggingMode && (
                        <Icon123
                            icon={<SaveOutlined />}
                            onClick={() => {
                                if (currentAssemblyGroup?.svg) {
                                    downloadURL(
                                        `data:text/plain;charset=utf-8,${encodeURIComponent(
                                            currentAssemblyGroup?.svg
                                        )}`,
                                        `graphic_${vehicle?.DatECode?.replace(
                                            / /g,
                                            ''
                                        )}_${currentAssemblyGroup?.assemblyGroup.assemblyGroupId.toString()}.svg`
                                    );
                                }
                            }}
                        />
                    )}
                </ButtonGroupBar>

                {typeOfGraphic === 'FullGraphic' && (
                    <ButtonGroupBar isMobile={isMobile} flexDirection="row">
                        <Icon123
                            icon={<PreviousElementIcon />}
                            // active={!!defaultDamages?.length}
                            onClick={() => {
                                graphicDamageSelectionModel.events.setPreviousAssemblyGroup(
                                    filteredByZoneAndOptionsGraphicsList
                                );
                            }}
                        />

                        <Icon123
                            icon={<NextElementIcon />}
                            // active={!!defaultDamages?.length}
                            onClick={() => {
                                graphicDamageSelectionModel.events.setNextAssemblyGroup(
                                    filteredByZoneAndOptionsGraphicsList
                                );
                            }}
                        />
                    </ButtonGroupBar>
                )}

                <ButtonGroupBar isMobile={isMobile}>
                    <Icon123
                        icon={isShowMaterialLegends ? <CrossIcon /> : <InfoIcon />}
                        active={isShowMaterialLegends}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                isShowMaterialLegends: true
                            });
                        }}
                    />
                </ButtonGroupBar>
            </ToolBar>

            {/* ======================================= right tool bar ===============================================  */}

            <ToolBar
                isMobile={isMobile}
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,

                    zIndex: showFullscreen ? 1010 : 10,
                    height: isMobile ? '100%' : 'unset'
                }}
            >
                <ButtonGroupBar isMobile={isMobile}>
                    <Icon123 icon={<ScaleInIcon />} onClick={() => svgViewerEvents?.zoomIn()} />
                    <Icon123 icon={<ScaleOutIcon />} onClick={() => svgViewerEvents?.zoomOut()} />
                    <Icon123 icon={<ScaleFitIcon />} onClick={() => svgViewerEvents?.fitToViewer()} />
                    <Icon123
                        icon={<FullScreenIcon />}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                showFullscreen: !showFullscreen
                            });
                        }}
                    />
                </ButtonGroupBar>
                <SplitterButtonGroupBar />
                <ButtonGroupBar isMobile={isMobile}>
                    <Icon123
                        icon={<MultiSelectionIcon />}
                        active={!!defaultDamages?.length || isShowMultiSelectMenu}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                isShowMultiSelectMenu: true
                            });
                        }}
                    />

                    <Icon123
                        icon={<SelectionFilterIcon />}
                        active={isSelectingSingle || isSelectingLeft || isSelectingRight || isShowFilterMenu}
                        onClick={() => {
                            graphicDamageSelectionModel.events.updateStore({
                                isShowFilterMenu: true
                            });
                        }}
                    />
                </ButtonGroupBar>
                <SplitterButtonGroupBar />
                <ButtonGroupBar isMobile={isMobile}>
                    {showFastMovingBar &&
                        pluginOptions?.settings?.fastMovingBar &&
                        pluginOptions?.settings?.fastMovingBar.length > 0 && (
                            <FastMovingBarIcon fastMovingBar={pluginOptions?.settings?.fastMovingBar} t={t} />
                        )}
                </ButtonGroupBar>
                <SplitterButtonGroupBar />
                <ButtonGroupBar isMobile={isMobile}>
                    <Icon123
                        icon={<EquipmentIcon />}
                        active={currentView === EquipmentList}
                        onClick={() => {
                            rightSideDrawerModel.events.setCurrentView(EquipmentList);
                        }}
                    />

                    <Icon123
                        icon={<AddIcon />}
                        active={currentView === ManualRepairPositionFormExtended}
                        onClick={() => {
                            manualPositionFormExtendedModel.events.newManualRepairPositionForm();
                            rightSideDrawerModel.events.setCurrentView(ManualRepairPositionFormExtended);
                        }}
                    />

                    <Badge
                        color="rgb(255, 209, 0)"
                        count={damageList.length}
                        offset={[-14, 14]}
                        style={{
                            pointerEvents: 'none',
                            color: 'rgb(16, 51, 102)'
                            // font: '700 12px / 18px "Noto Sans TC"',
                            // height: '18px'
                        }}
                    >
                        <Icon123
                            icon={<CartIcon />}
                            active={currentView === RepairPositionsList}
                            onClick={() => {
                                graphicDamageSelectionModel.events.updateStore({
                                    currentAssemblyGroupObject: undefined,
                                    currentAssemblyGroupObjects: undefined
                                });
                                rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                            }}
                        />
                    </Badge>
                </ButtonGroupBar>
            </ToolBar>
        </>
    );
});
