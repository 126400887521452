import { ArrowLeftOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Spin } from 'antd';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { firstLetterToUpperCase } from '../../utils/firstLetterToUpperCase';
import { EquipmentDeps } from './EquipmentDeps';
import { PriceHistory } from './PriceHistory';
import { ReactComponent as CheckIcon } from '../CommonComponents/Icons/CheckIcon.svg';
import { partsInfoModel } from '../../stores/partsInfoModel';

interface PartList {
    datProcessId: number;
    partName?: string;
}

export interface PartsInfoType {
    partList: PartList[];
}

export const PartsInfo: FC = () => {
    const { t } = useTranslation();

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const currentSparePartVariants = useStore(partsInfoModel.stores.currentSparePartVariants);
    const currentExtPartNoInfo = useStore(partsInfoModel.stores.currentExtPartNoInfo);
    const getSparePartVariantsPending = useStore(partsInfoModel.effects.getSparePartVariantsFx.pending);
    const partList = useStore(partsInfoModel.stores.currentPartList);

    const [showInfo, setShowInfo] = useState(false);
    const [equipmentsByPartNumber, setEquipmentsByPartNumber] = useState('');
    const [historyByPartNumber, setHistoryByPartNumber] = useState('');
    const [currentDatProcessId, setCurrentDatProcessId] = useState(0);

    useEffect(() => {
        if (partList.length === 1) {
            setShowInfo(true);
            setCurrentDatProcessId(partList[0].datProcessId);
            partsInfoModel.events.runGetSparePartVariants(partList[0].datProcessId);
            partsInfoModel.events.runGetExtPartNoInfoByFullVehicleAndIntPartNo(partList[0].datProcessId.toString());
        } else {
            setShowInfo(false);
            setCurrentDatProcessId(0);
        }

        setEquipmentsByPartNumber('');
        setHistoryByPartNumber('');
    }, [partList]);

    const country = pluginOptions?.settings?.locale?.country;
    const initState = !showInfo && !equipmentsByPartNumber && !historyByPartNumber;

    const sparePartPosition = currentExtPartNoInfo?.Dossiers.Dossier[0].SparePartPositions.SparePartPosition[0];
    const sparePartNumber = sparePartPosition?.PartNumber.toString();

    const sparePartVariants = currentSparePartVariants?.sparePartVariant;
    const sparePartNumbers = sparePartVariants?.filter(
        (value, index, self) => value.partNumber && self.findIndex(v => v.partNumber === value.partNumber) === index
    );
    sparePartNumbers?.sort((partA, partB) =>
        partA.partNumber === sparePartNumber ? -1 : partB.partNumber === sparePartNumber ? 1 : 0
    );

    if (getSparePartVariantsPending) return <Spin style={{ width: '100%', marginTop: '100px' }} />;

    return (
        <div
            key={partList[0]?.datProcessId}
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px'
            }}
        >
            {/* ============= HEADER ============ */}
            <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                <div style={{ float: 'left' }}>
                    <Space>
                        <Button
                            disabled={(partList.length === 1 && showInfo) || initState}
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                if (equipmentsByPartNumber || historyByPartNumber) {
                                    setEquipmentsByPartNumber('');
                                    setHistoryByPartNumber('');
                                    setShowInfo(true);
                                } else {
                                    setShowInfo(false);
                                    setCurrentDatProcessId(0);
                                }
                            }}
                        />
                        <h2 style={{ marginLeft: '8px', marginBottom: '0px' }}>
                            {t('PartsInfo.Header')}
                            {currentDatProcessId
                                ? ' - ' +
                                  firstLetterToUpperCase(
                                      partList.find(part => part.datProcessId === currentDatProcessId)?.partName || ''
                                  )
                                : ''}
                        </h2>
                    </Space>
                </div>
            </div>

            <div
                style={{
                    paddingRight: '4px',
                    marginTop: '20px',
                    position: 'absolute',
                    top: '54px',
                    left: '0px',
                    right: '0px',
                    bottom: '54px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row align="middle" gutter={[8, 8]}>
                    {showInfo && !getSparePartVariantsPending ? (
                        <>
                            <Col span={22} offset={2}>
                                {sparePartPosition ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <span>
                                            {t('PartsInfo.SparePart') + ': '}
                                            <b>{sparePartPosition?.PartNumber || ''}</b>
                                        </span>
                                        <b>
                                            {new Intl.NumberFormat(country, {
                                                minimumFractionDigits: 2
                                            }).format(sparePartPosition?.LastUPE || 0)}
                                        </b>
                                    </div>
                                ) : (
                                    <div>{t('PartsInfo.NoSparePart')}</div>
                                )}
                            </Col>
                            <Divider />
                            {sparePartNumbers?.length
                                ? sparePartNumbers.map(variant =>
                                      variant ? (
                                          <Col key={variant.partNumber} span={24}>
                                              <Row align="middle" gutter={[8, 8]}>
                                                  <Col span={3}>
                                                      {variant.partNumber === sparePartNumber ? (
                                                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                              <CheckIcon />
                                                          </div>
                                                      ) : null}
                                                  </Col>
                                                  <Col span={13}>
                                                      <div
                                                          key={variant.partNumber}
                                                          style={{ display: 'flex', justifyContent: 'space-between' }}
                                                      >
                                                          <span>{variant.partNumber}</span>
                                                          <span style={{ marginRight: '10px' }}>
                                                              {new Intl.NumberFormat(country, {
                                                                  minimumFractionDigits: 2
                                                              }).format(variant.price || 0)}
                                                          </span>
                                                      </div>
                                                  </Col>
                                                  <Col span={8}>
                                                      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                          <Button
                                                              icon={<InfoCircleOutlined />}
                                                              size="large"
                                                              onClick={() => {
                                                                  setEquipmentsByPartNumber(
                                                                      variant.partNumber || 'none'
                                                                  );
                                                                  setShowInfo(false);
                                                              }}
                                                          ></Button>
                                                          <Button
                                                              icon={<HistoryOutlined />}
                                                              size="large"
                                                              onClick={() => {
                                                                  setHistoryByPartNumber(variant.partNumber || 'none');
                                                                  setShowInfo(false);
                                                              }}
                                                          ></Button>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </Col>
                                      ) : null
                                  )
                                : null}
                        </>
                    ) : null}
                    {partList.length > 1 && initState
                        ? partList.map(part => (
                              <Col key={part.datProcessId} span={24}>
                                  <Button
                                      key={part.datProcessId}
                                      style={{ whiteSpace: 'nowrap', textAlign: 'left' }}
                                      size="large"
                                      block
                                      onClick={() => {
                                          setShowInfo(true);
                                          setCurrentDatProcessId(part.datProcessId);
                                          partsInfoModel.events.runGetSparePartVariants(part.datProcessId);
                                          partsInfoModel.events.runGetExtPartNoInfoByFullVehicleAndIntPartNo(
                                              part.datProcessId.toString()
                                          );
                                      }}
                                  >
                                      {part.partName ? firstLetterToUpperCase(part.partName) : ''}
                                  </Button>
                              </Col>
                          ))
                        : null}
                </Row>

                {equipmentsByPartNumber && <EquipmentDeps partNumber={equipmentsByPartNumber} />}
                {historyByPartNumber && <PriceHistory partNumber={historyByPartNumber} />}
            </div>

            {/* =================== FOOTER =================== */}
            <div
                style={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Row gutter={[8, 16]}>
                    <Col span={12}>
                        <Button
                            disabled={(partList.length === 1 && showInfo) || initState}
                            size="large"
                            style={{ width: '100%' }}
                            onClick={() => {
                                if (equipmentsByPartNumber || historyByPartNumber) {
                                    setEquipmentsByPartNumber('');
                                    setHistoryByPartNumber('');
                                    setShowInfo(true);
                                } else {
                                    setShowInfo(false);
                                    setCurrentDatProcessId(0);
                                }
                            }}
                        >
                            {t('PartsInfo.Back')}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            size="large"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => {
                                rightSideDrawerModel.events.clearHistoryAndClose();
                            }}
                        >
                            {t('PartsInfo.Close')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
