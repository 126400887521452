import {
    Wrapper,
    PoiButton,
    СarImage,
    ButtonBlock,
    Buttons,
    ButtonFrontCapote,
    ButtonsFront,
    ButtonBack
} from './style';
import { FC } from 'react';
import { CAR_TOP, CAR_FRONT_LEFT, CAR_FRONT_CAPOTE, CAR_LEFT, CAR_BACK, CAR_BOTTOM } from './constants';

interface PluginOptions {
    image: string;
    positions: string[];
    onAttahDamagePoint: (position: string) => void;
    isRotatedComponent?: boolean;
}

export const DamageSelector: FC<PluginOptions> = ({ image, onAttahDamagePoint, positions, isRotatedComponent }) => (
    <Wrapper rotate={isRotatedComponent?.toString()}>
        <СarImage src={image} alt="car image" />
        <ButtonBlock>
            <Buttons>
                {CAR_TOP.map(position => (
                    <PoiButton
                        key={position}
                        onClick={() => onAttahDamagePoint(position)}
                        primary={positions.includes(position)}
                    />
                ))}
            </Buttons>
            <Buttons>
                <ButtonsFront>
                    <PoiButton
                        onClick={() => onAttahDamagePoint(CAR_FRONT_LEFT)}
                        primary={positions.includes(CAR_FRONT_LEFT)}
                    />
                </ButtonsFront>

                <ButtonFrontCapote>
                    <PoiButton
                        onClick={() => onAttahDamagePoint(CAR_FRONT_CAPOTE)}
                        primary={positions.includes(CAR_FRONT_CAPOTE)}
                    />
                </ButtonFrontCapote>

                <PoiButton onClick={() => onAttahDamagePoint(CAR_LEFT)} primary={positions.includes(CAR_LEFT)} />

                <ButtonBack>
                    {CAR_BACK.map(position => (
                        <PoiButton
                            key={position}
                            onClick={() => onAttahDamagePoint(position)}
                            primary={positions.includes(position)}
                        />
                    ))}
                </ButtonBack>
            </Buttons>
            <Buttons>
                {CAR_BOTTOM.map(position => (
                    <PoiButton
                        key={position}
                        onClick={() => onAttahDamagePoint(position)}
                        primary={positions.includes(position)}
                    />
                ))}
            </Buttons>
        </ButtonBlock>
    </Wrapper>
);
