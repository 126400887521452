// TODO: make init file here
// пока не перенес сюда связи, т.к. в index.ts файле находится много сторов, созданных через combine,
// и пока непонятно, как их перенести сюда
import { sample } from 'effector';

import { containedPluginsEvents, containedPluginsStores } from './index';

const { vehicleSelectionInputsChanged } = containedPluginsEvents;
const { vehicleSelectionPluginOptions } = containedPluginsStores;

sample({
    clock: vehicleSelectionInputsChanged,
    source: vehicleSelectionPluginOptions,
    fn: (options, { numberPlate, vin, kilometers }) => ({
        ...options,
        inputFields: {
            NumberPlate: numberPlate,
            VIN: vin,
            MileageOdometer: kilometers
        }
    }),
    target: vehicleSelectionPluginOptions
});
