export const checkManufacturer = (position: DAT2.PositionItaly, repairPositions: DAT2.RepairPosition[]) => {
    const repairPositionsOfCurrentPosition = repairPositions.filter(
        rPos => position.Description === rPos.Description && position.DATProcessId === rPos.DATProcessId
    );
    const isRepairManufacturer = repairPositionsOfCurrentPosition.some(rPos => rPos.WorkLevelItaly === 'manufacturer');

    return (
        position.WorkLevelReplace === 'manufacturer' ||
        position.WorkLevelOverhaul === 'manufacturer' ||
        position.WorkLevelMechanic === 'manufacturer' ||
        isRepairManufacturer
    );
};
