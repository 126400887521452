import styled, { css } from 'styled-components';
import { BoxSwitcherStyledProps } from './types';

export const BoxSwitcherRoot = styled.div<BoxSwitcherStyledProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${({ noHeight }) => (noHeight ? 'unset' : '48px')};
    padding: ${({ noPadding }) => (noPadding ? '0' : '12px')};
    background-color: white;
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')};
    border: ${({ noBorder, theme, disabled }) =>
        noBorder ? 'unset' : `1px solid ${disabled ? theme.colors.dustBlue[50] : theme.colors.dustBlue[100]}`};
    box-sizing: border-box;
    outline: none;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                border-color: ${({ theme }) => theme.colors.dustBlue[300]};
            }

            &:focus,
            &:focus-within {
                border-color: ${({ theme }) => theme.colors.blue[500]};
            }
        `}
`;
