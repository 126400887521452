import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useToggle } from '@dat/core/hooks/useToggle';
import { AddressBookIcon, CloseIcon } from '@wedat/ui-kit/components/Icons';
import { CheckboxField, InputField } from '@wedat/ui-kit/Formik';
import { FormikMailValues } from '../../constants/formik';
import { modalsStores, modalsEvents } from '../../stores/modals';
import { SelectedSubjects } from '../SelectedSubjects';
import { recipientsEvents, recipientsStores } from '../../stores/recipients';
import { sendMailEvents, sendMailStores } from '../../stores/sendMail';
import { CC_CCN_ICONS_WIDTH, CC_ICON_WIDTH, CLOSE_ICON_WIDTH } from '../../constants/subjects';
import { InputClose } from '../ContractList/styles';
import { AttachmentsBlock } from './AttachmentsBlock';
import { Footer } from '../Main/styles';
import {
    ButtonStyled,
    Container,
    InputWrapper,
    TextareaStyled,
    InputsContainer,
    CCButtons,
    CCButton,
    CCText,
    AddressBookButton,
    Content,
    ChekboxesGroup,
    CheckboxTitle,
    CheckboxesContainer,
    SuccessfullyWrapper
} from './styles';
import { SuccessfullySentView } from '../SuccessfullySentView';

const { toggleIsContractListOpen, toggleIsContractListCCOpen, toggleIsContractListCCNOpen, toggleIsAttachments } =
    modalsEvents;
const {
    addItemToRecipients,
    addItemToCcRecipients,
    addItemToBccRecipients,
    removeItemFromRecipients,
    removeItemFromCcRecipients,
    removeItemFromBccRecipients,
    resetRecipients,
    resetCcRecipients,
    resetBccRecipients
} = recipientsEvents;
const { successfullySent } = sendMailEvents;

export const Inputs: React.FC = () => {
    const { t } = useTranslation();
    const [ccOpen, toggleCCOpen] = useToggle();
    const [ccnOpen, toggleCCNOpen] = useToggle();

    const { errors, touched, values, setFieldValue, resetForm } = useFormikContext<FormikMailValues>();
    const { subject, body } = values;

    const isContractListOpen = useStore(modalsStores.isContractListOpen);
    const isContractListCCOpen = useStore(modalsStores.isContractListCCOpen);
    const isContractListCCNOpen = useStore(modalsStores.isContractListCCNOpen);

    const recipients = useStore(recipientsStores.recipients);
    const ccRecipients = useStore(recipientsStores.ccRecipients);
    const bccRecipients = useStore(recipientsStores.bccRecipients);

    useEffect(() => {
        setFieldValue(
            'recipients',
            recipients.map(item => `CC:${item.mail}`)
        );
    }, [recipients, setFieldValue]);
    useEffect(() => {
        setFieldValue(
            'ccRecipients',
            ccRecipients.map(item => `CC:${item.mail}`)
        );
    }, [ccRecipients, setFieldValue]);
    useEffect(() => {
        setFieldValue(
            'bccRecipients',
            bccRecipients.map(item => `CC:${item.mail}`)
        );
    }, [bccRecipients, setFieldValue]);

    const handleOpenContractList = () => {
        toggleIsContractListOpen();
        toggleIsContractListCCOpen(false);
        toggleIsContractListCCNOpen(false);
        toggleIsAttachments(false);
    };

    const handleOpenContractListCC = () => {
        toggleIsContractListOpen(false);
        toggleIsContractListCCOpen();
        toggleIsContractListCCNOpen(false);
        toggleIsAttachments(false);
    };

    const handleOpenContractListCCN = () => {
        toggleIsContractListOpen(false);
        toggleIsContractListCCOpen(false);
        toggleIsContractListCCNOpen();
        toggleIsAttachments(false);
    };

    const handleHideCCInput = () => {
        toggleCCOpen();
        toggleIsContractListCCOpen(false);
    };

    const handleHideCCNInput = () => {
        toggleCCNOpen();
        toggleIsContractListCCNOpen(false);
    };

    const resetFormValues = () => {
        resetForm();
        successfullySent(false);
    };

    const sendButtonDisabled = !recipients.length || !subject || !body;

    const ccOrccnOpen = (ccOpen && !ccnOpen) || (!ccOpen && ccnOpen);
    const ccAndccnOpen = ccOpen && ccnOpen;
    const iconsWidth = ccOrccnOpen ? CC_ICON_WIDTH : CC_CCN_ICONS_WIDTH;
    const recipientsPaddingRight = ccAndccnOpen ? 0 : iconsWidth;

    const ccRecipientsPaddingRight = ccRecipients?.length === 0 ? CLOSE_ICON_WIDTH : 0;
    const bccRecipientsPaddingRight = bccRecipients?.length === 0 ? CLOSE_ICON_WIDTH : 0;

    const isSuccessfullySent = useStore(sendMailStores.isSuccessfullySent);

    return (
        <SuccessfullyWrapper>
            <Container>
                <Content>
                    {/* INPUTS */}
                    <InputsContainer>
                        <InputWrapper>
                            <AddressBookButton onClick={handleOpenContractList} active={isContractListOpen}>
                                <AddressBookIcon />
                            </AddressBookButton>

                            <SelectedSubjects
                                subjects={recipients}
                                removeItem={removeItemFromRecipients}
                                removeAllSubjects={resetRecipients}
                                addSubject={addItemToRecipients}
                                label={t('inputs.address')}
                                error={touched.recipients ? errors.recipients : ''}
                                paddingRight={recipientsPaddingRight}
                            />

                            <CCButtons>
                                {!ccOpen && (
                                    <CCButton onClick={toggleCCOpen}>
                                        <CCText font="font12">+CC</CCText>
                                    </CCButton>
                                )}
                                {!ccnOpen && (
                                    <CCButton onClick={toggleCCNOpen}>
                                        <CCText font="font12">+CCN</CCText>
                                    </CCButton>
                                )}
                            </CCButtons>
                        </InputWrapper>

                        {ccOpen && (
                            <InputWrapper>
                                <AddressBookButton onClick={handleOpenContractListCC} active={isContractListCCOpen}>
                                    <AddressBookIcon />
                                </AddressBookButton>

                                <SelectedSubjects
                                    subjects={ccRecipients}
                                    removeItem={removeItemFromCcRecipients}
                                    removeAllSubjects={resetCcRecipients}
                                    addSubject={addItemToCcRecipients}
                                    label={t('inputs.addressCC')}
                                    paddingRight={ccRecipientsPaddingRight}
                                />

                                {ccRecipients?.length === 0 && (
                                    <InputClose onClick={handleHideCCInput}>
                                        <CloseIcon />
                                    </InputClose>
                                )}
                            </InputWrapper>
                        )}

                        {ccnOpen && (
                            <InputWrapper>
                                <AddressBookButton onClick={handleOpenContractListCCN} active={isContractListCCNOpen}>
                                    <AddressBookIcon />
                                </AddressBookButton>

                                <SelectedSubjects
                                    subjects={bccRecipients}
                                    removeItem={removeItemFromBccRecipients}
                                    removeAllSubjects={resetBccRecipients}
                                    addSubject={addItemToBccRecipients}
                                    label={t('inputs.addressCCN')}
                                    paddingRight={bccRecipientsPaddingRight}
                                />

                                {bccRecipients?.length === 0 && (
                                    <InputClose onClick={handleHideCCNInput}>
                                        <CloseIcon />
                                    </InputClose>
                                )}
                            </InputWrapper>
                        )}

                        <InputWrapper>
                            <InputField helperText={errors.subject} name="subject" label={t('inputs.subject')} />
                        </InputWrapper>

                        <InputWrapper>
                            <TextareaStyled
                                label={t('inputs.content')}
                                helperText={errors.body}
                                name="body"
                                placeholder={t('inputs.contentPlaceholder')}
                            />
                        </InputWrapper>
                    </InputsContainer>

                    {/* ATTACHMENTS */}
                    <AttachmentsBlock />

                    <CheckboxesContainer>
                        <ChekboxesGroup>
                            <CheckboxTitle font="note">{t('inputs.settings')}</CheckboxTitle>
                            <CheckboxField name="exportCombinedZip" label={t('inputs.exportCombinedZip')} />
                            <CheckboxField name="exportCombinedPdf" label={t('inputs.exportCombinedPdf')} />
                        </ChekboxesGroup>

                        <ChekboxesGroup>
                            <CheckboxTitle font="note">{t('inputs.attachmentOptions')}</CheckboxTitle>
                            <CheckboxField name="dossier" label={t('inputs.dataXml')} />
                            <CheckboxField name="adzFiles" label={t('inputs.attachAdz')} />
                            <CheckboxField name="szf" label={t('inputs.dataSzf')} />
                        </ChekboxesGroup>
                    </CheckboxesContainer>
                </Content>

                <Footer>
                    <ButtonStyled onClick={resetFormValues} typeStyle={{ type: 'transparent' }}>
                        {t('inputs.cancel')}
                    </ButtonStyled>
                    <ButtonStyled type="submit" disabled={sendButtonDisabled}>
                        {t('inputs.send')}
                    </ButtonStyled>
                </Footer>
            </Container>

            {isSuccessfullySent && <SuccessfullySentView />}
        </SuccessfullyWrapper>
    );
};
