import styled from 'styled-components/macro';
import { Fade } from '@wedat/ui-kit/components/Fade';
import { media } from '@wedat/ui-kit/mediaQueries';

export const AuthPageStyled = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
`;

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 50%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray['100']};
`;

export const StyledFade = styled(Fade)`
    display: flex;
    justify-content: flex-end;
`;

export const CarImg = styled.img`
    position: relative;
    max-width: 100%;
    margin-right: -5%;
    z-index: 1;
`;
export const LogoImg = styled.img`
    position: absolute;
    width: 84px;
    height: 132px;
    top: 32px;
    left: 32px;

    ${media.tabletSmall`
        width: 64px;
        height: 100px;
    `}

    ${media.phoneBig`
        top: 24px;
        left: 24px;
        width: 34px;
        height: 52px;
    `}
`;

export const RightSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 50%;
    height: 100%;
    background-color: white;
`;
