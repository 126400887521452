import styled, { css, createGlobalStyle } from 'styled-components';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
interface Props {
    showTimeSelect?: boolean;
}

const datepickerStyles = ({ showTimeSelect }: Props) => css`
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .react-datepicker {
        font-family: 'Noto Sans TC';
        box-sizing: border-box;
        border-radius: 8px;
        border-width: 0;
        padding: 20px 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &-wrapper {
            width: 100%;
        }
        &-popper {
            animation: fade-in 0.25s ease-out;
            z-index: 2; // should be more then label in Input
            padding-top: 0;
        }
        &__day {
            ${({ theme }) => theme.typography.note};
            border-radius: 50%;
            width: 32px;
            height: 32px;
            line-height: 30px;
            margin: 2px;
            :hover {
                background-color: ${({ theme }) => theme.colors.dustBlue['100']};
            }
            &--selected {
                background: ${({ theme }) => theme.colors.blue['400']};
                font-weight: 700;
                :hover {
                    background-color: ${({ theme }) => theme.colors.blue['400']};
                }
            }
            &--keyboard-selected {
                color: inherit;
                background: none;

                &:hover {
                    background: #f0f0f0;
                }
            }
            &--outside-month {
                &.react-datepicker__day--selected {
                    color: ${({ theme }) => theme.colors.white};
                }
            }
        }
        &__month-container {
            margin: 0 auto 14px;
            padding: 0 20px;
        }
        &__header {
            background-color: ${({ theme }) => theme.colors.white};
            border-bottom: none;
        }
        &__current-month {
            ${({ theme }) => theme.typography.defaultText};
            color: ${({ theme }) => theme.colors.gray_300};
            width: fit-content;
            margin-left: 14px;
        }
        &__current-month::first-letter {
            text-transform: uppercase;
            position: absolute;
        }
        &__day-name {
            ${({ theme }) => theme.typography.footnote};
            color: ${({ theme }) => theme.colors.dustBlue['400']};
            margin-bottom: 14px;
            &::first-letter {
                text-transform: uppercase;
                position: absolute;
            }
        }
        &__day--outside-month {
            color: ${({ theme }) => theme.colors.dustBlue['400']};
            pointer-events: none;
        }
        &__week {
            display: flex;
            justify-content: space-between;
        }
        &__day-names {
            display: flex;
            justify-content: space-around;
            margin-top: 26px;
        }
        &__today-button {
            background-color: transparent;
            width: fit-content;
            padding: 12px 40px;
            border: ${({ theme }) => `1px solid ${theme.colors.dustBlue['300']}`};
            border-radius: 8px;
            margin: 0 auto;
            color: ${({ theme }) => theme.colors.dustBlue['900']};
            ${({ theme }) => theme.typography.note};
        }

        &__time-container,
        &__time-container--with-today-button {
            right: auto;
            display: flex;
            flex-direction: column;
            left: 100%;
            width: 170px;
            height: 100%;
            border: none;
            border-left: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
        }

        &__time {
            flex: 1;
            padding-bottom: 20px;
            height: calc(100% - 90px);
        }

        &__header--time {
            padding-left: 0;
            padding-right: 0;
            padding-top: 24px;
            padding-bottom: 20px;
        }

        &-time__header {
            ${({ theme }) => theme.typography.defaultText};
        }

        &__time-list {
            /* Need kill inline styles */
            height: 100% !important;
            ${makeCustomScrollBar()};
        }

        &__time-list-item {
            ${({ theme }) => theme.typography.note};
            line-height: 20px;
            text-align: left;
        }
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;
        height: 100%;
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: ${({ theme }) => theme.colors.blue['400']};
    }

    ${showTimeSelect &&
    css`
        .react-datepicker-popper {
            width: 470px;
            box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.21);
        }
    `}
`;
export const GlobalStyle = createGlobalStyle<Props>`
    ${({ showTimeSelect }) => datepickerStyles({ showTimeSelect })};

    .react-datepicker {
        &-popper {
            z-index: 10001; // more then Modal z-index (if portal)
        }
    }
`;

export const StylesWrapper = styled.div<Props>`
    position: relative;

    ${({ showTimeSelect }) => datepickerStyles({ showTimeSelect })};
`;

export const Icon = styled.button`
    width: 24px;
    position: absolute;
    top: 55%;
    right: 14px;
    transform: translateY(-50%);

    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;
