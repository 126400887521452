import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

type Props = {
    isVisibleAppointment?: boolean;
};

export const EfficiencyContainer = styled.div<Props>`
    height: 180px;

    margin: 20px 20px 0 20px;
    display: flex;
    ${media.desktopSmall`
        height: ${({ isVisibleAppointment }) => isVisibleAppointment && '320px'};
        flex-direction: column-reverse;
    `}

    ${media.phoneBig`
        height: ${({ isVisibleAppointment }) => isVisibleAppointment && '375px'};
    `}
`;

export const Appointment = styled.div`
    width: 296px;
    height: 100%;
    box-shadow: 0 0 6px 0 ${({ theme }) => theme.colors.gray['300']};
    border-radius: 16px;
    margin-right: 16px;

    ${media.desktopSmall`
        width: 100%;
        height: 120px;
        margin-top: 16px;
        margin-right: 16px;
    `}
    ${media.tabletSmall`
        width: calc(100% - 32px); //subtract 32px for margin
        margin-left: 16px;
        margin-right: 16px;
    `}
    ${media.phoneBig`
        width: calc(100% - 24px); //subtract 24px for margin
        height: 175px;
        margin-left: 12px;
        margin-right: 12px;
    `};
`;

export const MainWrapper = styled.div<Props>`
    width: ${({ isVisibleAppointment }) =>
        isVisibleAppointment ? 'calc(100% - 296px)' : '100%'}; //subtract appointment width
    display: flex;

    ${media.desktopSmall`
        width: 100%;
    `}
`;
