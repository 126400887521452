import { createEffect, createEvent, createStore, restore } from 'effector';
import { getAppointments, createOrUpdateAppointment, createOrUpdateExpireDate } from '@dat/api2/services/bff/customer';
import { SELECT_FIELDS } from './constants';
import { AppointmentFormSubmit } from './types';
import { SchedulerEvent } from '@dat/scheduler/src/types';

/**
 * Events
 */
const setSelectedUser = createEvent<number>();
const changeActiveTab = createEvent<string>();
//Todo: remove any
const updateSelectedEvent = createEvent<any>();

/**
 * Effects
 */
const getAppointmentsList = createEffect(getAppointments);
const createOrUpdateAppointments = createEffect(createOrUpdateAppointment);
const createExpirationDate = createEffect(createOrUpdateExpireDate);
const submitForm = createEffect(async (payload: AppointmentFormSubmit) => {
    await createOrUpdateAppointment({ value: payload.updatedAppointments });
    await createOrUpdateExpireDate({ value: payload.updatedExpiryDates });
    await getAppointmentsList();
});

/**
 * Stores
 */
const selectedUser = createStore(0).on(setSelectedUser, (_, payload) => payload);

const selectedTab = createStore(SELECT_FIELDS.appointments).on(changeActiveTab, (_, payload) => payload);

const selectedEvent = createStore<SchedulerEvent | null>(null).on(updateSelectedEvent, (_, payload) => payload);

const appointments = restore<BFF.Customer.Response.GetAppointments>(getAppointmentsList.doneData, {
    appointmentDates: [],
    expiryClaimsDate: []
});

const todayAppointments = createStore<DAT2.Appointment[]>([]);

todayAppointments.on(getAppointmentsList.doneData, (_state, payload) => {
    const date = new Date();
    return payload.appointmentDates.filter(
        item =>
            new Date(item.fromDate).getTime() >= date.setUTCHours(0, 0, 0, 0) &&
            new Date(item.toDate).getTime() <= date.setUTCHours(23, 59, 59, 999)
    );
});

export const schedulerStores = {
    appointments,
    selectedTab,
    selectedEvent,
    selectedUser,
    todayAppointments
};

export const schedulerEvents = {
    changeActiveTab,
    updateSelectedEvent,
    setSelectedUser
};

export const schedulerEffects = {
    getAppointmentsList,
    createOrUpdateAppointments,
    createExpirationDate,
    submitForm
};
