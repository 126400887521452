import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10001;
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const StyledButton = styled(Button)`
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 48px;
`;

export const AnylineContent = styled.div`
    width: 100%;
    height: 100%;
`;
