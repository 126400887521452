import React, { FC, useMemo } from 'react';
import { useStore } from 'effector-react';

import { dialogStores, dialogEvents } from '../../../stores/dialogs';
import { getDateFromTimestamp } from '../../../utils/getDateFromTimestamp';
import { isSenderCurrentUser } from '../../../utils/isSenderCurrentUser';
import { sharedUserStores } from '@dat/shared-models/user';

import { Text } from '@wedat/ui-kit/components/Text';
import {
    Container,
    TextBlock,
    TextAndDate,
    ProfileIconStyled,
    GroupAndRole,
    UnreadMessagesCounter,
    MessageAndCounter,
    UserAvatar
} from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
    companionName?: string;
    dialogId: string;
    companionAvatar: any;
}

export const DialogItem: FC<Props> = ({ companionName, dialogId, companionAvatar }) => {
    const { t } = useTranslation();
    const dialogs = useStore(dialogStores.dialogs);
    const currentDialog = useStore(dialogStores.currentDialog);
    const username = useStore(sharedUserStores.username);
    const customerNumber = useStore(sharedUserStores.customerNumber);

    const isCurrent = dialogId === currentDialog?.id;
    const currentItemDialog = dialogs.find(dialog => dialog.id === dialogId);
    const companionChatLength = currentItemDialog?.messages.length || 0;
    const lastMessage = currentItemDialog?.messages[companionChatLength - 1];
    const currentItemDialogMessages = currentItemDialog?.messages;

    const currentItemDialogUnreadMessagesСount = useMemo(
        () =>
            currentItemDialogMessages?.filter(
                message => !message.read && !isSenderCurrentUser({ sender: message.sender, username, customerNumber })
            ).length,
        [currentItemDialogMessages, customerNumber, username]
    );

    return (
        <Container
            isCurrent={isCurrent}
            onClick={() => {
                if (dialogId) {
                    dialogEvents.openDialog(dialogId);
                }
            }}
        >
            {companionAvatar ? <UserAvatar src={companionAvatar} alt="user avatar" /> : <ProfileIconStyled />}
            <TextBlock>
                <TextAndDate>
                    <Text textTransform="capitalize" fontSize="18px" lineHeight="27px" color="gray_300">
                        {companionName}
                    </Text>
                    <Text fontSize="16px" fontWeight={300} color="gray_40">
                        {lastMessage && getDateFromTimestamp(lastMessage.created, true)}
                    </Text>
                </TextAndDate>
                <GroupAndRole>
                    <Text textTransform="capitalize" fontSize="16px" lineHeight="24px" fontWeight={400} color="gray_40">
                        {t('groupAndRole')}
                    </Text>
                </GroupAndRole>
                <MessageAndCounter>
                    <Text textOverflow="ellipsis" fontSize="16px" lineHeight="24px" color="gray_300">
                        {lastMessage?.text}
                    </Text>
                    {!!currentItemDialogUnreadMessagesСount && (
                        <UnreadMessagesCounter>{currentItemDialogUnreadMessagesСount}</UnreadMessagesCounter>
                    )}
                </MessageAndCounter>
            </TextBlock>
        </Container>
    );
};
