import React from 'react';
import { useStore } from 'effector-react';
import { useRouteMatch } from 'react-router-dom';

import { containedPluginsStores } from '../../../stores/containedPlugins';
import { ROUTES } from '../../../constants/router';
import { useBlink } from '../Grapa/useBlink';

import { FastTrack } from '@dat/fast-track';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { isIOS } from 'react-device-detect';
import { FastTrackStyled } from './styles';

export const FastTrackPage: React.FC = () => {
    const options = useStore(containedPluginsStores.fastTrackPluginOptions);
    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const match = useRouteMatch(ROUTES.claim.fastTrack);
    const blink = useBlink([options]);

    const shouldMount = isVehicleIdentified && (blink || match);

    if (shouldMount) {
        return (
            <FastTrackStyled isIOS={isIOS}>
                <FastTrack options={options} />
            </FastTrackStyled>
        );
    }

    return null;
};
