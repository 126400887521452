import { guard, sample } from 'effector';

import { factorsStores } from '../../factors';
import { contractEvents } from '@dat/shared-models/contract';
import { invoiceRatesEffects, invoiceRatesEvents, invoiceRatesStores } from './index';
import { sharedVehicleEvents, sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

const { invoiceRateNameSelected, invoiceRateSelected, triggerAutoSelectFirstInvoiceRate } = invoiceRatesEvents;
const { listInvoiceRatesFx } = invoiceRatesEffects;
const { requestConfigurationParams, invoiceRates, selectOptions, isInvoiceRateNameNotDefinedInContract } =
    invoiceRatesStores;
const { contractFactorsParameters } = factorsStores;
const { newContractReceived } = contractEvents;
const { newVehicleReceived } = sharedVehicleEvents;
const { isVehicleIdentified } = sharedVehicleStores;

sample({
    clock: [newContractReceived, requestConfigurationParams],
    source: requestConfigurationParams,
    target: listInvoiceRatesFx
});

guard({
    source: sample({
        clock: invoiceRateNameSelected,
        source: invoiceRates,
        fn: (invoiceRates, selectedName) => invoiceRates.find(rate => rate.name === selectedName)
    }),
    filter: (foundRate): foundRate is DAT2.RefOrRateSet => !!foundRate,
    target: invoiceRateSelected
});

sample({
    source: contractFactorsParameters,
    fn: ({ CalculationFactor: { referenceSetName } }) => referenceSetName === undefined || referenceSetName === '',
    target: isInvoiceRateNameNotDefinedInContract
});

guard({
    clock: [listInvoiceRatesFx.done, newVehicleReceived],
    source: [isInvoiceRateNameNotDefinedInContract, isVehicleIdentified],
    filter: sourceData => sourceData.every(is => is),
    target: triggerAutoSelectFirstInvoiceRate
});

sample({
    clock: triggerAutoSelectFirstInvoiceRate,
    source: selectOptions,
    fn: selectOptions => selectOptions[0]?.key,
    target: invoiceRateNameSelected
});
