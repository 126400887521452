import React, { FC, useEffect } from 'react';
import { useAsyncDebounce } from 'react-table';
import { Input } from '@wedat/ui-kit/components/Input';
import { InputProps } from '@wedat/ui-kit/components/Input/types';
import { Event } from 'effector';
import { Container, IconWrapper } from './styles';
import { SearchIcon } from '@wedat/ui-kit/components/Icons';

interface Props extends InputProps {
    setGlobalFilter?: Event<string>;
}

export const SearchInput: FC<Props> = ({ setGlobalFilter }) => {
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter?.(value);
    }, 200);

    useEffect(
        () => () => {
            onChange('');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Container>
            <Input
                onChange={e => {
                    onChange(e.target.value);
                }}
                placeholder="Search"
            />
            <IconWrapper>
                <SearchIcon />
            </IconWrapper>
        </Container>
    );
};
