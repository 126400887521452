import React from 'react';

const Info = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
        <path
            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path d="M11 6H11.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M9 10H11V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 15H13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Info;
