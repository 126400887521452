import { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Modal, List } from 'antd';
import { FastTrackConfiguration, FastTrackElement } from '@dat/api2dat/types/fastTrackInterfaceTypes';
import { fastTrackElementModel, getAvailableDamagesByDATID } from '../../stores/fastTrackElementModel';
import { ReactComponent as TimesIcon } from '../CommonComponents/Icons/TimesIcon.svg';
import { ReactComponent as SelectedElementIcon } from '../CommonComponents/Icons/SelectedElementIcon.svg';

import './button.css';
import './damageSelectionModal.css';

export const DamageSelectedModal: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const { t } = useTranslation();

    const { selectedElements, markElementId } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    if (!fastTrackConfiguration) return null;

    return (
        <Modal
            className={isMobile ? 'weDat-ft-damageSelectedModal-mobile' : 'weDat-ft-damageSelectedModal'}
            visible={!!markElementId}
            title={t(`fastLocalNameDATID.${markElementId}`)}
            cancelText={t('fastTrack.Cancel')}
            onCancel={_ => {
                fastTrackElementModel.events.setMarkElementId('');
            }}
            zIndex={1010}
            centered
            footer={null}
        >
            <List itemLayout="vertical">
                {getListItemOptionsDamage(markElementId, fastTrackConfiguration, selectedElements)}
                <List.Item
                    onClick={_ => {
                        fastTrackElementModel.events.delFastTrackDamage(markElementId);
                        fastTrackElementModel.events.setMarkElementId('');
                    }}
                    style={{ borderRadius: '0 0 12px 12px' }}
                >
                    <div style={{ display: 'flex' }}>
                        <TimesIcon style={{ marginRight: '12px' }} />
                        {t('fastTrack.noDamage')}
                    </div>
                </List.Item>
            </List>
        </Modal>
    );
};

function getListItemOptionsDamage(
    markElementId: string,
    fastTrackConfiguration: FastTrackConfiguration,
    selectedElements: FastTrackElement[]
) {
    const availableDamages = getAvailableDamagesByDATID(markElementId, fastTrackConfiguration);

    const optionsDamage = availableDamages.map(element => (
        <List.Item
            key={element.damageid}
            onClick={_ => {
                fastTrackElementModel.events.setFastTrackDamage({
                    damageType: element.damageid,
                    elementId: markElementId
                });
                fastTrackElementModel.events.setMarkElementId('');
            }}
        >
            <div style={{ display: 'flex' }}>
                {element.damageTranslation}
                {selectedElements.find(el => el.elementId === markElementId)?.damageType === element.damageid ? (
                    <SelectedElementIcon style={{ marginLeft: '8px' }} />
                ) : null}
            </div>
        </List.Item>
    ));

    return optionsDamage;
}
