import React, { useCallback } from 'react';
import { WrapperStyled, TabStyled } from './styles';

export interface TabsProps {
    /*** To set view tabs style */
    typeStyle?: 'primary' | 'secondary' | 'dark' | 'mobile';

    /*** To set an array with tabs*/
    values: Array<TabButton>;

    /*** Set this for an tabs change handler*/
    onChange: (id: string) => void;

    /*** To set active tab for tabs* @default false*/
    active?: string;

    /*** Set this for width: 100% */
    fillWidth?: boolean;
}

export interface TabButton {
    /*** To set unique identifier for tabs*/
    id: string;
    /*** To set label for tabs* @default info*/
    label: string;
    /*** To set active tab for tabs* @default false*/
    isActive?: boolean;
    /*** To set disable tab for tabs */
    disabled?: boolean;
}

/**
 * Tabs allow to explore and switch between different views.
 * Only one tab can be active at a time.
 *
 * Using props active for set flag.
 *
 * Use Array with parameters to set tabs properties.
 */

export const Tabs: React.FC<TabsProps> = ({
    typeStyle = 'primary',
    values,
    onChange,
    active = values[0]?.id,
    fillWidth
}) => {
    const onClickHandler = useCallback(
        id => {
            onChange(id);
        },
        [onChange]
    );

    return (
        <WrapperStyled fillWidth={fillWidth}>
            {values.map(({ id, label, disabled }) => (
                <TabStyled
                    key={id}
                    isActive={id === active}
                    onClick={() => onClickHandler(id)}
                    typeStyle={typeStyle}
                    disabled={disabled}
                    type="button"
                >
                    {label}
                </TabStyled>
            ))}
        </WrapperStyled>
    );
};
