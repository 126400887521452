import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { AttachmentGroup } from '@dat/core/types/attachments';
import { Attachment } from '../../../Attachment';
import { Text } from '@wedat/ui-kit/components/Text';
import { Checkbox } from '@wedat/ui-kit/components/Checkbox';
import { attachmentsStores, attachmentsEvents } from '../../../../stores/attachments';
import { AttachmentsList, Container, Info, TitleWrapper } from './styles';

const { addItemToAttachments, removeItemFromAttachments } = attachmentsEvents;

interface Props {
    attachments: DAT2.Internal.FolderOfClaimAttachment[];
    group?: AttachmentGroup;
}

export const Group: React.FC<Props> = ({ attachments, group }) => {
    const { t } = useTranslation();
    const selectedAttachments = useStore(attachmentsStores.selectedAttachments);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const check =
            attachments.length > 0 &&
            attachments.every(item =>
                selectedAttachments.some(itemSelected => item.fileName === itemSelected.fileName)
            );
        setChecked(check);
    }, [selectedAttachments, attachments]);

    const addItem = (data: DAT2.Internal.FolderOfClaimAttachment) => () => addItemToAttachments(data);

    const removeItem = (data: DAT2.Internal.FolderOfClaimAttachment) => () => removeItemFromAttachments(data);

    const isExist = (item: DAT2.Internal.FolderOfClaimAttachment) => !!selectedAttachments.find(i => i.id === item.id);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);

        if (checked) {
            attachments.forEach(attachment => removeItemFromAttachments(attachment));
        } else {
            attachments.forEach(attachment => !isExist(attachment) && addItemToAttachments(attachment));
        }
    };

    return (
        <Container>
            <TitleWrapper>
                <Info>
                    <Text font="defaultBold">
                        {group ? group.name || t('gallery.unnamedGroup') : t('gallery.other')}
                    </Text>
                    &nbsp;
                    <Text font="note">({attachments?.length})</Text>
                </Info>
                <Checkbox label={t('gallery.selectEntireFolder')} checked={checked} onChange={handleChange} />
            </TitleWrapper>

            <AttachmentsList>
                {attachments.length > 0 ? (
                    attachments.map(attachment => (
                        <Attachment
                            attachment={attachment}
                            selected={isExist(attachment)}
                            onClick={isExist(attachment) ? removeItem(attachment) : addItem(attachment)}
                            key={attachment.id}
                        />
                    ))
                ) : (
                    <Text font="note">{t('gallery.noAttachments')}</Text>
                )}
            </AttachmentsList>
        </Container>
    );
};
