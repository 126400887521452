export const defaultConfiguration = {
    hasPanel: false,
    hasPanelDamage: false,
    DamageColors: {
        opacity: 0.6,
        Components: {
            reflections: '#decfcf',
            scratch: '#b40081',
            scrape: '#b40081',
            rust: '#b40081',
            dent: '#87b23e',
            crack: '#a42828',
            shift: '#f1b141',
            missing: '#408b90',
            dirt: '#44323d',
            lampDamage: '#ffe600',
            glassDamage: '#7d48ce'
        }
    },
    PanelColors: {
        opacity: 0.4,
        Components: {
            bumperRear: '#1CE6FF',
            bumperFront: '#FFFF00',
            doorFrontLeft: '#FF34FF',
            doorFrontRight: '#FF4A46',
            pillarLeft: '#008941',
            pillarRight: '#006FA6',
            doorRearLeft: '#00FECF',
            doorRearRight: '#008000',
            fenderRearLeft: '#04F757',
            fenderRearRight: '#7900D7',
            fenderFrontLeft: '#A30059',
            fenderFrontRight: '#D790FF',
            foglightFrontLeft: '#FFDBE5',
            foglightFrontRight: '#7A4900',
            headlightLeft: '#0000A6',
            headlightRight: '#63FFAC',
            taillightLeft: '#004D43',
            taillightRight: '#8FB0FF',
            trunk: '#5A0007',
            windowRear: '#4FC601',
            doorWindowFrontLeft: '#BA0900',
            doorWindowFrontRight: '#6B7900',
            doorWindowRearLeft: '#A079BF',
            doorWindowRearRight: '#B77B68',
            handleFrontLeft: '#00C2A0',
            handleFrontRight: '#FFAA92',
            handleRearLeft: '#FF90C9',
            handleRearRight: '#B903AA',
            mirrorLeft: '#D16100',
            mirrorRight: '#3B5DFF',
            plateRear: '#A1C299',
            plateFront: '#0AA6D8',
            radiatorGrill: '#00846F',
            roof: '#FFB500',
            wheelRearLeft: '#C2FFED',
            wheelRearRight: '#DDEFFF',
            wheelFrontLeft: '#CC0744',
            wheelFrontRight: '#C2FF99',
            windscreen: '#6F0062',
            sillLeft: '#0CBD66',
            sillRight: '#EEC3FF',
            hood: '#FF2F80',
            fillercap: '#788D66',
            wiperFront: '#885578',
            wiperRear: '#FAD09F',
            pillarRearLeft: '#FF8A9A',
            pillarRearRight: '#D157A0',
            fixedWindowRearLeft: '#BEC459',
            fixedWindowRearRight: '#456648',
            rimRearLeft: '#0086ED',
            rimRearRight: '#00A6AA',
            rimFrontLeft: '#FF913F',
            rimFrontRight: '#575329',
            logoFront: '#FFFFFF',
            logoGrid: '#FFFFFF',
            logoRear: '#FFFFFF',
            logo: '#FFFFFF',
            lowergrillFront: '#FF00FF',
            lowergrillFrontLeft: '#0000FF',
            lowergrillFrontRight: '#9999FF',
            taillightinternalRight: '#0099FF',
            taillightinternalLeft: '#D60093',
            bumperlowerRear: '#CC0000',
            bumperspoilerRear: '#7B7BBD',
            fenderflareRearLeft: '#9AAA15',
            fenderflareRearRight: '#2D50FF',
            fenderflareFrontLeft: '#E430D7',
            fenderflareFrontRight: '#E8FB75'
        }
    }
};
