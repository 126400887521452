import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '@dat/core/constants/dateFormats';
import { domain } from '../plugin';
import { VinOcrScanDate } from '../../types/ocr';

const vinOcrScan = domain.createEvent<string>();
const vinOcrScanDate = domain.createStore<VinOcrScanDate>({}).on(vinOcrScan, () => ({
    OCRVINScanDate: format(new Date(), DEFAULT_DATE_FORMAT)
}));

//
/*** Export ***/
//
export const vehicleVinOCRStores = {
    vinOcrScanDate
};

export const vehicleVinOCREvents = {
    vinOcrScan
};
