import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Permissions = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const Title = styled.p`
    ${({ theme }) => theme.typography.mobileHeader};

    margin: 0 0 24px;
`;

export const Image = styled.img`
    margin-bottom: 12px;
`;

export const PermissionText = styled.p`
    font-size: 16px;
    font-weight: 600;

    margin: 12px 0;
`;

export const Description = styled.p`
    ${({ theme }) => theme.typography.note};
    text-align: center;

    color: ${({ theme }) => theme.colors.dustBlue['600']};

    padding: 0 12px;
    margin: 0 0 16px;
`;

export const Buttons = styled.div`
    width: 100%;
    padding: 24px;

    ${media.tabletSmall`
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    `}

    ${media.phone`
        display: flex;
        flex-direction: column;
    `}
`;

export const Skip = styled.div`
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.typography.note};
    text-align: center;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    height: 48px;
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.colors.dustBlue['900']}`};
    border-radius: 8px;

    ${media.tabletSmall`
        width: 100%;
        margin-top: 0;
        margin-right: 5px;
    `}

    ${media.phone`
        margin-top: 5px;
    `}
`;
