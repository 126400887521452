import { useStore } from 'effector-react';
import { useTheme } from 'styled-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';

import { Text } from '@wedat/ui-kit/components/Text';

import { Container, HeadersContainer, FieldWrapper, PositionContainer } from './styles';
import { getParsedArraySafe } from '@dat/api2/utils';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';

export const DiscountedPositions = () => {
    const {
        colors: { dustBlue }
    } = useTheme();
    const { t } = useTranslation();

    const repairCalculation = useStore(sharedRepairCalculationStores.repairCalculation);
    const discountedPositions = getParsedArraySafe(
        repairCalculation?.CalcResultCommon?.DiscountPositions?.DiscountPosition
    );

    const headers = useMemo(
        () => [
            // width numbers here and in jsx are taken from design
            { label: t('postEditingDiscountTable.DVN'), width: 142 },
            { label: t('postEditingDiscountTable.repairType'), width: 142 },
            { label: t('postEditingDiscountTable.description'), width: 304 },
            { label: t('postEditingDiscountTable.calculationType'), width: 142 },
            { label: t('postEditingDiscountTable.basePrice'), width: 142 },
            { label: t('postEditingDiscountTable.percent'), width: 142 },
            { label: t('postEditingDiscountTable.sum'), width: 164 }
        ],
        [t]
    );

    return (
        <Container>
            <HeadersContainer>
                {headers.map((item, idx) => (
                    <FieldWrapper key={idx} width={item.width}>
                        <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                            {item.label}
                        </Text>
                    </FieldWrapper>
                ))}
            </HeadersContainer>
            {discountedPositions.map(item => (
                <PositionContainer>
                    <FieldWrapper width={142}>
                        <Text font="footnote" fontSize={16}>
                            {item.DATProcessId}
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={142}>
                        <Text font="footnote" fontSize={16}>
                            {item.RepairType}
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={304}>
                        <Text font="footnote" fontSize={16}>
                            {item.Description}
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={142}>
                        <Text font="footnote" fontSize={16}>
                            {item.DiscountKind}
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={142}>
                        <Text font="footnote" fontSize={16}>
                            {item.BaseValue}
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={142}>
                        <Text font="footnote" fontSize={16}>
                            <NumberFormat>{item.CorrectionPercentage}%</NumberFormat>
                        </Text>
                    </FieldWrapper>
                    <FieldWrapper width={164}>
                        <Text font="footnote" fontSize={16}>
                            {(item.BaseValue || 0) - (item.CorrectionValue || 0)}
                        </Text>
                    </FieldWrapper>
                </PositionContainer>
            ))}
        </Container>
    );
};
