import { sample } from 'effector';
import { spread } from 'patronum';
import deepmerge from 'deepmerge';
import * as R from 'ramda';

import { sharedTemplateEvents, sharedTemplateStores } from './index';
import { sharedConfigurationStores } from '../configuration';
import { contractStores } from '../contract';
import { mergeAllTemplatesWithDefault } from './utils/mergeAllTemplatesWithDefault';
import { INITIAL_TEMPLATE_CONFIGURATION } from '../configuration/constants';

const { setDefaultTemplate } = sharedTemplateEvents;
const { availableTemplates, templateId, _templateKey, templateSettings } = sharedTemplateStores;
const { customerConfiguration, userSettings } = sharedConfigurationStores;

sample({
    source: { contractTemplateId: contractStores.templateId, defaultTemplateId: userSettings.defaultTemplateId },
    fn: ({ contractTemplateId, defaultTemplateId }) => contractTemplateId || defaultTemplateId || null,
    target: templateId
});

sample({
    clock: setDefaultTemplate,
    source: userSettings.defaultTemplateId,
    target: templateId
});

sample({
    source: { customerConfiguration, availableTemplateIds: userSettings.availableTemplateIds },
    fn: ({ customerConfiguration, availableTemplateIds }) => {
        let templates = {
            ...customerConfiguration.templates,
            default: deepmerge<DAT2.TemplateConfiguration>(
                INITIAL_TEMPLATE_CONFIGURATION,
                customerConfiguration.templates?.default || {}
            )
        };

        if (availableTemplateIds) {
            templates = R.pick(['default', ...availableTemplateIds], templates);
        }

        return mergeAllTemplatesWithDefault(templates);
    },
    target: availableTemplates
});

sample({
    source: { availableTemplates, _templateKey },
    fn: ({ availableTemplates, _templateKey }) =>
        availableTemplates[_templateKey]?.settings || availableTemplates.default.settings,
    target: spread({ targets: templateSettings })
});
