import { FC, useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useTheme } from 'styled-components';

import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { getWorkLevelSymbol } from '../../../utils/getWorkLevelSymbol';
import { calculatePositionCorrPrice } from '../../../utils/calculatePositionCorrPrice';
import { PART_DISCOUNT_TYPE_OPTIONS } from '../../../constants/selects';
import { AGGREGATE_PART_CODE_ITALY, PositionsTypesValues } from '../../../constants/positionFieldsValues';
import { modalsEvents } from '../../../stores/ui/modals';
import { isUpperCase } from '@dat/core/utils/isUpperCase';
import { checkTimeExistence } from '../../../utils/checkTimeExistence';
import { toastEffects } from '@dat/smart-components/Toast/store';
import { checkManufacturer } from '../../../utils/checkManufacturer';
import { checkWorkBenchOrWorkLogic } from '../../../utils/checkWorkBenchOrWorkLogic';

import { Table } from '@wedat/ui-kit/components/Table';
import { InputFieldStyled } from '../../core/styles';
import { InfoButton } from './styles';
import { PositionNumberInputField } from '../../PositionNumberInputField';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import { PositionsFormValues } from '../../../types/positions';
import { BoxSwitcherField, SelectField } from '@wedat/ui-kit/Formik';
import { CircledQuestionMark } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    onChangePositionsSelection: (indexes: number[]) => void;
}

export const PositionsTable: FC<Props> = ({ onChangePositionsSelection }) => {
    const {
        values: { positions },
        setFieldValue,
        getFieldProps
    } = useFormikContext<PositionsFormValues>();
    const { t } = useTranslation();
    const materialPositions = useStore(sharedRepairCalculationStores.materialPositions);
    const repairPositions = useStore(sharedRepairCalculationStores.repairPositions);
    const {
        colors: { dustBlue }
    } = useTheme();

    // TODO: think about delete copypaste
    const handleChangePercent = useCallback(
        (value: number | undefined, index: number) => {
            if (!value) {
                // Need logic for empty PartDiscountType if PartDiscrountPerc is empty
                setFieldValue(`positions.${index}.PartDiscountType`, '');
            }

            if (
                value &&
                (positions[index].PartDiscountType === '' || typeof positions[index].PartDiscountType === 'undefined')
            ) {
                setFieldValue(`positions.${index}.PartDiscountType`, 'S');
            }
        },
        [setFieldValue, positions]
    );

    const headers = useMemo(
        () => [
            t('common.pn'),
            t('positionsTable.quantity'),
            t('common.designation'),
            t('common.sr'),
            t('common.la'),
            t('common.srev'),
            t('common.me'),
            t('positionsTable.partsPrice'),
            '*',
            t('positionsTable.discountType'),
            t('positionsTable.discountPercent'),
            t('positionsTable.corrPrice'),
            ''
        ],
        [t]
    );

    const columns = useMemo(
        () =>
            headers.map((item, idx) => ({
                Header: (
                    <Text font="footnote" fontWeight={700} color={dustBlue['200']}>
                        {item}
                    </Text>
                ),
                accessor: `col${idx + 1}`
            })),
        [dustBlue, headers]
    );

    const data = useMemo(
        () =>
            positions.map((pos, i) => {
                const isGray =
                    (pos.Type === PositionsTypesValues.aggregateComponent ||
                        pos.Type === PositionsTypesValues.required) &&
                    isUpperCase(pos.Description);
                const isAggregate = pos.Type === PositionsTypesValues.aggregate;
                const isAggregateComponent = pos.Type === PositionsTypesValues.aggregateComponent && !isGray;

                // disabled for editing
                const isWorkBenchOrWorkLogic = checkWorkBenchOrWorkLogic(pos);

                const isManual = !pos.DATProcessId && pos.PartCodeItaly !== AGGREGATE_PART_CODE_ITALY && !isAggregate;
                const isManualWithSparePartPrice = !!(isManual && pos.ValueParts);

                const doesReplaceExist = checkTimeExistence(pos.WorkTimeReplace);
                const doesOverhaulExist = checkTimeExistence(pos.WorkTimeOverhaul);
                const doesLacquerExist = checkTimeExistence(pos.WorkTimeLacquer);
                const doesMechanicExist = checkTimeExistence(pos.WorkTimeMechanic);

                const hasQuantity = pos.Amount && pos.Amount > 0;
                const quantityFromMaterial = materialPositions.find(mPos => pos.PartNumber === mPos.PartNumber)?.Amount;
                const quantityFromMaterialConverted = quantityFromMaterial
                    ? String(quantityFromMaterial).split('.').join(',')
                    : '';
                const amount = hasQuantity ? pos.Amount : quantityFromMaterialConverted;

                const isManufacturer = checkManufacturer(pos, repairPositions) && !isManual;

                const mechanicFieldProps = getFieldProps(`positions.${i}.WorkTimeMechanic`);
                const overhaulFieldProps = getFieldProps(`positions.${i}.WorkTimeOverhaul`);
                const discountPercFieldProps = getFieldProps(`positions.${i}.PartDiscountPerc`);

                return {
                    col1: (
                        <InputFieldStyled
                            width={150}
                            name={`positions.${i}.PartNumber`}
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col2: amount,
                    col3: (
                        <InputFieldStyled
                            aggregate={isAggregate}
                            aggregateComponent={isAggregateComponent}
                            isGray={isGray}
                            width={300}
                            name={`positions.${i}.Description`}
                            disabled={isWorkBenchOrWorkLogic || isAggregateComponent}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col4: (
                        <PositionNumberInputField
                            name="WorkTimeReplace"
                            index={i}
                            withPrefix={doesReplaceExist && !(isAggregateComponent || isGray)}
                            workLevelSymbol={getWorkLevelSymbol({
                                position: pos,
                                key: 'WorkLevelReplace'
                            })}
                            width={100}
                            disabled={isAggregateComponent || isGray || isWorkBenchOrWorkLogic}
                            hideValue={isAggregateComponent || isGray}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col5: (
                        <PositionNumberInputField
                            name="WorkTimeOverhaul"
                            index={i}
                            withPrefix={doesOverhaulExist && !(isAggregateComponent || isGray)}
                            workLevelSymbol={getWorkLevelSymbol({
                                position: pos,
                                key: 'WorkLevelOverhaul'
                            })}
                            width={100}
                            disabled={isAggregateComponent || isGray || isWorkBenchOrWorkLogic}
                            hideValue={isAggregateComponent || isGray}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                            onChange={() => {
                                if (
                                    !!mechanicFieldProps.value &&
                                    mechanicFieldProps.value !== '0' &&
                                    (isManufacturer || isManual)
                                ) {
                                    setFieldValue(`positions.${i}.WorkTimeOverhaul`, '');
                                    toastEffects.showInfoToastFx({
                                        message: {
                                            namespace: 'italian-calculation',
                                            key: 'alert.setTime.message'
                                        },
                                        title: {
                                            namespace: 'italian-calculation',
                                            key: 'alert.setTime.title'
                                        },
                                        toastId: 'setTimeAlert'
                                    });
                                }
                            }}
                        />
                    ),
                    col6: (
                        <PositionNumberInputField
                            name="WorkTimeLacquer"
                            index={i}
                            withPrefix={doesLacquerExist && !(isAggregateComponent || isGray)}
                            workLevelSymbol={getWorkLevelSymbol({
                                position: pos,
                                key: 'WorkLevelLacquer'
                            })}
                            width={100}
                            disabled={isAggregateComponent || isGray || isWorkBenchOrWorkLogic || isManufacturer}
                            hideValue={isAggregateComponent || isGray || isManufacturer}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col7: (
                        <PositionNumberInputField
                            name="WorkTimeMechanic"
                            index={i}
                            withPrefix={doesMechanicExist && !(isAggregateComponent || isGray)}
                            workLevelSymbol={getWorkLevelSymbol({
                                position: pos,
                                key: 'WorkLevelMechanic'
                            })}
                            width={100}
                            disabled={
                                (isAggregate ||
                                    isAggregateComponent ||
                                    isGray ||
                                    isWorkBenchOrWorkLogic ||
                                    !isManufacturer) &&
                                !isManual
                            }
                            hideValue={isAggregateComponent || isGray}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                            onChange={() => {
                                if (
                                    !!overhaulFieldProps.value &&
                                    overhaulFieldProps.value !== '0' &&
                                    (isManufacturer || isManual)
                                ) {
                                    setFieldValue(`positions.${i}.WorkTimeMechanic`, '');
                                    toastEffects.showInfoToastFx({
                                        message: {
                                            namespace: 'italian-calculation',
                                            key: 'alert.setTime.message'
                                        },
                                        title: {
                                            namespace: 'italian-calculation',
                                            key: 'alert.setTime.title'
                                        },
                                        toastId: 'setTimeAlert'
                                    });
                                }
                            }}
                        />
                    ),
                    col8: (
                        <PositionNumberInputField
                            workLevelSymbol={isManualWithSparePartPrice ? '#' : ''}
                            width={100}
                            withPrefix={isManualWithSparePartPrice}
                            index={i}
                            name="ValueParts"
                            defaultLeftPadding={isManualWithSparePartPrice ? 22 : 0}
                            thousandSeparator="."
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col9: pos.ValueParts && (
                        <BoxSwitcherField
                            name={`positions.${i}.PartDiscountAsterisk`}
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                            noBorder
                            noPadding
                            borderRadius={12}
                            noHeight
                        />
                    ),
                    col10: pos.ValueParts && (
                        <SelectField
                            name={`positions.${i}.PartDiscountType`}
                            options={PART_DISCOUNT_TYPE_OPTIONS}
                            disabled={isAggregate || isWorkBenchOrWorkLogic || !discountPercFieldProps.value}
                            withPortal
                            inputSize="small"
                            borderRadius={4}
                        />
                    ),
                    col11: pos.ValueParts && (
                        <PositionNumberInputField
                            name="PartDiscountPerc"
                            index={i}
                            width={100}
                            onChange={value => handleChangePercent(value, i)}
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                            inputSize="small"
                            borderRadius={4}
                            stopPropagation
                        />
                    ),
                    col12: (
                        <NumberFormat
                            value={calculatePositionCorrPrice(pos)}
                            disabled={isAggregate || isWorkBenchOrWorkLogic}
                        />
                    ),
                    col13:
                        (pos.WorkLevelMechanic === 'manufacturer' ||
                            pos.WorkLevelReplace === 'manufacturer' ||
                            pos.WorkLevelLacquer === 'manufacturer' ||
                            pos.WorkLevelOverhaul === 'manufacturer') &&
                        (pos.WorkTimeLacquer || pos.WorkTimeMechanic || pos.WorkTimeOverhaul || pos.WorkTimeReplace) ? (
                            // TODO: this is done by data attribute because if we used selectedRowIds for it then table data would have them as dependencies,
                            // which would result in infinite loop of updating dependencies and rerendering of the table (think of some other solution)
                            // look for the styling and usage of data attribute in ui-kit/components/Table/styles.ts
                            <InfoButton data-is-in-selected-row>
                                <CircledQuestionMark onClick={() => modalsEvents.setActiveInfoPosition(pos)} />
                            </InfoButton>
                        ) : null
                };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleChangePercent, materialPositions, positions]
    );

    const onTableChange = (table: TableInstance<object>) => {
        const arrayIndexes = Object.keys(table.state.selectedRowIds).map(item => +item);
        onChangePositionsSelection(arrayIndexes);
    };

    return (
        <Table
            columns={columns}
            data={data}
            mobileHeaders
            rowsSelectable
            onTableChange={onTableChange}
            mobileGridColAmount={3}
        />
    );
};
