import { TFunction } from 'react-i18next';
import { PreviewTabs } from '../../../types/plugin';

export const tabs = ({ t, usersCount, hideTabs }: { t: TFunction; usersCount: number; hideTabs: PreviewTabs[] }) =>
    [
        { id: PreviewTabs.General, label: t('previewTabs.general') },
        { id: PreviewTabs.Comment, label: t('previewTabs.comment') },
        { id: PreviewTabs.History, label: t('previewTabs.history') },
        { id: PreviewTabs.Users, label: `${t('previewTabs.users')} (${usersCount || '0'})` }
    ].filter(tab => hideTabs.indexOf(tab.id) === -1);
