import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isMailOpen, toggleIsMailOpen] = createToggle(false);

export const sharedMailEvents = {
    toggleIsMailOpen
};

export const sharedMailStores = {
    isMailOpen
};
