import React, { FC, useMemo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '@wedat/ui-kit/Formik';
import { combinedEquipmentStores } from '../../stores/equipment';
import { CategoriesWrapper } from './styles';

export const Categories: FC = () => {
    const { categories } = useStore(combinedEquipmentStores);
    const { t } = useTranslation();
    const selectOptions = useMemo(
        () => categories.map(category => ({ value: category, label: t(category.toLowerCase()) })),
        [categories, t]
    );

    return (
        <CategoriesWrapper>
            <SelectField
                options={selectOptions}
                label={t('filterByType')}
                valueKey="value"
                name="selectedCategories"
                isMulti
            />
        </CategoriesWrapper>
    );
};
