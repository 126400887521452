import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { Input } from '@wedat/ui-kit/components/Input';
import { i18nStores } from '@dat/shared-models/i18n';
import { LIBRARIES } from './constants';
import { GooglePlacesAddress } from './GooglePlacesAddress';
import { Values } from './types';
import { Wrapper } from './styles';
import './google.css';

interface Props {
    name: string;
}

type FormikProps = {
    [key: string]: Values;
};

export const GooglePlaces: React.FC<Props> = ({ name }) => {
    const { t } = useTranslation();
    const formikContext = useFormikContext<FormikProps>();
    const { values, setFieldValue } = formikContext;
    const currentLocale = useStore(i18nStores.locale);

    const [fullAddress, setFullAddress] = useState<Values>(values[name]);
    const [countryName, setCountryName] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('');
    const [countryAutocomplete, setCountryAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    useEffect(() => {
        setFieldValue(name, fullAddress);
        setCountryName(fullAddress?.country?.name ?? '');
        setCountryCode(fullAddress?.country?.code ?? '');
    }, [name, fullAddress, setFieldValue]);

    const onLoadAutocomplete = (params: google.maps.places.Autocomplete) => {
        setCountryAutocomplete(params);
    };

    const onPlaceChanged = () => {
        if (!!countryAutocomplete) {
            const place = countryAutocomplete.getPlace();

            setCountryName(place.name ?? '');
            setCountryCode(place.address_components?.[0]?.short_name ?? '');

            setFullAddress({
                country: { name: place.name, code: place.address_components?.[0]?.short_name },
                province: '',
                city: '',
                street: '',
                postcode: '',
                formattedAddress: ''
            });
        }
    };

    const onChangeCountryName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCountryName(e.target.value);
    };

    const onBlur = () => {
        if (!countryName) {
            setFullAddress({
                country: { name: '', code: '' },
                province: '',
                city: '',
                street: '',
                postcode: '',
                formattedAddress: ''
            });
        } else {
            setCountryName(fullAddress?.country?.name ?? '');
        }
    };

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}
            libraries={LIBRARIES}
            language={currentLocale}
        >
            <Wrapper>
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                    options={{
                        types: ['country']
                    }}
                >
                    <Input
                        label={t('form-builder:googlePlaces.country')}
                        value={countryName}
                        onChange={onChangeCountryName}
                        onBlur={onBlur}
                    />
                </Autocomplete>
                <GooglePlacesAddress
                    name={name}
                    countryCode={countryCode}
                    fullAddress={fullAddress}
                    setFullAddress={setFullAddress}
                />
            </Wrapper>
        </LoadScript>
    );
};
