import { createEffect, attach, restore, combine } from 'effector';

import { API2 } from '@dat/api2';
import { contractStores } from '@dat/shared-models/contract';
import { getParsedArraySafe } from '@dat/api2/utils';
import { createPaintTypesSelectOptions } from '../../../utils/selectsOptions';

import { PayloadForGetPaintTypesManufacturer } from '../../../types/paintTypes';

const getPaintTypesFx = createEffect(async (payload: DAT2.Request.GetLacquerTypeKeys) => {
    const response = await API2.calculatePro.getLacquerTypeKeys(payload);

    return getParsedArraySafe(response.lacquerTypes?.lacquerType);
});
const getPaintTypesEuroFx = attach({
    effect: getPaintTypesFx,
    mapParams: () => ({
        paintMethod: 'EURO_LACQUER' as const
    })
});

const getPaintTypesManufacturerFx = attach({
    effect: getPaintTypesFx,
    mapParams: (payload: PayloadForGetPaintTypesManufacturer) => ({
        ...payload,
        paintMethod: 'MANUFACTURER_SPECIFIC' as const
    })
});

const getPaintTypesAztFx = attach({
    effect: getPaintTypesFx,
    mapParams: () => ({
        paintMethod: 'AZT' as const
    })
});

const getPaintTypesCzFx = attach({
    effect: getPaintTypesFx,
    mapParams: () => ({
        // Change to CZ, when server will be fixed
        paintMethod: 'EURO_LACQUER' as const
    })
});

const paintTypesEuro = restore(getPaintTypesEuroFx, []);
const paintTypesManufacturer = restore(getPaintTypesManufacturerFx, []);
const paintTypesAzt = restore(getPaintTypesAztFx, []);
const paintTypesCz = restore(getPaintTypesCzFx, []);

const selectOptionsEuro = paintTypesEuro.map(createPaintTypesSelectOptions);
const selectOptionsManufacturer = paintTypesManufacturer.map(createPaintTypesSelectOptions);
const selectOptionsAzt = paintTypesAzt.map(createPaintTypesSelectOptions);
const selectOptionsCz = paintTypesCz.map(createPaintTypesSelectOptions);

const paintTypesEuroAreNotFetched = paintTypesEuro.map(({ length }) => !length);

const paintTypesAztAreNotFetched = paintTypesAzt.map(({ length }) => !length);

const paintTypesCzAreNotFetched = paintTypesCz.map(({ length }) => !length);

const partialPayloadForGetPaintTypesManufacturer = combine({
    vehicleType: contractStores.contract.map(contract => contract?.Dossier?.Vehicle?.VehicleType),
    manufacturer: contractStores.contract.map(contract => contract?.Dossier?.Vehicle?.Manufacturer),
    mainType: contractStores.contract.map(contract => contract?.Dossier?.Vehicle?.BaseModel)
});

//
/*** Export ***/
//
export const paintTypesEffects = {
    getPaintTypesEuroFx,
    getPaintTypesManufacturerFx,
    getPaintTypesAztFx,
    getPaintTypesCzFx
};
export const paintTypesStores = {
    paintTypesEuro,
    paintTypesManufacturer,
    paintTypesAzt,
    paintTypesCz,
    selectOptionsEuro,
    selectOptionsManufacturer,
    selectOptionsAzt,
    selectOptionsCz,
    paintTypesEuroAreNotFetched,
    paintTypesAztAreNotFetched,
    paintTypesCzAreNotFetched,
    partialPayloadForGetPaintTypesManufacturer
};
