import React, { FC, SyntheticEvent, useState } from 'react';

import { StyledSlider, Slide, PrevArrow, NextArrow } from './styles';
import { Lightbox } from '../Lightbox';
import { Arrow } from '../../assets/Icon';

import './slick.min.css';

interface Props {
    images?: string[];
    slidesToShow?: number;
    noLightbox?: boolean;
    withGradient?: boolean;
    withDots?: boolean;
    withArrows?: boolean;
    story?: boolean;
    headerSlide?: boolean;
}

export const ImagesSlider: FC<Props> = React.memo(
    ({ images, slidesToShow, withDots = false, withArrows = true, noLightbox, withGradient = false, headerSlide }) => {
        const [initialLightboxImageIndex, setInitialLightboxImageIndex] = useState(0);
        const [isLightboxOpen, setIsLightboxOpen] = useState(false);

        if (!images?.length) {
            return null;
        }

        const settings = {
            slidesToShow: slidesToShow ? slidesToShow : 3,
            infinite: false,
            dots: withDots,
            prevArrow: (
                <PrevArrow withDots={withDots} withArrows={withArrows}>
                    <Arrow />
                </PrevArrow>
            ),
            nextArrow: (
                <NextArrow withDots={withDots} withArrows={withArrows}>
                    <Arrow />
                </NextArrow>
            ),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: slidesToShow ? slidesToShow : 2
                    }
                },
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: slidesToShow ? slidesToShow : 1
                    }
                }
            ]
        };

        const handleSlideClick = ({ target }: SyntheticEvent) => {
            if (target instanceof HTMLDivElement && (!noLightbox || headerSlide)) {
                const index = target.closest<HTMLDivElement>('.slick-slide')?.dataset.index || 0;

                setInitialLightboxImageIndex(Number(index));

                if (!noLightbox && !headerSlide) {
                    setIsLightboxOpen(true);
                }
            }
        };

        const handleHeaderSlideClick = () => {
            if (!noLightbox && headerSlide) {
                setIsLightboxOpen(true);
            }
        };

        const handleLightboxClose = () => setIsLightboxOpen(false);

        return (
            <>
                {headerSlide && <Slide url={images[initialLightboxImageIndex]} onClick={handleHeaderSlideClick} />}
                <StyledSlider {...settings} withGradient={withGradient} story>
                    {images.map(image => (
                        <Slide key={image.slice(0, 20)} url={image} onClick={handleSlideClick} />
                    ))}
                </StyledSlider>
                {!noLightbox && (
                    <Lightbox
                        images={images}
                        initialImageIndex={initialLightboxImageIndex}
                        isOpen={isLightboxOpen}
                        onClose={handleLightboxClose}
                    />
                )}
            </>
        );
    }
);
