import { createEvent, restore } from 'effector';
import { FastMovingBarItems } from '../types/plugin';

const setFastMovingBarMenuSelect = createEvent<FastMovingBarItems | null>();
const fastMovingBarMenuSelect = restore(setFastMovingBarMenuSelect, null);

export const fastMovingBarModel = {
    stores: {
        fastMovingBarMenuSelect
    },
    events: {
        setFastMovingBarMenuSelect
    },
    effects: {}
};
