import React, { FC, useState, memo } from 'react';
import { useStore } from 'effector-react';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { Col, Collapse, Space } from 'antd';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { useTranslation } from 'react-i18next';
import { ReplaceTypeView } from './ReplaceTypeView';
import { LacquerTypeView } from './LacquerTypeView';
import { OverhaulTypeView } from './OverhaulTypeView';
import { SelectedElement } from '../../CommonComponents/SelectedElement';
import { convertToArrayIfNot } from '@dat/api2dat/utils/arrayConvert';
import { DvnNotFound } from '../GraphicSetDamageForms';
import { PZSTTypeView } from './PZSTTypeView';
import { convertRepairTypeToFullRepairType } from '../../../utils/ftRepairToRepairPosition';
import { LacquerDemountedTypeView } from './LacquerDemountedTypeView';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { splitOnTwoParts } from '../../../utils/splitOnTwoParts';

const { Panel } = Collapse;

function isRepairType(dvn: number, RepairType: string, repairPositions: RepairPosition[]) {
    return !!repairPositions.find(item => item.DATProcessId === dvn && item.RepairType === RepairType);
}

export const DefaultDvnRepairView2: FC<any> = memo(
    () => {
        const { t } = useTranslation();
        const { currentAssemblyGroup, currentAssemblyGroupObject, currentDVNs } = useStore(
            graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
        );

        const currentObj = currentAssemblyGroupObject;
        const el = currentAssemblyGroupObject;

        const repairPositions = useStore(RepairPositionsModel.store.repairPositions);

        const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
        const additionalRC = pluginOptions?.settings?.additionalRC;

        const [activeKey, setActiveKey] = useState<string[]>([]);

        if (currentDVNs?.length !== 1) return <DvnNotFound />;
        const dvn = currentDVNs[0];
        const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;

        const showLacquer = dvnLR?.rcs.rc.find(item => item === 'L' || (item === 'U' && additionalRC?.includes('U')));
        const repairCodesPZST =
            dvnLR?.rcs.rc.filter(
                elem => (elem === 'P' || elem === 'Z' || elem === 'S' || elem === 'T') && additionalRC?.includes(elem)
            ) || [];

        function unfoldKey(key: string) {
            if (!activeKey.includes(key)) {
                setActiveKey([...activeKey, key]);
            }
        }

        const { isSplitalble, leftPart, rightPart } = splitOnTwoParts(el?.titleMenu);
        let description = el?.titleMenu || '';
        if (isSplitalble && el?.dvnLeft?.dvn === dvn) description = leftPart;
        if (isSplitalble && el?.dvnRight?.dvn === dvn) description = rightPart;

        return (
            <>
                <Collapse
                    expandIconPosition="right"
                    activeKey={activeKey.filter(key => key !== 'dis- and mounting')}
                    onChange={val => setActiveKey(convertToArrayIfNot(val) as [string])}
                >
                    {dvnLR?.rcs.rc.find(item => item === 'E') && (
                        <Panel
                            disabled={!dvnLR?.rcs.rc.find(item => item === 'E')}
                            header={
                                <Space>
                                    <SelectedElement
                                        disabled={!dvnLR?.rcs.rc.find(item => item === 'E')}
                                        checked={isRepairType(dvnLR?.dvn || 0, 'replace', repairPositions)}
                                        onChange={_e => {
                                            if (currentAssemblyGroup) {
                                                if (!isRepairType(dvnLR?.dvn || 0, 'replace', repairPositions)) {
                                                    RepairPositionsModel.event.addReplaceEDamage({
                                                        DATProcessId: dvnLR?.dvn || 0,
                                                        Description: description,
                                                        assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                    });
                                                } else {
                                                    RepairPositionsModel.event.delRepairTypeDamage({
                                                        dvn: dvnLR?.dvn || 0,
                                                        repairType: 'replace'
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                    {t('RepairType.label.E')}
                                </Space>
                            }
                            key="replace"
                        >
                            <Col offset={1} span={22}>
                                <ReplaceTypeView />
                            </Col>
                        </Panel>
                    )}

                    {dvnLR?.rcs.rc.find(item => item === 'I') ? (
                        <Panel
                            header={
                                <Space>
                                    <SelectedElement
                                        checked={isRepairType(dvnLR?.dvn || 0, 'overhaul', repairPositions)}
                                        onChange={_e => {
                                            if (isRepairType(dvnLR?.dvn || 0, 'overhaul', repairPositions)) {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: dvnLR?.dvn || 0,
                                                    repairType: 'overhaul'
                                                });
                                            }
                                            if (!isRepairType(dvnLR?.dvn || 0, 'overhaul', repairPositions)) {
                                                // todo set default value from settings
                                                // if (currentAssemblyGroup) {
                                                //     RepairPositionsModel.event.addOverhaulIDamage({
                                                //         assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                //         DATProcessId: dvnLR?.dvn || 0,
                                                //         Description: currentObj?.titleMenu || '',
                                                //         WorkTime: 1,
                                                //         WorkPrice: 0
                                                //     });
                                                // }
                                                unfoldKey('overhaul');
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: dvnLR?.dvn || 0,
                                                    repairType: 'overhaul'
                                                });
                                            }
                                        }}
                                    />
                                    {t('RepairType.label.I')}
                                </Space>
                            }
                            key="overhaul"
                        >
                            <Col offset={1} span={22}>
                                <OverhaulTypeView />
                            </Col>
                        </Panel>
                    ) : (
                        <Panel
                            header={
                                <Space>
                                    <SelectedElement
                                        checked={isRepairType(dvnLR?.dvn || 0, 'fixing', repairPositions)}
                                        onChange={_e => {
                                            if (!isRepairType(dvnLR?.dvn || 0, 'fixing', repairPositions)) {
                                                // todo set default value from settings
                                                // if (currentAssemblyGroup) {
                                                //     RepairPositionsModel.event.addFixingSlashDamage({
                                                //         assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                //         DATProcessId: dvnLR?.dvn || 0,
                                                //         Description: currentObj?.titleMenu || '',
                                                //         WorkTime: 1,
                                                //         WorkPrice: 0
                                                //     });
                                                // }
                                                unfoldKey('fixing');
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: dvnLR?.dvn || 0,
                                                    repairType: 'fixing'
                                                });
                                            }
                                        }}
                                    />
                                    {t('RepairType.label./')}
                                </Space>
                            }
                            key="fixing"
                        >
                            <Col offset={1} span={22}>
                                <OverhaulTypeView />
                            </Col>
                        </Panel>
                    )}

                    {dvnLR?.rcs.rc.find(item => item === 'A') && (
                        <Panel
                            disabled={!dvnLR?.rcs.rc.find(item => item === 'A')}
                            header={
                                <Space>
                                    <SelectedElement
                                        style={{ marginLeft: '4px' }}
                                        disabled={!dvnLR?.rcs.rc.find(item => item === 'A')}
                                        checked={isRepairType(dvnLR?.dvn || 0, 'dis- and mounting', repairPositions)}
                                        onChange={_e => {
                                            if (currentAssemblyGroup)
                                                if (
                                                    !isRepairType(dvnLR?.dvn || 0, 'dis- and mounting', repairPositions)
                                                ) {
                                                    RepairPositionsModel.event.addDisAndMountingADamage({
                                                        DATProcessId: dvnLR?.dvn || 0,
                                                        Description: description,
                                                        assemblyGroup: currentAssemblyGroup.assemblyGroup
                                                    });
                                                } else {
                                                    RepairPositionsModel.event.delRepairTypeDamage({
                                                        dvn: dvnLR?.dvn || 0,
                                                        repairType: 'dis- and mounting'
                                                    });
                                                }
                                        }}
                                    />
                                    {t('RepairType.label.A')}
                                </Space>
                            }
                            key="dis- and mounting"
                            showArrow={false}
                        ></Panel>
                    )}

                    {showLacquer && (
                        <Panel
                            disabled={!showLacquer}
                            header={
                                <Space>
                                    <SelectedElement
                                        disabled={!showLacquer}
                                        checked={isRepairType(dvnLR?.dvn || 0, 'lacquer', repairPositions)}
                                        onChange={_e => {
                                            if (!isRepairType(dvnLR?.dvn || 0, 'lacquer', repairPositions)) {
                                                // todo set default value from settings
                                                // if (currentAssemblyGroup) {
                                                //     RepairPositionsModel.event.addLacquerLDamage({
                                                //         assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                //         DATProcessId: dvnLR?.dvn || 0,
                                                //         Description: currentObj?.titleMenu || '',
                                                //         LacquerLevel: 'surface',
                                                //         LacquerLevelId: 1
                                                //         // WorkTime: 0,
                                                //         // WorkPrice: 0,
                                                //         // SparePartDiscount: 0
                                                //     });
                                                // }
                                                unfoldKey('lacquer');
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: dvnLR?.dvn || 0,
                                                    repairType: 'lacquer'
                                                });
                                            }
                                        }}
                                    />
                                    {t('RepairType.label.L')}
                                </Space>
                            }
                            key="lacquer"
                        >
                            <Col offset={1} span={22}>
                                <LacquerTypeView />
                            </Col>
                        </Panel>
                    )}

                    {showLacquer && additionalRC?.includes('M') && (
                        <Panel
                            disabled={!showLacquer}
                            header={
                                <Space>
                                    <SelectedElement
                                        disabled={!showLacquer}
                                        checked={isRepairType(dvnLR?.dvn || 0, 'lacquer demounted', repairPositions)}
                                        onChange={_e => {
                                            if (!isRepairType(dvnLR?.dvn || 0, 'lacquer demounted', repairPositions)) {
                                                // todo set default value from settings
                                                // if (currentAssemblyGroup) {
                                                //     RepairPositionsModel.event.addLacquerLDamage({
                                                //         assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                //         DATProcessId: dvnLR?.dvn || 0,
                                                //         Description: currentObj?.titleMenu || '',
                                                //         LacquerLevel: 'surface',
                                                //         LacquerLevelId: 1
                                                //         // WorkTime: 0,
                                                //         // WorkPrice: 0,
                                                //         // SparePartDiscount: 0
                                                //     });
                                                // }
                                                unfoldKey('lacquer demounted');
                                            } else {
                                                RepairPositionsModel.event.delRepairTypeDamage({
                                                    dvn: dvnLR?.dvn || 0,
                                                    repairType: 'lacquer demounted'
                                                });
                                            }
                                        }}
                                    />
                                    {t('RepairType.label.M')}
                                </Space>
                            }
                            key="lacquer demounted"
                        >
                            <Col offset={1} span={22}>
                                <LacquerDemountedTypeView />
                            </Col>
                        </Panel>
                    )}

                    {repairCodesPZST.length &&
                        repairCodesPZST.map(rc => {
                            const repairType = convertRepairTypeToFullRepairType(rc) || '';

                            return (
                                <Panel
                                    disabled={!repairType}
                                    header={
                                        <Space>
                                            <SelectedElement
                                                disabled={!repairType}
                                                checked={isRepairType(dvnLR?.dvn || 0, repairType, repairPositions)}
                                                onChange={_e => {
                                                    if (currentAssemblyGroup) {
                                                        if (
                                                            !isRepairType(dvnLR?.dvn || 0, repairType, repairPositions)
                                                        ) {
                                                            RepairPositionsModel.event.addPZSTDamage({
                                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                                DATProcessId: dvnLR?.dvn || 0,
                                                                Description: currentObj?.titleMenu || '',
                                                                // WorkTime: 0,
                                                                // WorkPrice: 0,
                                                                repairType: repairType
                                                            });
                                                            unfoldKey(repairType);
                                                        } else {
                                                            RepairPositionsModel.event.delRepairTypeDamage({
                                                                dvn: dvnLR?.dvn || 0,
                                                                repairType: repairType
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                            {t('RepairType.label.' + rc)}
                                        </Space>
                                    }
                                    key={repairType}
                                >
                                    <Col offset={1} span={22}>
                                        <PZSTTypeView />
                                    </Col>
                                </Panel>
                            );
                        })}
                </Collapse>
                <br />
            </>
        );
    },
    () => true
);
