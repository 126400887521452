export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'dd/MM/yyyy, hh:mm aa';
export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_INPUT_MASK = DEFAULT_DATE_FORMAT.replace(/[a-zA-Z]/g, '9');

export const TodayTranslate: Record<DAT2.Locale, string> = {
    'en-US': 'Today',
    'el-GR': 'Σήμερα',
    'fr-FR': "Aujourd'hui",
    'it-IT': 'Oggi',
    'ru-RU': 'Сегодня',
    'pl-PL': 'Dziś',
    'zh-CN': '今天',
    'cs-CZ': 'Dnes',
    'de-DE': 'Heute',
    'es-ES': 'Hoy',
    'ko-KR': '오늘',
    'nl-NL': 'Vandaag',
    'de-AT': 'Heute',
    'de-CH': 'Heute'
};

export const TimeTranslate: Record<DAT2.Locale, string> = {
    'en-US': 'Time',
    'el-GR': 'χρόνος',
    'fr-FR': 'Temps',
    'it-IT': 'Volta',
    'ru-RU': 'Время',
    'pl-PL': 'Czas',
    'zh-CN': '时间',
    'cs-CZ': 'Čas',
    'de-DE': 'Zeit',
    'es-ES': 'Tiempo',
    'ko-KR': '시간',
    'nl-NL': 'Tijd',
    'de-AT': 'Zeit',
    'de-CH': 'Zeit'
};

export const DateFnsLocaleFormat = {
    'en-US': 'enUS',
    'el-GR': 'el',
    'fr-FR': 'fr',
    'it-IT': 'it',
    'ru-RU': 'ru',
    'pl-PL': 'pl',
    'zh-CN': 'zhCN',
    'cs-CZ': 'cs',
    'de-DE': 'de',
    'es-ES': 'es',
    'ko-KR': 'ko',
    'nl-NL': 'nl',
    'de-AT': 'deAT',
    'de-CH': 'de'
} as const;
