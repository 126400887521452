import { datProdServicesUrls } from '../baseParameters';
import { Credentials } from '../types/api2datTypes';
import {
    GetExtPartNoInfoByFullVehicleAndIntPartNoParams,
    GetExtPartNoInfoByFullVehicleAndIntPartNoResponse,
    GetExtPartNoInfoByModelAndExtPartNoParams,
    GetExtPartNoInfoByModelAndExtPartNoResponse
} from '../types/SparePartsServiceTypes';
import { sendCommonSoapRequest } from '../utils/soapRequest';

const urlSparePartsService =
    datProdServicesUrls.sparePartsServiceUrl || 'https://www.dat.de/PartsInfo/services/SpareParts';

const hardCredentials: Credentials = {
    interfacePartnerNumber: '3131411',
    interfacePartnerSignature: 'jA0EAwMCrjjoCCEuBBBgySvQtBjnp+NKnkteqoMkkzMRZlXddAQsnEG1vdDRAyRfRq+a41mrUtxJrmOg',
    customerNumber: '3131411',
    customerLogin: 'ferrsimo',
    customerPassword: 'ferrsimo01'
};

// https://www.dat.de/PartsInfo/services/SpareParts?wsdl
// https://www.dat.de/fileadmin/de/support/interface-documentation/EN/SilverDAT_interface_compendium/index.htm#4813

export async function getExtPartNoInfoByModelAndExtPartNo({
    credentials,
    params
}: {
    credentials?: Credentials;
    params?: GetExtPartNoInfoByModelAndExtPartNoParams;
    url?: string;
}): Promise<GetExtPartNoInfoByModelAndExtPartNoResponse | undefined> {
    if (!credentials) return;

    const SoapResult = await sendCommonSoapRequest(
        // url || urlSparePartsService,
        urlSparePartsService,
        'getExtPartNoInfoByModelAndExtPartNo',
        // credentials,
        hardCredentials,
        { request: params },
        ['SparePartPositions.SparePartPosition', 'Dossiers.Dossier', 'DATECodeEquipment.EquipmentPosition']
    );
    return SoapResult.Envelope.Body.getExtPartNoInfoByModelAndExtPartNoResponse.result;
}

export async function getExtPartNoInfoByFullVehicleAndIntPartNo({
    credentials,
    params
}: {
    credentials?: Credentials;
    params?: GetExtPartNoInfoByFullVehicleAndIntPartNoParams;
    url?: string;
}): Promise<GetExtPartNoInfoByFullVehicleAndIntPartNoResponse | undefined> {
    if (!credentials) return;

    if (params?.intPartNo && params.intPartNo.length <= 0) return;

    const SoapResult = await sendCommonSoapRequest(
        // url || urlSparePartsService,
        urlSparePartsService,
        'getExtPartNoInfoByFullVehicleAndIntPartNo',
        // credentials,
        hardCredentials,
        { request: params },
        [
            'SparePartPositions.SparePartPosition',
            'Dossiers.Dossier',
            'DATECodeEquipment.EquipmentPosition',
            'SeriesEquipment.EquipmentPosition',
            'SpecialEquipment.EquipmentPosition'
        ]
    );
    return SoapResult.Envelope.Body.getExtPartNoInfoByFullVehicleAndIntPartNoResponse.result;
}
