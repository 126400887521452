import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';
import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { TextareaField } from '@wedat/ui-kit/Formik';
import { Wrapper as WrapperInput } from '@wedat/ui-kit/components/Input/styles';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import { CheckboxContainer } from '@wedat/ui-kit/components/Checkbox/styles';
import { IconWrapper } from '../Main/styles';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

export const Content = styled.div`
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;

    overflow-y: auto;
    ${!isMobile && makeCustomScrollBar()}
`;

export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 22px;
    display: flex;
    align-items: flex-end;

    ${WrapperInput} {
        flex: 1;
    }
`;

export const Label = styled.label`
    margin-bottom: 10px;
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.dustBlue['800']};
`;

export const TextareaStyled = styled(TextareaField)`
    width: 100%;
    height: 140px;
    border-radius: 4px;
`;

export const ButtonStyled = styled(Button)``;

export const InputsContainer = styled.div`
    padding-top: 4px;
`;

export const CCButtons = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding: 2px 4px;
    z-index: 5;
`;

export const CCButton = styled.div`
    margin-right: 12px;
    cursor: pointer;
`;

export const CCText = styled(Text)`
    color: ${({ theme }) => theme.colors.blue['700']};
`;

export const AddressBookButton = styled(IconWrapper)`
    margin-right: 12px;
    min-width: 48px;
`;

export const CheckboxesContainer = styled.div`
    padding-bottom: 12px;
`;

export const CheckboxTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.dustBlue['400']};
`;

export const ChekboxesGroup = styled.div`
    display: grid;
    grid-row-gap: 12px;
    margin-bottom: 12px;

    ${CheckboxContainer} {
        height: auto;
    }
`;

export const SuccessfullyWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`;
