import { isUpperCase } from '@dat/core/utils/isUpperCase';
import {
    AGGREGATE_PART_CODE_ITALY,
    AGGREGATE_POSITION_ENTRY_TYPE,
    PositionsTypesValues
} from '../constants/positionFieldsValues';
import { getWorkBenchDescription } from './getWorkBenchDescription';

interface Param {
    positionsItaly: DAT2.PositionItaly[];
    initialRepairPositions: DAT2.RepairPosition[];
    fromManual?: boolean;
    fromAggregate?: boolean;
    updatedInitialFormPositions?: DAT2.PositionItaly[];
}

export const createRepairPositionsUpdate = ({
    positionsItaly,
    initialRepairPositions,
    fromManual,
    fromAggregate
}: Param): DAT2.RepairPosition[] => {
    const updatedRepairPositions: DAT2.RepairPosition[] = [];

    positionsItaly.forEach(
        ({
            Amount,
            DATProcessId,
            Description,
            WorkTimeReplace,
            WorkTimeLacquer,
            WorkTimeOverhaul,
            WorkTimeMechanic,
            ValueParts,
            PartDiscountType,
            PartDiscountAsterisk,
            PartNumber,
            PartDiscountPerc,
            Type,
            PartCodeItaly,
            WorkLevelReplace,
            WorkLevelOverhaul,
            WorkLevelMechanic,
            Location
        }) => {
            const repairPositionUpdate: DAT2.RepairPosition = {
                DATProcessId,
                Description,
                PositionEntryType: fromManual ? 'manual' : fromAggregate ? AGGREGATE_POSITION_ENTRY_TYPE : 'graphical',
                SparePartDiscountType: PartDiscountType,
                SparePartDiscountAsterisk: PartDiscountAsterisk,
                SparePartNumber: String(PartNumber),
                SparePartDiscount: PartDiscountPerc,
                WorkPositionNumber: fromAggregate ? String(AGGREGATE_PART_CODE_ITALY) : '',
                SparePartPrice: (ValueParts || 0) / (Amount || 1)
            };

            if (fromAggregate) {
                repairPositionUpdate.DentsPartOrientation = 'S';
            }

            const aggregateStructure: DAT2.RepairPosition = {
                DATProcessId: 0,
                PositionEntryType: 'italiaOverwrite',
                WorkPositionNumber: PartCodeItaly,
                Description,
                DentsPartOrientation: 'S',
                SparePartPrice: 0,
                ...(Location && { Location })
            };

            const isGray = Type === PositionsTypesValues.aggregateComponent && isUpperCase(Description);
            // grayPositionPayload was added to all positions because it contains the condition inside itself, so there would be no problem.
            const grayPositionPayload = isGray
                ? {
                      PositionEntryType: 'italiaOverwrite',
                      SparePartPrice: ValueParts && ValueParts > 0 ? ValueParts : 0
                  }
                : {};

            const repairPositionsOfCurrentPosition = initialRepairPositions.filter(
                pos => pos.Description === Description && pos.DATProcessId === DATProcessId
            );
            const isRepairManufacturer = repairPositionsOfCurrentPosition.some(
                pos => pos.WorkLevelItaly === 'manufacturer'
            );

            //laquer can't contain manufacturer
            const isManufacturer =
                WorkLevelReplace === 'manufacturer' ||
                WorkLevelOverhaul === 'manufacturer' ||
                WorkLevelMechanic === 'manufacturer' ||
                isRepairManufacturer;

            const isWorkBench =
                PartNumber === 99990 ||
                PartNumber === '9999A' ||
                PartNumber === '9999B' ||
                PartNumber === 99999 ||
                PartNumber === 'B0001' ||
                PartNumber === 'B0002' ||
                PartNumber === 'B0003';

            const findRepairPosition = (repairType: DAT2.RepairPosition['RepairType']) =>
                initialRepairPositions.find(repairPos => {
                    if (fromManual) {
                        return repairPos.Description === Description && repairPos.RepairType === repairType;
                    } else {
                        return repairPos.DATProcessId === DATProcessId && repairPos.RepairType === repairType;
                    }
                });

            const isDescriptionManual = Description?.startsWith('#');

            // check when grapa is fixed
            // const isRepairTypeFixing = initialRepairPositions
            //     .filter(pos => pos.DATProcessId === DATProcessId)
            //     .some(pos => pos.RepairType === 'fixing');
            // if (isRepairTypeFixing) {
            //     const foundPosition = initialRepairPositions.filter(pos => pos.DATProcessId === DATProcessId)[0];
            //     updatedRepairPositions.push({
            //         DATProcessId,
            //         RepairType: 'adjust',
            //         Description,
            //         WorkTime: foundPosition.WorkTime,
            //         PositionEntryType: 'graphical',
            //         WorkLevelItaly: foundPosition.WorkLevelItaly,
            //         IsManualDescription: false
            //     });

            //     return;
            // }

            // update of workBench positions
            if (isWorkBench) {
                const workBenchPosition = initialRepairPositions.find(pos => pos.SparePartNumber === PartNumber);
                const workBenchDescription = getWorkBenchDescription(workBenchPosition?.Description);

                updatedRepairPositions.push({
                    DATProcessId: 0,
                    RepairType: 'incidental costs',
                    Description: workBenchDescription,
                    PositionEntryType: 'italiaPostCalc',
                    SparePartNumber: String(PartNumber),
                    SparePartNumberOrigin: 'MANUAL',
                    SparePartAmount: Amount,
                    WorkTime: WorkTimeReplace,
                    PreDamage: false
                });

                return;
            }

            // updating manufacturer positions
            if (isManufacturer) {
                const arr = [];

                const typeReplacePos = {
                    ...repairPositionUpdate,
                    RepairType: 'replace',
                    PositionEntryType: 'graphical',
                    WorkLevelItaly: 'manufacturer'
                };

                const typeDisAndMountPos = {
                    DATProcessId,
                    Description,
                    RepairType: 'dis- and mounting',
                    WorkTime: WorkTimeReplace,
                    PositionEntryType: 'italiaOverwrite',
                    WorkLevelItaly: 'manufacturer'
                };

                const typeOverhaulPos = {
                    DATProcessId,
                    RepairType: 'overhaul',
                    Description,
                    WorkTime: WorkTimeOverhaul,
                    PositionEntryType: 'italiaOverwrite',
                    WorkLevelItaly: 'manufacturer'
                };

                const typeTechnicalInspectionTimeZeroPos = {
                    DATProcessId,
                    RepairType: 'technical inspection',
                    Description,
                    WorkTime: 0,
                    PositionEntryType: 'italiaOverwrite',
                    WorkLevelItaly: 'manufacturer'
                };

                const overhaulWithoutMechanic = !WorkTimeMechanic && WorkTimeOverhaul;
                const noOverhaulNoMechanic = !WorkTimeMechanic && !WorkTimeOverhaul;

                if (WorkTimeMechanic) {
                    arr.push(typeReplacePos, typeDisAndMountPos, {
                        DATProcessId,
                        RepairType: 'technical inspection',
                        Description,
                        WorkTime: WorkTimeMechanic,
                        PositionEntryType: 'italiaOverwrite',
                        WorkLevelItaly: 'manufacturer'
                    });
                }

                if (overhaulWithoutMechanic) {
                    if (WorkTimeReplace) {
                        arr.push(
                            typeReplacePos,
                            typeDisAndMountPos,
                            typeOverhaulPos,
                            typeTechnicalInspectionTimeZeroPos
                        );
                    } else {
                        arr.push(typeReplacePos, typeOverhaulPos, typeTechnicalInspectionTimeZeroPos);
                    }
                }

                if (noOverhaulNoMechanic) {
                    arr.push(typeReplacePos, typeDisAndMountPos, typeTechnicalInspectionTimeZeroPos);
                }

                updatedRepairPositions.push(...arr);

                return;
            }

            // updating aggregate positions
            if (fromAggregate) {
                if (WorkTimeReplace) {
                    updatedRepairPositions.push({
                        ...aggregateStructure,
                        WorkTime: WorkTimeReplace,
                        RepairType: 'dis- and mounting'
                    });
                }
                if (WorkTimeOverhaul) {
                    updatedRepairPositions.push({
                        ...aggregateStructure,
                        WorkTime: WorkTimeOverhaul,
                        RepairType: 'overhaul'
                    });
                }
                if (WorkTimeLacquer) {
                    updatedRepairPositions.push({
                        ...aggregateStructure,
                        WorkTime: WorkTimeLacquer,
                        RepairType: 'lacquer'
                    });
                }

                return;
            }

            // updating manual positions
            if (fromManual) {
                if (WorkTimeMechanic) {
                    updatedRepairPositions.push({
                        ...findRepairPosition('mechanic'),
                        ...repairPositionUpdate,
                        WorkTime: WorkTimeMechanic,
                        PositionEntryType: 'manual',
                        RepairType: 'overhaul',
                        WorkType: 'mechanic',
                        SparePartPrice: repairPositionUpdate.SparePartPrice
                    });
                }
                if (WorkTimeReplace) {
                    updatedRepairPositions.push(
                        ...[
                            // replace
                            {
                                DATProcessId,
                                Description,
                                RepairType: 'dis- and mounting',
                                WorkTime: WorkTimeReplace,
                                PositionEntryType: 'manual',
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice
                            },
                            // overhaul
                            {
                                DATProcessId,
                                Description,
                                PositionEntryType: 'manual',
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice,
                                SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                                SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                                RepairType: 'replace',
                                ...(WorkTimeOverhaul ? { WorkTime: WorkTimeOverhaul } : { WorkLevelItaly: 'replace' })
                            },
                            // lacquer
                            {
                                DATProcessId,
                                Description,
                                PositionEntryType: 'manual',
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice,
                                RepairType: 'lacquer',
                                LacquerLevel: 'surface',
                                ...(WorkTimeLacquer && { WorkTime: WorkTimeLacquer })
                            }
                        ]
                    );
                }
                if (WorkTimeOverhaul && !WorkTimeReplace) {
                    updatedRepairPositions.push(
                        ...[
                            {
                                DATProcessId,
                                Description,
                                PositionEntryType: 'manual',
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice,
                                RepairType: 'lacquer',
                                LacquerLevel: 'surface',
                                ...(WorkTimeLacquer && { WorkTime: WorkTimeLacquer })
                            },

                            {
                                DATProcessId,
                                Description,
                                RepairType: 'overhaul',
                                PositionEntryType: 'manual',
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice,
                                WorkTime: WorkTimeOverhaul,
                                SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                                SparePartDiscountType: repairPositionUpdate.SparePartDiscountType
                            },
                            {
                                DATProcessId,
                                Description,
                                RepairType: 'dis- and mounting',
                                PositionEntryType: 'manual',
                                WorkLevelItaly: 'medium-avg',
                                SparePartPrice: repairPositionUpdate.SparePartPrice
                            }
                        ]
                    );
                }
                if (WorkTimeLacquer && !WorkTimeReplace && !WorkTimeOverhaul) {
                    updatedRepairPositions.push({
                        ...findRepairPosition('lacquer'),
                        ...repairPositionUpdate,
                        DATProcessId,
                        RepairType: 'lacquer',
                        WorkTime: WorkTimeLacquer,
                        PositionEntryType: repairPositionUpdate.PositionEntryType,
                        LacquerLevel: 'surface',
                        SparePartPrice: repairPositionUpdate.SparePartPrice
                    });
                }
                // EMPTY TIMES
                if (ValueParts && !WorkTimeMechanic && !WorkTimeOverhaul && !WorkTimeLacquer && !WorkTimeReplace) {
                    updatedRepairPositions.push({
                        ...findRepairPosition('replace'),
                        ...repairPositionUpdate,
                        RepairType: 'replace',
                        SparePartPrice: repairPositionUpdate.SparePartPrice
                    });
                }
                if (!ValueParts && !WorkTimeMechanic && !WorkTimeOverhaul && !WorkTimeLacquer && !WorkTimeReplace) {
                    updatedRepairPositions.push({
                        DATProcessId: 0,
                        RepairType: 'comment',
                        Description,
                        IsManualDescription: true,
                        PositionEntryType: 'manual',
                        IsRepairExtension: false,
                        SparePartUsed: false,
                        PreDamage: false,
                        SparePartPrice: 0
                    });
                }

                return;
            }

            // updating graphical and others
            if (
                !fromAggregate &&
                !isManufacturer
                // check when grapa is fixed
                //  && !isRepairTypeFixing
            )
                if (WorkTimeReplace) {
                    const arr = [];
                    if (!WorkTimeOverhaul) {
                        arr.push({
                            PositionEntryType: repairPositionUpdate.PositionEntryType,
                            SparePartNumber: repairPositionUpdate.SparePartNumber,
                            SparePartPrice: repairPositionUpdate.SparePartPrice,
                            DATProcessId,
                            Description,
                            WorkLevelItaly: 'replace',
                            RepairType: 'replace',
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        });
                    } else {
                        arr.push({
                            PositionEntryType: repairPositionUpdate.PositionEntryType,
                            SparePartNumber: repairPositionUpdate.SparePartNumber,
                            SparePartPrice: repairPositionUpdate.SparePartPrice,
                            DATProcessId,
                            Description,
                            WorkTime: WorkTimeOverhaul,
                            RepairType: 'replace',
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        });
                    }
                    if (!WorkTimeLacquer) {
                        arr.push({
                            PositionEntryType: repairPositionUpdate.PositionEntryType,
                            SparePartNumber: repairPositionUpdate.SparePartNumber,
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            DATProcessId,
                            Description,
                            RepairType: 'lacquer',
                            LacquerLevel: 'surface',
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        });
                    } else {
                        arr.push({
                            PositionEntryType: repairPositionUpdate.PositionEntryType,
                            SparePartNumber: repairPositionUpdate.SparePartNumber,
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            DATProcessId,
                            Description,
                            RepairType: 'lacquer',
                            WorkTime: WorkTimeLacquer,
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        });
                    }
                    updatedRepairPositions.push(
                        ...[
                            {
                                DATProcessId,
                                RepairType: 'dis- and mounting',
                                WorkTime: WorkTimeReplace,
                                PositionEntryType: repairPositionUpdate.PositionEntryType,
                                SparePartNumber: repairPositionUpdate.SparePartNumber,
                                SparePartPrice: repairPositionUpdate.SparePartPrice,
                                SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                                SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                                Description,
                                ...(isDescriptionManual && { WorkManualInput: '#' }),
                                ...grayPositionPayload
                            },
                            ...arr
                        ]
                    );
                }
            if (WorkTimeOverhaul && !WorkTimeReplace) {
                const arr = [];

                if (!WorkTimeLacquer) {
                    arr.push({
                        PositionEntryType: repairPositionUpdate.PositionEntryType,
                        SparePartNumber: repairPositionUpdate.SparePartNumber,
                        SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                        SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                        DATProcessId,
                        Description,
                        RepairType: 'lacquer',
                        LacquerLevel: 'surface',
                        ...(isDescriptionManual && { WorkManualInput: '#' }),
                        ...grayPositionPayload
                    });
                } else {
                    arr.push({
                        PositionEntryType: repairPositionUpdate.PositionEntryType,
                        SparePartNumber: repairPositionUpdate.SparePartNumber,
                        SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                        SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                        DATProcessId,
                        Description,
                        RepairType: 'lacquer',
                        LacquerLevel: 'surface',
                        WorkTime: WorkTimeLacquer,
                        ...(isDescriptionManual && { WorkManualInput: '#' }),
                        ...grayPositionPayload
                    });
                }

                updatedRepairPositions.push(
                    ...[
                        {
                            DATProcessId,
                            Description,
                            RepairType: 'overhaul',
                            PositionEntryType: repairPositionUpdate.PositionEntryType,
                            SparePartNumber: repairPositionUpdate.SparePartNumber,
                            WorkTime: WorkTimeOverhaul,
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        },
                        {
                            DATProcessId,
                            Description,
                            SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                            SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                            RepairType: 'dis- and mounting',
                            PositionEntryType: 'graphical',
                            WorkLevelItaly: 'medium-avg',
                            ...(isDescriptionManual && { WorkManualInput: '#' }),
                            ...grayPositionPayload
                        },
                        ...arr
                    ]
                );
            }
            if (WorkTimeLacquer && !WorkTimeReplace && !WorkTimeOverhaul) {
                updatedRepairPositions.push({
                    ...findRepairPosition('lacquer'),
                    ...repairPositionUpdate,
                    DATProcessId,
                    Description,
                    SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                    SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                    RepairType: 'lacquer',
                    WorkTime: WorkTimeLacquer,
                    PositionEntryType: repairPositionUpdate.PositionEntryType,
                    ...(isDescriptionManual && { WorkManualInput: '#' }),
                    LacquerLevel: 'surface',
                    ...grayPositionPayload
                });
            }
            // updates mechanic times for non manual positions
            if (WorkTimeMechanic && !fromManual) {
                updatedRepairPositions.push({
                    ...findRepairPosition('mechanic'),
                    ...repairPositionUpdate,
                    WorkTime: WorkTimeMechanic,
                    PositionEntryType: 'italiaOverwrite',
                    RepairType: 'technical inspection',
                    SparePartDiscount: repairPositionUpdate.SparePartDiscount,
                    SparePartDiscountType: repairPositionUpdate.SparePartDiscountType,
                    ...(isDescriptionManual && { WorkManualInput: '#' }),
                    ...grayPositionPayload
                });
            }
            // EMPTY TIMES
            if (ValueParts && !WorkTimeMechanic && !WorkTimeOverhaul && !WorkTimeLacquer && !WorkTimeReplace) {
                updatedRepairPositions.push({
                    ...findRepairPosition('replace'),
                    ...repairPositionUpdate,
                    RepairType: 'replace',
                    ...grayPositionPayload
                });
            }

            if (!ValueParts && !WorkTimeMechanic && !WorkTimeOverhaul && !WorkTimeLacquer && !WorkTimeReplace) {
                updatedRepairPositions.push({
                    ...findRepairPosition('replace'),
                    ...repairPositionUpdate,
                    RepairType: 'replace',
                    ...grayPositionPayload
                });
            }
        }
    );

    return updatedRepairPositions;
};
