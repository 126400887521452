import { FC, useEffect } from 'react';

import { useStore } from 'effector-react';
import 'antd/dist/antd.css';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './i18n';

import '@dat/core/styles/fonts.css';

import { PluginOptions } from './types/plugin';

import { Main } from './components/Main';
import { pluginOptionsModel } from './stores/pluginOptionsModel';
import { Alert } from 'antd';

export interface AppProps {
    options?: PluginOptions;
}

// const App: FC<Partial<Props>> = ({ options }) => {
export const App: FC<AppProps> = ({ options }) => {
    // const requestCredentials = useStore(requestCredentialsModel.stores.requestCredentials);
    // const token =
    //     'dat-authorizationtoken' in requestCredentials ? requestCredentials['dat-authorizationtoken'] : undefined;

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    useEffect(() => {
        !!options && pluginOptionsModel.events.updatePluginOptions(options);
    }, [options]);

    if (!pluginOptions) return null;

    if (!pluginOptions?.token && !pluginOptions?.credentials)
        return (
            <Alert
                style={{ width: '640px', maxWidth: '90%', margin: '16px auto 0px auto' }}
                message="No authority provided. 'token' or 'credentials' param must be provided."
                type="warning"
            />
        );

    return (
        <I18nextProvider i18n={i18n}>
            <Main />
        </I18nextProvider>
    );
    // (
    //     <>
    //         {!token && !options?.credentials ? (
    //             <Auth options={{}} />
    //         ) : (
    //             // @ts-ignore
    //             <PluginProvider name="fast-track" options={options || {}} noAuth>
    //                 <Main options={options} />
    //             </PluginProvider>
    //         )}
    //     </>
    // );
};
