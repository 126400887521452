import { sample } from 'effector';

import { fuelLevelEffects } from './index';
import { contractStores } from '@dat/shared-models/contract';

const { fuelValueProcessingFx, fuelValueUpdatingContractFx } = fuelLevelEffects;

const { contractId } = contractStores;

sample({
    clock: fuelValueProcessingFx,
    source: contractId,
    fn: contractId => contractId,
    target: fuelValueUpdatingContractFx
});
