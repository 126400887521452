import React, { FC } from 'react';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';
import { OptionsContext } from './contexts/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => (
    <PluginProvider name="form-builder" options={options}>
        <OptionsContext.Provider value={options}>
            <Main {...options} />
        </OptionsContext.Provider>
    </PluginProvider>
);

export default App;
