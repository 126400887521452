import { KeyValueOption } from '@wedat/ui-kit/components/Select';

export const createOptionsWithDisabledOnes = <T extends KeyValueOption>(
    allOptions: T[],
    availableOptions: KeyValueOption[]
): T[] => {
    const availableOptionsKeys = availableOptions.map(({ key }) => key);

    return allOptions.map(option => {
        if (!availableOptionsKeys.includes(option.key)) {
            return {
                ...option,
                disabled: true
            };
        } else {
            return option;
        }
    });
};
