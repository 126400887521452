import { createEffect, createEvent, createStore, attach } from 'effector';
import { API2 } from '@dat/api2';
import { ASSIGNED_USERS_FIELD, DEFAULT_COMMENT_FIELD } from './constants';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { pluginStores } from '../plugin';
import { statusesEvents } from '../statuses';
import vehiclePlaceholderImage from '../../assets/images/vehicle-placeholder.png';
import { sharedVehiclesImagesStores } from '@dat/shared-models/contract/Dossier/Vehicle/Images';
import { IMAGE_ASPECT } from '../images/constants';
import { createDataURL } from '@dat/core/utils/data/createDataURL';
import { PreviewFieldType, PreviewGroup, PreviewTabs } from '../../types/plugin';
import { processPlaceholders } from '../../utils/processPlaceholders';

const claimWrapper = createStore<DAT2.Internal.Response.GetClaim | null>(null);
const templateData = createStore<{ [fieldName: string]: string | number }>({});
const claimHistory = createStore<DAT2.NotificationEvent[]>([]);
const comment = createStore<string>('');
const status = createStore<string>('');
const users = createStore<DAT2.Internal.User[]>([]);
const vehicleImage = createStore<string>('');
const activeTab = createStore<PreviewTabs | null>(null);

const updateClaimFx = createEffect((request: DAT2.Request.CreateOrUpdateContract) => {
    if (!request.templateData || !request.templateData.entry) return;
    return API2.myClaim.createOrUpdateContract(request);
});

const fetchClaimFx = createEffect(
    async ({
        claimId,
        fieldGroups,
        templateId
    }: {
        claimId: number;
        fieldGroups: PreviewGroup[];
        templateId: number;
    }) => {
        const claimItem = await API2.myClaimInternal.getClaim({ claimId, mode: 'CALCULATION' });
        const templateData = claimItem?.fieldSets?.[templateId]?.data;
        if (templateData) {
            fieldGroups.forEach(group =>
                group.fields.forEach(field => {
                    if (field.value) {
                        templateData[field.fieldName] = processPlaceholders({ value: field.value, templateData });
                    }
                })
            );
        }
        return claimItem;
    }
);

const loadClaimFx = attach({
    source: { pluginOptions: pluginStores.pluginOptions, templateId: sharedTemplateStores.templateId },
    mapParams: ({ claimId }: { claimId: number }, { pluginOptions, templateId }) => ({
        claimId,
        fieldGroups: pluginOptions?.settings?.preview?.generalSection || [],
        templateId: templateId || 0
    }),
    effect: fetchClaimFx
});

const updateCurrentClaimFx = attach({
    source: { claimWrapper, pluginOptions: pluginStores.pluginOptions },
    mapParams: (templateData: { [fieldName: string]: string }, { claimWrapper, pluginOptions }) => {
        if (!claimWrapper) throw Error('Claim cannot be null');
        const data = Object.keys(templateData).map(fieldName => {
            let value = templateData[fieldName];
            if (
                pluginOptions?.settings?.preview?.generalSection?.some(group =>
                    group?.fields?.some(field => field.fieldName === fieldName && field.type === PreviewFieldType.DATE)
                )
            ) {
                value = new Date(value).toLocaleString('de', { month: '2-digit', day: '2-digit', year: '2-digit' });
            }
            return {
                key: fieldName,
                value
            };
        });

        return {
            contractId: claimWrapper.claim.id,
            contractType: claimWrapper.claim.claimType,
            networkType: claimWrapper.claim.networkType,
            templateData: data.length ? { entry: data } : undefined
        };
    },
    effect: updateClaimFx
});

const loadClaimHistoryFx = createEffect(async (request: DAT2.Request.ListContractHistory) => {
    const res = (await API2.myClaim.listContractHistory(request)).return;
    if (res) {
        return Array.isArray(res) ? res : [res];
    }
});

const updateUsersOfClaimFx = attach({
    source: [claimWrapper, users],
    mapParams: (_params, [claimItem, users]) => {
        if (!claimItem) throw Error('Claim cannot be null');
        return {
            contractId: claimItem.claim.id,
            contractType: claimItem.claim.claimType,
            networkType: claimItem.claim.networkType,
            templateData: { entry: { key: ASSIGNED_USERS_FIELD, value: JSON.stringify(users) } }
        };
    },
    effect: updateClaimFx
});

const updateTemplateData = createEvent<{ fieldName: string; value: string | number }>();
const updateComment = createEvent<string>();
const changeUser = createEvent<{ user: DAT2.Internal.User; active: boolean }>();
const setActiveTab = createEvent<PreviewTabs>();

claimWrapper.on(loadClaimFx.doneData, (_, state) => state);

templateData
    .on(loadClaimFx.doneData, (_, _state) => ({}))
    .on(updateTemplateData, (current, { fieldName, value }) => ({ ...current, [fieldName]: value }));

claimHistory.on(loadClaimHistoryFx.doneData, (_, state) => state);

comment
    .on(
        loadClaimFx.doneData,
        (_, { fieldSets }) =>
            fieldSets?.[sharedTemplateStores.templateId.getState() || 0]?.data[
                pluginStores.pluginOptions.getState()?.settings?.preview?.commentSection?.fieldName ||
                    DEFAULT_COMMENT_FIELD
            ] || ''
    )
    .on(updateComment, (_, value) => value);

status
    .on(loadClaimFx.doneData, (_, { claim: { status } }) => status?.name)
    .on(statusesEvents.statusChanged, (_, transition) => transition.name);

users
    .on(loadClaimFx.doneData, (_, { fieldSets }) => {
        const value = fieldSets?.[sharedTemplateStores.templateId.getState() || 0]?.data[ASSIGNED_USERS_FIELD];
        return value ? JSON.parse(value) : [];
    })
    .on(changeUser, (users, userItem) => {
        if (userItem.active) {
            return [...users, userItem.user].sort((a, b) =>
                `${a.firstName} ${a.surname}`.localeCompare(`${b.firstName} ${b.surname}`)
            );
        }
        return [...users.filter(user => user.login !== userItem.user.login)];
    });

vehicleImage.on(loadClaimFx.doneData, (_, claimWrapper) => {
    const eCode = `${claimWrapper?.claim?.vehicle?.eCode || ''}${claimWrapper.claim.vehicle?.eCodeContainer || ''}`;
    if (!eCode) {
        return vehiclePlaceholderImage;
    }

    const image = sharedVehiclesImagesStores.allVehiclesImages.getState()[eCode]?.[IMAGE_ASPECT]?.[0];
    if (image) {
        return createDataURL({
            type: 'image',
            subType: image.imageFormat,
            data: image.imageBase64,
            base64: true
        });
    }

    return vehiclePlaceholderImage;
});

activeTab.on(setActiveTab, (_, tab) => tab).on(loadClaimFx, () => null);

const previewOpened = createStore<boolean>(false);
const openPreview = createEvent<{ claimId: number }>();
const closePreview = createEvent<void>();
previewOpened.on(openPreview, () => true).on(closePreview, () => false);

export const previewEffects = {
    loadClaimFx,
    loadClaimHistoryFx,
    updateCurrentClaimFx,
    updateUsersOfClaimFx
};

export const previewEvents = {
    updateTemplateData,
    updateComment,
    changeUser,
    setActiveTab,
    closePreview,
    openPreview
};

export const previewStores = {
    claimWrapper,
    claimHistory,
    templateData,
    comment,
    status,
    users,
    vehicleImage,
    activeTab,
    previewOpened
};
