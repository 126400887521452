export const convertConstructionPeriodToDate = (constructionTimeKey: string, locale: string = 'fr') => {
    if (+constructionTimeKey > 9998) {
        return 'LAST';
    }
    const ct = parseInt(constructionTimeKey);
    let y = Math.floor((ct - 1) / 120);
    let m = Math.floor((((ct - 1) % 120) + 1) / 10);
    let d = Math.floor((ct - y * 120 - m * 10) * 3 + 1);
    return new Date(Date.UTC(y + 1970, m - 1, d)).toLocaleDateString(locale);
};
