import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

import { useInstance } from '../../../hooks/useInstance';

import { Subject } from '../../../types/subjects';

import { ItemContainer } from './styles';

export const ListItem: React.FC<ListChildComponentProps<Subject[]>> = ({ index, style, data: foundSubjects }) => {
    const { t } = useTranslation();
    const { displayedId, subjectSelectedInList } = useInstance('displayedId');
    const subject = foundSubjects[index];

    return (
        <ItemContainer
            style={style}
            key={subject._id}
            selected={subject._id === displayedId}
            onClick={() => subjectSelectedInList(subject)}
        >
            {subject.name || t('emptyContact')} {subject.surname || ''}
        </ItemContainer>
    );
};
