import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from 'effector';
import { v4 as uuidv4 } from 'uuid';
import { TagsInput } from '@wedat/ui-kit/components/TagsInput';
import { TooltipContent } from './TooltipContent';
import { Container } from './styles';

interface Props {
    subjects: BFF.AddressBook.Subject[];
    removeItem: Event<BFF.AddressBook.Subject>;
    addSubject: Event<BFF.AddressBook.Subject>;
    removeAllSubjects: Event<void>;
    label: string;
    error?: string | string[];
    paddingRight?: number;
}

export const SelectedSubjects: React.FC<Props> = ({
    subjects,
    removeItem,
    removeAllSubjects,
    label,
    error,
    addSubject,
    paddingRight
}) => {
    const { t } = useTranslation();
    const tooltipContentRef = useRef<HTMLDivElement | null>(null);

    const formattedSubjects = useMemo(
        () =>
            subjects.map(item => ({
                id: item._id,
                tooltipContent: String(item.mail),
                displayContent: String(item.name),
                ...item
            })),
        [subjects]
    );

    const handleTagRemove = (subject: Partial<BFF.AddressBook.Subject>) => {
        removeItem(subject as BFF.AddressBook.Subject);
    };

    const handleAddNewTag = (subjectContent: string) => {
        const subject = {
            _id: uuidv4(),
            mail: subjectContent,
            name: subjectContent
        };
        addSubject(subject);
    };

    const helperText = error?.length ? error[0] : error?.toString();

    return (
        <>
            <Container>
                <TagsInput
                    tags={formattedSubjects}
                    label={label}
                    overflowingText={t('inputs.addedAdresses', { count: subjects?.length })}
                    overflowingTooltip={tooltipContentRef.current as Element}
                    onAllTagsRemove={removeAllSubjects}
                    onTagRemove={handleTagRemove}
                    addNewTag={handleAddNewTag}
                    helperText={helperText}
                    status={helperText ? 'error' : 'default'}
                    paddingRight={paddingRight}
                />
            </Container>

            <TooltipContent ref={tooltipContentRef} subjects={subjects} />
        </>
    );
};
