import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { StyledPreloaderIcon } from './styles';

interface Props extends ComponentProps<typeof StyledPreloaderIcon> {
    isLoading: boolean;
}

const _PreloaderCircle: React.FC<Props> = ({ isLoading = true, ...rest }) =>
    isLoading ? <StyledPreloaderIcon {...rest} /> : null;

/*
 * Using `styled` to allow styling of the component via styled-CSS injection:
 *
 * ${PreloaderCircle} {
 *   ...styles
 * }
 */
export const PreloaderCircle = styled(_PreloaderCircle)``;
