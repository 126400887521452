import { createEffect, createEvent } from 'effector';

import { API2 } from '@dat/api2';
import { contractStores } from '../stores';
import { getFieldText, getParsedArraySafe } from '@dat/api2/utils';

import { PayloadForChangeContractStatus } from './types';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';

const { contract } = contractStores;

const getPossibleContractStatusTransitionsFx = createEffect(async (contractId: number) => {
    const response = await API2.myClaim.getPossibleContractStatusTransitions({ contractId });
    return getParsedArraySafe(response.PossibleContractStatusTransitions);
});

const changeContractStatusFx = createNotifyingEffect({
    handler: async ({ statusName, contractId, comment, statusId, statusType }: PayloadForChangeContractStatus) => {
        if (!statusId) {
            if (!statusType) statusType = 'custom';
            await API2.myClaim.changeContractStatus({
                statusType: statusType === 'custom' ? statusName : statusType,
                contractId,
                note: comment
            });
            return;
        }

        const result = await API2.myClaimInternal.setStatus({
            claimId: contractId,
            comment,
            statusId
        });

        const checkError = (events?: DAT2.Internal.FrontendEvent[]) =>
            events
                ?.filter(e => e.event === 'core_message_error' && e.data)
                ?.map(e => e.data)
                ?.forEach(errorMessage => {
                    throw new Error(errorMessage);
                });

        checkError(result.events?.beforeAction);
        checkError(result.events?.afterAction);

        if (!result?.data?.historyEntry) {
            // unknown error
            throw new Error('defaultErrorStatusNotChanged');
        }
    }
});

const statusName = contract.map(contract => getFieldText(contract?.statusName));
const statusType = contract.map(contract => getFieldText(contract?.statusType));
const statusId = contract.map(contract => getFieldText(contract?.status_id));

const disabledStatusSet = createEvent();

export const sharedContractStatusEvents = {
    disabledStatusSet
};
export const sharedContractStatusEffects = {
    getPossibleContractStatusTransitionsFx,
    changeContractStatusFx
};
export const sharedContractStatusStores = {
    statusName,
    statusType,
    statusId
};
