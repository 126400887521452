import styled from 'styled-components/macro';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    ${makeCustomScrollBar()};

    .list {
        ${makeCustomScrollBar()};
    }
`;

export const Text = styled.div`
    color: ${({ theme }) => theme.colors.gray_300};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
`;
