import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@wedat/ui-kit/components/Button';
import { FormikErrors } from 'formik';

import { Text } from '@wedat/ui-kit/components/Text';
import { Input } from '@wedat/ui-kit/components/Input';
import { Datepicker } from '@dat/smart-components/Datepicker';
import { DatepickerParsedValue } from '@wedat/ui-kit/components/Datepicker/types';

import {
    ButtonSectionStyled,
    ButtonStyledWrapper,
    CreateAppointmentPopupContentStyled,
    CreateAppointmentPopupStyled,
    DatepickerStyled,
    ExpirationPickersStyled,
    InputSectionStyled,
    UpperSectionStyled
} from '../../CustomEditor/styles';
import { SelectAssignee } from '../../SelectAssignee';

interface Props {
    isEditing: boolean;
    onClose: () => void;
    values: { expiryDate: Date; description: string; address: string; city: string; zip: string };
    onExpiredDateChange: (value: DatepickerParsedValue) => void;
    onSubmit: () => void;
    error: FormikErrors<Date>;
    descriptionPrefix: string;
    onDescriptionChange: (e: string) => void;
    onAddressChange: (e: string) => void;
    onCityChange: (e: string) => void;
    onZipChange: (e: string) => void;
    onAssigneeChange: (id: number) => void;
    isAdmin?: boolean;
}

export const NewExpiryClaimModal: React.FC<Props> = ({
    onClose,
    isEditing,
    values,
    onExpiredDateChange,
    onSubmit,
    descriptionPrefix,
    error,
    onDescriptionChange,
    onAddressChange,
    onCityChange,
    onZipChange,
    onAssigneeChange,
    isAdmin
}) => {
    const { t } = useTranslation();

    const isDescriptionProvided = !!descriptionPrefix.trim().length;

    return (
        <CreateAppointmentPopupStyled isDescriptionProvided={isDescriptionProvided}>
            <UpperSectionStyled>
                <Text fontSize="18px">{isEditing ? t('edit.expiryClaim') : t('create.expiryClaim')}</Text>
            </UpperSectionStyled>

            <CreateAppointmentPopupContentStyled>
                <UpperSectionStyled>
                    <Input
                        prefix={isDescriptionProvided ? descriptionPrefix : ''}
                        value={values.description}
                        name="description"
                        label={t('description')}
                        onChange={e => onDescriptionChange(e.target.value)}
                    />
                </UpperSectionStyled>

                {isAdmin && <SelectAssignee onChange={onAssigneeChange} />}

                <Text font="footnote">{t('date')}</Text>

                <ExpirationPickersStyled>
                    <DatepickerStyled>
                        <Datepicker onChange={onExpiredDateChange} value={values.expiryDate} />
                    </DatepickerStyled>
                </ExpirationPickersStyled>

                <InputSectionStyled>
                    <Input
                        value={values.address}
                        label={t('address')}
                        onChange={e => onAddressChange(e.target.value)}
                    />
                </InputSectionStyled>
                <InputSectionStyled>
                    <Input value={values.city} label={t('city')} onChange={e => onCityChange(e.target.value)} />
                </InputSectionStyled>
                <InputSectionStyled>
                    <Input value={values.zip} label={t('zip')} onChange={e => onZipChange(e.target.value)} />
                </InputSectionStyled>
            </CreateAppointmentPopupContentStyled>

            {error && (
                <Text color="red" fontSize="12px">
                    {error}
                </Text>
            )}

            <ButtonStyledWrapper>
                <ButtonSectionStyled>
                    <Button
                        onClick={onClose}
                        typeStyle={{
                            type: 'secondary'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </ButtonSectionStyled>
                <ButtonSectionStyled>
                    <Button
                        disabled={!!error}
                        onClick={onSubmit}
                        typeStyle={{
                            type: 'primary'
                        }}
                    >
                        {t('create.new')}
                    </Button>
                </ButtonSectionStyled>
            </ButtonStyledWrapper>
        </CreateAppointmentPopupStyled>
    );
};
