import { Button, PreloaderCircle } from '@wedat/ui-kit';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

type Props = {
    height?: number;
};

export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;

export const ArrowWrapper = styled.div`
    width: 40px;
    height: 40px;

    margin: 0 12px 10px 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue[300]};
    border-radius: 8px;

    svg {
        transform: rotate(180deg);
    }
`;
export const Content = styled.div`
    height: 93%;
    padding-bottom: 15px;
    padding: 0 12px 15px 12px;
`;

export const Title = styled.div`
    margin-top: -10px;
    margin-left: 10px;
    ${({ theme }) => theme.typography.mobileHeader}
    color: ${({ theme }) => theme.colors.dustBlue[900]};
`;

export const AppointmentsContent = styled.div`
    max-height: 460px;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    ${makeCustomScrollBar()}
`;

export const Claims = styled.div<Props>`
    height: ${({ height }) =>
        height
            ? `calc(100% - ${height + 30}px)`
            : 'calc(100% - 400px)'}; //subtract appointment height + 15px(for margin, padding)
    padding: 32px 0px 12px 0px;
`;

export const ClaimsContent = styled.div`
    height: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 32px;

    overflow-y: auto;
    overflow-x: hidden;
    ${makeCustomScrollBar()}
`;

export const StyledPreloaderCircle = styled(PreloaderCircle)`
    position: absolute;
    top: 30%;
    z-index: 10;
`;

export const ButtonStyled = styled(Button)`
    width: 180px;

    padding: 8px;
    margin-bottom: 5px;
`;

export const TextWrapper = styled.div`
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.typography.mobileHeader}
`;
