import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';

export const StyledValueContainer: React.FC = styled(components.ValueContainer)`
    &&& {
        position: relative;
        ${({ theme }) => theme.typography.note};
        color: ${({ theme }) => theme.colors.dustBlue['900']};
        padding-left: 12px;
    }
`;

export const CounterLayout = styled.div`
    display: flex;
`;

export const Hide = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
`;
