import { attach, forward, sample } from 'effector';

import { vehicleSelectionEffects } from './vehicleSelection';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { extractOptionsFromMultipleVariantSelectionProp } from '../utils/extractOptionsFromMultipleVariantSelectionProp';
import { ClassificationGroupsKeys } from '@dat/core/constants';
import { effectorLogger } from '@dat/core/utils';

import { ParsedVXSVehicleObject } from '@dat/core/types';
import { EquipmentObject, GetEquipmentObjectParam, GetEquipmentObjectRequestData } from '../types/equipment';
import { MultipleVariantSelectionData } from '../types/multipleVariantSelection';
import { KeyValueOption } from '@wedat/ui-kit/components/Select';
import { domain } from './plugin';

//
/*** Multiple variant selection data ***/
//

// "multipleVariantSelectionData" is an object containing tree-object
// with all possible options of received vehicle variants
const setMultipleVariantSelectionData = domain.createEvent<MultipleVariantSelectionData | null>();
const resetMultipleVariantSelectionData = domain.createEvent();
const multipleVariantSelectionData = domain
    .createStore<MultipleVariantSelectionData | null>(null)
    .on(setMultipleVariantSelectionData, (_, data) => data)
    .reset(resetMultipleVariantSelectionData);

const createMultipleVariantSelectionData = setMultipleVariantSelectionData.prepend(
    (parsedVXSVehicleObjects: ParsedVXSVehicleObject[]) => {
        const result: MultipleVariantSelectionData = {
            vehicleTypes: {}
        };

        parsedVXSVehicleObjects.forEach(({ vehicleInfo, datECodeEquipment }) => {
            const {
                vehicleType,
                manufacturer,
                baseModel,
                subModel,
                vehicleTypeName,
                manufacturerName,
                baseModelName,
                subModelName
            } = vehicleInfo;

            const vehicleTypeObject = result.vehicleTypes[vehicleType] || {
                name: vehicleTypeName,
                manufacturers: {}
            };
            const manufacturerObject = vehicleTypeObject.manufacturers[manufacturer] || {
                name: manufacturerName,
                baseModels: {}
            };
            const baseModelObject = manufacturerObject.baseModels[baseModel] || {
                name: baseModelName,
                subModels: {}
            };
            const subModelObject = baseModelObject.subModels[subModel] || {
                name: subModelName,
                equipmentObject: {}
            };
            const { equipmentObject } = subModelObject;

            result.vehicleTypes[vehicleType] = vehicleTypeObject;
            vehicleTypeObject.manufacturers[manufacturer] = manufacturerObject;
            manufacturerObject.baseModels[baseModel] = baseModelObject;
            baseModelObject.subModels[subModel] = subModelObject;

            // Equipment for current sub-model
            datECodeEquipment.forEach(({ id, description, classificationGroup }) => {
                if (classificationGroup) {
                    const isOptionInArray = equipmentObject[classificationGroup]?.some(({ key }) => key === id);

                    if (!isOptionInArray && description) {
                        const equipmentOption = {
                            key: id,
                            value: description,
                            label: description
                        };
                        const groupInEquipmentObject = equipmentObject[classificationGroup];

                        if (groupInEquipmentObject) {
                            groupInEquipmentObject.push(equipmentOption);
                        } else {
                            equipmentObject[classificationGroup] = [equipmentOption];
                        }
                    }
                }
            });
        });

        return result;
    }
);

//
/*** Get/set vehicle ***/
//
interface SelectedVehicle {
    vehicleType: string;
    manufacturer: string;
    baseModel: string;
    subModel: string;
    selectedEquipment: string[];
}

// Event "setVehicleFromMultipleSelection" is called with "SelectedVehicle" data
// when user press arrow button in MultipleVariantSelectionModal or close this modal
const setVehicleFromMultipleSelection = domain.createEvent<SelectedVehicle>();

// Effect "getVehicleFromMultipleSelection" is called in "getVehicleIdentificationByVinFx" or "searchVehicleByTextFx"
// when received VXS data contains more than one variant of found vehicle.
// This effect creates "multipleVariantSelectionData" and then waits for "setVehicleFromMultipleSelection" call
// and resolves promise when vehicle is selected or rejects it otherwise.
const getVehicleFromMultipleSelectionFx = domain.createEffect({
    handler: (parsedVXSVehicleObjects: ParsedVXSVehicleObject[]): Promise<ParsedVXSVehicleObject> =>
        new Promise((resolve, reject) => {
            // Create multiple variant selection data -> open modal
            createMultipleVariantSelectionData(parsedVXSVehicleObjects);

            // Watch for "setVehicleFromMultipleSelection" calls
            const unwatch = setVehicleFromMultipleSelection.watch(
                ({ manufacturer, baseModel, subModel, selectedEquipment }) => {
                    // Find selected vehicle in received VXS data
                    const foundVehicle = parsedVXSVehicleObjects.find(({ vehicleInfo, datECodeEquipment }) => {
                        const isVehicleInfoCorrect =
                            vehicleInfo.manufacturer === manufacturer &&
                            vehicleInfo.baseModel === baseModel &&
                            vehicleInfo.subModel === subModel;
                        const isEquipmentCorrect = selectedEquipment.every(selectedKey =>
                            datECodeEquipment.some(({ id }) => id === selectedKey)
                        );

                        return isVehicleInfoCorrect && isEquipmentCorrect;
                    });

                    if (foundVehicle) {
                        // Resolve found vehicle
                        resolve(foundVehicle);
                    } else {
                        // Reject if vehicle is not found, e.g. when selection is not completed
                        reject();
                    }

                    // Unwatch event calls
                    unwatch();
                }
            );
        })
});

//
/*** Visible options (based on already selected) ***/
//
interface VisibleOptionsObject {
    vehicleTypes: KeyValueOption[];
    manufacturers: KeyValueOption[];
    baseModels: KeyValueOption[];
    subModels: KeyValueOption[];
    equipmentObject: EquipmentObject;
}

const updateVisibleOptions = domain.createEvent<SelectedVehicle>();
const initVisibleOptions = updateVisibleOptions.prepend<void>(() => ({
    vehicleType: '',
    manufacturer: '',
    baseModel: '',
    subModel: '',
    selectedEquipment: []
}));
const visibleOptions = domain
    .createStore<VisibleOptionsObject>({
        vehicleTypes: [],
        manufacturers: [],
        baseModels: [],
        subModels: [],
        equipmentObject: {}
    })
    .reset(resetMultipleVariantSelectionData);

// Connect "updateVisibleOptions" and "visibleOptions"
sample({
    source: multipleVariantSelectionData,
    clock: updateVisibleOptions,
    fn: (multipleVariantSelectionData, selectedOptions) => {
        const { vehicleType, manufacturer, baseModel, subModel } = selectedOptions;

        //TODO: move everything to single util

        // Get props from multipleVariantSelectionData object
        const vehicleTypeObject = multipleVariantSelectionData?.vehicleTypes[vehicleType];
        const manufacturerObject = vehicleTypeObject?.manufacturers[manufacturer];
        const baseModelObject = manufacturerObject?.baseModels[baseModel];
        const subModelObject = baseModelObject?.subModels[subModel];

        // Create options for selects
        const vehicleTypes = extractOptionsFromMultipleVariantSelectionProp(multipleVariantSelectionData?.vehicleTypes);
        const manufacturers = extractOptionsFromMultipleVariantSelectionProp(vehicleTypeObject?.manufacturers);
        const baseModels = extractOptionsFromMultipleVariantSelectionProp(manufacturerObject?.baseModels);
        const subModels = extractOptionsFromMultipleVariantSelectionProp(baseModelObject?.subModels);
        const equipmentObject: EquipmentObject = { ...subModelObject?.equipmentObject };

        // Show equipment group options only if there are more than one
        Object.entries(equipmentObject).forEach(([group, options]) => {
            if (!options || options.length <= 1) {
                delete equipmentObject[group as ClassificationGroupsKeys];
            }
        });

        return { vehicleTypes, manufacturers, baseModels, subModels, equipmentObject };
    },
    target: visibleOptions
});

// Detach equipment object to separate store because it's needed in EquipmentSelect
const allEquipmentObject = domain
    .createStore<EquipmentObject>({})
    .on(visibleOptions, (_, { equipmentObject }) => equipmentObject);

//
/*** Available equipment ***/
//
interface GetAvailableEquipmentObjectParam extends GetEquipmentObjectRequestData {
    classificationGroups: ClassificationGroupsKeys[];
}

const getAvailableEquipmentObjectFx = attach({
    effect: vehicleSelectionEffects.getEquipmentObjectFx,
    source: { prevEquipmentObject: allEquipmentObject },
    mapParams: cDRH(
        (
            { classificationGroups, ...requestData }: GetAvailableEquipmentObjectParam,
            { prevEquipmentObject }
        ): GetEquipmentObjectParam => ({
            requestData,
            prevEquipmentObject,
            classificationGroups
        })
    )
});
const resetAvailableEquipment = domain.createEvent();
const availableEquipmentObject = domain
    .createStore<EquipmentObject>({})
    .on(getAvailableEquipmentObjectFx.doneData, (_, data) => data)
    .reset(resetAvailableEquipment);

// Update available equipment when all equipment is updated
forward({
    from: allEquipmentObject,
    to: availableEquipmentObject
});

//
/*** Export ***/
//
export const multipleVariantSelectionEvents = {
    setMultipleVariantSelectionData,
    resetMultipleVariantSelectionData,
    createMultipleVariantSelectionData,
    setVehicleFromMultipleSelection,
    updateVisibleOptions,
    initVisibleOptions,
    resetAvailableEquipment
};
export const multipleVariantSelectionEffects = {
    getVehicleFromMultipleSelectionFx,
    getAvailableEquipmentObjectFx
};
export const multipleVariantSelectionStores = {
    multipleVariantSelectionData,
    visibleOptions,
    allEquipmentObject,
    availableEquipmentObject
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(multipleVariantSelectionEvents);
    effectorLogger(multipleVariantSelectionEffects);
    effectorLogger(multipleVariantSelectionStores);
}
