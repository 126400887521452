import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
export const Span = styled.div`
    font-size: 20px;
    margin-top: 100px;
`;
