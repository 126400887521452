import * as Yup from 'yup';
import { DataField } from '../types/dataScheme';

export const getValidationSchema = (fieldsObj: Record<string, any>) => {
    let shape = {};
    fieldsObj.fields.forEach((field: DataField) => {
        if (field.pattern?.max) {
            shape = {
                ...shape,
                ...{
                    [field.id]: Yup.string().max(field.pattern?.max, `Must be ${field.pattern?.max} characters or less`)
                }
            };
        }
        if (field.required) {
            shape = {
                ...shape,
                ...{
                    [field.id]: Yup.string().required('Required')
                }
            };
        }
    });
    return shape;
};
