import { InferenceSession } from 'onnxruntime-web';

export enum Steps {
    AwaitMedia,
    EnablePermission,
    MakePhoto,
    SendForm,
    Additional,
    Retake
}

export type Constraint = {
    [Property in CarType]: {
        [K in Sides]: number[];
    };
};

export interface Sessions {
    checker: InferenceSession;
    detection: InferenceSession;
}

export interface FormStep {
    // base64 string
    photo: string;
    photoType: PluginStep['photoType'];
    comment?: string;
    geolocation?: {
        latitude?: number;
        longitude?: number;
        date: string;
        time: string;
    };
}

export interface ResultForm {
    steps: FormStep[];
    // additional photo
    additionalSteps?: string[];
}

export interface HelpStep {
    // image for help photo modal (if empty "Help modal" doesnt show before capture)
    img?: string;
    // video for help photo modal (if empty "Help modal" doesnt show before capture)
    video?: string;
    // title below help image in modal
    title?: string;
    // description of help image
    description?: string;
}

export interface PreviewStep {
    // small image in slider during photo
    img?: string;
    // title for preview image in slider during photo
    title?: string;
}

export interface MaskStep {
    // image for mask over video
    img?: string;

    // images  for mask over video which indicates recognition status
    // g - for green or success
    img_g?: string;
    // r - for red or failed
    img_r?: string;

    // description for mask over video
    description?: string;
    // title for mask image in slider during photo
    title?: string;
}

export type Sides = 'front' | 'rear' | 'frontRight' | 'frontLeft' | 'rearRight' | 'rearLeft';
export type CarType = 'SW' | 'SUV' | '5-doors' | '4-doors' | '3-doors' | 'generic';

export interface ValidationContent {
    title: string;
    description: string;
    img: string;
}

export interface PluginStep {
    // type of photo ('damage' | 'vehicle' or ets.)
    photoType: string;
    // show retake page after capture (default is true)
    withRetake?: boolean;
    // embed geolocation, date, time in the photo (default is true)
    embedGeo?: boolean;
    // show step in landscape mode (default is false it is mean forcePortrait)
    forceLandscape?: boolean;
    // show comment block after make photo
    // TODO: comment because someone can change one's mind
    // isShowComment?: boolean;
    // Criteria for validation of image (front-right and etc)
    acceptableCriteria?: Sides;
    helpSteps?: HelpStep[];
    mask?: MaskStep;
    preview?: PreviewStep;
}

export interface PluginProps extends Pick<DAT2.Plugins.PluginBaseOptions, 'isComponent'> {
    // content for validation modal
    validationContent?: ValidationContent;
    // selector to element where plugin will render
    selector?: string;
    // to load test data for debugging in dev stand
    debug?: boolean;
    steps: PluginStep[];
    realTime?: boolean;
    carType?: CarType;
    // for dynamic mask SVG definition
    datECode?: string;
    // show summary page (default 'true') if false completeFormCallback invoke in last capture photo step
    withSummary?: boolean;
    completeFormCallback: (form: ResultForm) => void;
    // default true
    disableStreamAfterComplete?: boolean;
    translates?: {
        enablePermissionsTitle?: string;
        enablePermissionsDescription?: string;
        enablePermissionsBtn?: string;
        refreshPageBtn?: string;
        disablePermissionsTitle?: string;
        enablePermissionsSubTitle?: string;
        disablePermissionsSubTitle?: string;
        enableLocationBtn?: string;
        rejectCameraDescription?: string;
        rejectLocationDescription?: string;
        // special text message for Safari
        rejectLocationDescriptionSafari?: string;
        enablePermissionsSkip?: string;
        tapToDismiss?: string;
        commentFieldPlaceholder?: string;
        positionInLandscape?: string;
        positionInPortrait?: string;
        reviewMediaTitle?: string;
        submitBtn?: string;
        additional?: string;
        confirm?: string;
        change?: string;
    };
    icons?: {
        permissions?: string;
        rejectPermissions?: string;
        spinner?: string;
        location?: string;
    };
    gpsInfo?: {
        // default 'top-right'
        position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'center-top' | 'center-bottom';
        styles?: {
            // only words or rgb (e.g 'white' or 'rgb(255, 255, 10)')
            fontColor?: string;
            // only px (default 25px)
            fontSize?: string;
            // default Arial
            fontFamily?: string;
            // default black
            backgroundColor?: string;
            // only px only 4 values supports (e.g '10px 10px 10px 10px') only integer
            padding?: string;
            // only px
            borderRadius?: string;
        };
    };
    additionalPhotoForceLandscape?: boolean;
}

export type Dictionary<T = any> = {
    [index: string]: T;
};

type DictionaryActions = Dictionary<(...args: any) => any>;

export type ExtractActions<T extends DictionaryActions> = {
    [P in keyof T]: ReturnType<T[P]>;
}[keyof T];
