import styled from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const EquipmentValueStyled = styled.div`
    width: 100%;
    margin-top: 24px;
    overflow-x: auto;

    ${makeCustomScrollBar()}

    ${media.phoneBig`
        background-color: unset;
    `}
`;
