import styled, { css } from 'styled-components';
import { media, makeCustomScrollBar } from '../../mediaQueries';
import { isSafari } from 'react-device-detect';

interface HeaderTitleProps {
    title?: string;
}

interface ModalProps {
    isOpen?: boolean;
    maxWidth?: string;
    bodyHeight?: string;
    bodyNoPadding?: boolean;
    unsetOverflow?: boolean;
    bodyWidth?: string;
    fullFill?: boolean;
}

interface ModalContentProps {
    fullFill?: boolean;
}

export const ModalWindow = styled.div<ModalProps>`
    max-width: ${({ maxWidth }) => maxWidth};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    height: ${({ bodyHeight }) => (bodyHeight ? bodyHeight : '90vh')};

    ${({ fullFill }) =>
        fullFill &&
        css`
            height: 100vh;
            max-width: 100vh;
        `}

    ${media.phone`
        height: 100%;
        max-width: unset;
        border-radius: 0;
    `}
`;

export const ModalContent = styled.div<ModalContentProps>`
    width: 100%;
    height: 100%;
    max-height: 90vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: ${isSafari ? 'unset' : 'space-between'};

    ${({ fullFill }) => fullFill && `max-height: 100vh;`}

    ${media.phone`
        max-height: 100vh;
    `}
`;

export const ModalHeader = styled.div<HeaderTitleProps>`
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: ${title => (title ? `space-between` : `flex-end`)};
    align-items: baseline;
`;

export const ModalBody = styled.div<ModalProps>`
    width: ${({ bodyWidth }) => (bodyWidth ? bodyWidth : '100%')};
    height: 100%;
    padding: ${({ bodyNoPadding }) => (bodyNoPadding ? '0' : '0 20px')};
    border-radius: 2px;
    overflow: ${({ unsetOverflow }) => (unsetOverflow ? 'unset' : 'auto')};
    flex: 1;

    ${makeCustomScrollBar()};

    ${({ fullFill }) =>
        fullFill &&
        css`
            width: 100%;
        `}

    ${media.phone`
        border-radius: 0;
        width: 100%;
    `}
`;

export const ModalTitle = styled.h4`
    max-width: 350px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    ${({ theme }) => theme.typography.semiHeading};
    margin-bottom: 0;
`;

export const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    transition: opacity 150ms;

    &:hover {
        opacity: 0.5;
    }
`;

export const ModalFooter = styled.div`
    width: 100%;
    display: flex;
`;
