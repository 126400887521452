import React from 'react';

const SuccessIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="32" cy="32" r="32" fill="currentColor" fillOpacity="0.25" />
        <path
            d="M26.1818 40.3171L18.5455 32.7947L16 35.3022L26.1818 45.332L48 23.8395L45.4545 21.332L26.1818 40.3171Z"
            fill="currentColor"
        />
    </svg>
);

export default SuccessIcon;
