import { createEvent, createStore } from 'effector';
import { formEvents } from '../form';

const onGreetingEnd = createEvent<boolean>();
const hideForm = createEvent();
const hideWelcomeModal = createEvent();
const onWelcomeHide = createEvent();
const setIsFormAuthTriggered = createEvent<boolean>();

const setIsFormHidden = createEvent<boolean>();
const setIsReadyToLogin = createEvent<boolean>();
const setIsWelcomeShown = createEvent<boolean>();
const setIsProfileShown = createEvent<boolean>();

const isReadyToLogin = createStore<boolean>(false).on(setIsReadyToLogin, (_, newState) => newState);
const isFormHidden = createStore<boolean>(false).on(setIsFormHidden, (_, newState) => newState);
const isWelcomeShown = createStore<boolean>(false);
const isProfileShown = createStore<boolean>(false).on(setIsProfileShown, (_, newState) => newState);
const isFormAuthTriggered = createStore<boolean>(false)
    .on(formEvents.formSubmitted, () => true)
    .on(setIsFormAuthTriggered, (_, newState) => newState);

export const greetingStores = {
    isReadyToLogin,
    isFormHidden,
    isWelcomeShown,
    isProfileShown,
    isFormAuthTriggered
};

export const greetingEvents = {
    setIsReadyToLogin,
    onGreetingEnd,
    hideForm,
    setIsFormHidden,
    setIsWelcomeShown,
    hideWelcomeModal,
    setIsProfileShown,
    onWelcomeHide,
    setIsFormAuthTriggered
};
