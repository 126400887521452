import React, { useState, useEffect, useRef } from 'react';
import { CloseIcon } from '../Icons';
import { Input } from './Input';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { DEFAULT_MIN_INPUT_VISIBLE_WIDTH } from './constants';
import { TagsInputProps } from './types';
import { Wrapper, Subject, CloseWrapper, Container, ManyItems, ErrorStyled } from './styles';

export const TagsInput: React.FC<TagsInputProps> = ({
    tags,
    addNewTag,
    onTagRemove,
    onAllTagsRemove,
    label,
    overflowingText,
    overflowingTooltip,
    helperText,
    status,
    paddingRight
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const itemsRef = useRef<HTMLDivElement | null>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [newTagValue, setNewTagValue] = useState('');

    useEffect(() => {
        const containerWidth = containerRef.current?.offsetWidth ?? 0;
        const itemsWidth = itemsRef.current?.scrollWidth ?? 0;
        const overflowing = itemsWidth > containerWidth - DEFAULT_MIN_INPUT_VISIBLE_WIDTH;

        containerRef.current && (containerRef.current.scrollLeft = 0);

        setOverflowing(overflowing);
    }, [tags, paddingRight]);

    const ManyItemsContent = () => (
        <ManyItems ref={itemsRef} overflowing={overflowing}>
            {tags?.map((item, idx) => (
                <Tooltip text={item.tooltipContent} placement="bottom" width="fit-content" key={idx}>
                    <Subject>
                        <Text font="note" textOverflow="ellipsis">
                            {item.displayContent}
                        </Text>
                        <CloseWrapper onClick={() => onTagRemove(item)}>
                            <CloseIcon />
                        </CloseWrapper>
                    </Subject>
                </Tooltip>
            ))}
        </ManyItems>
    );

    return (
        <Wrapper paddingRight={paddingRight}>
            <Container ref={containerRef} blockScroll={!newTagValue}>
                {tags.length > 0 && overflowing && (
                    <Tooltip text={overflowingTooltip ?? ''} placement="bottom" width="fit-content">
                        <Subject>
                            <Text font="note" textOverflow="ellipsis">
                                {overflowingText}
                            </Text>

                            <CloseWrapper onClick={onAllTagsRemove}>
                                <CloseIcon />
                            </CloseWrapper>
                        </Subject>
                    </Tooltip>
                )}

                {!overflowing && <ManyItemsContent />}
                <Input
                    addNewTag={addNewTag}
                    filled={!!tags.length}
                    label={label}
                    status={status}
                    newTagValue={newTagValue}
                    setNewTagValue={setNewTagValue}
                />
                {overflowing && <ManyItemsContent />}
            </Container>
            {status === 'error' && helperText && <ErrorStyled>{helperText}</ErrorStyled>}
        </Wrapper>
    );
};
