import { GenericSVGGraphic } from '../types/plugin';

/**
 * Dynamic source URLs for the corresponding vehicle types.
 */
export const urlOfRepoForSVG = 'https://storage.yandexcloud.net/grapa-data/svg-FastTrack';

// Vehicle type part to be replaced in URL
export const vehicleTypePart = '$VEHICLE_TYPE$';

export const dynamicGenericSVGGraphics: GenericSVGGraphic[] = [
    {
        graphicId: 'default',
        label: 'Default Mode',
        sourceUrl: `${urlOfRepoForSVG}/fastTrack.svg`
    },
    {
        graphicId: 'frontDriver',
        label: 'Front Driver',
        sourceUrl: `${urlOfRepoForSVG}/FT1_${vehicleTypePart}.svg`
    },
    {
        graphicId: 'rearPassenger',
        label: 'Rear Passenger',
        sourceUrl: `${urlOfRepoForSVG}/FT2_${vehicleTypePart}.svg`
    },
    {
        graphicId: 'rear',
        label: 'Rear',
        sourceUrl: `${urlOfRepoForSVG}/FT4_${vehicleTypePart}.svg`
    },
    {
        graphicId: 'front',
        label: 'Front',
        sourceUrl: `${urlOfRepoForSVG}/FT3_${vehicleTypePart}.svg`
    }
];
