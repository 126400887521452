import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from './Card';
import { PriceContainer, Field, Title, Content, TextField } from './styles';
import { useStore } from 'effector-react';
import { calculationEvents, calculationStores } from '../../stores/calculation';
import { createDataForPriceTab } from '../../utils/createDataForPriceTab';
import { createDataForCards } from '../../utils/createDataForCards';
import { sharedDossierStores } from '@dat/shared-models/contract/Dossier';

export const Price: FC = () => {
    const { t } = useTranslation();
    const fieldValues = useStore(calculationStores.fieldValues);
    const cardValues = useStore(calculationStores.cardValues);
    const dossier = useStore(sharedDossierStores.dossier);

    useEffect(() => {
        calculationEvents.setFieldValues(createDataForPriceTab(t, dossier));
        calculationEvents.setCardValues(createDataForCards(t, dossier));
    }, [dossier, t]);

    return (
        <PriceContainer>
            <Title>{t('calculateContractTabs.price')}</Title>
            <Content>
                {fieldValues.map((field, idx) => (
                    <Field key={idx}>
                        <TextField styles={field.styles}>{field.title}</TextField>
                        <TextField styles={field.styles} content="value">
                            {field.value}
                        </TextField>
                    </Field>
                ))}
                {cardValues.map((item, idx) => (
                    <Card isTotal={idx === 0} key={idx} cardTitle={item.cardTitle} cardValues={item.values} />
                ))}
            </Content>
        </PriceContainer>
    );
};
