import { sample } from 'effector';

import { componentEvents } from '../component';
import { i18n, sharedI18NEffects } from '@dat/shared-models/i18n';
import { INITIAL_LOCALE } from '@dat/shared-models/i18n/constants';
import { PayloadForAddTranslationsToI18Next } from '@dat/shared-models/i18n/types';

const { pluginInitialized } = componentEvents;

sample({
    source: pluginInitialized,
    fn: ({ name, translations }) => ({ namespace: name, translations } as PayloadForAddTranslationsToI18Next),
    filter: ({ name, translations }) => !i18n.hasResourceBundle(INITIAL_LOCALE, name) && !!translations,
    target: sharedI18NEffects.addTranslationsToI18NextFx
});
