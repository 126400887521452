import styled from 'styled-components/macro';
import { InputField } from '@wedat/ui-kit/Formik';
import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    ${media.phoneBig`
        height: 100%;
        justify-content: space-between;
    `}
`;

export const BlocksWrapper = styled.div`
    display: flex;

    ${media.phoneBig`
        flex-direction: column;
    `}
`;

export const TimeBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    ${media.phoneBig`
        margin-right: 0;
        margin-bottom: 20px;
    `}
`;

export const TypeBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputFieldStyled = styled(InputField)`
    min-width: unset;
`;

export const InputFieldWrapper = styled.div`
    width: 220px;
    margin-top: 20px;
    position: relative;

    ${media.phoneBig`
        width: 100%;
    `}
`;

export const SemiHeadingWrapper = styled.div`
    margin-bottom: 12px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    padding-bottom: 20px;

    ${media.phoneBig`
        flex-direction: column;
    `}
`;

export const ButtonStyled = styled(Button)`
    width: 100px;
    border: solid 2px ${({ theme }) => theme.colors.blueDark};

    ${media.phoneBig`
        width: 100%;
        margin-bottom: 10px;
    `}
`;

export const CancelButton = styled(Button)`
    width: 140px;
    margin-left: 20px;
    color: ${({ theme }) => theme.colors.blueDark};
    border-color: ${({ theme }) => theme.colors.blueDark};
    background-color: ${({ theme }) => theme.colors.white};

    ${media.phoneBig`
        width: 100%;
        margin-left: 0;
    `}
`;

export const InputText = styled(Text)`
    position: absolute;
    right: 20px;
    bottom: 13px;

    ${media.phoneBig`
        left: unset;
        right: 16px;
    `}
`;
export const AdditionalWrapper = styled.div``;
