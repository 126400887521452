import { pluginOptionsModel } from './pluginOptionsModel';
import { requestCredentialsModel } from './requestCredentialsModel';
import { fastTrackElementModel } from './fastTrackElementModel';
import { combine, createEffect, createEvent, forward, guard, sample } from 'effector';
import { contractFieldsModel } from './contractFields';
import API2DAT from '@dat/api2dat';
import { CreateContract } from '@dat/api2dat/types/createContractTypes';
import { Dossier } from '@dat/api2dat/types/contractTypes';
import { getUniqDocNumber } from '../utils/uniqId';
import { predefinedVehicleSelectionModel } from './predefinedVehicleSelection';
import { genericSVGGraphicModel } from './genericSVGGraphicModel';
import { vehicleSVGModel } from './vehicleSVGModel';
import { subscribeEffectsToToast } from '@dat/smart-components/Toast/store';
import { i18n } from '../i18n';
import { updateI18n } from '../utils/locale/updateI18n';
import { defaultNS } from '../utils/locale/createI18nInstance';
import { commonModel } from './commonModel';

// const initPlugin = createEffect({
//     async handler(pluginOptions: PluginOptions | null) {
//         if (!pluginOptions) return;

//         // pluginOptions.datServicesUrls && setDatServicesUrls(pluginOptions.datServicesUrls);

//         if (pluginOptions.settings?.contractId) {
//             // fastTrackElementModel.events.setContractId(pluginOptions.settings?.contractId);
//             // fastTrackElementModel.effects.getFastTrackConfiguration();
//             // fastTrackElementModel.effects.getDamageFromClaim();
//             // if (pluginOptions.settings?.showResultTable) fastTrackElementModel.effects.getContract();
//         } else {
//             pluginOptions.settings?.fastTrackConfiguration &&
//                 fastTrackElementModel.events.setFastTrackConfiguration(pluginOptions.settings?.fastTrackConfiguration);
//         }

//         fastTrackElementModel.events.setFirstFastTrackSVG();
//     }
// });

pluginOptionsModel.stores.pluginOptions
    .map(pluginOptions => pluginOptions?.settings?.fastTrackConfiguration)
    .watch(
        fastTrackConfiguration =>
            !!fastTrackConfiguration && fastTrackElementModel.events.setFastTrackConfiguration(fastTrackConfiguration)
    );

pluginOptionsModel.stores.pluginOptions.watch(() => fastTrackElementModel.events.setFirstFastTrackSVG());

const isCredentialPresent = requestCredentialsModel.stores.requestCredentials.map(
    cred =>
        ('dat-authorizationtoken' in cred && !!cred['dat-authorizationtoken']) ||
        ('customerNumber' in cred && !!cred['customerNumber'])
);

const contractId = pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.contractId);

combine(contractId, isCredentialPresent).watch(([contractId, isCredentialPresent]) => {
    if (!contractId || !isCredentialPresent) return;

    fastTrackElementModel.events.setContractId(contractId);
    fastTrackElementModel.effects.getFastTrackConfiguration();
    fastTrackElementModel.effects.getDamageFromClaim();

    // const pluginOptions = pluginOptionsModel.stores.pluginOptions.getState();
    // if (pluginOptions?.settings?.showResultTable)
    fastTrackElementModel.effects.getContractFx();
});

// pluginOptionsModel.stores.pluginOptions.watch(pluginOptions => initPlugin(pluginOptions));

const clear = createEvent<undefined>();
clear.watch(_ => {
    fastTrackElementModel.events.clearFastTrackDamage();
    const pluginOptions = pluginOptionsModel.stores.pluginOptions.getState();
    const createNewClaimAvailable = pluginOptions?.settings?.createNewClaimAvailable;
    if (createNewClaimAvailable) {
        contractFieldsModel.events.clearContractFields();
        fastTrackElementModel.events.setContractId(0);
        fastTrackElementModel.events.clearContract();
    }
});

const calculate = createEffect({
    async handler() {
        const contract = fastTrackElementModel.stores.$contract.getState();

        const pluginOptions = pluginOptionsModel.stores.pluginOptions.getState();
        if (!pluginOptions) return;
        const { settings } = pluginOptions;
        const { createNewClaimAvailable } = settings || {};

        // let contractId = settings?.contractId;
        let contractId = fastTrackElementModel.stores.$contractId.getState();

        // const contractId = $contractId.getState() || $contract.getState()?.Dossier.DossierId;

        if (createNewClaimAvailable && !contractId && !contract && settings?.locale && settings?.createContractInit) {
            const credentials = requestCredentialsModel.stores.requestCredentials.getState();
            if (!credentials) return;

            // determine vehicle
            const vehicleSelectionModel = predefinedVehicleSelectionModel.stores.$vehicleSelectionModel.getState();
            if (!vehicleSelectionModel) return;
            const vehicle = await API2DAT.vehicleIdentificationService.getVehicleIdentification(credentials, {
                request: {
                    locale: settings.locale,
                    restriction: 'REPAIR',
                    datECode: vehicleSelectionModel?.datECode,
                    constructionTime: vehicleSelectionModel.constructionTime
                }
            });

            // create new dossier
            const contractFields = contractFieldsModel.stores.$contractFields.getState();
            const dossier: Dossier = {
                Name: getUniqDocNumber(),
                Country: settings?.createContractInit?.Dossier.Country, // 'RU',
                Language: settings?.createContractInit?.Dossier.Language, //'ru',
                Currency: settings?.createContractInit?.Dossier.Currency, //'RUB',
                TradingData: {
                    Owner: {
                        // LastName: 'Петров И.И.',
                        PhoneMobile: contractFields.phone //'+7-555-55-55'
                    }
                },
                Vehicle: {
                    ...vehicle,
                    // VehicleIdentNumber: 'TestVin654321',
                    // MileageOdometer: 99999,
                    RegistrationData: {
                        LicenseNumber: contractFields.plateNumber || '' //'A001AA77'
                    }
                    // InitialRegistration: '2020-11-25T14:54:51.326Z'
                }
                // RepairOrder: {
                //     DamageDate: '2020-05-11T21:00:00.000Z'
                // }
            };
            const params: CreateContract = {
                // ...settings?.createContractInit,
                contractType: settings?.createContractInit?.contractType, // 'vro_calculation',
                networkType: settings?.createContractInit?.networkType, //'DATRUSSIA',
                templateId: settings?.createContractInit?.templateId, // 118941,
                Dossier: dossier
                // templateData: {
                //     entry: [
                //         { key: 'referenceNumberInsurer', value: 'referenceNumberInsurer738' },
                //         { key: 'economicRegion', value: '60' },
                //         { key: 'ownerFullName', value: 'Николаев Е.Е.' }
                //     ]
                // }
            };

            // save dossier to claim
            contractId = await API2DAT.myClaimExternalService.createOrUpdateContract(credentials, params);

            fastTrackElementModel.events.setContractId(contractId);
        }

        await fastTrackElementModel.effects.saveFTClaim();
        await fastTrackElementModel.effects.calculateFastTrack();
        await fastTrackElementModel.effects.getContractFx();
        fastTrackElementModel.events.setSelectedElementsModified(false);
        predefinedVehicleSelectionModel.events.setVehicleSelectionModified(false);
    }
});

sample({
    source: pluginOptionsModel.stores.pluginOptions,
    clock: [
        fastTrackElementModel.events.setFastTrackDamage,
        fastTrackElementModel.events.delFastTrackDamage,
        fastTrackElementModel.events.clearFastTrackDamage
    ],
    filter: pluginOptions => !!pluginOptions?.settings?.autoCalculation,
    target: calculate
});

sample({
    source: combine({
        contract: fastTrackElementModel.stores.$contract,
        pluginOptions: pluginOptionsModel.stores.pluginOptions
    }),
    clock: calculate.done
}).watch(({ contract, pluginOptions }) => pluginOptions?.onCalculate && pluginOptions.onCalculate(contract));

sample({
    source: pluginOptionsModel.stores.pluginOptions,
    clock: calculate.failData,
    fn: (pluginOptions, error) => ({ pluginOptions, error })
}).watch(({ pluginOptions, error }) => pluginOptions?.onError && pluginOptions.onError(error));

guard({
    clock: [
        fastTrackElementModel.events.setFastTrackDamage,
        fastTrackElementModel.events.delFastTrackDamage,
        fastTrackElementModel.events.clearFastTrackDamage
    ],
    source: fastTrackElementModel.stores.$fastTrackElements.map(fastElements => fastElements.selectedElements),
    filter: pluginOptionsModel.stores.pluginOptions.map(
        pluginOptions => !!pluginOptions?.settings?.saveFTDamageOnChange
    ),
    target: fastTrackElementModel.events.saveFTClaimLastWithWaiting
});

sample({
    source: { vehicleSelection: predefinedVehicleSelectionModel.stores.$vehicleSelectionModel },
    fn: ({ vehicleSelection }) => vehicleSelection?.datECode,
    target: vehicleSVGModel.effects.getVehicleTypeFX
});

sample({
    source: { datECode: fastTrackElementModel.stores.$datECode },
    fn: ({ datECode }) => datECode,
    target: vehicleSVGModel.effects.getVehicleTypeFX
});

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.genericSVGGraphics),
    clock: guard({
        source: pluginOptionsModel.stores.pluginOptions.map(
            pluginOptions => pluginOptions?.settings?.genericSVGGraphics
        ),
        filter: genericSVGGraphics => !!genericSVGGraphics?.length
    }),
    target: genericSVGGraphicModel.events.initGenericGraphicFirst
});

forward({ from: genericSVGGraphicModel.events.update, to: fastTrackElementModel.events.setFirstFastTrackSVG });

subscribeEffectsToToast([fastTrackElementModel.effects.saveFTClaim]);

sample({
    source: commonModel.stores.isMobile,
    fn: isMobile => !isMobile,
    target: commonModel.events.setSummaryPanelOpened
});

pluginOptionsModel.stores.localesParams.watch(localesParams => {
    if (!localesParams?.language) return;
    const locale = localesParams?.locale?.language + '-' + localesParams?.locale?.country;
    i18n.changeLanguage(locale);
    updateI18n(i18n, locale, [defaultNS]);
});

export const fastTrackInterfaceModel = {
    events: {
        clear
    },
    effects: {
        calculate
        // initPlugin
    }
};
