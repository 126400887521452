import React from 'react';
import { createEffect, createEvent, createStore, sample, attach, restore } from 'effector';
import { toast } from 'react-toastify';

import { NotificationObject, NotificationParams } from './types';
import { EffectOrReEffect } from '../../core/types/effector';
import { ToastContent } from './ToastContent';

const showToastFx = createEffect(({ title, message, status = 'info', toastId }: NotificationObject) => {
    toast[status](<ToastContent title={title} message={message} status={status} />, {
        draggable: true,
        toastId: toastId || String(Math.random()),
        autoClose: false
    });
});

const showInfoToastFx = attach({
    effect: showToastFx,
    mapParams: ({
        message,
        title = {
            namespace: 'ui-kit',
            key: 'infoModalTitle'
        },
        toastId
    }: NotificationParams): NotificationObject => ({
        title,
        message,
        status: 'info',
        toastId
    })
});

const showErrorToastFx = attach({
    effect: showToastFx,
    mapParams: ({
        message,
        title = {
            namespace: 'ui-kit',
            key: 'errorModalTitle'
        },
        toastId
    }: NotificationParams): NotificationObject => ({
        title,
        message,
        status: 'error',
        toastId
    })
});

const showWarningToastFx = attach({
    effect: showToastFx,
    mapParams: (message: NotificationObject['message']) => ({
        title: {
            namespace: 'ui-kit' as const,
            key: 'warningModalTitle'
        },
        message,
        status: 'warn' as const
    })
});
const showSuccessToastFx = attach({
    effect: showToastFx,
    mapParams: (message: NotificationObject['message']) => ({
        title: {
            namespace: 'ui-kit' as const,
            key: 'successModalTitle'
        },
        message,
        status: 'success' as const
    })
});

const setRenderedToastId = createEvent<number>();
const renderedToastId = restore(setRenderedToastId, null);

const subscribedEffects = createStore<EffectOrReEffect<any, any>[]>([]);
const subscribeEffectsToToast = createEvent<
    EffectOrReEffect<any, any>[] | Record<string, EffectOrReEffect<any, any>>
>();
const unsubscribedEffectsReceived = sample({
    clock: subscribeEffectsToToast,
    source: subscribedEffects,
    fn: (subscribedEffects, effects) => {
        if (!Array.isArray(effects)) {
            effects = Object.values(effects);
        }

        return effects.filter(effect => !subscribedEffects.includes(effect));
    }
});

subscribedEffects.on(unsubscribedEffectsReceived, (currentEffects, newEffects) => [...currentEffects, ...newEffects]);

unsubscribedEffectsReceived.watch(effects => {
    effects.forEach(effect => {
        effect.failData.watch(error => {
            if (!error?.message.includes('TAKE_LAST')) {
                toastEffects.showErrorToastFx({
                    message: {
                        namespace: 'ui-kit',
                        key: error?.message || 'defaultErrorMessage'
                    }
                });
            }
        });
    });
});

export const toastStores = {
    renderedToastId
};

export const toastEvents = {
    setRenderedToastId
};

//
/*** Export ***/
//
export const toastEffects = {
    showToastFx,
    showErrorToastFx,
    showInfoToastFx,
    showSuccessToastFx,
    showWarningToastFx
};

// export { createNotifyingEffect } from './createNotifyingEffect';
export { subscribeEffectsToToast };
