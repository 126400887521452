import { TFunction } from 'react-i18next';
import { NumberFormatWrapper } from '../components/NumberFormatWrapper';

export const createDataForCards = (t: TFunction, dossier: DAT2.Dossier | null) => {
    const currency = dossier?.Currency;

    const totalSparePartsCosts = dossier?.RepairCalculation?.CalculationSummary?.SparePartsCosts?.TotalSum;

    const totalBodyWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Bodywork?.Price;

    const totalElectricsWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Electric?.Price;

    const totalMechanicsWorksPrice = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Mechanic?.Price;

    const totalWorkCosts = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.TotalSum;

    const totalPaintWorkCosts = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.TotalSum;

    const paintMaterialPrice = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Material?.TotalSum;

    const totalDeductiblePrice = dossier?.RepairCalculation?.CalculationSummary?.TotalGrossDeductible;

    const amountPay = dossier?.RepairCalculation?.CalcResultCommon?.RepairCalculationSummary?.AmountCustomer;

    const insurancePay = dossier?.RepairCalculation?.CalcResultCommon?.RepairCalculationSummary?.AmountInsurance;

    const bodyWorkTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Bodywork?.Units;

    const electricTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Electric?.Units;

    const mechanicTime = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.Mechanic?.Units;

    const totalLabourTimeSpent = (bodyWorkTime || 0) + (electricTime || 0) + (mechanicTime || 0);

    const totalLacquerTimeSpent = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.Wage?.Units;

    const currencyValue = currency ?? '';

    return [
        {
            cardTitle: t('dataForCards.totalCosts(including VAT)'),
            values: [
                {
                    title: t('dataForCards.totalCosts(including VAT)'),
                    value: <NumberFormatWrapper value={totalSparePartsCosts}>{currencyValue}</NumberFormatWrapper>
                },
                { title: t('dataForCards.smallParts[PERCENT_OF_PARTS]'), value: '-' },
                {
                    title: t('dataForCards.sparePartsTotal'),
                    value: <NumberFormatWrapper value={totalSparePartsCosts}>{currencyValue}</NumberFormatWrapper>,
                    styles: {
                        fontWeight: 700,
                        fontSize: '15px'
                    }
                }
            ]
        },
        {
            cardTitle: t('dataForCards.labour'),
            values: [
                {
                    title: t('dataForCards.timeSpent'),
                    value: (
                        <NumberFormatWrapper value={totalLabourTimeSpent}>{t('dataForCards.hour')}</NumberFormatWrapper>
                    )
                },
                {
                    title: t('dataForCards.body'),
                    value: <NumberFormatWrapper value={totalBodyWorksPrice}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.electrics'),
                    value: <NumberFormatWrapper value={totalElectricsWorksPrice}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.mechanics'),
                    value: <NumberFormatWrapper value={totalMechanicsWorksPrice}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.totalWorkCosts'),
                    value: <NumberFormatWrapper value={totalWorkCosts}>{currencyValue}</NumberFormatWrapper>,
                    styles: {
                        fontWeight: 700,
                        fontSize: '15px'
                    }
                }
            ]
        },
        {
            cardTitle: t('dataForCards.paintwork'),
            values: [
                {
                    title: t('dataForCards.timeSpent'),
                    value: (
                        <NumberFormatWrapper value={totalLacquerTimeSpent}>
                            {t('dataForCards.hour')}
                        </NumberFormatWrapper>
                    )
                },
                {
                    title: t('dataForCards.labourCostsForPaintwork'),
                    value: <NumberFormatWrapper value={totalPaintWorkCosts}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.paintMaterial'),
                    value: <NumberFormatWrapper value={paintMaterialPrice}>{currencyValue}</NumberFormatWrapper>,
                    styles: {
                        fontWeight: 700,
                        fontSize: '15px'
                    }
                }
            ]
        },
        {
            cardTitle: t('dataForCards.deductible'),
            values: [
                {
                    title: t('dataForCards.deductible'),
                    value: <NumberFormatWrapper value={totalDeductiblePrice}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.payebleByOwner'),
                    value: <NumberFormatWrapper value={amountPay}>{currencyValue}</NumberFormatWrapper>
                },
                {
                    title: t('dataForCards.payableByInsurenceCom'),
                    value: <NumberFormatWrapper value={insurancePay}>{currencyValue}</NumberFormatWrapper>,
                    styles: {
                        fontWeight: 700,
                        fontSize: '15px'
                    }
                }
            ]
        }
    ];
};
