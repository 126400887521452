import React from 'react';
import { useStore } from 'effector-react';
import { MenuItem } from './types';
import { Header as HeaderComponent } from '@dat/smart-components/Header';
import { Button, Item, StyledText, NotificationsStyled, ItemLast, StyledTooltip } from './styles';
import { contractsListEffects } from '../../stores/contractsList';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { NewOrder } from '../NewOrder';
import { containedPluginsStores } from '../../stores/invoices';
import { pluginStores } from '../../stores/plugin';
import { ImportIni } from '../ImportIni';
import { Invoice } from '../Invoice';
import { ProfileAddressBook } from '../ProfileAdressBook';
import { SchedulerMenu } from '@dat/claim-management/src/components/SchedulerMenu';
import { efficiencyEvents } from '../../stores/efficiency';
import { useTranslation } from 'react-i18next';

interface Props {
    fixed?: boolean;
}

const SchedulerMenuWrapper = () => (
    <ItemLast>
        <SchedulerMenu />
    </ItemLast>
);

export const Header: React.FC<Props> = props => {
    const isTabletSmall = useMedia(sizes.tabletSmall);
    const importIni = useStore(pluginStores.pluginOptions)?.settings?.importIni;
    const isInvoiceAvailable = useStore(containedPluginsStores.invoiceOptions);
    const menuItems: MenuItem[] = [];

    const { t } = useTranslation();

    const notificationButton = (
        <StyledTooltip text={t('header.notification')} width="auto" placement="top-start">
            <NotificationsStyled />
        </StyledTooltip>
    );

    if (importIni) {
        menuItems.push({
            component: ImportIni
        });
    }

    if (isInvoiceAvailable) {
        menuItems.push({
            component: Invoice
        });
    }

    if (!isTabletSmall) {
        menuItems.push({
            component: NewOrder
        });
        menuItems.push({
            component: ProfileAddressBook,
            isIcon: true,
            withTooltip: true
        });
    }
    menuItems.push({
        component: SchedulerMenuWrapper,
        isIcon: true,
        withTooltip: true
    });

    return (
        <>
            <HeaderComponent
                additionalButtonsChildren={notificationButton}
                withReloader={!isTabletSmall}
                onReload={() => {
                    contractsListEffects.reloadContractsFx();
                    efficiencyEvents.reloadEfficiencyMetrics();
                }}
                rightContent={menuItems.map((item, index) => {
                    if (item.component) {
                        const Component = item.component;

                        if (item.withTooltip) {
                            return (
                                <Item key={index} isIcon={item.isIcon}>
                                    <StyledTooltip
                                        text={t(`header.${item.component.name}`)}
                                        width="auto"
                                        placement="top-start"
                                    >
                                        <Component />
                                    </StyledTooltip>
                                </Item>
                            );
                        } else {
                            return (
                                <Item key={index} isIcon={item.isIcon}>
                                    <Component />
                                </Item>
                            );
                        }
                    }
                    if (!item?.text) return null;
                    return (
                        <Item key={index}>
                            <Button
                                type="button"
                                onClick={() => {
                                    item?.onClick?.();
                                }}
                            >
                                <StyledText fontWeight={500} textTransform="capitalize">
                                    {item.text}
                                </StyledText>
                            </Button>
                        </Item>
                    );
                })}
                noHideContent
                {...props}
            />
        </>
    );
};
