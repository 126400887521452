import styled, { css } from 'styled-components/macro';

interface PhotoProps {
    notMaskDescription?: boolean;
    portrait?: boolean;
}

export const Description = styled.div`
    margin-right: 16px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.gray_300};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`;

export const PhotoStyled = styled.div<PhotoProps>`
    position: absolute;
    left: 50%;
    bottom: 30px;
    width: calc(100% - 80px);
    transform: translateX(-50%);

    flex: 1 1 auto;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    z-index: 3;

    ${({ portrait }) =>
        portrait &&
        css`
            flex-direction: column;

            ${Description} {
                margin-bottom: 40px;
                margin-right: 0;
            }
        `}
`;

export const PhotoBtnWrapper = styled.div`
    display: flex;
    position: relative;
`;
