import * as R from 'ramda';
import { spreadAllImagesToAspects } from '../utils/spreadAllImagesToAspects';
import { AllVehiclesImages, PayloadForAddVehicleImages, PayloadForClearVehiclesImages } from '../types';

// TODO: useless code, does nothing
export const addVehicleImages = (
    allVehiclesImages: AllVehiclesImages,
    { datECode, aspect, images }: PayloadForAddVehicleImages
) => {
    if (allVehiclesImages[datECode]?.[aspect]) {
        return allVehiclesImages;
    }

    if (aspect === 'ALL') {
        return {
            ...allVehiclesImages,
            [datECode]: spreadAllImagesToAspects(images)
        };
    }

    return R.set(R.lensPath([datECode, aspect]), images, allVehiclesImages);
};

export const clearVehiclesImages = (
    allVehiclesImages: AllVehiclesImages,
    { persist = [] }: PayloadForClearVehiclesImages
) => R.pick(persist, allVehiclesImages);
