import { forward, sample } from 'effector';

import { equipmentEvents, equipmentStores } from './index';
import { pluginStores } from '../plugin';

const { newExistingTransferEquipmentIdsReceived } = equipmentEvents;
const { existingTransferEquipmentIds } = equipmentStores;

const { pluginOptions, contractExistingEquipmentIds, contractExistingEquipmentIdsByVin } = pluginStores;

forward({
    from: contractExistingEquipmentIdsByVin,
    to: existingTransferEquipmentIds
});

forward({
    from: contractExistingEquipmentIds,
    to: existingTransferEquipmentIds
});

sample({
    clock: newExistingTransferEquipmentIdsReceived,
    source: { pluginOptions, contractExistingEquipmentIds },
    filter: ({ pluginOptions, contractExistingEquipmentIds }) =>
        !contractExistingEquipmentIds.length && !!pluginOptions?.isComponent,
    fn: (_, data) => data,
    target: existingTransferEquipmentIds
});
