import React, { useMemo } from 'react';

import { Content } from '../styles';

import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { dossierStores } from '../../../../stores/dossier';
import { decreaseTypeSelectOptions } from '../constants';
import { getEquipmentPositionPropertyPath } from '../utils';

import { DatNumberDisplayField } from '../../../DatNumberDisplayField';

import { Table } from '@wedat/ui-kit/components/Table';
import { EvaluationNumberInputField } from '../../../evaluationFields/Input';
import { EvaluationSelectField } from '../../../evaluationFields/Select';

interface Props {
    isMobile: boolean;
}

export const SingleValueValuation: React.FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();
    const specialEquipmentPositions = useStore(dossierStores.specialEquipmentPositions);

    const columns = useMemo(
        () => [
            {
                Header: t('equipmentValuation.Optional equipment'),
                accessor: 'col1'
            },
            {
                Header: t('equipmentValuation.Original price'),
                accessor: 'col2'
            },
            {
                Header: t('equipmentValuation.Devaluation'),
                accessor: 'col3'
            },
            {
                Header: t('DAT'),
                accessor: 'col4'
            },
            {
                Header: t('Manually'),
                accessor: 'col5'
            }
        ],
        [t]
    );

    const data = useMemo(
        () =>
            specialEquipmentPositions.map((position, index) => ({
                col1: position.Description,
                col2:
                    position.ValuationControlType === 'no price' ? (
                        <EvaluationNumberInputField
                            defaultValue={1}
                            name={getEquipmentPositionPropertyPath({
                                propertyName: 'OriginalPrice',
                                index
                            })}
                            placeholder={isMobile ? 'Manually' : ''}
                        />
                    ) : (
                        <DatNumberDisplayField
                            propertyPath={getEquipmentPositionPropertyPath({
                                propertyName: 'OriginalPrice',
                                index
                            })}
                        />
                    ),
                col3: (
                    <EvaluationSelectField
                        name={getEquipmentPositionPropertyPath({
                            propertyName: 'DecreaseType',
                            index
                        })}
                        valueKey="key"
                        valueType="string"
                        options={decreaseTypeSelectOptions}
                    />
                ),
                col4: (
                    <DatNumberDisplayField
                        propertyPath={getEquipmentPositionPropertyPath({
                            propertyName: 'ResidualValue',
                            index
                        })}
                    />
                ),
                col5: (
                    <EvaluationNumberInputField
                        name={getEquipmentPositionPropertyPath({
                            propertyName: 'ResidualValue',
                            index
                        })}
                        placeholder={isMobile ? 'Manually' : ''}
                    />
                )
            })),
        [isMobile, specialEquipmentPositions]
    );

    if (specialEquipmentPositions.length === 0) {
        return null;
    }

    return (
        <Content>
            <Table columns={columns} data={data} breakpoint="laptop" mobileGridColAmount={1} />
        </Content>
    );
};
