import { getParsedArraySafe } from '@dat/api2/utils';
import { excludeEquipmentWithoutIds } from '../../../utils/excludeEquipmentWithoutIds';

export const extractExistingEquipmentIds = (equipment: DAT2.Equipment | undefined | null) => {
    const seriesEquipment = getParsedArraySafe(equipment?.SeriesEquipment?.EquipmentPosition);
    const specialEquipment = getParsedArraySafe(equipment?.SpecialEquipment?.EquipmentPosition);
    const allEquipment = [...seriesEquipment, ...specialEquipment];
    const equipmentWithIds = excludeEquipmentWithoutIds(allEquipment);

    return equipmentWithIds.map(({ DatEquipmentId }) => DatEquipmentId);
};
