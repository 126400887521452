const listDvnRims: number[] = [
    66531, // Rim  Front axle / steering
    66532, // Rim R Front axle / steering
    76041, // Rim L Rear axle
    76042 // Rim R  Rear axle
];

export function isDVNRims(dvn: number | undefined): boolean {
    if (!dvn) return false;
    return listDvnRims.includes(dvn);
}
