import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';

interface Props {
    onChange?: (values: any) => void;
}

export const FormikController: FC<Props> = ({ onChange }) => {
    const { values, dirty } = useFormikContext();
    const [wasChanged, setWasChanged] = useState(false);

    useEffect(() => {
        if (dirty) {
            setWasChanged(true);
        }
    }, [values, dirty]);

    useEffect(() => {
        if (wasChanged) {
            onChange?.(values);
        }
    }, [onChange, values, wasChanged]);

    return null;
};
