import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TabsHeaderWrapper = styled.div`
    padding: 0 24px;
`;
