import { FC } from 'react';
import { Done } from '@wedat/ui-kit/components/Icons/index';

import {
    StepTitle,
    StepTitleNumber,
    StepperProgressPart,
    StepperProgressBar,
    StepperProgressWrapper,
    TitleBody,
    TitleContainer,
    TitleContainerMedia
} from './styles';

import { StepperProgressProps } from '../../types/config';
import { useTranslation } from 'react-i18next';

export const StepperProgress: FC<StepperProgressProps> = ({
    stepTitles,
    currentStep,
    onProgressBarClick,
    isDone,
    isFilled,
    title
}) => {
    const { t } = useTranslation();
    return (
        <>
            <StepperProgressPart>
                <StepperProgressWrapper>
                    {stepTitles.map((title, index) => (
                        <StepTitle key={`${title}-${index}`}>
                            <StepTitleNumber
                                isActive={index + 1 === currentStep || (index < currentStep - 1 && isDone)}
                                isDoneActive={index + 1 < currentStep}
                                disabled={isFilled}
                                onClick={() => {
                                    onProgressBarClick(index + 1);
                                }}
                            >
                                {index < currentStep - 1 && isDone ? <Done /> : index + 1}
                            </StepTitleNumber>
                            <TitleBody>
                                <TitleContainer> {index + 1 === currentStep && t(`title.${title}`)} </TitleContainer>
                                {index !== stepTitles.length - 1 && (
                                    <StepperProgressBar
                                        isActive={index + 1 === currentStep || (index < currentStep - 1 && isDone)}
                                        isDoneActive={index + 1 < currentStep}
                                    />
                                )}
                            </TitleBody>
                        </StepTitle>
                    ))}
                </StepperProgressWrapper>
                <TitleContainerMedia>{t(`title.${title}`)}</TitleContainerMedia>
            </StepperProgressPart>
        </>
    );
};
