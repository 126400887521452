import React, { useMemo } from 'react';
import { DrawerItem } from './DrawerItem';
import { DrawerProps, FormattedDrawerItem } from './types';
import { Container } from './styles';
import { createDrawerItemsWithOffsets } from './utils/createDrawerItemsWithOffsets';

export const Drawer: React.FC<DrawerProps> = ({ options }) => {
    const { position, fullScreen, halfScreen, items } = options;

    const fomattedItems: FormattedDrawerItem[] = useMemo(() => {
        const halfScreenWidth = halfScreen ? window.innerWidth / 2 : undefined;
        return createDrawerItemsWithOffsets({ items, halfScreenWidth });
    }, [items, halfScreen]);

    return (
        <Container position={position}>
            {fomattedItems.map(item => (
                <DrawerItem
                    {...item}
                    offset={item.offset}
                    zIndex={item.zIndex}
                    position={position}
                    fullScreen={fullScreen}
                    halfScreen={halfScreen}
                />
            ))}
        </Container>
    );
};

export * from './types';
