import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import styled, { css } from 'styled-components/macro';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import { SearchInput } from '@wedat/ui-kit/components/SearchInput';
import { NotFound } from '@dat/smart-components/NotFound';

export const HeaderContent = styled.div``;

export const HeaderTitle = styled(Text)`
    margin-bottom: 4px;
`;
export const HeaderDescription = styled(Text)`
    margin-bottom: 0;
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const Container = styled.div`
    padding: 40px 24px;
    border-top: 2px solid ${({ theme }) => theme.colors.dustBlue['100']};
    overflow: auto;
    height: calc(100% - 90px);

    ${makeCustomScrollBar()}
`;

export const SearchInputStyled = styled(SearchInput)`
    margin-bottom: 8px;
`;

export const NotFoundStyled = styled(NotFound)`
    margin-top: 60px;
`;

export const Buttons = styled.div``;

interface ButtonProps {
    active?: boolean;
}

export const ButtonSubModel = styled.button<ButtonProps>`
    width: 100%;
    height: 48px;
    padding-left: 12px;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.dustBlue['50']};
    }

    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.dustBlue['50']};
        `}

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
    }
`;

export const Footer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-top: 2px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;

export const ButtonStyled = styled(Button)`
    padding-left: 40px;
    padding-right: 40px;
    width: auto;
    &:not(:first-child) {
        margin-left: 10px;
    }
`;
