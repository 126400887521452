import React from 'react';

const Save = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.5793 4.07925L13.9208 0.42075C13.752 0.252 13.545 0.12825 13.32 0.0607499V0H0.72C0.32175 0 0 0.32175 0 0.72V17.28C0 17.6783 0.32175 18 0.72 18H17.28C17.6783 18 18 17.6783 18 17.28V5.09625C18 4.71375 17.8493 4.34925 17.5793 4.07925ZM6.12 1.62H11.88V3.96H6.12V1.62ZM16.38 16.38H1.62V1.62H4.68V4.68C4.68 5.07825 5.00175 5.4 5.4 5.4H12.6C12.9983 5.4 13.32 5.07825 13.32 4.68V2.1105L16.38 5.1705V16.38ZM9 7.425C7.21125 7.425 5.76 8.87625 5.76 10.665C5.76 12.4538 7.21125 13.905 9 13.905C10.7888 13.905 12.24 12.4538 12.24 10.665C12.24 8.87625 10.7888 7.425 9 7.425ZM9 12.465C8.0055 12.465 7.2 11.6595 7.2 10.665C7.2 9.6705 8.0055 8.865 9 8.865C9.9945 8.865 10.8 9.6705 10.8 10.665C10.8 11.6595 9.9945 12.465 9 12.465Z"
            fill="#C9CFDC"
        />
    </svg>
);

export default Save;
