import styled from 'styled-components/macro';

export const StatusFilterWrapper = styled.div`
    position: relative;
    min-width: 260px;
`;

export const SelectMenuWrapper = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    z-index: 1;
    background-color: white;
    padding: 5px;
    width: 100%;
    border-radius: 0 0 15px 15px;
`;

export const OptionWrapper = styled.div`
    padding: 5px;
    display: flex;
    &:hover {
        background: #eee;
        cursor: pointer;
    }
    span {
        margin-left: auto;
    }
`;

export const NoRecords = styled.div`
    text-align: center;
`;
