// PreparedManualPositions

import React, { FC, memo } from 'react';

import { Button, Space, Row, Col, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';

import { EditPreparedMPositions } from './EditPreparedMPositions';
import { preparedManualPositionsModel } from '../../stores/preparedManualPositionsModel';

export const EditPreparedMPositionsView: FC<{}> = memo(
    () => {
        const { t } = useTranslation();

        // const isMobile = useStore(commonModel.stores.isMobile);
        const saveFxPending = useStore(preparedManualPositionsModel.effect.saveFx.pending);

        return (
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px'
                }}
            >
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Space>
                        <Button
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                rightSideDrawerModel.events.backView();
                            }}
                        />
                        <h2 style={{ height: '30px', marginLeft: '8px', marginBottom: '0px' }}>
                            {t('PreparedEditingManualRepairPositionForm.title')}
                        </h2>
                    </Space>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        top: '50px',
                        left: '0px',
                        right: '0px',
                        bottom: '54px'
                    }}
                >
                    <Spin spinning={saveFxPending}>
                        <EditPreparedMPositions />
                    </Spin>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={12}>
                            <Button
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    rightSideDrawerModel.events.clearHistoryAndClose();
                                }}
                            >
                                {t('ManualRepairPositionForm.Cancel')}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={saveFxPending}
                                size="large"
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={async () => {
                                    await preparedManualPositionsModel.effect.saveFx(undefined);
                                    rightSideDrawerModel.events.backView();
                                }}
                            >
                                {t('ManualRepairPositionForm.Confirm')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);
