import { LOCALES } from '@dat/core/constants/i18n';

export const LOCALE_STORAGE_KEY = 'DAT-locale';

const getBrowserLocale = (userLang: string): DAT2.Locale => {
    const browserLocale = (userLang.includes('-') ? userLang : `${userLang}-${userLang.toUpperCase()}`) as DAT2.Locale;

    if (LOCALES.includes(browserLocale)) return browserLocale;

    return 'en-US';
};

export const getLocalStorageLocale = () => localStorage.getItem(LOCALE_STORAGE_KEY) as DAT2.Locale | null;

export const setLocalStorageLocale = (locale: DAT2.Locale) => {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale);
};

export const removeLocalStorageLocale = () => localStorage.removeItem(LOCALE_STORAGE_KEY);

export const getInitialLocale = (): DAT2.Locale => {
    const localeLS = localStorage.getItem(LOCALE_STORAGE_KEY) as DAT2.Locale | null;

    const localeBrowser = getBrowserLocale(navigator.language);

    return localeLS || localeBrowser;
};
