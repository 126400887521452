import { PluginOptions } from './types/plugin';
// import { testCredentials3451429 } from '@dat/api2dat/utils/testCredentials';
// import { testAuthData3451429 } from '@dat/api2dat/constants/testAuthData3451429';

export const defaultOptions: PluginOptions = {
    onCalculate: contract => {
        console.log('onCalculate:');
        console.log(contract);
    },
    onError: error => {
        console.log('onError:');
        console.log(error);
    },

    // credentials: testAuthData3451429,

    // credentialsWithPartnerSignature: {
    //     ...testCredentials3451429
    // },
    // datServicesUrls: {
    //     soapMyClaimExternalServiceUrl: 'https://www.datgroup.com/myClaim/soap/v2/MyClaimExternalService',
    //     // soapMyClaimExternalServiceUrl: 'https://www.dat.de/myClaim/soap/v2/MyClaimExternalService',
    //     restFastTrackServiceUrl: 'https://www.dat.de/myClaim/rest/FastTrackService',
    //     restTokenServiceUrl: 'https://www.dat.de/AuthorizationManager/service--/endpoint/tokenService',
    //     soapVehicleIdentificationServiceUrl: 'https://www.dat.de/myClaim/soap/v2/VehicleIdentificationService',
    //     soapVehicleRepairServiceUrl: 'https://www.dat.de/myClaim/soap/v2/VehicleRepairService'
    // },

    locale: 'en-US',

    settings: {
        debuggingMode: false,
        maxWidth: '900px',
        maxWidthSVG: '900px',
        maxMobileWidth: 862,
        // backgroundColor: 'white',
        backgroundColor: 'rgb(250, 250, 250)',

        // contractId: 13023295,
        // contractId: 16036917,

        saveFTDamageOnChange: false,

        createNewClaimAvailable: true,
        // if createNewClaimAvailable enabled then by 'clear' button you can create clear claim and save it by 'calculate button'
        // in this case showPredefinedVehicleSelection is mandatory

        // parameters use fore Vehicle Identification Service
        locale: {
            attr: {
                country: 'ru',
                datCountryIndicator: 'ru',
                language: 'RU'
            }
        },
        // parameters use fore creation claim
        createContractInit: {
            contractType: 'vro_calculation',
            networkType: 'DATRUSSIA',
            templateId: '128800',
            Dossier: {
                Country: 'RU',
                Language: 'ru',
                Currency: 'RUB'
            }
        },

        // show or not block with fast-track element select menu
        showFastTrackElementsMenu: true,
        fastTrackElementsMenuModal: true,
        fastTrackElementsMenu: [
            {
                menuGroupId: 'glasses',
                menuGroupLabel: 'Glasses',
                menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                elementDatIDs: ['DATID_0043', 'DATID_0044', 'DATID_0047']
            },
            {
                menuGroupId: 'doors',
                menuGroupLabel: 'Doors',
                // menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                elementDatIDs: ['DATID_0041', 'DATID_0042']
            }
        ],

        showSVGFastTrack: true,
        // list of SVG schemes may be just 1 element
        // mainSVGURLs: [
        //     './FastTrackSVG/fastTrack.svg',
        //     // './FastTrackSVG/fastTrack_portrait.svg',

        //     './FastTrackSVG/SW/FT1_SW.svg',
        //     './FastTrackSVG/SW/FT2_SW.svg'
        //     // './FastTrackSVG/SW/FT3_SW.svg',
        //     // './FastTrackSVG/SW/FT4_SW.svg'

        //     // './FastTrackSVG/SUV/FT1_SUV.svg',
        //     // './FastTrackSVG/SUV/FT2_SUV.svg',
        //     // './FastTrackSVG/SUV/FT3_SUV.svg',
        //     // './FastTrackSVG/SUV/FT4_SUV.svg',

        //     // './FastTrackSVG/3_doors/FT1_3_doors.svg',
        //     // './FastTrackSVG/3_doors/FT2_3_doors.svg',
        //     // './FastTrackSVG/3_doors/FT3_3_doors.svg',
        //     // './FastTrackSVG/3_doors/FT4_3_doors.svg',

        //     // './FastTrackSVG/4_doors/FT1_4_doors.svg',
        //     // './FastTrackSVG/4_doors/FT2_4_doors.svg',
        //     // './FastTrackSVG/4_doors/FT3_4_doors.svg',
        //     // './FastTrackSVG/4_doors/FT4_4_doors.svg',

        //     // './FastTrackSVG/5_doors/FT1_5_doors.svg',
        //     // './FastTrackSVG/5_doors/FT2_5_doors.svg',
        //     // './FastTrackSVG/5_doors/FT3_5_doors.svg',
        //     // './FastTrackSVG/5_doors/FT4_5_doors.svg'
        // ],

        // genericSVGGraphics: [
        //     {
        //         graphicId: 'topView',
        //         label: 'Generic top View',
        //         // sourceUrl: 'https://dat.myshelf.info/fast-track/FastTrackSVG/fastTrack.svg'
        //         sourceUrl: 'https://app.datrus.ru/fast-track/FastTrackSVG/fastTrack.svg'
        //     },
        //     {
        //         graphicId: 'frontLeft',
        //         label: 'Generic front left',
        //         // sourceUrl: 'https://dat.myshelf.info/fast-track/FastTrackSVG/SW/FT1_SW.svg'
        //         sourceUrl: 'https://app.datrus.ru/fast-track/FastTrackSVG/SW/FT1_SW.svg'
        //     },
        //     {
        //         graphicId: 'backRight',
        //         label: 'Generic back right',
        //         // sourceUrl: 'https://dat.myshelf.info/fast-track/FastTrackSVG/SW/FT2_SW.svg'
        //         sourceUrl: 'https://app.datrus.ru/fast-track/FastTrackSVG/SW/FT2_SW.svg'
        //     }
        // ],

        showListDamages: true,

        autoCalculation: true,
        showResultTable: true,
        resultFormat: {
            locales: 'de-DE',
            numberFormatOptions: {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2
                // maximumFractionDigits: 0
            }
        }

        //     fastTrackDamages: {
        //         FTGROUP_GLASS: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         FTGROUP_RIMS: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         DATID_WC_FL: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         default: [
        //             {
        //                 id: 'level0',
        //                 label: 'Замена'
        //             },
        //             {
        //                 id: 'level1',
        //                 label: 'Ремонт + Покраска'
        //             },
        //             {
        //                 id: 'level2',
        //                 label: 'Покраска'
        //             }
        //         ],
        //         FTGROUP_TIRES: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         FTGROUP_LIGHTS: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         DATID_WC_RR: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         DATID_WC_RL: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ],
        //         DATID_WC_FR: [
        //             {
        //                 id: 'level3',
        //                 label: 'Замена'
        //             }
        //         ]
        //     }
        // },
    }
};
