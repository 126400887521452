import { DependencyList, useEffect, useLayoutEffect, useState } from 'react';

/*
 * Hack for Grapa - it's purpose is to help run all Grapa's logic without rendering.
 *
 * If `deps` are updated, immediately changes value to `true`
 * and then after full mount changes it back to `false`.
 */
export const useBlink = (deps: DependencyList) => {
    const [blink, setBlink] = useState(false);

    useLayoutEffect(() => {
        setBlink(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useEffect(() => {
        if (blink) {
            setTimeout(() => {
                setBlink(false);
            });
        }
    }, [blink]);

    return blink;
};
