import React from 'react';
import { useStore, useStoreMap } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { attachmentsEffects, attachmentsStores } from '../../../stores/attachments';
import { lightboxEvents } from '../../../stores/lightbox';
import { editorEvents } from '../../../stores/imageEditor';

import { AttachmentsList, Container, Download, AttachmentsHeader, Name } from './styles';
import { UploadButton } from '../UploadButton';
import { Attachment } from '@wedat/ui-kit/components/Attachment';
import { Text } from '@wedat/ui-kit/components/Text';

import { ParsedAttachmentItem } from '../../../types/attachments';
import { triggerFileDownload } from '@dat/core/utils/attachments/triggerFileDownload';
import { sharedAttachmentsEffects } from '@dat/shared-models/contract/Attachments';

interface Props {
    attachments: ParsedAttachmentItem[];
    id?: number;
}

export const Group: React.FC<Props> = ({ id, attachments }) => {
    const groupConfig = useStoreMap({
        store: attachmentsStores.groups,
        keys: [id],
        fn: (groups, [id]) => groups.find(group => group.id === id) || null
    });
    const uploadsRemaining = useStoreMap({
        store: attachmentsStores.remainingUploadsOfGroups,
        keys: [id],
        fn: (remainingUploadsOfGroups, [id]) => remainingUploadsOfGroups[Number(id)] || 0
    });

    const { t } = useTranslation();
    const groupName = groupConfig ? groupConfig.name || 'Unnamed group' : t('other');
    const isMaxLengthReached = uploadsRemaining <= 0;
    const contractId = useStore(attachmentsStores.contractId);

    return (
        <Container>
            <AttachmentsHeader>
                <Name>
                    {groupName} <Text>({attachments.length})</Text>
                </Name>
                {!!groupConfig && !!attachments.length && (
                    <Download
                        height="20"
                        onClick={() => {
                            attachmentsEffects.downloadFolderAsZipFx({ claimId: contractId, folderId: groupConfig.id });
                        }}
                    />
                )}
            </AttachmentsHeader>
            <AttachmentsList>
                {!!groupConfig && !isMaxLengthReached && (
                    <UploadButton
                        groupId={groupConfig.id}
                        accept={groupConfig.accept}
                        uploadsRemaining={uploadsRemaining}
                    />
                )}
                {attachments.map((attachment, index) => (
                    <Attachment
                        offsetLazy={400}
                        onClickExpand={() =>
                            lightboxEvents.openGroupInLightbox({
                                groupId: attachment.documentID,
                                initialIndex: index
                            })
                        }
                        onClickEdit={() =>
                            editorEvents.showEditor({
                                isOpen: true,
                                image: attachment.base64,
                                groupId: attachment.documentID
                            })
                        }
                        onClickDelete={() => {
                            sharedAttachmentsEffects.deleteAttachmentFx({
                                contractId,
                                folderId: groupConfig?.id ?? 0, // 0 for "other" folder
                                filename: attachment.fileName
                            });
                        }}
                        onClickDownload={() => {
                            triggerFileDownload({ filename: attachment.fileName, data: attachment.base64 });
                        }}
                        attachment={attachment}
                        index={index}
                        key={attachment.uploaded + attachment.fileName + index}
                    />
                ))}
            </AttachmentsList>
        </Container>
    );
};
