import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { dialogEvents, dialogStores } from '../../../stores/dialogs';
import { sharedProfilesStores } from '@dat/shared-models/profiles';
import { sharedUserStores } from '@dat/shared-models/user';
import { Text } from '@wedat/ui-kit/components/Text';
import { Arrow } from '@wedat/ui-kit/assets/Icon';
import {
    Container,
    ProfileIconStyled,
    CloseIconStyled,
    GroupAndRole,
    MemberDataWrapper,
    TextWrapper,
    BackButton,
    UserAvatar
} from './styles';
import { pluginStores } from '../../../stores/plugin';
import { isSenderCurrentUser } from '../../../utils/isSenderCurrentUser';
import { getMemberCustomerNumber } from '../../../utils/getMemberCustomerNumber';
import { getMemberUsername } from '../../../utils/getMemberUsername';

export const Header = () => {
    const { t } = useTranslation();
    const currentDialog = useStore(dialogStores.currentDialog);
    const partners = useStore(dialogStores.partners);
    const sameCustomerProfiles = useStore(sharedProfilesStores.sameCustomerProfiles);
    const username = useStore(sharedUserStores.username);
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const customerNumber = useStore(sharedUserStores.customerNumber);

    const companion = currentDialog?.members.find(
        member => !isSenderCurrentUser({ sender: member, username, customerNumber })
    );

    const companionUsername = getMemberUsername(companion);

    const isPartner = getMemberCustomerNumber(companion) !== customerNumber;

    const companionData = isPartner
        ? partners.find(partner => partner.customerNumber === getMemberCustomerNumber(companion))
        : companionUsername && sameCustomerProfiles[companionUsername];

    const userFullName =
        companionData && !!companionData?.name && 'surname' in companionData
            ? `${companionData?.name} ${'surname' in companionData ? companionData?.surname : ''}`
            : // @ts-ignore
              companion?.username || '';

    return (
        <Container>
            {currentDialog && (
                <BackButton
                    onClick={() => {
                        dialogEvents.openDialog('');
                    }}
                >
                    <Arrow />
                </BackButton>
            )}
            <MemberDataWrapper>
                {!!companionData && 'image' in companionData ? (
                    <UserAvatar src={companionData.image} alt="user avatar" />
                ) : (
                    <ProfileIconStyled />
                )}
                {!!companionData && (
                    <TextWrapper>
                        <Text textTransform="capitalize" fontSize="18px" lineHeight="27px" color="gray_300">
                            {isPartner ? companionData?.name : userFullName}
                        </Text>
                        <GroupAndRole>
                            <Text
                                textTransform="capitalize"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={400}
                                color="gray_40"
                            >
                                {t('groupAndRole')}
                            </Text>
                        </GroupAndRole>
                    </TextWrapper>
                )}
            </MemberDataWrapper>
            <CloseIconStyled
                width="18"
                height="18"
                onClick={() => {
                    pluginOptions?.closeChat?.();
                }}
            />
        </Container>
    );
};
