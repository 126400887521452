import React from 'react';
import { useTranslation } from 'react-i18next';

import { pluginStores } from '../../../stores/plugin';
import { displayGrossRadioItems } from './constants';

import {
    Container,
    VatRate,
    Left,
    Field,
    Right,
    Image,
    Wrapper,
    PriceContainer,
    ImageWrapper,
    VehicleNameWrapper,
    LeftInfo
} from './styles';
import { EvaluationRadioGroupField } from '../../evaluationFields/RadioGroup';
import { DatNumberDisplayField } from '../../DatNumberDisplayField';
import { useStore } from 'effector-react';
import { SalesProcurement } from '../SalesProcurement';
import { Text } from '@wedat/ui-kit/components/Text';

import { contractStores } from '@dat/shared-models/contract';
import { currencyValues } from '@dat/core/constants/currency';
import { VehicleName } from '../../VehicleName';
import { addDecimalZerosToFormattedValue } from '../SalesProcurement/utils';

interface Props {
    isMobile: boolean;
}

export const TopSection: React.FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();
    const pluginOptions = useStore(pluginStores.pluginOptions);

    const displayGrossRadioItemsValues = displayGrossRadioItems(t);

    const contract = useStore(contractStores.contract);

    const currency = contract?.Dossier?.Currency;
    const currentCurrency = currencyValues[currency || 'EUR'];

    return (
        <Wrapper>
            <Container>
                <Left>
                    <Field>
                        <LeftInfo>
                            <EvaluationRadioGroupField
                                items={displayGrossRadioItemsValues}
                                name="Valuation.DisplayGross"
                                valueType="boolean"
                                column={isMobile}
                            />

                            <VatRate>
                                <Text color={'inherit'} font="note">
                                    {t('price.VAT Rate')} &nbsp;
                                </Text>
                                <DatNumberDisplayField
                                    propertyPath="VAT.VatAtValuationTime"
                                    suffix=" %"
                                    renderText={value => (
                                        <Text color={'inherit'} font="note">
                                            {value}
                                        </Text>
                                    )}
                                />
                            </VatRate>
                        </LeftInfo>

                        {isMobile && (
                            <ImageWrapper>
                                {pluginOptions?.image && (
                                    <Image src={`data:image/png;base64,${pluginOptions?.image}`} alt="vehicle photo" />
                                )}
                                <PriceContainer>
                                    <DatNumberDisplayField
                                        propertyPath="Valuation.SalesPrice"
                                        decimalScale={0}
                                        withoutIsActive
                                        renderText={addDecimalZerosToFormattedValue}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                    />
                                    &nbsp;{currentCurrency}
                                </PriceContainer>
                            </ImageWrapper>
                        )}
                    </Field>

                    {isMobile && (
                        <VehicleNameWrapper>
                            <VehicleName big />
                        </VehicleNameWrapper>
                    )}

                    <SalesProcurement isMobile={isMobile} withoutHeaders />
                </Left>

                {!isMobile && (
                    <Right>
                        <VehicleName big />

                        <ImageWrapper>
                            {pluginOptions?.image && (
                                <Image src={`data:image/png;base64,${pluginOptions?.image}`} alt="vehicle photo" />
                            )}

                            <PriceContainer>
                                <DatNumberDisplayField
                                    propertyPath="Valuation.SalesPrice"
                                    decimalScale={0}
                                    withoutIsActive
                                    renderText={addDecimalZerosToFormattedValue}
                                />
                                &nbsp;{currentCurrency}
                            </PriceContainer>
                        </ImageWrapper>
                    </Right>
                )}
            </Container>
        </Wrapper>
    );
};
