import { createEvent, createStore, restore, Store } from 'effector';
import * as R from 'ramda';

import { equipmentStores } from '../equipment';
import { createPluginResult } from '../../utils/createPluginResult';

import { PluginOptions } from '../../types/plugin';
import { PayloadForGetEquipment } from '../../types/equipment';
import { API } from '@dat/api';
import { filterArrayOfObjectsByExistingKeys, parseVXSResponse } from '@dat/core/utils';
import { parseEquipmentByVin } from '../../utils/parseEquipmentByVin';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { sharedEquipmentStores } from '@dat/shared-models/contract/Dossier/Vehicle/Equipment';
import { extractExistingEquipmentIds } from '../equipment/utils/extractExistingEquipmentIds';

const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

const payloadForGetEquipment = createStore<PayloadForGetEquipment | null>(null, {
    updateFilter: (update, current) => !R.equals(update, current)
}).on(initPlugin, (_, { requestData, vinEquipmentIds }) => ({
    requestData,
    vinEquipmentIds
}));

const getVehicleIdentificationByVinFx = createNotifyingEffect({
    handler: async (data: DAT.GetVehicleIdentificationByVinRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByVin(data);

        const vehicleObjects = parseVXSResponse(response);

        const vinResultEquipment = vehicleObjects[0].vinResultEquipment;

        const equipment = parseEquipmentByVin(response);

        const vinEquipmentIds = filterArrayOfObjectsByExistingKeys(vinResultEquipment, ['datNumber']).map(
            ({ datNumber }) => +datNumber
        );

        return {
            vinEquipmentIds,
            equipment
        };
    }
});

const equipmentByVin = createStore<DAT2.Equipment | null>(null).on(
    getVehicleIdentificationByVinFx.doneData,
    (_, data) => data.equipment
);

const pluginResult = equipmentStores.existingTransferEquipment.map(createPluginResult);
const completePlugin = createEvent();

// Write here, because we have cycle dependencies for pluginStores
const getEquipmentIds = (equipmentData: Store<DAT2.Equipment | null>) =>
    equipmentData.map<number[]>((equipment, prevIds) => {
        const ids = extractExistingEquipmentIds(equipment);
        const isEquals = R.equals(ids, prevIds);

        return prevIds && isEquals ? prevIds : ids;
    });

const contractExistingEquipmentIds = getEquipmentIds(sharedEquipmentStores.equipment);

const contractExistingEquipmentIdsByVin = getEquipmentIds(equipmentByVin);

//
/*** Export ***/
//
export const pluginEvents = {
    initPlugin,
    completePlugin
};
export const pluginStores = {
    pluginOptions,
    pluginResult,
    equipmentByVin,
    payloadForGetEquipment,
    contractExistingEquipmentIds,
    contractExistingEquipmentIdsByVin
};

export const pluginEffects = {
    getVehicleIdentificationByVinFx
};
