import { DEFAULT_WORKBENCH_VALUES } from '../components/WorkBench/constants';
import { WorkbenchFormValues } from '../types/workbenchForm';

interface GetWorkBenchPayloadParams {
    positionsItaly: DAT2.PositionItaly[];
    repairPositions: DAT2.RepairPosition[];
}

const NON_CHANGEABLE_DATA = {
    Type: 'corrections' as DAT2.PositionItalyType,
    Position: 2,
    PartCodeItaly: 99999,
    PartNumber: '99999'
};

const getConditionalData = (Amount: number, Description: string) => ({
    ...NON_CHANGEABLE_DATA,
    Amount,
    Description
});

export const getWorkBenchPayload = (values: WorkbenchFormValues): GetWorkBenchPayloadParams => {
    const { type, dependentTime, price, timeForParts, timeForPreparatory } = values;

    const positionsItaly: DAT2.PositionItaly[] = [];
    const repairPositions: DAT2.RepairPosition[] = [];

    // docs for all these are in the user-story DATG-1784

    const isDefaultWorkFront = dependentTime === DEFAULT_WORKBENCH_VALUES.defaultWorkFront;
    const isDefaultWorkBack = dependentTime === DEFAULT_WORKBENCH_VALUES.defaultWorkBack;
    const isDefaultWorkTotal = dependentTime === DEFAULT_WORKBENCH_VALUES.defaultWorkTotal;
    const isDefaultWorkStrips = dependentTime === DEFAULT_WORKBENCH_VALUES.defaultWorkStrips;

    if (type === 'traditional') {
        if (isDefaultWorkFront) {
            const data = getConditionalData(1, 'Workbench, traditional');
            positionsItaly.push(data);
        }

        if (isDefaultWorkBack) {
            const data = getConditionalData(2, 'Rear Workbench, traditional');
            positionsItaly.push(data);
        }

        if (isDefaultWorkTotal) {
            const data = getConditionalData(3, 'Total Workbench, traditional');
            positionsItaly.push(data);
        }

        if (isDefaultWorkStrips) {
            const data = getConditionalData(4, 'Clamping strips anchored Workbench, traditional');
            positionsItaly.push(data);
        }
    }

    if (type === 'universal') {
        if (isDefaultWorkFront) {
            const data = getConditionalData(1, 'Workbench, universal');
            positionsItaly.push(data);
        }

        if (isDefaultWorkBack) {
            const data = getConditionalData(2, 'Workbench, universal');
            positionsItaly.push(data);
        }

        if (isDefaultWorkTotal) {
            const data = getConditionalData(3, 'Workbench, universal');
            positionsItaly.push(data);
        }

        if (isDefaultWorkStrips) {
            const data = getConditionalData(4, 'Workbench, universal');
            positionsItaly.push(data);
        }
    }

    if (type === 'other') {
        const data = getConditionalData(1, 'Workbench, others');
        positionsItaly.push({ ...data, ValueParts: price });
    }

    if (timeForParts) {
        repairPositions.push({
            DATProcessId: 0,
            RepairType: 'incidental costs',
            PositionEntryType: 'italiaPostCalc',
            IsRepairExtension: false,
            SparePartNumber: '9999A',
            SparePartNumberOrigin: 'MANUAL',
            WorkTime: +timeForParts,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            DentsOutOfReach: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AlloyLM: false,
            LargeScale: false,
            AdhesiveTechnologyScale: false,
            AdditionLM: false,
            WorkCompleted: false,
            ContainMicroDents: false,
            WorkManualInput: '#',
            AdhesiveMethod: false,
            WorkIndication: 1
        });
    }

    if (timeForPreparatory) {
        repairPositions.push({
            DATProcessId: 0,
            RepairType: 'incidental costs',
            PositionEntryType: 'italiaPostCalc',
            IsRepairExtension: false,
            SparePartNumber: '9999B',
            SparePartNumberOrigin: 'MANUAL',
            WorkTime: +timeForPreparatory,
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            DentsOutOfReach: false,
            IsAdditionalLM: false,
            PreDamage: false,
            AlloyLM: false,
            LargeScale: false,
            AdhesiveTechnologyScale: false,
            AdditionLM: false,
            WorkCompleted: false,
            ContainMicroDents: false,
            WorkManualInput: '#',
            AdhesiveMethod: false,
            WorkIndication: 1
        });
    }

    return { positionsItaly, repairPositions };
};
