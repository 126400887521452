// Find average
// Find top 3 smallest number
import { Coords } from '../types/plugin';

export const distance = (lat1: number, lon1: number, lat2: number, lon2: number, unit = 'K') => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return unit === 'K' ? dist * 1.609344 : dist * 0.8684;
};

export const findNearestLocation = (users: DAT2.Internal.InternalPartner[], claimLocation: Coords) => {
    const signedUsers = users.map(user => {
        if (user.latitude && user.longitude && user.latitude !== 0) {
            const { latitude, longitude } = user;
            const res = distance(latitude, longitude, claimLocation.lat, claimLocation.lng);

            return {
                ...user,
                currentDistance: res
            };
        }

        return user;
    });
    return findTop(signedUsers);
};

const findAverage = (users: DAT2.Internal.InternalPartner[]) => {
    const total = users.reduce((acc, c) => {
        if (c.currentDistance) {
            return acc + c.currentDistance;
        }
        return acc;
    }, 0);
    return total / users.length;
};

const findTop = (users: DAT2.Internal.InternalPartner[]) => {
    const average = findAverage(users);

    return users.map(user => {
        const distance = user.currentDistance;

        return { ...user, distanceGroup: distance && distance < average ? 'nearest' : 'farest' };
    });
};
