import { vehicleSelectionEffects, vehicleSelectionEvents, vehicleSelectionStores } from '../../stores/vehicleSelection';
import {
    multipleVariantSelectionEffects,
    multipleVariantSelectionEvents,
    multipleVariantSelectionStores
} from '../../stores/multipleVariantSelection';
import { commonEffects, commonEvents } from '../../stores/common';
import { classificationGroupsNames, ClassificationGroupsKeys } from '@dat/core/constants';
import { createConstructionTimes } from '@dat/core/utils';

import { CurrentFormikValues } from '../../types/formik';
import { FieldEventCallback } from '@wedat/ui-kit/Formik';
import { ClassificationGroupsNames } from '@dat/core/types';

interface AdditionalOptions {
    name: ClassificationGroupsNames;
    isSelectedOptionAvailable: boolean;
    isInMultipleVariantSelectionModal?: boolean;
}

export const equipmentSelectChangeCallback: FieldEventCallback<CurrentFormikValues, AdditionalOptions> = async (
    value,
    formikContext,
    { name, isSelectedOptionAvailable, isInMultipleVariantSelectionModal }
) => {
    const { setFieldValue, values } = formikContext;
    const {
        vehicleSelects: mainModelVehicleSelects,
        equipmentSelects: mainModelEquipmentSelects,
        firstRegistration,
        firstRegistrationFilter,
        multipleVariantSelects: { equipmentSelects: multipleVariantEquipmentSelects, ...multipleVariantVehicleSelects }
    } = values;
    // Available options array contains a new value anyway
    const availableOptions = [value];
    // Dynamic data dependent on isInMultipleVariantSelectionModal
    const formikNamePath = `${isInMultipleVariantSelectionModal ? 'multipleVariantSelects.' : ''}equipmentSelects.`;
    const vehicleSelects = isInMultipleVariantSelectionModal ? multipleVariantVehicleSelects : mainModelVehicleSelects;
    const equipmentSelects = isInMultipleVariantSelectionModal
        ? { ...multipleVariantEquipmentSelects }
        : { ...mainModelEquipmentSelects };
    const { getAvailableEquipmentObjectFx } = isInMultipleVariantSelectionModal
        ? multipleVariantSelectionEffects
        : vehicleSelectionEffects;
    const { resetAvailableEquipment } = isInMultipleVariantSelectionModal
        ? multipleVariantSelectionEvents
        : vehicleSelectionEvents;
    let classificationGroups: ClassificationGroupsKeys[] = [];

    if (isInMultipleVariantSelectionModal) {
        const allEquipmentObject = multipleVariantSelectionStores.allEquipmentObject.getState();

        classificationGroups = Object.keys(allEquipmentObject) as ClassificationGroupsKeys[];
    }

    if (!isInMultipleVariantSelectionModal) {
        // Do change?
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            setFieldValue(`equipmentSelects.${name}`, equipmentSelects[name]);

            return;
        }
    }

    // Delete an old value of changed select
    delete equipmentSelects[name];

    // If one of the vehicleSelects is empty
    // then return
    if (Object.values(vehicleSelects).some(value => !value)) {
        return;
    }

    if (isSelectedOptionAvailable) {
        // Exclude empty (not selected) options.
        // Value of changed select is deleted
        // so it's not going into selectedOptions array
        const selectedOptions = Object.values(equipmentSelects).filter(option => !!option);

        availableOptions.push(...selectedOptions);
    } else {
        resetAvailableEquipment();
    }

    if (!isInMultipleVariantSelectionModal) {
        commonEvents.resetOptionsDependentOnEquipment();

        // Compile DAT code when all equipment is selected
        const allEquipmentObject = vehicleSelectionStores.allEquipmentObject.getState();
        const allEquipmentCount = Object.keys(allEquipmentObject).length;

        if (availableOptions.length === allEquipmentCount) {
            vehicleSelectionEffects.compileDatECodeFx({
                ...vehicleSelects,
                selectedOptions: availableOptions
            });
        }
    }

    // Get equipment
    const availableEquipmentObject = await getAvailableEquipmentObjectFx({
        ...vehicleSelects,
        availableOptions,
        classificationGroups,
        ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
    });
    // Reset focused selects
    commonEvents.focusSelect('');

    // Going through all equipment selects:
    // reset select if new available options
    // don't contain current value of select
    let selectToFocus = '';

    Object.entries(availableEquipmentObject).forEach(([groupKey, groupAvailableOptions]) => {
        const groupName = classificationGroupsNames[groupKey as ClassificationGroupsKeys];
        const selectFullName = formikNamePath + groupName;
        const groupCurrentValue = equipmentSelects[groupName];
        const groupAvailableValues = groupAvailableOptions?.map(option => option.key);

        // No need to check changed select
        if (groupName === name) {
            return;
        }

        // Focus select if it is not filled with value and it's available options length !== 1
        // (if there is only one available option, it will be auto-selected)
        if (!selectToFocus && !groupCurrentValue && groupAvailableValues?.length !== 1) {
            selectToFocus = selectFullName;
        }

        // Reset and focus select if value is not available
        if (groupCurrentValue && !groupAvailableValues?.includes(groupCurrentValue)) {
            setFieldValue(selectFullName, '');

            if (!selectToFocus && groupAvailableValues?.length !== 1) {
                selectToFocus = selectFullName;
            }
        }
    });

    commonEvents.focusSelect(selectToFocus);
};
