import { createEvent, createStore } from 'effector';
import { createToggle } from '@dat/core/utils/effector/createToggle';
import { CONTRACT_ID, IS_GALLERY_MODAL_OPEN } from './constants';

const [isGalleryModalOpen, toggleIsGalleryModalOpen] = createToggle(false);

const selectedContractId = createStore<number>(0);
const setSelectedContractId = createEvent<number>();
selectedContractId
    .on(setSelectedContractId, (_, state) => state)
    .on(selectedContractId.updates, state => {
        if (state > 0) {
            toggleIsGalleryModalOpen();
            localStorage.setItem(IS_GALLERY_MODAL_OPEN, JSON.stringify(true));
            localStorage.setItem(CONTRACT_ID, JSON.stringify(state));
        }
    });

export const sharedGalleryEvents = {
    setSelectedContractId,
    toggleIsGalleryModalOpen
};

export const sharedGalleryStores = {
    selectedContractId,
    isGalleryModalOpen
};
