import { useContext } from 'react';
import { useStoreMap } from 'effector-react';
import _ from 'lodash';

import { pluginStores } from '../stores/plugin';

import { Instance, InstanceEvents } from '../types/instance';
import { AppContext } from '../App.context';

type Result<KeysToPick extends keyof Instance> = Pick<Instance, KeysToPick> & InstanceEvents;

// For each address-book inside form-builder individual instance is created
// for stores not to be overwritten by each other. The hook below allows us to
// use a certain instance binded to current a-book component
export const useInstance = <KeysToPick extends keyof Instance>(
    ...keysToPick: Array<KeysToPick>
): Result<KeysToPick> => {
    const appInstanceId = useContext(AppContext);

    return useStoreMap({
        store: pluginStores.instances,
        keys: [appInstanceId, ...keysToPick],
        fn: instances => ({
            ..._.pick(instances[appInstanceId], [...keysToPick]),
            ...instances[appInstanceId]?.events
        })
    });
};
