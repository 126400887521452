import React, { FC, useState, ChangeEvent } from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';

import { Switcher, SwitcherProps } from '../../components/SwitcherOld';

import { FieldEventCallback } from '../types';

export interface SwitcherFieldProps extends Omit<SwitcherProps, 'onChange' | 'value'> {
    onChange?: FieldEventCallback;
    onUserChange?: (field: FieldInputProps<any>) => void;
    name: string;
}

export const SwitcherField: FC<SwitcherFieldProps> = React.memo(({ name, onChange, onUserChange, ...rest }) => {
    const formikContext = useFormikContext();
    const [{ ...field }, , helpers] = useField(name);

    const [isChecked, setChecked] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const valN = e.target.checked ? 1 : 0;

        const currentValue = rest.switchOptions[valN].value;

        helpers.setValue(currentValue);
        onChange?.(currentValue, formikContext);
        onUserChange?.({
            ...field,
            value: currentValue
        });
        setChecked(e.target.checked);
    };

    return <Switcher {...field} {...rest} checked={isChecked} onChange={handleChange} />;
});
