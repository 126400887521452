import { FC, useContext } from 'react';
import { FormStep, PluginProps, ResultForm, Steps } from '../../types';
import { FormContext, actions } from '../../reducers/form';
import {
    Photos,
    PhotoStyled,
    PhotoTitle,
    PhotoWrapper,
    TabPhotoPanelStyled,
    Title,
    Image,
    AdditionalIconStyled
} from './styles';

const { changeStep, retakePhoto } = actions;

interface Props {
    title: string;
    steps: PluginProps['steps'];
    formSteps: ResultForm['steps'];
    filterKey: FormStep['photoType'];
}

interface AdditionalProps {
    title: string;
}

interface PhotoProps {
    photo: string;
    title?: string;
    onClick?: () => void;
}

interface PhotoItemProps {
    title?: string;
    onClick?: () => void;
}

interface TabPhotoPanelProps {
    title: string;
}

const PhotoItem: FC<PhotoItemProps> = ({ children, title, onClick }) => (
    <PhotoStyled onClick={onClick}>
        <PhotoWrapper>{children}</PhotoWrapper>
        <PhotoTitle>{title}</PhotoTitle>
    </PhotoStyled>
);

const Photo: FC<PhotoProps> = ({ photo, title, onClick }) => (
    <PhotoItem title={title} onClick={onClick}>
        <Image alt="photo" src={photo} />
    </PhotoItem>
);

const TabPhotoPanel: FC<TabPhotoPanelProps> = ({ children, title }) => (
    <TabPhotoPanelStyled>
        <Title asTag="p" font="defaultMedium">
            {title}
        </Title>
        <Photos>{children}</Photos>
    </TabPhotoPanelStyled>
);

export const TabPhotoAdditional: FC<AdditionalProps> = ({ title }) => {
    const {
        state: {
            resultForm: { additionalSteps = [] }
        },
        dispatch
    } = useContext(FormContext);

    return (
        <TabPhotoPanel title={title}>
            {additionalSteps.map((photo, idx) => (
                <Photo key={idx.toString()} photo={photo} title={`Photo ${idx + 1}`} />
            ))}
            <PhotoItem
                title={`Photo ${additionalSteps.length + 1}`}
                onClick={() => dispatch(changeStep(Steps.Additional))}
            >
                <AdditionalIconStyled />
            </PhotoItem>
        </TabPhotoPanel>
    );
};

export const TabPhoto: FC<Props> = ({ title, steps, formSteps, filterKey }) => {
    const { dispatch } = useContext(FormContext);
    const items = formSteps
        .map((step, idx) => ({
            ...step,
            formIndex: idx
        }))
        .filter(step => step.photoType.toLowerCase() === filterKey.toLowerCase());
    const filteredSteps = steps.filter(step => step.photoType.toLowerCase() === filterKey.toLowerCase());

    if (items.length === 0) return null;

    const titleParsed = title.toLowerCase().substring(0, 1).toUpperCase() + title.toLowerCase().substring(1);

    return (
        <TabPhotoPanel title={titleParsed}>
            {items.map((formStep, idx) => (
                <Photo
                    key={idx.toString()}
                    photo={formStep.photo}
                    title={filteredSteps[idx]?.mask?.title}
                    onClick={() => dispatch(retakePhoto(formStep.formIndex))}
                />
            ))}
        </TabPhotoPanel>
    );
};
