import { createEffect, createEvent, restore } from 'effector';
import { NotificationObject } from './types';
import { I18N_NAMESPACES } from '../../core/constants/i18n';
import { i18n } from '@dat/shared-models/i18n';

const showNotification = createEvent<NotificationObject | null>();
const resetNotification = createEvent();
const notification = restore(showNotification, null).reset(resetNotification);

const showInfoMessage = showNotification.prepend((message: string) => ({
    title: i18n.t(`${I18N_NAMESPACES['ui-kit']}:infoModalTitle`),
    message
}));
const showError = showNotification.prepend((message: string) => ({
    title: i18n.t(`${I18N_NAMESPACES['ui-kit']}:errorModalTitle`),
    message
}));

const answerToPrompt = createEvent<boolean>();
const getPromptAnswer = createEffect((message: string) => {
    showNotification({
        title: i18n.t(`${I18N_NAMESPACES['ui-kit']}:infoModalTitle`),
        message,
        isPrompt: true
    });

    return new Promise<boolean>(rs => {
        const unwatch = answerToPrompt.watch(answer => {
            rs(answer);

            unwatch();
        });
    });
});

//
/*** Export ***/
//
export const events = {
    showNotification,
    showError,
    showInfoMessage,
    resetNotification,
    answerToPrompt
};
export const effects = {
    getPromptAnswer
};
export const stores = {
    notification
};
