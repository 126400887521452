import React, { FC, useCallback } from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';

import { MultiSelectProps, Select, SelectProps, SelectOption, SingleSelectProps } from '../../components/Select';

import { FieldEventCallback } from '../types';

export interface SelectFieldProps extends Omit<SelectProps, 'onChange' | 'value'> {
    changeCallback?: FieldEventCallback;
    changeCallbackMulti?: FieldEventCallback<any, void, string[]>;
    //TODO: [any] - split field to separate single and multiple fields
    onUserChange?: (field: FieldInputProps<any>) => void;
    valueKey?: 'key' | 'value';
    valueType?: 'string' | 'number';
    borderRadius?: number;
}

export const SelectField: FC<SelectFieldProps> = React.memo(
    ({ name, changeCallback, changeCallbackMulti, onUserChange, options, valueKey = 'value', valueType, ...rest }) => {
        const formikContext = useFormikContext();
        const [field, , fieldHelpers] = useField(name);

        const handleChangeSingle = useCallback(
            (option: SelectOption) => {
                const value = option[valueKey];

                if (field.value === value) {
                    return;
                }

                fieldHelpers.setValue(value);

                // !!! Use changeCallback carefully !!!
                // formikContext passed to it doesn't contain a new value
                // so this value is passed as the first parameter
                changeCallback?.(value as string, formikContext);
                onUserChange?.({
                    ...field,
                    value
                });
            },
            [valueKey, field, fieldHelpers, changeCallback, formikContext, onUserChange]
        );

        const handleChangeMulti = useCallback(
            (options: SelectOption[]) => {
                const value = options.map(item => item[valueKey]);
                fieldHelpers.setValue(value);

                // !!! Use changeCallback carefully !!!
                // formikContext passed to it doesn't contain a new value
                // so this value is passed as the first parameter
                changeCallbackMulti?.(value as string[], formikContext);
                onUserChange?.({
                    ...field,
                    value
                });
            },
            [fieldHelpers, changeCallbackMulti, formikContext, onUserChange, field, valueKey]
        );

        const handleChange = rest.isMulti
            ? (handleChangeMulti as MultiSelectProps['onChange'])
            : (handleChangeSingle as SingleSelectProps['onChange']);

        return (
            <Select
                {...field}
                {...rest}
                valueKey={valueKey}
                valueType={valueType}
                options={options}
                onChange={handleChange as any}
            />
        );
    }
);
