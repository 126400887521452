import { sample, forward, combine } from 'effector';
import { contractEvents, contractStores } from '@dat/shared-models/contract';
import { sharedPartnersEffects } from '@dat/shared-models/contract/Partners';

import { locationEffects } from './index';
import { retrieveAddress } from '../../utils/retrieveAddress';
import { partnersStores } from '../partners';

const { newContractReceived } = contractEvents;
const { getClaimPositionFx } = locationEffects;
const { contractId } = contractStores;
const { activeRole } = partnersStores;

sample({
    source: newContractReceived,
    fn: retrieveAddress,
    target: getClaimPositionFx
});

const partnerPayload = combine(contractId, activeRole, (contractId, customerRole) => ({ contractId, customerRole }));

forward({
    from: partnerPayload,
    to: sharedPartnersEffects.getCustomerPartnersFx
});
