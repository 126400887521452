import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { StyledVSMButton } from '@dat/vehicle-selection-modal/src/components/Main/styles';
import { ButtonIcon } from '@wedat/ui-kit/components/ButtonIcon';
import { StyledNavLink } from '../../../../components/StyledNavLink';
import { Tooltip } from '@wedat/ui-kit/components/Tooltip';

interface PluginLinkProps {
    disabled?: boolean;
}

interface VehicleSelectionContainerProps {
    isVehicleIdentified: boolean;
    noImage?: boolean;
}

export const Text = styled.div`
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
    color: inherit;
`;

export const PluginBox = styled.div`
    padding: 24px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;

    box-shadow: 0px 8px 32px ${({ theme }) => theme.colors.gray[50]}, 0px -8px 32px ${({ theme }) => theme.colors.white};
    border-radius: 16px;

    ${StyledVSMButton} {
        width: 100%;
    }

    ${media.laptop`
        width: 100%;
    `}

    ${media.laptopSmall`
        padding-left: 16px;
        padding-right: 16px;
    `}

    ${media.phoneBig`
        width: 100%;
    `}
`;

export const VehicleSelectionContainer = styled(PluginBox)<VehicleSelectionContainerProps>`
    padding-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '170px' : '120px')};
    margin-top: -200px;

    ${media.laptopSmall`
        margin-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '-270px' : '-210px')};
        padding-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '350px' : '170px')};
    `}

    ${media.phoneBig`
        margin-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '-230px' : '-210px')};
        padding-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '300px' : '160px')};
    `}

    ${media.phone`
        margin-top: -210px;
        padding-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '250px' : '120px')};
    `}

    ${media.phoneMedium`
        margin-top: -210px;
        padding-top: ${({ isVehicleIdentified, noImage }) => (isVehicleIdentified && !noImage ? '210px' : '120px')};
    `}

    ${media.phoneSmall`
        padding-top: 210px;
    `}
`;

export const PluginsLinksContainer = styled.div`
    margin: 1rem 0;
    display: flex;
`;

export const StyledTooltip = styled(Tooltip)`
    flex: 1;
    display: flex;
    height: 48px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    overflow: hidden;

    &:first-of-type {
        border-radius: 8px 0px 0px 8px;
        border-right: none;
    }

    &:last-of-type {
        border-radius: 0px 8px 8px 0px;
        border-left: none;
    }
`;

export const PluginLink = styled.div<PluginLinkProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
    font-family: 'Noto Sans TC', sans-serif;
    color: ${({ theme: { colors } }) => colors.dustBlue['900']};
    transition: color 0.3s, opacity 0.3s;

    & svg {
        width: 24px;
        height: 24px;
        color: ${({ theme }) => theme.colors.dustBlue['900']};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['500']};
        & svg {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    ${StyledNavLink} {
        padding-left: 0;
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            & svg {
                color: ${theme.colors.dustBlue['200']};
            }
        `}
`;

export const AppointmentPopup = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.blueDark};
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Noto Sans TC', sans-serif;

    ${media.laptop`
        width: 100%;
    `}
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    width: 48px;
    padding: 0;
`;

export const HideBlockContainer = styled.div`
    margin-right: 20px;

    ${media.laptopSmall`
        margin-right: 0
    `}
`;
