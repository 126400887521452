/*
 * Works the same as "getTextValuesFromParsedObject" but recursive (arrays are deleted)
 */
import { getFieldText } from './getFieldText';
import { PlainObject } from '@dat/core/types/common';
import { ExtractTextFromFieldWithPartialAttributes } from '../types';

export type GetTextValuesFromParsedObjectRecursive<ParsedObject extends PlainObject> = {
    [K in keyof ParsedObject]: ParsedObject[K] extends DAT2.Field.AttrText<DAT2.Field.Primitive> | undefined
        ? ParsedObject[K]['_text']
        : ParsedObject[K] extends DAT2.Field.TextWithPartialAttributes<DAT2.Field.Primitive, object> | undefined
        ? ExtractTextFromFieldWithPartialAttributes<ParsedObject[K]>
        : ParsedObject[K] extends PlainObject | undefined
        ? GetTextValuesFromParsedObjectRecursive<ParsedObject[K]>
        : ParsedObject[K] extends DAT2.Field.Primitive | undefined
        ? ParsedObject[K]
        : never;
};

export const getTextValuesFromParsedObjectRecursive = <Obj extends PlainObject>(
    obj: Obj
): GetTextValuesFromParsedObjectRecursive<Obj> =>
    Object.entries(obj).reduce((acc, [key, field]) => {
        if (Array.isArray(field)) {
            return acc;
        }

        if (typeof field === 'object' && field._text === undefined) {
            acc[key as keyof GetTextValuesFromParsedObjectRecursive<Obj>] =
                getTextValuesFromParsedObjectRecursive(field);
        } else {
            const text = getFieldText(field);

            if (text !== undefined) {
                acc[key as keyof GetTextValuesFromParsedObjectRecursive<Obj>] = text;
            }
        }

        return acc;
    }, {} as GetTextValuesFromParsedObjectRecursive<any>);
