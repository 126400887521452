import styled from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import { AbsolutePrefix } from '@wedat/ui-kit/components/NumberInput/styles';
import { InputStyled } from '@wedat/ui-kit/components/Input/styles';

export const VehicleConditionStyled = styled.div`
    width: 100%;
    margin-top: 24px;
    overflow-x: auto;

    ${makeCustomScrollBar()}
`;

export const EvaluationNumberWrapper = styled.div`
    ${AbsolutePrefix} {
        height: calc(100% - 2px);
        border-radius: 8px 0 0 8px;
    }
    ${InputStyled} {
        padding-left: 30px;
    }
`;

export const Col1Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.laptop`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const Col1WrapperRight = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.laptop`
        margin-top: 10px;
        width: 100%;
    `}
`;

export const NumberOfOwnersNWrapper = styled.div`
    width: 33%;

    ${media.laptop`
        width: 100%;
    `}
`;

export const DatNumberRenderText = styled.div`
    position: relative;
    height: 48px;
    width: 100%;
    padding-left: 34px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;

export const DatNumberRenderTextSign = styled.div`
    display: flex;
    align-items: center;
    height: calc(100% - 2px);
    padding: 2px 8px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    z-index: 10;
    color: ${({ theme }) => theme.colors.dustBlue['200']};
`;

export const Col4Wrapper = styled.div`
    text-align: start;
`;
