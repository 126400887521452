import styled from 'styled-components/macro';

export const NotFoundWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
`;

export const NotFoundImg = styled.img`
    width: 215px;
    height: 176px;
`;
