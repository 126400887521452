import React, { useMemo } from 'react';

import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { EquipmentValueStyled } from './styles';

import { useTranslation } from 'react-i18next';

import { DatNumberDisplayField } from '../../DatNumberDisplayField';
import { EvaluationNumberInputField } from '../../evaluationFields/Input';

import { Table } from '@wedat/ui-kit/components/Table';

export const EquipmentValue: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useMedia(sizes.phoneBig);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'col1'
            },
            {
                Header: t('DAT'),
                accessor: 'col2'
            },
            {
                Header: t('Manually'),
                accessor: 'col3'
            }
        ],
        [t]
    );

    const data = useMemo(
        () => [
            {
                col1: t('equipmentValue.Original price of the equipment'),
                col2: <DatNumberDisplayField propertyPath="Valuation.EquipmentOriginalPrice" />,
                col3: ''
            },
            {
                col1: t('equipmentValue.Total value of equipment'),
                col2: <DatNumberDisplayField propertyPath="Valuation.EquipmentPrice" />,
                col3: (
                    <EvaluationNumberInputField
                        name="Valuation.EquipmentPrice"
                        placeholder={isMobile ? 'Manually' : ''}
                    />
                )
            }
        ],
        [isMobile, t]
    );

    return (
        <EquipmentValueStyled>
            <Table columns={columns} data={data} />
        </EquipmentValueStyled>
    );
};
