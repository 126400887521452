import { RadioProps, RadioValue } from './';
import { parseValueAsXML } from '../../../api2/utils/soap/parseXML';

export const convertValueToType = (value: string, valueType: RadioProps['valueType']): RadioValue =>
    valueType ? conversionFns[valueType](value) : value;

const convertStringNumberToNumber = (stringNumber: string) => +stringNumber;
const convertStringBooleanToBoolean = (stringBoolean: string) => stringBoolean.toLowerCase() === 'true';

const conversionFns = {
    number: convertStringNumberToNumber,
    boolean: convertStringBooleanToBoolean,
    'from-xml': parseValueAsXML
};
