import { CurrentFormikValues } from '../types/formik';
import { ValuateFinancePluginOptions } from '@dat/valuate-finance';
import { EquipmentPluginResult } from '@dat/equipment';

export const createValuateFinanceRequest = (
    values: CurrentFormikValues,
    existingEquipment: EquipmentPluginResult
): ValuateFinancePluginOptions['requestData'] => {
    const { datECode, container, kilometers, constructionPeriod, firstRegistration, repairType } = values;

    const equipmentIds = existingEquipment.SpecialEquipment.map(({ DatEquipmentId }) => DatEquipmentId);
    const equipment = {
        equipmentPosition: equipmentIds.map(datEquipmentId => ({ datEquipmentId }))
    };

    return {
        datECode,
        container,
        mileage: +kilometers,
        constructionTime: +constructionPeriod,
        registrationDate: (firstRegistration || new Date('12-12-12')).toISOString(),
        restriction: repairType as DAT.Restriction,
        equipment
    };
};
