import React, { FC } from 'react';
import { HeaderBlocks, SideBarBlocks } from '../../types/hideBlocks';
import { useHiddenBlock } from '../../hooks/useHiddenBlock';

interface Props {
    headerBlock?: HeaderBlocks;
    sidebarBlock?: SideBarBlocks;
}

export const HideBlock: FC<Props> = ({ sidebarBlock, headerBlock, children }) => {
    const blockHidden = useHiddenBlock({ headerBlock, sidebarBlock });

    return !blockHidden ? <>{children}</> : null;
};
