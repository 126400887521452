import * as R from 'ramda';

import { defaultFields } from '../../../constants/defaultFields';
import { findSubjectById } from '../../../utils/findSubjectById';

import { PayloadForInstanceOptionsReceived } from '../types';
import { Instance, InstanceDynamicProperties } from '../../../types/instance';
import { SubjectsData } from '../../../types/subjects';
import { createNewSubject } from '../../../utils/createNewSubject';

type Param = {
    subjectsData: SubjectsData | undefined;
    initialPayload: PayloadForInstanceOptionsReceived;
    currentInstance?: Instance;
};
type Result = InstanceDynamicProperties;

export const computeInstanceDynamicProperties = ({ subjectsData, initialPayload, currentInstance }: Param): Result => {
    const { subjectType, assignedId } = initialPayload;
    const subjectTypeData = subjectsData?.[subjectType];
    const initialSubjects = subjectTypeData?.subjects || [];
    const defaultSubject = createNewSubject();

    const result: Result = {
        ...initialPayload,
        isDataReceived: !!subjectsData,
        initialSubjects,
        fields: subjectTypeData?.fields || defaultFields,
        currentSubjects: initialSubjects,
        displayedId: assignedId || defaultSubject._id,
        assignedSubject: undefined,
        displayedSubject: defaultSubject
    };

    if (isDataPathEquals(initialPayload, currentInstance) && currentInstance.isDataReceived) {
        persistUserSpecificProperties(result, currentInstance);
    }

    result.assignedSubject = findSubjectById(result.currentSubjects, result.assignedId);
    result.displayedSubject = findSubjectById(result.currentSubjects, result.displayedId) || defaultSubject;

    return result;
};

//TODO: describe
const persistUserSpecificProperties = (result: Result, currentInstance: Instance) => {
    const propertiesToPersist: Array<keyof InstanceDynamicProperties> = ['currentSubjects', 'displayedId'];

    Object.assign(result, R.pick(propertiesToPersist, currentInstance));
};

// if: data === customersSubjectData[networkOwnerId][subjectType]
// then: dataPath === [networkOwnerId][subjectType]
const isDataPathEquals = (
    initialPayload: PayloadForInstanceOptionsReceived,
    currentInstance?: Instance
): currentInstance is Instance => {
    if (!currentInstance) return false;

    const dataPathProperties: Array<keyof PayloadForInstanceOptionsReceived> = ['networkOwnerId', 'subjectType'];

    return R.equals(R.pick(dataPathProperties, initialPayload), R.pick(dataPathProperties, currentInstance));
};
