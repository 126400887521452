import { PlainObject } from '@dat/core/types/common';

type Result<T extends PlainObject> = {
    [K in keyof T]: null extends T[K] ? Exclude<T[K], null> | undefined : T[K];
};

export const convertNullValuesToUndefined = <T extends PlainObject>(obj: T): Result<T> => {
    const result: Result<T> = { ...obj };

    for (const key in result) {
        if (result[key] === null) {
            // @ts-ignore
            result[key] = undefined;
        }
    }

    return result;
};
