import React, { FC } from 'react';

import { pluginEvents } from './stores/plugin';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { InboxPage } from './pages/InboxPage';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => (
    <PluginProvider
        name="inbox"
        options={options}
        onInit={pluginEvents.initPlugin}
        onUnmount={pluginEvents.pluginUnmounted}
    >
        <InboxPage />
    </PluginProvider>
);

export default App;
