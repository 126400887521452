// sparePartSearchModel
import { combine, createEvent, restore, createEffect, sample, createStore } from 'effector';

import {
    getExtPartNoInfoByFullVehicleAndIntPartNo,
    getExtPartNoInfoByModelAndExtPartNo
} from '@dat/api2dat/services/sparePartsService';
import {
    Dossier,
    GetExtPartNoInfoByFullVehicleAndIntPartNoParams,
    GetExtPartNoInfoByFullVehicleAndIntPartNoResponse,
    GetExtPartNoInfoByModelAndExtPartNoParams,
    GetExtPartNoInfoByModelAndExtPartNoResponse
} from '@dat/api2dat/types/SparePartsServiceTypes';
import { requestCredentialsModel } from './requestCredentialsModel';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { splitDatECode } from '../utils/splitDatECode';
import { pluginOptionsModel } from './pluginOptionsModel';
import { RepairPositionsModel } from './repairPositionsModel';
import { equipmentModel } from './equipmentModel';

const getExtPartNoInfoByModelAndExtPartNoFx = createEffect(getExtPartNoInfoByModelAndExtPartNo);

const setResultGetExtPartNoInfoByModelAndExtPartNo = createEvent<GetExtPartNoInfoByModelAndExtPartNoResponse | null>();
const resultGetExtPartNoInfoByModelAndExtPartNo = restore(setResultGetExtPartNoInfoByModelAndExtPartNo, null);

const runGetExtPartNoInfoByModelAndExtPartNo = createEvent<string[]>();

resultGetExtPartNoInfoByModelAndExtPartNo.on(getExtPartNoInfoByModelAndExtPartNoFx.doneData, (_, newState) => newState);

sample({
    source: combine([
        pluginOptionsModel.stores.localesParams,
        availableAssemblyGroupsModel.stores.datECode,
        requestCredentialsModel.stores.requestCredentials,
        requestCredentialsModel.stores.datServicesUrls
    ]),
    clock: runGetExtPartNoInfoByModelAndExtPartNo,
    fn: ([localesParams, datECode, requestCredentials, datServicesUrls], partNo) => {
        if (!localesParams) return {};

        const { locale } = localesParams;

        const sDatECode = splitDatECode(datECode);

        const params: GetExtPartNoInfoByModelAndExtPartNoParams = {
            locale: {
                attr: locale || {
                    country: 'us',
                    datCountryIndicator: 'de',
                    language: 'EN'
                }
            },
            coverage: 'COMPLETE',

            vehicleType: parseInt(sDatECode.vehicleTypeId),
            manufacturer: parseInt(sDatECode.manufacturerId),
            baseModel: parseInt(sDatECode.baseModelId),
            subModel: parseInt(sDatECode.subModelId),

            extPartNo: partNo
        };
        return {
            credentials: requestCredentials,
            params,
            url: datServicesUrls.sparePartsServiceUrl || ''
        };
    },
    target: getExtPartNoInfoByModelAndExtPartNoFx
});

// ================================================================================================
// getExtPartNoInfoByFullVehicleAndIntPartNo

const setCurrentExtPartNoInfo = createEvent<GetExtPartNoInfoByFullVehicleAndIntPartNoResponse | null>();
const currentExtPartNoInfo = restore(setCurrentExtPartNoInfo, null);

const getExtPartNoInfoByFullVehicleAndIntPartNoFx = createEffect(getExtPartNoInfoByFullVehicleAndIntPartNo);
getExtPartNoInfoByFullVehicleAndIntPartNoFx.doneData.watch(setCurrentExtPartNoInfo);

const runGetExtPartNoInfoByFullVehicleAndIntPartNo = createEvent<string | string[]>();

currentExtPartNoInfo.on(runGetExtPartNoInfoByFullVehicleAndIntPartNo, () => null);

sample({
    source: combine([
        pluginOptionsModel.stores.localesParams,
        availableAssemblyGroupsModel.stores.datECode,
        requestCredentialsModel.stores.requestCredentials,
        requestCredentialsModel.stores.datServicesUrls,
        pluginOptionsModel.stores.pluginOptions,
        equipmentModel.stores.specialEquipment
    ]),
    clock: runGetExtPartNoInfoByFullVehicleAndIntPartNo,
    fn: ([localesParams, datECode, requestCredentials, datServicesUrls, pluginOptions, specialEquipment], dvn) => {
        if (!localesParams || !dvn) return {};

        const constructionTime = pluginOptions?.settings?.contract?.Dossier?.Vehicle?.ConstructionTime;
        const equipment = specialEquipment?.EquipmentPosition.map(equip => equip.DatEquipmentId).filter(
            EquipmentId => !!EquipmentId
        ) as number[];

        const { locale } = localesParams;
        const sDatECode = splitDatECode(datECode);
        const params: GetExtPartNoInfoByFullVehicleAndIntPartNoParams = {
            locale: {
                attr: locale || {
                    country: 'us',
                    datCountryIndicator: 'de',
                    language: 'EN'
                }
            },
            datECode: sDatECode.datECode,
            intPartNo: dvn,
            constructionTime,
            equipment
        };
        return {
            credentials: requestCredentials,
            params,
            url: datServicesUrls.sparePartsServiceUrl || ''
        };
    },
    target: getExtPartNoInfoByFullVehicleAndIntPartNoFx
});

// ===============================================================================================
// cache getExtPartNoInfoByFullVehicleAndIntPartNo

const sparePartPositionDossier = createStore<Dossier | null>(null);
const setSparePartPositionDossier = createEvent<Dossier | undefined>();
sparePartPositionDossier.on(setSparePartPositionDossier, (_, value) => value);

const sparePartPosition = sparePartPositionDossier.map(pos => pos?.SparePartPositions.SparePartPosition);

const addSparePartPosition = createEvent<GetExtPartNoInfoByModelAndExtPartNoResponse>();

getExtPartNoInfoByFullVehicleAndIntPartNoFx.doneData.watch(addSparePartPosition);

sample({
    source: combine([sparePartPositionDossier, availableAssemblyGroupsModel.stores.datECode]),
    clock: addSparePartPosition,
    fn: ([sparePartPositionDossier, datECode], extPartNoInfo) => {
        if (!extPartNoInfo?.Dossiers?.Dossier.length) return;

        extPartNoInfo.Dossiers.Dossier.forEach(dossier => {
            if (datECode !== dossier.Vehicle.DatECode) {
                return;
            }
        });

        if (!sparePartPositionDossier) return extPartNoInfo.Dossiers.Dossier?.[0];

        const result: Dossier = sparePartPositionDossier;

        extPartNoInfo.Dossiers.Dossier.forEach(dossier => {
            dossier.SparePartPositions.SparePartPosition.forEach(sparePosition => {
                const foundVal = result.SparePartPositions.SparePartPosition.find(
                    position => position.DATProcessId === sparePosition.DATProcessId
                );
                if (!foundVal) result.SparePartPositions.SparePartPosition.push(sparePosition);
            });
        });

        return result;
    },
    target: setSparePartPositionDossier
});

sample({
    source: sparePartPosition,
    clock: [RepairPositionsModel.event.setRepairPositions, RepairPositionsModel.event.initRepairPosition],
    fn: (existingPositions, updatedRepairPosition) => {
        if (!updatedRepairPosition) return [];
        const uniqDvn = Array.from(new Set(updatedRepairPosition.map(item => item.DATProcessId.toString())));
        const requestDvns =
            uniqDvn?.filter(dvn => !existingPositions?.find(exPos => exPos.DATProcessId.toString() === dvn)) || [];
        return requestDvns;
    },
    target: runGetExtPartNoInfoByFullVehicleAndIntPartNo
});

export const sparePartSearchModel = {
    stores: {
        resultGetExtPartNoInfoByModelAndExtPartNo,
        currentExtPartNoInfo,

        sparePartPositionDossier,
        sparePartPosition
    },
    events: {
        runGetExtPartNoInfoByModelAndExtPartNo,
        setResultGetExtPartNoInfoByModelAndExtPartNo,
        runGetExtPartNoInfoByFullVehicleAndIntPartNo
    },
    effects: {
        getExtPartNoInfoByModelAndExtPartNoFx,
        getExtPartNoInfoByFullVehicleAndIntPartNoFx
    }
};
