import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    padding-bottom: 20px;
    justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
    width: 140px;
    margin-left: 20px;
    color: ${({ theme }) => theme.colors.blueDark};
    border-color: ${({ theme }) => theme.colors.blueDark};
    background-color: ${({ theme }) => theme.colors.white};

    ${media.phoneBig`
        width: 100%;
        margin-left: 0;
    `}
`;

export const ButtonStyled = styled(Button)`
    width: 100px;
    border: solid 2px ${({ theme }) => theme.colors.blueDark};

    ${media.phoneBig`
        width: 100%;
        margin-bottom: 10px;
    `}
`;

export const AmountWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
