import { useEffect, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';

const isLandscapeFn = () =>
    isSafari || isIOS
        ? window.matchMedia('(orientation: landscape)').matches
        : [90, 270].includes(window.screen.orientation?.angle);

/**
 * Hook to get orientation
 */
export const useOrientation = () => {
    const [isLandscape, setLandscape] = useState(isLandscapeFn);
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        // detect orientation change
        const handleOrientation = () =>
            setTimeout(() => {
                if (mounted) {
                    setLandscape(isLandscapeFn());
                }
            }, 100);

        window.addEventListener('orientationchange', handleOrientation);

        return () => {
            window.removeEventListener('orientationchange', handleOrientation);

            setMounted(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLandscape
    };
};
