import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Tooltip } from '@wedat/ui-kit/components/Tooltip';
import { Text } from '@wedat/ui-kit/components/Text';

interface StyledButtonIconProps {
    arrowIconDirection?: 'up' | 'down';
}

interface StandardIndicatorProps {
    standard: boolean;
    isSelected?: boolean;
}

export const StandardIndicator = styled.div<StandardIndicatorProps>`
    width: 12px;
    height: 12px;
    background: ${({ theme, standard }) => (standard ? theme.colors.dustBlue['600'] : 'none')};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['600']};
    border-radius: 50%;
`;

export const EquipmentContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
    background-color: ${({ theme }) => theme.colors.white};

    ${media.laptop`
        border: none;
        overflow: auto;
    `};
`;

export const TransferEquipmentButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 64px;

    ${media.laptop`
        width: 100%;
        height: 48px;
    `}
`;

export const VinContainer = styled(Text)`
    padding: 4px 8px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.yellow['400']};
    ${({ theme }) => theme.typography.footnote}
    font-weight: 700;
`;

export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${({ theme }) => theme.colors.dustBlue['400']};

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const InfoIconWrapper = styled.span`
    color: ${({ theme }) => theme.colors.dustBlue['400']};
`;

export const ArrowIconWrapper = styled.span<StyledButtonIconProps>`
    transition: transform 0.1s linear;

    ${({ arrowIconDirection }) => {
        switch (arrowIconDirection) {
            case 'up':
                return css`
                    transform: rotate(180deg);
                `;
        }
    }};
`;

export const StyledTooltip = styled(Tooltip)`
    margin-left: 12px;
    display: flex;
    align-items: flex-end;
    &:hover {
        color: ${({ theme }) => theme.colors.yellow['800']};
    }
`;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 0 32px;
`;

export const AvailableTableWrapper = styled(TableWrapper)`
    border-right: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;

export const ExistingTableWrapper = styled(TableWrapper)`
    border-left: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;
