import React, { useMemo } from 'react';
import { useFormikContext, FormikValues } from 'formik';

import { Table } from '@wedat/ui-kit/components/Table';
import { BasicValuationStyled } from './styles';

import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { DatNumberDisplayField } from '../../DatNumberDisplayField';
import { EvaluationNumberInputField } from '../../evaluationFields/Input';

import { pluginStores } from '../../../stores/plugin';
import { dossierStores } from '../../../stores/dossier';

interface Props {
    isMobile: boolean;
}

export const BasicValuation: React.FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();

    const { values } = useFormikContext<FormikValues>();

    const pluginOptions = useStore(pluginStores.pluginOptions);
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);

    const referenceMileage = dossierWithExtractedTextValues?.Valuation?.ReferenceMileage || 0;
    const requestMileage = pluginOptions?.requestData?.mileage || 0;

    const mileageEstimated = values.Vehicle.MileageEstimated || 0;
    const mileageDifference = referenceMileage - mileageEstimated;

    const columns = useMemo(
        () => [
            {
                Header: t('Description'),
                accessor: 'col1'
            },
            {
                Header: t('DAT'),
                accessor: 'col2'
            },
            {
                Header: t('Manually'),
                accessor: 'col3'
            }
        ],
        [t]
    );

    const data = useMemo(
        () => [
            {
                col1: t('basicValuation.Listed original price'),
                col2: <DatNumberDisplayField propertyPath="Valuation.OriginalPrice" />,
                col3: ''
            },
            {
                col1: t('basicValuation.Reference value'),
                col2: <DatNumberDisplayField propertyPath="Valuation.BasePrice" />,
                col3: ''
            },
            {
                col1: t('basicValuation.Correction by first registration'),
                col2: <DatNumberDisplayField propertyPath="Valuation.InitialRegistrationCorr" />,
                col3: (
                    <EvaluationNumberInputField
                        name="Valuation.InitialRegistrationCorr"
                        placeholder={isMobile ? 'Manually' : ''}
                    />
                )
            },
            {
                col1: (
                    <>
                        {t('basicValuation.Correction by mileage details')}
                        <br />
                        {t('basicValuation.Reference driving route')}&nbsp;
                        <DatNumberDisplayField
                            fixedDecimalScale={false}
                            propertyPath="Valuation.ReferenceMileage"
                            suffix=" km"
                        />
                        <br />
                        {t('basicValuation.Mileage')}&nbsp;
                        <DatNumberDisplayField
                            fixedDecimalScale={false}
                            value={mileageEstimated ? mileageEstimated : requestMileage}
                            suffix=" km"
                        />
                        <br />
                        {t('basicValuation.Mileage difference')}&nbsp;
                        <DatNumberDisplayField fixedDecimalScale={false} value={mileageDifference} suffix=" km" />
                    </>
                ),
                col2: <DatNumberDisplayField propertyPath="Valuation.MileageCorr" />,
                col3: (
                    <EvaluationNumberInputField name="Valuation.MileageCorr" placeholder={isMobile ? 'Manually' : ''} />
                )
            },
            {
                col1: t('basicValuation.Base value determined by DAT'),
                col2: <DatNumberDisplayField propertyPath="Valuation.BasePrice2" />,
                col3: ''
            }
        ],

        [isMobile, mileageDifference, mileageEstimated, requestMileage, t]
    );

    return (
        <BasicValuationStyled>
            <Table columns={columns} data={data} />
        </BasicValuationStyled>
    );
};
