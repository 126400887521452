// RimsView
import './RimsEquipmentView.css';

import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Row, Col } from 'antd';
import { rimsListModel } from '../../stores/rimsListModel';
import { equipmentModel } from '../../stores/equipmentModel';
const { SvgLoader } = require('@wedat/react-svgmt');

export const RimsEquipmentView: FC<{}> = () => {
    const dataEquipment = useStore(equipmentModel.stores.dataEquipment);
    const eqIdRim = useStore(rimsListModel.stores.eqIdRim);

    const equipmentWithRims = dataEquipment
        .filter(eq => eqIdRim.find(eqRim => eqRim.id === eq.DatEquipmentId?.toString()))
        .map(equipment => {
            const rim = eqIdRim.find(eqRim => eqRim.id === equipment.DatEquipmentId?.toString());
            return {
                equipment,
                rim
            };
        });

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                backgroundColor: 'white',
                padding: '16px'
            }}
        >
            <Row gutter={[8, 16]}>
                {equipmentWithRims?.map(eqRimItem => (
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
                        <div
                            className={
                                eqRimItem.equipment.selected
                                    ? 'rimsEquipmentGridSelected'
                                    : 'rimsEquipmentGridNotSelected'
                            }
                            onClick={_ => {
                                equipmentModel.events.changeEquipment(eqRimItem.equipment);
                            }}
                        >
                            <SvgLoader
                                width={'100%'}
                                height={'100px'}
                                svgXML={eqRimItem.rim?.rim.svgWithCoordinates.svgStr}
                            />
                            <br />
                            {'' + eqRimItem.equipment.DatEquipmentId + ' ' + eqRimItem.equipment.Description}
                            {eqRimItem.equipment.ContainedEquipmentPositions?.EquipmentPosition.length && (
                                <>
                                    {eqRimItem.equipment.ContainedEquipmentPositions?.EquipmentPosition.map(ep => (
                                        <>
                                            <br />
                                            &nbsp; &nbsp; {'- ' + ep.DatEquipmentId + ' ' + ep.Description}
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};
