import styled, { css } from 'styled-components/macro';
import { Image } from '../common/Image';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';
import { Text } from '@wedat/ui-kit/components/Text';

export interface Props {
    borderRadius?: number;
    opened?: boolean;
}
interface CardCarProps {
    disabled: boolean;
}

export const BaseInfoWrapper = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    padding: 0 8px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 96px;
    flex-shrink: 0;
    flex-direction: column;
`;

export const MobileImage = styled(Image)`
    ${PreloaderCircle} {
        width: 40%;
    }
`;

export const Information = styled.div`
    display: flex;
    width: 100%;
    min-width: 188px;
    flex: 1;
    align-items: center;
`;

export const NumberPlate = styled(Text)`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 4px;
    padding: 1px 5px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    width: fit-content;
    font-size: 10px;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
`;

export const CarOwner = styled(Text)`
    width: 85%;
    margin-top: 4px;
    font-size: 12px;
`;

export const ReferenceNumber = styled(Text)`
    color: ${({ theme }) => theme.colors.dustBlue['600']};
    width: 100%;
`;

export const IconsContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`;

export const IconWrapper = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
    cursor: pointer;

    ${({ opened, theme }) =>
        opened &&
        css`
            color: ${theme.colors.blue['700']};
        `}
`;

export const IconThreeDotsWrapper = styled(IconWrapper)`
    margin-right: 16px;
`;

export const InfoPair = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const InfoPairHalfed = styled(InfoPair)`
    width: 70%;
    min-width: 120px;
    padding-left: 8px;
    padding-right: 8px;

    cursor: pointer;
`;

export const InfoPairRight = styled(InfoPair)`
    width: 30%;
    min-width: 68px;
`;

export const CardCarStyled = styled.div<Props & CardCarProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray['100']};
    overflow: hidden;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: '';
                background-color: rgba(255, 255, 255, 0.4);
            }

            ${ImageWrapper},
            ${InfoPairHalfed},
            ${BaseInfoWrapper}
        `};
`;

export const SlideUpMenuContainer = styled.div`
    padding: 16px 0;
`;

export const SlideUpMenuItem = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 12px 26px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    border-bottom: 2px solid ${({ theme }) => theme.colors.dustBlue['50']};
    overflow: hidden;

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.colors.dustBlue['200']};
            pointer-events: none;
        `}

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
        color: ${({ theme }) => theme.colors.blue['700']};
    }

    &:last-of-type {
        border-bottom: none;
    }
`;

export const SlideUpMenuItemTitle = styled(Text)`
    margin-left: 12px;
    color: inherit;
`;

export const SwipeContent = styled.div`
    position: absolute;
    right: -144px;
    top: 0;
    width: 144px;
    height: 96px;
    display: flex;
`;

export const SwipeContentItem = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: ${({ theme }) => theme.colors.white};
`;

export const SwipePrintout = styled(SwipeContentItem)`
    background-color: ${({ theme }) => theme.colors.blue['300']};
`;

export const SwipeAttachment = styled(SwipeContentItem)`
    background-color: ${({ theme }) => theme.colors.blue['400']};
`;
