import React, { ReactNode, useState } from 'react';
import { Container, Panel, Selector, Selectors } from './styles';
import { SLIDE_ENABLE_THRESHOLD } from './constants';

export interface SliderProps {
    children: ReactNode[];
}

export const Slider: React.FC<SliderProps> = ({ children }) => {
    const [panel, setPanel] = useState(0);
    const [touchPosition, setTouchPosition] = useState(0);

    const handleTouchMove = (currentTouch: number) => {
        const diff = touchPosition - currentTouch;
        if (
            (diff > SLIDE_ENABLE_THRESHOLD && panel < children.length - 1) ||
            (diff < -SLIDE_ENABLE_THRESHOLD && panel > 0)
        ) {
            setPanel(panel + (diff > SLIDE_ENABLE_THRESHOLD ? 1 : -1));
        }
        setTouchPosition(0);
    };
    const visibleChildren = children.filter(child => child);
    return (
        <Container
            onTouchStart={e => setTouchPosition(e.touches[0].clientX)}
            onTouchMove={e => {
                if (touchPosition) {
                    handleTouchMove(e.touches[0].clientX);
                }
            }}
        >
            {visibleChildren.map((child, index) => (
                <Panel selected={panel === index} key={index}>
                    {child}
                </Panel>
            ))}
            <Selectors>
                {visibleChildren.map((_child, index) => (
                    <Selector key={index} selected={panel === index} onClick={() => setPanel(index)} />
                ))}
            </Selectors>
        </Container>
    );
};
