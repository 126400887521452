import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { partSelectionEffects } from '@dat/shared-models/partSelection';
import { ButtonsContainer, ConfirmButton, DenyButton, Title } from './styles';

interface Props {
    handlePromptDismiss: () => void;
    isOpen: boolean;
    id: number;
}

export const ConfirmationPrompt: FC<Props> = ({ handlePromptDismiss, isOpen, id }) => {
    const { t } = useTranslation();

    return (
        <ModalPrimary
            bodyHeight="auto"
            title={t('confirmationModal.title')}
            onDismiss={handlePromptDismiss}
            isOpen={isOpen}
        >
            <Title>{t('confirmationModal.question')}</Title>
            <ButtonsContainer>
                <ConfirmButton
                    onClick={() => {
                        handlePromptDismiss();
                        partSelectionEffects.deletePartEffect(id);
                    }}
                >
                    {t('confirmationModal.yesAnswer')}
                </ConfirmButton>
                <DenyButton onClick={handlePromptDismiss} typeStyle={{ type: 'transparent' }}>
                    {t('confirmationModal.noAnswer')}
                </DenyButton>
            </ButtonsContainer>
        </ModalPrimary>
    );
};
