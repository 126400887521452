import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface FormikMailValues extends DAT2.Internal.Request.LandingMail {}

export const INITIAL_FORM_MAIL_VALUES: FormikMailValues = {
    // message: '<p>Test Message</p>\n', //mail message body (html)
    from: 'USER', //determines sender: just always leave USER
    recipients: [],
    ccRecipients: [],
    bccRecipients: [],
    templates: '-1',
    subject: '', //mail message subject
    valuationReports: [],
    documents: [], //IF of the folder(s) to send: must be known in advance,
    reports: [], //id of he REPORT: must be known in advance
    attachmentOptions: [],
    exportFiles: true,
    exportProfils: [],
    customerRoleExport: null,
    exportCombinedZip: true, // ZIP all files into one single attachment
    exportCombinedPdf: false,
    reportConfig: [],
    dossier: false,
    adzFiles: false,
    szf: false,
    useSignature: false,
    claimId: null, //claim ID
    body: '', //mail message body (html): redundant?
    calculationReports: [
        {
            text: 'Calcolo',
            printAct: 'printCostCalculation',
            category: 'CALCULATION',
            selected: false,
            favorite: false,
            options: []
        }
    ]
};

export const validationSchema = (t: TFunction) =>
    Yup.object<FormikMailValues>().shape({
        recipients: Yup.array().required(t('errors.recipientsRequired')).min(1, t('errors.recipientsRequired')),
        subject: Yup.string().required(t('errors.subjectRequired')),
        body: Yup.string().required(t('errors.contentRequired'))
    });
