import { contractEffects, contractStores } from '@dat/shared-models/contract';
import { combine, createStore } from 'effector';
import { createToggle } from '@dat/core/utils/effector/createToggle';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { i18nStores } from '@dat/shared-models/i18n';
import { sharedLoginStores } from '@dat/shared-models/auth/login';
import deepmerge from 'deepmerge';
import { FastTrackPluginOptions } from '../../../../fast-track';

// * stores
const contractId = createStore<number | string>(0);
contractId.on(contractEffects.getContractFx, (_, payload) => payload);

const fastTrackPluginOptions = combine({
    contractId: contractStores.contractId,
    datECode: sharedVehicleStores.datECode,
    currency: sharedTemplateStores.templateSettings.currency,
    productsConfiguration: sharedTemplateStores.productsConfiguration,
    locale: i18nStores.locale,
    token: sharedLoginStores.token
}).map<FastTrackPluginOptions>(({ contractId, datECode, currency, productsConfiguration, locale, token }) => ({
    isComponent: true,
    credentialsWithPartnerSignature: undefined,
    locale,
    token,
    settings: deepmerge(
        {
            contractId,
            datECode,
            debugingMode: false,
            createNewClaimAvailable: false,
            showContractFields: false,
            showPredefinedVehicleSelection: false,
            fastTrackElementsMenuModal: true,
            saveFTDamageOnChange: true,
            fastTrackElementsMenu: [
                {
                    menuGroupId: 'glasses',
                    menuGroupLabel: 'Glasses',
                    // menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                    elementDatIDs: ['DATID_0043', 'DATID_0044', 'DATID_0047']
                },
                {
                    menuGroupId: 'doors',
                    menuGroupLabel: 'Doors',
                    // menuGroupImgUrl: './MenuGroupPictures/glass.jpg',
                    elementDatIDs: ['DATID_0041', 'DATID_0042']
                }
            ],
            mainSVGURLs: [
                `${process.env.PUBLIC_URL}/FastTrackSVG/fastTrack.svg`,
                `${process.env.PUBLIC_URL}/FastTrackSVG/SW/FT1_SW.svg`,
                `${process.env.PUBLIC_URL}/FastTrackSVG/SW/FT2_SW.svg`
            ],
            resultFormat: {
                numberFormatOptions: {
                    currency
                }
            }
        },
        productsConfiguration?.['fast-track']?.settings || {},
        { arrayMerge: (_destinationArray, sourceArray) => sourceArray }
    )
}));

const [vehicleSelectionPluginCarsOpened, vehicleSelectionPluginCarsTrigger] = createToggle(false);

// * exports
export const stepperContractStores = {
    contractId,
    vehicleSelectionPluginCarsTrigger,
    vehicleSelectionPluginCarsOpened,
    fastTrackPluginOptions
};
