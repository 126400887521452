import { DomusCalculationParameters } from '../../types/factors';

export const domusCalculationInitialParameters: DomusCalculationParameters = {
    layerMono: '',
    layerAdd15Mono: '',
    layerDouble: '',
    layerAdd15Double: '',
    layerTriple: '',
    layerAdd15Triple: '',
    layerQuad: '',
    layerAdd15Quad: '',
    wasteDisposalCostsPercValue: '',
    wasteDisposalCostsMaxValue: '',
    isSurchargePaintingNeighbouringParts: false,
    isDeductionNeighbouringMetalParts: false,
    isSurchargeWasteDisposalCosts: false,
    isDeductionIdenticalParts: false,
    isSurchargeDifferingColourShades: false,
    isDeductionCommonWork: false
};
