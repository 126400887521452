import { combine, createEffect, createEvent, createStore, restore } from 'effector';

import { API2 } from '@dat/api2';
import { createInvoiceRatesSelectOptions } from '../../../utils/selectsOptions';
import { getParsedArraySafe, getFieldText } from '@dat/api2/utils';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { PayloadForListInvoiceRates } from '../../../types/invoiceRates';

import { INVOICE_RATES_LACQUER_METHODS } from './constants';

const requestConfigurationParams = combine<PayloadForListInvoiceRates>({
    isFiType: true,
    isoCountryCode: sharedTemplateStores.templateSettings.country,
    network: sharedTemplateStores.templateSettings.networkType
});

const listInvoiceRatesFx = createEffect(async (payload: PayloadForListInvoiceRates): Promise<DAT2.RefOrRateSet[]> => {
    const response = await API2.myClaim.listInvoiceRates(payload);
    const invoiceRates = getParsedArraySafe(response.return?.rateList?.refOrRateSet);

    invoiceRates.forEach(item => {
        if (item.CalculationFactor?.selectedLacquerMethod) {
            const selectedLacquerMethod = getFieldText(item.CalculationFactor.selectedLacquerMethod) as number;

            item.CalculationFactor.selectedLacquerMethod = INVOICE_RATES_LACQUER_METHODS[selectedLacquerMethod];
        }
    });

    return invoiceRates;
});
const invoiceRates = restore(listInvoiceRatesFx, []);
const selectOptions = invoiceRates.map(createInvoiceRatesSelectOptions);

const invoiceRateNameSelected = createEvent<DAT2.RefOrRateSet['name']>();
const invoiceRateSelected = createEvent<DAT2.RefOrRateSet>();
const isInvoiceRateNameNotDefinedInContract = createStore<boolean>(true);
const triggerAutoSelectFirstInvoiceRate = createEvent<void>();

//
/*** Export ***/
//
export const invoiceRatesEvents = {
    invoiceRateNameSelected,
    invoiceRateSelected,
    triggerAutoSelectFirstInvoiceRate
};
export const invoiceRatesEffects = {
    listInvoiceRatesFx
};
export const invoiceRatesStores = {
    requestConfigurationParams,
    invoiceRates,
    selectOptions,
    isInvoiceRateNameNotDefinedInContract
};
