import React, { useState } from 'react';
import { FieldsetStyled } from '../../Fieldset';
import { Label } from '../../Label';
import { Legend, LegendText } from '../../Legend';
import { InputProps } from '../types';
import { InputWrapper } from './styles';

export const Input: React.FC<InputProps> = ({ addNewTag, filled, label, status, newTagValue, setNewTagValue }) => {
    const [focused, setFocused] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTagValue(e.target.value);
    };

    const addTag = () => {
        if (newTagValue) {
            setNewTagValue('');
            addNewTag(newTagValue);
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addTag();
        }
    };

    const handleBlur = () => {
        setNewTagValue('');
        setFocused(false);
        addTag();
    };

    const handleFocus = () => {
        setFocused(true);
    };

    return (
        <>
            <InputWrapper>
                {/* because styled-component doesn't support "enterKeyHint" */}
                <input
                    value={newTagValue}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    enterKeyHint="enter"
                />
            </InputWrapper>

            <Label filled={filled} focused={focused} status={status}>
                {label}
            </Label>

            <FieldsetStyled status={status}>
                <Legend filled={filled} focused={focused}>
                    <LegendText fontSize="12px" textOverflow="ellipsis">
                        {label}
                    </LegendText>
                </Legend>
            </FieldsetStyled>
        </>
    );
};
