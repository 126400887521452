import React, { ButtonHTMLAttributes, FC } from 'react';
import { ButtonStyled } from './styles';

type Primary = { type: 'primary' };
type Secondary = { type: 'secondary' };
type Transparent = { type: 'transparent' };
type Square = { type: 'square'; color: Color; size?: 36 | 48 };

export type Color = 'blue' | 'yellow' | 'red';
export type TypeStyle = Primary | Secondary | Square | Transparent;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    /*** To set shape style for button */
    typeStyle?: TypeStyle;
    /*** To set style for square shape button use
        type: 'square'
        color: 'blue' | 'yellow' | 'red';
        size?: 36 | 48;
     */

    /*** To set text case style*/
    uppercase?: boolean;

    /*** To set disabled value for button*/
    disabled?: boolean;

    /*** To set value for button*/
    value?: string;

    /*** To set button action type (*only for primary)*/
    submit?: boolean;

    /*** Set this for an button click handler*/
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    /*** Set this for height: 100% */
    fillHeight?: boolean;
}

export const Button: FC<ButtonProps> = ({
    typeStyle = { type: 'primary' },
    uppercase = false,
    disabled = false,
    submit = false,
    onClick = () => {},
    children,
    ...rest
}) => {
    const childrenContent = (() => {
        if (children) {
            return children;
        } else if (typeStyle.type === 'primary') {
            return submit ? 'submit' : 'button';
        }
    })();

    return (
        <ButtonStyled
            type={submit ? 'submit' : 'button'}
            typeStyle={typeStyle}
            uppercase={uppercase}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {childrenContent}
        </ButtonStyled>
    );
};
