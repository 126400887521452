import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { positionsEffects } from '../../../stores/positions';
import { modalsEvents } from '../../../stores/ui/modals';
import { WorkLogicFormValues } from '../../../types/worklogicForm';

import { RadioGroupItem } from '@wedat/ui-kit/components/RadioGroup';
import { RadioGroupField, CheckboxField } from '@wedat/ui-kit/Formik';
import {
    Wrapper,
    CancelButton,
    AdditionalWrapper,
    ButtonStyled,
    ButtonsWrapper,
    MarginWrapper,
    RadioGroupFieldWrapper
} from '../styles';

export const Content = () => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<WorkLogicFormValues>();

    const isNeighbouringFalse = !values.isSurchargePaintingNeighbouringParts;

    useEffect(() => {
        setFieldValue('surcharge', isNeighbouringFalse ? '' : 'partial');
    }, [isNeighbouringFalse, setFieldValue]);

    const surchargeRadioItems: RadioGroupItem<string>[] = [
        {
            value: 'complete',
            label: t('workLogic.complete')
        },
        {
            value: 'partial',
            label: t('workLogic.partial')
        }
    ];

    return (
        <Wrapper>
            <AdditionalWrapper>
                <CheckboxField
                    name="isSurchargePaintingNeighbouringParts"
                    label={t('workLogic.surchargeForPainting')}
                />
                <RadioGroupFieldWrapper>
                    <RadioGroupField
                        disabled={!values.isSurchargePaintingNeighbouringParts}
                        name="surcharge"
                        column
                        items={surchargeRadioItems}
                    />
                </RadioGroupFieldWrapper>
                <MarginWrapper>
                    <CheckboxField
                        name="isSurchargeWasteDisposalCosts"
                        label={t('workLogic.surchargeWasteDisposalCosts')}
                    />
                </MarginWrapper>
                <MarginWrapper>
                    <CheckboxField name="isDeductionIdenticalParts" label={t('workLogic.deducationForInstallation')} />
                </MarginWrapper>
                <MarginWrapper>
                    <CheckboxField
                        name="isDeductionNeighbouringMetalParts"
                        label={t('workLogic.deducationForNeighbouring')}
                    />
                </MarginWrapper>
                <MarginWrapper>
                    <CheckboxField
                        name="isSurchargeDifferentColourShades"
                        label={t('workLogic.surchargeDifferentColourShades')}
                    />
                </MarginWrapper>
                <MarginWrapper>
                    <CheckboxField name="isDeductionCommonWork" label={t('workLogic.deducationForCommonWork')} />
                </MarginWrapper>
            </AdditionalWrapper>
            <ButtonsWrapper>
                <ButtonStyled
                    onClick={() => {
                        positionsEffects.saveWorkLogicFx({ workLogicFormValues: values });
                        modalsEvents.setIsWorkLogicOpen();
                    }}
                >
                    {t('common.ok')}
                </ButtonStyled>
                <CancelButton onClick={modalsEvents.setIsWorkLogicOpen}>{t('common.cancel')}</CancelButton>
            </ButtonsWrapper>
        </Wrapper>
    );
};
