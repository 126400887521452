import { createEffect } from 'effector';
import { contractEffects as sharedModelsContractEffects } from '@dat/shared-models/contract';
import { createPayloadForCreateOrUpdateContract } from '../../utils/createPayloadForCreateOrUpdateContract';
import { ValuatePluginResult } from '../../pages/Claim/Valuation/types';

const { updateCurrentContractFx } = sharedModelsContractEffects;

// Save memoFields ValuateFinance
const saveMemoFieldsValuateFx = createEffect((valuatePluginResult: ValuatePluginResult) =>
    updateCurrentContractFx(createPayloadForCreateOrUpdateContract({ valuatePluginResult }))
);

export const contractEffects = {
    saveMemoFieldsValuateFx
};
