import { Form as FormFormik } from 'formik';
import styled from 'styled-components/macro';

export const Container = styled.div`
    padding: 24px 32px;
    height: 100%;
`;

export const Form = styled(FormFormik)`
    height: 100%;
`;
