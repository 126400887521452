import styled, { css } from 'styled-components';
import { InputProps } from '../Input/types';
import { Text } from '../Text';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const ErrorStyled = styled.div`
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.red[900]};
    margin-left: 12px;
`;

export const errorStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.bgColor};
    border-left: 3px solid ${({ theme }) => theme.colors.mark};
    box-shadow: none;
    &:focus {
        outline: none;
        border-left: 3px solid ${({ theme }) => theme.colors.bgColor};
        box-shadow: 0 0 0 1.6px ${({ theme }) => theme.colors.dustBlue['900']};
    }
`;

export const successStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.bgColor};
    box-shadow: none;
    border-left: 3px solid ${({ theme }) => theme.colors.primary};
    &:focus {
        outline: none;
        border-left: 3px solid ${({ theme }) => theme.colors.bgColor};
        box-shadow: 0 0 0 1.6px ${({ theme }) => theme.colors.dustBlue['900']};
    }
`;

export const defaultStyle = css`
    border: 1px solid ${({ theme }) => theme.colors.dustBlue[100]};
    &:focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.colors.dustBlue[100]};
    }
`;

export const TextAreaWrapperStyled = styled.div<Pick<InputProps, 'borderRadius'>>`
    display: flex;
    position: relative;
    user-select: none;
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '8px')};
    width: 100%;
`;

interface Props {
    border?: boolean;
    height: string;
    status?: 'error' | 'success' | 'default';
}

export const StyledTextArea = styled.textarea<Props>`
    display: inline-flex;
    ${({ height }) => height && `height: ${height};`}
    padding: 10px;
    padding-right: 20px;
    border: none;
    outline: none;
    resize: none;
    font-weight: 500;
    font-size: 18px;
    overflow: auto;

    &::placeholder {
        font-weight: 500;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.gray_40};
    }
`;

export const TextStyled = styled(Text)`
    margin-left: auto;
`;
