import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

interface Props {
    isComponent?: boolean;
}

export const Container = styled.div<Props>`
    display: flex;
    //TODO: try to solve DATG-1793 without --dat-vh
    height: ${({ isComponent }) => (isComponent ? '100%' : 'calc(var(--dat-vh, 1vh) * 100)')};

    ${media.laptopSmall`
        position: relative;
    `}
`;

export const MobileMessagingAreaWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.bgColor};
`;
