import deepmerge from 'deepmerge';

import { getFieldText } from '@dat/api2/utils';
import { convertManualFieldsToDossier } from './convertManualFieldsToDossier';

import { ManualFields } from '../types/manualFields';
import { PartialDeep } from 'type-fest';

export const createPayloadForCreateDossier = (
    dossier: DAT2.Dossier,
    manualFields: PartialDeep<ManualFields>
): DAT2.Request.ChangeDossierN => {
    const dossierWithManualFields = deepmerge(dossier, convertManualFieldsToDossier(manualFields), {
        arrayMerge: overwriteArray
    });

    omitExcessPropertiesFromDossier(dossierWithManualFields, manualFields);

    return {
        Dossier: dossierWithManualFields
    };
};

/* If some fields are not deleted, server responds with error */
const omitExcessPropertiesFromDossier = (dossier: DAT2.Dossier, manualFields: PartialDeep<ManualFields>) => {
    const EquipmentSign = getFieldText(dossier.Valuation?.EquipmentSign);
    const hasEquipmentPriceManualValue = manualFields.Valuation?.EquipmentPrice !== undefined;
    const shouldOmitLumpSumValuation = EquipmentSign !== 'flat-rate value' || hasEquipmentPriceManualValue;

    delete dossier.Vehicle?.Tires;

    if (shouldOmitLumpSumValuation) {
        delete dossier.Valuation?.ManualEquipmentOriginalPrice;
        delete dossier.Valuation?.EquipmentPercentage;
        delete dossier.Valuation?.EquipmentDecreaseType;
        delete dossier.Valuation?.EquipmentDecreaseTypeRemaining;
    }
};

const overwriteArray = (_leftArray: Array<any>, rightArray: Array<any>) => rightArray;
