import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { DiscountTypes } from '../../constants/enums';
import { PositionsFormValues } from '../../types/positions';
import { gross } from '../../utils/totalSums/gross';
import { netCorr } from '../../utils/totalSums/netCorr';
import { vat } from '../../utils/totalSums/vat';
import { getTotalItem, getTotalItemIndex } from '../../utils/getTotalItem';

import { Text } from '@wedat/ui-kit/components/Text';
import { NumberInputField, SwitcherField } from '@wedat/ui-kit/Formik';
import { ArrowExpand } from '@wedat/ui-kit/components/Icons';
import { Info, NumberFormatStyled } from '../core/styles';
import {
    AnimatedCard,
    Column,
    ColumnMobile,
    ContainerMobile,
    DiscountDisplaySwapMobile,
    FlexDescriptionWrapperMobile,
    FlexWrapperMobile,
    FlexWrapperTitleMobile,
    GridWrapper,
    IconWrapperMobile,
    InputWrapperMobile,
    NumberFormatSmall,
    TextStyled,
    TotalHeaderRow
} from './styles';

export const TotalSumsMobile = () => {
    const [totalHeaderScrollY, setTotalHeaderScrollY] = useState(0);
    const {
        values: { totalSumsItems }
    } = useFormikContext<PositionsFormValues>();
    const { t } = useTranslation();
    const {
        colors: { dustBlue }
    } = useTheme();

    const containerRef = useRef(null);
    const [isTableShown, setIsTableShown] = useState(false);

    const total = getTotalItem(totalSumsItems);
    const totalIndex = getTotalItemIndex(totalSumsItems);

    const totalHeaderScrollLimit = totalHeaderScrollY > 80;

    useClickOutside(containerRef, () => {
        setIsTableShown(false);
    });

    const handleScroll = () => {
        setTotalHeaderScrollY(window.scrollY);
    };

    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ContainerMobile ref={containerRef}>
            {total && (
                <TotalHeaderRow scrollLimit={totalHeaderScrollLimit}>
                    <Column>
                        <Text font="font12" color={dustBlue['200']}>
                            {t('totalSums.netDesignation')}
                        </Text>
                        <NumberFormatSmall value={total.ValueNet} />
                    </Column>
                    <Column>
                        <Text font="font12" color={dustBlue['200']}>
                            {t('totalSums.discount')}
                        </Text>
                        <NumberFormatSmall value={total.ValueNetDiscountPerc} />
                    </Column>
                    <Column>
                        <Text font="font12" color={dustBlue['200']}>
                            {t('totalSums.netCorr')}
                        </Text>
                        <NumberFormatSmall value={netCorr(total)} />
                    </Column>
                    <Column>
                        <Text font="font12" color={dustBlue['200']}>
                            {t('totalSums.vat')}
                        </Text>
                        <NumberFormatSmall value={vat(total)} />
                    </Column>
                    <Column>
                        <Text font="font12" color={dustBlue['200']}>
                            {t('totalSums.gross')}
                        </Text>
                        <NumberFormatSmall value={gross(total)} />
                    </Column>
                </TotalHeaderRow>
            )}
            <FlexWrapperTitleMobile onClick={() => setIsTableShown(!isTableShown)}>
                <Text font="mobileHeader" color={dustBlue['900']}>
                    Total sums
                </Text>
                <IconWrapperMobile show={isTableShown}>
                    <ArrowExpand />
                </IconWrapperMobile>
            </FlexWrapperTitleMobile>
            <AnimatedCard show={isTableShown}>
                {total && (
                    <Info key={total.Description}>
                        <FlexWrapperMobile>
                            <TextStyled font="footnote" fontWeight={700}>
                                {total.Description}
                            </TextStyled>
                            <InputWrapperMobile>
                                <NumberInputField
                                    width={116}
                                    name={
                                        total.DiscountDisplayType === DiscountTypes.absolute
                                            ? `totalSumsItems[${totalIndex}].ValueNetDiscountValue`
                                            : `totalSumsItems[${totalIndex}].ValueNetDiscountPerc`
                                    }
                                    inputSize="small"
                                    placeholder="Discount"
                                    borderRadius={4}
                                    defaultLeftPadding={3}
                                />
                                <SwitcherField
                                    name={`totalSumsItems[${totalIndex}].DiscountDisplayType`}
                                    switchOptions={[
                                        {
                                            child: <DiscountDisplaySwapMobile>%</DiscountDisplaySwapMobile>,
                                            value: DiscountTypes.percent
                                        },
                                        {
                                            child: <DiscountDisplaySwapMobile>€</DiscountDisplaySwapMobile>,
                                            value: DiscountTypes.absolute
                                        }
                                    ]}
                                />
                            </InputWrapperMobile>
                        </FlexWrapperMobile>
                    </Info>
                )}
                {totalSumsItems.map((item, idx) => {
                    if (item.Identifier !== 'total') {
                        return (
                            <Info key={item.Description}>
                                <FlexDescriptionWrapperMobile>
                                    <TextStyled font="footnote">{item.Description}</TextStyled>
                                    <InputWrapperMobile>
                                        {item.DiscountDisplayType === DiscountTypes.absolute ? (
                                            <NumberInputField
                                                width={116}
                                                name={`totalSumsItems[${idx}].ValueNetDiscountValue`}
                                                inputSize="small"
                                                placeholder="Discount"
                                                borderRadius={4}
                                                defaultLeftPadding={3}
                                            />
                                        ) : (
                                            <NumberInputField
                                                width={116}
                                                name={`totalSumsItems[${idx}].ValueNetDiscountPerc`}
                                                inputSize="small"
                                                placeholder="Discount"
                                                borderRadius={4}
                                                defaultLeftPadding={3}
                                            />
                                        )}

                                        <SwitcherField
                                            name={`totalSumsItems[${idx}].DiscountDisplayType`}
                                            switchOptions={[
                                                {
                                                    child: <DiscountDisplaySwapMobile>%</DiscountDisplaySwapMobile>,
                                                    value: DiscountTypes.percent
                                                },
                                                {
                                                    child: <DiscountDisplaySwapMobile>€</DiscountDisplaySwapMobile>,
                                                    value: DiscountTypes.absolute
                                                }
                                            ]}
                                        />
                                    </InputWrapperMobile>
                                </FlexDescriptionWrapperMobile>
                                <GridWrapper>
                                    <ColumnMobile>
                                        <Text font="font12" fontWeight={700} color={dustBlue['200']}>
                                            Net
                                        </Text>
                                        <NumberFormatStyled value={item.ValueNet} />
                                    </ColumnMobile>
                                    <ColumnMobile>
                                        <Text font="font12" fontWeight={700} color={dustBlue['200']}>
                                            Net. cor.
                                        </Text>
                                        <NumberFormatStyled value={netCorr(item)} />
                                    </ColumnMobile>
                                    <ColumnMobile>
                                        <Text font="font12" fontWeight={700} color={dustBlue['200']}>
                                            VAT
                                        </Text>
                                        <NumberFormatStyled value={vat(item)} />
                                    </ColumnMobile>
                                    <ColumnMobile>
                                        <Text font="font12" fontWeight={700} color={dustBlue['200']}>
                                            Gross
                                        </Text>
                                        <NumberFormatStyled value={gross(item)} />
                                    </ColumnMobile>
                                </GridWrapper>
                            </Info>
                        );
                    }

                    return null;
                })}
            </AnimatedCard>
        </ContainerMobile>
    );
};
