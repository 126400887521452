import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button } from '@wedat/ui-kit/components/Button';
import { FactorsParametersObject } from '../../types/factors';
import { ButtonWrapper, Container } from './styles';

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormikContext<FactorsParametersObject>();

    return (
        <Container>
            <ButtonWrapper>
                <Button onClick={() => handleSubmit()}>{t('common.save')}</Button>
            </ButtonWrapper>
        </Container>
    );
};
