import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Notifications } from '../Notifications';
import { Text, Tooltip } from '@wedat/ui-kit';

interface ControllableLinkProps {
    disabled?: boolean;
    isIcon?: boolean;
    withTooltip?: boolean;
}

export const Item = styled.div<ControllableLinkProps>`
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
    margin-left: 12px;
    margin-right: 12px;
    transition: color 150ms;

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }

    ${media.tablet`
        width: 100%;
    `}
`;

export const ItemLast = styled.div`
    padding-right: 12px;
`;

export const StyledText = styled(Text)`
    color: ${({ theme }) => theme.colors.textPrimary};
    transition: color 150ms;
    &:hover {
        color: ${({ theme }) => theme.colors.textHeader};
    }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s;

    ${media.tablet`
        width: 100%;
    `}

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export const NotificationsStyled = styled(Notifications)`
    margin-right: 10px;
`;

export const StyledTooltip = styled(Tooltip)`
    height: 48px;
    display: flex;
    align-items: center;
`;
