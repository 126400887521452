// ManualSetView
import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Row, Col, Spin, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import { Dvn, ObjectInfo } from '../../../types/svgGrapaTypes';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { getManufacturerByDatECode } from '@dat/api2dat/services/vehicleSelectionPredefined';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { useTranslation } from 'react-i18next';

import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { sparePartSearchModel } from '../../../stores/sparePartSearchModel';

import './antFormCustomize.css';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { workTimeUnits } from '../../../initData/workTimeUnits';
import { mappingDVNWorkTypeWorkLevelModel } from '../../../stores/mappingDVNWorkTypeWorkLevelModel';

const { Option } = Select;

const layout = {
    // labelCol: { span: 10 },
    labelCol: { xs: 10, sm: 10, md: 10 },
    // wrapperCol: { span: 24 }
    wrapperCol: { xs: 10, sm: 10, md: 10 }
};

// const styleFormItem = {
//     marginBottom: '8px'
// };

export function ManualSet({
    // isLeft,
    el,
    dvnLR,
    repairPosition
}: {
    // isLeft: boolean;
    el: ObjectInfo;
    dvnLR: Dvn;
    repairPosition?: RepairPosition;
}) {
    let SparePartNumber,
        SparePartPrice,
        SparePartDiscount,
        WorkTime,
        WorkPrice,
        WorkType,
        WorkLevel,
        PreDamage,
        PredamagePercentage;

    const currentExtPartNoInfo = useStore(sparePartSearchModel.stores.currentExtPartNoInfo);
    const sparePartPositionLIst = useStore(sparePartSearchModel.stores.sparePartPosition);

    const getExtPartNoInfoByFullVehicleAndIntPartNoFxPending = useStore(
        sparePartSearchModel.effects.getExtPartNoInfoByFullVehicleAndIntPartNoFx.pending
    );
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    const manufacturer = pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode
        ? getManufacturerByDatECode(pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode)
        : '';

    const useWorkTypeLevel = pluginOptions?.settings?.useWorkTypeLevel;

    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);

    const country = pluginOptions?.settings?.locale?.country;

    const sparePartPosition =
        currentExtPartNoInfo?.Dossiers.Dossier[0].SparePartPositions.SparePartPosition[0] ||
        sparePartPositionLIst?.find(sparePart => repairPosition?.DATProcessId === sparePart.DATProcessId);

    // const { DATProcessId, PartNumber, LastUPE, LastUPEDate, Orderable, WorkTimeMin, WorkTimeMax } = sparePartPosition;

    const { t } = useTranslation();

    const { currentAssemblyGroup } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

    const mappingDVNWorkTypeWorkLevel = useStore(mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel);

    const hst = datECode.slice(2, 5);

    if (repairPosition)
        ({
            SparePartNumber,
            SparePartPrice,
            SparePartDiscount,
            WorkTime,
            WorkPrice,
            WorkType,
            WorkLevel,
            PreDamage,
            PredamagePercentage
        } = repairPosition);
    // const isPresentAdvancedData = !!SparePartNumber || !!SparePartPrice || !!SparePartDiscount || !!WorkTime;

    // const [showFieldsManualSet, setShowFieldsManualSet] = useState(isPresentAdvancedData);

    const [currentDvn, setCurrentDvn] = useState(0);
    const [showPreDamagePrc, setShowPreDamagePrc] = useState(false);

    useEffect(() => {
        if (currentDvn !== dvnLR.dvn)
            setShowPreDamagePrc(typeof repairPosition?.PredamagePercentage === 'number' ? true : false);
    }, [dvnLR.dvn, currentDvn, repairPosition?.PredamagePercentage]);

    useEffect(() => {
        setCurrentDvn(dvnLR.dvn);
    }, [dvnLR.dvn]);

    const timeUnitsPerHour = workTimeUnits.find(unit => unit.hst === hst)?.timeUnitsPerHour;
    const workTimeInTimeUnits = WorkTime ? Math.round(WorkTime * (timeUnitsPerHour || 1)) : 0;

    const showFieldsManualSet = true;

    if (!currentAssemblyGroup) return null;

    const RepairType = 'replace';
    const defWorkType = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workType;
    const defWorkLevel = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workLevel;

    return (
        <>
            {/* <div style={{ textAlign: isLeft ? 'left' : 'right', padding: '4px' }}>
                <Button
                    icon={showFieldsManualSet ? <UpOutlined /> : <DownOutlined />}
                    onClick={() => setShowFieldsManualSet(!showFieldsManualSet)}
                >
                    {' '}
                    Advanced
                </Button>
            </div> */}
            {showFieldsManualSet && (
                <>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.PreDamage')}
                        </Col>
                        <Col span={12}>
                            <Select
                                size="large"
                                defaultValue="none"
                                value={showPreDamagePrc ? 'preDamagePrc' : PreDamage ? 'preDamage' : 'none'}
                                style={{
                                    width: '100%'
                                }}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        PreDamage: value === 'preDamage',
                                        ...(value !== 'preDamagePrc' && { PredamagePercentage: null })
                                    });
                                    setShowPreDamagePrc(value === 'preDamagePrc');
                                }}
                            >
                                <Option value="none">{t('ManualRepairPositionForm.PreDamageOptions.none')}</Option>
                                <Option value="preDamage">
                                    {t('ManualRepairPositionForm.PreDamageOptions.preDamage')}
                                </Option>
                                <Option value="preDamagePrc">
                                    {t('ManualRepairPositionForm.PreDamageOptions.preDamagePrc')}
                                </Option>
                            </Select>
                        </Col>
                        {showPreDamagePrc && (
                            <>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ManualRepairPositionForm.PreDamage')} (%)
                                </Col>
                                <Col span={12}>
                                    <InputNumber
                                        size="large"
                                        type="number"
                                        min={0}
                                        max={100}
                                        value={PredamagePercentage !== null ? PredamagePercentage : undefined}
                                        onChange={value => {
                                            RepairPositionsModel.event.addReplaceEDamage({
                                                DATProcessId: dvnLR.dvn,
                                                Description: el.titleMenu,
                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                PreDamage: false,
                                                PredamagePercentage: value
                                            });
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.SparePartNumber')}
                        </Col>
                        <Col span={12}>
                            <Input
                                size="large"
                                addonAfter={
                                    <a
                                        href={
                                            'https://www.google.com/search?tbm=isch&q=' +
                                            (SparePartNumber || sparePartPosition?.PartNumber) +
                                            ' ' +
                                            manufacturer
                                        }
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        <SearchOutlined />
                                    </a>
                                }
                                value={SparePartNumber}
                                placeholder={sparePartPosition?.PartNumber.toString()}
                                onChange={e => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartNumber: e.target.value
                                    });
                                }}
                            />
                        </Col>

                        {!!SparePartNumber && sparePartPosition?.PartNumber && (
                            <>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ExtPartNoInfoView.PartNumber')}
                                </Col>
                                <Col span={12}>
                                    {getExtPartNoInfoByFullVehicleAndIntPartNoFxPending ? (
                                        <Spin />
                                    ) : (
                                        <>
                                            {sparePartPosition?.PartNumber}
                                            &nbsp; &nbsp;
                                            <a
                                                href={
                                                    'https://www.google.com/search?tbm=isch&q=' +
                                                    sparePartPosition?.PartNumber +
                                                    ' ' +
                                                    manufacturer
                                                }
                                                target={'_blank'}
                                                rel="noreferrer"
                                            >
                                                <SearchOutlined />
                                            </a>
                                        </>
                                    )}
                                </Col>
                            </>
                        )}
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.SparePartPrice')}
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                placeholder={new Intl.NumberFormat(country, {
                                    minimumFractionDigits: 2
                                }).format(sparePartPosition?.LastUPE || 0)}
                                type="number"
                                value={SparePartPrice}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartPrice: value
                                    });
                                }}
                            />
                        </Col>
                        {!!SparePartPrice && sparePartPosition?.LastUPE && (
                            <>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ExtPartNoInfoView.LastUPE')}
                                </Col>
                                <Col span={12}>
                                    {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending &&
                                        new Intl.NumberFormat(country, {
                                            minimumFractionDigits: 2
                                        }).format(sparePartPosition?.LastUPE || 0)}
                                </Col>
                            </>
                        )}

                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.SparePartDiscount')}
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                type="number"
                                value={SparePartDiscount}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartDiscount: value
                                    });
                                }}
                            />
                        </Col>
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.WorkTime')}
                        </Col>
                        {timeUnitsPerHour && (
                            <>
                                <Col span={12}>
                                    <InputNumber
                                        size="large"
                                        type="number"
                                        value={workTimeInTimeUnits}
                                        onChange={value => {
                                            RepairPositionsModel.event.addReplaceEDamage({
                                                DATProcessId: dvnLR.dvn,
                                                Description: el.titleMenu,
                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                                WorkPrice: 0
                                            });
                                        }}
                                    />
                                    <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                                </Col>
                                <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                            </>
                        )}
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                type="number"
                                value={WorkTime}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        WorkTime: Math.round(value * 100) / 100,
                                        WorkPrice: 0
                                    });
                                }}
                            />
                            <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                        </Col>

                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ManualRepairPositionForm.WorkPrice')}
                        </Col>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                type="number"
                                value={WorkPrice}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        WorkTime: 0,
                                        WorkPrice: value
                                    });
                                }}
                            />
                        </Col>
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ExtPartNoInfoView.WorkTime')}
                        </Col>
                        <Col span={12}>
                            {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending &&
                                (sparePartPosition?.WorkTimeMin === sparePartPosition?.WorkTimeMax
                                    ? sparePartPosition?.WorkTimeMin
                                    : '' + sparePartPosition?.WorkTimeMin + ' - ' + sparePartPosition?.WorkTimeMax)}
                        </Col>

                        {useWorkTypeLevel && (
                            <>
                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ExtPartNoInfoView.WorkType')}
                                </Col>
                                <Col span={12}>
                                    <Select
                                        size="large"
                                        style={{ width: 120 }}
                                        defaultValue={defWorkType}
                                        // value={WorkType === null ? WorkType : WorkType || defWorkType}
                                        value={WorkType}
                                        onChange={value => {
                                            RepairPositionsModel.event.addReplaceEDamage({
                                                DATProcessId: dvnLR.dvn,
                                                Description: el.titleMenu,
                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                WorkType: value || null
                                            });
                                        }}
                                        allowClear
                                    >
                                        <Option value="other">{t('WorkType.Other')}</Option>
                                        <Option value="mechanic">{t('WorkType.Mechanic')}</Option>
                                        <Option value="car body">{t('WorkType.CarBody')}</Option>
                                        <Option value="electric">{t('WorkType.Electric')}</Option>
                                    </Select>
                                </Col>

                                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                                    {t('ExtPartNoInfoView.WorkLevel')}
                                </Col>
                                <Col span={12}>
                                    <Select
                                        size="large"
                                        style={{ width: 120 }}
                                        defaultValue={defWorkLevel}
                                        value={WorkLevel}
                                        onChange={value => {
                                            RepairPositionsModel.event.addReplaceEDamage({
                                                DATProcessId: dvnLR.dvn,
                                                Description: el.titleMenu,
                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                WorkLevel: value || null
                                            });
                                        }}
                                        allowClear
                                    >
                                        <Option value="0">{t('WorkLevel.0')}</Option>
                                        <Option value="1">{t('WorkLevel.1')}</Option>
                                        <Option value="2">{t('WorkLevel.2')}</Option>
                                        <Option value="3">{t('WorkLevel.3')}</Option>
                                    </Select>
                                </Col>
                            </>
                        )}
                    </Row>

                    <Form
                        style={{ margin: '16px 0px 16px 0px' }}
                        {...layout}
                        // onSubmitCapture={handleSubmit}
                    >
                        {/* <Form.Item label={i18n.t('ManualRepairPositionForm.Description')}>
                        <Input />
                    </Form.Item> */}

                        {/* <Form.Item label={t('ManualRepairPositionForm.SparePartNumber')} style={styleFormItem}>
                            <Input
                                value={SparePartNumber}
                                onChange={e => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartNumber: e.target.value
                                    });
                                }}
                            />
                        </Form.Item> */}

                        {/* <Form.Item label={t('ExtPartNoInfoView.PartNumber')} style={styleFormItem}>
                            {getExtPartNoInfoByFullVehicleAndIntPartNoFxPending ? (
                                <Spin /> 
                            ) : (
                                sparePartPosition?.PartNumber
                            )}
                            {/* <Input value={PartNumber} onChange={e => {}} /> */}
                        {/* </Form.Item> */}
                        {/* 
                        <Form.Item label={t('ManualRepairPositionForm.SparePartPrice')} style={styleFormItem}>
                            <InputNumber
                                value={SparePartPrice}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartPrice: value
                                    });
                                }}
                            /> */}

                        {/* {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending && (
                            <>
                                &nbsp;&nbsp;&nbsp;
                                {t('ExtPartNoInfoView.LastUPE')}
                                &nbsp;&nbsp;
                                {new Intl.NumberFormat('ru', {
                                    minimumFractionDigits: 2
                                }).format(sparePartPosition?.LastUPE || 0)}
                                &nbsp;&nbsp;&nbsp;
                                {t('ExtPartNoInfoView.Orderable')}: &nbsp;&nbsp;&nbsp;
                                <Checkbox checked={sparePartPosition?.Orderable} />
                            </>
                        )} */}
                        {/* </Form.Item> */}

                        {/* <Form.Item label={t('ExtPartNoInfoView.LastUPE')} style={styleFormItem}>
                            {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending &&
                                new Intl.NumberFormat('ru', {
                                    minimumFractionDigits: 2
                                }).format(sparePartPosition?.LastUPE || 0)}
                        </Form.Item> */}

                        {/* <Form.Item label={t('ExtPartNoInfoView.Orderable')}>
                        <Checkbox checked={sparePartPosition?.Orderable} />
                    </Form.Item> */}

                        {/* <Form.Item label={t('ManualRepairPositionForm.SparePartDiscount')} style={styleFormItem}>
                            <InputNumber
                                value={SparePartDiscount}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        SparePartDiscount: value
                                    });
                                }}
                            />
                        </Form.Item> */}

                        {/* <Form.Item label={t('ManualRepairPositionForm.WorkTime')} style={styleFormItem}>
                            <InputNumber
                                value={WorkTime}
                                onChange={value => {
                                    RepairPositionsModel.event.addReplaceEDamage({
                                        DATProcessId: dvnLR.dvn,
                                        Description: el.titleMenu,
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        WorkTime: value
                                    });
                                }}
                            /> */}
                        {/* {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending && (
                            <>
                                &nbsp;&nbsp;&nbsp;
                                {t('ExtPartNoInfoView.WorkTime')}
                                &nbsp;&nbsp;
                                {sparePartPosition?.WorkTimeMin === sparePartPosition?.WorkTimeMax
                                    ? sparePartPosition?.WorkTimeMin
                                    : '' + sparePartPosition?.WorkTimeMin + ' - ' + sparePartPosition?.WorkTimeMax}
                            </>
                        )} */}
                        {/* </Form.Item> */}

                        {/* <Form.Item label={t('ExtPartNoInfoView.WorkTime')} style={styleFormItem}>
                            {!getExtPartNoInfoByFullVehicleAndIntPartNoFxPending &&
                                (sparePartPosition?.WorkTimeMin === sparePartPosition?.WorkTimeMax
                                    ? sparePartPosition?.WorkTimeMin
                                    : '' + sparePartPosition?.WorkTimeMin + ' - ' + sparePartPosition?.WorkTimeMax)}
                        </Form.Item> */}

                        {/* <Form.Item label={i18n.t('ManualRepairPositionForm.SparePartAmount')}>
                       <InputNumber />
                    </Form.Item> */}
                    </Form>
                </>
            )}
        </>
    );
}
