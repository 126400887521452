import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { PositionsFormValues } from '../../types/positions';
import { DiscountTypes } from '../../constants/enums';
import { getTotalItem, getTotalItemIndex } from '../../utils/getTotalItem';

import { NumberInputField, SwitcherField } from '@wedat/ui-kit/Formik';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import {
    Container,
    AnimatedTableWrapper,
    DataRightPart,
    FlexWrapper,
    TextStyled,
    IconWrapper,
    Column,
    DataWrapper,
    TextWidthStyled,
    DataWrapperStyled,
    DumbComponent,
    IconWrapperFlipped
} from './styles';
import { DiscountDisplaySwap, Wrapper } from '../core/styles';
import { Text } from '@wedat/ui-kit/components/Text';
import { ArrowExpand } from '@wedat/ui-kit/components/Icons';
import { netCorr } from '../../utils/totalSums/netCorr';
import { vat } from '../../utils/totalSums/vat';
import { gross } from '../../utils/totalSums/gross';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';

export const TotalSums = () => {
    const {
        values: { totalSumsItems }
    } = useFormikContext<PositionsFormValues>();
    const { t } = useTranslation();
    const {
        colors: { dustBlue }
    } = useTheme();
    const [isTableShown, setIsTableShown] = useState(false);

    const containerRef = useRef(null);

    const triggerAnimation = useCallback(() => {
        setIsTableShown(!isTableShown);
    }, [isTableShown]);

    useClickOutside(containerRef, () => {
        if (isTableShown) {
            triggerAnimation();
        }
    });

    const total = getTotalItem(totalSumsItems);
    const totalIndex = getTotalItemIndex(totalSumsItems);

    const titles = useMemo(
        () => [
            t('totalSums.netDesignation'),
            t('totalSums.discount'),
            t('totalSums.netCorr'),
            t('totalSums.vat'),
            t('totalSums.gross')
        ],
        [t]
    );

    const data = useMemo(
        () =>
            totalSumsItems.map((item, idx) => (
                <DataWrapperStyled>
                    <TextWidthStyled font="note" fontWeight={700}>
                        {item?.Description}
                    </TextWidthStyled>
                    <DataRightPart>
                        <FlexWrapper>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={item?.ValueNet} />
                            </Text>
                        </FlexWrapper>
                        <Wrapper>
                            <NumberInputField
                                width={120}
                                name={
                                    item.DiscountDisplayType === DiscountTypes.absolute
                                        ? `totalSumsItems[${idx}].ValueNetDiscountValue`
                                        : `totalSumsItems[${idx}].ValueNetDiscountPerc`
                                }
                                placeholder={t('totalSums.discount')}
                                inputSize="small"
                                borderRadius={4}
                            />
                            <SwitcherField
                                name={`totalSumsItems[${idx}].DiscountDisplayType`}
                                switchOptions={[
                                    {
                                        child: <DiscountDisplaySwap>%</DiscountDisplaySwap>,
                                        value: DiscountTypes.percent
                                    },
                                    {
                                        child: <DiscountDisplaySwap>€</DiscountDisplaySwap>,
                                        value: DiscountTypes.absolute
                                    }
                                ]}
                            />
                        </Wrapper>
                        <FlexWrapper>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={netCorr(item)} />
                            </Text>
                        </FlexWrapper>
                        <FlexWrapper>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={vat(item)} />
                            </Text>
                        </FlexWrapper>
                        <FlexWrapper isLast>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={gross(item)} />
                            </Text>
                            <DumbComponent />
                        </FlexWrapper>
                    </DataRightPart>
                </DataWrapperStyled>
            )),
        [t, totalSumsItems]
    );

    return (
        <Container ref={containerRef}>
            {total && (
                <DataWrapper>
                    <TextWidthStyled font="note" fontWeight={700}>
                        {total.Description}
                    </TextWidthStyled>
                    <DataRightPart>
                        <FlexWrapper>
                            <TextStyled font="note" color={dustBlue['200']}>
                                {t('totalSums.netDesignation')}
                            </TextStyled>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={total.ValueNet} />
                            </Text>
                        </FlexWrapper>
                        <Wrapper>
                            {total.DiscountDisplayType === DiscountTypes.absolute ? (
                                <NumberInputField
                                    width={120}
                                    name={`totalSumsItems[${totalIndex}].ValueNetDiscountValue`}
                                    placeholder={t('totalSums.discount')}
                                    inputSize="small"
                                    borderRadius={4}
                                />
                            ) : (
                                <NumberInputField
                                    width={120}
                                    name={`totalSumsItems[${totalIndex}].ValueNetDiscountPerc`}
                                    placeholder={t('totalSums.discount')}
                                    inputSize="small"
                                    borderRadius={4}
                                />
                            )}

                            <SwitcherField
                                name={`totalSumsItems[${totalIndex}].DiscountDisplayType`}
                                switchOptions={[
                                    {
                                        child: <DiscountDisplaySwap>%</DiscountDisplaySwap>,
                                        value: DiscountTypes.percent
                                    },
                                    {
                                        child: <DiscountDisplaySwap>€</DiscountDisplaySwap>,
                                        value: DiscountTypes.absolute
                                    }
                                ]}
                            />
                        </Wrapper>
                        <FlexWrapper>
                            <TextStyled font="note" color={dustBlue['200']}>
                                {t('totalSums.netCorr')}
                            </TextStyled>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={netCorr(total)} />
                            </Text>
                        </FlexWrapper>
                        <FlexWrapper>
                            <TextStyled font="note" color={dustBlue['200']}>
                                {t('totalSums.vat')}
                            </TextStyled>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={vat(total)} />
                            </Text>
                        </FlexWrapper>
                        <FlexWrapper>
                            <TextStyled font="note" color={dustBlue['200']}>
                                {t('totalSums.gross')}
                            </TextStyled>
                            <Text font="note" fontWeight={700}>
                                <NumberFormat value={gross(total)} />
                            </Text>
                            <IconWrapper show={!isTableShown} onClick={triggerAnimation}>
                                <ArrowExpand />
                            </IconWrapper>
                        </FlexWrapper>
                    </DataRightPart>
                </DataWrapper>
            )}
            <AnimatedTableWrapper show={isTableShown}>
                <Column>
                    <DataWrapperStyled>
                        <TextWidthStyled font="note" color={dustBlue['200']}>
                            {t('common.designation')}
                        </TextWidthStyled>
                        <DataRightPart>
                            {titles.map((item, idx) => {
                                const isLast = idx === titles.length - 1;
                                if (isLast) {
                                    return (
                                        <FlexWrapper isLast>
                                            <Text font="note" color={dustBlue['200']}>
                                                {item}
                                            </Text>
                                            <IconWrapperFlipped show={isTableShown} onClick={triggerAnimation}>
                                                <ArrowExpand />
                                            </IconWrapperFlipped>
                                        </FlexWrapper>
                                    );
                                }

                                return (
                                    <FlexWrapper>
                                        <Text font="note" color={dustBlue['200']}>
                                            {item}
                                        </Text>
                                    </FlexWrapper>
                                );
                            })}
                        </DataRightPart>
                    </DataWrapperStyled>
                    {data}
                </Column>
            </AnimatedTableWrapper>
        </Container>
    );
};
