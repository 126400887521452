import { createStore, createEvent, combine, sample } from 'effector';
import { AvailableAssemblyGroup, OnMouseOver } from '../types/graphicTypes';
import { findDvnsByDatId } from '../utils/findDvnByDatId';
import { findGroupByDvns } from '../utils/findGroupByDvns';

import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { GenericSVGGraphic, genericSVGGraphicModel } from './genericSVGGraphicModel';

const onMouseOver = createStore<OnMouseOver | null>(null);
const setOnMouseOver = createEvent<OnMouseOver | null>();
onMouseOver.on(setOnMouseOver, (_, newval) => newval);

const clearOnMouseOver = createEvent();
onMouseOver.on(clearOnMouseOver, _ => ({
    currentAssemblyGroup: undefined,
    datId: undefined,
    currentGenericGraphic: undefined
}));

const setHighlightObjectByDVN = createEvent<number[]>();
onMouseOver.on(setHighlightObjectByDVN, (oldState, dvns) => {
    const { availableAssemblyGroups: availableAssemblyGroupsList } =
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore.getState();

    const findedGroup = findGroupByDvns(dvns, availableAssemblyGroupsList);

    if (!availableAssemblyGroupsList) return oldState;

    return {
        currentAssemblyGroup: findedGroup?.currentAssemblyGroup,
        datId: findedGroup?.currentAssemblyGroupObject?.datid,
        currentGenericGraphic: undefined
    };
});

const setHighlightGenericGraphic = createEvent<GenericSVGGraphic | undefined>();
onMouseOver.on(setHighlightGenericGraphic, (_, genericGraphic) => ({
    currentAssemblyGroup: undefined,
    datId: '',
    currentGenericGraphic: genericGraphic
}));

const setHighlightByAssemblyGroupAdnDatid = createEvent<{
    availableAssemblyGroup: AvailableAssemblyGroup;
    datId: string | undefined;
}>();
onMouseOver.on(setHighlightByAssemblyGroupAdnDatid, (_, { availableAssemblyGroup, datId }) => ({
    currentAssemblyGroup: availableAssemblyGroup,
    datId,
    currentGenericGraphic: undefined
}));

const setHighlightObjectByFTDatId = createEvent<string>();
sample({
    source: combine([availableAssemblyGroupsModel.stores.datECode, genericSVGGraphicModel.stores.dvnMapStore]),
    clock: setHighlightObjectByFTDatId,
    fn: ([datECode, dvnMap], ftdatId) => findDvnsByDatId(ftdatId, datECode, dvnMap),
    target: setHighlightObjectByDVN
});

export const onMouseOverModel = {
    stores: {
        onMouseOver
    },
    events: {
        setHighlightByAssemblyGroupAdnDatid,
        setHighlightObjectByDVN,
        setHighlightGenericGraphic,
        setHighlightObjectByFTDatId,
        clearOnMouseOver,
        setOnMouseOver
    }
};
