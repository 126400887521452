import { TFunction } from 'i18next';
import { RadioGroupItem } from '@wedat/ui-kit/components/RadioGroup';

export const INITIAL_WORKBENCH_VALUES = {
    time: 'noTime',
    type: 'traditional'
};

// these are default unchangeable values from workBench docs provided by Simone
export const DEFAULT_WORKBENCH_VALUES = {
    defaultPriceTrad: 245,
    defaultPriceUniv: 220,
    defaultWorkBack: 3.2,
    defaultWorkFront: 4.5,
    defaultWorkStrips: 1.7,
    defaultWorkTotal: 7
};

export const MAP_TIME_VALUES = {
    noTime: {
        key: 'dependentTime',
        value: ''
    },
    front: {
        key: 'dependentTime',
        value: DEFAULT_WORKBENCH_VALUES.defaultWorkFront
    },
    rear: {
        key: 'dependentTime',
        value: DEFAULT_WORKBENCH_VALUES.defaultWorkBack
    },
    total: {
        key: 'dependentTime',
        value: DEFAULT_WORKBENCH_VALUES.defaultWorkTotal
    },
    strips: {
        key: 'dependentTime',
        value: DEFAULT_WORKBENCH_VALUES.defaultWorkStrips
    }
};

export const MAP_PRICE_VALUES = {
    traditional: {
        key: 'price',
        value: DEFAULT_WORKBENCH_VALUES.defaultPriceTrad
    },
    universal: {
        key: 'price',
        value: DEFAULT_WORKBENCH_VALUES.defaultPriceUniv
    },
    other: {
        key: 'price',
        value: ''
    }
};

export const getTimeBlockRadioValues = (t: TFunction): RadioGroupItem<string>[] => [
    {
        value: 'noTime',
        label: t('workBench.noTime')
    },
    {
        value: 'front',
        label: t('workBench.timeForFrontEnd')
    },
    {
        value: 'rear',
        label: t('workBench.timeForRearEnd')
    },
    {
        value: 'total',
        label: t('workBench.totalTime')
    },
    {
        value: 'strips',
        label: t('workBench.timeForAnchoring')
    }
];

export const getTypeBlockRadioValues = (t: TFunction): RadioGroupItem<string>[] => [
    {
        value: 'traditional',
        label: t('workBench.traditional')
    },
    {
        value: 'universal',
        label: t('workBench.universal')
    },
    {
        value: 'other',
        label: t('workBench.other')
    }
];
