import { TFunction } from 'react-i18next';
import { KeyValueOption } from '@wedat/ui-kit/components/Select';

export const collisionDamageSelectOptions = (t: TFunction): KeyValueOption<DAT2.AccidentDamage>[] => [
    {
        key: 'accident free',
        value: 'accident free',
        label: t('vehicleCondition.collisionDamageSelect.Accident-free')
    },
    {
        key: 'damage unrepaired',
        value: 'damage unrepaired',
        label: t('vehicleCondition.collisionDamageSelect.Collision damage (unrepaired)')
    },
    {
        key: 'damage repaired',
        value: 'damage repaired',
        label: t('vehicleCondition.collisionDamageSelect.Collision damage (repaired)')
    },
    {
        key: 'unknown',
        value: 'unknown',
        label: t('Unknown')
    },
    {
        key: 'no',
        value: 'no',
        label: t('No')
    },
    {
        key: 'yes',
        value: 'yes',
        label: t('Yes')
    }
];

export const numberOfOwnersSelectOptions = (t: TFunction): KeyValueOption<DAT2.NumberOfOwnersN>[] => [
    {
        key: 'unknown',
        label: t('Unknown'),
        value: 'unknown'
    },
    {
        key: 0,
        label: '0',
        value: '0'
    },
    {
        key: 1,
        label: '1',
        value: '1'
    },
    {
        key: 2,
        label: '2',
        value: '2'
    },
    {
        key: 3,
        label: '3',
        value: '3'
    },
    {
        key: 4,
        label: '4',
        value: '4'
    },
    {
        key: 5,
        label: '5',
        value: '5'
    },
    {
        key: 6,
        label: '6',
        value: '6'
    },
    {
        key: 7,
        label: '7',
        value: '7'
    },
    {
        key: 8,
        label: '8',
        value: '8'
    },
    {
        key: 9,
        label: '9',
        value: '9'
    },
    {
        key: 10,
        label: '10',
        value: '10'
    },
    {
        key: 11,
        label: '11',
        value: '11'
    },
    {
        key: 12,
        label: '12',
        value: '12'
    },
    {
        key: 13,
        label: '13',
        value: '13'
    },
    {
        key: 14,
        label: '14',
        value: '14'
    },
    {
        key: 15,
        label: '15',
        value: '15'
    }
];
