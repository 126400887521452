import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SearchIcon } from '@wedat/ui-kit/components/Icons';
import { Button } from '@wedat/ui-kit/components/Button';
import { EquipmentTabs } from '../../../constants/activeTabsEnum';
import { IconWrapper, Container, AdditionalInfo, MainMobileContainer, CountSelecetedRows } from './styles';
import { FooterConflicts, FooterConflictsProps } from '../FooterConflicts';
import { tableFilterEvents, tableFilterStores } from '../../../stores/tableFilter';
import { FooterLegend } from '../FooterLegend';
import { useStore } from 'effector-react';

interface Props extends FooterConflictsProps {
    activeTab: string;
    availableSelectedRowsLength: number;
    existingSelectedRowsLength: number;
    transferAvailableToExisting: () => void;
    transferExistingToAvailable: () => void;
}

export const MobileBottomMenu: React.FC<Props> = ({
    activeTab,
    availableSelectedRowsLength,
    existingSelectedRowsLength,
    transferAvailableToExisting,
    transferExistingToAvailable,
    toggleConflicted,
    removeSelected,
    confirmSelected,
    onlyConflicted,
    conflictedRowsLength
}) => {
    const { t } = useTranslation();
    const mobileFilterVisible = useStore(tableFilterStores.mobileFilterVisible);
    const { toggleMobileFilterVisible } = tableFilterEvents;

    const isExisiting = activeTab === EquipmentTabs.ExistingEquipment;

    return (
        <Container>
            <AdditionalInfo>
                {isExisiting && conflictedRowsLength ? (
                    <FooterConflicts
                        toggleConflicted={toggleConflicted}
                        removeSelected={removeSelected}
                        confirmSelected={confirmSelected}
                        onlyConflicted={onlyConflicted}
                        conflictedRowsLength={conflictedRowsLength}
                    />
                ) : (
                    <FooterLegend />
                )}
            </AdditionalInfo>

            <MainMobileContainer>
                <IconWrapper onClick={() => toggleMobileFilterVisible()} actived={mobileFilterVisible}>
                    <SearchIcon />
                </IconWrapper>
                <Button
                    onClick={isExisiting ? transferAvailableToExisting : transferExistingToAvailable}
                    disabled={isExisiting ? !existingSelectedRowsLength : !availableSelectedRowsLength}
                >
                    {isExisiting ? (
                        <>
                            {t('movingToAvailable')}
                            {!!existingSelectedRowsLength && (
                                <CountSelecetedRows>{existingSelectedRowsLength}</CountSelecetedRows>
                            )}
                        </>
                    ) : (
                        <>
                            {t('movingToExisting')}
                            {!!availableSelectedRowsLength && (
                                <CountSelecetedRows>{availableSelectedRowsLength}</CountSelecetedRows>
                            )}
                        </>
                    )}
                </Button>
            </MainMobileContainer>
        </Container>
    );
};
