import React from 'react';
import { Input } from '@wedat/ui-kit/components/Input';
import { StyledWrapper, SearchInputContainer, RightSelectInput } from '../styles';
import { contractsListEvents, contractsListStores } from '../../../../stores/contractsList';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { COLUMNS } from './constants';
import { Select } from '@wedat/ui-kit/components/Select';

export const SearchInput: React.FC = () => {
    const activeGlobalSearch = useStore(contractsListStores.activeGlobalSearch);
    const { t } = useTranslation();
    const options = COLUMNS.map(name => ({
        value: name,
        label: t(`column.${name}`)
    }));

    return (
        <SearchInputContainer>
            <RightSelectInput>
                <StyledWrapper>
                    <Select
                        options={options}
                        value={activeGlobalSearch.columnName}
                        onChange={option => {
                            option?.value &&
                                contractsListEvents.setFilters.globalSearch({
                                    columnName: String(option.value),
                                    value: activeGlobalSearch.value
                                });
                        }}
                        name="globalSearchColumnSelect"
                        valueKey="value"
                        label={t('globalSearch.columnName')}
                    />
                </StyledWrapper>
            </RightSelectInput>
            <Input
                name="search"
                placeholder={t('globalSearch.placeholder')}
                onChange={e => {
                    contractsListEvents.setFilters.globalSearch({
                        columnName: activeGlobalSearch.columnName,
                        value: e.target.value
                    });
                }}
            />
        </SearchInputContainer>
    );
};
