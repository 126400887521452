import { DrawerItem } from '../types';

type Params = {
    items: DrawerItem[];
    halfScreenWidth?: number;
};

export const createDrawerItemsWithOffsets = ({ items, halfScreenWidth }: Params) => {
    const offsets: number[] = new Array(items.length).fill(0);

    const result = items.map((item, idx) => {
        const width = halfScreenWidth || items[idx].width;
        const nextItemId = idx + 1;
        const nextItem = items[nextItemId];

        if (!item.isOpen && nextItem) {
            Array.from({ length: items.length - nextItemId }).forEach((_, i) => {
                const drawerIdx = nextItemId + i;
                const isItemExist = !!items[drawerIdx];

                if (isItemExist) {
                    offsets[drawerIdx] = (offsets?.[drawerIdx] ?? 0) + width;
                }
            });
        }

        return {
            ...item,
            zIndex: items.length - idx,
            offset: offsets[idx]
        };
    });

    return result;
};
