import React, { useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Option, selectEvents, selectStores } from './stores';
import { StatusFilterWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { Select } from '@wedat/ui-kit/components/Select';

export const StatusFilter: React.FC = () => {
    const { t } = useTranslation();

    const selected = useStore(selectStores.selected);
    const options = useStore(selectStores.options);
    const { triggerLoading, setSelected } = selectEvents;

    useEffect(() => {
        triggerLoading();
    }, [triggerLoading]);

    const formatedOptions = useMemo(
        () => [
            { value: '', label: t('statusFilter.selectMenu.all') },
            ...options?.map(item => ({
                value: item.value,
                label: item.count ? `${item.label} x${item.count}` : item.label
            }))
        ],
        [options, t]
    );

    return (
        <StatusFilterWrapper>
            <Select
                options={formatedOptions}
                value={selected.value}
                onChange={option => {
                    option && setSelected(option as Option);
                }}
                name="globalStatusFilter"
                valueKey="value"
                label={t('statusFilter.placeholder')}
            />
        </StatusFilterWrapper>
    );
};
