import React, { FC } from 'react';

import { Wrapper, Button, StyledNotificationsIcon } from './styles';
import { Counter } from '@wedat/ui-kit/components/Counter';

interface Props {
    onClick: () => void;
    countOfMessages: number;
}

export const NotificationsButton: FC<Props> = ({ onClick, countOfMessages }) => (
    <Wrapper>
        <Button type="button" onClick={onClick}>
            <StyledNotificationsIcon height="100%" width="100%" />
            <Counter count={countOfMessages} />
        </Button>
    </Wrapper>
);
