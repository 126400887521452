import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

export const Button = styled.button`
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    flex: none;

    height: 40px;

    background-color: ${({ theme }) => theme.colors.deepBlue['800']};

    border: none;
    border-radius: 8px;

    &:disabled {
        background-color: ${({ theme }) => theme.colors.gray['800']};
        opacity: 0.4;
    }
`;

export const TextStyled = styled(Text)`
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.white};
`;
