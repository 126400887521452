import { FC, useState } from 'react';
import { useOrientation } from '../../../hooks/orientation';
import { PluginStep } from '../../../types';
import { RightArrowIcon } from '@wedat/ui-kit/components/Icons';

import { HelpModalStyled, Source, Video, Image, Content, Title, Description, Button } from './styles';

interface Props {
    onLastHelpClick: () => void;
    helpSteps?: PluginStep['helpSteps'];
    description?: string;
}

export const HelpModal: FC<Props> = ({ onLastHelpClick, helpSteps }) => {
    const { isLandscape } = useOrientation();
    const [nextStepIdx, setNextStepIndex] = useState(0);

    const handleClickNextHelp = (key: number) => () => {
        setNextStepIndex(key + 1);
    };

    const help = helpSteps?.[nextStepIdx];

    const clickEvent =
        !!helpSteps?.length && helpSteps.length - 1 === nextStepIdx
            ? onLastHelpClick
            : handleClickNextHelp(nextStepIdx);

    return (
        <HelpModalStyled isLandscape={isLandscape}>
            {help?.video && !help.img && (
                <Video key={help.video} autoPlay loop muted controls playsInline>
                    <Source src={help.video} type="video/mp4" />
                </Video>
            )}
            {help?.img && !help.video && <Image alt="helpImage" src={help.img} />}
            <Content>
                {help?.title && <Title>{help?.title}</Title>}
                {help?.description && <Description>{help?.description}</Description>}
                <Button onClick={clickEvent}>
                    <RightArrowIcon />
                </Button>
            </Content>
        </HelpModalStyled>
    );
};
