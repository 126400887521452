import { forward } from 'effector';

import { sharedAuthEvents, sharedAuthStores } from './index';
import { datAuthTokenName, DAT_REFRESH_TOKEN_NAME } from '@dat/core/constants';
import { apiEffects, apiEvents } from '@dat/api2/stores';
import { apiEvents as datApiEvents } from '@dat/api/stores';
import { sharedLoginEvents, sharedLoginStores } from './login';

const { validTokenReceived, loggedIn, validRefreshTokenReceived } = sharedAuthEvents;
const { loggedOut, tokenExpired, logoutManually } = sharedLoginEvents;
const { isLoggedOutManually } = sharedAuthStores;
const { token, refreshToken } = sharedLoginStores;

/* Login */
forward({
    // Token must be set before all requests are executed, so `setTokenFx` must be called
    // in `forward` instead of `.watch` because of effector's computation priority
    // https://effector.dev/docs/advanced-guide/computation-priority
    from: validTokenReceived,
    to: [token, apiEffects.setTokenFx]
});

validTokenReceived.watch(token => {
    localStorage.setItem(datAuthTokenName, token);
});

validRefreshTokenReceived.watch(token => {
    if (!localStorage.getItem(DAT_REFRESH_TOKEN_NAME)) {
        localStorage.setItem(DAT_REFRESH_TOKEN_NAME, token);
    }
});

forward({
    from: validRefreshTokenReceived,
    to: refreshToken
});

/* Logout */
token.reset(loggedOut);
isLoggedOutManually.on(logoutManually, () => true);
isLoggedOutManually.reset(loggedIn);

forward({
    from: [datApiEvents.expireToken, apiEvents.tokenExpired],
    to: tokenExpired
});

loggedOut.watch(() => {
    apiEffects.removeTokenFx();
    localStorage.removeItem(datAuthTokenName);
    localStorage.removeItem(DAT_REFRESH_TOKEN_NAME);
});

forward({
    from: sharedLoginEvents.setToken,
    to: apiEffects.setTokenFx
});
