import { attach, createEffect, createEvent, restore } from 'effector';
import { createGate } from 'effector-react';

import { PluginOptions } from '../../types/plugin';
import { greetingEvents } from '../greeting';

export const PluginGate = createGate<PluginOptions>();
const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);
const authRenderlessFx = createEffect<PluginOptions, void>();

const { setIsReadyToLogin } = greetingEvents;

const invokeReadyToLogin = attach({
    source: pluginOptions,
    effect: () => setIsReadyToLogin(true)
});

const invokeOnLoginCallbackFx = createEffect<void, void>();

const invokeOnTokenExpireCallbackFx = attach({
    source: pluginOptions,
    effect: pluginOptions => pluginOptions?.onTokenExpire?.()
});

export const pluginEvents = {
    initPlugin
};
export const pluginEffects = {
    authRenderlessFx,
    invokeOnTokenExpireCallbackFx,
    invokeOnLoginCallbackFx,
    invokeReadyToLogin
};
export const pluginStores = {
    pluginOptions
};
