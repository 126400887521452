import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';
import { subscribeEffectsToToast } from '@dat/smart-components/Toast/store';
import { contractEffects } from '@dat/shared-models/contract';
import { factorsEffects } from '../factors';
import { invoiceRatesEffects } from '../selects/invoiceRates';
import { paintTypesEffects } from '../selects/paintTypes';
import { priceDatesEffects } from '../selects/priceDates';

const isLoading = createIsLoadingStore(
    Object.values({
        ...contractEffects,
        ...factorsEffects,
        ...invoiceRatesEffects,
        ...paintTypesEffects,
        ...priceDatesEffects
    })
);

subscribeEffectsToToast([
    contractEffects.getContractFx,
    contractEffects.updateContractFx,
    ...Object.values({
        ...factorsEffects,
        ...invoiceRatesEffects,
        ...paintTypesEffects,
        ...priceDatesEffects
    })
]);

export const commonStores = {
    isLoading
};
