import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Wrapper } from '../core/styles';
import { Paintwork } from '../Paintwork';
import { Labour } from '../Labour';

export const PaintAndLabourWrapper = styled(Wrapper)`
    ${media.tabletSmall`
    flex-direction: column;
`}
`;

export const PaintworkStyled = styled(Paintwork)`
    width: calc(50% - 10px);

    ${media.tabletSmall`
        width: 100%;
        margin-bottom: 20px;
    `}
`;

export const LabourStyled = styled(Labour)`
    margin-left: 20px;
    width: calc(50% - 10px);

    ${media.tabletSmall`
        width: 100%;
        margin-left: 0;
        flex-direction: column;
    `}
`;
