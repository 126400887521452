import { TemplateParam } from './types';

export const templateMyClaim = ({
    method,
    valuesString
}: TemplateParam) => `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:dat="dat">
            <soapenv:Header/>
                <soapenv:Body>
                    <dat:${method}>
                        ${valuesString}
                    </dat:${method}>
                </soapenv:Body>
            </soapenv:Envelope>`;
