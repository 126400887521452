import React from 'react';
import { useStore } from 'effector-react';
import { modalsEvents, modalsStores } from '../../../stores/ui/modals';
import { aiClaimEvents } from '../../../stores/aiClaim';
import { AiClaim as AiClaimPlugin } from '@dat/ai-claim';

export const AiClaim: React.FC = () => {
    const isOpen = useStore(modalsStores.isAiClaimModalOpen);

    return (
        <AiClaimPlugin
            options={{
                onComplete: aiClaimEvents.aiClaimCompleted,
                isOpen,
                onDismiss: modalsEvents.toggleIsAiClaimModalOpen
            }}
        />
    );
};
