import React, { FC, InputHTMLAttributes } from 'react';
import { Container, HiddenCheckbox } from './styles';

export interface SwitcherProps extends InputHTMLAttributes<HTMLInputElement> {
    switchOptions: {
        child: React.ReactChild;
        value: string;
    }[];
    checked?: boolean;
}

// TODO: rename folder and component
export const Switcher: FC<SwitcherProps> = ({ switchOptions, checked, ...rest }) => (
    <label>
        <Container>
            <HiddenCheckbox checked={checked} {...rest} type="checkbox" />
            {checked ? switchOptions[1].child : switchOptions[0].child}
        </Container>
    </label>
);
