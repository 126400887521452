import styled, { css } from 'styled-components';
import { components } from 'react-select';
import { FC } from 'react';
import { OptionProps } from './types';

const { Option } = components;

export const StyledOption: FC<OptionProps> = styled(Option)<OptionProps>`
    &&& {
        ${({ isSelected, isMulti, isDisabled, defaultWhiteSpaceOption }) => css`
            height: 32px;
            background-color: ${({ theme }) => (isSelected ? theme.colors.blue['100'] : 'white')};
            ${({ theme }) => theme.typography.note};
            color: ${({ theme }) => theme.colors.dustBlue['900']};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 3px 0 3px 15px;
            border-radius: 0;
            display: flex;
            align-items: center;

            cursor: pointer;

            ${isMulti &&
            css`
                border: none;
            `}

            ${defaultWhiteSpaceOption &&
            css`
                white-space: normal;
                height: auto;
            `}

            ${isDisabled &&
            css`
                background-color: ${({ theme }) => theme.colors.deepBlue['50']};
                color: ${({ theme }) => theme.colors.dustBlue['200']};
                pointer-events: none;
            `}

            ${isSelected &&
            css`
                & label svg {
                    position: relative;
                    background-color: ${({ theme }) => theme.colors.blue['400']};
                }
            `}

            ${!isSelected &&
            css`
                &:hover {
                    background-color: ${({ theme }) => theme.colors.blue['50']};
                }

                & label svg {
                    display: none;
                }
            `}
        `}

        & label {
            display: inline-block;
            height: 20px;
            margin-right: 14px;
        }
    }
`;
