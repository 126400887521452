import React from 'react';

const SuccessToast = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.9248 0C7.144 0 0 7.1776 0 16C0 24.8224 7.1776 32 16 32C24.8224 32 32 24.8224 32 16C32 7.1776 24.7888 0 15.9248 0ZM16 28.8C8.9424 28.8 3.2 23.0576 3.2 16C3.2 8.9424 8.9072 3.2 15.9248 3.2C23.0256 3.2 28.8 8.9424 28.8 16C28.8 23.0576 23.0576 28.8 16 28.8Z"
            fill="currentColor"
        />
        <path
            d="M20.6031 11.0665L13.7122 18.7314L11.3969 16.156C11.3967 16.1558 11.3965 16.1556 11.3964 16.1554C11.3092 16.0575 11.204 15.9781 11.0861 15.9232C10.9677 15.8683 10.8397 15.8395 10.7095 15.8395C10.5794 15.8395 10.4513 15.8683 10.333 15.9232C10.2152 15.978 10.1102 16.0572 10.0232 16.1549C9.9353 16.2517 9.86676 16.3655 9.82033 16.4892C9.77373 16.6132 9.75 16.7456 9.75 16.8787C9.75 17.0118 9.77373 17.1442 9.82033 17.2683C9.86684 17.3921 9.93554 17.5061 10.0236 17.603C10.0238 17.6032 10.024 17.6034 10.0242 17.6036L13.0187 20.9345C13.3968 21.3551 14.0204 21.3552 14.3985 20.9346C14.3985 20.9346 14.3986 20.9346 14.3986 20.9345L21.9758 12.5141C21.976 12.5139 21.9762 12.5138 21.9764 12.5136C22.0644 12.4166 22.1332 12.3026 22.1797 12.1788C22.2263 12.0547 22.25 11.9224 22.25 11.7892C22.25 11.6561 22.2263 11.5238 22.1797 11.3997C22.1332 11.2761 22.0647 11.1623 21.9769 11.0654C21.8898 10.9677 21.7848 10.8885 21.667 10.8338C21.5487 10.7788 21.4206 10.75 21.2905 10.75C21.1603 10.75 21.0323 10.7788 20.9139 10.8338C20.796 10.8886 20.6908 10.968 20.6036 11.066C20.6034 11.0662 20.6033 11.0664 20.6031 11.0665Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
        />
    </svg>
);

export default SuccessToast;
