import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@react-google-maps/api';
import { Input } from '@wedat/ui-kit/components/Input';
import { Values } from '../types';
import { formatAddressFromAutocomplete } from './formatAddressFromAutocomplete';
import '../google.css';

interface Props {
    name: string;
    countryCode: string;
    fullAddress: Values;
    setFullAddress: React.Dispatch<React.SetStateAction<Values>>;
}

export const GooglePlacesAddress: React.FC<Props> = ({ countryCode, fullAddress, setFullAddress }) => {
    const { t } = useTranslation();
    const [formattedAddress, setFormattedAddress] = useState<string>('');
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    useEffect(() => {
        setFormattedAddress(fullAddress?.formattedAddress ?? '');
    }, [fullAddress]);

    const onLoadAutocomplete = (params: google.maps.places.Autocomplete) => {
        setAutocomplete(params);
    };

    const onPlaceChanged = () => {
        if (!!autocomplete) {
            const place = autocomplete.getPlace();
            const formatted_address = place.formatted_address ?? '';

            const { address, postcode, locality, administrative_area_level_1 } = formatAddressFromAutocomplete(
                place.address_components
            );

            setFormattedAddress(formatted_address);

            setFullAddress({
                ...fullAddress,
                street: address,
                city: locality,
                province: administrative_area_level_1,
                postcode: postcode,
                formattedAddress: formatted_address
            });
        }
    };

    const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormattedAddress(e.target.value);
    };

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof Values) => {
        setFullAddress({
            ...fullAddress,
            [fieldName]: e.target.value
        });
    };

    return (
        <>
            <Autocomplete
                onLoad={onLoadAutocomplete}
                onPlaceChanged={onPlaceChanged}
                options={{
                    componentRestrictions: {
                        country: [countryCode]
                    },
                    types: ['address']
                }}
            >
                <Input
                    label={t('form-builder:googlePlaces.formattedAddress')}
                    disabled={!countryCode}
                    value={formattedAddress}
                    onChange={onChangeAddress}
                    placeholder={t('form-builder:googlePlaces.formattedAddressPlaceholder')}
                />
            </Autocomplete>
            <Input
                label={t('form-builder:googlePlaces.province')}
                disabled={!countryCode}
                value={fullAddress?.province ?? ''}
                onChange={e => onChangeField(e, 'province')}
            />
            <Input
                label={t('form-builder:googlePlaces.city')}
                disabled={!countryCode}
                value={fullAddress?.city ?? ''}
                onChange={e => onChangeField(e, 'city')}
            />
            <Input
                label={t('form-builder:googlePlaces.street')}
                disabled={!countryCode}
                value={fullAddress?.street ?? ''}
                onChange={e => onChangeField(e, 'street')}
            />
            <Input
                label={t('form-builder:googlePlaces.postcode')}
                disabled={!countryCode}
                value={fullAddress?.postcode ?? ''}
                onChange={e => onChangeField(e, 'postcode')}
            />
        </>
    );
};
