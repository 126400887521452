import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Card, Row, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { DamageElementGroup } from '../../types/graphicTypes';
import { getLacquerDescription } from '../../utils/convertRepairPositionsToDamageList';
import { convertRepairType } from '../../utils/ftRepairToRepairPosition';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';

import { RepairPositionsModel } from '../../stores/repairPositionsModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms/GraphicSetDamageForms';
import { DATProcessIdCommentListModel } from '../../stores/DATProcessIdCommentListModel';
import { getPartsGraphics } from '@dat/dat-svg/src/utils/getSvgElementByDatId';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { ManualRepairPositionFormExtended } from '../GraphicSetDamageForms/ManualRepairPositionForm/ManualRepairPositionFormExtended';
import { manualPositionFormExtendedModel } from '../../stores/manualPositionFormExtendedModel';

// import { ReactComponent as ManualEditingIcon } from '../CommonComponents/Icons/ManualEditingIcon.svg';
import { ReactComponent as ManualPositionIcon } from '../CommonComponents/Icons/ManualPositionIcon.svg';

import { InlineIcon } from '../CommonComponents/InlineIcon';
import { ReactComponent as DeleteIcon } from '../CommonComponents/Icons/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '../CommonComponents/Icons/EditIcon.svg';

import './RepairPositionElement2.css';
import { sparePartSearchModel } from '../../stores/sparePartSearchModel';

const { SvgLoader } = require('@wedat/react-svgmt');

interface Props {
    damageElement: DamageElementGroup;
}

function removeNumberFromStringBeginning(str: string | undefined) {
    if (!str) return str;
    var index = str.substring(0).search(/[^0-9]/);
    return str.substring(index);
}

export const RepairPositionElement2: FC<Props> = ({ damageElement }) => {
    const { t } = useTranslation();
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const debuggingMode = pluginOptions?.settings?.debuggingMode;
    const showSVGIconsRepairPositions = pluginOptions?.settings?.showSVGIconsRepairPositions;

    const { availableAssemblyGroups } = useStore(availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore);

    const sparePartPosition = useStore(sparePartSearchModel.stores.sparePartPosition);

    const DATProcessIdCommentList = useStore(DATProcessIdCommentListModel.store.DATProcessIdCommentList);

    const comment = DATProcessIdCommentList.find(el => el.DATProcessId === damageElement.DATProcessId);

    const resultFormat = pluginOptions?.settings?.priceFormat;
    const dossierCurrency = pluginOptions?.settings?.contract?.Dossier?.Currency;
    const locale = pluginOptions?.locale;

    const formatter = new Intl.NumberFormat(locale || 'en-US', {
        ...resultFormat?.numberFormatOptions,
        style: 'currency',
        currency: dossierCurrency || resultFormat?.numberFormatOptions?.currency || 'EUR',
        minimumFractionDigits: resultFormat?.numberFormatOptions?.minimumFractionDigits || 2
    });

    const sparePartInfoDamageElement = sparePartPosition?.find(pos => pos.DATProcessId === damageElement.DATProcessId);

    const SparePartDiscount = damageElement.SparePartDiscount || 0;
    const SparePartPrice =
        damageElement.SparePartPrice || (!!damageElement.WorkTypeE && sparePartInfoDamageElement?.LastUPE) || 0;
    const sparePartNumber =
        damageElement.SparePartNumber || (!!damageElement.WorkTypeE && sparePartInfoDamageElement?.PartNumber) || '';

    return (
        <Card size="small" style={{ width: '100%', padding: '0px', borderWidth: '2px', borderRadius: '12px' }}>
            <Row gutter={[8, 8]}>
                {showSVGIconsRepairPositions && (
                    <Col span={4}>
                        {damageElement.repairPositions[0].PositionEntryType === 'manual' ? (
                            <ManualPositionIcon style={{ width: '100%' }} />
                        ) : (
                            <SvgLoader
                                style={{ maxWidth: '600px', maxHeight: '64px' }}
                                svgXML={
                                    getPartsGraphics([damageElement.DATProcessId], availableAssemblyGroups)?.[0].svg
                                        ?.resultSvg
                                }
                            />
                        )}
                    </Col>
                )}
                <Col span={showSVGIconsRepairPositions ? 20 : 24}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Space
                                size="small"
                                style={{
                                    float: 'right',
                                    padding: '0px 0px 8px 8px'
                                }}
                            >
                                <InlineIcon
                                    icon={<DeleteIcon />}
                                    onClick={e => {
                                        RepairPositionsModel.event.delRepairPositionByKey(
                                            damageElement.repairPositions.map(el => el.key)
                                        );

                                        e.stopPropagation();
                                    }}
                                />

                                {/* <Button
                        // size="large"
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={e => {
                            if (damageElement.repairPositions[0].PositionEntryType === 'manual') {
                                RepairPositionsModel.event.delRepairPositionByKey(damageElement.repairPositions[0].key);
                            } else {
                                RepairPositionsModel.event.delDamage(damageElement.DATProcessId);
                                e.stopPropagation();
                            }
                        }}
                    /> */}

                                <InlineIcon
                                    icon={<EditIcon />}
                                    onClick={e => {
                                        if (damageElement.repairPositions[0].PositionEntryType === 'manual') {
                                            manualPositionFormExtendedModel.events.setManualRepairPositionForm(
                                                damageElement.repairPositions[0]
                                            );
                                            rightSideDrawerModel.events.setHistoryView(
                                                ManualRepairPositionFormExtended
                                            );
                                        } else {
                                            graphicDamageSelectionModel.events.setCurrentObjectsByDVN([
                                                damageElement.repairPositions[0].DATProcessId
                                            ]);
                                            graphicDamageSelectionModel.events.updateStore({
                                                typeOfGraphic: 'FullGraphic'
                                            });
                                            rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
                                        }
                                        e.stopPropagation();
                                    }}
                                />

                                {/* <Button
                        // size="large"
                        type="text"
                        icon={<EditOutlined />}
                        onClick={e => {
                            if (damageElement.repairPositions[0].PositionEntryType === 'manual') {
                                manualPositionModel2.events.setManualRepairPositionForm(
                                    damageElement.repairPositions[0]
                                );
                                rightSideDrawerModel.events.setHistoryView(ManualRepairPositionForm2);
                            } else {
                                graphicDamageSelectionModel.events.setCurrentObjectsByDVN([
                                    damageElement.repairPositions[0].DATProcessId
                                ]);
                                graphicDamageSelectionModel.events.updateStore({
                                    typeOfGraphic: 'FullGraphic'
                                });
                                rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
                            }
                            e.stopPropagation();
                        }}
                    /> */}
                            </Space>
                            <div className="wedat-grapa-repair-position-description">
                                <b>{`${removeNumberFromStringBeginning(
                                    damageElement.repairPositions[0].Description
                                )}  ${
                                    damageElement.leftRight &&
                                    !(
                                        damageElement.repairPositions[0].Description?.endsWith('L') ||
                                        damageElement.repairPositions[0].Description?.endsWith('R')
                                    )
                                        ? damageElement.leftRight
                                        : ''
                                } `}</b>
                                {debuggingMode && !!damageElement.repairPositions[0].DATProcessId && (
                                    <>{damageElement.repairPositions[0].DATProcessId}&nbsp;</>
                                )}
                                {debuggingMode && damageElement.repairPositions[0].ConstructionGroup && (
                                    <>{damageElement.repairPositions[0].ConstructionGroup}</>
                                )}
                            </div>
                        </Col>

                        <Col span={16}>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    {!!sparePartNumber && (
                                        <>{`${t('ManualRepairPositionForm.SparePartNumber')} : ` + sparePartNumber}</>
                                    )}
                                </Col>
                                <Col span={6}>
                                    {!!damageElement.WorkTypeE ? (
                                        <div className="wedat-grapa-repair-position-type-active">
                                            <div className="wedat-grapa-repair-position-repairType">
                                                {t(
                                                    'RepairType.code.' +
                                                        convertRepairType(damageElement.WorkTypeE.RepairType).code
                                                )}
                                            </div>
                                            <div className="wedat-grapa-repair-position-WorkTime">
                                                {damageElement.WorkTypeE.WorkTime ||
                                                    damageElement.WorkTypeE.WorkPrice ||
                                                    ''}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="wedat-grapa-repair-position-type"></div>
                                    )}
                                </Col>
                                <Col span={6}>
                                    {!!damageElement.WorkTypeA ? (
                                        <div className="wedat-grapa-repair-position-type-active">
                                            <div className="wedat-grapa-repair-position-repairType">
                                                {t(
                                                    'RepairType.code.' +
                                                        convertRepairType(damageElement.WorkTypeA.RepairType).code
                                                )}
                                            </div>
                                            <div className="wedat-grapa-repair-position-WorkTime">
                                                {damageElement.WorkTypeA.WorkTime ||
                                                    damageElement.WorkTypeA.WorkPrice ||
                                                    ''}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="wedat-grapa-repair-position-type"></div>
                                    )}
                                </Col>
                                <Col span={6}>
                                    {!!damageElement.WorkTypeI ? (
                                        <div className="wedat-grapa-repair-position-type-active">
                                            <div className="wedat-grapa-repair-position-repairType">
                                                {t(
                                                    'RepairType.code.' +
                                                        convertRepairType(damageElement.WorkTypeI.RepairType).code
                                                )}
                                            </div>
                                            <div className="wedat-grapa-repair-position-WorkTime">
                                                {damageElement.WorkTypeI.WorkTime ||
                                                    damageElement.WorkTypeI.WorkPrice ||
                                                    ''}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="wedat-grapa-repair-position-type"></div>
                                    )}
                                </Col>
                                <Col span={6}>
                                    {!!damageElement.WorkTypeL ? (
                                        <div className="wedat-grapa-repair-position-type-active">
                                            <div className="wedat-grapa-repair-position-repairType">
                                                {t(
                                                    'RepairType.code.' +
                                                        convertRepairType(damageElement.WorkTypeL.RepairType).code
                                                )}
                                            </div>
                                            <div className="wedat-grapa-repair-position-WorkTime">
                                                {damageElement.WorkTypeL.WorkTime ||
                                                    damageElement.WorkTypeL.WorkPrice ||
                                                    ''}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="wedat-grapa-repair-position-type"></div>
                                    )}
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <div className="wedat-grapa-repair-position-SparePartPrice">
                                {SparePartDiscount && SparePartPrice ? <s>{formatter.format(SparePartPrice)}</s> : ''}
                                &nbsp;
                            </div>
                            <div className="wedat-grapa-repair-position-SparePartDiscount">
                                {SparePartDiscount
                                    ? `${t('ManualRepairPositionForm.SparePartDiscountShort')} : ` +
                                      SparePartDiscount +
                                      ' %'
                                    : ''}
                                &nbsp;
                            </div>
                            <div className="wedat-grapa-repair-position-ResultPrice">
                                {SparePartPrice
                                    ? formatter.format((SparePartPrice * (100 - (SparePartDiscount || 0))) / 100)
                                    : ''}
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    {damageElement.repairPositions.map(repPosItem => {
                        if (!repPosItem.RepairType) return null;

                        let addInfo = '';
                        if (repPosItem.RepairType === 'lacquer' || repPosItem.RepairType === 'lacquer demounted') {
                            addInfo = getLacquerDescription(repPosItem, t);
                        }

                        const repType = convertRepairType(repPosItem.RepairType);
                        const codeRepairType = repType?.key || '';
                        const signRepairType = repType?.code || '';

                        // if it's most frequently used codes don't show it in additional info
                        // because it is present in main part of the view
                        // show in this part only rarely used codes
                        if (['E', 'A', 'I', '/', 'L', 'M'].includes(signRepairType))
                            return (
                                <Row key={repPosItem.WorkType + repPosItem.DATProcessId.toString()}>
                                    {!!repPosItem.WorkType && repPosItem.WorkType !== 'other' && (
                                        <>
                                            <Col span={12}>{`${t('ExtPartNoInfoView.WorkType')}`}</Col>
                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                {repPosItem.WorkType}
                                            </Col>
                                        </>
                                    )}
                                    {!!repPosItem.WorkLevel && (
                                        <>
                                            <Col span={12}>{`${t('ExtPartNoInfoView.WorkLevel')}`}</Col>
                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                {repPosItem.WorkLevel}
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            );

                        return (
                            <Row key={repPosItem.key}>
                                <Col span={24}>
                                    <b>
                                        {t('repairType.' + codeRepairType)}
                                        &nbsp;
                                        {t('RepairType.code.' + signRepairType)}
                                        &nbsp;
                                        {addInfo}
                                        {/* {repPosItem.WorkPrice ? '(price)' : ''} */}
                                    </b>
                                </Col>

                                {!!repPosItem.WorkTime && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.WorkTime')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.WorkTime}
                                        </Col>
                                    </>
                                )}

                                {!!repPosItem.WorkPrice && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.WorkPrice')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.WorkPrice}
                                        </Col>
                                    </>
                                )}

                                {!!repPosItem.SparePartNumber && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.SparePartNumber')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.SparePartNumber}
                                        </Col>
                                    </>
                                )}
                                {!!repPosItem.SparePartPrice && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.SparePartPrice')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.SparePartPrice}
                                        </Col>
                                    </>
                                )}
                                {!!repPosItem.SparePartDiscount && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.SparePartDiscount')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.SparePartDiscount}
                                        </Col>
                                    </>
                                )}
                                {!!repPosItem.SparePartAmount && (
                                    <>
                                        <Col span={12}>{`${t('ManualRepairPositionForm.SparePartAmount')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.SparePartAmount}
                                        </Col>
                                    </>
                                )}

                                {!!repPosItem.WorkType && repPosItem.WorkType !== 'other' && (
                                    <>
                                        <Col span={12}>{`${t('ExtPartNoInfoView.WorkType')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.WorkType}
                                        </Col>
                                    </>
                                )}
                                {!!repPosItem.WorkLevel && (
                                    <>
                                        <Col span={12}>{`${t('ExtPartNoInfoView.WorkLevel')}`}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {repPosItem.WorkLevel}
                                        </Col>
                                    </>
                                )}
                            </Row>
                        );
                    })}

                    {!!comment && (
                        <Row>
                            <Col span={8} style={{ textAlign: 'left' }}>
                                <h3>{t('ManualRepairPositionForm.Comment')}</h3>
                            </Col>
                            <Col span={16} style={{ textAlign: 'left' }}>
                                <h3>{comment.Comment}</h3>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Card>
    );
};
