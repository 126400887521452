import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const Content = styled.div``;

export const Wrapper = styled.div`
    margin-bottom: 32px;
    display: flex;
`;

export const WrapContent = styled.div`
    margin-right: 28px;
`;

export const DescriptionMobile = styled(Text)`
    margin-bottom: 24px;
    display: none;

    ${media.phone`
        display: block;
    `}
`;

export const DescriptionDesktop = styled(Text)`
    margin-bottom: 24px;

    ${media.phone`
        display: none;
    `}
`;

export const Title = styled(Text)`
    margin-bottom: 32px;
`;

export const Image = styled.img`
    width: 234px;
    height: 140px;
`;

export const InputWrapper = styled.div`
    margin-bottom: 20px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ButtonStyled = styled(Button)`
    margin-left: 20px;
`;
