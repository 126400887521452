import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { PartialFactorsParametersObject, FactorName, FactorsParametersObject } from '../types/factors';
import { PayloadForUpdateCurrentContract } from '@dat/shared-models/contract/types';
import { PlainObject } from '@dat/core/types/common';
import { PartialDeep } from 'type-fest';

export const createPayloadForUpdateContractFactors = (
    factorsParameters: PartialFactorsParametersObject
): PayloadForUpdateCurrentContract => ({
    Dossier: {
        RepairCalculation: {
            ProcedureRelatedParameters: {
                ProcedureRelatedParameter: parseFactorsParametersObject(factorsParameters)
            }
        }
    },
    templateData: {
        entry: {
            key: CONTRACT_ENTRIES_KEYS.MEMO.labourRates,
            value: JSON.stringify(factorsParameters)
        }
    }
});

const parseFactorsParametersObject = (
    factorsParametersObject: PartialFactorsParametersObject
): DAT2.ProcedureRelatedParameter[] => {
    const result: DAT2.ProcedureRelatedParameter[] = [];

    Object.entries(factorsParametersObject).forEach(([factor, parameters]) => {
        if (parameters) {
            const procedureRelatedParameters = createProcedureRelatedParametersArray({
                factor: factor as FactorName,
                parameters
            });

            result.push(...procedureRelatedParameters);
        }
    });

    return result;
};

interface CreateProcedureRelatedParametersArrayParam {
    factor: FactorName;
    parameters: PartialDeep<FactorsParametersObject[FactorName]>;
}

const createProcedureRelatedParametersArray = ({
    factor,
    parameters
}: CreateProcedureRelatedParametersArrayParam): DAT2.ProcedureRelatedParameter[] => {
    const additionalKeys = ['_attributes'];
    const parametersEntries = Object.entries(parameters).filter(([key]) => !additionalKeys.includes(key));

    return parametersEntries.map(([parameterKey, parameterValue]) => ({
        ...extractParameterAttributes({ parameters, parameterKey }),
        factor,
        attribute: parameterKey,
        _text: parameterValue
    }));
};

interface ExtractParameterAttributesParam {
    parameters: PartialDeep<FactorsParametersObject[FactorName]>;
    parameterKey: string;
}

const extractParameterAttributes = ({ parameters, parameterKey }: ExtractParameterAttributesParam): PlainObject => {
    const result: PlainObject = {};

    if (typeof parameters === 'object' && '_attributes' in parameters && parameters._attributes) {
        Object.entries(parameters._attributes).forEach(([attrKey, attrObject]) => {
            if (parameterKey in attrObject) {
                result[attrKey] = attrObject[parameterKey];
            }
        });
    }

    return result;
};
