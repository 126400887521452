import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import { InboxPageStyled, Content, MainFiltersWrapper } from './styles';
import { Header } from '../../components/Header';
import { List } from '../../components/List';
import { Modals } from '../../components/Modals';
import { MobileBottomMenu } from '../../components/MobileBottomMenu';
import { MainFilters } from '../../components/List/Filters/MainFilters';

import { useMedia } from '@dat/core/hooks/useMedia';
import { schedulerEffects } from '@dat/shared-models/scheduler';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { contractsListStores } from '../../stores/contractsList';
import { Efficiency } from '../../components/Efficiency';
import { pluginStores } from '../../stores/plugin';

export const InboxPage: React.FC = () => {
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const isMobileSearchVisible = useStore(contractsListStores.isMobileSearchVisible);
    const isEfficiencyEnabled = !!useStore(pluginStores.pluginOptions)?.settings?.efficiency?.enabled;
    const isLaptopSmall = useMedia(sizes.laptopSmall);
    const isTabletSmall = useMedia(sizes.tabletSmall);

    useEffect(() => {
        schedulerEffects.getAppointmentsList();
    }, []);

    return (
        <InboxPageStyled>
            {pluginOptions?.isComponent && <Header />}
            {isTabletSmall && isMobileSearchVisible && (
                <MainFiltersWrapper>
                    <MainFilters />
                </MainFiltersWrapper>
            )}
            {isEfficiencyEnabled && <Efficiency />}
            <Content>
                <List />
                <Modals />
            </Content>
            {isLaptopSmall && <MobileBottomMenu />}
        </InboxPageStyled>
    );
};
