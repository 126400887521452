import { FC, HTMLAttributes, useEffect, useState } from 'react';

import { FadeContainer } from './styles';

interface Props extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
    show: boolean;
    onFadeInEnd?: () => void;
    onFadeOutEnd?: () => void;
    fullFill?: boolean;
}

export const Fade: FC<Props> = ({ children, show, onFadeInEnd, onFadeOutEnd, className, fullFill }) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show) {
            setRender(true);
            onFadeInEnd?.();
        }
    }, [onFadeInEnd, show]);

    const onAnimationEnd = () => {
        if (!show) {
            setRender(false);
            onFadeOutEnd?.();
        }
    };

    if (render) {
        return (
            <FadeContainer show={show} onAnimationEnd={onAnimationEnd} className={className} fullFill={fullFill}>
                {children}
            </FadeContainer>
        );
    }

    return null;
};
