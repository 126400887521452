import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const BottomWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 24px 32px;
    background-color: ${({ theme }) => theme.colors.white};

    ${media.laptop`
        margin-left: 0;
        margin-bottom: 120px;
    `}
`;
