import React, { CSSProperties, FC } from 'react';
import { Button, Col, Row } from 'antd';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { ReactComponent as SettingsIcon } from '../CommonComponents/Icons/SettingsIcon.svg';
import { ReactComponent as DeleteIcon } from '../CommonComponents/Icons/DeleteIcon.svg';
import { ReactComponent as ChevronDownIcon } from '../CommonComponents/Icons/ChevronDownIcon.svg';
import { ReactComponent as ChevronUpIcon } from '../CommonComponents/Icons/ChevronUpIcon.svg';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { fastTrackInterfaceModel } from '../../stores/subscribes';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { Dossier } from '@dat/api2dat/types/contractTypes';
import { commonModel } from '../../stores/commonModel';

interface SummaryPanelFooterParams {
    isMobile?: boolean;
    dossier?: Dossier;
    content?: {
        calcResult?: boolean;
        calcElements?: boolean;
    };
    style?: CSSProperties;
}
export const SummaryPanelFooter: FC<SummaryPanelFooterParams> = ({
    isMobile,
    dossier,
    content = { calcResult: true, calcElements: true },
    style
}) => {
    const { t } = useTranslation();

    const contractId = useStore(fastTrackElementModel.stores.$contractId);
    const fastTrackElements = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const calculatePending = useStore(fastTrackInterfaceModel.effects.calculate.pending);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const summaryPanelOpened = useStore(commonModel.stores.summaryPanelOpened);

    if (!pluginOptions) return <div>get pluginOptions...</div>;

    const { settings } = pluginOptions;

    const { createNewClaimAvailable, resultFormat } = settings || {};

    const calculationDisable =
        fastTrackElements.selectedElements.length === 0 || (!createNewClaimAvailable && !contractId);

    const formatter = new Intl.NumberFormat(
        resultFormat?.locales || 'ru-RU',
        resultFormat?.numberFormatOptions || {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        }
    );

    const calculationSummary = dossier?.RepairCalculation?.CalculationSummary;
    const calcResultItaly = dossier?.RepairCalculation?.CalcResultItaly;

    const sparePartsCost = calculationSummary?.SparePartsCosts?.TotalSum || 0;
    const labourCosts = calculationSummary?.LabourCosts?.TotalSum || 0;
    // vro_domus_calculation contract (Italian) doesn't contain LacquerCosts in common RepairCalculation.CalculationSummary,
    // so in this case it's necessary to take this information from RepairCalculation.CalcResultItaly (same as for Italian Calc.)
    const lacquerCosts = !!calcResultItaly
        ? calcResultItaly.SummariesItaly?.TotalSummaryItaly?.TotalSummaryItemItaly?.find(
              item => item.Identifier === 'lacquerMaterial'
          )?.ValueNet || 0
        : calculationSummary?.LacquerCosts?.TotalSum || 0;
    const totalNetCosts = calculationSummary?.TotalNetCorrected || calculationSummary?.TotalNetCosts || 0;
    const totalGrossCosts = calculationSummary?.TotalGrossCorrected || calculationSummary?.TotalGrossCosts || 0;

    return (
        <div
            className={isMobile ? 'weDat-ft-summary-panel-footer-mobile' : 'weDat-ft-summary-panel-footer'}
            style={style}
        >
            {content.calcElements && (
                <Row>
                    <Col xs={6}>
                        <div className="weDat-ft-calcElement">
                            {t('fastTrack.CalculationSummary.SpareParts')}
                            <div>{formatter.format(sparePartsCost)}</div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="weDat-ft-calcElement">
                            {t('fastTrack.CalculationSummary.Work')}
                            <div>{formatter.format(labourCosts)}</div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="weDat-ft-calcElement">
                            {t('fastTrack.CalculationSummary.Painting')}
                            <div>{formatter.format(lacquerCosts)}</div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="weDat-ft-calcElement">
                            {t('fastTrack.CalculationSummary.TotalPrice')}
                            <div>{formatter.format(totalNetCosts)}</div>
                        </div>
                    </Col>
                </Row>
            )}

            {content.calcResult && (
                <div className={isMobile ? 'weDat-ft-calcResult-wrapper-mobile' : 'weDat-ft-calcResult-wrapper'}>
                    <div className="weDat-ft-calcResult">
                        <div className="weDat-ft-calcResult-header">
                            {t('fastTrack.CalculationSummary.TotalPriceVAT')}
                        </div>
                        <div className="weDat-ft-calcResult-price">
                            {formatter.format(totalGrossCosts)}
                            {calculatePending && (
                                <SyncOutlined style={{ fontSize: '20px', marginLeft: '10px', color: '#8b98b3' }} spin />
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {!isMobile && (
                            <>
                                <Button
                                    size="large"
                                    icon={<DeleteIcon />}
                                    onClick={() => {
                                        fastTrackInterfaceModel.events.clear();
                                    }}
                                />
                                {settings?.debuggingMode && (
                                    <Button
                                        size="large"
                                        icon={<SettingsIcon />}
                                        onClick={() => {
                                            pluginOptionsModel.events.toggleShowPluginOptions();
                                        }}
                                    />
                                )}
                            </>
                        )}

                        <Button
                            disabled={calculatePending || calculationDisable}
                            className={
                                'weDat-ft-calculate-btn ' +
                                (calculatePending || calculationDisable ? '' : 'weDat-ft-main-btn')
                            }
                            size="large"
                            onClick={async () => {
                                fastTrackInterfaceModel.effects.calculate();
                            }}
                        >
                            {t('fastTrack.calculate')}
                        </Button>

                        {isMobile && (
                            <Button
                                size="large"
                                icon={
                                    summaryPanelOpened ? (
                                        <ChevronDownIcon width="24" height="24" color="#323B4C" />
                                    ) : (
                                        <ChevronUpIcon width="24" height="24" color="#323B4C" />
                                    )
                                }
                                onClick={() => {
                                    commonModel.events.setSummaryPanelOpened(!summaryPanelOpened);
                                }}
                                style={{ marginRight: '10px' }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
