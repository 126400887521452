import { XMLObjectInstance } from '@dat/api/utils';
import { EquipmentPositionObject } from '../../types';

interface EquipmentContainerField {
    EquipmentPosition: DAT.EquipmentPosition;
}

export const createEquipmentObjectsArray = (
    equipmentContainerField: XMLObjectInstance<EquipmentContainerField> | undefined,
    additionalProps?: Partial<EquipmentPositionObject>
): EquipmentPositionObject[] =>
    equipmentContainerField?.getFieldArray('EquipmentPosition').map(pos => {
        const containedEquipmentField = pos.getSingleField('ContainedEquipmentPositions');
        const children = containedEquipmentField
            ? createEquipmentObjectsArray(containedEquipmentField, {
                  isChild: true
              })
            : undefined;

        return {
            id: pos.getSingleField('DatEquipmentId')._innerText,
            description: pos.getSingleField('Description')?._innerText,
            classificationGroup: pos.getSingleField('EquipmentClass')?._innerText,
            equipmentGroup: pos.getSingleField('EquipmentGroup')?._innerText,
            category: pos.getSingleField('Category')?._innerText,
            children,
            ...additionalProps
        };
    }) || [];
