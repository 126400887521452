import { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './i18n';
// import '@dat/core/styles/fonts.css';

import { PluginOptions } from './types/plugin';
import { useStore } from 'effector-react';
import { pluginOptionsModel } from './stores/pluginOptionsModel';
import { GraphicDamageSelection } from './components/GraphicDamageSelection/GraphicDamageSelection';
import './stores/subscribes';
import { Alert } from 'antd';

interface Props {
    options?: PluginOptions;
}

const App: FC<Props> = ({ options }) => {
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    useEffect(() => {
        !!options && pluginOptionsModel.events.updatePluginOptions(options);
    }, [options]);

    if (!pluginOptions) return null;

    if (!pluginOptions?.token && !pluginOptions?.credentials)
        return (
            <Alert
                style={{ width: '640px', maxWidth: '90%', margin: '16px auto 0px auto' }}
                message="No authority provided. 'token' or 'credentials' param must be provided."
                type="warning"
            />
        );

    if (!pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode)
        return (
            <Alert
                style={{ width: '640px', maxWidth: '90%', margin: '16px auto 0px auto' }}
                message="No Vehicle provided. 'contract.Dossier.VehicleVehicle' with DatECode  param must be provided."
                type="warning"
            />
        );

    return (
        <I18nextProvider i18n={i18n}>
            <GraphicDamageSelection />
        </I18nextProvider>
    );
};

export default App;
