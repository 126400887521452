import { createEquipmentObjectsArray } from './createEquipmentObjectsArray';
import { uniqEquipmentObjects } from './uniqEquipmentObjects';

import { XMLObjectInstance } from '@dat/api/utils';
import { EquipmentPositionObject } from '../../types';

export const parseEquipmentField = (
    response: XMLObjectInstance<DAT.VXS['Vehicle']['Equipment']> | undefined
): EquipmentPositionObject[] => {
    const optionalEquipment = createEquipmentObjectsArray(response?.getSingleField('SpecialEquipment'));
    const standardEquipment = createEquipmentObjectsArray(response?.getSingleField('SeriesEquipment'), {
        standard: true
    });

    return uniqEquipmentObjects([...optionalEquipment, ...standardEquipment]);
};
