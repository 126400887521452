import { DEFAULT_WORKBENCH_VALUES, INITIAL_WORKBENCH_VALUES } from '../components/WorkBench/constants';
import { WorkbenchFormValues } from '../types/workbenchForm';

export const getWorkBenchValues = (positionsItaly: DAT2.PositionItaly[]): WorkbenchFormValues => {
    const values: WorkbenchFormValues = INITIAL_WORKBENCH_VALUES as WorkbenchFormValues;

    const workbenches = positionsItaly.filter(
        position => position.PartNumber === '9999A' || position.PartNumber === '9999B' || position.PartNumber === 99999
    );

    const mainWorkbench = workbenches.find(position => position.PartNumber === 99999);

    if (mainWorkbench?.ValueParts === DEFAULT_WORKBENCH_VALUES.defaultPriceTrad) {
        values.type = 'traditional';
    }
    if (mainWorkbench?.ValueParts === DEFAULT_WORKBENCH_VALUES.defaultPriceUniv) {
        values.type = 'universal';
    }

    if (!mainWorkbench?.WorkTimeReplace) {
        values.time = 'noTime';
    }
    if (mainWorkbench?.WorkTimeReplace === DEFAULT_WORKBENCH_VALUES.defaultWorkBack) {
        values.time = 'rear';
    }
    if (mainWorkbench?.WorkTimeReplace === DEFAULT_WORKBENCH_VALUES.defaultWorkFront) {
        values.time = 'front';
    }
    if (mainWorkbench?.WorkTimeReplace === DEFAULT_WORKBENCH_VALUES.defaultWorkStrips) {
        values.time = 'strips';
    }
    if (mainWorkbench?.WorkTimeReplace === DEFAULT_WORKBENCH_VALUES.defaultWorkTotal) {
        values.time = 'total';
    }

    const timeForPartsWorkbench = workbenches.find(position => position.PartNumber === '9999A');

    if (typeof timeForPartsWorkbench === 'object' && timeForPartsWorkbench.WorkTimeReplace) {
        values.timeForParts = `${timeForPartsWorkbench.WorkTimeReplace}` || '';
    }

    const timeForPreparatoryWorkbench = workbenches.find(position => position.PartNumber === '9999B');

    if (typeof timeForPreparatoryWorkbench === 'object' && timeForPreparatoryWorkbench.WorkTimeReplace) {
        values.timeForPreparatory = `${timeForPreparatoryWorkbench.WorkTimeReplace}` || '';
    }

    return values;
};
