// LacquerTypeView
import React, { memo } from 'react';
import { useStore } from 'effector-react';
import { Button, Col, Row, Divider, Checkbox } from 'antd';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { grapaInterfaceModel } from '../../../stores/subscribes';
import { italyAniaModel2 } from '../../../stores/italyAniaModel2';
import { useTranslation } from 'react-i18next';
import { availableAniaComplicity } from '../../../services/ItalyAniaTypes2';

function getTypeButton(dvn: number, RepairType: string, complicity: string, repairPositions?: RepairPosition[]) {
    if (!repairPositions) return 'default';
    return repairPositions.find(
        item => item.DATProcessId === dvn && item.RepairType === RepairType && item.WorkLevelItaly === complicity
    )
        ? 'primary'
        : 'default';
}

const anyaMandatoryConstantFields = {
    // WorkType: 'other',
    // IsRepairExtension: false,
    // DescriptionId: 2645001,
    // WorkCompleted: false,
    // AdhesiveMethod: false,
    // DentsOutOfReach: false,
    // LargeScale: false,
    // AdhesiveTechnologyScale: false,
    // AlloyLM: false,
    // ConstructionType: 6,
    // ConstructionGroupPolygon: 7
    // WorkIndication: 0
};

export const AniaTimesView2 = memo(
    () => {
        // const { t } = useTranslation();
        // const arrayAvailableLacquer = getArrayAvailableLacquer(t);
        // const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
        const {
            currentAssemblyGroup,
            // currentAssemblyGroupObjects,
            currentAssemblyGroupObject: currentObj
        } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);

        const { t } = useTranslation();

        // if (currentDVNs?.length !== 1) return null;
        // const dvn = currentDVNs[0];

        // const lacquer = repairPositions.find(item => item.DATProcessId === dvn && item.RepairType === 'lacquer');

        const currentAniaTimes = useStore(italyAniaModel2.stores.currentAniaTimes);
        const repairPositions = useStore(grapaInterfaceModel.stores.currentDvnRepairPositions);
        const listCurrentAggregatesByDvn = useStore(italyAniaModel2.stores.listCurrentAggregatesByDvn);

        // const italyAniaRequests = useStore(italyAniaModel.stores.italyAniaRequests);
        const currentDVNs = useStore(graphicDamageSelectionModel.stores.currentDVNs);
        if (currentDVNs?.length !== 1) return null;
        const dvn = currentDVNs[0];

        // const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;

        // const dvnRepairTimes = getDvnRepairTimesExample;

        // const repairPositions = useStore(RepairPositionsModel.store.repairPositions);

        if (!currentAniaTimes) return null;

        const dvnRepairTimes = currentAniaTimes;

        // const aggregateByComponentDvn = getAggregateByComponentDvn;

        const Description = currentObj?.titleMenu || '';
        const ConstructionGroupId = currentAssemblyGroup?.assemblyGroup.assemblyGroupId || 0;
        const ConstructionGroup = currentAssemblyGroup?.assemblyGroup.name || '';

        function setCommonComplicity(complicity: string) {
            RepairPositionsModel.event.delRepairTypeDamage({
                dvn,
                repairType: 'replace'
            });
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'overhaul',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    WorkLevelItaly: complicity,
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes?.overhaul?.[complicity] || 0) / 100
                }
            ]);
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'dis- and mounting',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    WorkLevelItaly: complicity,
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes['dis- and- mounting']?.[complicity] || 0) / 100
                }
            ]);
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'lacquer',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    // LacquerLevel: 'surface',
                    // LacquerLevelId: 1,
                    WorkLevelItaly: complicity,
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes.lacquer?.[complicity] || 0) / 100
                }
            ]);
        }

        function setCommonReplace() {
            RepairPositionsModel.event.delRepairTypeDamage({
                dvn,
                repairType: 'overhaul'
            });
            RepairPositionsModel.event.delRepairTypeDamage({
                dvn,
                repairType: 'fixing'
            });
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'replace',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    WorkLevelItaly: 'replace',
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes.overhaul?.['replace'] || 0) / 100
                }
            ]);
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'dis- and mounting',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    WorkLevelItaly: 'replace',
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes['dis- and- mounting']?.['replace'] || 0) / 100
                }
            ]);
            RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                {
                    ...anyaMandatoryConstantFields,
                    DATProcessId: dvn,
                    RepairType: 'lacquer',
                    Description,
                    ConstructionGroupId,
                    ConstructionGroup,
                    PositionEntryType: 'graphical',
                    // LacquerLevel: 'surface',
                    // LacquerLevelId: 1,
                    WorkLevelItaly: 'replace',
                    // WorkManualInput: '#',
                    WorkTime: (dvnRepairTimes.lacquer?.['replace'] || 0) / 100
                }
            ]);
        }

        const isReplacePosition =
            repairPositions.find(
                item => item.DATProcessId === dvn && item.RepairType === 'replace' && item.WorkLevelItaly === 'replace'
            ) &&
            repairPositions.find(
                item =>
                    item.DATProcessId === dvn &&
                    item.RepairType === 'dis- and mounting' &&
                    item.WorkLevelItaly === 'replace'
            ) &&
            repairPositions.find(
                item => item.DATProcessId === dvn && item.RepairType === 'lacquer' && item.WorkLevelItaly === 'replace'
            );

        return (
            <div style={{ margin: '0px 0px 0px 0px' }}>
                {/* <div
            // className={'laquerSelectionButtonsContainer'}
            > */}

                <Row gutter={[8, 8]}>
                    {/* <Col md={{ span: listCurrentAggregatesByDvn.length > 0 && isReplacePosition ? 12 : 24 }} span={24}> */}
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col span={9}>
                                <Button
                                    style={{ width: '100%', border: '0px', textAlign: 'left', padding: '8px' }}
                                    size="large"
                                >
                                    {t('Ania.Time')}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', border: '0px', padding: '0px' }}
                                    size="large"
                                    onClick={() =>
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn,
                                            repairType: 'dis- and mounting'
                                        })
                                    }
                                >
                                    {t('Ania.dis- and- mounting')}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', border: '0px', padding: '0px' }}
                                    size="large"
                                    onClick={() => {
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn,
                                            repairType: 'overhaul'
                                        });
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn,
                                            repairType: 'replace'
                                        });
                                    }}
                                >
                                    {t('Ania.overhaul')}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', border: '0px', padding: '0px' }}
                                    size="large"
                                    onClick={() =>
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn,
                                            repairType: 'lacquer'
                                        })
                                    }
                                >
                                    {t('Ania.lacquer')}
                                </Button>
                            </Col>

                            {availableAniaComplicity.map(
                                complicity =>
                                    (dvnRepairTimes.overhaul?.[complicity] ||
                                        dvnRepairTimes['dis- and- mounting']?.[complicity] ||
                                        dvnRepairTimes.lacquer?.[complicity]) && (
                                        <>
                                            <Col span={9}>
                                                <Button
                                                    style={{
                                                        width: '100%',
                                                        border: '0px',
                                                        padding: '8px',
                                                        textAlign: 'left'
                                                    }}
                                                    size="large"
                                                    onClick={() => {
                                                        setCommonComplicity(complicity);
                                                    }}
                                                >
                                                    {t('Ania.Complicity.' + complicity)}
                                                </Button>
                                            </Col>
                                            <Col span={5}>
                                                <Button
                                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                                    size="large"
                                                    type={getTypeButton(
                                                        dvn,
                                                        'dis- and mounting',
                                                        complicity,
                                                        repairPositions
                                                    )}
                                                    onClick={() => {
                                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck(
                                                            [
                                                                {
                                                                    DATProcessId: dvn,
                                                                    RepairType: 'dis- and mounting',
                                                                    Description,
                                                                    ConstructionGroupId,
                                                                    ConstructionGroup,
                                                                    PositionEntryType: 'graphical',
                                                                    WorkLevelItaly: complicity,
                                                                    // WorkManualInput: '#',
                                                                    WorkTime:
                                                                        (dvnRepairTimes['dis- and- mounting']?.[
                                                                            complicity
                                                                        ] || 0) / 100
                                                                }
                                                            ]
                                                        );
                                                    }}
                                                >
                                                    {convertAniaTime(
                                                        dvnRepairTimes?.['dis- and- mounting']?.[complicity]
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col span={5}>
                                                <Button
                                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                                    size="large"
                                                    type={getTypeButton(dvn, 'overhaul', complicity, repairPositions)}
                                                    onClick={() => {
                                                        RepairPositionsModel.event.delRepairTypeDamage({
                                                            dvn,
                                                            repairType: 'replace'
                                                        });
                                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck(
                                                            [
                                                                {
                                                                    DATProcessId: dvn,
                                                                    RepairType: 'overhaul',
                                                                    Description,
                                                                    ConstructionGroupId,
                                                                    ConstructionGroup,
                                                                    PositionEntryType: 'graphical',
                                                                    WorkLevelItaly: complicity,
                                                                    // WorkManualInput: '#',
                                                                    WorkTime:
                                                                        (dvnRepairTimes.overhaul?.[complicity] || 0) /
                                                                        100
                                                                }
                                                            ]
                                                        );
                                                    }}
                                                >
                                                    {convertAniaTime(dvnRepairTimes.overhaul?.[complicity])}
                                                </Button>
                                            </Col>

                                            <Col span={5}>
                                                <Button
                                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                                    size="large"
                                                    type={getTypeButton(dvn, 'lacquer', complicity, repairPositions)}
                                                    onClick={() => {
                                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck(
                                                            [
                                                                {
                                                                    DATProcessId: dvn,
                                                                    RepairType: 'lacquer',
                                                                    Description,
                                                                    ConstructionGroupId,
                                                                    ConstructionGroup,
                                                                    PositionEntryType: 'graphical',
                                                                    // LacquerLevel: 'surface',
                                                                    // LacquerLevelId: 1,
                                                                    WorkLevelItaly: complicity,
                                                                    // WorkManualInput: '#',
                                                                    WorkTime:
                                                                        (dvnRepairTimes.lacquer?.[complicity] || 0) /
                                                                        100
                                                                }
                                                            ]
                                                        );
                                                    }}
                                                >
                                                    {convertAniaTime(dvnRepairTimes.lacquer?.[complicity])}
                                                </Button>
                                            </Col>
                                        </>
                                    )
                            )}

                            {/* ============================ Replace ======================================== */}

                            <Col span={9}>
                                <Button
                                    style={{ width: '100%', border: '0px', padding: '8px', textAlign: 'left' }}
                                    size="large"
                                    onClick={setCommonReplace}
                                >
                                    {t('Ania.Complicity.replace')}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                    size="large"
                                    type={getTypeButton(dvn, 'dis- and mounting', 'replace', repairPositions)}
                                    // onClick={setCommonReplace}
                                    onClick={() =>
                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                                            {
                                                DATProcessId: dvn,
                                                RepairType: 'dis- and mounting',
                                                Description,
                                                ConstructionGroupId,
                                                ConstructionGroup,
                                                PositionEntryType: 'graphical',
                                                WorkLevelItaly: 'replace',
                                                // WorkManualInput: '#',
                                                WorkTime: (dvnRepairTimes['dis- and- mounting']?.['replace'] || 0) / 100
                                            }
                                        ])
                                    }
                                >
                                    {convertAniaTime(dvnRepairTimes['dis- and- mounting']?.['replace'])}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                    size="large"
                                    type={getTypeButton(dvn, 'replace', 'replace', repairPositions)}
                                    // onClick={setCommonReplace}
                                    onClick={() => {
                                        RepairPositionsModel.event.delRepairTypeDamage({
                                            dvn,
                                            repairType: 'overhaul'
                                        });
                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                                            {
                                                DATProcessId: dvn,
                                                RepairType: 'replace',
                                                Description,
                                                ConstructionGroupId,
                                                ConstructionGroup,
                                                PositionEntryType: 'graphical',
                                                WorkLevelItaly: 'replace',
                                                // WorkManualInput: '#',
                                                WorkTime: (dvnRepairTimes.overhaul?.['replace'] || 0) / 100
                                            }
                                        ]);
                                    }}
                                >
                                    {convertAniaTime(dvnRepairTimes.overhaul?.['replace'])}
                                </Button>
                            </Col>
                            <Col span={5}>
                                <Button
                                    style={{ width: '100%', paddingLeft: '4px', paddingRight: '4px' }}
                                    size="large"
                                    type={getTypeButton(dvn, 'lacquer', 'replace', repairPositions)}
                                    // onClick={setCommonReplace}
                                    onClick={() =>
                                        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
                                            {
                                                DATProcessId: dvn,
                                                RepairType: 'lacquer',
                                                Description,
                                                ConstructionGroupId,
                                                ConstructionGroup,
                                                PositionEntryType: 'graphical',
                                                // LacquerLevel: 'surface',
                                                // LacquerLevelId: 1,
                                                WorkLevelItaly: 'replace',
                                                // WorkManualInput: '#',
                                                WorkTime: (dvnRepairTimes.lacquer?.['replace'] || 0) / 100
                                            }
                                        ])
                                    }
                                >
                                    {convertAniaTime(dvnRepairTimes.lacquer?.['replace'])}
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    {isReplacePosition && listCurrentAggregatesByDvn.length > 0 && (
                        // <Col md={{ span: 12 }} span={24}>
                        <Col span={24}>
                            <Divider>{t('Ania.Aggregates')}</Divider>
                            <AniaAggregates />
                        </Col>
                    )}
                </Row>

                {/* <Divider />
            <h3>Available Agregate</h3>
            <Row gutter={[8, 8]}>
                {aggregateByComponentDvn.aggregates.map(aggregate => (
                    <Col span={24}>
                        <Button style={{ width: '100%', textAlign: 'left' }} size="large">
                            {aggregate.description}
                        </Button>
                    </Col>
                ))}
            </Row> */}

                {/* </div> */}
            </div>
        );
    },
    () => true
);

function convertAniaTime(time?: number) {
    if (!time) return '0.00';
    return (time / 100).toFixed(2);
}

function AniaAggregates() {
    const listCurrentAggregatesByDvn = useStore(italyAniaModel2.stores.listCurrentAggregatesByDvn);
    const currentDVNs = useStore(graphicDamageSelectionModel.stores.currentDVNs);

    if (currentDVNs?.length !== 1) return null;
    // const dvn = currentDVNs[0];

    return (
        <>
            <Divider />
            {/* <h3>Available Agregate</h3> */}
            <Row gutter={[8, 8]}>
                {listCurrentAggregatesByDvn.map(aggregate => (
                    <>
                        <Col span={24}>
                            <Button
                                type={aggregate.isReplace ? 'primary' : 'default'}
                                style={{ width: '100%', textAlign: 'left' }}
                                size="large"
                                onClick={() => {
                                    if (aggregate.isReplace) {
                                        aggregate.dvn.forEach(item =>
                                            RepairPositionsModel.event.delDamage(item.positionDvn)
                                        );
                                    } else {
                                        aggregate.dvn.forEach(item =>
                                            italyAniaModel2.events.addAniaReplace(item.positionDvn)
                                        );
                                    }
                                }}
                            >
                                {aggregate.title}
                            </Button>
                        </Col>
                        {aggregate.dvn.map(dvnAggregate => (
                            <Col offset={2} span={22}>
                                {/* <Button
                                    type={
                                        dvnAggregate.DVNSX === dvn || dvnAggregate.DVNDX === dvn ? 'primary' : 'default'
                                    }
                                    style={{ width: '100%', textAlign: 'left' }}
                                    size="large"
                                >
                                    {dvnAggregate.DSPEL}
                                </Button> */}

                                <Checkbox
                                    checked={dvnAggregate.isReplace}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            italyAniaModel2.events.addAniaReplace(dvnAggregate.positionDvn);
                                        } else {
                                            RepairPositionsModel.event.delDamage(dvnAggregate.positionDvn);
                                        }
                                    }}
                                >
                                    {dvnAggregate.DSPEL + ' ' + dvnAggregate.positionDvn}
                                </Checkbox>
                            </Col>
                        ))}
                    </>
                ))}
            </Row>
        </>
    );
}
