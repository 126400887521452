import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@wedat/ui-kit/components/Text';
import notFound from '../../assets/images/notFound.png';
import { NotFoundWrapper, NotFoundImg } from './styles';

export const NotFound: React.FC = () => {
    const { t } = useTranslation();

    return (
        <NotFoundWrapper>
            <NotFoundImg src={notFound} alt={t('notFound')} />
            <Text>{t('errors.notFound')}</Text>
        </NotFoundWrapper>
    );
};
