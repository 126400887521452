import React from 'react';

const Delete = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.54541 7.5V13.5C6.54541 13.6989 6.63161 13.8897 6.78505 14.0303C6.93849 14.171 7.1466 14.25 7.36359 14.25C7.58059 14.25 7.78869 14.171 7.94213 14.0303C8.09557 13.8897 8.18177 13.6989 8.18177 13.5V7.5C8.18177 7.30109 8.09557 7.11032 7.94213 6.96967C7.78869 6.82902 7.58059 6.75 7.36359 6.75C7.1466 6.75 6.93849 6.82902 6.78505 6.96967C6.63161 7.11032 6.54541 7.30109 6.54541 7.5Z"
            fill="#C9CFDC"
        />
        <path
            d="M10.6364 6.75C10.8534 6.75 11.0615 6.82902 11.215 6.96967C11.3684 7.11032 11.4546 7.30109 11.4546 7.5V13.5C11.4546 13.6989 11.3684 13.8897 11.215 14.0303C11.0615 14.171 10.8534 14.25 10.6364 14.25C10.4194 14.25 10.2113 14.171 10.0579 14.0303C9.90444 13.8897 9.81824 13.6989 9.81824 13.5V7.5C9.81824 7.30109 9.90444 7.11032 10.0579 6.96967C10.2113 6.82902 10.4194 6.75 10.6364 6.75Z"
            fill="#C9CFDC"
        />
        <path
            d="M12.2727 3H17.1818C17.3988 3 17.6069 3.07902 17.7604 3.21967C17.9138 3.36032 18 3.55109 18 3.75C18 3.94891 17.9138 4.13968 17.7604 4.28033C17.6069 4.42098 17.3988 4.5 17.1818 4.5H16.2769L15.0464 14.664C14.9352 15.5813 14.4589 16.4288 13.7087 17.0443C12.9584 17.6598 11.9869 18.0001 10.98 18H7.02C6.01314 18.0001 5.04161 17.6598 4.29135 17.0443C3.54108 16.4288 3.06479 15.5813 2.95364 14.664L1.72145 4.5H0.818182C0.601187 4.5 0.393079 4.42098 0.23964 4.28033C0.0862012 4.13968 0 3.94891 0 3.75C0 3.55109 0.0862012 3.36032 0.23964 3.21967C0.393079 3.07902 0.601187 3 0.818182 3H5.72727C5.72727 2.20435 6.07208 1.44129 6.68583 0.87868C7.29959 0.31607 8.13202 0 9 0C9.86798 0 10.7004 0.31607 11.3142 0.87868C11.9279 1.44129 12.2727 2.20435 12.2727 3ZM9 1.5C8.56601 1.5 8.14979 1.65804 7.84292 1.93934C7.53604 2.22064 7.36364 2.60218 7.36364 3H10.6364C10.6364 2.60218 10.464 2.22064 10.1571 1.93934C9.85021 1.65804 9.43399 1.5 9 1.5ZM3.36927 4.5L4.58018 14.499C4.64703 15.0493 4.93287 15.5576 5.38301 15.9268C5.83315 16.296 6.41599 16.5001 7.02 16.5H10.98C11.5837 16.4997 12.1662 16.2955 12.616 15.9263C13.0658 15.5572 13.3514 15.049 13.4182 14.499L14.6324 4.5H3.37091H3.36927Z"
            fill="#C9CFDC"
        />
    </svg>
);

export default Delete;
