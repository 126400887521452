import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { FieldContent } from '../FieldContent';

export const GroupStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${media.phoneBig`
        flex-direction: column;
    `}
`;

export const GroupNameStyled = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
`;

export const MemoFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div<{ horizontalFieldsFullWidth?: boolean }>`
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
    width: 100%;
    margin-bottom: 20px;

    ${({ horizontalFieldsFullWidth }) => horizontalFieldsFullWidth && `grid-template-columns: 1fr;`}
`;

export const FieldContentStyled = styled(FieldContent)`
    margin-bottom: 0;
    justify-content: flex-end;
`;
