import React, { FC } from 'react';
import { useStoreMap } from 'effector-react';

import { vehicleSelectionStores } from '../../stores/vehicleSelection';
import { multipleVariantSelectionStores } from '../../stores/multipleVariantSelection';
import { classificationGroupsNames, ClassificationGroupsKeys } from '@dat/core/constants';
import { equipmentSelectChangeCallback } from './changeCallback';
import { createOptionsWithDisabledOnes } from '../../utils/createOptionsWithDisabledOnes';

import { CustomSelectField } from '../CustomSelect';

import { CurrentFormikValues } from '../../types/formik';
import { FieldEventCallback } from '@wedat/ui-kit/Formik';
import { SelectFieldProps } from '@wedat/ui-kit/Formik/SelectField';

interface Props extends Omit<SelectFieldProps, 'name' | 'options'> {
    classificationGroupKey: ClassificationGroupsKeys;
    isInMultipleVariantSelectionModal?: boolean;
}

export const EquipmentSelect: FC<Props> = React.memo(
    ({ classificationGroupKey, isInMultipleVariantSelectionModal, ...rest }) => {
        const name = classificationGroupsNames[classificationGroupKey];
        // Dynamic data dependent on isInMultipleVariantSelectionModal
        const formikNamePath = `${isInMultipleVariantSelectionModal ? 'multipleVariantSelects.' : ''}equipmentSelects.`;
        const allEquipmentObjectStore = isInMultipleVariantSelectionModal
            ? multipleVariantSelectionStores.allEquipmentObject
            : vehicleSelectionStores.allEquipmentObject;
        const availableEquipmentObjectStore = isInMultipleVariantSelectionModal
            ? multipleVariantSelectionStores.availableEquipmentObject
            : vehicleSelectionStores.availableEquipmentObject;

        // All options
        const allGroupOptions =
            useStoreMap({
                store: allEquipmentObjectStore,
                keys: [classificationGroupKey],
                fn: (allEquipmentObject, [groupKey]) => allEquipmentObject[groupKey]
            }) || [];
        // Available options
        const availableGroupOptions =
            useStoreMap({
                store: availableEquipmentObjectStore,
                keys: [classificationGroupKey],
                fn: (allEquipmentObject, [groupKey]) => allEquipmentObject[groupKey]
            }) || [];
        // Available options values
        const availableGroupOptionsValues = availableGroupOptions.map(({ key }) => key);

        const groupSelectChangeCallback: FieldEventCallback<CurrentFormikValues> = (value, formikContext) => {
            const isSelectedOptionAvailable = availableGroupOptionsValues.includes(value);

            equipmentSelectChangeCallback(value, formikContext, {
                name,
                isSelectedOptionAvailable,
                isInMultipleVariantSelectionModal
            });
        };

        return (
            <CustomSelectField
                changeCallback={groupSelectChangeCallback}
                label={rest.label}
                name={formikNamePath + name}
                options={createOptionsWithDisabledOnes(allGroupOptions, availableGroupOptions)}
                {...rest}
            />
        );
    }
);
