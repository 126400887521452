import { attach, guard, sample } from 'effector';
import { delay } from 'patronum';

import { vehicleSelectionEffects, vehicleSelectionEvents, vehicleSelectionStores } from './vehicleSelection';
import {
    vehicleIdentificationEffects,
    vehicleIdentificationEvents,
    vehicleIdentificationStores
} from './vehicleIdentification';
import { ClassificationGroupsKeys, classificationGroupsNames } from '@dat/core/constants';
import { getPromptAnswer } from '@dat/smart-components/NotificationModal';
import { vehicleImagesEffects } from './vehicleImages';
import { vehicleRepairEffects } from './vehicleRepair';
import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';
import { authRenderlessFx } from '@dat/auth';

import { PluginOptions } from '../types/plugin';
import { ParsedVXSVehicleObject } from '@dat/core/types';
import { TechnicalData } from '../types/vehicleSelection';
import { domain, pluginEvents, pluginStores } from './plugin';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { vehicleOCREffects } from './ocr';
import { sharedLoginStores } from '@dat/shared-models/auth/login';
import { contractEffects } from './contract';
import { i18n } from '@dat/shared-models/i18n';

const { listContractsFx } = contractEffects;
//
/*** Loading status ***/
//

const isLoading = createIsLoadingStore(
    Object.values({
        ...vehicleSelectionEffects,
        ...vehicleIdentificationEffects,
        ...vehicleImagesEffects,
        ...vehicleRepairEffects,
        ...vehicleOCREffects,
        listContractsFx
    })
);

//
/*** Authorize and get vehicle without UI ***/
//
const getVehicleRenderless = attach({
    source: sharedTemplateStores.templateSettings,
    effect: async ({ country, restriction }, options: PluginOptions) => {
        const { onComplete, inputFields } = options;

        if (inputFields) {
            const { NumberPlate, VIN } = inputFields;

            let vehicle: ParsedVXSVehicleObject | undefined;
            let technicalData: TechnicalData | undefined;

            if (NumberPlate) {
                vehicle = await vehicleIdentificationEffects.getVehicleByLicencePlateFx({
                    licencePlate: NumberPlate,
                    country
                });
            } else if (VIN) {
                vehicle = await vehicleIdentificationEffects.getVehicleIdentificationByVinFx({ vin: VIN });
            }
            if (vehicle) {
                const { datECode, constructionTime, container } = vehicle.vehicleInfo;
                const withContainer = restriction === 'APPRAISAL' || restriction === 'ALL';

                technicalData = await vehicleSelectionEffects.getTechnicalDataFx({
                    datECode,
                    container: withContainer ? container : '',
                    constructionTime: +constructionTime
                });

                // TODO: ignore
                // @ts-ignore
                onComplete({ vehicle, technicalData });
            }
        }
    }
});

guard({
    source: pluginEvents.initPlugin,
    filter: pluginStores.isStatic,
    target: authRenderlessFx
});

guard({
    clock: authRenderlessFx.doneData,
    source: pluginStores.pluginOptions,
    filter: sharedLoginStores.isAuthorized,
    // TODO: ignore
    // @ts-ignore
    target: getVehicleRenderless
});

//
/*** Focus select ***/
//
const focusedSelectName = domain.createStore('');
const focusSelect = domain.createEvent<string>();

// Reset store first to trigger update even if name is the same
focusedSelectName.reset(focusSelect);

guard({
    // Using `delay` to ensure that `isLoading` store is updated before calling `focusSelect`
    source: delay({ source: focusSelect, timeout: 0 }),
    filter: isLoading.map(is => !is),
    target: focusedSelectName
});

//
/*** Focus first equipment select which has more than one option ***/
//
const focusFirstNotSingleOptionEquipmentSelect = domain.createEvent();

sample({
    source: vehicleSelectionStores.allEquipmentObject,
    clock: focusFirstNotSingleOptionEquipmentSelect,
    fn: allEquipmentObject => {
        // Get needed equipment select name and pass it to focusSelect
        const firstNotSingleOptionGroupEntry = Object.entries(allEquipmentObject).find(
            ([_, options]) => options && options.length > 1
        );

        if (!firstNotSingleOptionGroupEntry) {
            return '';
        }

        const firstNotSingleOptionGroupKey = firstNotSingleOptionGroupEntry[0] as ClassificationGroupsKeys;
        const firstNotSingleOptionGroupName = classificationGroupsNames[firstNotSingleOptionGroupKey];

        return `equipmentSelects.${firstNotSingleOptionGroupName}`;
    },
    target: focusSelect
});

//
/*** Reset options dependent on equipment (if DAT-code changes) ***/
//
const resetOptionsDependentOnEquipment = domain.createEvent().prepend<void>(() => {
    vehicleSelectionEvents.resetConstructionPeriods();
    vehicleSelectionEvents.resetTechnicalData();
    vehicleIdentificationEvents.resetVinColors();
    vehicleIdentificationEvents.resetLastSuccessfullyRequestedVin();
    vehicleIdentificationEvents.resetVinRequestResultEquipment();
    vehicleIdentificationEvents.resetLastSuccessfullyRequestedLicencePlate();
});

//
/*** Show prompt when some of selects are changed if VIN was successfully requested ***/
//
const getVinRequestResetPromptAnswerFx = domain.createEffect({
    handler: () => {
        if (!vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()) {
            return true;
        } else {
            return getPromptAnswer(i18n.t('vehicle-selection:vinRequestResetPromptText')).then(doChange => {
                if (doChange) {
                    vehicleIdentificationEvents.resetLastSuccessfullyRequestedVin();
                }

                return doChange;
            });
        }
    }
});

//
/*** Export ***/
//
export const commonEvents = {
    focusSelect,
    focusFirstNotSingleOptionEquipmentSelect,
    resetOptionsDependentOnEquipment
};
export const commonEffects = {
    getVehicleRenderless,
    getVinRequestResetPromptAnswerFx
};
export const commonStores = {
    isLoading,
    focusedSelectName
};
