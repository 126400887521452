import React, { ReactElement, FC } from 'react';
import { NotificationObject } from './types';

import { Icon, ToastContentStyled, ToastInfo, ToastMessage, ToastTitle } from './styles';

import { SuccessToast, ErrorToast, WarningToast } from '@wedat/ui-kit/assets/Icon';
import { useTranslation } from 'react-i18next';
import { I18N_NAMESPACES } from '../../core/constants/i18n';

export const icon: Record<NotificationObject['status'], ReactElement> = {
    success: <SuccessToast />,
    error: <ErrorToast />,
    info: <ErrorToast />,
    warn: <WarningToast />
};

export const ToastContent: FC<NotificationObject> = ({ title, message, status }) => {
    const { t } = useTranslation();

    return (
        <ToastContentStyled>
            <Icon>{icon[status]}</Icon>
            <ToastInfo>
                <ToastTitle>{t(`${I18N_NAMESPACES[title.namespace]}:${title.key}`)}</ToastTitle>
                <ToastMessage>{t(`${I18N_NAMESPACES[message.namespace]}:${message.key}`)}</ToastMessage>
            </ToastInfo>
        </ToastContentStyled>
    );
};
