import { XMLObjectInstance } from '@dat/api/utils';

export const parseVehicleImagesResponse = (response: XMLObjectInstance<DAT.GetVehicleImagesNResponse>) => {
    const imagesObjects = response.getFieldArray('images').map(image => ({
        base64: image.getSingleField('imageBase64')._innerText,
        format: image.getSingleField('imageFormat')._innerText
    }));
    const validImagesObjects = imagesObjects.filter(image => image.base64 && image.format);

    const imagesBase64Strings = validImagesObjects.map(({ base64, format }) => `data:image/${format};base64,${base64}`);

    return {
        imagesBase64Strings
    };
};
