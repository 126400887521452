import { forward, sample } from 'effector';
import deepmerge from 'deepmerge';

import { evaluationEffects, evaluationEvents, evaluationStores } from './index';
import { dossierEffects, dossierStores } from '../dossier';
import { extractManualFieldsFromDossier } from '../../utils/extractManualFieldsFromDossier';
import { createPayloadForCreateDossier } from '../../utils/createPayloadForCreateDossier';

import { ManualFields } from '../../types/manualFields';

const { allManualFieldsSubmitted } = evaluationEvents;
const { getVehicleEvaluationFx, getVehicleEvaluationWithManualFieldsFx } = evaluationEffects;
const { manualFieldsOfInitialEvaluation, manualFieldsOfLastEvaluation, formikValuesData } = evaluationStores;
const { createDossierFx } = dossierEffects;
const { dossier } = dossierStores;

getVehicleEvaluationWithManualFieldsFx.use((payload: DAT2.Request.CreateDossierN) =>
    createDossierFx({ ...payload, Save: false })
);

sample({
    clock: getVehicleEvaluationFx.doneData,
    source: formikValuesData,
    fn: (values, dossier) => deepmerge<ManualFields>(values, extractManualFieldsFromDossier(dossier)),
    target: [manualFieldsOfInitialEvaluation, manualFieldsOfLastEvaluation]
});

forward({
    from: allManualFieldsSubmitted,
    to: formikValuesData
});

sample({
    clock: allManualFieldsSubmitted,
    source: dossier,
    fn: createPayloadForCreateDossier,
    target: getVehicleEvaluationWithManualFieldsFx
});

sample({
    clock: getVehicleEvaluationWithManualFieldsFx.done,
    source: allManualFieldsSubmitted,
    target: manualFieldsOfLastEvaluation
});
