import styled from 'styled-components/macro';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const ListUsersWrapper = styled.div`
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const UserRow = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
`;

export const Item = styled.div`
    margin: 5px;
`;

export const UserPicture = styled(Item)`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
