import deepmerge from 'deepmerge';

import { commonEffects } from '../../stores/common';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { GetVehicleByLicencePlateParam, vehicleIdentificationEffects } from '../../stores/vehicleIdentification';
import { ParsedVXSVehicleObject } from '@dat/core/types';
import { domain } from '../../stores/plugin';
import { vehicleOCREffects } from '../../stores/ocr';
import { GetVehicleByOCRFxParams } from '../../types/ocr';
import { AVAILABLE_COUNTRIES, DEFAULT_VEHICLE_DATA } from '../../constants';

const getVehicleByVinFx = domain.createEffect(
    cDRH(
        async (payload: DAT.GetVehicleIdentificationByVinRequest): Promise<ParsedVXSVehicleObject> =>
            new Promise(async (resolve, reject) => {
                // TODO: problem with modal when we go back to inbox
                const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

                if (!doChange) {
                    reject();
                }

                const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleIdentificationByVinFx(
                    payload
                );

                resolve(deepmerge(parsedVXSVehicleObject, { vehicleInfo: { vin: payload.vin } }));
            })
    )
);

const getVehicleByNumberPlateFx = domain.createEffect(
    async (payload: GetVehicleByLicencePlateParam): Promise<ParsedVXSVehicleObject | undefined> =>
        new Promise(async resolve => {
            // TODO: DATG-2574 разобраться на кой черт это надо и, если надо, отрефакторить на Toast. Выпилить notificationModal
            // const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

            // if (!doChange) {
            //     reject();
            // }

            const parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleByLicencePlateFx(payload);

            resolve(parsedVXSVehicleObject);
        })
);

interface ParsedVXSVehicleObjectWithOCR extends ParsedVXSVehicleObject {
    licencePlate?: string;
    country?: string;
}

const getVehicleByOCRFx = domain.createEffect(
    async (params: GetVehicleByOCRFxParams): Promise<ParsedVXSVehicleObjectWithOCR | undefined> =>
        new Promise(async (resolve, reject) => {
            const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

            if (!doChange) {
                reject();
            }

            const { country, OCRPayload } = params;
            const { licencePlate } = await vehicleOCREffects.sendPlateReaderFx(OCRPayload);
            let parsedVXSVehicleObject;

            if (licencePlate && AVAILABLE_COUNTRIES.includes(country)) {
                parsedVXSVehicleObject = await vehicleIdentificationEffects.getVehicleByLicencePlateFx({
                    licencePlate,
                    country
                });
            } else {
                parsedVXSVehicleObject = { ...DEFAULT_VEHICLE_DATA, licencePlate, country };
            }

            if (parsedVXSVehicleObject) {
                resolve({ ...parsedVXSVehicleObject, licencePlate, country });
            }
        })
);

export const vehicleSelectionModalEffects = {
    getVehicleByVinFx,
    getVehicleByNumberPlateFx,
    getVehicleByOCRFx
};
