import { useEffect, useRef } from 'react';

export function useEmbedElementHeightByAspectRatio(aspectRatio: number) {
    const targetRef = useRef<HTMLEmbedElement>(null);

    useEffect(() => {
        targetRef.current?.setAttribute('height', targetRef.current.offsetWidth / aspectRatio + 'px');
    }, [targetRef, aspectRatio, targetRef.current?.offsetWidth]);

    useEffect(() => {
        function handleResize() {
            targetRef.current?.setAttribute('height', targetRef.current.offsetWidth / aspectRatio + 'px');
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [aspectRatio]);

    return targetRef;
}
