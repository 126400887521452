import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components/macro';
import { Event } from 'effector';

import { getLight, getDark } from '@wedat/ui-kit/theme';
import { Gate } from './stores/component';
import '@wedat/ui-kit/i18n';
import '@dat/auth/src/i18n';
import '@dat/shared-models/init';
import './stores/init';

import { useMergeOptionsWithConfiguration } from './hooks/useMergeOptionsWIthConfiguration';

import { Auth } from '@dat/auth';
import { Remount } from './Remount';
import { GlobalStyle } from '@wedat/ui-kit/GlobalStyle';
import { Toast } from '@dat/smart-components/Toast';
import { Preloader } from '@wedat/ui-kit/components/Preloader';
import { PayloadForAddTranslationsToI18Next } from '@dat/shared-models/i18n/types';
import { useStore } from 'effector-react';
import { i18n, i18nStores } from '@dat/shared-models/i18n';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';

export interface PluginProviderProps<TOptions extends DAT2.Plugins.PluginBaseOptions> {
    /* Plugin name */
    name: DAT2.Plugins.PluginName;
    /* Plugin options */
    options: TOptions;
    /* Translations object is used here packages\smart-components\PluginProvider\stores\i18n\init.ts */
    translations?: PayloadForAddTranslationsToI18Next['translations'];
    /* Called after logging-in and initializing main content */
    onInit?: (options: TOptions) => void;
    /* Called after main content is unmounted */
    onUnmount?: (options: TOptions) => void;
    /* Event that triggers remount of children when called */
    triggerRemount?: Event<any>;
    /* Preloader state */
    isLoading?: boolean;
    /* Always shows main content without auth */
    noAuth?: boolean;
}

export const PluginProvider = <TOptions extends DAT2.Plugins.PluginBaseOptions>(
    props: PropsWithChildren<PluginProviderProps<TOptions>>
) => {
    const { name, onInit, triggerRemount, isLoading, noAuth, children } = props;
    const options = useMergeOptionsWithConfiguration(props.options, name);
    const isComponent = Boolean(options?.isComponent);
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(noAuth || isComponent));

    const locale = useStore(i18nStores.locale);

    const userTheme = useStore(sharedConfigurationStores.userSettings.theme);
    const dark = userTheme?.dark;
    const theme = dark ? getDark(locale) : getLight(locale);

    const handleLogin = useCallback(() => setIsLoggedIn(true), []);
    const handleLogout = useCallback(() => setIsLoggedIn(false), []);

    //TODO: word "init" means single(!) action that occurs in the beginning of something. So `onInit` should be triggered only once when user is logged in. Further updates of options should be handled by separate callback e.g. `onOptionsUpdate`.
    const handleInit = useCallback(() => {
        if (isLoggedIn) {
            onInit?.(options);
        }
    }, [onInit, options, isLoggedIn]);

    useEffect(handleInit, [handleInit]);

    useEffect(() => triggerRemount?.watch(handleInit), [triggerRemount, handleInit]);

    return (
        <ThemeProvider theme={theme}>
            <I18nextProvider defaultNS={name} i18n={i18n}>
                {isLoggedIn ? (
                    /*
                     * Some plugins need to fully reset their state after some action,
                     * e.g. VSM in ai-claim should be reset after contract is created,
                     * so that when you open ai-claim next time, VSM would be in it's initial state.
                     * Besides stores which are reset manually via `.reset`,
                     * React's state also should be reset everywhere in plugin.
                     * `Remount` forces all it's children to reset when `triggerRemount` event is called.
                     */
                    <Remount trigger={triggerRemount}>
                        <Gate {...props} options={options} />
                        {children}
                    </Remount>
                ) : (
                    <Auth
                        options={{
                            isComponent,
                            token: options?.token,
                            credentials: options?.credentials,
                            onLogin: handleLogin,
                            onTokenExpire: handleLogout,
                            client: true
                        }}
                    />
                )}

                {!isComponent && <GlobalStyle />}
                <Toast />
                <Preloader isLoading={!!isLoading} />
            </I18nextProvider>
        </ThemeProvider>
    );
};
