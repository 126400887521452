import React, { FC, useEffect, useRef, useState } from 'react';
import tippy, { Instance, roundArrow } from 'tippy.js';
import { TooltipProps } from './types';
import { Container } from './styles';

export const Tooltip: FC<TooltipProps> = ({ text, children, placement = 'bottom', width, withoutArrow, ...rest }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const instanceRef = useRef<Instance | null>(null);

    const [containerWidth, setContainerWidth] = useState('100');

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            instanceRef.current?.destroy();

            instanceRef.current = tippy(container, {
                content: text || '',
                offset: [0, 10],
                appendTo: () => container,
                arrow: !withoutArrow && roundArrow,
                placement
            });

            setContainerWidth(`${container.offsetWidth}px`);
        }
    }, [containerWidth, text, placement, withoutArrow]);

    return (
        <Container ref={containerRef} width={width || containerWidth} {...rest}>
            {children}
        </Container>
    );
};
