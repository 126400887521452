import styled from 'styled-components/macro';

export const PartnerName = styled.span`
    margin-right: 20px;
    ${({ theme }) => theme.typography.footnote};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const PartnerRole = styled.span`
    ${({ theme }) => theme.typography.footnote};
    font-weight: 400;
    text-align: right;
    color: ${({ theme }) => theme.colors.dustBlue['200']};
`;

export const Partner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    min-height: 44px;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['100']};
    }
`;
