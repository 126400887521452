import styled from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    width: 100%;
    max-height: 100%;
    flex-direction: column;
    border-left: 2px solid ${({ theme }) => theme.colors.bgColor};

    ${media.laptopSmall`
        height: 100%;
    `}
`;

export const MessagesWrapper = styled.div`
    height: 100%;
    max-height: 100vh;
    padding: 22px;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.gray_10};

    ${makeCustomScrollBar()}
`;
