import { FC, useEffect, useReducer, useState } from 'react';
import '@dat/core/styles/fonts.css';
import { PhotoStepsPage } from './PhotoStepsPage';
import { PermissionsPage } from './PermissionsPage';
import { preload } from '../utils/preload';
import { ResultStepPage } from './ResultStepPage';
import { AdditionalPhotoStep } from './AdditionalPhotoStep';
import { RetakePhotoStep } from './RetakePhotoStep';
import { CarType, PluginProps, Steps } from '../types';
import { Spinner } from './Spinner';
import { delay } from '../utils/common';
import { getUserMedia, isMediaDeviceAccepted } from '../utils/mediaDevices';
import { isGeolocationAccepted } from '../utils/geolocation';
import { initialState, reducer, FormContext, actions } from '../reducers/form';
import { StepRoute } from './StepRoute';
import { useOrientation } from '../hooks/orientation';
import { DEFAULT_VALIDATION_CONTENT } from '../constants';
import { setStepsImgDynamicSVG } from '../utils/setStepsImgDynamicSVG';
import { API2 } from '@dat/api2';
import { getKindOfSVG } from '../utils/getKindOfSVG';
import { PluginStyled } from './styles';

const { changeStep } = actions;

/**
 * Main component for plugin
 */
export const Main: FC<PluginProps> = options => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLandscape } = useOrientation();
    const [, setForceUpdate] = useState(0);
    const [steps, setSteps] = useState(options.steps);
    const [carType, setCarType] = useState<CarType>(options.carType || 'SW');

    useEffect(() => {
        const { datECode } = options;
        const setDynamicMask = async (datECode: string) => {
            const [vehicleType, doors] = await API2.ania.vehicleType.getVehicleType({
                FZA: datECode.substring(0, 2) || '',
                HST: datECode.substring(2, 5) || '',
                HT: datECode.substring(5, 8) || ''
            });

            const kindOfSVG = getKindOfSVG(datECode, vehicleType, doors);
            setCarType(kindOfSVG as CarType);
            const newSteps = setStepsImgDynamicSVG(options.steps, kindOfSVG);
            setSteps(newSteps);
        };

        if (datECode) {
            setDynamicMask(datECode);
        }
    }, [options]);

    useEffect(() => {
        if (!window.PLUGIN_API) {
            window.PLUGIN_API = {};
        }

        window.PLUGIN_API.__options = options;
    }, [options]);

    const setPreload = () => {
        preload(
            steps?.map(step => {
                const helpsImages = step.helpSteps?.map(item => item.img) || [];

                return [step?.mask?.img, step.preview?.img, ...helpsImages];
            })
        );
    };

    useEffect(() => {
        // special force render App on orientationchange to re-render video container size
        const forceRender = () => setTimeout(() => setForceUpdate(count => ++count), 100);

        window.addEventListener('orientationchange', forceRender);
        window.addEventListener('resize', forceRender);

        setPreload();

        try {
            // preload all steps image
            // TODO: add icons from options
            setPreload();
        } catch {}

        // get geolocation/user media to determinate page
        delay()
            .then(isGeolocationAccepted)
            .then(isMediaDeviceAccepted)
            .then(() => getUserMedia(isLandscape))
            .then(() => dispatch(changeStep(Steps.MakePhoto)))
            .catch(() => dispatch(changeStep()));

        return () => {
            window.removeEventListener('orientationchange', forceRender);
            window.removeEventListener('resize', forceRender);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            <PluginStyled>
                <StepRoute step={Steps.AwaitMedia}>
                    <Spinner />
                </StepRoute>
                <StepRoute step={Steps.EnablePermission}>
                    <PermissionsPage />
                </StepRoute>
                <StepRoute step={Steps.MakePhoto}>
                    <PhotoStepsPage
                        completeFormCallback={options.completeFormCallback}
                        steps={steps}
                        carType={carType}
                        debug={options.debug}
                        realTime={options.realTime}
                        withSummary={options.withSummary}
                        validationContent={options.validationContent || DEFAULT_VALIDATION_CONTENT}
                    />
                </StepRoute>
                <StepRoute step={Steps.SendForm}>
                    <ResultStepPage completeFormCallback={options.completeFormCallback} steps={steps} />
                </StepRoute>
                <StepRoute step={Steps.Additional}>
                    <AdditionalPhotoStep additionalPhotoOrientation={options?.additionalPhotoForceLandscape} />
                </StepRoute>
                <StepRoute step={Steps.Retake}>
                    <RetakePhotoStep steps={steps} />
                </StepRoute>
            </PluginStyled>
        </FormContext.Provider>
    );
};
