import { SchedulerEvent } from '../types';
import { IEventObject } from 'tui-calendar';
import { DefaultTheme } from 'styled-components';

export const eventTypeArrFormatter = (inputArr: DAT2.Appointment[], theme: DefaultTheme) =>
    inputArr.map((item: DAT2.Appointment) => ({
        title: `${item.registrationNumber ? `${item.registrationNumber}: ` : ' '}${item.customerInfo?.name || ''} ${
            item.customerInfo?.surname || ''
        } - ${item.customerInfo?.telephone} - ${item.customerInfo?.email || ''} ${item.description || ''}`,
        start: item.fromDate,
        end: item.toDate,
        id: item.id,
        category: 'time',
        body: `${item.address || ''} ${item.city || ''} ${item.zip || ''}`,
        state: item.contractId,
        bgColor: theme.colors.blueDark,
        color: theme.colors.white,
        borderColor: theme.colors.gray_300,
        description: item.description,
        registrationNumber: item.registrationNumber,
        customerInfo: item.customerInfo,
        address: item.address,
        city: item.city,
        zip: item.zip,
        createdBy: item.createdBy,
        assignee: item.assignee
    }));

export const appointmentTypeFormatter = (inputArr: SchedulerEvent[]) =>
    inputArr.map(item => ({
        contractId: item.state,
        fromDate: item.start,
        toDate: item.end,
        type: 'personal',
        description: item.description,
        registrationNumber: item.registrationNumber,
        customerInfo: item.customerInfo,
        address: item.address,
        city: item.city,
        zip: item.zip,
        createdBy: item.createdBy,
        assignee: item.assignee
    }));

export const expiryClaimToEventTypeFormatter = (inputArr: DAT2.ExpiryClaim[], theme: DefaultTheme) =>
    inputArr.map(item => ({
        title: `${item.registrationNumber ? `${item.registrationNumber}: ` : ' '}${item.customerInfo?.name || ''} ${
            item.customerInfo?.surname || ''
        } - ${item.customerInfo?.telephone} - ${item.customerInfo?.email || ''} ${item.description || ''}`,
        start: new Date(new Date(item.date).setHours(8)).toISOString(),
        end: new Date(new Date(item.date).setHours(20)).toISOString(),
        body: `${item.address || ''} ${item.city || ''} ${item.zip || ''}`,
        isAllDay: true,
        id: item.id,
        state: item.contractId,
        category: 'time',
        bgColor: theme.colors.blueDark,
        color: theme.colors.white,
        borderColor: theme.colors.gray_300,
        description: item.description,
        registrationNumber: item.registrationNumber,
        customerInfo: item.customerInfo,
        address: item.address,
        city: item.city,
        zip: item.zip,
        createdBy: item.createdBy,
        assignee: item.assignee
    }));

export const eventToExpiryClaimTypeFormatter = (inputArr: SchedulerEvent[]) =>
    inputArr.map(item => ({
        contractId: item.state,
        date: item.start,
        type: 'personal',
        description: item.description,
        registrationNumber: item.registrationNumber,
        customerInfo: item.customerInfo,
        address: item.address,
        city: item.city,
        zip: item.zip,
        createdBy: item.createdBy,
        assignee: item.assignee
    }));

export const updatedEventListFormatter = (
    eventsList: SchedulerEvent[],
    updatedEvent: SchedulerEvent,
    updatedEventFromCalendar: IEventObject
) =>
    eventsList.map(item =>
        item.id === updatedEventFromCalendar.schedule.id
            ? {
                  ...updatedEvent,
                  start: updatedEventFromCalendar.start.toDate().toISOString(),
                  end: updatedEventFromCalendar.end.toDate().toISOString()
              }
            : item
    );
