import { createEffect, createEvent, createStore } from 'effector';
import { createReEffect } from 'effector-reeffect';
import deepmerge from 'deepmerge';

import { API2 } from '@dat/api2';
import { createEffectDossier } from '../../utils/createEffectDossier';
import { initialManualFields } from '../../constants/initialManualFields';
import { getISODateString } from '@dat/core/utils';
import { authRenderlessFx } from '@dat/auth';

import { ManualFields } from '../../types/manualFields';
import { PluginOptions } from '../../types/plugin';
import { PayloadForGetVehicleEvaluation } from './types';
import { PartialDeep } from 'type-fest';

const getVehicleEvaluationFx = createEffectDossier((payload: PayloadForGetVehicleEvaluation) => {
    const defaultParams: Partial<PayloadForGetVehicleEvaluation> = { vatType: 'R' };

    return API2.valuateFinance.getVehicleTargetDateEvaluationHistory({ ...defaultParams, ...payload, save: false });
});
const getVehicleEvaluationWithManualFieldsFx = createReEffect<DAT2.Request.ChangeDossierN, DAT2.Dossier>({
    strategy: 'TAKE_LAST'
});

const getVehicleEvaluationRenderless = createEffect(async (options: PluginOptions) => {
    const { onComplete, requestData } = options;

    await authRenderlessFx(options);

    const Dossier = await getVehicleEvaluationFx({
        ...requestData,
        evaluationDate: requestData.evaluationDate || getISODateString(new Date())
    });

    onComplete?.({
        Dossier
    });
});

const manualFieldsOfInitialEvaluation = createStore<ManualFields>(initialManualFields);
const manualFieldsOfLastEvaluation = createStore<ManualFields>(initialManualFields);
/* Manual fields are submitted partially because 'formik' fully deletes empty values (undefined, null) */
const handleSubmitPartialManualFields = createEvent<PartialDeep<ManualFields>>();
const allManualFieldsSubmitted = handleSubmitPartialManualFields.map(
    (manualFields: PartialDeep<ManualFields>): ManualFields =>
        deepmerge<ManualFields>(initialManualFields, manualFields)
    // This needs to be done, because 'formik' fully deletes empty values (undefined, null) from it's values object
    // But empty values should be included in payload to override old non-empty values.
    // Also in "Lump-sum valuation" there are 4 fields which can't be empty, otherwise server will respond with error
    // so we take their default values from 'initialManualFields`
);

const formikValuesData = createStore<ManualFields>(initialManualFields);

export const evaluationEvents = {
    handleSubmitPartialManualFields,
    allManualFieldsSubmitted
};
export const evaluationEffects = {
    getVehicleEvaluationFx,
    getVehicleEvaluationWithManualFieldsFx,
    getVehicleEvaluationRenderless
};
export const evaluationStores = {
    manualFieldsOfInitialEvaluation,
    manualFieldsOfLastEvaluation,
    formikValuesData
};
