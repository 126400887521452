import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { DownloadIcon } from '@wedat/ui-kit/components/Icons';

export const Container = styled.div`
    &:not(:last-child) {
        margin-bottom: 40px;
    }
`;

export const AttachmentsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
`;

export const Name = styled.h2`
    font-size: 18px;
    align-items: baseline;
`;

export const AttachmentsList = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    ${media.laptop`
        grid-template-columns: 1fr 1fr 1fr;
    `}

    ${media.phoneMedium`
        grid-gap: 14px;
    `}
`;

export const Download = styled(DownloadIcon)`
    cursor: pointer;
    margin-left: 15px;

    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;
