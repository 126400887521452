import { CustomSelectProps } from './index';
import { OptionText } from './styles';

export const getOptions = (options: CustomSelectProps['options']) =>
    options.map(({ key, value, ...rest }) => {
        const label = <OptionText>{value}</OptionText>;

        return {
            ...rest,
            value,
            key,
            label
        };
    });
