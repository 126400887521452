import { createEffect as effectorCreateEffect, createEvent, createStore, } from 'effector';
import { patchInstance } from './instance';
import { patchRunner } from './runner';
import { TAKE_EVERY } from './strategy';
export const createReEffectFactory = (createEffect = effectorCreateEffect) => (nameOrConfig, maybeConfig) => {
    const instance = createEffect(nameOrConfig, maybeConfig);
    const cancelled = createEvent({ named: 'cancelled' });
    const cancel = createEvent({ named: 'cancel' });
    const inFlightInternal = createStore(0, {
        named: 'reeffectInFlight',
    }).on(instance, s => s + 1);
    const pendingInternal = inFlightInternal.map({
        fn: amount => amount > 0,
        named: 'reeffectPending',
    });
    const config = maybeConfig
        ? maybeConfig
        : nameOrConfig && typeof nameOrConfig === 'object'
            ? nameOrConfig
            : {};
    const scope = {
        strategy: config.strategy || TAKE_EVERY,
        feedback: config.feedback || false,
        limit: config.limit || Infinity,
        timeout: config.timeout,
        cancelled,
        cancel,
        inFlight: inFlightInternal,
        pending: pendingInternal,
        anyway: instance.finally,
    };
    patchRunner(instance.graphite.scope.runner, scope);
    patchInstance(instance, scope);
    return instance;
};
