import { LabourRateField } from '@dat/shared-models/labour-rates-generic/types';
import * as Yup from 'yup';
export enum Operator {
    EQUAL = 'equal',
    IS_NOT_NULL = 'isNotNull'
}

export const equal = <T>(value1: T, value2: T) => value1 === value2;

export const isNotNull = <T>(value: T) => !!value;

export const conditions = {
    equal,
    isNotNull
};
export const createValidation = (fieldsDictionary: LabourRateField[]) => {
    const yupObj = Yup.object({});
    const filtered = fieldsDictionary.filter(item => item.required);
    const nodes = filtered.map(item => item.name);
    filtered.forEach(item =>
        Object.assign(yupObj, {
            fields: { [item.name]: Yup.number().moreThan(0).required('Required'), ...yupObj.fields },
            _nodes: [...nodes]
        })
    );
    return yupObj;
};
