import { FC } from 'react';
import { StyledButton, Title, ButtonContainer, Container } from './styles';
import AddIcon from '@wedat/ui-kit/assets/Icon/Add';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@wedat/ui-kit/components/Button';

export const PartSelectionButton: FC<ButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <ButtonContainer>
                <StyledButton typeStyle={{ type: 'primary' }} onClick={onClick}>
                    <AddIcon fill="black" height="25px" width="25px" />
                    <Title>{t('page.createNewButton')}</Title>
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};
