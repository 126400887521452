import { FC } from 'react';
import { useStore } from 'effector-react';
import { Formik } from 'formik';

// Components
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { ModalContent } from '@wedat/ui-kit/components/Modal/styles';
import { Preloader } from '@wedat/ui-kit/components/Preloader';

// Deps
import { labourRatesGenericEffects, labourRatesGenericStores } from '@dat/shared-models/labour-rates-generic';
import { FormState } from '@dat/shared-models/labour-rates-generic/types';
import { formToConfig } from '@dat/shared-models/labour-rates-generic/utils/formToConfig';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { FormGenerator } from '../FormGenerator';
import { createValidation } from '../../utils/conditions';
import { apiStores } from '@dat/api2/stores';

interface Props {
    isOpen: boolean;
    onDismiss: () => void;
}
export const RateModal: FC<Props> = ({ isOpen, onDismiss }) => {
    const rateConfigStore = useStore(labourRatesGenericStores.configWithRateParams);
    const country = useStore(apiStores.country);
    const rateParams = useStore(labourRatesGenericStores.rateParams);
    const ownerId = useStore(labourRatesGenericStores.ownerId);
    const initialFormState = useStore(labourRatesGenericStores.initialFormState);

    const handleSubmit = async (inputs: FormState) => {
        await labourRatesGenericEffects.createOrUpdateRateFx({
            id: rateParams.id,
            ownerId: ownerId ?? rateParams.ownerId,
            factorsData: formToConfig(inputs),
            country
        });
        onDismiss();
    };

    if (!rateConfigStore.config || !initialFormState || !initialFormState) {
        return <Preloader isLoading />;
    }

    return (
        <ModalPrimary onDismiss={onDismiss} fullWidth={true} isOpen={isOpen} hideHeader bodyNoPadding={true}>
            <ModalContent>
                <Formik
                    validationSchema={createValidation(rateConfigStore.fieldsDictionary)}
                    initialValues={initialFormState}
                    onSubmit={handleSubmit}
                >
                    {({ isValid, dirty }) => (
                        <>
                            <Header closeLabourRatesModal={onDismiss} />
                            <FormGenerator />
                            <Footer isValid={isValid} dirty={dirty} />
                        </>
                    )}
                </Formik>
            </ModalContent>
        </ModalPrimary>
    );
};
