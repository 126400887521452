// zoneGraphicsModel

import { createEffect, createEvent, restore, sample, combine } from 'effector';
import { GetZoneGraphicBaseResult, GetZusatzByDatECodeResult } from '@dat/api2dat/types/zusatzTypes';
// import { getSvgZoneGraphicById, getZoneGraphicBase, getZusatzByDatECode } from '@dat/api2dat/services/zusatzService';
import { getSvgZoneGraphicById, getZoneGraphicBase } from '@dat/api2dat/services/staticGrapaService';
import { pluginOptionsModel } from './pluginOptionsModel';

const setZoneGraphicBase = createEvent<GetZoneGraphicBaseResult | null>();
const zoneGraphicBase = restore(setZoneGraphicBase, null);

const getZoneGraphicBaseFx = createEffect(getZoneGraphicBase);
getZoneGraphicBaseFx.doneData.watch(setZoneGraphicBase);

// init

interface CurrentSvgZoneGraphic {
    id: string;
    value: string;
}

const setCurrentSvgZoneGraphic = createEvent<CurrentSvgZoneGraphic | null>();
const currentSvgZoneGraphic = restore(setCurrentSvgZoneGraphic, null);

const getSvgZoneGraphicByIdFx = createEffect(getSvgZoneGraphicById);
getSvgZoneGraphicByIdFx.doneData.watch(setCurrentSvgZoneGraphic);

const setZusatzData = createEvent<GetZusatzByDatECodeResult | null>();
const zusatzData = restore(setZusatzData, null);

const setZoneGraphicId = createEvent<number | null>();
const zoneGraphicId = restore(setZoneGraphicId, null);

const setDatECode = createEvent<string | null>();
const datECode = restore(setDatECode, null);

// on change DatECode update svg clean and update zusatzData

sample({
    source: [datECode, zoneGraphicBase],
    // clock: [datECode , getZoneGraphicBaseFx.doneData],
    fn: ([datECode, zoneGraphicBase]) => {
        if (zoneGraphicBase && datECode) {
            const fza = parseInt(datECode.substring(0, 2));
            const hst = parseInt(datECode.substring(2, 5));
            const ht = parseInt(datECode.substring(5, 8));

            const foundItem = zoneGraphicBase.htZonengraphics.htZonengraphic.find(
                item => item.fza === fza && item.hst === hst && item.ht === ht
            );

            if (foundItem) {
                return foundItem.id;
            }

            return null;
        }
        return null;
    },
    target: setZoneGraphicId
});

combine(
    pluginOptionsModel.stores.pluginOptions.map(opt => opt?.datServicesUrls?.staticGrapaServiceUrl),
    zoneGraphicId
).watch(([serviceUrl, id]) => {
    setCurrentSvgZoneGraphic({ id: '', value: '' });
    getSvgZoneGraphicByIdFx({ id, serviceUrl });
});

// datECode.watch(getZusatzByDatECodeFx);

const setCurrentZoneDATID = createEvent<string>();
const currentZoneDATID = restore(setCurrentZoneDATID, '');

const currentGroupZoneId = currentZoneDATID.map(datid => {
    if (!datid) return null;
    return parseInt(datid.slice(-2));
});

setDatECode.watch(_ => setCurrentZoneDATID(''));

export const zoneGraphicsModel = {
    stores: {
        zoneGraphicBase,
        currentSvgZoneGraphic,
        zusatzData,
        currentZoneDATID,
        currentGroupZoneId
    },
    events: {
        setZusatzData,
        setZoneGraphicBase,
        // changeDatECode,
        setDatECode,
        setCurrentZoneDATID
    },
    effects: {
        getZoneGraphicBaseFx
    }
};
