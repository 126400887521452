import { useCallback } from 'react';
import { fieldsValuesStore } from '../stores/fieldsValues';
import { useStore } from 'effector-react';
import { optionsStore } from '../stores/conditions';

export const useGetConditionValues = (formName: string) => {
    const fieldsValues = useStore(fieldsValuesStore);
    const options = useStore(optionsStore);

    const getFieldValue = useCallback(
        (fieldId: string) => {
            let value = undefined;
            Object.keys(fieldsValues).forEach(key => {
                if (key === formName) {
                    value = fieldsValues[key][fieldId];
                }
            });
            return value;
        },
        [formName, fieldsValues]
    );

    const getFieldKey = useCallback(
        (fieldId: string, _key: string) => (fieldsValues ? fieldsValues[fieldId] : undefined),
        [fieldsValues]
    );

    const getOption = useCallback((option: string) => (options ? options[option] : undefined), [options]);
    return { getFieldValue, getFieldKey, getOption };
};
