import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikMailValues, validationSchema, INITIAL_FORM_MAIL_VALUES } from '../../constants/formik';
import { sendMailEffects } from '../../stores/sendMail';
import { DrawerContents } from '../DrawerContents';
import { Container } from './styles';

export const Main: React.FC = () => {
    const { t } = useTranslation();

    const handleSubmit = async (values: FormikMailValues, { resetForm }: FormikHelpers<FormikMailValues>) => {
        await sendMailEffects.sendMailFxWithAttachments({
            values,
            succsessCallback: () => resetForm()
        });
    };

    return (
        <Container>
            <Formik
                initialValues={INITIAL_FORM_MAIL_VALUES}
                onSubmit={handleSubmit}
                validationSchema={validationSchema(t)}
            >
                <Form>
                    <DrawerContents />
                </Form>
            </Formik>
        </Container>
    );
};
