/*
 * Returns updated steps with mask img URLs by dynamic immg names (constants/stepsMaskImgDynamicNames.ts).
 */

import {
    STEPS_MASK_IMG_DYNAMIC_NAMES,
    STEPS_MASK_IMG_DYNAMIC_NAMES_R,
    STEPS_MASK_IMG_DYNAMIC_NAMES_G,
    MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART
} from '../constants/stepsMaskImgDynamicNames';
import { PluginStep } from '../types';

export const setStepsImgDynamicSVG = (steps: PluginStep[], kindOfSVG: string) =>
    steps.map(step => {
        const names = Object.keys(STEPS_MASK_IMG_DYNAMIC_NAMES);

        const urls = Object.values(STEPS_MASK_IMG_DYNAMIC_NAMES);
        const urls_r = Object.values(STEPS_MASK_IMG_DYNAMIC_NAMES_R);
        const urls_g = Object.values(STEPS_MASK_IMG_DYNAMIC_NAMES_G);

        const maskImg = step.mask?.img;

        if (!maskImg || !names.includes(maskImg)) return step;

        const newStep = {
            ...step,
            mask: {
                ...step.mask,
                img: urls[names.indexOf(maskImg)]?.replace(MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART, kindOfSVG),
                img_r: urls_r[names.indexOf(maskImg)]?.replace(MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART, kindOfSVG),
                img_g: urls_g[names.indexOf(maskImg)]?.replace(MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART, kindOfSVG)
            }
        };

        return newStep;
    });
