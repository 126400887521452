import { ArrowDownIcon } from '@wedat/ui-kit/components/Icons';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

interface AdditionalPositionWrapperProps {
    isSelected: boolean;
    isSubPosition?: boolean;
}

interface AnimatedContainerProps {
    show: boolean;
    subPosAmount: number;
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    ${makeCustomScrollBar()}
`;

export const HeadersContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PositionContainer = styled.div`
    display: grid;
`;

export const AdditionalPositionWrapper = styled.div<AdditionalPositionWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
    ${({ isSubPosition, theme }) => isSubPosition && `background-color: ${theme.colors.gray['50']};`}
    ${({ isSelected, theme }) => isSelected && `background-color: ${theme.colors.red['50']};`}

    &:hover {
        background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.gray['100']};
    }
`;

export const FieldWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    position: relative;
    flex-shrink: 0;
    margin-right: 16px;
`;

export const AnimatedContainer = styled.div<AnimatedContainerProps>`
    display: flex;
    flex-direction: column;
    max-height: ${({ show, subPosAmount }) => (show ? `${subPosAmount * 56}px` : '0')};
    transition: max-height 0.3s;
    overflow: hidden;
`;

export const ArrowDownIconStyled = styled(ArrowDownIcon)<{ shown: boolean }>`
    position: absolute;
    cursor: pointer;
    right: 17px;
    top: 11px;
    height: 17px;
    transition: transform 0.3s;
    ${({ shown }) => (shown ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')}
`;
