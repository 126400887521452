export const processPlaceholders = ({
    value,
    templateData
}: {
    value: string;
    templateData: { [_name: string]: string };
}) => {
    if (!value) {
        return '';
    }

    const matches = Array.from(value.matchAll(/{([^}]+)}/g), match => match[1]);
    let updatedValue = value;
    matches.forEach(fieldName => {
        updatedValue = updatedValue.replace(`{${fieldName}}`, templateData[fieldName] || '');
    });

    return updatedValue;
};
