import * as Yup from 'yup';
import { startOfTomorrow, addHours } from 'date-fns';
import { DISABLED_HOURS } from '@dat/shared-models/scheduler/constants';

const TODAY = new Date();
const NOW = new Date(Date.now());

export const INITIAL_APPOINTMENT_FORM_VALUES = {
    date: TODAY,
    startTime: new Date(),
    endTime: addHours(NOW, 1),
    expiryDate: startOfTomorrow(),
    description: '',
    address: '',
    city: '',
    zip: ''
};

export const INITIAL_EXPIRY_CLAIM_FORM_VALUES = {
    expiryDate: startOfTomorrow(),
    description: '',
    address: '',
    city: '',
    zip: ''
};

export const APPOINTMENT_FORM_SCHEMA = Yup.object({
    date: Yup.date().label('date').required('Required'),
    startTime: Yup.date()
        .label('endTime')
        .required('Required')
        .test('start-time', 'Please select working hours', (value): boolean => {
            if (value) {
                return !DISABLED_HOURS.includes(value.getHours());
            }

            return false;
        }),
    endTime: Yup.date()
        .label('endTime')
        .required('Required')
        .min(Yup.ref('startTime'), 'End time cannot be less than start time')
        .test('end-time', 'Please select working hours', (value): boolean => {
            if (value) {
                return !DISABLED_HOURS.includes(value.getHours());
            }

            return false;
        })
        .test('end-time', 'End time should be greater than start time', function (this, value): boolean {
            if (value) {
                const startTime = this.parent.startTime.getTime();
                const endTime = value.getTime();
                return startTime !== endTime;
            }

            return false;
        }),
    expiryDate: Yup.date()
        .label('expiryDate')
        .required('Required')
        .min(Yup.ref('date'), 'Expiry Claim date cannot be in past')
        .test({
            name: 'the-same',
            message: 'Expiry Claim date should be at least 1 day bigger',
            test: function (this, value) {
                if (value) {
                    const startDate = this.parent.date.getTime();
                    const expiryDate = value.getTime();
                    return startDate !== expiryDate;
                }

                return false;
            }
        })
});

export const EXPIRY_CLAIM_FORM_SCHEMA = Yup.object({
    expiryDate: Yup.date().label('expiryDate').required('Required').min(NOW, 'Expiry Claim date cannot be in past')
});

export const POPUP_STYLES = {
    zIndex: 3333
};
