export const getTablePadding = (isLabour?: boolean, isPaintWork?: boolean) => {
    if (isLabour) {
        return 175;
    }

    if (isPaintWork) {
        return 140;
    }

    return 110;
};
