import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Categories } from '../Categories';
import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { EquipmentTabs } from '../../constants/activeTabsEnum';
import { DesktopContainer, MobileFixedHeader, Title, CloseWrapper, RowInfo, BackWrapper } from './styles';
import { ArrowUpIcon, CloseIcon } from '@wedat/ui-kit/components/Icons';

interface Props {
    isMobile: boolean;
    activeTab: EquipmentTabs;
    handleChangeTabs: (id?: string) => void;
    closeEquipmentModal: () => void;
    availableRows: number;
    existingRows: number;
}

export const Header: React.FC<Props> = ({
    isMobile,
    activeTab,
    handleChangeTabs,
    closeEquipmentModal,
    availableRows,
    existingRows
}) => {
    const { t } = useTranslation();

    const tabs = [
        {
            id: EquipmentTabs.AvailableEquipment,
            label: `${t('availableEquipment')} (${availableRows})`
        },
        {
            id: EquipmentTabs.ExistingEquipment,
            label: `${t('existingEquipment')} (${existingRows})`
        }
    ];

    return isMobile ? (
        <MobileFixedHeader>
            <RowInfo>
                <BackWrapper onClick={closeEquipmentModal}>
                    <ArrowUpIcon />
                </BackWrapper>
                <Title>{t('equipment')}</Title>
            </RowInfo>

            <Tabs typeStyle="mobile" values={tabs} onChange={handleChangeTabs} active={activeTab} fillWidth />
        </MobileFixedHeader>
    ) : (
        <DesktopContainer>
            <RowInfo>
                <Title>{t('equipment')}</Title>
                <Categories />
            </RowInfo>

            <CloseWrapper onClick={closeEquipmentModal}>
                <CloseIcon />
            </CloseWrapper>
        </DesktopContainer>
    );
};
