import { API } from '@dat/api';
import { effectorLogger, parseVehicleImagesResponse } from '@dat/core/utils';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { domain } from './plugin';

//
/*** Get vehicle images ***/
//
type GetVehicleImagesParam = Omit<DAT.GetVehicleImagesNRequest, 'aspect' | 'imageType'> & {
    aspect?: DAT.GetVehicleImagesNRequest['aspect'];
    imageType?: DAT.GetVehicleImagesNRequest['imageType'];
};

const getVehicleImagesFx = createNotifyingEffect({
    handler: async ({ datECode, aspect = 'ALL', imageType = 'PICTURE' }: GetVehicleImagesParam) => {
        try {
            const response = await API.vehicleImages.getVehicleImagesN({ datECode, aspect, imageType });

            return parseVehicleImagesResponse(response).imagesBase64Strings;
        } catch {
            return [];
        }
    }
});
const resetVehicleImages = domain.createEvent();
const vehicleImages = domain
    .createStore<string[]>([])
    .on(getVehicleImagesFx.doneData, (_, images) => images)
    .reset(resetVehicleImages);

//
/*** Export ***/
//
export const vehicleImagesEvents = {
    resetVehicleImages
};
export const vehicleImagesEffects = {
    getVehicleImagesFx
};
export const vehicleImagesStores = {
    vehicleImages
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(vehicleImagesEvents);
    effectorLogger(vehicleImagesEffects);
    effectorLogger(vehicleImagesStores);
}
