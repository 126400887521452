import { icons, translates } from '../constants';
import { Dictionary, FormStep, PluginProps, ResultForm } from '../types';

export const getTranslates = (): Dictionary<string> => {
    const pluginTranslates = window.PLUGIN_API?.__options?.translates || {};

    // merge translates
    return {
        ...translates,
        ...pluginTranslates
    };
};

export const getIcons = (): Dictionary<string | undefined> => {
    const pluginIcons = window.PLUGIN_API?.__options?.icons || {};

    // merge icons
    return {
        ...icons,
        ...pluginIcons
    };
};

export const getGpsPosition = (): string => window.PLUGIN_API?.__options?.gpsInfo?.position || 'top-right';

export const getGpsStyles = (): Dictionary<string> => {
    const defaultStyles: Required<Required<Required<PluginProps>['gpsInfo']>['styles']> = {
        fontColor: 'white',
        fontFamily: 'Arial',
        fontSize: '25px',
        backgroundColor: 'black',
        padding: '0px 0px 0px 0px',
        borderRadius: '0px'
    };
    const pluginStyles = window.PLUGIN_API?.__options?.gpsInfo?.styles || {};

    // merge styles
    return {
        ...defaultStyles,
        ...pluginStyles
    };
};

/**
 * Check disable stream or not after submit
 */
export const isDisableStream = () =>
    typeof window.PLUGIN_API?.__options?.disableStreamAfterComplete === 'boolean'
        ? window.PLUGIN_API?.__options?.disableStreamAfterComplete
        : true;

export const groupByPhotoType = (steps: ResultForm['steps']) => {
    const groups: Dictionary<FormStep[]> = {};

    steps.forEach(step => {
        const photoType = step.photoType.toLowerCase();

        if (Array.isArray(groups[photoType])) {
            groups[photoType].push(step);
        } else {
            groups[photoType] = [step];
        }
    });

    return groups;
};
