import styled from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const ContainerStyled = styled.div`
    padding: 24px 24px 13px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    border-radius: 16px;

    ${makeCustomScrollBar()}

    ${media.phoneBig`
        padding: 24px;
        padding-left: 0;
        padding-right: 0;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    ${media.phoneBig`
        margin-bottom: 10px;
        padding-left: 24px;
        font-size: 20px;
        line-height: 28px;
    `}
`;

export const SizedBox = styled.div`
    width: 75px;
    height: 32px;
`;
