import {
    Clock,
    GetClock,
    GetGuardClock,
    GetGuardSource,
    GetSource,
    guard,
    GuardFilterC,
    GuardFilterS,
    GuardFilterSC,
    GuardResult,
    IfUnknown,
    is,
    MultiTarget,
    NonFalsy,
    Source,
    Store,
    Target,
    Unit,
    UnitValue
} from 'effector';
import { matchPath } from 'react-router-dom';
import urljoin from 'url-join';

/*
 * All overloads of `guardPath` are just modified overloads of standard `guard` from effector's index.d.ts file
 * Implementation of `guardPath` is located at the bottom
 */

export function guardPath<
    A,
    X extends GetSource<S>,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    T extends Target = Target
>(config: {
    source: S;
    clock: C;
    filter?: (source: GetSource<S>, clock: GetClock<C>) => source is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, X>;
    name?: string;
    greedy?: boolean;
}): T;
// ST
export function guardPath<
    A,
    X extends GetSource<S>,
    S extends Source<A> = Source<A>,
    T extends Target = Target
>(config: {
    source: S;
    filter?: (source: GetSource<S>) => source is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, X>;
    name?: string;
    greedy?: boolean;
}): T;
// СT
export function guardPath<B, X extends GetClock<C>, C extends Clock<B> = Clock<B>, T extends Target = Target>(config: {
    clock: C;
    filter?: (clock: GetClock<C>) => clock is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, X>;
    name?: string;
    greedy?: boolean;
}): T;

/* user-defined typeguardPath: without target */
// SC
export function guardPath<
    A,
    X extends GetSource<S>,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>
>(config: {
    source: S;
    clock: C;
    filter?: (source: GetSource<S>, clock: GetClock<C>) => source is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<X>;
// S
export function guardPath<A, X extends GetSource<S>, S extends Source<A> = Source<A>>(config: {
    source: S;
    filter?: (source: GetSource<S>) => source is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<X>;
// C
export function guardPath<B, X extends GetClock<C>, C extends Clock<B> = Clock<B>>(config: {
    clock: C;
    filter?: (clock: GetClock<C>) => clock is X;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<X>;

// ---------------------------------------
/* boolean fn or store: with target */
// SСT
export function guardPath<
    A = any,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    F extends GuardFilterSC<S, C> = GuardFilterSC<S, C>,
    T extends Target = Target
>(config: {
    source: S;
    clock: C;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, GetGuardSource<S, F>>;
    name?: string;
    greedy?: boolean;
}): T;
// ST
export function guardPath<
    A = any,
    S extends Source<A> = Source<A>,
    F extends GuardFilterS<S> = GuardFilterS<S>,
    T extends Target = Target
>(config: {
    source: S;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, GetGuardSource<S, F>>;
    name?: string;
    greedy?: boolean;
}): T;
// СT
export function guardPath<
    B = any,
    C extends Clock<B> = Clock<B>,
    F extends GuardFilterC<C> = GuardFilterC<C>,
    T extends Target = Target
>(config: {
    clock: C;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: MultiTarget<T, GetGuardClock<C, F>>;
    name?: string;
    greedy?: boolean;
}): T;

/* boolean fn or store: without target */
// SC (units: BooleanConstructor)
export function guardPath<A = any, B = any>(config: {
    source: Unit<A>;
    clock: Unit<B>;
    filter?: BooleanConstructor;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<NonFalsy<A>>;
// SC (units: boolean fn or store)
export function guardPath<A = any, B = any>(config: {
    source: Unit<A>;
    clock: Unit<B>;
    filter?: ((source: A, clock: B) => boolean) | Store<boolean>;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<A>;
// SC
export function guardPath<
    A = any,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    F extends GuardFilterSC<S, C> = GuardFilterSC<S, C>
>(config: {
    source: S;
    clock: C;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<GetGuardSource<S, F>>;
// S (unit: BooleanConstructor)
export function guardPath<A = any>(config: {
    source: Unit<A>;
    filter?: BooleanConstructor;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<NonFalsy<A>>;
// S (unit - boolean fn or store)
export function guardPath<A = any>(config: {
    source: Unit<A>;
    filter?: ((source: A) => boolean) | Store<boolean>;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<A>;
// S
export function guardPath<
    A = any,
    S extends Source<A> = Source<A>,
    F extends GuardFilterS<S> = GuardFilterS<S>
>(config: {
    source: S;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<GetGuardSource<S, F>>;
// C (unit: boolean fn or store)
export function guardPath<B = any>(config: {
    clock: Unit<B>;
    filter?: BooleanConstructor;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<NonFalsy<B>>;
// C (unit: boolean fn or store)
export function guardPath<B = any>(config: {
    clock: Unit<B>;
    filter?: ((clock: B) => boolean) | Store<boolean>;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<B>;
// C
export function guardPath<B = any, C extends Clock<B> = Clock<B>, F extends GuardFilterC<C> = GuardFilterC<C>>(config: {
    clock: C;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    name?: string;
    greedy?: boolean;
}): GuardResult<GetGuardClock<C, F>>;

// ---------------------------------------
// guardPath with source param
// ---------------------------------------

/* user-defined typeguardPath: with target */
// SСT
export function guardPath<
    A,
    X extends GetSource<S>,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    T extends Target = Target
>(
    source: S,
    config: {
        clock: C;
        filter?: (source: GetSource<S>, clock: GetClock<C>) => source is X;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        target: MultiTarget<T, X>;
        name?: string;
        greedy?: boolean;
    }
): T;
// ST
export function guardPath<A, X extends GetSource<S>, S extends Source<A> = Source<A>, T extends Target = Target>(
    source: S,
    config: {
        filter?: (source: GetSource<S>) => source is X;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        target: MultiTarget<T, X>;
        name?: string;
        greedy?: boolean;
    }
): T;

/* user-defined typeguardPath: without target */
// SC
export function guardPath<
    A,
    X extends GetSource<S>,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>
>(
    source: S,
    config: {
        clock: C;
        filter?: (source: GetSource<S>, clock: GetClock<C>) => source is X;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<X>;
// S
export function guardPath<A, X extends GetSource<S>, S extends Source<A> = Source<A>>(
    source: S,
    config: {
        filter?: (source: GetSource<S>) => source is X;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<X>;

// ---------------------------------------
/* boolean fn or store: with target */
// SСT
export function guardPath<
    A = any,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    F extends GuardFilterSC<S, C> = GuardFilterSC<S, C>,
    T extends Target = Target
>(
    source: S,
    config: {
        clock: C;
        filter?: F;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        target: MultiTarget<T, GetGuardSource<S, F>>;
        name?: string;
        greedy?: boolean;
    }
): T;
// ST
export function guardPath<
    A = any,
    S extends Source<A> = Source<A>,
    F extends GuardFilterS<S> = GuardFilterS<S>,
    T extends Target = Target
>(
    source: S,
    config: {
        filter?: F;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        target: MultiTarget<T, GetGuardSource<S, F>>;
        name?: string;
        greedy?: boolean;
    }
): T;

/* boolean fn or store: without target */
// SC (units: BooleanConstructor)
export function guardPath<A = any, B = any>(
    source: Unit<A>,
    config: {
        clock: Unit<B>;
        filter?: BooleanConstructor;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<NonFalsy<A>>;
// SC (units: boolean fn or store)
export function guardPath<A = any, B = any>(
    source: Unit<A>,
    config: {
        clock: Unit<B>;
        filter?: ((source: A, clock: B) => boolean) | Store<boolean>;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<A>;
// SC
export function guardPath<
    A = any,
    B = any,
    S extends Source<A> = Source<A>,
    C extends Clock<B> = Clock<B>,
    F extends GuardFilterSC<S, C> = GuardFilterSC<S, C>
>(
    source: S,
    config: {
        clock: C;
        filter?: F;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<GetGuardSource<S, F>>;
// S (unit: BooleanConstructor)
export function guardPath<A = any>(
    source: Unit<A>,
    config: {
        filter?: BooleanConstructor;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<NonFalsy<A>>;
// S (unit: boolean fn or store)
export function guardPath<A = any>(
    source: Unit<A>,
    config: {
        filter?: ((source: A) => boolean) | Store<boolean>;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<A>;
// S
export function guardPath<A = any, S extends Source<A> = Source<A>, F extends GuardFilterS<S> = GuardFilterS<S>>(
    source: S,
    config: {
        filter?: F;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        name?: string;
        greedy?: boolean;
    }
): GuardResult<GetGuardSource<S, F>>;

// guardPath's last overload for `guardPath(source, config)`
export function guardPath<
    S extends Source<unknown>,
    C extends Clock<unknown>,
    F extends IfUnknown<
        UnitValue<S>,
        Store<boolean> | ((clock: GetClock<C>) => boolean),
        IfUnknown<
            UnitValue<C>,
            Store<boolean> | ((source: GetSource<S>) => boolean),
            Store<boolean> | ((source: GetSource<S>, clock: GetClock<C>) => boolean)
        >
    >,
    T extends Target
>(
    source: S,
    config: {
        clock?: C;
        filter?: F;
        path: string | Store<string>;
        basename?: string | Store<string>;
        exact?: boolean;
        strict?: boolean;
        target: F extends (value: any, ...args: any) => value is infer X
            ? MultiTarget<T, X>
            : MultiTarget<T, GetGuardSource<S, F>>;
        name?: string;
        greedy?: boolean;
    }
): T;

// guardPath's last overload for `guardPath(config)`
export function guardPath<
    S extends Source<unknown>,
    C extends Clock<unknown>,
    F extends IfUnknown<
        UnitValue<S>,
        Store<boolean> | ((clock: GetClock<C>) => boolean),
        IfUnknown<
            UnitValue<C>,
            Store<boolean> | ((source: GetSource<S>) => boolean),
            Store<boolean> | ((source: GetSource<S>, clock: GetClock<C>) => boolean)
        >
    >,
    T extends Target
>(config: {
    source?: S;
    clock?: C;
    filter?: F;
    path: string | Store<string>;
    basename?: string | Store<string>;
    exact?: boolean;
    strict?: boolean;
    target: F extends (value: any, ...args: any) => value is infer X
        ? MultiTarget<T, X>
        : MultiTarget<T, IfUnknown<UnitValue<S>, GetGuardClock<C, F>, GetGuardSource<S, F>>>;
    name?: string;
    greedy?: boolean;
}): T;

export function guardPath({ path, basename, exact, strict, filter, ...guardPayload }: any) {
    return guard({
        ...guardPayload,
        filter: (source, clock) => {
            const condition = filter ? filter(source, clock) : true;

            path = is.store(path) ? path.getState() : path;
            basename = (is.store(basename) ? basename.getState() : basename) || '';

            return (
                !!matchPath(window.location.pathname, {
                    path: urljoin('/', basename, path),
                    exact,
                    strict
                }) && condition
            );
        }
    });
}
