import { PluginResult } from '@dat/equipment/src/types/plugin';
import { createEvent, createStore } from 'effector';

const setExistingEquipmentStore = createEvent<PluginResult>();

const existingEquipmentStore = createStore<PluginResult>({ SeriesEquipment: [], SpecialEquipment: [] }).on(
    setExistingEquipmentStore,
    (_state, payload) => payload
);

export const equipmentStores = {
    existingEquipmentStore
};

export const equipmentEvents = {
    setExistingEquipmentStore
};
