import { FC } from 'react';
import { createEvent, createStore, sample } from 'effector';
import { RepairPositionsList } from '../components/RepairPositionsList/RepairPositionsList';
import { GraphicSetDamageForms } from '../components/GraphicSetDamageForms/GraphicSetDamageForms';
import { commonModel } from './commonModel';
import { EquipmentListNonConfirmed } from '../components/EquipmentListNonConfirmed/EquipmentListNonConfirmed';
import { graphicDamageSelectionModel } from './graphicDamageSelectionModel';

const historyRightView = createStore<FC<any>[]>([RepairPositionsList]);
const currentView = historyRightView.map(hist => (hist.length ? hist[hist.length - 1] : null));

const setCurrentView = createEvent<FC<any> | null>();
historyRightView.on(setCurrentView, (_hist, view) => {
    if (!view) return [];
    const res = [view];
    return res;
});

const setHistoryView = createEvent<FC<any> | null>();

historyRightView.on(setHistoryView, (hist, view) => {
    if (!view) return [...hist];
    const res = [...hist, view];
    return res;
});

const backView = createEvent();
historyRightView.on(backView, hist => {
    const res = hist.slice(0, -1);
    return res;
});

const clearHistoryAndClose = createEvent();
historyRightView.on(clearHistoryAndClose, _hist => {
    const res: FC<any>[] = [];
    return res;
});

sample({
    clock: commonModel.events.setIsEquipmentCheck,
    filter: val => val,
    fn: () => EquipmentListNonConfirmed,
    target: setCurrentView
});

sample({
    clock: setCurrentView,
    fn: currentView => ({ showRightSideDrawer: !!currentView }),
    target: graphicDamageSelectionModel.events.updateStore
});

sample({
    source: currentView,
    clock: graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore,
    filter: (curView, damageStore) => {
        if (curView === GraphicSetDamageForms && !damageStore?.currentAssemblyGroupObjects?.length) {
            return true;
        }
        return false;
    },
    fn: (_curView, _damageStore) => RepairPositionsList,
    target: setCurrentView
});

export const rightSideDrawerModel = {
    stores: {
        currentView,
        historyRightView
    },
    events: {
        setCurrentView,
        setHistoryView,
        backView,
        clearHistoryAndClose
    },
    effects: {}
};
