import { pluginEvents } from '../index';
import { Instance } from '../../../types/instance';
import { InstancePropertySetter } from '../types';

export const createInstancePropertySetter = <K extends keyof Instance>(
    instanceId: string,
    key: K
): InstancePropertySetter<K> =>
    pluginEvents.updateInstance.prepend<Instance[K]>(value => ({
        instanceId,
        properties: { [key]: value }
    }));
