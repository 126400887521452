import { attach } from 'effector';
import { createGate } from 'effector-react';

import { ContractStatusChangeModalProps } from '../../index';

export const Gate = createGate<ContractStatusChangeModalProps>();

const props = Gate.state;
const contractId = props.map(props => props.contractId);

const callOnStatusChangeFx = attach({
    source: props,
    effect: (props, status: DAT2.ContractStatusTransition) => props.onStatusChange?.(status)
});

const callOnStatusChangeFailedFx = attach({
    source: props,
    effect: props => props.onStatusChangeFailed?.()
});

export const componentEffects = {
    callOnStatusChangeFx,
    callOnStatusChangeFailedFx
};
export const componentStores = {
    props,
    contractId
};
