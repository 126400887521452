import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { radioItems } from '../../../constants/radioItems';
import { selectsOptions } from '../../../constants/selectsOptions';
import { paintTypesStores } from '../../../stores/selects/paintTypes';

import {
    CheckboxFieldContainer,
    Field,
    FieldsContainer,
    RadioGroupContainer,
    TitleWrapper,
    TabsWrapper
} from '../../Layout/styles';
import { SelectField, RadioGroupField, InputField, CheckboxField, TabsField } from '@wedat/ui-kit/Formik';

import { FactorsParametersObject } from '../../../types/factors';
import { InputWithSelect } from '../../InputWithSelect';
import { Text } from '@wedat/ui-kit/components/Text';
import { FieldWrap, LeftInput, RightInput } from './styles';
import { Input } from '@wedat/ui-kit/components/Input';

export const AztLacquerFactors: React.FC = () => {
    const { t } = useTranslation();
    const {
        values: {
            AztLacquerFactor: {
                addition,
                wageMode,
                materialMode,
                wageInclMaterialMode,
                isApplyUndercoatLacquerWhenRemoved,
                isLacquerAssemblyWhenRemoved,
                selectedPaintWork,
                wageInclMaterial
            }
        },
        setFieldValue,
        initialValues
    } = useFormikContext<FactorsParametersObject>();
    const paintTypesSelectOptions = useStore(paintTypesStores.selectOptionsAzt);

    /* "addition" affects "exemplarySheets"  */
    // TODO: после открытия labour rates, компонент реднерится несколько раз и в addition появляется boolean значение
    const isExemplarySheetsDisabled = !(addition || []).includes('B');
    const isColourMixCounterDisabled = !(addition || []).includes('A');

    const onUserChangeAddition = (selectedValues: string[]) => {
        const shouldSetInitialExemplarySheets = selectedValues.includes('B');
        const shouldSetColourMixCounter = selectedValues.includes('A');

        setFieldValue(
            'AztLacquerFactor.exemplarySheets',
            shouldSetInitialExemplarySheets ? initialValues.AztLacquerFactor.exemplarySheets : ''
        );
        setFieldValue(
            'AztLacquerFactor.colourMixCounter',
            shouldSetColourMixCounter ? initialValues.AztLacquerFactor.colourMixCounter : ''
        );
    };

    const percentOrAbsolute = radioItems.percentOrAbsolute(t);
    const perTimeOrAbsolute = radioItems.perTimeOrAbsolute(t);
    const materialModeValues = radioItems.materialMode(t);
    const selectedPaintWorkValues = radioItems.selectedPaintWork(t);

    const tabValues = selectedPaintWorkValues.map(item => ({ ...item, id: item.value }));

    const shouldShowMaterialSeparately = selectedPaintWork === 'WAGE_MATERIAL_SEPARATELY';
    const shouldShowMaterialInclusive = selectedPaintWork === 'WAGE_INCLUSIVE_MATERIAL';

    const wageInclMaterialStatic = Number(wageInclMaterial) / 100;

    return (
        <>
            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="AztLacquerFactor.type"
                        options={paintTypesSelectOptions}
                        label={t('paint.type')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
                <Field>
                    <SelectField
                        changeCallbackMulti={onUserChangeAddition}
                        name="AztLacquerFactor.addition"
                        options={selectsOptions.addition}
                        isMulti={true}
                        label={t('paint.addition')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
                <Field>
                    <InputField
                        disabled={isColourMixCounterDisabled}
                        name="AztLacquerFactor.colourMixCounter"
                        label={t('paint.colourMixCounter')}
                    />
                </Field>
                <Field>
                    <InputField
                        disabled={isExemplarySheetsDisabled}
                        name="AztLacquerFactor.exemplarySheets"
                        label={t('paint.exemplarySheets')}
                    />
                </Field>
                <Field>
                    <InputField name="AztLacquerFactor.colorName" label={t('paint.colorName')} />
                </Field>
                <Field>
                    <InputField name="AztLacquerFactor.colorCode" label={t('paint.colorCode')} />
                </Field>
                <Field>
                    <InputField name="AztLacquerFactor.color2ndName" label={`${t('paint.color2ndName')} [2]`} />
                </Field>
                <Field>
                    <InputField name="AztLacquerFactor.color2ndCode" label={`${t('paint.color2ndCode')} [2]`} />
                </Field>
            </FieldsContainer>
            <TabsWrapper>
                <TabsField name="AztLacquerFactor.selectedPaintWork" values={tabValues} typeStyle="mobile" />
            </TabsWrapper>
            {shouldShowMaterialSeparately && (
                <>
                    <TitleWrapper>
                        <Text font="defaultBold">{t('paint.wageSubtitle')}</Text>
                    </TitleWrapper>

                    <RadioGroupContainer>
                        <RadioGroupField items={perTimeOrAbsolute} name="AztLacquerFactor.wageMode" />
                    </RadioGroupContainer>

                    <FieldsContainer marginTop>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.wage"
                                disabled={wageMode !== 'PER_TIME'}
                                label={t('paint.wage')}
                            />
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.wageFlatRate"
                                disabled={wageMode !== 'ABSOLUTE'}
                                label={t('paint.wageFlatRate')}
                            />
                        </Field>
                        <Field>
                            <InputWithSelect
                                inputProps={{
                                    name: 'AztLacquerFactor.discountWage',
                                    label: t('common.discount')
                                }}
                                selectProps={{
                                    name: 'AztLacquerFactor._attributes.mode.discountWage',
                                    options: percentOrAbsolute
                                }}
                            />
                        </Field>
                    </FieldsContainer>

                    <TitleWrapper>
                        <Text font="defaultBold">{t('paint.paintMaterialSubtitle')}</Text>
                    </TitleWrapper>

                    <RadioGroupContainer>
                        <RadioGroupField items={materialModeValues} name="AztLacquerFactor.materialMode" />
                    </RadioGroupContainer>

                    <FieldsContainer marginTop>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.materialPerPointCost"
                                disabled={materialMode !== 'LACQUER_METHOD'}
                                label={t('paint.aztLacquer.repairWageLacquerMaterialPerPoint')}
                            />
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.materialIndex"
                                disabled={materialMode !== 'LACQUER_METHOD'}
                                label={`${t('paint.aztLacquer.materialIndex')}*`}
                            />
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.materialFlatRatePercent"
                                disabled={materialMode !== 'PERCENT'}
                                label={t('paint.aztLacquer.materialFlatRatePercent')}
                            />
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.materialFlatRateAbsolute"
                                disabled={materialMode !== 'ABSOLUTE'}
                                label={`${t('paint.aztLacquer.materialFlatRateAbsolute')}*`}
                            />
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.materialSpecialLacquer"
                                label={t('paint.aztLacquer.materialSpecialLacquer')}
                            />
                        </Field>
                        <Field>
                            <InputWithSelect
                                inputProps={{
                                    name: 'AztLacquerFactor.discountMaterial',
                                    label: t('common.discount')
                                }}
                                selectProps={{
                                    name: 'AztLacquerFactor._attributes.mode.discountMaterial',
                                    options: percentOrAbsolute
                                }}
                            />
                        </Field>
                    </FieldsContainer>
                </>
            )}
            {shouldShowMaterialInclusive && (
                <>
                    <RadioGroupContainer>
                        <RadioGroupField items={perTimeOrAbsolute} name="AztLacquerFactor.wageInclMaterialMode" />
                    </RadioGroupContainer>
                    <FieldsContainer marginTop>
                        <Field>
                            <FieldWrap>
                                <LeftInput>
                                    <InputField
                                        name="AztLacquerFactor.wageInclMaterial"
                                        disabled={wageInclMaterialMode !== 'PER_TIME'}
                                        label={t('paint.wageInclMaterial')}
                                    />
                                </LeftInput>
                                <RightInput>
                                    <Input
                                        label={t('paint.wageInclMaterialStatic')}
                                        value={String(wageInclMaterialStatic)}
                                        disabled
                                    />
                                </RightInput>
                            </FieldWrap>
                        </Field>
                        <Field>
                            <InputField
                                name="AztLacquerFactor.wageInclMaterialFlatRate"
                                disabled={wageInclMaterialMode !== 'ABSOLUTE'}
                                label={t('paint.wageInclMaterialFlatRate')}
                            />
                        </Field>
                        <Field>
                            <InputWithSelect
                                inputProps={{
                                    name: 'AztLacquerFactor.discountWageInclMaterial',
                                    label: t('common.discount')
                                }}
                                selectProps={{
                                    name: 'AztLacquerFactor._attributes.mode.discountWageInclMaterial',
                                    options: percentOrAbsolute
                                }}
                            />
                        </Field>
                    </FieldsContainer>
                </>
            )}

            <TitleWrapper>
                <Text font="defaultBold">{t('paint.additionalSurcharging')}</Text>
            </TitleWrapper>
            <FieldsContainer>
                <Field>
                    <InputWithSelect
                        inputProps={{
                            name: 'AztLacquerFactor.newForOld',
                            label: t('paint.newForOld')
                        }}
                        selectProps={{
                            name: 'AztLacquerFactor._attributes.mode.newForOld',
                            options: percentOrAbsolute
                        }}
                    />
                </Field>
            </FieldsContainer>
            <FieldsContainer marginTop>
                <Field>
                    <InputField
                        name="AztLacquerFactor.preparationTimePercent"
                        label={t('paint.aztLacquer.preparationTimePercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="AztLacquerFactor.preparationTimePlasticPercent"
                        label={t('paint.aztLacquer.preparationTimePlasticPercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="AztLacquerFactor.otherPreparationTimePercent"
                        label={t('paint.aztLacquer.otherPreparationTimePercent')}
                    />
                </Field>
                <Field>
                    <InputField
                        name="AztLacquerFactor.disposalCostPercent"
                        label={t('paint.aztLacquer.disposalCostPercent')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.maskingWorkGlassCount}
                        name="AztLacquerFactor.maskingWorkGlassCount"
                        label={t('paint.aztLacquer.maskingWorkGlassCount')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.maskingWorkPlasticCount}
                        name="AztLacquerFactor.maskingWorkPlasticCount"
                        label={t('paint.aztLacquer.maskingWorkPlasticCount')}
                    />
                </Field>
                <Field>
                    <SelectField
                        options={selectsOptions.matBlackWindowFrameCount}
                        name="AztLacquerFactor.matBlackWindowFrameCount"
                        label={t('paint.aztLacquer.matBlackWindowFrameCount')}
                    />
                </Field>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="AztLacquerFactor.isSurchargeForSecondColor"
                        label={t('paint.aztLacquer.isSurchargeForSecondColor')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <TitleWrapper>
                <Text font="defaultBold">{t('paint.componentCondition')}</Text>
            </TitleWrapper>
            <FieldsContainer marginTop>
                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={isLacquerAssemblyWhenRemoved}
                        name="AztLacquerFactor.isApplyUndercoatLacquerWhenRemoved"
                        label={t('paint.aztLacquer.isApplyUndercoatLacquerWhenRemoved')}
                    />
                </CheckboxFieldContainer>

                <CheckboxFieldContainer>
                    <CheckboxField
                        disabled={isApplyUndercoatLacquerWhenRemoved}
                        name="AztLacquerFactor.isLacquerAssemblyWhenRemoved"
                        label={t('paint.aztLacquer.isLacquerAssemblyWhenRemoved')}
                    />
                </CheckboxFieldContainer>

                <CheckboxFieldContainer>
                    <CheckboxField
                        name="AztLacquerFactor.automaticEDismounted"
                        label={t('paint.aztLacquer.automaticEDismounted')}
                    />
                </CheckboxFieldContainer>

                <CheckboxFieldContainer>
                    <CheckboxField
                        name="AztLacquerFactor.isLacquerPlasticWhenFitted"
                        label={t('paint.aztLacquer.isLacquerPlasticWhenFitted')}
                    />
                </CheckboxFieldContainer>

                <CheckboxFieldContainer>
                    <CheckboxField
                        name="AztLacquerFactor.isLaquerWithoutDisassembly"
                        label={t('paint.aztLacquer.isLaquerWithoutDisassembly')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
        </>
    );
};
