import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { TextArea } from '@wedat/ui-kit/components/TextArea';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const TextareaStyled = styled(TextArea)`
    width: 100%;
    margin-right: 10px;
    margin-bottom: 0;
    line-height: 27px;
    border: none;

    ${makeCustomScrollBar()}

    &::placeholder {
        line-height: 27px;
        font-weight: 500;
    }
`;

export const SendButton = styled(Button)`
    width: 48px;
    height: 48px;
    margin: auto 0 0 auto;
    border-radius: 3px;
    border: none;
    flex-shrink: 0;

    &:hover,
    :focus {
        border: none;
        background-color: ${({ theme }) => theme.colors.blueDark};
    }
`;
