import React from 'react';
import { useStore } from 'effector-react';
import _ from 'lodash';

import { dialogStores } from '../../stores/dialogs';
import { sharedUserStores } from '@dat/shared-models/user';
import { sharedProfilesStores } from '@dat/shared-models/profiles';
import { getDialogItemCompanionName } from '../../utils/getDialogItemCompanionName';
import { getCompanionUserAvatar } from '../../utils/getCompanionUserAvatar';

// import { SearchAndFilter } from './SearchAndFilter';
import { DialogItem } from './DialogItem';
import { Container, RoleBlocksWrapper, RoleBlock, TextStyled, Header, HeaderText } from './styles';
import { pluginStores } from '../../stores/plugin';
import { CloseIconStyled } from '../MessagingArea/Header/styles';
import { useTranslation } from 'react-i18next';

export const DialogList = () => {
    const { t } = useTranslation();
    const partners = useStore(dialogStores.partners);
    const roleSortedDialogs = useStore(dialogStores.roleSortedDialogs);
    const username = useStore(sharedUserStores.username);
    const customerNumber = useStore(sharedUserStores.customerNumber);
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const sameCustomerProfiles = useStore(sharedProfilesStores.sameCustomerProfiles);

    return (
        <Container>
            {/* <SearchAndFilter /> */}
            {/* TODO: delete after add SearchAndFilter */}
            <Header>
                <HeaderText font="semiHeading" color="inherit">
                    {t('title')}
                </HeaderText>
                <CloseIconStyled
                    width="18"
                    height="18"
                    onClick={() => {
                        pluginOptions?.closeChat?.();
                    }}
                />
            </Header>
            <RoleBlocksWrapper>
                {Object.entries(roleSortedDialogs).map(([role, dialogs]) => (
                    <RoleBlock key={role}>
                        <TextStyled textTransform="capitalize" color="inherit">
                            {role.toLowerCase()}
                        </TextStyled>
                        {dialogs.map(dialog => {
                            const companionName = getDialogItemCompanionName({
                                dialog,
                                username,
                                sameCustomerProfiles,
                                partners,
                                customerNumber
                            });

                            const companioUserAvatar = getCompanionUserAvatar({
                                dialog,
                                username,
                                sameCustomerProfiles
                            });

                            return (
                                <DialogItem
                                    key={dialog.id}
                                    dialogId={dialog.id}
                                    companionName={companionName}
                                    companionAvatar={companioUserAvatar}
                                />
                            );
                        })}
                    </RoleBlock>
                ))}
            </RoleBlocksWrapper>
        </Container>
    );
};
