import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Label } from '@wedat/ui-kit/components/Label';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div`
    border-radius: 2px;
    max-width: 279px;
    height: 48px;

    ${media.laptopSmall`
        max-width: unset;
    `}
`;

export const LabelStyled = styled(Label)``;

export const SubjectName = styled.div`
    width: calc(100% - 40px);
    padding-left: 12px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    font: ${({ theme }) => theme.typography.note.font};
    font-weight: 500;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ButtonStyled = styled(Button)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.white};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.dustBlue['400']};
    border-color: ${({ theme }) => theme.colors.white};
    text-transform: capitalize;
    border: none;
    transition: border-color 150ms, opacity 150ms;

    &:hover {
        background-color: transparent;
        border-color: ${({ theme }) => theme.colors.dustBlue['400']};
    }

    &:focus-visible,
    &:focus {
        background-color: transparent;
        border-color: ${({ theme }) => theme.colors.blue['500']};

        ${LabelStyled} {
            color: ${({ theme }) => theme.colors.blue['500']};
        }
    }

    &:disabled {
        background-color: transparent;
        opacity: 0.4;
    }
`;

export const List = styled.div`
    position: absolute;
    z-index: 2;
    padding-top: 6px;
    padding-bottom: 6px;
    top: calc(100% - 8px);
    left: -1px;
    width: 100%;
    max-height: 200px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['400']};
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transform: scaleY(0);
    box-sizing: content-box;
    opacity: 0;
    transform-origin: 100% 0;
    transition: transform 150ms, opacity 150ms;
`;

export const Item = styled.div`
    padding: 2px 12px;
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const ButtonFilledStyled = styled(ButtonStyled)`
    border: none;

    &:hover {
        ${List} {
            opacity: 1;
            transform: scaleY(1);
        }
    }
`;

export const Icon = styled.div`
    position: absolute;
    right: 14px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const ButtonAddressBook = styled.div`
    padding: 14px 18px;
    display: flex;
    align-items: center;
`;

export const IconImage = styled.img`
    width: 20px;
    height: 20px;
`;

export const TextStyled = styled(Text)`
    margin-left: 14px;
`;
