import { sample } from 'effector';
import { spread } from 'patronum';

import { configurationStores } from './index';
import { pluginStores } from '../plugin';
import { aiGalleryEvents } from '../gallery';
import { defaultConfiguration } from '../../constants/defaultConfiguration';
import { sharedTemplateStores } from '@dat/shared-models/template';

const { configuration } = configurationStores;
const { pluginOptions } = pluginStores;
const { productsConfiguration } = sharedTemplateStores;

sample({
    source: { pluginOptions, productsConfiguration },
    fn: ({ pluginOptions, productsConfiguration }) => ({
        ...defaultConfiguration,
        ...productsConfiguration?.['ai-gallery'],
        ...pluginOptions
    }),
    target: configuration
});

spread({
    source: configuration,
    targets: {
        hasPanel: aiGalleryEvents.setPanel,
        hasPanelDamage: aiGalleryEvents.setPanelDamage,
        AttachmentFolderId: aiGalleryEvents.setAttachmentFolderId
    }
});
