import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { contractStores } from '@dat/shared-models/contract';
import { modalsEvents, modalsStores } from '../../stores/ui/modals';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Table } from '@wedat/ui-kit/components/Table';
import { ButtonStyled, InfoTableWrapper, ButtonWrapper } from './styles';

export const ModalInfoTable: React.FC = () => {
    const { t } = useTranslation();
    const activeInfoPosition = useStore(modalsStores.activeInfoPosition);
    const contract = useStore(contractStores.contract);

    const labourPosition =
        contract?.Dossier?.RepairCalculation?.CalcResultCustomerRanking?.LabourPositions?.LabourPosition;

    const isLabourPositionArray = Array.isArray(labourPosition);

    const currentPositionCustomerRankingData = isLabourPositionArray
        ? labourPosition?.find(
              (item: DAT2.CustomerRankingLabourPosition) => item.DATProcessId === activeInfoPosition?.DATProcessId
          )
        : labourPosition;

    const connectedPositions = useMemo(
        () =>
            isLabourPositionArray
                ? labourPosition?.filter(
                      (item: DAT2.CustomerRankingLabourPosition) =>
                          item.RequiredByPosition?.DATProcessId === currentPositionCustomerRankingData?.DATProcessId
                  )
                : [],
        [currentPositionCustomerRankingData?.DATProcessId, isLabourPositionArray, labourPosition]
    );

    const infoColumns = useMemo(
        () => [
            {
                Header: t('common.rc'),
                accessor: 'col1'
            },
            {
                Header: t('common.dvn'),
                accessor: 'col2'
            },
            {
                Header: t('common.wpn'),
                accessor: 'col3'
            },
            {
                Header: t('common.designation'),
                accessor: 'col4'
            },
            {
                Header: t('labour.labourTime'),
                accessor: 'col5'
            }
        ],
        [t]
    );

    const infoData = useMemo(() => {
        const { RepairType, DATProcessId, LabourPosId, Description, Duration } = {
            ...currentPositionCustomerRankingData
        };

        const connectedPositionsData =
            connectedPositions?.map((item: DAT2.CustomerRankingLabourPosition) => ({
                col1: item.RepairType || '-',
                col2: item.DATProcessId || '-',
                col3: item.LabourPosId || '-',
                col4: item.Description || '-',
                col5: item.Duration || '-'
            })) || [];

        const currentPositionData = [
            {
                col1: RepairType || '-',
                col2: DATProcessId || '-',
                col3: LabourPosId || '-',
                col4: Description || '-',
                col5: Duration || '-'
            }
        ];

        return [...connectedPositionsData, ...currentPositionData];
    }, [connectedPositions, currentPositionCustomerRankingData]);

    return (
        <ModalPrimary
            title={t('modalInfoTable.title')}
            bodyHeight="fit-content"
            isOpen={!!activeInfoPosition}
            onDismiss={() => modalsEvents.setActiveInfoPosition(null)}
        >
            <InfoTableWrapper>
                <Table columns={infoColumns} data={infoData || []} mobileHeaders />
            </InfoTableWrapper>
            <ButtonWrapper>
                <ButtonStyled onClick={() => modalsEvents.setActiveInfoPosition(null)}>{t('common.ok')}</ButtonStyled>
            </ButtonWrapper>
        </ModalPrimary>
    );
};
