import styled, { css } from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div<{ small?: boolean }>`
    position: relative;
    display: flex;
    min-width: 140px;
    width: 140px;
    height: 140px;
    background-color: ${({ theme }) => theme.colors.dustBlue['50']};
    border-radius: 8px;
    overflow: hidden;
    user-select: none;

    ${({ small }) =>
        small &&
        css`
            min-width: 92px;
            width: 92px;
            height: 92px;
        `}
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

interface OverlayProps {
    selected?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: rgba(70, 80, 100, 0.8);
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 0.15s ease;
    cursor: pointer;

    &:hover,
    &:active {
        opacity: 1;
    }

    ${media.laptop`
        display: none;
    `}
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
    transition: opacity 0.15s;
    color: ${({ theme }) => theme.colors.white};

    &:hover {
        background-color: rgba(70, 80, 100, 0.95);
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.dustBlue['800']};
    }

    ${media.laptop`
        &:hover {
            background-color: transparent;
        }
    `}
`;

export const SelectedIconWrapper = styled(IconWrapper)`
    position: absolute;
    top: 0;
    right: 0;
`;

export const MobileIconWrapper = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba(70, 80, 100, 0.8);
    color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;
    width: 32px;
    height: 32px;

    ${media.laptop`
        display: flex;
    `};
`;

export const DocumentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Name = styled(Text)`
    position: absolute;
    left: 4px;
    right: 4px;
    text-align: center;
    bottom: 8px;

    ${media.laptop`
        bottom: 4px;
    `};
`;
