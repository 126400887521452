import styled, { keyframes, css } from 'styled-components';

interface FadeOutContainerProps {
    show: boolean;
    fullFill?: boolean;
}

const fadeIn = keyframes` 
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

const fadeOut = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `;

export const FadeContainer = styled.div<FadeOutContainerProps>`
    animation: ${({ show }) => (show ? fadeIn : fadeOut)} 1s;
    ${({ fullFill }) =>
        fullFill &&
        css`
            width: 100%;
            height: 100%;
        `};
`;
