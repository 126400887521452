import { createEffect, createStore } from 'effector';
import { createReEffect } from 'effector-reeffect';
import { API2 } from '@dat/api2';
import { CustomersSubjectsData } from './type';

const getSubjectsFx = createEffect(API2.bff.addressBook.getSubjects);
const updateSubjectsFx = createReEffect({ handler: API2.bff.addressBook.updateSubjects, strategy: 'QUEUE' });

const customersSubjectsData = createStore<CustomersSubjectsData>({});

export const sharedSubjectsDataStores = {
    customersSubjectsData
};
export const sharedSubjectsDataEffects = {
    getSubjectsFx,
    updateSubjectsFx
};
