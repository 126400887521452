import { CalculateParams } from '@dat/api2dat/types/api2datTypes';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { PluginOptions } from '../types/plugin';
import { convertRepairType } from './ftRepairToRepairPosition';

const lacquerFactors = [
    {
        lacquerMethod: 'AZT',
        lacquerFactor: 'AztLacquerFactor'
    },
    {
        lacquerMethod: 'EURO_LACQUER',
        lacquerFactor: 'EuroLacquerFactor'
    },
    {
        lacquerMethod: 'MANUFACTURER_SPECIFIC',
        lacquerFactor: 'ManufacturerLacquerFactor'
    }
];

/*
 * Returns object with electric/mechanic/body/lacquer wages and lacquerTypeId in appropriate view for further parsing to XML
 */
const generateCalculationWages = (
    electric?: number,
    mechanic?: number,
    body?: number,
    lacquer?: number,
    lacquerTypeId?: number
) => [
    {
        attr: {
            level: 1,
            type: 'VRO_REPAIRWAGE_ELECTRIC'
        },
        '#text': electric
    },
    {
        attr: {
            level: 1,
            type: 'VRO_REPAIRWAGE_MECHANIC'
        },
        '#text': mechanic
    },
    {
        attr: {
            level: 1,
            type: 'VRO_REPAIRWAGE_BODY'
        },
        '#text': body
    },
    {
        attr: {
            level: 1,
            type: 'lacquer'
        },
        '#text': lacquer
    },
    {
        attr: {
            level: 1,
            type: 'lacquerType'
        },
        '#text': lacquerTypeId
    }
];

/**
 *
 * @param pluginOptions
 * @param repairPositions
 * @param equipmentPositions - all already existing and selected equipment
 * @returns object with parameters for further SOAP request (vehicleRepairService -> calculate)
 *          or NULL if some required parameters are missing
 */
export const getInterimCalcParams = (
    pluginOptions: PluginOptions | null,
    repairPositions?: RepairPosition[] | null,
    equipmentPositions?: (number | undefined)[]
): CalculateParams => {
    const dossier = pluginOptions?.settings?.contract?.Dossier;
    const locale = pluginOptions?.settings?.locale;
    const repairParameters = dossier?.RepairCalculation?.RepairParameters;
    // if (!repairParameters) return null;

    const procedureRelatedParameters =
        dossier?.RepairCalculation?.ProcedureRelatedParameters?.ProcedureRelatedParameter;

    // REPAIR POSITIONS
    const datProcessInfo = repairPositions?.map(repPos => {
        const repairCode = convertRepairType(repPos.RepairType || 'replace')?.code || '';

        return {
            attr: {
                method: repPos.PositionEntryType || 'graphical'
            },
            datProcessId: repPos.DATProcessId || 0,
            repairCode: repairCode,
            // TODO: find out "Work Price" parameter name in request for positions with repair types OVERHAUL, FIXING, REPLACE
            price: repPos.SparePartPrice || repPos.LacquerPrice,
            // blockWageFlatPrice: repPos.WorkPrice || repPos.LacquerPrice, // work price. !*** doesn't work ??? ***!
            time: repairCode === 'I' ? repPos.WorkTime || 0 : repPos.WorkTime,
            discount: repPos.SparePartDiscount,
            lacquerLevel: repPos.LacquerLevelId,
            preDamage: repPos.PreDamage
        };
    });

    // EQUIPMENT
    const equipment = equipmentPositions?.map(eq => ({
        '#text': eq
    }));

    // LACQUER
    const lacquerTypeId = repairParameters?.LacquerTypeId;
    const lacquerMethod = repairParameters?.LacquerMethod;
    const lacquerFactor = lacquerFactors.find(lf => lf.lacquerMethod === lacquerMethod)?.lacquerFactor;

    // WAGES
    const bodyWage = procedureRelatedParameters?.find(par => par.attr.attribute === 'bodyWage1')?.['#text'] as
        | number
        | undefined;
    const mechanicWage = procedureRelatedParameters?.find(par => par.attr.attribute === 'mechanicWage1')?.['#text'] as
        | number
        | undefined;
    const electricWage = procedureRelatedParameters?.find(par => par.attr.attribute === 'electricWage1')?.['#text'] as
        | number
        | undefined;
    const lacquerWage = procedureRelatedParameters?.find(
        par => par.attr.attribute === 'wage' && par.attr.factor === lacquerFactor
    )?.['#text'] as number | undefined;

    return {
        locale: {
            attr: locale
        },
        calculationWages: {
            calculationWage: generateCalculationWages(electricWage, mechanicWage, bodyWage, lacquerWage, lacquerTypeId)
        },
        constructionTime: dossier?.Vehicle?.ConstructionTime || 0,
        datECode: dossier?.Vehicle?.DatECode || '',
        equipment: equipment,
        isDMSCalculation: repairParameters?.DMSCalculation || false,
        isPhantomCalculation: repairParameters?.PhantomCalculation || false,
        isSerialCalculation: repairParameters?.SeriesSpecific || false,
        paintingMethod: lacquerMethod || '',
        useTimeUnitsOfManufacturer: repairParameters?.TimeUnitsOfManufacturer || false,
        datProcessInfo: datProcessInfo
    };
};
