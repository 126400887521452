import { forward } from 'effector';

import { imagesEffects } from './index';
import { contractsListEffects } from '../contractsList';

const { getImagesOfContractsFx } = imagesEffects;
const { loadMoreContractsFx } = contractsListEffects;

forward({
    from: loadMoreContractsFx.doneData,
    to: getImagesOfContractsFx
});
