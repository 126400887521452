import { createEvent, createStore } from 'effector';
import { CancelledError, TimeoutError } from './error';
import { assign, read } from './tools';
export const cancellable = (promise, abort, timeout) => {
    let cancel;
    const cancelable = new Promise((_, reject) => {
        let timeoutId;
        const rejectWith = Cls => arg => {
            clearTimeout(timeoutId);
            reject(new Cls(arg));
            abort && abort();
        };
        cancel = rejectWith(CancelledError);
        if (typeof timeout === 'number') {
            timeoutId = setTimeout(rejectWith(TimeoutError), timeout, timeout);
        }
    });
    return assign(Promise.race([promise, cancelable]), { cancel });
};
export const defer = () => {
    const deferred = {};
    deferred.req = new Promise((resolve, reject) => {
        deferred.rs = resolve;
        deferred.rj = reject;
    });
    deferred.req.catch(() => { });
    return deferred;
};
export const createRunning = () => {
    const cancelAllEv = createEvent({
        sid: 'internal/cancelAll',
    });
    const $running = createStore([], {
        sid: 'internal/$running',
        serialize: 'ignore',
    });
    $running.watch(cancelAllEv, (runs, strategy) => runs.forEach(p => p.cancel(strategy)));
    const push = (promise, scope) => {
        read(scope)($running).push(promise);
    };
    const unpush = (promise, scope) => {
        if (!promise)
            return;
        read(scope)($running).splice(read(scope)($running).indexOf(promise), 1);
    };
    const cancelAll = (strategy, scope) => {
        read(scope)($running).forEach(p => p.cancel(strategy));
    };
    return { $running, push, unpush, cancelAll, cancelAllEv };
};
