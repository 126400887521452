import { EquipmentPluginResult } from '@dat/equipment';

export const parseEquipmentPluginResult = (equipmentPluginResult: EquipmentPluginResult): DAT2.Equipment => {
    const seriesEquipment = equipmentPluginResult?.SeriesEquipment.map(({ DatEquipmentId }) => ({
        DatEquipmentId
    }));
    const specialEquipment = equipmentPluginResult?.SpecialEquipment.map(({ DatEquipmentId }) => ({
        DatEquipmentId
    }));

    return {
        SeriesEquipment: {
            EquipmentPosition: seriesEquipment
        },
        SpecialEquipment: {
            EquipmentPosition: specialEquipment
        }
    };
};
