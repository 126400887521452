import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import { greetingStores, greetingEvents } from '../../stores/greeting';
import { pluginEffects } from '../../stores/plugin';

import { Fade } from '@wedat/ui-kit/components/Fade';
import { ProfileForm } from '@dat/smart-components/ProfileForm';
import { WelcomePopup } from '../../components/WelcomePopup';
import { FormAuth } from '../../components/FormAuth';
import { AuthPageStyled, CarImg, LeftSide, LogoImg, RightSide, StyledFade } from './styles';
import carImg from '../../assets/car.svg';
import logoImg from '../../assets/logo.svg';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';

export const AuthPage: React.FC = () => {
    const isFormHidden = useStore(greetingStores.isFormHidden);
    const isWelcomeShown = useStore(greetingStores.isWelcomeShown);
    const isProfileShown = useStore(greetingStores.isProfileShown);
    const isMobile = useMedia(sizes.phoneBig);

    useEffect(() => {
        greetingEvents.setIsFormAuthTriggered(false);
    }, []);

    return (
        <AuthPageStyled>
            {isMobile && isProfileShown ? null : <LogoImg src={logoImg} alt="logo" />}

            {!isMobile && (
                <LeftSide>
                    <StyledFade show>
                        <CarImg src={carImg} />
                    </StyledFade>
                </LeftSide>
            )}

            <RightSide>
                <Fade show={!isFormHidden}>
                    <FormAuth />
                </Fade>
                <Fade
                    show={isWelcomeShown}
                    onFadeInEnd={greetingEvents.hideWelcomeModal}
                    onFadeOutEnd={greetingEvents.onWelcomeHide}
                >
                    <WelcomePopup />
                </Fade>
                <Fade
                    show={isProfileShown}
                    onFadeOutEnd={() => {
                        pluginEffects.invokeOnLoginCallbackFx();
                    }}
                    fullFill={isMobile}
                >
                    <ProfileForm inAuth />
                </Fade>
            </RightSide>
        </AuthPageStyled>
    );
};
