import { attach, forward, sample, split } from 'effector';

import { pluginEffects, pluginEvents, PluginGate, pluginStores } from './index';
import { formEvents } from '../form';
import { sharedAuthEffects, sharedAuthStores } from '@dat/shared-models/auth';
import { datAuthTokenName } from '@dat/core/constants';
import { PluginOptions } from '../../types/plugin';
import { greetingEvents } from '../greeting';
import { sharedLoginEvents, sharedLoginStores } from '@dat/shared-models/auth/login';

const { initPlugin } = pluginEvents;
const { authRenderlessFx, invokeOnLoginCallbackFx, invokeOnTokenExpireCallbackFx } = pluginEffects;
const { pluginOptions } = pluginStores;
const { shouldLoginViaForm } = formEvents;
const { token } = sharedLoginStores;
const { isLoggedOutManually } = sharedAuthStores;
const { setIsReadyToLogin } = greetingEvents;

// TODO: это костыль, нужно убрать его после создания компонента PluginProvider
split({
    // @ts-ignore TODO: ignore - error occurred after installing effector 22.2.0
    source: PluginGate.open,
    match: {
        isAuthorized: sharedLoginStores.isAuthorized
    },
    cases: {
        isAuthorized: attach({
            source: token,
            effect: (token, options: PluginOptions) => {
                options.onLogin?.(token);
                setIsReadyToLogin(true);
            }
        }),
        __: initPlugin
    }
});

invokeOnLoginCallbackFx.use(
    attach({
        source: { pluginOptions, token },
        effect: ({ pluginOptions, token }) => {
            pluginOptions?.onLogin?.(token);
            setIsReadyToLogin(false);
        }
    })
);

const localStorageToken = localStorage.getItem(datAuthTokenName) || '';

const initialized = split(sample({ pluginOptions, isLoggedOutManually }, initPlugin), {
    afterManualLogout: ({ isLoggedOutManually }) => isLoggedOutManually,
    withDataForLogin: ({ pluginOptions }) =>
        !!pluginOptions?.credentials || !!pluginOptions?.token || !!localStorageToken
});

forward({
    from: [initialized.afterManualLogout, initialized.__],
    to: shouldLoginViaForm
});

sample({
    source: initialized.withDataForLogin,
    fn: ({ pluginOptions }) => ({
        credentials: pluginOptions?.credentials,
        token: pluginOptions?.token || localStorageToken
    }),
    target: sharedAuthEffects.loginFx
});

// TODO: when "show welcome modal" parameter is created use code below with that parameter as condition
// forward({
//     from: sharedAuthEvents.loggedIn,
//     to: invokeOnLoginCallbackFx
// });

forward({
    from: sharedLoginEvents.loggedOut,
    to: invokeOnTokenExpireCallbackFx
});

authRenderlessFx.use(
    options =>
        new Promise((resolve, reject) => {
            const unwatch = shouldLoginViaForm.watch(() => {
                reject();
                unwatch();
            });

            initPlugin({
                ...options,
                onLogin: token => {
                    options.onLogin?.(token);
                    resolve();
                }
            });
        })
);
