import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { useMedia } from '@dat/core/hooks/useMedia';

import { AdministrationMenu } from '../../components/Administration';
import { PartSelectionPage } from '../Claim/PartSelectionPage';
import { LabourRatesGenericPage } from '../Claim/LabourRatesGeneric';

import { sizes } from '@wedat/ui-kit/mediaQueries';

import { ROUTES } from '../../constants/router';

import { AdministrationWrapper, Content, IconWrapper } from './style';

import { Header } from '@dat/smart-components/Header';
import { ArrowBack } from '@wedat/ui-kit/components/Icons';

export const AdministrationPage: React.FC = () => {
    const isLaptop = useMedia(sizes.laptopSmall);
    const history = useHistory();

    const handleGoBack = () => {
        history.push('/');
    };

    return (
        <>
            <Header />
            <IconWrapper onClick={handleGoBack}>
                <ArrowBack />
            </IconWrapper>
            <AdministrationWrapper isLaptop={isLaptop}>
                <AdministrationMenu />
                <Content isLaptop={isLaptop}>
                    <Route exact path={ROUTES.administration.partSelection} component={PartSelectionPage} />
                    <Route exact path={ROUTES.administration.labourRatesGeneric} component={LabourRatesGenericPage} />
                </Content>
            </AdministrationWrapper>
        </>
    );
};
