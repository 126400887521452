import { MultiSelectValue, SingleSelectValue, ValueLabelOption } from './types';

interface GetValueForReactSelectParam {
    options: ValueLabelOption[];
    value: SingleSelectValue | MultiSelectValue;
    isMulti?: boolean;
    // use this key to define value. We can either have value or key as value
    valueKey: 'value' | 'key';
    // Need for typecast
    valueType?: 'string' | 'number';
}

const checkEqualValue = (
    optionValue: ValueLabelOption<string | number>['key'],
    value: SingleSelectValue | MultiSelectValue,
    valueType?: string
) => {
    if (valueType === 'string') {
        return String(optionValue) === String(value);
    }
    if (valueType === 'number' && value !== '' && value !== null) {
        // + don't work because value can be null
        return Number(optionValue) === Number(value);
    }

    return optionValue === value;
};

export const getValueForReactSelect = ({
    options,
    value,
    isMulti,
    valueKey,
    valueType
}: GetValueForReactSelectParam): ValueLabelOption | ValueLabelOption[] | null => {
    if (isMulti) {
        if (!Array.isArray(value)) {
            return null;
        }

        return options.filter(option => value.includes(option[valueKey] || ''));
    }

    const result = options.find(option => checkEqualValue(option[valueKey], value, valueType)) || null;
    return result;
};
