import { SoftRequired } from '@dat/core/types/utility';

// TODO: URLS SHOULD BE IN ENV
export const INITIAL_CUSTOMER_SETTINGS: SoftRequired<DAT2.CustomerSettings> = {
    role: 'REPAIRER',
    apiUrl: process.env.REACT_APP_API_URL || '',
    bffUrl: process.env.REACT_APP_BFF_URL || '',
    aniaUrl: 'https://wedat.it/domus/api',
    prUrl: 'https://api.platerecognizer.com/v1/',
    isAdmin: false
};

export const INITIAL_USER_SETTINGS: SoftRequired<Omit<DAT2.UserSettings, 'locale'>> = {
    // `locale` is defined in `i18n` stores
    availableTemplateIds: undefined,
    defaultTemplateId: undefined,
    welcomePopup: undefined,
    profileManagement: undefined,
    theme: undefined
};

export const INITIAL_TEMPLATE_SETTINGS: SoftRequired<DAT2.TemplateSettings> = {
    country: 'IT',
    currency: 'EUR',
    networkType: 'ANIA',
    contractType: 'vro_calculation',
    restriction: 'REPAIR',
    disabledStatuses: ['PreProcessing'],
    initialStatus: undefined,
    externalJS: undefined,
    externalCSS: undefined
};

export const INITIAL_TEMPLATE_CONFIGURATION: DAT2.TemplateConfiguration = {
    settings: INITIAL_TEMPLATE_SETTINGS
};
