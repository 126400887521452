import { sample } from 'effector';
import { PayloadForGetEquipment } from '../../types/equipment';
import { PluginOptions } from '../../types/plugin';
import { equipmentEffects } from '../equipment';
import { pluginStores, pluginEvents, pluginEffects } from './index';

const { pluginOptions, payloadForGetEquipment, pluginResult } = pluginStores;
const { completePlugin } = pluginEvents;
const { getVehicleIdentificationByVinFx } = pluginEffects;

interface PayloadParams {
    payloadForGetEquipment: PayloadForGetEquipment;
    pluginOptions: PluginOptions;
}

sample({
    source: { payloadForGetEquipment, pluginOptions },
    filter: (payload): payload is PayloadParams =>
        !!payload.payloadForGetEquipment && !!payload.pluginOptions?.isComponent,
    fn: ({ payloadForGetEquipment, pluginOptions }: PayloadParams) => {
        if (pluginOptions?.inVSM && pluginOptions.vin) {
            return {
                ...payloadForGetEquipment,
                inVSM: pluginOptions.inVSM,
                vin: pluginOptions.vin
            };
        }

        return payloadForGetEquipment;
    },
    target: equipmentEffects.getAllEquipmentFx
});

sample({
    clock: getVehicleIdentificationByVinFx.doneData,
    source: payloadForGetEquipment,
    filter: (payloadForGetEquipment): payloadForGetEquipment is PayloadForGetEquipment => !!payloadForGetEquipment,
    fn: (payloadForGetEquipment: PayloadForGetEquipment, { vinEquipmentIds }) => ({
        ...payloadForGetEquipment,
        vinEquipmentIds
    }),
    target: equipmentEffects.getAllEquipmentFx
});

sample({
    clock: completePlugin,
    source: { pluginResult, pluginOptions }
}).watch(({ pluginResult, pluginOptions }) => {
    pluginOptions?.onComplete(pluginResult);
});
