import styled from 'styled-components/macro';
import { ProfileIcon } from '@wedat/ui-kit/components/Icons';
import { media } from '@wedat/ui-kit/mediaQueries';

interface ContainerProps {
    isCurrent: boolean;
}

export const ProfileIconStyled = styled(ProfileIcon)`
    height: 48px;
    width: 48px;
    flex-shrink: 0;
`;

export const UserAvatar = styled.img`
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    border-radius: 50%;
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    padding: 16px 20px;
    cursor: pointer;

    // TODO change color
    ${({ isCurrent }) => isCurrent && 'background-color: rgba(66, 164, 255, 0.25);'}

    ${ProfileIconStyled} {
        path {
            ${({ isCurrent, theme }) => isCurrent && `fill: ${theme.colors.blueDark};`}
        }
    }

    &:hover {
        // TODO change color
        background-color: rgba(66, 164, 255, 0.25);

        ${ProfileIconStyled} {
            path {
                fill: ${({ theme }) => theme.colors.blueDark};
            }
        }
    }
`;

export const TextBlock = styled.div`
    width: 340px;
    display: flex;
    margin-left: 10px;
    flex-direction: column;

    ${media.laptopSmall`
        width: 100%;
    `}
`;

export const TextAndDate = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const GroupAndRole = styled.div`
    display: flex;
    align-items: center;
`;

export const UnreadMessagesCounter = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blueDark};
    border-radius: 50%;
`;

export const MessageAndCounter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
