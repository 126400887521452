import styled from 'styled-components';

interface ContainerProps {
    width: string;
}

export const Container = styled.div<ContainerProps>`
    width: fit-content;

    div[data-tippy-root] {
        ${({ width }) => `width: ${width};`}
        padding: 10px 20px;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.dustBlue['800']};
        font-weight: 500;
        font-size: 18px;
        border-radius: 8px;
        white-space: normal;
        opacity: 0.75;

        transition-duration: 0ms !important;

        .tippy-content,
        .tippy-box {
            transition-duration: 0ms !important;
        }

        /* below css fixes arrow position */
        .tippy-box[data-placement^='top'] > .tippy-svg-arrow {
            bottom: 0;
        }
        .tippy-box[data-placement^='top'] > .tippy-svg-arrow:after,
        .tippy-box[data-placement^='top'] > .tippy-svg-arrow > svg {
            top: 15px;
            transform: rotate(180deg);
        }
        .tippy-box[data-placement^='bottom'] > .tippy-svg-arrow {
            top: 0;
        }
        .tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg {
            bottom: 16px;
        }
        .tippy-box[data-placement^='left'] > .tippy-svg-arrow {
            right: 0;
        }
        .tippy-box[data-placement^='left'] > .tippy-svg-arrow:after,
        .tippy-box[data-placement^='left'] > .tippy-svg-arrow > svg {
            transform: rotate(90deg);
            top: calc(50% - 3px);
            left: 11px;
        }
        .tippy-box[data-placement^='right'] > .tippy-svg-arrow {
            left: 0;
        }
        .tippy-box[data-placement^='right'] > .tippy-svg-arrow:after,
        .tippy-box[data-placement^='right'] > .tippy-svg-arrow > svg {
            transform: rotate(-90deg);
            top: calc(50% - 3px);
            right: 11px;
        }
        .tippy-svg-arrow {
            width: 16px;
            height: 16px;
            fill: ${({ theme }) => theme.colors.dustBlue['800']};
            text-align: initial;
        }
        .tippy-svg-arrow,
        .tippy-svg-arrow > svg {
            position: absolute;
        }
    }
`;
