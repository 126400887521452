import React from 'react';
import { useGate, useStore } from 'effector-react';
import { PluginGate } from './stores/plugin';
import { VIEWS } from './constants/views';
import './stores/init';
import { PluginProvider } from '@dat/smart-components/PluginProvider';

import { PluginOptions } from './types/plugin';
import { sharedAuthEffects, sharedAuthStores } from '@dat/shared-models/auth';
import { greetingStores } from './stores/greeting';
import { Preloader } from '@wedat/ui-kit/components/Preloader';

interface Props {
    options: PluginOptions;
}

const App: React.FC<Props> = ({ options }) => {
    const { authMode = 'page' } = options;

    useGate(PluginGate, options);

    const Component = VIEWS[authMode];

    const isFormAuthTriggered = useStore(greetingStores.isFormAuthTriggered);
    const isLoggedOutManually = useStore(sharedAuthStores.isLoggedOutManually);
    const isLoginPending = useStore(sharedAuthEffects.loginFx.pending);

    if (!isFormAuthTriggered && isLoginPending && !isLoggedOutManually) {
        return <Preloader isLoading fullScreen />;
    }

    return (
        <PluginProvider name="auth" options={options} noAuth>
            <Component />
        </PluginProvider>
    );
};

export default App;
