import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { FastMovingBarItems } from '../../types/plugin';
// import { Button, Row } from 'antd';
import { TFunction } from 'react-i18next';
import { fastMovingBarModel } from '../../stores/fastMovingBarModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { FastMovingBarView } from './FastMovingBarView';
import { Icon123 } from '../CommonComponents/Icon';
const { SvgLoader } = require('@wedat/react-svgmt');

export interface FastMovingBarParams {
    fastMovingBar: FastMovingBarItems[];
    submenuShowTitle?: boolean;
    t: TFunction;
}

export const FastMovingBarIcon: FC<FastMovingBarParams> = ({
    fastMovingBar
}: // submenuShowTitle
// enableOnMouseOver,
// t
FastMovingBarParams) => {
    const fastMovingBarMenuSelect = useStore(fastMovingBarModel.stores.fastMovingBarMenuSelect);
    const currentView = useStore(rightSideDrawerModel.stores.currentView);

    return (
        <>
            {fastMovingBar.map(item => (
                <Icon123
                    key={item.menuGroupId}
                    // icon={item.svgIcon && <img width="24px" height="24px" src={item.svgIcon} alt="" />}
                    icon={item.svgIcon && <SvgLoader path={item.svgIcon} />}
                    active={
                        fastMovingBarMenuSelect?.menuGroupId === item.menuGroupId && currentView === FastMovingBarView
                    }
                    onClick={() => {
                        fastMovingBarModel.events.setFastMovingBarMenuSelect(item);
                        rightSideDrawerModel.events.setCurrentView(FastMovingBarView);
                    }}
                />

                // <Row key={item.menuGroupId}>
                //     <Button
                //         className={'buttonMainBar'}
                //         icon={
                //             !submenuShowTitle &&
                //             item.svgIcon && <img width="16px" height="16px" src={item.svgIcon} alt="" />
                //         }
                //         onClick={() => {
                //             fastMovingBarModel.events.setFastMovingBarMenuSelect(item);
                //             rightSideDrawerModel.events.setCurrentView(FastMovingBarView);
                //         }}
                //     >
                //         {submenuShowTitle ? t(`fastMovingBarMenu.${item.menuGroupId}`) || item.menuGroupLabel : ''}
                //     </Button>
                // </Row>
            ))}
        </>
    );
};
