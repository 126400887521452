import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import styled, { css } from 'styled-components/macro';
import { RemoveIconWrapper } from '../../Main/styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`;

export const Content = styled.div`
    overflow-y: auto;
    ${makeCustomScrollBar()}
`;

export const ReportItem = styled.div<{ selected?: boolean }>`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    cursor: pointer;

    ${({ theme, selected }) =>
        selected &&
        css`
            background-color: ${theme.colors.gray['50']};
        `}

    &:active {
        background-color: ${({ theme }) => theme.colors.gray['100']};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }
`;

export const RemoveReportItemWrapper = styled(RemoveIconWrapper)`
    position: absolute;
    right: 24px;
`;
