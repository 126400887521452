import { getEquipmentPositionPropertyPath } from '../components/sections/EquipmentValuation/utils';
import { addDatPrefixToFieldName } from '../utils/addDatPrefixToFieldName';

const baseNames = [
    // "Basic valuation" section
    'Valuation.OriginalPrice',
    'Valuation.BasePrice',
    'Valuation.InitialRegistrationCorr',
    'Valuation.MileageCorr',
    'Valuation.BasePrice2',

    // "Equipment valuation" section
    ...Array.from({ length: 100 /* random number which is greater than average number of eq. positions */ }).flatMap(
        (_, index) => [
            getEquipmentPositionPropertyPath({
                propertyName: 'OriginalPrice',
                index
            }),
            getEquipmentPositionPropertyPath({
                propertyName: 'ResidualValue',
                index
            })
        ]
    ),

    // "Equipment value" section
    'Vehicle.Equipment.OriginalEquipmentValue',
    'Vehicle.Equipment.EquipmentValue',

    // "Vehicle condition" section
    'Valuation.BasePrice3',
    'Valuation.Condition.IncreaseInValue',
    'Valuation.Condition.DecreaseInValue',
    'Valuation.DefaultTiresPrice',
    'Valuation.Condition.TiresMountedValue',
    'Valuation.Condition.TiresUnmountedValue',
    'Valuation.Condition.ConditionSubTotal1',
    'Valuation.Condition.OwnerCorrectionAmount',
    'Valuation.Condition.ConditionCorrectionAmount',
    'Valuation.Condition.ConditionSubTotal2',
    'Valuation.Condition.RepairCosts',

    // "Sales / Procurement" section
    'Valuation.SalesPrice',
    'Valuation.Margin'
];

export const namesOfPriceDependentFields = [...baseNames, ...baseNames.map(addDatPrefixToFieldName)];
