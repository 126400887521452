import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { ReactComponent as SummaryPanelHeaderIcon } from '../CommonComponents/Icons/SummaryPanelHeaderIcon.svg';
import { ListDamagesFT } from '../FastTrackCalculation/ListDamagesFT';
import { SummarySVG } from './SummarySVG';

import './summaryPanel.css';

export const SummaryPanel = () => {
    const { t } = useTranslation();

    const fastTrackElements = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);

    if (!pluginOptions) return <div>get pluginOptions...</div>;

    return (
        <div style={{ margin: '24px' }}>
            {fastTrackElements.selectedElements.length ? (
                <>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <SummaryPanelHeaderIcon />
                        </div>
                        <div style={{ height: '90px', marginLeft: '110px' }}>
                            <SummarySVG />
                        </div>
                    </div>
                    <ListDamagesFT />
                </>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '40%' }}>
                    <SummaryPanelHeaderIcon width="126" height="140" />
                    <div style={{ fontSize: '14px', color: '#8B98B3', margin: '10px auto 0 auto', width: '230px' }}>
                        {t('fastTrack.calculationDisableReason')}
                    </div>
                </div>
            )}
        </div>
    );
};
