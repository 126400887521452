import { media } from '@wedat/ui-kit/mediaQueries';
import styled, { css } from 'styled-components';

interface Props {
    isRequired?: boolean;
}
export const FieldContainer = styled.div<Props>`
    min-width: 200px;
    width: 80%;
    ${({ isRequired }) =>
        isRequired &&
        css`
            label {
                &:after {
                    content: '*';
                    color: ${({ theme }) => theme.colors.red['800']};
                }
            }
        `}
    ${media.phoneMostSmall`
        min-width: 180px;
        width: 180px;
    `};
`;

export const TabsWrapper = styled.div`
    grid-column: 1 / -1;
    button {
        height: 32px;
        font-size: 14px;
    }

    ${media.laptopSmall`
         margin-bottom: 12px
    `}
`;
