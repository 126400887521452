/**
 * Preload all plugin images when start
 */

const preloadImage = (images: Array<string | undefined>) => {
    images.forEach(src => {
        if (!src) return;

        const img = new Image();

        img.src = src;
    });
};

export const preload = (images: Array<Array<string | undefined>> | undefined) => {
    if (!Array.isArray(images)) return;

    images.forEach(images => preloadImage(images));
};
