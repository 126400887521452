import axios from 'axios';

interface RequestGetTranslations {
    language: string;
    namespaces: string[];
}

interface ResponseGetTranslations {
    [TNamespace: string]: {
        [TLocale: string]: Record<string | number, any>;
    };
}

const bffUrl = process.env.REACT_APP_BFF_URL;

const bffAxiosInstance = axios.create({
    baseURL: bffUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const getTranslations = (data: RequestGetTranslations): Promise<ResponseGetTranslations> =>
    bffAxiosInstance.post('translations/getTranslations', data);
