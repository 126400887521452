import styled from 'styled-components/macro';

export const PageWrapper = styled.section`
    min-height: 100vh;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 100%;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;
