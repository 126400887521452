const splitChar = '@';
export function splitOnTwoParts(title?: string) {
    const defaultValue = {
        isSplitalble: false,
        leftPart: '',
        rightPart: ''
    };

    if (!title) return defaultValue;

    const parts = title.split(splitChar);
    if (parts.length === 2)
        return {
            isSplitalble: true,
            leftPart: parts[0].trim(),
            rightPart: parts[1].trim()
        };
    return defaultValue;
}
