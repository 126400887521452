import { forwardRef } from 'react';
import { useMediaContainerSize } from '../../hooks/mediaDevices';
import { useOrientation } from '../../hooks/orientation';

import { Mask, Player, VideoStyled } from './styles';

interface Props {
    // mask accept to over video
    mask?: string;
}

export const Video = forwardRef<HTMLVideoElement, Props>(({ mask = '' }, ref) => {
    const { width, height } = useMediaContainerSize();
    const { isLandscape } = useOrientation();

    return (
        <VideoStyled width={width} height={height}>
            <Player ref={ref} autoPlay muted playsInline isLandscape={isLandscape} />
            {mask && <Mask mask={mask} />}
        </VideoStyled>
    );
});
