import { datProdServicesUrls } from '../baseParameters';
import { Credentials } from '../types/api2datTypes';
import { Vehicle } from '../types/contractTypes';
import { VehicleIdentificationParams } from '../types/vehicleIdentificationServiceTypes';
import { sendCommonSoapRequest } from '../utils/soapRequest';

const urlVehicleIdentificationService =
    datProdServicesUrls.soapVehicleIdentificationServiceUrl ||
    'https://www.dat.de/myClaim/soap/v2/VehicleIdentificationService';

export async function getVehicleIdentification(
    credentials: Credentials,
    params: VehicleIdentificationParams,
    url?: string
): Promise<Vehicle> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlVehicleIdentificationService,
        'getVehicleIdentification',
        credentials,
        params
    );
    return SoapResult.Envelope.Body.getVehicleIdentificationResponse.VXS.Vehicle;
}
