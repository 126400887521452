import { getFieldText, getParsedArraySafe, getTextValuesFromParsedObject } from '@dat/api2/utils';
import { pickPropertiesWithOrigin } from './pickPropertiesWithOrigin';

import { PartialDeep } from 'type-fest';
import { EquipmentPosition, ManualFields, ValuationCondition } from '../types/manualFields';
import { parseDateString } from '@dat/core/utils/parseDateString';

export const extractManualFieldsFromDossier = (dossier: DAT2.Dossier): PartialDeep<ManualFields> => ({
    Vehicle: {
        InitialRegistration: parseDateString(dossier.Vehicle?.InitialRegistration),
        MileageEstimated: dossier.Vehicle?.MileageEstimated,
        Equipment: {
            ...extractManualFieldsFromObject(dossier.Vehicle?.Equipment),
            SpecialEquipment: {
                EquipmentPosition: parseEquipmentPositions(
                    dossier.Vehicle?.Equipment?.SpecialEquipment?.EquipmentPosition
                )
            }
        }
    },
    VAT: {
        VatType: dossier.VAT?.VatType
    },
    Valuation: {
        ...extractManualFieldsFromObject(dossier.Valuation),
        Condition: parseValuationCondition(dossier.Valuation?.Condition)
    }
});

const parseEquipmentPositions = (positions?: DAT2.SingleOrArray<DAT2.EquipmentPosition>): EquipmentPosition[] =>
    getParsedArraySafe(positions).map(position => ({
        ...extractManualFieldsFromObject(position),
        DecreaseType: getFieldText(position.DecreaseType),
        DatEquipmentId: position.DatEquipmentId
    }));

const parseValuationCondition = (condition?: DAT2.ValuationCondition): ValuationCondition => ({
    ...extractManualFieldsFromObject(condition),
    AccidentDamage: condition?.AccidentDamage ?? 'unknown',
    NumberOfOwnersN: condition?.NumberOfOwnersN ?? 'unknown',
    RepairCostsInTradeMargin: condition?.RepairCostsInTradeMargin ?? false
});

const extractManualFieldsFromObject = (object: Record<string, any> | undefined = {}) => {
    const objectWithManualFields = pickPropertiesWithOrigin({
        object,
        origin: 'user'
    });

    return getTextValuesFromParsedObject(objectWithManualFields);
};
