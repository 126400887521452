import { Layout } from '../Layout';
import { useCallback, useContext, useRef, useState } from 'react';
import { getUserMedia } from '../../utils/mediaDevices';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { GEOLOCATION_ENABLE, GEOLOCATION_KEY, getGeolocation } from '../../utils/geolocation';
import { getIcons, getTranslates } from '../../utils/plugin';
import { delay } from '../../utils/common';
import { FormContext, actions } from '../../reducers/form';
import { useOrientation } from '../../hooks/orientation';
import { isSafari } from 'react-device-detect';
import { Content, Permissions, Title, Image, PermissionText, Description, Buttons, Skip } from './styles';

const { changeStep } = actions;

export const PermissionsPage = () => {
    const [rejectCamera, setRejectCamera] = useState(false);
    const [rejectGeo, setRejectGeo] = useState(false);
    const [loading, setLoading] = useState(false);
    const videoRef = useRef<{ base: HTMLVideoElement }>();
    const translates = getTranslates();
    const icons = getIcons();
    const { dispatch } = useContext(FormContext);
    const { isLandscape } = useOrientation();

    const handlePermissions = useCallback(() => {
        if (rejectCamera) return window.location.reload();

        setLoading(true);

        delay()
            .then(() => getUserMedia(isLandscape))
            .catch(() => {
                setRejectCamera(true);
                // break promise chain
                throw new Error();
            })
            .then(() =>
                getGeolocation()
                    .then(() => {
                        localStorage.setItem(GEOLOCATION_KEY, GEOLOCATION_ENABLE);
                    })
                    .catch(() => {
                        setRejectGeo(true);
                        // break promise chain
                        throw new Error();
                    })
            )
            .then(() => {
                dispatch(changeStep());
                setLoading(false);
            })
            .catch(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejectCamera, videoRef]);

    const handleGeo = useCallback(() => {
        setLoading(true);

        delay()
            .then(getGeolocation)
            .then(() => dispatch(changeStep()))
            .catch(() => {
                setRejectGeo(true);
            })
            .then(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <Permissions>
                {loading && <Spinner />}
                <Content>
                    <Title>{rejectGeo ? translates.disablePermissionsTitle : translates.enablePermissionsTitle}</Title>
                    {!rejectCamera && rejectGeo ? (
                        <Image alt="permission" src={icons.location} />
                    ) : (
                        <Image alt="permission" src={icons.permissions} />
                    )}
                    <PermissionText>
                        {!rejectCamera && !rejectGeo
                            ? translates.enablePermissionsSubTitle
                            : rejectCamera && translates.disablePermissionsSubTitle}
                    </PermissionText>
                    <Description>
                        {rejectCamera
                            ? translates.rejectCameraDescription
                            : rejectGeo
                            ? isSafari
                                ? translates.rejectLocationDescriptionSafari
                                : translates.rejectLocationDescription
                            : translates.enablePermissionsDescription}
                    </Description>
                </Content>

                <Buttons>
                    {!rejectGeo && (
                        <Button onClick={handlePermissions}>
                            {rejectCamera ? translates.refreshPageBtn : translates.enablePermissionsBtn}
                        </Button>
                    )}
                    {rejectGeo && <Button onClick={handleGeo}>{translates.enableLocationBtn}</Button>}
                    {rejectGeo && (
                        <Skip onClick={() => dispatch(changeStep())}>{translates.enablePermissionsSkip}</Skip>
                    )}
                </Buttons>
            </Permissions>
        </Layout>
    );
};
