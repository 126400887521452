import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import jwt from 'jsonwebtoken';
import { hasNotValidToken } from './hasNotValidToken';
import { sharedLoginEvents } from '@dat/shared-models/auth/login';
import { datAuthTokenName, DAT_REFRESH_TOKEN_NAME } from '@dat/core/constants';
import { INITIAL_CUSTOMER_SETTINGS } from '@dat/shared-models/configuration/constants';
import _ from 'lodash';

const TOKEN_EXPIRE_AT = 1200 * 1000; // 20 min;

const conditionError = (error: AxiosError) =>
    error?.response?.status === 403 ||
    error?.response?.status === 401 ||
    error?.response?.statusText.toLowerCase().includes('refresh');

export const refreshTokenInterceptors = (instance: AxiosInstance, headers?: AxiosRequestConfig['headers']) => {
    instance.interceptors.response.use(
        config => config.data,
        error => {
            if (conditionError(error)) {
                sharedLoginEvents.loggedOut();
            }
            return Promise.reject(error.response.data);
        }
    );

    const refreshTokenInterceptor = async (): Promise<{
        accessToken: string;
        refreshToken: string;
    }> => {
        const refreshToken = localStorage.getItem(DAT_REFRESH_TOKEN_NAME);
        try {
            const { data } = await axios({
                method: 'POST',
                url: `${INITIAL_CUSTOMER_SETTINGS.bffUrl}auth/refresh-token`,
                data: {
                    refreshToken
                }
            });

            if (data.accessToken) {
                const { accessToken, refreshToken } = data;
                return {
                    accessToken,
                    refreshToken
                };
            }

            sharedLoginEvents.loggedOut();
            return Promise.reject();
        } catch (e) {
            sharedLoginEvents.loggedOut();
            return Promise.reject(e);
        }
    };

    const memoRefresh = _.debounce(refreshTokenInterceptor, 10000, { leading: true });

    instance.interceptors.request.use(
        async request => {
            const token = localStorage.getItem(datAuthTokenName);
            if (token) {
                const decodeResult = jwt.decode(token);
                const decodeResultObject = typeof decodeResult === 'object' ? decodeResult : JSON.parse(decodeResult);
                const expireAt = decodeResultObject.iat + TOKEN_EXPIRE_AT;

                if (hasNotValidToken(expireAt)) {
                    const data = await memoRefresh();

                    if (request.headers && data?.accessToken && data.refreshToken) {
                        const { accessToken, refreshToken } = data;
                        sharedLoginEvents.setToken(accessToken);
                        sharedLoginEvents.setRefreshToken(refreshToken);
                        request.headers = {
                            ...request.headers,
                            [datAuthTokenName]: accessToken,
                            ...headers
                        };
                    }
                }
            }
            return request;
        },
        (error: AxiosError) => {
            if (conditionError(error)) {
                sharedLoginEvents.loggedOut();
            }
            return Promise.reject(error.response);
        }
    );
};
