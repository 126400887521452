import { restore } from 'effector';
import { VehicleSelectionModalPluginResult } from '@dat/vehicle-selection-modal';
import { domain } from '../plugin';

// Vehicle should be fully selected, otherwise "createOrUpdateContract" will work incorrect.
// This comment is just a tip, because VSM has own validation which checks the selection of vehicle.
const vehicleSelectionCompleted = domain.createEvent<VehicleSelectionModalPluginResult>();
const vehicleSelectionPluginResult = restore(vehicleSelectionCompleted, null);

export const containedPluginsEvents = {
    vehicleSelectionCompleted
};
export const containedPluginsStores = {
    vehicleSelectionPluginResult
};
