// const DAT_API_URL = process.env.REACT_APP_API_URL || '';

export enum DatProductsUrls {
    VehicleRepair = 'myClaim/soap/v2',
    ValuateFinance = 'https://www.datgroup.com/FinanceLine/soap/Evaluation',
    MyClaim = 'myClaim/soap/v2/MyClaimExternalService',
    MyClaimInternalJsonApi = 'myClaim/json',
    MyClaimInternalRestApi = 'myClaim/restApi/v1'
}

export enum VehicleRepairServices {
    VehicleSelectionService = 'VehicleSelectionService',
    VehicleIdentificationService = 'VehicleIdentificationService',
    VehicleImagery = 'VehicleImagery',
    ConversionFunctionsService = 'ConversionFunctionsService',
    VehicleRepairService = 'VehicleRepairService'
}

//TODO: rename to EquipmentClassesIds
// Classification groups keys - https://www.dat.de/fileadmin/de/support/interface-documentation/EN/SilverDAT_interface_compendium/index.htm#6121
//TODO: move to models
export enum ClassificationGroupsKeys {
    Engine = '1',
    Body = '2',
    Model = '7',
    Transmission = '11',
    Wheelbase = '3',
    DriveType = '4',
    NumberOfAxles = '9',
    DriversCabin = '5',
    Tonnage = '6',
    SuspensionType = '8',
    EquipmentLine = '10' // optional
}
