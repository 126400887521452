import { useState, useEffect } from 'react';

export const useMedia = (query: string) => {
    const queryAdapted = `(max-width: ${query}px)`;
    const [matches, setMatches] = useState(window.matchMedia(queryAdapted).matches);

    useEffect(() => {
        const media = window.matchMedia(queryAdapted);
        const listener = () => setMatches(media.matches);

        if (media.addEventListener) {
            media.addEventListener('change', listener);
        } else {
            media.addListener(listener);
        }

        return () => {
            if (media.removeEventListener) {
                media.removeEventListener('change', listener);
            } else {
                media.removeListener(listener);
            }
        };
    }, [queryAdapted]);

    return matches;
};
