import { combine, createEvent, restore, createEffect, sample } from 'effector';
import { getDomusTimes } from '../services/italyAniaSerivce2';
import { Aggregate, AggregateDvn, ResultGetDomusTimes } from '../services/ItalyAniaTypes2';
import { RC } from '../types/svgGrapaTypes';
import { findObjectInfoByDvn } from '../utils/findGroupByDvns';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
// import { Dvn } from '../types/svgGrapaTypes';
import { graphicDamageSelectionModel } from './graphicDamageSelectionModel';
import { pluginOptionsModel } from './pluginOptionsModel';
import { BaseDamageParams, RepairPositionsModel } from './repairPositionsModel';
import { grapaInterfaceModel } from './subscribes';

const setResultGetDomusTimes = createEvent<ResultGetDomusTimes | null>();
const resultGetDomusTimes = restore(setResultGetDomusTimes, null);

const getDomusTimesFX = createEffect(getDomusTimes);

sample({
    source: combine([pluginOptionsModel.stores.vehicle]),
    clock: getDomusTimesFX.doneData,
    fn: ([vehicle], resultGetDomusTimesFX) => {
        if (
            resultGetDomusTimesFX?.datecode &&
            vehicle?.DatECode?.substr(0, resultGetDomusTimesFX?.datecode.length) === resultGetDomusTimesFX?.datecode
        )
            return resultGetDomusTimesFX;
        return null;
    },
    target: setResultGetDomusTimes
});

sample({
    source: combine([
        pluginOptionsModel.stores.vehicle.map(v => v?.DatECode),
        pluginOptionsModel.stores.pluginOptions.map(
            opt => opt?.settings?.contract?.complexTemplateData?.attr?.templateType
        ),
        grapaInterfaceModel.stores.doorsNumber
    ]),
    fn: ([datECode, templateType, doors]) => (templateType === 'vro_domus_calculation' ? { datECode, doors } : {}),
    target: getDomusTimesFX
});

const currentAniaTimes = combine(
    resultGetDomusTimes,
    graphicDamageSelectionModel.stores.currentDVNs,
    pluginOptionsModel.stores.vehicle,
    graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore,
    // grapaInterfaceModel.stores.doorsNumber,

    (domusTimes, dvns, vehicle, graphicDamageSelectionModelStore) => {
        if (
            !(
                graphicDamageSelectionModelStore.currentAssemblyGroupObject?.dvnLeft?.rcs.rc.includes(RC.E) ||
                graphicDamageSelectionModelStore.currentAssemblyGroupObject?.dvnRight?.rcs.rc.includes(RC.E)
            )
        )
            return null;

        if (dvns?.length !== 1 || domusTimes?.datecode !== vehicle?.DatECode) return null;
        const foundVal = domusTimes?.dvn.find(item => item.dvn === dvns[0]);
        if (!!foundVal) return foundVal.repairTime;
        return null;
    }
);

type DvnPosition = 'left' | 'right' | 'single';

interface AggregateDvn2 extends AggregateDvn {
    isReplace: boolean;
    position: DvnPosition;
    positionDvn: number;
}

interface AggregateWithSelection extends Aggregate {
    title: string; // description + DSPEL...
    isReplace: boolean;
    dvn: AggregateDvn2[];
}

const listCurrentAggregatesByDvn = combine([
    graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore,
    graphicDamageSelectionModel.stores.currentDVNs,
    resultGetDomusTimes,
    RepairPositionsModel.store.repairPositions
]).map(([graphicDamageSelectionModelStore, currentDVNs, domusTimes, repairPositions]) => {
    let result: AggregateWithSelection[] = [];

    if (!domusTimes || currentDVNs?.length !== 1) return [];

    const dvn = currentDVNs[0];

    const leftDvn = graphicDamageSelectionModelStore.currentAssemblyGroupObject?.dvnLeft?.dvn;
    const rightDvn = graphicDamageSelectionModelStore.currentAssemblyGroupObject?.dvnRight?.dvn;
    let position: DvnPosition = 'single';
    if (leftDvn && rightDvn) {
        if (leftDvn === dvn) position = 'left';
        if (rightDvn === dvn) position = 'right';
    }

    const filteredAggregates = domusTimes.aggregates.filter(item => item.dvn.find(dnvItem => dnvItem.DVN === dvn));

    result = filteredAggregates.map(aggr => ({
        ...aggr,
        // title: aggr.description + ' (' + aggr.dvn.reduce((prevVal, currVal) => prevVal + ' ' + currVal.DSPEL, '') + ')',
        title: aggr.description,
        isReplace: false,
        // dvn: aggr.dvn,
        dvn: aggr.dvn.map(dvn => ({
            ...dvn,
            position,
            positionDvn: dvn.DVN,
            isReplace:
                !!repairPositions.find(
                    repPos =>
                        repPos.RepairType === 'replace' &&
                        repPos.WorkLevelItaly === 'replace' &&
                        repPos.DATProcessId === dvn.DVN
                ) &&
                !!repairPositions.find(
                    repPos =>
                        repPos.RepairType === 'lacquer' &&
                        repPos.WorkLevelItaly === 'replace' &&
                        repPos.DATProcessId === dvn.DVN
                ) &&
                !!repairPositions.find(
                    repPos =>
                        repPos.RepairType === 'dis- and mounting' &&
                        repPos.WorkLevelItaly === 'replace' &&
                        repPos.DATProcessId === dvn.DVN
                )
        }))
    }));

    result = result.map(aggr => ({
        ...aggr,
        isReplace: aggr.dvn.filter(dvnAggr => dvnAggr.isReplace).length === aggr.dvn.length
    }));

    return result;
});

const addReplace = createEvent<BaseDamageParams | undefined>();

sample({
    source: combine([
        pluginOptionsModel.stores.vehicle,
        resultGetDomusTimes
        // availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ]),
    clock: addReplace,
    fn: ([vehicle, domusTimes], replacePosition) => {
        if (domusTimes?.datecode !== vehicle?.DatECode) return replacePosition;

        const dvnRepairTimes = domusTimes?.dvn.find(item => item.dvn === replacePosition?.DATProcessId);

        if (!!dvnRepairTimes) return;

        return replacePosition;
    },
    target: RepairPositionsModel.event.addReplaceEDamage
});

const addAniaReplace = createEvent<number>();

sample({
    clock: addReplace,
    fn: posReplace => posReplace?.DATProcessId || 0,
    target: addAniaReplace
});

sample({
    source: combine([
        pluginOptionsModel.stores.vehicle,
        resultGetDomusTimes,
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ]),
    clock: addAniaReplace,
    fn: ([vehicle, domusTimes, availableAssemblyGroupsStore], dvn) => {
        if (domusTimes?.datecode !== vehicle?.DatECode) return;

        const dvnRepairTimes = domusTimes?.dvn.find(
            // item => item.datecode === vehicle?.DatECode && item.dvn === dvn.toString()
            item => item.dvn === dvn
        );
        if (!dvnRepairTimes) return;

        const foundObjectInfo = findObjectInfoByDvn(dvn, availableAssemblyGroupsStore.availableAssemblyGroups);

        const Description = foundObjectInfo?.titleMenu;
        const ConstructionGroupId = 0;
        const ConstructionGroup = '';

        RepairPositionsModel.event.delRepairTypeDamage({
            dvn,
            repairType: 'overhaul'
        });
        RepairPositionsModel.event.delRepairTypeDamage({
            dvn,
            repairType: 'fixing'
        });
        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
            {
                DATProcessId: dvn,
                RepairType: 'replace',
                Description,
                ConstructionGroupId,
                ConstructionGroup,
                PositionEntryType: 'graphical',
                WorkLevelItaly: 'replace',
                // WorkManualInput: '#',
                WorkTime: (dvnRepairTimes.repairTime.overhaul?.['replace'] || 0) / 100
            }
        ]);
        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
            {
                DATProcessId: dvn,
                RepairType: 'dis- and mounting',
                Description,
                ConstructionGroupId,
                ConstructionGroup,
                PositionEntryType: 'graphical',
                WorkLevelItaly: 'replace',
                // WorkManualInput: '#',
                WorkTime: (dvnRepairTimes.repairTime['dis- and- mounting']?.['replace'] || 0) / 100
            }
        ]);
        RepairPositionsModel.event.addRepairPositionsWithExistenceCheck([
            {
                DATProcessId: dvn,
                RepairType: 'lacquer',
                Description,
                ConstructionGroupId,
                ConstructionGroup,
                PositionEntryType: 'graphical',
                LacquerLevel: 'surface',
                LacquerLevelId: 1,
                WorkLevelItaly: 'replace',
                // WorkManualInput: '#',
                WorkTime: (dvnRepairTimes.repairTime.lacquer?.['replace'] || 0) / 100
            }
        ]);
    }
});

export const italyAniaModel2 = {
    stores: {
        resultGetDomusTimes,
        currentAniaTimes,
        listCurrentAggregatesByDvn
    },
    events: {
        setResultGetDomusTimes,
        addAniaReplace,
        addReplace
    },
    effects: {
        getDomusTimesFX
    }
};
