import { RepairPositionWithRowKey } from '../stores/repairPositionsModel';

/**
 * @param manualRepairPositionForm - current manual repair position values
 * @returns boolean:  true, if there are all required fields in manualRepairPositionForm, otherwise false
 */
export const checkManualRepairPositionForm = (manualRepairPositionForm: RepairPositionWithRowKey) => {
    const { Description, RepairType, SparePartPrice, WorkTime, WorkPrice, LacquerPrice } = manualRepairPositionForm;

    if (!RepairType || !Description) return false;

    if (RepairType === 'replace') {
        if (SparePartPrice || WorkTime || WorkPrice) return true;
    } else if (
        RepairType === 'overhaul' ||
        RepairType === 'fixing' ||
        RepairType === 'visual inspection' ||
        RepairType === 'dis- and reassemble' ||
        RepairType === 'adjust' ||
        RepairType === 'technical inspection' ||
        RepairType === 'dis- and mounting'
    ) {
        if (WorkTime || WorkPrice) return true;
    } else if (RepairType === 'lacquer') {
        if (WorkTime || LacquerPrice) return true;
    } else if (RepairType === 'incidental costs') {
        if (SparePartPrice) return true;
    }

    return false;
};
