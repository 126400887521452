export const parseFormValuesToArray = (values: any) =>
    Object.keys(values).map(key => {
        if (typeof values[key] === 'boolean') {
            return {
                key,
                value: values[key] === true ? 1 : 0
            };
        }

        return {
            key,
            value: values[key]
        };
    });
