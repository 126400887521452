import React, { useState, useEffect, useRef, useCallback } from 'react';
import { init } from '@anyline/anyline-js';
import { toastEffects } from '@dat/smart-components/Toast/store';
import { CloseIcon } from '@wedat/ui-kit/components/Icons';
import { datLicense } from './constants';
import { vehicleVinOCREvents } from '../../stores/vinOcr';

import { AnylineContent, StyledButton, Container, Wrapper } from './styles';
import { Preloader } from '@wedat/ui-kit/components/Preloader';

interface Props {
    anylineCarsToggle: () => void;
}

export const Anyline: React.FC<Props> = ({ anylineCarsToggle }) => {
    const elRef = useRef<HTMLDivElement | null>(null);
    const [anyline, setAnyline] = useState<ReturnType<typeof init>>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (elRef.current) {
            const anylineEntity = init({
                config: {},
                viewConfig: {
                    cutouts: [
                        {
                            cancelOnResult: false
                        }
                    ]
                },
                preset: 'vin',
                license: datLicense,
                element: elRef.current
            });

            anylineEntity.onResult = result => {
                const vin = result.result?.find((i: any) => i?.identifier === 'text')?.text;
                anylineCarsToggle();
                anylineEntity.stopScanning();
                anylineEntity.dispose();
                vehicleVinOCREvents.vinOcrScan(vin);
            };

            anylineEntity
                ?.startScanning()
                .then(() => setLoading(false))
                .catch(e => {
                    toastEffects.showErrorToastFx({
                        message: {
                            namespace: 'vehicle-selection',
                            key: e.message
                        }
                    });
                    anylineCarsToggle();
                    anylineEntity.dispose();
                });

            setAnyline(anylineEntity);
        }
    }, [anylineCarsToggle, elRef]);

    const handleStop = useCallback(() => {
        if (!anyline) return;

        anylineCarsToggle();
        anyline.stopScanning();
        anyline.dispose();
    }, [anyline, anylineCarsToggle]);

    return (
        <Wrapper>
            <Preloader isLoading={loading} noOpacity />

            <Container>
                <AnylineContent ref={elRef} />
                <StyledButton onClick={handleStop}>
                    <CloseIcon />
                </StyledButton>
            </Container>
        </Wrapper>
    );
};
