import { sample, guard } from 'effector';

import { pluginEvents, pluginStores } from './index';
import { contractEffects } from '@dat/shared-models/contract';
import { sharedProfilesStores, sharedProfilesEffects } from '@dat/shared-models/profiles';
import { sharedAuthEvents } from '@dat/shared-models/auth';

const { initPlugin } = pluginEvents;
const { pluginOptions } = pluginStores;
const { sameCustomerProfiles } = sharedProfilesStores;
const { getProfilesFx } = sharedProfilesEffects;
const { getContractFx } = contractEffects;
const { loggedIn } = sharedAuthEvents;

// 0 is just for ts, contractId in this case exists only if plugin is used outside of claim(!isComponent)
const contractId = pluginOptions.map(item => item?.contractId || 0);
const isStandAlone = pluginOptions.map(item => !item?.isComponent);

sample({
    source: initPlugin
});

guard({
    clock: initPlugin,
    source: contractId,
    filter: isStandAlone,
    target: getContractFx
});

const noSameCustomerProfiles = sameCustomerProfiles.map(item => !Object.keys(item).length);

guard({
    clock: loggedIn,
    filter: noSameCustomerProfiles,
    target: getProfilesFx
});
