import styled from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { WrapperStyled } from '@wedat/ui-kit/components/Tabs/styles';

export const TabsContainer = styled.div`
    width: 100%;

    ${WrapperStyled} {
        overflow-x: auto;

        & button {
            min-width: 200px;

            ${media.phone`
                padding-right: 20px;
                min-width: max-content;
                text-align: left;
                background-color: transparent;
                border-color: transparent;
            `}
        }

        ${media.phone`
            ${makeCustomScrollBar()}
        `}
    }
`;
