import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const BasicInformationStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

export const Content = styled.div``;

export const ColumnsWrapepr = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 16px;

    ${media.laptop`
        grid-template-columns: 1fr;
    `}
`;

export const Column = styled.div`
    width: 30%;

    ${media.tablet`
        width: 100%;
    `}
`;

export const Field = styled.div`
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const Info = styled.div`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
`;

export const TextWrap = styled.div``;

export const List = styled.ul`
    margin: 10px 0 10px 20px;
`;

export const ListItem = styled.li`
    &:not(:last-child) {
        margin-bottom: 5px;
    }
`;

export const InfoTitle = styled.div`
    margin-bottom: 10px;
`;
