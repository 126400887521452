// mappingDVNWorkTypeWorkLevelService

import { convertRepairTypeToFullRepairType } from '../utils/ftRepairToRepairPosition';

export interface GetMappingDVNWorkTypeWorkLevelType {
    [dvn: string]: {
        [repairType: string]: {
            workType: string;
            workLevel: string;
        };
    };
}

const TypeCodeToWorkType: { [key: string]: string } = {
    O: 'other',
    M: 'mechanic',
    K: 'car body',
    E: 'electric'
};

export async function getMappingDVNWorkTypeWorkLevel(): Promise<GetMappingDVNWorkTypeWorkLevelType> {
    const options = {
        method: 'GET'
    };
    const url = 'https://storage.yandexcloud.net/grapa-data/MappingDVNWorkTypeWorkLevel.csv';

    const resultFetch = await fetch(url, options);

    const csvContent = await resultFetch.text();

    // csvContent Example:
    //
    // CR,DVN,Work type,Level
    // E,12111,M,3
    // E,17203,E,1
    // E,17204,E,1
    //

    const strings = csvContent.split(/\r?\n/);

    strings.shift(); // remove header

    const result: GetMappingDVNWorkTypeWorkLevelType = {};

    strings.forEach(str => {
        const elements = str.split(',');
        const [repairTypeCode, dvn, workTypeCode, workLevel] = elements;

        const repairType = convertRepairTypeToFullRepairType(repairTypeCode);
        const workType = TypeCodeToWorkType[workTypeCode];

        if (repairType && dvn)
            result[dvn] = {
                ...result[dvn],
                [repairType]: {
                    workType,
                    workLevel
                }
            };
    });

    return result;
}
