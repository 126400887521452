import { launch, step } from 'effector';
import { defer } from './promise';
import { assign, own, setMeta } from './tools';
export const patchInstance = (instance, { cancelled, cancel, feedback, inFlight, pending }) => {
    assign(instance, { cancelled, cancel, inFlight, pending });
    own(instance, [cancelled, cancel]);
    setMeta(cancelled, 'needFxCounter', 'dec');
    instance.create = (paramsOrConfig, [strategyOrConfig]) => {
        const config = (paramsOrConfig && (paramsOrConfig.strategy || paramsOrConfig.timeout)
            ? paramsOrConfig
            : strategyOrConfig && (strategyOrConfig.strategy || strategyOrConfig.timeout)
                ? strategyOrConfig
                : { strategy: strategyOrConfig });
        const req = defer();
        launch(instance, {
            params: config === paramsOrConfig ? config.params : paramsOrConfig,
            args: {
                strategy: config.strategy,
                timeout: config.timeout,
            },
            req,
        });
        return req.req;
    };
    if (feedback) {
        const feedbackStep = step.compute({
            fn(data, _, stack) {
                data.strategy = stack.parent.parent.value.strategy;
                return data;
            },
        });
        instance.done.graphite.seq.push(feedbackStep);
        instance.fail.graphite.seq.push(feedbackStep);
    }
};
