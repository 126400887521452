import styled, { css } from 'styled-components/macro';
import { media, makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const EquipmentValuationStyled = styled.div`
    width: 100%;
    margin-top: 24px;
    overflow-x: auto;
    ${makeCustomScrollBar()}

    ${media.phoneBig`
        background-color: unset;
    `}
`;

export const FieldGroup = styled.div``;

export const Field = styled.div`
    width: 100px;
`;

export const Content = styled.div`
    margin-top: 30px;
`;

export const GroupContent = styled.div`
    display: flex;
    align-items: center;
`;

export const Table = styled.table`
    border-spacing: 0;
    width: 100%;
`;

export const THead = styled.thead``;
export const TBody = styled.tbody``;

export const Th = styled.th`
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_40};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
`;

export const Td = styled.td<{ disabled?: boolean }>`
    min-width: 120px;
    padding: 8px 10px;
    color: ${({ theme }) => theme.colors.gray_300};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
        `}
`;

export const Tr = styled.tr`
    background-color: var(--light);
    &:not(:first-child) {
        border-top: 2px solid ${({ theme }) => theme.colors.gray_10};
    }
`;

export const Text = styled.span`
    font-size: 14px;
    margin-right: 10px;
`;
export const TextPercent = styled.span`
    font-size: 14px;
    margin-left: 10px;
`;

export const NoWrap = styled.div`
    display: flex;
    align-items: center;

    ${media.phoneBig`
        flex-direction: column;
        align-items: start;
        margin-bottom: 10px;
    `}
`;

export const ResponsiveWrapepr = styled.div`
    ${media.phoneBig`
        margin-bottom: 5px;
    `}
`;

export const TabsWrapper = styled.div`
    max-width: 600px;

    ${media.laptop`
        max-width: 100%;
    `}
`;
