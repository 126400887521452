import styled, { css } from 'styled-components';
import { makeCustomScrollBar, media } from '../../mediaQueries';

interface WrapperProps {
    fillWidth?: boolean;
}

interface Props {
    isActive: boolean;
    typeStyle: 'primary' | 'secondary' | 'dark' | 'mobile';
}

export const WrapperStyled = styled.div<WrapperProps>`
    display: flex;
    border-radius: 2px;
    margin-bottom: 24px;
    overflow: auto;

    ${({ fillWidth }) => fillWidth && `width: 100%;`}

    ${makeCustomScrollBar()}

    ${media.phoneBig`
        margin-bottom: 12px;
        width: 300px;

        ${({ fillWidth }) => fillWidth && `width: 100%;`}
    `}
`;

export const primaryActive = css`
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blue['700']};
    border-color: ${({ theme }) => theme.colors.blue['700']};
`;

export const primaryDefault = css`
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: pointer;
`;

export const primaryStyle = css<Props>`
    height: 57px;
    margin-right: 32px;
    text-align: left;
    border-bottom: 4px solid transparent;
    ${({ theme }) => theme.typography.defaultText};
    ${({ isActive }) => (isActive ? primaryActive : primaryDefault)};

    ${media.phoneBig`
        height: 36px;
        ${({ theme }) => theme.typography.note};
        font-weight: 700;
    `}
`;

export const secondaryDefault = css`
    cursor: pointer;
    border-bottom: 4px solid ${({ theme }) => theme.colors.gray_10};
`;

export const secondaryActive = css`
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
`;

export const secondaryStyle = css<Props>`
    width: 200px;
    height: 43px;
    padding-bottom: 16px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    ${({ isActive }) => (isActive ? secondaryActive : secondaryDefault)};
`;

export const disabledStyle = css`
    opacity: 0.4;
    pointer-events: none;
`;

export const darkDefault = css`
    cursor: pointer;
    border-bottom: 4px solid transparent;
`;

export const darkActive = css`
    border-bottom: 4px solid ${({ theme }) => theme.colors.gray_300};
`;

export const darkStyle = css<Props>`
    height: 34px;
    padding-bottom: 8px;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.gray_300};
    ${({ isActive }) => (isActive ? darkActive : darkDefault)};
`;

export const mobileDefault = css`
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['400']};
`;

export const mobileActive = css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue['400']};
    border: 1px solid ${({ theme }) => theme.colors.blue['400']};
`;

export const mobileStyle = css<Props>`
    flex: 1;
    height: 40px;
    padding: 8px;
    overflow: hidden;
    cursor: pointer;
    ${({ isActive }) => (isActive ? mobileActive : mobileDefault)};
    ${({ theme }) => theme.typography.note}

    &:first-of-type {
        border-radius: 8px 0px 0px 8px;
    }

    &:last-of-type {
        border-radius: 0px 8px 8px 0px;
    }
`;

const types = {
    primary: css`
        ${primaryStyle};
    `,
    secondary: css`
        ${secondaryStyle}
    `,
    dark: css`
        ${darkStyle};

        &:not(:first-child) {
            margin-left: 30px;
        }
    `,
    mobile: css`
        ${mobileStyle};
    `
};

export const TabStyled = styled.button<Props>`
    flex: none;
    position: relative;
    outline: none;
    border: none;
    background: none;
    white-space: nowrap;
    overflow: hidden;
    ${({ typeStyle }) => types[typeStyle]};
    ${({ disabled }) => disabled && disabledStyle}
`;
