import { Store } from 'effector';

import { domain, pluginEvents, pluginStores } from './index';
import { configurationStores } from '../configuration';

const { resetPlugin } = pluginEvents;

const storesIgnoringReset: Store<any>[] = [pluginStores.pluginOptions, configurationStores.configuration];

domain.onCreateStore(store => {
    if (!storesIgnoringReset.includes(store)) {
        store.reset(resetPlugin);
    }
});
