import { TemplateParam } from './types';

export const templateValuateFinance = ({
    method,
    localeString,
    valuesString
}: TemplateParam) => `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:dat="dat">
            <soapenv:Header/>
            <soapenv:Body>
                <dat:${method}>
                    <request>
                        ${localeString}
                        ${valuesString}
                    </request>
                </dat:${method}>
            </soapenv:Body>
        </soapenv:Envelope>`;
