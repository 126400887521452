import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { getParsedArraySafe } from '@dat/api2/utils';
import { VinResult } from '../types';

export const extractVinResultFromContract = (contract: DAT2.ContractFromGetContract): VinResult | undefined => {
    const entries = getParsedArraySafe(contract?.customTemplateData?.entry);
    const vinResultEntry = entries.find(entry => entry.key === CONTRACT_ENTRIES_KEYS.MEMO.vinResult);
    const vinResultJSON = vinResultEntry?.value._text;

    if (vinResultJSON) {
        try {
            return JSON.parse(String(vinResultJSON));
        } catch {
            return undefined;
        }
    }
};
