import { historyEvents, historyStores } from './index';

const { history } = historyStores;

history.watch(history => {
    Object.entries(historyEvents).forEach(([methodName, event]) => {
        event.watch(params => {
            // @ts-ignore
            history?.[methodName](...params);
        });
    });
});
