import React from 'react';

import './stores/init';

import { pluginEvents } from './stores/plugin';
import { TRANSLATIONS } from './i18n';

import { PluginProvider } from '@dat/smart-components/PluginProvider';

import { PluginOptions } from './types/plugin';

import { Main } from './components/Main';

interface Props {
    options: PluginOptions;
}

const App: React.FC<Props> = ({ options }) => (
    <PluginProvider name="chat" options={options} translations={TRANSLATIONS} onInit={pluginEvents.initPlugin}>
        <Main />
    </PluginProvider>
);

export default App;
