import axios from 'axios';

import { parseXML, XMLObjectInstance } from './utils/parseXML';
import { createSoapRequest, SoapRequestData, SoapValues } from './utils/createSoapRequest';
import { parseErrorResponse } from './utils/parseErrorReponse';
import { VehicleRepairServices } from '@dat/core/constants/enums';
import { ErrorCodes } from './constants/enums';
import { stores } from '@dat/api2/stores/apiStores';
import { refreshTokenInterceptors } from '@dat/api2/utils/refreshTokenInterceptors';
import { apiEvents } from './stores';

export const datAxiosInstance = axios.create({
    method: 'POST',
    headers: {
        'Content-Type': 'text/xml'
    }
});

refreshTokenInterceptors(datAxiosInstance);

type AxiosParams<V extends SoapValues> = SoapRequestData<V> & {
    service?: VehicleRepairServices;
};

const datAxios = <Response, Values extends SoapValues = {}>({
    service,
    ...soapRequestData
}: AxiosParams<Values>): Promise<XMLObjectInstance<Response>> => {
    const host = stores.apiUrl.getState();
    const path = `${soapRequestData.product}/${service || ''}`;
    const url = new URL(path, host).href;

    return datAxiosInstance({ url, data: createSoapRequest(soapRequestData) })
        .then(XML => parseXML<Response>(String(XML)))
        .catch(XML => {
            const { errorCode, errorMessage } = parseErrorResponse(XML);

            if (errorCode === ErrorCodes.AuthorizationFailed) {
                apiEvents.expireToken();
            }

            throw new Error(errorMessage);
        });
};

export default datAxios;
