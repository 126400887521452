import styled from 'styled-components/macro';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div`
    width: 440px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.white};

    ${media.laptopSmall`
        width: 100%;
    `}
`;

export const RoleBlocksWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 20px;
    overflow: auto;

    ${makeCustomScrollBar()}
`;

export const RoleBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const TextStyled = styled(Text)`
    margin-bottom: 20px;
    margin-left: 20px;
`;

export const HeaderText = styled(Text)`
    display: none;

    ${media.laptopSmall`    
        display: flex;
    `}
`;

export const Header = styled.div`
    height: 88px;
    display: none;
    padding-right: 24px;
    padding-left: 20px;
    align-items: center;
    justify-content: flex-end;

    ${media.tablet`
        display: flex;
    `}

    ${media.laptopSmall`    
        justify-content: space-between;
        border-bottom: 2px solid ${({ theme }) => theme.colors.gray_10};
    `}
`;
