import { guard } from 'effector';
import _ from 'lodash';

import { pluginEvents } from './index';
import { contractEffects } from '@dat/shared-models/contract';

const { initPlugin } = pluginEvents;

guard({
    source: initPlugin.map(({ contractId }) => contractId),
    filter: (contractId): contractId is number => !isNaN(Number(contractId)),
    target: contractEffects.getContractFx
});
