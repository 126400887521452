import { aiGalleryEffects, aiGalleryEvents, aiGalleryStores } from './index';

import { forward, guard, sample } from 'effector';

import { contractEffects, contractEvents, contractStores } from '@dat/shared-models/contract';
import { extractAIResultFromContract } from './utils/extractAIResultFromContract';

const shouldGetContract = guard({
    source: contractStores.contractId,
    clock: aiGalleryEvents.getGalleryContract,
    filter: (contractId, aiContractId) => contractId !== aiContractId
});

sample({
    clock: shouldGetContract,
    source: aiGalleryEvents.getGalleryContract,
    target: contractEffects.getContractFx
});

forward({
    from: aiGalleryEvents.getGalleryContract,
    to: aiGalleryEffects.listAttachmentsOfContractFx
});

sample({
    source: aiGalleryStores.attachmentFolderId,
    clock: aiGalleryEffects.listAttachmentsOfContractFx.doneData,
    fn: (attachmentFolderId, data) =>
        attachmentFolderId ? data.filter(({ documentID }) => documentID === attachmentFolderId) : data,
    target: aiGalleryStores.attachments
});

sample({
    source: contractEvents.newContractReceived,
    fn: extractAIResultFromContract,
    target: aiGalleryStores.aiGallery
});
