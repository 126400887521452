import React, { FC, ReactNode } from 'react';
import { CardContainer, CardContent, CardTitle, CardValue } from './styles';
import { TextField } from '../styles';

interface Values {
    title: string;
    value: string | number | ReactNode;
    styles?: {
        fontWeight: number;
        fontSize: string;
    };
}

interface Props {
    cardTitle?: string;
    cardValues?: Array<Values>;
    isTotal?: boolean;
}

export const Card: FC<Props> = ({ cardTitle, cardValues, isTotal }) => (
    <CardContainer isTotal={isTotal}>
        <CardTitle>{cardTitle}</CardTitle>
        <CardContent>
            {cardValues?.map((item, index) => (
                <CardValue key={index}>
                    <TextField styles={item.styles}>{item.title}</TextField>
                    <TextField styles={item.styles} content="value">
                        {item.value}
                    </TextField>
                </CardValue>
            ))}
        </CardContent>
    </CardContainer>
);
