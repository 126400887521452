import { CalculationParameters } from '../../types/factors';

export const calculationInitialParameters: CalculationParameters = {
    referenceSetName: '',
    showLongWorkStrings: false,
    showAllIncludedWork: false,
    showFordFinisNumber: false,
    calculationWithoutWork: false,
    biwOptimizationMode: 'SUPPRESS',
    preDamageTotal: '',
    discount: '',
    newForOld: '',
    //TODO: request is invalid if this value is empty
    // totalValueImprovementAmount: '',
    vatRate: '',
    selectedLacquerMethod: 'EURO_LACQUER',

    _attributes: {
        mode: {
            discount: 'PERCENT',
            newForOld: 'PERCENT'
        }
    }
};
