import { LabourRateField, Operator } from '../types';
import { LacquerFactorTypes, ManufacturerLacquerType } from './paints';
import { radioItems } from './radioItems';

export const DEFAULT_FIELD: LabourRateField = {
    name: 'default',
    type: 'text',
    order: 0,
    value: ''
};

const selectedLacquerMethodValues = radioItems.selectedLacquerMethod();
const tabValues = selectedLacquerMethodValues.map(item => ({ ...item, id: String(item.value) }));

export const selectedLacquerMethod = (initValue = 0): LabourRateField => ({
    name: 'CalculationFactor-selectedLacquerMethod',
    type: 'tabs',
    options: tabValues,
    value: initValue,
    order: 0,
    hidden: false,
    placeholder: 'Selected Lacquer Method'
});

export const metaDataFactor: LabourRateField[] = [
    {
        name: 'MetadataFactor-name',
        type: 'text',
        value: '',
        hidden: true,
        placeholder: 'Metadata Factor',
        order: 0
    },
    {
        name: 'MetadataFactor-address',
        type: 'text',
        value: '',
        hidden: false,
        placeholder: 'Metadata Factor Address',
        order: 1
    },
    {
        name: 'MetadataFactor-brands',
        type: 'text',
        value: '',
        hidden: false,
        placeholder: 'Metadata Factor brands',
        order: 2
    },
    {
        name: 'MetadataFactor-fleets',
        type: 'text',
        value: '',
        hidden: false,
        placeholder: 'Metadata Factor fleets',
        order: 3
    },
    {
        name: 'MetadataFactor-identification',
        type: 'text',
        value: '',
        hidden: true,
        placeholder: 'Metadata Factor identification',
        order: 4
    },
    {
        name: 'MetadataFactor-address',
        type: 'text',
        value: '',
        hidden: false,
        placeholder: 'Metadata Factor Address',
        order: 5
    },
    {
        name: 'MetadataFactor-kzArt',
        type: 'number',
        value: 14,
        hidden: true,
        placeholder: 'Metadata Factor kzArt',
        order: 6
    }
];
export const calculationFactor: LabourRateField[] = [
    {
        name: 'CalculationFactor-value-discount',
        type: 'number',
        nullable: true,
        float: true,
        value: 0,
        order: 1,
        hidden: true,
        placeholder: 'Calculation Factor Discount'
    },
    {
        name: 'CalculationFactor-mode-discount',
        type: 'mode',
        nullable: true,
        related: 'CalculationFactor-value-discount',
        value: '0',
        order: 2,
        hidden: false,
        placeholder: 'Discount Mode'
    },
    {
        name: 'CalculationFactor-kzArt',
        type: 'number',
        value: 1,
        order: 3,
        hidden: true,
        placeholder: 'Calculation Factor kzArt'
    }
];
export const sparePartFactor: LabourRateField[] = [
    {
        name: 'SparePartFactor-value-discount',
        type: 'number',
        value: 0,
        hidden: true,
        placeholder: 'Discount',
        order: 0
    },
    {
        name: 'SparePartFactor-mode-discount',
        type: 'mode',
        related: 'SparePartFactor-value-discount',
        value: 0,
        hidden: false,
        placeholder: 'Discount Mode',
        order: 1
    },
    {
        name: 'SparePartFactor-value-discountBiw',
        type: 'number',
        value: 0,
        hidden: true,
        placeholder: 'Discount Biw',
        order: 2
    },
    {
        name: 'SparePartFactor-mode-discountBiw',
        type: 'mode',
        related: 'SparePartFactor-value-discountBiw',
        value: 0,
        hidden: false,
        placeholder: 'Discount Biw Mode',
        order: 3
    },
    {
        name: 'SparePartFactor-surchargeSeparated-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Separate spare parts surcharge',
        order: 4
    },
    {
        name: 'SparePartFactor-surchargeInProtocolOly-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Show SP surcharge only in the protocol',
        order: 5
    },
    {
        name: 'SparePartFactor-discount-blockedPositions',
        type: 'number',
        value: 0,
        hidden: false,
        placeholder: 'Discount Blocked Positioons',
        order: 6
    },
    {
        name: 'SparePartFactor-kzArt',
        type: 'number',
        value: 2,
        hidden: true,
        placeholder: 'Spare Part Factor kzArt',
        order: 7
    }
];
export const labourCostFactor: LabourRateField[] = [
    {
        name: 'LabourCostFactor-value-bodyWage1',
        type: 'numberFloat',
        value: 0,
        order: 0,
        hidden: false,
        placeholder: 'Body Wage1',
        required: true
    },
    {
        name: 'LabourCostFactor-value-bodyWage2',
        type: 'numberFloat',
        value: 0,
        order: 1,
        hidden: false,
        placeholder: 'Body Wage2'
    },
    {
        name: 'LabourCostFactor-value-bodyWage3',
        type: 'numberFloat',
        value: 0,
        order: 2,
        hidden: false,
        placeholder: 'Body Wage3'
    },
    {
        name: 'LabourCostFactor-value-electricWage1',
        type: 'numberFloat',
        value: 0,
        order: 3,
        hidden: false,
        placeholder: 'Electric Wage1'
    },
    {
        name: 'LabourCostFactor-value-electricWage2',
        type: 'numberFloat',
        value: 0,
        order: 4,
        hidden: false,
        placeholder: 'Electric Wage2'
    },
    {
        name: 'LabourCostFactor-value-electricWage3',
        type: 'numberFloat',
        value: 0,
        order: 5,
        hidden: false,
        placeholder: 'Electric Wage3'
    },
    {
        name: 'LabourCostFactor-value-mechanicWage1',
        type: 'numberFloat',
        value: 0,
        order: 6,
        hidden: false,
        placeholder: 'Mechanic Wage1'
    },
    {
        name: 'LabourCostFactor-value-mechanicWage2',
        type: 'numberFloat',
        value: 0,
        order: 7,
        hidden: false,
        placeholder: 'Mechanic Wage2'
    },
    {
        name: 'LabourCostFactor-value-mechanicWage3',
        type: 'numberFloat',
        value: 0,
        order: 8,
        hidden: false,
        placeholder: 'Mechanic Wage3'
    },
    {
        name: 'LabourCostFactor-value-discount',
        type: 'number',
        value: 0,
        hidden: false,
        placeholder: 'Discount',
        order: 9
    },
    {
        name: 'LabourCostFactor-value-dentWage',
        type: 'numberFloat',
        value: 0,
        hidden: false,
        placeholder: 'Dent (Eur/Hour)',
        order: 10
    },
    {
        name: 'LabourCostFactor-timeUnit',
        type: 'text',
        value: 0,
        hidden: true,
        placeholder: 'Time Unit',
        order: 11
    },
    {
        name: 'LabourCostFactor-timeUnitsPerHour',
        type: 'number',
        value: 0,
        hidden: true,
        placeholder: 'Time Units Per Hour (Eur/Hour)',
        order: 12
    },
    {
        name: 'LabourCostFactor-kzArt',
        type: 'number',
        value: 3,
        hidden: true,
        placeholder: 'Labour Cost Factor kzArt',
        order: 13
    }
];

// Paint type
// Wage Per Hour
export const euroLacquerFactor: LabourRateField[] = [
    {
        name: 'EuroLacquerFactor-timeUnit',
        type: 'text',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        order: 0,
        value: 0,
        hidden: true,
        placeholder: 'Time Unit'
    },
    {
        name: 'EuroLacquerFactor-timeUnitsPerHour',
        type: 'number',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        order: 1,
        value: 0,
        hidden: true,
        placeholder: 'Time Units Per Hour (Eur/Hour)'
    },
    {
        name: 'EuroLacquerFactor-type',
        type: 'select',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        order: 2,
        options: LacquerFactorTypes,
        value: 0,
        hidden: false,
        placeholder: 'Paint Type'
    },
    {
        name: 'EuroLacquerFactor-value-wage',
        type: 'number',
        order: 3,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Wage (Eur/Hour)'
    },
    {
        name: 'EuroLacquerFactor-value-discountMaterial',
        type: 'number',
        order: 4,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Discount Material'
    },

    {
        name: 'EuroLacquerFactor-value-discountWage',
        type: 'number',
        order: 5,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Discount Wage'
    },
    {
        name: 'EuroLacquerFactor-value-discountWageInclMaterial',
        type: 'number',
        order: 6,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Discount Wage (incl material)'
    },

    {
        name: 'EuroLacquerFactor-materialIndex',
        type: 'number',
        value: 0,
        order: 7,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        hidden: true,
        placeholder: 'Material Index'
    },
    {
        name: 'EuroLacquerFactor-materialIndexMax',
        type: 'number',
        order: 8,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: true,
        placeholder: 'Material Index (max)'
    },
    {
        name: 'EuroLacquerFactor-materialIndexMin',
        type: 'number',
        order: 9,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: true,
        placeholder: 'Material Index (min)'
    },
    {
        name: 'EuroLacquerFactor-isApplyUndercoatLacquerWhenRemoved-blockedPositions',
        type: 'checkbox',
        order: 10,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Apply Undercoat Lacquer When Removed'
    },
    {
        name: 'EuroLacquerFactor-isLacquerPlasticWhenFitted-blockedPositions',
        type: 'checkbox',
        order: 11,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Lacquer Plastic When Fitted'
    },
    {
        name: 'EuroLacquerFactor-isFrameworkUse-blockedPositions',
        type: 'checkbox',
        order: 12,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Framework Use'
    },
    {
        name: 'EuroLacquerFactor-materialMode-blockedPositions',
        type: 'checkbox',
        order: 13,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Material Mode'
    },
    {
        name: 'EuroLacquerFactor-isReducedPreparationTime-blockedPositions',
        type: 'checkbox',
        order: 14,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Reduced Preparation Time'
    },
    {
        name: 'EuroLacquerFactor-wageInclMaterialMode-blockedPositions',
        type: 'checkbox',
        order: 15,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Wage Incl Material Mode'
    },
    {
        name: 'EuroLacquerFactor-isSurchargeForSecondColor-blockedPositions',
        type: 'checkbox',
        order: 16,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Surcharge For Second Color'
    },
    {
        name: 'EuroLacquerFactor-isLacquerAssemblyWhenRemoved-blockedPositions',
        type: 'checkbox',
        order: 17,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 0,
        hidden: false,
        placeholder: 'Is Lacquer Assembly When Removed'
    },
    {
        name: 'EuroLacquerFactor-kzArt',
        type: 'number',
        order: 18,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '1'
        },
        value: 4,
        hidden: true,
        placeholder: 'Euro Lacquer Factor kzArt'
    }
];
export const manufacturerLacquerFactor: LabourRateField[] = [
    {
        name: 'ManufacturerLacquerFactor-timeUnit',
        type: 'text',
        value: 0,
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '2'
        },
        hidden: true,
        order: 0,
        placeholder: 'Time Unit'
    },
    {
        name: 'ManufacturerLacquerFactor-timeUnitsPerHour',
        type: 'number',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '2'
        },
        float: true,
        value: 0,
        order: 1,
        hidden: true,
        placeholder: 'Time Units Per Hour (Eur/Hour)'
    },
    {
        name: 'ManufacturerLacquerFactor-type',
        type: 'select',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '2'
        },
        options: ManufacturerLacquerType,
        value: 0,
        order: 2,
        hidden: false,
        placeholder: 'Paint Type'
    },
    {
        name: 'ManufacturerLacquerFactor-value-wage',
        type: 'number',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '2'
        },
        order: 3,
        value: 0,
        hidden: false,
        placeholder: 'Wage (Eur/Hour)'
    },

    {
        name: 'ManufacturerLacquerFactor-kzArt',
        type: 'number',
        condition: {
            operator: Operator.EQUAL,
            valueOf: 'CalculationFactor-selectedLacquerMethod',
            toValue: '2'
        },
        order: 4,
        value: 5,
        hidden: true,
        placeholder: 'Manufacturer Lacquer Factor kzArt'
    }
];

// Check For Flag and add to response
export const aztLacquerFactor: LabourRateField[] = [
    {
        name: 'AztLacquerFactor-value-wage',
        type: 'number',
        value: 0,
        hidden: false,
        order: 0,
        placeholder: 'Wage (Eur/Hour)'
    },
    {
        name: 'AztLacquerFactor-isSurchargeForMatt-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Is Surcharge For Material',
        order: 1
    },
    {
        name: 'AztLacquerFactor-isLacquerAdjustColorTone4Coat-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isLacquerAdjustColorTone4Coat',
        order: 2
    },
    {
        name: 'AztLacquerFactor-wageInclMaterialMode-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'wageInclMaterialMode',
        order: 3
    },
    {
        name: 'AztLacquerFactor-isSurchargeForSecondColor-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isSurchargeForSecondColor',
        order: 4
    },
    {
        name: 'AztLacquerFactor-isLacquerAssemblyWhenRemoved-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isLacquerAssemblyWhenRemoved',
        order: 5
    },
    {
        name: 'AztLacquerFactor-type',
        type: 'select',
        options: ManufacturerLacquerType,
        value: 0,
        hidden: false,
        placeholder: 'Paint Type',
        order: 6
    },
    {
        name: 'AztLacquerFactor-kzArt',
        type: 'number',
        value: 0,
        hidden: true,
        placeholder: 'Azt Lacquer Factor kzArt',
        order: 7
    }
];

export const domusCalculationFactor: LabourRateField[] = [
    {
        name: 'DomusCalculationFactor-layerMono',
        type: 'numberFloat',
        float: true,
        nullable: true,
        value: 0,
        hidden: false,
        placeholder: 'layerMono',
        order: 0
    },
    {
        name: 'DomusCalculationFactor-layerAdd15Mono',
        type: 'numberFloat',
        value: 0,
        float: true,
        nullable: true,
        hidden: false,
        placeholder: 'layerAdd15Mono',
        order: 1
    },
    {
        name: 'DomusCalculationFactor-layerDouble',
        type: 'numberFloat',
        value: 0,
        float: true,
        nullable: true,
        hidden: false,
        placeholder: 'layerDouble',
        order: 2
    },
    {
        name: 'DomusCalculationFactor-layerAdd15Double',
        type: 'numberFloat',
        value: 0,
        hidden: false,
        nullable: true,
        float: true,
        placeholder: 'layerAdd15Double',
        order: 3
    },
    {
        name: 'DomusCalculationFactor-layerTriple',
        type: 'numberFloat',
        value: 0,
        float: true,
        nullable: true,
        hidden: false,
        placeholder: 'layerTriple',
        order: 4
    },
    {
        name: 'DomusCalculationFactor-layerAdd15Triple',
        type: 'numberFloat',
        value: 0,
        float: true,
        nullable: true,
        hidden: false,
        placeholder: 'layerAdd15Triple',
        order: 5
    },
    {
        name: 'DomusCalculationFactor-layerQuad',
        type: 'numberFloat',
        float: true,
        nullable: true,
        value: 0,
        hidden: false,
        placeholder: 'layerQuad',
        order: 6
    },
    {
        name: 'DomusCalculationFactor-layerAdd15Quad',
        type: 'numberFloat',
        float: true,
        nullable: true,
        value: 0,
        hidden: false,
        placeholder: 'layerAdd15Quad',
        order: 7
    },
    {
        name: 'DomusCalculationFactor-value-wasteDisposalCostsPercValue',
        type: 'number',
        value: 0,
        hidden: false,
        placeholder: 'Waste Disposal Costs Value (Percent)',
        order: 8
    },
    {
        name: 'DomusCalculationFactor-value-wasteDisposalCostsMaxValue',
        type: 'numberFloat',
        float: true,
        value: 0,
        hidden: false,
        placeholder: 'Waste Disposal Costs Value (Euro)',
        order: 9
    },
    {
        name: 'DomusCalculationFactor-isDeductionCommonWork',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Is Deduction Common Work',
        order: 10
    },
    {
        name: 'DomusCalculationFactor-isDeductionIdenticalParts',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Is Deduction Identical Parts',
        order: 11
    },
    {
        name: 'DomusCalculationFactor-isDeductionNeighbouringMetalParts',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isDeductionNeighbouringMetalParts',
        order: 12
    },
    {
        name: 'DomusCalculationFactor-isSurchargeDifferingColourShades',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isSurchargeDifferingColourShades',
        order: 13
    },
    {
        name: 'DomusCalculationFactor-isSurchargePaintingNeighbouringParts',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'Is Surcharge Painting Neighbouring Parts',
        order: 14
    },
    {
        name: 'DomusCalculationFactor-isSurchargeWasteDisposalCosts',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isSurchargeWasteDisposalCosts',
        order: 15
    },
    {
        name: 'DomusCalculationFactor-isWageReadOnly',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'isWageReadOnly',
        order: 16
    },
    {
        name: 'DomusCalculationFactor-wasteDisposalCostsMaxValue-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'wasteDisposalCostsMaxValue',
        order: 17
    },
    {
        name: 'DomusCalculationFactor-wasteDisposalCostsPercValue-blockedPositions',
        type: 'checkbox',
        value: 0,
        hidden: false,
        placeholder: 'wasteDisposalCostsPercValue',
        order: 18
    },
    {
        name: 'DomusCalculationFactor-kzArt',
        type: 'number',
        value: 10,
        hidden: true,
        placeholder: 'Domus Calculation Factor kzArt',
        order: 19
    }
];

export const discountRuleFactor: LabourRateField[] = [
    {
        name: 'DiscountRuleFactor-discountResultFieldName',
        type: 'text',
        value: '',
        order: 0,
        hidden: false,
        placeholder: 'Discount Result Field Name',
        label: 'Discount Result Field Name'
    },
    {
        name: 'DiscountRuleFactor-discountInputFieldName',
        type: 'text',
        value: '',
        order: 1,
        hidden: false,
        placeholder: 'Discount Input Field Name',
        label: 'Discount Input Field Name'
    }
];

export const fieldsDictionary: LabourRateField[] = [
    ...metaDataFactor,
    ...calculationFactor,
    ...sparePartFactor,
    ...labourCostFactor,
    ...euroLacquerFactor,
    ...manufacturerLacquerFactor,
    ...aztLacquerFactor,
    ...domusCalculationFactor,
    ...discountRuleFactor
];
