import { createEvent, restore } from 'effector';
import { PluginOptions } from '../../types/plugin';

//
/*** Init plugin ***/
//
const initPlugin = createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

//
/*** Export ***/
//
export const pluginEvents = {
    initPlugin
};

export const pluginStores = {
    pluginOptions
};
