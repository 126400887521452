import React, { FC, CSSProperties } from 'react';
import './buttonGroupBar.css';

export interface ButtonGroupBarType {
    style?: CSSProperties;
    className?: string;
    isMobile?: boolean;
    flexDirection?: 'row' | 'column';
    children: React.ReactNode;
}

export const ButtonGroupBar: FC<ButtonGroupBarType> = ({
    style,
    className,
    isMobile = false,
    flexDirection = 'column',
    children
}) => {
    if (!children) return null;
    return (
        <div
            className={
                (isMobile ? 'weDat-grapa-button-group-bar-mobile ' : 'weDat-grapa-button-group-bar ') +
                (flexDirection === 'row' ? 'weDat-grapa-button-group-bar-direction-row ' : '') +
                className
            }
            style={style}
        >
            {children}
        </div>
    );
};
