import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';

export const FilterMenu: FC<{}> = () => {
    const { isShowFilterMenu, isSelectingSingle, isSelectingLeft, isSelectingRight } = useStore(
        graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
    );

    const { t } = useTranslation();

    function onClose() {
        graphicDamageSelectionModel.events.updateStore({
            isShowFilterMenu: false
        });
    }

    return (
        <Modal
            className="modal-grapa"
            // centered
            mask
            maskClosable
            cancelText={t('FilterMenu.Close')}
            footer={null}
            // closable={false}
            title={t('FilterMenu.SelectionFilter')}
            // okButtonProps={{
            //     style: { display: 'none' }
            // }}
            visible={isShowFilterMenu}
            // width={'200px'}
            width={'300px'}
            zIndex={1015}
            onCancel={onClose}
        >
            <div
                style={{
                    // maxWidth: '120px',
                    // margin: '16px auto 16px auto'
                    // lineHeight: '2'
                    margin: '0px 8px 0px 8px'
                }}
            >
                <Button
                    size="large"
                    style={{ width: '100%', margin: '8px 0px 8px 0px' }}
                    type={isSelectingSingle ? 'primary' : 'default'}
                    onClick={_ => {
                        graphicDamageSelectionModel.events.updateStore({
                            isSelectingSingle: !isSelectingSingle
                        });
                    }}
                >
                    {t('FilterMenu.Single')}
                </Button>

                <br />
                <Button
                    size="large"
                    style={{ width: '100%', margin: '8px 0px 8px 0px' }}
                    type={isSelectingLeft ? 'primary' : 'default'}
                    onClick={_ => {
                        graphicDamageSelectionModel.events.updateStore({
                            isSelectingLeft: !isSelectingLeft
                        });
                    }}
                >
                    {t('FilterMenu.Left')}
                </Button>

                <br />
                <Button
                    size="large"
                    style={{ width: '100%', margin: '8px 0px 8px 0px' }}
                    type={isSelectingRight ? 'primary' : 'default'}
                    onClick={_ => {
                        graphicDamageSelectionModel.events.updateStore({
                            isSelectingRight: !isSelectingRight
                        });
                    }}
                >
                    {t('FilterMenu.Right')}
                </Button>
            </div>
        </Modal>
    );
};
