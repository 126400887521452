import { FC } from 'react';
import { SeparatorStyled } from './styles';

interface Props {
    width?: number;
    height?: number;
    fit?: boolean;
}

export const Separator: FC<Props> = ({ width = 0, height = 15, fit = false }) => (
    <SeparatorStyled width={width} height={height} fit={fit} />
);
