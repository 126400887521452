import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const WhiteContainer = styled.div`
    width: 100%;
    padding: 20px 20px 0;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
`;

export const TitleWrapper = styled.div`
    ${media.phoneBig`
        text-align: center
    `}
`;
