import { guard, sample } from 'effector';
import { pluginEvents } from './index';
import { contractEffects, contractStores } from '@dat/shared-models/contract';

const { initPlugin } = pluginEvents;
const { getContractFx } = contractEffects;
const { contractId } = contractStores;

const contractIdReceived = guard({
    source: initPlugin.map(({ contractId }) => contractId),
    filter: (contractId): contractId is number => !isNaN(Number(contractId))
});

const shouldGetContract = guard({
    clock: contractIdReceived,
    source: contractId,
    filter: (currentContractId, receivedContractId) => currentContractId !== receivedContractId
});

sample({
    clock: shouldGetContract,
    source: contractIdReceived,
    target: getContractFx
});
