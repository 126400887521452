export const INITIAL_FORM_VALUATION_VALUES: FormikValuationValues = {
    vehicleContainer: '',
    kilometers: '',
    firstRegistration: undefined
};

export interface FormikValuationValues {
    vehicleContainer: string;
    kilometers: string | number | null;
    firstRegistration: Date | undefined;
}
