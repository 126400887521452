import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableWrapper } from '../Layout/styles';
import { Table } from '../Table';
import { TableFooter } from '../TableFooter';
import { createDataForSpareParts, createDataForSparePartsTableFooter } from '../../utils/createDataForSpareParts';
import { useStore } from 'effector-react';
import { Column } from 'react-table';
import { TableData } from '../../types/table';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { sharedDossierStores } from '@dat/shared-models/contract/Dossier';

export const SpareParts: FC = () => {
    const { t } = useTranslation();

    const dossier = useStore(sharedDossierStores.dossier);
    const isMobile = useMedia(sizes.phone);

    const data: TableData[] = useMemo(() => createDataForSpareParts(t, dossier, isMobile), [dossier, isMobile, t]);

    const columns: Column<TableData>[] = useMemo(
        () => [
            {
                Header: String(t('sparePartsTable.headers.DVN')),
                accessor: 'col1'
            },
            {
                Header: String(t('sparePartsTable.headers.number')),
                accessor: 'col2'
            },
            {
                Header: String(t('sparePartsTable.headers.description')),
                accessor: 'col3'
            },
            {
                Header: String(t('sparePartsTable.headers.type')),
                accessor: 'col4'
            },
            {
                Header: String(t('sparePartsTable.headers.discount')),
                accessor: 'col5'
            },
            {
                Header: String(t('sparePartsTable.headers.quantity')),
                accessor: 'col6'
            },
            {
                Header: String(t('sparePartsTable.headers.unitPrice')),
                accessor: 'col7'
            },
            {
                Header: String(t('sparePartsTable.headers.price')),
                accessor: 'col8'
            }
        ],
        [t]
    );

    const footerData = useMemo(() => createDataForSparePartsTableFooter(t, dossier), [dossier, t]);
    return (
        <TableWrapper>
            <Table columns={columns} data={data} title={t('sparePartsTable.title')} />
            <TableFooter items={footerData.items} isSpareParts={true} isMobile={isMobile} />
        </TableWrapper>
    );
};
