// partsInfoModel
import { combine, createEvent, restore, createEffect, sample, createStore } from 'effector';

import {
    getExtPartNoInfoByFullVehicleAndIntPartNo,
    getExtPartNoInfoByModelAndExtPartNo
} from '@dat/api2dat/services/sparePartsService';
import {
    GetExtPartNoInfoByFullVehicleAndIntPartNoParams,
    GetExtPartNoInfoByFullVehicleAndIntPartNoResponse,
    GetExtPartNoInfoByModelAndExtPartNoParams,
    GetExtPartNoInfoByModelAndExtPartNoResponse
} from '@dat/api2dat/types/SparePartsServiceTypes';
import { requestCredentialsModel } from './requestCredentialsModel';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { splitDatECode } from '../utils/splitDatECode';
import { pluginOptionsModel } from './pluginOptionsModel';
import { GetSparePartVariantsParams, ResultGetSparePartVariants } from '@dat/api2dat/types/api2datTypes';
import { getSparePartVariants } from '@dat/api2dat/services/vehicleRepairOnlineService';

export interface PartList {
    datProcessId: number;
    partName?: string;
}

const setCurrentPartList = createEvent<PartList[]>();
const currentPartList = createStore<PartList[]>([]).on(setCurrentPartList, (_, newState) => newState);

const getExtPartNoInfoByModelAndExtPartNoFx = createEffect(getExtPartNoInfoByModelAndExtPartNo);

const setResultGetExtPartNoInfoByModelAndExtPartNo = createEvent<GetExtPartNoInfoByModelAndExtPartNoResponse | null>();
const resultGetExtPartNoInfoByModelAndExtPartNo = restore(setResultGetExtPartNoInfoByModelAndExtPartNo, null);

const runGetExtPartNoInfoByModelAndExtPartNo = createEvent<string[]>();

resultGetExtPartNoInfoByModelAndExtPartNo.on(getExtPartNoInfoByModelAndExtPartNoFx.doneData, (_, newState) => newState);

sample({
    source: combine([
        pluginOptionsModel.stores.localesParams,
        availableAssemblyGroupsModel.stores.datECode,
        requestCredentialsModel.stores.requestCredentials,
        requestCredentialsModel.stores.datServicesUrls
    ]),
    clock: runGetExtPartNoInfoByModelAndExtPartNo,
    fn: ([localesParams, datECode, requestCredentials, datServicesUrls], partNo) => {
        if (!localesParams) return {};

        const { locale } = localesParams;

        const sDatECode = splitDatECode(datECode);

        // TEMPORARILY bugfix: removed leading letter "A" in mercedes part numbers, which breaks getExtPartNoInfoByModelAndExtPartNo request
        // TODO: define a transcodification for mercedes part numbers
        const partNoVerified =
            sDatECode.manufacturerId === '570' ? partNo.map(pn => (pn[0] === 'A' ? pn.slice(1) : pn)) : partNo;

        const params: GetExtPartNoInfoByModelAndExtPartNoParams = {
            locale: {
                attr: locale || {
                    country: 'us',
                    datCountryIndicator: 'de',
                    language: 'EN'
                }
            },
            coverage: 'COMPLETE',

            vehicleType: parseInt(sDatECode.vehicleTypeId),
            manufacturer: parseInt(sDatECode.manufacturerId),
            baseModel: parseInt(sDatECode.baseModelId),
            subModel: parseInt(sDatECode.subModelId),

            extPartNo: partNoVerified
        };
        return {
            credentials: requestCredentials,
            params,
            url: datServicesUrls.sparePartsServiceUrl || ''
        };
    },
    target: getExtPartNoInfoByModelAndExtPartNoFx
});

// ================================================================================================
// getExtPartNoInfoByFullVehicleAndIntPartNo

const setCurrentExtPartNoInfo = createEvent<GetExtPartNoInfoByFullVehicleAndIntPartNoResponse | null>();
const currentExtPartNoInfo = restore(setCurrentExtPartNoInfo, null);

const getExtPartNoInfoByFullVehicleAndIntPartNoFx = createEffect(getExtPartNoInfoByFullVehicleAndIntPartNo);
getExtPartNoInfoByFullVehicleAndIntPartNoFx.doneData.watch(setCurrentExtPartNoInfo);

const runGetExtPartNoInfoByFullVehicleAndIntPartNo = createEvent<string | string[]>();

currentExtPartNoInfo.on(runGetExtPartNoInfoByFullVehicleAndIntPartNo, () => null);

sample({
    source: combine([
        pluginOptionsModel.stores.localesParams,
        availableAssemblyGroupsModel.stores.datECode,
        requestCredentialsModel.stores.requestCredentials,
        requestCredentialsModel.stores.datServicesUrls,
        pluginOptionsModel.stores.pluginOptions
    ]),
    clock: runGetExtPartNoInfoByFullVehicleAndIntPartNo,
    fn: ([localesParams, datECode, requestCredentials, datServicesUrls, pluginOptions], dvn) => {
        if (!localesParams || !dvn) return {};

        const constructionTime = pluginOptions?.settings?.contract?.Dossier?.Vehicle?.ConstructionTime;

        const { locale } = localesParams;
        const sDatECode = splitDatECode(datECode);
        const params: GetExtPartNoInfoByFullVehicleAndIntPartNoParams = {
            locale: {
                attr: locale || {
                    country: 'us',
                    datCountryIndicator: 'de',
                    language: 'EN'
                }
            },
            datECode: sDatECode.datECode,
            intPartNo: dvn,
            constructionTime
        };
        return {
            credentials: requestCredentials,
            params,
            url: datServicesUrls.sparePartsServiceUrl || ''
        };
    },
    target: getExtPartNoInfoByFullVehicleAndIntPartNoFx
});

// ===============================================================================================
// getSparePartVariants

const setCurrentSparePartVariants = createEvent<ResultGetSparePartVariants | null>();
const currentSparePartVariants = createStore<ResultGetSparePartVariants | null>(null).on(
    setCurrentSparePartVariants,
    (_, newState) => newState
);
const getSparePartVariantsFx = createEffect(getSparePartVariants);
const runGetSparePartVariants = createEvent<number>();

sample({
    source: {
        localesParams: pluginOptionsModel.stores.localesParams,
        datECode: availableAssemblyGroupsModel.stores.datECode,
        requestCredentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls
    },
    clock: runGetSparePartVariants,
    fn: ({ localesParams, datECode, requestCredentials, datServicesUrls }, datProcessId) => {
        const locale = localesParams?.locale || {
            country: 'us',
            datCountryIndicator: 'de',
            language: 'EN'
        };

        const params: GetSparePartVariantsParams = {
            locale: {
                attr: locale
            },
            datECode,
            datProcessId
        };

        return {
            credentials: requestCredentials,
            params,
            url: datServicesUrls?.vehicleRepairOnlineServiceUrl || ''
        };
    },
    target: getSparePartVariantsFx
});

getSparePartVariantsFx.doneData.watch(setCurrentSparePartVariants);

sample({
    source: currentSparePartVariants,
    fn: currentSparePartVariants => {
        const sparePartVariants = currentSparePartVariants?.sparePartVariant;
        return Array.from(new Set(sparePartVariants?.map(variant => variant.partNumber)));
    },
    target: runGetExtPartNoInfoByModelAndExtPartNo
});

export const partsInfoModel = {
    stores: {
        resultGetExtPartNoInfoByModelAndExtPartNo,
        currentExtPartNoInfo,

        currentPartList,
        currentSparePartVariants
    },
    events: {
        runGetExtPartNoInfoByModelAndExtPartNo,
        setResultGetExtPartNoInfoByModelAndExtPartNo,
        runGetExtPartNoInfoByFullVehicleAndIntPartNo,
        runGetSparePartVariants,
        setCurrentPartList
    },
    effects: {
        getExtPartNoInfoByModelAndExtPartNoFx,
        getExtPartNoInfoByFullVehicleAndIntPartNoFx,
        getSparePartVariantsFx
    }
};
