import styled from 'styled-components';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { CloseIcon, ProfileIcon } from '@wedat/ui-kit/components/Icons';

interface WrapperProps {
    isSingleNotification: boolean;
}

export const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray_40};
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const StyledProfileIcon = styled(ProfileIcon)`
    height: 48px;
    width: 48px;
    margin-right: 10px;
`;

export const NotificationsWrapper = styled.div``;

export const Image = styled.img`
    height: 48px;
    width: 48px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
`;

export const Wrapper = styled.div<WrapperProps>`
    width: 380px;
    height: ${({ isSingleNotification }) => (isSingleNotification ? 'auto' : '368px')};
    padding: 20px;
    padding-right: 14px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.colors.bgColor};
    border-radius: 4px;
    background-color: white;
    position: absolute;
    top: 72px;
    right: 184px;

    ${media.phoneBig`
        right: 50%;
        transform: translateX(50%);
    `}

    ${media.phoneMedium`
        width: 310px;
    `}
`;

export const Content = styled.div`
    width: 100%;
    height: 80%;
    padding-right: 6px;
    overflow: auto;

    ${makeCustomScrollBar()}
`;

export const Title = styled.h2`
    font-weight: 600;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.gray_300};
    margin-bottom: 20px;
`;

export const NotificationCard = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.gray_10};
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const CreationDate = styled.span`
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray_40};
`;

export const NotificationMessage = styled.span`
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray_300};
`;

export const CompanionName = styled.span`
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.gray_300};
`;

export const PartnerName = styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray_40};
`;

export const ContractLink = styled.button`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray_300};
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.yellow['10']};
    transition: border-bottom 0.3s, opacity 0.3s;

    &:hover {
        border-bottom: 2px solid transparent;
        opacity: 0.5;
    }
`;

export const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ProfileInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
