import React from 'react';
import { useFormikContext } from 'formik';

import { DatepickerField, DatepickerFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationDatepickerField: React.FC<DatepickerFieldProps> = props => {
    const { submitForm } = useFormikContext();

    return <DatepickerField {...props} onEditEnd={submitForm} />;
};
