import { FC } from 'react';

import { Container, MessageWrapper, TimeWrapper } from './styles';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    message: string;
    own?: boolean;
    time?: string;
}

export const Message: FC<Props> = ({ own, message, time }) => (
    <Container own={own}>
        <MessageWrapper own={own}>
            <Text color="inherit">{message}</Text>
        </MessageWrapper>
        <TimeWrapper own={own}>
            <Text font="note" color="gray_40">
                {time}
            </Text>
        </TimeWrapper>
    </Container>
);
