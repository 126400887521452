import React, { FC } from 'react';
import { FieldWrap, Label, Content } from './styles';

export interface Props {
    width?: string;
    height?: string;
    size?: 'col-1' | 'col-2' | 'col-3' | 'col-4' | 'col-6' | 'col-8';
    label?: string;
    isActive?: boolean;
    content?: string | React.ReactNode;
}

export const Field: FC<Props> = ({ label, width, content, children, size, isActive, height }) => (
    <FieldWrap width={width} size={size} isActive={!!isActive} height={height}>
        <Label>{label}</Label>
        {content ? <Content>{content}</Content> : children}
    </FieldWrap>
);
