import React, { FC } from 'react';

import { Checkbox, Radio, Switch } from 'antd';
import { SwitchWithSwipe } from './Switch/SwitchWithSwipe';

export const SelectedElement: FC<{
    checked: boolean;
    disabled?: boolean;
    onChange?: (e: Event) => void;
    style?: React.CSSProperties;
    type?: 'radio' | 'checkBox' | 'boxWithDot' | 'switch' | 'switchWithSwipe';
}> = ({ checked, disabled, onChange, style, type = 'switchWithSwipe' }) => {
    if (type === 'radio')
        return (
            <Radio
                style={{ paddingRight: '10px', float: 'left', marginTop: '1px', ...style }}
                checked={checked}
                disabled={disabled}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    (onChange as any)?.(e);
                }}
            />
        );
    if (type === 'checkBox')
        return (
            <Checkbox
                style={{ paddingRight: '10px', float: 'left', marginTop: '1px', ...style }}
                checked={checked}
                disabled={disabled}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    (onChange as any)?.(e);
                }}
            />
        );

    if (type === 'switch')
        return (
            <Switch
                style={{
                    float: 'left',
                    marginTop: '1px',
                    ...style
                }}
                checked={checked}
                disabled={disabled}
                onClick={(_v, e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    (onChange as any)?.(e);
                }}
                // onChange={e => {
                //     // e.stopPropagation();
                //     // e.preventDefault();
                //     (onChange as any)?.(e);
                // }}
            />
        );

    if (type === 'switchWithSwipe')
        return (
            <SwitchWithSwipe
                checked={checked}
                disabled={disabled}
                onChange={e => {
                    // e?.stopPropagation();
                    // e?.preventDefault();
                    (onChange as any)?.(e);
                }}
            />
        );

    return (
        <Checkbox
            style={{ paddingRight: '10px', float: 'left', marginTop: '1px', ...style }}
            indeterminate={checked}
            checked={false}
            disabled={disabled}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
            onChange={e => {
                (onChange as any)?.(e);
            }}
        />
    );
};
