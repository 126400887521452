import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce, TableState, FilterValue } from 'react-table';
import { InputContainer, FilterInput } from './styles';

interface Props {
    globalFilter: TableState | string;
    setGlobalFilter: (filterValue: FilterValue) => void;
}

export const GlobalFilter: FC<Props> = ({ globalFilter, setGlobalFilter }) => {
    const { t } = useTranslation();
    const [_, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <InputContainer>
            <FilterInput
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={t('searchPlaceholder')}
            />
        </InputContainer>
    );
};
