import { Button, Modal } from 'antd';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';
import { ReactComponent as ChevronLeftIcon } from '../CommonComponents/Icons/ChevronLeftIcon.svg';
import { ReactComponent as ChevronRightIcon } from '../CommonComponents/Icons/ChevronRightIcon.svg';
import { ReactComponent as ChevronDownIcon } from '../CommonComponents/Icons/ChevronDownIcon.svg';
import { dynamicGenericSVGGraphics } from '../../initData/dynamicGenericSVGGraphics';

export const FastTrackSVGController = ({ isMobile, aiEnabled }: { isMobile?: boolean; aiEnabled?: boolean }) => {
    const { t } = useTranslation();

    const fastTrackSVG = useStore(fastTrackElementModel.stores.$fastTrackSVG);
    const aiMode = useStore(fastTrackElementModel.stores.$aiMode);
    const genericSVGGraphics = useStore(genericSVGGraphicModel.stores.genericSVGGraphics);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const graphicIds = dynamicGenericSVGGraphics.map(grap => grap.graphicId);

    return genericSVGGraphics?.length && genericSVGGraphics?.length > 1 ? (
        isMobile ? (
            <>
                <div className="weDat-ft-graphic-view-mobile">
                    <Button
                        type="text"
                        size="large"
                        onClick={() => {
                            if (aiMode) {
                                fastTrackElementModel.events.setAiMode(false);
                                fastTrackElementModel.events.setFastTrackSVGById(graphicIds[graphicIds.length - 1]);
                            } else if (fastTrackSVG?.graphicId === graphicIds[0]) {
                                fastTrackElementModel.events.setAiMode(!!aiEnabled);
                                fastTrackElementModel.events.setFastTrackSVGById(
                                    aiEnabled ? 'aiMode' : graphicIds[graphicIds.length - 1]
                                );
                            } else {
                                const curGraphicIdIndex = graphicIds.indexOf(fastTrackSVG?.graphicId || '');
                                fastTrackElementModel.events.setFastTrackSVGById(graphicIds[curGraphicIdIndex - 1]);
                            }
                        }}
                    >
                        {<ChevronLeftIcon width="24" height="24" />}
                    </Button>
                    <div className="weDat-ft-graphic-view-select" onClick={() => setIsModalVisible(true)}>
                        <label>{t('fastTrack.GraphicView.View')}</label>
                        <span>{t('fastTrack.GraphicView.' + (fastTrackSVG?.graphicId || 'AIMode'))}</span>
                        <ChevronDownIcon width="24" height="24" color="#687792" />
                    </div>
                    <Button
                        type="text"
                        size="large"
                        onClick={() => {
                            if (aiMode) {
                                fastTrackElementModel.events.setAiMode(false);
                                fastTrackElementModel.events.setFastTrackSVGById(graphicIds[0]);
                            } else if (fastTrackSVG?.graphicId === graphicIds[graphicIds.length - 1]) {
                                fastTrackElementModel.events.setAiMode(!!aiEnabled);
                                fastTrackElementModel.events.setFastTrackSVGById(aiEnabled ? 'aiMode' : graphicIds[0]);
                            } else {
                                const curGraphicIdIndex = graphicIds.indexOf(fastTrackSVG?.graphicId || '');
                                fastTrackElementModel.events.setFastTrackSVGById(graphicIds[curGraphicIdIndex + 1]);
                            }
                        }}
                    >
                        {<ChevronRightIcon width="24" height="24" />}
                    </Button>
                </div>

                <Modal
                    footer={null}
                    title={t('fastTrack.GraphicView.View')}
                    visible={isModalVisible}
                    zIndex={1015}
                    className="weDat-ft-graphicView-modal"
                    onCancel={() => setIsModalVisible(false)}
                >
                    {graphicIds.map(view => (
                        <div
                            className="graphicView"
                            onClick={() => {
                                fastTrackElementModel.events.setAiMode(false);
                                fastTrackElementModel.events.setFastTrackSVGById(view);
                                setIsModalVisible(false);
                            }}
                        >
                            {t('fastTrack.GraphicView.' + view)}
                        </div>
                    ))}
                    {aiEnabled && (
                        <div
                            className="graphicView"
                            onClick={() => {
                                fastTrackElementModel.events.setAiMode(true);
                                fastTrackElementModel.events.setFastTrackSVG(null);
                                setIsModalVisible(false);
                            }}
                        >
                            {t('fastTrack.GraphicView.AIMode')}
                        </div>
                    )}
                </Modal>
            </>
        ) : (
            <div className="ant-btn-group weDat-ft-graphic-view">
                {graphicIds.map(view => (
                    <Button
                        type={!aiMode && fastTrackSVG?.graphicId === view ? 'primary' : 'default'}
                        style={{ width: `${100 / 6}%` }}
                        onClick={() => {
                            if (aiMode) fastTrackElementModel.events.setAiMode(false);
                            fastTrackElementModel.events.setFastTrackSVGById(view);
                        }}
                    >
                        {t('fastTrack.GraphicView.' + view)}
                    </Button>
                ))}
                <Button
                    type={aiMode ? 'primary' : 'default'}
                    disabled={aiEnabled ? false : true}
                    style={{ width: `${100 / 6}%` }}
                    onClick={() => {
                        if (!aiMode) {
                            fastTrackElementModel.events.setFastTrackSVG(null);
                            fastTrackElementModel.events.setAiMode(true);
                        }
                    }}
                >
                    {t('fastTrack.GraphicView.AIMode')}
                </Button>
            </div>
        )
    ) : null;
};
