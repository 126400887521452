import styled from 'styled-components/macro';
import preloaderImage from '@wedat/ui-kit/assets/images/preloader.gif';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const PhotoItem = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 8px;
    margin: 5px 10px;
    cursor: pointer;
    background-image: url(${preloaderImage});
    background-size: contain;
`;

export const PhotosHeader = styled.div`
    display: flex;
    padding: 5px;
`;

export const PhotosHeaderTitle = styled.div`
    margin-left: 5px;
    font-size: 16px;
    font-weight: bolder;
`;

export const DownloadAll = styled.a`
    margin-right: 10px;
    margin-left: auto;
    color: ${({ theme }) => theme.colors.gray['500']};
`;

export const PhotosList = styled.div`
    width: 100%;
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const PhotosListItems = styled.div`
    white-space: nowrap;
`;
