import { combine, createEvent, createStore } from 'effector';
import { spread } from 'patronum';

type EditorState = {
    image: string;
    imageName?: string;
    isOpen: boolean;
    groupId: number;
};

const showEditor = createEvent<EditorState>();
const hideEditor = createEvent();

const image = createStore<string>('');
const imageName = createStore<string>('');
const groupId = createStore<number>(0);
const isOpen = createStore<boolean>(false).reset(hideEditor);

spread({
    source: showEditor,
    targets: {
        image,
        groupId,
        isOpen
    }
});

const editorState = combine<EditorState>({
    image,
    isOpen,
    imageName,
    groupId
});

export const editorEvents = {
    showEditor,
    hideEditor
};
export const editorStores = {
    isOpen,
    image,
    editorState
};
