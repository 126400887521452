import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isAppointmentPopupOpen, toggleIsAppointmentPopupOpen] = createToggle(false);
const [isExpiryPopupOpen, toggleIsExpiryPopupOpen] = createToggle(false);

export const appointmentEvents = {
    toggleIsAppointmentPopupOpen,
    toggleIsExpiryPopupOpen
};

export const appointmentStores = {
    isAppointmentPopupOpen,
    isExpiryPopupOpen
};
