import { attach, sample } from 'effector';
import _ from 'lodash';

import { getParsedArraySafe } from '@dat/api2/utils';

import { TablePosition, UpdatePositionsPayload } from './types';

import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { contractStores, contractEffects } from '@dat/shared-models/contract';
import { postEditingStores, postEditingEffects } from './index';

const { repairCalculation, repairPositions } = sharedRepairCalculationStores;
const { initialFormPositions, selectedPositions } = postEditingStores;
const { updatePositions, deletePositions } = postEditingEffects;
const { contractId } = contractStores;
const { createOrUpdateContractFx, calculateCurrentContractFx } = contractEffects;

sample({
    clock: repairCalculation,
    fn: (repairCalculation: DAT2.RepairCalculation | undefined): TablePosition[] => {
        const materialPositions = getParsedArraySafe(
            repairCalculation?.CalcResultCommon?.MaterialPositions?.MaterialPosition
        );
        const labourPositions = getParsedArraySafe(
            repairCalculation?.CalcResultCommon?.LabourPositions?.LabourPosition
        );
        const lacquerPositions = getParsedArraySafe(
            repairCalculation?.CalcResultCommon?.LacquerPositions?.LacquerPosition
        );

        const convertedMaterialPositions: TablePosition[] = materialPositions.map(pos => {
            const correspondingLabourPositions = labourPositions
                .filter(item => pos.DATProcessId === item.DATProcessId)
                .map(item => ({ ...item, isSubPosition: true }));
            const correspondingLacquerPositions = lacquerPositions.filter(
                item => pos.DATProcessId === item.DATProcessId
            );
            const labourTimeCalculated = correspondingLabourPositions.reduce(
                (sum, currentItem) => (currentItem.Duration ? sum + currentItem.Duration : sum),
                0
            );
            const labourPriceCalculated = correspondingLabourPositions.reduce(
                (sum, currentItem) => (currentItem.ValueTotalCorrected ? sum + currentItem.ValueTotalCorrected : sum),
                0
            );
            const lacquerMaterialCalculated = correspondingLacquerPositions.reduce(
                (sum, currentItem) => (currentItem.Material ? sum + currentItem.Material : sum),
                0
            );
            const lacquerTimeCalculated = correspondingLacquerPositions.reduce(
                (sum, currentItem) => (currentItem.Duration ? sum + currentItem.Duration : sum),
                0
            );
            const lacquerPriceCalculated = correspondingLacquerPositions.reduce(
                (sum, currentItem) => (currentItem.ValueTotalCorrected ? sum + currentItem.ValueTotalCorrected : sum),
                0
            );
            const LLTotal = labourPriceCalculated + lacquerPriceCalculated;
            const totalValueCalculated = LLTotal + (pos.ValueTotalCorrected || 0);

            return {
                ...pos,
                labourTimeCalculated,
                labourPriceCalculated,
                lacquerMaterialCalculated,
                lacquerTimeCalculated,
                lacquerPriceCalculated,
                totalValueCalculated,
                LLTotal,
                isSubPosition: false,
                subLabourPositions: correspondingLabourPositions,
                subLacquerPositions: correspondingLacquerPositions
            };
        });

        return convertedMaterialPositions;
    },
    target: initialFormPositions
});

// TODO: in func below come up with something to avoid copy paste
updatePositions.use(
    attach({
        source: [contractId, repairCalculation, repairPositions],
        effect: async (
            [contractId, _repairCalculation, repairPositions],
            { position, updatingField, subType }: UpdatePositionsPayload
        ) => {
            let correspondingRepairPositions = repairPositions.filter(
                item => item.DATProcessId === position.DATProcessId
            );
            const repairPositionsWithoutCorresponding = repairPositions.filter(
                item => item.DATProcessId !== position.DATProcessId
            );

            if (!subType) {
                const typedPosition = { ...position } as TablePosition;

                if (updatingField === 'SparePartNumber') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === 'replace') {
                            return {
                                ...pos,
                                SparePartNumberOrigin: 'MANUAL',
                                SparePartNumber: typedPosition.PartNumber
                            };
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'Description') {
                    const supportVariable: DAT2.RepairPosition[] = correspondingRepairPositions.map(pos => {
                        const newPos: DAT2.RepairPosition = {
                            ...pos,
                            IsManualDescription: true
                        };
                        delete newPos.DescriptionId;
                        delete newPos.WorkIndication;

                        if (pos.RepairType === 'replace') {
                            return { ...newPos, Description: typedPosition.Description };
                        }

                        return newPos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'SparePartPrice') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === 'replace') {
                            return {
                                ...pos,
                                SparePartPrice: typedPosition.ValueTotalCorrected
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'SparePartDiscount') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === 'replace') {
                            return {
                                ...pos,
                                SparePartDiscount: typedPosition.SparePartDiscount
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                /////////////////////////////////////////////
                /////////////////////////////////////////////
                /////////////////////////////////////////////
            } else if (subType) {
                type TypedPosition = DAT2.LabourPosition & DAT2.LacquerPosition & { PartPrice?: number };
                const typedPosition = { ...position } as TypedPosition;

                if (updatingField === 'Duration') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === typedPosition.RepairType) {
                            return {
                                ...pos,
                                WorkTime: typedPosition.Duration,
                                WorkIndication: 1
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'Description') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        const newPos: DAT2.RepairPosition = {
                            ...pos,
                            IsManualDescription: true
                        };
                        delete newPos.DescriptionId;
                        delete newPos.WorkIndication;

                        if (pos.RepairType === typedPosition.RepairType) {
                            return { ...newPos, Description: typedPosition.Description };
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'SparePartDiscount') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === typedPosition.RepairType) {
                            return {
                                ...pos,
                                // TODO: ignore
                                // @ts-ignore
                                WorkTime: typedPosition.SparePartDiscount
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'WorkLevel') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === typedPosition.RepairType) {
                            return {
                                ...pos,
                                WorkLevel: typedPosition.Level
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }

                if (updatingField === 'ValueTotal') {
                    const supportVariable = correspondingRepairPositions.map(pos => {
                        if (pos.RepairType === typedPosition.ValueTotal) {
                            return {
                                ...pos,
                                ValueTotal: typedPosition.ValueTotal
                            } as DAT2.RepairPosition;
                        }

                        return pos;
                    });

                    correspondingRepairPositions = [...supportVariable];
                }
            }

            await createOrUpdateContractFx({
                contractId,
                Dossier: {
                    RepairCalculation: {
                        RepairPositions: {
                            RepairPosition: [...repairPositionsWithoutCorresponding, ...correspondingRepairPositions]
                        }
                    }
                }
            });
        }
    })
);

deletePositions.use(
    attach({
        source: [contractId, selectedPositions, repairPositions],
        effect: async ([contractId, selectedPositions, repairPositions]) => {
            const repairPositionsForUpdateIds = selectedPositions.map(item => {
                const stringsArr = item.split('-');

                return stringsArr[stringsArr.length - 1];
            });

            const repairPositionsForUpdate = repairPositions.filter(item => {
                if (item.DATProcessId) {
                    return !repairPositionsForUpdateIds.includes(String(item.DATProcessId));
                }
                return true;
            });

            await createOrUpdateContractFx({
                contractId,
                Dossier: {
                    RepairCalculation: {
                        RepairPositions: {
                            RepairPosition: repairPositionsForUpdate
                        }
                    }
                }
            });
        }
    })
);

sample({
    clock: [updatePositions.done, deletePositions.done],
    fn: _.noop,
    target: calculateCurrentContractFx
});
