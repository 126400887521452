import styled from 'styled-components/macro';

interface Props {
    color?: string;
}

export const ModalTextFieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
    width: 100%;

    @media (max-width: 540px) {
        width: 100%;
    }
`;

export const ModalTextLabel = styled.span<Props>`
    color: ${props => props.color || '#c9cfdc'};
    font-weight: 500;
    font-size: 18px;
    margin-right: 20px;
`;

export const ModalTextValue = styled.span`
    color: #465064;
    font-weight: 500;
    font-size: 18px;
`;
