import React, { FC } from 'react';

import './graphicTooltip.css';

export interface GraphicTooltipType {
    text?: string;
    posX?: string | number;
    posY?: string | number;
    tooltipPosition?: string; // left | right
    arrowDirection?: string; // left | right | top | bottom
    arrowPosition?: string; // start | center | end
    children?: React.ReactNode;
}

export const GraphicTooltip: FC<GraphicTooltipType> = props => {
    const tooltipCoords =
        props?.tooltipPosition === 'left'
            ? { right: props?.posX || 0, top: props?.posY || 0 }
            : { left: props?.posX || 0, top: props?.posY || 0 };
    const arrowDirectionClass = props?.arrowDirection ? props?.arrowDirection + 'TooltipArrow' : '';
    const arrowPositionClass =
        props?.arrowPosition && props?.arrowDirection ? props?.arrowPosition + '-' + arrowDirectionClass : '';

    return (
        <div className={`graphicTooltip ${arrowDirectionClass} ${arrowPositionClass}`} style={tooltipCoords}>
            {props?.text}
            {props.children}
        </div>
    );
};
