import styled, { css } from 'styled-components/macro';

import { InputStyled } from '@wedat/ui-kit/components/Input/styles';
import { Button } from '@wedat/ui-kit/components/Button';
import { ModalTextFieldContainer } from '../RegistrationNumberRequestResultModal/styles';
import { media } from '@wedat/ui-kit/mediaQueries';
import { ButtonIcon } from '@wedat/ui-kit/components/ButtonIcon';
import { CheckIcon } from '@wedat/ui-kit/components/Icons';

export const StyledModalTextFieldContainer = styled(ModalTextFieldContainer)`
    width: 100%;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-between;
`;

export const SliderContainer = styled.div<{ width: string }>`
    width: ${({ width }) => `${width}`};

    ${media.phone`
        width: 330px;
    `}

    ${media.phoneSmall`
        width: 300px;
    `}
`;

export const InputsContainer = styled.div`
    max-width: 289px;
    width: 100%;
    margin: auto;
    text-align: center;

    ${InputStyled} {
        text-align: center;
    }
`;

export const InputMarginWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
`;

export const SectionTitle = styled.h3`
    font-size: ${({ theme: { fontSize } }) =>
        // @ts-ignore TODO: remove it from pluginOptions
        fontSize?.alternativeModelTitle ? fontSize?.alternativeModelTitle : '20px'};
    margin: 25px 20px 0;
`;

export const ColorField = styled.div`
    width: 100%;
    height: 47px;
    background: #f1f2f4;
    border-radius: 2px;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    ${media.laptop`
        width: 100%;
    `}
`;

export const ColorText = styled.span`
    color: #465064;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
`;

export const AlternativeModelText = styled.p`
    font-size: ${({ theme: { fontSize } }) =>
        // @ts-ignore TODO: remove it from pluginOptions
        fontSize?.alternativeModelText ? fontSize?.alternativeModelText : '14'}px;
    margin: 2px 20px 12px;
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    @media screen and (max-width: 540px) {
        margin-top: 1rem;
    }
`;

interface EditButtonProps {
    title: string;
}

export const EditButton = styled(Button)<EditButtonProps>`
    width: 32px;
    padding: 0;
    border-radius: 50%;

    img {
        width: 16px;
    }
`;

export const MainButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${({ theme }) => theme.colors.gray_10};
    background-color: white;
    padding: 10px 20px;

    button {
        text-transform: uppercase;
    }
`;

export const EquipmentButton = styled(Button)`
    width: fit-content;
    padding: 17px;
    margin-right: 10px;
    margin: 0 10px;
`;

export const StyledFinishButton = styled(Button)`
    width: fit-content;
    padding: 17px;

    ${media.laptop`
        width: 100%;
   `}
`;

export const StyledTechnicalDataButton = styled(Button)`
    width: fit-content;
    padding: 17px;
    margin-right: 10px;

    ${media.laptop`
        margin-right: 0;
    `}
`;

export const GetStarted = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;

    ${media.laptop`
        margin-bottom: 20px;
    `}

    ${media.tabletSmall`
        width: 100%;
        flex-direction: column;
    `}
`;

export const InProgress = styled.div`
    width: 100%;
    display: flex;
`;

export const SelectsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 280px;
    row-gap: 20px;

    ${media.tablet`
        min-width: 230px;
    `}
`;

export const SelectsGroupContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    grid-gap: 20px;

    ${media.laptop`
        grid-template-columns: 1fr;
    `}
`;

export const SelectsSeparator = styled.div`
    width: 3px;
    height: auto;
    border-radius: 2px;
    background: #f1f2f4;
    margin: 0 10px;

    ${media.laptop`
        display: none;
    `}
`;

export const CheckboxLabel = styled.span`
    margin-left: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #c9cfdc;
`;

export const DatepickerContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

export const VehicleSelectionWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr auto 5fr;
    margin: 20px 0;

    ${media.phone`
        margin-bottom: 0;
    `}

    ${media.laptop`
        grid-template-columns: repeat(1, 100%);
    `}
`;

export const YellowDot = styled.div`
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 50%;
    background: #ffb444;
`;

export const SliderWrapper = styled.div<{ sticky?: boolean }>`
    display: flex;
    justify-content: center;
    margin: 0 20px;

    ${({ sticky }) =>
        sticky &&
        css`
            position: -webkit-sticky;
            position: sticky;
            align-self: flex-start;
            top: 50%;
            transform: translateY(-50%);
        `}
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    max-width: fit-content;
    padding: 14px;
    margin-right: 10px;
`;

export const PaintTypeCheckIcon = styled(CheckIcon)`
    ${({ theme }) => css`
        width: 16px;
        color: ${theme.colors.blueDark};
        margin-right: 10px;
    `}
`;
