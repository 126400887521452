import { attach, createEvent, createStore } from 'effector';

import { initialFactorsParameters } from '../../constants/factors';
import { contractEffects } from '@dat/shared-models/contract';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { createPayloadForUpdateContractFactors } from '../../utils/createPayloadForUpdateContractFactors';
import { omitDomusCalculationFactor } from '../../utils/omitDomusCalculationFactor';
import { effectorLogger } from '@dat/core/utils';

import { FactorsParametersObject, PartialFactorsParametersObject } from '../../types/factors';

const contractFactorsParameters = createStore<FactorsParametersObject>(initialFactorsParameters);
const predefinedFactorsParametersUpdated = createEvent<PartialFactorsParametersObject>();
const isContractWithDomusCalculationFactor = sharedTemplateStores.templateSettings.contractType.map(
    contractType => contractType === 'vro_domus_calculation'
);
const updateContractFactorsFx = attach({
    //TODO: send only changed parameters
    effect: contractEffects.updateCurrentContractFx,
    source: isContractWithDomusCalculationFactor,
    mapParams: (factorsParameters: PartialFactorsParametersObject, isContractWithDomusCalculationFactor) => {
        if (!isContractWithDomusCalculationFactor) {
            factorsParameters = omitDomusCalculationFactor(factorsParameters);
        }

        return createPayloadForUpdateContractFactors(factorsParameters);
    }
});

//
/*** Export ***/
//
export const factorsEvents = {
    predefinedFactorsParametersUpdated
};
export const factorsEffects = {
    updateContractFactorsFx
};
export const factorsStores = {
    contractFactorsParameters,
    isContractWithDomusCalculationFactor
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(factorsEvents);
    effectorLogger(factorsEffects);
    effectorLogger(factorsStores);
}
