import React from 'react';

import ErrorPageImage from '../../../assets/images/ErrorPageImage.png';
import { Container, Content, Image, Text } from './styles';

export const FallBackUI = () => (
    <Container>
        <Content>
            <Image src={ErrorPageImage} alt="ErrorPageImage" />
            <Text>Something went wrong</Text>
        </Content>
    </Container>
);
