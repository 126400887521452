import _ from 'lodash';
import { getFieldText, getParsedArraySafe } from '@dat/api2/utils';
import { filterArrayOfObjectsByExistingKeys } from '@dat/core/utils';

type Param = {
    contract: DAT2.ContractFromGetContract | null;
    customerPartners: DAT2.Partner[] | null;
};
type Result = DAT2.Partner[];

// Gets assigned partners from customer's partners-list and `Dossier.TradingData`
export const extractAllContractPartners = ({ contract, customerPartners }: Param): Result => {
    const participatingPartnersEntries = getParsedArraySafe(contract?.participatingPartners?.entry).map(
        ({ key, value }) => ({ role: key, partnerId: getFieldText(value) })
    );

    const allPartners = [
        ...(customerPartners || []),
        ...extractPartnersFromTradingData(contract?.Dossier?.TradingData)
    ];
    const uniquePartners = _.uniqBy(allPartners, ({ partnerId }) => partnerId);

    return _.intersectionBy(uniquePartners, participatingPartnersEntries, ({ partnerId }) => partnerId);
};

const extractPartnersFromTradingData = (tradingData: DAT2.TradingData | undefined = {}): DAT2.Partner[] => {
    const partnersAddressData = Object.values(tradingData).filter((item): item is DAT2.AddressData =>
        _.isPlainObject(item)
    );
    const validPartnersAddressData = filterArrayOfObjectsByExistingKeys(partnersAddressData, [
        'CustomerTypeShort',
        'CustomerType',
        'CustomerNumber'
    ]);

    return validPartnersAddressData.map(
        (addressData): DAT2.Partner => ({
            city: addressData.StreetCity,
            country: addressData.Country,
            customerNumber: addressData.CustomerNumber,
            name: addressData.NameLong,
            partnerId: addressData.CustomerTypeShort,
            role: addressData.CustomerType,
            street: addressData.Street,
            streetNr: addressData.StreetNumber,
            zip: addressData.StreetZipCode
        })
    );
};
