import { PartialDeep } from 'type-fest';

export type GenericField = number | string | boolean | object | { [key: string]: string };

export type ConfigKeys = keyof DAT2.Internal.FactorName;

export interface GenericObject {
    [key: string]: any;
}

export type FormState = GenericObject;

export interface RateParam {
    id: number;
    ownerId: number;
    country: string;
}

type DatFactorName =
    | 'Dime'
    | 'WasteDisposal'
    | 'AztLacquerFactor'
    | 'CalculationFactor'
    | 'SparePartFactor'
    | 'LabourCostFactor'
    | 'DomusCalculationFactor'
    | 'EuroLacquerFactor'
    | 'ManufacturerLacquerFactor';

export type Condition = {
    operator: Operator;
    valueOf: string;
    toValue: GenericField;
};

export enum Operator {
    EQUAL = 'equal',
    IS_NOT_NULL = 'isNotNull'
}

export type LabourRateFieldType =
    | 'radioGroup'
    | 'select'
    | 'text'
    | 'number'
    | 'numberFloat'
    | 'radioButtonGroup'
    | 'mode'
    | 'price'
    | 'date'
    | 'checkbox'
    | 'tabs'
    | 'radio';

export type LabourRateField = {
    name: string;
    type: LabourRateFieldType;
    value: GenericField;
    hidden?: boolean;
    related?: string;
    nullable?: boolean;
    condition?: Condition;
    placeholder?: string;
    order: number;
    options?: {
        value: string | number;
        label: string;
        id: string;
    }[];
    label?: string;
    validator?: (value: GenericField) => boolean;
    error?: string;
    disabled?: boolean;
    required?: boolean;
    readonly?: boolean;
    float?: boolean;
};

export interface FormConfig {
    fields: LabourRateField[];
    title: string;
    id: string;
    type: string;
}

export type FactorName = Extract<DatFactorName, keyof FactorsParametersObject>;

export interface FactorsParametersObject {
    CalculationFactor: CalculationParameters;
    SparePartFactor: SparePartsParameters;
    LabourCostFactor: LabourCostParameters;
    DomusCalculationFactor: DomusCalculationParameters;
    EuroLacquerFactor: EuroLacquerParameters;
    ManufacturerLacquerFactor: ManufacturerLacquerParameters;
}
export type PartialFactorsParametersObject = PartialDeep<FactorsParametersObject>;

export type PercentOrAbsoluteMode = 'ABSOLUTE' | 'PERCENT';
export type PerTimeOrAbsoluteMode = 'PER_TIME' | 'ABSOLUTE';
export type MaterialMode = 'LACQUER_METHOD' | 'PERCENT' | 'ABSOLUTE';
export type LacquerMethod = 'EURO_LACQUER' | 'MANUFACTURER_SPECIFIC' | 'AZT' | 'CZ';

export interface CalculationParameters {
    referenceSetName: string;
    showLongWorkStrings: boolean;
    showAllIncludedWork: boolean;
    showFordFinisNumber: boolean;
    calculationWithoutWork: boolean;
    biwOptimizationMode: 'SUPPRESS' | 'AUTOMATIC' | 'FORCE';
    preDamageTotal: string;
    discount: string;
    newForOld: '';
    // totalValueImprovementAmount: string;
    vatRate: string;
    selectedLacquerMethod: LacquerMethod;

    _attributes: {
        mode: {
            discount: PercentOrAbsoluteMode;
            newForOld: PercentOrAbsoluteMode;
        };
    };
}

export interface SparePartsParameters {
    priceDate: string;
    increaseDecrease: string;
    surchargeInProtocolOly: boolean;
    surchargeSeparated: boolean;
    sparePartsDisposalCosts: string;
    sparePartLumpSum: string;
    bracketSetRentCost: string;
    discount: string;
    discountBiw: string;

    bracketSetProcurementCost: string;
    procurementCost: string;
    bodyInWhiteProcurementCost: string;

    smallSparePartFlatRatePrice: string;
    smallSparePartPercentOfPart: string;
    smallSparePartPercentOfPartBiw: string;

    _attributes: {
        mode: {
            discount: PercentOrAbsoluteMode;
            discountBiw: PercentOrAbsoluteMode;
        };
    };
}

export interface LabourCostParameters {
    mechanicWage1: string;
    mechanicWage2: string;
    mechanicWage3: string;
    bodyWage1: string;
    bodyWage2: string;
    bodyWage3: string;
    electricWage1: string;
    electricWage2: string;
    electricWage3: string;
    dentWage: string;
    dentsCountInProtocol: boolean;
    isSuppressCavityProtection: boolean;
    discount: string;
    discountBiw: string;
    labourLumpSum: string;

    _attributes: {
        mode: {
            discount: PercentOrAbsoluteMode;
            discountBiw: PercentOrAbsoluteMode;
        };
    };
}

export interface DomusCalculationParameters {
    layerMono: string;
    layerAdd15Mono: string;
    layerDouble: string;
    layerAdd15Double: string;
    layerTriple: string;
    layerAdd15Triple: string;
    layerQuad: string;
    layerAdd15Quad: string;
    wasteDisposalCostsPercValue: string;
    wasteDisposalCostsMaxValue: string;
    isSurchargePaintingNeighbouringParts: boolean;
    isDeductionNeighbouringMetalParts: boolean;
    isSurchargeWasteDisposalCosts: boolean;
    isDeductionIdenticalParts: boolean;
    isSurchargeDifferingColourShades: boolean;
    isDeductionCommonWork: boolean;
}

export interface EuroLacquerParameters {
    type: string;
    addition: string[];
    exemplarySheets: string;
    colorName: string;
    colorCode: string;
    color2ndName: string;
    color2ndCode: string;

    wageMode: PerTimeOrAbsoluteMode;
    wage: string;
    wageFlatRate: string;
    discountWage: string;

    materialMode: MaterialMode;
    materialPerPointCost: string;
    materialIndex: string;
    materialFlatRatePercent: string;
    materialFlatRateAbsolute: string;
    materialSpecialLacquer: string;
    discountMaterial: string;

    wageInclMaterialMode: PerTimeOrAbsoluteMode;
    wageInclMaterial: string;
    wageInclMaterialFlatRate: string;
    discountWageInclMaterial: string;

    preparationTimePercent: string;
    preparationTimePlasticPercent: string;
    isReducedPreparationTime: boolean;
    isFrameworkUse: boolean;
    isSurchargeForSecondColor: boolean;
    isLacquerPlasticWhenFitted: boolean;
    isApplyUndercoatLacquerWhenRemoved: boolean;
    isLacquerAssemblyWhenRemoved: boolean;
    disposalCostPercent: string;
    maskingWorkGlassCount: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
    maskingWorkPlasticCount: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
    matBlackWindowFrameCount: '1' | '2' | '3' | '4';

    _attributes: {
        mode: {
            discountWage: PercentOrAbsoluteMode;
            discountMaterial: PercentOrAbsoluteMode;
            discountWageInclMaterial: PercentOrAbsoluteMode;
        };
    };
}

export interface ManufacturerLacquerParameters {
    type: string;
    addition: string;
    colorName: string;
    colorCode: string;
    color2ndName: string;
    color2ndCode: string;

    wageMode: PerTimeOrAbsoluteMode;
    wage: string;
    wageFlatRate: string;
    discountWage: string;
    discountWageBiw: string;

    materialMode: MaterialMode;
    materialFlatRatePercent: string;
    materialFlatRateAbsolute: string;
    discountMaterial: string;
    discountMaterialBiw: string;

    wageInclMaterialMode: PerTimeOrAbsoluteMode;
    wageInclMaterial: string;
    wageInclMaterialFlatRate: string;
    discountWageInclMaterial: string;
    discountWageInclMaterialBiw: string;

    preparationTimePercent: string;
    isLacquerAssemblyWhenRemoved: boolean;

    _attributes: {
        mode: {
            discountWage: PercentOrAbsoluteMode;
            discountWageBiw: PercentOrAbsoluteMode;
            discountMaterial: PercentOrAbsoluteMode;
            discountMaterialBiw: PercentOrAbsoluteMode;
            discountWageInclMaterial: PercentOrAbsoluteMode;
            discountWageInclMaterialBiw: PercentOrAbsoluteMode;
        };
    };
}
