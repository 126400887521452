import { generateMemberId } from './generateMemberId';

interface Params {
    sender: DAT2.Plugins.Chat.DialogMemberId;
    username: string;
    customerNumber: number;
}

export const isSenderCurrentUser = ({ sender, username, customerNumber }: Params) => {
    const id = generateMemberId({ customerNumber, username });

    return id === sender;
};
