export const calculatePositionCorrPrice = ({
    PartDiscountPerc = 100,
    ValueParts = 0,
    PartDiscountType
}: DAT2.PositionItaly) => {
    const valuePercent = ValueParts * (PartDiscountPerc / 100);
    if (PartDiscountType === 'M') {
        return ValueParts + valuePercent;
    }
    return PartDiscountType ? ValueParts - valuePercent : '';
};
