import styled from 'styled-components/macro';

import { Button } from '@wedat/ui-kit/components/Button';

export const StyledModalButton = styled(Button)`
    cursor: pointer;
    margin-right: 10px;
`;

export const Field = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 2fr 5fr;
    }
`;

export const ModalContainer = styled.div`
    width: auto;
`;

export const Label = styled.label`
    display: inline-block;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gray['300']};
`;

export const ModalButtonsContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 15px;
`;
