import { useFormikContext } from 'formik';

import { ManualFields } from '../types/manualFields';

// Checks if "Gross" radio button is selected
export const useShouldShowGrossValues = () => {
    const {
        values: {
            Valuation: { DisplayGross }
        }
    } = useFormikContext<ManualFields>();

    return !!DisplayGross;
};
