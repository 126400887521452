// TODO: type this constant with DAT2.Locales
export const countries = [
    { key: 'AT', languageCode: 'DE', value: 'Austria' },
    { key: 'BG', languageCode: 'BG', value: 'Bulgaria' },
    { key: 'CH', languageCode: 'DE', value: 'Switzerland' },
    { key: 'CN', languageCode: 'CN', value: 'China' },
    { key: 'CZ', languageCode: 'CZ', value: 'Czech Republic' },
    { key: 'DE', languageCode: 'DE', value: 'Germany' },
    { key: 'ES', languageCode: 'ES', value: 'Spain' },
    { key: 'FR', languageCode: 'FR', value: 'France' },
    { key: 'GR', languageCode: 'GR', value: 'Greece' },
    { key: 'HU', languageCode: 'HU', value: 'Hungary' },
    { key: 'IT', languageCode: 'IT', value: 'Italy' },
    { key: 'NL', languageCode: 'NL', value: 'Netherlands' },
    { key: 'PL', languageCode: 'PL', value: 'Poland' },
    { key: 'RO', languageCode: 'RO', value: 'Romania' },
    { key: 'RU', languageCode: 'RU', value: 'Russia' },
    { key: 'SK', languageCode: 'SK', value: 'Slovakia' },
    { key: 'TR', languageCode: 'TR', value: 'Turkey' },
    { key: 'US', languageCode: 'EN', value: 'USA' }
];
