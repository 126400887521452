import { FC, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { useClickOutside } from '@dat/core/hooks/useClickOutside';
import { sharedUserStores } from '@dat/shared-models/user';
import { i18nStores } from '@dat/shared-models/i18n';

import { Dropdown } from '@wedat/ui-kit/components/Dropdown';
import { ArrowDownIcon, SignOutIcon, profileIcon } from '@wedat/ui-kit/components/Icons';

import {
    Arrow,
    ButtonProfile,
    Container,
    Flag,
    ImageStyled,
    Item,
    ItemContent,
    ItemText,
    ItemTitle,
    Wrap
} from './styles';
import { useTranslation } from 'react-i18next';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { FLAGS_IMAGES } from '@dat/core/constants/flags';
import { sharedLoginEvents } from '@dat/shared-models/auth/login';
import { Link } from 'react-router-dom';
import { handleDownloadClick } from './utils';

interface Props {
    changeLanguage?: () => void;
    openProfile?: () => void;
}

export const Profile: FC<Props> = ({ changeLanguage, openProfile }) => {
    const { t } = useTranslation();
    const currentLocale = useStore(i18nStores.locale);
    const userProfile = useStore(sharedUserStores.userProfile);
    const role = useStore(sharedConfigurationStores.customerSettings.role);

    const [isListVisible, setIsListVisible] = useState(false);

    const containerRef = useRef(null);

    useClickOutside(containerRef, () => {
        setIsListVisible(false);
    });

    const handleClickProfile = () => {
        setIsListVisible(!isListVisible);
    };

    const openVersions = () => {
        process.env.REACT_APP_SHOW_VERSIONS && window.ivwt?.show();
    };

    return (
        <Container ref={containerRef} onClick={handleClickProfile}>
            <ButtonProfile>
                <ImageStyled src={userProfile?.image || profileIcon} alt="user avatar" />
                <Arrow>
                    <ArrowDownIcon />
                </Arrow>
            </ButtonProfile>
            <Dropdown side="left" isOpen={isListVisible}>
                <Item head>
                    <Wrap>
                        <ItemTitle>
                            {userProfile?.name} {userProfile?.surname}
                        </ItemTitle>
                        <ItemText>{t(`all-components:roles.${role.toLowerCase()}`)}</ItemText>
                    </Wrap>
                </Item>
                <Link to="/administration/labour-rates-generic">
                    <Item>
                        <ItemTitle>{t('auth:profile.administration')}</ItemTitle>
                    </Item>
                </Link>

                <Item
                    onClick={() => {
                        handleDownloadClick(currentLocale);
                    }}
                >
                    <ItemTitle>{t('auth:profile.howToUseWeDAT')}</ItemTitle>
                </Item>
                <Item onClick={openProfile}>
                    <ItemTitle>{t('auth:profile.text')}</ItemTitle>
                </Item>
                {process.env.REACT_APP_SHOW_VERSIONS && window.ivwt?.show && (
                    <Item onClick={openVersions}>
                        <ItemTitle>{t('auth:profile.versions')}</ItemTitle>
                    </Item>
                )}
                <Item onClick={changeLanguage}>
                    <ItemTitle>{t('auth:profile.language')}</ItemTitle>
                    <Flag src={FLAGS_IMAGES[currentLocale]} />
                </Item>
                <Item onClick={() => sharedLoginEvents.logoutManually()}>
                    <ItemContent>
                        <SignOutIcon />
                        <ItemTitle>{t('auth:profile.logOut')}</ItemTitle>
                    </ItemContent>
                </Item>
            </Dropdown>
        </Container>
    );
};
