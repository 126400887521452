import { createEvent, createEffect, attach, restore } from 'effector';
import { API2 } from '@dat/api2';
import { printReportsStore } from '../printReports';
import { attachmentsStores } from '../attachments';
import { mailConfigurationStores } from '../configuration';
import { toastEffects } from '@dat/smart-components/Toast/store';
import { createDataForReturnAttachments } from '../../utils/createDataForReturnAttachments';
import { pluginStores } from '../plugin';

import { sharedMailEvents } from '@dat/shared-models/mail';

const { pluginOptions } = pluginStores;
const { selectedReports } = printReportsStore;
const { selectedAttachments } = attachmentsStores;

const { mailConfig } = mailConfigurationStores;

const successfullySent = createEvent<boolean>();
const isSuccessfullySent = restore(successfullySent, false);
isSuccessfullySent.watch(state => {
    if (state) {
        setTimeout(() => {
            successfullySent(false);
            sharedMailEvents.toggleIsMailOpen(false);
        }, 3000);
    }
});

const sendAttachmentsFx = createEffect(API2.myClaimInternal.sendAttachments);
const landingMailFx = createEffect(API2.myClaimInternal.landingMail);

type SendMailParams = {
    data: {
        values: DAT2.Internal.Request.LandingMail;
        succsessCallback: () => void;
    };
    selectedAttachments: DAT2.Internal.FolderOfClaimAttachment[];
    selectedReports: DAT2.Internal.PrintReport[];
    contractId: number;
    mailboxFolderId: number;
};

const sendMailFx = createEffect(
    async ({ data, contractId, selectedAttachments, selectedReports, mailboxFolderId }: SendMailParams) => {
        if (!contractId) return;

        const selectedAttachmentsIds = selectedAttachments.map(item => item.id.toString());
        const selectedreportsIds = selectedReports.map(item => item.id.toString());

        const { values, succsessCallback } = data;

        const entriesDataForReturnAttachments = createDataForReturnAttachments(selectedAttachments);

        const formattedData = {
            ...values,
            claimId: contractId,
            reports: selectedreportsIds,
            documents: [mailboxFolderId.toString()]
        };

        let sendAttachmentsReponse:
            | DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Response.SendAttachments>
            | undefined;

        if (selectedAttachmentsIds.length > 0) {
            sendAttachmentsReponse = await sendAttachmentsFx({
                data: selectedAttachmentsIds,
                claimId: contractId,
                folderId: mailboxFolderId
            });
        }

        const landingMailFxReponse = await landingMailFx(formattedData);

        if (!sendAttachmentsReponse?.error && entriesDataForReturnAttachments.length > 0) {
            entriesDataForReturnAttachments.forEach(async ([documentId, attacmentsIds]) => {
                await sendAttachmentsFx({
                    data: attacmentsIds.map(i => i.toString()),
                    claimId: contractId,
                    folderId: documentId
                });
            });
        }

        if (typeof landingMailFxReponse.error !== 'undefined') {
            toastEffects.showErrorToastFx({
                message: {
                    namespace: 'mail',
                    key: landingMailFxReponse.error || 'send.sendError'
                }
            });
            return;
        }

        if (landingMailFxReponse.data || landingMailFxReponse.events) {
            sendMailEvents.successfullySent(true);
            succsessCallback();
        }

        return landingMailFxReponse;
    }
);

const sendMailFxWithAttachments = attach({
    source: { pluginOptions, selectedAttachments, selectedReports, mailConfig },
    mapParams: (data: SendMailParams['data'], { pluginOptions, selectedAttachments, selectedReports, mailConfig }) => ({
        data,
        contractId: pluginOptions?.contractId ?? 0,
        selectedAttachments,
        selectedReports,
        mailboxFolderId: mailConfig.mailboxFolderId ?? 0
    }),
    effect: sendMailFx
});

export const sendMailEvents = {
    successfullySent
};

export const sendMailEffects = {
    landingMailFx,
    sendMailFxWithAttachments
};

export const sendMailStores = {
    isSuccessfullySent
};
