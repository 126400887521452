export const getFileName = ({
    defaultPrintoutDescription,
    vin,
    registrationNumber,
    description
}: {
    defaultPrintoutDescription?: string;
    vin?: string;
    registrationNumber?: string;
    description?: string;
}) => {
    let fileName = '';

    if (vin && defaultPrintoutDescription === 'vin') {
        fileName = vin;
    } else if (registrationNumber && defaultPrintoutDescription === 'registrationNumber') {
        fileName = registrationNumber;
    }

    if (description) {
        if (fileName.length) {
            fileName = `${fileName} - ${description}`;
        } else {
            fileName = description;
        }
    }

    if (fileName) {
        fileName = `${fileName}.pdf`;
    }

    return fileName;
};
