import { createEffect, sample } from 'effector';

import { getFilesByDatECode } from '@dat/api2dat/services/staticGrapaService';
import { pluginOptionsModel } from './pluginOptionsModel';
import { requestCredentialsModel } from './requestCredentialsModel';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { AvailableAssemblyGroup, AvailableAssemblyGroupsStore, DatECodeMaterialInfo } from '../types/graphicTypes';
import { splitDatECode } from '../utils/splitDatECode';

import { ConstructionGroup } from '../types/svgDescriptionTypes';
import { parseGroupGraphicFromSVG } from '../utils/groupGraphicParser';
import { getLanguage } from '../utils/datLocale';
import { firstLetterToUpperCase } from '../utils/firstLetterToUpperCase';
import { zoneGraphicsModel } from './zoneGraphicsModel';
import { ConstructionGroupZusatz } from '@dat/api2dat/types/zusatzTypes';
import { decodeXml } from '@dat/api2dat/utils/xmlSpecialCharacters';

// updateFromStaticGrapaServiceModel

const getFilesByDatECodeFx = createEffect(getFilesByDatECode);

const endOfZoneGraphicsFile = 'zoneGraphics.json';
const endOfSvgDescriptionFile = 'svgdescription.json';
const endOfMaterialLIstFile = 'materialList.json';

sample({
    source: [
        pluginOptionsModel.stores.modelDatECode,
        pluginOptionsModel.stores.localesParams,
        requestCredentialsModel.stores.datServicesUrls
    ],
    // clock: getFilesByDatECodeFx,
    fn: ([datECode, localesParams]) => {
        // const beginDate = new Date();
        const { vehicleTypeId, manufacturerId, baseModelId } = splitDatECode(datECode);
        const vehicleType = parseInt(vehicleTypeId);
        const manufacturer = parseInt(manufacturerId);
        const mainType = parseInt(baseModelId);
        const result: AvailableAssemblyGroupsStore = {
            locale: localesParams?.locale,
            vehicleType,
            manufacturer,
            mainType,
            startDownloading: new Date(),
            availableAssemblyGroups: []
        };
        return result;
    },
    target: availableAssemblyGroupsModel.events.setAvailableAssemblyGroupsStore
});

sample({
    source: [pluginOptionsModel.stores.modelDatECode],
    clock: getFilesByDatECodeFx.doneData,
    fn: ([_datECode], filesGrapa) => {
        const foundMaterialLIst = filesGrapa.find(
            file => file.name.slice(-endOfMaterialLIstFile.length) === endOfMaterialLIstFile
        );
        const foundMaterialLIstString = new TextDecoder().decode(foundMaterialLIst?.buffer);
        const materialList = JSON.parse(foundMaterialLIstString || '') as DatECodeMaterialInfo;

        return materialList;
    },
    target: availableAssemblyGroupsModel.events.setMaterialList
});

sample({
    source: [pluginOptionsModel.stores.modelDatECode],
    clock: getFilesByDatECodeFx.doneData,
    fn: ([datECode], filesGrapa) => {
        const foundZoneGraphics = filesGrapa.find(
            file => file.name.slice(-endOfZoneGraphicsFile.length) === endOfZoneGraphicsFile
        );
        const zoneGraphicsString = new TextDecoder().decode(foundZoneGraphics?.buffer);
        const constructiongroup = JSON.parse(zoneGraphicsString || '') as ConstructionGroupZusatz[];

        const result = { datECode, constructiongroup };

        return result;
    },
    target: zoneGraphicsModel.events.setZusatzData
});

sample({
    source: [
        pluginOptionsModel.stores.modelDatECode,
        pluginOptionsModel.stores.localesParams
        // requestCredentialsModel.stores.datServicesUrls
    ],

    clock: getFilesByDatECodeFx.doneData,
    fn: ([modelDatECode, localesParams], filesGrapa) => {
        const beginDate = new Date();

        const { vehicleTypeId, manufacturerId, baseModelId } = splitDatECode(modelDatECode);

        const vehicleType = parseInt(vehicleTypeId);
        const manufacturer = parseInt(manufacturerId);
        const mainType = parseInt(baseModelId);

        const languageCode = getLanguage(localesParams?.language).languageCode || 'ENG';

        const foundGroupsDescription = filesGrapa.find(
            file => file.name.slice(-endOfSvgDescriptionFile.length) === endOfSvgDescriptionFile
        );
        const groupsDescriptionString = new TextDecoder().decode(foundGroupsDescription?.buffer);
        const groupsDescription = JSON.parse(groupsDescriptionString || '') as ConstructionGroup[];

        const availableAssemblyGroups: AvailableAssemblyGroup[] = groupsDescription.map(grDesc => {
            const name = firstLetterToUpperCase(
                decodeXml(
                    grDesc.constructionGroupNames.lang.find(el => el.attr.language === languageCode)?.['#text'] || '-'
                )
            );

            let isHailGroup = grDesc.constructionGroupNames.lang
                .find(el => el.attr.language === 'ENG')
                ?.['#text']?.toLowerCase()
                .includes('hail');

            return {
                assemblyGroup: {
                    assemblyGroupId: grDesc.constructiongroupid,
                    name
                },
                isHailGroup,
                svg: ''
            };
        });

        filesGrapa
            .filter(file => file.name.slice(-4) === '.svg')
            .forEach(svgFile => {
                const svg = new TextDecoder().decode(svgFile.buffer);

                const assemblyGroupId = parseInt(svgFile.name.slice(-8, -4));

                const { objectsInfo, datidInfo } = parseGroupGraphicFromSVG(svg, languageCode);

                const result = {
                    svg,
                    startDownloading: new Date(),
                    startParsingDate: new Date(),
                    availableDate: new Date(),
                    objectsInfo,
                    datidInfo
                };

                let foundIndexGr = availableAssemblyGroups.findIndex(
                    gr => gr.assemblyGroup.assemblyGroupId === assemblyGroupId
                );

                if (foundIndexGr === -1) {
                    availableAssemblyGroups.push({
                        ...result,
                        assemblyGroup: {
                            assemblyGroupId,
                            name: '-'
                        }
                    });
                } else {
                    availableAssemblyGroups[foundIndexGr] = { ...availableAssemblyGroups[foundIndexGr], ...result };
                }
            });

        const result: AvailableAssemblyGroupsStore = {
            locale: localesParams?.locale,
            vehicleType,
            manufacturer,
            mainType,
            startDownloading: new Date(),
            availableDate: new Date(),
            availableAssemblyGroups
        };

        console.log('grapa static parse time: ' + (new Date().getTime() - beginDate.getTime()));

        return result;
    },
    target: availableAssemblyGroupsModel.events.setAvailableAssemblyGroupsStore
});

export const updateFromStaticGrapaService = {
    stores: {},
    events: {},
    effects: {
        getFilesByDatECodeFx
    }
};
