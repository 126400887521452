// ElementsMenuList

import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { ColumnsType } from 'antd/lib/table';
import { fastTrackElementsMenuModel } from '../../stores/fastTrackElementsMenuModel';
import { FastTrackElement } from '@dat/api2dat/types/fastTrackInterfaceTypes';
import { getTranslationFromFastTrackConfiguration } from '../../utils/getTranslationFromFastTrackConfiguration';
import i18next from 'i18next';

const columnsftClaimDamages: ColumnsType<any> = [
    {
        title: 'fastTrack.element',
        dataIndex: 'elemLable'
        // align: 'center',
    },

    {
        title: 'fastTrack.damage',
        dataIndex: 'damageLable',
        align: 'center',
        // width: '11em'
        width: '30%'
    },

    {
        width: 50,
        title: 'Action',
        align: 'right',
        render: (_, record) => (
            // <CloseOutlined
            //     onClick={e => {
            //         fastTrackElementModel.events.delFastTrackDamage(record.elementId);
            //         e.stopPropagation();
            //     }}
            // />
            <Button
                // type={record.damageType ? 'primary' : 'ghost'}
                type={'primary'}
                disabled={!record.damageType}
                onClick={e => {
                    fastTrackElementModel.events.delFastTrackDamage(record.elementId);
                    e.stopPropagation();
                }}
                icon={<CloseOutlined />}
            ></Button>
        )
    }
];

export const ElementsMenuTable: FC = () => {
    const { t } = useTranslation();
    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    const fastTrackElementsMenuState = useStore(fastTrackElementsMenuModel.stores.$fastTrackElementsMenu);

    // const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    // if (!pluginOptions) return null;
    // const { settings } = pluginOptions;
    // const fastTrackElementsMenu = pluginOptions?.settings?.fastTrackElementsMenu;

    if (!fastTrackConfiguration) return null;

    const translationFromFastTrackConfiguration = getTranslationFromFastTrackConfiguration({
        fastTrackConfiguration,
        language: i18next.language
    });

    // const currentGroupFTMenu = fastTrackElementsMenu?.find(groupMenu=>);

    if (!fastTrackElementsMenuState) return null;

    const elementsWithDamages: FastTrackElement[] =
        fastTrackElementsMenuState?.elementDatIDs?.map(dATID => {
            const selectedElement = selectedElements.find(el => el.elementId === dATID);
            return { elementId: dATID, damageType: selectedElement?.damageType || '', userComment: '' };
        }) || [];

    const dataSource = elementsWithDamages.map(el => ({
        ...el,
        elemLable: t(`fastLocalNameDATID.${el.elementId}`),
        damageLable: t(
            `fastLocalNameDamages.${el.damageType}`,
            translationFromFastTrackConfiguration[el.damageType] || el.damageType
        )
    }));

    return (
        <Table
            rowKey="elementId"
            // className={styles.customElement}
            dataSource={dataSource}
            columns={columnsftClaimDamages}
            pagination={false}
            // size="small"
            // size="large"
            bordered
            showHeader={false}
            locale={{ emptyText: t('fastTrack.NoData') }}
            onRow={record => ({
                onClick: _ => {
                    // fastTrackElementModel.events.setMarkElementId(record.elementId);
                    fastTrackElementModel.events.setDamageIfOneElseMarkElementId(record.elementId);
                } // click row
                // onDoubleClick: event => {}, // double click row
                // onContextMenu: event => {}, // right button click row
                // onMouseEnter: event => {}, // mouse enter row
                // onMouseLeave: event => {} // mouse leave row
            })}
        />
    );
};
