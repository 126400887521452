import React, { FC } from 'react';
import { useStore } from 'effector-react';

import { Datepicker as DatepickerBase, DatepickerProps } from '@wedat/ui-kit/components/Datepicker';

import { i18nStores } from '@dat/shared-models/i18n';
import { stores } from './store';

export const Datepicker: FC<DatepickerProps> = props => {
    const currentLocale = useStore(i18nStores.locale);
    const dateFormat = useStore(stores.dateFormat);
    const inputMask = useStore(stores.inputMask);

    // format "any date, time"
    const dateFormatDefault = props.showTimeSelect ? dateFormat : dateFormat.split(',')[0];

    return (
        <DatepickerBase currentLocale={currentLocale} dateFormat={dateFormatDefault} inputMask={inputMask} {...props} />
    );
};
