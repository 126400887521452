import { HTMLAttributes } from 'react';
import { useTheme } from 'styled-components';
import { LogoImg, LogoDarkImg, LogoSilverImg, LogoSilverDarkImg, StyledLogoProps } from './styles';

interface Props extends StyledLogoProps, Pick<HTMLAttributes<SVGSVGElement>, 'className'> {
    silverLogo?: boolean;
}

const LOGO = {
    wedat: {
        light: LogoImg,
        dark: LogoDarkImg
    },
    silver: {
        light: LogoSilverImg,
        dark: LogoSilverDarkImg
    }
};

export const Logo: React.FC<Props> = ({ size, className, silverLogo }) => {
    const theme = useTheme();
    const dark = theme.name === 'dark';

    const logoType = silverLogo ? LOGO.silver : LOGO.wedat;
    const Component = (dark ? logoType.dark : logoType.light) as React.ElementType;

    return <Component size={size} className={className} />;
};
