import styled from 'styled-components/macro';
import { UploadFileIcon } from '@wedat/ui-kit/assets/Icon';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Checkbox/styles';

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    ${media.phone`
        flex-direction: row;
        width: 100%;
    `}
`;

export const UploadIconWrap = styled.div`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: ${({ theme }) => theme.colors.gray_10};
    border-radius: 50%;

    ${media.phone`
        margin-bottom: 0;
        width: 20px;
        height: 18px;
        background-color: transparent;
    `}
`;

export const UploadFileIconStyled = styled(UploadFileIcon)`
    color: ${({ theme }) => theme.colors.gray_40};
    transition: color 0.3s;
`;

export const Description = styled.p`
    margin: 0;
    max-width: 158px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray_40};

    ${media.phone`
        margin-left: 10px;
        max-width: 100%;
        color: ${({ theme }) => theme.colors.gray_300};
    `}
`;

export const Root = styled.div`
    width: 100%;
    height: 308px;
    border: ${({ theme }) => `2px dashed ${theme.colors.gray_40}`};
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        ${UploadFileIconStyled} {
            color: ${({ theme }) => theme.colors.blueDark};
        }
    }

    ${media.phone`
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        background-color: transparent;
        border: ${({ theme }) => `2px solid ${theme.colors.gray_10}`};
        border-radius: 4px;
    `}
`;

export const TextMobile = styled(Text)`
    display: none;

    ${media.phone`
        display: flex;
    `}
`;

export const TextDesktop = styled(Text)`
    display: flex;

    ${media.phone`
        display: none;
    `}
`;
