import { attach, combine, createEffect, createEvent, merge, restore, sample, forward } from 'effector';
// import { attach, combine, createEffect, createEvent, guard, merge, restore, sample } from 'effector-logger';
import { getExistingEquipmentN, getPossibleEquipmentN } from '@dat/api2dat/services/conversionFunctionsService';
import { GetEquipmentNParams } from '../../../api2dat/types/api2datTypes';
import { pluginOptionsModel } from './pluginOptionsModel';
import { requestCredentialsModel } from './requestCredentialsModel';
import { equipmentModel } from './equipmentModel';
import { graphicDamageSelectionModel } from './graphicDamageSelectionModel';
import { sparePartVariantsModel } from './sparePartVariantsModel';

const defaultLocale = {
    country: 'US',
    datCountryIndicator: 'DE',
    language: 'en'
};

const CONSTRUCTION_TIME_DEFAULT = 9999;

equipmentModel.stores.dvnEquipment.on(
    merge([sparePartVariantsModel.effects.getSparePartVariantsFx.doneData]),
    (prevData, addedData) => {
        if (!addedData) return;
        const data = {
            datProcessId: addedData.datProcessId,
            equipments: addedData.equipment
        };

        let newData = prevData.filter(item => addedData.datProcessId !== item.datProcessId);
        return newData.concat([data]);
    }
);

const setNonCheckedDvnEquipments = createEvent<number[]>();
const nonCheckedDvnEquipments = restore(setNonCheckedDvnEquipments, []);
const addNonCheckedDvnEquipments = createEvent<number[]>();
nonCheckedDvnEquipments.on(addNonCheckedDvnEquipments, (prevVal, addedVal) =>
    prevVal.concat(addedVal.filter(item => prevVal.indexOf(item) < 0))
);

// sample({
//     source: equipmentModel.stores.dvnEquipment,
//     clock: RepairPositionsModel.event.setRepairPositions,
//     fn: (dvnEquipment, repPositions) =>
//         repPositions
//             .filter(repPos => !dvnEquipment.find(equip => equip.datProcessId === repPos.DATProcessId))
//             .map(repPos => repPos.DATProcessId),
//     target: addNonCheckedDvnEquipments
// });

sample({
    source: equipmentModel.stores.dvnEquipment,
    clock: graphicDamageSelectionModel.stores.currentDVNs,
    fn: (dvnEquipment, dvns) => dvns?.filter(dvn => !dvnEquipment.find(equip => equip.datProcessId === dvn)) || [],
    target: sparePartVariantsModel.effects.getSparePartVariantsByDvnsFx
});

forward({
    from: addNonCheckedDvnEquipments,
    to: sparePartVariantsModel.effects.getSparePartVariantsByDvnsFx
});

const getExistingEquipmentNFx = createEffect(getExistingEquipmentN);
const getExistingEquipmentFx = attach({
    source: combine({
        credentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls,
        vehicle: pluginOptionsModel.stores.vehicleIdentification,
        localesParams: pluginOptionsModel.stores.localesParams
    }),
    mapParams: (_, { credentials, vehicle, localesParams, datServicesUrls }) => {
        const params: GetEquipmentNParams = {
            locale: { attr: localesParams?.locale || defaultLocale },
            datECode: vehicle?.DatECode || '',
            constructionTime: vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT,
            restriction: 'REPAIR'
        };
        return { credentials, params, url: datServicesUrls.urlConversionFunctionsService };
    },
    effect: getExistingEquipmentNFx
});

equipmentModel.stores.existingEquipment.reset(getExistingEquipmentNFx);
equipmentModel.stores.existingEquipment.on(getExistingEquipmentNFx.doneData, (_, newData) =>
    equipmentModel.events.setExistingEquipment(newData)
);

const getPossibleEquipmentNFx = createEffect(getPossibleEquipmentN);
const getPossibleEquipmentFx = attach({
    source: combine({
        credentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls,
        vehicle: pluginOptionsModel.stores.vehicleIdentification,
        localesParams: pluginOptionsModel.stores.localesParams
    }),
    mapParams: (_, { credentials, vehicle, localesParams, datServicesUrls }) => {
        const params: GetEquipmentNParams = {
            locale: { attr: localesParams?.locale || defaultLocale },
            datECode: vehicle?.DatECode || '',
            constructionTime: vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT,
            restriction: 'REPAIR'
        };
        return { credentials, params, url: datServicesUrls.urlConversionFunctionsService };
    },
    effect: getPossibleEquipmentNFx
});

equipmentModel.stores.possibleEquipment.reset(getPossibleEquipmentNFx);
equipmentModel.stores.possibleEquipment.on(getPossibleEquipmentNFx.doneData, (_, newData) =>
    equipmentModel.events.setPossibleEquipment(newData)
);

export const dvnEquipmentModel = {
    store: {
        nonCheckedDvnEquipments
    },
    event: {
        setNonCheckedDvnEquipments
    },
    effect: {
        getExistingEquipmentFx,
        getPossibleEquipmentFx
    }
};
