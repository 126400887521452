/*
 * Global customer-configuration which contains settings for different users, templates, claimBuilders, etc.
 * Stores for templates are located in separate folder `template`.
 */
import { combine, createStore, restore } from 'effector';
import { PartialDeep } from 'type-fest';

import { INITIAL_CUSTOMER_SETTINGS, INITIAL_USER_SETTINGS } from './constants';
import { makeRestoreable } from '@dat/core/utils/effector/makeRestoreable';
import { getParsedArraySafe } from '@dat/api2/utils';
import { filterArrayOfObjectsByExistingKeys } from '@dat/core/utils';

/*
 * Main customer configuration
 * Don't use this store directly - configuration can be invalid because it's fetched from server
 */
const customerConfiguration = createStore<PartialDeep<DAT2.CustomerConfiguration>>({});

/*
 * Settings stores are always preprocessed and valid
 */
const customerSettings = restore(makeRestoreable(INITIAL_CUSTOMER_SETTINGS));
const userSettings = restore(makeRestoreable(INITIAL_USER_SETTINGS));

const claimBuilders = combine(
    [customerConfiguration, userSettings.availableTemplateIds],
    ([customerConfiguration, availableTemplateIds]) => {
        const initialBuilders = getParsedArraySafe(customerConfiguration.claimBuilders);
        const validBuilders = filterArrayOfObjectsByExistingKeys(initialBuilders, ['name', 'templateId']);
        if (availableTemplateIds) {
            return validBuilders.filter(({ templateId }) => availableTemplateIds.includes(templateId));
        }

        return validBuilders;
    }
);

export const sharedConfigurationStores = {
    customerConfiguration,
    customerSettings, // object
    userSettings, // object
    claimBuilders
};
