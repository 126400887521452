import { FormBuilderPluginOptions } from '@dat/form-builder';
import { DataField, DataRow, FieldType } from '@dat/form-builder/src/types/dataScheme';
import { TFunction } from 'i18next';
import { Subject } from '../../../types/subjects';

export const parseToFormBuilderData = (
    fields: BFF.AddressBook.Field[],
    displayedSubject: Subject | undefined,
    t: TFunction
): FormBuilderPluginOptions['data'] => {
    const size = 1;
    const subarray = [];

    for (let i = 0; i < Math.ceil(fields.length / size); i++) {
        subarray[i] = {
            name: `row-${i + 1}`,
            fields: fields.slice(i * size, i * size + size)
        };
    }

    const rows: Array<DataRow> = subarray.map(item => {
        const fields: Array<DataField> = item.fields.map(item => ({
            ...item,
            Type: (item.type || 'string') as FieldType,
            label: t(`fieldsLabels.${item.name}`, item.name),
            initValue: displayedSubject?.[item.name] || '',
            id: item.name
        }));

        return {
            ...item,
            fields
        };
    });

    return [
        {
            formName: 'formBuilderData'
        },
        {
            groups: [
                {
                    groupName: '',
                    content: {
                        rows
                    }
                }
            ]
        },
        { conditions: [] }
    ];
};
