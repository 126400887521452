import { ManufacturerLacquerParameters } from '../../types/factors';

export const manufacturerLacquerInitialParameters: ManufacturerLacquerParameters = {
    type: '',
    addition: '',
    colorName: '',
    colorCode: '',
    color2ndName: '',
    color2ndCode: '',

    wageMode: 'PER_TIME',
    wage: '',
    wageFlatRate: '',
    discountWage: '',
    discountWageBiw: '',

    materialMode: 'LACQUER_METHOD',
    materialFlatRatePercent: '',
    materialFlatRateAbsolute: '',
    discountMaterial: '',
    discountMaterialBiw: '',

    wageInclMaterialMode: 'PER_TIME',
    wageInclMaterial: '',
    wageInclMaterialFlatRate: '',
    discountWageInclMaterial: '',
    discountWageInclMaterialBiw: '',

    preparationTimePercent: '',
    isLacquerAssemblyWhenRemoved: false,

    _attributes: {
        mode: {
            discountWage: 'PERCENT',
            discountWageBiw: 'PERCENT',
            discountMaterial: 'PERCENT',
            discountMaterialBiw: 'PERCENT',
            discountWageInclMaterial: 'PERCENT',
            discountWageInclMaterialBiw: 'PERCENT'
        }
    }
};
