import { restore, createDomain } from 'effector';

import { PluginOptions } from '../../types/plugin';

export const domain = createDomain();
const resetPlugin = domain.createEvent();

const initPlugin = domain.createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

export const pluginEvents = {
    initPlugin,
    resetPlugin
};
export const pluginStores = {
    pluginOptions
};
