import { FC, useCallback, useContext, useMemo, useState } from 'react';

// Components
import { CommentPhoto } from './CommentPhoto';
import { Photo } from './Photo';
import { HelpModal } from './HelpModal';
import { ValidationModal } from './ValidationModal';

import { CarType, FormStep, PluginProps, ValidationContent } from '../../types';
import { FormContext, actions } from '../../reducers/form';

// Utils
import { turnOffUserMedia } from '../../utils/mediaDevices';
import { padNum } from '../../utils/padNum';

// Hooks
import { useCompleteFormCallback } from '../../hooks/plugin';
import { useGeolocation } from '../../hooks/geolocation';
import { useVideoAiValidation } from '../../hooks/useVideoAiValidation';

// Style
import { Spinner } from '../Spinner';
import { PhotoSteps, StepsStyled, Strong } from './styles';
import { Layout } from '../Layout';

const { changeStep, setResultFormStep } = actions;

interface Props {
    steps: PluginProps['steps'];
    completeFormCallback: PluginProps['completeFormCallback'];
    withSummary?: boolean;
    carType: CarType;
    debug?: boolean;
    realTime?: boolean;
    validationContent: ValidationContent;
}

// help modal
enum Steps {
    ShowModal,
    MakePhoto,
    CommentPhoto,
    ValidationModal
}

/**
 * Component for all steps which include photo
 */
export const PhotoStepsPage: FC<Props> = ({
    steps,
    completeFormCallback,
    carType,
    realTime = true,
    debug = false,
    withSummary = true,
    validationContent
}) => {
    const [step, setStep] = useState(0);
    const [photo, setPhoto] = useState('');
    const [loading] = useState(false);
    const [comment, setComment] = useState('');
    const [previousPhoto, setPreviousPhoto] = useState(''); // need if retake photo
    const {
        state: { resultForm, videoStream },
        dispatch
    } = useContext(FormContext);
    const { validate, valid, setIsValid, clearValidationState } = useVideoAiValidation();
    const position = useGeolocation(step);

    // TODO: comment because someone can change one's mind
    // const isShowComment = steps[step]?.isShowComment;
    const helpSteps = steps[step]?.helpSteps || [];

    const isShowHelpModal = helpSteps.length > 0;
    const withRetake = steps[step].withRetake === undefined ? true : steps[step].withRetake;
    const currentStep = steps[step];
    const nextStep = steps[step + 1];
    const isLastStep = step === steps.length - 1;
    const completeCallback = useCompleteFormCallback(completeFormCallback);
    const [photoState, setPhotoState] = useState<Steps>(isShowHelpModal ? Steps.ShowModal : Steps.MakePhoto);

    const handleNextStep = useCallback(
        (formStep: FormStep) => {
            // reset comment
            setComment('');

            if (isLastStep && !withSummary) {
                turnOffUserMedia(videoStream);
                // merge form result cuz dispatch doesn't update data in hook
                return completeCallback({
                    ...resultForm,
                    steps: [...resultForm.steps, formStep]
                });
            }

            // TODO: check last step, if blocks were changed
            if (isLastStep) {
                // move to summary
                return dispatch(changeStep());
            } else {
                setStep(step => ++step);
            }

            if (!!nextStep?.helpSteps && nextStep.helpSteps.length > 0) {
                setPhotoState(Steps.ShowModal);
            } else {
                setPhotoState(Steps.MakePhoto);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [completeCallback, isLastStep, withSummary, nextStep, resultForm, videoStream]
    );

    const handleRetake = useCallback(() => {
        setPhotoState(Steps.MakePhoto);
        setPhoto(previousPhoto);
    }, [previousPhoto]);

    const handleSave = useCallback(
        async (comment = '', newPhoto?: string) => {
            const date = new Date();

            const formStep = {
                comment,
                photo: newPhoto || photo,
                photoType: currentStep.photoType || '',
                geolocation: {
                    date: date.toLocaleDateString(),
                    time: date.toLocaleTimeString(),
                    latitude: position?.coords.latitude,
                    longitude: position?.coords.longitude
                }
            };

            dispatch(setResultFormStep(formStep));

            handleNextStep(formStep);
            setIsValid(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleNextStep, photo, position]
    );

    const handleBatchPhoto = useCallback(
        async (batch: string[]) => {
            if (currentStep.acceptableCriteria) {
                await validate(batch, currentStep.acceptableCriteria, carType);
            }
        },
        [carType, validate, currentStep.acceptableCriteria]
    );

    const handlePhoto = useCallback(
        async (newPhoto: string, comment?: string) => {
            if (withRetake) {
                setPhoto(newPhoto);
                setPreviousPhoto(photo);
                // TODO: change this if we don't need comment forever
                setComment(comment || '');
                setPhotoState(Steps.CommentPhoto);
            } else {
                // send photo to save form
                handleSave(comment, newPhoto);
            }
            clearValidationState();
        },
        [photo, withRetake, handleSave, clearValidationState]
    );

    const maskImg = useMemo(() => {
        const { mask } = currentStep;
        if (!mask?.img_g || !mask?.img_r) {
            return mask?.img;
        }

        if (valid) {
            return mask.img_g;
        }

        return mask.img_r;
    }, [valid, currentStep]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <PhotoSteps>
            <StepsStyled blue={photoState === Steps.ShowModal}>
                <Strong>{padNum(step + 1)}</Strong> / {padNum(steps.length)}
            </StepsStyled>

            <Layout forceLandscape={currentStep?.forceLandscape}>
                {photoState === Steps.ShowModal ? (
                    <HelpModal
                        helpSteps={currentStep.helpSteps}
                        onLastHelpClick={() => setPhotoState(Steps.MakePhoto)}
                    />
                ) : photoState === Steps.MakePhoto ? (
                    <Photo
                        comment={comment}
                        maskDescription={currentStep?.mask?.description}
                        embedGeo={currentStep?.embedGeo}
                        maskImg={maskImg}
                        realTime={realTime}
                        valid={valid}
                        debug={debug}
                        onBatchPhoto={handleBatchPhoto}
                        // showComment={isShowComment}
                        onPhoto={handlePhoto}
                    />
                ) : photoState === Steps.ValidationModal ? (
                    <ValidationModal
                        onRetakeClick={handleRetake}
                        side={currentStep.acceptableCriteria || ''}
                        content={validationContent}
                    />
                ) : (
                    <CommentPhoto
                        comment={comment}
                        img={photo}
                        // showComment={isShowComment}
                        slider={{
                            current: currentStep?.preview?.img,
                            currentTitle: currentStep?.preview?.title,
                            currentCount: step,
                            after: nextStep?.preview?.img,
                            before: photo,
                            count: steps.length
                        }}
                        onRetake={handleRetake}
                        onSave={handleSave}
                    />
                )}
            </Layout>
        </PhotoSteps>
    );
};
