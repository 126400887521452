import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { invoiceRatesEvents, invoiceRatesStores } from '../../stores/selects/invoiceRates';

import { HeaderStyled, SelectWrap, FormStyled, SelectContainer, TitleContainer, IconWrapper } from './styles';
import { SelectField } from '@wedat/ui-kit/Formik';

import { CloseIcon } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    closeLabourRatesModal?: () => void;
}

export const Header: React.FC<Props> = ({ closeLabourRatesModal }) => {
    const { t } = useTranslation();
    const invoiceRatesSelectOptions = useStore(invoiceRatesStores.selectOptions);

    return (
        <HeaderStyled>
            <FormStyled>
                <TitleContainer>
                    <Text font="mobileHeader">{t('common.labourRates')}</Text>
                    <IconWrapper onClick={closeLabourRatesModal}>
                        <CloseIcon />
                    </IconWrapper>
                </TitleContainer>

                <SelectContainer>
                    <SelectWrap>
                        <SelectField
                            name="CalculationFactor.referenceSetName"
                            options={invoiceRatesSelectOptions}
                            changeCallback={invoiceRatesEvents.invoiceRateNameSelected}
                            label={t('baseInformation.labelSelect')}
                        />
                    </SelectWrap>
                </SelectContainer>
            </FormStyled>
        </HeaderStyled>
    );
};
