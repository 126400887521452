import React, { FC } from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import _ from 'lodash';

import { DatepickerProps } from '../../components/Datepicker';
import { Datepicker } from '@dat/smart-components/Datepicker';

import { FieldEventCallback } from '../types';
import { DatepickerParsedValue, DatepickerUnparsedValue } from '../../components/Datepicker/types';
import { DataField } from '@dat/form-builder/src/types/dataScheme';

interface ConfigField {
    configField?: DataField;
}

export type DatepickerFieldProps = Omit<DatepickerProps, 'value' | 'onChange'> & {
    name: string;
    onEditEnd?: FieldEventCallback<any, FieldInputProps<Date | undefined> & ConfigField, Date | undefined>;
    configField?: DataField;
};

export const DatepickerField: FC<DatepickerFieldProps> = ({ name, onEditEnd, configField, ...rest }) => {
    const formikContext = useFormikContext();
    const [field, , fieldHelpers] = useField<DatepickerUnparsedValue>(name);

    const handleChangeAfterLeave = (value: DatepickerParsedValue) => {
        fieldHelpers.setValue(value);
        onEditEnd?.(value, formikContext, { ...field, value, configField });
    };
    return (
        <Datepicker
            {...field}
            {...rest}
            showTimeSelect={configField?.format === 'timePicker'}
            onChange={_.noop}
            onChangeAfterLeave={handleChangeAfterLeave}
        />
    );
};
