import { hideBlocksStores } from './index';
import { sharedTemplateStores } from '@dat/shared-models/template';

const { productsConfiguration } = sharedTemplateStores;
const { hideBlocks } = hideBlocksStores;

hideBlocks.on(
    productsConfiguration,
    (_, productsConfiguration) => productsConfiguration?.['claim-management']?.hideBlocks
);
