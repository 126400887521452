import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { contractStores } from '@dat/shared-models/contract';
import { positionsEffects } from '../../stores/positions';
import { PositionsFormValues } from '../../types/positions';

import { SaveIcon } from '@wedat/ui-kit/components/Icons';
import { ButtonIconStyled, Container, IconWrapper, TextStyled } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
    isMobile?: boolean;
}

export const ActionButtons: FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();
    const { values, initialValues, dirty } = useFormikContext<PositionsFormValues>();
    const contractId = useStore(contractStores.contractId);

    const savePositions = async () => {
        if (dirty) {
            await positionsEffects.savePositionsFx({
                positions: values.positions,
                labourItems: values.labourItems,
                paintworkItems: values.paintworkItems,
                totalSumsItems: values.totalSumsItems,
                initialFormPositions: initialValues.positions,
                contractId
            });
        }
    };

    return (
        <Container>
            <ButtonIconStyled disabled={!dirty} onClick={savePositions}>
                <IconWrapper>
                    <SaveIcon />
                </IconWrapper>
                {!isMobile && <TextStyled>{t('common.save')}</TextStyled>}
            </ButtonIconStyled>
        </Container>
    );
};
