import { useDebug } from '../../hooks/useDebug';
import { LogsBlock, LogText } from './style';

export const Log = () => {
    const { log } = useDebug();
    return (
        <LogsBlock>
            {log.map(i => (
                <LogText>{i}</LogText>
            ))}
        </LogsBlock>
    );
};
