import axios from '../axios';
import { DatProductsUrls } from '@dat/core/constants/enums';

export const listContracts = (values: DAT.GetMyClaimListContractsRequest) =>
    axios<DAT.GetMyClaimListContractsResponse>({
        product: DatProductsUrls.MyClaim,
        method: 'listContracts',
        values
    });

export const listAttachmentsOfContract = (values: DAT.GetListAttachmentsOfContractRequest) =>
    axios<DAT.GetListAttachmentsOfContractResponse>({
        product: DatProductsUrls.MyClaim,
        method: 'listAttachmentsOfContract',
        values
    });

export const getPossibleNetworkStatuses = (values: DAT.GetPossibleNetworkStatusesRequest) =>
    axios<DAT.GetPossibleNetworkStatusesResponse>({
        product: DatProductsUrls.MyClaim,
        method: 'getPossibleNetworkStatuses',
        values
    });

export const getContract = (values: DAT2.Request.GetContract) =>
    axios<DAT2.Response.GetContract>({
        product: DatProductsUrls.MyClaim,
        method: 'getContract',
        values
    });
