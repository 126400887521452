import { PayloadForChangeAttachmentStatus, ParsedAttachmentItem } from '../../types/attachments';

export const changeAttachmentStatusReducer = (
    currentAttachments: ParsedAttachmentItem[],
    { name, status }: PayloadForChangeAttachmentStatus
) => {
    const attachmentIndex = currentAttachments.findIndex(({ fileName }) => fileName === name);

    if (attachmentIndex !== -1) {
        const newAttachments = [...currentAttachments];

        newAttachments[attachmentIndex] = {
            ...currentAttachments[attachmentIndex],
            status
        };

        return newAttachments;
    }
};
