import { createStore, createEffect, guard, createEvent } from 'effector';
// import { createStore, createEffect, guard, createEvent, forward } from 'effector-logger/macro';

import { getUrlBlobByUrl } from '@dat/grapa/src/utils/getByUrl';

export interface GenericSVGGraphic {
    graphicId: string;
    label?: string;
    sourceUrl: string;
    graphicSVG?: string;
}

const genericSVGGraphics = createStore<GenericSVGGraphic[]>([]);

const update = createEvent<GenericSVGGraphic>();
genericSVGGraphics.on(update, (state, graphic) => {
    const newState = [...state];
    const findIndexValue = newState.findIndex(val => val.graphicId === graphic.graphicId);
    if (findIndexValue === -1) {
        newState.push(graphic);
    } else {
        newState[findIndexValue] = graphic;
    }
    return newState;
});

const initGenericGraphicFx = createEffect({
    async handler(genericSVGGraphics: GenericSVGGraphic[] | undefined) {
        if (!genericSVGGraphics) return;
        const groupPromises = genericSVGGraphics.map(async genericSVGGraphic => {
            const newVal = { ...genericSVGGraphic };
            newVal.graphicSVG = await getUrlBlobByUrl(genericSVGGraphic.sourceUrl);
            update(newVal);
        });
        await Promise.all(groupPromises);
    }
});

const setGenericGraphicFx = createEffect({
    async handler(genericSVGGraphics: GenericSVGGraphic[] | null) {
        if (!genericSVGGraphics) return;
        const groupPromises = genericSVGGraphics.map(async genericSVGGraphic => {
            const newVal = { ...genericSVGGraphic };
            newVal.graphicSVG = await getUrlBlobByUrl(genericSVGGraphic.sourceUrl);
            update(newVal);
        });
        await Promise.all(groupPromises);
    }
});

const initGenericGraphicFirst = createEvent<GenericSVGGraphic[] | undefined>();
guard({
    source: initGenericGraphicFirst,
    filter: initGenericGraphicFx.pending.map(pending => !pending),
    target: initGenericGraphicFx
});

export const genericSVGGraphicModel = {
    stores: {
        genericSVGGraphics
    },
    events: {
        initGenericGraphicFirst,
        update
    },
    effects: {
        initGenericGraphicFx,
        setGenericGraphicFx
    }
};
