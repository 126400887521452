import deepmerge from 'deepmerge';
import { format } from 'date-fns';

import { parseVehicleSelectionPluginResult } from './parseVehicleSelectionPluginResult';
import { createLabourRatesMemoFieldEntry } from './createLabourRatesMemoFieldEntry';
import { parseEquipmentPluginResult } from './parseEquipmentPluginResult';
import { parseFormBuilderField } from './parseFormBuilderField';

import { PayloadForUpdateCurrentContract } from '@dat/shared-models/contract/types';
import { VehicleSelectionModalPluginResult } from '@dat/vehicle-selection-modal';
import { EquipmentPluginResult } from '@dat/equipment';
import { PartialFactorsParametersObject } from '@dat/labour-rates/src/types/factors';
// import { GrapaPluginResult } from '@dat/grapa';
import { parseFormValuesToArray } from './parseFormValuesToArray';
import { ResultGrapaDossier } from '@dat/grapa/src/types/plugin';
import { FormikValuationValues } from '../components/ModalValuation/constants';
import { ValuatePluginResult } from '../pages/Claim/Valuation/types';
import { ParsedVXSVehicleObject } from '@dat/core/types';
import { parseFuelValue } from './parseFuelValue';

interface Param {
    vehicleSelectionPluginResult?: VehicleSelectionModalPluginResult | null;
    equipmentPluginResult?: EquipmentPluginResult | null;
    formBuilderField?: Parameters<typeof parseFormBuilderField>[0] | null;
    grapaPluginResult?: ResultGrapaDossier | null;
    labourRatesFactorsParameters?: PartialFactorsParametersObject;
    valuatePluginResult?: ValuatePluginResult;
    formikMailValues?: DAT2.Internal.Request.LandingMail;
    formikValuationValues?: FormikValuationValues;
    vinResult?: ParsedVXSVehicleObject | null;
    fuelValue?: string | null;
}

export const createPayloadForCreateOrUpdateContract = ({
    vehicleSelectionPluginResult,
    equipmentPluginResult,
    formBuilderField,
    grapaPluginResult,
    labourRatesFactorsParameters,
    formikMailValues,
    formikValuationValues,
    valuatePluginResult,
    fuelValue
}: Param): PayloadForUpdateCurrentContract => {
    let result: PayloadForUpdateCurrentContract = {};

    if (fuelValue) {
        result = deepmerge(result, {
            templateData: {
                entry: parseFuelValue(fuelValue)
            },
            Dossier: {
                Vehicle: {
                    VehicleDataItaly: {}
                }
            }
        });
    }

    if (vehicleSelectionPluginResult) {
        result = deepmerge(result, parseVehicleSelectionPluginResult(vehicleSelectionPluginResult));
        result = deepmerge(result, {
            templateData: {
                entry: [createLabourRatesMemoFieldEntry({ vehicleSelectionPluginResult, labourRatesFactorsParameters })]
            },
            Dossier: {
                Vehicle: {
                    VINResult: vehicleSelectionPluginResult.vinResult,
                    TechInfo: vehicleSelectionPluginResult.technicalData
                }
            }
        });
    }

    if (equipmentPluginResult) {
        result = deepmerge(result, {
            Dossier: {
                Vehicle: {
                    Equipment: parseEquipmentPluginResult(equipmentPluginResult)
                }
            }
        });
    }

    if (formBuilderField) {
        result = deepmerge(result, {
            templateData: {
                entry: parseFormBuilderField(formBuilderField)
            },
            Dossier: {
                Vehicle: {
                    VehicleDataItaly: {}
                }
            }
        });
    }

    if (formikMailValues) {
        result = deepmerge(result, {
            templateData: {
                entry: parseFormValuesToArray(formikMailValues)
            },
            Dossier: {
                Vehicle: {
                    VehicleDataItaly: {}
                }
            }
        });
    }
    if (formikValuationValues) {
        result = deepmerge(result, {
            templateData: {
                entry: parseFormValuesToArray(formikValuationValues)
            },
            Dossier: {
                Vehicle: {
                    Container: formikValuationValues.vehicleContainer,
                    MileageOdometer: formikValuationValues.kilometers,
                    InitialRegistration:
                        formikValuationValues.firstRegistration &&
                        format(formikValuationValues.firstRegistration, 'yyyy-MM-dd')
                }
            }
        });
    }

    if (grapaPluginResult) {
        result = deepmerge(result, {
            Dossier: {
                Vehicle: {
                    Equipment: {
                        DeselectedSeriesEquipment:
                            grapaPluginResult.Vehicle?.Equipment?.DeselectedSeriesEquipment ||
                            result.Dossier?.Vehicle?.Equipment?.DeselectedSeriesEquipment,
                        SpecialEquipment:
                            grapaPluginResult.Vehicle?.Equipment?.SpecialEquipment ||
                            result.Dossier?.Vehicle?.Equipment?.SpecialEquipment,
                        SeriesEquipment:
                            grapaPluginResult.Vehicle?.Equipment?.SeriesEquipment ||
                            result.Dossier?.Vehicle?.Equipment?.SeriesEquipment
                    }
                },
                RepairOrder: {
                    DATProcessIdCommentList: grapaPluginResult?.RepairOrder?.DATProcessIdCommentList
                },
                RepairCalculation: {
                    RepairPositions: {
                        RepairPosition:
                            grapaPluginResult.RepairCalculation?.RepairPositions?.RepairPosition ||
                            result.Dossier?.RepairCalculation?.RepairPositions?.RepairPosition
                    }
                }
            }
        });
    }

    if (valuatePluginResult) {
        result = deepmerge(result, {
            templateData: {
                entry: parseFormValuesToArray(valuatePluginResult)
            }
        });
    }

    return result;
};
