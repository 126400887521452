import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

export const FilterInput = styled.input`
    width: 400px;
    border: 2px solid #f1f2f4;
    border-radius: 5px;
    font-size: 15px;
    padding: 8px;

    ${media.phone`
        background-color: #fff;
        border: none;
        border-radius: 2px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        padding: 12px 15px;
    `}
`;

export const InputContainer = styled.div`
    margin: 20px 0;
`;
