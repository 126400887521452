/*
 * Returns SVG name prefix for the photo widget mask image, corresponding to the vehicle type.
 */
export const getKindOfSVG = (datECode: string, vehicleType: string, doors: number) => {
    // Identifiers for 2-doors vehicles
    const twoDoorsVehicleIdentifiers = [
        'Cbr2',
        'Cpe/Cbr2',
        'Cpe2',
        'Htp2',
        'Road2',
        'Rod/Cpe2',
        'Spi2',
        'Tg2',
        'Berl2v3'
    ];
    const doorsNumber = [3, 4, 5].includes(doors) ? doors : 5;
    let kindOfSVG = doorsNumber + '_doors';

    if (datECode.includes('01730048') || datECode.includes('01730049')) {
        // DAT Europa Code for some model of the Range Rover
        kindOfSVG = 'RRover';
    } else if (datECode.includes('01570190') || datECode.includes('01570188')) {
        // DAT Europa Code for some model of the Mercedes-Benz electric SUV
        kindOfSVG = 'EQA';
    } else if (datECode.includes('01905152')) {
        // DAT Europa Code for some model of the Volkswagen electric SUV
        kindOfSVG = 'ID4';
    } else if (
        // SUV identifiers
        vehicleType.toUpperCase().includes('SUV') ||
        vehicleType.toUpperCase().includes('SAV') ||
        vehicleType.toUpperCase().includes('MV')
    ) {
        kindOfSVG = 'SUV';
    } else if (vehicleType.toUpperCase().includes('SW')) {
        // SW identifier
        kindOfSVG = 'SW';
    } else if (twoDoorsVehicleIdentifiers.includes(vehicleType)) {
        // If the 2-doors vehicle was identified, set SVG name prefix for 3-doors, because there is no SVG for 2-doors vehicle yet
        kindOfSVG = '3_doors';
    } else if (vehicleType === 'Berl2v3' && doorsNumber === 5) {
        // 5-doors vehicle identifier
        kindOfSVG = '5_doors';
    }

    return kindOfSVG;
};
