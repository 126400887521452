import styled from 'styled-components/macro';

import { attachmentIconStyle, AttachmentItemContainer } from '@wedat/ui-kit/components/Attachment/styles';
import { PlusIcon } from '@wedat/ui-kit/components/Icons';

export const UploadIcon = styled(PlusIcon)`
    ${attachmentIconStyle}
`;

export const UploadIconContainer = styled.div`
    ${attachmentIconStyle};

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    ${UploadIcon} {
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const UploadButtonContainer = styled(AttachmentItemContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    cursor: pointer;

    &:hover {
        ${UploadIconContainer} {
            ${UploadIcon} {
                color: ${({ theme }) => theme.colors.blue['500']};
            }
        }

        border-color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const BottomText = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    opacity: 0.25;
    pointer-events: none;
`;
