import { useState } from 'react';
import { useStore, useStoreMap } from 'effector-react';

import { sharedRepairCalculationStores } from '@dat/shared-models/contract/Dossier/RepairCalculation';
import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';

import { NumberFormat, SlideUpMenu, WalletIcon } from '@wedat/ui-kit';
import { Dropdown } from '@wedat/ui-kit/components/Dropdown';
import { Text } from '@wedat/ui-kit/components/Text';
import { sizes } from '@wedat/ui-kit/mediaQueries';

import { useMedia } from '@dat/core/hooks/useMedia';

import { formatTotalPriceValues } from './utils/formatTotalPriceValues';
import { provideTotalPriceOptions } from './utils/provideTotalPriceOptions';

import { ButtonStyled, ButtonWrapper, Container, IconWrapper, Item } from './styles';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { commify } from '@wedat/ui-kit/utils/commify';
import { useTranslation } from 'react-i18next';
import { contractStores } from '@dat/shared-models/contract';
import { currencyValues } from '@dat/core/constants/currency';
import { getParsedArraySafe } from '@dat/api2/utils';

export const TotalPrice = () => {
    const [isListVisible, setIsListVisible] = useState(false);

    const options = useStore(sharedRepairCalculationStores.calculationSummary);

    const contract = useStore(contractStores.contract);

    const currency = contract?.Dossier?.Currency || '';
    const currentCurrency = currencyValues[currency] || currency;

    const prices = provideTotalPriceOptions(options);
    const mainPrice = (options?.TotalNetCorrected ?? options?.TotalNetCosts) || 0;
    const formattedPrice = `${commify(mainPrice?.toFixed(2))} ${currentCurrency}`;

    // vro_domus_calculation contract (Italian) doesn't contain LacquerCosts in common RepairCalculation.CalculationSummary,
    // so in this case it's necessary to take this info from RepairCalculation.CalcResultItaly (same as for Italian Calc.)
    const totalSumsItems = useStoreMap(sharedCalcResultItalyStores.summariesItaly, summaries =>
        getParsedArraySafe(summaries?.TotalSummaryItaly?.TotalSummaryItemItaly)
    );
    const lacquerItaly = totalSumsItems.find(item => item.Identifier === 'lacquerMaterial')?.ValueNet;
    if (lacquerItaly && !prices?.find(pr => pr.key === 'LacquerCosts')) {
        prices?.push({
            key: 'LacquerCosts',
            value: lacquerItaly
        });
    }

    const isLaptop = useMedia(sizes.laptop);

    const theme = useTheme();

    const { t } = useTranslation();

    return (
        <>
            <Container>
                <ButtonWrapper
                    onClick={() => {
                        setIsListVisible(!isListVisible);
                    }}
                    onMouseEnter={!isLaptop ? () => setIsListVisible(true) : _.noop}
                    onMouseLeave={() => {
                        setIsListVisible(false);
                    }}
                >
                    <ButtonStyled>
                        <IconWrapper>
                            <WalletIcon />
                        </IconWrapper>
                        <Text
                            fontSize={isLaptop ? '14px' : '16px'}
                            fontWeight={700}
                            color={isLaptop ? theme.colors.black : theme.colors.blue['700']}
                        >
                            {formattedPrice}
                        </Text>
                    </ButtonStyled>
                    {!isLaptop && (
                        <Dropdown
                            onMouseEnter={() => {
                                setIsListVisible(true);
                            }}
                            side="left"
                            isOpen={isListVisible}
                        >
                            {prices?.map(option => (
                                <Item key={option.key}>
                                    <Text fontSize="14px">
                                        <NumberFormat value={!option.value ? 0 : option.value || 0} /> €
                                    </Text>
                                    <Text fontSize="14px" color={theme.colors.dustBlue['600']}>
                                        {t(`claim-management:totals.${option.key}`)}
                                    </Text>
                                </Item>
                            ))}
                        </Dropdown>
                    )}
                </ButtonWrapper>
            </Container>

            {isLaptop && (
                <SlideUpMenu
                    isOpen={isListVisible}
                    closeSlideUpMenu={() => {
                        setIsListVisible(false);
                    }}
                >
                    <Item>
                        <Text fontSize="16px" fontWeight={700} color={theme.colors.black}>
                            Total
                        </Text>
                        <Text fontSize="16px" fontWeight={700} color={theme.colors.black}>
                            {formattedPrice}
                        </Text>
                    </Item>

                    {prices?.map(option => (
                        <Item key={option.key}>
                            <Text fontSize="14px" color={theme.colors.dustBlue['600']}>
                                {formatTotalPriceValues(option.key)}
                            </Text>
                            <Text fontSize="14px" fontWeight={700} color={theme.colors.black}>
                                {!option.value ? 0 : option.value || 0} €
                            </Text>
                        </Item>
                    ))}
                </SlideUpMenu>
            )}
        </>
    );
};
