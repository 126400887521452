import React from 'react';
import { Formik } from 'formik';
import { useStore } from 'effector-react';

import { getWorkBenchValues } from '../../utils/getWorkBenchValues';
import { INITIAL_WORKBENCH_VALUES } from './constants';

import { Content } from './Content';
import { Container } from './styles';
import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';

export const WorkBench = () => {
    const positionsItaly = useStore(sharedCalcResultItalyStores.positionsItaly);

    const valuesFromPositions = getWorkBenchValues(positionsItaly);

    const initialValues = Object.keys(valuesFromPositions).length ? valuesFromPositions : INITIAL_WORKBENCH_VALUES;

    return (
        <Container>
            <Formik initialValues={initialValues} onSubmit={() => {}}>
                <Content />
            </Formik>
        </Container>
    );
};
