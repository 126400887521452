import _ from 'lodash';

// Parent.FieldName -> Parent.DatFieldName
export const addDatPrefixToFieldName = (path: string) => {
    const pathArray = path.split('.');
    const pathWithoutPropertyName = pathArray.slice(0, -1).join('.');
    const propertyName = pathArray[pathArray.length - 1];
    const propertyNameWithDatPrefix = 'Dat' + _.upperFirst(propertyName);

    return `${pathWithoutPropertyName}.${propertyNameWithDatPrefix}`;
};
