export const stack = <T>(size = 3) => {
    let currStack: T[] = [];

    const push = (value: T) => {
        if (currStack.length >= size) {
            currStack.shift();
        }
        currStack.push(value);
    };

    const flush = () => {
        currStack = [];
    };

    return {
        push,
        flush,
        currStack
    };
};
