import React, { FC, useEffect, useMemo, useState } from 'react';
import { RowContent } from '../RowContent';
import { conditionRulesStore } from '../../stores/conditions';
import { GroupNameStyled, GroupStyled, MemoFieldsWrapper, Content, FieldContentStyled } from './styles';
import { useStore } from 'effector-react';
import { Condition } from '../../utils/conditions/Condition';
import { fieldsValuesStore } from '../../stores/fieldsValues';
import { pathOr } from 'ramda';
import { useGetConditionValues, useGetConditionRules } from '../../hooks/';
import { DataGroup, DataCondition } from '../../types/dataScheme';

interface Props {
    group: DataGroup;
    formName: string;
    horizontalFields?: boolean;
    horizontalFieldsFullWidth?: boolean;
}

export const GroupContent: FC<Props> = ({ group, formName, horizontalFields, horizontalFieldsFullWidth }) => {
    const {
        content: { rows },
        groupName,
        condition
    } = group;
    const conditionRules = useStore(conditionRulesStore);

    const fieldsValues = useStore(fieldsValuesStore);
    const [currentCondition, setCondition] = useState<DataCondition | null>(null);
    const [isConditionTrue, setIsConditionTrue] = useState<boolean | undefined>(false);

    const getRules = useGetConditionRules();
    const currentConditionName = condition?.namedCondition;

    const getKeyCondition = useMemo(() => {
        if (currentConditionName) {
            return {
                [String(currentConditionName)]: condition?.applyConditionFor
            };
        } else return undefined;
    }, [condition, currentConditionName]);
    const { getFieldKey, getFieldValue, getOption } = useGetConditionValues(formName);

    // set condition rules for current group
    useEffect(() => {
        if (conditionRules) {
            // @ts-ignore
            const currentConditionRules = getRules(formName, currentConditionName);
            setCondition(currentConditionRules);
        }
    }, [getRules, formName, currentConditionName, conditionRules]);

    // check if condition should apply for group
    useEffect(() => {
        if (currentCondition) {
            const name = currentConditionName;
            const rules = pathOr([], ['condition', 0])(currentCondition);
            const tryApplyCondition = new Condition(
                { namedCondition: name, condition: rules },
                { value: getFieldValue, key: getFieldKey, option: getOption }
            );
            setIsConditionTrue(tryApplyCondition.compare());
        } else {
            setIsConditionTrue(undefined);
        }
    }, [fieldsValues, currentCondition, currentConditionName, getFieldValue, getFieldKey, getOption]);

    const rowsWithoutMemo = rows.map(item => ({
        name: item.name,
        fields: item.fields.filter(item => item.Type !== 'memo' && item.Type !== 'googlePlaces')
    }));

    const memoFields = rows.map(item => ({
        name: item.name,
        fields: item.fields.filter(item => item.Type === 'memo' || item.Type === 'googlePlaces')
    }));

    return (
        <>
            <GroupNameStyled>{groupName}</GroupNameStyled>

            <GroupStyled>
                {!!rowsWithoutMemo &&
                    (horizontalFields ? (
                        <Content horizontalFieldsFullWidth={horizontalFieldsFullWidth}>
                            {rowsWithoutMemo.map((row, idx) => (
                                <React.Fragment key={`${row.name}_${idx}`}>
                                    {row.fields.map(field => (
                                        <FieldContentStyled
                                            key={field.id}
                                            fieldId={field.id}
                                            formName={formName}
                                            isConditionTrue={isConditionTrue || undefined}
                                            keyCondition={getKeyCondition || null}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </Content>
                    ) : (
                        rowsWithoutMemo.map((row, idx) => (
                            <RowContent
                                key={`${row.name}_${idx}`}
                                formName={formName}
                                isConditionTrue={isConditionTrue || undefined}
                                keyCondition={getKeyCondition || null}
                                row={row}
                            />
                        ))
                    ))}
            </GroupStyled>
            <MemoFieldsWrapper>
                {!!memoFields &&
                    memoFields.map((item, idx) => (
                        <RowContent
                            key={`${item.name}_${idx}`}
                            formName={formName}
                            isConditionTrue={isConditionTrue || undefined}
                            keyCondition={getKeyCondition || null}
                            row={item}
                        />
                    ))}
            </MemoFieldsWrapper>
        </>
    );
};
