import React from 'react';

import { RadioGroupStyled, RadioStyled, RadioGroupStylesProps } from './styles';
import { Radio, RadioProps, RadioValue } from '../Radio';

import { RadioGroupItem } from './types';

export type RadioGroupProps = Pick<RadioProps, 'name' | 'value' | 'onChange' | 'disabled' | 'valueType'> &
    RadioGroupStylesProps & {
        items: Array<RadioGroupItem<RadioValue>>;
    };

export const RadioGroup: React.FC<RadioGroupProps> = ({
    name,
    value,
    items,
    onChange,
    disabled,
    valueType,
    column
}) => (
    <RadioGroupStyled column={column}>
        {items.map(item => (
            <RadioStyled column={column} key={String(item.value)}>
                <Radio
                    disabled={disabled || item.disabled}
                    value={item.value}
                    checked={item.value === value}
                    onChange={onChange}
                    name={name}
                    valueType={valueType}
                >
                    {item.label}
                </Radio>
            </RadioStyled>
        ))}
    </RadioGroupStyled>
);

export * from './types';
