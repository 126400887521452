import styled, { css } from 'styled-components/macro';
import { StyledLinkProps } from './index';
import { media } from '@wedat/ui-kit/mediaQueries';

export const StyledLink = styled.a<StyledLinkProps>`
    ${({ theme, disabled }) => css`
        margin-right: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 100%;
        font: ${theme.typography.note.font};
        color: ${disabled ? theme.colors.textDisabled : theme.colors.textPrimary};
        border-bottom: 4px solid transparent;
        transition: opacity 150ms;

        ${disabled &&
        css`
            pointer-events: none;
        `}

        &:hover {
            opacity: 0.5;
        }
    `}

    ${media.tablet`
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
    `}
`;
