import { sendCommonSoapRequest } from '../utils/soapRequest';
import { convertToArrayIfNot } from '../utils/arrayConvert';
import { decodeXml } from '../utils/xmlSpecialCharacters';

import {
    ListContractsParams,
    ListContractsItem,
    ContractIdParams,
    AttachmentFolder,
    ListAttachmentsOfContractParams,
    Attachment,
    UploadAttachmentByIdentificationParams,
    UploadAttachmentByFolderIDParams,
    Credentials
} from '../types/api2datTypes';

import { Contract } from '../types/contractTypes';
import { CreateContract } from '../types/createContractTypes';
import { datProdServicesUrls } from '../baseParameters';

// MyClaimExternalService

const urlMyClaimExternalService =
    datProdServicesUrls.soapMyClaimExternalServiceUrl || 'https://www.dat.de/myClaim/soap/v2/MyClaimExternalService';

// ================================================================================================ working with contract

export async function listContracts(
    credentials: Credentials,
    params: ListContractsParams,
    url?: string
): Promise<ListContractsItem[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'listContracts',
        credentials,
        params
    );

    if (!SoapResult.Envelope.Body.listContractsResponse.return) return [];
    const res = convertToArrayIfNot(SoapResult.Envelope.Body.listContractsResponse.return);
    res.forEach(claim => {
        claim.vehicle = decodeXml(claim.vehicle);
    });
    return res;
}

export async function getContract(credentials: Credentials, params: ContractIdParams, url?: string): Promise<Contract> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'getContract',
        credentials,
        params,
        [
            'RepairPositions.RepairPosition',
            'MaterialPositions.MaterialPosition',
            'LabourPositions.LabourPosition',
            'LacquerPositions.LacquerPosition',
            'AdditionalEquipment.EquipmentPosition',
            'SeriesEquipment.EquipmentPosition',
            'SpecialEquipment.EquipmentPosition',
            'DeselectedSeriesEquipment.EquipmentPosition',
            'LacquerConstants.LacquerConstant',
            'DATProcessIdCommentList.DATProcessIdComment',
            'TotalSummaryItaly.TotalSummaryItemItaly'
        ]
    );

    const claimDAT: Contract = SoapResult.Envelope.Body.getContractResponse.return;

    if (claimDAT?.Dossier?.Vehicle?.ManufacturerName?.['#text'])
        claimDAT.Dossier.Vehicle.ManufacturerName['#text'] = decodeXml(
            claimDAT.Dossier.Vehicle.ManufacturerName['#text']
        );

    if (claimDAT?.Dossier?.Vehicle?.BaseModelName?.['#text'])
        claimDAT.Dossier.Vehicle.BaseModelName['#text'] = decodeXml(claimDAT.Dossier.Vehicle.BaseModelName['#text']);

    return claimDAT;
}
export async function createOrUpdateContract(
    credentials: Credentials,
    params: CreateContract,
    url?: string
): Promise<number> {
    //
    // =============== if requier create new contract and present templateData neccesery create two request
    //
    if (!params.contractId && params.templateData?.entry?.length) {
        const paramWithoutTemplateData = { ...params };
        delete paramWithoutTemplateData.templateData;
        let SoapResult = await sendCommonSoapRequest(
            url || urlMyClaimExternalService,
            'createOrUpdateContract',
            credentials,
            paramWithoutTemplateData
        );

        const contractId = SoapResult.Envelope.Body.createOrUpdateContractResponse.return;
        const paramsWithContractId = { ...params, contractId };
        SoapResult = await sendCommonSoapRequest(
            url || urlMyClaimExternalService,
            'createOrUpdateContract',
            credentials,
            paramsWithContractId
        );
        return SoapResult.Envelope.Body.createOrUpdateContractResponse.return;
    }

    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'createOrUpdateContract',
        credentials,
        params
    );
    return SoapResult.Envelope.Body.createOrUpdateContractResponse.return;
}

// ================================================================================================ working with attachments

export async function listAttachmentFolders(
    credentials: Credentials,
    params: ContractIdParams,
    url?: string
): Promise<AttachmentFolder[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'listAttachmentFolders',
        credentials,
        params
    );
    return convertToArrayIfNot(SoapResult.Envelope.Body.listAttachmentFoldersResponse.return.attachmentFolder);
}

export async function listAttachmentsOfContract(
    credentials: Credentials,
    params: ListAttachmentsOfContractParams,
    url?: string
): Promise<Attachment[]> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'listAttachmentsOfContract',
        credentials,
        params
    );
    if (SoapResult.Envelope.Body.listAttachmentsOfContractResponse.return)
        return convertToArrayIfNot(SoapResult.Envelope.Body.listAttachmentsOfContractResponse.return);
    return [];
}

export async function uploadAttachmentByIdentification(
    credentials: Credentials,
    params: UploadAttachmentByIdentificationParams,
    url?: string
): Promise<boolean> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'uploadAttachmentByIdentification',
        credentials,
        params
    );
    return SoapResult.Envelope.Body.uploadAttachmentByIdentificationResponse.return;
}

export async function uploadAttachmentByFolderID(
    credentials: Credentials,
    params: UploadAttachmentByFolderIDParams,
    url?: string
): Promise<boolean> {
    const SoapResult = await sendCommonSoapRequest(
        url || urlMyClaimExternalService,
        'uploadAttachmentByFolderID',
        credentials,
        params
    );
    return SoapResult.Envelope.Body.uploadAttachmentByFolderIDResponse.return;
}
