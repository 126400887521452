import { calculationInitialParameters } from './calculation';
import { sparePartsInitialParameters } from './spareParts';
import { labourCostInitialParameters } from './labourCost';
import { domusCalculationInitialParameters } from './domusCalculation';
import { euroLacquerInitialParameters } from './euroLacquer';
import { manufacturerLacquerInitialParameters } from './manufacturerLacquer';
import { aztLacquerInitialParameters } from './aztLacquer';
import { CZ_LACQUER_INITIAL_PARAMETERS } from './czLacquer';

import { FactorsParametersObject } from '../../types/factors';

export const initialFactorsParameters: FactorsParametersObject = {
    CalculationFactor: calculationInitialParameters,
    SparePartFactor: sparePartsInitialParameters,
    LabourCostFactor: labourCostInitialParameters,
    DomusCalculationFactor: domusCalculationInitialParameters,
    EuroLacquerFactor: euroLacquerInitialParameters,
    ManufacturerLacquerFactor: manufacturerLacquerInitialParameters,
    AztLacquerFactor: aztLacquerInitialParameters,
    CzLacquerFactor: CZ_LACQUER_INITIAL_PARAMETERS
};
