import { isSenderCurrentUser } from './isSenderCurrentUser';
import { PartnerMemberData } from '../types/members';
import { getMemberUsername } from './getMemberUsername';

interface Param {
    dialog: DAT2.Plugins.Chat.Dialog;
    username: string;
    sameCustomerProfiles: BFF.Customer.Response.GetProfiles;
    partners: PartnerMemberData[];
    customerNumber: number;
}

export const getDialogItemCompanionName = ({
    dialog,
    username,
    sameCustomerProfiles,
    partners,
    customerNumber
}: Param) => {
    const companionUsername =
        getMemberUsername(dialog.members.find(member => getMemberUsername(member) !== username)) || '';

    const userFullName = `${sameCustomerProfiles[companionUsername]?.name || ''} ${
        sameCustomerProfiles[companionUsername]?.surname || ''
    }`;

    const userHasFullName =
        sameCustomerProfiles[companionUsername]?.name && sameCustomerProfiles[companionUsername]?.surname;

    if (userHasFullName) {
        return userFullName;
    } else if (companionUsername) {
        return companionUsername;
    } else {
        return partners.find(
            partner =>
                dialog.members.find(
                    item =>
                        !isSenderCurrentUser({
                            sender: item,
                            customerNumber,
                            username
                        })
                    //@ts-ignore
                )?.customerNumber === partner.customerNumber
        )?.name;
    }
};
