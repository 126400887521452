import { equipmentEffects } from '../equipment';
import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';

//
/*** Loading status ***/
//
const isLoading = createIsLoadingStore(Object.values(equipmentEffects));

//
/*** Export ***/
//
export const commonStores = {
    isLoading
};
