import { FormValues } from '../../../types';

type Params = [possibleStatuses: DAT2.ContractStatusTransition[], formValues: FormValues];
export type Result = DAT2.ContractStatusTransition | undefined;

export const findSelectedStatus = (...[possibleStatuses, { statusId }]: Params): Result => {
    const selectedStatus = possibleStatuses.find(status => status.statusId === statusId);

    return selectedStatus;
};
