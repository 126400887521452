import styled from 'styled-components/macro';

export const InputWrapper = styled.div`
    // because styled-component doesn't support "enterKeyHint"
    min-width: 50%;

    input {
        color: inherit;
        opacity: 1;
        width: 100%;
        border: 0px;
        margin: 0px;
        outline: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
        ${({ theme }) => theme.typography.note};
    }
`;
