import { CustomersSubjectsData } from '@dat/shared-models/addressBook/type';

export const createSubjectsDataWithRoles = (customersSubjectsData: CustomersSubjectsData) => {
    const customersSubjectsDataArray = customersSubjectsData?.current ?? [];
    return Object.entries(customersSubjectsDataArray).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: {
                ...value,
                subjects: [...value.subjects.map(item => ({ ...item, role: key }))]
            }
        }),
        {}
    );
};
