import { filterArrayOfObjectsByExistingKeys, getISODateString } from '@dat/core/utils';
import { KeyValueOption } from '@wedat/ui-kit/components/Select';

export const createNumberedSelectOptions = ({ from, to }: { from: number; to: number }): KeyValueOption[] => {
    const result = [];

    for (let i = from; i <= to; i++) {
        result.push({ value: String(i), key: i, label: String(i) });
    }

    return result;
};

export const createInvoiceRatesSelectOptions = (invoiceRates: DAT2.RefOrRateSet[]) => {
    const validInvoiceRates = filterArrayOfObjectsByExistingKeys(invoiceRates, ['name']);

    return validInvoiceRates.map(({ name }) => ({
        value: String(name),
        label: String(name),
        key: name
    }));
};

export const createPriceDatesSelectOptions = (priceDates: DAT2.PriceDate[]) =>
    priceDates.map(({ date }) => {
        const currDate = new Date(date).toLocaleDateString('fr');
        return {
            value: currDate,
            label: currDate,
            key: getISODateString(new Date(date))
        };
    });

export const createPaintTypesSelectOptions = (paintTypes: DAT2.LacquerType[]) =>
    paintTypes.map(({ description, key }) => ({
        value: description,
        label: description,
        key: String(key)
    }));
