/* eslint-disable */
import { combine, createEffect, guard, sample, forward, createEvent } from 'effector';
// import { combine, createEffect, guard, sample, forward } from 'effector-logger';
// import { createEvent, createStore } from 'effector-logger/macro';
import * as R from 'ramda';
import { PluginOptions } from '../types/plugin';

import { i18n } from '../i18n';

import { splitDatECode } from '../utils/splitDatECode';

import { GenericSVGGraphic, genericSVGGraphicModel } from './genericSVGGraphicModel';
import { pluginOptionsModel } from './pluginOptionsModel';
import { RepairPositionsModel, RepairPositionWithRowKey } from './repairPositionsModel';
import { availableAssemblyGroupsModel } from './availableAssemblyGroupsModel';
import { graphicDamageSelectionModel } from './graphicDamageSelectionModel';
import { requestCredentialsModel } from './requestCredentialsModel';
import { dvnEquipmentModel } from './dvnEquipmentModel';
import { DataEquipment, equipmentModel } from './equipmentModel';
import { getLanguage } from '../utils/datLocale';
import { getDoorsNumber } from '../utils/getDoorsNumber';
import { updateI18n } from '../utils/locale/updateI18n';
import { defaultNS } from '../utils/locale/createI18nInstance';
import { zoneGraphicsModel } from './zoneGraphicsModel';
import { sparePartSearchModel } from './sparePartSearchModel';
import { updateFromStaticGrapaService } from './updateFromStaticGrapaServiceModel';
import { uniqIdString } from '../utils/uniqId';
import { DATProcessIdCommentListModel } from './DATProcessIdCommentListModel';
import { commonModel } from './commonModel';
import { vehicleSVGModel } from './vehicleSVGModel';
// import { getInterimCalcParams } from '../utils/getInterimCalcParams';
import { interimCalculationModel } from './interimCalculationModel';
import { mappingDVNWorkTypeWorkLevelModel } from './mappingDVNWorkTypeWorkLevelModel';
import { sparePartVariantsModel } from './sparePartVariantsModel';
// import { getLeftOrRightSide } from '../utils/convertRepairPositionsToDamageList';

sample({
    source: [
        pluginOptionsModel.stores.pluginOptions,
        RepairPositionsModel.store.repairPositions,
        DATProcessIdCommentListModel.store.DATProcessIdCommentList,
        equipmentModel.stores.deselectedSeriesEquipment,
        equipmentModel.stores.specialEquipment
        // availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
    ],
    clock: commonModel.events.confirm,
    fn: ([
        pluginOptions,
        inputRepairPosition,
        DATProcessIdComment,
        DeselectedSeriesEquipment,
        SpecialEquipment
        // availableAssemblyGroupsStore
    ]) => {
        let RepairPosition = inputRepairPosition;
        if (pluginOptions?.settings?.contract?.complexTemplateData?.attr?.templateType === 'vro_domus_calculation') {
            // todo remove when will be fixed on backend or application site
            RepairPosition = inputRepairPosition.map(repPos => ({ ...repPos, WorkManualInput: undefined }));
        }

        // const { availableAssemblyGroups } = availableAssemblyGroupsStore;

        const updatedRepairPosition = RepairPosition.map(el => ({
            ...el,
            IsManualDescription: true

            // Description:
            //     el.Description?.endsWith('L') || el.Description?.endsWith('R')
            //         ? el.Description
            //         : el.Description + ' ' + getLeftOrRightSide(availableAssemblyGroups, el.DATProcessId)

            // Description: el.Description?.startsWith(el.DATProcessId.toString())
            //     ? el.Description
            //     : el.DATProcessId + ' ' + el.Description
        }));

        return {
            onConfirmDossier: pluginOptions?.onConfirmDossier,
            dossier: {
                Vehicle: {
                    Equipment: {
                        DeselectedSeriesEquipment: DeselectedSeriesEquipment || undefined,
                        SpecialEquipment: SpecialEquipment || undefined,
                        SeriesEquipment: pluginOptions?.settings?.contract?.Dossier?.Vehicle?.Equipment?.SeriesEquipment
                    }
                },
                RepairCalculation: {
                    RepairPositions: {
                        RepairPosition: updatedRepairPosition
                    }
                },
                RepairOrder: {
                    DATProcessIdCommentList: {
                        DATProcessIdComment
                    }
                }
            }
        };
    }
}).watch(({ onConfirmDossier, dossier }) => onConfirmDossier?.(dossier));

// const initPlugin = createEffect({
//     async handler(pluginOptions: PluginOptions | undefined) {
//         if (!pluginOptions) return;

//         const resources = pluginOptions.settings?.i18n?.resources;
//         if (!resources) return;
//     }
// });

// sample({
//     source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeRepairPositions),
//     // repairPositions: RepairPositionsModel.store.repairPositions
//     clock: RepairPositionsModel.event.setRepairPositions,
//     fn: (onChangeRepairPositions, repairPositions) => ({
//         onChangeRepairPositions,
//         repairPositions
//     })
// }).watch(({ onChangeRepairPositions, repairPositions }) => onChangeRepairPositions?.(repairPositions));

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeRepairPositions),
    clock: RepairPositionsModel.event.setConfirmedRepairPositions,
    fn: (onChangeRepairPositions, repairPositions) => ({
        onChangeRepairPositions,
        repairPositions
    })
}).watch(({ onChangeRepairPositions, repairPositions }) => onChangeRepairPositions?.(repairPositions));

// sample({
//     source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeDeselectedSeriesEquipment),
//     clock: equipmentModel.events.setDeselectedSeriesEquipment,
//     fn: (onChangeDeselectedSeriesEquipment, equipment) => ({
//         onChangeDeselectedSeriesEquipment,
//         equipment
//     })
// }).watch(
//     ({ onChangeDeselectedSeriesEquipment, equipment }) =>
//         equipment && onChangeDeselectedSeriesEquipment?.(equipment.EquipmentPosition)
// );

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeDeselectedSeriesEquipment),
    clock: equipmentModel.events.setConfirmedDeselectedSeriesEquipment,
    fn: (onChangeDeselectedSeriesEquipment, equipment) => ({
        onChangeDeselectedSeriesEquipment,
        equipment
    })
}).watch(({ onChangeDeselectedSeriesEquipment, equipment }) =>
    onChangeDeselectedSeriesEquipment?.(equipment?.EquipmentPosition || [])
);

// sample({
//     source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeSpecialEquipment),
//     clock: equipmentModel.events.setSpecialEquipment,
//     fn: (onChangeSpecialEquipment, equipment) => ({
//         onChangeSpecialEquipment,
//         equipment
//     })
// }).watch(
//     ({ onChangeSpecialEquipment, equipment }) => equipment && onChangeSpecialEquipment?.(equipment.EquipmentPosition)
// );

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeSpecialEquipment),
    clock: equipmentModel.events.setConfirmedSpecialEquipment,
    fn: (onChangeSpecialEquipment, equipment) => ({
        onChangeSpecialEquipment,
        equipment
    })
}).watch(({ onChangeSpecialEquipment, equipment }) => onChangeSpecialEquipment?.(equipment?.EquipmentPosition || []));

// sample({
//     source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeDATProcessIdCommentList),
//     clock: DATProcessIdCommentListModel.event.setDATProcessIdCommentList,
//     fn: (onChangeDATProcessIdCommentList, DATProcessIdCommentList) => ({
//         onChangeDATProcessIdCommentList,
//         DATProcessIdCommentList
//     })
// }).watch(({ onChangeDATProcessIdCommentList, DATProcessIdCommentList }) => DATProcessIdCommentList && onChangeDATProcessIdCommentList?.(DATProcessIdCommentList));

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.onChangeDATProcessIdCommentList),
    clock: DATProcessIdCommentListModel.event.setConfirmedDATProcessIdCommentList,
    fn: (onChangeDATProcessIdCommentList, DATProcessIdCommentList) => ({
        onChangeDATProcessIdCommentList,
        DATProcessIdCommentList
    })
}).watch(
    ({ onChangeDATProcessIdCommentList, DATProcessIdCommentList }) =>
        DATProcessIdCommentList && onChangeDATProcessIdCommentList?.(DATProcessIdCommentList)
);

guard({
    // INTERIM CALCULATION: calculate on confirm
    source: pluginOptionsModel.stores.pluginOptions,
    clock: commonModel.events.confirm,
    filter: pluginOptions =>
        !!pluginOptions?.settings?.useInterimCalculation &&
        !!pluginOptions.settings.contract?.Dossier?.RepairCalculation?.RepairParameters,
    target: interimCalculationModel.effects.getInterimCalculationFx
});

guard({
    // INTERIM CALCULATION: calculate on plugin init
    source: pluginOptionsModel.stores.pluginOptions,
    filter: pluginOptions =>
        !!pluginOptions?.settings?.useInterimCalculation &&
        !!pluginOptions.settings.contract?.Dossier?.RepairCalculation?.RepairParameters,
    target: interimCalculationModel.effects.initInterimCalculationFx
});

guard({
    source: pluginOptionsModel.stores.pluginOptions.map(
        pluginOptions =>
            pluginOptions?.settings?.contract?.Dossier?.RepairCalculation?.RepairPositions?.RepairPosition || []
    ),
    filter: repairPositions => Array.isArray(repairPositions)
    // target: RepairPositionsModel.event.initRepairPosition
}).watch(repPos => {
    RepairPositionsModel.event.initRepairPosition(repPos.map(repPos => ({ ...repPos, key: uniqIdString() })));
});

guard({
    source: pluginOptionsModel.stores.pluginOptions.map(
        pluginOptions =>
            pluginOptions?.settings?.contract?.Dossier?.RepairOrder?.DATProcessIdCommentList?.DATProcessIdComment
    ),
    filter: () => true
    // filter: DATProcessIdComment => Array.isArray(DATProcessIdComment),
    // target: DATProcessIdCommentListModel.event.initDATProcessIdCommentList
}).watch(DATProcessIdComment => {
    if (Array.isArray(DATProcessIdComment))
        DATProcessIdCommentListModel.event.initDATProcessIdCommentList(DATProcessIdComment);
});

const modelDatECode = pluginOptionsModel.stores.pluginOptions.map(
    pluginOptions => pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode
);

const modelDatECodeWithAlternative = pluginOptionsModel.stores.pluginOptions.map(pluginOptions => {
    const Vehicle = pluginOptions?.settings?.contract?.Dossier?.Vehicle;

    if (Vehicle?.AlternativeVehicleType) {
        const { AlternativeVehicleType, AlternativeManufacturer, AlternativeBaseModel, AlternativeSubModel } = Vehicle;

        const AlternativeDatECode =
            String(AlternativeVehicleType).padStart(2, '0') +
            String(AlternativeManufacturer).padStart(3, '0') +
            String(AlternativeBaseModel).padStart(3, '0') +
            String(AlternativeSubModel).padStart(3, '0');
        return AlternativeDatECode;
    }
    return Vehicle?.DatECode;
});

sample({
    source: [genericSVGGraphicModel.stores.genericSVGGraphics, modelDatECode],
    clock: pluginOptionsModel.stores.pluginOptions.map(opt => opt?.settings?.genericSVGGraphics),
    filter: ([initGenericSVGGraphics, datECode], genericSVGGraphics) => {
        if (!initGenericSVGGraphics || !datECode || !genericSVGGraphics) return false;

        const a = genericSVGGraphics.map(g => g.graphicId);
        const b = initGenericSVGGraphics.map(g => g.graphicId);
        return (
            !!initGenericSVGGraphics &&
            !!initGenericSVGGraphics.length &&
            (!a.some(v => !b.indexOf(v)) || !b.some(v => !a.indexOf(v)))
        );
    },
    fn: ([initGenericSVGGraphics, datECode], genericSVGGraphics) => {
        let graphics = genericSVGGraphics;
        if (datECode && splitDatECode(datECode).vehicleTypeId !== '1') graphics = [];
        return graphics;
    },
    target: genericSVGGraphicModel.events.initGenericGraphicFirst
});

guard({
    source: pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.ftSettings?.ftDvnMap),
    filter: ftDvnMap => !!ftDvnMap,
    target: genericSVGGraphicModel.events.setDvnMapByFtDvnMap
});

guard({
    source: pluginOptionsModel.stores.pluginOptions.map(
        pluginOptions => pluginOptions?.settings?.ftSettings?.ftRepairs
    ),
    filter: ftRepairs => !!ftRepairs,
    target: genericSVGGraphicModel.events.setVxsRepairsByFtRepairs
});

guard({
    source: pluginOptionsModel.stores.pluginOptions.map(
        pluginOptions => pluginOptions?.settings?.ftSettings?.ftDamages
    ),
    filter: ftDamages => !!ftDamages,
    target: genericSVGGraphicModel.events.setFtDamages
});

sample({
    source: pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.showFullscreen),
    fn: showFullscreen => {
        return {
            showFullscreen: showFullscreen
        };
    },
    target: graphicDamageSelectionModel.events.updateStore
});

const isCredentialPresent = requestCredentialsModel.stores.requestCredentials.map(
    cred =>
        ('dat-authorizationtoken' in cred && !!cred['dat-authorizationtoken']) ||
        ('customerNumber' in cred && !!cred['customerNumber'])
);

// const modelParams = combine(pluginOptionsModel.stores.pluginOptions).map<
//     | {
//           DatECode?: string;
//           locale?: {
//               country: string; // 'ru'
//               datCountryIndicator: string; // 'ru'
//               language: string; // 'RU'
//           };
//           language?: string;
//           languageCode?: string; // for graphic request  'RUS'
//       }
//     | undefined
// >(([pluginOptions], oldState) => {
//     const language = pluginOptions?.language;

//     const newState = {
//         DatECode: pluginOptions?.settings?.contract?.Dossier?.Vehicle?.DatECode,

//         locale: {
//             language: getLanguage(language).locale.language || 'en',
//             country: getLanguage(language).locale.country || 'US',
//             datCountryIndicator: pluginOptions?.settings?.locale?.datCountryIndicator || 'DE'
//         },

//         language: language
//     };
//     return !R.equals(oldState, newState) ? newState : undefined;
// });

// combine(
//     modelDatECode,
//     pluginOptionsModel.stores.localesParams,
//     isCredentialPresent,
//     requestCredentialsModel.stores.requestCredentials,
//     requestCredentialsModel.stores.datServicesUrls
// ).watch(([DatECode, localesParams, isCredentialPresent, credentials, datServicesUrls]) => {
//     if (!localesParams || !DatECode || !isCredentialPresent) return;

//     const { locale, language } = localesParams;

//     const { vehicleTypeId, manufacturerId, baseModelId } = splitDatECode(DatECode);
//     // const credentials = requestCredentialsModel.stores.requestCredentials.getState();
//     // const datServicesUrls = requestCredentialsModel.stores.datServicesUrls.getState();

//     availableAssemblyGroupsModel.events.initListStartFirst({
//         vehicleType: parseInt(vehicleTypeId),
//         manufacturer: parseInt(manufacturerId),
//         mainType: parseInt(baseModelId),
//         credentials,
//         locale: locale || {
//             country: 'us',
//             datCountryIndicator: 'de',
//             language: 'EN'
//         },
//         languageCode: getLanguage(language).languageCode || 'ENG',
//         url: datServicesUrls.soapVehicleRepairServiceUrl
//     });
// });

combine(
    modelDatECodeWithAlternative,
    pluginOptionsModel.stores.localesParams,
    requestCredentialsModel.stores.datServicesUrls
).watch(([datECode, localesParams, datServicesUrls]) => {
    if (!localesParams || !datECode) return;
    const { locale, language } = localesParams;
    updateFromStaticGrapaService.effects.getFilesByDatECodeFx({
        datECode,
        serviceUrl: datServicesUrls.staticGrapaServiceUrl
    });
});

sample({
    source: modelDatECodeWithAlternative,
    fn: _ => [] as GenericSVGGraphic[],
    target: genericSVGGraphicModel.events.updateGenericSVGGraphics
});

sample({
    source: modelDatECodeWithAlternative,
    filter: datECode => !!datECode && splitDatECode(datECode).vehicleTypeId === '1',
    fn: datECode => datECode,
    target: vehicleSVGModel.effects.getVehicleTypeFX
});

sample({
    source: combine([
        availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore.map(
            availGroupStore => availGroupStore.availableAssemblyGroups[0]
        )
    ]),
    fn: ([assemblGroup]) => ({
        currentAssemblyGroup: assemblGroup,
        showGroupsList: false,
        showGenericGraphic: false,
        currentAssemblyGroupObject: undefined,
        currentAssemblyGroupObjects: undefined
    }),
    target: graphicDamageSelectionModel.events.updateStore
});

sample({
    source: combine({
        availableAssemblyGroups: availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore.map(
            availGroupStore => availGroupStore.availableAssemblyGroups
        ),
        dvnMap: genericSVGGraphicModel.stores.dvnMapStore,
        datECode: availableAssemblyGroupsModel.stores.datECode
    }),
    target: genericSVGGraphicModel.events.setFtDatIdToDvnMap
});

sample({
    source: combine({
        repPos: pluginOptionsModel.stores.pluginOptions.map(
            pluginOptions =>
                pluginOptions?.settings?.contract?.Dossier?.RepairCalculation?.RepairPositions?.RepairPosition
        ),
        modelDatECodeWithAlternative: pluginOptionsModel.stores.modelDatECodeWithAlternative,
        localesParams: pluginOptionsModel.stores.localesParams,
        requestCredentials: requestCredentialsModel.stores.requestCredentials,
        datServicesUrls: requestCredentialsModel.stores.datServicesUrls
    }),
    fn: ({ repPos }) =>
        (repPos || []).filter(repPos => !(repPos as RepairPositionWithRowKey).key).map(repPos => repPos.DATProcessId),
    target: sparePartVariantsModel.effects.getSparePartVariantsByDvnsFx
});

pluginOptionsModel.stores.localesParams.watch(localesParams => {
    if (!localesParams?.language) return;
    const locale = localesParams?.locale?.language + '-' + localesParams?.locale?.country;
    i18n.changeLanguage(locale);
    updateI18n(i18n, locale, [defaultNS]);
});

const doorsNumber = pluginOptionsModel.stores.pluginOptions.map(opt =>
    getDoorsNumber(opt?.settings?.contract?.Dossier?.Vehicle?.Equipment?.SeriesEquipment?.EquipmentPosition)
);

guard({
    source: [pluginOptionsModel.stores.vehicleIdentification, isCredentialPresent],
    filter: ([opt, isCredentialPresent]) => isCredentialPresent,
    target: dvnEquipmentModel.effect.getPossibleEquipmentFx
});
guard({
    source: [pluginOptionsModel.stores.vehicleIdentification, isCredentialPresent],
    filter: ([opt, isCredentialPresent]) => isCredentialPresent,
    target: dvnEquipmentModel.effect.getExistingEquipmentFx
});

const currentDvnRepairPositions = combine(
    RepairPositionsModel.store.repairPositions,
    graphicDamageSelectionModel.stores.currentDVNs,
    (repPos, currentDVNs) => {
        if (currentDVNs?.length !== 1) return [];
        const dvn = currentDVNs[0];
        return repPos.filter(item => item.DATProcessId === dvn);
    }
);

// vehicle.watch(vehicle => zoneGraphicsModel.events.setDatECode(vehicle?.DatECode || null));
modelDatECodeWithAlternative.watch(datECode => zoneGraphicsModel.events.setDatECode(datECode || null));

// sample({
//     source: [
//         availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore,
//         zoneGraphicsModel.stores.currentConstructionGroupsId
//     ],
//     clock: zoneGraphicsModel.events.setCurrentZoneDATID,
//     fn: ([availableAssemblyGroupsStore, currentConstructionGroupsId]) => {
//         if (Array.isArray(currentConstructionGroupsId) && currentConstructionGroupsId.length > 0) {
//             const firstGrId = currentConstructionGroupsId?.filter(gr => Array.isArray(gr.zones?.zone))[0]
//                 .constructiongroupid;
//             const currentAssemblyGroup = availableAssemblyGroupsStore.availableAssemblyGroups.find(
//                 gr => gr.assemblyGroup.assemblyGroupId === firstGrId
//             );
//             graphicDamageSelectionModel.events.updateStore({
//                 typeOfGraphic: 'FullGraphic',
//                 currentAssemblyGroup,
//                 showGroupsList: false,
//                 currentAssemblyGroupObject: undefined,
//                 currentAssemblyGroupObjects: undefined
//             });
//         }
//         return {};
//     }
//     // target: graphicDamageSelectionModel.events.updateStore
// });

export const grapaInterfaceModel = {
    stores: { doorsNumber, currentDvnRepairPositions },
    events: {},
    effects: {
        // initPlugin
    }
};

graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
    .map(val => val.currentDVNs?.[0]?.toString())
    .watch(sparePartSearchModel.events.runGetExtPartNoInfoByFullVehicleAndIntPartNo);

sample({
    source: equipmentModel.stores.dvnEquipment,
    clock: equipmentModel.stores.specialEquipment,
    fn: (dvnEquipment, _specialEquipment) => {
        const dvns = dvnEquipment.map(equip => equip.datProcessId.toString());
        return dvns;
    },
    target: sparePartSearchModel.events.runGetExtPartNoInfoByFullVehicleAndIntPartNo
});

const filteredByZoneAndOptionsGraphicsList = combine(
    modelDatECode,
    equipmentModel.stores.dataEquipment,
    zoneGraphicsModel.stores.currentGroupZoneId,
    zoneGraphicsModel.stores.zusatzData,
    availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore
).map(([datECode, dataEquipment, currentGroupZoneId, zusatzData, availableAssemblyGroupsStore]) => {
    // filter all groups witch are not clickable like rims
    let resultFilteredGraphicsList = availableAssemblyGroupsStore.availableAssemblyGroups.filter(
        gr => gr.objectsInfo?.length
    );

    // set first hail group if present to the top of the left group menu
    const firstHailIndex = resultFilteredGraphicsList.findIndex(gr => gr.isHailGroup);
    if (firstHailIndex >= 0) {
        const firstHail = resultFilteredGraphicsList[firstHailIndex];
        resultFilteredGraphicsList.splice(firstHailIndex, 1);
        resultFilteredGraphicsList.unshift(firstHail);
    }

    if (!currentGroupZoneId || !datECode) return resultFilteredGraphicsList;

    const subModelId = datECode.slice(8, 11);

    const currentConstructionGroupsId = zusatzData?.constructiongroup.filter(
        constGr =>
            (!currentGroupZoneId ||
                (Array.isArray(constGr.zones?.zone)
                    ? constGr.zones?.zone.find(zone => zone.id === (currentGroupZoneId || -1))
                    : !!currentGroupZoneId)) &&
            ((!constGr.options?.option && !constGr.subModels?.subModel) ||
                constGr.options?.option?.find(itemOption =>
                    dataEquipment.find(equipItem => equipItem.selected && equipItem.DatEquipmentId === itemOption)
                ) ||
                constGr.subModels?.subModel?.find(
                    itemSubModel => parseInt(subModelId) === parseInt(itemSubModel.toString())
                ))
    );
    const isFiltratedByZoneGroups = currentConstructionGroupsId && currentConstructionGroupsId.length > 0;
    if (isFiltratedByZoneGroups) {
        resultFilteredGraphicsList = resultFilteredGraphicsList.filter(gr =>
            currentConstructionGroupsId?.find(curGr => curGr.constructiongroupid === gr.assemblyGroup.assemblyGroupId)
        );

        const resultFilteredGraphicsListWithNumberOfZone = resultFilteredGraphicsList.map(gr => {
            const constGr = currentConstructionGroupsId?.find(
                curGr => curGr.constructiongroupid === gr.assemblyGroup.assemblyGroupId
            );

            const numberOfZone = constGr?.zones?.zone?.length || 9999;

            return { ...gr, numberOfZone };
        });

        resultFilteredGraphicsList = resultFilteredGraphicsListWithNumberOfZone.sort(
            (a, b) => a.numberOfZone - b.numberOfZone
        );

        // const resultFilteredGraphicsListWithOneZone = availableAssemblyGroupsStore.availableAssemblyGroups.filter(gr =>
        //     currentConstructionGroupsId?.find(
        //         curGr =>
        //             curGr.constructiongroupid === gr.assemblyGroup.assemblyGroupId && Array.isArray(curGr.zones?.zone) && curGr.zones?.zone.length === 1
        //     )
        // );

        // const resultFilteredGraphicsListWithMultiZone = availableAssemblyGroupsStore.availableAssemblyGroups.filter(gr =>
        //     currentConstructionGroupsId?.find(
        //         curGr =>
        //             curGr.constructiongroupid === gr.assemblyGroup.assemblyGroupId && Array.isArray(curGr.zones?.zone) && curGr.zones?.zone.length !== 1
        //     )
        // );
        // const resultFilteredGraphicsListWithoutZone = availableAssemblyGroupsStore.availableAssemblyGroups.filter(gr =>
        //     currentConstructionGroupsId?.find(
        //         curGr =>
        //             curGr.constructiongroupid === gr.assemblyGroup.assemblyGroupId && !Array.isArray(curGr.zones?.zone)
        //     )
        // );

        // resultFilteredGraphicsList = [...resultFilteredGraphicsListWithZone, ...resultFilteredGraphicsListWithoutZone];
        // resultFilteredGraphicsList = [...resultFilteredGraphicsListWithOneZone, ...resultFilteredGraphicsListWithMultiZone , ...resultFilteredGraphicsListWithoutZone];
    }

    return resultFilteredGraphicsList;
});

sample({
    source: filteredByZoneAndOptionsGraphicsList,
    clock: zoneGraphicsModel.events.setCurrentZoneDATID,
    fn: (filteredGraphicsList, currentGroupZoneId) => {
        if (currentGroupZoneId && Array.isArray(filteredGraphicsList) && filteredGraphicsList.length > 0) {
            const currentAssemblyGroup = filteredGraphicsList[0];
            return {
                typeOfGraphic: 'FullGraphic',
                currentAssemblyGroup,
                showGroupsList: false,
                currentAssemblyGroupObject: undefined,
                currentAssemblyGroupObjects: undefined
            };
        }
        return {};
    },
    target: graphicDamageSelectionModel.events.updateStore
});

forward({
    from: [
        modelDatECodeWithAlternative,
        pluginOptionsModel.stores.localesParams,
        requestCredentialsModel.stores.datServicesUrls
    ],
    to: graphicDamageSelectionModel.events.resetGraphicDamageSelection
});

pluginOptionsModel.stores.pluginOptions
    .map(opt => opt?.datServicesUrls?.staticGrapaServiceUrl)
    .watch(serviceUrl => zoneGraphicsModel.effects.getZoneGraphicBaseFx(serviceUrl));

const newDatEquipment = combine(
    RepairPositionsModel.store.newDvnRepairPosition,
    equipmentModel.stores.dataEquipment
).map(([newDvnRepairPosition, dataEquipment]) => {
    let dataSource: DataEquipment[] = [];
    if (newDvnRepairPosition.length)
        dataSource = dataEquipment.filter(equipment => equipment.dvns?.find(dvn => newDvnRepairPosition.includes(dvn)));
    return dataSource;
});

sample({
    source: newDatEquipment,
    clock: commonModel.events.confirmWithEquipmentCheck,
    filter: newDatEquipment => {
        return newDatEquipment.length > 0;
    },
    fn: () => true,
    target: commonModel.events.setIsEquipmentCheck
});

sample({
    source: newDatEquipment,
    clock: commonModel.events.confirmWithEquipmentCheck,
    filter: newDatEquipment => !newDatEquipment.length,
    target: commonModel.events.confirm
});

sample({
    source: RepairPositionsModel.store.repairPositions,
    clock: availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore,
    filter: (repairPositions, availableAssemblyGroupsStore) =>
        !!repairPositions[0]?.DATProcessId && !!availableAssemblyGroupsStore.availableAssemblyGroups.length,
    fn: (repairPositions, availableAssemblyGroupsStore) => {
        return [repairPositions[0].DATProcessId];
    },
    target: graphicDamageSelectionModel.events.setCurrentAssemblyGroup
});

sample({
    clock: graphicDamageSelectionModel.events.setCurrentAssemblyGroup,
    fn: () => ({
        typeOfGraphic: 'FullGraphic'
    }),
    target: graphicDamageSelectionModel.events.updateStore
});

sample({
    source: RepairPositionsModel.store.repairPositions,
    clock: availableAssemblyGroupsModel.stores.availableAssemblyGroupsStore,
    filter: (repairPositions, availableAssemblyGroupsStore) => {
        if (!!repairPositions[0]?.DATProcessId) return false;
        if (availableAssemblyGroupsStore.availableAssemblyGroups?.find(gr => gr.isHailGroup)) return true;
        return false;
    },
    fn: (_repairPositions, availableAssemblyGroupsStore) => {
        const firstHailGroup = availableAssemblyGroupsStore.availableAssemblyGroups?.find(gr => gr.isHailGroup);
        if (firstHailGroup)
            return {
                typeOfGraphic: 'FullGraphic',
                currentAssemblyGroup: firstHailGroup,
                currentAssemblyGroupObject: undefined,
                currentAssemblyGroupObjects: undefined
            };

        return {};
    },
    target: graphicDamageSelectionModel.events.updateStore
});

// guard({
//     source: pluginOptionsModel.stores.pluginOptions.map(
//         pluginOptions => pluginOptions?.settings?.contract?.Dossier?.Country
//     ),
//     clock: pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.useWorkTypeLevel),
//     filter: (country, useWorkTypeLevel) => !!(country === 'FR' && useWorkTypeLevel),
//     target: mappingDVNWorkTypeWorkLevelModel.effects.getMappingDVNWorkTypeWorkLevelFX // init data from service})
// });

sample({
    source: [
        pluginOptionsModel.stores.pluginOptions.map(pluginOptions => pluginOptions?.settings?.useWorkTypeLevel),
        pluginOptionsModel.stores.pluginOptions.map(
            pluginOptions => pluginOptions?.settings?.useServiceForWorkTypeWorkLevel
        )
    ],
    filter: ([useWorkTypeLevel, useServiceForWorkTypeWorkLevel]) =>
        !!(useServiceForWorkTypeWorkLevel && useWorkTypeLevel),
    target: mappingDVNWorkTypeWorkLevelModel.effects.getMappingDVNWorkTypeWorkLevelFX // init data from service})
});

// events happens on start and every change type device
sample({
    clock: commonModel.stores.deviceType,
    fn: devType => {
        if (devType === 'phone')
            return {
                showGraphicGroupsMenu: false,
                showRightSideDrawer: false
            };
        if (devType === 'tablet')
            return {
                showGraphicGroupsMenu: true,
                showRightSideDrawer: false
            };
        if (devType === 'laptop')
            return {
                showGraphicGroupsMenu: true,
                showRightSideDrawer: true
            };
        return {};
    },
    target: graphicDamageSelectionModel.events.updateStore
});

export const subscribes = {
    stores: {
        filteredByZoneAndOptionsGraphicsList,
        newDatEquipment
    },
    events: {},
    effects: {}
};
