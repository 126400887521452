import { useStore } from 'effector-react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';

export const DefaultDamageMenu = () => {
    const { isShowMultiSelectMenu, availableDefaultDamages, defaultDamages } = useStore(
        graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
    );
    const { t } = useTranslation();

    function onClose() {
        graphicDamageSelectionModel.events.updateStore({
            isShowMultiSelectMenu: false
        });
    }

    return (
        <Modal
            className="modal-grapa"
            mask
            maskClosable
            cancelText="Close"
            footer={null}
            title={t('DefaultDamageMenu.DamagesForFastSelection')}
            visible={isShowMultiSelectMenu}
            width={'300px'}
            zIndex={1015}
            onCancel={onClose}
        >
            <div>
                {availableDefaultDamages?.map(defaultDamage => (
                    <div key={defaultDamage.defaultDamageId} style={{ margin: '0px 8px 0px 8px' }}>
                        <Button
                            size="large"
                            style={{ margin: '8px 0px 8px 0px', width: '100%' }}
                            type={
                                !!defaultDamages?.find(
                                    damElem => damElem.defaultDamageId === defaultDamage.defaultDamageId
                                )
                                    ? 'primary'
                                    : 'default'
                            }
                            onClick={_ => {
                                graphicDamageSelectionModel.events.togleDefaultDamage(defaultDamage.defaultDamageId);
                            }}
                        >
                            {t('DefaultDamageMenu.availableDefaultDamages.' + defaultDamage.defaultDamageId) ||
                                defaultDamage.damageName}
                        </Button>
                    </div>
                ))}
            </div>
        </Modal>
    );
};
