import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

interface ContainerProp {
    isInbox?: boolean;
}

export const Container = styled.div<ContainerProp>`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray_10};

    ${media.laptop`
        ${({ isInbox }) => !isInbox && 'justify-content: space-between;'};
    `}
`;

export const ButtonStyled = styled(Button)`
    width: fit-content;
    padding: 10px 20px;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray_10};

    &:not(:first-child) {
        margin-left: 20px;
    }

    ${media.laptop`
        min-width: 48px;
        height: 48px;
        padding: 10px;
    `}
`;

export const SelectButton = styled(Button)`
    width: fit-content;
    padding: 10px 20px;
    margin-left: 20px;

    ${media.laptop`
        width: 100%;
    `}
`;

export const MobileIconWrapper = styled.div`
    display: none;

    ${media.laptop`
        display: flex;
    `}
`;

export const TextStyled = styled(Text)`
    display: flex;

    ${media.laptop`
        display: none;
    `}
`;
