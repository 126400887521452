import { sample } from 'effector';
import { attachmentsEffects } from '../attachments';
import { printReportsEffects } from '../printReports';
import { pluginEvents } from './index';

import { sharedAttachmentsEffects } from '@dat/shared-models/contract/Attachments';

const { initPlugin } = pluginEvents;

const { getListPrintReportForClaim } = printReportsEffects;
const { getFoldersOfClaimWithToken } = attachmentsEffects;
const { uploadSingleAttachmentFx, uploadMultipleAttachmentsFx, deleteAttachmentFx } = sharedAttachmentsEffects;

sample({
    clock: initPlugin,
    filter: options => !!options.contractId,
    fn: options => ({ claimId: options.contractId ?? 0 }),
    target: [getFoldersOfClaimWithToken, getListPrintReportForClaim]
});

sample({
    clock: [uploadSingleAttachmentFx.done, uploadMultipleAttachmentsFx.done, deleteAttachmentFx.done],
    filter: options => !!options.params.contractId,
    fn: options => ({ claimId: options.params.contractId ?? 0 }),
    target: [getFoldersOfClaimWithToken, getListPrintReportForClaim]
});
