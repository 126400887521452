import { bffAxiosInstance } from '../../bffAxios';

export const createDialog = (data: BFF.Chat.Request.CreateDialog): Promise<void> =>
    bffAxiosInstance.post('chat/createDialog', data);

export const sendMessage = (data: BFF.Chat.Request.SendMessage): Promise<void> =>
    bffAxiosInstance.post('chat/sendMessage', data);

export const readMessages = (data: BFF.Chat.Request.ReadMessages): Promise<void> =>
    bffAxiosInstance.post('chat/readMessages', data);
