import { useEffect } from 'react';

interface Props {
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
    swipeMainRef: React.MutableRefObject<HTMLDivElement | null>;
    swipeContentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const useSwipe = ({ containerRef, swipeMainRef, swipeContentRef }: Props) => {
    useEffect(() => {
        const container = containerRef.current;
        const main = swipeMainRef.current;
        const swipeContent = swipeContentRef.current;

        if (!container || !main || !swipeContent) return;

        const transition = 'transform 150ms ease-out';
        const maxAllowedYDiffForHorizontalScroll = 20;

        let position = { startX: 0, scrollLeft: 0, startY: 0 };
        let swipeContentWidth = swipeContent.clientWidth;
        let offsetLeft = 0;
        let movedY = false;

        const setContainerTransform = () => {
            const swipedOffset = -swipeContentWidth / 2;
            const swiped = swipedOffset >= offsetLeft;
            const translateX = swiped ? -swipeContentWidth : 0;
            position.scrollLeft = Math.abs(translateX);

            main.style.transition = transition;
            swipeContent.style.transition = transition;
            main.style.transform = `translate(${translateX}px, 0)`;
            swipeContent.style.transform = `translate(${translateX}px, 0)`;
        };

        const onTouchStart = (e: TouchEvent) => {
            main.style.transition = '';
            swipeContent.style.transition = '';

            movedY = false;
            position.startX = e.changedTouches[0].pageX;
            position.startY = e.changedTouches[0].pageY;
        };

        const onTouchMove = (e: TouchEvent) => {
            const { pageX, pageY } = e.changedTouches[0];
            const diffYscroll = Math.abs(pageY - position.startY);

            if (movedY || diffYscroll > maxAllowedYDiffForHorizontalScroll) {
                movedY = true;
                setContainerTransform();
                return;
            }

            const slideX = pageX - position.startX;
            const maxOffsetLeft = slideX - position.scrollLeft;

            if (maxOffsetLeft > 0) {
                offsetLeft = 0;
            } else if (maxOffsetLeft < -swipeContentWidth) {
                offsetLeft = -swipeContentWidth;
            } else {
                offsetLeft = maxOffsetLeft;
            }

            main.style.transform = `translate(${offsetLeft}px, 0)`;
            swipeContent.style.transform = `translate(${offsetLeft}px, 0)`;
        };

        const onTouchEnd = () => {
            setContainerTransform();
        };

        container.addEventListener('touchstart', onTouchStart);
        container.addEventListener('touchend', onTouchEnd);
        container.addEventListener('touchcancel', onTouchEnd);
        container.addEventListener('touchmove', onTouchMove);

        return () => {
            container.removeEventListener('touchstart', onTouchStart);
            container.removeEventListener('touchend', onTouchEnd);
            container.removeEventListener('touchcancel', onTouchEnd);
            container.removeEventListener('touchmove', onTouchMove);
        };
    }, [containerRef, swipeMainRef, swipeContentRef]);
};
