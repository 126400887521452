import React from 'react';
import { Container, NotFoundImg } from './styles';
import noRowsData from '../../assets/images/noRowsData.png';
import { Text } from '@wedat/ui-kit/components/Text';
import { useTranslation } from 'react-i18next';

export const NoRowsData: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <NotFoundImg src={noRowsData} alt={t('NothingFound')} />
            <Text>{t('noRowsData')}</Text>
        </Container>
    );
};
