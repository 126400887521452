import { DataField, FieldType } from '../../types/dataScheme';

export const getFieldProps = (field: DataField): any => {
    if (field.Type in fieldsPropsGetters) {
        return fieldsPropsGetters[field.Type](field);
    }

    return {};
};

type FieldPropsGetter = (field: DataField) => object;

const getBaseProps: FieldPropsGetter = field => ({
    name: field.id || 'name',
    validator: () => true,
    placeholder: field.placeholder || '',
    className: field.className || '',
    label: field.label || '',
    disabled: field.readOnly || false,
    options: field.options
});

const getInputProps: FieldPropsGetter = field => ({
    ...getBaseProps(field),
    type: 'text', // react-number-format accepts "text"
    mask: field.pattern?.mask?.replace(/#/g, '*') || undefined
});

const fieldsPropsGetters: Record<FieldType, FieldPropsGetter> = {
    string: getInputProps,
    integer: getInputProps,
    float: getInputProps,
    memo: (field: DataField) => ({
        ...getBaseProps(field),
        rows: field.rows
    }),
    select: (field: DataField) => ({
        ...getBaseProps(field),
        options: field.options || []
    }),
    boolean: getBaseProps,
    radio: (field: DataField) => ({
        ...getBaseProps(field),
        items: field.items || []
    }),
    date: getBaseProps,
    subject: () => ({}),
    button: getBaseProps,
    submit: getBaseProps,
    calculatedValue: () => ({}),
    googlePlaces: getBaseProps
};
