import { FC } from 'react';

import { PreloaderContainer, PreloaderImage } from './styles';

import preloaderImage from '../../assets/images/preloader.gif';

// Main component
interface Props {
    isLoading: boolean;
    fullScreen?: boolean;
    noOpacity?: boolean;
}

export const Preloader: FC<Props> = ({ isLoading, fullScreen = true, noOpacity }) => {
    if (!isLoading) {
        return null;
    }

    if (fullScreen) {
        return (
            <PreloaderContainer noOpacity={noOpacity}>
                <PreloaderImage alt="Loading..." src={preloaderImage} />
            </PreloaderContainer>
        );
    }

    return <PreloaderImage alt="Loading..." src={preloaderImage} />;
};
