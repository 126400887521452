import styled from 'styled-components/macro';

export const SubmenuTitle = styled.h4`
    font-weight: 500;
    font-size: 18px;
    color: #465064;
    margin-bottom: 20px;
`;

export const ValuesContainer = styled.div`
    margin-left: 20px;
`;
