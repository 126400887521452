import { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { pluginStores } from '../../stores/plugin';
import { formikInitialValues } from './formikInitialValues';
import { vehicleSelectionEffects, vehicleSelectionStores } from '../../stores/vehicleSelection';
import { vehicleIdentificationStores } from '../../stores/vehicleIdentification';
import { equipmentEffects } from '@dat/equipment/src/stores/equipment';
import { modalsStores, modalsEvents } from '../../stores/modals';
import { equipmentStores } from '../../stores/equipment';

import { StyledVSMButton } from './styles';
import { MainModal } from '../VehicleSelectionModal';
import { NotificationModal } from '@dat/smart-components/NotificationModal';

import { CurrentFormikValues } from '../../types/formik';
import { PluginResult } from '../../types/plugin';
import { createValuateFinanceRequest } from '../../utils/createValuateFinanceRequest';
import { mainEvents } from './store';
import { EquipmentModal } from '../EquipmentModal';
import { TechnicalDataModal } from '../VehicleDataModal';
import _ from 'lodash';
import { PortalFields } from '../PortalFields';
import { vehicleOCRStores } from '../../stores/ocr';
import { vehicleVinOCRStores } from '../../stores/vinOcr';
import { VsmPluginCars } from '../PluginCars';
import { Anyline } from '../Anyline';
import { SubModelsModal } from '../SubModelsModal';

export const Main: FC = () => {
    const { t } = useTranslation();
    const pluginOptions = useStore(pluginStores.pluginOptions);
    const technicalData = useStore(vehicleSelectionStores.technicalData);
    const vinResult = useStore(vehicleIdentificationStores.vinResult);
    const vinEquipmentIds = useStore(vehicleIdentificationStores.vinEquipmentIds);
    const isVSMModalOpen = useStore(modalsStores.isVSMModalOpen);
    const existingEquipment = useStore(equipmentStores.existingEquipmentStore);
    const plateReaderResult = useStore(vehicleOCRStores.plateReaderResult);
    const vinOcrScanDate = useStore(vehicleVinOCRStores.vinOcrScanDate);
    const registrationNumberRequestResult = useStore(vehicleIdentificationStores.registrationNumberRequestResult);
    const manufacturers = useStore(vehicleSelectionStores.manufacturers);
    const baseModels = useStore(vehicleSelectionStores.baseModels);
    const subModels = useStore(vehicleSelectionStores.subModels);

    const isPluginCarsOpen = useStore(modalsStores.isPluginCarsOpen);
    const isAnylineOpen = useStore(modalsStores.isAnylineOpen);

    const [prevValues, setPrevValues] = useState(formikInitialValues);

    useEffect(() => {
        vehicleSelectionEffects.getVehicleTypesFx({});
        vehicleSelectionEffects.getManufacturersFx({ vehicleType: '1' });
        // Need for claim-stepper, isOpen option don't work dynamic
        if (pluginOptions?.toggleModal) {
            modalsEvents.toggleIsVSMModalOpen(pluginOptions?.isModal);
        }
    }, [pluginOptions]);

    useEffect(() => mainEvents.shouldOpenModal.watch(() => modalsEvents.toggleIsVSMModalOpen(true)), []);

    const handleFormikSubmit = async (values: CurrentFormikValues) => {
        const { constructionPeriod, container, repairType, datECode, kilometers, firstRegistration, vehicleSelects } =
            values;

        // TODO: replace with validation
        if (
            !constructionPeriod ||
            ((repairType === 'APPRAISAL' || repairType === 'ALL') && !container) ||
            (repairType === 'APPRAISAL' && (!kilometers || !firstRegistration))
        ) {
            modalsEvents.toggleIsVSMModalOpen(false);
        } else {
            await equipmentEffects.getAllEquipmentFx({
                requestData: {
                    datECode,
                    constructionTime: +constructionPeriod,
                    container,
                    restriction: pluginOptions?.settings?.restriction
                },
                vinEquipmentIds
            });

            const ManufacturerName = manufacturers.find(item => item.key === vehicleSelects.manufacturer)?.value || '';
            const BaseModelName = baseModels.find(item => item.key === vehicleSelects.baseModel)?.value || '';
            const SubModelName = subModels.find(item => item.key === vehicleSelects.subModel)?.value || '';

            const pluginResult: PluginResult = {
                vehicle: { ...values, ManufacturerName, BaseModelName, SubModelName },
                existingEquipment,
                vinResult: { ...vinResult, registrationNumberRequestResult, paintType: values.paintType },
                valuateFinanceRequest: createValuateFinanceRequest(values, existingEquipment),
                OCRResult: { ...plateReaderResult, ...vinOcrScanDate }
            };

            if (technicalData) {
                pluginResult.technicalData = technicalData;
            }

            pluginOptions?.onComplete(pluginResult);
            modalsEvents.toggleIsVSMModalOpen(false);
        }
    };

    const handleFocusInput = (values: CurrentFormikValues) => {
        setPrevValues(values);
    };

    const handleBlurInput = (values: CurrentFormikValues) => {
        // TODO: fix problem first load when we get data from backend. We have unnecessary request
        if (!_.isEqual(prevValues, values)) {
            values.constructionPeriod && handleFormikSubmit(values);
            setPrevValues(values);
        }
    };

    const toggleIsPluginCarsOpen = () => {
        modalsEvents.toggleIsPluginCarsOpen();
        pluginOptions?.pluginCarsToggleCallback?.();
    };

    const toggleIsAnylineOpen = () => {
        modalsEvents.toggleIsAnylineOpen();
        pluginOptions?.pluginCarsToggleCallback?.();
    };

    return (
        <Formik
            initialValues={formikInitialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleFormikSubmit}
        >
            {({ values }) => (
                <>
                    {!!pluginOptions?.isModal
                        ? null
                        : !pluginOptions?.isInStepper && (
                              <StyledVSMButton onClick={modalsEvents.toggleIsVSMModalOpen}>
                                  {t('vehicleSelectionModalTitle')}
                              </StyledVSMButton>
                          )}

                    <MainModal
                        isOpen={!isPluginCarsOpen && (pluginOptions?.isOpen ?? isVSMModalOpen)}
                        onDismiss={pluginOptions?.onDismiss ?? modalsEvents.toggleIsVSMModalOpen}
                    />
                    <PortalFields onBlur={() => handleBlurInput(values)} onFocus={() => handleFocusInput(values)} />
                    <EquipmentModal />
                    <TechnicalDataModal />
                    <NotificationModal />
                    <SubModelsModal />
                    <VsmPluginCars isPluginCarsOpen={isPluginCarsOpen} pluginCarsToggle={toggleIsPluginCarsOpen} />
                    {isAnylineOpen && <Anyline anylineCarsToggle={toggleIsAnylineOpen} />}
                </>
            )}
        </Formik>
    );
};
