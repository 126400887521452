import styled from 'styled-components/macro';
import { Button as ButtonDefault } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 536px;

    ${media.phone`
        max-width: 100%;
    `}
`;

export const CancelButton = styled(ButtonDefault)`
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: 138px;
    align-self: flex-end;
    text-transform: capitalize;
    margin-bottom: 15px;

    ${media.phone`
        width: 100%;
        align-self: auto;
    `}
`;

export const Description = styled.p`
    margin-bottom: 30px;
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: var(--gray);
`;

export const Buttons = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ${media.phone`
        flex-direction: column;
    `}
`;

export const Button = styled(ButtonDefault)`
    width: calc(50% - 8px);
    background-color: transparent;
    border: ${({ theme }) => `2px solid ${theme.colors.gray_10}`};
    color: ${({ theme }) => theme.colors.gray_300};
    margin-bottom: 16px;

    &:nth-child(2n) {
        margin-left: 16px;

        ${media.phone`
            margin-left: 0;
            margin-top: 10px;
        `}
    }

    ${media.phone`
        width: 100%;
    `}
`;
