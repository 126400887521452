import { TFunction } from 'i18next';

export const SELECT_FIELDS = {
    appointments: 'appointments',
    expiryClaims: 'expiryClaims'
};

export const weekFields = (t: TFunction) => ({
    startDayOfWeek: 1,
    workweek: true,
    hourStart: 8,
    hourEnd: 18,
    daynames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]
});

export const monthFields = (t: TFunction) => ({
    startDayOfWeek: 0,
    workweek: false,
    daynames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')]
});

export const VIEW_OPTIONS = {
    month: 'month',
    today: 'day',
    week: 'week'
};

export const POPUP_STYLES = {
    zIndex: 33333
};

export const GOOGLE_MAPS_LINK = 'https://www.google.com/maps/search/?api=1&query=';

export const CALENDAR_HEIGHT = '700px';
