import styled from 'styled-components/macro';

export const ButtonContainer = styled.div<{
    withoutText?: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
`;

export const ButtonTextWrapper = styled.span`
    margin-left: 12px;
    ${({ theme }) => theme.typography.defaultText}
`;
