import React, { FC, useRef, useState, useEffect } from 'react';

import { useStore } from 'effector-react';
import { Drawer, ConfigProvider, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

// import { useTranslation } from 'react-i18next';

// import 'antd/dist/antd.css';
import 'antd/dist/antd.variable.css';
// import '../../antd.variable.css';
import './graphicDamageSelection.css';
import './styles.css';

import { useSize } from '../../utils/hooks/useResizer';
// import { downloadURL } from '../../utils/downloadURL';
// import { convertRepairPositionsToDamageList } from '../../utils/convertRepairPositionsToDamageList';

import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { graphicDamageSelectionModel } from '../../stores/graphicDamageSelectionModel';
import { RepairPositionsModel } from '../../stores/repairPositionsModel';
import { availableAssemblyGroupsModel } from '../../stores/availableAssemblyGroupsModel';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';

import GraphicGroupsMenu from '../GraphicGroupsMenu/GraphicGroupsMenu';
import { GroupGraphicSVG3 } from '../GroupGraphicSVG3/GroupGraphicSVG3';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms/GraphicSetDamageForms';
import { RepairPositionsList } from '../RepairPositionsList/RepairPositionsList';
import { FilterMenu } from '../FilterMenu/FilterMenu';
import { DefaultDamageMenu } from '../DefaultDamageMenu/DefaultDamageMenu';

import { genericSVGGraphicModel } from '../../stores/genericSVGGraphicModel';
import { onMouseOverModel } from '../../stores/onMouseOverModel';
import { GenericSVGGraphic } from '../GenericSVGGraphic/GenericSVGGraphic';

import { ZoneGraphic } from '../ZoneGraphic/ZoneGraphic';
import { zoneGraphicsModel } from '../../stores/zoneGraphicsModel';

// import { updateFromStaticGrapaService } from '../../stores/updateFromStaticGrapaServiceModel';

import { commonModel } from '../../stores/commonModel';

import { RimsEquipmentView } from '../RimsView/RimsEquipmentView';

import { SvgViewerEvents } from '../GroupGraphicSVG3/GroupGraphicSVG3Types';
import { ToolBars } from './ToolBars';
import { ToolBarsMobile } from './ToolBarsMobile';
import { ZoneGraphicPreview } from '../ZoneGraphic/ZoneGraphicPreview';
import { MaterialLegends } from '../MaterialLegends/MaterialLegends';

// const { SvgLoader } = require('@wedat/react-svgmt');

const widthLeftPanel = 400;
const widthRightPanel = 'var(--widthRightPanel)';

export const GraphicDamageSelection: FC<{}> = () => {
    // const { t } = useTranslation();

    const {
        showGraphicGroupsMenu,
        showRightSideDrawer,
        showFullscreen,
        typeOfGraphic,
        currentAssemblyGroup,
        currentAssemblyGroupObject
    } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);
    const onMouseOverStore = useStore(onMouseOverModel.stores.onMouseOver);

    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);

    const materialList = useStore(availableAssemblyGroupsModel.stores.materialList);

    const currentGenericGraphic = useStore(genericSVGGraphicModel.stores.currentGenericGraphic);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const repairPositionByDatId = useStore(genericSVGGraphicModel.stores.repairPositionByDatId);

    // const loadingFromStaticGraphicService = useStore(updateFromStaticGrapaService.effects.getFilesByDatECodeFx.pending);

    const currentSvgZoneGraphic = useStore(zoneGraphicsModel.stores.currentSvgZoneGraphic);
    const zoneGraphicSVG = currentSvgZoneGraphic?.value;

    const currentZoneDATID = useStore(zoneGraphicsModel.stores.currentZoneDATID);

    const CurrentRightSideView = useStore(rightSideDrawerModel.stores.currentView);

    const { width } = useStore(commonModel.stores.grapaWindowSize);

    let classNameDamageSelectionBody = '';

    const refContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (refContainer.current) commonModel.events.setGrapaRootHtmlElement(refContainer.current);
    }, [refContainer]);

    const refGraphicDamageSelectionBody = useRef<HTMLDivElement>(null);
    const sizeGraphicDamageSelectionBody = useSize(refGraphicDamageSelectionBody);

    const [svgViewerEvents, setSvgViewerEvents] = useState<SvgViewerEvents | undefined>();

    const isMobile = useStore(commonModel.stores.isMobile);
    // const isTablet = useStore(commonModel.stores.isTablet);

    if (!pluginOptions) return null;

    const isShowRightSideDrawer = showRightSideDrawer && !!CurrentRightSideView;

    if (!isMobile) {
        if (showGraphicGroupsMenu && !isShowRightSideDrawer) {
            classNameDamageSelectionBody = 'graphicDamageSelectionBodyLeftMenu';
        }
        if (!showGraphicGroupsMenu && isShowRightSideDrawer) {
            classNameDamageSelectionBody = 'graphicDamageSelectionBodyRightMenu';
        }
        if (showGraphicGroupsMenu && isShowRightSideDrawer) {
            classNameDamageSelectionBody = 'graphicDamageSelectionBodyLeftRightMenu';
        }
    } else {
        classNameDamageSelectionBody = '';
    }

    if (pluginOptions.settings?.useDATTheme) {
        ConfigProvider.config({
            theme: {
                // primaryColor: '#103366'
                primaryColor: '#42A4FF'
            }
        });
        // document.documentElement.style.setProperty('--ant-primary-1', '#e6edfa');
        document.documentElement.style.setProperty('--weDat-grapa-background', '#f0f2f5');
        document.documentElement.style.setProperty('--weDat-grapa-button-group-bar-background', 'rgba(255,255,255,1)');

        document.documentElement.style.setProperty('--weDat-grapa-toolbar-icon', '#465064');
        document.documentElement.style.setProperty('--weDat-grapa-toolbar-icon-onHover', '#2594fe');
        document.documentElement.style.setProperty('--weDat-grapa-toolbar-icon-onActive', '#bcdeff');
        document.documentElement.style.setProperty('--weDat-grapa-toolbar-icon-active', '#e3f2ff');
    }

    return (
        <ConfigProvider>
            <div
                className="weDat-grapa-root"
                ref={refContainer}
                style={
                    showFullscreen
                        ? {
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              margin: 'auto',
                              position: 'fixed',
                              overflow: 'hidden',
                              zIndex: 1001,

                              background: 'var(--weDat-grapa-background)'
                          }
                        : {
                              width: '100%',
                              maxWidth: pluginOptions.settings?.maxWidth || '800px',
                              height: pluginOptions.settings?.height || '800px',
                              margin: 'auto',
                              position: 'relative',
                              overflow: 'hidden',

                              background: 'var(--weDat-grapa-background)'
                          }
                }
            >
                {/* ============================= Modal windows ======================================  */}

                <FilterMenu />
                <DefaultDamageMenu />

                {/* =============================== SVG graphics ================================================  */}

                <div
                    ref={refGraphicDamageSelectionBody}
                    className={
                        (isMobile ? `graphicDamageSelectionBody-mobile ` : `graphicDamageSelectionBody `) +
                        `${classNameDamageSelectionBody}`
                    }
                >
                    {!onMouseOverStore?.currentAssemblyGroup &&
                        zoneGraphicSVG &&
                        (onMouseOverStore?.currentGenericGraphic || typeOfGraphic === 'ZoneGraphic') && (
                            <ZoneGraphic
                                repairPositions={repairPositions}
                                height={sizeGraphicDamageSelectionBody?.height}
                                width={sizeGraphicDamageSelectionBody?.width}
                                graphicSVG={zoneGraphicSVG}
                                onElementClick={datid => {
                                    zoneGraphicsModel.events.setCurrentZoneDATID(datid);
                                    graphicDamageSelectionModel.events.updateStore({
                                        typeOfGraphic: 'FullGraphic'
                                    });
                                }}
                                currentZoneDATID={currentZoneDATID}
                                showFullscreen={showFullscreen}
                                setShowFullScreen={showFullscreen => {
                                    graphicDamageSelectionModel.events.updateStore({
                                        showFullscreen,
                                        currentAssemblyGroupObject: undefined,
                                        currentAssemblyGroupObjects: undefined
                                    });
                                }}
                                // showZoomButton={showZoomButton}
                                setEvents={setSvgViewerEvents}
                            />
                        )}

                    {!onMouseOverStore?.currentAssemblyGroup &&
                        zoneGraphicSVG &&
                        (onMouseOverStore?.currentGenericGraphic || typeOfGraphic === 'RimsGraphic') && (
                            <>
                                <RimsEquipmentView />
                            </>
                        )}

                    {!onMouseOverStore?.currentAssemblyGroup &&
                        (onMouseOverStore?.currentGenericGraphic || typeOfGraphic === 'GenericGraphic') && (
                            <GenericSVGGraphic
                                height={sizeGraphicDamageSelectionBody?.height}
                                width={sizeGraphicDamageSelectionBody?.width}
                                graphicSVG={
                                    onMouseOverStore?.currentGenericGraphic?.graphicSVG ||
                                    currentGenericGraphic?.graphicSVG
                                }
                                selectedElementsId={repairPositionByDatId}
                                onElementClick={id => {
                                    graphicDamageSelectionModel.events.updateStore({
                                        typeOfGraphic: 'GenericGraphic'
                                    });
                                    genericSVGGraphicModel.events.setCurrentGrapaObjectsByDatId(id);
                                    rightSideDrawerModel.events.setCurrentView(GraphicSetDamageForms);
                                }}
                                showFullscreen={showFullscreen}
                                setShowFullScreen={showFullscreen => {
                                    graphicDamageSelectionModel.events.updateStore({
                                        showFullscreen,
                                        currentAssemblyGroupObject: undefined,
                                        currentAssemblyGroupObjects: undefined
                                    });
                                }}
                                // showZoomButton={showZoomButton}
                                setEvents={setSvgViewerEvents}
                            />
                        )}

                    {(!!onMouseOverStore?.currentAssemblyGroup ||
                        !!onMouseOverStore?.currentGenericGraphic ||
                        (typeOfGraphic === 'ZoneGraphic' && !zoneGraphicSVG) ||
                        typeOfGraphic === 'FullGraphic') && (
                        <GroupGraphicSVG3
                            currentAssemblyGroup={onMouseOverStore?.currentAssemblyGroup || currentAssemblyGroup}
                            currentAssemblyGroupObject={
                                !onMouseOverStore?.currentAssemblyGroup ? currentAssemblyGroupObject : undefined
                            }
                            materialList={materialList}
                            repairPositions={repairPositions}
                            highlightDatid={onMouseOverStore?.datId}
                            height={sizeGraphicDamageSelectionBody?.height}
                            width={sizeGraphicDamageSelectionBody?.width}
                            showFullscreen={showFullscreen}
                            setShowFullScreen={showFullscreen => {
                                graphicDamageSelectionModel.events.updateStore({
                                    showFullscreen,
                                    currentAssemblyGroupObject: undefined,
                                    currentAssemblyGroupObjects: undefined
                                });
                            }}
                            // showZoomButton={showZoomButton}
                            setEvents={setSvgViewerEvents}
                        />
                    )}

                    {isMobile ? (
                        <ToolBarsMobile svgViewerEvents={svgViewerEvents} />
                    ) : (
                        <ToolBars svgViewerEvents={svgViewerEvents} />
                    )}
                </div>

                <MaterialLegends isMobile={isMobile} />

                {/* ==============================  Graphic Groups Menu  ==================  */}

                <Drawer
                    maskClosable
                    closable={false}
                    getContainer={false}
                    mask={isMobile}
                    placement="left"
                    style={{ position: 'absolute' }}
                    bodyStyle={{ overflowY: 'hidden' }}
                    title={null}
                    visible={showGraphicGroupsMenu}
                    width={!!width && width < widthLeftPanel ? width : widthLeftPanel}
                    zIndex={showFullscreen ? 1011 : 11}
                    onClose={() => {
                        graphicDamageSelectionModel.events.updateStore({
                            showGraphicGroupsMenu: !showGraphicGroupsMenu
                        });
                    }}
                >
                    {!isMobile && zoneGraphicSVG && (
                        <div style={{ width: '100%', textAlign: 'center', margin: '-16px 0px 0px 0px' }}>
                            <ZoneGraphicPreview
                                repairPositions={repairPositions}
                                height={350}
                                width={350}
                                graphicSVG={zoneGraphicSVG}
                                onElementClick={datid => {
                                    zoneGraphicsModel.events.setCurrentZoneDATID(datid);
                                    graphicDamageSelectionModel.events.updateStore({
                                        typeOfGraphic: 'FullGraphic'
                                    });
                                }}
                                currentZoneDATID={currentZoneDATID}
                            />
                        </div>
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            top: '0px',
                            margin: '8px 16px'
                        }}
                    >
                        <Button
                            size="large"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => {
                                // rightSideDrawerModel.events.backView();
                                graphicDamageSelectionModel.events.updateStore({
                                    showGraphicGroupsMenu: false
                                });
                            }}
                        />
                    </div>

                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            top: !isMobile && zoneGraphicSVG ? '312px' : '56px',
                            bottom: '0px'
                        }}
                    >
                        <GraphicGroupsMenu isMobile={isMobile} />
                    </div>
                </Drawer>

                {/* =============================  Right side view Drawer  ==================  */}

                <Drawer
                    maskClosable
                    closable={false}
                    getContainer={false}
                    mask={isMobile}
                    placement="right"
                    style={{ position: 'absolute', maxWidth: '100%' }}
                    contentWrapperStyle={{ overflowY: 'hidden' }}
                    title={null}
                    visible={isShowRightSideDrawer}
                    // width="256px"
                    width={widthRightPanel}
                    zIndex={showFullscreen ? 1011 : 11}
                    onClose={() => {
                        rightSideDrawerModel.events.clearHistoryAndClose();
                    }}
                >
                    <div
                        style={{
                            margin: '8px',
                            position: 'absolute',
                            bottom: '0px',
                            top: '0px',
                            left: '0px',
                            right: '0px',
                            maxWidth: '100%'
                        }}
                    >
                        {!!CurrentRightSideView ? <CurrentRightSideView /> : <RepairPositionsList />}
                    </div>
                </Drawer>
            </div>
        </ConfigProvider>
    );
};
