export const AGGREGATE_PART_CODE_ITALY = 4004;

export const AGGREGATE_POSITION_ENTRY_TYPE = 'italiaOverwrite';

export const AGGREGATE_DESCRIPTION = 'Units comprising:';

export enum PositionsTypesValues {
    aggregate = 'aggregate',
    aggregateComponent = 'aggregateComponent',
    single = 'single',
    comment = 'comment',
    required = 'required'
}
