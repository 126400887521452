import axios from 'axios';
import { refreshTokenInterceptors } from './utils/refreshTokenInterceptors';

export const bffAxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

refreshTokenInterceptors(bffAxiosInstance);
