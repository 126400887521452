import { createEffect, createEvent, createStore } from 'effector';
import { API2 } from '@dat/api2';

const setKindOfSVG = createEvent<string>();
const kindOfSVG = createStore<string>('').on(setKindOfSVG, (_, newKindOfSVG) => newKindOfSVG);

const getVehicleTypeFX = createEffect(API2.ania.vehicleType.getVehicleType);

export const vehicleSVGStores = {
    kindOfSVG
};
export const vehicleSVGEvents = {
    setKindOfSVG
};
export const vehicleSVGEffects = {
    getVehicleTypeFX
};
