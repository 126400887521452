import deepmerge from 'deepmerge';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { PartialFactorsParametersObject } from '@dat/labour-rates/src/types/factors';
import { VehicleSelectionModalPluginResult } from '@dat/vehicle-selection-modal';

interface Param {
    vehicleSelectionPluginResult: VehicleSelectionModalPluginResult;
    labourRatesFactorsParameters?: PartialFactorsParametersObject;
}

export const createLabourRatesMemoFieldEntry = ({
    vehicleSelectionPluginResult,
    labourRatesFactorsParameters = {}
}: Param): DAT2.Field.EntryForRequest<string, string> => {
    const { paintType } = vehicleSelectionPluginResult.vehicle;
    const vehicleSelectionFactorsParameters: PartialFactorsParametersObject = {
        EuroLacquerFactor: {
            type: String(paintType)
        },
        ManufacturerLacquerFactor: {
            type: String(paintType)
        }
    };

    return {
        key: CONTRACT_ENTRIES_KEYS.MEMO.labourRates,
        value: JSON.stringify(deepmerge(labourRatesFactorsParameters, vehicleSelectionFactorsParameters))
    };
};
