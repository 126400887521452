import React from 'react';
import { MobileBottomMenu } from './MobileBottomMenu';
import { FooterConflicts, FooterConflictsProps } from './FooterConflicts';
import { FooterLegend } from './FooterLegend';
import { BottomWrapper } from './styles';

interface Props extends FooterConflictsProps {
    isMobile: boolean;
    activeTab: string;
    availableSelectedRowsLength: number;
    existingSelectedRowsLength: number;
    transferAvailableToExisting: () => void;
    transferExistingToAvailable: () => void;
}

export const Footer: React.FC<Props> = ({
    isMobile,
    activeTab,
    toggleConflicted,
    removeSelected,
    confirmSelected,
    onlyConflicted,
    conflictedRowsLength,
    ...rest
}) =>
    isMobile ? (
        <MobileBottomMenu
            activeTab={activeTab}
            toggleConflicted={toggleConflicted}
            removeSelected={removeSelected}
            confirmSelected={confirmSelected}
            onlyConflicted={onlyConflicted}
            conflictedRowsLength={conflictedRowsLength}
            {...rest}
        />
    ) : (
        <BottomWrapper>
            <FooterLegend />
            {conflictedRowsLength !== 0 && (
                <FooterConflicts
                    toggleConflicted={toggleConflicted}
                    removeSelected={removeSelected}
                    confirmSelected={confirmSelected}
                    onlyConflicted={onlyConflicted}
                    conflictedRowsLength={conflictedRowsLength}
                />
            )}
        </BottomWrapper>
    );
