import React, { useEffect, useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { useStore } from 'effector-react';

import { SELECT_FIELDS } from '../../constants/constants';
import { schedulerEffects, schedulerEvents, schedulerStores } from '@dat/shared-models/scheduler';
import { sharedUserStores } from '@dat/shared-models/user';
import {
    getDescriptionFields,
    getFormattedExpiryClaimISOString,
    getFormattedTimeISOString
} from '@dat/shared-models/scheduler/utils';
import { contractStores } from '@dat/shared-models/contract';
import { pluginStores } from '../../stores/plugin';

import {
    newAppointmentFieldValues,
    NEW_APPOINTMENT_SCHEMA,
    newExpiryClaimFieldValues,
    NEW_EXPIRY_CLAIM_SCHEMA
} from '../../constants/formValues';

import { NewAppointmentModal, NewExpiryClaimModal } from '../Modals';
import { isAdmin } from '@dat/shared-models/scheduler/constants';
import { SchedulerEvent } from '../../types';

interface Props {
    isEditingView: boolean;
    onClose: () => void;
}

export const CustomEditor: React.FC<Props> = ({ isEditingView, onClose }) => {
    const appointments = useStore(schedulerStores.appointments);
    const selectedFilter = useStore(schedulerStores.selectedTab);
    const selectedEvent = useStore(schedulerStores.selectedEvent);
    const selectedUserID = useStore(schedulerStores.selectedUser);

    const contractId = useStore(contractStores.contractId);
    const contract = useStore(contractStores.contract);
    const customerNumber = useStore(sharedUserStores.customerNumber);
    const pluginOptions = useStore(pluginStores.pluginOptions);

    const [assignee, setAssignee] = useState<number | null>(null);

    const claimID = pluginOptions?.contractId ? pluginOptions.contractId.toString() : contractId.toString();
    const licenseNumber = contract?.Dossier?.Vehicle?.RegistrationData?.LicenseNumber || '';

    const { ownerName, ownerSurname, ownerPhone, ownerEmail } = getDescriptionFields(contract);

    const isAppointmentView = selectedFilter === SELECT_FIELDS.appointments;

    const descriptionPrefix = `${licenseNumber} ${ownerName} ${ownerSurname} ${ownerPhone} ${ownerEmail}`;

    const selectedDates = {
        start: selectedEvent ? new Date(selectedEvent.start) : new Date(),
        end: selectedEvent ? new Date(selectedEvent.end) : new Date()
    };

    const handleAssigneeChange = (id: number) => {
        setAssignee(id);
    };

    const handleAppointmentSubmit = async (values: FormikValues) => {
        try {
            const startDate = getFormattedTimeISOString(values.date);
            const endDate = getFormattedTimeISOString(values.endTime);
            const isDateChanged = new Date(values.date) > new Date((selectedEvent as SchedulerEvent)?.start);
            const endTime = isDateChanged ? startDate : endDate;

            const newAppointment = {
                contractId: selectedEvent?.state || claimID,
                fromDate: startDate,
                toDate: endTime,
                type: 'personal',
                description: values.description || selectedEvent?.description,
                registrationNumber: licenseNumber,
                customerInfo: {
                    name: ownerName,
                    surname: ownerSurname,
                    telephone: ownerPhone,
                    email: ownerEmail
                },
                address: values.address,
                city: values.city,
                zip: values.zip,
                createdBy: customerNumber,
                assignee: assignee || selectedUserID
            };

            let updatedAppointments: Omit<DAT2.Appointment, 'id'>[];

            if (isEditingView) {
                updatedAppointments = appointments.appointmentDates.map(appointment =>
                    appointment.id === selectedEvent?.id ? newAppointment : appointment
                );
            } else {
                updatedAppointments = [...appointments.appointmentDates, newAppointment];
            }

            await schedulerEffects.createOrUpdateAppointments({ value: updatedAppointments });
            await schedulerEffects.getAppointmentsList();
        } catch (e) {
            console.error(e, 'error on appointment creation');
        }
    };

    const handleExpiryClaimSubmit = async (formValues: FormikValues) => {
        try {
            const expirationDate = getFormattedExpiryClaimISOString(formValues.expiryDate);

            const newExpiryClaim = {
                contractId: selectedEvent?.state || claimID,
                date: expirationDate,
                type: 'personal',
                description: selectedEvent?.description || formValues.description,
                registrationNumber: licenseNumber,
                customerInfo: {
                    name: ownerName,
                    surname: ownerSurname,
                    telephone: ownerPhone,
                    email: ownerEmail
                },
                address: formValues.address,
                city: formValues.city,
                zip: formValues.zip,
                createdBy: customerNumber,
                assignee: assignee || selectedUserID
            };

            const isExistingExpiryClaim = appointments.expiryClaimsDate.find(
                expiryClaim => expiryClaim.contractId === newExpiryClaim.contractId
            );
            const updatedExpiryDates = isExistingExpiryClaim
                ? appointments.expiryClaimsDate.map(expiryClaim =>
                      expiryClaim.contractId === newExpiryClaim.contractId ? newExpiryClaim : expiryClaim
                  )
                : [...appointments.expiryClaimsDate, newExpiryClaim];

            await schedulerEffects.createExpirationDate({ value: updatedExpiryDates });
            await schedulerEffects.getAppointmentsList();
        } catch (e) {
            console.error(e, 'error on expiry claim creation');
        }
    };

    useEffect(
        () => () => {
            schedulerEvents.updateSelectedEvent(null);
        },
        []
    );

    return isAppointmentView ? (
        <Formik
            initialValues={newAppointmentFieldValues(selectedDates, selectedEvent)}
            validationSchema={NEW_APPOINTMENT_SCHEMA}
            onSubmit={handleAppointmentSubmit}
            render={({ values, setFieldValue, errors, submitForm, resetForm }) => (
                <NewAppointmentModal
                    values={values}
                    isEditing={isEditingView}
                    error={Object.values(errors)?.[0]}
                    descriptionPrefix={descriptionPrefix}
                    onDateChange={value => setFieldValue('date', value)}
                    onStartTimeChange={value => setFieldValue('startTime', value)}
                    onEndTimeChange={value => setFieldValue('endTime', value)}
                    onDescriptionChange={value => setFieldValue('description', value)}
                    onAddressChange={value => setFieldValue('address', value)}
                    onCityChange={value => setFieldValue('city', value)}
                    onZipChange={value => setFieldValue('zip', value)}
                    onAssigneeChange={handleAssigneeChange}
                    isAdmin={isAdmin(customerNumber)}
                    onSubmit={() => {
                        submitForm();
                        onClose();
                    }}
                    onClose={() => {
                        resetForm();
                        onClose();
                    }}
                />
            )}
        />
    ) : (
        <Formik
            initialValues={newExpiryClaimFieldValues(selectedDates, selectedEvent)}
            validationSchema={NEW_EXPIRY_CLAIM_SCHEMA}
            onSubmit={handleExpiryClaimSubmit}
            render={({ values, setFieldValue, errors, submitForm, resetForm }) => (
                <NewExpiryClaimModal
                    error={Object.values(errors)?.[0]}
                    descriptionPrefix={descriptionPrefix}
                    isEditing={isEditingView}
                    values={values}
                    isAdmin={isAdmin(customerNumber)}
                    onAssigneeChange={handleAssigneeChange}
                    onExpiredDateChange={value => setFieldValue('expiryDate', value)}
                    onDescriptionChange={value => setFieldValue('description', value)}
                    onAddressChange={value => setFieldValue('address', value)}
                    onCityChange={value => setFieldValue('city', value)}
                    onZipChange={value => setFieldValue('zip', value)}
                    onSubmit={() => {
                        submitForm();
                        onClose();
                    }}
                    onClose={() => {
                        resetForm();
                        onClose();
                    }}
                />
            )}
        />
    );
};
