import React from 'react';
import { useStore } from 'effector-react';

import { statusesEvents, statusesStores } from '../../../stores/statuses';
import { modalsEvents, modalsStores } from '../../../stores/ui/modals';

import { ContractStatusChangeModal } from '@dat/smart-components/ContractStatusChangeModal';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { SlideUpWrap } from './styles';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';

export const StatusChange: React.FC = () => {
    const contractId = useStore(statusesStores.contractId);
    const initialStatusId = useStore(statusesStores.initialStatusId);
    const isOpen = useStore(modalsStores.isStatusModalOpen);
    const isTablet = useMedia(sizes.tablet);

    const contentRender = () => (
        <ContractStatusChangeModal
            contractId={contractId}
            initialStatusId={initialStatusId || undefined}
            onStatusChange={statusesEvents.statusChanged}
            onStatusChangeFailed={modalsEvents.toggleIsStatusModalOpen}
            isOpen={isOpen}
            onDismiss={modalsEvents.toggleIsStatusModalOpen}
        />
    );

    return isTablet ? (
        <SlideUpMenu isOpen={isOpen} closeSlideUpMenu={modalsEvents.toggleIsStatusModalOpen}>
            <SlideUpWrap>{contentRender()}</SlideUpWrap>
        </SlideUpMenu>
    ) : (
        contentRender()
    );
};
