import * as myClaim from './services/myClaim';
import * as vehicleSelection from './services/vehicleSelection';
import * as vehicleIdentification from './services/vehicleIdentification';
import * as vehicleImages from './services/vehicleImages';
import * as conversionOperations from './services/conversionOperations';
import * as vehicleRepair from './services/vehicleRepair';

export const API = {
    vehicleSelection,
    vehicleIdentification,
    vehicleImages,
    myClaim,
    conversionOperations,
    vehicleRepair
};
