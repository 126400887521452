import React, { FC } from 'react';
import _ from 'lodash';
import { InputFieldProps } from '@wedat/ui-kit/Formik';
import { InputField } from '@wedat/ui-kit/Formik/InputField';
import Search from '@wedat/ui-kit/assets/Icon/Search';
import Vector from '@wedat/ui-kit/assets/Icon/Vector';
import {
    InputFieldWithButtonContainer,
    ButtonsContainer,
    InputContainer,
    StyledButtonIcon,
    OCRIconWrapper,
    Label
} from './styles';
import { useField } from 'formik';
import { CameraIcon } from '@wedat/ui-kit/components/Icons';

export interface InputFieldWithButtonsProps extends InputFieldProps {
    onSearch?: (value: string) => void;
    onBookClick?: () => void;
    onOCRUpload?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    isSearchDisabled?: boolean;
    withBookButton?: boolean;
    withOCRButton?: boolean;
    withSearchButton?: boolean;
    withNumberPlate?: boolean;
    isBookDisabled?: boolean;
    doubleButton?: boolean;
    uppercase?: boolean;
    OCRclick?: () => void;
    isMobile?: boolean;
}

export const InputFieldWithButtons: FC<InputFieldWithButtonsProps> = ({
    name,
    onSearch,
    onBookClick,
    onOCRUpload,
    isSearchDisabled,
    withBookButton,
    withOCRButton,
    withNumberPlate = true,
    isBookDisabled = true,
    withSearchButton = true,
    OCRclick,
    isMobile,
    ...rest
}) => {
    const [field] = useField(name);

    return (
        <InputFieldWithButtonContainer withNumberPlate={withNumberPlate}>
            <InputContainer fullWidth={!(withSearchButton && withBookButton)}>
                <InputField name={name} {...rest} />
                {withOCRButton && (
                    <OCRIconWrapper onClick={OCRclick}>
                        <Label>
                            <CameraIcon />
                            {!isMobile && onOCRUpload && (
                                <input hidden type="file" accept="image/*" onChange={onOCRUpload} />
                            )}
                        </Label>
                    </OCRIconWrapper>
                )}
            </InputContainer>
            <ButtonsContainer>
                {withNumberPlate && (
                    <StyledButtonIcon
                        onClick={() => onSearch?.(field.value)}
                        typeStyle={{ type: 'primary' }}
                        disabled={isSearchDisabled || !field.value}
                    >
                        <Search />
                    </StyledButtonIcon>
                )}
                {withBookButton && (
                    <StyledButtonIcon onClick={onBookClick} typeStyle={{ type: 'secondary' }} disabled={isBookDisabled}>
                        <Vector />
                    </StyledButtonIcon>
                )}
            </ButtonsContainer>
        </InputFieldWithButtonContainer>
    );
};
