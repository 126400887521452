import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';

import { DatepickerHeader } from './DatepickerHeader';
import { Input } from '../Input';
import { CalendarIcon } from '../Icons';

import { DatepickerParsedValue, DatepickerUnparsedValue } from './types';
import { InputProps } from '../Input/types';

import { DEFAULT_DATE_FORMAT, DEFAULT_LOCALE, DEFAULT_INPUT_MASK, DEFAULT_DATE_FORMAT_WITH_TIME } from './constants';

import {
    getMonthsOptions,
    getYearsOptions,
    getValidValue,
    isValuesEqualByDate,
    parseValue,
    getTodayTranslate,
    getTimeTranslate
} from './utils';

import 'react-datepicker/dist/react-datepicker.css';
import { GlobalStyle, Icon, StylesWrapper, Wrapper } from './styles';

export type DatepickerProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
    /* Called on every input/calendar change if value can be parsed into valid date */
    onChange?: (value: DatepickerParsedValue) => void;
    /* Called on blur/calendar-close with valid date (between minDate & maxDate) if date is changed */
    onChangeAfterLeave?: (value: DatepickerParsedValue) => void;
    value?: DatepickerUnparsedValue;
    label?: string;
    currentLocale?: DAT2.Locale;
    dateFormat?: string;
    inputMask?: string | RegExp;
    showTimeSelect?: boolean;
};

const DatepickerInput = React.forwardRef<InputProps, any>((props, ref) => (
    <Input {...props} withIcon ref={ref} title="" />
));

export const Datepicker: FC<DatepickerProps> = ({
    value,
    onChange,
    onChangeAfterLeave,
    onBlur,
    onCalendarClose,
    minDate,
    maxDate,
    label,
    popperPlacement,
    portalId,
    currentLocale = DEFAULT_LOCALE,
    dateFormat,
    inputMask = DEFAULT_INPUT_MASK,
    showTimeSelect,
    ...rest
}) => {
    const parsedValue = useMemo(() => parseValue(value), [value]);
    const dateFormatDefault = showTimeSelect ? DEFAULT_DATE_FORMAT_WITH_TIME : DEFAULT_DATE_FORMAT;
    const [unvalidatedValue, setUnvalidatedValue] = useState<DatepickerParsedValue>(parsedValue);

    const yearsOptions = useMemo(() => getYearsOptions(), []);
    const monthsOptions = useMemo(() => getMonthsOptions(currentLocale), [currentLocale]);

    const datepickerRef = useRef<ReactDatePicker>(null);

    useEffect(() => {
        setUnvalidatedValue(parsedValue);
    }, [parsedValue]);

    const handleChange = (value: DatepickerUnparsedValue) => {
        value = parseValue(value);
        setUnvalidatedValue(value);
        onChange?.(value);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        handleChangeAfterLeave();
        onBlur?.(e);
    };
    const handleCalendarClose = () => {
        handleChangeAfterLeave();
        onCalendarClose?.();
    };
    const handleChangeAfterLeave = () => {
        const validValue = getValidValue({ unvalidatedValue, minDate, maxDate });

        setUnvalidatedValue(validValue);

        if (!isValuesEqualByDate(validValue, parsedValue, showTimeSelect)) {
            onChangeAfterLeave?.(validValue);
        }
    };

    const openOnCalendarIconClick = () => {
        const datepickerElement = datepickerRef?.current;

        datepickerElement?.setOpen(true);
    };

    return (
        <Wrapper>
            {portalId && <GlobalStyle showTimeSelect={showTimeSelect} />}
            <StylesWrapper showTimeSelect={showTimeSelect}>
                <DatePicker
                    {...rest}
                    ref={datepickerRef}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onCalendarClose={handleCalendarClose}
                    selected={unvalidatedValue}
                    dateFormat={dateFormat || dateFormatDefault}
                    minDate={minDate}
                    maxDate={maxDate}
                    showTimeSelect={showTimeSelect}
                    timeFormat="hh:mm aa"
                    timeCaption={getTimeTranslate(currentLocale)}
                    placeholderText={dateFormat}
                    customInput={<DatepickerInput mask={showTimeSelect ? '' : inputMask} label={label} />}
                    renderCustomHeader={props => (
                        <DatepickerHeader monthsOptions={monthsOptions} yearsOptions={yearsOptions} {...props} />
                    )}
                    popperPlacement={popperPlacement ? popperPlacement : 'bottom-start'}
                    showPopperArrow={false}
                    autoComplete="off"
                    locale={currentLocale}
                    portalId={portalId}
                    todayButton={getTodayTranslate(currentLocale)}
                />
                <Icon onClick={openOnCalendarIconClick}>
                    {CalendarIcon ? <CalendarIcon /> : null} {/* For storybook */}
                </Icon>
            </StylesWrapper>
        </Wrapper>
    );
};
