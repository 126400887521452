import styled, { css } from 'styled-components/macro';

interface HelpModalProps {
    isLandscape: boolean;
}

export const Content = styled.div`
    width: 343px;
    margin-top: 40px;
`;

export const HelpModalStyled = styled.div<HelpModalProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1 1 auto;

    width: 100%;

    background-color: ${({ theme }) => theme.colors.white};

    overflow: auto;

    ${({ isLandscape }) =>
        isLandscape &&
        css`
            padding-top: 30px;
            padding-bottom: 30px;
            flex-direction: row;

            ${Content} {
                margin-top: 0;
                margin-left: 40px;
            }
        `}
`;

export const Video = styled.video`
    width: 284px;
    height: 284px;
    object-fit: contain;
`;

export const Source = styled.source``;

export const Image = styled.img`
    width: 284px;
    height: 284px;
    object-fit: contain;
`;

export const Title = styled.h3`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.deepBlue['800']};
`;

export const Description = styled.p`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.deepBlue['800']};
`;

export const Button = styled.button`
    position: absolute;
    bottom: 56px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: none;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.deepBlue['800']};
    border-radius: 10px;
`;
