import React, { useRef, useState, useEffect, CSSProperties } from 'react';

import { GroupGraphicSVG3Props } from './GroupGraphicSVG3Types';
import { Button } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useStore } from 'effector-react';
import {
    graphicDamageSelectionModel,
    GraphicDamageSelectionModelStore
} from '../../stores/graphicDamageSelectionModel';

import './GroupGraphicSVG3_0.css';
import { DatidInfo, ObjectInfoSVG } from '../../types/svgGrapaTypes';
import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { maxDelayDoubleClickMs, maxTouchClickMs, scaleFactor, zoomOnDoubleClick } from '../../initData/eventConfig';
import { useTranslation } from 'react-i18next';
import { DatECodeMaterialInfo } from '../../types/graphicTypes';
import { rightSideDrawerModel } from '../../stores/rightSideDrawerModel';
import { GraphicSetDamageForms } from '../GraphicSetDamageForms/GraphicSetDamageForms';
import { onMouseOverModel } from '../../stores/onMouseOverModel';
import { GraphicTooltip, GraphicTooltipType } from '../GraphicDamageSelection/GraphicTooltip';
import { getTooltipPosition } from '../../utils/getTooltipPosition';
import { getLanguage } from '../../utils/datLocale';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { commonModel } from '../../stores/commonModel';
import { PartsInfo } from '../PartsInfo/PartsInfo';
import { PartList, partsInfoModel } from '../../stores/partsInfoModel';

var { ReactSVGPanZoom, TOOL_AUTO } = require('react-svg-pan-zoom');
// import { SvgLoader, SvgProxy } from '@wedat/react-svgmt';
const { SvgLoader, SvgProxy } = require('@wedat/react-svgmt');

let timer: NodeJS.Timeout;
let timerSvg: NodeJS.Timeout;
let clicks = 0;
let clicksSvg = 0;
let onTouchStartTime = 0;

enum UsingStyles {
    materialMetal = 'materialMetal',
    materialGlass = 'materialGlass',
    materialBrakeLight = 'materialBrakeLight',

    materialPlastic = 'materialPlastic',
    materialGlassFibre = 'materialGlassFibre',
    materialCarbon = 'materialCarbon',
    materialPlasticWithoutText = 'materialPlasticWithoutText',
    materialHardenedSteel = 'materialHardenedSteel',
    materialLightMetal = 'materialLightMetal',
    materialAluminium = 'materialAluminium',
    materialMagnesium = 'materialMagnesium',
    materialTitan = 'materialTitan',
    materialInternalCovers = 'materialInternalCovers',
    materialHighVoltage = 'materialHighVoltage',

    leftRighStSelectedElements = 'leftRighStSelectedElements',
    leftStSelectedElements = 'leftStSelectedElements',
    rightStSelectedElements = 'rightStSelectedElements',

    selectableElement = 'selectableElement',
    selectedElement = 'selectedElement',
    DATElement = 'DATElement',
    deSelectedElement = 'deSelectedElement',
    unfilteredElement = 'unfilteredElement',
    unfilteredElementDatId = 'unfilteredElementDatId'
}

const listAvailMaterial: { [key: string]: string } = {
    S01: UsingStyles.materialHardenedSteel, // "Stahl hochfest === acero muy resistente", "S01": "very resistant steel",
    I01: UsingStyles.materialInternalCovers, // "Verkleidung innen === Revestimiento interior","I01": "Inner lining",
    K01: UsingStyles.materialPlastic, // "Kunststoff === material sintético","K01": "synthetic material",
    K03: UsingStyles.materialCarbon, // "Kohlefaser === Fibra de carbono","K03": "Carbon fiber",
    L04: UsingStyles.materialTitan, // "Titan === Titanio","L04": "Titanium",
    L02: UsingStyles.materialAluminium, // "Aluminium === aluminio","L02": "aluminum",
    K05: UsingStyles.materialPlasticWithoutText, // "Kunststoff ohne Text === material sintético sin texto", "K05": "synthetic material without text",
    HV1: UsingStyles.materialHighVoltage, // "Hochvoltkomponente === Componente de alto voltaje", "HV1": "High voltage component",
    K02: UsingStyles.materialGlassFibre, // "Glasfaser === Fibra de vidrio","K02": "Fiberglass",
    L03: UsingStyles.materialMagnesium, // "Magnesium === Magnesio","L03": "Magnesium",
    L01: UsingStyles.materialLightMetal // "Leichtmetall === metal ligero",  "L01": "light metal
};

const listGlassDvn = [
    44910, // Windshield
    55010, // rear window
    45611, // Headlight compl. L
    45612, // Headlight compl. R
    45901, // Front fog lights L
    45902, // Front fog lights R
    20451, //  Door glass fr. L
    20452, //  Door glass fr. R
    24452, //  Door glass rr R
    24451, //  Door glass rr L
    5611, //  Side glazing L
    5612, //  Side glazing R

    21291,
    21381,
    21292,
    21382,
    25291,
    25381,
    25292,
    25382
];

const listBrakeLightDvn = [
    55511, // Rear light inside L
    55512, // Rear light inside R
    55411, // Rear light outside L
    55412, // Rear light outside R
    55521, // Rear light L
    55522, // Rear light R
    55900, // Third brake light
    55905, // Brake light
    55611, // Tail light inside L
    55612, // Tail light inside R
    55651, // todo from myClaim source nee to be check
    55652, // todo from myClaim source nee to be check
    55911 // todo from myClaim source nee to be check
];

function getMaterialClass(objectsInfo: ObjectInfoSVG, materialList: DatECodeMaterialInfo) {
    let result = '';

    if (objectsInfo.dvnLeft?.dvn && listBrakeLightDvn.includes(objectsInfo.dvnLeft?.dvn)) {
        return UsingStyles.materialBrakeLight;
    }
    if (objectsInfo.dvnRight?.dvn && listBrakeLightDvn.includes(objectsInfo.dvnRight?.dvn)) {
        return UsingStyles.materialBrakeLight;
    }

    if (objectsInfo.dvnLeft?.dvn && listGlassDvn.includes(objectsInfo.dvnLeft?.dvn)) {
        return UsingStyles.materialGlass;
    }
    if (objectsInfo.dvnRight?.dvn && listGlassDvn.includes(objectsInfo.dvnRight?.dvn)) {
        return UsingStyles.materialGlass;
    }

    let foundMaterial;
    if (objectsInfo.dvnLeft?.dvn) foundMaterial = materialList.dvnMaterialList[objectsInfo.dvnLeft?.dvn.toString()];
    if (objectsInfo.dvnRight?.dvn) foundMaterial = materialList.dvnMaterialList[objectsInfo.dvnRight?.dvn.toString()];

    if (foundMaterial) {
        result = listAvailMaterial[foundMaterial];
        if (result) return result;
    }

    if (
        (objectsInfo.dvnLeft && objectsInfo.dvnLeft.etBauart === 0) ||
        (objectsInfo.dvnRight && objectsInfo.dvnRight.etBauart === 0)
    )
        result = UsingStyles.materialPlastic;
    else if (
        (objectsInfo.dvnLeft && objectsInfo.dvnLeft.etBauart === 4) ||
        (objectsInfo.dvnRight && objectsInfo.dvnRight.etBauart === 4)
    )
        result = UsingStyles.materialPlasticWithoutText;
    else if (
        (objectsInfo.dvnLeft && objectsInfo.dvnLeft.etBauart === 5) ||
        (objectsInfo.dvnRight && objectsInfo.dvnRight.etBauart === 5)
    )
        result = UsingStyles.materialHardenedSteel;
    else if (
        (objectsInfo.dvnLeft && objectsInfo.dvnLeft.etBauart === 6) ||
        (objectsInfo.dvnRight && objectsInfo.dvnRight.etBauart === 6)
    )
        // result = UsingStyles.materialLightMetal;
        result = UsingStyles.materialMetal;
    else if (
        (objectsInfo.dvnLeft && objectsInfo.dvnLeft.etBauart === 7) ||
        (objectsInfo.dvnRight && objectsInfo.dvnRight.etBauart === 7)
    )
        result = UsingStyles.materialAluminium;

    return result;
}

function getSelectedClass(objectsInfos: ObjectInfoSVG[], repairPositions: RepairPosition[]) {
    let isSelectedLeft = false;
    let isSelectedRight = false;

    let isLeftPresent = false;
    let isRightPresent = false;

    objectsInfos.forEach(objectsInfo => {
        if (objectsInfo.dvnLeft) {
            isLeftPresent = true;
            if (!isSelectedLeft)
                isSelectedLeft = !!repairPositions.find(repPos => repPos.DATProcessId === objectsInfo.dvnLeft?.dvn);
        }
        if (objectsInfo.dvnRight) {
            isRightPresent = true;
            if (!isSelectedRight)
                isSelectedRight = !!repairPositions.find(repPos => repPos.DATProcessId === objectsInfo.dvnRight?.dvn);
        }
    });

    if (
        (isSelectedRight && !isLeftPresent) ||
        (isSelectedLeft && !isRightPresent) ||
        (isSelectedRight && isSelectedLeft)
    )
        return UsingStyles.leftRighStSelectedElements;

    if (isSelectedRight && !isSelectedLeft) return UsingStyles.rightStSelectedElements;

    if (!isSelectedRight && isSelectedLeft) return UsingStyles.leftStSelectedElements;
}

function isPasFilterByLeftRiteAndAvailableDamages(
    datidInfo: DatidInfo,
    graphicDamageSelectionModelStore: GraphicDamageSelectionModelStore
): boolean {
    const { defaultDamages, isSelectingLeft, isSelectingRight, isSelectingSingle } = graphicDamageSelectionModelStore;

    // const leftRightFilterNotSelect = !isSelectingLeft && !isSelectingRight && !isSelectingSingle;
    const isLeftRightFilterSelect = isSelectingLeft || isSelectingRight || isSelectingSingle;

    if (!isLeftRightFilterSelect && !defaultDamages?.length) return true;
    if (isSelectingLeft && isSelectingRight && isSelectingSingle && !defaultDamages?.length) return true;

    let isLeftPresent = !!datidInfo.objectInfoSVG.find(
        objectsInfo =>
            !!objectsInfo.dvnLeft &&
            (!defaultDamages ||
                defaultDamages.length === 0 ||
                defaultDamages.find(defDam => !!objectsInfo.dvnLeft?.rcs.rc.find(rc => rc === defDam.codeRC)))
    );
    let isRightPresent = !!datidInfo.objectInfoSVG.find(
        objectsInfo =>
            !!objectsInfo.dvnRight &&
            (!defaultDamages ||
                defaultDamages.length === 0 ||
                defaultDamages.find(defDam => !!objectsInfo.dvnRight?.rcs.rc.find(rc => rc === defDam.codeRC)))
    );

    if (!isLeftRightFilterSelect && (isLeftPresent || isRightPresent)) return true;

    if (isSelectingSingle && isLeftPresent !== isRightPresent) return true;
    if (isSelectingRight && isLeftPresent && isRightPresent) return true;
    if (isSelectingLeft && isLeftPresent && isRightPresent) return true;

    return false;
}

export const GroupGraphicSVG3 = ({
    repairPositions,
    currentAssemblyGroup,
    currentAssemblyGroupObject,
    materialList,
    highlightDatid,
    width,
    height,
    showFullscreen,
    // setShowFullScreen,
    showZoomButton = false,
    setEvents
}: GroupGraphicSVG3Props) => {
    const Viewer = useRef(null);
    const [value, setValue] = useState<Partial<any>>({});
    const [tool, setTool] = useState<any>(TOOL_AUTO);
    const [tooltipOptions, setTooltipOptions] = useState<GraphicTooltipType | null>(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (Viewer.current) {
            (Viewer.current as any).fitToViewer();
            setEvents?.({
                zoomIn: () => (Viewer.current as any).zoomOnViewerCenter(scaleFactor),
                zoomOut: () => (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor),
                fitToViewer: () => (Viewer.current as any).fitToViewer()
            });
        }
    }, [Viewer, showFullscreen, setEvents]);

    const localesParams = useStore(pluginOptionsModel.stores.localesParams);
    const graphicDamageSelectionModelStore = useStore(
        graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
    );

    const isLaptop = useStore(commonModel.stores.isLaptop);

    const { defaultDamages, isSelectingLeft, isSelectingRight, isSelectingSingle } = graphicDamageSelectionModelStore;
    const isFilterEnable = isSelectingLeft || isSelectingRight || isSelectingSingle || defaultDamages?.length;
    if (!currentAssemblyGroup?.availableDate || !width || !height)
        return (
            <div
                style={{
                    width: '100%',
                    margin: '10em 0em 0em 0em',
                    display: 'inline-block'
                }}
            >
                {/* <Spin spinning size="large" /> */}
                {t('groupGraphic')}
            </div>
        );

    const onSetValue = (value: any) => {
        let deltaX = value.SVGWidth * 0.0;
        let deltaY = value.SVGHeight * 0.0;

        let maxX;
        let minX;
        if (value.a > 1) {
            maxX = deltaX;
            minX = -deltaX + value.SVGWidth - value.SVGWidth * value.a;
        } else {
            maxX = deltaX + value.SVGWidth - value.SVGWidth * value.a;
            minX = -deltaX;
        }

        let maxY;
        let minY;
        if (value.d > 1) {
            maxY = deltaY;
            minY = -deltaY + value.SVGHeight - value.SVGHeight * value.d;
        } else {
            maxY = deltaY + value.SVGHeight - value.SVGHeight * value.d;
            minY = -deltaY;
        }

        let e = value.e > maxX ? maxX : value.e < minX ? minX : value.e;
        let f = value.f > maxY ? maxY : value.f < minY ? minY : value.f;

        setValue({ ...value, e, f });
    };

    const onElementSelect = (datidInfo: DatidInfo) => {
        graphicDamageSelectionModel.events.setCurrentObjectByDATIDandGroup({
            datid: datidInfo.datid
        });
        rightSideDrawerModel.events.setCurrentView(GraphicSetDamageForms);
        onMouseOverModel.events.setHighlightObjectByDVN([+datidInfo.datid]);
    };

    const onSvgClick = (e: any, touch = false) => {
        clicksSvg++; //count clicks
        if (clicksSvg === 1) {
            timerSvg = setTimeout(function () {
                clicksSvg = 0;
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clearTimeout(timerSvg); //prevent single-click action
            clicks = 0; //after action performed, reset counter
            clicksSvg = 0;
            if (e.scaleFactor !== 1) (Viewer.current as any).reset();
            else {
                // if (!showFullscreen) setShowFullScreen(true);
                // else
                touch
                    ? (Viewer.current as any).zoom(e.value.endX, e.value.endY, zoomOnDoubleClick)
                    : (Viewer.current as any).zoom(e.point.x, e.point.y, zoomOnDoubleClick);
            }
        }
    };

    const onElementClick = (_e: any, datidInfo: DatidInfo) => {
        console.log('onClick element');
        clicks++; //count clicks
        if (clicks === 1) {
            timer = setTimeout(function () {
                clicks = 0;
                if (isPasFilterByLeftRiteAndAvailableDamages(datidInfo, graphicDamageSelectionModelStore)) {
                    onElementSelect(datidInfo);
                }
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clicks = 0; //after action performed, reset counter
        }
    };

    const onElementMouseEnter = (e: any, datidInfo: DatidInfo) => {
        const languageCode = getLanguage(localesParams?.language).languageCode || 'ENG';

        const tooltipText = datidInfo.objectInfoSVG
            .map(
                info =>
                    (info.dvnLeft?.dvn ? info.dvnLeft?.dvn + '/' : '') +
                    (info.dvnRight?.dvn || '') +
                    ' ' +
                    info.title?.langs.lang.find(lng => lng.attr.languageCode === languageCode)?.['#text']
            )
            .join('\n');

        setTooltipOptions({
            text: tooltipText,
            ...getTooltipPosition(e)
        });
    };

    const onElementMouseLeave = (_e: any) => {
        setTooltipOptions(null);
    };

    const onElementContextMenu = async (e: any, datidInfo: DatidInfo) => {
        const languageCode = getLanguage(localesParams?.language).languageCode || 'ENG';
        const partList: PartList[] = datidInfo.objectInfoSVG
            .filter(part => {
                const dvnLR = part.dvnLeft || part.dvnRight;
                return dvnLR?.rcs.rc.find(item => item === 'E');
            })
            .map(part => ({
                datProcessId: part.dvnLeft?.dvn || part.dvnRight?.dvn || 0,
                partName: part.title?.langs.lang.find(lng => lng.attr.languageCode === languageCode)?.['#text']
            }));
        if (!partList[0].datProcessId) return;

        partsInfoModel.events.setCurrentPartList(partList);
        rightSideDrawerModel.events.setCurrentView(PartsInfo);

        e.preventDefault();
    };

    const currentZoom = value.a;

    const barButtonStyle: CSSProperties = {
        // marginBottom: '6px'
        margin: '8px',
        border: 'none'
    };

    return (
        <div>
            {showZoomButton && (
                <div
                    style={{
                        // textAlign: 'left',
                        position: 'absolute',
                        // left: '0',
                        bottom: '0',
                        right: '90px',
                        // right: '-48px',
                        zIndex: showFullscreen ? 1011 : 11,
                        backgroundColor: 'rgba(255, 255, 255, 0.98)',
                        // padding: '8px'
                        margin: '12px',
                        borderRadius: '8px'
                    }}
                >
                    <Button
                        style={barButtonStyle}
                        icon={<ZoomInOutlined />}
                        onClick={_e => {
                            (Viewer.current as any).zoomOnViewerCenter(scaleFactor);
                        }}
                    />
                    {/* <br /> */}
                    <Button
                        style={barButtonStyle}
                        icon={<ZoomOutOutlined />}
                        onClick={() => {
                            (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor);
                        }}
                    />
                    {/* {value.d !== 1 && ( */}
                    {true && (
                        <>
                            {/* <br /> */}
                            <Button
                                disabled={currentZoom === 1}
                                style={barButtonStyle}
                                icon={<FullscreenOutlined />}
                                onClick={() => {
                                    (Viewer.current as any).fitToViewer();
                                }}
                            />
                        </>
                    )}
                </div>
            )}
            {isLaptop && tooltipOptions && <GraphicTooltip {...tooltipOptions} />}
            <ReactSVGPanZoom
                value={value as any}
                onChangeValue={onSetValue}
                ref={Viewer}
                // SVGBackground="#f0f2f5"
                // background="#f0f2f5"
                // background="#ffffff"
                SVGBackground="var(--weDat-grapa-background)"
                background="var(--weDat-grapa-background)"
                customMiniature={() => null}
                customToolbar={() => null}
                width={width}
                height={height}
                scaleFactorMax={10}
                scaleFactorMin={0.6} // 0.6
                scaleFactor={scaleFactor}
                scaleFactorOnWheel={scaleFactor}
                detectWheel={showFullscreen}
                // detectAutoPan={showFullscreen}
                detectAutoPan={true}
                detectPinchGesture={showFullscreen}
                // tool="auto"
                // tool="pan"
                // tool={(showFullscreen ? tool : 'none') as any}
                tool={(currentZoom !== 1 ? tool : 'none') as any}
                onChangeTool={setTool}
                preventPanOutside={true}
                disableDoubleClickZoomWithToolAuto={true}
                onTouchStart={(_e: any) => {
                    onTouchStartTime = new Date().getTime();
                }}
                onTouchEnd={(e: any) => {
                    if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                    if (currentZoom !== 1) onSvgClick(e, true);
                }}
                onClick={onSvgClick}
            >
                <svg height={height} width={width}>
                    <SvgLoader svgXML={currentAssemblyGroup.svg}>
                        {currentAssemblyGroup.datidInfo?.map(datidInfo => (
                            <div key={datidInfo.datid}>
                                <SvgProxy
                                    // key={datidInfo.datid}
                                    class={'childWithoutEvent'}
                                    // selector={`#${datidInfo.datid} *:not(:first-child)`}
                                    selector={
                                        `#${datidInfo.datid} > g > path:not(:first-child) ` +
                                        `, #${datidInfo.datid} > g > g > * `
                                    }
                                />

                                <SvgProxy
                                    // key={datidInfo.datid}
                                    class={
                                        datidInfo.class +
                                        (' ' + UsingStyles.DATElement) +
                                        (' ' + getMaterialClass(datidInfo.objectInfoSVG[0], materialList)) +
                                        (isPasFilterByLeftRiteAndAvailableDamages(
                                            datidInfo,
                                            graphicDamageSelectionModelStore
                                        )
                                            ? ' ' + UsingStyles.selectableElement
                                            : ' ' + UsingStyles.unfilteredElementDatId) +
                                        (' ' + getSelectedClass(datidInfo.objectInfoSVG, repairPositions)) +
                                        // (' ' + getSelectedClassByDaiIdInfo(datidInfo, repairPostionWithDatId)) +
                                        (currentAssemblyGroupObject?.datid === datidInfo.datid ||
                                        highlightDatid === datidInfo.datid
                                            ? ' ' + UsingStyles.selectedElement
                                            : '')
                                    }
                                    onTouchStart={(_e: any) => {
                                        onTouchStartTime = new Date().getTime();
                                    }}
                                    ontouchend={(e: any) => {
                                        if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                                        if (currentZoom !== 1) onElementClick(e, datidInfo);
                                    }}
                                    onClick={(e: any) => {
                                        onElementClick(e, datidInfo);
                                    }}
                                    onContextMenu={(e: any) => {
                                        onElementContextMenu(e, datidInfo);
                                    }}
                                    onMouseEnter={(e: any) => {
                                        onElementMouseEnter(e, datidInfo);
                                    }}
                                    onMouseLeave={onElementMouseLeave}
                                    // selector={'#' + datidInfo.datid + ' [pointer-events="all"]'}
                                    // selector={'#' + datidInfo.datid + ' > [pointer-events="all"]'}
                                    // selector={'#' + datidInfo.datid + ' > g > polygon '}
                                    // selector={'#' + datidInfo.datid + ' > g > * '}

                                    selector={
                                        `#${datidInfo.datid} [pointer-events="all"]` +
                                        `, #${datidInfo.datid}  > g > polygon` +
                                        `, #${datidInfo.datid}  > g > polyline` +
                                        // `, #${datidInfo.datid}  > g :first-child` +
                                        `, #${datidInfo.datid}  > g > path:first-child` +
                                        // `, #${datidInfo.datid}  > g > [fill="#FFFFFF"]` +
                                        // `, #${datidInfo.datid}  > g > path` +
                                        // `, #${datidInfo.datid}  > g > line` +
                                        ''
                                    }
                                    // selector='#Standardebene > [id^="DATID_"]'
                                    // selector={'#' + datidInfo.datid + ' > g :first-child'}
                                    // selector={'#' + datidInfo.datid + ' > g :last-child'}
                                />
                            </div>
                        ))}

                        {isFilterEnable ? (
                            <SvgProxy
                                class={UsingStyles.unfilteredElement}
                                selector='#Standardebene > :not([id^="DATID_"])'
                                // selector=':not([id^="DATID_"])'
                            />
                        ) : (
                            <SvgProxy
                                class=""
                                selector='#Standardebene > :not([id^="DATID_"])'
                                // selector=':not([id^="DATID_"])'
                            />
                        )}

                        {currentAssemblyGroup.datidInfo?.map(datidInfo => (
                            <SvgProxy
                                key={datidInfo.datid + '_123'}
                                class={
                                    isPasFilterByLeftRiteAndAvailableDamages(
                                        datidInfo,
                                        graphicDamageSelectionModelStore
                                    )
                                        ? ''
                                        : UsingStyles.unfilteredElement
                                }
                                selector={'#' + datidInfo.datid}
                            />
                        ))}
                    </SvgLoader>
                </svg>
            </ReactSVGPanZoom>
        </div>
    );
};
