import React from 'react';
import { attach, combine, createApi, createEffect, createEvent, createStore, guard, restore } from 'effector';
import { changeAttachmentStatusReducer } from '@dat/core/utils/attachments/changeAttachmentStatusReducer';
import { groupAttachments } from '@dat/core/utils/attachments/groupAttachments';
import { getAttachmentsWithoutGroup } from '@dat/core/utils/attachments/getAttachmentsWithoutGroup';
import { getRemainingUploadsOfGroups } from '@dat/core/utils/attachments/getRemainingUploadsOfGroups';

import { AttachmentGroup, ParsedAttachmentItem, PayloadForUploadAttachmentsToGroup } from '../../types/attachments';
import { triggerFileDownload } from '@dat/core/utils/attachments/triggerFileDownload';
import { API2 } from '@dat/api2';

const setContractId = createEvent<number>();
const contractId = createStore<number>(0);

const listAttachmentsOfContractFx = createEffect<DAT2.Request.ListAttachmentsOfContract, ParsedAttachmentItem[]>();
const attachments = restore<ParsedAttachmentItem[]>(listAttachmentsOfContractFx, []);
const { addNewAttachment, changeAttachmentStatus } = createApi(attachments, {
    addNewAttachment: (currentAttachments, newAttachment: ParsedAttachmentItem) => [
        newAttachment,
        ...currentAttachments
    ],
    changeAttachmentStatus: changeAttachmentStatusReducer
});

const groups = createStore<AttachmentGroup[]>([]);
const groupedAttachments = combine({ attachments, groups }, groupAttachments);
const attachmentsWithoutGroup = combine({ attachments, groups }, getAttachmentsWithoutGroup);
const remainingUploadsOfGroups = combine({ groupedAttachments, groups }, getRemainingUploadsOfGroups);

const uploadAttachmentByFolderIdFx = createEffect<
    DAT2.Request.UploadAttachmentByFolderID,
    DAT2.Response.UploadAttachmentByFolderID
>();
const uploadAttachmentByFolderIdToCurrentContract = createEvent<DAT2.AttachmentItem>();
const uploadAttachmentsToGroupFx = attach({
    source: remainingUploadsOfGroups,
    effect: (remainingUploadsOfGroups, { groupId, attachments }: PayloadForUploadAttachmentsToGroup) => {
        const uploadsRemaining = groupId === 0 ? Infinity : remainingUploadsOfGroups[groupId] || 0;
        const attachmentsAllowedToUpload = attachments.slice(0, uploadsRemaining);

        attachmentsAllowedToUpload.forEach(uploadAttachmentByFolderIdToCurrentContract);
    }
});

const handleFileInputChange = createEvent<React.ChangeEvent<HTMLInputElement>>();
const filesInGroupSelected = guard({
    source: handleFileInputChange.map(e => ({
        groupId: parseInt(e.target.name),
        files: Array.from(e.target.files || [])
    })),
    filter: ({ groupId, files }) => !isNaN(groupId) && !!files.length
});

const downloadFolderAsZipFx = createEffect(async (payload: DAT2.Internal.Request.GetAttachmentsFolderAsZip) => {
    const data = await API2.myClaimInternal.getAttachmentsFolderAsZip(payload);

    triggerFileDownload({
        filename: `${payload.claimId}_${payload.folderId}.zip`,
        data: `data:application/zip;base64,${data}`
    });
});

export const attachmentsEvents = {
    setContractId,
    addNewAttachment,
    changeAttachmentStatus,
    uploadAttachmentByFolderIdToCurrentContract,
    handleFileInputChange,
    filesInGroupSelected
};
export const attachmentsEffects = {
    listAttachmentsOfContractFx,
    uploadAttachmentByFolderIdFx,
    uploadAttachmentsToGroupFx,
    downloadFolderAsZipFx
};
export const attachmentsStores = {
    contractId,
    attachments,
    groups,
    groupedAttachments,
    attachmentsWithoutGroup,
    remainingUploadsOfGroups
};
