import React, { useMemo, useCallback } from 'react';
import { useStore } from 'effector-react';

import { SUB_ROUTES_PATH_NAMES } from '../../constants/router';

import { FooterMobile, MenuProps, BurgerMenuProps } from '@dat/smart-components/FooterMobile';
import {
    AssignPatnerIcon,
    CalculationIcon,
    ClaimIcon,
    GalleryIcon,
    GrapaIcon,
    CalendarIcon,
    PrintoutIcon,
    MailIcon,
    SmallCarIcon,
    TagIcon
} from '@wedat/ui-kit/components/Icons';
import { useTranslation } from 'react-i18next';
import { containedPluginsStores } from '../../stores/containedPlugins';
import { AssignPartner } from '@dat/assign-partner';
import { Printout } from '@dat/printout';
import { contractStores } from '@dat/shared-models/contract';
import { useToggle } from '@dat/core/hooks/useToggle';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

import { SchedulerMenu } from '../SchedulerMenu';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { modalsEvents, modalsStores } from '../../stores/modals';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { ModalValuation } from '../ModalValuation';
import { valuateEffects } from '../../stores/valuate';
import { sharedMailEvents } from '@dat/shared-models/mail';

import { Mail } from '@dat/mail';
import { HideBlock } from '../HideBlock';
import { useHiddenBlock } from '../../hooks/useHiddenBlock';

const { processingValuateDataFx } = valuateEffects;

export const Footer = () => {
    const { t } = useTranslation();

    const printoutPluginOptions = useStore(containedPluginsStores.printoutPluginOptions);
    const assignPartnerOptions = useStore(containedPluginsStores.assignPartnerPluginoptions);
    const isCalculationAvailable = useStore(containedPluginsStores.isCalculationAvailable);
    const mailOptions = useStore(containedPluginsStores.mailPluginOptions);
    const isContractReceived = useStore(contractStores.isContractReceived);
    const isVehicleIdentified = useStore(sharedVehicleStores.isVehicleIdentified);
    const [isOpenPrintout, setOpenPrintout] = useToggle(false);
    const [isOpenScheduler, setOpenScheduler] = useToggle(false);
    const [isOpenAssignPartner, setOpenAssignPartner] = useToggle(false);
    const [isOpenBurgerMenu, toggleOpenBurgerMenu, setOpenBurgerMenu] = useToggle(false);
    const isValuationModalOpen = useStore(modalsStores.isValuationModalOpen);
    const contractType = useStore(sharedTemplateStores.templateSettings.contractType);

    const productsConfiguration = useStore(sharedTemplateStores.productsConfiguration);

    const isItalian = contractType === 'vro_domus_calculation';

    const isFooterWithLabels = productsConfiguration?.['claim-management'].isFooterWithLabels || null;

    const fastTrackHidden = useHiddenBlock({ headerBlock: 'fast-track' });
    const grapaHidden = useHiddenBlock({ headerBlock: 'grapa' });
    const calculationHidden = useHiddenBlock({ headerBlock: 'calculation' });
    const valuationHidden = useHiddenBlock({ headerBlock: 'valuation' });
    const galleryHidden = useHiddenBlock({ sidebarBlock: 'gallery' });
    const printoutHidden = useHiddenBlock({ sidebarBlock: 'printout' });
    const assignPartnerHidden = useHiddenBlock({ sidebarBlock: 'assign-partner' });
    const schedulerHidden = useHiddenBlock({ sidebarBlock: 'scheduler' });
    const mailHidden = useHiddenBlock({ sidebarBlock: 'mail' });

    const getIconFromProductsConf = useCallback(
        (pluginName: DAT2.Plugins.PluginName) => {
            const imageUrl = productsConfiguration?.[pluginName]?.icon;
            return imageUrl ? <img src={imageUrl} alt={pluginName} /> : undefined;
        },
        [productsConfiguration]
    );

    const getFooterLabelsFromConf = useCallback(
        (pluginName: DAT2.Plugins.PluginName) => {
            const footerLabels = productsConfiguration?.['claim-management'].footerLabels;

            const configurationValue = footerLabels
                ? footerLabels.find((item: { pluginName: string; label: string }) =>
                      item.pluginName === pluginName ? item : null
                  )
                : null;

            return isFooterWithLabels && configurationValue ? configurationValue.label : null;
        },
        [isFooterWithLabels, productsConfiguration]
    );

    const menu = useMemo(() => {
        const result: MenuProps[] = [
            {
                icon: getIconFromProductsConf('claim-management') ?? <ClaimIcon />,
                link: `./${SUB_ROUTES_PATH_NAMES.claim.opening}`,
                label: getFooterLabelsFromConf('claim-management')
            }
        ];

        if (!fastTrackHidden) {
            result.push({
                icon: getIconFromProductsConf('fast-track') ?? <SmallCarIcon />,
                link: `./${SUB_ROUTES_PATH_NAMES.claim.fastTrack}`,
                disabled: !isVehicleIdentified,
                label: getFooterLabelsFromConf('fast-track')
            });
        }
        if (!grapaHidden) {
            result.push({
                icon: getIconFromProductsConf('grapa') ?? <GrapaIcon />,
                link: `./${SUB_ROUTES_PATH_NAMES.claim.grapa}`,
                disabled: !isVehicleIdentified,
                label: getFooterLabelsFromConf('grapa')
            });
        }
        if (!calculationHidden) {
            result.push({
                icon: getIconFromProductsConf('italian-calculation') ?? <CalculationIcon />,
                link: `./${SUB_ROUTES_PATH_NAMES.claim.calculation}`,
                disabled: (!isCalculationAvailable && !isItalian) || (isItalian && !isVehicleIdentified),
                label: getFooterLabelsFromConf('italian-calculation')
            });
        }
        if (!valuationHidden) {
            result.push({
                icon: getIconFromProductsConf('valuate-finance') ?? <TagIcon />,
                onClick: processingValuateDataFx,
                disabled: !isVehicleIdentified,
                label: getFooterLabelsFromConf('valuate-finance')
            });
        }

        return result;
    }, [
        calculationHidden,
        fastTrackHidden,
        getFooterLabelsFromConf,
        getIconFromProductsConf,
        grapaHidden,
        isCalculationAvailable,
        isItalian,
        isVehicleIdentified,
        valuationHidden
    ]);

    const burgerMenu = useMemo(() => {
        const result: BurgerMenuProps[] = [];

        if (!galleryHidden) {
            result.push({
                icon: getIconFromProductsConf('gallery') ?? <GalleryIcon />,
                link: `./${SUB_ROUTES_PATH_NAMES.claim.gallery}`,
                text: t('header.attachment'),
                disabled: !isContractReceived
            });
        }
        if (!printoutHidden) {
            result.push({
                icon: getIconFromProductsConf('printout') ?? <PrintoutIcon />,
                text: t('header.printout'),
                onClick: () => {
                    setOpenPrintout();
                }
            });
        }
        if (!assignPartnerHidden) {
            result.push({
                icon: getIconFromProductsConf('assign-partner') ?? <AssignPatnerIcon />,
                text: t('header.assignPartner'),
                onClick: () => {
                    setOpenAssignPartner();
                }
            });
        }
        if (!schedulerHidden) {
            result.push({
                icon: getIconFromProductsConf('ai-claim') ?? <CalendarIcon />,
                text: t('header.scheduler'),
                onClick: () => {
                    setOpenScheduler();
                }
            });
        }
        if (!mailHidden) {
            result.push({
                icon: <MailIcon />,
                text: t('header.mail'),
                onClick: () => {
                    sharedMailEvents.toggleIsMailOpen();
                }
            });
        }

        return result;
    }, [
        assignPartnerHidden,
        galleryHidden,
        getIconFromProductsConf,
        isContractReceived,
        mailHidden,
        printoutHidden,
        schedulerHidden,
        setOpenAssignPartner,
        setOpenPrintout,
        setOpenScheduler,
        t
    ]);

    return (
        <>
            <FooterMobile
                menu={menu}
                burgerMenu={burgerMenu}
                isOpenBurgerMenu={isOpenBurgerMenu}
                toggleOpenBurgerMenu={toggleOpenBurgerMenu}
                setOpenBurgerMenu={setOpenBurgerMenu}
                high={false}
                isFooterWithLabels={isFooterWithLabels}
            />
            <Printout
                options={{
                    ...printoutPluginOptions,
                    isOpen: isOpenPrintout,
                    callbackClose: setOpenPrintout,
                    isComponent: true
                }}
            />
            <SchedulerMenu callbackClose={setOpenScheduler} isOpen={isOpenScheduler} />

            <AssignPartner
                options={{
                    ...assignPartnerOptions,
                    isHeader: true,
                    isOpen: isOpenAssignPartner,
                    isComponent: true,
                    callbackClose: setOpenAssignPartner
                }}
            />

            <ModalPrimary
                maxWidth="700px"
                bodyHeight="650px"
                title={t('modals.valuation.title')}
                bodyNoPadding
                isOpen={isValuationModalOpen}
                onDismiss={modalsEvents.toggleIsValuationModalOpen}
            >
                <ModalValuation />
            </ModalPrimary>

            <HideBlock sidebarBlock="mail">
                <Mail options={mailOptions} />
            </HideBlock>
        </>
    );
};
