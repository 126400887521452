import styled, { css } from 'styled-components/macro';
import { TextareaField } from '@wedat/ui-kit/Formik/TextareaField';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    isRequired?: boolean;
}

export const FieldStyle = styled.div<Props>`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
    ${({ isRequired }) =>
        isRequired &&
        css`
            label {
                &:after {
                    content: '*';
                    color: ${({ theme }) => theme.colors.red['800']};
                }
            }
        `}
`;

export const TextStyled = styled(Text)``;

export const DateFieldWrapper = styled.div`
    min-width: 150px;

    .react-datepicker {
        width: fit-content;
        padding-right: 6px;

        &__month-container {
            width: fit-content;
        }
    }
`;

export const StyledTextareaField = styled(TextareaField)`
    // TODO: rethink this
    width: 200%;
    height: 120px;

    ${media.laptop`
        width: 100%;
    `}
`;
