import React from 'react';
import { useHistory } from 'react-router';

import { HistoryGate } from '../../stores/history';

export const HistoryGateConnector: React.FC = () => {
    const history = useHistory();

    return <HistoryGate {...history} />;
};
