// preparedManualPositionsModel

import { createEvent, createEffect, attach, restore, sample } from 'effector';
import { deletePart, editPart, getParts } from '../services/manualPositionsService';
import { ManualPosition } from '../types/ManualPositionsServiceTypes';

const setPreparedManualPositionsList = createEvent<ManualPosition[] | null>();
const preparedManualPositionsList = restore(setPreparedManualPositionsList, null);

const defManualPositionVal: ManualPosition = {
    country: 'IT', // ToDo may be require use correct for every user, but currently looking as optional and non used
    repairType: '',
    repairCode: '',
    description: '',
    price: 0,
    workingTime: 0,
    quantity: 1
};

const fetchPreparedManualPositionsListFx = createEffect(getParts);

sample({
    clock: fetchPreparedManualPositionsListFx.doneData,
    fn: data => data?.data.list || null,
    target: setPreparedManualPositionsList
});

const setEditedManualItem = createEvent<ManualPosition | null>();
const editedManualItem = restore(setEditedManualItem, null);

const updateEditedManualItem = createEvent<Partial<ManualPosition> | null>();
editedManualItem.on(updateEditedManualItem, (oldVal, val) => {
    if (!val) return null;
    return {
        ...defManualPositionVal,
        ...oldVal,
        ...val
    };
});

const setByIdEditedManualItem = createEvent<number>();
sample({
    source: preparedManualPositionsList,
    clock: setByIdEditedManualItem,
    fn: (list, id) => list?.find(item => item.id === id) || null,
    target: setEditedManualItem
});

const clearEditedManualItem = createEvent();
editedManualItem.on(clearEditedManualItem, (_oldVal, _val) => null);

const newEditedManualItem = createEvent();
editedManualItem.on(newEditedManualItem, (_oldVal, _val) => defManualPositionVal);

const deleteFx = createEffect(deletePart);

const saveFx = attach({
    source: editedManualItem,
    mapParams: (_params: undefined, data) => {
        if (!data) return null;
        return {
            payload: data,
            id: data?.id
        };
    },
    effect: createEffect(async (data: { id?: number; payload: ManualPosition } | null) => {
        if (data) return editPart(data);
    })
});

export const preparedManualPositionsModel = {
    store: {
        preparedManualPositionsList,
        editedManualItem
    },

    event: {
        updateEditedManualItem,
        setByIdEditedManualItem,
        clearEditedManualItem,
        newEditedManualItem
    },

    effect: {
        fetchPreparedManualPositionsListFx,
        deleteFx,
        saveFx
    }
};
