import { pending } from 'patronum';
import { evaluationEffects } from '../evaluation';
import { subscribeEffectsToToast } from '@dat/smart-components/Toast/store';

const { getVehicleEvaluationFx, getVehicleEvaluationWithManualFieldsFx } = evaluationEffects;

subscribeEffectsToToast(evaluationEffects);

const isLoading = pending({
    effects: [getVehicleEvaluationFx, getVehicleEvaluationWithManualFieldsFx]
});

export const commonStores = {
    isLoading
};
