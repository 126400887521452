/**
 * @param e mouse event
 * @returns tooltip position settings depending on left/right menus state (opened/closed) and hovered SVG side (left/right)
 */
export const getTooltipPosition = (e: any) => {
    const windowWidth = document.querySelector('.graphicDamageSelectionBody')?.clientWidth || 0; // width between left and right menus

    const mouseOffsetX = e.offsetX; // length from LEFT MENU to mouse position
    const mouseOffsetY = e.offsetY; // length from PAGE TOP to mouse position
    const mouseClientX = e.clientX; // length from PAGE LEFT SIDE to mouse position

    const element = e.target.getBoundingClientRect(); // part svg to rectangle element
    const elementX = element.x; // length from PAGE LEFT SIDE to svg left side
    const elementWidth = element.width;

    const diffX = mouseClientX - elementX; // length from SVG LEFT SIDE to mouse position
    const leftSideHovered = windowWidth && diffX < elementWidth * 0.5;

    return {
        posX: leftSideHovered ? windowWidth - mouseOffsetX + 20 : mouseOffsetX + 20,
        posY: mouseOffsetY - 15,
        tooltipPosition: leftSideHovered ? 'left' : 'right',
        arrowDirection: leftSideHovered ? 'right' : 'left',
        arrowPosition: 'start'
    };
};
