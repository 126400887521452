import styled from 'styled-components/macro';
import { Arrow } from '@wedat/ui-kit/assets/Icon';
import { makeCustomScrollBar, media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit';

export interface ClaimsOverdueProps {
    selected: boolean;
}

export interface BarsContainerProps {
    displayed: boolean;
}

export interface IconArrowWrapperProps {
    opened?: boolean;
    isTransformed?: boolean;
}

export const IconArrowWrapper = styled.div<IconArrowWrapperProps>`
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
        transition: transform 0.2s;
        ${({ opened, theme }) =>
            opened &&
            `
                transform: rotate(-180deg);
                opacity: 1;
                color: ${theme.colors.blue['700']};
            `}
    }
`;

export const InfoContainer = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    padding-bottom: 5px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    margin-top: auto;
`;

export const BarsContainer = styled.div<BarsContainerProps>`
    justify-content: space-evenly;
    flex: 1;
    margin-right: 10px;
    display: ${(p: BarsContainerProps) => (p.displayed ? 'flex' : 'none')};
`;

export const Bars = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const InfoItem = styled.div`
    flex: 1;
    padding: 5px;
    margin-top: auto;
`;

export const ClaimsOverdueWrapper = styled(InfoItem)`
    align-self: end;
    padding-bottom: 0;
`;

export const ClaimsOverdue = styled(InfoItem)<ClaimsOverdueProps>`
    cursor: pointer;
    width: fit-content;
    padding: 10px 25px 5px 25px;
    ${({ selected, theme }) =>
        selected &&
        `
            background-color: ${theme.colors.blue['50']};
            border-radius: 8px;
        `}
`;

export const DatepickerContainer = styled.div`
    font-size: 20px;
    font-weight: bolder;
    display: flex;
`;

export const DatepickerContainerText = styled.div`
    align-self: center;
    margin-right: 5px;
`;

export const DatepickerContainerValue = styled.div`
    display: inline-block;
    width: 94px;
    margin-right: 5px;
`;

export const ArrowsContainer = styled.div`
    flex: 0 0 40px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const ArrowWrapper = styled.div<IconArrowWrapperProps>`
    border: thin ${({ theme }) => theme.colors.gray['300']} solid;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    rotate: ${({ isTransformed }) => isTransformed && '180deg'};
`;

export const ArrowRight = styled(Arrow)`
    rotate: 180deg;
`;

export const DetailsContainer = styled.div`
    display: flex;
    padding: 20px 20px 10px 20px;
    flex: 1;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 6px 0 ${({ theme }) => theme.colors.gray['300']};

    ${media.laptopSmall`
        flex-direction: column;
        padding: 10px 20px;
        
        ${ArrowWrapper} {
            border: none;
            align-self: center;
        }
        
        ${ClaimsOverdue} {
            white-space: nowrap;
        }
        
        ${InfoContainer} {
            margin-right: 0;
        }
    `}

    ${media.phone`
        padding: 10px 15px;
        
        ${IconArrowWrapper} {
            width: 10px;
        }
        
        ${Bars} {
            max-width: 220px;
            justify-content: flex-start;
        }
    `}
`;

export const ValueDescription = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray['500']};
    display: block;
    width: fit-content;
`;
