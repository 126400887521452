import React from 'react';

const Vector = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M16 0H3C1.794 0 0 0.7191 0 2.7V15.3C0 17.2809 1.794 18 3 18H18V16.2H3.012C2.55 16.1892 2 16.0254 2 15.3C2 14.5746 2.55 14.4108 3.012 14.4H18V1.8C18 0.8073 17.103 0 16 0ZM16 12.6H2V2.7C2 1.9746 2.55 1.8108 3 1.8H16V12.6Z"
            fill="currentColor"
        />
    </svg>
);

export default Vector;
