import { API2 } from '@dat/api2';
import { attach, createEffect, createStore } from 'effector';
import { getParsedArraySafe } from '@dat/api2/utils';
import { getFileName } from '../../utils/getFileName';

type GetDocumentProps = {
    printData: DAT2.Request.GetPrintReport;
    printoutConfig: DAT2.Plugins.Printout.Configuration;
};

const printoutConfig = createStore<DAT2.Plugins.Printout.Configuration>({});

const fetchDocumentFx = createEffect(async (printData: DAT2.Request.GetPrintReport) => {
    const response = await API2.myClaim.getPrintReport(printData);
    return getParsedArraySafe(response.return)[0];
});

const getDocumentFx = createEffect(async ({ printData, printoutConfig }: GetDocumentProps) => {
    const doc = await fetchDocumentFx(printData);

    if (doc) {
        const defaultPrintoutDescription = printoutConfig.defaultPrintoutDescription;
        const { registrationNumber, vin, description } = printData;

        const fileName =
            getFileName({ defaultPrintoutDescription, description, vin, registrationNumber }) || doc.fileName;

        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = fileName;
        link.href = 'data:application/octet-stream;base64,' + doc.binaryData;
        link.click();
        link.remove();
    }
});

const getDocumentWithConfFx = attach({
    source: printoutConfig,
    effect: getDocumentFx,
    mapParams: (printData: DAT2.Request.GetPrintReport, printoutConfig) => ({ printData, printoutConfig })
});

const openDocumentFx = createEffect(async (printData: DAT2.Request.GetPrintReport) => {
    const doc = await fetchDocumentFx(printData);

    if (doc) {
        const byteCharacters = atob(doc.binaryData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
    }
});

export const printoutEffects = {
    getDocumentWithConfFx,
    openDocumentFx
};

export const printoutStores = {
    printoutConfig
};
