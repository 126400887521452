import React from 'react';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { disabledTime } from '@dat/shared-models/scheduler/constants';
import { POPUP_STYLES } from '@dat/scheduler/src/constants/constants';

import 'antd/es/date-picker/style/css';

const DatePicker = generatePicker(dateFnsGenerateConfig);

export interface TimepickerProps {
    name: string;
    format?: string;
    value: Date | null | undefined;
    onChange: (value: Date | null, dateString: string) => void;
}

export const Timepicker: React.FC<TimepickerProps> = ({ name = '', format = 'HH:mm', value, onChange }) => (
    <DatePicker
        picker="time"
        disabledHours={disabledTime}
        value={value}
        onChange={onChange}
        name={name}
        format={format}
        popupStyle={POPUP_STYLES}
    />
);
