import { createEvent, createStore, restore } from 'effector';

const openStatusModalByContractId = createEvent<number>();

const contractId = restore(openStatusModalByContractId, 0);
const initialStatusId = createStore<number | null>(null);
const statusChanged = createEvent<DAT2.ContractStatusTransition>();

export const statusesEvents = {
    openStatusModalByContractId,
    statusChanged
};
export const statusesStores = {
    contractId,
    initialStatusId
};
