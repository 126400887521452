import { FC, useState, useMemo, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { EquipmentTable, SelectedIdsTypes } from '../Table';
import { createRowsWithConflicted, createRowsWithDisabled, getTableSelected } from './utils';
import * as R from 'ramda';
import _ from 'lodash';

import { combinedEquipmentStores, equipmentEvents } from '../../stores/equipment';
import { tableFilterEvents, tableFilterStores } from '../../stores/tableFilter';

import {
    StandardIndicator,
    EquipmentContainer,
    TransferEquipmentButtonsContainer,
    VinContainer,
    IconsContainer,
    ArrowIconWrapper,
    StyledTooltip,
    AvailableTableWrapper,
    ExistingTableWrapper
} from './styles';
import { StyledButtonIcon } from '../Table/styles';

import { CurrentFormikValues } from '../../types/formik';
import { Column } from 'react-table';
import { TableData, CellType } from '../../types/table';
import { EquipmentTabs } from '../../constants/activeTabsEnum';
import { transferWithSwap } from '../../utils/transferWithSwap';
import {
    ArrowExpand,
    CheckboxFalseIcon,
    CheckboxTrueIcon,
    CheckInCircleIcon,
    InfoIcon,
    WrenchIcon
} from '@wedat/ui-kit/components/Icons';
import { useToggle } from '@dat/core/hooks/useToggle';
import { EquipmentItemData } from '../../types/equipment';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { pluginEffects, pluginStores } from '../../stores/plugin';

interface Props {
    isMobile: boolean;
    closeEquipmentModal: () => void;
}

export const EquipmentTransfer: FC<Props> = ({ isMobile, closeEquipmentModal }) => {
    const { t } = useTranslation();
    const {
        values: { selectedCategories }
    } = useFormikContext<CurrentFormikValues>();
    const { allTransferEquipment, existingTransferEquipmentIds } = useStore(combinedEquipmentStores);
    const [firstTableSelectedRowsIds, setFirstTableSelectedRowsIds] = useState<SelectedIdsTypes>({});
    const [secondTableSelectedRowsIds, setSecondTableSelectedRowsIds] = useState<SelectedIdsTypes>({});
    const firstFilterValue = useStore(tableFilterStores.firstFilterValue);
    const secondFilterValue = useStore(tableFilterStores.secondFilterValue);
    const mobileFilterValue = useStore(tableFilterStores.mobileFilterValue);
    const existingEquipmentStringIds = existingTransferEquipmentIds.map(id => String(id));

    const pluginOptions = useStore(pluginStores.pluginOptions);

    const [conflictedRows, setConflictedRows] = useState<EquipmentItemData[]>([]);
    const [removedRows, setRemovedRows] = useState<EquipmentItemData[]>([]);
    const [onlyConflicted, toggleConflicted, setConflictedFlag] = useToggle(false);
    const [activeTab, setActiveTab] = useState<EquipmentTabs>(EquipmentTabs.ExistingEquipment);

    useEffect(() => {
        if (!pluginOptions?.isComponent && pluginOptions?.vin) {
            pluginEffects.getVehicleIdentificationByVinFx({
                vin: pluginOptions.vin,
                restriction: pluginOptions.requestData.restriction || 'REPAIR'
            });
        }
    }, [pluginOptions]);

    const handleChangeTabs = (id?: string) => {
        setActiveTab(id as EquipmentTabs);
    };

    // Filter equipment by categories
    const allEquipmentFilteredByCategories = allTransferEquipment.filter(
        ({ Category }) => !selectedCategories.length || (Category && selectedCategories.includes(Category))
    );

    const existingEquipment = useMemo(
        () =>
            _.uniqBy(
                allEquipmentFilteredByCategories
                    .filter(({ key }) => existingTransferEquipmentIds.includes(+key))
                    .map((item, _idx, arr) => {
                        const sameKeysItems = arr.filter(el => el.key === item.key);

                        if (sameKeysItems.length === 1) {
                            return item;
                        }

                        const itemWithVIN = sameKeysItems.find(el => !!el.vin);

                        return itemWithVIN || item;
                    }),
                'key'
            ),
        [allEquipmentFilteredByCategories, existingTransferEquipmentIds]
    );

    const availableEquipmentWithoutExisting = allEquipmentFilteredByCategories.filter(
        ({ key }) => !existingTransferEquipmentIds.includes(+key)
    );

    const firstTableSelected = useMemo(
        () => getTableSelected(Object.keys(firstTableSelectedRowsIds), availableEquipmentWithoutExisting),
        [availableEquipmentWithoutExisting, firstTableSelectedRowsIds]
    );

    const secondTableSelected = useMemo(
        () => getTableSelected(Object.keys(secondTableSelectedRowsIds), existingEquipment),
        [existingEquipment, secondTableSelectedRowsIds]
    );

    const availableEquipmentRows: TableData[] = useMemo(
        () => createRowsWithDisabled(availableEquipmentWithoutExisting, firstTableSelected),
        [availableEquipmentWithoutExisting, firstTableSelected]
    );

    const existingEquipmentRows: TableData[] = useMemo(
        () => createRowsWithConflicted(existingEquipment, conflictedRows, onlyConflicted),
        [existingEquipment, conflictedRows, onlyConflicted]
    );

    const equipmentTableColumns: Column<TableData>[] = useMemo(
        () => [
            {
                disableSortBy: true,
                accessor: 'col0',
                Cell: ({ row }: CellType) => (row.isSelected ? <CheckboxTrueIcon /> : <CheckboxFalseIcon />)
            },
            {
                Header: t<string>('id'),
                accessor: 'col1'
            },
            {
                Header: () => <WrenchIcon />,
                accessor: 'col2',
                Cell: ({ row }: CellType) => (
                    <StandardIndicator standard={!row.original.col2} isSelected={row.isSelected} />
                )
            },
            {
                Header: 'VIN',
                accessor: 'col3',
                Cell: ({ row }: CellType) => {
                    if (row.original.col3) {
                        if (isMobile) {
                            return <VinContainer>VIN</VinContainer>;
                        }

                        return <CheckInCircleIcon width="16" />;
                    }

                    return '';
                }
            },
            {
                Header: t<string>('designation'),
                accessor: 'col4'
            },
            {
                disableSortBy: true,
                Header: '',
                id: 'expander',
                Cell: ({ row }: CellType) => (
                    <IconsContainer>
                        {row?.original?.children?.length && (
                            <ArrowIconWrapper
                                arrowIconDirection={row.isExpanded ? 'down' : 'up'}
                                {...row.getToggleRowExpandedProps()}
                                onClick={e => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                <ArrowExpand />
                            </ArrowIconWrapper>
                        )}
                        {row.original?.conflictedItem?.replaced && (
                            <StyledTooltip
                                onClick={e => e.stopPropagation()}
                                text={t('replacedText', {
                                    replacedKey: row.original.conflictedItem.replaced.key,
                                    replacedDescription: row.original.conflictedItem.replaced.description,
                                    newEquipmentItemKey: row.original.conflictedItem.key,
                                    newEquipmentItemDescription: row.original.conflictedItem.description
                                })}
                                width="auto"
                                placement="top-end"
                                withoutArrow
                            >
                                <InfoIcon />
                            </StyledTooltip>
                        )}
                    </IconsContainer>
                )
            }
        ],
        [isMobile, t]
    );

    const transferToRight = () => {
        const result = transferWithSwap({
            availableTableSelected: firstTableSelected,
            existingEquipment,
            conflicted: conflictedRows
        });

        setConflictedRows(result.conflicted);
        setRemovedRows(result.removed);

        const onlyKeys = result.existing.map(i => i.key);
        equipmentEvents.equipmentMoved(onlyKeys);
        setFirstTableSelectedRowsIds({});
    };

    const transferToLeft = () => {
        const onlyKeys = secondTableSelected.map(item => item.key);
        equipmentEvents.equipmentMoved(R.without(onlyKeys, existingEquipmentStringIds));
        setSecondTableSelectedRowsIds({});
    };

    const removeSelected = () => {
        const existingKeys = existingEquipment.map(i => i.key);
        const conflictedKeys = conflictedRows.map(i => i.key);
        const removedKeys = removedRows.map(i => i.key);
        const result = [...R.without(conflictedKeys, existingKeys), ...removedKeys];

        equipmentEvents.equipmentMoved(result);
        setConflictedRows([]);
        setConflictedFlag(false);
    };

    const confirmSelected = () => {
        setConflictedRows([]);
    };

    return (
        <>
            <Header
                closeEquipmentModal={closeEquipmentModal}
                isMobile={isMobile}
                activeTab={activeTab}
                handleChangeTabs={handleChangeTabs}
                availableRows={availableEquipmentRows.length}
                existingRows={existingEquipmentRows.length}
            />

            <EquipmentContainer>
                {isMobile ? (
                    <>
                        {activeTab === EquipmentTabs.AvailableEquipment && (
                            <EquipmentTable
                                columns={equipmentTableColumns}
                                data={availableEquipmentRows}
                                title={t('availableEquipment')}
                                onSelectRows={setFirstTableSelectedRowsIds}
                                selectedIds={firstTableSelectedRowsIds}
                                isMobile={isMobile}
                                filterValue={mobileFilterValue}
                                setFilterValue={tableFilterEvents.setMobileFilterValue}
                            />
                        )}
                        {activeTab === EquipmentTabs.ExistingEquipment && (
                            <EquipmentTable
                                columns={equipmentTableColumns}
                                data={existingEquipmentRows}
                                title={t('existingEquipment')}
                                onSelectRows={setSecondTableSelectedRowsIds}
                                selectedIds={secondTableSelectedRowsIds}
                                isMobile={isMobile}
                                filterValue={mobileFilterValue}
                                setFilterValue={tableFilterEvents.setMobileFilterValue}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <AvailableTableWrapper>
                            <EquipmentTable
                                columns={equipmentTableColumns}
                                data={availableEquipmentRows}
                                title={t('availableEquipment')}
                                onSelectRows={setFirstTableSelectedRowsIds}
                                selectedIds={firstTableSelectedRowsIds}
                                isMobile={isMobile}
                                filterValue={firstFilterValue}
                                setFilterValue={tableFilterEvents.setFirstFilterValue}
                            />
                        </AvailableTableWrapper>

                        <TransferEquipmentButtonsContainer>
                            <StyledButtonIcon
                                arrowIconDirection="right"
                                onClick={transferToRight}
                                disabled={!firstTableSelected.length}
                            >
                                <ArrowExpand />
                            </StyledButtonIcon>
                            <StyledButtonIcon
                                arrowIconDirection="left"
                                onClick={transferToLeft}
                                disabled={!secondTableSelected.length}
                            >
                                <ArrowExpand />
                            </StyledButtonIcon>
                        </TransferEquipmentButtonsContainer>

                        <ExistingTableWrapper>
                            <EquipmentTable
                                columns={equipmentTableColumns}
                                data={existingEquipmentRows}
                                title={t('existingEquipment')}
                                onSelectRows={setSecondTableSelectedRowsIds}
                                selectedIds={secondTableSelectedRowsIds}
                                isMobile={isMobile}
                                filterValue={secondFilterValue}
                                setFilterValue={tableFilterEvents.setSecondFilterValue}
                            />
                        </ExistingTableWrapper>
                    </>
                )}
            </EquipmentContainer>

            <Footer
                isMobile={isMobile}
                activeTab={activeTab}
                availableSelectedRowsLength={firstTableSelected.length}
                existingSelectedRowsLength={secondTableSelected.length}
                transferAvailableToExisting={transferToLeft}
                transferExistingToAvailable={transferToRight}
                toggleConflicted={toggleConflicted}
                removeSelected={removeSelected}
                confirmSelected={confirmSelected}
                onlyConflicted={onlyConflicted}
                conflictedRowsLength={conflictedRows.length}
            />
        </>
    );
};
