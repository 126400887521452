import { forward, sample } from 'effector';
import { v4 as uuidv4 } from 'uuid';

import { pluginCarsEvents } from './index';
import { attachmentsEvents } from '../attachments';
import { extractDataFromBase64 } from '@dat/core/utils/attachments/extractDataFromBase64';
import { parseAttachmentItem } from '@dat/core/utils/attachments/parseAttachmentItem';

const { pluginCarsCompleted, photosReceived, closePluginCars } = pluginCarsEvents;

forward({ from: pluginCarsCompleted, to: closePluginCars });

sample({
    clock: pluginCarsCompleted,
    fn: ({ steps: completedSteps }) => completedSteps.map(item => item.photo),
    target: photosReceived
});

photosReceived.watch(photos => {
    const attachments = photos.map((photo: string) => ({
        fileName: uuidv4(),
        documentID: 0,
        binaryData: extractDataFromBase64(photo),
        mimeType: 'image/jpeg',
        uploaded: new Date().toISOString()
    }));

    const attachmentsWithBase64 = attachments.map(parseAttachmentItem);
    attachmentsEvents.addAttachments(attachmentsWithBase64);
});
