import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { DvnMapItem } from '../types/ft/dvnMapType';
import { getFtDatIdByDvnMap } from './getFtDatIdToDvnMap';

// prefer use convertRepairPositionToDatId
export function convertRepairPositionToDatId_obsolete(
    repairPositions: RepairPosition[],
    dvnMap: DvnMapItem[],
    datECode: string
): string[] {
    const result: string[] = [];

    repairPositions.forEach(repairPosition => {
        let foundItem;
        foundItem = dvnMap.find(
            item => isDatECodeMatchMask(datECode, item.datECodeMask) && item.dvns.includes(repairPosition.DATProcessId)
        );
        if (!foundItem)
            foundItem = dvnMap.find(item => !item.datECodeMask && item.dvns.includes(repairPosition.DATProcessId));

        if (foundItem) result.push(foundItem.datId);
    });

    return result;
}

export function isDatECodeMatchMask(datECode: string, mask: string) {
    return !mask || datECode.substring(0, mask.length) === mask;
}

export function convertRepairPositionToDatId(
    repairPositions: RepairPosition[],
    ftDatIdToDvnMap: { [key: string]: number }
): string[] {
    let result: string[] = [];

    result = repairPositions
        .map(repairPosition => getFtDatIdByDvnMap(repairPosition.DATProcessId, ftDatIdToDvnMap))
        .filter(dvn => !!dvn) as string[];

    // repairPositions.forEach(repairPosition => {
    //     let foundItem = getFtDatIdByDvnMap(repairPosition.DATProcessId, ftDatIdToDvnMap);
    //     if (foundItem) result.push(foundItem);
    // });

    return result;
}
