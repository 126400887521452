import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Column = styled.div`
    padding-top: 24px;
    padding-bottom: 32px;
    display: flex;

    ${media.phoneBig`
        padding-top: 16px;
        padding-bottom: 16px;
        align-items: center;
        flex-direction: column;
    `}
`;
