import { constructionPeriodsArray } from '@dat/core/constants';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatepickerField, NumberInputField } from '@wedat/ui-kit/Formik';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';

import { vehicleImagesStores } from '../../../stores/images';
import { modalsEvents } from '../../../stores/modals';
import { vehicleStores } from '../../../stores/vehicle';
import { FormikValuationValues } from '../constants';
import { CustomSelectField } from '../CustomSelect';

import {
    ButtonStyled,
    Footer,
    InputWrapper,
    Container,
    Content,
    Wrapper,
    Image,
    DescriptionMobile,
    Title,
    DescriptionDesktop,
    WrapContent
} from './styles';
import { parseDateString } from '@dat/core/utils/parseDateString';
import { useTheme } from 'styled-components';

export const FormValuation = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const containers = useStore(vehicleStores.containers);
    const vehicleImages = useStore(vehicleImagesStores.vehicleImages);
    const vehicle = useStore(sharedVehicleStores.vehicle);
    const { setFieldValue, initialValues } = useFormikContext<FormikValuationValues>();

    useEffect(() => {
        const container = vehicle?.Container || initialValues.vehicleContainer;
        const kilometers = vehicle?.MileageOdometer || initialValues.kilometers;
        const firstRegistration = vehicle?.InitialRegistration || initialValues.firstRegistration;

        setFieldValue('vehicleContainer', container);
        setFieldValue('kilometers', kilometers);
        setFieldValue('firstRegistration', parseDateString(String(firstRegistration)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const manufacturer = vehicle?.VehicleDataItaly?.ManufacturerNameItaly || '';
    const baseModel = vehicle?.VehicleDataItaly?.BaseModelNameItaly || '';
    const subModel = vehicle?.VehicleDataItaly?.SubModelNameItaly || '';

    const title = `${manufacturer}&nbsp;${baseModel}&nbsp;${subModel}`;

    return (
        <Container>
            <Content>
                <Wrapper>
                    <WrapContent>
                        {title && (
                            <Title asTag="h3" fontWeight={700} font="font12" color={theme.colors.dustBlue['900']}>
                                <span dangerouslySetInnerHTML={{ __html: title }} />
                            </Title>
                        )}
                        <DescriptionDesktop font="note" asTag="p" color={theme.colors.dustBlue['900']}>
                            {t('modals.valuation.description')}
                        </DescriptionDesktop>
                    </WrapContent>

                    {vehicleImages && vehicleImages[0] && (
                        <Image src={`data:image/png;base64,${vehicleImages[0].imageBase64}`} alt="vehicle photo" />
                    )}
                </Wrapper>
                <DescriptionMobile font="note" asTag="p" color={theme.colors.dustBlue['900']}>
                    {t('modals.valuation.description')}
                </DescriptionMobile>
                <InputWrapper>
                    <CustomSelectField
                        label={t('modals.valuation.containerLabel')}
                        disabled={!containers.length}
                        name="vehicleContainer"
                        defaultWhiteSpaceOption
                        options={containers}
                    />
                </InputWrapper>
                <InputWrapper>
                    <NumberInputField fixedDecimalScale={false} thousandSeparator="." name="kilometers" label="KM" />
                </InputWrapper>
                <InputWrapper>
                    <DatepickerField
                        maxDate={new Date()}
                        minDate={new Date(`${constructionPeriodsArray[0]}`)}
                        name="firstRegistration"
                        label={`${t('modals.valuation.firstRegistrationLabel')}`}
                        popperPlacement="bottom"
                        portalId="valuate-datepicker"
                    />
                </InputWrapper>
            </Content>
            <Footer>
                <ButtonStyled
                    type="button"
                    onClick={modalsEvents.toggleIsValuationModalOpen}
                    typeStyle={{ type: 'transparent' }}
                >
                    {t('modals.valuation.cancel')}
                </ButtonStyled>
                <ButtonStyled type="submit">{t('modals.valuation.confirm')}</ButtonStyled>
            </Footer>
        </Container>
    );
};
