import React, { FC } from 'react';
import { parsePolygon } from '../utils/parsePolygon';
import { Svg } from './styles';
// import { getOffset } from '../utils/getOffset';
import { getFileName } from '../utils/getFileName';
import { ImageResult } from '../../../types/ai-gallery';
import { useStore } from 'effector-react';
import { aiGalleryStores } from '../../../stores/gallery';
import { configurationStores } from '../../../stores/configuration';
interface Props {
    scale: number;
    list: ImageResult[];
    currentFileName: string;
}

export const Layer: FC<Props> = React.memo(({ scale, list, currentFileName }) => {
    const hasPanel = useStore(aiGalleryStores.hasPanel);
    const hasPanelDamage = useStore(aiGalleryStores.hasPanelDamage);
    const configuration = useStore(configurationStores.configuration);

    return (
        <Svg>
            {hasPanel && (
                <g transform={`scale(${scale || 1})`}>
                    {list.map(item => {
                        const fileName = getFileName(item.fileName);

                        if (fileName !== currentFileName) return null;

                        // TODO: comment because offset is necessary
                        // const offset = getOffset(item.carDetection[0]?.bbox.xMin, item.carDetection[0]?.bbox.yMin);

                        return item.panelSegmentation.map(carPanel =>
                            carPanel.polygons.map((polyCoord, key: number) => (
                                <path
                                    key={key}
                                    // TODO: comment because offset is necessary
                                    // transform={`translate(${offset.xMin}, ${offset.yMin})`}
                                    fill={configuration?.PanelColors.Components[carPanel.className]}
                                    opacity={configuration?.PanelColors.opacity}
                                    d={`M${parsePolygon(polyCoord)}z`}
                                />
                            ))
                        );
                    })}
                </g>
            )}
            {hasPanelDamage && (
                <g transform={`scale(${scale || 1})`}>
                    {list.map(item => {
                        const fileName = getFileName(item.fileName);

                        if (fileName !== currentFileName) return null;

                        // TODO: comment because offset is necessary
                        // const offset = getOffset(item.carDetection[0]?.bbox.xMin, item.carDetection[0]?.bbox.yMin);

                        return item.damageSegmentation.map(damageSegment =>
                            damageSegment.polygons.map((polyCoord, key: number) => (
                                <path
                                    key={key}
                                    // TODO: comment because offset is necessary
                                    // transform={`translate(${offset.xMin}, ${offset.yMin})`}
                                    fill={configuration?.DamageColors.Components[damageSegment.className]}
                                    opacity={configuration?.DamageColors.opacity}
                                    d={`M${parsePolygon(polyCoord)}z`}
                                />
                            ))
                        );
                    })}
                </g>
            )}
        </Svg>
    );
});
