import { createEffect } from 'effector';

import { datAuthTokenName } from '@dat/core/constants';
import { datAxiosInstance } from '@dat/api/axios';
import { datAxiosInstance as datAxiosInstance2 } from '../datAxiosInstance';
import { bffAxiosInstance } from '../bffAxios';
import { events, stores } from './apiStores';

const setTokenFx = createEffect((token: DAT2.Token) => {
    datAxiosInstance.defaults.headers.common[datAuthTokenName] = token;
    datAxiosInstance2.defaults.headers.common[datAuthTokenName] = token;
    bffAxiosInstance.defaults.headers.common[datAuthTokenName] = token;
});

const removeTokenFx = createEffect(() => {
    datAxiosInstance.defaults.headers.common[datAuthTokenName] = '';
    datAxiosInstance2.defaults.headers.common[datAuthTokenName] = '';
    bffAxiosInstance.defaults.headers.common[datAuthTokenName] = '';
});

export const apiEvents = {
    ...events
};
export const apiEffects = {
    setTokenFx,
    removeTokenFx
};
export const apiStores = {
    ...stores
};
