import React from 'react';
import { components } from 'react-select';
import { BadgeCounter } from '../BadgeCounter';
import { ValueLabelOption } from '../types';

import { StyledValueContainer, CounterLayout, Hide } from './styles';

export const ValueContainer: typeof components.ValueContainer = ({
    isMulti,
    children,
    selectProps: { value },
    ...props
}) => {
    if (isMulti) {
        const multiValues = value?.map((option: ValueLabelOption) => option);
        const multiValueLabel = multiValues?.[0]?.label || '';

        return (
            <StyledValueContainer {...props}>
                {multiValueLabel}
                {value?.length > 1 && (
                    <CounterLayout>
                        <BadgeCounter selectedValues={value?.length - 1} />
                    </CounterLayout>
                )}
                <Hide>{children}</Hide>
            </StyledValueContainer>
        );
    }

    return <StyledValueContainer {...props}>{children}</StyledValueContainer>;
};
