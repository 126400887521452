import { EuroLacquerParameters } from '../../types/factors';

export const euroLacquerInitialParameters: EuroLacquerParameters = {
    type: '',
    addition: [],
    exemplarySheets: '',
    colorName: '',
    colorCode: '',
    color2ndName: '',
    color2ndCode: '',

    wageMode: 'PER_TIME',
    wage: '',
    wageFlatRate: '',
    discountWage: '',

    materialMode: 'LACQUER_METHOD',
    materialPerPointCost: '',
    materialIndex: '',
    materialFlatRatePercent: '',
    materialFlatRateAbsolute: '',
    materialSpecialLacquer: '',
    discountMaterial: '',

    wageInclMaterialMode: 'PER_TIME',
    wageInclMaterial: '',
    wageInclMaterialFlatRate: '',
    discountWageInclMaterial: '',

    preparationTimePercent: '',
    preparationTimePlasticPercent: '',
    isReducedPreparationTime: false,
    isFrameworkUse: false,
    isSurchargeForSecondColor: false,
    isLacquerPlasticWhenFitted: false,
    isApplyUndercoatLacquerWhenRemoved: false,
    isLacquerAssemblyWhenRemoved: false,
    disposalCostPercent: '',
    maskingWorkGlassCount: '1',
    maskingWorkPlasticCount: '1',
    matBlackWindowFrameCount: '1',

    _attributes: {
        mode: {
            discountWage: 'PERCENT',
            discountMaterial: 'PERCENT',
            discountWageInclMaterial: 'PERCENT'
        }
    }
};
