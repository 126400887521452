import React, { FC, CSSProperties, ReactNode } from 'react';

import './iconBtn.css';

export interface IconType {
    style?: CSSProperties;
    className?: string;
    icon?: ReactNode;
    active?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

export const IconBtn: FC<IconType> = ({ style, className, icon, active = false, onClick, children }) => (
    <div
        className={
            (active ? 'weDat-ft-toolbar-icon weDat-ft-toolbar-icon-active ' : 'weDat-ft-toolbar-icon ') + className
        }
        style={{
            ...style
        }}
        onClick={onClick}
    >
        {icon}
        {children}
    </div>
);
