import styled from 'styled-components/macro';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit';

export const MainContainer = styled.div`
    padding: 20px;
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    margin-left: 15px;
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.gray['300']};

    @media ((min-width: ${sizes.tabletSmall}px) and (max-width: ${sizes.tablet}px)) or (max-width: ${sizes.phone}px) {
        padding: 20px 40px 50px 40px;
    }
`;

export const MainInfo = styled.div`
    width: 200px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;

    @media ((min-width: ${sizes.tabletSmall}px) and (max-width: ${sizes.tablet}px)) or (max-width: ${sizes.phone}px) {
        display: none;
    }
`;

export const MainInfoTitle = styled(Text)`
    margin-top: 8px;
    margin-bottom: 20px;
`;

export const MainInfoItem = styled.div`
    margin-top: auto;
`;

export const GaugeContainer = styled.div`
    position: relative;
    width: 175px;
    height: 100px;
    align-self: center;
    margin-left: auto;
`;

export const GaugeEfficiencyPercent = styled.div`
    display: none;
    @media ((min-width: ${sizes.tabletSmall}px) and (max-width: ${sizes.tablet}px)) or (max-width: ${sizes.phone}px) {
        display: block;
        position: absolute;
        width: 175px;
        text-align: center;
        left: 0;
        bottom: -30%;
        padding-left: 10px;
    }
`;
