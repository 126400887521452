import { combine, createEffect, sample, guard } from 'effector';
import { contractStores, contractEffects } from '../contract';
import { sharedUserStores } from '../user';
import { BlockingStatusProps, TemplateData } from './types';

export const retrieveField = (field: string, complexTemplateData?: TemplateData[] | DAT2.ComplexTemplateData) => {
    if (Array.isArray(complexTemplateData)) {
        const foundField = complexTemplateData?.find(({ label }) => label === field);
        return String(foundField?.stringValue) || null;
    }

    if (complexTemplateData?.field && Array.isArray(complexTemplateData?.field)) {
        const foundField = complexTemplateData?.field.find(({ label }) => label === field);
        return String(foundField?.stringValue) || null;
    }

    return null;
};

// Combines

const isDisabled = combine(
    contractStores.contract,
    sharedUserStores.customerNumber,
    sharedUserStores.username,
    (contract, customerNumber, username) => {
        if (!contract || !customerNumber || !username) {
            return false;
        }

        const blockedUserLoginFieldData = retrieveField('blockedUserId', contract.complexTemplateData);
        const blockedUntilDate = retrieveField('blockedUntilDate', contract.complexTemplateData);

        if (blockedUserLoginFieldData === 'undefined' || blockedUntilDate === 'undefined') {
            return false;
        }

        const isTimePast = new Date().getTime() > new Date(blockedUntilDate ?? '').getTime();
        const isSameUser = String(blockedUserLoginFieldData ?? '') === String(`${customerNumber}/${username}`);

        if (isTimePast || (!isTimePast && isSameUser)) {
            return false;
        }

        return true;
    }
);

// Effects

const updateBlockingStatusFx = createEffect({
    handler: async ({ customerNumber, username, complexTemplateData }: BlockingStatusProps) => {
        const blockedUserLoginFieldData = retrieveField('blockedUserId', complexTemplateData);

        const loginTimePlus30Minutes = new Date().getMinutes() + 30;
        const timeToSet = new Date().setMinutes(loginTimePlus30Minutes);
        const activeUntil = String(new Date(timeToSet));

        if (blockedUserLoginFieldData !== 'undefined') {
            const data = `${sharedUserStores.customerNumber.getState()}/${sharedUserStores.username.getState()}`;

            const perfEntries = performance.getEntriesByType('navigation');
            const [navs] = perfEntries;

            if ((navs as PerformanceNavigationTiming).type === 'reload') {
                const timeAfterReloading = new Date().getMinutes() + 30;
                const timeToSetAfterReloading = new Date().setMinutes(timeAfterReloading);
                const activeUntilAfterReloading = String(new Date(timeToSetAfterReloading));

                const reloader = `${customerNumber}/${username}`;
                reloader === data &&
                    (await contractEffects.updateCurrentContractFx({
                        templateData: {
                            entry: [
                                {
                                    key: 'blockedUserId',
                                    value: {
                                        _text: String(data)
                                    }
                                },
                                {
                                    key: 'blockedUntilDate',
                                    value: {
                                        _text: activeUntilAfterReloading
                                    }
                                }
                            ]
                        }
                    }));
            }

            return;
        }

        if (blockedUserLoginFieldData === 'undefined') {
            await contractEffects.updateCurrentContractFx({
                templateData: {
                    entry: [
                        {
                            key: 'blockedUserId',
                            value: {
                                _text: String(`${customerNumber}/${username}`)
                            }
                        },
                        {
                            key: 'blockedUntilDate',
                            value: {
                                _text: activeUntil
                            }
                        }
                    ]
                }
            });
        }

        await contractEffects.updateCurrentContractFx({
            templateData: {
                entry: [
                    {
                        key: 'blockedUserId',
                        value: {
                            _text: String(`${customerNumber}/${username}`)
                        }
                    },
                    {
                        key: 'blockedUntilDate',
                        value: {
                            _text: activeUntil
                        }
                    }
                ]
            }
        });
    }
});

const unblockUserFx = createEffect({
    handler: async () => {
        await contractEffects.updateCurrentContractFx({
            templateData: {
                entry: [
                    {
                        key: 'blockedUserId',
                        value: {
                            _text: 'undefined'
                        }
                    },
                    {
                        key: 'blockedUntilDate',
                        value: {
                            _text: 'undefined'
                        }
                    }
                ]
            }
        });
    }
});

const shouldGetContract = guard({
    source: contractStores.contractId,
    clock: updateBlockingStatusFx.doneData,
    filter: source => !isNaN(Number(source)) && Number(source) !== 0
});

sample({
    clock: shouldGetContract,
    source: contractStores.contractId,
    target: contractEffects.getContractFx
});

// Exports

export const blockingModalEffects = {
    updateBlockingStatusFx,
    unblockUserFx
};

export const blockingModalCombines = {
    isDisabled
};
