import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { modalsEvents, modalsStores } from '../../../stores/ui/modals';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';

import { Button, Buttons, CancelButton, Content, Description } from './styles';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { orderEvents } from '../../../stores/order';

export const NewOrder: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isOpen = useStore(modalsStores.isNewOrderModalOpen);
    const claimBuilders = useStore(sharedConfigurationStores.claimBuilders);

    const handleClick = ({ layoutType, ...rest }: DAT2.ClaimBuilder) => {
        if (layoutType === 'guided') {
            history.push(`/guided`);
            return;
        }
        orderEvents.createOrderWithType({
            ...rest,
            layoutType: layoutType || 'standard'
        });
    };

    return (
        <ModalPrimary
            isOpen={isOpen}
            title={t('newOrder.title')}
            onDismiss={modalsEvents.toggleIsNewOrderModalOpen}
            bodyHeight="fit-content"
        >
            <Content>
                <Description>{t('newOrder.description')}</Description>
                <Buttons>
                    {claimBuilders.map(builder => (
                        <Button
                            key={builder.name}
                            typeStyle={{ type: 'primary' }}
                            onClick={() => {
                                handleClick(builder as DAT2.ClaimBuilder);
                            }}
                        >
                            {builder.name}
                        </Button>
                    ))}
                </Buttons>

                <CancelButton onClick={modalsEvents.toggleIsNewOrderModalOpen}>
                    {t('newOrder.buttonCancel')}
                </CancelButton>
            </Content>
        </ModalPrimary>
    );
};
