import * as tokenService from './services/tokenService';
import * as vehicleRepairService from './services/vehicleRepairService';
import * as myClaimExternalService from './services/myClaimExternalService';
import * as fastTrackInterface from './services/fastTrackInterface';
import * as vehicleIdentificationService from './services/vehicleIdentificationService';

const API2DAT = {
    tokenService,
    vehicleRepairService,
    myClaimExternalService,
    fastTrackInterface,
    vehicleIdentificationService
};

export default API2DAT;
