import { WorkLevelBase } from '../types/positions';

export const WORK_LEVELS_SYMBOLS: Record<WorkLevelBase, string> = {
    easy: 'l',
    medium: 'm',
    hard: 'g',
    manual: '#',
    manufacturer: 'C',
    replace: 'S'
};

export const INITIAL_POSITION: DAT2.PositionItaly = {
    // @ts-ignore
    Amount: '',
    DATProcessId: 0,
    Description: '',
    Location: '',
    // @ts-ignore
    PartCodeItaly: '',
    PartNumber: '',
    // @ts-ignore
    Position: '',
    Type: '',
    PartDiscountAsterisk: '',
    PartDiscountType: '',
    // @ts-ignore
    PartDiscountPerc: '',
    // @ts-ignore
    ValueParts: '',
    // @ts-ignore
    WorkLevelReplace: '',
    WorkTimeOverhaul: '',
    WorkTimeReplace: '',
    WorkTimeLacquer: '',
    WorkTimeMechanic: ''
};

export const USER_DEFINED_POSITIONS: DAT2.UserDefinedPosition[] = [
    {
        Description: 'ADESIVO MONTANTE PORTA',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'ADESIVO TELAIO CRISTALLO PORTA ANT. O POST.',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'ALZACRISTALLO ELETTRICO',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'CHIUSURA CENTRALIZZATA',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'CONNESSIONE CENTRALINA ELETTRONICA',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'CRIST. FISSO PARAF. POST. COLLA, SE RIUTIL.',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'CRIST. LUNOTTO E PARAB. A COLLA,SE RIUTIL.',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'FANALERIA PARAURTI',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'MOTORINO TERGILUNOTTO',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'RETROVISORE EST. REGOLAZ. ELETTR.',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'RIVESTIMENTO INSONORIZZANTE COFANO',
        Amount: null,
        WorkTimeReplace: 0.3
    },
    {
        Description: 'SPOILER',
        Amount: null,
        WorkTimeReplace: 0.3
    }
];

export enum PaintWorkItemsWithDiscount {
    multiLayer = 'multiLayer',
    allLayers = 'allLayers'
}
