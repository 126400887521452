import React, { FC, useEffect } from 'react';

import translations from './i18n';
import { pluginEvents } from './stores/plugin';
import './stores/init';

import { Main } from './components/Main';
import { PluginProvider } from '@dat/smart-components/PluginProvider';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
    closeEquipmentModal: () => void;
}

const App: FC<Props> = ({ options, closeEquipmentModal }) => {
    useEffect(
        () => () => {
            pluginEvents.completePlugin();
        },
        []
    );

    return (
        <PluginProvider name="equipment" options={options} translations={translations} onInit={pluginEvents.initPlugin}>
            <Main closeEquipmentModal={closeEquipmentModal} />
        </PluginProvider>
    );
};

export default App;
