import React from 'react';
import { useStore } from 'effector-react';

import { attachmentsStores } from '../../stores/attachments';

import { Group } from './Group';

export const Attachments: React.FC = () => {
    const groups = useStore(attachmentsStores.groups);
    const groupedAttachments = useStore(attachmentsStores.groupedAttachments);
    const attachmentsWithoutGroup = useStore(attachmentsStores.attachmentsWithoutGroup);

    return (
        <section>
            {groups.map(({ id }) => (
                <Group id={id} attachments={groupedAttachments[id]} key={id} />
            ))}
            {!!attachmentsWithoutGroup.length && <Group attachments={attachmentsWithoutGroup} />}
        </section>
    );
};
