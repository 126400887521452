import { GeolocationInfo } from './geolocation';
import { getGpsPosition, getGpsStyles } from './plugin';

/**
 * Get coords (x, y) for GPS block
 */
const getPositionCoords = (
    canvas: HTMLCanvasElement,
    blockWidth: number,
    blockHeight: number
): { x: number; y: number } => {
    const position = getGpsPosition();

    switch (position) {
        case 'top-right':
            return { x: canvas.width - blockWidth, y: 0 };
        case 'top-left':
            return { x: 0, y: 0 };
        case 'center-top':
            return { x: canvas.width / 2 - blockWidth / 2, y: 0 };
        case 'bottom-right':
            return { x: canvas.width - blockWidth, y: canvas.height - blockHeight };
        case 'bottom-left':
            return { x: 0, y: canvas.height - blockHeight };
        case 'center-bottom':
            return { x: canvas.width / 2 - blockWidth / 2, y: canvas.height - blockHeight };
        default:
            return { x: canvas.width - blockWidth, y: 0 }; // top-right
    }
};

/**
 * Get padding number values
 */
const getPaddingValues = (padding: string) => {
    const paddingRegExp = /(\d+)px (\d+)px (\d+)px (\d+)px/;
    const [, paddingTop, paddingRight, paddingBottom, paddingLeft] = padding.match(paddingRegExp) || [
        '',
        '0',
        '0',
        '0',
        '0'
    ];

    return {
        paddingTop: +paddingTop,
        paddingRight: +paddingRight,
        paddingBottom: +paddingBottom,
        paddingLeft: +paddingLeft
    };
};

/**
 * Draw GPS and Time info into photo
 */
export const drawGps = (canvas: HTMLCanvasElement, geo: GeolocationInfo): void => {
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const { fontColor, fontFamily, fontSize, backgroundColor, padding, borderRadius } = getGpsStyles();

    const { paddingTop, paddingBottom, paddingLeft } = getPaddingValues(padding);

    const borderRadiusValue = Number.parseFloat(borderRadius);

    // initialize font-size font-family and background-color
    ctx.font = `${fontSize} ${fontFamily}`;
    ctx.fillStyle = backgroundColor;

    const firstRowText = `${geo.date} ${geo.time}`;
    const secondRowText = `Lat: ${geo.latitude} Long: ${geo.longitude}`;

    // calculate sizes
    const { width: firstRowWidth } = ctx.measureText(firstRowText);
    const { width: secondRowWidth } = ctx.measureText(secondRowText);

    const rowTextHeight = parseInt(ctx.font.substring(0, 2));
    const blockWidth = Math.max(firstRowWidth, secondRowWidth) + paddingLeft + paddingBottom;
    const blockHeight = 2 * rowTextHeight + paddingTop + paddingBottom;

    const position = getPositionCoords(canvas, blockWidth, blockHeight);

    if (borderRadiusValue) {
        // set rounded corners and bgr for stroke
        ctx.lineJoin = 'round';
        ctx.lineWidth = borderRadiusValue;
        ctx.strokeStyle = backgroundColor;

        // draw stroke and rect
        ctx.strokeRect(
            position.x + borderRadiusValue / 2,
            position.y + borderRadiusValue / 2,
            blockWidth - borderRadiusValue,
            blockHeight - borderRadiusValue
        );
        ctx.fillRect(
            position.x + borderRadiusValue / 2,
            position.y + borderRadiusValue / 2,
            blockWidth - borderRadiusValue,
            blockHeight - borderRadiusValue
        );
    } else {
        ctx.rect(position.x, position.y, blockWidth, blockHeight);
        ctx.fill();
    }

    // initialize font-color
    ctx.fillStyle = fontColor;

    // draw text
    ctx.fillText(firstRowText, position.x + paddingLeft, position.y + rowTextHeight + paddingTop);
    ctx.fillText(secondRowText, position.x + paddingLeft, position.y + 2 * rowTextHeight + paddingTop);
};
