import * as vehicleSelection from './services/vehicle-selection';
import * as valuateFinance from './services/valuate-finance';
import * as myClaim from './services/my-claim';
import * as myClaimInternal from './services/myClaim-internal';
import * as equipment from './services/equipment';
import * as calculatePro from './services/calculate-pro';
import * as bff from './services/bff';
import * as ania from './services/ania';
import * as aiValidation from './services/ai-validator';

import './stores/init';

export const API2 = {
    aiValidation,
    vehicleSelection,
    valuateFinance,
    myClaim,
    myClaimInternal,
    equipment,
    calculatePro,
    bff,
    ania
};
