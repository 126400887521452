import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;

    ${media.laptopSmall`
        width: 400px;
    `}

    ${media.phoneBig`
        width: 100%;
        height: 100%;
    `}

    ${media.phoneMedium`
        /* width: 300px; */
        padding-top: 20px;
    `}
`;

export const RadioGroupFieldWrapper = styled.div`
    margin-top: 12px;
    margin-left: 30px;
`;

export const MarginWrapper = styled.div`
    margin-top: 20px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${media.phoneBig`
        height: 100%;
        justify-content: space-between;
    `}
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    padding-bottom: 20px;

    ${media.phoneBig`
        flex-direction: column;
    `}
`;

export const ButtonStyled = styled(Button)`
    width: 100px;
    border: solid 2px ${({ theme }) => theme.colors.blueDark};

    ${media.phoneBig`
        width: 100%;
        margin-bottom: 10px;
    `}
`;

export const CancelButton = styled(Button)`
    width: 140px;
    margin-left: 20px;
    color: ${({ theme }) => theme.colors.blueDark};
    border-color: ${({ theme }) => theme.colors.blueDark};
    background-color: ${({ theme }) => theme.colors.white};

    ${media.phoneBig`
        width: 100%;
        margin-left: 0;
    `}
`;

export const AdditionalWrapper = styled.div``;
