// OverhaulTypeView
import React, { useState } from 'react';

import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, InputNumber, Select, Checkbox } from 'antd';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { workTimeUnits } from '../../../initData/workTimeUnits';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { mappingDVNWorkTypeWorkLevelModel } from '../../../stores/mappingDVNWorkTypeWorkLevelModel';

const { Option } = Select;

const arrayFixingTimes = [1, 1.5, 2, 2.5, 3, 5];

// const layout = {
//     labelCol: { span: 10 },
//     wrapperCol: { span: 24 }
// };

export function OverhaulTypeView() {
    const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
    const {
        currentAssemblyGroup,
        currentAssemblyGroupObject: currentObj,
        currentDVNs
    } = useStore(graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore);
    const { t } = useTranslation();

    const datECode = useStore(pluginOptionsModel.stores.modelDatECode);

    const mappingDVNWorkTypeWorkLevel = useStore(mappingDVNWorkTypeWorkLevelModel.stores.mappingDVNWorkTypeWorkLevel);

    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const useWorkTypeLevel = pluginOptions?.settings?.useWorkTypeLevel;

    const [checkedPreDamage, setCheckedPreDamage] = useState(false);

    // if (currentDVNs?.length !== 1) return null;
    // const dvn = currentDVNs[0];

    // const dvnLR = currentObj?.dvnLeft || currentObj?.dvnRight;
    // const dvn = dvnLR.dvn;

    if (currentDVNs?.length !== 1) return null;
    const dvn = currentDVNs[0];
    const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;
    if (!dvnLR) return null;

    const hst = datECode.slice(2, 5);

    const RepairType = dvnLR.rcs.rc.find(elem => elem === 'I') ? 'overhaul' : 'fixing';
    const timeUnitsPerHour = workTimeUnits.find(unit => unit.hst === hst)?.timeUnitsPerHour;

    const repairPosition = repairPositions.find(item => item.DATProcessId === dvn && item.RepairType === RepairType);

    const WorkTime = repairPosition?.WorkTime || 0;
    const workTimeInTimeUnits = Math.round(WorkTime * (timeUnitsPerHour || 1));
    const WorkPrice = repairPosition?.WorkPrice || 0;
    const PreDamage = repairPosition?.PreDamage;

    const WorkType = repairPosition?.WorkType;
    const WorkLevel = repairPosition?.WorkLevel;

    if (!currentAssemblyGroup || !RepairType) return null;

    const defWorkType = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workType;
    const defWorkLevel = mappingDVNWorkTypeWorkLevel?.[dvnLR.dvn]?.[RepairType]?.workLevel;

    const onChangeOverhaulFixing =
        RepairType === 'overhaul'
            ? RepairPositionsModel.event.addOverhaulIDamage
            : RepairPositionsModel.event.addFixingSlashDamage;

    return (
        <div
            style={{ margin: '16px 0px 16px 0px' }}
            // className={'fixingTimeSelectionButtons'}
        >
            <Row align="middle" gutter={[8, 8]}>
                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('ManualRepairPositionForm.PreDamage')}
                </Col>
                <Col span={12}>
                    <Checkbox
                        defaultChecked={PreDamage}
                        onChange={e => {
                            if (WorkTime || WorkPrice)
                                onChangeOverhaulFixing({
                                    assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                    DATProcessId: dvnLR.dvn || 0,
                                    Description: currentObj?.titleMenu || '',
                                    PreDamage: e.target.checked
                                });
                            setCheckedPreDamage(e.target.checked);
                        }}
                    >
                        {t('ManualRepairPositionForm.PreDamageOptions.preDamage')}
                    </Checkbox>
                </Col>
                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('ManualRepairPositionForm.WorkTime')}
                </Col>
                {timeUnitsPerHour && (
                    <>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                type="number"
                                value={workTimeInTimeUnits}
                                onChange={value => {
                                    onChangeOverhaulFixing({
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        DATProcessId: dvnLR.dvn || 0,
                                        Description: currentObj?.titleMenu || '',
                                        WorkTime: Math.round((value / (timeUnitsPerHour || 1)) * 100) / 100,
                                        WorkPrice: 0,
                                        PreDamage: checkedPreDamage
                                    });
                                }}
                            />
                            <span>&nbsp;{t('ManualRepairPositionForm.TimeUnits')}</span>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 10 }}></Col>
                    </>
                )}
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={WorkTime}
                        onChange={value => {
                            onChangeOverhaulFixing({
                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                DATProcessId: dvnLR.dvn || 0,
                                Description: currentObj?.titleMenu || '',
                                WorkTime: Math.round(value * 100) / 100,
                                WorkPrice: 0,
                                PreDamage: checkedPreDamage
                            });
                        }}
                    />
                    <span>&nbsp;{t('ManualRepairPositionForm.Hours')}</span>
                </Col>

                <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                    {t('ManualRepairPositionForm.WorkPrice')}
                </Col>
                <Col span={12}>
                    <InputNumber
                        size="large"
                        type="number"
                        value={WorkPrice}
                        onChange={value => {
                            onChangeOverhaulFixing({
                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                DATProcessId: dvnLR.dvn || 0,
                                Description: currentObj?.titleMenu || '',
                                WorkPrice: parseFloat(value.toString()),
                                WorkTime: 0,
                                PreDamage: checkedPreDamage
                            });
                        }}
                    />
                </Col>

                {useWorkTypeLevel && (
                    <>
                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ExtPartNoInfoView.WorkType')}
                        </Col>
                        <Col span={12}>
                            <Select
                                size="large"
                                style={{ width: 120 }}
                                defaultValue={defWorkType}
                                value={WorkType}
                                onChange={value => {
                                    onChangeOverhaulFixing({
                                        DATProcessId: dvnLR.dvn,
                                        Description: currentObj?.titleMenu || '',
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        WorkType: value || null
                                    });
                                }}
                                allowClear
                            >
                                <Option value="other">{t('WorkType.Other')}</Option>
                                <Option value="mechanic">{t('WorkType.Mechanic')}</Option>
                                <Option value="car body">{t('WorkType.CarBody')}</Option>
                                <Option value="electric">{t('WorkType.Electric')}</Option>
                            </Select>
                        </Col>

                        <Col style={{ textAlign: 'right' }} xs={{ span: 12 }} sm={{ span: 10 }}>
                            {t('ExtPartNoInfoView.WorkLevel')}
                        </Col>
                        <Col span={12}>
                            <Select
                                size="large"
                                style={{ width: 120 }}
                                defaultValue={defWorkLevel}
                                value={WorkLevel}
                                onChange={value => {
                                    onChangeOverhaulFixing({
                                        DATProcessId: dvnLR.dvn,
                                        Description: currentObj?.titleMenu || '',
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        WorkLevel: value || null
                                    });
                                }}
                                allowClear
                            >
                                <Option value="0">{t('WorkLevel.0')}</Option>
                                <Option value="1">{t('WorkLevel.1')}</Option>
                                <Option value="2">{t('WorkLevel.2')}</Option>
                                <Option value="3">{t('WorkLevel.3')}</Option>
                            </Select>
                        </Col>
                    </>
                )}
            </Row>

            {/* <Form
                style={{ margin: '16px 0px 16px 0px' }}
                {...layout}
                // onSubmitCapture={handleSubmit}
            >
                <Form.Item label={t('ManualRepairPositionForm.WorkTime')}>
                    <InputNumber
                        // className="inputWorkTime"
                        // style={{ textAlign: 'right' }}
                        type="number"
                        value={WorkTime}
                        onChange={value => {
                            // const { value } = e.target;
                            if (RepairType === 'overhaul') {
                                if (currentAssemblyGroup)
                                    RepairPositionsModel.event.addOverhaulIDamage({
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        DATProcessId: dvnLR.dvn || 0,
                                        Description: currentObj?.titleMenu || '',
                                        WorkTime: parseFloat(value.toString())
                                    });
                            } else if (currentAssemblyGroup)
                                RepairPositionsModel.event.addFixingSlashDamage({
                                    assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                    DATProcessId: dvnLR.dvn || 0,
                                    Description: currentObj?.titleMenu || '',
                                    WorkTime: parseFloat(value.toString())
                                });
                        }}
                    />
                </Form.Item>
            </Form> */}
            <Row gutter={[8, 8]}>
                {/* <Col offset={18} span={6}>
                    <Input
                        // className="inputWorkTime"
                        style={{ textAlign: 'right' }}
                        type="number"
                        value={WorkTime}
                        onChange={e => {
                            const { value } = e.target;
                            if (RepairType === 'overhaul') {
                                if (currentAssemblyGroup)
                                    RepairPositionsModel.event.addOverhaulIDamage({
                                        assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                        DATProcessId: dvnLR.dvn || 0,
                                        Description: currentObj?.titleMenu || '',
                                        WorkTime: parseFloat(value)
                                    });
                            } else if (currentAssemblyGroup)
                                RepairPositionsModel.event.addFixingSlashDamage({
                                    assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                    DATProcessId: dvnLR.dvn || 0,
                                    Description: currentObj?.titleMenu || '',
                                    WorkTime: parseFloat(value)
                                });
                        }}
                    />
                </Col> */}
                {/* todo may be create params showFixingTimes */}
                {false &&
                    arrayFixingTimes.map(item => (
                        <Col span={4}>
                            <Button
                                style={{ width: '100%' }}
                                size="large"
                                key={item}
                                // className="buttonGraphicDamageForm"
                                type={WorkTime === item ? 'primary' : 'default'}
                                onClick={() => {
                                    if (RepairType === 'overhaul') {
                                        if (currentAssemblyGroup)
                                            RepairPositionsModel.event.addOverhaulIDamage({
                                                assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                                DATProcessId: dvnLR?.dvn || 0,
                                                Description: currentObj?.titleMenu || '',
                                                WorkTime: item
                                            });
                                    } else if (currentAssemblyGroup)
                                        RepairPositionsModel.event.addFixingSlashDamage({
                                            assemblyGroup: currentAssemblyGroup.assemblyGroup,
                                            DATProcessId: dvnLR?.dvn || 0,
                                            Description: currentObj?.titleMenu || '',
                                            WorkTime: item
                                        });
                                }}
                            >
                                {item}
                            </Button>
                        </Col>
                    ))}
            </Row>
        </div>
    );
}
