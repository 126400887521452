import React, { FC, InputHTMLAttributes } from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox, Text } from './styles';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

export const Checkbox: FC<CheckboxProps> = ({ className, checked, disabled, label, onChange, ...rest }) => (
    <label>
        <CheckboxContainer className={className}>
            <HiddenCheckbox checked={checked} disabled={disabled} {...rest} onChange={onChange} type="checkbox" />
            <StyledCheckbox checked={checked}>
                <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </Icon>
            </StyledCheckbox>
            {label && <Text disabled={disabled}>{label}</Text>}
        </CheckboxContainer>
    </label>
);
