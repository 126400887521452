/*
 * Receives function in which first parameter is an object with required field "restriction".
 * Sets field "restriction" to optional and makes default value of "restriction"
 * to be the value defined in "apiStores.restriction".
 */
import { sharedTemplateStores } from '../../template';

type OptionalRestriction<T> = Omit<T, 'restriction'> & {
    restriction?: DAT.Restriction;
};
type OriginalHandler<Data, Result, Args extends any[]> = (data: Data, ...args: Args) => Result;

export const createDefaultRestrictionHandler =
    <Data, Result, Args extends any[] = []>(originalHandler: OriginalHandler<Data, Result, Args>) =>
    (
        {
            restriction = sharedTemplateStores.templateSettings.restriction.getState(),
            ...rest
        }: OptionalRestriction<Data>,
        ...args: Args
    ) =>
        originalHandler({ restriction, ...rest } as unknown as Data, ...args);
