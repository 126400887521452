import styled from 'styled-components/macro';
import { PreloaderPreview } from '../styles';
import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';

export const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

export const HistoryHeader = styled.div`
    border-bottom: thin ${({ theme }) => theme.colors.gray['300']} solid;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.dustBlue['600']};
`;

export const HistoryLoading = styled(PreloaderPreview)`
    height: 100px;
`;

export const HistoryHeaderDate = styled.div`
    flex: 0 0 100px;
    margin-right: 10px;
`;

export const HistoryList = styled.div`
    overflow: auto;
    ${makeCustomScrollBar()}
`;

export const HistoryListItem = styled.div`
    display: flex;
    padding: 10px;
    border-bottom: thin solid ${({ theme }) => theme.colors.gray['200']};
`;

export const HistoryDate = styled.div`
    flex: 0 0 100px;
    margin-right: 10px;
    margin-top: 4px;
`;
