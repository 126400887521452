import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div<{ disableHover: boolean }>`
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 10px;
    min-height: 44px;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    ${({ disableHover }) =>
        !disableHover &&
        css`
            &:hover {
                background-color: ${({ theme }) => theme.colors.blue['50']};
            }

            &:active {
                background-color: ${({ theme }) => theme.colors.blue['100']};
            }
        `}

    ${media.tablet`
        border-radius: 0;
    `}
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 300px;
    padding-top: 10px;
    padding-bottom: 8px;
`;

export const IconContainer = styled.div`
    display: flex;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 100%;
    color: ${({ theme }) => theme.colors.dustBlue['400']};

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
    &:active {
        color: ${({ theme }) => theme.colors.blue['100']};
    }

    svg {
        width: 20px;
        height: 20px;
    }

    ${media.tablet`
        width: 48px;
        svg {
            width: 22px;
            height: 22px;
        }
    `}
`;
