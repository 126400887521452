import { componentEvents } from './index';

const { pluginUnmounted } = componentEvents;

// pluginInitialized.watch(({ onInit, options }) => {
//     onInit?.(options);
// });

pluginUnmounted.watch(({ onUnmount, options }) => {
    onUnmount?.(options);
});
