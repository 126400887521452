import { v4 as uuidv4 } from 'uuid';
import { generateMemberId } from './generateMemberId';

interface Params {
    customerNumber: number;
    username: string;
    messageText: string;
}

export const createMessage = ({ customerNumber, username, messageText }: Params): DAT2.Plugins.Chat.Message => ({
    created: Date.now(),
    id: uuidv4(),
    read: false,
    sender: generateMemberId({ customerNumber, username }),
    text: messageText
});
