import { datProdServicesUrls } from '@dat/api2dat/baseParameters';
import { Credentials, DatServicesUrls } from '@dat/api2dat/types/api2datTypes';
import { pluginOptionsModel } from './pluginOptionsModel';

const requestCredentials = pluginOptionsModel.stores.pluginOptions.map<Credentials>((options, lastState) => {
    const newOpt = options?.credentials || { 'dat-authorizationtoken': options?.token || '' };
    if (JSON.stringify(newOpt) === JSON.stringify(lastState) && !!lastState) return lastState;
    return newOpt;
});

const datServicesUrls = pluginOptionsModel.stores.pluginOptions.map<DatServicesUrls>((options, lastState) => {
    const newVal: DatServicesUrls = {
        ...datProdServicesUrls,
        ...options?.datServicesUrls
    };
    if (JSON.stringify(newVal) === JSON.stringify(lastState) && !!lastState) return lastState;
    return newVal;
});

export const requestCredentialsModel = {
    stores: {
        requestCredentials,
        datServicesUrls
    },
    events: {}
};
