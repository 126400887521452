import React, { useEffect } from 'react';
import { Route, useParams, Switch } from 'react-router-dom';
import CacheRoute from 'react-router-cache-route';
import { generatePath, Redirect } from 'react-router';
import { useGate, useStore } from 'effector-react';

import { ALWAYS_ALIVE_ROUTES, ROUTES, ROUTES_PARAMS_VALUES } from '../../constants/router';
import { useScrollUpOnLocationChange } from '../../hooks/useScrollUpOnLocationChange';
import { ClaimPageGate } from '../../stores/router';
import { contractEvents, contractStores } from '@dat/shared-models/contract';

import { PageWrapper } from './styles';
import { Preloader } from '@wedat/ui-kit/components/Preloader';
import { ClaimHeader } from '../../components/ClaimHeader';
import { AlwaysAliveRoute } from '../../components/AlwaysAliveRoute';
import { OpeningPage } from './Opening';
import { FastTrackPage } from './FastTrack';
import { GrapaPage } from './Grapa';
import { GalleryPage } from './Gallery';
import { CalculationPage } from './Calculation';
import { UnavailableClaim } from './UnavailableClaim';
import { SchedulerPage } from './SchedulerPage';
import { RoutesParams } from '../../types/router';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Footer } from '../../components/Footer';
import { ValuationPage } from './Valuation';
import { PartSelectionPage } from './PartSelectionPage';
import { LabourRatesGenericPage } from './LabourRatesGeneric';

// claim = contract = order = заявка на обслуживание
export const ClaimPage: React.FC = () => {
    const params = useParams<RoutesParams['claim']>();
    const { contractId } = params;
    const isExistingContract = contractId !== ROUTES_PARAMS_VALUES.claim.contractId.new;
    const contract = useStore(contractStores.contract);

    const isContractDisabled = useStore(contractStores.isContractDisabled);

    const isTablet = useMedia(sizes.tablet);

    useGate(ClaimPageGate, params);
    useScrollUpOnLocationChange();

    useEffect(() => {
        contractEvents.resetContract();
    }, []);

    if (isExistingContract && !contract) {
        return <Preloader isLoading={true} />;
    }

    if (isContractDisabled) {
        return <UnavailableClaim />;
    }

    return (
        <PageWrapper>
            <ClaimHeader />

            <CacheRoute path={ROUTES.claim.opening} component={OpeningPage} />
            <AlwaysAliveRoute path={ROUTES.claim.fastTrack} component={FastTrackPage} />
            <AlwaysAliveRoute path={ROUTES.claim.grapa} component={GrapaPage} />
            <AlwaysAliveRoute path={ROUTES.claim.gallery} component={GalleryPage} />

            <Switch>
                <Route path={ALWAYS_ALIVE_ROUTES.claim} component={() => null} />

                <Route path={ROUTES.claim.valuation} component={ValuationPage} />
                <Route path={ROUTES.claim.calculation} component={CalculationPage} />
                <Route path={ROUTES.claim.scheduler} component={SchedulerPage} />
                <Route path={ROUTES.claim.partSelection} component={PartSelectionPage} />
                <Route path={ROUTES.claim.labourRatesGeneric} component={LabourRatesGenericPage} />

                <Redirect to={generatePath(ROUTES.claim.opening, { contractId })} />
            </Switch>

            {isTablet && <Footer />}
        </PageWrapper>
    );
};
