import { createEvent, createStore, restore } from 'effector';

const setIsMobile = createEvent<boolean>();
const isMobile = restore(setIsMobile, false);

const setSummaryPanelOpened = createEvent<boolean>();
const summaryPanelOpened = createStore<boolean>(true).on(setSummaryPanelOpened, (_, val) => val);

export const commonModel = {
    stores: {
        isMobile,
        summaryPanelOpened
    },
    events: {
        setIsMobile,
        setSummaryPanelOpened
    },
    effects: {}
};
