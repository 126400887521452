import { makeCustomScrollBar } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 4px;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;

export const Header = styled.div`
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
`;

export const SelectWrapper = styled.div`
    margin-right: 12px;
    flex: 1;
`;

export const InputWrapper = styled.div`
    position: relative;
    flex: 1;
`;

export const InputClose = styled.div`
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    color: ${({ theme }) => theme.colors.dustBlue['400']};
    cursor: pointer;
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    overflow-y: auto;

    ${makeCustomScrollBar()}
`;
