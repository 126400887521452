import { getImageTensorFromPath } from './imageHelperCarChecker';
import { runPhotoCapturingModel } from './modelHelperCarChecker';
import { modelConstraints224 } from '../constants/constraints';
import { CarType, Sessions, Sides } from '../types';

const CONSTRAINTS_LENGTH = 8;

export async function inferenceCarChecker(
    path: string,
    bodyType: CarType = 'SW',
    viewName: Sides = 'front',
    sessions: Sessions
) {
    // 1. Convert image to tensor
    const [imageTensor, height, width] = await getImageTensorFromPath(path);
    // 2. Take constraints base on view
    // view_mapping = {
    //   "front": 1,
    //   "rear": 2,
    //   "frontRight":3,
    //   "frontLeft":4,
    //   "rearRight":5,
    //   "rearLeft":6,
    // }
    // move view to one position back
    const view = new Int32Array(1);
    view[0] = 1;
    const image_shape = new Int32Array(2);
    const constraints = new Float32Array(CONSTRAINTS_LENGTH);
    image_shape[0] = Number(height);
    image_shape[1] = Number(width);

    // set dynamic keys for modelConstraints
    const currConstraint = modelConstraints224[bodyType];

    for (let i = 0; i < CONSTRAINTS_LENGTH; i++) {
        constraints[i] = currConstraint[viewName][i];
    }

    // 3. Run detection and checker model
    const { output, inferenceTime } = await runPhotoCapturingModel(
        imageTensor,
        constraints,
        view,
        image_shape,
        sessions
    );
    // 3. Return predictions and the amount of time it took to inference.
    return { output, inferenceTime };
}
