// SwitchDvnRepairView

import React, { memo } from 'react';
import { useStore } from 'effector-react';

import { AniaTimesView2 } from './AniaTimesView2';
import { DefaultDvnRepairView2 } from './DefaultDvnRepairView2';
import { pluginOptionsModel } from '../../../stores/pluginOptionsModel';
import { italyAniaModel2 } from '../../../stores/italyAniaModel2';

type DvnRepairView = 'default' | 'ania';

export const SwitchDvnRepairView = memo(
    () => {
        const isVroDomusCalcualtion = useStore(
            pluginOptionsModel.stores.pluginOptions.map(
                opt => opt?.settings?.contract?.complexTemplateData?.attr?.templateType === 'vro_domus_calculation'
            )
        );

        const currentAniaTimes = useStore(italyAniaModel2.stores.currentAniaTimes);

        let dvnRepairView: DvnRepairView = 'default';
        if (isVroDomusCalcualtion && currentAniaTimes) dvnRepairView = 'ania';

        function renderSwitchDvnRepairView(dvnRepairView: DvnRepairView) {
            switch (dvnRepairView) {
                case 'ania':
                    return <AniaTimesView2 />;
                default:
                    return <DefaultDvnRepairView2 />;
            }
        }

        return (
            <div>
                {/* <div style={{ margin: '0px 0px 16px 0px' }}>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Button
                            style={{ width: '100%' }}
                            size={'large'}
                            type={dvnRepairView === 'default' ? 'primary' : 'default'}
                            onClick={() => setDvnRepairView('default')}
                        >
                            {t('Ania.Default')}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            // icon={getDvnRepairTimesFXPending <LoadingOutlined />}
                            style={{ width: '100%' }}
                            size={'large'}
                            type={dvnRepairView === 'ania' ? 'primary' : 'default'}
                            onClick={() => setDvnRepairView('ania')}
                        >
                            {t('Ania.Ania')}
                        </Button>
                    </Col>
                </Row>
            </div> */}
                {renderSwitchDvnRepairView(dvnRepairView)}
            </div>
        );
    },

    () => true
);
