// SwitchWithSwipe

import React from 'react';
import { Switch } from 'antd';

type StateEv = { screenX: number; screenY: number } | undefined;

export function SwitchWithSwipe(props: {
    checked: boolean;
    disabled?: boolean;
    // onClick: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
    // onClick: (checked: boolean) => void;
    // onChange?: (e?: Event) => void;
    onChange?: (value: boolean) => void;
    style?: React.CSSProperties;
}) {
    // const [beginTouch, setBeginTouch] = useState<{ screenX: number; screenY: number } | undefined>(undefined);

    let beginTouch: StateEv = undefined;

    function setBeginTouch(val: StateEv) {
        beginTouch = val;
    }

    function onBegin({ screenX, screenY }: { screenX: number; screenY: number }) {
        setBeginTouch({
            screenX,
            screenY
        });
    }

    function onEnd({ screenX }: { screenX: number; screenY: number }) {
        if (beginTouch) {
            if (screenX - beginTouch.screenX <= 0 && props.checked) {
                props.onChange?.(false);
            }
            if (screenX - beginTouch.screenX >= 0 && !props.checked) {
                props.onChange?.(true);
            }
        }
        setBeginTouch(undefined);
    }

    return (
        <span
            onTouchStart={e => {
                e.stopPropagation();
                e.preventDefault();
                if (e.touches.length === 1)
                    onBegin({
                        screenX: e.touches[0].screenX,
                        screenY: e.touches[0].screenY
                    });
            }}
            onTouchMove={e => {
                e.stopPropagation();
                e.preventDefault();
                if (e.touches.length === 1)
                    onEnd({
                        screenX: e.touches[0].screenX,
                        screenY: e.touches[0].screenY
                    });
            }}
            onTouchEnd={e => {
                e.stopPropagation();
                e.preventDefault();
                if (beginTouch) onEnd(beginTouch);
            }}
            onMouseDown={e => {
                e.stopPropagation();
                e.preventDefault();
                onBegin({
                    screenX: e.screenX,
                    screenY: e.screenY
                });
            }}
            onMouseUp={e => {
                e.stopPropagation();
                e.preventDefault();
                onEnd({
                    screenX: e.screenX,
                    screenY: e.screenY
                });
            }}
            onMouseLeave={e => {
                e.stopPropagation();
                e.preventDefault();
                onEnd({
                    screenX: e.screenX,
                    screenY: e.screenY
                });
            }}
            onMouseMove={e => {
                e.stopPropagation();
                e.preventDefault();
                onEnd({
                    screenX: e.screenX,
                    screenY: e.screenY
                });
            }}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Switch style={props.style} checked={props.checked} disabled={props.disabled} />
        </span>
    );
}
