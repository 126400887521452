import styled, { css } from 'styled-components/macro';
import { SortDown } from '@wedat/ui-kit/assets/Icon';
import { media } from '@wedat/ui-kit/mediaQueries';

interface ColumnWrapperProps {
    useRemainingSpace?: boolean;
    sort?: boolean;
    center?: boolean;
}

export const Container = styled.div`
    display: flex;
    padding-bottom: 5px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.dustBlue['50']};
    overflow: hidden;
    height: 35px;
`;

export const ColumnWrapper = styled.div<ColumnWrapperProps>`
    margin: 0 10px;
    padding: 0 10px;
    color: #aaa;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 0 0 180px;
    ${({ useRemainingSpace = false }) =>
        useRemainingSpace &&
        css`
            flex: auto;
            min-width: 150px;
        `};

    ${({ center }) =>
        center &&
        css`
            text-align: center;
            flex: 0 0 200px;
        `};

    ${({ sort, theme }) =>
        sort &&
        css`
            &:hover {
                color: ${theme.colors.blue['700']};
                cursor: pointer;
            }
        `};

    ${media.tablet`
        flex: ${({ useRemainingSpace = false }) => `${useRemainingSpace ? 'auto' : `0 0 130px`}`};
        padding: 0 5px;
        ${({ center }) =>
            center &&
            css`
                flex: 0 0 170px;
            `};
    `}
`;

export const ColumnWrapperEmptyStart = styled(ColumnWrapper)`
    flex: 0 0 154px;
    margin: 0;
    padding: 0;

    ${media.tablet`
        flex: 0 0 148px;  
    `}
`;

export const ColumnWrapperEmptyEnd = styled(ColumnWrapper)`
    flex: 0 0 34px;
    margin-right: 20px;

    ${media.tablet`
        flex: 0 0 58px;
    `}
`;

const sortIconStyles = css`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 5px;
`;

export const SortUpIcon = styled(SortDown)`
    transform: rotate(180deg);
    ${sortIconStyles};
`;

export const SortDownIcon = styled(SortDown)`
    ${sortIconStyles}
`;
