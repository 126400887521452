import { attachmentsEvents } from './index';
import { parseAttachmentItem } from '@dat/core/utils/attachments/parseAttachmentItem';
import { convertFilesToAttachments } from '@dat/core/utils/attachments/convertFilesToAttachments';

const { filesInSelected, addAttachments } = attachmentsEvents;

filesInSelected.watch(async files => {
    const attachments = await convertFilesToAttachments({ files, groupId: 0 });

    const attachmentsWithBase64 = attachments.map(parseAttachmentItem);

    addAttachments(attachmentsWithBase64);
});
