import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const FlexWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 12px;
    row-gap: 8px;

    ${media.tabletSmall`
        grid-template-columns: repeat(3, 1fr);
    `}

    ${media.laptopSmall`
        grid-template-columns: repeat(2, 50%);
    `}

    ${media.phoneBig`
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-wrap: wrap;
    `}
`;

export const DataBlock = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 24px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    background-color: ${({ theme }) => theme.colors.gray['50']};

    ${media.phoneBig`
        /* 12px it's indent between blocks, column-gap */
        width: calc(50% - 12px);
    `}
`;

export const TextStyled = styled(Text)`
    margin-right: 8px;
`;
