import { useStore } from 'effector-react';
import { useState, useEffect, FC, useMemo } from 'react';
import { DataField } from '@dat/form-builder/src/types/dataScheme';
import { stepperStores } from '../../stores/stepper';
import { StepperProps } from '../../types/config';
import { StepperActions } from '../StepperActions';
import { StepperProgress } from './stepperProgress';
import { Container, StepperSelector, Step, Heading } from './styles';
import { useTranslation } from 'react-i18next';
import { unset } from 'lodash';
import { contractEffects } from '@dat/shared-models/contract';
import { Grapa } from '@dat/grapa';
import { containedPluginsStores } from '@dat/claim-management/src/stores/containedPlugins';
import { FastTrack } from '@dat/fast-track';
import { stepperContractStores } from '../../stores/contract';

export const Stepper: FC<StepperProps> = ({ steps }) => {
    const optionsForGrapa = useStore(containedPluginsStores.grapaPluginOptions);
    const { t } = useTranslation();
    const [isDisabled, setDisabled] = useState(true);
    const [isDone, setIsDone] = useState(false);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const currentStepData = useStore(stepperStores.currentStepData);
    const [values, setValues] = useState<Record<string, string>>({});
    const fields = steps[currentStep - 1]?.options?.FormBuilder?.data[1]?.groups[0]?.content?.rows[0]?.fields;
    const filtered: Array<string | boolean> = fields?.map((item: DataField) => item.required && item.id);
    const filt = filtered?.flat().filter(item => item !== false);
    const isGrapa = useMemo(() => steps[currentStep - 1].plugin?.includes('Grapa'), [currentStep, steps]);
    const isFastTrack = useMemo(() => steps[currentStep - 1].plugin?.includes('FastTrack'), [currentStep, steps]);
    const fastTrackOptions = useStore(stepperContractStores.fastTrackPluginOptions);
    useEffect(() => {
        if (currentStep === 1) {
            contractEffects.createContractFx();
        }
    }, [currentStep]);

    //TODO: refactor: create useCallback
    useEffect(() => {
        fields?.forEach((field: DataField) => {
            !steps[currentStep - 1]?.plugin?.includes('DamageSelector') &&
                setValues(prev => ({
                    ...prev,
                    [field.id]: currentStepData[field.id] ? currentStepData[field.id] : ''
                }));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);
    useEffect(() => {
        if (filt?.length === 0) {
            setDisabled(false);
        }

        const isDisable = filt?.every(
            item => typeof item === 'string' && Object.keys(values).includes(item) && values[item] !== ''
        );
        setDisabled(!isDisable);
        if (isFastTrack || isGrapa) {
            setDisabled(false);
        }
    }, [filt, isFastTrack, isGrapa, values]);

    const goNextStep = () => {
        Object.keys(values).forEach(item => {
            values[item] === '' && unset(values, item);
        });
        const nextStep = currentStep + 1;
        if (nextStep <= steps.length) {
            setCurrentStep(nextStep);
        }
        setIsDone(true);
    };

    const goPreviousStep = () => {
        Object.keys(values).forEach(item => {
            values[item] === '' && unset(values, item);
        });

        const previousStep = currentStep - 1;
        if (previousStep >= 1) {
            setCurrentStep(previousStep);
        }
    };

    const onProgressBarClick = (index: number) => {
        if (index <= currentStep) {
            goPreviousStep();
        } else {
            goNextStep();
        }
    };

    return (
        <Container>
            <Heading font="Noto Sans TC">{t('page.header.title')}</Heading>
            <StepperProgress
                onProgressBarClick={onProgressBarClick}
                stepTitles={steps.map(step => step.title)}
                title={steps[currentStep - 1].title}
                currentStep={currentStep}
                isLast={currentStep === steps.length}
                isFilled={isDisabled}
                isDone={isDone}
            />
            {isGrapa ? (
                <Grapa options={optionsForGrapa} />
            ) : isFastTrack ? (
                <FastTrack options={fastTrackOptions} />
            ) : (
                <StepperSelector isFastTrack={isFastTrack}>
                    {steps.map(
                        (step, index) =>
                            index + 1 === currentStep && (
                                <Step isFastTrack={isFastTrack} key={`${step.title}-${currentStep}`}>
                                    <step.element
                                        step={index + 1}
                                        currentStep={currentStep}
                                        setValues={setValues}
                                        values={values}
                                        fields={fields}
                                        goNextStep={goNextStep}
                                    />
                                </Step>
                            )
                    )}
                </StepperSelector>
            )}
            <StepperActions
                isFirst={currentStep === 1}
                goPreviousStep={goPreviousStep}
                isFilled={isDisabled}
                goNextStep={goNextStep}
                isLast={currentStep === steps.length}
            />
        </Container>
    );
};
