import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { BellIcon } from '@wedat/ui-kit/components/Icons';

export const StyledNotificationsIcon = styled(BellIcon)`
    color: ${({ theme }) => theme.colors.textPrimary};

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    height: 48px;
    min-width: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    position: relative;

    ${media.phone`
        position: unset;
    `}
`;

export const Button = styled.button`
    position: relative;
    border: 0;
    margin: 0;
    width: 22px;
    height: 22px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray_10};
    cursor: pointer;
`;
