import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Gallery } from '@dat/gallery';
import { sharedGalleryEvents, sharedGalleryStores } from '@dat/shared-models/gallery';
import { useHistory } from 'react-router';
import { contractStores } from '@dat/shared-models/contract';
import { CONTRACT_ID, IS_GALLERY_MODAL_OPEN } from '@dat/shared-models/gallery/constants';

export const GalleryModal: React.FC = () => {
    const [fromLocalStorageOpen, setLocalStorageOpen] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const isOpen = useStore(sharedGalleryStores.isGalleryModalOpen);
    const contractId = useStore(contractStores.contractId);
    const dismissModal = () => {
        sharedGalleryEvents.toggleIsGalleryModalOpen();
        localStorage.removeItem(IS_GALLERY_MODAL_OPEN);
        localStorage.removeItem(CONTRACT_ID);
    };

    useEffect(() => {
        if (JSON.parse(localStorage.getItem(CONTRACT_ID) as string) !== contractId) {
            localStorage.removeItem(IS_GALLERY_MODAL_OPEN);
        } else {
            localStorage.setItem(IS_GALLERY_MODAL_OPEN, JSON.stringify(true));
        }
        if (history?.location.pathname === `/`) {
            localStorage.removeItem(IS_GALLERY_MODAL_OPEN);
            localStorage.removeItem(CONTRACT_ID);
        }
    }, [contractId, history?.location.pathname]);
    useEffect(() => {
        if (JSON.parse(localStorage.getItem(IS_GALLERY_MODAL_OPEN) as string)) {
            setLocalStorageOpen(true);
        } else {
            setLocalStorageOpen(false);
            localStorage.removeItem(IS_GALLERY_MODAL_OPEN);
        }
    }, [isOpen, fromLocalStorageOpen, contractId, history?.location.pathname]);

    if (!fromLocalStorageOpen) return null;

    return (
        <ModalPrimary
            isOpen={fromLocalStorageOpen}
            title={t('inbox:modals.gallery')}
            onDismiss={dismissModal}
            bodyHeight="fit-content"
        >
            <Gallery
                options={{
                    isComponent: true,
                    contractId
                }}
            />
        </ModalPrimary>
    );
};
