import { forward } from 'effector';

import { pluginEvents } from './index';
import { attachmentsEffects, attachmentsEvents } from '../attachments';

forward({
    from: pluginEvents.initPlugin.map(({ contractId }) => contractId),
    to: attachmentsEvents.setContractId
});

forward({
    from: pluginEvents.initPlugin.map(({ contractId }) => ({ contractId })),
    to: attachmentsEffects.listAttachmentsOfContractFx
});
