export const initialConfig: DAT2.Internal.FactorsParametersObject = {
    MetadataFactor: {
        kzArt: 14,
        name: 'First name'
    },
    CalculationFactor: {
        discount: {
            mode: 1,
            value: 10
        },
        kzArt: 1,
        selectedLacquerMethod: 1
    },
    SparePartFactor: {
        discount: {
            mode: 1,
            value: 10
        },
        kzArt: 2,
        discountBiw: {
            mode: 1,
            value: null
        },
        blockedPositions: {
            // surchargeSeparated: 1,
            // surchargeInProtocolOly: 1,
            // discount: 1
        }
    },
    LabourCostFactor: {
        bodyWage1: {
            mode: 1,
            value: 0
        },
        bodyWage2: {
            mode: 1,
            value: 0
        },
        bodyWage3: {
            mode: 1,
            value: 0
        },
        mechanicWage1: {
            mode: 1,
            value: 0
        },
        mechanicWage2: {
            mode: 1,
            value: 0
        },
        mechanicWage3: {
            mode: 1,
            value: 0
        },
        electricWage1: {
            mode: 1,
            value: 0
        },
        electricWage2: {
            mode: 1,
            value: 0
        },
        electricWage3: {
            mode: 1,
            value: 0
        },
        dentWage: {
            mode: 1,
            value: 0
        },
        discount: {
            mode: 1,
            value: 0
        },

        kzArt: 3,
        timeUnit: 'STD',
        timeUnitsPerHour: 1
    },
    EuroLacquerFactor: {
        kzArt: 4,
        timeUnit: 'STD',
        timeUnitsPerHour: 1,
        wage: {
            mode: 1,
            value: 12
        },
        type: null,
        materialIndex: 100,
        materialIndexMax: 999,
        materialIndexMin: 50,
        discountMaterial: {
            mode: 1,
            value: null
        },
        discountWage: {
            mode: 1,
            value: null
        },
        discountWageInclMaterial: {
            mode: 1,
            value: null
        },
        // TODO: Clarify why api doesn't support blocked position
        blockedPositions: {
            // isApplyUndercoatLacquerWhenRemoved: 1,
            // isLacquerPlasticWhenFitted: 1,
            // isFrameworkUse: 1,
            // materialMode: 1,
            // isReducedPreparationTime: 1,
            // wageInclMaterialMode: 1,
            // isSurchargeForSecondColor: 1,
            // isLacquerAssemblyWhenRemoved: 1
        }
    },
    ManufacturerLacquerFactor: {
        kzArt: 5,
        wage: {
            mode: 1,
            value: null
        },
        type: null,
        timeUnit: 'STD',
        timeUnitsPerHour: 1
    },

    DomusCalculationFactor: {
        kzArt: 10,
        layerMono: 0,

        layerAdd15Mono: 0,
        layerDouble: 0,
        layerAdd15Double: 0,
        layerTriple: 0,
        layerAdd15Triple: 0,
        layerQuad: 0,
        layerAdd15Quad: 0,

        wasteDisposalCostsPercValue: {
            mode: 1,
            value: 0
        },
        wasteDisposalCostsMaxValue: {
            mode: 1,
            value: 0
        },
        isSurchargePaintingNeighbouringParts: false,
        isDeductionNeighbouringMetalParts: false,
        isSurchargeWasteDisposalCosts: false,
        isDeductionIdenticalParts: false,
        isSurchargeDifferingColourShades: false,
        isDeductionCommonWork: false,
        isWageReadOnly: null,
        blockedPositions: {
            // wasteDisposalCostsMaxValue: 1,
            // wasteDisposalCostsPercValue: 1
        }
    }
};
