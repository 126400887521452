import React from 'react';
import { useStore } from 'effector-react';
import { dossierStores } from '../../stores/dossier';
import { TextHTML } from './styled';

interface Props {
    big?: boolean;
}

export const VehicleName: React.FC<Props> = ({ big }) => {
    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);

    let ManufacturerName, BaseModelName, SubModelName;

    if (dossierWithExtractedTextValues.Vehicle) {
        ManufacturerName = dossierWithExtractedTextValues.Vehicle.ManufacturerName || '';
        BaseModelName = dossierWithExtractedTextValues.Vehicle.BaseModelName || '';
        SubModelName = dossierWithExtractedTextValues.Vehicle.SubModelName || '';
    }

    const vehicleText = `${ManufacturerName} ${BaseModelName} ${SubModelName}`;

    return <TextHTML dangerouslySetInnerHTML={{ __html: vehicleText }} big={big} />;
};
