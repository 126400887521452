import { FC } from 'react';

import { pluginEvents } from './stores/plugin';
import './stores/init';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = ({ options }) => (
    <PluginProvider name="damage-selector" options={options} onInit={pluginEvents.initPlugin}>
        <Main
            image={options.carImage}
            manualPositions={options.manualPositions}
            onDescriptionSubmit={options.onDescriptionSubmit}
            onPoiUpdate={options.poiUpdateCallback}
            withoutApi={options.withoutApi}
        />
    </PluginProvider>
);

export default App;
