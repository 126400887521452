import { KeyValueOption } from '@wedat/ui-kit/components/Select';
import { TFunction } from 'react-i18next';

const selectRC = (t: TFunction): KeyValueOption[] => [
    {
        key: 0,
        value: '',
        label: t('modal.placeholder.select')
    },
    {
        key: 1,
        value: t('table.dismountAndMount'),
        label: t('modal.select.repairType.dismountAndMount')
    },
    {
        key: 2,
        value: t('table.replace'),
        label: t('modal.select.repairType.replace')
    },
    {
        key: 3,
        value: t('table.repairs'),
        label: t('modal.select.repairType.repairs')
    },
    {
        key: 4,
        value: t('table.paint'),
        label: t('modal.select.repairType.paint')
    },
    {
        key: 5,
        value: t('table.auxiliaryCosts'),
        label: t('modal.select.repairType.auxiliaryCosts')
    },
    {
        key: 6,
        value: t('table.check'),
        label: t('modal.select.repairType.check')
    },
    {
        key: 7,
        value: t('table.repair'),
        label: t('modal.select.repairType.repair')
    },
    {
        key: 8,
        value: t('table.adjusting'),
        label: t('modal.select.repairType.adjusting')
    },
    {
        key: 9,
        value: t('table.technicalInspection'),
        label: t('modal.select.repairType.technicalInspection')
    }
];

const selectCountries = (t: TFunction): KeyValueOption[] => [
    {
        key: 0,
        value: '',
        label: t('modal.placeholder.select')
    },
    {
        key: 1,
        value: 'IT',
        label: t('modal.select.countries.italy')
    }
];

export const selectsOptions = {
    selectCountries,
    selectRC
};
