import { sample } from 'effector';

import { pluginEffects, pluginEvents, pluginStores } from './index';
import { evaluationEffects, evaluationEvents, evaluationStores } from '../evaluation';
import { dossierStores } from '../dossier';
import { getISODateString } from '@dat/core/utils';
import deepmerge from 'deepmerge';
import { ManualFields } from '../../types/manualFields';
import { extractManualFieldsFromDossier } from '../../utils/extractManualFieldsFromDossier';
import { sharedVehicleEffects, sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { sharedEquipmentStores, sharedEquipmentEffects } from '@dat/shared-models/equipment';
import { CONSTRUCTION_TIME_DEFAULT } from '@dat/core/constants/constructionTime';
import { parseVehicleTranslationEquipment } from '@dat/core/utils/parseVehicleTranslationEquipment';
import { PayloadForGetVehicleEvaluation } from '../evaluation/types';

const { allManualFieldsSubmitted } = evaluationEvents;
const { manualFieldsOfInitialEvaluation, formikValuesData } = evaluationStores;
const { initPlugin, completePlugin } = pluginEvents;
const { invokeUpdateCallbackFx, invokeCompleteCallbackFx } = pluginEffects;
const { dossier } = dossierStores;
const { getVehicleEvaluationWithManualFieldsFx, getVehicleEvaluationFx } = evaluationEffects;
const { pluginOptions } = pluginStores;

const { datECode, vehicle } = sharedVehicleStores;

/*
    Init Plugin
    1. If we have vinResult. We make getVehicleTranslation and target getVehicleEvaluation
    2. If we haven't vinResult. We make getExistingEquipment, parse reponse. then make getVehicleTranslation and target getVehicleEvaluation
*/

// We have vinResult
sample({
    clock: initPlugin,
    source: { vinEquipmentResult: sharedEquipmentStores.vinEquipmentResult, datECode, vehicle },
    filter: ({ vinEquipmentResult }, pluginOptions) => vinEquipmentResult.length > 0 && !!pluginOptions.isComponent,
    fn: ({ datECode, vehicle, vinEquipmentResult }, pluginOptions) => {
        const constructionTime = vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT;

        return {
            ...pluginOptions.requestData,
            datECode: datECode || '',
            constructionTime,
            restriction: 'APPRAISAL',
            specialEquipmentId: vinEquipmentResult || []
        } as DAT2.Request.GetVehicleTranslation;
    },
    target: sharedEquipmentEffects.getVehicleTranslationFx
});

// Check vehicleTranslationEquipment after response getVehicleTranslationFx
sample({
    clock: sharedEquipmentStores.vehicleTranslationEquipment,
    source: pluginOptions,
    fn: (pluginOptions, vehicleTranslationEquipment) =>
        ({
            ...pluginOptions?.requestData,
            evaluationDate: pluginOptions?.requestData.evaluationDate || getISODateString(new Date()),
            equipment: parseVehicleTranslationEquipment(vehicleTranslationEquipment)
        } as PayloadForGetVehicleEvaluation),
    target: getVehicleEvaluationFx
});

// Without VinResult
sample({
    clock: initPlugin,
    source: { vinEquipmentResult: sharedEquipmentStores.vinEquipmentResult, datECode, vehicle },
    filter: ({ vinEquipmentResult }, pluginOptions) => vinEquipmentResult.length === 0 && !!pluginOptions.isComponent,
    fn: ({ datECode, vehicle }, pluginOptions) => {
        const constructionTime = vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT;

        return {
            ...pluginOptions.requestData,
            datECode: datECode || '',
            constructionTime,
            restriction: 'REPAIR'
        } as DAT2.Request.GetExistingEquipmentN;
    },
    target: sharedEquipmentEffects.getExistingEquipmentFx
});

sample({
    clock: sharedEquipmentStores.existingEquipment,
    source: { vehicle, datECode, pluginOptions },
    fn: ({ datECode, vehicle, pluginOptions }, existingEquipment) => {
        const constructionTime = vehicle?.ConstructionTime || CONSTRUCTION_TIME_DEFAULT;

        return {
            ...pluginOptions?.requestData,
            datECode: datECode || '',
            constructionTime,
            restriction: 'APPRAISAL',
            specialEquipmentId: existingEquipment || []
        } as DAT2.Request.GetVehicleTranslation;
    },
    target: sharedEquipmentEffects.getVehicleTranslationFx
});

// When move from a claim, we merge the data pluginOptions and dossier for update form and data server
sample({
    source: { values: formikValuesData, dossier, pluginOptions },
    clock: getVehicleEvaluationFx.doneData,
    filter: ({ dossier, pluginOptions }) => !!pluginOptions?.requestData.totalNetPrice && !!dossier,
    fn: ({ values, pluginOptions, dossier }) =>
        deepmerge<ManualFields>(
            values,
            extractManualFieldsFromDossier({
                ...dossier,
                Valuation: {
                    ...dossier.Valuation,
                    Condition: {
                        ...dossier.Valuation?.Condition,
                        RepairCosts: { _text: pluginOptions?.requestData.totalNetPrice || 0, origin: 'user' }
                    }
                }
            })
        ),
    target: allManualFieldsSubmitted
});

sample({
    source: dossier,
    fn: Dossier => ({
        Dossier
    }),
    target: invokeUpdateCallbackFx
});

sample({
    clock: getVehicleEvaluationWithManualFieldsFx.doneData,
    source: pluginOptions,
    filter: options => !!options?.isComponent,
    target: completePlugin
});

sample({
    clock: completePlugin,
    source: dossier,
    fn: Dossier => ({
        Dossier
    }),
    target: invokeCompleteCallbackFx
});

initPlugin.watch(({ onUpdate, onComplete }) => {
    onUpdate && invokeUpdateCallbackFx.watch(onUpdate);
    onComplete && invokeCompleteCallbackFx.watch(onComplete);
});

/* For testing */
// initPlugin.watch(() => {
//     dossierEffects.getDossierFx({ DossierId: 23923549 });
// });

// Update initialFormikValues after initPlugin
sample({
    clock: initPlugin,
    source: manualFieldsOfInitialEvaluation,
    filter: (_, pluginOptions) => !!pluginOptions.requestData.totalNetPrice,
    fn: (manual, pluginOptions) => ({
        ...manual,
        Valuation: {
            ...manual.Valuation,
            Condition: {
                ...manual.Valuation.Condition,
                RepairCosts: pluginOptions.requestData.totalNetPrice
            }
        }
    }),
    target: manualFieldsOfInitialEvaluation
});

// If we have VIN and single plugin
sample({
    clock: initPlugin,
    source: pluginOptions,
    filter: pluginOptions => !pluginOptions?.isComponent && !!pluginOptions?.vin,
    fn: pluginOptions =>
        ({
            vin: pluginOptions?.vin
        } as DAT.GetVehicleIdentificationByVinRequest),
    target: sharedVehicleEffects.getVehicleIdentificationByVinFx
});

sample({
    clock: sharedVehicleEffects.getVehicleIdentificationByVinFx.doneData,
    target: sharedVehicleStores.vinResult
});

sample({
    clock: sharedEquipmentStores.vinEquipmentResult,
    source: pluginOptions,
    filter: pluginOptions => !pluginOptions?.isComponent,
    fn: (pluginOptions, vinEquipmentResult) =>
        ({
            ...pluginOptions?.requestData,
            restriction: 'APPRAISAL',
            specialEquipmentId: vinEquipmentResult || []
        } as DAT2.Request.GetVehicleTranslation),
    target: sharedEquipmentEffects.getVehicleTranslationFx
});
