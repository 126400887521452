import { FC } from 'react';
import { isMobileDevice } from '../../utils/mobile';
import { useOrientation } from '../../hooks/orientation';
import { getTranslates } from '../../utils/plugin';
import { LayoutStyled, Orientation, OrientationIconStyled, Title } from './styles';

interface Props {
    forceLandscape?: boolean;
}

/**
 * Layout for all pages
 * If orientation change app will rerender in app.tsx
 */
export const Layout: FC<Props> = ({ children, forceLandscape = false }) => {
    const { isLandscape } = useOrientation();
    const isMobile = isMobileDevice();
    const translates = getTranslates();

    return (
        <LayoutStyled isLandscape={isLandscape}>
            {isMobile && isLandscape && !forceLandscape ? (
                <Orientation>
                    <Title>{translates.positionInPortrait}</Title>
                    <OrientationIconStyled />
                </Orientation>
            ) : isMobile && !isLandscape && forceLandscape ? (
                <Orientation>
                    <Title>{translates.positionInLandscape}</Title>
                    <OrientationIconStyled portrait />
                </Orientation>
            ) : (
                children
            )}
        </LayoutStyled>
    );
};
