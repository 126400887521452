import { j2xParser, parse, X2jOptions } from 'fast-xml-parser';

const optionsParseFromXML: Partial<X2jOptions> = {
    attributeNamePrefix: '', // '@_'
    attrNodeName: 'attr', // default is 'false'
    textNodeName: '#text',
    ignoreAttributes: false,
    ignoreNameSpace: true,
    allowBooleanAttributes: false,
    parseNodeValue: true,
    parseAttributeValue: false,
    trimValues: true,
    cdataTagName: '__cdata', // default is 'false'
    cdataPositionChar: '\\c',
    //   localeRange: "", // To support non english character in tag/attribute values.
    parseTrueNumberOnly: true,
    arrayMode: false
    // attrValueProcessor: a => he.decode(a, {isAttributeValue: true}),//default is a=>a
    // tagValueProcessor : a => he.decode(a) //default is a=>a
};

const optionsToXML = {
    attributeNamePrefix: '',
    attrNodeName: 'attr', // default is false
    textNodeName: '#text',
    ignoreAttributes: false,
    cdataTagName: '__cdata', // default is false
    cdataPositionChar: '\\c',
    format: true,
    indentBy: '  ',
    supressEmptyNode: false
    // tagValueProcessor: a=> he.encode(a, { useNamedReferences: true}),// default is a=>a
    // attrValueProcessor: a=> he.encode(a, {isAttributeValue: isAttribute, useNamedReferences: true})// default is a=>a
};

export function xmlToObj(xml: string, arrayMode?: boolean | ((tagName: string, parentTagName: string) => boolean)) {
    optionsParseFromXML.arrayMode = arrayMode || false;
    return parse(xml, optionsParseFromXML);
}
const objToXmlPareser = new j2xParser(optionsToXML);

export function objToXml(obj: object) {
    return objToXmlPareser.parse(obj);
}

export function xmlToObjWithArrayMode(textXML: string, tagNamesWithParent?: string[]) {
    const arrayMode =
        !!tagNamesWithParent && tagNamesWithParent.length > 0
            ? (tagName: string, parentTagName: string) => {
                  const keyToFind = parentTagName + '.' + tagName;
                  return tagNamesWithParent.indexOf(keyToFind) >= 0;
              }
            : false;
    const resObj = xmlToObj(textXML, arrayMode);
    return resObj;
}
