import { FC, useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';

// Components
import { PartSelectionTable } from '../components/Table';
import { Header } from '../components/Header';
import { AccordionContainer, StyledPageContainer } from './styles';
import { PartSelectionButton } from '../components/Button';
import { PartSelectionModal } from '../components/Modal';
import { ConfirmationPrompt } from '../components/ConfirmationPrompt';

// Effector
import { partSelectionEffects, partSelectionStores, partSelectionEvents } from '@dat/shared-models/partSelection';
import { useToggle } from '@dat/core/hooks/useToggle';
import { useHistory } from 'react-router';

export const PartSelectionPage: FC = () => {
    const part = useStore(partSelectionStores.part);
    const parts = useStore(partSelectionStores.parts);
    const history = useHistory();

    const [isClicked, setClicked] = useState(true);
    const handleClick = useCallback(() => {
        setClicked(!isClicked);
    }, [isClicked]);

    const [isOpen, setIsOpen] = useToggle(false);

    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const handlePromptDismiss = useCallback(() => {
        setIsPromptOpen(!isPromptOpen);
    }, [isPromptOpen]);

    const getParts = useCallback(async () => {
        await partSelectionEffects.getPartsEffect();
    }, []);

    useEffect(() => {
        getParts();
    }, [getParts]);

    const isInAdministration = history.location.pathname.includes('/administration');

    return (
        <StyledPageContainer>
            {!isInAdministration && <Header isClicked={isClicked} handleClick={handleClick} />}
            {isClicked && (
                <AccordionContainer isInAdministration={isInAdministration}>
                    <PartSelectionButton
                        onClick={() => {
                            partSelectionEvents.createNewPart();
                            setIsOpen();
                        }}
                    />
                    <PartSelectionTable
                        onEdit={() => setIsOpen()}
                        handlePromptDismiss={handlePromptDismiss}
                        data={parts}
                    />

                    <PartSelectionModal onDismiss={setIsOpen} isOpen={isOpen} />
                </AccordionContainer>
            )}
            {isPromptOpen && (
                <ConfirmationPrompt
                    handlePromptDismiss={handlePromptDismiss}
                    isOpen={isPromptOpen}
                    id={part?.id || 0}
                />
            )}
        </StyledPageContainer>
    );
};
