import { WorkLogicFormValues } from '../../types/worklogicForm';

export const INITIAL_WORK_LOGIC_VALUES: WorkLogicFormValues = {
    isDeductionCommonWork: false,
    isDeductionIdenticalParts: false,
    isDeductionNeighbouringMetalParts: false,
    isSurchargeDifferingColourShades: false,
    isSurchargePaintingNeighbouringParts: false,
    isSurchargeWasteDisposalCosts: false,
    surcharge: ''
};
