import React from 'react';
import { sharedLoginStores } from '@dat/shared-models/auth/login';
import { useStore } from 'effector-react';
import { DownloadAll, PhotoItem, PhotosHeader, PhotosHeaderTitle, PhotosList, PhotosListItems } from './styles';
import { imagesEffects } from '../../../../stores/images';
import { useTranslation } from 'react-i18next';
import { getDownloadAllAttachmentsUrl } from '@dat/api2/services/myClaim-internal';
import { API2 } from '@dat/api2';

interface PhotosProps {
    claimId: number;
    attachments: DAT2.Internal.FolderOfClaimAttachment[];
}

export const Photos: React.FC<PhotosProps> = ({ claimId, attachments }) => {
    const { t } = useTranslation();
    const token = useStore(sharedLoginStores.token);
    return (
        <div>
            <PhotosHeader>
                <PhotosHeaderTitle>
                    {t('preview.photos.header')} ({attachments.length})
                </PhotosHeaderTitle>
                <DownloadAll href={getDownloadAllAttachmentsUrl({ claimId, token })}>
                    {t('preview.photos.downloadAll')}
                </DownloadAll>
            </PhotosHeader>
            <PhotosList>
                <PhotosListItems>
                    {attachments.map(({ id, document_id, fileName }) => (
                        <PhotoItem
                            key={id}
                            onClick={() =>
                                imagesEffects.downloadImageOfClaim({
                                    filename: fileName,
                                    claimId,
                                    photoId: id,
                                    folderId: document_id ?? 0,
                                    token
                                })
                            }
                            loading="lazy"
                            src={`${API2.myClaimInternal.getPhotoUrl({
                                photoId: id,
                                folderId: document_id ?? 0,
                                claimId,
                                token
                            })}&thumbnail=true`}
                        />
                    ))}
                </PhotosListItems>
            </PhotosList>
        </div>
    );
};
