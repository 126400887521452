import { TFunction } from 'react-i18next';
import { NumberFormatWrapper } from '../components/NumberFormatWrapper';

export const createDataForPriceTab = (t: TFunction, dossier: DAT2.Dossier | null) => {
    const currency = dossier?.Currency;

    const totalSparePartsCosts = dossier?.RepairCalculation?.CalculationSummary?.SparePartsCosts?.TotalSum;

    const totalLabourCosts = dossier?.RepairCalculation?.CalculationSummary?.LabourCosts?.TotalSum;

    const totalPaintWorkCosts = dossier?.RepairCalculation?.CalculationSummary?.LacquerCosts?.TotalSum;

    const totalCostsWithoutDiscounts = dossier?.RepairCalculation?.CalculationSummary?.TotalNetCosts;

    const totalCosts = dossier?.RepairCalculation?.CalcResultCommon?.RepairCalculationSummary?.TotalNetCorrected;

    const totalGrossCosts = dossier?.RepairCalculation?.CalculationSummary?.TotalGrossCosts;

    const totalVat = dossier?.RepairCalculation?.CalculationSummary?.TotalVAT;

    const totalAuxiliaryCosts = dossier?.RepairCalculation?.CalculationSummary?.AuxiliaryCosts?.TotalSum;

    const totalPriceDiscount = dossier?.RepairCalculation?.CalculationSummary?.TotalGrossDiscount;

    const currencyValue = currency ?? '';

    return [
        {
            title: t('dataForPriceTab.sparePartsTotal'),
            value: <NumberFormatWrapper value={totalSparePartsCosts}>{currencyValue}</NumberFormatWrapper>
        },
        {
            title: t('dataForPriceTab.workExpenditure'),
            value: <NumberFormatWrapper value={totalLabourCosts}>{currencyValue}</NumberFormatWrapper>
        },
        {
            title: t('dataForPriceTab.paintwork'),
            value: <NumberFormatWrapper value={totalPaintWorkCosts}>{currencyValue}</NumberFormatWrapper>
        },
        {
            title: t('dataForPriceTab.auxiliaryCosts'),
            value: <NumberFormatWrapper value={totalAuxiliaryCosts}>{currencyValue}</NumberFormatWrapper>
        },
        { title: t('dataForPriceTab.auxiliaryCosts(at 100%)'), value: '-' },
        { title: t('dataForPriceTab.totalPriceForOtherSurcharges/discounts'), value: '-' },
        {
            title: t('dataForPriceTab.totalCostsWithoutDisc'),
            value: <NumberFormatWrapper value={totalCostsWithoutDiscounts}>{currencyValue}</NumberFormatWrapper>,
            styles: {
                fontWeight: 700,
                fontSize: '15px'
            }
        },
        {
            title: t('dataForPriceTab.totalPriceDiscount'),
            value: <NumberFormatWrapper value={totalPriceDiscount}>{currencyValue}</NumberFormatWrapper>
        },
        {
            title: t('dataForPriceTab.totalCosts'),
            value: <NumberFormatWrapper value={totalCosts}>{currencyValue}</NumberFormatWrapper>,
            styles: {
                fontWeight: 700,
                fontSize: '15px'
            }
        },
        {
            title: t('dataForPriceTab.VAT'),
            value: <NumberFormatWrapper value={totalVat}>{currencyValue}</NumberFormatWrapper>
        },
        { title: t('dataForPriceTab.noValueForOldExchangePart'), value: '-' },
        {
            title: t('dataForPriceTab.totalCosts(including VAT)'),
            value: <NumberFormatWrapper value={totalGrossCosts}>{currencyValue}</NumberFormatWrapper>,
            styles: {
                fontWeight: 700,
                fontSize: '15px'
            }
        }
    ];
};
