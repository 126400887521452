/*
 * Model of current user
 */
import { createEffect, createStore } from 'effector';

import { API2 } from '@dat/api2';
import { USER_PROFILE_REQUIRED_PROPERTIES } from './constants';

const customerNumber = createStore<DAT2.Credentials['customerNumber']>(0);
const username = createStore<DAT2.Credentials['user']>('');

const updateUserProfileFx = createEffect(API2.bff.user.update);
const userProfile = createStore<DAT2.UserProfile | null>(null).on(updateUserProfileFx.doneData, (_, state) => state);
const isUserProfileFilled = userProfile.map(profile => USER_PROFILE_REQUIRED_PROPERTIES.every(key => !!profile?.[key]));

export const sharedUserEffects = {
    updateUserProfileFx
};
export const sharedUserStores = {
    customerNumber,
    username,
    userProfile,
    isUserProfileFilled
};
