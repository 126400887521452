import React from 'react';

const WarningToast = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.2883 12.2935H17.7141V21.251H14.2883V12.2935ZM14.2866 23.0425H17.7124V26.6255H14.2866V23.0425Z"
            fill="currentColor"
        />
        <path
            d="M19.0279 1.90258C18.4318 0.729145 17.2705 0 15.9995 0C14.7286 0 13.5673 0.729145 12.9712 1.90438L0.402129 26.7401C0.123219 27.2855 -0.01494 27.8975 0.00128013 28.5158C0.0175002 29.134 0.187539 29.7372 0.494624 30.2658C0.797286 30.7967 1.22712 31.236 1.74168 31.5401C2.25624 31.8442 2.83774 32.0027 3.42877 32H28.5703C29.783 32 30.8809 31.3514 31.5061 30.2658C31.8127 29.737 31.9825 29.1339 31.9987 28.5157C32.0149 27.8976 31.8771 27.2856 31.5986 26.7401L19.0279 1.90258ZM3.42877 28.4169L15.9995 3.58123L28.5789 28.4169H3.42877Z"
            fill="currentColor"
        />
    </svg>
);

export default WarningToast;
