import { Button } from '@wedat/ui-kit/components/Button';
import styled from 'styled-components/macro';

export const Container = styled.div`
    min-width: 300px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${({ theme }) => theme.colors.gray['50']};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
`;

export const StyledConfirmButton = styled(Button)`
    width: fit-content;
    padding: 17px;
    text-transform: uppercase;
`;
