export const getInputSize = (inputSize?: 'small' | 'medium' | 'default') => {
    switch (inputSize) {
        case 'medium':
            return '40px';
        case 'small':
            return '32px';
        default:
            return '48px';
    }
};
