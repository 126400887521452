import { useMemo } from 'react';
import { useStoreMap } from 'effector-react';
import deepmerge from 'deepmerge';

import { sharedTemplateStores } from '@dat/shared-models/template';

export const useMergeOptionsWithConfiguration = <TOptions extends DAT2.Plugins.PluginBaseOptions>(
    options: TOptions,
    name: DAT2.Plugins.PluginName
): TOptions => {
    const configuration = useStoreMap(
        sharedTemplateStores.productsConfiguration,
        productsConfiguration => productsConfiguration?.[name] || {}
    );

    // If plugin is a child of another plugin, then it should be configurable via template-configuration, like plugins
    // contained in "claim-management". Therefore, it's options should be completely over-writable by configuration.
    // On the other side, if plugin is standalone, then options passed by client to `init` have more priority then configuration.
    const shouldOverwriteOptionsWithConfiguration = options.isComponent;
    const leftObject = shouldOverwriteOptionsWithConfiguration ? options : configuration; // object whose properties will be overwritten
    const rightObject = shouldOverwriteOptionsWithConfiguration ? configuration : options; // prioritized object

    return useMemo(
        () =>
            deepmerge<TOptions>(leftObject, rightObject, {
                arrayMerge: /* overwrite */ (_leftArray, rightArray) => rightArray
            }),
        [leftObject, rightObject]
    );
};
