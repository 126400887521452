import { createEvent, restore, sample } from 'effector';

const confirm = createEvent();
const cancel = createEvent();

const confirmWithEquipmentCheck = createEvent();

const setIsEquipmentCheck = createEvent<boolean>();
const isEquipmentCheck = restore(setIsEquipmentCheck, false);

export const maxMobileWidth = 886;
export const maxTabletWidth = 1350;

type DeviceType = 'laptop' | 'tablet' | 'phone' | null;
export function getDeviceType(width: number): DeviceType {
    if (!width) return null;
    if (width <= maxMobileWidth) return 'phone';
    if (width <= maxTabletWidth) return 'tablet';
    return 'laptop';
}

const setGrapaWindowSize = createEvent<{ width: number; height: number }>();
const grapaWindowSize = restore(setGrapaWindowSize, { width: 0, height: 0 });

const deviceType = grapaWindowSize.map(size => getDeviceType(size.width));

const isLaptop = grapaWindowSize.map(size => getDeviceType(size.width) === 'laptop');
const isTablet = grapaWindowSize.map(size => getDeviceType(size.width) === 'tablet');
const isMobile = grapaWindowSize.map(size => getDeviceType(size.width) === 'phone');

const setGrapaRootHtmlElement = createEvent<HTMLDivElement>();
const grapaRootHtmlElement = restore(setGrapaRootHtmlElement, null);

const updateGrapaSize = createEvent();

// can be problem with compatibility with baidu browser during dynamic change grapa window size,
// in this case use manual call for different events
// window.addEventListener('resize', () => updateGrapaSize());
const resizeGrapaObserver = new ResizeObserver(() => updateGrapaSize());
grapaRootHtmlElement.watch(val => {
    val && resizeGrapaObserver.observe(val);
});

sample({
    source: grapaRootHtmlElement,
    clock: [updateGrapaSize, grapaRootHtmlElement],
    fn: htmlElement => ({
        width: htmlElement?.offsetWidth || 0,
        height: htmlElement?.offsetHeight || 0
    }),
    target: setGrapaWindowSize
});

export const commonModel = {
    stores: {
        grapaWindowSize,
        deviceType,
        isMobile,
        isTablet,
        isLaptop,
        isEquipmentCheck
    },
    events: {
        confirm,
        confirmWithEquipmentCheck,
        setIsEquipmentCheck,
        cancel,

        setGrapaWindowSize,
        setGrapaRootHtmlElement,
        updateGrapaSize
    },
    effects: {}
};
