import React, { FC } from 'react';
import { useStore } from 'effector-react';

import translations from './i18n';
import { pluginEvents } from './stores/plugin';
import { commonStores } from './stores/common';

import { PluginProvider } from '@dat/smart-components/PluginProvider';
import { Main } from './components/Main';

import { PluginOptions } from './types/plugin';
import './stores/init';

interface Props {
    options: PluginOptions;
}

const App: FC<Props> = React.memo(({ options }) => {
    const isLoading = useStore(commonStores.isLoading);

    return (
        <PluginProvider
            name="vehicle-selection"
            options={options}
            translations={translations}
            onInit={pluginEvents.initPlugin}
            triggerRemount={pluginEvents.resetPlugin}
            isLoading={isLoading}
        >
            <Main />
        </PluginProvider>
    );
});

export default App;
