import { IEventObject, IEventScheduleObject } from 'tui-calendar';

export const getCurrentEvent = (
    appointments: BFF.Customer.Response.GetAppointments,
    event: IEventObject | IEventScheduleObject,
    isAppointmentView: boolean
) => {
    if (!appointments) return;

    const events: Array<DAT2.Appointment | DAT2.ExpiryClaim | undefined> = isAppointmentView
        ? appointments.appointmentDates
        : appointments.expiryClaimsDate;

    const currentEvent: DAT2.Appointment | DAT2.ExpiryClaim | undefined = events.find(i => i?.id === event.schedule.id);

    return currentEvent;
};
