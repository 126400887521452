/*
 * VSM is not made for working with contracts,
 * but still it needs to be able to set vehicle from existing contract,
 * so contract should be fetched through APIv1, because VSM works with first version.
 * This file need to be deleted, when we replace APIv1 with APIv2.
 */

import { createEffect, createStore, sample } from 'effector';

import { API } from '@dat/api';
import { API2 } from '@dat/api2';
import { parseVXSResponse } from '@dat/core/utils';
import { extractOCRFromContract } from '../utils/extractOCRFromContract';
import { domain } from './plugin';
import { ParsedVXSVehicleObject, VinResult, VinResultEquipmentObject } from '@dat/core/types';
import { getParsedArraySafe } from '@dat/api2/utils';
import { extractVinResultFromContract } from '@dat/core/utils/extractVinResultFromContract';

const getVehicleFromContractFx = domain.createEffect(async (data: DAT2.Request.GetContract) => {
    const [response, response2] = await Promise.all([API.myClaim.getContract(data), API2.myClaim.getContract(data)]);

    const parsedVXS = parseVXSResponse(response.getSingleField('return'))[0];
    const vinResult = response2.return && extractVinResultFromContract(response2.return);
    const OCRResult = response2.return && extractOCRFromContract(response2.return);

    return {
        ...parsedVXS,
        vinColors: vinResult?.vinColors,
        vinResult,
        OCRResult
    };
});

getVehicleFromContractFx.fail.watch(err => {
    console.log('Error: contract data is damaged or invalid', err.error);
});

type VehicleFromContract = Partial<ParsedVXSVehicleObject> & {
    vinResult?: VinResult;
    OCRResult?: DAT2.Field.EntryForRequest<string, DAT2.Field.Primitive>[];
    vinResultEquipment?: VinResultEquipmentObject[];
};

const vehicleFromContract = createStore<VehicleFromContract>({}).on(
    getVehicleFromContractFx.doneData,
    (state, newState) => {
        if (state.vinResult && !newState.vinResult) {
            return state;
        }

        return newState;
    }
);
// <--- Smart Search Logic Below --->

const contractInVSM = createStore<DAT2.ContractFromGetContract | null>(null);

// the effect below is a copy of getContractFx effect. It is recreated so that we don't get all the
// connections to that effect here
const getContractInVSMFx = createEffect(async (contractId: number | string) => {
    contractId = +contractId;
    if (isNaN(contractId)) throw new Error(`${getContractInVSMFx.name}: contractId must be number`);

    const response = await API2.myClaim.getContract({ contractId });
    const contract = response.return;

    if (!contract) throw new Error(`${getContractInVSMFx.name}: bad response`);

    return contract;
});

const listContractsFx = createEffect(async (data: DAT2.Request.ListContracts) => {
    const res = await API2.myClaim.listContracts(data);
    return res.return;
});

const listContracts = createStore<DAT2.ContractFromListContracts[]>([]).on(
    listContractsFx.doneData,
    (_state, payload) => getParsedArraySafe(payload)
);

const lastCreatedFromListContracts = listContracts.map(list => getParsedArraySafe(list)[0]);

sample({
    clock: lastCreatedFromListContracts,
    fn: (lastCreatedFromListContracts): string | number => lastCreatedFromListContracts.contractId || '',
    target: getContractInVSMFx
});

sample({
    clock: getContractInVSMFx.doneData,
    target: contractInVSM
});

export const contractEffects = {
    getVehicleFromContractFx,
    listContractsFx,
    getContractInVSMFx
};

export const contractStores = {
    vehicleFromContract,
    listContracts,
    lastCreatedFromListContracts,
    contractInVSM
};
