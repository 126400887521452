import { InitialPayloadForCreateContract } from '../types';

interface Param {
    country: DAT2.CountryCode;
    currency: string;
}

export const createInitialPayloadForCreateContract = ({
    country,
    currency
}: Param): InitialPayloadForCreateContract => ({
    Dossier: {
        Name: generateUniqClaimName(),
        Country: country,
        Currency: currency
    },
    templateData: {
        entry: [
            {
                key: 'createOrderDate',
                value: new Date().toLocaleDateString('fr')
            }
        ]
    }
});

const generateUniqClaimName = () => {
    const uniqId = new Date()
        .toISOString()
        .replace(/[^0-9]/g, '')
        .slice(0, 14);

    return `Claim_${uniqId}`;
};
