import { useTranslation } from 'react-i18next';
import {
    SmileUnavailableIconStyled,
    Root,
    Wrap,
    Description,
    HomeIconStyled,
    NavLinkStyled,
    TextStyled
} from './styles';

export const UnavailableClaim = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <Wrap>
                <SmileUnavailableIconStyled />
                <Description>
                    Unfortunately, this claim is unavailable for now. Please, get back to the inbox.
                </Description>
                <NavLinkStyled exact to="/">
                    <HomeIconStyled />
                    <TextStyled>{t('header.home')}</TextStyled>
                </NavLinkStyled>
            </Wrap>
        </Root>
    );
};
