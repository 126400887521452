import { createEvent, createStore, sample, combine } from 'effector';
import * as R from 'ramda';
import { DATProcessIdComment } from '@dat/api2dat/types/contractTypes';
import { commonModel } from './commonModel';

const DATProcessIdCommentList = createStore<DATProcessIdComment[]>([], {
    updateFilter: (newResult, oldResult) => !R.equals(oldResult, newResult)
});

const setDATProcessIdCommentList = createEvent<DATProcessIdComment[]>();
DATProcessIdCommentList.on(setDATProcessIdCommentList, (_, newDATProcessIdCommentList) => newDATProcessIdCommentList);
const initDATProcessIdCommentList = createEvent<DATProcessIdComment[]>();
DATProcessIdCommentList.on(initDATProcessIdCommentList, (_, newDATProcessIdCommentList) => newDATProcessIdCommentList);

const addOrUpdateDATProcessIdComment = createEvent<DATProcessIdComment>();
const delDATProcessIdComment = createEvent<{ DATProcessId: number | undefined }>();

const confirmedDATProcessIdCommentList = createStore<DATProcessIdComment[]>([]);
const setConfirmedDATProcessIdCommentList = createEvent<DATProcessIdComment[]>();
confirmedDATProcessIdCommentList.on(
    setConfirmedDATProcessIdCommentList,
    (_, newDATProcessIdCommentList) => newDATProcessIdCommentList
);
confirmedDATProcessIdCommentList.on(
    initDATProcessIdCommentList,
    (_, newDATProcessIdCommentList) => newDATProcessIdCommentList
);

sample({
    source: DATProcessIdCommentList,
    clock: commonModel.events.confirm,
    target: setConfirmedDATProcessIdCommentList
});

sample({
    source: confirmedDATProcessIdCommentList,
    clock: commonModel.events.cancel,
    target: setDATProcessIdCommentList
});

sample({
    source: DATProcessIdCommentList,
    clock: addOrUpdateDATProcessIdComment,
    fn: (DATProcessIdCommentList, { DATProcessId, Description, Comment }) => {
        const newRecord: DATProcessIdComment = {
            DATProcessId,
            Description,
            Comment
        };

        let newDATProcessIdCommentList = DATProcessIdCommentList.filter(
            item => item.DATProcessId !== DATProcessId
        ).concat(newRecord);
        return newDATProcessIdCommentList;
    },
    target: setDATProcessIdCommentList
});

sample({
    source: DATProcessIdCommentList,
    clock: delDATProcessIdComment,
    fn: (DATProcessIdCommentList, { DATProcessId }) => {
        if (!DATProcessId) return DATProcessIdCommentList;
        let newDATProcessIdCommentList = DATProcessIdCommentList.filter(el => !(el.DATProcessId === DATProcessId));
        return newDATProcessIdCommentList;
    },
    target: setDATProcessIdCommentList
});

const isModified = combine(DATProcessIdCommentList, confirmedDATProcessIdCommentList).map(
    ([com, confCom]) => com !== confCom
);

export const DATProcessIdCommentListModel = {
    store: {
        DATProcessIdCommentList,
        confirmedDATProcessIdCommentList,
        isModified
    },

    event: {
        initDATProcessIdCommentList,
        setDATProcessIdCommentList,

        setConfirmedDATProcessIdCommentList,

        addOrUpdateDATProcessIdComment,
        delDATProcessIdComment
    }
};
