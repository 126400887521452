import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
`;

interface GalleryWrapperProps {
    noImage?: boolean;
}

export const GalleryWrapper = styled.div<GalleryWrapperProps>`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    min-height: 200px;
    padding-left: 24px;
    padding-right: 24px;

    ${({ noImage }) =>
        noImage &&
        css`
            display: flex;
            align-items: flex-end;
            padding-bottom: 40px;
            ${PlateInfoContainer} {
                position: inherit;
            }
        `};

    ${media.laptopSmall`
        padding: 0 16px;
    `}

    & > div {
        .slick-dots {
            display: flex !important;
            position: absolute;
            width: 100%;
            bottom: -15px;
            justify-content: center;
            margin: 0;
        }

        .slick-dots li {
            margin-left: 25px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                top: 9px;
                left: -20px;
                border-radius: 50%;
                background: ${({ theme }) => theme.colors.gray_40};
                display: inline-block;
            }
        }

        .slick-dots li button {
            position: absolute;
            width: 20px;
            height: 20px;
            right: 4px;
            top: 4px;
            opacity: 0;
            cursor: pointer;
            z-index: 10;
        }

        .slick-dots .slick-active::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            top: 9px;
            left: -20px;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.white};
            display: inline-block;
        }

        .slick-slide div {
            margin: 0 auto 10px;

            ${media.phoneBig`
                width: 300px;
            `}

            ${media.phoneMedium`
                width: 170px;
            `}
        }
    }
`;

interface GalleryContainerProps {
    center?: boolean;
    bottom?: boolean;
    right?: boolean;
}

export const GalleryContainer = styled.span<GalleryContainerProps>`
    position: relative;
    display: flex;
    align-items: flex-start;

    ${({ center }) =>
        center &&
        css`
            justify-content: center;
        `}

    ${({ bottom }) =>
        bottom &&
        css`
            height: 80%;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 70px;

            ${media.phone`
                margin-bottom: 100px;
            `}
        `};

    ${({ right }) =>
        right &&
        css`
            justify-content: flex-end;
        `}
`;

export const Price = styled(Text)`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
`;

export const PlateInfoContainer = styled.div`
    position: absolute;
    bottom: -25px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: fit-content;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 8px;
`;

export const Image = styled.img`
    width: 70%;
    height: auto;
`;
