import { createEvent } from 'effector';

import { ParsedAttachmentItem } from '../../types/attachments';
import { domain } from '../plugin';

const addAttachments = domain.createEvent<ParsedAttachmentItem[]>();
const deleteAttachment = createEvent<number>();

const attachments = domain
    .createStore<ParsedAttachmentItem[]>([])
    .on(addAttachments, (oldAttachments, newAttachments) => [...oldAttachments, ...newAttachments].reverse())
    .on(deleteAttachment, (attachments, idx) => attachments.filter((_, index) => index !== idx));

const uploadAttachmentByFolderIdToCurrentContract = createEvent<DAT2.AttachmentItem>();

const uploadAttachmentsToFx = domain.createEffect((attachments: DAT2.AttachmentItem[]) => {
    attachments.forEach(uploadAttachmentByFolderIdToCurrentContract);
});

const filesInSelected = domain.createEvent<Blob[]>();

export const attachmentsEvents = {
    uploadAttachmentByFolderIdToCurrentContract,
    filesInSelected,
    addAttachments,
    deleteAttachment
};
export const attachmentsEffects = {
    uploadAttachmentsToFx
};
export const attachmentsStores = {
    attachments
};
