export async function getBlobByUrl(url: string) {
    const options: RequestInit = {
        method: 'GET'
    };
    const result = await fetch(url, options);
    if (result.status !== 200) throw new Error(`Response status: ${result.status} text: ${result.statusText}`);
    return result.blob();
}

export async function getStringByUrl(url: string) {
    const options: RequestInit = {
        method: 'GET'
    };
    const result = await fetch(url, options);
    if (result.status !== 200) throw new Error(`Response status: ${result.status} text: ${result.statusText}`);
    return result.text();
}

export async function getUrlBlobByUrl(url: string) {
    return URL.createObjectURL(await getBlobByUrl(url));
}

export async function getBase64ByUrl(url: string) {
    return blobToBase64(await getBlobByUrl(url));
}

// convert a blob to base64
export async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = function () {
            let dataUrl = reader.result;
            resolve(dataUrl as string);
        };
        reader.readAsDataURL(blob);
    });
}
