import React from 'react';
import { CheckInCircleIcon, CircleIcon, DocumentIcon, TrashIcon } from '@wedat/ui-kit/components/Icons';
import { Container, SelectedIconWrapper, Image, Overlay, MobileIconWrapper, DocumentContainer, Name } from './styles';

import { parseNameFormat } from '@wedat/ui-kit/utils/parseNameFormat';
import { parseNameAttachment } from '@wedat/ui-kit/utils/parseNameAttachment';
import { formats } from '@wedat/ui-kit/components/Attachment/constants';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    attachment: {
        fileName: string;
        base64?: string;
    };
    selected?: boolean;
    onClick?: () => void;
    small?: boolean;
    trashIcon?: boolean;
}

export const Attachment: React.FC<Props> = ({ attachment, selected, onClick, small, trashIcon }) => {
    const { base64, fileName } = attachment;

    const format = parseNameFormat(fileName);
    const name = parseNameAttachment(fileName);
    const isFormatText = formats.includes(format);

    const SelectedIcon = () => (selected ? <CheckInCircleIcon width="24" /> : <CircleIcon width="24" />);
    const Icon = () => (trashIcon ? <TrashIcon width="24" /> : <SelectedIcon />);

    return (
        <Container small={small}>
            {isFormatText ? (
                <DocumentContainer>
                    <DocumentIcon />
                    <Text>{format}</Text>
                    <Name font="font12" textOverflow="ellipsis">
                        {name}
                    </Name>
                </DocumentContainer>
            ) : (
                <Image src={base64} />
            )}

            <Overlay selected={selected}>
                <SelectedIconWrapper onClick={onClick}>
                    <Icon />
                </SelectedIconWrapper>
            </Overlay>

            <MobileIconWrapper onClick={onClick}>
                <Icon />
            </MobileIconWrapper>
        </Container>
    );
};
