import { API2 } from '@dat/api2';
import { createStore, createEffect } from 'effector';
import { getParsedArraySafe } from '@dat/api2/utils';

const getVehicleImagesFx = createEffect({
    handler: async ({ datECode, aspect = 'ALL', imageType = 'PICTURE' }: DAT2.Request.GetVehicleImagesN) => {
        const response = await API2.vehicleSelection.getVehicleImagesN({ datECode, aspect, imageType });

        return getParsedArraySafe(response.vehicleImagesN?.images);
    }
});

const vehicleImages = createStore<DAT2.VehicleImage[]>([]).on(getVehicleImagesFx.doneData, (_, images) => images);

export const vehicleImagesEffects = {
    getVehicleImagesFx
};

export const vehicleImagesStores = {
    vehicleImages
};
