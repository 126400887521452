import { v4 as uuidv4 } from 'uuid';
import { extractDataFromBase64 } from './extractDataFromBase64';
import { convertFileToBase64 } from '../data/convertFileToBase64';

// this is for ts
type ExtendedBlob = Blob & { name?: string };
interface Param {
    files: (File | ExtendedBlob)[];
    groupId: number;
}

export const convertFilesToAttachments = ({ files, groupId }: Param): Promise<DAT2.AttachmentItem[]> =>
    Promise.all(
        files.map(async file => {
            const base64 = await convertFileToBase64(file);
            const data = extractDataFromBase64(base64);

            return {
                fileName: `${uuidv4()}_${file.name || ''}`,
                documentID: groupId,
                binaryData: data,
                mimeType: file.type,
                uploaded: new Date().toISOString()
            };
        })
    );
