import { createApi, createEffect } from 'effector';

import * as reducers from './reducers';
import { pendingParams } from '@dat/core/utils/effector/pendingParams';
import { currentUserDataKey } from './constants';

import { createEntriesArrayFromSubject } from '../../utils/createEntriesArrayFromSubject';
import { sharedSubjectsDataStores, sharedSubjectsDataEffects } from '@dat/shared-models/addressBook';

const { customersSubjectsData } = sharedSubjectsDataStores;
const { getSubjectsFx } = sharedSubjectsDataEffects;

const customersSubjectsDataApi = createApi(customersSubjectsData, reducers.customersSubjectsData);

const customersNumbersFetching = pendingParams({
    effect: getSubjectsFx,
    mapParams: params => params?.networkOwnerCustomerNumber || currentUserDataKey
});

const updateContractSubjectFx = createEffect<
    DAT2.SingleOrArray<DAT2.Field.EntryForRequest<DAT2.Field.Primitive, DAT2.Field.Primitive>>,
    void
>();

window.addEventListener('addressBookFieldChanged', ((e: CustomEvent) => {
    updateContractSubjectFx(createEntriesArrayFromSubject(e.detail.formValues, e.detail.subjectType));
}) as EventListener);

export const subjectsDataEvents = {
    ...customersSubjectsDataApi
};
export const subjectsDataStores = {
    customersNumbersFetching
};
export const subjectsDataEffects = {
    updateContractSubjectFx
};
