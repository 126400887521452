import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { sharedUserStores } from '@dat/shared-models/user';
import { pluginEffects } from '../../stores/plugin';

import { Button } from '@wedat/ui-kit/components/Button';
import { Container, TextWrapper, StaticText, UserName, ImageStyled, FlexWrapper } from './styles';
import DefaultUserAvataer from '@wedat/ui-kit/assets/images/DefaultUserAvatar.png';

export const WelcomePopup = () => {
    const { t } = useTranslation();
    const userProfile = useStore(sharedUserStores.userProfile);

    return (
        <Container>
            <ImageStyled src={userProfile?.image ? userProfile.image : DefaultUserAvataer} alt="User Image" />
            <TextWrapper>
                <FlexWrapper>
                    <UserName>{userProfile?.name && `${userProfile?.name}, `}</UserName>
                    {!userProfile?.name ? (
                        <StaticText>{t('welcome.message2')}</StaticText>
                    ) : (
                        <StaticText>
                            {t('welcome.message')} {t('welcome.message2')}
                        </StaticText>
                    )}
                </FlexWrapper>
            </TextWrapper>
            {userProfile?.name && userProfile?.surname && userProfile?.email && (
                <Button
                    onClick={() => {
                        if (userProfile?.name && userProfile?.surname && userProfile?.email) {
                            pluginEffects.invokeOnLoginCallbackFx();
                        }
                    }}
                >
                    {t('welcome.continue')}
                </Button>
            )}
        </Container>
    );
};
