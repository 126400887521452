import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { contractsListEffects, contractsListStores } from '../../../../stores/contractsList';
import { FolderFiltersWrapper } from '../styles';
import { FolderType } from '../../../../stores/contractsList/types';
import { Tabs } from '@wedat/ui-kit/components/Tabs';

export const FoldersList: React.FC = () => {
    const listUserSettings = useStore(contractsListStores.listUserSettings);
    const activeFolder = useStore(contractsListStores.activeFolder);
    const foldersCounts = useStore(contractsListStores.foldersCounts);

    const tabsValues = useMemo(
        () =>
            listUserSettings.folderFilters?.map(item => ({
                id: String(item.id),
                label: `${item.name} (${foldersCounts[item.id] ?? '---'})`
            })),
        [foldersCounts, listUserSettings]
    );

    const handleTabChange = (id: string) => {
        const storedFilterId = listUserSettings.folderFilters.find(item => item.id === +id)?.storedFilter_id;

        contractsListEffects.loadFolderFilterFx({
            folderType: FolderType.FILTER,
            folderId: +id,
            storedFilterId
        });
    };

    return tabsValues.length ? (
        <FolderFiltersWrapper>
            <Tabs values={tabsValues} active={String(activeFolder.folderId)} onChange={handleTabChange} />
        </FolderFiltersWrapper>
    ) : null;
};
