import { sample } from 'effector';

import { configurationStores } from './index';
import { pluginCarsStores } from '../pluginCars';
import { pluginStores } from '../plugin';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { defaultConfiguration } from '../../constants/defaultConfiguration';

import { ClientConfiguration } from '../../types/plugin';

const { configuration } = configurationStores;
const { pluginOptions } = pluginStores;
const { productsConfiguration } = sharedTemplateStores;

const sharedConfiguration = productsConfiguration.map<ClientConfiguration | undefined>(
    productsConfiguration => productsConfiguration?.['ai-claim']
);

sample({
    source: { pluginOptions, sharedConfiguration },
    fn: ({ pluginOptions, sharedConfiguration }) => ({
        ...defaultConfiguration,
        ...sharedConfiguration,
        ...pluginOptions
    }),
    target: configuration
});

pluginCarsStores.steps.on(sharedConfiguration, (_, sharedConfiguration) => sharedConfiguration?.photoCapture?.steps);
