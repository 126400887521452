import { Card, List } from 'antd';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { fastTrackElementsMenuModel } from '../../stores/fastTrackElementsMenuModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { ElementsMenuList } from './ElementsMenuList';
import { ElementsMenuDrawer } from './ElementsMenuDrawer';
import { ReactComponent as SelectedElementIcon } from '../CommonComponents/Icons/SelectedElementIcon.svg';

import './fastTrackElementsMenu.css';
import { ElementsMenuModal } from './ElementsMenuModal';

export const FastTrackElementsMenuMobile: FC<{ elementsMenuType?: 'drawer' | 'modal' }> = ({ elementsMenuType }) => {
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const fastTrackElementsMenuState = useStore(fastTrackElementsMenuModel.stores.$fastTrackElementsMenu);

    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const selectedElementsIds = selectedElements.map(elem => elem.elementId);

    const fastTrackElementsMenu = pluginOptions?.settings?.fastTrackElementsMenu;
    const fastTrackElementsMenuModal = pluginOptions?.settings?.fastTrackElementsMenuModal;
    const showSVGFastTrack = pluginOptions?.settings?.showSVGFastTrack;
    if (!fastTrackElementsMenu) return null;

    return (
        <div className={showSVGFastTrack ? 'weDat-ft-elements-menu-mobile' : 'weDat-ft-elements-menu-noSVG-mobile'}>
            {!fastTrackElementsMenuState || fastTrackElementsMenuModal ? (
                <List className="weDat-ft-elements-list-mobile" itemLayout="vertical">
                    {fastTrackElementsMenu.map(menuGroup => (
                        <List.Item
                            key={menuGroup.menuGroupId}
                            onClick={() => {
                                fastTrackElementsMenuModel.events.setFastTrackElementsMenu(menuGroup);
                            }}
                        >
                            <Card hoverable style={{ width: '100%' }}>
                                <div className="weDat-ft-elements-list-img">
                                    <svg height="32" width="58">
                                        <image height="32" viewBox="0 0 32 58" xlinkHref={menuGroup.menuGroupImgUrl} />
                                    </svg>
                                    {menuGroup.elementDatIDs?.find(elemId => selectedElementsIds.includes(elemId)) && (
                                        <SelectedElementIcon />
                                    )}
                                </div>
                                <Card.Meta title={menuGroup.menuGroupLabel || menuGroup.menuGroupId} />
                            </Card>
                        </List.Item>
                    ))}
                </List>
            ) : null}

            {!fastTrackElementsMenuModal && fastTrackElementsMenuState && <ElementsMenuList />}
            {fastTrackElementsMenuModal &&
                fastTrackElementsMenuState &&
                (elementsMenuType === 'modal' ? <ElementsMenuModal /> : <ElementsMenuDrawer />)}
        </div>
    );
};
