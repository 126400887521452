import { Item, ListUsersWrapper, UserPicture, UserRow } from './styles';
import React from 'react';
import { previewEvents } from '../../../../../stores/preview';
import { Checkbox, Text } from '@wedat/ui-kit';
import { Profile } from '@wedat/ui-kit/assets/Icon';

export interface ListProps {
    users: DAT2.Internal.User[];
    selected?: string[];
}

export const List: React.FC<ListProps> = ({ users, selected }) => (
    <ListUsersWrapper>
        <div>
            {users.map(user => (
                <UserRow key={user.id}>
                    {!!selected && (
                        <Item>
                            <Checkbox
                                checked={selected.indexOf(user.login) !== -1}
                                onChange={event => previewEvents.changeUser({ user, active: event.target.checked })}
                            />
                        </Item>
                    )}
                    <UserPicture>
                        <Profile />
                    </UserPicture>
                    <Item>
                        <Text
                            textTransform="capitalize"
                            fontWeight="bolder"
                        >{`${user.firstName} ${user.surname}`}</Text>
                    </Item>
                </UserRow>
            ))}
        </div>
    </ListUsersWrapper>
);
