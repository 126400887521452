import { Text } from '@wedat/ui-kit/components/Text';
import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';
import { CommentBtn } from '../../CommentBtn';

interface ButtonBlockProps {
    isTransparent?: boolean;
}

export const Photo = styled.img`
    width: 100%;
    object-fit: contain;
`;

export const PhotoWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translate(-50%, -50%);

    flex: none;

    ${Photo} {
        height: 100%;
    }
`;

export const Main = styled.div`
    position: relative;
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
`;

export const Buttons = styled.div`
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 20px;

    ${media.phone`
        flex-direction: column;
        justify-content: flex-end;
    `}
`;

export const ButtonBlock = styled.div<ButtonBlockProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${({ theme, isTransparent }) => (isTransparent ? theme.colors.black : theme.colors.white)};
    background-color: ${({ theme, isTransparent }) =>
        isTransparent ? theme.colors.white : theme.colors.deepBlue['800']};
    height: 48px;
    border: ${({ theme }) => `1px solid ${theme.colors.dustBlue['900']}`};
    border-radius: 8px;

    & > svg {
        width: 20px;
    }
`;

export const TextStyled = styled(Text)`
    color: inherit;
    margin-left: 10px;
`;

export const CommentBtnStyled = styled(CommentBtn)`
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 20px;
    }
`;
