import { apiStores } from './index';
import { bffAxiosInstance } from '../bffAxios';
import { aniaAxiosInstance } from '../aniaAxios';
import { prAxiosInstance } from '../prAxios';

const { bffUrl, aniaUrl, prUrl } = apiStores;

bffUrl.watch(bffUrl => (bffAxiosInstance.defaults.baseURL = bffUrl));
aniaUrl.watch(aniaUrl => (aniaAxiosInstance.defaults.baseURL = aniaUrl));
prUrl.watch(prUrl => (prAxiosInstance.defaults.baseURL = prUrl));
