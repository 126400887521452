import axios, { AxiosResponse } from 'axios';

export const getAiValidationResult = (files: FormData): Promise<AxiosResponse<{ isValid: boolean }>> =>
    axios({
        method: 'POST',
        url: 'https://ai.wedat.eu/api/v1/files/',
        data: files,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
