import { TableData } from '../../types/table';
import { Row } from 'react-table';

// Custom filter for filtering expanding rows too.
export const customGlobalFilter = () => (rows: Row<TableData>[], _: Array<string>, filterValue: string) =>
    rows.filter(row => {
        const col4Value = row.values['col4'];
        const col1Value = row.values['col1'];

        if (row.original.children) {
            const subRows = row.original.children.filter(subRow => {
                const subCol4Value = subRow['col4'];

                if (subCol4Value.toLowerCase().includes(filterValue.toLowerCase())) {
                    row.toggleRowExpanded?.(true);
                }

                return subCol4Value ? subCol4Value.toLowerCase().includes(String(filterValue).toLowerCase()) : true;
            });

            if (subRows.length) {
                return true;
            }
        }

        return col4Value && col1Value
            ? col4Value.toLowerCase().includes(filterValue.toLowerCase()) ||
                  col1Value.toLowerCase().includes(filterValue.toLowerCase())
            : true;
    });
