import { useCallback, useEffect } from 'react';
import { FormikContextType, useField, useFormikContext } from 'formik';

interface SelectOption {
    disabled?: boolean;
    key?: string | number;
}
interface SelectProps<T> {
    options: T[];
    name: string;
    changeCallback?: (value: string, context: FormikContextType<unknown>) => {};
}

export const useValueAutoSelection = <T extends SelectOption>(props: SelectProps<T>) => {
    const { name, options, changeCallback } = props;
    const [field, , fieldHelpers] = useField(name);
    const formikContext = useFormikContext();

    /*** Handle change ***/
    const handleChange = useCallback(
        (value: string) => {
            if (field.value === value) {
                return;
            }

            fieldHelpers.setValue(value);

            // !!! Use changeCallback carefully !!!
            // formikContext passed to it doesn't contain a new value
            // so this value is passed as the first parameter
            changeCallback?.(value, formikContext);
        },
        [field.value, changeCallback, fieldHelpers, formikContext]
    );

    /*** Update value automatically ***/
    useEffect(() => {
        const availableOptions = options.filter(option => !option.disabled);
        const isValueInOptions = options.some(({ key }) => key === field.value);

        // Auto-select of single option
        if (!field.value && availableOptions.length === 1) {
            handleChange(String(availableOptions[0].key));
        }
        // Reset value if options were reset or it's not in options
        if (options.length === 0 || !isValueInOptions) {
            handleChange('');
        }
    }, [options, field.value, handleChange]);
};
