import { attach, combine, Effect, forward, guard, restore, sample } from 'effector';
import { uniqBy } from 'ramda';

import { API } from '@dat/api';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { API2 } from '@dat/api2';
import { domain, pluginStores } from './plugin';
import { effectorLogger, convertConstructionPeriodToDate, sortKeyValueArrayByAlphabet } from '@dat/core/utils';
import { getTextValuesFromParsedObject } from '@dat/api2/utils';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { ClassificationGroupsKeys } from '@dat/core/constants';
import { sharedTemplateStores } from '@dat/shared-models/template';

import { EquipmentObject, GetEquipmentObjectParam, GetEquipmentObjectRequestData } from '../types/equipment';
import { TechnicalData } from '../types/vehicleSelection';
import { i18n } from '@dat/shared-models/i18n';

//
/*** Handlers for vehicle-data effects ***/
//

/*** Get vehicle types handler ***/
const getVehicleTypesHandler = cDRH(async (data: DAT.GetVehicleTypesRequest) => {
    try {
        const response = await API.vehicleSelection.getVehicleTypes(data);

        return response.getFieldArray('vehicleType');
    } catch {
        return [];
    }
});

/*** Get manufacturers handler ***/
const getManufacturersHandler = cDRH(async (data: DAT.GetManufacturersRequest) => {
    // Check for validity
    if (!data.vehicleType) {
        return [];
    }

    try {
        const response = await API.vehicleSelection.getManufacturers(data);

        return response.getFieldArray('manufacturer');
    } catch {
        return [];
    }
});

/*** Get base models handler ***/
const createGetBaseModelsHandler = (isAlternative: boolean) =>
    cDRH(async (data: DAT.GetBaseModelsNRequest) => {
        // Check for validity
        if (!data.vehicleType || !data.manufacturer) {
            return [];
        }

        try {
            const response = await API.vehicleSelection.getBaseModelsN({
                ...data,
                withRepairIncomplete: !isAlternative && pluginStores.settings.getState().repairIncomplete
            });

            return response.getFieldArray('baseModelN').map(baseModel => ({
                ...baseModel,
                title: baseModel.repairIncomplete && i18n.t('vehicle-selection:alternativeModelInfoText')
            }));
        } catch {
            return [];
        }
    });

/*** Get sub models handler ***/
const getSubModelsHandler = cDRH(async (data: DAT.GetSubModelsRequest) => {
    // Check for validity
    if (!data.vehicleType || !data.manufacturer || !data.baseModel) {
        return [];
    }

    try {
        const response = await API.vehicleSelection.getSubModels(data);

        return response.getFieldArray('subModel');
    } catch {
        return [];
    }
});

//
/*** Get vehicle types ***/
//
const getVehicleTypesFx = domain.createEffect({
    handler: getVehicleTypesHandler
});
const resetVehicleTypes = domain.createEvent(); // (test feature) for country test
const vehicleTypes = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getVehicleTypesFx.doneData, (_, data) => data)
    .reset(resetVehicleTypes);

//
/*** Alternative model ***/
//

/*** Status ***/
const setIsOptionWithRepairIncompleteSelected = domain.createEvent<boolean>();
const isOptionWithRepairIncompleteSelected = restore(setIsOptionWithRepairIncompleteSelected, false);

/*** Get manufacturers ***/
const getAlternativeManufacturersFx = domain.createEffect({
    handler: getManufacturersHandler
});
const resetAlternativeManufacturers = domain.createEvent();
const alternativeManufacturers = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getAlternativeManufacturersFx.doneData, (_, data) => data)
    .reset(resetAlternativeManufacturers);

/*** Get base models ***/
const getAlternativeBaseModelsFx = domain.createEffect({
    handler: createGetBaseModelsHandler(true)
});
const resetAlternativeBaseModels = domain.createEvent();
const alternativeBaseModels = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getAlternativeBaseModelsFx.doneData, (_, data) => {
        const withAZSort = pluginStores.settings.getState().AZMainModel;

        return withAZSort ? sortKeyValueArrayByAlphabet(data) : data;
    })
    .reset(resetAlternativeBaseModels);

/*** Get sub models ***/
const getAlternativeSubModelsFx = domain.createEffect({
    handler: getSubModelsHandler
});
const resetAlternativeSubModels = domain.createEvent();
const alternativeSubModels = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getAlternativeSubModelsFx.doneData, (_, data) => data)
    .reset(resetAlternativeSubModels);

/*** Wrap effect to subscribe it to repair-incomplete status ***/
interface WithRepairIncompleteParam {
    withRepairIncomplete?: boolean;
}

const subscribeEffectToRepairIncomplete = <Params extends WithRepairIncompleteParam, Done>(
    effect: Effect<Params, Done>
) =>
    attach({
        effect,
        source: isOptionWithRepairIncompleteSelected,
        mapParams: (data: Params, isOptionWithRepairIncompleteSelected): Params => ({
            ...data,
            withRepairIncomplete: isOptionWithRepairIncompleteSelected
        })
    });

//
/*** Main model ***/
//

/*** Get manufacturers ***/
const getManufacturersFx = domain.createEffect({
    handler: getManufacturersHandler
});
const resetManufacturers = domain.createEvent();
const manufacturers = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getManufacturersFx.doneData, (_, data) => data)
    .reset(resetManufacturers);

/*** Get base models ***/
const getBaseModelsFx = domain.createEffect({
    handler: createGetBaseModelsHandler(false)
});
const resetBaseModels = domain.createEvent();
const baseModels = domain
    .createStore<Array<DAT.GetBaseModelsNResponse['baseModelN']>>([])
    .on(getBaseModelsFx.doneData, (_, data) => {
        const withAZSort = pluginStores.settings.getState().AZMainModel;

        return withAZSort ? sortKeyValueArrayByAlphabet(data) : data;
    })
    .reset(resetBaseModels);

/*** Get sub models ***/
const getSubModelsFx = subscribeEffectToRepairIncomplete(
    domain.createEffect({
        handler: getSubModelsHandler
    })
);
const resetSubModels = domain.createEvent();
const subModels = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getSubModelsFx.doneData, (_, data) => data)
    .reset(resetSubModels);

//
/*** Init alternative model options ***/
//
sample({
    source: manufacturers,
    clock: guard({
        source: isOptionWithRepairIncompleteSelected,
        filter: isOptionWithRepairIncompleteSelected => isOptionWithRepairIncompleteSelected
    }),
    fn: manufacturers => manufacturers,
    target: alternativeManufacturers
});
sample({
    source: baseModels,
    clock: guard({
        source: isOptionWithRepairIncompleteSelected,
        filter: isOptionWithRepairIncompleteSelected => isOptionWithRepairIncompleteSelected
    }),
    fn: baseModels => baseModels.filter(({ repairIncomplete }) => !repairIncomplete),
    target: alternativeBaseModels
});

//
/*** Compile DAT Code ***/
//
const compileDatECodeFx = subscribeEffectToRepairIncomplete(
    domain.createEffect({
        handler: cDRH(async (data: DAT.CompileDatECodeRequest) => {
            const response = await API.vehicleSelection.compileDatECode(data);

            return response.getSingleField('datECode')._innerText;
        })
    })
);

//
/*** Get classification groups ***/
//
const getClassificationGroupsFx = subscribeEffectToRepairIncomplete(
    domain.createEffect({
        handler: cDRH(async (data: DAT.GetClassificationGroupsRequest) => {
            const response = await API.vehicleSelection.getClassificationGroups(data);
            const classificationGroups = response.getFieldArray('classificationGroup').map(group => group._innerText);

            // Move equipment line classificationGroup to the end of list
            const equipmentLineIndex = classificationGroups.indexOf(ClassificationGroupsKeys.EquipmentLine);

            if (equipmentLineIndex !== -1) {
                classificationGroups.splice(equipmentLineIndex, 1);
                classificationGroups.push(ClassificationGroupsKeys.EquipmentLine);
            }

            return classificationGroups.filter(group => group);
        })
    })
);
const classificationGroups = domain
    .createStore<ClassificationGroupsKeys[]>([])
    .on(getClassificationGroupsFx.doneData, (_, groups) => groups);

//
/*** Get options by classification ***/
//
const getOptionsbyClassificationFx = subscribeEffectToRepairIncomplete(
    domain.createEffect({
        handler: cDRH(API.vehicleSelection.getOptionsbyClassification)
    })
);

//
/*** Get all / available equipment objects ***/
//
const resetAllEquipment = domain.createEvent();
const allEquipmentObject = domain.createStore<EquipmentObject>({}).reset(resetAllEquipment);

const resetAvailableEquipment = domain.createEvent();
const availableEquipmentObject = domain.createStore<EquipmentObject>({});

sample({
    source: allEquipmentObject,
    clock: resetAvailableEquipment,
    target: availableEquipmentObject
});

const getEquipmentObjectFx = createNotifyingEffect({
    handler: async ({ requestData, prevEquipmentObject, classificationGroups }: GetEquipmentObjectParam) => {
        // Check for validity
        if (!requestData.vehicleType || !requestData.manufacturer || !requestData.baseModel || !requestData.subModel) {
            return {};
        }

        // When getting all equipment object
        // classificationGroups should be updated
        if (!classificationGroups) {
            // Using ".catch" to avoid errors when classification groups are requested with new firstRegistration
            classificationGroups = await getClassificationGroupsFx(requestData).catch(() => []);
        }

        const newEquipmentObject: EquipmentObject = {};

        await Promise.all(
            classificationGroups?.map(group => {
                // Exclude selected option of current classificationGroup
                const availableOptions = requestData.availableOptions?.filter(
                    optionId => !prevEquipmentObject[group]?.some(({ key }) => optionId === key)
                );

                return getOptionsbyClassificationFx({ ...requestData, availableOptions, classification: group })
                    .then(response => {
                        newEquipmentObject[group] = response.getFieldArray('options');
                    })
                    .catch(err => err);
            })
        );

        return newEquipmentObject;
    }
});

// Get all possible equipment for selected vehicle
const getAllEquipmentObjectFx = attach({
    effect: getEquipmentObjectFx,
    source: allEquipmentObject,
    mapParams: cDRH(
        (
            requestData: GetEquipmentObjectRequestData,
            prevEquipmentObject: EquipmentObject
        ): GetEquipmentObjectParam => ({
            requestData,
            prevEquipmentObject
        })
    )
});

// In case when every allEquipmentObject's received options are single
// they will be preselected, and "compileDatECodeFx" in changeCallback
// of EquipmentSelect won't be called. Therefore compiling DAT code manually.
getAllEquipmentObjectFx.done.watch(
    ({ params: { vehicleType, manufacturer, baseModel, subModel }, result: allEquipmentObject }) => {
        const groupsArray = Object.values(allEquipmentObject);
        const isEveryOptionSingle = groupsArray.length && groupsArray.every(options => options?.length === 1);

        if (isEveryOptionSingle) {
            const selectedOptions = groupsArray
                .map(options => {
                    if (options) {
                        return options[0].key;
                    }

                    return undefined;
                })
                .filter(option => !!option) as string[];

            compileDatECodeFx({ vehicleType, manufacturer, baseModel, subModel, selectedOptions });
        }
    }
);

// Get equipment based on already selected options
const getAvailableEquipmentObjectFx = attach({
    effect: getEquipmentObjectFx,
    source: { prevEquipmentObject: allEquipmentObject, classificationGroups },
    mapParams: cDRH(
        (
            requestData: GetEquipmentObjectRequestData,
            { prevEquipmentObject, classificationGroups }
        ): GetEquipmentObjectParam => ({
            requestData,
            prevEquipmentObject,
            classificationGroups
        })
    )
});

forward({
    from: getAllEquipmentObjectFx.doneData,
    to: [allEquipmentObject, availableEquipmentObject]
});
forward({
    from: getAvailableEquipmentObjectFx.doneData,
    to: availableEquipmentObject
});

//
/*** Get construction periods and time ***/
//
const getConstructionPeriodsFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetConstructionPeriodsNRequest) => {
        const response = await API.vehicleSelection.getConstructionPeriodsN(data);
        const { constructionTimeMin, constructionTimeMax } = response.getSingleField('constructionPeriodN');
        const constructionPeriods = response.getFieldArray('entry').map(({ value }) => ({
            key: value,
            value: convertConstructionPeriodToDate(value),
            label: convertConstructionPeriodToDate(value)
        }));
        const uniqueConstructionPeriods = uniqBy(({ key }) => key, constructionPeriods);

        return {
            constructionTimeMin,
            constructionTimeMax,
            constructionPeriods: uniqueConstructionPeriods
        };
    })
});

/*** Construction periods ***/
const setConstructionPeriods = domain.createEvent<DAT.KeyValueField[]>();
const resetConstructionPeriods = domain.createEvent();
const constructionPeriods = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getConstructionPeriodsFx.doneData, (_, { constructionPeriods }) => constructionPeriods)
    .on(setConstructionPeriods, (_, periods) => periods)
    .reset(resetConstructionPeriods);

/*** Construction time ***/
const constructionTimeFrom = domain
    .createStore('')
    .on(getConstructionPeriodsFx.doneData, (_, { constructionTimeMin }) => constructionTimeMin)
    .reset(resetConstructionPeriods);
const constructionTimeTo = domain
    .createStore('')
    .on(getConstructionPeriodsFx.doneData, (_, { constructionTimeMax }) => constructionTimeMax)
    .reset(resetConstructionPeriods);

//
/*** Containers (price focus cases) ***/
//
const getContainersFx = createNotifyingEffect({
    handler: cDRH(API.vehicleSelection.getPriceFocusCases)
});
const containers = domain
    .createStore<DAT.KeyValueField[]>([])
    .on(getContainersFx.doneData, (_, response) => response.getFieldArray('priceFocusCase'))
    .reset(resetConstructionPeriods);

// Get containers when constructionTimeFrom or constructionTimeTo are updated and not empty
// if restriction equals "ALL" or "APPRAISAL" + get datECode from last getConstructionPeriodsFx call
sample({
    source: getConstructionPeriodsFx,
    clock: guard({
        source: combine({ constructionTimeFrom, constructionTimeTo }),
        filter: ({ constructionTimeFrom, constructionTimeTo }) => {
            if (sharedTemplateStores.templateSettings.restriction.getState() === 'REPAIR') {
                return false;
            }

            return !!constructionTimeFrom && !!constructionTimeTo;
        }
    }),
    fn: ({ datECode }, { constructionTimeFrom, constructionTimeTo }) => ({
        datECode,
        constructionTimeFrom,
        constructionTimeTo
    }),
    target: getContainersFx
});

//
/*** Technical data ***/
//
const getTechnicalDataFx = domain.createEffect({
    handler: cDRH(async (data: DAT2.Request.GetVehicleData): Promise<TechnicalData | undefined> => {
        const vehicleData = await API2.vehicleSelection.getVehicleData(data);

        if (!vehicleData?.VXS.Vehicle?.TechInfo) {
            return undefined;
        }

        return {
            ...getTextValuesFromParsedObject(vehicleData.VXS.Vehicle?.TechInfo),
            EmissionClassN: vehicleData?.VXS.Vehicle?.TechInfo.EmissionClassN?.EmissionClassItemN?.description
        };
    })
});
const resetTechnicalData = domain.createEvent();
const technicalData = restore(getTechnicalDataFx, null).reset(resetTechnicalData);

sample({
    source: getConstructionPeriodsFx.done,
    fn: ({ params, result }) => ({
        ...params,
        constructionTime: Math.max(...result.constructionPeriods.map(({ key }) => +key))
    }),
    target: getTechnicalDataFx
});

//TODO: focus broken when trying to focus "paintTypes" - isLoading stays "true"
const isVehicleSelected = technicalData.map(data => !!data);

//
/*** Export ***/
//
export const vehicleSelectionEvents = {
    resetVehicleTypes,
    setIsOptionWithRepairIncompleteSelected,
    resetAlternativeManufacturers,
    resetAlternativeBaseModels,
    resetAlternativeSubModels,
    resetManufacturers,
    resetBaseModels,
    resetSubModels,
    resetAllEquipment,
    resetAvailableEquipment,
    setConstructionPeriods,
    resetConstructionPeriods,
    resetTechnicalData
};
export const vehicleSelectionEffects = {
    getVehicleTypesFx,
    getManufacturersFx,
    getBaseModelsFx,
    getSubModelsFx,
    compileDatECodeFx,
    getClassificationGroupsFx,
    getOptionsbyClassificationFx,
    getEquipmentObjectFx,
    getAllEquipmentObjectFx,
    getAvailableEquipmentObjectFx,
    getConstructionPeriodsFx,
    getContainersFx,
    getAlternativeManufacturersFx,
    getAlternativeBaseModelsFx,
    getAlternativeSubModelsFx,
    getTechnicalDataFx
};
export const vehicleSelectionStores = {
    vehicleTypes,
    isOptionWithRepairIncompleteSelected,
    alternativeManufacturers,
    alternativeBaseModels,
    alternativeSubModels,
    manufacturers,
    baseModels,
    subModels,
    classificationGroups,
    allEquipmentObject,
    availableEquipmentObject,
    constructionPeriods,
    containers,
    technicalData,
    isVehicleSelected
};
export const combinedVehicleSelectionStores = combine(vehicleSelectionStores);

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(vehicleSelectionEvents);
    effectorLogger(vehicleSelectionEffects);
    effectorLogger(vehicleSelectionStores);
}
