import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modalsEvents, modalsStores } from '../../stores/modals';
import { vehicleSelectionStores } from '../../stores/vehicleSelection';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { Table } from '@wedat/ui-kit/components/Table';

import { TechincalDataItem } from '../../types/vehicleSelection';

export const TechnicalDataModal: FC = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isTechnicalDataModalOpen);
    const technicalData = useStore(vehicleSelectionStores.technicalData);
    const data = technicalData
        ? Object.entries(technicalData)?.map(
              (dataItem: string[]): TechincalDataItem => ({
                  col1: t(`technicalDataModal.${dataItem[0]}`),
                  col2: dataItem[1]
              })
          )
        : [];

    const columns = [
        {
            Header: t('technicalDataModal.column.name'),
            accessor: 'col1'
        },
        {
            Header: t('technicalDataModal.column.value'),
            accessor: 'col2'
        }
    ];

    return (
        <ModalPrimary
            bodyHeight="auto"
            bodyWidth="50vw"
            title={t('technicalData')}
            isOpen={isOpen}
            onDismiss={modalsEvents.closeTechnicalDataModal}
        >
            <Table columns={columns} data={data} mobileGridColAmount={2} />
        </ModalPrimary>
    );
};
