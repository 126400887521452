import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';
import { Accordion } from '@wedat/ui-kit/components/Accordion';

export const StyledAccordion = styled(Accordion)`
    margin-bottom: 20px;
`;

export const AttachmentsTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    width: 100%;
    padding-right: 44px;
`;

export const AttachmentsTitle = styled(Text)`
    margin-left: 20px;
    margin-right: 12px;
`;

export const AttachmentsCount = styled(Text)`
    color: ${({ theme }) => theme.colors.dustBlue['400']};
`;

export const Attachments = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(92px, 1fr));
    margin-top: 20px;
`;
