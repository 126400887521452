import styled from 'styled-components';
import { media } from '@wedat/ui-kit/mediaQueries';

interface Props {
    color?: string;
    fontWeight?: number;
    fontSize?: string;
    marginRight?: number;
}

interface ConstantsProps {
    isVisible?: number;
}

export const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;

    ${media.phone`
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
    `}
`;

export const FooterItem = styled.div<Props>`
    margin-bottom: 20px;

    &:not(:last-child) {
        ${media.phone`
            margin: 0;
        `}
    }

    & span:first-child {
        margin-right: 20px;
        margin-left: 20px;

        ${media.phone`
            margin-left: 0;
        `}
    }

    ${media.phone`
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    `}
`;

export const ConstantsItem = styled.div`
    min-width: 200px;
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 2px solid #f1f2f4;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 20px;

    ${media.phoneBig`
        width: 100%;
        margin-right: 0;
    `}

    ${media.phone`
        border: none;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    `}
`;

export const ConstantsContainer = styled.div<ConstantsProps>`
    width: 100%;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    justify-content: space-between;
    flex-wrap: wrap;

    ${media.phone`
        margin-top: -140px;
        background-color: #fff;
        border-radius: 2px;
        padding: 15px;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 160px;
    `}
`;

export const FooterItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    ${media.phone`
        width: 100%;
        padding: 16px 16px 20px 16px;
        flex-direction: column;

        & ${FooterItem}:not(:last-child) {
            margin-bottom: 15px;
        }
    `}
`;

export const SparePartsContainer = styled.div`
    margin-top: -110px;
    margin-bottom: 60px;
    background-color: #fff;
    border-radius: 2px;
    font-weight: 500;

    ${media.phone`
        width: 100%;
        padding: 16px 16px 20px 16px;
        flex-direction: column;

        & ${FooterItem}:not(:last-child) {
            margin-bottom: 15px;
        }
    `}
`;
