export interface FieldData {
    name: string;
    value: any;
    type?: string;
}

const parseSubject = (field: FieldData) => {
    const arr = Object.keys(field.value).map(item => {
        const isDate = new Date(field.value[item]) instanceof Date;
        return {
            key: `${field.name}_${item.replace('_', '')}`,
            value: isDate ? field.value[item].toLocaleDateString('fr') : field.value[item]
        };
    });

    return arr;
};

const parseBool = (field: FieldData) => {
    const value = field.value === true ? 1 : 0;
    return {
        key: field.name,
        value
    };
};

const parseBuilderField = (field: FieldData) => {
    if (field.type === 'subject') {
        return parseSubject(field);
    }

    if (typeof field.value === 'boolean') {
        return [parseBool(field)];
    }

    return [
        {
            key: field.name,
            value: field.value
        }
    ];
};

const parseArrayBuilderField = (fields: FieldData[]) => fields.map(parseBuilderField).flat();

export const parseFormBuilderField = (
    field: FieldData | FieldData[]
): Array<DAT2.Field.EntryForRequest<string, DAT2.Field.Primitive>> =>
    Array.isArray(field) ? parseArrayBuilderField(field) : parseBuilderField(field);
