import axios from 'axios';
import { GetZoneGraphicBaseResult } from '../types/zusatzTypes';
import { xmlToObjWithArrayMode } from '../utils/xmlConverters';

const decompress = require('brotli/decompress');

const isBrotliBrowserSupport = false;

// import untar from 'js-untar';
const untar = require('js-untar');

const staticGrapaServiceUrl = !isBrotliBrowserSupport
    ? 'https://storage.yandexcloud.net/grapa-data/grapa-service2'
    : 'https://storage.yandexcloud.net/grapa-data/grapa-service';

// https://github.com/InvokIT/js-untar
export interface UnTarFile {
    name: string; // The full filename (including path and ustar filename prefix).  '01_020_002/01_020_002_0001.svg';
    mtime: string; // 1643800293;
    size: number; // 408228;

    buffer: ArrayBuffer;
    blob: Blob;

    checksum: number;
    linkname: string;
    mode: string; // '000666 ';
    uid: number;
    gid: number;
    type: string;
    ustarFormat: string;

    getBlobUrl: () => URL; //A unique ObjectUrl to the data can be retrieved with this method for easy usage of extracted data in <img> tags etc.
    readAsString: () => string; // Parse the file contents as a UTF-8 string.
    readAsJSON: () => object; // Parse the file contents as a JSON object.

    // If the .tar file was in the ustar format (which most are), the following properties are also defined:

    version: string;
    uname: string;
    gname: string;
    devmajor: number;
    devminor: number;
    namePrefix: string;
}

// fetch file
export async function getPackFileByDatECode(datECode: string, serviceUrl?: string) {
    const fza = datECode.substring(0, 2);
    const hst = datECode.substring(2, 5);
    const ht = datECode.substring(5, 8);
    const fzaHstHt = fza + '_' + hst + '_' + ht;

    const path = 'fullGraphics/' + fza + '/' + hst + '/' + fzaHstHt + '.tar.brotli';

    const staticGrapaServiceAxiosInstance = axios.create({
        baseURL: serviceUrl || staticGrapaServiceUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = (await staticGrapaServiceAxiosInstance.get(path, { responseType: 'arraybuffer' })).data;
    return data;
}

export async function getFilesByDatECode({ datECode, serviceUrl }: { datECode: string; serviceUrl?: string }) {
    let data = await getPackFileByDatECode(datECode, serviceUrl);

    let startTime = performance.now();
    let endTime = performance.now();

    if (!isBrotliBrowserSupport) {
        // unpack data
        startTime = performance.now();
        data = decompress(Buffer.from(data)).buffer;
        endTime = performance.now();
        console.log(`Call to decompress took ${endTime - startTime} milliseconds`);
    }

    // read from tar split on files
    startTime = performance.now();
    const extractedFiles = (await untar(data)) as UnTarFile[];
    endTime = performance.now();
    console.log(`Call to untar took ${endTime - startTime} milliseconds`);

    return extractedFiles;
}

export async function getSvgZoneGraphicById({ id, serviceUrl }: { id: number | null; serviceUrl?: string }) {
    if (!id) return null;
    const staticGrapaServiceAxiosInstance = axios.create({
        baseURL: serviceUrl || staticGrapaServiceUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!isBrotliBrowserSupport) {
        const packData = (
            await staticGrapaServiceAxiosInstance.get(`zoneSVG/zusatz4a_zg_${id}.svg`, { responseType: 'arraybuffer' })
        ).data;

        // unpack data
        let startTime = performance.now();
        const unpackData = decompress(Buffer.from(packData)).buffer;
        let endTime = performance.now();
        console.log(`Call to decompress took ${endTime - startTime} milliseconds`);

        return { id: id.toString(), value: new TextDecoder().decode(unpackData) as string };
    }

    const data = (await staticGrapaServiceAxiosInstance.get(`zoneSVG/zusatz4a_zg_${id}.svg`, { responseType: 'text' }))
        .data;
    return { id: id.toString(), value: data as string };
}

export async function getZoneGraphicBase(serviceUrl?: string) {
    const staticGrapaServiceAxiosInstance = axios.create({
        baseURL: serviceUrl || staticGrapaServiceUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!isBrotliBrowserSupport) {
        const data = (
            await staticGrapaServiceAxiosInstance.get(`zoneSVG/zusatz4a_ht_zg.xml`, { responseType: 'arraybuffer' })
        ).data;
        const unpackData = decompress(Buffer.from(data));
        const result = xmlToObjWithArrayMode(new TextDecoder().decode(unpackData)) as GetZoneGraphicBaseResult;
        return result;
    }

    const data = (await staticGrapaServiceAxiosInstance.get(`zoneSVG/zusatz4a_ht_zg.xml`, { responseType: 'text' }))
        .data;
    const result = xmlToObjWithArrayMode(data) as GetZoneGraphicBaseResult;
    return result;
}
