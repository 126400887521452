import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardIndicator } from '../../EquipmentTransfer/styles';
import { LegendContainer, LegendItem, IndicatorWrapper, StyledText } from './styles';

export const FooterLegend: React.FC = () => {
    const { t } = useTranslation();

    return (
        <LegendContainer>
            <LegendItem>
                <IndicatorWrapper>
                    <StandardIndicator standard />
                </IndicatorWrapper>
                <StyledText font="note" textOverflow="ellipsis">
                    {t('standardEquipment')}
                </StyledText>
            </LegendItem>
            <LegendItem>
                <IndicatorWrapper>
                    <StandardIndicator standard={false} />
                </IndicatorWrapper>
                <StyledText font="note" textOverflow="ellipsis">
                    {t('optionalEquipment')}
                </StyledText>
            </LegendItem>
        </LegendContainer>
    );
};
