import { ProgressBarStep, StepsWrapper } from './styles';

import { STEPS_OPTIONS } from '../constants';

import { fuelLevelEffects } from '../../../stores/fuelLevel';

interface Props {
    fuelValue?: string;
}

const { fuelValueProcessingFx } = fuelLevelEffects;

export const ProgressBar = ({ fuelValue }: Props) => {
    const currentStep = STEPS_OPTIONS.find(step =>
        step.values.find(value => (fuelValue ? value === +fuelValue : undefined))
    );

    return (
        <StepsWrapper>
            {STEPS_OPTIONS.map((option, idx) => (
                <ProgressBarStep
                    onClick={async () => {
                        await fuelValueProcessingFx(String(option.values[option.values.length - 1]));
                    }}
                    stepBg={currentStep?.backgroundColor}
                    isActive={option.values.some(value => (fuelValue ? value <= +fuelValue : undefined))}
                    key={idx}
                />
            ))}
        </StepsWrapper>
    );
};
