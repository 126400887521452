import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { sharedMailEvents, sharedMailStores } from '@dat/shared-models/mail';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Drawer, DrawerOptions } from '@wedat/ui-kit/components/Drawer';
import { Inputs } from '../Inputs';
import { ContractList } from '../ContractList';
import { Attachments } from '../Attachments';
import { modalsStores, modalsEvents } from '../../stores/modals';
import { recipientsStores, recipientsEvents } from '../../stores/recipients';

const { toggleIsMailOpen } = sharedMailEvents;

const { toggleIsContractListOpen, toggleIsContractListCCOpen, toggleIsContractListCCNOpen, toggleIsAttachments } =
    modalsEvents;
const {
    addItemToRecipients,
    addItemToCcRecipients,
    addItemToBccRecipients,
    removeItemFromRecipients,
    removeItemFromCcRecipients,
    removeItemFromBccRecipients,
    resetRecipients,
    resetCcRecipients,
    resetBccRecipients
} = recipientsEvents;

export const DrawerContents: React.FC = () => {
    const { t } = useTranslation();

    const isMailOpen = useStore(sharedMailStores.isMailOpen);
    const isContractListOpen = useStore(modalsStores.isContractListOpen);
    const isContractListCCOpen = useStore(modalsStores.isContractListCCOpen);
    const isContractListCCNOpen = useStore(modalsStores.isContractListCCNOpen);
    const isAttachmentsOpen = useStore(modalsStores.isAttachmentsOpen);

    const recipients = useStore(recipientsStores.recipients);
    const ccRecipients = useStore(recipientsStores.ccRecipients);
    const bccRecipients = useStore(recipientsStores.bccRecipients);

    const isTablet = useMedia(sizes.laptop);
    const isMobile = useMedia(sizes.phoneBig);

    const options: DrawerOptions = {
        position: 'right',
        fullScreen: isMobile,
        halfScreen: isTablet,
        items: [
            {
                isOpen: isMailOpen,
                onClose: () => toggleIsMailOpen(false),
                width: 540,
                title: t('titles.mail'),
                component: <Inputs />
            },
            {
                isOpen: isContractListOpen,
                onClose: () => toggleIsContractListOpen(false),
                width: 340,
                title: t('titles.contactList'),
                component: (
                    <ContractList
                        selectedDestination={recipients}
                        addItemToDestination={addItemToRecipients}
                        removeItemFromDestination={removeItemFromRecipients}
                        reset={resetRecipients}
                        close={() => toggleIsContractListOpen(false)}
                        isTablet={isTablet}
                        isOpen={isContractListOpen}
                    />
                )
            },
            {
                isOpen: isContractListCCOpen,
                onClose: () => toggleIsContractListCCOpen(false),
                width: 340,
                title: t('titles.contactListCC'),
                component: (
                    <ContractList
                        selectedDestination={ccRecipients}
                        addItemToDestination={addItemToCcRecipients}
                        removeItemFromDestination={removeItemFromCcRecipients}
                        reset={resetCcRecipients}
                        close={() => toggleIsContractListCCOpen(false)}
                        isTablet={isTablet}
                        isOpen={isContractListCCOpen}
                    />
                )
            },
            {
                isOpen: isContractListCCNOpen,
                onClose: () => toggleIsContractListCCNOpen(false),
                width: 340,
                title: t('titles.contactListCCN'),
                component: (
                    <ContractList
                        selectedDestination={bccRecipients}
                        addItemToDestination={addItemToBccRecipients}
                        removeItemFromDestination={removeItemFromBccRecipients}
                        reset={resetBccRecipients}
                        close={() => toggleIsContractListCCNOpen(false)}
                        isTablet={isTablet}
                        isOpen={isContractListCCNOpen}
                    />
                )
            },
            {
                isOpen: isAttachmentsOpen,
                onClose: () => toggleIsAttachments(false),
                width: 516,
                title: t('titles.attachments'),
                component: <Attachments isTablet={isTablet} />
            }
        ]
    };

    return <Drawer options={options} />;
};
