import { ClassificationGroupsKeys } from './enums';

export const classificationGroupsNames = {
    [ClassificationGroupsKeys.Engine]: 'engine',
    [ClassificationGroupsKeys.Body]: 'bodywork',
    [ClassificationGroupsKeys.Model]: 'model',
    [ClassificationGroupsKeys.Transmission]: 'transmission',
    [ClassificationGroupsKeys.Wheelbase]: 'wheelbase',
    [ClassificationGroupsKeys.DriveType]: 'typeOfDrive',
    [ClassificationGroupsKeys.NumberOfAxles]: 'numberOfAxles',
    [ClassificationGroupsKeys.DriversCabin]: 'driversCab',
    [ClassificationGroupsKeys.Tonnage]: 'vehicleWeight',
    [ClassificationGroupsKeys.SuspensionType]: 'suspensionType',
    [ClassificationGroupsKeys.EquipmentLine]: 'equipmentLine'
} as const;
