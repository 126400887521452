import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { getMonth, getYear } from 'date-fns';
import { Select, ValueLabelOption } from '../../Select';
import { Header } from './styles';

type DatepickerHeaderProps = Parameters<Required<ReactDatePickerProps>['renderCustomHeader']>[0] & {
    yearsOptions: ValueLabelOption[];
    monthsOptions: ValueLabelOption[];
};

export const DatepickerHeader: React.FC<DatepickerHeaderProps> = ({
    date,
    changeYear,
    changeMonth,
    yearsOptions,
    monthsOptions
}) => (
    <Header>
        <div className="react-datepicker__custom-header-years">
            <Select
                name="year"
                valueKey="value"
                options={yearsOptions}
                value={getYear(date)}
                onChange={option => changeYear(Number(option?.value))}
                isSearchable={false}
            />
        </div>
        <div className="react-datepicker__custom-header-months">
            <Select
                name="month"
                valueKey="value"
                options={monthsOptions}
                value={getMonth(date)}
                onChange={option => changeMonth(Number(option?.value))}
                isSearchable={false}
            />
        </div>
    </Header>
);
