import React, { FC, useMemo, ReactNode, useRef } from 'react';
import Modal, { Styles } from 'react-modal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIOS } from 'react-device-detect';
import { options } from './constants';
import { useMedia } from '../../../core/hooks/useMedia';
import { sizes } from '../../mediaQueries';

import { CloseIcon } from '../Icons';

import { ModalWindow, ModalBody, ModalHeader, CloseButton, ModalContent, ModalTitle, ModalFooter } from './styles';

//TODO: shouldMountInitially
export interface ModalProps {
    onDismiss: () => void;
    isOpen: boolean;
    title?: string;
    maxWidth?: string;
    fullWidth?: boolean;
    headerChildren?: ReactNode;
    bodyHeight?: string;
    bodyWidth?: string;
    bodyNoPadding?: boolean;
    zIndex?: number;
    // for the case of datepicker being the only component in the modal so that it cat go outside the modalBody
    unsetOverflow?: boolean;
    footerChildren?: ReactNode;
    hideHeader?: boolean;
    fullFill?: boolean;
}

export const ModalPrimary: FC<ModalProps> = ({
    children,
    isOpen,
    title,
    maxWidth = '90vw',
    fullWidth,
    headerChildren,
    bodyHeight,
    bodyWidth,
    bodyNoPadding,
    unsetOverflow,
    footerChildren,
    hideHeader,
    zIndex,
    fullFill,
    ...rest
}) => {
    const mobile = useMedia(sizes.phone);
    const isIOSMobile = mobile && isIOS;

    const customStyles: Styles = useMemo(
        () => ({
            overlay: {
                position: 'fixed',
                inset: 0,
                zIndex: zIndex || 10000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(70, 80, 100, 0.5)'
            },
            content:
                mobile || fullFill
                    ? {
                          position: 'fixed',
                          inset: 0,
                          height: '100%',
                          maxHeight: '100vh',
                          display: 'unset',
                          justifyContent: 'unset',
                          alignItems: 'unset',
                          padding: 0,
                          backgroundColor: '#fff',
                          border: 'none',
                          overflow: 'unset'
                      }
                    : {
                          position: 'fixed',
                          top: 'auto',
                          left: 'auto',
                          backgroundColor: '#fff',
                          borderRadius: '4px',
                          outline: 'none',
                          overflow: unsetOverflow ? 'unset' : 'hidden',
                          padding: 0,
                          right: 'unset',
                          bottom: 'unset',
                          width: fullWidth ? '100%' : 'max-content',
                          maxWidth,
                          border: 'none'
                      }
        }),
        [fullWidth, maxWidth, mobile, unsetOverflow, zIndex, fullFill]
    );

    const targetElement = useRef<HTMLElement>();

    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            style={customStyles}
            onRequestClose={rest.onDismiss}
            onAfterOpen={() => {
                if (targetElement.current && !isIOSMobile) {
                    disableBodyScroll(targetElement.current, options);
                }
            }}
            onAfterClose={() => clearAllBodyScrollLocks()}
            preventScroll={true}
        >
            <ModalWindow maxWidth={maxWidth} bodyHeight={bodyHeight} fullFill={fullFill}>
                {children && (
                    <ModalContent fullFill={fullFill}>
                        {!hideHeader && (
                            <ModalHeader>
                                <ModalTitle>
                                    {headerChildren}
                                    {title}
                                </ModalTitle>
                                <CloseButton onClick={rest.onDismiss}>
                                    <CloseIcon />
                                </CloseButton>
                            </ModalHeader>
                        )}
                        <ModalBody
                            className="scrollAllowedModalWindow"
                            ref={targetElement as React.RefObject<any>}
                            unsetOverflow={unsetOverflow}
                            bodyNoPadding={bodyNoPadding}
                            bodyWidth={bodyWidth}
                            fullFill={fullFill}
                        >
                            {children}
                        </ModalBody>
                        {footerChildren && <ModalFooter>{footerChildren}</ModalFooter>}
                    </ModalContent>
                )}
            </ModalWindow>
        </Modal>
    );
};
