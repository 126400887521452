export const formatAddressFromAutocomplete = (GeocoderAddressComponent?: google.maps.GeocoderAddressComponent[]) => {
    let address = '';
    let postcode = '';
    let locality = '';
    let administrative_area_level_1 = '';

    if (GeocoderAddressComponent) {
        for (const component of GeocoderAddressComponent as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];

            switch (componentType) {
                case 'street_number': {
                    address = `${component.long_name} ${address}`;
                    break;
                }

                case 'route': {
                    address += component.short_name;
                    break;
                }

                case 'postal_code': {
                    postcode = `${component.long_name}${postcode}`;
                    break;
                }

                case 'postal_code_suffix': {
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                }

                case 'locality':
                    locality = component.long_name;
                    break;

                case 'administrative_area_level_1': {
                    administrative_area_level_1 = component.short_name;
                    break;
                }
            }
        }
    }

    return { address, postcode, locality, administrative_area_level_1 };
};
