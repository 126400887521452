import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { equipmentSignRadioItems } from './constants';

import { EquipmentValuationStyled, TabsWrapper } from './styles';
import { SingleValueValuation } from './SingleValueValuation';
import { LumpSumValuation } from './LumpSumValuation';

import { ManualFields } from '../../../types/manualFields';
import { TabsField } from '@wedat/ui-kit/Formik';

interface Props {
    isMobile: boolean;
}

export const EquipmentValuation: React.FC<Props> = ({ isMobile }) => {
    const { t } = useTranslation();

    const {
        values: {
            Valuation: { EquipmentSign }
        }
    } = useFormikContext<ManualFields>();

    const isSingleValueValuationActive = EquipmentSign === 'calculated value';
    const isLumpSumValuationActive = EquipmentSign === 'flat-rate value';

    const equipmentSignRadioItemsValues = equipmentSignRadioItems(t);

    return (
        <EquipmentValuationStyled>
            <TabsWrapper>
                <TabsField
                    name="Valuation.EquipmentSign"
                    values={equipmentSignRadioItemsValues}
                    typeStyle="mobile"
                    fillWidth={isMobile}
                />
            </TabsWrapper>

            {isSingleValueValuationActive && <SingleValueValuation isMobile={isMobile} />}
            {isLumpSumValuationActive && <LumpSumValuation isMobile={isMobile} />}
        </EquipmentValuationStyled>
    );
};
