import * as Yup from 'yup';

export const PART_SELECTION_VALIDATION = Yup.object({
    country: Yup.string(),
    select: Yup.string(),
    description: Yup.string(),
    price: Yup.number(),
    workingTime: Yup.number(),
    quantity: Yup.number()
});
