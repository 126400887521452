import styled from 'styled-components/macro';

export const ClaimItemWrapper = styled.div`
    width: 100%;
    height: 75px;

    background: ${({ theme }) => theme.colors.white};

    border: 1px solid ${({ theme }) => theme.colors.dustBlue[100]};
    border-radius: 12px;

    padding: 12px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;

    & svg:last-child {
        margin-top: 5px;
        cursor: pointer;
    }

    ${({ theme }) => theme.typography.defaultText};
`;

export const ClaimImg = styled.div`
    width: 60px;
    height: 44px;
`;

export const ClaimInfo = styled.div`
    margin-left: 10px;
`;

export const Image = styled.img`
    width: 60px;
    height: 44px;
`;
