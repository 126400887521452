import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useStore } from 'effector-react';

import { paintTypesStores } from '../../../stores/selects/paintTypes';

import { SelectField, InputField, CheckboxField } from '@wedat/ui-kit/Formik';

import { FactorsParametersObject } from '../../../types/factors';

import { CheckboxFieldContainer, Field, FieldsContainer } from '../../Layout/styles';
import { selectsOptions } from '../../../constants/selectsOptions';

export const CzLacquerFactor: React.FC = () => {
    const { t } = useTranslation();
    const {
        values: {
            CzLacquerFactor: { isMultiColoured }
        }
    } = useFormikContext<FactorsParametersObject>();
    const paintTypesSelectOptions = useStore(paintTypesStores.selectOptionsCz);

    return (
        <>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isCalculationWithoutConstant"
                        label={t('paint.czLacquer.isCalculationWithoutConstant')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <Field>
                    <InputField name="CzLacquerFactor.wage" label={t('paint.wage')} />
                </Field>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="CzLacquerFactor.type"
                        options={paintTypesSelectOptions}
                        label={t('paint.type')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isMultiColoured"
                        label={t('paint.czLacquer.isMultiColoured')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <Field>
                    <SelectField
                        name="CzLacquerFactor.colourCount"
                        disabled={!isMultiColoured}
                        options={selectsOptions.colourCount}
                        label={t('paint.colourCount')}
                        valueType="string"
                        valueKey="key"
                    />
                </Field>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isCertifiedMaterials"
                        label={t('paint.czLacquer.isCertifiedMaterials')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isAntiScratchingCoating"
                        label={t('paint.czLacquer.isAntiScratchingCoating')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isDiscountCombined"
                        label={t('paint.czLacquer.isDiscountCombined')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
            <FieldsContainer marginBottom>
                <CheckboxFieldContainer>
                    <CheckboxField
                        name="CzLacquerFactor.isDiscountSparePart"
                        label={t('paint.czLacquer.isDiscountSparePart')}
                    />
                </CheckboxFieldContainer>
            </FieldsContainer>
        </>
    );
};
