import React from 'react';
import { useStore } from 'effector-react';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { pluginOptionsModel } from '../../stores/pluginOptionsModel';
import { PropsEditor } from '../PropsEditor/PropsEditor';
// import { ContractFields } from '../ContractFields/ContractFields';
// import { PredefinedVehicleSelection } from '../PredefinedVehicleSelection/PredefinedVehicleSelection';
import { FastTrackCalculation } from '../FastTrackCalculation/FastTrackCalculation';
import { SummaryPanelFooter } from '../SummaryPanel/SummaryPanelFooter';
import { SummaryPanelMin } from '../SummaryPanel/SummaryPanelMin';
import { SummaryPanelDrawer } from '../SummaryPanel/SummaryPanelDrawer';
import { commonModel } from '../../stores/commonModel';
import { useElementSize } from '../../utils/hooks/useResizer';

import './index.css';

export const Main: React.FC<{}> = () => {
    const contract = useStore(fastTrackElementModel.stores.$contract);
    const pluginOptions = useStore(pluginOptionsModel.stores.pluginOptions);
    const summaryPanelOpened = useStore(commonModel.stores.summaryPanelOpened);

    const {
        targetRef: refContainer,
        windowSize: { width }
    } = useElementSize({ showFullscreen: false, width: 0, height: 0 });

    if (!pluginOptions) return <div>get pluginOptions...</div>;

    // const { settings } = pluginOptions;
    // const { showContractFields, showPredefinedVehicleSelection } = settings || {};

    const isMobile = !!width && width <= (pluginOptions.settings?.maxMobileWidth || 862);
    commonModel.events.setIsMobile(isMobile);

    return (
        <div id="weDat-ft-root" ref={refContainer}>
            <div
                id="weDat-ft-main"
                className={
                    isMobile
                        ? 'weDat-ft-body-mobile'
                        : 'weDat-ft-body ' +
                          (summaryPanelOpened ? 'weDat-ft-body-summaryPanel' : 'weDat-ft-body-summaryPanelMin')
                }
            >
                <PropsEditor />
                {/* {showContractFields && <ContractFields />}
                {showPredefinedVehicleSelection && <PredefinedVehicleSelection />} */}

                <FastTrackCalculation />
            </div>

            {isMobile && (
                <SummaryPanelFooter
                    isMobile={true}
                    dossier={contract?.Dossier}
                    content={{ calcResult: true }}
                    style={{ position: 'absolute', bottom: 0, left: 0 }}
                />
            )}

            {!summaryPanelOpened && !isMobile && <SummaryPanelMin />}
            <SummaryPanelDrawer isMobile={isMobile} dossier={contract?.Dossier} />
        </div>
    );
};
