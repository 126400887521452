import { combine, guard, sample, split } from 'effector';

import { sharedUserStores } from '@dat/shared-models/user';
import { sharedProfilesStores } from '@dat/shared-models/profiles';
import { contractStores, contractEvents, contractEffects } from '@dat/shared-models/contract';
import { dialogStores, dialogEvents, dialogEffects } from './index';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { generateChatId } from '../../utils/generateChatId';
import { isSenderCurrentUser } from '../../utils/isSenderCurrentUser';
import { combineEvents } from 'patronum';
import { generateMemberId } from '../../utils/generateMemberId';
import { createMessage } from '../../utils/createMessage';

const { currentDialog, contractDialogs, partners, dialogs } = dialogStores;
const { sameCustomerProfiles } = sharedProfilesStores;
const { openDialog, createDialogEvent, sendMessageEvent, readMessagesEvent, sendMessage } = dialogEvents;
const { createDialogFx, sendMessageFx, readMessagesFx, createContractBeforeDialogFx } = dialogEffects;
const { contractId, customTemplateData, contract } = contractStores;
const { getContractFx, createContractFx } = contractEffects;
const { customerNumber, username } = sharedUserStores;
const { newContractReceived } = contractEvents;

createContractBeforeDialogFx.use(createContractFx);

const chatFieldData = customTemplateData.map(item => {
    const data = item.find(entry => entry.key === CONTRACT_ENTRIES_KEYS.MEMO.chat)?.value._text;

    if (!!data) {
        return JSON.parse(String(data));
    }

    return null;
});

const currentDialogHasMessages = currentDialog.map(item => !!item?.messages.length);
const currentDialogNoMessages = currentDialog.map(item => !item?.messages.length);

guard({
    clock: newContractReceived,
    source: chatFieldData,
    filter: chatFieldData => !!chatFieldData,
    target: contractDialogs
});

sample({
    clock: openDialog,
    source: dialogs,
    fn: (dialogs, id) => dialogs.find(item => item.id === id) || null,
    target: currentDialog
});

sample({
    clock: currentDialog,
    target: readMessagesEvent
});

const isContractCreated = contract.map(Boolean);
const contractGotAfterCreation = combineEvents({ events: [createContractBeforeDialogFx.done, getContractFx.done] });
const shouldCreateDialog = guard({
    clock: [sendMessage, contractGotAfterCreation],
    source: sendMessage,
    filter: currentDialogNoMessages
});

split({
    source: shouldCreateDialog,
    match: {
        createDialog: isContractCreated
    },
    cases: {
        createDialog: createDialogEvent,
        __: createContractBeforeDialogFx
    }
});

guard({
    clock: sendMessage,
    filter: currentDialogHasMessages,
    target: sendMessageEvent
});

// TODO: create a message generating util to not repeat the code
sample({
    clock: createDialogEvent,
    source: [contractId, username, currentDialog, customerNumber],
    fn: ([contractId, username, currentDialog, customerNumber], { messageText }): BFF.Chat.Request.CreateDialog => ({
        contractId,
        members: currentDialog!.members,
        dialogId: currentDialog!.id,
        message: createMessage({ customerNumber, username, messageText }),
        type: 'personal'
    }),
    target: createDialogFx
});

sample({
    clock: sendMessageEvent,
    source: [contractId, currentDialog, username, customerNumber],
    fn: ([contractId, currentDialog, username, customerNumber], { messageText }): BFF.Chat.Request.SendMessage => ({
        contractId,
        dialogId: currentDialog?.id || '',
        message: createMessage({ customerNumber, username, messageText })
    }),
    target: sendMessageFx
});

sample({
    clock: [sendMessageEvent, createDialogEvent],
    source: [currentDialog, username, customerNumber],
    fn: ([currentDialog, username, customerNumber], { messageText }): any => {
        const updatedCurrentDialog = { ...currentDialog };

        const newMessage: DAT2.Plugins.Chat.Message = createMessage({ customerNumber, username, messageText });

        const messages = updatedCurrentDialog.messages || [];

        updatedCurrentDialog.messages = [...messages, newMessage];

        return updatedCurrentDialog;
    },
    target: currentDialog
});

sample({
    clock: [sendMessageEvent, createDialogEvent],
    source: [dialogs, currentDialog, username, customerNumber],
    fn: ([dialogs, currentDialog, username, customerNumber], { messageText }) => {
        const updatedDialogs = [...dialogs];

        const newMessage: DAT2.Plugins.Chat.Message = createMessage({ customerNumber, username, messageText });
        const currentDialogIndex = dialogs.findIndex(item => item.id === currentDialog?.id);
        const messages = updatedDialogs[currentDialogIndex].messages || [];

        updatedDialogs[currentDialogIndex].messages = [...messages, newMessage];

        return updatedDialogs;
    },
    target: dialogs
});

const hasUnreadMessages = currentDialog.map(item => !!item?.messages.find(message => message.read === false));
const readMessagesSource = combine([contractId, currentDialog], ([contractId, currentDialog]) => ({
    contractId,
    dialogId: currentDialog?.id || ''
}));

guard({
    clock: readMessagesEvent,
    source: readMessagesSource,
    filter: hasUnreadMessages,
    target: readMessagesFx
});

sample({
    source: [sameCustomerProfiles, partners, username, customerNumber, contractDialogs, contractId],
    fn: ([sameCustomerProfiles, partners, currentUsername, customerNumber, contractDialogs, contractId]) => {
        const newDialogs: DAT2.Plugins.Chat.ContractDialogs = [...contractDialogs];

        Object.keys(sameCustomerProfiles)
            .filter(item => item !== currentUsername)
            .forEach(username => {
                const dialogExists = newDialogs.find(
                    item =>
                        item.id ===
                        generateChatId({
                            contractId,
                            customerNumber,
                            username: currentUsername,
                            companionCustomerNumber: customerNumber,
                            companionUsername: username
                        })
                );

                if (!dialogExists) {
                    newDialogs.push({
                        members: [
                            generateMemberId({ customerNumber, username: currentUsername }),
                            generateMemberId({ customerNumber, username })
                        ],
                        messages: [],
                        id: generateChatId({
                            contractId,
                            customerNumber,
                            username: currentUsername,
                            companionCustomerNumber: customerNumber,
                            companionUsername: username
                        }),
                        type: 'personal'
                    });
                }
            });

        partners.forEach(partner => {
            const dialogExists = newDialogs.find(
                item =>
                    item.id ===
                    generateChatId({
                        contractId,
                        customerNumber,
                        username: currentUsername,
                        companionCustomerNumber: partner.customerNumber,
                        // change to actual companion username
                        companionUsername: 'companionUsername'
                    })
            );

            if (!dialogExists) {
                newDialogs.push({
                    members: [
                        generateMemberId({ customerNumber, username: currentUsername }),
                        generateMemberId({
                            customerNumber: partner.customerNumber,
                            username: partner.name
                        })
                    ],
                    messages: [],
                    id: generateChatId({
                        contractId,
                        customerNumber,
                        username: currentUsername,
                        companionCustomerNumber: partner.customerNumber,
                        // change to actual companion username
                        companionUsername: 'companionUsername'
                    }),
                    type: 'personal'
                });
            }
        });

        // TODO: temporary solution, will be needing change when group chat development starts
        const filteredNewDialogs = newDialogs.filter(dialog => dialog.id.includes(String(customerNumber)));

        return [...filteredNewDialogs];
    },
    target: dialogs
});

sample({
    clock: readMessagesFx,
    source: [dialogs, username, customerNumber],
    fn: ([dialogs, username, customerNumber], { dialogId }) => {
        const targetDialogIndex = dialogs.findIndex(({ id }) => id === dialogId);
        const targetDialog = dialogs[targetDialogIndex];

        if (!targetDialog) return dialogs;

        const { messages } = targetDialog;
        const newMessages = messages.map(message => ({
            ...message,
            read: !isSenderCurrentUser({ sender: message.sender, username, customerNumber })
        }));

        const newDialogs = [...dialogs];
        newDialogs[targetDialogIndex] = { ...targetDialog, messages: newMessages };

        return newDialogs;
    },
    target: dialogs
});
