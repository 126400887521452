import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 100%;

    ${media.laptop`
        background-color: ${({ theme }) => theme.colors.gray['100']};
        position: absolute;
        top: 0;
        bottom: 0;
    `}
`;
