import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modalsEvents, modalsStores } from '../../stores/ui/modals';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { UserDefinedPositions } from '../UserDefinedPositions';

export const ModalUserDefinedPositions = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isUserDefinedPositionsOpen);

    return (
        <ModalPrimary
            title={t('userDefinedPositions.title')}
            bodyWidth="fit-content"
            bodyHeight="fit-content"
            isOpen={isOpen}
            onDismiss={modalsEvents.toggleIsUserDefinedPositionsOpen}
        >
            <UserDefinedPositions toggleIsOpen={modalsEvents.toggleIsUserDefinedPositionsOpen} />
        </ModalPrimary>
    );
};
