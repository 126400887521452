import axios from '../axios';
import { DatProductsUrls, VehicleRepairServices } from '@dat/core/constants/enums';

export const getEquipmentGroups = () =>
    axios<DAT.GetEquipmentGroupsResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.ConversionFunctionsService,
        method: 'getEquipmentGroups'
    });

export const getExistingEquipmentN = (values: DAT.GetExistingEquipmentNRequest) =>
    axios<DAT.GetExistingEquipmentNResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.ConversionFunctionsService,
        method: 'getExistingEquipmentN',
        values
    });

export const getPossibleEquipmentN = (values: DAT.GetPossibleEquipmentNRequest) =>
    axios<DAT.GetPossibleEquipmentNResponse>({
        product: DatProductsUrls.VehicleRepair,
        service: VehicleRepairServices.ConversionFunctionsService,
        method: 'getPossibleEquipmentN',
        values
    });
