import React, { FC, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
    vehicleTypeChangeCallback,
    manufacturerChangeCallback,
    baseModelChangeCallback,
    subModelChangeCallback
} from './changeCallbacks';
import { multipleVariantSelectionEvents, multipleVariantSelectionStores } from '../../stores/multipleVariantSelection';
import { ClassificationGroupsKeys } from '@dat/core/constants';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { EquipmentSelect } from '../EquipmentSelect';
import { CustomSelectField } from '../CustomSelect';
import { ButtonsWrapper, Container, StyledConfirmButton } from './styles';

import { CurrentFormikValues } from '../../types/formik';

// TODO: allow submitting only when every option is selected (?)
export const MultipleVariantSelectionModal: FC = React.memo(() => {
    const { t } = useTranslation();
    const multipleVariantSelectionData = useStore(multipleVariantSelectionStores.multipleVariantSelectionData);
    const { vehicleTypes, manufacturers, baseModels, subModels, equipmentObject } = useStore(
        multipleVariantSelectionStores.visibleOptions
    );
    const currentEquipmentGroupsKeys = Object.keys(equipmentObject) as ClassificationGroupsKeys[];

    const { setFieldValue, initialValues, values } = useFormikContext<CurrentFormikValues>();
    const { vehicleType, manufacturer, baseModel, subModel, equipmentSelects } = values.multipleVariantSelects;

    useEffect(() => {
        setFieldValue('multipleVariantSelects', initialValues.multipleVariantSelects);

        multipleVariantSelectionEvents.initVisibleOptions();
    }, [multipleVariantSelectionData, setFieldValue, initialValues]);

    const handleSubmit = () => {
        const selectedEquipment = Object.values(equipmentSelects).filter(value => value);
        const visibleEquipmentSelectsCount = Object.keys(equipmentObject).length;

        if (selectedEquipment.length !== visibleEquipmentSelectsCount) {
            // TODO: prompt or error
            return;
        }

        multipleVariantSelectionEvents.setVehicleFromMultipleSelection({
            vehicleType,
            manufacturer,
            baseModel,
            subModel,
            selectedEquipment
        });

        // Close modal
        multipleVariantSelectionEvents.resetMultipleVariantSelectionData();
    };

    return (
        <ModalPrimary
            isOpen={!!multipleVariantSelectionData}
            maxWidth="680px"
            title={t('multipleVariantSelectionModalTitle')}
            onDismiss={handleSubmit}
            footerChildren={
                <ButtonsWrapper>
                    <StyledConfirmButton onClick={() => handleSubmit()}>{t('confirm')}</StyledConfirmButton>
                </ButtonsWrapper>
            }
        >
            <Container>
                <CustomSelectField
                    changeCallback={vehicleTypeChangeCallback}
                    name="multipleVariantSelects.vehicleType"
                    options={vehicleTypes}
                />

                <CustomSelectField
                    changeCallback={manufacturerChangeCallback}
                    name="multipleVariantSelects.manufacturer"
                    options={manufacturers}
                />

                <CustomSelectField
                    changeCallback={baseModelChangeCallback}
                    name="multipleVariantSelects.baseModel"
                    options={baseModels}
                />

                <CustomSelectField
                    changeCallback={subModelChangeCallback}
                    name="multipleVariantSelects.subModel"
                    options={subModels}
                />

                {currentEquipmentGroupsKeys.map(group => (
                    <EquipmentSelect key={group} isInMultipleVariantSelectionModal classificationGroupKey={group} />
                ))}
            </Container>

            {/*TODO: button*/}
        </ModalPrimary>
    );
});
