import { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMedia } from '@dat/core/hooks/useMedia';

import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Select } from '@wedat/ui-kit/components/Select';

import { SideBar, SideBarItem, SelectWrapper } from './style';

type SelectedOption = {
    id: number;
    label: string;
    value: string;
};

const createItem = (id: number, label: string, value: string): SelectedOption => ({
    id,
    label,
    value
});

export const AdministrationMenu = () => {
    const [selectedOption, setSelectedOption] = useState<SelectedOption>();
    const [activeId, setActiveId] = useState<number>();
    const isLaptop = useMedia(sizes.laptopSmall);

    const { t } = useTranslation();
    const history = useHistory();

    const items: SelectedOption[] = [
        createItem(0, t('auth:profile.labour-settings'), 'labour-rates-generic'),
        createItem(1, t('auth:profile.part-selection-settings'), 'part-selection')
    ];

    const handleChangeContent = (option: SelectedOption): void => {
        setSelectedOption(items.find(item => item.value === option.value));
        history.push(`/administration/${option.value}`);
    };

    return (
        <>
            {isLaptop && (
                <SelectWrapper>
                    <Select
                        options={items.map(item => ({
                            value: item.value,
                            label: item.label
                        }))}
                        value={(selectedOption as SelectedOption)?.value}
                        onChange={option => {
                            option && handleChangeContent(option as SelectedOption);
                        }}
                        name="selectMenuItem"
                        valueKey="value"
                        label={t('administration.menu')}
                        defaultValue={items[0]}
                    />
                </SelectWrapper>
            )}
            {!isLaptop && (
                <SideBar>
                    {items.map(item => (
                        <SideBarItem
                            key={item.id}
                            onClick={() => {
                                setActiveId(item.id);
                                handleChangeContent(item);
                            }}
                            isActive={activeId === item.id}
                        >
                            {item.label}
                        </SideBarItem>
                    ))}
                </SideBar>
            )}
        </>
    );
};
