import React from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modalsEvents, modalsStores } from '../../stores/ui/modals';

import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { WorkLogic } from '../WorkLogic';

export const ModalWorkLogic: React.FC = () => {
    const { t } = useTranslation();
    const isOpen = useStore(modalsStores.isWorkLogicOpen);

    return (
        <ModalPrimary
            title={t('modalWorkLogic.title')}
            bodyWidth="fit-content"
            bodyHeight="fit-content"
            isOpen={isOpen}
            onDismiss={modalsEvents.setIsWorkLogicOpen}
        >
            <WorkLogic />
        </ModalPrimary>
    );
};
