import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Button } from 'antd';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-hjson';
// import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-textmate';

import { pluginOptionsModel } from '../../stores/pluginOptionsModel';

export const PropsEditor: FC = () => {
    const { showPluginOptions, pluginOptionsHJSONText, isModified } = useStore(
        pluginOptionsModel.stores.pluginOptionsEditor
    );

    if (!showPluginOptions) return null;

    return (
        <div
            style={{
                border: '2px solid black',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                width: '450px',
                maxWidth: '100%',
                // display: showPluginOptions ? 'block' : 'none',
                zIndex: 1020,
                backgroundColor: 'white'
            }}
        >
            <div style={{ margin: '8px', textAlign: 'right' }}>
                <Button
                    disabled={!isModified}
                    onClick={() => {
                        pluginOptionsModel.events.applyTextOptions();
                    }}
                >
                    Apply
                </Button>
                &nbsp;
                <Button
                    onClick={() => {
                        pluginOptionsModel.events.offShowPluginOptions();
                    }}
                >
                    Close
                </Button>
            </div>
            <AceEditor
                highlightActiveLine
                showGutter
                fontSize={14}
                height="calc(100%-50px)"
                mode="hjson"
                name="blah2"
                placeholder=""
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2
                }}
                showPrintMargin={false}
                style={{
                    position: 'absolute',
                    top: '50px',
                    right: 0,
                    bottom: 0,
                    left: 0
                }}
                theme="github"
                value={pluginOptionsHJSONText}
                width="100%"
                onChange={pluginOptionsModel.events.updateOptionText}
            />
        </div>
    );
};
