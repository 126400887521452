import { Bar } from './Bar';
import {
    ArrowRight,
    ArrowWrapper,
    BarsContainer,
    DetailsContainer,
    ClaimsOverdue,
    InfoContainer,
    ArrowsContainer,
    IconArrowWrapper,
    Bars,
    ValueDescription,
    DatepickerContainerText,
    DatepickerContainerValue,
    DatepickerContainer,
    InfoWrapper,
    InfoItem,
    ClaimsOverdueWrapper
} from './styles';
import { Datepicker } from '@dat/smart-components/Datepicker';
import { ArrowLong } from '@wedat/ui-kit/components/Icons';
import { efficiencyStores, efficiencyEvents } from '../../../stores/efficiency';
import { useStore } from 'effector-react/effector-react.cjs';
import { useToggle } from '@dat/core/hooks/useToggle';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { ArrowDownIcon, Text } from '@wedat/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { addWeeks, subWeeks } from 'date-fns';
import { useTheme } from 'styled-components';

export const Details = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const metrics = useStore(efficiencyStores.detailsEfficiencyMetrics);
    const metricsFilters = useStore(efficiencyStores.detailsEfficiencyFilters);
    const perDays = metrics.perDays;
    const countClaimsOverdue = useStore(efficiencyStores.countClaimsOverdue);
    const selected = useStore(efficiencyStores.selected);

    const [isMore, toggleMore] = useToggle(false);
    const isLaptopSmall = useMedia(sizes.laptopSmall);

    const onClickPrevious = () =>
        efficiencyEvents.setEfficiencyDateFilter({
            from: subWeeks(metricsFilters.from, 1),
            to: metricsFilters.from
        });

    const onClickNext = () => {
        efficiencyEvents.setEfficiencyDateFilter({
            from: addWeeks(metricsFilters.from, 1),
            to: addWeeks(metricsFilters.from, 2)
        });
    };

    return (
        <DetailsContainer>
            <InfoContainer>
                <DatepickerContainer>
                    <DatepickerContainerText>{t('efficiency.claimsFrom')}</DatepickerContainerText>
                    <DatepickerContainerValue>
                        <Datepicker
                            name="from"
                            onChange={value =>
                                value &&
                                efficiencyEvents.setEfficiencyDateFilter({
                                    from: new Date(value.getTime()),
                                    to: addWeeks(value, 1)
                                })
                            }
                            value={metricsFilters.from}
                        />
                    </DatepickerContainerValue>
                </DatepickerContainer>
                <InfoWrapper>
                    <InfoItem>
                        <Text fontSize="44px">{metrics.totalTodo !== -1 ? metrics.totalTodo : '--'}</Text>
                        <ValueDescription>{t('efficiency.todo')}</ValueDescription>
                    </InfoItem>
                    <ClaimsOverdueWrapper>
                        <ClaimsOverdue
                            onClick={() => efficiencyEvents.toggleSelectedClaimOverdue()}
                            selected={selected.claimsOverdue}
                        >
                            <Text color={theme.colors.red['500']} fontSize="36px">
                                {countClaimsOverdue !== -1 ? countClaimsOverdue : '--'}
                            </Text>
                            <ValueDescription>{t('efficiency.claimsOverdue')}</ValueDescription>
                        </ClaimsOverdue>
                    </ClaimsOverdueWrapper>
                    {isLaptopSmall && (
                        <IconArrowWrapper opened={isMore} onClick={() => toggleMore()}>
                            <ArrowDownIcon />
                        </IconArrowWrapper>
                    )}
                </InfoWrapper>
            </InfoContainer>
            <BarsContainer displayed={!isLaptopSmall || isMore}>
                {isLaptopSmall && (
                    <ArrowWrapper onClick={onClickPrevious}>
                        <ArrowLong />
                    </ArrowWrapper>
                )}
                <Bars>
                    {!!perDays &&
                        Object.keys(perDays)
                            .sort((a, b) => Number(a) - Number(b))
                            .map(k => {
                                const key = Number(k);
                                const { due, completed } = perDays[key];
                                return (
                                    <Bar
                                        key={k}
                                        due={due}
                                        completed={completed}
                                        time={new Date(key)}
                                        max={metrics.max}
                                    />
                                );
                            })}
                </Bars>
                {isLaptopSmall && (
                    <ArrowWrapper onClick={onClickNext}>
                        <ArrowRight />
                    </ArrowWrapper>
                )}
            </BarsContainer>
            {!isLaptopSmall && (
                <ArrowsContainer>
                    <ArrowWrapper onClick={onClickPrevious}>
                        <ArrowLong />
                    </ArrowWrapper>
                    <ArrowWrapper isTransformed={true} onClick={onClickNext}>
                        <ArrowLong />
                    </ArrowWrapper>
                </ArrowsContainer>
            )}
        </DetailsContainer>
    );
};
