import { API } from '@dat/api';
import { domain, pluginStores } from './plugin';
import { effectorLogger } from '@dat/core/utils';

import { KeyValueOption } from '@wedat/ui-kit/components/Select';

//
/*** Paint types ***/
//
const getPaintTypesFx = domain.createEffect({
    handler: async (data: DAT.GetLacquerTypeKeysRequest) => {
        const paintMethod = pluginStores.settings.getState().paintMethod;

        if (!paintMethod) {
            return [];
        }

        try {
            const response = await API.vehicleRepair.getLacquerTypeKeys({ ...data, paintMethod });

            return response
                .getFieldArray('lacquerType')
                .map(paintType => {
                    const key = paintType.getSingleField('key')._innerText;
                    const value = paintType.getSingleField('description')._innerText;

                    return key && value
                        ? {
                              key,
                              value
                          }
                        : undefined;
                })
                .filter(paintType => !!paintType) as KeyValueOption[];
        } catch {
            return [];
        }
    }
});
const resetPaintTypes = domain.createEvent();
const paintTypes = domain
    .createStore<KeyValueOption[]>([])
    .on(getPaintTypesFx.doneData, (_, data) => data)
    .reset(resetPaintTypes);

//
/*** Export ***/
//
export const vehicleRepairEvents = {
    resetPaintTypes
};
export const vehicleRepairEffects = {
    getPaintTypesFx
};
export const vehicleRepairStores = {
    paintTypes
};

//
/*** Logger ***/
//
if (process.env.NODE_ENV === 'development') {
    effectorLogger(vehicleRepairEvents);
    effectorLogger(vehicleRepairEffects);
    effectorLogger(vehicleRepairStores);
}
