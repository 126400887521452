interface Param<T extends Record<string, any>> {
    object: T;
    origin: string;
}

export const pickPropertiesWithOrigin = <T extends Record<string, unknown>>({
    object,
    origin
}: Param<T>): Partial<T> => {
    const result: Partial<T> = {};

    for (const key in object) {
        const value = object[key];

        if (isValueWithOrigin(value) && value.origin === origin) {
            result[key] = value;
        }
    }

    return result;
};

const isValueWithOrigin = (value: any): value is DAT2.Field.AttrOrigin => value.hasOwnProperty('origin');
