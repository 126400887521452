// Formik fields names are the same to properties names of API requests
// e.g. vehicleType, manufacturer, baseModel etc.
// And some responses has similar properties, but with the first capital letter
// e.g. VehicleType, Manufacturer, BaseModel etc.
// Purpose of this function is to ease the task of converting
// response objects to Formik values objects. 🍰
import { PlainObject } from '../types/common';

type ReturnObject<K extends keyof PlainObject> = {
    [L in K]: any;
};

const setStringFirstLetterToLowerCase = (string: string) => string[0].toLowerCase() + string.slice(1);

export const setObjectKeysFirstLetterToLowerCase = <K extends keyof PlainObject>(obj: PlainObject): ReturnObject<K> => {
    const newObj: ReturnObject<K> = {} as ReturnObject<K>;

    Object.keys(obj).forEach(key => {
        const newKey = setStringFirstLetterToLowerCase(String(key));

        newObj[newKey as K] = obj[key];
    });

    return newObj;
};
