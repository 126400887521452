export const CONTRACT_ENTRIES_KEYS = {
    MEMO: {
        labourRates: 'jsonLabourRates',
        vinResult: 'VINResult',
        AIResult: 'AIJsonResponse',
        chat: 'chat',
        mileageOdometer: 'mileageOdometer',
        vehicleFirstRegistration: 'vehicleFirstRegistration',

        OCR: {
            vehicleModelOCR: 'vehicleModelOCR',
            vehicleColorOCR: 'vehicleColorOCR',
            vehicleMakerOCR: 'vehicleMakerOCR',
            predictedPlateOCR: 'predictedPlateOCR',
            OCRPlateScanDate: 'OCRPlateScanDate',
            OCRVINScanDate: 'OCRVINScanDate'
        },

        ADMIN: {
            profiles: 'profiles'
        }
    }
};
