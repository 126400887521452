import { sample } from 'effector';
import { sharedVehiclesImagesEffects, sharedVehiclesImagesEvents } from './stores';

const { addVehicleImages } = sharedVehiclesImagesEvents;
const { getVehicleImagesFx } = sharedVehiclesImagesEffects;

// TODO: useless code, does nothing
sample({
    source: getVehicleImagesFx.done,
    fn: ({ params: { datECode, aspect }, result }) => ({ datECode, aspect, images: result }),
    target: addVehicleImages
});
