import { useEffect } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';

import { FormikValuationValues, INITIAL_FORM_VALUATION_VALUES } from './constants';

import { Container, Form } from './styles';

import { formEvents } from '../../stores/form';
import { vehicleEffects } from '../../stores/vehicle';
import { useStore } from 'effector-react';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { FormValuation } from './FormValuation';

export const ModalValuation = () => {
    const datECode = useStore(sharedVehicleStores.datECode);

    const handleSubmit = (values: FormikValuationValues) => {
        formEvents.valuationFormSubmitted(values);
    };

    // Get construction periods when datECode is compiled
    useEffect(() => {
        if (datECode) {
            vehicleEffects.getConstructionPeriodsFx({ datECode });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Formik initialValues={INITIAL_FORM_VALUATION_VALUES} onSubmit={handleSubmit}>
                <Form>
                    <FormValuation />
                </Form>
            </Formik>
        </Container>
    );
};
