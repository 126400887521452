import { FC, useState, useEffect, Dispatch, useCallback, useMemo, ChangeEvent } from 'react';
import { GoogleMap, DirectionsRenderer, Marker, DirectionsService } from '@react-google-maps/api';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import {
    PartnerCard,
    PartnerName,
    Title,
    PartnerInfo,
    PartnerInfoContainer,
    ButtonsWrapper,
    StyledButton,
    StyledResetButton,
    ConfirmButtonsContainer,
    NoPartnersWrapper,
    Drawer,
    MapContent,
    PartnersContent,
    WrapperContent,
    PartnerRoleWrapper,
    UpperPart,
    BackgroundWrapper,
    InputWrapper,
    SearchStyled,
    IconWrapper,
    RightPart
} from './styles';

import { Coords } from '../../types/plugin';
import { useMap } from '../../hooks/useMap';
import { useMedia } from '@dat/core/hooks/useMedia';
import { contractStores } from '@dat/shared-models/contract';
import { partnersEvents } from '../../stores/partners';

import { carCrashIcon, marker, ArrowBack, markerLarge } from '@wedat/ui-kit/components/Icons';
import { ModalProps } from '@wedat/ui-kit/components/Modal';
import { Close } from '@wedat/ui-kit/assets/Icon';
import { Text } from '@wedat/ui-kit/components/Text';
import { Input } from '@wedat/ui-kit/components/Input';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { useTheme } from 'styled-components';
import * as R from 'ramda';

export interface Props extends ModalProps {
    partners: DAT2.Internal.InternalPartner[];
    activePartnerIndex: number;
    activeRole: DAT2.CustomerRole;
    claimPosition: Coords;
    setActivePartner: Dispatch<number>;
}

export const PartnersModal: FC<Props> = ({
    isOpen,
    onDismiss,
    partners,
    claimPosition,
    activePartnerIndex,
    activeRole,
    setActivePartner
}) => {
    const { t } = useTranslation();

    const [selectedPartner, setSelectedPartner] = useState<DAT2.Internal.InternalPartner | null>();
    const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(-1);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [filteredPartners, setFilteredPartners] = useState<DAT2.Internal.InternalPartner[]>([]);

    const isLaptopSmall = useMedia(sizes.laptopSmall);
    const isPhone = useMedia(sizes.phone);
    const isTablet = useMedia(sizes.tablet);
    const theme = useTheme();

    const {
        isLoaded,
        direction,
        center,
        directionResult,
        onLoad,
        onUnmount,
        setDirection,
        setCenter,
        setDirectionResult,
        onDirectionCallback
    } = useMap();

    const contractId = useStore(contractStores.contractId);
    const contract = useStore(contractStores.contract);

    const handleSelectPartner = useCallback(
        (partner: DAT2.Internal.InternalPartner, index: number) => {
            const position = {
                lat: partner.latitude || 0,
                lng: partner.longitude || 0
            };
            setSelectedPartnerIndex(index);
            setSelectedPartner(partner);
            if (position.lat === 0) {
                setDirection(null);
                setDirectionResult(undefined);
            }

            if (position.lat !== 0) {
                setCenter(position);
                setDirection(position);
            }
        },
        [setSelectedPartner, setSelectedPartnerIndex, setCenter, setDirection, setDirectionResult]
    );

    const handleAssignPartner = () => {
        partnersEvents.assignPartnerToContract({
            contractId,
            rolePartner: activeRole,
            partnerId: selectedPartner ? selectedPartner.id : 0
        });
        setSelectedPartner(null);
        setSelectedPartnerIndex(-1);
        onDismiss();
    };

    const handleUnAssignPartner = () => {
        partnersEvents.unAssignPartnerToContract({
            contractId,
            rolePartner: activeRole
        });
        setActivePartner(-1);
    };

    useEffect(() => {
        setSelectedPartnerIndex(-1);
    }, [isOpen, contract, activeRole, contractId]);

    const containerStyle = {
        width: '100%',
        height: isTablet ? '440px' : '100%'
    };

    const handleChangeSearchInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchInputValue(e?.target?.value);
            setFilteredPartners(
                partners.filter(partner =>
                    Object.values(R.omit(['_id'], partner)).some(value =>
                        String(value).toUpperCase().includes(e?.target?.value.toUpperCase())
                    )
                )
            );
        },
        [partners]
    );

    const hasPosition = claimPosition.lat !== 0;

    const DirectioServiceMemo = useMemo(() => {
        if (selectedPartnerIndex && direction) {
            return (
                <DirectionsService
                    options={{
                        destination: claimPosition,
                        origin: direction,
                        drivingOptions: {
                            departureTime: new Date()
                        },
                        travelMode: google.maps.TravelMode.DRIVING
                    }}
                    callback={onDirectionCallback}
                />
            );
        }
        return null;
    }, [direction, claimPosition, selectedPartnerIndex, onDirectionCallback]);

    const DirectionRendered = useMemo(() => {
        if (directionResult && selectedPartnerIndex) {
            return (
                <DirectionsRenderer
                    options={{
                        directions: directionResult
                    }}
                />
            );
        }
        return null;
    }, [directionResult, selectedPartnerIndex]);

    const isShowNoPartnersWrapper = useMemo(
        () => (filteredPartners.length === 0 && searchInputValue.length > 0) || partners.length === 0,
        [filteredPartners.length, partners.length, searchInputValue.length]
    );

    return (
        <>
            {isOpen && (
                <BackgroundWrapper>
                    <PartnerRoleWrapper>
                        <UpperPart>
                            {isPhone && (
                                <IconWrapper onClick={onDismiss}>
                                    <ArrowBack />
                                </IconWrapper>
                            )}
                            <Title asTag="h3">{t('choosePartner')}</Title>
                            <RightPart>
                                {isLaptopSmall && !isPhone && (
                                    <InputWrapper>
                                        <Input
                                            placeholder={t('EnterAName')}
                                            onChange={e => handleChangeSearchInput(e)}
                                            value={searchInputValue}
                                        />
                                        <SearchStyled />
                                    </InputWrapper>
                                )}
                                {!isPhone && <Close onClick={onDismiss} color={theme.colors.black} />}
                            </RightPart>
                        </UpperPart>
                        <WrapperContent>
                            <PartnersContent>
                                <Drawer isEmpty={!!partners.length}>
                                    {(searchInputValue.length > 0 ? filteredPartners : partners).map(
                                        (partner, index) => {
                                            const isPartnerSelected = selectedPartnerIndex === index;
                                            return (
                                                <PartnerCard
                                                    key={index}
                                                    isAssigned={activePartnerIndex === index}
                                                    isSelected={isPartnerSelected}
                                                    withNoMargin={partners.length <= 2}
                                                    isSingle={partners.length === 1}
                                                    onClick={() => {
                                                        handleSelectPartner(partner, index);
                                                    }}
                                                >
                                                    <PartnerName isSelected={isPartnerSelected}>
                                                        {partner.name}
                                                    </PartnerName>
                                                    <PartnerInfo isSelected={isPartnerSelected}>
                                                        {partner.city}, {partner.street}
                                                        {partner.currentDistance &&
                                                            hasPosition &&
                                                            `(${partner.currentDistance?.toFixed(2)} km)`}
                                                    </PartnerInfo>
                                                    {hasPosition && partner.currentDistance && (
                                                        <PartnerInfo isSelected={isPartnerSelected}>
                                                            Expected arrival{' '}
                                                            {directionResult?.routes[0]?.legs[0]?.duration?.text}
                                                        </PartnerInfo>
                                                    )}
                                                    <PartnerInfoContainer>
                                                        <PartnerInfo isSelected={isPartnerSelected}>
                                                            {t('postCode')}
                                                        </PartnerInfo>
                                                        <Text fontSize="16px">{partner.zip}</Text>
                                                    </PartnerInfoContainer>
                                                </PartnerCard>
                                            );
                                        }
                                    )}
                                    {isShowNoPartnersWrapper && (
                                        <NoPartnersWrapper>
                                            <Title asTag="h2">{t('noPartners')}</Title>
                                        </NoPartnersWrapper>
                                    )}
                                </Drawer>
                            </PartnersContent>
                            {isPhone && (
                                <InputWrapper>
                                    <Input
                                        placeholder={t('EnterAName')}
                                        onChange={e => handleChangeSearchInput(e)}
                                        value={searchInputValue}
                                    />
                                    <SearchStyled />
                                </InputWrapper>
                            )}

                            <MapContent>
                                {isLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={12}
                                        options={{
                                            zoomControl: false,
                                            mapTypeControl: false,
                                            scaleControl: false,
                                            streetViewControl: false,
                                            rotateControl: false,
                                            fullscreenControl: false
                                        }}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                    >
                                        {DirectioServiceMemo}
                                        {DirectionRendered}
                                        <Marker onLoad={onLoad} position={claimPosition} icon={carCrashIcon} />
                                        {!!partners.length
                                            ? partners.map((partner, index) => (
                                                  <Marker
                                                      key={`${partner.id}-${index}`}
                                                      onLoad={onLoad}
                                                      onClick={() => {
                                                          handleSelectPartner(partner, index);
                                                      }}
                                                      position={{
                                                          lat: partner.latitude || 0,
                                                          lng: partner.longitude || 0
                                                      }}
                                                      icon={selectedPartner?.id === partner.id ? markerLarge : marker}
                                                  />
                                              ))
                                            : null}
                                    </GoogleMap>
                                )}
                            </MapContent>
                        </WrapperContent>

                        {!!partners.length && (
                            <ButtonsWrapper>
                                <StyledResetButton disabled={activePartnerIndex < 0} onClick={handleUnAssignPartner}>
                                    {t('reset')}
                                </StyledResetButton>
                                <ConfirmButtonsContainer>
                                    <StyledButton disabled={!selectedPartner} onClick={handleAssignPartner}>
                                        {t('ok')}
                                    </StyledButton>
                                </ConfirmButtonsContainer>
                            </ButtonsWrapper>
                        )}
                    </PartnerRoleWrapper>
                </BackgroundWrapper>
            )}
        </>
    );
};
