import React from 'react';

const CalendarChecked = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 216.374 216.374" {...props}>
        <path
            d="M207.374 30.135h-25.438V13.432c0-4.971-4.029-9-9-9s-9 4.029-9 9v16.703H52.438V13.432c0-4.971-4.029-9-9-9s-9 4.029-9 9v16.703H9a9 9 0 0 0-9 9v163.808a9 9 0 0 0 9 9h198.374a9 9 0 0 0 9-9V39.135a9 9 0 0 0-9-9zm-9 18v19.997H18V48.135h180.374zM18 193.942V86.132h180.374v107.811H18z"
            fill="currentColor"
        />
        <path
            d="M140.288 102.718 89.82 153.186l-13.734-13.734a9.001 9.001 0 0 0-12.728 12.729l20.098 20.098c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636l56.832-56.831a9 9 0 0 0-12.728-12.73z"
            fill="currentColor"
        />
    </svg>
);

export default CalendarChecked;
