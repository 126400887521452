import React, { FC } from 'react';

import { BurgerIcon, CloseIcon } from '@wedat/ui-kit/components/Icons';
import { SlideUpMenu } from '@wedat/ui-kit/components/SlideUpMenu';
import { Text } from '@wedat/ui-kit/components/Text';

import {
    Container,
    FooterMobileStyled,
    FooterMenu,
    ScrollableMenu,
    Icon,
    Item,
    ItemLink,
    MenuContainer,
    MenuIcon,
    MenuItem,
    MenuItemLink
} from './styles';

export interface MenuProps {
    icon?: React.ReactNode;
    link?: string;
    onClick?: () => void;
    disabled?: boolean;
    component?: React.FC;
    label?: string;
}

export interface BurgerMenuProps {
    icon?: React.ReactNode;
    link?: string;
    text?: string;
    disabled?: boolean;
    onClick?: () => void;
    component?: React.FC;
}

interface Props {
    menu: MenuProps[];
    burgerMenu?: BurgerMenuProps[];
    isOpenBurgerMenu: boolean;
    toggleOpenBurgerMenu: () => void;
    setOpenBurgerMenu: (value: boolean) => void;
    menuFillWidth?: boolean;
    high?: boolean;
    disableBurgerMenu?: boolean;
    isFooterWithLabels?: boolean;
}

export const FooterMobile: FC<Props> = ({
    menu,
    burgerMenu,
    isOpenBurgerMenu,
    toggleOpenBurgerMenu,
    setOpenBurgerMenu,
    menuFillWidth,
    high,
    disableBurgerMenu = false,
    isFooterWithLabels
}) => (
    <FooterMobileStyled high={high}>
        <Container>
            <ScrollableMenu disableBurgerMenu={disableBurgerMenu} scroll={menu.length > 5}>
                {menu.map((item, key) => {
                    if (item.component) {
                        const Component = item.component;
                        return (
                            <Item
                                onClick={() => {
                                    setOpenBurgerMenu(false);
                                    item.onClick?.();
                                }}
                                menuFillWidth={menuFillWidth}
                                key={key}
                            >
                                <Component />
                                <Text fontSize="11px">{item.label}</Text>
                            </Item>
                        );
                    }

                    if (item.link) {
                        return (
                            <ItemLink
                                to={item.link}
                                key={key}
                                disabled={item.disabled}
                                onClick={() => {
                                    setOpenBurgerMenu(false);
                                    item.onClick?.();
                                }}
                            >
                                <Icon>{item.icon}</Icon>
                                <Text fontSize="11px" lineHeight="18px">
                                    {item.label}
                                </Text>
                            </ItemLink>
                        );
                    }

                    return (
                        <Item
                            onClick={() => {
                                setOpenBurgerMenu(false);
                                item.onClick?.();
                            }}
                            key={key}
                            disabled={item.disabled}
                        >
                            <Icon>{item.icon}</Icon>
                            <Text fontSize="11px" lineHeight="18px">
                                {item.label}
                            </Text>
                        </Item>
                    );
                })}
            </ScrollableMenu>
            {disableBurgerMenu ? null : (
                <FooterMenu>
                    {burgerMenu && (
                        <Item onClick={toggleOpenBurgerMenu}>
                            <Icon>{isOpenBurgerMenu ? <CloseIcon /> : <BurgerIcon />}</Icon>
                            {isFooterWithLabels && (
                                <Text fontSize="11px" lineHeight="18px">
                                    Menu
                                </Text>
                            )}
                        </Item>
                    )}
                </FooterMenu>
            )}
        </Container>

        {burgerMenu && (
            <SlideUpMenu isOpen={isOpenBurgerMenu} closeSlideUpMenu={toggleOpenBurgerMenu} isBurger>
                <MenuContainer>
                    {burgerMenu.map((item, key) => {
                        if (item.component) {
                            const Component = item.component;
                            return (
                                <Item
                                    onClick={() => {
                                        setOpenBurgerMenu(false);
                                        item.onClick?.();
                                    }}
                                    key={key}
                                >
                                    <Component />
                                </Item>
                            );
                        }

                        if (item.link) {
                            return (
                                <MenuItemLink
                                    to={item.link}
                                    key={key}
                                    onClick={() => {
                                        setOpenBurgerMenu(false);
                                        item.onClick?.();
                                    }}
                                    disabled={item.disabled}
                                >
                                    <MenuIcon>{item.icon}</MenuIcon>
                                    <Text fontSize="16px">{item.text}</Text>
                                </MenuItemLink>
                            );
                        }

                        return (
                            <MenuItem
                                onClick={() => {
                                    setOpenBurgerMenu(false);
                                    item.onClick?.();
                                }}
                                key={key}
                                disabled={item.disabled}
                            >
                                <MenuIcon>{item.icon}</MenuIcon>
                                <Text fontSize="16px">{item.text}</Text>
                            </MenuItem>
                        );
                    })}
                </MenuContainer>
            </SlideUpMenu>
        )}
    </FooterMobileStyled>
);
