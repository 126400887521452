import React, { useCallback, useContext, useState } from 'react';
import { CommentPhoto } from '../PhotoStepsPage/CommentPhoto';
import { Photo } from '../PhotoStepsPage/Photo';
import { actions, FormContext } from '../../reducers/form';
import { Layout } from '../Layout';
import { PluginProps } from '../../types';

const { setAdditionalStep } = actions;

enum AdditionalSteps {
    MakePhoto,
    CommentPhoto
}

interface Props {
    additionalPhotoOrientation?: PluginProps['additionalPhotoForceLandscape'];
}

export const AdditionalPhotoStep: React.FC<Props> = ({ additionalPhotoOrientation }) => {
    const [photo, setPhoto] = useState('');
    const [photoStep, setPhotoStep] = useState<AdditionalSteps>(AdditionalSteps.MakePhoto);
    const { dispatch } = useContext(FormContext);

    const handlePhoto = useCallback(
        (newPhoto: string) => {
            setPhotoStep(AdditionalSteps.CommentPhoto);

            setPhoto(newPhoto);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [photo]
    );

    const handleRetake = useCallback(() => {
        setPhotoStep(AdditionalSteps.MakePhoto);
    }, []);

    const handleSave = useCallback(() => {
        dispatch(setAdditionalStep(photo));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photo]);

    return (
        <Layout forceLandscape={additionalPhotoOrientation}>
            {photoStep === AdditionalSteps.MakePhoto ? (
                <Photo embedGeo={false} showComment={false} onPhoto={handlePhoto} />
            ) : (
                <CommentPhoto img={photo} showComment={false} onRetake={handleRetake} onSave={handleSave} />
            )}
        </Layout>
    );
};
