import { EquipmentPositionObject } from '../types';
import { ParsedEquipment } from '@dat/equipment/src/types/equipment';

export const prepareEquipmentForContract = (equipments: EquipmentPositionObject[]): ParsedEquipment =>
    equipments.map(item => ({
        DatEquipmentId: +item.id,
        Description: item.description,
        EquipmentGroup: item.equipmentGroup,
        Category: item.category,
        ...(item.children?.length && {
            ContainedEquipmentPositions: {
                EquipmentPosition: item.children?.length ? prepareEquipmentForContract(item.children) : []
            }
        })
    }));
