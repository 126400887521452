import { createStore, guard } from 'effector';
import { ParsedVXSVehicleObject } from '@dat/core/types';
import { sharedDossierStores } from '../index';
import { checkIsVehicleIdentified } from './utils/checkIsVehicleIdentified';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { createDefaultRestrictionHandler as cDRH } from '@dat/shared-models/configuration/utils/createDefaultRestrictionHandler';
import { API } from '@dat/api';
import { parseVXSResponse } from '@dat/core/utils';

const vehicle = sharedDossierStores.dossier.map(dossier => dossier?.Vehicle || null);
const datECode = vehicle.map(vehicle => vehicle?.DatECode);
const isVehicleIdentified = vehicle.map(checkIsVehicleIdentified);
const newVehicleReceived = guard({
    clock: datECode,
    source: vehicle,
    filter: checkIsVehicleIdentified
});

//
/*** Identify vehicle by VIN ***/
//
const getVehicleIdentificationByVinFx = createNotifyingEffect({
    handler: cDRH(async (data: DAT.GetVehicleIdentificationByVinRequest) => {
        const response = await API.vehicleIdentification.getVehicleIdentificationByVin({
            ...data,
            // use only for valuation-finance, don't use anything this. For valuation we need restriction ALL
            restriction: 'ALL'
        });
        const vehicleObjects = parseVXSResponse(response);

        return vehicleObjects[0];
    })
});

const vinResult = createStore<ParsedVXSVehicleObject | null>(null);

export const sharedVehicleEvents = {
    newVehicleReceived
};
export const sharedVehicleStores = {
    vehicle,
    datECode,
    isVehicleIdentified,
    vinResult
};

export const sharedVehicleEffects = {
    getVehicleIdentificationByVinFx
};
