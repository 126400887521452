import React, { FC, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useStore } from 'effector-react';

import { collisionDamageSelectOptions, numberOfOwnersSelectOptions } from './constants';
import { dossierStores } from '../../../stores/dossier';
import { useDealerShipSalesValue } from './hooks';

import {
    VehicleConditionStyled,
    NumberOfOwnersNWrapper,
    Col1Wrapper,
    DatNumberRenderText,
    DatNumberRenderTextSign,
    Col1WrapperRight,
    Col4Wrapper
} from './styles';
import { DatNumberDisplayField } from '../../DatNumberDisplayField';
import { EvaluationSelectField } from '../../evaluationFields/Select';
import { EvaluationCheckboxField } from '../../evaluationFields/Checkbox';
import { EvaluationNumberInputField } from '../../evaluationFields/Input';
import { Table } from '@wedat/ui-kit/components/Table';
import { EvaluationNumberWithSign } from './EvaluationNumberWithSign';

export const VehicleCondition: FC = () => {
    const { t } = useTranslation();

    const dossierWithExtractedTextValues = useStore(dossierStores.dossierWithExtractedTextValues);

    const ConditionCorrectionFactorPerc =
        dossierWithExtractedTextValues.Valuation?.Condition?.ConditionCorrectionFactorPerc || 100;

    const dealerShipSalesValue = useDealerShipSalesValue();

    const collisionDamageSelectOptionsValues = collisionDamageSelectOptions(t);
    const numberOfOwnersSelectOptionsValues = numberOfOwnersSelectOptions(t);

    const columns = useMemo(
        () => [
            {
                Header: t('Description'),
                accessor: 'col1'
            },
            {
                Header: t('DAT'),
                accessor: 'col3'
            },
            {
                Header: t('Manually'),
                accessor: 'col4'
            }
        ],
        [t]
    );

    const data = useMemo(
        () => [
            {
                col1: t('vehicleCondition.Base value'),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField propertyPath="Valuation.BasePrice3" />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Collision damage'),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <EvaluationSelectField
                            name="Valuation.Condition.AccidentDamage"
                            options={collisionDamageSelectOptionsValues}
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Value increase'),
                col3: <DatNumberDisplayField onlyWithDatPrefix propertyPath="Valuation.Condition.IncreaseInValue" />,
                col4: (
                    <Col4Wrapper>
                        <EvaluationNumberWithSign
                            withPrefix
                            prefix="+"
                            min={0}
                            name="Valuation.Condition.IncreaseInValue"
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Value reduction'),
                col3: <DatNumberDisplayField onlyWithDatPrefix propertyPath="Valuation.Condition.DecreaseInValue" />,
                col4: (
                    <Col4Wrapper>
                        <EvaluationNumberWithSign
                            withPrefix
                            prefix="-"
                            min={0}
                            name="Valuation.Condition.DecreaseInValue"
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: (
                    <Trans
                        i18nKey="vehicleCondition.Surcharge/discount for the tires mounted"
                        components={[<DatNumberDisplayField propertyPath="Valuation.DefaultTiresPrice" />]}
                    />
                ),
                col3: <DatNumberDisplayField onlyWithDatPrefix propertyPath="Valuation.Condition.TiresMountedValue" />,
                col4: (
                    <Col4Wrapper>
                        <EvaluationNumberWithSign withPrefix prefix="+" name="Valuation.Condition.TiresMountedValue" />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Residual value of unmounted tires'),
                col3: (
                    <DatNumberDisplayField onlyWithDatPrefix propertyPath="Valuation.Condition.TiresUnmountedValue" />
                ),
                col4: (
                    <Col4Wrapper>
                        <EvaluationNumberWithSign
                            withPrefix
                            prefix="+"
                            name="Valuation.Condition.TiresUnmountedValue"
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Subtotal I'),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField propertyPath="Valuation.Condition.ConditionSubTotal1" />
                    </Col4Wrapper>
                )
            },
            {
                col1: (
                    <Col1Wrapper>
                        <span>{t('vehicleCondition.Number of vehicle owners')}</span>
                        <NumberOfOwnersNWrapper>
                            <EvaluationSelectField
                                name="Valuation.Condition.NumberOfOwnersN"
                                options={numberOfOwnersSelectOptionsValues}
                            />
                        </NumberOfOwnersNWrapper>
                        <Col1WrapperRight>
                            <DatNumberDisplayField
                                fixedDecimalScale={false}
                                propertyPath="Valuation.Condition.OwnerCorrectionPerc"
                            />
                            &nbsp;&nbsp;
                            <EvaluationNumberInputField
                                fixedDecimalScale={false}
                                name="Valuation.Condition.OwnerCorrectionPerc"
                            />
                        </Col1WrapperRight>
                    </Col1Wrapper>
                ),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField
                            propertyPath="Valuation.Condition.OwnerCorrectionAmount"
                            allowNegative={false}
                            renderText={value => (
                                <DatNumberRenderText>
                                    <DatNumberRenderTextSign>-</DatNumberRenderTextSign>
                                    {value}
                                </DatNumberRenderText>
                            )}
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: (
                    <Col1Wrapper>
                        <span>{t('vehicleCondition.Adaptation factor (%)')}</span>
                        <Col1WrapperRight>
                            <DatNumberDisplayField
                                fixedDecimalScale={false}
                                propertyPath="Valuation.Condition.ConditionCorrectionFactorPerc"
                            />
                            &nbsp;&nbsp;
                            <EvaluationNumberInputField
                                fixedDecimalScale={false}
                                name="Valuation.Condition.ConditionCorrectionFactorPerc"
                            />
                        </Col1WrapperRight>
                    </Col1Wrapper>
                ),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField
                            propertyPath="Valuation.Condition.ConditionCorrectionAmount"
                            allowNegative={false}
                            renderText={value => (
                                <DatNumberRenderText>
                                    <DatNumberRenderTextSign>
                                        {ConditionCorrectionFactorPerc >= 100 ? '+' : '-'}
                                    </DatNumberRenderTextSign>
                                    {value}
                                </DatNumberRenderText>
                            )}
                        />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Subtotal II'),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField propertyPath="Valuation.Condition.ConditionSubTotal2" />
                    </Col4Wrapper>
                )
            },
            {
                col1: (
                    <Col1Wrapper>
                        <span>{t('vehicleCondition.Repair costs')}</span>
                        <Col1WrapperRight>
                            <EvaluationCheckboxField
                                name="Valuation.Condition.RepairCostsInTradeMargin"
                                label={t('vehicleCondition.Add to trade margin')}
                            />
                        </Col1WrapperRight>
                    </Col1Wrapper>
                ),
                col3: (
                    <DatNumberDisplayField
                        defaultValue={0}
                        onlyWithDatPrefix
                        propertyPath="Valuation.Condition.RepairCosts"
                    />
                ),
                col4: (
                    <Col4Wrapper>
                        <EvaluationNumberWithSign withPrefix prefix="-" name="Valuation.Condition.RepairCosts" />
                    </Col4Wrapper>
                )
            },
            {
                col1: t('vehicleCondition.Dealership sales value'),
                col3: '',
                col4: (
                    <Col4Wrapper>
                        <DatNumberDisplayField value={dealerShipSalesValue < 0 ? 0 : dealerShipSalesValue} />
                    </Col4Wrapper>
                )
            }
        ],
        [
            ConditionCorrectionFactorPerc,
            collisionDamageSelectOptionsValues,
            dealerShipSalesValue,
            numberOfOwnersSelectOptionsValues,
            t
        ]
    );

    return (
        <>
            <VehicleConditionStyled>
                <Table
                    columns={columns}
                    data={data}
                    breakpoint="laptop"
                    mobileGridColAmount={1}
                    lastItemNotTextAlignEnd
                />
            </VehicleConditionStyled>
        </>
    );
};
