import React from 'react';
import { Formik } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { sharedUserEffects } from '@dat/shared-models/user';

import { DEFAULT_USER_PROFILE, validationSchema } from '../../constants';
import { PictureRow } from '../PictureRow';
import { Form } from '../form';
import { Column } from './styles';
import { dataStores } from '../../stores/data';

export const PictureAndForm = () => {
    const { t } = useTranslation();
    const formUserProfile = useStore(dataStores.formUserProfile);

    // Add translate here, because inside validationSchema we have an error i18next.t undefined
    const translate = {
        name: t('auth:profile.form.error.name'),
        surname: t('auth:profile.form.error.surname'),
        email: t('auth:profile.form.error.email'),
        telephone: t('auth:profile.form.error.telephone')
    };

    return (
        <Formik
            initialValues={formUserProfile || DEFAULT_USER_PROFILE}
            onSubmit={sharedUserEffects.updateUserProfileFx}
            validationSchema={validationSchema(translate)}
            enableReinitialize
        >
            <Column>
                <PictureRow />
                <Form />
            </Column>
        </Formik>
    );
};
