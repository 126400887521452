import { createEvent, createStore } from 'effector';
import { INITIAL_CUSTOMER_SETTINGS, INITIAL_TEMPLATE_SETTINGS } from '@dat/shared-models/configuration/constants';
import { INITIAL_LOCALE } from '@dat/shared-models/i18n/constants';

const apiUrl = createStore(INITIAL_CUSTOMER_SETTINGS.apiUrl);
const bffUrl = createStore(INITIAL_CUSTOMER_SETTINGS.bffUrl);
const aniaUrl = createStore(INITIAL_CUSTOMER_SETTINGS.aniaUrl);
const prUrl = createStore(INITIAL_CUSTOMER_SETTINGS.prUrl);
const country = createStore(INITIAL_TEMPLATE_SETTINGS.country);
const locale = createStore(INITIAL_LOCALE);

const tokenExpired = createEvent();

export const events = {
    tokenExpired
};
export const stores = {
    apiUrl,
    bffUrl,
    aniaUrl,
    prUrl,
    country,
    locale
};
