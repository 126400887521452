import { restore, attach, createDomain } from 'effector';
import { PluginOptions } from '../../types/plugin';

export const domain = createDomain();
const resetPlugin = domain.createEvent();

//
/*** Init plugin ***/
//
const initPlugin = domain.createEvent<PluginOptions>();
const pluginOptions = restore(initPlugin, null);

const callOnCompleteFx = attach({
    source: pluginOptions,
    effect: pluginOptions => pluginOptions?.onComplete?.()
});

//
/*** Export ***/
//
export const pluginEffects = {
    callOnCompleteFx
};

export const pluginEvents = {
    initPlugin,
    resetPlugin
};

export const pluginStores = {
    pluginOptions
};
