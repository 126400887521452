import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderStyled, SelectWrap, FormStyled, SelectContainer, TitleContainer, IconWrapper } from './styles';
import { InputField } from '@wedat/ui-kit/Formik';

import { CloseIcon } from '@wedat/ui-kit/components/Icons';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    closeLabourRatesModal?: () => void;
}

export const Header: React.FC<Props> = ({ closeLabourRatesModal }) => {
    const { t } = useTranslation();

    return (
        <HeaderStyled>
            <FormStyled>
                <TitleContainer>
                    <Text font="mobileHeader">{t('common.labourRates')}</Text>
                    <IconWrapper onClick={closeLabourRatesModal}>
                        <CloseIcon />
                    </IconWrapper>
                </TitleContainer>

                <SelectContainer>
                    <SelectWrap>
                        <InputField name="MetadataFactor-name" label={t('fields.MetadataFactor-name')} />
                    </SelectWrap>
                </SelectContainer>
            </FormStyled>
        </HeaderStyled>
    );
};
