import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

interface MobileTableHeadingProps {
    isTitle?: boolean;
    isSelected?: boolean;
}

export const TableWrapper = styled.div`
    padding: 20px;
    padding-bottom: 0;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 7px;

    ${media.phone`
        padding: 0;
        background-color: transparent;
    `}
`;

// TODO: [color]
export const MobileTableHeading = styled.td<MobileTableHeadingProps>`
    &&& {
        display: inline-block;
        color: ${({ isTitle }) => (isTitle ? '#C9CFDC' : 'unset')};
        margin-right: ${({ isTitle }) => (isTitle ? '5px' : '15px')};
        padding: 0;

        ${({ isSelected }) =>
            isSelected &&
            css`
                color: rgba(255, 255, 255, 0.5);
            `}
    }
`;
