import { TFunction } from 'react-i18next';
import { createNumberedSelectOptions } from '../utils/selectsOptions';
import { KeyValueOption } from '@wedat/ui-kit/components/Select';

const biwOptimizationMode = (t: TFunction): KeyValueOption[] => [
    {
        key: 'SUPPRESS',
        label: t('calculations.biwOptimizationModeOptions.suppress'),
        value: t('calculations.biwOptimizationModeOptions.suppress')
    },
    {
        key: 'AUTOMATIC',
        label: t('calculations.biwOptimizationModeOptions.automatic'),
        value: t('calculations.biwOptimizationModeOptions.automatic')
    },
    {
        key: 'FORCE',
        label: t('calculations.biwOptimizationModeOptions.force'),
        value: t('calculations.biwOptimizationModeOptions.force')
    }
];
const addition: KeyValueOption[] = [
    //TODO: translate
    {
        key: 'A',
        label: 'Tone adjustment - mixing unit',
        value: 'Tone adjustment - mixing unit'
    },
    {
        key: 'B',
        label: 'Tone adjustment - sample panel',
        value: 'Tone adjustment - sample panel'
    }
];
const maskingWorkGlassCount: KeyValueOption[] = createNumberedSelectOptions({ from: 1, to: 10 });
const maskingWorkPlasticCount: KeyValueOption[] = createNumberedSelectOptions({ from: 1, to: 10 });
const matBlackWindowFrameCount: KeyValueOption[] = createNumberedSelectOptions({ from: 1, to: 4 });
const colourCount: KeyValueOption[] = createNumberedSelectOptions({ from: 2, to: 9 });

export const selectsOptions = {
    biwOptimizationMode,
    addition,
    maskingWorkGlassCount,
    maskingWorkPlasticCount,
    matBlackWindowFrameCount,
    colourCount
};
