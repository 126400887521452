interface CreateDatECodeParam {
    vehicleType?: string;
    manufacturer?: string;
    baseModel?: string;
    subModel?: string;
}

export const createDatECode = ({ vehicleType, manufacturer, baseModel, subModel }: CreateDatECodeParam) => {
    vehicleType = vehicleType ? vehicleType.padStart(2, '0') : '';
    manufacturer = manufacturer ? manufacturer.padStart(3, '0') : '';
    baseModel = baseModel ? baseModel.padStart(3, '0') : '';
    subModel = subModel ? subModel.padStart(3, '0') : '';

    return vehicleType + manufacturer + baseModel + subModel;
};
