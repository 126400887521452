import styled from 'styled-components/macro';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { PreloaderCircle } from '@wedat/ui-kit/components/PreloaderCircle';

export const Container = styled.div`
    display: flex;
    justify-content: center;

    ${PreloaderCircle} {
        width: 60px;
    }

    @media screen and (max-width: ${sizes.phone}px) {
        margin-top: 20px;

        ${PreloaderCircle} {
            width: 40px;
        }
    }
`;
