import { useEffect, useState } from 'react';
import { getGeolocation } from '../utils/geolocation';

/**
 * Hook to get geolocation invoke once when component mounted
 */
export const useGeolocation = (...deps: any[]) => {
    const [position, setPosition] = useState<GeolocationPosition | null>(null);

    useEffect(() => {
        const getPosition = async () => {
            let position = null;

            try {
                position = await getGeolocation();
            } catch {}

            setPosition(position);
        };

        getPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    return position;
};
