import { FC } from 'react';

// Hooks
import { useOrientation } from '../../../hooks/orientation';

// Types
import { ValidationContent } from '../../../types';
import { Button, Content, Description, HelpModalStyled, Image, Title } from '../HelpModal/styles';
import { RetakeIcon } from '@wedat/ui-kit/components/Icons';

interface Props {
    onRetakeClick: () => void;
    side: string;
    content: ValidationContent;
}

export const ValidationModal: FC<Props> = ({ onRetakeClick, side, content }) => {
    const { isLandscape } = useOrientation();

    return (
        <HelpModalStyled isLandscape={isLandscape}>
            <Image alt="helpImage" src={content.img} />
            <Content>
                {content?.title && <Title>{content?.title}</Title>}
                {content?.description && (
                    <Description>
                        {content?.description} <b>{side}</b>
                    </Description>
                )}
                <Button onClick={onRetakeClick}>
                    <RetakeIcon />
                </Button>
            </Content>
        </HelpModalStyled>
    );
};
