import React from 'react';

const Close = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="16" height="14" viewBox="-1 0 16 14" fill="none" {...props}>
        <path
            d="M8.64462 6.99901L13.6571 1.99813C13.8766 1.77862 14 1.48091 14 1.17048C14 0.860046 13.8766 0.562331 13.6571 0.342824C13.4376 0.123318 13.1399 0 12.8295 0C12.5191 0 12.2213 0.123318 12.0018 0.342824L7.00098 5.35536L2.00011 0.342824C1.78061 0.123318 1.48289 -2.31288e-09 1.17246 0C0.862033 2.31288e-09 0.564319 0.123318 0.344813 0.342824C0.125307 0.562331 0.00198939 0.860046 0.00198939 1.17048C0.00198939 1.48091 0.125307 1.77862 0.344813 1.99813L5.35734 6.99901L0.344813 11.9999C0.235553 12.1083 0.148832 12.2372 0.0896509 12.3792C0.0304697 12.5213 0 12.6736 0 12.8275C0 12.9814 0.0304697 13.1338 0.0896509 13.2758C0.148832 13.4179 0.235553 13.5468 0.344813 13.6552C0.45318 13.7644 0.582108 13.8512 0.72416 13.9103C0.866211 13.9695 1.01858 14 1.17246 14C1.32635 14 1.47871 13.9695 1.62076 13.9103C1.76282 13.8512 1.89174 13.7644 2.00011 13.6552L7.00098 8.64265L12.0018 13.6552C12.1102 13.7644 12.2391 13.8512 12.3812 13.9103C12.5232 13.9695 12.6756 14 12.8295 14C12.9834 14 13.1357 13.9695 13.2778 13.9103C13.4198 13.8512 13.5488 13.7644 13.6571 13.6552C13.7664 13.5468 13.8531 13.4179 13.9123 13.2758C13.9715 13.1338 14.002 12.9814 14.002 12.8275C14.002 12.6736 13.9715 12.5213 13.9123 12.3792C13.8531 12.2372 13.7664 12.1083 13.6571 11.9999L8.64462 6.99901Z"
            fill="currentColor"
        />
    </svg>
);

export default Close;
