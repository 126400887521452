import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import deepmerge from 'deepmerge';

import { factorsEvents } from '../../stores/factors';

import { PartialFactorsParametersObject, FactorsParametersObject } from '../../types/factors';

/*
 * This renderless component is a connector between stores' data and Formik's state.
 * It's purpose is to watch some stores' changes / events' calls and update form if needed.
 */
export const FormikController = () => {
    const { setFormikState } = useFormikContext<FactorsParametersObject>();

    const mergeInFormikValues = useCallback(
        (incomingValues: PartialFactorsParametersObject) => {
            setFormikState(state => ({
                ...state,
                values: deepmerge<FactorsParametersObject, PartialFactorsParametersObject>(state.values, incomingValues)
            }));
        },
        [setFormikState]
    );

    /* Set predefined parameters from invoice-rate object */
    useEffect(
        () =>
            factorsEvents.predefinedFactorsParametersUpdated.watch(predefinedParameters => {
                mergeInFormikValues(predefinedParameters);
            }),
        [mergeInFormikValues]
    );

    return null;
};
