import React from 'react';
import { useStore } from 'effector-react';
import { usersListStores } from '../../../../../stores/usersList';
import { List } from '../List';
import { Arrow } from '@wedat/ui-kit/assets/Icon';
import { BackButton } from '../../styles';
import { useTranslation } from 'react-i18next';
import { ManageUsersContainer } from './styles';

export interface ManageUsersProps {
    selected: string[];
    goBack: () => void;
}

export const ManageUsers: React.FC<ManageUsersProps> = ({ selected, goBack }) => {
    const { t } = useTranslation();
    const availableUsers = useStore(usersListStores.availableUsers);
    return (
        <ManageUsersContainer>
            <legend>
                <BackButton onClick={goBack}>
                    <Arrow />
                </BackButton>
                {t('preview.users.manage')}
            </legend>
            <List users={availableUsers} selected={selected} />
        </ManageUsersContainer>
    );
};
