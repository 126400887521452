/*
 * Convert "DAT.Response.GetEquipment" to "ParsedEquipment"
 */
import { getParsedArraySafe } from '@dat/api2/utils';
import { excludeEquipmentWithoutIds } from './excludeEquipmentWithoutIds';
import { FlatEquipmentPosition, ParsedEquipment, ValidEquipmentPosition } from '../types/equipment';

// Merges "SeriesEquipment" and "SpecialEquipment" in one flat array
const flattenEquipmentResponse = (response: DAT2.Response.GetEquipment): FlatEquipmentPosition[] => {
    const { VXS } = response;
    const seriesEquipment = getParsedArraySafe(VXS.SeriesEquipment?.EquipmentPosition).map(pos => ({
        isSeries: true,
        ...pos
    }));
    const specialEquipment = getParsedArraySafe(VXS.SpecialEquipment?.EquipmentPosition).map(pos => ({
        isSpecial: true,
        ...pos
    }));

    return [...seriesEquipment, ...specialEquipment];
};

const markVinEquipment = (equipment: ValidEquipmentPosition[], vinEquipmentIds: number[]): ParsedEquipment =>
    equipment.map(pos => (vinEquipmentIds.includes(pos.DatEquipmentId) ? { ...pos, vin: true } : pos));

/*
 * Main
 */
export const parseEquipmentResponse = (
    response: DAT2.Response.GetEquipment | DAT2.Response.GetEquipmentTranslation,
    vinEquipmentIds?: number[]
): ParsedEquipment => {
    const flatEquipment = flattenEquipmentResponse(response as DAT2.Response.GetEquipment);

    const validEquipment = excludeEquipmentWithoutIds(flatEquipment);

    return vinEquipmentIds ? markVinEquipment(validEquipment, vinEquipmentIds) : validEquipment;
};
