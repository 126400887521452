import styled, { css } from 'styled-components/macro';
import { hideScrollBar } from '../../mediaQueries';

export const Wrapper = styled.div<{ paddingRight?: number }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 48px;

    ${({ paddingRight }) =>
        paddingRight &&
        css`
            padding-right: ${paddingRight}px;
        `}
`;

export const Subject = styled.div`
    background: ${({ theme }) => theme.colors.dustBlue['50']};
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 6px;
`;

export const CloseWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 6px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover,
    &:active {
        color: ${({ theme }) => theme.colors.dustBlue['400']};
    }
`;

export const Container = styled.div<{ blockScroll: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: ${({ blockScroll }) => (blockScroll ? 'hidden' : 'auto')};
    min-height: 48px;
    padding: 6px 10px;

    ${hideScrollBar()}
`;

export const ManyItems = styled.div<{ overflowing?: boolean }>`
    display: flex;
    visibility: ${({ overflowing }) => (overflowing ? 'hidden' : 'visible')};
`;

export const ErrorStyled = styled.div`
    ${({ theme }) => theme.typography.footnote};
    color: ${({ theme }) => theme.colors.red['900']};
    margin-left: 12px;
`;
