import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const InboxPageStyled = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    width: calc(100% - 40px);
    box-shadow: 0px 8px 32px ${({ theme }) => theme.colors.gray[50]}, 0px -8px 32px ${({ theme }) => theme.colors.white};

    ${media.phoneBig`
        margin: 20px auto 80px;
        width: calc(100% - 24px);
    `}
`;

export const MainFiltersWrapper = styled.div`
    position: fixed;
    top: 80px; // fixed header height
    left: 0;
    right: 0;
    padding: 16px 10px;
    background-color: white;
    z-index: 2;
    border-top: 1px solid ${({ theme }) => theme.colors.gray['100']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['100']};
    box-shadow: rgb(0 0 0 / 10%) 0px 8px 8px -8px;
`;
