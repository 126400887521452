import { createToggle } from '@dat/core/utils/effector/createToggle';

const [isOpenAIClaimModal, toggleIsOpenAIClaimModal] = createToggle(false);
const [isOpenSuccessModal, toggleSuccessModal] = createToggle(false);

export const modalsEvents = {
    toggleIsOpenAIClaimModal,
    toggleSuccessModal
};
export const modalsStores = {
    isOpenAIClaimModal,
    isOpenSuccessModal
};
