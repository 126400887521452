import React from 'react';
import { useStoreMap } from 'effector-react';
import { useTheme } from 'styled-components';

import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';

import { Text } from '@wedat/ui-kit/components/Text';
import { Container } from '../Container';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';
import { useTranslation } from 'react-i18next';
import { DataBlock, FlexWrapper, TextStyled } from './styles';

export const SumsOfIndividual = () => {
    const { t } = useTranslation();
    const baseSummary = useStoreMap(
        sharedCalcResultItalyStores.summariesItaly,
        summaries => summaries?.BaseSummaryItaly
    );

    const { colors } = useTheme();

    return (
        <Container title={t('sumsOfIndividual.title')}>
            <FlexWrapper>
                <DataBlock>
                    <TextStyled font="note" color={colors.dustBlue['600']}>
                        {t('common.sr')}
                    </TextStyled>
                    <Text font="note" fontWeight={700}>
                        <NumberFormat value={baseSummary?.WorkTimeReplace} />
                    </Text>
                </DataBlock>
                <DataBlock>
                    <TextStyled font="note" color={colors.dustBlue['600']}>
                        {t('common.la')}
                    </TextStyled>
                    <Text font="note" fontWeight={700}>
                        <NumberFormat value={baseSummary?.WorkTimeOverhaul} />
                    </Text>
                </DataBlock>
                <DataBlock>
                    <TextStyled font="note" color={colors.dustBlue['600']}>
                        {t('common.srev')}
                    </TextStyled>
                    <Text font="note" fontWeight={700}>
                        <NumberFormat value={baseSummary?.WorkTimeLacquer} />
                    </Text>
                </DataBlock>
                <DataBlock>
                    <TextStyled font="note" color={colors.dustBlue['600']}>
                        {t('common.me')}
                    </TextStyled>
                    <Text font="note" fontWeight={700}>
                        <NumberFormat value={baseSummary?.WorkTimeMechanic} />
                    </Text>
                </DataBlock>
                <DataBlock>
                    <TextStyled font="note" color={colors.dustBlue['600']} textOverflow="ellipsis">
                        {t('sumsOfIndividual.spareParts')}
                    </TextStyled>
                    <Text font="note" fontWeight={700}>
                        <NumberFormat value={baseSummary?.ValueParts} />
                    </Text>
                </DataBlock>
            </FlexWrapper>
        </Container>
    );
};
