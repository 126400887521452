import { efficiencyEffects, efficiencyEvents, efficiencyStores } from './index';
import { contractsListEffects, contractsListEvents } from '../contractsList';
import { guard, forward } from 'effector';
import { pluginStores } from '../plugin';

const { detailsEfficiencyFilters, selected } = efficiencyStores;
const { reloadEfficiencyMetrics } = efficiencyEvents;
const { detailsEfficiencyFiltersChangeFx, mainEfficiencyFiltersChangeFx, loadTotalClaimsOverdueFx } = efficiencyEffects;
const { loadUserSettingsFx } = contractsListEffects;

guard({
    source: pluginStores.pluginOptions,
    clock: reloadEfficiencyMetrics,
    filter: pluginOptions => !!pluginOptions?.settings?.efficiency?.enabled,
    target: [detailsEfficiencyFiltersChangeFx, loadTotalClaimsOverdueFx]
});

guard({
    source: pluginStores.pluginOptions,
    clock: reloadEfficiencyMetrics,
    filter: pluginOptions =>
        !!pluginOptions?.settings?.efficiency?.enabled && !pluginOptions?.settings?.efficiency?.gaugeDisabled,
    target: mainEfficiencyFiltersChangeFx
});

forward({
    from: loadUserSettingsFx.doneData,
    to: reloadEfficiencyMetrics
});

forward({
    from: detailsEfficiencyFilters.updates,
    to: detailsEfficiencyFiltersChangeFx
});

forward({
    from: selected.updates,
    to: contractsListEvents.setFilters.efficiency
});
