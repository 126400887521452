import enUS from '@wedat/ui-kit/assets/images/countryFlags/english.svg';
import elGR from '@wedat/ui-kit/assets/images/countryFlags/greek.svg';
import frFR from '@wedat/ui-kit/assets/images/countryFlags/french.svg';
import itIT from '@wedat/ui-kit/assets/images/countryFlags/italian.svg';
import ruRU from '@wedat/ui-kit/assets/images/countryFlags/russian.svg';
import plPL from '@wedat/ui-kit/assets/images/countryFlags/polish.svg';
import zhCN from '@wedat/ui-kit/assets/images/countryFlags/chinese.svg';
import csCZ from '@wedat/ui-kit/assets/images/countryFlags/czech.svg';
import deDE from '@wedat/ui-kit/assets/images/countryFlags/deutsche.svg';
import esES from '@wedat/ui-kit/assets/images/countryFlags/spanish.svg';
import koKR from '@wedat/ui-kit/assets/images/countryFlags/korean.svg';
import nlNL from '@wedat/ui-kit/assets/images/countryFlags/nederlandse.svg';
import deAT from '@wedat/ui-kit/assets/images/countryFlags/austria.svg';
import deCH from '@wedat/ui-kit/assets/images/countryFlags/switzerland.svg';

export const FLAGS_IMAGES: Record<DAT2.Locale, string> = {
    'en-US': enUS,
    'el-GR': elGR,
    'fr-FR': frFR,
    'it-IT': itIT,
    'ru-RU': ruRU,
    'pl-PL': plPL,
    'zh-CN': zhCN,
    'cs-CZ': csCZ,
    'de-DE': deDE,
    'es-ES': esES,
    'ko-KR': koKR,
    'nl-NL': nlNL,
    'de-AT': deAT,
    'de-CH': deCH
};
