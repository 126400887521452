import { GaugeContainer, GaugeEfficiencyPercent, MainContainer, MainInfo, MainInfoItem, MainInfoTitle } from './styles';
import { Gauge } from './Gauge';
import { efficiencyStores } from '../../../stores/efficiency';
import { useStore } from 'effector-react';
import { Text } from '@wedat/ui-kit';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Main = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { totalCompletedNotInTime, totalCompletedInTime } = useStore(efficiencyStores.mainEfficiencyMetrics);
    const countClaimOverdue = useStore(efficiencyStores.countClaimsOverdue);
    const efficiency =
        totalCompletedInTime !== undefined && totalCompletedNotInTime !== undefined && countClaimOverdue !== -1
            ? (totalCompletedInTime * 100) / (totalCompletedNotInTime + totalCompletedInTime + countClaimOverdue)
            : 0;
    return (
        <MainContainer>
            <MainInfo>
                <MainInfoTitle fontSize="22px" fontWeight="bolder">
                    {t('efficiency.myEfficiency')}
                </MainInfoTitle>
                <MainInfoItem>
                    <Text fontSize="30px">
                        <Text fontSize="44px">{!!efficiency ? efficiency.toFixed(1) : '--'}</Text>%
                    </Text>
                    <div>
                        <Text fontSize="12px" color={theme.colors.gray['500']}>
                            {t('efficiency.claimsCompletedInTime')}
                        </Text>
                    </div>
                </MainInfoItem>
            </MainInfo>
            <GaugeContainer>
                <Gauge efficiency={efficiency} />
                <GaugeEfficiencyPercent>
                    <Text fontSize="44px">{!!efficiency ? efficiency.toFixed(1) : '--'}</Text>%
                </GaugeEfficiencyPercent>
            </GaugeContainer>
        </MainContainer>
    );
};
