import { DiscountTypes } from '../../constants/enums';

export const vat = (item: DAT2.TotalSummaryItemItaly) => {
    const isPercent = item.DiscountDisplayType === DiscountTypes.percent;
    if (isPercent && item.ValueNetDiscountPerc) {
        const valueVat = item.ValueVat || 0;
        const valDiscPerc = item.ValueNetDiscountPerc;

        return valueVat - (valueVat * valDiscPerc) / 100;
    } else if (!isPercent && item.ValueNetDiscountValue) {
        const valueVat = item.ValueVat || 0;
        const valDiscPerc = item.ValueNetDiscountValue;

        return valueVat - valDiscPerc;
    }

    return item.ValueVat || 0;
};
