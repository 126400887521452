import { createStore, createEvent } from 'effector';

const addItemToRecipients = createEvent<BFF.AddressBook.Subject>();
const addItemToCcRecipients = createEvent<BFF.AddressBook.Subject>();
const addItemToBccRecipients = createEvent<BFF.AddressBook.Subject>();

const removeItemFromRecipients = createEvent<BFF.AddressBook.Subject>();
const removeItemFromCcRecipients = createEvent<BFF.AddressBook.Subject>();
const removeItemFromBccRecipients = createEvent<BFF.AddressBook.Subject>();

const resetRecipients = createEvent();
const resetCcRecipients = createEvent();
const resetBccRecipients = createEvent();

const recipients = createStore<BFF.AddressBook.Subject[]>([])
    .on(addItemToRecipients, (state, payload) => [...state, payload])
    .on(removeItemFromRecipients, (state, payload) => state.filter(item => item._id !== payload._id))
    .reset(resetRecipients);

const ccRecipients = createStore<BFF.AddressBook.Subject[]>([])
    .on(addItemToCcRecipients, (state, payload) => [...state, payload])
    .on(removeItemFromCcRecipients, (state, payload) => state.filter(item => item._id !== payload._id))
    .reset(resetCcRecipients);

const bccRecipients = createStore<BFF.AddressBook.Subject[]>([])
    .on(addItemToBccRecipients, (state, payload) => [...state, payload])
    .on(removeItemFromBccRecipients, (state, payload) => state.filter(item => item._id !== payload._id))
    .reset(resetBccRecipients);

export const recipientsEvents = {
    addItemToRecipients,
    addItemToCcRecipients,
    addItemToBccRecipients,
    removeItemFromRecipients,
    removeItemFromCcRecipients,
    removeItemFromBccRecipients,
    resetRecipients,
    resetCcRecipients,
    resetBccRecipients
};

export const recipientsStores = {
    recipients,
    ccRecipients,
    bccRecipients
};
