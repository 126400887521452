import i18next, { Resource, TFunction, ResourceLanguage, i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';

type Param = {
    [key: string]: ResourceLanguage;
};
type Result = { i18n: i18n; t: TFunction };

export const defaultNS = 'grapa';

export const createI18nInstance = (translations: Param): Result => {
    const i18n = i18next.createInstance();

    i18n.use(initReactI18next).init({
        ns: [defaultNS],
        defaultNS,
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        resources: createI18nResources(translations),
        react: { useSuspense: false }
    });

    const t = (...args: Parameters<TFunction>): ReturnType<TFunction> => {
        const t = i18n.getFixedT(i18n.language);

        return t(...args);
    };

    return { i18n, t };
};

const createI18nResources = (translations: Param): Resource =>
    Object.entries(translations).reduce(
        (result, [lng, translation]) => ({ ...result, [lng]: { [defaultNS]: translation } }),
        {}
    );

export const defaultLanguage: string = 'en-US';
export const languageStorageKey = 'DAT-language';
