import { attach, guard } from 'effector';
import * as R from 'ramda';

import { contractEffects as sharedContractEffects } from '@dat/shared-models/contract';

import { containedPluginsStores } from '../containedPlugins';
import { contractEffects, contractEvents } from './index';
import { attachmentsStores } from '../attachments';
import { sharedAttachmentsEffects } from '@dat/shared-models/contract/Attachments';
import { sharedContractStatusEffects } from '@dat/shared-models/contract/Status';
import { modalsEvents } from '../ui/modals';
import { configurationStores } from '../configuration';
import { pluginEffects, pluginEvents } from '../plugin';
import { pluginEvents as vehicleSelectionPluginEvents } from '@dat/vehicle-selection-modal/src/stores/plugin';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { createPayloadForCreateOrUpdateContract } from '@dat/claim-management/src/utils/createPayloadForCreateOrUpdateContract';

import { PayloadForHandleCreation } from './types';

const { handleCreationFx } = contractEffects;
const { callOnCompleteFx } = pluginEffects;
const { createContractFx } = sharedContractEffects;
const { vehicleSelectionPluginResult } = containedPluginsStores;
const { attachments } = attachmentsStores;
const { configuration } = configurationStores;
const { uploadMultipleAttachmentsFx } = sharedAttachmentsEffects;
const { changeContractStatusFx } = sharedContractStatusEffects;
const { templateSettings } = sharedTemplateStores;
handleCreationFx.use(
    attach({
        source: templateSettings.initialStatus,
        effect: async (initialStatus, { vehicleSelectionPluginResult, attachments }) => {
            const contractId = await createContractFx(
                createPayloadForCreateOrUpdateContract({ vehicleSelectionPluginResult })
            );

            await uploadMultipleAttachmentsFx({
                contractId,
                attachments: attachments.map(R.omit(['base64', 'status']))
            });
            await changeContractStatusFx({
                contractId,
                statusName: initialStatus || 'PreProcessing',
                statusType: 'custom'
            });
        }
    })
);

guard({
    clock: contractEvents.submitAiClaim,
    source: { vehicleSelectionPluginResult, attachments },
    filter: (payload): payload is PayloadForHandleCreation => !!payload.vehicleSelectionPluginResult,
    target: handleCreationFx
});

guard({
    clock: handleCreationFx.done,
    source: configuration,
    filter: configuration => !configuration.sync,
    target: callOnCompleteFx
});
guard({
    clock: handleCreationFx.finally,
    source: configuration,
    filter: configuration => !configuration.sync,
    target: [vehicleSelectionPluginEvents.resetPlugin, pluginEvents.resetPlugin]
});

guard({
    clock: handleCreationFx.done,
    source: configuration,
    filter: configuration => configuration.showModalSuccess && !configuration.sync,
    target: modalsEvents.toggleSuccessModal
});
