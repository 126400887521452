import { DEFAULT_DATE_FORMAT } from '@wedat/ui-kit/components/Datepicker/constants';
import { createEvent, restore } from 'effector';

const setDateFormat = createEvent<string>();
const dateFormat = restore(setDateFormat, DEFAULT_DATE_FORMAT);

const setInputMask = (dateFormat: string) => {
    if (dateFormat.toLocaleLowerCase() === 'd/m/yyyy') {
        const digit = /[1-9]{1,2}/;
        return digit;
    }
    return dateFormat.replace(/[a-zA-Z]/g, '9').replace(',', '');
};

const inputMask = dateFormat.map(setInputMask);

//
/*** Export ***/
//
export const events = {
    setDateFormat
};
export const stores = {
    dateFormat,
    inputMask
};
