/* eslint-disable func-names */
/* eslint-disable camelcase */

const xml_special_to_escaped_one_map: any = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&apos;'
};

const escaped_one_to_xml_special_map: any = {
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>',
    '&#13;': '',
    '&apos;': "'"
};

export function encodeXml(string: string) {
    return string.replace(/([&"<>])/g, function (_str, item) {
        return xml_special_to_escaped_one_map[item];
    });
}

export function decodeXml(string: string) {
    return string
        ? string.replace(/(&quot;|&lt;|&gt;|&amp;|&#13;|&apos;)/g, function (_str, item) {
              return escaped_one_to_xml_special_map[item];
          })
        : '';
}
