import { AvailableAssemblyGroup } from '../types/graphicTypes';
import { findGroupByDvns } from './findGroupByDvns';

export function getSvgElementByDatId(
    svg: string | undefined,
    datid: string[]
): { resultSvg: string; width: number; height: number } | undefined {
    if (!svg || !datid) return;

    var parser = new DOMParser();
    var svgObj = parser.parseFromString(svg, 'image/svg+xml');

    const gStandardebene = svgObj.getElementById('Standardebene');

    if (!gStandardebene) return;

    const gDatid = datid.map(datidItem => svgObj.getElementById(datidItem));

    const isShowOverGroup = false;
    if (!isShowOverGroup) {
        gStandardebene.innerHTML = '';
        gDatid.forEach(gDatidItem => !!gDatidItem && gStandardebene.appendChild(gDatidItem));
    }

    const elemDiv = document.createElement('div');
    // elemDiv.style.display = "none";
    elemDiv.style.visibility = 'hidden';
    const svgElem = svgObj.children[0];
    elemDiv.appendChild(svgElem);
    document.body.appendChild(elemDiv);

    const divBounding = elemDiv.getBoundingClientRect();

    let left = Number.MAX_SAFE_INTEGER;
    let right = 0;
    let top = Number.MAX_SAFE_INTEGER;
    let bottom = 0;

    gDatid.forEach(gDatidItem => {
        if (gDatidItem) {
            const bounding = gDatidItem.getBoundingClientRect();
            if (left > bounding.left) left = bounding.left;
            if (right < bounding.right) right = bounding.right;
            if (top > bounding.top) top = bounding.top;
            if (bottom < bounding.bottom) bottom = bounding.bottom;
        }
    });

    const boundingWidth = right - left;
    const boundingHeight = bottom - top;

    const scaleX = svgElem.clientWidth / 83.873;
    const scaleY = svgElem.clientHeight / 137.336;

    const sideMargin = 10;

    const width = (boundingWidth + 2 * sideMargin) / scaleX;
    const height = (boundingHeight + 2 * sideMargin) / scaleY;

    gStandardebene.setAttribute(
        'transform',
        'translate(' +
            (divBounding.left - left + sideMargin) / scaleX +
            ',' +
            (divBounding.top - top + sideMargin) / scaleY +
            ')'
    );

    svgElem.setAttribute(
        'viewBox',
        // '0 0 83.873 137.336'
        '0 0 ' + width + ' ' + height
    );

    var oSerializer = new XMLSerializer();
    var resultSvg = oSerializer.serializeToString(svgElem);

    elemDiv.remove();

    return { resultSvg, width, height };
}

export function getPartsGraphics(dvns: number[], availableAssemblyGroupsList: AvailableAssemblyGroup[]) {
    const allDvns = dvns.filter((value, index, self) => self.indexOf(value) === index);

    let result: {
        dvn: number;
        svg?: {
            resultSvg?: string;
            width?: number;
            height?: number;
        };
    }[] = [];

    result = allDvns.map(dvn => {
        const foundGroup = findGroupByDvns([dvn], availableAssemblyGroupsList);
        let svg = {};
        if (foundGroup?.currentAssemblyGroupObject?.datid)
            svg =
                getSvgElementByDatId(foundGroup?.currentAssemblyGroup?.svg, [
                    foundGroup?.currentAssemblyGroupObject?.datid
                ]) || {};

        return {
            dvn,
            svg
        };
    });

    return result;
}
