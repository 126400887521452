import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    & svg {
        margin-left: 10px;
    }
`;
export const Title = styled.span`
    margin: 0 10px;
`;

export const ButtonContainer = styled.div``;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 15px 0 15px 0;
`;
