import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldItem } from './FieldItem';
import { useStore } from 'effector-react';
import { addFieldValue, formFieldsStore, addFieldKeyValue, fieldsValuesStore } from '../../stores/fieldsValues';
import { FieldStyle } from './styles';
import { Condition } from '../../utils/conditions/Condition';
import { getFromCurrentForm } from '../../utils';
import { useGetConditionValues } from '../../hooks/';
import { DataField, DataFieldCondition } from '../../types/dataScheme';

interface Props {
    fieldId: string;
    formName: string;
    isConditionTrue: boolean | undefined;
    keyCondition: Object | null;
    isMemo?: boolean;
}

export const FieldContent: FC<Props> = ({ fieldId, formName, isConditionTrue, keyCondition, ...rest }) => {
    const formFields = useStore(formFieldsStore);
    const fieldsValues = useStore(fieldsValuesStore);
    const [isVisible, setIsVisible] = useState(true);

    // current field data
    const currentFormFields = getFromCurrentForm(formFields, formName);

    const field: DataField = useMemo(() => currentFormFields[fieldId], [currentFormFields, fieldId]);

    //  change field values from field condition result
    const { getFieldKey, getFieldValue, getOption } = useGetConditionValues(formName);

    const applySelfCondition = useCallback(
        (conditionSetting: DataFieldCondition) => {
            const { applyConditionFor } = conditionSetting;
            const condition = new Condition(
                { condition: conditionSetting },
                { key: getFieldKey, value: getFieldValue, option: getOption }
            );
            if (applyConditionFor.indexOf('value') !== -1) {
                const value = `${condition.calculate()}`;
                const oldValue = fieldsValues?.[formName]?.[fieldId];
                if (value !== oldValue) {
                    addFieldKeyValue({ formName, fieldId, key: 'text', value });
                    addFieldValue({ formName, newValue: { [fieldId]: value } });
                }
                return;
            }
            const value = condition.compare();
            if (applyConditionFor.indexOf('visible') !== -1) {
                setIsVisible(!!value);
            }
            applyConditionFor.forEach((key: string) => {
                addFieldKeyValue({ formName, fieldId, key, value });
            });
        },
        [formName, fieldId, getFieldKey, getFieldValue, getOption, fieldsValues]
    );

    //  change field values from group condition result
    const applyGroupCondition = useCallback(() => {
        if (keyCondition) {
            const fieldKeys = Object.values(keyCondition)[0]; // keys to change
            const value = isConditionTrue;
            setIsVisible(!!isConditionTrue);
            fieldKeys &&
                fieldKeys.forEach((key: string) => {
                    addFieldKeyValue({ formName, fieldId, key, value });
                });
        }
    }, [formName, keyCondition, fieldId, isConditionTrue]);

    useEffect(() => {
        // if field has own condition
        if (field?.conditions) {
            field.conditions.forEach((fieldCondition: DataFieldCondition) => {
                applySelfCondition(fieldCondition);
            });
        } else if (field?.condition) {
            applySelfCondition(field.condition);
        }
        // if fields group have condition
        else if (keyCondition) {
            applyGroupCondition();
        }
        // if no any condition
        else if (typeof isConditionTrue === undefined) {
            setIsVisible(!!field?.visible);
        }
    }, [isConditionTrue, field, applyGroupCondition, applySelfCondition, fieldsValues, keyCondition]);

    const onBlurHandler = useCallback(
        (e: React.FocusEvent<HTMLInputElement>, props: any) => {
            const newValue = { [String(props.id)]: e.target.value };
            addFieldValue({ formName, newValue });
        },
        [formName]
    );

    if (field && isVisible)
        return (
            <FieldStyle isRequired={field?.required} {...rest}>
                <FieldItem field={field} onBlurHandler={onBlurHandler} />
            </FieldStyle>
        );
    else return null;
};
