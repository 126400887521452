import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstance } from '../../hooks/useInstance';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { useMedia } from '@dat/core/hooks/useMedia';
import { createNewSubject } from '../../utils/createNewSubject';

import { Col, Container, Layout, MainContent, HeaderChildrenWrapper, MobileContentWrapper } from './styles';
import { ModalPrimary } from '@wedat/ui-kit/components/Modal';
import { SubjectsList } from '../SubjectsList';
import { SubjectForm } from '../SubjectForm';
import { BackButton } from '../SubjectForm/styles';
import { Buttons } from '../Buttons';
import { Card } from '../Card';
import { Arrow } from '@wedat/ui-kit/assets/Icon';

export const Main: FC = () => {
    const { t } = useTranslation();
    const { instanceId, isFormVisibleOnMobile, isModalOpen, closeModal, hideFormOnMobile, showList, onCloseModal } =
        useInstance('instanceId', 'isFormVisibleOnMobile', 'isModalOpen', 'showList', 'onCloseModal');
    // TODO: DATG-2154
    const [formValues, setFormValues] = useState(createNewSubject());
    const isLaptop = useMedia(sizes.laptop);
    const isInstanceInitialized = !!instanceId;

    const isTablet = useMedia(sizes.tablet);

    if (!isInstanceInitialized) return null;

    const handleCloseModal = () => {
        closeModal();
        onCloseModal?.();
    };

    return (
        <Layout>
            <Card formValues={formValues} />

            <ModalPrimary
                isOpen={isModalOpen}
                onDismiss={handleCloseModal}
                headerChildren={
                    isFormVisibleOnMobile && (
                        <HeaderChildrenWrapper>
                            <BackButton onClick={hideFormOnMobile}>
                                <Arrow />
                                {t('backButton')}
                            </BackButton>
                        </HeaderChildrenWrapper>
                    )
                }
                fullWidth={isLaptop}
                footerChildren={<Buttons />}
            >
                <Container>
                    {!isTablet ? (
                        <MainContent>
                            <SubjectsList />
                            <SubjectForm formValues={formValues} setFormValues={setFormValues} />
                        </MainContent>
                    ) : (
                        <MobileContentWrapper>
                            <Col hidden={!isFormVisibleOnMobile && showList}>
                                <SubjectForm formValues={formValues} setFormValues={setFormValues} />
                            </Col>
                            <SubjectsList />
                        </MobileContentWrapper>
                    )}
                </Container>
            </ModalPrimary>
        </Layout>
    );
};
