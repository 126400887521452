import React from 'react';

import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

import { Text } from '@wedat/ui-kit/components/Text';
import { Button } from '@wedat/ui-kit/components/Button';
import { Input } from '@wedat/ui-kit/components/Input';
import { Datepicker } from '@dat/smart-components/Datepicker';
import { Timepicker } from '@wedat/ui-kit/components/Timepicker';

import { DatepickerParsedValue } from '@wedat/ui-kit/components/Datepicker/types';

import {
    ButtonSectionStyled,
    ButtonStyledWrapper,
    CreateAppointmentPopupContentStyled,
    CreateAppointmentPopupStyled,
    DatePickerSectionStyled,
    DatePickerWrapperStyled,
    DatepickerStyled,
    InputSectionStyled,
    UpperSectionStyled
} from '../../CustomEditor/styles';
import { SelectAssignee } from '../../SelectAssignee';

interface Props {
    isEditing: boolean;
    onClose: () => void;
    values: {
        date: Date;
        startTime: Date;
        endTime: Date;
        description: string;
        address: string;
        city: string;
        zip: string;
    };
    onStartTimeChange: (value: Date | null) => void;
    onEndTimeChange: (value: Date | null) => void;
    onDateChange: (value: DatepickerParsedValue) => void;
    onAddressChange: (e: string) => void;
    onCityChange: (e: string) => void;
    onZipChange: (e: string) => void;
    onDescriptionChange: (e: string) => void;
    onSubmit: () => void;
    error: FormikErrors<Date>;
    descriptionPrefix: string;
    onAssigneeChange: (id: number) => void;
    isAdmin?: boolean;
}

export const NewAppointmentModal: React.FC<Props> = ({
    onClose,
    isEditing,
    values,
    onStartTimeChange,
    onEndTimeChange,
    onDateChange,
    onDescriptionChange,
    onSubmit,
    error,
    descriptionPrefix,
    onAddressChange,
    onZipChange,
    onCityChange,
    onAssigneeChange,
    isAdmin
}) => {
    const { t } = useTranslation();

    const isDescriptionProvided = !!descriptionPrefix.trim().length;

    return (
        <CreateAppointmentPopupStyled isDescriptionProvided={isDescriptionProvided}>
            <UpperSectionStyled>
                <Text fontSize="18px">{isEditing ? t('edit.appointment') : t('create.appointment')}</Text>
            </UpperSectionStyled>

            <CreateAppointmentPopupContentStyled>
                <UpperSectionStyled>
                    <Input
                        prefix={isDescriptionProvided ? descriptionPrefix : ''}
                        value={values.description}
                        label={t('description')}
                        onChange={e => onDescriptionChange(e.target.value)}
                    />
                </UpperSectionStyled>

                {isAdmin && <SelectAssignee onChange={onAssigneeChange} />}

                <Text font="footnote">{t('date')}</Text>

                <DatepickerStyled>
                    <Datepicker name="date" onChange={onDateChange} value={values.date} />
                </DatepickerStyled>

                <DatePickerWrapperStyled>
                    <DatePickerSectionStyled>
                        <Text fontSize="14px">{t('start.time')}</Text>

                        <Timepicker name="startTime" onChange={onStartTimeChange} value={values.startTime} />
                    </DatePickerSectionStyled>

                    <DatePickerSectionStyled>
                        <Text font="footnote">{t('end.time')}</Text>

                        <Timepicker name="endTime" onChange={onEndTimeChange} value={values.endTime} />
                    </DatePickerSectionStyled>
                </DatePickerWrapperStyled>

                <InputSectionStyled>
                    <Input
                        value={values.address}
                        label={t('address')}
                        onChange={e => onAddressChange(e.target.value)}
                    />
                </InputSectionStyled>
                <InputSectionStyled>
                    <Input value={values.city} label={t('city')} onChange={e => onCityChange(e.target.value)} />
                </InputSectionStyled>
                <InputSectionStyled>
                    <Input value={values.zip} label={t('zip')} onChange={e => onZipChange(e.target.value)} />
                </InputSectionStyled>
            </CreateAppointmentPopupContentStyled>

            {error && (
                <Text color="red" fontSize="12px">
                    {error}
                </Text>
            )}

            <ButtonStyledWrapper>
                <ButtonSectionStyled>
                    <Button
                        onClick={onClose}
                        typeStyle={{
                            type: 'secondary'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </ButtonSectionStyled>
                <ButtonSectionStyled>
                    <Button
                        onClick={onSubmit}
                        disabled={!!error}
                        typeStyle={{
                            type: 'primary'
                        }}
                    >
                        {t('create.new')}
                    </Button>
                </ButtonSectionStyled>
            </ButtonStyledWrapper>
        </CreateAppointmentPopupStyled>
    );
};
