import { RepairPosition } from '@dat/api2dat/types/contractTypes';
import { ftRepairType, ftRepairItemType, FtVxsRepairsType } from '../types/ft/ftRepairType';

export function ftRepairToRepairPosition(ftRepair: ftRepairType[]): FtVxsRepairsType[] {
    return ftRepair.map(repItem => {
        const spliingelemId = repItem.elemId.split('.');
        const damageId = spliingelemId[0];
        const elemOrGroupId = spliingelemId[1];

        let vxsRepairPositions = repItem.vxsRepairPositions || repItem.repair.map(convertFtRepairToVxsRepairPosition);

        return {
            damageId,
            elemOrGroupId,
            vxsRepairPositions
        };
    });
}

// see sphinx code package eu.dat.myclaim.vxs method: importRepairPosition

export function convertFtRepairToVxsRepairPosition(ftRepairItem: ftRepairItemType): Partial<RepairPosition> {
    return {
        DATProcessId: ftRepairItem.dvn || undefined,
        RepairType: convertRepairTypeToFullRepairType(ftRepairItem.repairType),
        PositionEntryType: ftRepairItem.repairProcessType || 'graphical',

        DentsCalculationMethod: ftRepairItem.dentingMethod,
        DentNumberLess: ftRepairItem.dentNumberLess,
        DentNumberMore: ftRepairItem.dentNumberMore,

        LacquerCountSpotRepair: ftRepairItem.countSpotRepair,
        LacquerLevelId: ftRepairItem.lacquerLevel,
        SparePartPrice: ftRepairItem.price,
        WorkTime: ftRepairItem.worktime || ftRepairItem.time
    };
}

// see package eu.dat.myclaim.model.dictionary enum RepairType
export type RepairType =
    | 'A'
    | 'B'
    | 'C'
    | 'D'
    | 'E'
    | 'F'
    | 'G'
    | 'H'
    | 'I'
    | 'J'
    | 'K'
    | 'L'
    | 'M'
    | 'N'
    | 'O'
    | 'P'
    | 'Q'
    | 'R'
    | 'S'
    | 'T'
    | 'U'
    | 'V'
    | 'W'
    | 'X'
    | 'Y'
    | 'Z'
    | '/';

export const RepairTypeEnum = [
    ['DISMOUNTING_MOUNTING', 'A', 'dismountMount', 'dis- and mounting'], // SDII, FIO RepairCode "A"     removal and installation
    ['UNDERBODY_PROTECTION', 'B', 'underBodyProtection', 'underbody protection'], // SDII RepairCode "B"
    ['PAINT_SPOT_REPAIRS', 'C', 'spotPaintRepair', 'spot repair'], // SDII, FIO RepairCode "C"
    ['DISASSEMBLY', 'D', 'disassemble', 'disassembly'], // SDII, FIO RepairCode "D"
    ['REPLACEMENT', 'E', 'replace', 'replace'], // SDII, FIO RepairCode "E"
    ['ASSEMBLY', 'F', 'assemble', 'assembly'], // SDII, FIO RepairCode "F"
    ['CUTTING', 'G', 'cut', 'cutting'], // SDII, FIO RepairCode "G"
    ['DEAERATION', 'H', 'deaerate', 'deareate'], // SDII, FIO RepairCode "H"
    ['REPARATION_AND_PAINTING', 'I', 'repair', 'overhaul'], // SDII, FIO RepairCode "I"     Repairing (the automatic painting is also active)
    ['CLEANING', 'J', 'clean', 'cleaning'], // SDII, FIO RepairCode "J"
    ['DEDUCTIBLE_COSTS', 'K', 'deductibleCosts', 'deductible costs'], // SDII RepairCode "K"
    ['PAINTING', 'L', 'paint', 'lacquer'], // SDII, FIO RepairCode "L"     Parts painted when installed
    ['PAINTING_DISMOUNTED', 'M', 'paintDismounted', 'lacquer demounted'], // SDII, FIO RepairCode "M"     Parts painted when removed
    ['ADDITIONAL_COSTS', 'N', 'auxiliaryCosts', 'incidental costs'], // SDII, FIO RepairCode "N"
    ['BODY_CAVITY_PROTECTION', 'O', 'hollowSpaceProtection', 'body cavity protection'], // SDII, FIO RepairCode "O"
    ['VISUAL_INSPECTION', 'P', 'check', 'visual inspection'], // SDII, FIO RepairCode "P"
    ['REPLACE_NO_MATERIAL', 'Q', 'replaceNoMaterial', 'replace (no material costs)'], // SDII, FIO RepairCode "Q"
    ['RISK', 'R', 'risk', 'risk'], // SDII, FIO RepairCode "R"     Part for possible extended repair costs
    ['ADJUSTEMENT', 'S', 'adjust', 'adjust'], // SDII, FIO RepairCode "S"     Settings
    ['TECHNICAL_INSPECTION', 'T', 'technicalInspection', 'technical inspection'], // SDII, FIO RepairCode "T"     using measuring or test gauge
    ['MANUAL_PAINTING', 'U', 'paintManual', 'lacquer (no automatics)'], // SDII, FIO RepairCode "U"
    ['MEASURING', 'V', 'measure', 'measuring'], // SDII, FIO RepairCode "V"
    ['BALANCING', 'W', 'balance', 'balancing'], // SDII, FIO RepairCode "W"
    ['EXPOSE', 'X', 'expose', 'expose'], // SDII, FIO RepairCode "X"
    ['COMPLETE', 'Y', 'complete', 'complete'], // SDII, FIO RepairCode "Y"
    ['DISASSEMBLY_ASSEMBLY', 'Z', 'disassembleAssemble', 'dis- and reassemble'], // SDII, FIO RepairCode "Z"     dismantling and assembling
    ['REPARATION_WITHOUT_PAINTING', '/', '', 'fixing'] // FIO RepairCode "/" => Class RE   Repair
];

export function convertRepairTypeToFullRepairType(repairType: RepairType | string) {
    return RepairTypeEnum.find(item => item[1] === repairType)?.[3] || undefined;
}

export function repairTypeByRepairCode(repairCode: string) {
    const foundRepType = RepairTypeEnum.find(item => item[1] === repairCode);

    if (!foundRepType)
        return {
            code: '',
            key: '',
            repairType: ''
        };

    return {
        representation: foundRepType[0],
        code: foundRepType[1], // repairCode
        key: foundRepType[2], // repairTypeNameSDIIDatExchange
        repairType: foundRepType[3] // repairTypeNameFioVXSImport
    };
}

export function convertRepairType(repairType?: string) {
    if (!repairType)
        return {
            code: '',
            key: '',
            repairType: ''
        };

    const foundRepType = RepairTypeEnum.find(item => item[3] === repairType);

    if (!foundRepType)
        return {
            code: '',
            key: '',
            repairType: ''
        };

    return {
        code: foundRepType[1],
        key: foundRepType[2],
        repairType: foundRepType[3]
    };
}
