import React, { FC } from 'react';
import { NumberFormat } from '@wedat/ui-kit/components/NumberFormat';

interface Props {
    value?: number;
}

export const NumberFormatWrapper: FC<Props> = ({ value, children }) => (
    <>
        <NumberFormat value={value || '-'} /> {children}
    </>
);
