// ElementsMenuDrawer

import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, List, Row, Select, Switch } from 'antd';
import { fastTrackElementsMenuModel } from '../../stores/fastTrackElementsMenuModel';
import { fastTrackElementModel, getAvailableDamagesByDATID } from '../../stores/fastTrackElementModel';
import { FastTrackElement } from '@dat/api2dat/types/fastTrackInterfaceTypes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as TimesIcon } from '../CommonComponents/Icons/TimesIcon.svg';

import './fastTrackElementsMenu.css';

const { Option } = Select;

export const ElementsMenuDrawer: FC = () => {
    const { t } = useTranslation();
    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    const fastTrackElementsMenuState = useStore(fastTrackElementsMenuModel.stores.$fastTrackElementsMenu);

    if (!fastTrackConfiguration) return null;

    if (!fastTrackElementsMenuState) return null;

    const elementsWithDamages: FastTrackElement[] =
        fastTrackElementsMenuState?.elementDatIDs?.map(dATID => {
            const selectedElement = selectedElements.find(el => el.elementId === dATID);
            return { elementId: dATID, damageType: selectedElement?.damageType || '', userComment: '' };
        }) || [];

    return (
        <Drawer
            closable={false}
            getContainer={false}
            mask={false}
            placement={'right'}
            contentWrapperStyle={{ overflowY: 'hidden' }}
            className="weDat-ft-elementsMenuDrawer"
            visible={!!fastTrackElementsMenuState}
            title={
                <>
                    <Button
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={_ => fastTrackElementsMenuModel.events.setFastTrackElementsMenu(null)}
                    />
                    <span style={{ marginLeft: '16px', fontSize: '20px' }}>
                        {fastTrackElementsMenuState?.menuGroupLabel || ' '}
                    </span>
                </>
            }
            footer={
                <Button
                    onClick={_ => fastTrackElementsMenuModel.events.setFastTrackElementsMenu(null)}
                    className="weDat-ft-elementsMenuDrawer-close"
                >
                    {t('close')}
                </Button>
            }
            width="100%"
            height="100%"
            zIndex={1009}
        >
            <List itemLayout="vertical">
                {elementsWithDamages.map(el => {
                    const availableDamages = getAvailableDamagesByDATID(el.elementId, fastTrackConfiguration);

                    return (
                        <List.Item>
                            <Row align="middle" style={{ marginBottom: '24px' }}>
                                <Col xs={21}>{t(`fastLocalNameDATID.${el.elementId}`)}</Col>

                                <Col xs={3}>
                                    <Switch
                                        checked={!!selectedElements.find(selEl => selEl.elementId === el.elementId)}
                                        onChange={val => {
                                            if (val) {
                                                fastTrackElementModel.events.setDamageIfOneElseMarkElementId(
                                                    el.elementId
                                                );
                                            } else {
                                                fastTrackElementModel.events.delFastTrackDamage(el.elementId);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row align="middle">
                                <Col xs={24}>
                                    <Select
                                        placeholder={t('placeholder.SelectValue')}
                                        value={el.damageType || undefined}
                                        onChange={val => {
                                            if (val === 'noDamage') {
                                                fastTrackElementModel.events.delFastTrackDamage(el.elementId);
                                            } else {
                                                fastTrackElementModel.events.setFastTrackDamage({
                                                    damageType: val,
                                                    elementId: el.elementId
                                                });
                                            }
                                        }}
                                    >
                                        {availableDamages.map(dam => (
                                            <Option value={dam.damageid}>{dam.damageTranslation}</Option>
                                        ))}
                                        <Option value="noDamage">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TimesIcon width="16" height="16" style={{ margin: '0 8px 0 4px' }} />
                                                {t('fastTrack.noDamage')}
                                            </div>
                                        </Option>
                                    </Select>
                                </Col>
                            </Row>
                        </List.Item>
                    );
                })}
            </List>
        </Drawer>
    );
};
