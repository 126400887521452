import { sample } from 'effector';
import { spread } from 'patronum';

import { sharedUserStores } from './index';
import { sharedAuthEvents } from '../auth';
import { decodeDatToken } from '@dat/core/utils/auth/decodeDatToken';
import { sharedLoginEvents } from '../auth/login';

const { customerNumber, username, userProfile } = sharedUserStores;
const { loggedIn } = sharedAuthEvents;
const { loggedOut } = sharedLoginEvents;

sample({
    source: loggedIn,
    fn: ({ token }) => decodeDatToken(token),
    target: spread({
        targets: { customerNumber, user: username }
    })
});

userProfile.on(loggedIn, (_, { userData }) => userData);
userProfile.reset(loggedOut);
