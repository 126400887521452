import React from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';

import { RadioGroup, RadioGroupProps } from '../../components/RadioGroup';

import { RadioValue } from '../../components/Radio';
import { FieldEventCallback } from '../types';

export interface RadioGroupFieldProps extends Omit<RadioGroupProps, 'onChange' | 'value'> {
    name: string;
    changeCallback?: FieldEventCallback<any, void, RadioValue>;
    onUserChange?: (field: FieldInputProps<RadioValue>) => void;
}

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ name, changeCallback, onUserChange, ...rest }) => {
    const formikContext = useFormikContext();
    const [field, , helpers] = useField<RadioValue>(name);

    const handleChange = (value: RadioValue) => {
        helpers.setValue(value);
        changeCallback?.(value, formikContext);
        onUserChange?.({
            ...field,
            value
        });
    };

    return <RadioGroup {...field} {...rest} onChange={handleChange} />;
};
