import React from 'react';
import { PlusCircleIcon } from '@wedat/ui-kit/components/Icons';
import { AddIconWrapper, RemoveIconWrapper } from '../../Main/styles';
import { Container, Content, TitlesContainer, Title, Role } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
    data: BFF.AddressBook.Subject;
    addItem: () => void;
    removeItem: () => void;
    isExist: boolean;
}

export const ListItem: React.FC<Props> = ({ data, addItem, removeItem, isExist }) => {
    const { t } = useTranslation();

    return (
        <Container selected={isExist} onClick={!isExist ? addItem : removeItem}>
            <Content>
                <TitlesContainer>
                    <Title font="noteBold">{data.name}</Title>
                    <Role font="note">{t(`all-components:roles.${data.role}`)}</Role>
                </TitlesContainer>

                {!isExist ? (
                    <AddIconWrapper>
                        <PlusCircleIcon />
                    </AddIconWrapper>
                ) : (
                    <RemoveIconWrapper>
                        <PlusCircleIcon />
                    </RemoveIconWrapper>
                )}
            </Content>
        </Container>
    );
};
