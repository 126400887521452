import { createEffect, createEvent, createStore, restore } from 'effector';
import { getParsedArraySafe } from '@dat/api2/utils';

import { API2 } from '@dat/api2';
import { ParsedAttachmentItem } from '../../types/attachments';
import { AiGallery } from '../../types/ai-gallery';
import { parseAttachmentItem } from './utils/parseAttachmentItem';

const listAttachmentsOfContractFx = createEffect({
    handler: async (contractId: number) => {
        const response = await API2.myClaim.listAttachmentsOfContract({ contractId });
        const attachments = getParsedArraySafe(response.return);
        const attachmentsWithBase64 = attachments.map(parseAttachmentItem);

        return attachmentsWithBase64;
    }
});

// Events
const setPanel = createEvent<boolean>();
const setPanelDamage = createEvent<boolean>();
const getGalleryContract = createEvent<number>();

// Stores
const hasPanel = restore(setPanel, true);
const hasPanelDamage = restore(setPanelDamage, true);

const aiGallery = createStore<AiGallery | null>(null);

const setAttachmentFolderId = createEvent<number | undefined>();
const attachmentFolderId = restore(setAttachmentFolderId, null);

const attachments = createStore<ParsedAttachmentItem[]>([]);

export const aiGalleryEvents = {
    setPanel,
    setPanelDamage,
    setAttachmentFolderId,
    getGalleryContract
};

export const aiGalleryEffects = {
    listAttachmentsOfContractFx
};

export const aiGalleryStores = {
    aiGallery,
    attachmentFolderId,
    attachments,
    hasPanel,
    hasPanelDamage
};
