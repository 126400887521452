import axios from '../datAxios';
import { DatProductsPaths, VehicleRepairServices } from '../constants';

/*
 * Selection
 */
export const getVehicleTypes = (data: DAT2.Request.GetVehicleTypes) =>
    axios<DAT2.Response.GetVehicleTypes>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getVehicleTypes',
        data
    });

export const getVehicleData = (data: DAT2.Request.GetVehicleData) =>
    axios<DAT2.Response.GetVehicleData>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.VehicleSelectionService,
        method: 'getVehicleData',
        data
    });

/*
 * Identification
 */
export const getVehicleIdentificationByVin = (data: DAT2.Request.GetVehicleIdentificationByVin) =>
    axios<DAT2.Response.GetVehicleIdentificationByVin>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.VehicleIdentificationService,
        method: 'getVehicleIdentificationByVin',
        data
    });

/*
 * Images
 */
export const getVehicleImagesN = (data: DAT2.Request.GetVehicleImagesN) =>
    axios<DAT2.Response.GetVehicleImagesN>({
        product: DatProductsPaths.VehicleRepair,
        service: VehicleRepairServices.VehicleImagery,
        method: 'getVehicleImagesN',
        data
    });
