import { attach } from 'effector';
import { createNotifyingEffect } from '@dat/smart-components/Toast/createNotifyingEffect';
import { API2 } from '@dat/api2';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { FileParameters } from '../../components/ImportIni/types';

const handleFileFx = createNotifyingEffect({
    handler: async ({
        event,
        templateField,
        templateId,
        networkType,
        contractType,
        country,
        currency
    }: FileParameters): Promise<DAT2.Response.CreateOrUpdateContract> => {
        if (!templateId) {
            throw new Error('importIni.missingTemplateId');
        }
        if (!templateField) {
            throw new Error('importInit.missingTemplateField');
        }

        // Import file
        const data: string = await new Promise(resolve => {
            const files = event?.target?.files;
            if (!files?.length) return;

            const reader = new FileReader();
            reader.onload = ({ target }) => {
                const res = target?.result;
                if (!res) {
                    throw new Error('importIni.emptyContent');
                }
                resolve(res as string);
            };
            reader.readAsBinaryString(files[0]);
        });

        return API2.myClaim.createOrUpdateContract({
            contractType,
            templateId,
            networkType,
            Dossier: {
                Country: country,
                Currency: currency
            },
            templateData: {
                entry: [
                    {
                        key: templateField,
                        value: data
                    },
                    {
                        key: 'referenceNumber',
                        value: `ImportedClaim${Date.now()}`
                    }
                ]
            }
        });
    }
});

const handleFileInputChangeFx = attach({
    source: [
        sharedTemplateStores.templateId,
        sharedTemplateStores.templateSettings.networkType,
        sharedTemplateStores.templateSettings.contractType,
        sharedTemplateStores.templateSettings.country,
        sharedTemplateStores.templateSettings.currency
    ],
    mapParams: ({ event, templateField }, [templateId, networkType, contractType, country, currency]) => ({
        templateId: templateId || undefined,
        templateField,
        event,
        networkType,
        contractType,
        country,
        currency
    }),
    effect: handleFileFx
});

export const importIniEffects = {
    handleFileInputChangeFx
};
