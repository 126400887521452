import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';
import { isIOS } from 'react-device-detect';

export const Container = styled.div`
    display: flex;
    width: 100%;

    ${media.tablet`
        padding-bottom: 76px;

        ${
            isIOS &&
            css`
                padding-bottom: 96px;
            `
        }
    `}
`;
