import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 16px;
`;

export const PositionsTableContainer = styled.div`
    padding: 20px 10px 20px 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    margin-left: 24px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 8px;
`;

export const TextStyled = styled(Text)`
    margin-left: 12px;
`;

export const IconWrapperDelete = styled.span`
    height: 18px;
    width: 18px;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const ButtonStyledDelete = styled(Button)`
    min-width: 48px;
    width: fit-content;
    margin-left: 24px;
    padding-left: 40px;
    padding-right: 40px;
    border-color: ${({ theme }) => theme.colors.red['400']};
    color: ${({ theme }) => theme.colors.red['400']};
    background-color: ${({ theme }) => theme.colors.white};
    flex-shrink: 0;
    width: fit-content;

    ${TextStyled} {
        color: ${({ theme }) => theme.colors.red['400']};
    }

    ${IconWrapperDelete} {
        color: ${({ theme }) => theme.colors.red['400']};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.red['400']};
        border-color: ${({ theme }) => theme.colors.red['400']};

        ${TextStyled} {
            color: ${({ theme }) => theme.colors.white};
        }

        ${IconWrapperDelete} {
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
