import React from 'react';

import { pluginEvents } from './stores/plugin';

import { PluginProvider } from '@dat/smart-components/PluginProvider';

import { LabourRatesGenericPluginOptions } from './types/plugin';
import { Main } from './components';

interface Props {
    options: LabourRatesGenericPluginOptions;
}

const App: React.FC<Props> = ({ options }) => (
    <PluginProvider name="labour-rates-generic" options={options} onInit={pluginEvents.initPlugin}>
        <Main />
    </PluginProvider>
);

export default App;
