import { createEffect, createStore } from 'effector';

const possibleStatuses = createStore<DAT2.ContractStatusTransition[]>([]);
const changeStatusFx = createEffect<DAT2.ContractStatusTransition, void>();

export const statusesEffects = {
    changeStatusFx
};
export const statusesStores = {
    possibleStatuses
};
