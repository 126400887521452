import { VehicleTypeWithManufacturer } from '../types/vehicleSelectionServiceTypes';

export function getManufacturerByDatECode(
    datECode: string,
    vehicleTypeWithManufacturer?: VehicleTypeWithManufacturer[]
): string {
    const preparedDatECode = (datECode || '').replace(/[^0-9]/g, '');

    const usingVehicleTypeWithManufacturer = vehicleTypeWithManufacturer || vehicleTypeWithManufacturer_ruRu;

    const vehicleTypeId = String(+preparedDatECode.slice(0, 2));
    const manufacturerId = String(+preparedDatECode.slice(2, 5));

    const foundType = usingVehicleTypeWithManufacturer.find(item => item.attr.key === vehicleTypeId);
    if (!foundType) return '';

    const foundManufacturer = foundType.manufacturer.find(item => item.attr.key === manufacturerId);
    if (!foundManufacturer) return '';

    return foundManufacturer.attr.value;
}

export const vehicleTypeWithManufacturer_ruRu: VehicleTypeWithManufacturer[] = [
    {
        attr: {
            key: '1',
            value: 'Легковой автомобиль'
        },
        manufacturer: [
            {
                attr: {
                    key: '460',
                    value: 'ВАЗ'
                }
            },
            {
                attr: {
                    key: '291',
                    value: 'ГАЗ'
                }
            },
            {
                attr: {
                    key: '885',
                    value: 'УАЗ'
                }
            },
            {
                attr: {
                    key: '20',
                    value: 'Abarth'
                }
            },
            {
                attr: {
                    key: '40',
                    value: 'Alfa Romeo'
                }
            },
            {
                attr: {
                    key: '42',
                    value: 'Alpina'
                }
            },
            {
                attr: {
                    key: '57',
                    value: 'Aston Martin'
                }
            },
            {
                attr: {
                    key: '60',
                    value: 'Audi'
                }
            },
            {
                attr: {
                    key: '95',
                    value: 'Barkas'
                }
            },
            {
                attr: {
                    key: '107',
                    value: 'Bentley'
                }
            },
            {
                attr: {
                    key: '130',
                    value: 'BMW'
                }
            },
            {
                attr: {
                    key: '145',
                    value: 'Brilliance'
                }
            },
            {
                attr: {
                    key: '141',
                    value: 'Buick'
                }
            },
            {
                attr: {
                    key: '150',
                    value: 'Cadillac'
                }
            },
            {
                attr: {
                    key: '157',
                    value: 'Caterham'
                }
            },
            {
                attr: {
                    key: '160',
                    value: 'Chevrolet'
                }
            },
            {
                attr: {
                    key: '170',
                    value: 'Chrysler'
                }
            },
            {
                attr: {
                    key: '190',
                    value: 'Citroen'
                }
            },
            {
                attr: {
                    key: '191',
                    value: 'Corvette'
                }
            },
            {
                attr: {
                    key: '189',
                    value: 'Cupra'
                }
            },
            {
                attr: {
                    key: '194',
                    value: 'Dacia'
                }
            },
            {
                attr: {
                    key: '195',
                    value: 'Daewoo'
                }
            },
            {
                attr: {
                    key: '210',
                    value: 'Daihatsu'
                }
            },
            {
                attr: {
                    key: '215',
                    value: 'Datsun'
                }
            },
            {
                attr: {
                    key: '230',
                    value: 'Dodge'
                }
            },
            {
                attr: {
                    key: '235',
                    value: 'DS'
                }
            },
            {
                attr: {
                    key: '277',
                    value: 'Ferrari'
                }
            },
            {
                attr: {
                    key: '280',
                    value: 'Fiat'
                }
            },
            {
                attr: {
                    key: '285',
                    value: 'Ford'
                }
            },
            {
                attr: {
                    key: '296',
                    value: 'Genesis'
                }
            },
            {
                attr: {
                    key: '340',
                    value: 'Honda'
                }
            },
            {
                attr: {
                    key: '345',
                    value: 'Hyundai'
                }
            },
            {
                attr: {
                    key: '357',
                    value: 'Infiniti'
                }
            },
            {
                attr: {
                    key: '362',
                    value: 'Isuzu'
                }
            },
            {
                attr: {
                    key: '365',
                    value: 'Iveco'
                }
            },
            {
                attr: {
                    key: '380',
                    value: 'Jaguar'
                }
            },
            {
                attr: {
                    key: '390',
                    value: 'Jeep'
                }
            },
            {
                attr: {
                    key: '425',
                    value: 'Kia'
                }
            },
            {
                attr: {
                    key: '465',
                    value: 'Lamborghini'
                }
            },
            {
                attr: {
                    key: '470',
                    value: 'Lancia'
                }
            },
            {
                attr: {
                    key: '730',
                    value: 'Land Rover'
                }
            },
            {
                attr: {
                    key: '487',
                    value: 'Lexus'
                }
            },
            {
                attr: {
                    key: '502',
                    value: 'Lotus'
                }
            },
            {
                attr: {
                    key: '522',
                    value: 'Maserati'
                }
            },
            {
                attr: {
                    key: '550',
                    value: 'Mazda'
                }
            },
            {
                attr: {
                    key: '570',
                    value: 'Mercedes-Benz'
                }
            },
            {
                attr: {
                    key: '70',
                    value: 'MG Rover'
                }
            },
            {
                attr: {
                    key: '580',
                    value: 'MINI'
                }
            },
            {
                attr: {
                    key: '590',
                    value: 'Mitsubishi'
                }
            },
            {
                attr: {
                    key: '225',
                    value: 'Nissan'
                }
            },
            {
                attr: {
                    key: '650',
                    value: 'Opel'
                }
            },
            {
                attr: {
                    key: '670',
                    value: 'Peugeot'
                }
            },
            {
                attr: {
                    key: '895',
                    value: 'Piaggio (Vespa)'
                }
            },
            {
                attr: {
                    key: '700',
                    value: 'Pontiac'
                }
            },
            {
                attr: {
                    key: '710',
                    value: 'Porsche'
                }
            },
            {
                attr: {
                    key: '715',
                    value: 'Proton'
                }
            },
            {
                attr: {
                    key: '720',
                    value: 'Renault'
                }
            },
            {
                attr: {
                    key: '727',
                    value: 'Rolls Royce'
                }
            },
            {
                attr: {
                    key: '740',
                    value: 'Saab'
                }
            },
            {
                attr: {
                    key: '790',
                    value: 'Seat'
                }
            },
            {
                attr: {
                    key: '800',
                    value: 'Skoda'
                }
            },
            {
                attr: {
                    key: '560',
                    value: 'Smart'
                }
            },
            {
                attr: {
                    key: '810',
                    value: 'Ssangyong'
                }
            },
            {
                attr: {
                    key: '820',
                    value: 'Subaru'
                }
            },
            {
                attr: {
                    key: '830',
                    value: 'Suzuki'
                }
            },
            {
                attr: {
                    key: '850',
                    value: 'Tata'
                }
            },
            {
                attr: {
                    key: '853',
                    value: 'Tesla'
                }
            },
            {
                attr: {
                    key: '860',
                    value: 'Toyota'
                }
            },
            {
                attr: {
                    key: '865',
                    value: 'Trabant'
                }
            },
            {
                attr: {
                    key: '905',
                    value: 'Volkswagen'
                }
            },
            {
                attr: {
                    key: '910',
                    value: 'Volvo'
                }
            },
            {
                attr: {
                    key: '930',
                    value: 'Wartburg'
                }
            },
            {
                attr: {
                    key: '935',
                    value: 'Westfield'
                }
            },
            {
                attr: {
                    key: '960',
                    value: 'Zastava'
                }
            }
        ]
    },
    {
        attr: {
            key: '2',
            value: 'Фургон'
        },
        manufacturer: [
            {
                attr: {
                    key: '291',
                    value: 'ГАЗ'
                }
            },
            {
                attr: {
                    key: '885',
                    value: 'УАЗ'
                }
            },
            {
                attr: {
                    key: '190',
                    value: 'Citroen'
                }
            },
            {
                attr: {
                    key: '210',
                    value: 'Daihatsu'
                }
            },
            {
                attr: {
                    key: '280',
                    value: 'Fiat'
                }
            },
            {
                attr: {
                    key: '285',
                    value: 'Ford'
                }
            },
            {
                attr: {
                    key: '345',
                    value: 'Hyundai'
                }
            },
            {
                attr: {
                    key: '362',
                    value: 'Isuzu'
                }
            },
            {
                attr: {
                    key: '365',
                    value: 'Iveco'
                }
            },
            {
                attr: {
                    key: '425',
                    value: 'Kia'
                }
            },
            {
                attr: {
                    key: '520',
                    value: 'MAN'
                }
            },
            {
                attr: {
                    key: '550',
                    value: 'Mazda'
                }
            },
            {
                attr: {
                    key: '570',
                    value: 'Mercedes-Benz'
                }
            },
            {
                attr: {
                    key: '590',
                    value: 'Mitsubishi'
                }
            },
            {
                attr: {
                    key: '225',
                    value: 'Nissan'
                }
            },
            {
                attr: {
                    key: '650',
                    value: 'Opel'
                }
            },
            {
                attr: {
                    key: '670',
                    value: 'Peugeot'
                }
            },
            {
                attr: {
                    key: '720',
                    value: 'Renault'
                }
            },
            {
                attr: {
                    key: '820',
                    value: 'Subaru'
                }
            },
            {
                attr: {
                    key: '830',
                    value: 'Suzuki'
                }
            },
            {
                attr: {
                    key: '860',
                    value: 'Toyota'
                }
            },
            {
                attr: {
                    key: '905',
                    value: 'Volkswagen'
                }
            }
        ]
    },
    {
        attr: {
            key: '3',
            value: 'Мотоцикл'
        },
        manufacturer: [
            {
                attr: {
                    key: '47',
                    value: 'Aprilia'
                }
            },
            {
                attr: {
                    key: '105',
                    value: 'Benelli'
                }
            },
            {
                attr: {
                    key: '130',
                    value: 'BMW'
                }
            },
            {
                attr: {
                    key: '140',
                    value: 'Buell'
                }
            },
            {
                attr: {
                    key: '155',
                    value: 'Cagiva'
                }
            },
            {
                attr: {
                    key: '193',
                    value: 'Daelim'
                }
            },
            {
                attr: {
                    key: '218',
                    value: 'Derbi'
                }
            },
            {
                attr: {
                    key: '240',
                    value: 'Ducati'
                }
            },
            {
                attr: {
                    key: '260',
                    value: 'Fantic'
                }
            },
            {
                attr: {
                    key: '297',
                    value: 'Gilera'
                }
            },
            {
                attr: {
                    key: '320',
                    value: 'Harley-Davidson'
                }
            },
            {
                attr: {
                    key: '330',
                    value: 'Hercules'
                }
            },
            {
                attr: {
                    key: '340',
                    value: 'Honda'
                }
            },
            {
                attr: {
                    key: '344',
                    value: 'Hyosung'
                }
            },
            {
                attr: {
                    key: '345',
                    value: 'Hyundai'
                }
            },
            {
                attr: {
                    key: '363',
                    value: 'Italjet'
                }
            },
            {
                attr: {
                    key: '385',
                    value: 'Jawa'
                }
            },
            {
                attr: {
                    key: '420',
                    value: 'Kawasaki'
                }
            },
            {
                attr: {
                    key: '440',
                    value: 'Kreidler'
                }
            },
            {
                attr: {
                    key: '455',
                    value: 'KTM'
                }
            },
            {
                attr: {
                    key: '457',
                    value: 'Kymco'
                }
            },
            {
                attr: {
                    key: '485',
                    value: 'Laverda'
                }
            },
            {
                attr: {
                    key: '510',
                    value: 'Maico'
                }
            },
            {
                attr: {
                    key: '515',
                    value: 'Malaguti'
                }
            },
            {
                attr: {
                    key: '555',
                    value: 'MBK'
                }
            },
            {
                attr: {
                    key: '610',
                    value: 'Moto-Guzzi'
                }
            },
            {
                attr: {
                    key: '615',
                    value: 'Moto-Morini'
                }
            },
            {
                attr: {
                    key: '30',
                    value: 'MV Agusta'
                }
            },
            {
                attr: {
                    key: '625',
                    value: 'MZ'
                }
            },
            {
                attr: {
                    key: '670',
                    value: 'Peugeot'
                }
            },
            {
                attr: {
                    key: '675',
                    value: 'PGO'
                }
            },
            {
                attr: {
                    key: '895',
                    value: 'Piaggio (Vespa)'
                }
            },
            {
                attr: {
                    key: '718',
                    value: 'Puch'
                }
            },
            {
                attr: {
                    key: '742',
                    value: 'Sachs'
                }
            },
            {
                attr: {
                    key: '745',
                    value: 'Sanglas'
                }
            },
            {
                attr: {
                    key: '795',
                    value: 'Simson'
                }
            },
            {
                attr: {
                    key: '802',
                    value: 'Solo'
                }
            },
            {
                attr: {
                    key: '830',
                    value: 'Suzuki'
                }
            },
            {
                attr: {
                    key: '835',
                    value: 'SYM'
                }
            },
            {
                attr: {
                    key: '855',
                    value: 'Tornax'
                }
            },
            {
                attr: {
                    key: '870',
                    value: 'Triumph'
                }
            },
            {
                attr: {
                    key: '950',
                    value: 'Yamaha'
                }
            }
        ]
    },
    {
        attr: {
            key: '4',
            value: 'Грузовик'
        },
        manufacturer: [
            {
                attr: {
                    key: '200',
                    value: 'DAF'
                }
            },
            {
                attr: {
                    key: '285',
                    value: 'Ford'
                }
            },
            {
                attr: {
                    key: '347',
                    value: 'IFA'
                }
            },
            {
                attr: {
                    key: '362',
                    value: 'Isuzu'
                }
            },
            {
                attr: {
                    key: '365',
                    value: 'Iveco'
                }
            },
            {
                attr: {
                    key: '520',
                    value: 'MAN'
                }
            },
            {
                attr: {
                    key: '570',
                    value: 'Mercedes-Benz'
                }
            },
            {
                attr: {
                    key: '590',
                    value: 'Mitsubishi'
                }
            },
            {
                attr: {
                    key: '225',
                    value: 'Nissan'
                }
            },
            {
                attr: {
                    key: '720',
                    value: 'Renault'
                }
            },
            {
                attr: {
                    key: '750',
                    value: 'Scania'
                }
            },
            {
                attr: {
                    key: '905',
                    value: 'Volkswagen'
                }
            },
            {
                attr: {
                    key: '910',
                    value: 'Volvo'
                }
            }
        ]
    },
    {
        attr: {
            key: '5',
            value: 'Автобус'
        },
        manufacturer: [
            {
                attr: {
                    key: '10',
                    value: '-&gt; HST-Benennung eingeben &lt;-'
                }
            },
            {
                attr: {
                    key: '120',
                    value: 'Bova'
                }
            },
            {
                attr: {
                    key: '365',
                    value: 'Iveco'
                }
            },
            {
                attr: {
                    key: '410',
                    value: 'K?ssbohrer/Setra'
                }
            },
            {
                attr: {
                    key: '520',
                    value: 'MAN'
                }
            },
            {
                attr: {
                    key: '570',
                    value: 'Mercedes-Benz'
                }
            },
            {
                attr: {
                    key: '85',
                    value: 'Neoplan'
                }
            },
            {
                attr: {
                    key: '720',
                    value: 'Renault'
                }
            },
            {
                attr: {
                    key: '750',
                    value: 'Scania'
                }
            },
            {
                attr: {
                    key: '910',
                    value: 'Volvo'
                }
            }
        ]
    }
];
