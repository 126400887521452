import React from 'react';
import LazyLoad from 'react-lazyload';
import { parseNameAttachment } from '../../utils/parseNameAttachment';
import { parseNameFormat } from '../../utils/parseNameFormat';
import { DocumentIcon } from '../Icons';
import { formats } from './constants';

import {
    AttachmentItemContainer,
    Overlay,
    BottomText,
    Expand,
    Trash,
    Download,
    Edit,
    Image,
    Text,
    Name,
    IconContainer,
    DocumentTitleContainer,
    MobileIconContainer,
    Check,
    SelectIconContainer,
    Circle
} from './styles';

export type AttachmentStatus = 'uploaded' | 'uploading' | 'uploading-failed';
interface ParsedAttachmentItem {
    base64?: string;
    fileName: string;
    status?: AttachmentStatus;
}

export interface AttachmentProps {
    attachment: ParsedAttachmentItem;
    onClickExpand?: () => void;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
    onClickDownload?: () => void;
    onClickSelect?: () => void;
    index: number;
    offsetLazy?: number;
    small?: boolean;
    selected?: boolean;
    reverse?: boolean;
    fullIcon?: boolean;
}

export const Attachment: React.FC<AttachmentProps> = ({
    attachment,
    index,
    offsetLazy,
    onClickExpand,
    onClickEdit,
    onClickDelete,
    onClickDownload,
    onClickSelect,
    selected,
    reverse,
    fullIcon,
    ...rest
}) => {
    const { base64, status, fileName } = attachment;
    const format = parseNameFormat(fileName);
    const name = parseNameAttachment(fileName);
    const isFormatText = formats.includes(format);

    const getAttachment = () => (
        <AttachmentItemContainer isFormatText={isFormatText} {...rest}>
            {isFormatText ? (
                <>
                    <MobileIconContainer>
                        <IconContainer>
                            <Edit height="24" onClick={onClickEdit} />
                        </IconContainer>

                        <IconContainer>
                            <Trash height="24" onClick={onClickDelete} />
                        </IconContainer>
                    </MobileIconContainer>

                    <DocumentTitleContainer>
                        <DocumentIcon />
                        <Text>{format}</Text>
                    </DocumentTitleContainer>
                </>
            ) : (
                <Image src={base64} />
            )}
            {isFormatText && <Name>{name}</Name>}

            <Overlay status={status} isFormatText={isFormatText} selected={selected} reverse={reverse}>
                {onClickExpand && !isFormatText && (
                    <IconContainer fullIcon={fullIcon}>
                        <Expand onClick={onClickExpand} height="24" />
                    </IconContainer>
                )}

                {onClickEdit && !isFormatText && (
                    <IconContainer fullIcon={fullIcon}>
                        <Edit height="24" onClick={onClickEdit} />
                    </IconContainer>
                )}

                {onClickDownload && (
                    <IconContainer fullIcon={fullIcon}>
                        <Download height="24" onClick={onClickDownload} />
                    </IconContainer>
                )}

                {onClickDelete && (
                    <IconContainer fullIcon={fullIcon}>
                        <Trash height="24" onClick={onClickDelete} />
                    </IconContainer>
                )}

                {onClickSelect && (
                    <SelectIconContainer onClick={onClickSelect} fullIcon={fullIcon}>
                        {selected ? <Check height="24" /> : <Circle height="24" />}
                    </SelectIconContainer>
                )}

                <BottomText hide={isFormatText}>{String(index + 1).padStart(2, '0')}</BottomText>
            </Overlay>
        </AttachmentItemContainer>
    );

    return offsetLazy ? <LazyLoad offset={offsetLazy}>{getAttachment()}</LazyLoad> : getAttachment();
};
