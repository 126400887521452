import React, { memo } from 'react';
import { useStore } from 'effector-react';
import { Button, Col, Row, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { graphicDamageSelectionModel } from '../../../stores/graphicDamageSelectionModel';
import { rimsListModel } from '../../../stores/rimsListModel';
import { equipmentModel } from '../../../stores/equipmentModel';
import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { isDVNRims } from '../../../utils/isDVNRims';
import { commonModel } from '../../../stores/commonModel';
import { rightSideDrawerModel } from '../../../stores/rightSideDrawerModel';
import { getSvgElementByDatId } from '@dat/dat-svg/src/utils/getSvgElementByDatId';
import { SwitchDvnRepairView } from './SwitchDvnRepairView';
import { RimsEquipmentView } from '../../RimsView/RimsEquipmentView';
import { EquipmentTable } from '../../EquipmentList/EquipmentTable';
import { DATProcessIdCommentView } from './DATProcessIdCommentView';
import { ObjectInfo } from '../../../types/svgGrapaTypes';
import { RepairPositionsList } from '../../RepairPositionsList/RepairPositionsList';
import { DvnNotFound, GraphicSetDamageForms } from '../GraphicSetDamageForms';
import { DATProcessIdCommentListModel } from '../../../stores/DATProcessIdCommentListModel';

import { splitOnTwoParts } from '../../../utils/splitOnTwoParts';
import { ButtonWithMark } from '../../CommonComponents/ButtonWithMark/ButtonWithMark';
import { sparePartVariantsModel } from '../../../stores/sparePartVariantsModel';

const { SvgLoader } = require('@wedat/react-svgmt');

function setCurrentDVN(dvn?: number, currentAssemblyGroupObject?: ObjectInfo) {
    if (!!dvn && !!currentAssemblyGroupObject) {
        graphicDamageSelectionModel.events.updateStore({ currentDVNs: [dvn], currentAssemblyGroupObject });
        rightSideDrawerModel.events.setHistoryView(GraphicSetDamageForms);
    }
}

export const SetDamageDVNPartView = memo(
    () => {
        const { t } = useTranslation();

        const { currentAssemblyGroup, currentAssemblyGroupObjects, currentAssemblyGroupObject, currentDVNs } = useStore(
            graphicDamageSelectionModel.stores.graphicDamageSelectionModelStore
        );
        const isMobile = useStore(commonModel.stores.isMobile);
        const eqIdRim = useStore(rimsListModel.stores.eqIdRim);
        const repairPositions = useStore(RepairPositionsModel.store.repairPositions);
        const listDVNsRepPos = repairPositions.map(repPos => repPos.DATProcessId);
        const isRepPosModified = useStore(RepairPositionsModel.store.isModified);
        const isCommentModified = useStore(DATProcessIdCommentListModel.store.isModified);
        const isEquipmentModified = useStore(equipmentModel.stores.isModified);
        const isModified = isRepPosModified || isCommentModified || isEquipmentModified;
        const getSparePartVariantsFxPending = useStore(sparePartVariantsModel.effects.getSparePartVariantsFx.pending);
        const dataEquipment = useStore(equipmentModel.stores.dataEquipment);

        const currentObj = currentAssemblyGroupObject;
        if (!currentObj) return <DvnNotFound />;
        const { isSplitalble, leftPart, rightPart } = splitOnTwoParts(currentObj.titleMenu);
        const isOneElementInList = currentAssemblyGroupObjects?.length === 1 && !isSplitalble;

        if (currentDVNs?.length !== 1) return <DvnNotFound />;
        const dvn = currentDVNs[0];
        const dvnLR = currentObj?.dvnLeft?.dvn === dvn ? currentObj?.dvnLeft : currentObj?.dvnRight;

        let filteredDataSource = dataEquipment.filter(equipment =>
            equipment.dvns?.find(dvn => currentDVNs.includes(dvn))
        );

        if (isDVNRims(dvnLR?.dvn)) {
            filteredDataSource = filteredDataSource.filter(
                eq => !eqIdRim.find(eqRim => eqRim.id === eq.DatEquipmentId?.toString())
            );
        }

        const showLeftRightSelect = !isSplitalble && !!currentObj.dvnLeft?.dvn && !!currentObj.dvnRight?.dvn;

        let description = currentObj.titleMenu || '';
        if (isSplitalble && currentObj.dvnLeft?.dvn === dvn) description = leftPart;
        if (isSplitalble && currentObj.dvnRight?.dvn === dvn) description = rightPart;

        return (
            <div style={{ margin: '8px 8px 8px 8px' }}>
                <div
                    style={{
                        paddingRight: '4px',
                        marginBottom: '8px',
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px'
                    }}
                >
                    <Row gutter={[4, 8]} align="top">
                        <Col span={20}>
                            <div style={{ float: 'left' }}>
                                <Button
                                    size="large"
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        if (!isOneElementInList) {
                                            graphicDamageSelectionModel.events.updateStore({ currentDVNs: undefined });
                                            // commonModel.events.confirm();
                                            rightSideDrawerModel.events.backView();
                                        } else {
                                            // commonModel.events.cancel();
                                            rightSideDrawerModel.events.backView();
                                        }
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    textAlign: 'left',
                                    border: '0px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'normal',
                                    height: '64px',
                                    display: 'block'
                                }}
                                className="ant-btn ant-btn-lg"
                            >
                                {description}
                            </div>
                        </Col>

                        <Col span={4}>
                            <SvgLoader
                                style={{ maxWidth: '600px', maxHeight: '64px' }}
                                svgXML={
                                    getSvgElementByDatId(currentAssemblyGroup?.svg, [currentAssemblyGroupObject.datid])
                                        ?.resultSvg
                                }
                            />
                        </Col>

                        {showLeftRightSelect && (
                            <Col span={24}>
                                <Row gutter={[0, 8]}>
                                    {!!currentObj.dvnLeft?.dvn && !!currentObj.dvnRight?.dvn && (
                                        <>
                                            <Col span={12}>
                                                <ButtonWithMark
                                                    leftMark={listDVNsRepPos.includes(currentObj.dvnLeft?.dvn)}
                                                    type={currentObj.dvnLeft?.dvn === dvn ? 'primary' : 'default'}
                                                    style={{
                                                        width: '100%',
                                                        height: '40px',
                                                        borderTopRightRadius: '0px',
                                                        borderBottomRightRadius: '0px'
                                                    }}
                                                    size={'large'}
                                                    onClick={() => setCurrentDVN(currentObj.dvnLeft?.dvn, currentObj)}
                                                >
                                                    {t('PartSelectionDialog.L')}
                                                </ButtonWithMark>
                                            </Col>
                                            <Col span={12}>
                                                <ButtonWithMark
                                                    rightMark={listDVNsRepPos.includes(currentObj.dvnRight?.dvn)}
                                                    type={currentObj.dvnRight?.dvn === dvn ? 'primary' : 'default'}
                                                    style={{
                                                        width: '100%',
                                                        height: '40px',
                                                        borderTopLeftRadius: '0px',
                                                        borderBottomLeftRadius: '0px'
                                                    }}
                                                    size={'large'}
                                                    onClick={() => setCurrentDVN(currentObj.dvnRight?.dvn, currentObj)}
                                                >
                                                    {t('PartSelectionDialog.R')}
                                                </ButtonWithMark>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </Col>
                        )}
                    </Row>
                </div>
                <div
                    style={{
                        paddingRight: '4px',
                        position: 'absolute',
                        top: showLeftRightSelect ? '140px' : '68px',
                        left: '0px',
                        right: '0px',
                        bottom: '116px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <SwitchDvnRepairView />

                    <Row gutter={[8, 16]}>
                        {isDVNRims(dvnLR?.dvn) && (
                            <Col span={24}>
                                <RimsEquipmentView />
                            </Col>
                        )}
                        <Col span={24}>
                            {getSparePartVariantsFxPending ? (
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <Spin />
                                </div>
                            ) : (
                                filteredDataSource.length >= 1 && (
                                    <>
                                        <h3>{t('EquipmentList.title')}</h3>
                                        <EquipmentTable debugging={false} filteredDataSource={filteredDataSource} />
                                    </>
                                )
                            )}
                        </Col>
                    </Row>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        // top: '170px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={24}>
                            <DATProcessIdCommentView />
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (!isOneElementInList) {
                                        graphicDamageSelectionModel.events.updateStore({ currentDVNs: undefined });
                                        commonModel.events.confirmWithEquipmentCheck();
                                    } else {
                                        commonModel.events.cancel();
                                        rightSideDrawerModel.events.backView();
                                    }
                                }}
                            >
                                {t('ManualRepairPositionForm.Cancel')}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    if (isMobile) rightSideDrawerModel.events.clearHistoryAndClose();
                                    else rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                                    commonModel.events.confirmWithEquipmentCheck();
                                }}
                            >
                                {t('ManualRepairPositionForm.Confirm')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);
