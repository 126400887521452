import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { AccordionItem } from '@wedat/ui-kit/components/Accordion';
import { PlusIcon } from '@wedat/ui-kit/components/Icons';
import { modalsEvents, modalsStores } from '../../../stores/modals';
import { attachmentsEvents, attachmentsStores } from '../../../stores/attachments';
import { printReportsEvents, printReportsStore } from '../../../stores/printReports';
import { Attachment } from '../../Attachment';
import { IconWrapper } from '../../Main/styles';
import { AttachmentsTitleWrapper, AttachmentsTitle, Attachments, StyledAccordion, AttachmentsCount } from './styles';

const { removeItemFromAttachments } = attachmentsEvents;
const { removeItemFromReports } = printReportsEvents;
const { toggleIsAttachments, toggleIsContractListOpen, toggleIsContractListCCOpen, toggleIsContractListCCNOpen } =
    modalsEvents;

export const AttachmentsBlock: React.FC = () => {
    const { t } = useTranslation();

    const isAttachmentsOpen = useStore(modalsStores.isAttachmentsOpen);
    const selectedReports = useStore(printReportsStore.selectedReports);
    const selectedAttachments = useStore(attachmentsStores.selectedAttachments);

    const handleToogleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        toggleIsAttachments();
        toggleIsContractListOpen(false);
        toggleIsContractListCCOpen(false);
        toggleIsContractListCCNOpen(false);
    };

    const selectedAttachmentsCount = selectedAttachments.length + selectedReports.length;

    return (
        <StyledAccordion allowZeroExpanded>
            <AccordionItem
                uuid="Attachments"
                withoutArrowIcon={!selectedReports.length && !selectedAttachments.length}
                header={
                    <AttachmentsTitleWrapper
                        onClick={e => {
                            if (!selectedReports.length && !selectedAttachments.length) {
                                e.stopPropagation();
                            }
                        }}
                    >
                        <IconWrapper onClick={handleToogleOpen} active={isAttachmentsOpen}>
                            <PlusIcon />
                        </IconWrapper>
                        <AttachmentsTitle>{t('titles.attachments')}</AttachmentsTitle>
                        {selectedAttachmentsCount > 0 && (
                            <AttachmentsCount font="note" textOverflow="ellipsis">
                                {t('inputs.attachmentsCount', { count: selectedAttachmentsCount })}
                            </AttachmentsCount>
                        )}
                    </AttachmentsTitleWrapper>
                }
            >
                {selectedAttachmentsCount > 0 && (
                    <Attachments>
                        {selectedReports.map(report => (
                            <Attachment
                                attachment={{
                                    fileName: `${report.reportName}.report`
                                }}
                                onClick={() => removeItemFromReports(report)}
                                small
                                trashIcon
                                key={report.id}
                            />
                        ))}
                        {selectedAttachments.map(attachment => (
                            <Attachment
                                attachment={attachment}
                                onClick={() => removeItemFromAttachments(attachment)}
                                small
                                trashIcon
                                key={attachment.id}
                            />
                        ))}
                    </Attachments>
                )}
            </AccordionItem>
        </StyledAccordion>
    );
};
