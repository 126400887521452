import React, { FC } from 'react';
import { useTable, useRowSelect, useFilters, useGlobalFilter, useExpanded, Column, Row } from 'react-table';
import { Title } from '../Price/styles';
import { GlobalFilter } from './GlobalFilter';
import { Checkbox } from '@wedat/ui-kit/components/Checkbox';
import { TableContainer, TableRow, TableHead, ArrowIconContainer, MobileTd } from './styles';
import { useMedia } from '@dat/core/hooks/useMedia';
import { CellType, TableData } from '../../types/table';
import ArrowIcon from '@wedat/ui-kit/assets/Icon/Arrow';
import { sizes } from '@wedat/ui-kit/mediaQueries';

interface Props {
    columns: Column<TableData>[];
    data: TableData[];
    title: string;
    isLabour?: boolean;
    isPaintWork?: boolean;
}

export const Table: FC<Props> = ({ columns, data, title, isLabour, isPaintWork }) => {
    const isMobile = useMedia(sizes.phone);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } =
        useTable<TableData>(
            {
                columns,
                data
            },
            useFilters,
            useGlobalFilter,
            useExpanded,
            useRowSelect,
            hooks => {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        Cell: ({ row }: CellType) => <Checkbox {...row.getToggleRowSelectedProps()} />
                    },
                    ...columns
                ]);
            }
        );

    const mobileRowRenderer = (row: Row<TableData>, expanded: boolean) => (
        <>
            <MobileTd className="first">{row.original.col1}</MobileTd>
            <MobileTd className="second">{row.original.col2}</MobileTd>
            <MobileTd className="third">{row.original.col3}</MobileTd>
            <ArrowIconContainer
                onClick={e => {
                    e.stopPropagation();
                    row.toggleRowExpanded();
                }}
                isRowExpanded={expanded}
            >
                <ArrowIcon />
            </ArrowIconContainer>
            {expanded && (
                <>
                    {row.original.children?.[0].col4}
                    {row.original.children?.[0].col5}
                    {row.original.children?.[0].col6}
                    {row.original.children?.[0].col7}
                    {row.original.children?.[0].col8}
                    {row.original.children?.[0].col9}
                </>
            )}
        </>
    );

    return (
        <>
            <Title>{title}</Title>
            <GlobalFilter globalFilter={state} setGlobalFilter={setGlobalFilter} />
            <TableContainer isLabour={isLabour} isPaintWork={isPaintWork}>
                <table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </TableHead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <TableRow
                                    {...row.getRowProps()}
                                    onClick={() => {
                                        if (!isMobile) return;
                                        row.toggleRowSelected(!row.isSelected);
                                    }}
                                    isSelected={row.getToggleRowSelectedProps().checked}
                                    isExpanded={row.isExpanded}
                                >
                                    {isMobile
                                        ? mobileRowRenderer(row, row.isExpanded)
                                        : row.cells.map(cell => (
                                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                          ))}
                                </TableRow>
                            );
                        })}
                    </tbody>
                </table>
            </TableContainer>
        </>
    );
};
