export const scrollElement = (selector: string, scrollStep: number, direction: 'up' | 'down' | 'left' | 'right') => {
    const element = document.querySelector(selector);
    if (!element) return -1000;

    let newScrollVal = -1000;
    if (direction === 'up') {
        newScrollVal = element.scrollTop - scrollStep;
        element.scrollTop = newScrollVal;
    } else if (direction === 'down') {
        newScrollVal = element.scrollTop + scrollStep;
        element.scrollTop = newScrollVal;
    } else if (direction === 'left') {
        newScrollVal = element.scrollLeft - scrollStep;
        element.scrollLeft = newScrollVal;
    } else if (direction === 'right') {
        newScrollVal = element.scrollLeft + scrollStep;
        element.scrollLeft = newScrollVal;
    }
    return newScrollVal;
};

// Check if an element has reached the end of its possible scroll.
// Params: 'selector' - element's css selector;
//         'newScrollVal' - current scroll position (px)
//         'direction' - scroll direction
export const checkIfScrollEnd = (
    selector: string,
    newScrollVal: number,
    direction: 'up' | 'down' | 'left' | 'right'
) => {
    const element = document.querySelector(selector);
    if (!element) return false;
    if (direction === 'up') {
        return newScrollVal < 5;
    } else if (direction === 'down') {
        return element.scrollHeight - newScrollVal - element.clientHeight < 5;
    } else if (direction === 'left') {
        return newScrollVal < 5;
    } else if (direction === 'right') {
        return element.scrollWidth - newScrollVal - element.clientWidth < 5;
    }
    return false;
};
