import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

interface Props {
    isLaptop?: boolean;
}

export const AdministrationWrapper = styled.div<Props>`
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: ${props => props.isLaptop && 'column'};

    padding: 24px;
`;

export const Content = styled.div<Props>`
    width: ${props => (props.isLaptop ? '100%' : '82%')};
    min-height: 100vh;

    background: ${({ theme }) => theme.colors.white};
    padding: 16px;
    margin-left: 10px;

    border-radius: 15px;
`;

export const IconWrapper = styled.div`
    height: 48px;
    width: 48px;

    position: absolute;
    top: 15px;
    left: 130px;
    z-index: 1005;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.dustBlue['900']};
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 8px;
    cursor: pointer;

    ${media.laptop`
       width: 38px;
       height: 38px;

       top: 10px;
    `}
`;
