import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { Text } from '@wedat/ui-kit/components/Text';

export const Buttons = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ButtonStyled = styled(Button)`
    width: 98px;
`;

export const TableWrapper = styled.div`
    margin-bottom: 30px;
`;

export const Title = styled(Text)`
    margin-bottom: 20px;
`;

export const Description = styled(Text)`
    &:not(:last-of-type) {
        margin-bottom: 6px;
    }
`;

export const UnderlineText = styled.span`
    font-weight: 400;
    text-decoration: underline;
`;
