import { DatProductsUrls } from '@dat/core/constants/enums';

import { templateMyClaim, templateVehicle, templateValuateFinance } from '../templates';

import { TemplateParam } from '../templates/types';
import { stores } from '@dat/api2/stores/apiStores';

// Types
type SoapValue = string | number | SoapValues | SoapValue[] | undefined;

export interface SoapValues {
    [name: string]: SoapValue;
}

export interface SoapRequestData<V extends SoapValues> {
    product: DatProductsUrls;
    method: string;
    values?: V;
}

interface GetTemplateParam extends TemplateParam {
    product: DatProductsUrls;
}

// Parsing functions
function getTemplate({ product, ...templateData }: GetTemplateParam) {
    switch (product) {
        case DatProductsUrls.MyClaim:
            return templateMyClaim(templateData);
        case DatProductsUrls.VehicleRepair:
            return templateVehicle(templateData);
        case DatProductsUrls.ValuateFinance:
            return templateValuateFinance(templateData);
    }
}

function getValuesString(values: SoapValues = {}): string {
    return Object.keys(values).reduce<string>((acc, key) => acc + getFieldString(key, values[key]), '');
}

function getFieldString(key: string, value: SoapValue): string {
    if (!value) {
        return '';
    } else if (Array.isArray(value)) {
        return value.reduce<string>((acc, next) => acc + getFieldString(key, next), '');
    } else if (typeof value === 'object') {
        return `<${key}>${getValuesString(value)}</${key}>`;
    } else {
        return `<${key}>${value}</${key}>`;
    }
}

// Main
export const createSoapRequest = <V extends SoapValues>({ product, method, values }: SoapRequestData<V>) => {
    const datCountryIndicator = stores.country.getState();
    const [languageCode] = stores.locale.getState().split('-');

    // Making request body with XML fields
    // <fieldName>value</fieldName>
    const valuesString = getValuesString(values);
    const localeString = `<locale country="${datCountryIndicator}" datCountryIndicator="${datCountryIndicator}" language="${languageCode}" />`;

    return getTemplate({
        product,
        method,
        valuesString,
        localeString
    });
};
