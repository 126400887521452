import { RACE, TAKE_FIRST, TAKE_LAST } from './strategy';
export class ReEffectError extends Error {
    constructor(message) {
        super(message);
        delete this.stack;
    }
}
export class CancelledError extends ReEffectError {
    constructor(strategy = 'cancel') {
        super('Cancelled due to "' + strategy + '"' + ({
            [TAKE_FIRST]: `, there are already running effects`,
            [TAKE_LAST]: `, new effect was added`,
            [RACE]: `, other effect won race`,
            cancel: `, cancel all already running effects`,
        }[strategy] || `, but should not happen...`));
    }
}
export class LimitExceededError extends ReEffectError {
    constructor(limit, running) {
        super('Cancelled due to limit of "' + limit + '" exceeded,' +
            'there are already ' + running + ' running effects');
    }
}
export class TimeoutError extends ReEffectError {
    constructor(timeout) {
        super('Cancelled due to timeout of "' + timeout + '"ms');
    }
}
