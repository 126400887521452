import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@wedat/ui-kit/components/Tabs';
import { AttachmentsTabs, attachmentsTabsValues } from '../../constants/attachments';
import { Printout } from './Printout';
import { Gallery } from './Gallery';
import { Container, TabsHeaderWrapper } from './styles';

interface Props {
    isTablet: boolean;
}

export const Attachments: React.FC<Props> = ({ isTablet }) => {
    const { t } = useTranslation();
    const [activeValue, setActiveValue] = useState<AttachmentsTabs>(AttachmentsTabs.Gallery);

    const tabsHandler = (id: string) => {
        setActiveValue(id as AttachmentsTabs);
    };

    return (
        <Container>
            <TabsHeaderWrapper>
                <Tabs values={attachmentsTabsValues(t)} onChange={tabsHandler} active={activeValue} />
            </TabsHeaderWrapper>
            {activeValue === AttachmentsTabs.Gallery && <Gallery isTablet={isTablet} />}
            {activeValue === AttachmentsTabs.Printreports && <Printout isTablet={isTablet} />}
        </Container>
    );
};
