import { sample } from 'effector';
import { sharedVehicleStores } from '@dat/shared-models/contract/Dossier/Vehicle';
import { formEvents } from '../form';
import { contractStores } from '@dat/shared-models/contract';
import { valuateStores } from './index';
import { evaluationEffects } from '@dat/valuate-finance/src/stores/evaluation';
import { modalsEvents } from '../modals';
import { historyEvents } from '../history';
import { SUB_ROUTES_PATH_NAMES } from '../../constants/router';
import { getValuateData } from '../../utils/getValuateData';
import { getISODateString } from '@dat/core/utils';
import { sharedEquipmentStores } from '@dat/shared-models/equipment';

const { valuationFormSubmitted } = formEvents;
const { datECode, vehicle } = sharedVehicleStores;
const { vehicleTranslationEquipment } = sharedEquipmentStores;
const { contract } = contractStores;
const { redirectedValuate } = valuateStores;

const { requestDataValuate } = valuateStores;

const { getVehicleEvaluationFx } = evaluationEffects;

sample({
    clock: valuationFormSubmitted,
    source: { datECode, vehicle, vehicleTranslationEquipment, contract },
    fn: ({ datECode, vehicle, vehicleTranslationEquipment, contract }, formikValues) =>
        getValuateData({
            datECode,
            vehicle,
            equipment: vehicleTranslationEquipment,
            contract,
            values: formikValues
        }),
    target: requestDataValuate
});

sample({
    source: { redirectedValuate, requestDataValuate },
    filter: ({ requestDataValuate, redirectedValuate }) =>
        Object.keys(requestDataValuate).length > 0 && !redirectedValuate,
    fn: ({ requestDataValuate }) => ({
        ...requestDataValuate,
        evaluationDate: requestDataValuate.evaluationDate || getISODateString(new Date())
    }),
    target: getVehicleEvaluationFx
});

sample({
    clock: getVehicleEvaluationFx.doneData,
    fn: () => {
        modalsEvents.toggleIsValuationModalOpen(false);
        historyEvents.push([SUB_ROUTES_PATH_NAMES.claim.valuation]);
    }
});
