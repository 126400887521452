import React from 'react';
import _ from 'lodash';
import { useStore } from 'effector-react';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { CalendarNew } from '@wedat/ui-kit/assets/Icon';

import {
    BaseInfoWrapper,
    CalendarDate,
    CardCarStyled,
    CarOwner,
    ColumnWrapper,
    DamageNumber,
    DateFormatWrapper,
    DesktopImage,
    EfficiencyWrapper,
    IconColumnWrapper,
    IconWrapper,
    ImageWrapper,
    Information,
    InsuranceName,
    MainInfoColumnWrapper,
    NumberPlate,
    VehicleModel,
    StatusName
} from './styles';

import { ListItemInternalProps } from '../types';
import { GalleryIcon } from '../../../../assets/icons';
import { ReloadIcon } from '@wedat/ui-kit/components/Icons';
import { ExpiryDateFormat } from '../../ExpiryDateFormat';
import { getEfficiencyColor } from '../../../../utils/getEfficiencyColor';
import { pluginStores } from '../../../../stores/plugin';
import { useMedia } from '@dat/core/hooks/useMedia';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { Printout } from '@dat/printout';
import { sharedGalleryEvents } from '@dat/shared-models/gallery';
import { useTranslation } from 'react-i18next';
import { EXPIRE_YELLOW_FLAG_DEFAULT } from '../../ExpiryDateFormat/constants';
import { DateFormat } from '../../DateFormat';

export const Desktop: React.FC<ListItemInternalProps> = ({
    contract,
    onContractClick,
    onContractDbClick,
    onStatusClick
}) => {
    const { t } = useTranslation();
    const isTablet = useMedia(sizes.tablet);
    const {
        carOwner,
        opened,
        id: contractId,
        lastEntry,
        registrationNumber: numberPlate,
        referenceNumber,
        statusName,
        registrationNumber,
        vin,
        lossNumber,
        vehicle,
        templateFieldAndValue
    } = contract;
    const expireYellowFlag =
        useStore(pluginStores.pluginOptions)?.settings?.efficiency?.expireYellowFlag || EXPIRE_YELLOW_FLAG_DEFAULT;
    const templates = useStore(sharedTemplateStores.availableTemplates);
    const disabledStatuses = templates.default.settings.disabledStatuses;
    const isDisabled = Boolean(statusName && disabledStatuses?.includes(statusName));
    const expiryDate = templateFieldAndValue?.Date_expiryDate
        ? new Date(Number(templateFieldAndValue?.Date_expiryDate))
        : undefined;
    const expiryStatus = Number(templateFieldAndValue?.Integer_expiryStatus);

    const renderOpenedDate = () => (
        <>
            <CalendarNew width={18} height={18} />
            <DateFormatWrapper>
                <DateFormat>{new Date(opened).toISOString()}</DateFormat>
            </DateFormatWrapper>
        </>
    );

    const renderModifiedDate = () => (
        <>
            <ReloadIcon width={18} height={18} />
            <DateFormatWrapper>
                <DateFormat>{new Date(lastEntry).toISOString()}</DateFormat>
            </DateFormatWrapper>
        </>
    );

    return (
        <CardCarStyled disabled={isDisabled}>
            <BaseInfoWrapper>
                <EfficiencyWrapper
                    color={getEfficiencyColor({ date: expiryDate, efficiencyStatus: expiryStatus, expireYellowFlag })}
                />
                <ImageWrapper
                    onClick={isDisabled ? _.noop : onContractClick}
                    onDoubleClick={isDisabled ? _.noop : onContractDbClick}
                >
                    <DesktopImage />
                    {numberPlate && (
                        <NumberPlate font="footnote" fontWeight={700} textTransform="uppercase">
                            {numberPlate}
                        </NumberPlate>
                    )}
                </ImageWrapper>
                <Information>
                    <MainInfoColumnWrapper
                        onClick={isDisabled ? _.noop : onContractClick}
                        onDoubleClick={isDisabled ? _.noop : onContractDbClick}
                    >
                        <CarOwner fontWeight={700}>{carOwner && carOwner.trim() ? carOwner : '---'}</CarOwner>

                        <VehicleModel font="font12">{vehicle}</VehicleModel>

                        <StatusName
                            onClick={
                                isDisabled
                                    ? _.noop
                                    : event => {
                                          event.stopPropagation();
                                          onStatusClick();
                                      }
                            }
                        >
                            {t(`claim-management:status.${statusName}`)}
                        </StatusName>
                    </MainInfoColumnWrapper>
                    {isTablet ? (
                        <>
                            <ColumnWrapper>
                                <ExpiryDateFormat date={expiryDate} efficiencyStatus={expiryStatus} />
                                <div style={{ marginTop: '10px' }}>{referenceNumber}</div>
                            </ColumnWrapper>
                            <ColumnWrapper>
                                {renderModifiedDate()}
                                <div style={{ marginTop: '10px' }}>{templateFieldAndValue?.String_insurance_name}</div>
                            </ColumnWrapper>
                        </>
                    ) : (
                        <>
                            <ColumnWrapper>{referenceNumber}</ColumnWrapper>
                            <DamageNumber>{lossNumber}</DamageNumber>
                            <InsuranceName>{templateFieldAndValue?.String_insurance_name}</InsuranceName>
                            <CalendarDate hiddingPoint={1485}>{renderOpenedDate()}</CalendarDate>
                            <CalendarDate>
                                <ExpiryDateFormat date={expiryDate} efficiencyStatus={expiryStatus} />
                            </CalendarDate>
                            <CalendarDate hiddingPoint={1325}>{renderModifiedDate()}</CalendarDate>
                        </>
                    )}
                    <IconColumnWrapper>
                        <IconWrapper>
                            <Printout
                                options={{
                                    contractId,
                                    registrationNumber,
                                    vin,
                                    displayTitle: false,
                                    menuSide: 'left'
                                }}
                            />
                        </IconWrapper>
                        <IconWrapper>
                            <GalleryIcon
                                width={20}
                                onClick={() => {
                                    if (contractId) sharedGalleryEvents.setSelectedContractId(contractId);
                                }}
                            />
                        </IconWrapper>
                    </IconColumnWrapper>
                </Information>
            </BaseInfoWrapper>
        </CardCarStyled>
    );
};
