import axios from '../datAxios';
import { MyClaimServices, DatProductsPaths, VehicleRepairServices } from '../constants';

export const getContract = (data: DAT2.Request.GetContract) =>
    axios<DAT2.Response.GetContract>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'getContract',
        data,
        withRequestTag: false
    });

export const createOrUpdateContract = (data: DAT2.Request.CreateOrUpdateContract) =>
    axios<DAT2.Response.CreateOrUpdateContract>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'createOrUpdateContract',
        data,
        withRequestTag: false,
        breakLines: true
    });

export const uploadAttachmentByFolderID = (data: DAT2.Request.UploadAttachmentByFolderID) =>
    axios<DAT2.Response.UploadAttachmentByFolderID>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'uploadAttachmentByFolderID',
        data,
        withRequestTag: false,
        breakLines: true
    });

export const listAttachmentsOfContract = (data: DAT2.Request.ListAttachmentsOfContract) =>
    axios<DAT2.Response.ListAttachmentsOfContract>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'listAttachmentsOfContract',
        data,
        withRequestTag: false,
        breakLines: true
    });

export const listContracts = (data: DAT2.Request.ListContracts) =>
    axios<DAT2.Response.ListContracts>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'listContracts',
        data,
        withRequestTag: false
    });

export const listInvoiceRates = (data: DAT2.Request.ListInvoiceRates) =>
    axios<DAT2.Response.ListInvoiceRates>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'listInvoiceRates ',
        data,
        withRequestTag: false
    });

export const getPossibleContractStatusTransitions = (data: DAT2.Request.GetPossibleContractStatusTransitions) =>
    axios<DAT2.Response.GetPossibleContractStatusTransitions>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'getPossibleContractStatusTransitions ',
        data,
        withRequestTag: false
    });

export const changeContractStatus = (data: DAT2.Request.ChangeContractStatus) =>
    axios<DAT2.Response.ChangeContractStatus>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'changeContractStatus ',
        data,
        withRequestTag: false
    });

export const listPartners = (data?: DAT2.Request.ListPartners) =>
    axios<DAT2.Response.ListPartners>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'listPartners ',
        data,
        withRequestTag: false
    });

export const getPrintReport = (data?: DAT2.Request.GetPrintReport) =>
    axios<DAT2.Response.GetPrintReport>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'getPrintReport ',
        data,
        withRequestTag: false
    });

export const assignPartnerToContract = (data: DAT2.Request.AssignPartnerToContract) =>
    axios<DAT2.Response.AssignPartnerToContract>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'assignPartnerToContract ',
        data,
        withRequestTag: false
    });

export const assignPartnerByCustomerNumber = (data: DAT2.Request.AssignPartnerByCustomerNumber) =>
    axios<DAT2.Response.AssignPartnerByCustomerNumber>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'assignPartnerByCustomerNumber ',
        data,
        withRequestTag: false
    });

export const unAssignPartnerFromContract = (data: DAT2.Request.UnAssignPartnerFromContract) =>
    axios<DAT2.Response.UnAssignPartnerFromContract>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'unAssignPartnerFromContract ',
        data,
        withRequestTag: false
    });

export const listContractHistory = (data: DAT2.Request.ListContractHistory) =>
    axios<DAT2.Response.ListContractHistory>({
        product: DatProductsPaths.MyClaim,
        service: MyClaimServices.MyClaimExternalService,
        method: 'listContractHistory',
        data,
        withRequestTag: false
    });

export const addManualPositions = (data: DAT2.Request.AddManualPositions) =>
    axios<unknown>({
        product: DatProductsPaths.MyClaim,
        service: VehicleRepairServices.VehicleRepairItalyService,
        method: 'addManualPositions',
        data
    });

export const deleteAccesoryParts = (data: DAT2.Request.DeleteAccesoryParts) =>
    axios<unknown>({
        product: DatProductsPaths.MyClaim,
        service: VehicleRepairServices.VehicleRepairItalyService,
        method: 'deleteAccesoryParts',
        data
    });

export const addWorkBench = (data: DAT2.Request.AddWorkBench) =>
    axios<DAT2.Response.AddWorkBench>({
        product: DatProductsPaths.MyClaim,
        service: VehicleRepairServices.VehicleRepairItalyService,
        method: 'addWorkBench',
        data
    });

export const addWorkLogic = (data: DAT2.Request.AddWorkLogic) =>
    axios<DAT2.Response.AddWorkLogic>({
        product: DatProductsPaths.MyClaim,
        service: VehicleRepairServices.VehicleRepairItalyService,
        method: 'addWorkLogic',
        data
    });
