import React from 'react';
import _ from 'lodash';
import { ReloadIcon, SearchIcon } from '@wedat/ui-kit/components/Icons';
import { NewOrder } from '../../components/NewOrder';
import { contractsListEffects, contractsListEvents } from '../../stores/contractsList';
import { NewOrderWrapper, IconWrapper } from './styles';
import { FooterMobile } from '@dat/smart-components/FooterMobile';
import { ProfileAddressBook } from '../ProfileAdressBook';

export const MobileBottomMenu: React.FC = () => {
    const handleSearchToggleMobile = () => contractsListEvents.toggleMobileSearch();
    const handleReload = () => contractsListEffects.reloadContractsFx();

    const menu = [
        {
            component: () => (
                <IconWrapper onClick={handleSearchToggleMobile}>
                    <SearchIcon />
                </IconWrapper>
            )
        },
        {
            component: ProfileAddressBook
        },
        {
            component: () => (
                <IconWrapper onClick={handleReload}>
                    <ReloadIcon />
                </IconWrapper>
            )
        }
    ];

    return (
        <>
            <NewOrderWrapper>
                <NewOrder withoutText fillHeight />
            </NewOrderWrapper>

            <FooterMobile
                menu={menu}
                isOpenBurgerMenu={false}
                toggleOpenBurgerMenu={_.noop}
                setOpenBurgerMenu={_.noop}
                disableBurgerMenu
                high={false}
            />
        </>
    );
};
