import React, { FC, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Select, SelectOption } from '@wedat/ui-kit/components/Select';
import { sharedPartnersStores } from '@dat/shared-models/contract/Partners';
import { getSelectOptions } from '@dat/shared-models/scheduler/utils';
import { SelectStyled } from './styles';

interface Props {
    onChange: (selection: number) => void;
}

export const SelectAssignee: FC<Props> = ({ onChange }) => {
    const { t } = useTranslation();

    const { values }: FormikValues = useFormikContext();

    const partners = useStore(sharedPartnersStores.customerPartners);

    const selectOptions = getSelectOptions(partners);

    const [selected, setSelected] = useState(0);

    const handleAssigneeSelect = (selectedUser: SelectOption<string | number> | null) => {
        if (!selectedUser?.value) return;

        const selectedID = +selectedUser.value;

        setSelected(selectedID);
        onChange(selectedID);
    };

    useEffect(() => {
        if (values?.assignee) {
            const selectedUser = selectOptions?.find(option => option.value === values.assignee);
            setSelected(selectedUser?.value || 0);
        }
    }, [values, selectOptions]);

    return (
        <SelectStyled>
            <Select
                label={t('select.assignee')}
                name="user_selection"
                options={selectOptions}
                valueKey="value"
                onChange={handleAssigneeSelect}
                value={selected}
            />
        </SelectStyled>
    );
};
