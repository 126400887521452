import { createEvent, createStore } from 'effector';
import { checkManualRepairPositionForm } from '../utils/checkManualRepairPositionForm';
import { uniqIdString } from '../utils/uniqId';
import { RepairPositionsModel, RepairPositionWithRowKey } from './repairPositionsModel';

const manualRepairPositionForm = createStore<RepairPositionWithRowKey>({ key: uniqIdString(), DATProcessId: 0 });
const setManualRepairPositionForm = createEvent<RepairPositionWithRowKey>();
manualRepairPositionForm.on(setManualRepairPositionForm, (_, val) => ({ ...val }));

const newManualRepairPositionForm = createEvent();
manualRepairPositionForm.on(newManualRepairPositionForm, () => ({
    key: uniqIdString(), // ''
    DATProcessId: 0
}));

setManualRepairPositionForm.watch(values => {
    let WorkIndication = undefined;
    if (values?.WorkTime) WorkIndication = 1;
    if (values?.WorkPrice) WorkIndication = 2;

    if (checkManualRepairPositionForm(values)) {
        RepairPositionsModel.event.createOrUpdateRepairPosition({
            ...values,
            PositionEntryType: 'manual',
            RepairType: values.RepairType,
            WorkManualInput: !!values?.WorkTime ? '№' : undefined,
            PartDescription: values.Description,
            WorkIndication,
            SparePartUsed: false,
            WorkType: 'other',
            DentsWithFinishing: false,
            DentsWithSetupTime: false,
            DentsWithAddLightMetals: false,
            DentsOutOfReach: false,
            IsAdditionalLM: false,
            PreDamage: values.PreDamage,
            PredamagePercentage: values.PredamagePercentage,
            AlloyLM: false,
            LargeScale: false,
            AdhesiveTechnologyScale: false,
            AdditionLM: false,
            WorkCompleted: false,
            ContainMicroDents: false,
            AdhesiveMethod: false
        });
    } else {
        RepairPositionsModel.event.delRepairPositionByKey([values.key]);
    }
});

export const manualPositionFormExtendedModel = {
    stores: {
        manualRepairPositionForm
    },
    events: {
        newManualRepairPositionForm,
        setManualRepairPositionForm
    }
};
