import { attach, combine, createEffect, createEvent, createStore, forward } from 'effector';
import { contractsListEvents, contractsListStores } from '../../../../stores/contractsList';
import { API2 } from '@dat/api2';
import { debounce } from 'patronum';

export interface Option {
    value: string | number;
    label?: string;
    count?: number;
}

const getProposalsFx = createEffect(
    async (payload: DAT2.Internal.Request.GetFilterProposals): Promise<Option[]> =>
        (
            await API2.myClaimInternal.getFilterProposals<{ label: string[]; value: string[]; count: number }[]>(
                payload
            )
        ).map(({ label: [_networkName, statusName], value, count }) => ({
            label: statusName,
            value: value[0],
            count: count
        }))
);

const options = createStore<Option[]>([]);

const selected = createStore<Option>({ value: '' });
const setSelected = createEvent<Option>();

const isMenuVisible = createStore<boolean>(false);
const toggleMenuVisibility = createEvent<void>();
const setMenuVisibility = createEvent<boolean>();

const search = createStore<string>('');
const setSearch = createEvent<string>();
const setSearchTarget = debounce({ source: setSearch, timeout: 500 });

const triggerLoading = createEvent<void>();

const requestFetching = combine(
    { search: search, data: contractsListStores.combinedFilters },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ search, data: { status, ...filterWithoutStatus } }): DAT2.Internal.Request.GetFilterProposals => ({
        params: { filterElement: 'status', search },
        data: filterWithoutStatus
    })
);

const fetchingProposalFx = attach({ source: requestFetching, effect: getProposalsFx });

search
    .on(setSearchTarget, (_, search) => search)
    .on(search.updates, () => {
        setMenuVisibility(false);
        triggerLoading();
    });
isMenuVisible
    .on(setMenuVisibility, (_, state) => state)
    .on(toggleMenuVisibility, state => {
        const newState = !state;
        if (newState) {
            triggerLoading();
            return state;
        }
        return newState;
    });
selected
    .on(setSelected, (_, elem) => ({ ...elem }))
    .on(selected.updates, (_, option) => {
        contractsListEvents.setFilters.statuses(option.value);
        setMenuVisibility(false);
    });
options
    .on(fetchingProposalFx.doneData, (_, options) => options)
    .on(options.updates, () => {
        setMenuVisibility(true);
    });

forward({
    from: triggerLoading,
    to: fetchingProposalFx
});

export const selectStores = {
    options,
    selected,
    search,
    isMenuVisible
};

export const selectEvents = {
    setSelected,
    setSearch,
    toggleMenuVisibility,
    triggerLoading
};
