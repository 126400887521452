import { FC } from 'react';
import styled, { css } from 'styled-components';
import { components } from 'react-select';
import { makeCustomScrollBar } from '../../mediaQueries';
import { getInputSize } from '../../utils/getInputSize';

export const SelectRoot = styled.div`
    position: relative;
    width: 100%;
`;

export const Placeholder: FC = styled(components.Placeholder)`
    &&& {
        font-size: 18px;
        font-weight: 500;
        color: #c9cfdc;
    }
`;

export const MenuList: FC = styled(components.MenuList)`
    &&& {
        padding: 0;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0px 8px 28px rgba(104, 119, 146, 0.21);
        overflow-x: hidden;
        ${makeCustomScrollBar()}
    }
`;

export const Menu: FC = styled(components.Menu)`
    &&& {
        border: none;
        box-shadow: none;
        z-index: 10;
    }
`;

export const IndicatorsContainer: FC = styled(components.IndicatorsContainer)`
    &&& {
        ${({ selectProps: { inputSize } }) => css`
            height: ${getInputSize(inputSize)};
        `}

        align-self: center;
    }
`;

export const IndicatorSeparator: FC = styled(components.IndicatorSeparator)`
    display: none;
`;

export const DropdownIndicator: FC = styled(components.DropdownIndicator)`
    &&& {
        ${({ selectProps: { menuIsOpen } }) => css`
            color: #c9cfdc;
            transition: transform 150ms ease;
            transform: ${menuIsOpen ? 'rotate(180deg)' : 'rotate(0)'};
            cursor: pointer;
        `}
    }
`;

export const Control: FC = styled(components.Control).attrs(() => ({ className: 'react-select__control' }))`
    &&& {
        ${({ selectProps: { menuIsOpen, inputSize }, theme }) => css`
            height: ${getInputSize(inputSize)};
            box-sizing: border-box;
            box-shadow: none;
            border: none;
            cursor: pointer;
            ${inputSize && 'min-height: unset;'}

            ${!menuIsOpen &&
            css`
                &:hover {
                    border-color: ${theme.colors.dustBlue[300]};
                }
            `}
        `}
    }
`;

export const NoOptionsMessage: FC = styled(components.NoOptionsMessage)`
    &&& {
        ${({ theme }) => css`
            background-color: ${theme.colors.bgColor};
        `}
    }
`;

export const Input: FC = styled(components.Input)`
    position: relative;
`;

export const StyledStoryContainer = styled.div`
    width: 300px;
`;

const valueStyles = css`
    &&& {
        display: flex;
        align-items: center;
        margin: 0;

        svg {
            flex-shrink: 0;
        }
    }
`;

export const SingleValue: FC = styled(components.SingleValue)`
    ${valueStyles}
`;

export const MultiValue: FC = styled(components.MultiValue)`
    ${valueStyles}
`;
