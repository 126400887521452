import { sharedAuthEvents } from '@dat/shared-models/auth';
import { sharedLoginEvents, sharedLoginStores } from '@dat/shared-models/auth/login';
import { sharedUserStores, sharedUserEffects } from '@dat/shared-models/user';
import { guard, sample } from 'effector';
import { delay } from 'patronum';
import { Configuration } from '../../types/configuration';
import { configurationStores } from '../configuration';
import { formEffects } from '../form';
import { pluginEffects } from '../plugin';
import { greetingEvents, greetingStores } from './index';

const {
    onGreetingEnd,
    setIsFormHidden,
    setIsWelcomeShown,
    setIsProfileShown,
    hideWelcomeModal,
    onWelcomeHide,
    setIsFormAuthTriggered
} = greetingEvents;
const { isWelcomeShown, isFormAuthTriggered } = greetingStores;
const { loggedIn } = sharedAuthEvents;
const { loggedOut } = sharedLoginEvents;
const { loginWithCredentialsFromFormFx } = formEffects;
const { invokeOnLoginCallbackFx } = pluginEffects;
const { isUserProfileFilled } = sharedUserStores;
const { updateUserProfileFx } = sharedUserEffects;
const { configuration } = configurationStores;
const { isAuthorized } = sharedLoginStores;

loggedIn.watch(() => {
    setIsFormHidden(true);
});

sample({
    clock: loginWithCredentialsFromFormFx.doneData,
    source: configuration,
    fn: (configuration: Configuration) => {
        if (configuration.welcomePopup) {
            greetingEvents.setIsWelcomeShown(true);
        } else if (configuration.profileManagement) {
            setIsProfileShown(true);
        } else {
            invokeOnLoginCallbackFx();
        }
    }
});

updateUserProfileFx.doneData.watch(() => {
    setIsProfileShown(false);
});

hideWelcomeModal.watch(() => {
    onGreetingEnd(false);
});

sample({
    clock: onWelcomeHide,
    source: { isUserProfileFilled, configuration },
    fn: ({ isUserProfileFilled, configuration }) => {
        if (isUserProfileFilled || !configuration.profileManagement) {
            invokeOnLoginCallbackFx();
        } else {
            setIsProfileShown(true);
        }
    }
});

guard({
    source: { isAuthorized, isFormAuthTriggered },
    filter: ({ isAuthorized, isFormAuthTriggered }) => !!isAuthorized && !isFormAuthTriggered,
    target: invokeOnLoginCallbackFx
});

loggedOut.watch(() => {
    setIsFormHidden(false);
    setIsWelcomeShown(false);
    setIsFormAuthTriggered(false);
});

delay({ source: onGreetingEnd, timeout: 2000, target: isWelcomeShown });
delay({ source: setIsWelcomeShown, timeout: 1000, target: isWelcomeShown });
