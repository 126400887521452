import { guard, sample } from 'effector';
import { partnersEvents, partnersStores } from './index';
import { sharedPartnersStores, sharedPartnersEffects } from '@dat/shared-models/contract/Partners';
import { contractEffects, contractStores } from '@dat/shared-models/contract';
import { sharedConfigurationStores } from '@dat/shared-models/configuration';
import { AVAILABLE_ROLES } from './constants';

const { assignPartnerToContract, unAssignPartnerToContract } = partnersEvents;
const { unAssignedRoles } = partnersStores;
const { assignPartnerToCurrentContractFx, unAssignPartnerFromCurrentContractFx } = sharedPartnersEffects;
const { contractPartners } = sharedPartnersStores;
const { customerSettings } = sharedConfigurationStores;
const { contractId } = contractStores;
const { getContractFx } = contractEffects;

guard({
    source: assignPartnerToContract,
    filter: assignPartnerToCurrentContractFx.pending.map(state => !state),
    target: assignPartnerToCurrentContractFx
});

guard({
    source: unAssignPartnerToContract,
    filter: unAssignPartnerFromCurrentContractFx.pending.map(state => !state),
    target: unAssignPartnerFromCurrentContractFx
});

sample({
    clock: [assignPartnerToCurrentContractFx.done, unAssignPartnerFromCurrentContractFx.done],
    source: contractId,
    target: getContractFx
});

sample({
    source: [contractPartners, customerSettings.role],
    fn: ([contractPartners, currentCustomerRole]) =>
        AVAILABLE_ROLES.filter(
            role =>
                role !== currentCustomerRole &&
                !(contractPartners as DAT2.Partner[]).some(partner => partner.role === role)
        ),
    target: unAssignedRoles
});
