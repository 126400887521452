import styled from 'styled-components/macro';

export const Content = styled.div`
    flex: none;
`;

export const CommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    background-color: ${({ theme }) => theme.colors.gray_10};
`;

interface TextareaProps {
    isLandscape: boolean;
}

export const Textarea = styled.textarea<TextareaProps>`
    flex: 1 1 auto;
    width: 100%;

    margin-top: 5px;

    font-size: 16px;

    background-color: ${({ theme }) => theme.colors.gray_10};

    border: none;
    outline: none;
    resize: none;

    min-height: 0;

    ${({ isLandscape }) => isLandscape && 'min-height: 150px;'}
`;
