import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { previewStores } from '../../../../stores/preview';
import { ManageUsers } from './ManageUsers';
import { List } from './List';
import { AddButton, UsersListContainer } from './styles';
import { usersListEvents } from '../../../../stores/usersList';
import { useTranslation } from 'react-i18next';

export const Users = () => {
    const { t } = useTranslation();
    const [openedManage, setOpenedManage] = useState(false);
    const users = useStore(previewStores.users);
    return (
        <>
            {openedManage ? (
                <ManageUsers selected={users.map(user => user.login)} goBack={() => setOpenedManage(false)} />
            ) : (
                <UsersListContainer>
                    <AddButton
                        onClick={() => {
                            usersListEvents.loadMoreAvailableUsers();
                            setOpenedManage(true);
                        }}
                    >
                        {t('preview.users.manage')}
                    </AddButton>
                    <List users={users} />
                </UsersListContainer>
            )}
        </>
    );
};
