import { removeAlreadyLoadedContracts } from '../utils/removeAlreadyLoadedContracts';
import { PayloadForSetStatusOfContract } from '../types';

export const addNewContracts = (
    currentContracts: DAT2.Internal.ClaimItem[],
    newContracts: DAT2.Internal.ClaimItem[]
) => [...currentContracts, ...removeAlreadyLoadedContracts(newContracts, currentContracts)];

export const setStatusOfContract = (
    currentContracts: DAT2.Internal.ClaimItem[],
    { contractId, status: { statusId, name } }: PayloadForSetStatusOfContract
) => {
    const index = currentContracts.findIndex(contract => contract.id === contractId);

    if (index !== -1) {
        const newContracts = [...currentContracts];
        const updatedContract: DAT2.Internal.ClaimItem = {
            ...currentContracts[index],
            status_id: statusId,
            statusName: name
        };

        newContracts.splice(index, 1, updatedContract);

        return newContracts;
    }
};
