import React from 'react';

const UploadFileIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20.9953 19.0154L25.9984 23.012M20.9953 19.0154V37.0001V19.0154ZM20.9953 19.0154L15.9922 23.012L20.9953 19.0154Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.05458 13.2542C5.16183 13.7297 3.50814 14.8791 2.40421 16.4863C1.30028 18.0935 0.822088 20.0479 1.05948 21.9823C1.29688 23.9167 2.23352 25.698 3.69343 26.9915C5.15334 28.2849 7.03603 29.0016 8.98778 29.0067H10.989"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.6606 9.29961C27.9766 6.59506 26.2882 4.2514 23.9379 2.74371C21.5875 1.23603 18.751 0.677142 16.0032 1.18034C13.2555 1.68354 10.8022 3.21117 9.1406 5.45357C7.47902 7.69596 6.7335 10.4853 7.05518 13.2562C7.05518 13.2562 7.36137 15.0187 7.98776 16.0179"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.0002 29.0069C32.4131 29.0059 33.8098 28.7062 35.0984 28.1274C36.387 27.5485 37.5382 26.7038 38.4762 25.6487C39.4143 24.5936 40.1179 23.3521 40.5408 22.0059C40.9637 20.6597 41.0963 19.2394 40.9299 17.8383C40.7634 16.4373 40.3017 15.0873 39.5751 13.8773C38.8484 12.6672 37.8734 11.6246 36.7142 10.818C35.5549 10.0113 34.2377 9.45899 32.8493 9.1973C31.4609 8.93561 30.0327 8.9705 28.6587 9.29969L25.9971 10.0231"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UploadFileIcon;
