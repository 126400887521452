import { guard } from 'effector';
import _ from 'lodash';

import { pluginEvents } from './index';
import { contractEffects } from '@dat/shared-models/contract';
import { paintTypesEffects, paintTypesStores } from '../selects/paintTypes';

const { initPlugin } = pluginEvents;

guard({
    source: initPlugin.map(({ contractId }) => contractId),
    filter: (contractId): contractId is number => !isNaN(Number(contractId)),
    target: contractEffects.getContractFx
});

guard({
    source: initPlugin,
    filter: paintTypesStores.paintTypesEuroAreNotFetched,
    target: paintTypesEffects.getPaintTypesEuroFx
});

guard({
    source: initPlugin,
    filter: paintTypesStores.paintTypesAztAreNotFetched,
    target: paintTypesEffects.getPaintTypesAztFx
});
guard({
    source: initPlugin,
    filter: paintTypesStores.paintTypesCzAreNotFetched,
    target: paintTypesEffects.getPaintTypesCzFx
});
