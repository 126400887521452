import { FC, useState } from 'react';
import { Separator } from '../../Separator';
import { SliderProps } from '../../Slider';
// import { Comment } from '../../Comment';
import { useOrientation } from '../../../hooks/orientation';
import { CheckIcon, RetakeIcon } from '@wedat/ui-kit/components/Icons';
import { ButtonBlock, Buttons, CommentBtnStyled, Main, Photo, PhotoWrapper, TextStyled } from './styles';
import { translates } from '../../../constants';

interface Props {
    // link or base64
    img: string;
    onRetake: () => void;
    onSave: (comment: string) => void;
    showComment?: boolean;
    comment?: string;
    slider?: SliderProps;
}

export const CommentPhoto: FC<Props> = ({
    img,
    onRetake,
    onSave,
    // slider,
    comment: commentProps = '',
    showComment = false
}) => {
    const [comment, setComment] = useState(commentProps);
    const { isLandscape } = useOrientation();

    return (
        <>
            <PhotoWrapper>
                <Photo alt="photo" src={img} />
            </PhotoWrapper>
            <Main>
                <Buttons>
                    {isLandscape && showComment ? (
                        <>
                            <ButtonBlock>
                                <CommentBtnStyled showComment={showComment} value={comment} onChange={setComment} />
                            </ButtonBlock>
                            <Separator width={10} />
                        </>
                    ) : null}

                    <ButtonBlock isTransparent onClick={onRetake}>
                        <RetakeIcon /> <TextStyled>{translates.change}</TextStyled>
                    </ButtonBlock>

                    <Separator width={10} />

                    <ButtonBlock onClick={() => onSave(comment)}>
                        <CheckIcon /> <TextStyled>{translates.confirm}</TextStyled>
                    </ButtonBlock>
                </Buttons>
            </Main>
        </>
    );
};
