import styled from 'styled-components/macro';
import { Button } from '@wedat/ui-kit/components/Button';
import { media } from '@wedat/ui-kit/mediaQueries';

export const FormStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    ${media.phoneBig`
        display: flex;
        flex-direction: column;
    `}
`;

export const SaveButton = styled(Button)`
    display: flex;
    width: fit-content;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
`;

export const Container = styled.div`
    margin-left: 44px;

    ${media.phoneBig`
        margin-left: 0;
        width: 100%;
    `}
`;

export const FieldsSubtitle = styled.h5`
    margin: 5px 5px 10px;

    color: ${({ theme }) => theme.colors.gray_300};
    font-size: 16px;
    text-transform: capitalize;
`;
