import { FormikContextType, setIn } from 'formik';

import { vehicleSelectionEvents, vehicleSelectionEffects, vehicleSelectionStores } from '../../stores/vehicleSelection';
import { vehicleImagesEffects, vehicleImagesEvents } from '../../stores/vehicleImages';
import { vehicleIdentificationStores } from '../../stores/vehicleIdentification';
import { vehicleRepairEffects, vehicleRepairEvents } from '../../stores/vehicleRepair';
import { commonEffects, commonEvents, commonStores } from '../../stores/common';
import { selectsEditEvents } from '../../stores/selectsEdit';
import { fullDatECodeLength } from '../../constants';
import { createDatECode, createConstructionTimes } from '@dat/core/utils';

import { CurrentFormikValues } from '../../types/formik';
import { FieldEventCallback } from '@wedat/ui-kit/Formik';

export const containerChangeCallback: FieldEventCallback<CurrentFormikValues> = (container: string, formikContext) => {
    const { datECode, constructionPeriod, repairType } = formikContext.values;

    if (container && datECode.length === fullDatECodeLength) {
        vehicleSelectionEffects.getConstructionPeriodsFx({ datECode, container });

        if (constructionPeriod) {
            vehicleSelectionEffects.getTechnicalDataFx({
                datECode,
                container: repairType === 'APPRAISAL' ? container : '',
                constructionTime: +constructionPeriod
            });
        }
    }
};

export const constructionPeriodChangeCallback: FieldEventCallback<CurrentFormikValues> = (
    constructionPeriod: string,
    formikContext
) => {
    const { datECode, container, repairType } = formikContext.values;

    if (datECode.length === fullDatECodeLength && constructionPeriod) {
        vehicleSelectionEffects.getTechnicalDataFx({
            datECode,
            container: repairType === 'APPRAISAL' ? container : '',
            constructionTime: +constructionPeriod
        });
    }
};

//
/*** Main model callbacks ***/
//

// TODO: fix Can't perform a React state update - remove setFieldValue after bad requests
// These callbacks are chained.
// vehicleTypeChange -> manufacturerChange -> baseModelChange -> subModelChange
// e.g. when vehicleTypeChangeCallback is called, it resets manufacturers stores
// and when manufacturers stores is reset, manufacturers SelectField
// resets it's value and calls manufacturerChangeCallback with an empty string.
// And so on.
export const vehicleTypeChangeCallback: FieldEventCallback<CurrentFormikValues> = async (value, formikContext) => {
    const {
        setFieldValue,
        values: {
            firstRegistration,
            firstRegistrationFilter,
            vehicleSelects: { vehicleType }
        }
    } = formikContext;

    if (vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()) {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            setFieldValue('vehicleSelects.vehicleType', vehicleType);

            return;
        }
    }

    // Reset manufacturers
    vehicleSelectionEvents.resetManufacturers();
    // Update edit-input
    selectsEditEvents.updateEditInputsValues();

    if (value) {
        // Request
        await vehicleSelectionEffects.getManufacturersFx({
            vehicleType: value,
            ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
        });

        // Create DAT-code & focus
        setFieldValue('datECode', createDatECode({ vehicleType: value }));
        commonEvents.focusSelect('vehicleSelects.manufacturer');
    }
};

export const manufacturerChangeCallback: FieldEventCallback<CurrentFormikValues> = async (value, formikContext) => {
    const {
        setFieldValue,
        values: {
            vehicleSelects: { vehicleType, manufacturer },
            firstRegistration,
            firstRegistrationFilter
        }
    } = formikContext;

    if (vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()) {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            setFieldValue('vehicleSelects.manufacturer', manufacturer);

            return;
        }
    }

    // Reset baseModels
    vehicleSelectionEvents.resetBaseModels();
    // Update edit-input
    selectsEditEvents.updateEditInputsValues();

    if (value) {
        // Check validity
        if (!vehicleType) {
            return;
        }

        // Request
        await vehicleSelectionEffects.getBaseModelsFx({
            vehicleType,
            manufacturer: value,
            ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
        });

        // Create DAT-code & focus
        setFieldValue('datECode', createDatECode({ vehicleType, manufacturer: value }));
        commonEvents.focusSelect('vehicleSelects.baseModel');
    }
};

export const baseModelChangeCallback: FieldEventCallback<CurrentFormikValues> = async (value, formikContext) => {
    const {
        setFieldValue,
        initialValues,
        values: {
            vehicleSelects: { vehicleType, manufacturer, baseModel },
            firstRegistration,
            firstRegistrationFilter
        }
    } = formikContext;

    if (vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()) {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            setFieldValue('vehicleSelects.baseModel', baseModel);

            return;
        }
    }

    // Reset
    vehicleSelectionEvents.resetSubModels();
    vehicleRepairEvents.resetPaintTypes();
    setFieldValue('paintType', initialValues.paintType);

    // Update edit-inputs
    selectsEditEvents.updateEditInputsValues();

    if (value) {
        // Get selected option object from baseModels stores
        const baseModels = vehicleSelectionStores.baseModels.getState();
        const selectedOptionObject = baseModels.find(({ key }) => key === value);

        // Check validity
        if (!vehicleType || !manufacturer) {
            return;
        }

        // Data for requests
        const requestData = {
            vehicleType,
            manufacturer
        };
        const subModelsRequestData = {
            ...requestData,
            ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
        };

        // Alternative model request
        if (selectedOptionObject?.repairIncomplete) {
            const alternativeModelValues = {
                ...subModelsRequestData,
                baseModel: selectedOptionObject.alternativeBaseType || ''
            };

            vehicleSelectionEvents.setIsOptionWithRepairIncompleteSelected(true);
            await vehicleSelectionEffects.getAlternativeSubModelsFx(alternativeModelValues);

            setFieldValue('alternativeModelSelects', {
                ...alternativeModelValues,
                subModel: ''
            });
        } else {
            vehicleSelectionEvents.setIsOptionWithRepairIncompleteSelected(false);
        }

        // Main model and colors requests
        await Promise.all([
            vehicleSelectionEffects.getSubModelsFx({
                ...subModelsRequestData,
                baseModel: value
            }),
            vehicleRepairEffects.getPaintTypesFx({
                ...requestData,
                mainType: value
            })
        ]);

        // Create DAT-code & focus
        setFieldValue('datECode', createDatECode({ vehicleType, manufacturer, baseModel: value }));
        commonEvents.focusSelect('vehicleSelects.subModel');
    } else {
        vehicleSelectionEvents.setIsOptionWithRepairIncompleteSelected(false);
    }
};

export const subModelChangeCallback: FieldEventCallback<CurrentFormikValues> = async (value, formikContext) => {
    const {
        setFieldValue,
        initialValues,
        values: { vehicleSelects, firstRegistration, firstRegistrationFilter }
    } = formikContext;

    if (vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()) {
        const doChange = await commonEffects.getVinRequestResetPromptAnswerFx();

        if (!doChange) {
            setFieldValue('vehicleSelects.subModel', vehicleSelects.subModel);

            return;
        }
    }

    // Reset values
    vehicleImagesEvents.resetVehicleImages();
    vehicleSelectionEvents.resetAllEquipment();
    commonEvents.resetOptionsDependentOnEquipment();
    setFieldValue('equipmentSelects', {
        ...initialValues.equipmentSelects
    });
    // Update edit-input
    selectsEditEvents.updateEditInputsValues();

    if (value) {
        const { vehicleType, manufacturer, baseModel } = vehicleSelects;

        // Check validity
        if (!vehicleType || !manufacturer || !baseModel) {
            return;
        }

        // Reset focus to avoid focus blinks during requests
        commonEvents.focusSelect('');

        // Create DAT-code
        const datECode = createDatECode({ ...vehicleSelects, subModel: value });

        // Request
        await Promise.all([
            vehicleSelectionEffects.getAllEquipmentObjectFx({
                ...vehicleSelects,
                subModel: value,
                ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
            }),
            vehicleImagesEffects.getVehicleImagesFx({
                datECode
            })
        ]);

        // Set DAT-code
        setFieldValue('datECode', datECode);
        // Focus select that was chosen during equipment requests
        const selectToFocus = commonStores.focusedSelectName.getState();

        if (selectToFocus) {
            commonEvents.focusSelect(selectToFocus);
        } else {
            commonEvents.focusFirstNotSingleOptionEquipmentSelect();
        }
    }
};

//
/*** Alternative model callbacks ***/
//
export const alternativeVehicleTypeChangeCallback: FieldEventCallback<CurrentFormikValues> = async (
    value,
    formikContext
) => {
    const { firstRegistration, firstRegistrationFilter } = formikContext.values;

    vehicleSelectionEvents.resetAlternativeManufacturers();

    await vehicleSelectionEffects.getAlternativeManufacturersFx({
        vehicleType: value,
        ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
    });

    commonEvents.focusSelect('alternativeModelSelects.manufacturer');
};

export const alternativeManufacturerChangeCallback: FieldEventCallback<CurrentFormikValues> = async (
    value,
    formikContext
) => {
    vehicleSelectionEvents.resetAlternativeBaseModels();

    if (value) {
        const {
            alternativeModelSelects: { vehicleType },
            firstRegistration,
            firstRegistrationFilter
        } = formikContext.values;

        if (!vehicleType) {
            return;
        }

        await vehicleSelectionEffects.getAlternativeBaseModelsFx({
            vehicleType,
            manufacturer: value,
            ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
        });

        commonEvents.focusSelect('alternativeModelSelects.baseModel');
    }
};

export const alternativeBaseModelChangeCallback: FieldEventCallback<CurrentFormikValues> = async (
    value,
    formikContext
) => {
    vehicleSelectionEvents.resetAlternativeSubModels();

    if (value) {
        const {
            alternativeModelSelects: { vehicleType, manufacturer },
            firstRegistration,
            firstRegistrationFilter
        } = formikContext.values;

        if (!vehicleType || !manufacturer) {
            return;
        }

        await vehicleSelectionEffects.getAlternativeSubModelsFx({
            vehicleType,
            manufacturer,
            baseModel: value,
            ...createConstructionTimes(firstRegistration, firstRegistrationFilter)
        });

        commonEvents.focusSelect('alternativeModelSelects.subModel');
    }
};

export const alternativeSubModelChangeCallback: FieldEventCallback<CurrentFormikValues> = () => {
    commonEvents.focusFirstNotSingleOptionEquipmentSelect();
};

//
/*** First registration callbacks ***/
//
const updateEverythingWithNewFirstRegistration = async (formikContext: FormikContextType<CurrentFormikValues>) => {
    let {
        vehicleSelects: { vehicleType, manufacturer, baseModel, subModel },
        alternativeModelSelects,
        equipmentSelects,
        firstRegistration,
        firstRegistrationFilter
    } = formikContext.values;
    const constructionTimes = createConstructionTimes(firstRegistration, firstRegistrationFilter);

    // Requests
    const selectedEquipment = Object.values(equipmentSelects).filter(option => !!option);

    const [vehicleTypes, manufacturers, baseModels, subModels] = await Promise.all([
        // Main model
        vehicleSelectionEffects.getVehicleTypesFx({ ...constructionTimes }),
        vehicleSelectionEffects.getManufacturersFx({ vehicleType, ...constructionTimes }),
        vehicleSelectionEffects.getBaseModelsFx({
            vehicleType,
            manufacturer,
            ...constructionTimes
        }),
        vehicleSelectionEffects.getSubModelsFx({
            vehicleType,
            manufacturer,
            baseModel,
            ...constructionTimes
        }),
        // Alternative model
        vehicleSelectionEffects.getAlternativeManufacturersFx({
            vehicleType: alternativeModelSelects.vehicleType,
            ...constructionTimes
        }),
        vehicleSelectionEffects.getAlternativeBaseModelsFx({
            vehicleType: alternativeModelSelects.vehicleType,
            manufacturer: alternativeModelSelects.manufacturer,
            ...constructionTimes
        }),
        vehicleSelectionEffects.getAlternativeSubModelsFx({
            vehicleType: alternativeModelSelects.vehicleType,
            manufacturer: alternativeModelSelects.manufacturer,
            baseModel: alternativeModelSelects.baseModel,
            ...constructionTimes
        }),
        // Equipment
        vehicleSelectionEffects
            .getAllEquipmentObjectFx({
                vehicleType,
                manufacturer,
                baseModel,
                subModel,
                ...constructionTimes
            })
            .then(() =>
                // "availableEquipmentObject" is dependent on "allEquipmentObject"
                // so it should be requested only after "getAllEquipmentObjectFx"
                vehicleSelectionEffects.getAvailableEquipmentObjectFx({
                    vehicleType,
                    manufacturer,
                    baseModel,
                    subModel,
                    availableOptions: selectedEquipment,
                    ...constructionTimes
                })
            )
    ]);

    // Focus necessary select
    let selectToFocus = commonStores.focusedSelectName.getState();

    if (!subModels.length || !subModels.some(({ key }) => key === subModel)) {
        selectToFocus = 'vehicleSelects.subModel';
    }
    if (!baseModels.length || !baseModels.some(({ key }) => key === baseModel)) {
        selectToFocus = 'vehicleSelects.baseModel';
    }
    if (!manufacturers.length || !manufacturers.some(({ key }) => key === manufacturer)) {
        selectToFocus = 'vehicleSelects.manufacturer';
    }
    if (!vehicleTypes.length || !vehicleTypes.some(({ key }) => key === vehicleType)) {
        selectToFocus = 'vehicleSelects.vehicleType';
    }

    commonEvents.focusSelect(selectToFocus);
};

// First registration datepicker callback
export const firstRegistrationChangeCallback: FieldEventCallback<CurrentFormikValues, void, Date | undefined> = async (
    value,
    formikContext
) => {
    if (
        formikContext.values.firstRegistrationFilter &&
        !vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()
    ) {
        const updatedFormikContext = setIn(formikContext, 'values.firstRegistration', value);

        await updateEverythingWithNewFirstRegistration(updatedFormikContext);
    }
};

// First registration filter callback
export const firstRegistrationFilterChangeCallback: FieldEventCallback<CurrentFormikValues, void, boolean> = async (
    value,
    formikContext
) => {
    if (
        formikContext.values.firstRegistration &&
        !vehicleIdentificationStores.lastSuccessfullyRequestedVin.getState()
    ) {
        const updatedFormikContext = setIn(formikContext, 'values.firstRegistrationFilter', value);

        await updateEverythingWithNewFirstRegistration(updatedFormikContext);
    }
};
