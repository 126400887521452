import { FormState, GenericObject } from '../types';

// Revaluate Form State to
const objectChanger = (obj: GenericObject, key: keyof typeof obj, value: any) => {
    if (key in obj) {
        const temp = obj[key];
        obj[key] = {
            ...temp,
            ...value
        };
    } else {
        obj[key] = {
            ...value
        };
    }
};

export const castValues = (inputs: FormState): FormState => {
    const calcFactorSelected = 'CalculationFactor-selectedLacquerMethod';

    return {
        ...inputs,
        [calcFactorSelected]: Number(inputs[calcFactorSelected])
    };
};

// I no have idea what's going on here
// TODO: Figure out how it works and write tests for it
export const formToConfig = (inputs: FormState) => {
    const castedInput = castValues(inputs);

    const requestObject: GenericObject = {};
    for (const [key, value] of Object.entries(castedInput)) {
        const keyNames = `${key}`.split('-');
        if (keyNames.length === 2) {
            objectChanger(requestObject, keyNames[0], {
                [keyNames[1]]: value
            });
        } else {
            if (keyNames[0] in requestObject) {
                objectChanger(requestObject[keyNames[0]], keyNames[2], {
                    [keyNames[1]]: value
                });
            } else {
                requestObject[keyNames[0]] = {
                    [keyNames[2]]: {
                        [keyNames[1]]: value
                    }
                };
            }
        }
    }

    return requestObject;
};
