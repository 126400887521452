import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';

import { combinedVehicleIdentificationStores } from '../../stores/vehicleIdentification';
import { countries } from '@dat/core/constants';
import { splitDatECode, convertConstructionPeriodToDate } from '@dat/core/utils';

import { ModalPrimary, ModalProps } from '@wedat/ui-kit/components/Modal';
import {
    ModalTextFieldContainer,
    ModalTextLabel,
    ModalTextValue
} from '../RegistrationNumberRequestResultModal/styles';
import { SubmenuTitle, ValuesContainer } from './styles';

import { CurrentFormikValues } from '../../types/formik';
import { contractStores } from '../../stores/contract';
import { vehicleVinOCRStores } from '../../stores/vinOcr';

export const VinRequestResultModal: FC<ModalProps> = ({ isOpen, onDismiss, ...rest }) => {
    const {
        values: { vin, datECode, constructionPeriod, vehicleCountry }
    } = useFormikContext<CurrentFormikValues>();
    const { t } = useTranslation();

    const {
        vinRequestResultEquipment: { vinResultEquipment }
    } = useStore(combinedVehicleIdentificationStores);
    const vehicleFromContract = useStore(contractStores.vehicleFromContract);
    const vehicleFromContractEquipment = vehicleFromContract.vinResult?.vinRequestResultEquipment?.vinResultEquipment;
    const countryName = countries.find(({ key }) => key === vehicleCountry)?.value;

    const vinOcrScanDate = useStore(vehicleVinOCRStores.vinOcrScanDate);
    const currentVinOcrScanDate =
        vinOcrScanDate?.OCRVINScanDate ?? vehicleFromContract?.OCRResult?.find(i => i.key === 'OCRVINScanDate')?.value;

    return (
        <ModalPrimary
            {...rest}
            isOpen={isOpen}
            maxWidth="500px"
            title={t('vinRequestResultModalTitle')}
            onDismiss={onDismiss}
        >
            <SubmenuTitle>{t('vinRequestResultModal.modelIdentificationTitle')}:</SubmenuTitle>
            <ValuesContainer>
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('vinRequestResultModal.vinLabel')}</ModalTextLabel>
                    <ModalTextValue>{vin}</ModalTextValue>
                </ModalTextFieldContainer>
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('vinRequestResultModal.datECodeLabel')}</ModalTextLabel>
                    <ModalTextValue>{splitDatECode(datECode).maskedDatECode}</ModalTextValue>
                </ModalTextFieldContainer>
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('vinRequestResultModal.countryLabel')}</ModalTextLabel>
                    <ModalTextValue>
                        <ReactCountryFlag svg countryCode={vehicleCountry} /> {countryName}
                    </ModalTextValue>
                </ModalTextFieldContainer>
                <ModalTextFieldContainer>
                    <ModalTextLabel>{t('vinRequestResultModal.productionYearLabel')}</ModalTextLabel>
                    <ModalTextValue> {convertConstructionPeriodToDate(constructionPeriod).slice(-4)}</ModalTextValue>
                </ModalTextFieldContainer>
            </ValuesContainer>

            <SubmenuTitle>{t('vinRequestResultModal.OCR')}:</SubmenuTitle>
            <ModalTextFieldContainer>
                <ModalTextLabel>{t('vinRequestResultModal.OCRVINScanDate')}</ModalTextLabel>
                <ModalTextValue>{currentVinOcrScanDate}</ModalTextValue>
            </ModalTextFieldContainer>

            {(!!vinResultEquipment.length || !!vehicleFromContractEquipment?.length) && (
                <SubmenuTitle>{t('vinRequestResultModal.equipmentTitle')}:</SubmenuTitle>
            )}
            {vinResultEquipment.length
                ? vinResultEquipment.map(({ datNumber, manufacturerCode, shortName }) => (
                      <ValuesContainer key={datNumber + manufacturerCode}>
                          {datNumber && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.datNumberLabel')}</ModalTextLabel>
                                  <ModalTextValue>{datNumber}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                          {manufacturerCode && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.manufacturerCodeLabel')}</ModalTextLabel>
                                  <ModalTextValue>{manufacturerCode}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                          {shortName && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.nameLabel')}</ModalTextLabel>
                                  <ModalTextValue>{shortName}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                      </ValuesContainer>
                  ))
                : vehicleFromContractEquipment?.map(({ datNumber, manufacturerCode, shortName }) => (
                      <ValuesContainer key={datNumber + manufacturerCode}>
                          {datNumber && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.datNumberLabel')}</ModalTextLabel>
                                  <ModalTextValue>{datNumber}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                          {manufacturerCode && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.manufacturerCodeLabel')}</ModalTextLabel>
                                  <ModalTextValue>{manufacturerCode}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                          {shortName && (
                              <ModalTextFieldContainer>
                                  <ModalTextLabel>{t('vinRequestResultModal.nameLabel')}</ModalTextLabel>
                                  <ModalTextValue>{shortName}</ModalTextValue>
                              </ModalTextFieldContainer>
                          )}
                      </ValuesContainer>
                  ))}
        </ModalPrimary>
    );
};
