export const createDataForReturnAttachments = (attachments: DAT2.Internal.FolderOfClaimAttachment[]) => {
    const dataForReturnAttachments: { [key: number]: number[] } = {};

    const result = attachments.reduce((acc, item) => {
        const id = item.document_id ?? 0;
        const currentData = acc[id] ?? [];

        return {
            ...acc,
            [id]: [...currentData, item.id]
        };
    }, dataForReturnAttachments);

    return Object.entries(result);
};
