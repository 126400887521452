import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { acceptWebCamToVideo, captureSample, captureVideo, turnOffUserMedia } from '../../../utils/mediaDevices';
import { Video } from '../../Video';
import { PhotoButton } from '../../PhotoButton';
// import { CommentBtn } from '../../CommentBtn';
import { FormContext, actions } from '../../../reducers/form';
import { useOrientation } from '../../../hooks/orientation';
import { Description, PhotoBtnWrapper, PhotoStyled } from './styles';
import { useSelectCameras } from './hooks/useSelectCameras';
import { Log } from '../../Log';

const { setVideoStream } = actions;

interface Props {
    onPhoto: (img: string, comment?: string) => void;
    onBatchPhoto?: (imgs: string[]) => void;
    comment?: string;
    showComment?: boolean;
    maskImg?: string;
    maskDescription?: string;
    embedGeo?: boolean;
    realTime?: boolean;
    valid?: boolean;
    debug?: boolean;
}

export const Photo: FC<Props> = ({
    onPhoto,
    onBatchPhoto,
    maskImg,
    embedGeo,
    maskDescription,
    realTime,
    valid,
    debug = false
}) => {
    // const interval = useRef<any>(null); // we can save timer in useRef and pass it to child

    const videoRef = useRef<HTMLVideoElement>(null);
    // const [comment, setComment] = useState(commentProps);

    const {
        dispatch,
        state: { videoStream }
    } = useContext(FormContext);
    const { isLandscape } = useOrientation();

    const acceptVideo = async (deviceId?: MediaTrackConstraintSet['deviceId']) => {
        if (videoRef.current) {
            const stream = await acceptWebCamToVideo(isLandscape, videoRef.current, deviceId);

            dispatch(setVideoStream(stream));
        }
    };

    // TODO: TEST IN PROD WITH SUBMIT PER FRAME
    // const submitAi = async (captureTime?: DOMHighResTimeStamp) => {
    //     if (onBatchPhoto && videoRef.current) {
    //         // to be define stop step
    //         if (valid) {
    //             return 'ok';
    //         }

    //         const image = captureSample(videoRef.current);
    //         onBatchPhoto([image]);
    //         console.log('Frame captured 2 -fe', captureTime);

    //         videoRef?.current?.requestVideoFrameCallback(submitAi);
    //     }
    // };

    const submitAiWithInterval = useCallback(async () => {
        if (onBatchPhoto && videoRef.current) {
            const image = await captureSample(videoRef.current);
            await onBatchPhoto([image]);
            if (!valid) {
                await submitAiWithInterval();
            }
            console.log('Frame captured 2 -fe');
        }
    }, [onBatchPhoto, valid]);

    useEffect(() => {
        if (videoRef.current) {
            acceptVideo();
        }

        return () => {
            turnOffUserMedia(videoStream);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // if realTime is active providing functionality to recognize car on video and help to put camera into the right angle
    useEffect(() => {
        // run ai validation only if flag is on
        if (realTime && onBatchPhoto) {
            setTimeout(() => {
                submitAiWithInterval();
                return;
            }, 2000);
        }
    }, [onBatchPhoto, submitAiWithInterval, realTime]);

    const handlePhoto = useCallback(async () => {
        const image = await captureVideo(videoRef.current, embedGeo);

        onPhoto(image);
    }, [onPhoto, embedGeo]);

    const handleChangeCamera = (camera: MediaDeviceInfo) => {
        turnOffUserMedia(videoStream);

        acceptVideo(camera.deviceId);
    };

    const [GearIcon, SelectCameraSlideUp] = useSelectCameras(videoStream, handleChangeCamera);

    const PhotoBtnSection = (
        <PhotoBtnWrapper>
            <PhotoButton onClick={handlePhoto} disabled={!valid} />

            {GearIcon}
        </PhotoBtnWrapper>
    );

    return (
        <>
            <Video ref={videoRef} mask={maskImg} />
            {debug && <Log />}

            {isLandscape ? (
                <>
                    <PhotoStyled notMaskDescription={!maskDescription}>
                        {maskDescription && <Description>{maskDescription}</Description>}
                        {PhotoBtnSection}
                    </PhotoStyled>
                    {/* TODO: comment because someone can change one's mind */}
                    {/*
                    <CommentBtn
                        className={styles[`${CLASS}__comment-fluid`]}
                        showComment={showComment}
                        value={comment}
                        onChange={setComment}
                    /> */}
                </>
            ) : (
                <PhotoStyled portrait>
                    {maskDescription && <Description>{maskDescription}</Description>}
                    {PhotoBtnSection}
                </PhotoStyled>
            )}

            {SelectCameraSlideUp}
        </>
    );
};
