import { previewEvents } from '../../../../../stores/preview';
import { Datepicker, Input } from '@wedat/ui-kit';
import React from 'react';
import { PreviewField } from '../../../../../types/plugin';

export interface EditableValueProps {
    field: PreviewField;
    value: string | number;
}

export const EditableValue: React.FC<EditableValueProps> = ({ field: { fieldName, type }, value }) => (
    <>
        {type === 'date' ? (
            <Datepicker
                id={fieldName}
                name={fieldName}
                onChange={event =>
                    event &&
                    previewEvents.updateTemplateData({
                        fieldName,
                        value: event.getTime()
                    })
                }
                value={value ? new Date(value) : ''}
            />
        ) : (
            <Input
                id={fieldName}
                name={fieldName}
                type="string"
                value={String(value)}
                onChange={event =>
                    previewEvents.updateTemplateData({
                        fieldName,
                        value: event.target.value
                    })
                }
            />
        )}
    </>
);
