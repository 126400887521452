import styled from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

interface Props {
    isActive?: boolean;
    isDoneActive?: boolean;
    font?: string;
    isFastTrack?: boolean;
}
export const Heading = styled.p<Props>`
    font-family: ${props => props.font};
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    font-size: 20px;
    line-height: 28px;
    padding-left: 20px;
    padding-top: 20px;
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    font-weight: 700px;
`;
export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background: ${({ theme }) => theme.colors.gray['100']};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
export const StepperSelector = styled.div<Props>`
    min-width: ${props => (props?.isFastTrack ? '100%' : '50%')};
    min-height: ${props => props?.isFastTrack && '100vh'};
    background: ${({ theme }) => theme.colors.gray['100']};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 10px;
    box-shadow: 0px 8px 32px ${({ theme }) => theme.colors.gray[50]}, 0px -8px 32px ${({ theme }) => theme.colors.white};
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    ${media.tablet`
    width: 90%;
`}
`;

export const Step = styled.div<Props>`
    width: ${props => props?.isFastTrack && '100%'};
    height: ${props => props?.isFastTrack && '100%'};
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    ${media.tablet`
    margin-top: 40px;
`}
    ${media.phone`
    margin-top: 65px;
`}
`;

export const StepperProgressPart = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StepperProgressWrapper = styled.div`
    display: flex;
    margin: auto;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.gray['100']};
    ${media.phone`
    min-width: 4%;
`}
`;

export const StepperProgressBar = styled.div<Props>`
    min-width: 30px;
    height: 1px;
    margin-right: 8px;
    margin-left: 8px;
    background: ${props =>
        props.isDoneActive
            ? ({ theme }) => theme.colors.deepBlue['400']
            : props.isActive
            ? ({ theme }) => theme.colors.deepBlue['800']
            : ({ theme }) => theme.colors.deepBlue['100']};

    ${media.phone`
            min-width: 10px;
        `}
`;

export const StepTitle = styled.div`
    position: relative;
    display: flex;
`;
export const StepTitleNumber = styled.button<Props>`
    font-size: 1rem;

    background: ${props =>
        props.isDoneActive
            ? ({ theme }) => theme.colors.deepBlue['400']
            : props.isActive
            ? ({ theme }) => theme.colors.deepBlue['800']
            : ({ theme }) => theme.colors.gray['100']};

    height: 44px;
    width: 44px;
    color: ${props => (props.isActive ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.blueDark)};

    line-height: 1.5;
    border: 1px solid rgba(189, 199, 217, 1);
    border-radius: 100%;
    margin-right: 5px;
    ${media.phone`
    height: 30px;
    width: 30px;
`}
    ${media.phoneMostSmall`
    height: 25px;
    width: 25px;
`}
`;

export const TitleBody = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;
export const TitleContainer = styled.div`
    ${media.phone`
    display: none;
`}
`;
export const TitleContainerMedia = styled.div`
    display: none;

    ${media.phone`
    display: flex;
    padding-top: 5px;
`}
`;
