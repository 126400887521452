import styled from 'styled-components';
import { media } from '../../../mediaQueries';

export const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    ${media.phoneSmall`
        width: 80%;
    `}
`;

export const Text = styled.div`
    margin-top: 40px;
    color: ${({ theme }) => theme.colors.gray['300']};
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
`;
