import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

export const MobileFixedHeader = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colors.gray['50']};
    border-top: none;
    padding: 12px 12px 0;
`;

export const BackWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 12px;

    svg {
        transform: rotate(-90deg);
    }
`;

export const Title = styled(Text)`
    ${({ theme }) => theme.typography.mobileHeader}
    margin: 6px 32px 0;
`;

export const DesktopContainer = styled.div`
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const RowInfo = styled.div`
    display: flex;
`;

export const CloseWrapper = styled.div`
    cursor: pointer;
    padding: 2px;

    &:active {
        color: ${({ theme }) => theme.colors.blue['500']};
    }
`;
