import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../Table';
import { TableFooter } from '../TableFooter';
import { createDataForPaintwork, createDataForPaintworkTableFooter } from '../../utils/createDataForPaintwork';
import { useStore } from 'effector-react';
import { TableWrapper } from '../Layout/styles';
import { TableData } from '../../types/table';
import { Column } from 'react-table';
import { useMedia } from '@dat/core/hooks/useMedia';
import { ConstantsContainer, ConstantsItem } from '../TableFooter/styles';
import { TextField } from '../Price/styles';
import { sizes } from '@wedat/ui-kit/mediaQueries';
import { sharedDossierStores } from '@dat/shared-models/contract/Dossier';

export const Paintwork: FC = () => {
    const { t } = useTranslation();

    const dossier = useStore(sharedDossierStores.dossier);
    const isMobile = useMedia(sizes.phone);

    const data: TableData[] = useMemo(() => createDataForPaintwork(t, dossier, isMobile), [dossier, isMobile, t]);

    const columns: Column<TableData>[] = useMemo(
        () => [
            {
                Header: String(t('paintworkTable.headers.DVN')),
                accessor: 'col1'
            },
            {
                Header: String(t('paintworkTable.headers.item')),
                accessor: 'col2'
            },
            {
                Header: String(t('paintworkTable.headers.description')),
                accessor: 'col3'
            },
            {
                Header: String(t('paintworkTable.headers.level')),
                accessor: 'col4'
            },
            {
                Header: String(t('paintworkTable.headers.materialPoints')),
                accessor: 'col5'
            },
            {
                Header: String(t('paintworkTable.headers.material')),
                accessor: 'col6'
            },
            {
                Header: String(t('paintworkTable.headers.time')),
                accessor: 'col7'
            },
            {
                Header: String(t('paintworkTable.headers.labourCosts')),
                accessor: 'col8'
            },
            {
                Header: String(t('paintworkTable.headers.price')),
                accessor: 'col9'
            }
        ],
        [t]
    );

    const footerData = useMemo(() => createDataForPaintworkTableFooter(t, dossier), [dossier, t]);

    return (
        <TableWrapper>
            <Table columns={columns} data={data} title={t('paintworkTable.title')} isPaintWork={true} />
            {isMobile && (
                <ConstantsContainer isVisible={footerData.lacquerConstants?.length}>
                    {footerData.lacquerConstants?.map((item, index) => (
                        <ConstantsItem key={index}>
                            <TextField>{item?.label}</TextField>
                            <TextField>{`${item?.price}`}</TextField>
                        </ConstantsItem>
                    ))}
                </ConstantsContainer>
            )}
            <TableFooter items={footerData.items} lacquerConstants={footerData.lacquerConstants} isMobile={isMobile} />
        </TableWrapper>
    );
};
