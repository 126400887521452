import { media } from '@wedat/ui-kit/mediaQueries';
import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};

    ${media.laptop`
        padding: 12px 16px;
    `}
`;

export const ButtonWrapper = styled.div`
    width: 140px;

    ${media.laptop`
        width: 100%;
    `}
`;
