import { guard } from 'effector';
import _ from 'lodash';

import { pluginStores } from './index';
import { calculationEffects } from '../calculation';

const { pluginOptions } = pluginStores;

guard({
    source: pluginOptions.map(options => options?.contractId),
    filter: _.isNumber,
    target: calculationEffects.calculateAndGetContractFx
});
