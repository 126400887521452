export const getWorkBenchDescription = (description?: string) => {
    if (description === 'Banco da lavoro tradizionale' || description === 'traditional') {
        return 'traditional';
    }

    if (description === 'Banco da lavoro universale' || description === 'universal') {
        return 'universal';
    }

    return 'other';
};
