import React from 'react';
import { useFormikContext } from 'formik';

import { SelectField, SelectFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationSelectField: React.FC<SelectFieldProps> = props => {
    const { submitForm } = useFormikContext();

    return <SelectField {...props} changeCallback={submitForm} />;
};
