import React, { useState, FC } from 'react';

import { PositionsTable } from './PositionsTable';
import { Header } from './Header';
import { Container } from '../Container';
import { TableWrapper } from './styles';
import { PositionsList } from './PositionsList';

interface Props {
    isMobile?: boolean;
}

export const Positions: FC<Props> = ({ isMobile }) => {
    const [selectedPositionIndexes, setSelectedPositionIndexes] = useState<number[]>([]);

    const onPositionClick = (index: number) => {
        if (selectedPositionIndexes.includes(index)) {
            setSelectedPositionIndexes(selectedPositionIndexes.filter(idx => idx !== index));
        } else {
            setSelectedPositionIndexes([...selectedPositionIndexes, index]);
        }
    };

    return (
        <Container>
            <Header
                selectedPositionIndexes={selectedPositionIndexes}
                setSelectedPositionIndexes={setSelectedPositionIndexes}
                isMobile={!!isMobile}
            />
            {isMobile ? (
                <PositionsList onPositionClick={onPositionClick} selectedPositionIndexes={selectedPositionIndexes} />
            ) : (
                <TableWrapper>
                    <PositionsTable onChangePositionsSelection={setSelectedPositionIndexes} />
                </TableWrapper>
            )}
        </Container>
    );
};
