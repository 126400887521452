// VXS - Vehicle Exchange Structure
// https://www.dat.de/fileadmin/de/support/interface-documentation/EN/SilverDAT_interface_compendium/index.htm#4731

import { setObjectKeysFirstLetterToLowerCase } from '../setObjectKeysFirstLetterToLowerCase';
import { createEquipmentObjectsArray } from './createEquipmentObjectsArray';
import { parseEquipmentField } from './parseEquipmentField';

import { FieldObject, XMLObjectInstance } from '@dat/api/utils';
import { ParsedVXSVehicleObject, VehicleInfoKeys, VinResultEquipmentObject } from '../../types/VXS';

export const parseVXSResponse = <T extends DAT.VXS>(VXSResponse: XMLObjectInstance<T>): ParsedVXSVehicleObject[] => {
    let dossiers = VXSResponse.getFieldArray('Dossier');

    // Sometimes VXS contains only one "Vehicle" field
    if (!dossiers.length) {
        dossiers = [VXSResponse as FieldObject<T['Dossier']>];
    }

    return dossiers.map(dossier => {
        const vehicleField = dossier.getSingleField('Vehicle');

        if (!vehicleField) {
            throw new Error();
        }

        // Vehicle info
        const vehicleInfoFieldsObject = vehicleField.getSingleFieldsInnerTextObject(
            'DatECode',
            'VehicleIdentNumber',
            'ConstructionTime',
            'InitialRegistration',
            'Container',
            'VehicleTypeName',
            'ManufacturerName',
            'BaseModelName',
            'SubModelName',
            'VehicleType',
            'Manufacturer',
            'BaseModel',
            'SubModel'
        );

        const vehicleInfo = setObjectKeysFirstLetterToLowerCase<VehicleInfoKeys>({
            vehicleCountry: dossier.getSingleField('Country')?._innerText,
            vin: vehicleField.getSingleField('VehicleIdentNumber')?._innerText,
            numberPlate: vehicleField.getSingleField('RegistrationData')?.getSingleField('LicenseNumber')?._innerText,
            nationalCode: vehicleField.getSingleField('NationalCodeAustria')?.getSingleField('NationalCodeAustria')
                ?._innerText,
            //TODO: base number
            typeNoteNumber: vehicleField
                .getSingleField('MetaPositions')
                ?.getFieldArray('MetaPosition')
                ?.find(({ key }) => key === 'typeNoteNumberSwitzerland')?.value,
            kba: vehicleField.getSingleField('KbaNumbersN')?.getSingleField('KbaNumber')?._innerText, // also located in RegistrationData
            kilometers: vehicleField.getSingleField('MileageOdometer')?._innerText,
            ...vehicleInfoFieldsObject
        });

        // General equipment
        let equipment = parseEquipmentField(vehicleField.getSingleField('Equipment'));

        // DatECodeEquipment
        const datECodeEquipment = createEquipmentObjectsArray(vehicleField.getSingleField('DATECodeEquipment'));

        // Paint type
        const vinPaintType = vehicleField
            .getSingleField('VINResult')
            ?.getSingleField('VINColors')
            ?.getSingleField('VINColor')
            ?.getSingleField('PaintType')?._innerText;
        const contractPaintType = dossier
            .getSingleField('RepairCalculation')
            ?.getSingleField('RepairParameters')
            ?.getSingleField('LacquerTypeId')?._innerText;

        const paintType = contractPaintType || vinPaintType;

        // VIN-colors
        let vinColors: DAT2.VINColor[] = [];
        // VIN-result equipment
        let vinResultEquipment: VinResultEquipmentObject[] = [];

        const VINResultData = vehicleField.getSingleField('VINResult');

        if (VINResultData) {
            const colorsData = VINResultData.getSingleField('VINColors');
            const vinResultEquipmentData = VINResultData.getSingleField('VINEquipments');

            // Get vinColors
            if (colorsData) {
                // @ts-ignore
                vinColors = colorsData.getFieldArray('VINColor').map(color => {
                    const result = color.getSingleFieldsInnerTextObject(
                        'ColorID',
                        'Code',
                        'Description',
                        'PaintType',
                        'CountCoat',
                        'StandardColor'
                    );

                    return { ...result, Description: result.Description || 'No description' };
                });
            }
            // Get VIN-result equipment
            if (vinResultEquipmentData) {
                vinResultEquipment = vinResultEquipmentData.getFieldArray('VINEquipment').map(pos => {
                    const manufacturerCodeField = pos.getSingleField('ManufacturerCode');
                    const datNumberField = pos.getSingleField('AvNumberDat');
                    const shortNameField = pos.getSingleField('ShortName');

                    return {
                        manufacturerCode: manufacturerCodeField && manufacturerCodeField._innerText,
                        datNumber: datNumberField && datNumberField._innerText,
                        shortName: shortNameField && shortNameField._innerText
                    };
                });
            }
        }

        return { vehicleInfo, equipment, datECodeEquipment, paintType, vinColors, vinResultEquipment };
    });
};
