import React from 'react';
import { EvaluationNumberInputField } from '../../evaluationFields/Input';
import { EvaluationNumberWrapper } from './styles';
import { NumberInputFieldProps } from '@wedat/ui-kit/Formik';

export const EvaluationNumberWithSign: React.FC<NumberInputFieldProps> = ({ ...rest }) => (
    <EvaluationNumberWrapper>
        <EvaluationNumberInputField {...rest} />
    </EvaluationNumberWrapper>
);
