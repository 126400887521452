// compare

export const isEqual = <T>(value1: T, value2: T) => value1 === value2;

export const isNotNull = <T>(value: T) => !!value;

export const isOr = <T>(value1: T, value2: T) => !!value1 || !!value2;

export const isAnd = <T>(value1: T, value2: T) => !!value1 && !!value2;

export const isNonEqual = <T>(value1: T, value2: T) => value1 !== value2;

export const isGreater = <T>(value1: T, value2: T) => +value1 >= +value2;

export const isLess = <T>(value1: T, value2: T) => +value1 <= +value2;

export const filter = <T>(values: T[]) => {
    const res: T[] = values.filter(value => !!value);
    return res.length === values.length;
};

// calculate

export const plus = <T extends number>(value1: T, value2: T): number => value1 + value2;

export const minus = <T extends number>(value1: T, value2: T): number => value1 - value2;

export const multiply = <T extends number>(value1: T, value2: T): number => value1 * value2;

export const divide = <T extends number>(value1: T, value2: T): number => Math.floor(value1 / value2);
