import { createIsLoadingStore } from '@dat/core/utils/createIsLoadingStore';
import { sharedAttachmentsEffects } from '@dat/shared-models/contract/Attachments';
import { sharedContractStatusEffects } from '@dat/shared-models/contract/Status';

const isLoading = createIsLoadingStore(
    Object.values({
        ...sharedAttachmentsEffects,
        ...sharedContractStatusEffects
    })
);

export const commonStores = {
    isLoading
};
