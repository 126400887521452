import { useCallback, useState } from 'react';
import { PluginProps } from '../types';

/**
 * Hook to get callback with possibility invoke only once
 */
export const useCompleteFormCallback = (callback: PluginProps['completeFormCallback']) => {
    const [invoke, setInvoke] = useState(false);

    return useCallback(
        (...args: Parameters<PluginProps['completeFormCallback']>) => {
            if (!invoke) {
                setInvoke(true);

                callback(...args);
            }
        },
        [invoke, setInvoke, callback]
    );
};
