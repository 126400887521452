import React, { memo } from 'react';
import { useStore } from 'effector-react';
import { Button, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { RepairPositionsModel } from '../../../stores/repairPositionsModel';
import { commonModel } from '../../../stores/commonModel';
import { rightSideDrawerModel } from '../../../stores/rightSideDrawerModel';
import { RepairPositionsList } from '../../RepairPositionsList/RepairPositionsList';
import FtDamageForm from './FtDamageForm';
import { DATProcessIdCommentView } from '../PreselectPartDamage/DATProcessIdCommentView';
import { DATProcessIdCommentListModel } from '../../../stores/DATProcessIdCommentListModel';

export const FtDamageWrapper = memo(
    () => {
        const { t } = useTranslation();

        const isRepPosModified = useStore(RepairPositionsModel.store.isModified);
        const isCommentModified = useStore(DATProcessIdCommentListModel.store.isModified);
        const isModified = isRepPosModified || isCommentModified;

        return (
            <div style={{ margin: '8px 8px 8px 8px' }}>
                <div style={{ marginBottom: '8px', position: 'absolute', top: '0px', left: '0px', right: '0px' }}>
                    <Row gutter={[4, 8]} align="top">
                        <Col span={20}>
                            <div style={{ float: 'left' }}>
                                <Button
                                    size="large"
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        rightSideDrawerModel.events.backView();
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        paddingRight: '4px',
                        position: 'absolute',
                        top: '68px',
                        left: '0px',
                        right: '0px',
                        bottom: '112px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <FtDamageForm />
                </div>

                <div
                    style={{
                        position: 'absolute',
                        // top: '170px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                >
                    <Row gutter={[8, 16]}>
                        <Col span={24}>
                            <DATProcessIdCommentView />
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    commonModel.events.cancel();
                                    // rightSideDrawerModel.events.backView();
                                }}
                            >
                                {t('ManualRepairPositionForm.Cancel')}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                disabled={!isModified}
                                size="large"
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    commonModel.events.confirmWithEquipmentCheck();
                                    rightSideDrawerModel.events.setCurrentView(RepairPositionsList);
                                }}
                            >
                                {t('ManualRepairPositionForm.Confirm')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    },
    () => true
);
