import styled from 'styled-components';
import { DrawerOptions } from './types';

type ContainerProps = Pick<DrawerOptions, 'position'>;

export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: 0;
    left: ${({ position }) => (position === 'left' ? '0' : 'auto')};
    bottom: 0;
    right: ${({ position }) => (position === 'right' ? '0' : 'auto')};
    display: flex;
    flex-direction: ${({ position }) => (position === 'left' ? 'row' : 'row-reverse')};
    transition: transform 0.3s ease;
    z-index: 1002;
    width: 0px;
`;
