import styled from 'styled-components/macro';

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;

export const StyledImage = styled.img`
    width: 100%;
    cursor: pointer;
`;
