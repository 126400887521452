import React from 'react';
import { useField } from 'formik';

import { NumberInput, NumberInputProps } from '../../components/NumberInput';

export interface NumberInputFieldProps extends NumberInputProps {
    name: string;
    label?: string;
    hideValue?: boolean;
    hideZeroValue?: boolean;
}

export const NumberInputField: React.FC<NumberInputFieldProps> = ({
    name,
    label,
    onChange,
    hideValue,
    hideZeroValue,
    ...rest
}) => {
    const [field, , helpers] = useField<number | undefined>(name);

    const handleChange = (value: number | undefined) => {
        helpers.setValue(value);
        onChange?.(value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        field.onBlur(e);
        rest.onBlur?.(e);
    };

    if (hideValue || (field.value === 0 && hideZeroValue)) {
        return (
            <NumberInput
                {...rest}
                {...field}
                label={label}
                onChange={handleChange}
                onBlur={handleBlur}
                value=""
                prefix=""
            />
        );
    }

    return <NumberInput {...rest} {...field} label={label} onChange={handleChange} onBlur={handleBlur} />;
};
