import { stringify } from 'querystring';
import { myClaimProxy } from './my-claim-rest.ts';
import { INITIAL_CUSTOMER_SETTINGS } from '@dat/shared-models/configuration/constants';

// == myClaim REST API
export const deleteAttachments = ({
    claimId,
    folderId,
    attachmentsIds
}: DAT2.Internal.Request.DeleteAttachments): Promise<DAT2.Internal.Response.DeleteAttachments> =>
    myClaimProxy('deleteAttachments', {
        api: 'rest',
        url: `/claims/${claimId}/folders/${folderId}/attachments`,
        params: { ids: attachmentsIds },
        method: 'DELETE'
    });

export const getAttachmentsOfFolder = ({
    claimId,
    folderId
}: DAT2.Internal.Request.GetAttachmentsOfFolder): Promise<DAT2.Internal.Response.GetAttachmentsOfFolder> =>
    myClaimProxy('getAttachmentsOfFolder', {
        api: 'rest',
        url: `/claims/${claimId}/folders/${folderId}/attachments`,
        method: 'GET'
    });

export const getFoldersOfClaim = ({
    claimId
}: DAT2.Internal.Request.GetFoldersOfClaim): Promise<
    DAT2.Internal.Response.GetFoldersOfClaim | DAT2.Internal.Response.ProxyResponseErrors
> =>
    myClaimProxy('getFoldersOfClaim', {
        api: 'rest',
        url: `/claims/${claimId}/folders`,
        method: 'GET',
        params: {
            calculationPhotos: false,
            embedded: [
                'attachments',
                'attachments/binaryData',
                'attachments/photo',
                'attachments/customer',
                'attachments/user'
            ]
        }
    });

export const getAttachmentsFolderAsZip = ({
    claimId,
    folderId
}: DAT2.Internal.Request.GetAttachmentsFolderAsZip): Promise<DAT2.Internal.Response.GetAttachmentsFolderAsZip> =>
    myClaimProxy('getAttachmentsFolderAsZip', {
        api: 'rest',
        url: `/claims/${claimId}/folders/${folderId}/attachments`,
        method: 'GET',
        headers: { Accept: 'application/zip' }
    });

export const getPhotoUrl = ({
    photoId,
    claimId,
    folderId,
    token
}: {
    photoId: number;
    folderId: number;
    claimId: number;
    token: string;
}) =>
    `${INITIAL_CUSTOMER_SETTINGS.bffUrl}myClaim/photo?claimId=${claimId}&attachmentId=${photoId}&folderId=${
        folderId || 0
    }&token=${token}`;

export const getDownloadAllAttachmentsUrl = ({ claimId, token }: { claimId: number; token: string }) =>
    `${INITIAL_CUSTOMER_SETTINGS.bffUrl}myClaim/photo?claimId=${claimId}&token=${token}`;

export const defaultColumns = ({
    listerType,
    embedded
}: DAT2.Internal.Request.DefaultColumns): Promise<DAT2.Internal.Response.DefaultColumns> =>
    myClaimProxy('defaultColumns', {
        api: 'rest',
        method: 'GET',
        url: '/ui/columns/defaults',
        params: { listerType, embedded }
    });

// == myClaim JSON api
export const getClaim = async ({
    claimId,
    mode
}: DAT2.Internal.Request.GetClaim): Promise<DAT2.Internal.Response.GetClaim> =>
    (
        await myClaimProxy<
            { claim: number; mode: string },
            DAT2.Internal.Response.GetClaim,
            { data: DAT2.Internal.Response.GetClaim }
        >('getClaim', {
            api: 'json',
            method: 'GET',
            url: '/claims/GetClaim',
            params: {
                claim: claimId,
                mode
            }
        })
    ).data;

export const listClaims = async ({
    params,
    data,
    templateId
}: DAT2.Internal.Request.ListClaims): Promise<DAT2.Internal.Response.ListClaims> =>
    (
        await myClaimProxy<
            DAT2.Internal.Request.ListClaims['params'],
            DAT2.Internal.Request.ListClaims['data'],
            { data: DAT2.Internal.Response.ListClaims }
        >('listClaims', {
            api: 'json',
            url: '/claims/ListClaims',
            method: 'POST',
            params,
            data,
            headers: {
                templateId: templateId ? templateId : ''
            }
        })
    ).data;

export const getFilter = async ({
    storedFilter
}: DAT2.Internal.Request.GetFilter): Promise<DAT2.Internal.Response.GetFilter> =>
    (
        await myClaimProxy<
            { filterSetName: string; storedFilter: number },
            unknown,
            { data: { filter: DAT2.Internal.Response.GetFilter } }
        >('getFilter', {
            api: 'json',
            method: 'GET',
            url: '/statistics/filters/GetFilter',
            params: { filterSetName: 'FOLDER_FILTERSET', storedFilter }
        })
    ).data.filter;

export const getFilterProposals = async <R>({ params, data }: DAT2.Internal.Request.GetFilterProposals): Promise<R> =>
    (
        await myClaimProxy<
            { filterSet: string; filterElement: string | undefined; search: string | undefined },
            DAT2.Internal.Request.GetFilterProposals['data'],
            { data: R }
        >('getFilterProposals', {
            api: 'json',
            url: '/statistics/filters/GetProposals',
            method: 'POST',
            params: {
                filterSet: 'FOLDER_FILTERSET',
                filterElement: params?.filterElement,
                search: params?.search
            },
            data
        })
    ).data;

export const loadModuleConfig = async ({
    moduleId
}: DAT2.Internal.Request.LoadModuleConfig): Promise<DAT2.Internal.Response.LoadModuleConfig> => {
    const content: {
        data?: {
            moduleData?: {
                instanceModuleProperties?: {
                    listerSettings?: { lister: string; jsonColumns: string }[];
                };
                userInfo?: {
                    settings?: {
                        folderFilters?: DAT2.Internal.FolderFilter[];
                    };
                };
            };
        };
    } = await myClaimProxy('loadModuleConfig', {
        api: 'json',
        url: '/security/LoadModuleConfig',
        method: 'GET',
        params: {
            moduleId
        }
    });
    const res: DAT2.Internal.Response.LoadModuleConfig = {
        listerSettings: [],
        folderFilters: []
    };
    const moduleData = content?.data?.moduleData;
    if (!moduleData) {
        return res;
    }

    if (moduleData?.instanceModuleProperties?.listerSettings) {
        try {
            res.listerSettings = moduleData.instanceModuleProperties.listerSettings.map(setting => {
                // deserialize each lister setting stored as json
                const data = JSON.parse(setting.jsonColumns);
                return { name: setting.lister, visibleColumns: data?.length ? data[0] : data };
            });
        } catch (e) {
            console.log('e', e);
        }
    }

    if (moduleData?.userInfo?.settings?.folderFilters) {
        res.folderFilters = moduleData.userInfo.settings.folderFilters;
    }
    return res;
};

export const calculateContract = ({ claimId }: DAT2.Internal.Request.CalculateContract) =>
    myClaimProxy('calculateContract', {
        api: 'json',
        url: `/calculations/ExecuteCalculation?claimId=${claimId}`,
        method: 'POST'
    });

export const setStatus = ({
    claimId,
    statusId,
    comment
}: DAT2.Internal.Request.SetStatus): Promise<DAT2.Internal.Response.SetStatus> =>
    myClaimProxy('setStatus', {
        api: 'json',
        url: `/statuses/SetStatus`,
        method: 'GET',
        params: { claim: claimId, status: statusId, note: comment }
    });

export const getRates = (): Promise<DAT2.Internal.Response.Rates> =>
    myClaimProxy('/listSettlements', {
        api: 'json',
        url: '/wagessettings/ListSettlements',
        method: 'GET'
    });

export const getRate = (id: number): Promise<DAT2.Internal.Response.Rate> =>
    myClaimProxy('/dispatcher', {
        api: 'some',
        url: '/dispatcher--/call',
        method: 'POST',
        dataType: 'form',
        data: {
            componentName: 'vehicleRepair.administrationPage',
            action: 'onSelectCostRate',
            costRateId: id,
            currency: 'EUR',
            lkz: 'IT',
            fundamentals: null,
            defaultSettlement: false
        }
    });

export const deleteRate = (id: number) =>
    myClaimProxy('json', {
        api: 'json',
        bodyType: 'json',
        url: `/wagessettings/RemoveWagesSettings?settlementId=${id}`,
        method: 'GET'
    });

export const createOrUpdateRate = ({ id, factorsData, ownerId, country }: DAT2.Internal.Request.CreateRate) =>
    myClaimProxy('dispatcher', {
        api: 'some',
        url: '/dispatcher--/call',
        method: 'POST',
        dataType: 'form',
        data: {
            cid: 1981,
            componentName: 'vehicleRepair.administrationPage',
            action: 'onSaveFactors',
            contextLnr: !id ? -1 : id,
            factors: factorsData,
            fundamentals: {
                country,
                onlyAssignedToClaim: false,
                sharingScope: null,
                settlementType: 'INDIVIDUAL',
                sharingPartners: null,
                sharingRoles: null,
                owner_id: ownerId || 0,
                networkType: 'ANIA',
                claimType: 'vro_calculation'
            },
            defaultSettlement: false
        }
    });

export const getPartnerList = ({
    claimId
}: DAT2.Internal.Request.GetPartnerList): Promise<
    DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Response.ListPartnersResponse>
> =>
    myClaimProxy('getPartnerList', {
        api: 'json',
        method: 'GET',
        url: `/partners/ListPossiblePartners?${stringify({
            claimID: claimId,
            usePartnerAdresses: false,
            showOnlyWithPartnership: false,
            initialLoad: true,
            count: 50,
            offset: 0,
            recount: true
        })}`
    });

export const listUsers = async (): Promise<DAT2.Internal.Response.ListUsers> =>
    (
        await myClaimProxy<{ offset: number; count: number }, void, { data: DAT2.Internal.Response.ListUsers }>(
            'listUsers',
            {
                api: 'json',
                method: 'GET',
                url: '/security/ListUsers',
                params: { offset: 0, count: 100 }
            }
        )
    ).data;

export const landingMail = (
    payload: DAT2.Internal.Request.LandingMail
): Promise<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Response.LandingMail>> =>
    myClaimProxy('landingMail', {
        api: 'json',
        method: 'POST',
        url: '/mail/LandingMail',
        data: payload
    });

export const sendAttachments = ({
    data,
    claimId,
    folderId
}: DAT2.Internal.Request.SendAttachments): Promise<
    DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Response.SendAttachments>
> =>
    myClaimProxy('sendAttachments', {
        api: 'rest',
        method: 'PUT',
        url: `/claims/${claimId}/folders/${folderId}/attachments`,
        data
    });

export const getListPrintReportForClaim = ({
    claimId
}: DAT2.Internal.Request.GetListPrintReportsForClaim): Promise<
    Partial<DAT2.Internal.Response.ProxyResponse<DAT2.Internal.Response.GetListPrintReportsForClaim>>
> =>
    myClaimProxy('getListPrintReportForClaim', {
        api: 'json',
        method: 'GET',
        url: `/printManager/ListPrintReportForClaim?claimId=${claimId}`
    });
