import React, { FC, useEffect, useRef, useState } from 'react';

import { Button } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, FullscreenOutlined } from '@ant-design/icons';

import './svg.css';
import { maxDelayDoubleClickMs, maxTouchClickMs, scaleFactor, zoomOnDoubleClick } from '../../initData/eventConfig';
import { useTranslation } from 'react-i18next';
import { SvgViewerEvents } from '../GroupGraphicSVG3/GroupGraphicSVG3Types';
// import { ReactSVGPanZoom, TOOL_AUTO } from 'react-svg-pan-zoom';
var { ReactSVGPanZoom, TOOL_AUTO } = require('react-svg-pan-zoom');
const { SvgLoader, SvgProxy } = require('@wedat/react-svgmt');
// import { SvgLoader, SvgProxy } from '@wedat/react-svgmt';

export interface GenericSVGGraphicType {
    selectedElementsId: string[];
    width?: number;
    height?: number;
    graphicSVG?: string;
    onElementClick: (id: string) => void;
    showFullscreen: boolean | undefined;
    setShowFullScreen: (v: boolean) => void;
    showZoomButton?: boolean;

    setEvents?: (events: SvgViewerEvents) => void;
}

let timer: NodeJS.Timeout;
let timerSvg: NodeJS.Timeout;
let clicks = 0;
let clicksSvg = 0;
let onTouchStartTime = 0;

export const GenericSVGGraphic: FC<GenericSVGGraphicType> = ({
    selectedElementsId,
    width,
    height,
    graphicSVG,
    onElementClick,
    showFullscreen,
    // setShowFullScreen,
    showZoomButton = false,
    setEvents
}) => {
    const Viewer = useRef(null);
    const [value, setValue] = useState<Partial<any>>({});
    const [tool, setTool] = useState<any>(TOOL_AUTO);

    const { t } = useTranslation();

    useEffect(() => {
        if (Viewer.current) {
            (Viewer.current as any).fitToViewer();
            setEvents?.({
                zoomIn: () => (Viewer.current as any).zoomOnViewerCenter(scaleFactor),
                zoomOut: () => (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor),
                fitToViewer: () => (Viewer.current as any).fitToViewer()
            });
        }
    }, [Viewer, showFullscreen, setEvents]);

    useEffect(() => {
        const selectedElements = document.getElementsByClassName('selectedElements');
        Array.from(selectedElements).forEach(element => element.classList.remove('selectedElements'));
        // todo mast be test and rewriter
        selectedElementsId.forEach(selectElementId =>
            document
                .querySelectorAll('[id^=' + selectElementId + ']>path')
                .forEach(element => element.classList.add('selectedElements'))
        );
    }, [selectedElementsId]);

    if (!graphicSVG || !width || !height)
        return (
            <div
                style={{
                    width: '100%',
                    margin: '10em 0em 0em 0em',
                    display: 'inline-block'
                }}
            >
                {/* <Spin spinning size="large" /> */}
                {t('genericGraphic.title')}
            </div>
        );

    const onSetValue = (value: any) => {
        let deltaX = value.SVGWidth * 0.0;
        let deltaY = value.SVGHeight * 0.0;

        let maxX;
        let minX;
        if (value.a > 1) {
            maxX = deltaX;
            minX = -deltaX + value.SVGWidth - value.SVGWidth * value.a;
        } else {
            maxX = deltaX + value.SVGWidth - value.SVGWidth * value.a;
            minX = -deltaX;
        }

        let maxY;
        let minY;
        if (value.d > 1) {
            maxY = deltaY;
            minY = -deltaY + value.SVGHeight - value.SVGHeight * value.d;
        } else {
            maxY = deltaY + value.SVGHeight - value.SVGHeight * value.d;
            minY = -deltaY;
        }

        let e = value.e > maxX ? maxX : value.e < minX ? minX : value.e;
        let f = value.f > maxY ? maxY : value.f < minY ? minY : value.f;

        setValue({ ...value, e, f });
    };

    const onSvgClick = (e: any, touch = false) => {
        clicksSvg++; //count clicks
        if (clicksSvg === 1) {
            timerSvg = setTimeout(function () {
                clicksSvg = 0;
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clearTimeout(timerSvg); //prevent single-click action
            clicks = 0; //after action performed, reset counter
            clicksSvg = 0;
            if (e.scaleFactor !== 1) (Viewer.current as any).reset();
            else {
                // if (!showFullscreen) setShowFullScreen(true);
                // else
                touch
                    ? (Viewer.current as any).zoom(e.value.endX, e.value.endY, zoomOnDoubleClick)
                    : (Viewer.current as any).zoom(e.point.x, e.point.y, zoomOnDoubleClick);
            }
        }
    };

    const onElementClickId = (e: any) => {
        console.log('onClick element');
        clicks++; //count clicks
        if (clicks === 1) {
            timer = setTimeout(function () {
                clicks = 0;
                onElementClick(e.target.parentElement.id);
            }, maxDelayDoubleClickMs);
        } else {
            clearTimeout(timer); //prevent single-click action
            clicks = 0; //after action performed, reset counter
        }
    };

    const currentZoom = value.a;

    return (
        <div>
            {showZoomButton && (
                <div
                    style={{
                        // textAlign: 'left',
                        position: 'absolute',
                        // left: '0',
                        top: '0',
                        // right: '0',
                        right: '-48px',
                        zIndex: showFullscreen ? 1011 : 11,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        padding: '8px'
                    }}
                >
                    <Button
                        style={{ marginBottom: '6px' }}
                        icon={<ZoomInOutlined />}
                        onClick={_e => {
                            (Viewer.current as any).zoomOnViewerCenter(scaleFactor);
                        }}
                        onTouchEnd={_e => console.log('onTouchEnd ZoomInOutlined')}
                    />
                    <br />
                    <Button
                        style={{ marginBottom: '6px' }}
                        icon={<ZoomOutOutlined />}
                        onClick={() => {
                            (Viewer.current as any).zoomOnViewerCenter(1 / scaleFactor);
                        }}
                    />
                    <br />
                    <Button
                        disabled={currentZoom === 1}
                        style={{ marginBottom: '6px' }}
                        icon={<FullscreenOutlined />}
                        onClick={() => {
                            (Viewer.current as any).fitToViewer();
                        }}
                    />
                </div>
            )}
            <ReactSVGPanZoom
                value={value as any}
                onChangeValue={onSetValue}
                ref={Viewer}
                // SVGBackground="#f0f2f5"
                // background="#f0f2f5"
                // background="#ffffff"
                SVGBackground="var(--weDat-grapa-background)"
                background="var(--weDat-grapa-background)"
                customMiniature={() => null}
                customToolbar={() => null}
                width={width}
                height={height}
                scaleFactorMax={10}
                scaleFactorMin={0.6} // 0.6
                scaleFactor={scaleFactor}
                scaleFactorOnWheel={scaleFactor}
                detectWheel={showFullscreen}
                // detectAutoPan={showFullscreen}
                detectAutoPan={true}
                detectPinchGesture={showFullscreen}
                toolbarProps={{ SVGAlignX: 'right', SVGAlignY: 'center', position: 'right' }}
                // tool="auto"
                // tool="pan"
                // tool={(showFullscreen ? tool : 'none') as any}
                tool={(currentZoom !== 1 ? tool : 'none') as any}
                onChangeTool={setTool}
                preventPanOutside={false}
                disableDoubleClickZoomWithToolAuto={true}
                onTouchStart={(_e: any) => {
                    onTouchStartTime = new Date().getTime();
                }}
                onTouchEnd={(e: any) => {
                    if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                    if (currentZoom !== 1) onSvgClick(e, true);
                }}
                onClick={onSvgClick}
            >
                <svg height={height} width={width}>
                    <SvgLoader className="genericGraphic" height={height} width={width} path={graphicSVG}>
                        <SvgProxy
                            selector='[id^="DATID_"]>path'
                            // selector=':not([id^="DATID_"])'

                            onTouchStart={(_e: any) => {
                                onTouchStartTime = new Date().getTime();
                            }}
                            ontouchend={(e: any) => {
                                if (new Date().getTime() > onTouchStartTime + maxTouchClickMs) return;
                                if (currentZoom !== 1) onElementClickId(e);
                            }}
                            onClick={onElementClickId}
                        />

                        {selectedElementsId.map(selectElementId => (
                            <SvgProxy
                                key={selectElementId}
                                class={'selectedElements'}
                                selector={'[id^=' + selectElementId + ']>path'}
                                // selector=':not([id^="DATID_"])'
                                // onClick={(event: any) => onElementClick(event.target.parentElement.id)}
                            />
                        ))}
                    </SvgLoader>
                </svg>
            </ReactSVGPanZoom>
        </div>
    );
};
