import styled, { css } from 'styled-components/macro';
import { media } from '@wedat/ui-kit/mediaQueries';

export const List = styled.div`
    padding-bottom: 100px;

    ${media.phoneBig`
        margin-top: 12px;
        /* Indent for Footer */
        padding-bottom: 180px;
    `}
`;

interface AccordionHeaderProp {
    aggregate: boolean;
    aggregateComponent: boolean;
}

export const AccordionHeader = styled.div<AccordionHeaderProp>`
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    border-top: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};

    ${({ aggregate, theme }) => aggregate && `color: ${theme.colors.blue['300']};`}
    ${({ aggregateComponent, theme }) =>
        aggregateComponent &&
        css`
            border-top: 1px solid ${({ theme }) => theme.colors.white};
            background-color: ${theme.colors.blue['50']};
        `}
`;

export const Text = styled.span`
    ${({ theme }) => theme.typography.note};
    color: currentColor;
`;
