import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Icon = styled.div`
    width: 32px;
    height: 32px;
`;

export const ToastInfo = styled.div`
    margin-left: 20px;
`;

export const ToastMessage = styled.div`
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    color: inherit;
    white-space: pre-wrap;
`;

export const ToastTitle = styled.div`
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    line-height: 36px;
    color: inherit;
`;

export const StyledToastContainer = styled(ToastContainer)`
    max-width: 500px;
    width: 400px;
    z-index: 99999;

    .Toastify__toast--warning {
        background-color: #fff;
        ${Icon},
        ${ToastInfo},
        .Toastify__close-button {
            color: #ffb444;
        }
    }

    .Toastify__toast--error {
        background-color: #fff;
        ${Icon},
        ${ToastInfo},
        .Toastify__close-button {
            color: #ff6f42;
        }
    }

    .Toastify__toast--success {
        background-color: #fff;
        ${Icon},
        ${ToastInfo},
        .Toastify__close-button {
            color: #3fdf7f;
        }
    }
    .Toastify__toast--info {
        background-color: #fff;
        ${Icon},
        ${ToastInfo},
        .Toastify__close-button {
            color: #103366;
        }
    }

    .Toastify__progress-bar {
        background: transparent;
    }

    .Toastify__close-button {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        opacity: 1;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }
`;

export const ToastContentStyled = styled.div`
    display: flex;
    align-items: center;
`;
