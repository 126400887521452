import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useStore } from 'effector-react';

import { factorsEffects, factorsStores } from '../stores/factors';

import { AccordionContainer, AccrodionItemWrapper, Root } from './styles';
import { FormikController } from '../components/FormikController';
import { Header } from '../components/Header';
import { Calculations } from '../components/Calculations';
import { SparePartsFactors } from '../components/SparePartsFactors';
import { LabourCostFactors } from '../components/LabourCostFactors';
import { InsurancePositions } from '../components/InsurancePositions';
import { PaintFactors } from '../components/PaintFactors';
import { Toast } from '@dat/smart-components/Toast';
import { LabourRatesTabs } from './contants';
import { Footer } from '../components/Footer';

import { Accordion, AccordionItem } from '@wedat/ui-kit/components/Accordion';
import { Text } from '@wedat/ui-kit/components/Text';

interface Props {
    closeLabourRatesModal?: () => void;
}

export const LabourRatesPage: React.FC<Props> = ({ closeLabourRatesModal }) => {
    const { t } = useTranslation();

    const contractFactorsParameters = useStore(factorsStores.contractFactorsParameters);

    return (
        <Formik
            enableReinitialize
            initialValues={contractFactorsParameters}
            onSubmit={factorsEffects.updateContractFactorsFx}
        >
            <Root>
                <FormikController />
                <Toast />

                <Header closeLabourRatesModal={closeLabourRatesModal} />

                <AccordionContainer>
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                        <AccrodionItemWrapper>
                            <AccordionItem
                                uuid={LabourRatesTabs.Calculations}
                                header={<Text font="defaultBold">{t('calculations.title')}</Text>}
                            >
                                <Calculations />
                            </AccordionItem>
                        </AccrodionItemWrapper>

                        <AccrodionItemWrapper>
                            <AccordionItem
                                uuid={LabourRatesTabs.SparePartsFactors}
                                header={<Text font="defaultBold">{t('spareParts.title')}</Text>}
                            >
                                <SparePartsFactors />
                            </AccordionItem>
                        </AccrodionItemWrapper>

                        <AccrodionItemWrapper>
                            <AccordionItem
                                uuid={LabourRatesTabs.LabourCostFactors}
                                header={<Text font="defaultBold">{t('labourCost.title')}</Text>}
                            >
                                <LabourCostFactors />
                            </AccordionItem>
                        </AccrodionItemWrapper>

                        <AccrodionItemWrapper>
                            <AccordionItem
                                header={<Text font="defaultBold">{t('insurancePositions.title')}</Text>}
                                uuid={LabourRatesTabs.InsurancePositions}
                            >
                                <InsurancePositions />
                            </AccordionItem>
                        </AccrodionItemWrapper>

                        <AccrodionItemWrapper>
                            <AccordionItem
                                header={<Text font="defaultBold">{t('paint.title')}</Text>}
                                uuid={LabourRatesTabs.PaintFactors}
                            >
                                <PaintFactors />
                            </AccordionItem>
                        </AccrodionItemWrapper>
                    </Accordion>
                </AccordionContainer>

                <Footer />
            </Root>
        </Formik>
    );
};
