import { ParsedAttachmentItem } from '../../types/attachments';

export const parseAttachmentItem = (attachment: DAT2.AttachmentItem): ParsedAttachmentItem => ({
    ...attachment,
    base64: generateBase64String(attachment),
    status: 'uploaded'
});

interface GenerateBase64StringParam {
    binaryData: string;
    mimeType?: string;
}

const generateBase64String = ({ binaryData, mimeType }: GenerateBase64StringParam) =>
    `data:${mimeType || ''};base64,${binaryData}`;
