import styled, { css } from 'styled-components/macro';

interface IconWrapperProps {
    actived: boolean;
}

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;

    background-color: ${({ theme }) => theme.colors.white};
`;

export const AdditionalInfo = styled.div`
    padding: 12px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
    border-top: 1px solid ${({ theme }) => theme.colors.dustBlue['100']};
`;

export const MainMobileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
    margin-right: 24px;
    overflow: hidden;
    transition: background-color 150ms;
    cursor: pointer;

    svg {
        width: 28px;
        height: 28px;
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    ${({ actived, theme }) =>
        actived &&
        css`
            background-color: ${theme.colors.blue['50']};
            border: none;
        `}
`;

export const CountSelecetedRows = styled.span`
    background-color: ${({ theme }) => theme.colors.red['400']};
    border-radius: 8px;
    margin-left: 8px;
    padding: 2px 8px;
`;
