import { createEffect } from 'effector';
import { createGate } from 'effector-react';
import { RoutesParams } from '../../types/router';

export const ClaimPageGate = createGate<RoutesParams['claim']>();
const pushClaimRouteByIdFx = createEffect<string | number, void>();

export const routerEffects = {
    pushClaimRouteByIdFx
};
