import styled, { css } from 'styled-components';

import { ExpandIcon, EditIcon, DownloadIcon, TrashIcon, CheckInCircleIcon, CircleIcon } from '../Icons';

import preloaderImage from '../../assets/images/preloader-old.gif';

import { AttachmentStatus } from './index';
import { media } from '../../mediaQueries';

interface AttachmentItemContainerProps {
    isFormatText?: boolean;
    small?: boolean;
}

export const AttachmentItemContainer = styled.div<AttachmentItemContainerProps>`
    ${({ isFormatText, small }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        border: 1px solid ${({ theme }) => theme.colors.dustBlue['300']};
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        ${small &&
        css`
            width: 92px;
            height: 92px;
        `}

        ${isFormatText &&
        css`
            background-color: ${({ theme }) => theme.colors.dustBlue['50']};
        `}

        ${media.phone`
            ${
                isFormatText &&
                css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: ${({ theme }) => theme.colors.dustBlue['50']};

                    // TODO: needs to refactor straight svg selector
                    svg {
                        color: ${({ theme }) => theme.colors.black};
                    }
                `
            }
        `}

        ${media.phoneBig`
            width: 130px;
            height: 130px;
        `}

        ${media.phoneMedium`
            width: 100px;
            height: 100px;
        `}

        ${media.phoneSmall`
            width: 90px;
            height: 90px;
        `}
    `};
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

interface OverlayProps {
    status?: AttachmentStatus;
    isFormatText: boolean;
    selected?: boolean;
    reverse?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
    ${({ status, isFormatText, selected, reverse }) => css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;

        box-sizing: border-box;
        background: ${({ theme }) => theme.colors.dustBlue['800']} center;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.15s ease;

        &:hover {
            opacity: 0.95;
        }

        ${media.phone`
            padding-top: 6px;
            padding-left: 6px;
            padding-right: 6px;
            align-items: center;
            opacity: 0.80;

            ${
                isFormatText &&
                css`
                    display: none;
                `
            }
        `}

        ${status === 'uploading' &&
        css`
            background-image: url(${preloaderImage});
            opacity: 0.9;
            pointer-events: none;
        `}

        ${selected &&
        css`
            opacity: 0.9;
        `}

        ${reverse &&
        css`
            flex-direction: row-reverse;
        `}
    `}
`;

export const attachmentIconStyle = css`
    display: flex;
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.1s ease;
    cursor: pointer;

    ${media.phone`
        width: 20px;
        height: 20px;
    `}
`;

export const Text = styled.span`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
`;

export const Name = styled.span`
    position: absolute;
    bottom: 12px;
    left: 0;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.dustBlue['600']};

    ${media.phoneBig`
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
        -webkit-line-clamp: 1;
    `}

    ${media.phone`
        display: none;
    `}
`;

export const DocumentTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    align-items: center;

    ${media.phone`
        display: flex;
        flex-direction: row;
    `}
`;
interface IconContainerProps {
    fullIcon?: boolean;
}
export const IconContainer = styled.div<IconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ fullIcon }) => (fullIcon ? '100%' : '50%')};
    transition: opacity 0.15s ease;

    &:hover {
        opacity: 0.9;
        background-color: ${({ theme }) => theme.colors.dustBlue['900']};
    }

    ${media.phone`
        &:hover {
            background-color: transparent;
        }
    `}
`;

export const MobileIconContainer = styled.div`
    display: none;

    ${media.phone`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        &:hover {
            background-color: transparent;
        }
    `}
`;

export const Expand = styled(ExpandIcon)`
    ${attachmentIconStyle};
`;

export const Edit = styled(EditIcon)`
    ${attachmentIconStyle};
`;

export const Trash = styled(TrashIcon)`
    ${attachmentIconStyle};
`;

export const Download = styled(DownloadIcon)`
    ${attachmentIconStyle};
`;

export const Check = styled(CheckInCircleIcon)`
    ${attachmentIconStyle};
`;

export const Circle = styled(CircleIcon)`
    ${attachmentIconStyle};
`;

export const BottomText = styled.div<{ hide: boolean }>`
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    opacity: 0.25;
    pointer-events: none;

    ${media.phoneBig`
        ${({ hide }) =>
            hide &&
            css`
                display: none;
            `}
    `}
`;

export const AttachmentPreloader = styled(AttachmentItemContainer)`
    background-image: url(${preloaderImage});
`;

export const SelectIconContainer = styled(IconContainer)`
    height: 50%;
`;
