import { FC, useCallback, useContext } from 'react';
import { TabPhoto, TabPhotoAdditional } from '../TabPhoto';
import { Layout } from '../Layout';
import { Button } from '../Button';
import { getTranslates, groupByPhotoType } from '../../utils/plugin';
import { PluginProps } from '../../types';
import { FormContext } from '../../reducers/form';
import { useCompleteFormCallback } from '../../hooks/plugin';
import { turnOffUserMedia } from '../../utils/mediaDevices';
import { ButtonWrap, Content, Title } from './styles';

interface Props {
    steps: PluginProps['steps'];
    completeFormCallback: PluginProps['completeFormCallback'];
}

export const ResultStepPage: FC<Props> = ({ steps, completeFormCallback }) => {
    const translates = getTranslates();
    const {
        state: { resultForm, videoStream }
    } = useContext(FormContext);
    const formSteps = resultForm.steps;
    const groups = groupByPhotoType(formSteps);
    const completeCallback = useCompleteFormCallback(completeFormCallback);

    const handleSubmit = useCallback(() => {
        turnOffUserMedia(videoStream);

        completeCallback(resultForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completeCallback, resultForm]);

    return (
        <Layout>
            <Content>
                <Title asTag="p" font="mobileHeader">
                    {translates.reviewMediaTitle}
                </Title>
                {Object.keys(groups).map(groupKey => (
                    <TabPhoto
                        key={groupKey}
                        filterKey={groupKey}
                        formSteps={formSteps}
                        steps={steps}
                        title={groupKey}
                    />
                ))}

                <TabPhotoAdditional title={translates.additional} />
            </Content>
            <ButtonWrap>
                <Button onClick={handleSubmit}>{translates.submitBtn}</Button>
            </ButtonWrap>
        </Layout>
    );
};
