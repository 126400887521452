import { getTextValuesFromParsedObject } from '@dat/api2/utils';
import { initialFactorsParameters } from '../constants/factors';

import { PlainObject } from '@dat/core/types/common';
import { FactorName, FactorsParametersObject } from '../types/factors';

export const parseProcedureRelatedParametersObject = (object: DAT2.ProcedureRelatedParametersObject) => {
    const textValues = getTextValuesFromParsedObject(object);
    const _attributes = createAttributesObject(object);

    return {
        ...textValues,
        _attributes
    };
};

const createAttributesObject = (parametersObject: DAT2.ProcedureRelatedParametersObject) => {
    const _attributes: PlainObject = {};

    Object.entries(parametersObject).forEach(([parameterKey, parameterProperties]) => {
        const { _text, ...attributes } = parameterProperties;
        const attributesEntriesToAdd = Object.entries(attributes).filter(([attrKey]) =>
            attributesUsedInForm.includes(attrKey)
        );

        attributesEntriesToAdd.forEach(([attrKey, attrValue]) => {
            _attributes[attrKey] = {
                ..._attributes[attrKey],
                [parameterKey]: attrValue
            };
        });
    });

    return _attributes;
};

const attributesUsedInForm = Object.values(initialFactorsParameters).reduce<string[]>(
    (acc, parametersObject: FactorsParametersObject[FactorName]) => {
        if ('_attributes' in parametersObject && parametersObject._attributes) {
            acc.push(...Object.keys(parametersObject._attributes));
        }

        return Array.from(new Set(acc));
    },
    []
);
