/*
 * Returns updated steps with mask img URLs by dynamic immg names (constants/stepsMaskImgDynamicNames.ts).
 */

import {
    STEPS_MASK_IMG_DYNAMIC_NAMES,
    MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART
} from '../constants/stepsMaskImgDynamicNames';
import { PluginCarsGroupStep } from '../types/pluginCars';

export const setStepsImgDynamicSVG = (steps: PluginCarsGroupStep[], kindOfSVG: string) =>
    steps.map(step => {
        const names = Object.keys(STEPS_MASK_IMG_DYNAMIC_NAMES);
        const urls = Object.values(STEPS_MASK_IMG_DYNAMIC_NAMES);
        const maskImg = step.mask?.img;

        if (!maskImg || !names.includes(maskImg) || !kindOfSVG) return step;

        const newStep = {
            ...step,
            mask: {
                ...step.mask,
                img: urls[names.indexOf(maskImg)]?.replace(MASK_IMG_DYNAMIC_VEHICLE_TYPE_PART, kindOfSVG)
            }
        };

        return newStep;
    });
