import { Text } from '@wedat/ui-kit/components/Text';
import styled from 'styled-components/macro';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${Text} {
        color: ${({ theme }) => theme.colors.dustBlue['600']};
        text-align: center;
    }
`;

export const NotFoundImg = styled.img`
    width: 126px;
    height: 140px;
    margin-bottom: 12px;
`;
