import { multipleVariantSelectionEvents } from '../../stores/multipleVariantSelection';

import { CurrentFormikValues } from '../../types/formik';
import { FieldEventCallback } from '@wedat/ui-kit/Formik';

export const vehicleTypeChangeCallback: FieldEventCallback<CurrentFormikValues> = (value, formikContext) => {
    const { setFieldValue } = formikContext;

    setFieldValue('multipleVariantSelects.manufacturer', '');

    if (value) {
        multipleVariantSelectionEvents.updateVisibleOptions({
            vehicleType: value,
            manufacturer: '',
            baseModel: '',
            subModel: '',
            selectedEquipment: []
        });
    }
};

export const manufacturerChangeCallback: FieldEventCallback<CurrentFormikValues> = (value, formikContext) => {
    const {
        setFieldValue,
        values: {
            multipleVariantSelects: { vehicleType }
        }
    } = formikContext;

    setFieldValue('multipleVariantSelects.baseModel', '');

    if (value) {
        multipleVariantSelectionEvents.updateVisibleOptions({
            vehicleType,
            manufacturer: value,
            baseModel: '',
            subModel: '',
            selectedEquipment: []
        });
    }
};

export const baseModelChangeCallback: FieldEventCallback<CurrentFormikValues> = (value, formikContext) => {
    const {
        setFieldValue,
        values: {
            multipleVariantSelects: { vehicleType, manufacturer }
        }
    } = formikContext;

    setFieldValue('multipleVariantSelects.subModel', '');

    if (value) {
        multipleVariantSelectionEvents.updateVisibleOptions({
            vehicleType,
            manufacturer,
            baseModel: value,
            subModel: '',
            selectedEquipment: []
        });
    }
};

export const subModelChangeCallback: FieldEventCallback<CurrentFormikValues> = (value, formikContext) => {
    const {
        setFieldValue,
        initialValues,
        values: {
            multipleVariantSelects: { vehicleType, manufacturer, baseModel }
        }
    } = formikContext;

    setFieldValue('multipleVariantSelects.equipmentSelects', initialValues.multipleVariantSelects.equipmentSelects);

    if (value) {
        multipleVariantSelectionEvents.updateVisibleOptions({
            vehicleType,
            manufacturer,
            baseModel,
            subModel: value,
            selectedEquipment: []
        });
    }
};
