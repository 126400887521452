import styled from 'styled-components/macro';
import { Text } from '@wedat/ui-kit/components/Text';

export const Container = styled.div`
    position: relative;
`;

export const DropdownContainer = styled.div``;

interface ButtonProps {
    active: boolean;
}

export const Button = styled.button<ButtonProps>`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 8px;
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.dustBlue['800']};
    transition: color 150ms, background-color 150ms;
    color: ${({ theme }) => theme.colors.textPrimary};

    &:hover {
        color: ${({ theme }) => theme.colors.blue['500']};
    }

    ${({ active, theme }) => active && `background-color: ${theme.colors.blue['50']}`}
`;

export const ButtonAddressBook = styled.div`
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dustBlue['50']};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 150ms;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue['50']};
    }

    &:active {
        background-color: ${({ theme }) => theme.colors.blue['100']};
    }
`;

export const Title = styled(Text)`
    padding-top: 14px;
    padding-left: 18px;
`;
