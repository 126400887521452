export type PayloadForSetStatusOfContract = {
    contractId: number;
    status: DAT2.ContractStatusTransition;
};

export interface ListerUserSettings extends DAT2.Internal.Response.LoadModuleConfig {}

export enum FolderType {
    ALL_CLAIMS = 'ALL_CLAIMS',
    MY_CLAIMS = 'MY_CLAIMS',
    FILTER = 'DYNAMIC'
}
