import { sample } from 'effector';
import { attachmentsEvents } from '../attachments';
import { printReportsEvents } from '../printReports';
import { recipientsEvents } from '../recipients';
import { sendMailEvents } from './index';

const { successfullySent } = sendMailEvents;
const { resetRecipients, resetCcRecipients, resetBccRecipients } = recipientsEvents;
const { resetReports } = printReportsEvents;
const { resetAttachments } = attachmentsEvents;

sample({
    clock: successfullySent,
    target: [resetRecipients, resetCcRecipients, resetBccRecipients, resetReports, resetAttachments]
});
