import styled from 'styled-components/macro';

interface ProgressBarStepProps {
    stepBg?: string;
    isActive: boolean;
}

export const StepsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
`;

export const ProgressBarStep = styled.div<ProgressBarStepProps>`
    height: 4px;
    border-radius: 2px;
    margin-top: 1px;
    margin-bottom: 1px;
    background-color: ${({ theme, stepBg, isActive }) => (stepBg && isActive ? stepBg : theme.colors.dustBlue['100'])};

    &:hover {
        opacity: 0.7;
    }

    ${(): string => {
        const styles = Array.from({ length: 10 }).map(
            (_, idx) => `
                &:nth-child(${idx + 1}) {
                    width: ${15 - idx}px;
                }`
        );

        return `
            ${styles}
        `;
    }}
`;
