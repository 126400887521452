import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { sharedCalcResultItalyStores } from '@dat/shared-models/contract/Dossier/RepairCalculation/CalcResultItaly';

import { WrapperContent } from '../core/styles';
import { Tabs } from '@wedat/ui-kit/components/Tabs';

import { Positions } from '../Positions';
import { TotalSumsMobile } from '../TotalSums/mobile';
import { SumsOfIndividual } from '../SumsOfIndividual';
import { Paintwork } from '../Paintwork';
import { Labour } from '../Labour';
import { Content } from './styles';

enum CalculationTabs {
    Positions = '1',
    Sums = '2'
}

export const Mobile = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(CalculationTabs.Positions);
    const positionsItalyLength = useStore(sharedCalcResultItalyStores.positionsItaly).length;

    const tabsHandler = (id: SetStateAction<string>) => {
        setActiveTab(id as CalculationTabs);
    };
    return (
        <>
            <WrapperContent tabs>
                <Tabs
                    values={[
                        { id: CalculationTabs.Positions, label: t('calculateTabs.positions') },
                        { id: CalculationTabs.Sums, label: t('calculateTabs.sums') }
                    ]}
                    active={activeTab}
                    onChange={tabsHandler}
                />
            </WrapperContent>

            {activeTab === CalculationTabs.Positions && (
                <Content>
                    <Positions isMobile={true} />
                </Content>
            )}
            {activeTab === CalculationTabs.Sums && (
                <Content>
                    <WrapperContent>
                        <TotalSumsMobile />
                    </WrapperContent>
                    {!!positionsItalyLength && (
                        <WrapperContent>
                            <SumsOfIndividual />
                        </WrapperContent>
                    )}
                    {!!positionsItalyLength && (
                        <WrapperContent>
                            <Paintwork />
                        </WrapperContent>
                    )}
                    {!!positionsItalyLength && (
                        <WrapperContent>
                            <Labour />
                        </WrapperContent>
                    )}
                </Content>
            )}
        </>
    );
};
