import React from 'react';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { initialFormValues } from './constants';

import { Container, Divider, InputFieldContainer, InputWrapper } from './styles';
import { VehicleSelectionModal, VehicleSelectionModalPluginOptions } from '@dat/vehicle-selection-modal';

interface Props {
    options: VehicleSelectionModalPluginOptions;
}

export const VehicleInputs: React.FC<Props> = ({ options }) => (
    <Container>
        <Formik initialValues={initialFormValues} onSubmit={_.noop}>
            <Form>
                {/* Add id for Portal. We take inputs in VSM */}
                <InputWrapper id="number-plate-input" isNumberPlate />
                <InputWrapper id="vin-input" />
                <Divider />
                <VehicleSelectionModal options={options} />
                <InputFieldContainer>
                    <InputWrapper id="kilometers-input" short />
                    <InputWrapper id="tires-input" short />
                </InputFieldContainer>
            </Form>
        </Formik>
    </Container>
);
