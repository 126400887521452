import { sample } from 'effector';
import { spread } from 'patronum';
import deepmerge from 'deepmerge';

import { INITIAL_LOCALE } from '@dat/shared-models/i18n/constants';
import { setLocalStorageLocale } from './../i18n/utils/index';
import { sharedAuthEvents } from '../auth';
import { sharedUserStores } from '../user';
import { sharedConfigurationStores } from './index';
import { extractFromCustomerConfiguration } from './utils/extractFromCustomerConfiguration';
import { i18nStores } from '../i18n';
import { INITIAL_USER_SETTINGS } from './constants';
import { sharedLoginEvents } from '../auth/login';
import { getLocalStorageLocale } from '../i18n/utils';

const { customerConfiguration, customerSettings, userSettings } = sharedConfigurationStores;
const { loggedIn } = sharedAuthEvents;
const { loggedOut } = sharedLoginEvents;
const { username } = sharedUserStores;
const { locale } = i18nStores;

customerConfiguration.on(loggedIn, (_, { configuration }) => configuration);
customerConfiguration.reset(loggedOut);

/* Customer settings */
sample({
    source: customerConfiguration,
    fn: extractFromCustomerConfiguration.customerSettings,
    target: spread({ targets: customerSettings })
});

/* User settings */
sample({
    source: { customerConfiguration, username },
    fn: ({ customerConfiguration, username }) =>
        deepmerge.all(
            [
                INITIAL_USER_SETTINGS,
                extractFromCustomerConfiguration.userSettings(customerConfiguration, 'default'),
                extractFromCustomerConfiguration.userSettings(customerConfiguration, username)
            ],
            { arrayMerge: /* overwrite */ (_leftArray, rightArray) => rightArray }
        ),
    target: spread({ targets: { ...userSettings } })
});

sample({
    source: customerConfiguration,
    filter: customerConfiguration => {
        const result = extractFromCustomerConfiguration.userSettings(customerConfiguration, 'default');

        const localeLS = getLocalStorageLocale();

        // don't update store and don't trigger getTranslations
        if (localeLS || !result.locale) return false;

        return true;
    },
    fn: customerConfiguration => {
        const result = extractFromCustomerConfiguration.userSettings(customerConfiguration, 'default');

        if (result.locale) {
            setLocalStorageLocale(result.locale);
        }

        return result.locale || INITIAL_LOCALE;
    },
    target: locale
});
