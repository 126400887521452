import { sample, forward } from 'effector';
import { v4 as uuidv4 } from 'uuid';

import { pluginCarsEvents, pluginCarsStores } from './index';
import { attachmentsEffects, attachmentsStores } from '../attachments';
import { extractDataFromBase64 } from '@dat/core/utils/attachments/extractDataFromBase64';

import { GroupedPluginCarsPhotos } from '../../types/pluginCars';
import { FormStep } from '@dat/plugin-cars/src/types';

const { pluginCarsCompleted, groupedPhotosReceived, openPluginCars, closePluginCars } = pluginCarsEvents;
const { steps } = pluginCarsStores;
const { uploadAttachmentsToGroupFx } = attachmentsEffects;
const { remainingUploadsOfGroups } = attachmentsStores;

forward({ from: pluginCarsCompleted, to: closePluginCars });

steps.on(remainingUploadsOfGroups, (steps, remainingUploadsOfGroups) =>
    steps.filter(step => remainingUploadsOfGroups[step.groupId] > 0)
);

sample({
    clock: pluginCarsCompleted,
    source: steps,
    fn: (source, clock) => {
        const allPhotos: FormStep[] = [...clock.steps];
        const additionalPhotos =
            clock.additionalSteps && clock.additionalSteps.map(item => ({ photo: item, photoType: 'additional' }));
        additionalPhotos && allPhotos.push(...additionalPhotos);

        return allPhotos.reduce<GroupedPluginCarsPhotos>((acc, step, i) => {
            const groupId = source?.[i]?.groupId ?? 0;
            const groupPhotos = acc[groupId] || [];

            return {
                ...acc,
                [groupId]: [...groupPhotos, step.photo]
            };
        }, {});
    },
    target: groupedPhotosReceived
});

groupedPhotosReceived.watch(groupedPhotos => {
    Object.entries(groupedPhotos).forEach(([groupId, photos]) => {
        uploadAttachmentsToGroupFx({
            groupId: +groupId,
            attachments: photos.map(photo => ({
                fileName: uuidv4(),
                documentID: +groupId,
                binaryData: extractDataFromBase64(photo),
                mimeType: 'image/jpeg',
                uploaded: new Date().toISOString()
            }))
        });
    });
});

openPluginCars.watch(() => {
    const initialOverflow = document.body.style.overflow;

    document.body.style.overflow = 'hidden';

    const unwatch = closePluginCars.watch(() => {
        document.body.style.overflow = initialOverflow;
        unwatch();
    });
});
