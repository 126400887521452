import { useStore } from 'effector-react';
import React, { FC, useEffect, useRef } from 'react';
import { toastEvents, toastStores } from './store';

import { StyledToastContainer } from './styles';

export const Toast: FC = () => {
    const id = useRef(Math.random());
    const renderedToastId = useStore(toastStores.renderedToastId);

    useEffect(() => {
        if (!renderedToastId) {
            toastEvents.setRenderedToastId(id.current);
        }
    }, [renderedToastId]);

    if (renderedToastId !== id.current) {
        return null;
    }

    return <StyledToastContainer closeOnClick pauseOnFocusLoss closeButton />;
};
