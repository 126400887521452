import React from 'react';

const Edit = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="7.5" cy="7.5" r="6" stroke="currentColor" />
        <line x1="7.5" y1="4.5" x2="7.5" y2="10.5" stroke="currentColor" strokeLinecap="round" />
        <line x1="10.5" y1="7.5" x2="4.5" y2="7.5" stroke="currentColor" strokeLinecap="round" />
    </svg>
);

export default Edit;
