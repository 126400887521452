import { VehicleSelectionModalPluginResult } from '@dat/vehicle-selection-modal';
import { PartialPayloadForCreateOrUpdateContract } from '@dat/shared-models/contract/types';
import { CONTRACT_ENTRIES_KEYS } from '@dat/core/constants/contract';
import { VehicleInfoObject } from '@dat/core/types';

export const parseVehicleSelectionPluginResult = (
    vehicleSelectionPluginResult: VehicleSelectionModalPluginResult
): PartialPayloadForCreateOrUpdateContract => {
    const {
        datECode,
        numberPlate,
        vin,
        cnit,
        kilometers,
        firstRegistration,
        vehicleSelects,
        equipmentSelects,
        constructionPeriod,
        container,
        paintType,
        kba,
        repairType,
        tiresPercent
    } = vehicleSelectionPluginResult.vehicle;
    const { OCRResult } = vehicleSelectionPluginResult;

    const getVinResult = () => {
        const result = {
            ...vehicleSelectionPluginResult.vinResult
        };

        if (tiresPercent) {
            result.vehicleInfo = { ...result.vehicleInfo, tiresPercent } as VehicleInfoObject;
        }

        return result;
    };

    return {
        Dossier: {
            Vehicle: {
                DatECode: datECode,
                RegistrationData: {
                    LicenseNumber: numberPlate,
                    CNIT: cnit,
                    KbaCode: parseIntSafe(kba)
                },
                VehicleIdentNumber: vin,
                MileageOdometer: parseIntSafe(kilometers),
                InitialRegistration: firstRegistration?.toISOString(),
                ...parseVehicleSelects(vehicleSelects),
                DATECodeEquipment: {
                    EquipmentPosition: parseEquipmentSelects(equipmentSelects)
                },
                ConstructionTime: +constructionPeriod,
                Container: container,
                VinActive: !!vehicleSelectionPluginResult.vinResult?.vin
            },
            RepairCalculation: {
                RepairParameters: {
                    LacquerTypeId: paintType
                },
                ...(!(repairType === 'APPRAISAL') && {
                    ProcedureRelatedParameters: {
                        ProcedureRelatedParameter: [
                            {
                                _text: paintType || '',
                                attribute: 'type',
                                factor: 'EuroLacquerFactor',
                                type: 'String'
                            },
                            {
                                _text: paintType || '',
                                attribute: 'type',
                                factor: 'ManufacturerLacquerFactor',
                                type: 'String'
                            },
                            { _text: paintType || '', attribute: 'type', factor: 'AztLacquerFactor', type: 'String' }
                        ]
                    }
                })
            }
        },
        templateData: {
            entry: [createVinResultMemoFieldEntry(getVinResult()), ...createOCRResultEntry(OCRResult)]
        }
    };
};

const parseVehicleSelects = (vehicleSelects: VehicleSelectionModalPluginResult['vehicle']['vehicleSelects']) => {
    const { vehicleType, manufacturer, baseModel, subModel } = vehicleSelects;

    return {
        VehicleType: parseIntSafe(vehicleType),
        Manufacturer: parseIntSafe(manufacturer),
        BaseModel: parseIntSafe(baseModel),
        SubModel: parseIntSafe(subModel)
    };
};

const parseEquipmentSelects = (
    equipmentSelects: VehicleSelectionModalPluginResult['vehicle']['equipmentSelects']
): DAT2.EquipmentPosition[] =>
    Object.values(equipmentSelects)
        .filter(value => value !== '')
        .map(value => ({
            DatEquipmentId: +value
        }));

const createVinResultMemoFieldEntry = (
    vinResult: VehicleSelectionModalPluginResult['vinResult']
): DAT2.Field.EntryForRequest<string, string> => ({
    key: CONTRACT_ENTRIES_KEYS.MEMO.vinResult,
    value: JSON.stringify(vinResult)
});

const parseIntSafe = (value: string) => {
    const isEmpty = value === '';
    const result = parseInt(value);

    if (isEmpty || Number.isNaN(result)) {
        return undefined;
    }

    return result;
};

const createOCRResultEntry = (OCRResult: VehicleSelectionModalPluginResult['OCRResult']) =>
    OCRResult ? Object.entries(OCRResult)?.map(item => ({ key: item[0], value: item[1] })) : [];
