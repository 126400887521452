import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as DeleteIcon } from '../CommonComponents/Icons/DeleteIcon.svg';
import { fastTrackElementModel } from '../../stores/fastTrackElementModel';
import { getTranslationFromFastTrackConfiguration } from '../../utils/getTranslationFromFastTrackConfiguration';
import i18next from 'i18next';
import { IconBtn } from '../CommonComponents/IconBtn';
import { FastTrackElement } from '@dat/api2dat/types/fastTrackInterfaceTypes';

const columnsftClaimDamages: ColumnsType<any> = [
    {
        title: 'fastTrack.Damage',
        dataIndex: 'elemLabel'
    },
    {
        title: 'fastTrack.DamageDegree',
        dataIndex: 'damageLabel',
        align: 'left',
        width: '30%'
    },
    {
        width: 48,
        align: 'right',
        render: (_, record) => (
            <IconBtn
                icon={<DeleteIcon />}
                onClick={e => {
                    fastTrackElementModel.events.delFastTrackDamage(record.elementId);
                    e.stopPropagation();
                }}
            />
        )
    }
];

const columnsftClaimDamagesMobile: ColumnsType<any> = [
    {
        dataIndex: 'elemLabel',
        render: (_, record) => (
            <>
                <div style={{ fontSize: '16px' }}>{record.elemLabel}</div>
                <div style={{ color: '#687792' }}>{record.damageLabel}</div>
            </>
        )
    },
    {
        width: 48,
        align: 'right',
        render: (_, record) => (
            <IconBtn
                icon={<DeleteIcon />}
                onClick={e => {
                    fastTrackElementModel.events.delFastTrackDamage(record.elementId);
                    e.stopPropagation();
                }}
            />
        )
    }
];

export const ListDamagesFT: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const { t } = useTranslation();

    const { selectedElements } = useStore(fastTrackElementModel.stores.$fastTrackElements);
    const fastTrackConfiguration = useStore(fastTrackElementModel.stores.$fastTrackConfiguration);
    if (!fastTrackConfiguration) return null;

    const translationFromFastTrackConfiguration = getTranslationFromFastTrackConfiguration({
        fastTrackConfiguration,
        language: i18next.language
    });

    const mainRepairPositions: FastTrackElement[] = [];
    const additionalRepairPositions: FastTrackElement[] = [];

    selectedElements.forEach(el => {
        el.elementId.includes('DATID_0') || el.elementId.includes('DATID_WC_')
            ? mainRepairPositions.push(el)
            : additionalRepairPositions.push(el);
    });

    const columns = isMobile ? columnsftClaimDamagesMobile : columnsftClaimDamages;

    return (
        <>
            {mainRepairPositions.length ? (
                <Table
                    rowKey="mainElementId"
                    className="weDat-ft-list-damage"
                    dataSource={mainRepairPositions.map(el => ({
                        ...el,
                        elemLabel: t(`fastLocalNameDATID.${el.elementId}`),
                        damageLabel: t(
                            `fastLocalNameDamages.${el.damageType}`,
                            translationFromFastTrackConfiguration[el.damageType] || el.damageType
                        )
                    }))}
                    title={() => t('fastTrack.MainRepairPositions')}
                    columns={columns.map(col => ({
                        ...col,
                        title: t(col.title?.toString() || '').toString()
                    }))}
                    pagination={false}
                    size="small"
                    locale={{ emptyText: t('fastTrack.NoData') }}
                    onRow={record => ({
                        onClick: _ => {
                            fastTrackElementModel.events.setMarkElementId(record.elementId);
                        }
                    })}
                />
            ) : null}
            {additionalRepairPositions.length ? (
                <Table
                    rowKey="additionalElementId"
                    className="weDat-ft-list-damage"
                    dataSource={additionalRepairPositions.map(el => ({
                        ...el,
                        elemLabel: t(`fastLocalNameDATID.${el.elementId}`),
                        damageLabel: t(
                            `fastLocalNameDamages.${el.damageType}`,
                            translationFromFastTrackConfiguration[el.damageType] || el.damageType
                        )
                    }))}
                    title={() => t('fastTrack.AdditionalRepairPositions')}
                    columns={columns.map(col => ({
                        ...col,
                        title: t(col.title?.toString() || '').toString()
                    }))}
                    pagination={false}
                    size="small"
                    locale={{ emptyText: t('fastTrack.NoData') }}
                    onRow={record => ({
                        onClick: _ => {
                            fastTrackElementModel.events.setMarkElementId(record.elementId);
                        }
                    })}
                />
            ) : null}
        </>
    );
};
