import { TFunction } from 'react-i18next';
import { RadioGroupItem } from '@wedat/ui-kit/components/RadioGroup';
import { MaterialMode, PerTimeOrAbsoluteMode } from '../types';

export const percentOrAbsolute = (t: TFunction): RadioGroupItem<number>[] => [
    { value: 1, label: t('common.percentRate') },
    { value: 0, label: t('common.absolute') }
];

const perTimeOrAbsolute = (t: TFunction): RadioGroupItem<PerTimeOrAbsoluteMode>[] => [
    { value: 'PER_TIME', label: t('paint.wage') },
    { value: 'ABSOLUTE', label: t('paint.wageFlatRate') }
];

const materialMode = (t: TFunction): RadioGroupItem<MaterialMode>[] => [
    {
        value: 'LACQUER_METHOD',
        label: t('paint.euroLacquer.repairWageLacquerMaterialPerPoint')
    },
    {
        value: 'PERCENT',
        label: t('paint.euroLacquer.materialFlatRatePercent')
    },
    {
        value: 'ABSOLUTE',
        label: t('paint.euroLacquer.materialFlatRateAbsolute')
    }
];

const selectedLacquerMethod = (): RadioGroupItem<1 | 2>[] => [
    { value: 1, label: 'Eurolack' },
    {
        value: 2,
        label: 'Manufacturer'
    }
];

export const radioItems = {
    percentOrAbsolute,
    perTimeOrAbsolute,
    materialMode,
    selectedLacquerMethod
};
