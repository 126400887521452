import { ParsedEquipment } from '../types/equipment';
import { PluginResult } from '../types/plugin';

export const createPluginResult = (equipment: ParsedEquipment): PluginResult => {
    const SeriesEquipment = equipment.filter(({ isSeries }) => isSeries);
    const SpecialEquipment = equipment.filter(({ isSpecial }) => isSpecial);

    return {
        SeriesEquipment,
        SpecialEquipment
    };
};
