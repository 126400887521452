interface DATGrapaLocaleMapType {
    [lng: string]: localeDescription;
}

interface localeDescription {
    locale: {
        country: string; // 'ru'
        datCountryIndicator?: string; // 'ru'
        language: string; // 'RU'
    };
    languageCode?: string; // for graphic request  'RUS'
}

//
// languageCode available in grapa data:
//
// languageCode: "BG",  "Рег. номер",
// languageCode: "CN",  "标志",
// languageCode: "CZ", "značka registr.",
// languageCode: "D",  "Kennzeichen",
// languageCode: "E",  "Matrícula",
// languageCode: "ENG", "licence plate",
// languageCode: "F",  "Plaque de police",
// languageCode: "GR",  "Πινακίδα κυκλοφορίας",
// languageCode: "H",  "rendszámtábla"
// languageCode: "I",  "targa",
// languageCode: "LT"   "Registracijos numeris"
// languageCode: "NL",   "Kentekenplaat", "matrícula",
// languageCode: "P",   "matrícula",
// languageCode: "PL",   "tablica rejestracyjna",
// languageCode: "RO",   "numar inmatriculare",
// languageCode: "ROK",  "번호판",
// languageCode: "RUS",  "Государственный регистрационный знак",
// languageCode: "SK",  "evidenčné číslo",
// languageCode: "TR",  "Araç plakası",

// public void populate( Map<String, DataLanguage> map ) {
//     map.put( "CZ",    DataLanguage.cs );//Czech
//     map.put( "SK",    DataLanguage.sk );//Slovak
//     map.put( "GB",    DataLanguage.en );//english
//     map.put( "ENG",   DataLanguage.en );//English
//     map.put( "D",  DataLanguage.de );//German
//     map.put( "E",    DataLanguage.es );//Spanish
//     map.put( "I",    DataLanguage.it );//Italian
//     map.put( "IL",    DataLanguage.he );//Isael
//     map.put( "F",    DataLanguage.fr );//French
//     map.put( "BG",    DataLanguage.bg );//Bulgarian
//     map.put( "NL",    DataLanguage.nl );//Dutch
//     map.put( "RUS",   DataLanguage.ru );//Russian
//     map.put( "H",   DataLanguage.hu );//Hungarian
//     map.put( "RO",   DataLanguage.ro );//Romanian
//     map.put( "PL",   DataLanguage.pl );//Polish
//     map.put( "TR",   DataLanguage.tr );//Turkish
//     map.put( "GR",   DataLanguage.el );//Greek
// }

// A ("Oesterreich"),
// B ("Belgien"),
// BG ("Bulgarien"),
// CH ("Schweiz"),
// CN ("China"),
// CZ ("Tschechien"),
// D  ("Deutschland"),
// DK ("Daenemark"),
// E  ("Spanien"),
// F  ("Frankreich"),
// GB ("Grossbritannien"),
// GR ("Griechenland"),
// H  ("Ungarn"),
// HR ("Kroatien"),
// I  ("Italien"),
// L  ("Luxemburg"),
// NL ("Niederlande"),
// P  ("Portugal"),
// PL ("Polen"),
// RO ("Rumaenien"),
// RUS ("Russland"),
// S   ("Schweden"),
// SK  ("Slowakei"),
// SRB ("Serbien"),
// TR  ("Tuerkei");

// map.put( "CZ",    DataLanguage.cs );//Czech
// map.put( "SK",    DataLanguage.sk );//Slovak
// map.put( "GB",    DataLanguage.en );//english
// map.put( "ENG",   DataLanguage.en );//English
// map.put( "D",  DataLanguage.de );//German
// map.put( "E",    DataLanguage.es );//Spanish
// map.put( "I",    DataLanguage.it );//Italian
// map.put( "IL",    DataLanguage.he );//Isael
// map.put( "F",    DataLanguage.fr );//French
// map.put( "BG",    DataLanguage.bg );//Bulgarian
// map.put( "NL",    DataLanguage.nl );//Dutch
// map.put( "RUS",   DataLanguage.ru );//Russian
// map.put( "H",   DataLanguage.hu );//Hungarian
// map.put( "RO",   DataLanguage.ro );//Romanian
// map.put( "PL",   DataLanguage.pl );//Polish
// map.put( "TR",   DataLanguage.tr );//Turkish
// map.put( "GR",   DataLanguage.el );//Greek

// // TODO find a better way to handle languages, which hasn't the same name as the country
// localesMap.put( "EN", "ENG" ); // english
// localesMap.put( "GB", "D" ); // great britain -> "Germany" (who cares about other countries?)
// localesMap.put( "US", "D" ); // USA -> "Germany"
// localesMap.put( "EL", "GR" ); // greek
// localesMap.put( "CS", "CZ" ); // czech
// localesMap.put( "RU", "RUS" ); //RU is NOT Burgundi!!!!!
// localesMap.put( "JP", "D" ); //Japan auf D
// localesMap.put( "JA", "ENG" ); //Japan auf ENG
// localesMap.put( "CN", "CN" ); //China to 'CN' (to overwrite the entry 'VRC' in rflk_adr_lkz)
// localesMap.put( "ZH", "CN" ); //chinese to 'CN' (see 3.0-00485 China TP01)
// localesMap.put( "DA", "DK" ); //danish to 'DK'
// localesMap.put( "KO", "ROK" ); //korean to 'ROK'
// localesMapFromDatToIso.put( "CN", "CN" ); // China to 'CN' (to overwrite the entry 'VRC' in rflk_adr_lkz)
// //localesMapFromDatToIso.put( "RU", "RU" ); //RU is NOT Burgundi!!!!!
// localesMapFromDatToIsoSpecialsForLanguages.put( "GR", "EL" );   // the greek language is for DAT "GR", for ISO "EL"
// localesMapFromDatToIsoSpecialsForLanguages.put( "CZ", "CS" );   // the czech language is for DAT "CZ", for ISO "CS"
// localesMapFromDatToIsoSpecialsForLanguages.put( "ENG", "EN" );   // the english language is for DAT "ENG", for ISO "EN"
// localesMapFromDatToIsoSpecialsForLanguages.put( "CN", "ZH" );
// localesMapFromDatToIsoSpecialsForLanguages.put( "DK", "DA" );   // the danish language is for DAT "DK", for ISO "DA"
// localesMapFromDatToIsoSpecialsForLanguages.put( "ROK", "KO" );  // the korean language is for DAT "ROK", for ISO "KO"

// /*
// 	The Lithuanian cd texts are available only for spr_lkz == 'D  '.
// 	This is the same situation as English, where the cd texts are also only available for spr_lkz == 'D  '.
// 	The situation for English:
// 	 The locale is "en_US" or "en_GB". Because the lines above 'localesMap.put( "GB", "D" )', 'localesMap.put( "US", "D" )',
// 	 the language country will be mapped always to "D".
// 	The situation for Lithuanian:
// 	 The locale is "lt_LT". We must take care, that the country "LT" will be mapped to "D".
// 	 Therefore, we introduce here a new map "localesMapFromIsoToIsoSpecialsForLanguageCountries" with this exception.
// */
// localesMapFromIsoToIsoSpecialsForLanguageCountries.put( "LT", "DE" );   // the lithuanian language can only be used with german language country
// /*
// 	The situation for Portuguese:
// 	 The locale is "pt_PT". We must take care, that the country "P" will be mapped to "E".
// 	 Therefore, we add here to the map "localesMapFromIsoToIsoSpecialsForLanguageCountries" with this exception.
// */
// localesMapFromIsoToIsoSpecialsForLanguageCountries.put( "PT", "ES" );   // the portuguese language can only be used with spanish language country
// /*
// 	The situation for Korean:
// 	 The locale is "ko_KR". We must take care, that the country "ROK" will be mapped to "D".
// 	 Therefore, we add here to the map "localesMapFromIsoToIsoSpecialsForLanguageCountries" with this exception.
// */
// localesMapFromIsoToIsoSpecialsForLanguageCountries.put( "KR", "DE" );   // the korean language can only be used with german language country

// languageCountries.computeIfAbsent( "LT", k -> new ArrayList<>() );
// languageCountries.get( "LT" ).add( "LT" );

// // After having modified the xlsl (with a line xlsl_lkz="E" and xlsl_spr="P"), the following two lines can be removed
// languageCountries.computeIfAbsent( "P", k -> new ArrayList<>() );
// languageCountries.get( "P" ).add( "P" );

// languageCountries.computeIfAbsent( "DK", k -> new ArrayList<>() );
// languageCountries.get( "DK" ).add( "DK" );

// languageCountries.computeIfAbsent( "ROK", k -> new ArrayList<>() );
// languageCountries.get( "ROK" ).add( "ROK" );

// // read the base countries from datrxlan (if the xlan_lkz_basis is not null)
// buildLocalesMapIsoCountryToIsoBaseCountry();

// // After having modified the xlan (with a line xlan_lkz="P" and xlan_lkz_basis="E"), the following line can be removed
// localesMapIsoCountryToIsoBaseCountry.put( "PT", "ES" );

// languagesMap = new FlippableMap<>( FlippableMap.Type.BOTH );
// languagesMap.put( "CZ" , "cz_CZ" );
// languagesMap.put( "D"  , "de_DE" );
// languagesMap.put( "E"  , "es_ES" );
// languagesMap.put( "ENG", "en_US" );
// languagesMap.put( "F"  , "fr_FR" );
// languagesMap.put( "GR" , "el_GR" );
// languagesMap.put( "H"  , "hu_HU" );
// languagesMap.put( "I"  , "it_IT" );
// languagesMap.put( "PL" , "pl_PL" );
// languagesMap.put( "RO" , "ro_RO" );
// languagesMap.put( "RUS", "ru_RU" );
// languagesMap.put( "TR" , "tr_TR" );

const defaultLanguage = 'en';
const DATGrapaLocaleMap: DATGrapaLocaleMapType = {
    cs: {
        locale: {
            language: 'cs',
            country: 'CS'
        },
        languageCode: 'CZ'
    },
    de: {
        locale: {
            language: 'de',
            country: 'DE'
        },
        languageCode: 'D'
    },
    el: {
        locale: {
            language: 'gr',
            country: 'GR'
        },
        languageCode: 'GR'
    },
    en: {
        locale: {
            language: 'en',
            country: 'US'
        },
        languageCode: 'ENG'
    },
    es: {
        locale: {
            language: 'es',
            country: 'ES'
        },
        languageCode: 'E'
    },
    fr: {
        locale: {
            language: 'fr',
            country: 'FR'
        },
        languageCode: 'F'
    },
    it: {
        locale: {
            language: 'it',
            country: 'IT'
        },
        languageCode: 'I'
    },
    ko: {
        locale: {
            language: 'ko',
            country: 'KO'
        },
        languageCode: 'ROK'
    },
    nl: {
        locale: {
            language: 'nl',
            country: 'NL'
        },
        languageCode: 'NL'
    },
    pl: {
        locale: {
            language: 'pl',
            country: 'PL'
        },
        languageCode: 'PL'
    },
    ru: {
        locale: {
            language: 'ru',
            country: 'RU'
        },
        languageCode: 'RUS'
    },
    zh: {
        locale: {
            language: 'zn',
            country: 'ZN'
        },
        languageCode: 'CN'
    }
};

export function getLanguage(lang?: string): localeDescription {
    if (!lang) return DATGrapaLocaleMap[defaultLanguage];
    let locale = DATGrapaLocaleMap[lang];
    if (!locale) locale = DATGrapaLocaleMap[defaultLanguage];
    return locale;
}
