import styled, { css, FlattenInterpolation, ThemeProps, DefaultTheme } from 'styled-components';
import { SizeCounter } from './types';

const sizesList: { [key in SizeCounter]: FlattenInterpolation<ThemeProps<DefaultTheme>> } = {
    big: css`
        width: 40px;
        height: 28px;
        ${({ theme }) => theme.typography.footnote}
    `,
    medium: css`
        width: 38px;
        height: 26px;
        ${({ theme }) => theme.typography.font12}
    `,
    small: css`
        width: 30px;
        height: 18px;
        ${({ theme }) => theme.typography.font12}
    `,
    tiny: css`
        width: 8px;
        height: 8px;
        border-radius: 50%;
        text-indent: -9999px;
    `
};

export const Container = styled.div<{ size: SizeCounter }>`
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.dustBlue['900']};
    background-color: ${({ theme }) => theme.colors.yellow['400']};
    border-radius: 8px;
    transform: translate(-50%, -50%);
    ${({ size }) => sizesList[size]};
    font-weight: 700;
`;
