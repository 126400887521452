import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { radioItems } from '../../constants/radioItems';

import { TabsField } from '@wedat/ui-kit/Formik';
import { EuroLacquerFactors } from './EuroLacquerFactors';
import { ManufacturerLacquerFactors } from './ManufacturerLacquerFactors';

import { FactorsParametersObject } from '../../types/factors';
import { AccordionItemContent, TabsWrapper } from '../Layout/styles';
import { AztLacquerFactors } from './AztLacquerFactors';
import { CzLacquerFactor } from './СzLacquerFactors';
import { useStore } from 'effector-react';
import { sharedTemplateStores } from '@dat/shared-models/template';
import { DISABLE_COUNTRIES } from '../../constants/initialFactorsParameters/aztLacquer';
import { configurationStores } from '../../stores/configuration';

export const PaintFactors: React.FC = () => {
    const { t } = useTranslation();
    const country = useStore(sharedTemplateStores.templateSettings.country);
    const configuration = useStore(configurationStores.configuration);

    const {
        values: {
            CalculationFactor: { selectedLacquerMethod }
        }
    } = useFormikContext<FactorsParametersObject>();

    const shouldShowEuroLacquer = selectedLacquerMethod === 'EURO_LACQUER';
    const shouldShowManufacturerLacquer = selectedLacquerMethod === 'MANUFACTURER_SPECIFIC';
    const shouldShowAZTLacquer = selectedLacquerMethod === 'AZT';
    const shouldShowCZLacquer = selectedLacquerMethod === 'CZ';

    const countriesForHideAzt = configuration.countriesForHideAzt || [];

    const hideAztLacquer = [...countriesForHideAzt, ...DISABLE_COUNTRIES].includes(country);
    const selectedLacquerMethodValues = radioItems.selectedLacquerMethod(t);
    const tabValues = selectedLacquerMethodValues.map(item => ({ ...item, id: item.value }));
    const filteredTabValues = hideAztLacquer ? tabValues.filter(item => item.id !== 'AZT') : tabValues;
    const resultTabValues = country !== 'ES' ? filteredTabValues.filter(item => item.id !== 'CZ') : filteredTabValues;

    return (
        <AccordionItemContent>
            <TabsWrapper>
                <TabsField name="CalculationFactor.selectedLacquerMethod" values={resultTabValues} typeStyle="mobile" />
            </TabsWrapper>
            {shouldShowEuroLacquer && <EuroLacquerFactors />}
            {shouldShowManufacturerLacquer && <ManufacturerLacquerFactors />}
            {shouldShowAZTLacquer && <AztLacquerFactors />}
            {shouldShowCZLacquer && <CzLacquerFactor />}
        </AccordionItemContent>
    );
};
