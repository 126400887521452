import deepmerge from 'deepmerge';

import {
    getParsedArraySafe,
    getTextValuesFromParsedObject,
    getTextValuesFromParsedObjectRecursive
} from '@dat/api2/utils';

import { GetTextValuesFromParsedObjectRecursive } from '@dat/api2/utils/getTextValuesFromParsedObjectRecursive';
import { DossierWithExtractedTextValues } from '../types/dossier';

/*
 * Works like `getTextValuesFromParsedObjectRecursive` but with preserving `SpecialEquipment` positions
 */
export const extractTextValuesFromDossier = (dossier: DAT2.Dossier): DossierWithExtractedTextValues => {
    const dossierWithExtractedTextValues = getTextValuesFromParsedObjectRecursive(dossier);
    const specialEquipmentPositions = getParsedArraySafe(
        dossier.Vehicle?.Equipment?.SpecialEquipment?.EquipmentPosition
    );

    return deepmerge<GetTextValuesFromParsedObjectRecursive<DAT2.Dossier>, DossierWithExtractedTextValues>(
        dossierWithExtractedTextValues,
        {
            Vehicle: {
                Equipment: {
                    SpecialEquipment: {
                        EquipmentPosition: specialEquipmentPositions.map(getTextValuesFromParsedObject)
                    }
                }
            }
        }
    );
};
