import styled, { css } from 'styled-components/macro';
import { LIST_ITEM_HEIGHT } from '../constants';

interface ItemContainerProps {
    selected?: boolean;
}

export const ItemContainer = styled.div<ItemContainerProps>`
    max-width: 90%;
    height: ${LIST_ITEM_HEIGHT};
    padding: 10px;
    color: ${({ theme }) => theme.colors.gray_300};
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ selected }) =>
        selected &&
        css`
            background-color: rgba(66, 164, 255, 0.15);
            color: ${({ theme }) => theme.colors.blueDark};
        `}

    &:hover {
        color: ${({ theme }) => theme.colors.blueDark};
        background-color: rgba(66, 164, 255, 0.15);
    }
`;
