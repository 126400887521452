import { attach, createStore } from 'effector';
import { pending } from 'patronum';

import { contractEffects } from '@dat/shared-models/contract';

import { createPayloadForCreateOrUpdateContract } from '../../utils/createPayloadForCreateOrUpdateContract';

const fuelValueProcessingFx = attach({
    effect: contractEffects.updateCurrentContractFx,
    mapParams: fuelValue => createPayloadForCreateOrUpdateContract({ fuelValue })
});

const fuelValue = createStore('').on(fuelValueProcessingFx, (_, newFuelValue) => newFuelValue);

const fuelValueUpdatingContractFx = attach({ effect: contractEffects.getContractFx });

const isLoading = pending({
    effects: [fuelValueProcessingFx, fuelValueUpdatingContractFx]
});

export const fuelLevelStores = {
    fuelValue,
    isLoading
};

export const fuelLevelEffects = {
    fuelValueProcessingFx,
    fuelValueUpdatingContractFx
};
