import { createApi } from 'effector';
import { createToggle } from '@dat/core/utils/effector/createToggle';
import { domain } from './plugin';

const isEquipmentModalOpen = domain.createStore(false);
const isEquipmentModalOpenApi = createApi(isEquipmentModalOpen, {
    openEquipmentModal: (_, __: any) => true,
    closeEquipmentModal: (_, __: any) => false
});

const isTechnicalDataModalOpen = domain.createStore(false);
const isTechnicalDataModalOpenApi = createApi(isTechnicalDataModalOpen, {
    openTechnicalDataModal: (_, __: any) => true,
    closeTechnicalDataModal: (_, __: any) => false
});

const [isVSMModalOpen, toggleIsVSMModalOpen] = createToggle(false, { domain });
const [isPluginCarsOpen, toggleIsPluginCarsOpen] = createToggle(false, { domain });
const [isAnylineOpen, toggleIsAnylineOpen] = createToggle(false, { domain });
const [isSubModelsModalOpen, toggleSubModelsModalOpen] = createToggle(false, { domain });

export const modalsEvents = {
    ...isEquipmentModalOpenApi,
    ...isTechnicalDataModalOpenApi,
    toggleIsVSMModalOpen,
    toggleIsPluginCarsOpen,
    toggleIsAnylineOpen,
    toggleSubModelsModalOpen
};
export const modalsStores = {
    isEquipmentModalOpen,
    isSubModelsModalOpen,
    isTechnicalDataModalOpen,
    isVSMModalOpen,
    isPluginCarsOpen,
    isAnylineOpen
};
