import React from 'react';

import { Container, Layout } from './styles';
import { Buttons } from '../Buttons';
import { Attachments } from '../Attachments';
import { Lightbox } from '../Lightbox';
import { PluginCars } from '../PluginCars';
import { EditDialog } from '../EditDialog';
import { Portal } from '@wedat/ui-kit/components/Portal';

export const Main: React.FC = () => (
    <Layout>
        <Container>
            <Buttons />
            <Attachments />
            <Lightbox />
            <EditDialog />
            <Portal>
                <PluginCars />
            </Portal>
        </Container>
    </Layout>
);
