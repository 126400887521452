import fetch from 'node-fetch';
import { ResultGetVehicleType } from './ItalyAniaTypes2';

const urlItalyAniaService = 'https://wedat.it/domus/api';

export async function getVehicleType(datecode: string | undefined): Promise<ResultGetVehicleType | undefined> {
    if (!datecode) return;
    const FZA = datecode.substring(0, 2);
    const HST = datecode.substring(2, 5);
    const HT = datecode.substring(5, 8);
    const formData = 'FZA=' + FZA + '&HST=' + HST + '&HT=' + HT;

    const options = {
        method: 'POST',
        body: new URLSearchParams(formData)
    };
    const url = urlItalyAniaService + '/getVehicleType';

    const resultFetch = await fetch(url, options);
    const resJson = await resultFetch.json();
    const resArray: [string, number] = resJson;
    const result: ResultGetVehicleType = {
        datecode: datecode,
        response: resArray
    };
    return result;
}
